import { isEqual, sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { useGroup } from '../../../../../../contexts/GroupContext';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import setMultiSelectOptions from '../../../../../../lib/multiSelectOptions';
import { useGetAssignableCurriculumsQuery } from '../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { usePatchGroupCurriculumsMutation } from '../../../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupSubject } from '../../../../../../types/GroupSubject';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';
import { ChecklistOption } from '../../../../../design_system/Triage/Checklist';
import AssignSubject from '../../../../people/AssignSubjectsModal/AssignSubject';
import SearchableChecklistModal from '../../../../shared/SearchableChecklistModal/SearchableChecklistModal';

const t = initTranslations('groups.groups_edit.content.table.manage_group_content_modal');

export interface ManageContentModalProps {
  closeModal: () => void;
}

const ManageContentModal = ({ closeModal }: ManageContentModalProps) => {
  const group = useGroup();
  const { curriculums: groupAssignedCurriculums } = group;
  const {
    curriculum: { plural: curriculumPlural },
  } = useAccountTerminology();
  const [checkboxOptions, setCheckboxOptions] = useState<ChecklistOption[]>([]);
  const [isLoadingCheckboxOptions, setIsLoadingCheckboxOptions] = useState(true);
  const [selectedModalCurriculumOptions, setSelectedModalCurriculumOptions] = useState<
    ChecklistOption[]
  >([]);
  const { isLoading, data: curriculums, error } = useGetAssignableCurriculumsQuery();
  const [assignCurriculumsToGroup, result] = usePatchGroupCurriculumsMutation();
  const { isLoading: isSubmitting } = result;
  const changesMade = useMemo(() => {
    const startingContent = sortBy(groupAssignedCurriculums.map((curriculum) => curriculum.id));
    const selectedContent = sortBy(
      selectedModalCurriculumOptions.map((selectedCurriculum) => selectedCurriculum.id)
    );
    return !isEqual(startingContent, selectedContent);
  }, [groupAssignedCurriculums, selectedModalCurriculumOptions]);

  useEffect(() => {
    if (curriculums) {
      const uniqueIds: Record<number, boolean> = {};
      setCheckboxOptions(
        setMultiSelectOptions(
          curriculums
            .concat(groupAssignedCurriculums)
            .reduce<GroupSubject[]>((acc, item) => {
              if (!uniqueIds[item.id]) {
                uniqueIds[item.id] = true;
                acc.push(item);
              }
              return acc;
            }, [])
            .map((curriculum) => ({
              ...curriculum,
              title: <AssignSubject curriculum={curriculum} />,
              filterOn: curriculum.title,
            })),
          groupAssignedCurriculums.map((curriculum) => ({ id: curriculum.id })),
          'subject'
        )
      );
      setIsLoadingCheckboxOptions(false);
    }
  }, [groupAssignedCurriculums, curriculumPlural, curriculums]);

  useEffect(() => {
    setSelectedModalCurriculumOptions(checkboxOptions.filter((item) => item.checked));
  }, [checkboxOptions]);

  useDisplayFlashOnResponse({
    result,
    successFunction: closeModal,
  });

  const initialSelectedIds = checkboxOptions
    .filter((s) => s.checked)
    .map((curriculum) => curriculum.id);
  const currentlySelectedIds = selectedModalCurriculumOptions
    .filter((s) => s.checked)
    .map((curriculum) => curriculum.id);

  const addedIds = currentlySelectedIds.filter((id) => !initialSelectedIds.includes(id));
  const removedIds = initialSelectedIds.filter((id) => !currentlySelectedIds.includes(id));

  const taskModalArgs: TaskModalProps = {
    title: t('title'),
    isDisabled: isSubmitting || !changesMade,
    processing: isSubmitting,
    onCloseRequest: closeModal,
    primaryButtonText: t('submit_action'),
    primaryButtonTask: () => {
      assignCurriculumsToGroup({
        groupId: group.id,
        added_curriculum_ids: addedIds,
        removed_curriculum_ids: removedIds,
      });
    },
    heapModalName: 'manage-group-content-modal',
  };

  return (
    <SearchableChecklistModal
      checkboxOptions={checkboxOptions}
      errorMessage={error ? t('error_message', { curriculum: curriculumPlural }) : null}
      isLoading={isLoading || isLoadingCheckboxOptions}
      numberSelected={selectedModalCurriculumOptions.length}
      onSelectionChanged={(selectedOptions: ChecklistOption[]) =>
        setSelectedModalCurriculumOptions(selectedOptions)
      }
      taskModalProps={taskModalArgs}
    />
  );
};

export default ManageContentModal;
