import { find } from 'lodash';
import React, { ReactElement, ReactNode, useContext, useMemo, useState } from 'react';

import usePageTitle from '../hooks/usePageTitle';
import { ConsumptionCourse, ConsumptionCurriculum } from '../types/Editor';

type ConsumptionCourseProps = {
  children: ReactNode;
  course: ConsumptionCourse;
  curriculum: ConsumptionCurriculum;
  stepId: number;
  productTerm: string;
};

type ConsumptionCourseContextProps = {
  course: ConsumptionCourse;
  curriculum: ConsumptionCurriculum;
  isCourseCompleteShowing: boolean;
  setIsCourseCompleteShowing: (isCourseComplete: boolean) => void;
  isESignatureShowing: boolean;
  setIsESignatureShowing: (showESignature: boolean) => void;
  stepId: number;
  currentStepCompleted: boolean;
};

const ConsumptionCourseContext = React.createContext({} as ConsumptionCourseContextProps);

export const useConsumptionCourseContext = () => useContext(ConsumptionCourseContext);

const ConsumptionCourseProvider = ({
  children,
  course,
  curriculum,
  stepId,
  productTerm,
}: ConsumptionCourseProps): ReactElement => {
  const [isCourseCompleteShowing, setIsCourseCompleteShowing] = useState(false);
  const [isESignatureShowing, setIsESignatureShowing] = useState(false);

  const currentStep = useMemo(() => {
    return find(course.steps, { id: stepId });
  }, [course.steps, stepId]);

  const currentStepCompleted = useMemo(() => {
    if (currentStep) {
      const { completed } = currentStep;

      return completed;
    }
    return false;
  }, [currentStep]);

  usePageTitle({ resourceName: course.title, resourceType: 'course', productTerm });

  return (
    <ConsumptionCourseContext.Provider
      value={{
        course,
        curriculum,
        isCourseCompleteShowing,
        setIsCourseCompleteShowing,
        isESignatureShowing,
        setIsESignatureShowing,
        stepId,
        currentStepCompleted,
      }}
    >
      {children}
    </ConsumptionCourseContext.Provider>
  );
};

export { ConsumptionCourseProvider };
