import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';

import { reportsApi } from '../../../../redux/services/resourceApis/reports/reportsApi';
import DownloadCsvReport from '../DownloadCsvReport/DownloadCsvReport';

const SurveyDownloadCsvReport = ({ surveyId }: { surveyId: number }) => {
  const [getCsv] = reportsApi.useLazyGetSurveyReportCsvQuery();
  const [cookies] = useCookies(['survey_report_selected_sort']);
  const isCookies = cookies.survey_report_selected_sort;
  const sortCol = isCookies && cookies.survey_report_selected_sort['column'];
  const sortColDir = isCookies && cookies.survey_report_selected_sort['dir'];

  const downloadCsvDocument = useCallback(() => {
    getCsv({ id: surveyId, sortCol, sortColDir });
  }, [getCsv, sortCol, sortColDir, surveyId]);

  return (
    <DownloadCsvReport downloadCsvDocument={downloadCsvDocument} id='survey-button-download-csv' />
  );
};

export default SurveyDownloadCsvReport;
