import initTranslations from '../../lib/initTranslations';

const t = initTranslations('per_user_pricing.manage_plan_overlay_content.plan_cards');

export function getPlanCardStatusText(isNewPlan: boolean, isManageSeatsOverlay?: boolean) {
  if (isManageSeatsOverlay) {
    return isNewPlan ? t('updated_seats') : t('current_seats');
  } else {
    return isNewPlan ? t('new_plan') : t('current_plan');
  }
}
