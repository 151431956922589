import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { MenuOption } from '../../../../../design_system/core/MenuOptions';
import { SharedOptionProps } from '../../../ThreeDotOptions/types';

const t = initTranslations('three_dot_menu');

const AddToChrome = ({ isDisabled, onClick, id, tooltipText }: SharedOptionProps) => {
  return (
    <MenuOption
      iconName='share-nodes'
      iconWeight='regular'
      id={id}
      isDisabled={isDisabled}
      onClick={onClick}
      title={t('add_to_chrome')}
      tooltipText={tooltipText}
    />
  );
};

export default AddToChrome;
