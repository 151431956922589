import { CurrentTaskName } from '../../components/application/home/Onboarding/task_driven/TaskDrivenOnboardingCard/types';
import { TaskDrivenOnboardingActionId } from '../../types/TaskDrivenOnboarding';
import useCurrentAccount from '../useCurrentAccount';
import useCurrentUserAbilities from '../useCurrentUserAbilities';

export function useSkipTaskDrivenOnboardingAction(currentTaskName: CurrentTaskName) {
  const ability = useCurrentUserAbilities();
  const { status } = useCurrentAccount();
  const isTrialEnded = status === 'trial_ended';
  const canReadBulkUserUploadModal = ability.can('read', 'BulkUserUploadModal');
  const canReadManageSettings = ability.can('read', 'ManageSettings');
  const canCreateUser = ability.can('create', 'User');
  const skipInviteTeammateCard = currentTaskName === 'invite' && (!canCreateUser || isTrialEnded);

  const shouldSkipActionButton = (id: TaskDrivenOnboardingActionId) => {
    const skipChooseTemplateOrCreateRoles =
      ['choose-template', 'create-groups'].includes(id) && isTrialEnded;
    const skipBulkAdd = id === 'bulk-add' && !canReadBulkUserUploadModal;
    const skipAddIndividually = id === 'add-individually' && !canCreateUser;
    const skipAddViaHrTool = id === 'auto-add-hr' && !canReadManageSettings;

    return (
      skipChooseTemplateOrCreateRoles || skipBulkAdd || skipAddIndividually || skipAddViaHrTool
    );
  };

  return { skipInviteTeammateCard, shouldSkipActionButton };
}
