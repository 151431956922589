import React from 'react';

import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useGetStepQuery } from '../../../../redux/services/resourceApis/steps/stepsApi';
import ViewModeSkeleton from './components/ViewModeSkeleton/ViewModeSkeleton';
import ConsumeContent from './ConsumeContent';

type Props = {
  stepId: number;
};

const ConsumptionPageBase = ({ stepId }: Props) => {
  const {
    data: stepData,
    error,
    isLoading,
  } = useGetStepQuery(stepId, { refetchOnMountOrArgChange: true });

  if (error) return <BasicErrorDisplay error={error} />;
  if (isLoading || !stepData) return <ViewModeSkeleton />;

  return <ConsumeContent stepData={stepData} />;
};

export default ConsumptionPageBase;
