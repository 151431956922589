import styled from 'styled-components';

import hexToRGBA from '../../../../lib/HexToRGBA';

export const LockedOverlay = styled.div`
  background-color: ${({ theme: { vars } }) => hexToRGBA(vars.foundationSurface1, 0.5)};
  height: 100%;
  width: 100%;
  right: 0;
  z-index: 10;
  position: absolute;
  cursor: not-allowed;
`;
