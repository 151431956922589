import { TypeName } from '../../../../components/application/shared/ContentFlyout/types';
import { QueryLiteralsValues } from './types';

export const QUERY_LITERALS: Record<TypeName, QueryLiteralsValues> = {
  [TypeName.Curriculum]: {
    path: 'curriculums_data',
  },
  [TypeName.Course]: {
    path: 'courses_data',
    param: 'curriculum_id',
  },
  [TypeName.Step]: {
    path: 'steps_data',
    param: 'course_id',
  },
};
