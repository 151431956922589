import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { TrainingPathDelayState } from '../../../../../../redux/services/resourceApis/trainingPaths/types';
import Badge from '../../../../../design_system/display/badge/Badge';
import Icon from '../../../../../design_system/display/icons/Icon';

// Translations
const t = initTranslations('training_path.path_overview.overview_sets.overview_set');

// Styled Components
const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const StyledSetHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
`;

const StyledSetHeaderText = styled.h4`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin: 0;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

type DelayHeaderProps = {
  delayType: string | null;
  delayValue: number | null;
  delayState: TrainingPathDelayState;
};

const DelayHeader = ({ delayState, delayType, delayValue }: DelayHeaderProps) => {
  if (delayState.status === 'no_delay' || delayState.status === 'complete') return null;

  const delayInProgress = delayState.status === 'in_progress';

  const delayMessage = delayInProgress
    ? t('delay_in_progress')
    : t(`training_break.${delayType}`, { delayValue });

  const icon = delayInProgress ? 'rotate' : 'calendar';

  return (
    <StyledSetHeader>
      <StyledIcon name={icon} size='sm' />
      <StyledHeaderContainer>
        <StyledSetHeaderText>{delayMessage}</StyledSetHeaderText>
        {delayInProgress && (
          <Badge
            text={t('badge_message', { availableAtInWords: delayState.availableAtInWords })}
            type='trainual-purple'
          />
        )}
      </StyledHeaderContainer>
    </StyledSetHeader>
  );
};

export default DelayHeader;
