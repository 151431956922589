import React, { useReducer, useRef, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../contexts/EditorContext';
import { useEditorCourseContext } from '../../../../contexts/EditorCourseContext';
import { EditorToolbarProvider } from '../../../../contexts/EditorToolbarContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { GetStepResponse } from '../../../../redux/services/resourceApis/steps/types';
import AutoSaveStatusIndicator from '../../shared/./AutoSaveStatusIndicator/AutoSaveStatusIndicator';
import ShareChangesModal from '../../curriculums/ShareChangesModal/ShareChangesModal';
import { routes } from '../../publicApplication/applicationRouter';
import GoBackIconButton from '../components/GoBackIconButton/GoBackIconButton';
import { SharedToolbarStyles } from '../shared/styles';
import ToolbarTabs from '../ToolbarTabs';
import BaseToolbar, { LockedToolbar, ToolbarAll } from './';
import NotifyButton from './buttons/components/notify/NotifyButton';
import InsertToolbar from './InsertToolbar/InsertToolbar';

export const CREATE_TOOLBAR_HEIGHT = '7.625rem';

const ToolbarWrapper = styled.div`
  ${SharedToolbarStyles};
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerMd2} 0 ${constants.spacerMd2}`};
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  flex-grow: 1;
`;

const RightDiv = styled.div`
  position: absolute;
  top: ${({ theme: { constants } }) => constants.spacerMd1};
  right: ${({ theme: { constants } }) => constants.spacerMd2};
  display: flex;
  align-items: center;
  justify-content: end;
  flex-basis: 15%;
`;

const LeftDiv = styled.div`
  position: absolute;
  top: ${({ theme: { constants } }) => constants.spacerMd2};
  left: ${({ theme: { constants } }) => constants.spacerMd2};
  flex-basis: 15%;
`;

const Toolbar = styled(BaseToolbar)`
  padding: ${({ theme: { constants } }) => constants.spacerMd1};
`;

interface ToolbarState {
  action: ToolbarAction;
}

type ToolbarAction = 'format' | 'insert';

export interface ToolbarActionProps {
  type: ToolbarAction;
}

export const initialToolbarState: ToolbarState = {
  action: 'format',
};

export const toolbarReducer = (_state: ToolbarState, action: ToolbarActionProps): ToolbarState => {
  const { type } = action;

  switch (type) {
    case 'format':
      return { action: 'format' };
    case 'insert':
      return { action: 'insert' };
  }
};

const t = initTranslations('editor_toolbar');

interface Props {
  step: GetStepResponse;
  isLocked: boolean;
  isFetching: boolean;
}

const ToolbarContainer = ({ step, isLocked, isFetching }: Props) => {
  const { saveState } = useEditorContext();
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const {
    curriculum: { title, id, completionRequired },
    stepId,
  } = useEditorCourseContext();
  const [{ action }, toolbarDispatch] = useReducer(toolbarReducer, initialToolbarState);
  const ref = useRef<HTMLDivElement>(null);
  const { slug, lockBrandStyles } = useCurrentAccount();
  const { lastEditedBy, lastEditedOn, updatedAtInWords } = step.updatedAtData;

  return (
    <>
      <ToolbarWrapper className='editor-toolbar' ref={ref}>
        <LeftDiv>
          <NotifyButton setShowNotifyModal={setShowNotifyModal} />
        </LeftDiv>
        <CenterDiv>
          <ToolbarTabs checkedOption={action} toolbarDispatch={toolbarDispatch} />
          <EditorToolbarProvider
            buttons={lockBrandStyles ? LockedToolbar : ToolbarAll}
            context='docked'
          >
            {action === 'insert' && <InsertToolbar />}
            {action === 'format' && <Toolbar />}
          </EditorToolbarProvider>
        </CenterDiv>
        <RightDiv>
          <AutoSaveStatusIndicator
            autosaveStatus={saveState}
            isFetching={isFetching}
            lastEditedBy={lastEditedBy}
            lastEditedOn={lastEditedOn}
            updatedAtInWords={updatedAtInWords}
          />
          {!isLocked && (
            <GoBackIconButton onClick={() => routes.curriculumEdit({ slug, id }).push()} />
          )}
        </RightDiv>
      </ToolbarWrapper>

      {showNotifyModal && (
        <ShareChangesModal
          closeRequest={() => {
            setShowNotifyModal(false);
          }}
          completionRequired={completionRequired}
          curriculumId={id}
          curriculumTitle={title}
          dataSource='step'
          stepId={stepId}
          subtitle={t('notify.subtitle')}
          title={t('notify.title')}
        />
      )}
    </>
  );
};

export default ToolbarContainer;
