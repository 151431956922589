import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import SourceBadge from '../../../../components/design_system/navigation/SourceBadge/SourceBadge';
import useActionCableChannel from '../../../../hooks/useActionCableChannel';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../lib/initTranslations';
import {
  setIsSemanticStepsFetching,
  setSemanticSteps,
} from '../../../../redux/domains/searchResults/searchResultsSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import {
  useCreateChatCompletionMutation,
  useKnnSearchQuery,
} from '../../../../redux/services/resourceApis/openAI/openAiAPI';
import {
  ChatMessage,
  CompletionResponse,
  OpenAiModel,
} from '../../../../redux/services/resourceApis/openAI/types';
import { SearchResultStep } from '../../../../redux/services/resourceApis/searches/types';
import AiFeedbackModal from '../../ai/AiFeedbackModal/AiFeedbackModal';
import { FeedbackStatus } from '../../ai/Feedback/Feedback';
import { NO_ANSWER_RESPONSE, QA_PROMPT_MESSAGES } from '../../ai/prompts/prompts';
import { routes } from '../../publicApplication/applicationRouter';
import { Loader } from './Completion/styles';
import ResultsHeader from './ResultsHeader/ResultsHeader';
import ResultsMainBody from './ResultsMainBody/ResultsMainBody';
import {
  AnswerWrapper,
  FoundIn,
  FoundInWrapper,
  MainResults,
  ModelTag,
  SemanticResults,
  SourcesWrapper,
  Wrapper,
} from './styles';
import { AiSearchResultProps } from './types';

const t = initTranslations('compose.search');

// TODO:
// 1. Add specs
// 2. Add error handling
const AiSearchResult = ({ modelId, searchTerm }: AiSearchResultProps) => {
  const { configs } = usePrivateConfigs();
  const dispatch = useAppDispatch();
  const openAiModel = configs['SEMANTIC_SEARCH_DEFAULT_OPEN_AI_MODEL'] as OpenAiModel;
  const openAiModelTemperature = parseFloat(configs['SEMANTIC_SEARCH_DEFAULT_MODEL_TEMPERATURE']);
  const { showExperimentalSearch } = useCurrentUser();
  const {
    slug,
    splitFeatures: { newSearchUiEnabled },
  } = useCurrentAccount();

  const componentId = useMemo(() => uuidv4(), []);
  const {
    isLoading,
    isFetching,
    data: steps,
  } = useKnnSearchQuery({
    searchTerm,
    modelId,
  });

  const ability = useCurrentUserAbilities();
  const publishedSteps = useMemo(() => {
    if (!steps) return [];

    return steps.filter((step) => {
      if (!step.unpublished) return true;

      return ability.can('update', `EditCurriculum-${step.curriculumId}`);
    });
  }, [steps, ability]);

  const publishedStepsPresent = !!publishedSteps.length;
  const publishedStepsContent = useMemo(() => {
    return publishedSteps.map((step) => step.plainTextContent).join(', ');
  }, [publishedSteps]);

  const [trigger, { data }] = useCreateChatCompletionMutation();
  const [completionResponse, setCompletionResponse] = useState<CompletionResponse | undefined>();
  const [showAiFeedbackModal, setShowAiFeedbackModal] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState<FeedbackStatus>('un-started');
  const showSemanticResults =
    !newSearchUiEnabled ||
    (!!completionResponse?.completion && completionResponse.completion !== NO_ANSWER_RESPONSE);

  const generateStepsMetadata = (steps: SearchResultStep[]) => {
    const limitedSteps = steps.slice(0, 3);
    return limitedSteps
      .map((step) => window.location.origin + routes.consume({ slug, id: step.id }).href)
      .join(', ');
  };

  const channelProps = useMemo(() => {
    return {
      channel: 'AiCompletionsChannel',
      compose_feature: 'kNNSearch',
      component_id: componentId,
    };
  }, [componentId]);

  useEffect(() => setCompletionResponse(data), [data]);
  useEffect(() => {
    dispatch(setIsSemanticStepsFetching(isFetching));
  }, [dispatch, isFetching]);
  const received = useCallback((completionResponse: CompletionResponse) => {
    setCompletionResponse(completionResponse);
  }, []);

  useActionCableChannel<CompletionResponse>(channelProps, received);

  const handleKnnSearchResponse = useCallback(() => {
    const promptMessage: ChatMessage = { role: 'user', content: searchTerm };
    const assistantMessage: ChatMessage = {
      role: 'assistant',
      content: 'Can you provide me with the relevant information?',
    };

    const verificationMessage =
      'Analyze the provided information and answer the question as an informed employee. ' +
      'Remember, answer based on the provided information only. Your response should be concise while still being complete. ' +
      'If the information is not found, respond strictly with "We couldn\'t find an answer for your search. You can rephrase the question and try again." ' +
      'If the answer involves steps or instructions, start your response with "To [task], you need to follow these steps:" and then list each step clearly as "Step 1:", "Step 2:", and so on. ' +
      'Wrap each step in HTML <p> tags for proper formatting.';

    const documentsMessageWithVerification: ChatMessage = {
      role: 'user',
      content: `${verificationMessage}\n\n'''${publishedStepsContent}'''`,
    };

    const messages = [
      ...QA_PROMPT_MESSAGES,
      promptMessage,
      assistantMessage,
      documentsMessageWithVerification,
    ];

    if (!!messages.length) {
      trigger({
        messages,
        feature_name: 'kNNSearch',
        max_tokens: 2000,
        temperature: openAiModelTemperature,
        component_id: componentId,
        model: openAiModel,
      });
    }
  }, [
    searchTerm,
    publishedStepsContent,
    trigger,
    openAiModelTemperature,
    componentId,
    openAiModel,
  ]);

  const resetSemanticSteps = useCallback(() => {
    dispatch(setSemanticSteps([]));
  }, [dispatch]);

  useEffect(() => {
    resetSemanticSteps();
    setFeedbackStatus('un-started');

    if (publishedStepsPresent && !isFetching) {
      dispatch(setSemanticSteps(publishedSteps));
      handleKnnSearchResponse();
    }
  }, [
    handleKnnSearchResponse,
    publishedStepsPresent,
    isFetching,
    dispatch,
    publishedSteps,
    resetSemanticSteps,
  ]);

  const handleOpenAiFeedbackModal = () => {
    setShowAiFeedbackModal(true);
    setFeedbackStatus('un-started');
  };

  if (isLoading || isFetching) return <Loader />;
  if (!steps) return <></>;

  return (
    <Wrapper>
      <AnswerWrapper>
        {newSearchUiEnabled && (
          <MainResults>
            <ResultsHeader searchTerm={searchTerm} />

            <ResultsMainBody
              completionResponse={completionResponse}
              feedbackStatus={feedbackStatus}
              handleOpenAiFeedbackModal={handleOpenAiFeedbackModal}
              publishedStepsPresent={publishedStepsPresent}
              steps={publishedSteps}
            />
          </MainResults>
        )}

        {!newSearchUiEnabled && (
          <ResultsMainBody
            completionResponse={completionResponse}
            feedbackStatus={feedbackStatus}
            handleOpenAiFeedbackModal={handleOpenAiFeedbackModal}
            publishedStepsPresent={publishedStepsPresent}
            steps={publishedSteps}
          />
        )}

        {showAiFeedbackModal && (
          <AiFeedbackModal
            aiCompletionId={completionResponse?.id}
            metadata={{ steps: generateStepsMetadata(publishedSteps) }}
            setFeedbackStatus={setFeedbackStatus}
            setShowAiFeedbackModal={setShowAiFeedbackModal}
          />
        )}

        {showSemanticResults && (
          <SemanticResults hasMinHeight={!newSearchUiEnabled}>
            <FoundInWrapper visible={!isLoading}>
              {steps.length === 0 ? (
                <>{!newSearchUiEnabled && <p>{t('no_results')}</p>}</>
              ) : (
                <>
                  <FoundIn isGrey={newSearchUiEnabled}>
                    {newSearchUiEnabled ? t('answer_found_in') : t('found_in')}
                  </FoundIn>
                  <SourcesWrapper>
                    {publishedSteps.map((step) => (
                      <SourceBadge
                        key={`step-source-${step.id}`}
                        sourceName='curriculum'
                        sourceRoute={routes.consume({ slug, id: step.id })}
                        sourceText={step.title}
                      />
                    ))}
                  </SourcesWrapper>
                </>
              )}
            </FoundInWrapper>
          </SemanticResults>
        )}
      </AnswerWrapper>
      {showExperimentalSearch && <ModelTag>{modelId}</ModelTag>}
    </Wrapper>
  );
};

export default AiSearchResult;
