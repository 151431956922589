import React, { ChangeEvent, FC, KeyboardEvent } from 'react';

import { RegisteredMenuId } from '../../../../lib/idRegistry';
import AssistiveText from '../../../design_system/core/AssistiveText';
import FrequencyDropdown from '../dropdownMenus/FrequencyDropdown/FrequencyDropdown';
import {
  DurationFrequencyBox,
  DurationTimeSetterValue,
  DurationTimeSetterWrapper,
  HoursInput,
  HoursInputDashOverlay,
  StyledIcon,
} from './styles';
import { DurationTimeSetterProps } from './types';

const DurationTimeSetter: FC<DurationTimeSetterProps> = ({
  hours,
  setHours,
  frequency,
  setFrequency,
  errorText,
  elementId,
  useFrequencyDropdownPortal = false,
}) => {
  const menuId: RegisteredMenuId = `duration-time-setter-frequency-popup-${elementId}`;
  const isHoursUnset = hours === 0;
  const visibleInputValue = isHoursUnset ? '' : hours.toString();

  const handleHoursChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHours(Math.abs(+e.target.value) || 0);
  };

  const handleSkipNonDigitInput = (e: KeyboardEvent) => {
    if (!e.key.match(/^\d$/)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <DurationTimeSetterWrapper>
        <DurationTimeSetterValue>
          <StyledIcon name='clock' size='2xs' weight='regular' />
          <HoursInput
            min={0}
            name='duration_hours'
            onChange={handleHoursChange}
            onKeyPress={handleSkipNonDigitInput}
            type='number'
            value={visibleInputValue}
          />
          {isHoursUnset && (
            <HoursInputDashOverlay className='input-dash-overlay'>-</HoursInputDashOverlay>
          )}
        </DurationTimeSetterValue>

        <DurationFrequencyBox>
          <FrequencyDropdown
            currentFrequency={frequency}
            menuId={menuId}
            setFrequency={setFrequency}
            usePortal={useFrequencyDropdownPortal}
          />
        </DurationFrequencyBox>
      </DurationTimeSetterWrapper>
      {errorText && <AssistiveText id='input-error-text' text={errorText} type='error' />}
    </>
  );
};

export default DurationTimeSetter;
