import styled from 'styled-components';

import { fontSm4 } from '../../../../../../styled/TypeSystem';

export const StyledTitle = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 ${constants.spacerMd1} 0`};

  ${fontSm4};
`;

export const TimeSetterClickableWrapper = styled.div`
  width: fit-content;
`;
