import { OrgChartData } from '../../../../../types/OrgChart';

const createNestedStructure = function toOrgChart<DataType extends OrgChartData>(
  flat: DataType[] | null | undefined
): DataType | undefined {
  if (!flat) {
    return;
  }

  const roots: DataType[] = []; // things without parent

  // make them accessible by guid on this map
  const all: { [key: string]: DataType } = {};

  flat.forEach((item) => {
    // Need to create a new object to avoid "Cannot add property children, object is not extensible"
    all[item.id.toString()] = { ...item };
  });

  // connect children to its parent, and split roots apart
  Object.keys(all).forEach((id) => {
    const item = all[id];
    if (!item.parent?.id) {
      roots.push(item);
    } else if (item.parent?.id in all) {
      const parent = all[item.parent?.id];
      if (parent) {
        if (!('children' in parent)) {
          parent.children = [];
        }
        parent.children?.push(item);
      }
    }
  });

  return roots[0];
};

export default createNestedStructure;
