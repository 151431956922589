import styled from 'styled-components';

export const CheckoutDiscountsSectionWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
`;

export const CheckoutPriceArrowWrapper = styled.div`
  position: relative;
  height: 5.125rem;
`;

export const CheckoutPriceArrow = styled.img<{ withDiscountSection: boolean }>`
  width: 100%;
  max-width: 5.25rem;
  position: absolute;
  top: ${({ withDiscountSection }) => (withDiscountSection ? '35px' : '-26px')};
  right: ${({ withDiscountSection }) => (withDiscountSection ? '-83px' : '0')};
  ${({ withDiscountSection }) => !withDiscountSection && { transform: 'rotate(325deg)' }};
`;
