import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useEditorCourseContext } from '../../../../../contexts/EditorCourseContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { ReduxResult } from '../../../../../types/Redux';
import Step from '../../../../../types/Step';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import InputFieldWithCharacterCounter from '../../../../design_system/Triage/InputFieldWithCharacterCounter';
import { routes } from '../../../publicApplication/applicationRouter';
import OutsideClickHandler from '../../../shared/OutsideClickHandler';
import { getValidationSchema } from '../../shared/validators';

const Wrapper = styled.div`
  margin: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
`;

const InputWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const t = initTranslations('editor.create_step');

interface FormValues {
  title: string;
}

export type Props = {
  onSubmit: (values: FormValues) => void;
  error: FetchBaseQueryError | SerializedError | undefined;
  data: Step | undefined;
  isLoading: boolean;
  result: ReduxResult;
  scrollRef: React.RefObject<HTMLDivElement | null>;
};

const NewStepButton = ({ onSubmit, data, isLoading, scrollRef }: Props) => {
  const [showForm, setShowForm] = useState(false);
  const {
    course: { eSignatureDisplayedAndRequired, canBeModified },
    curriculum: { locked },
    setShowResetModal,
    setShowCurriculumModal,
  } = useEditorCourseContext();

  const onClickHandler = useCallback(() => {
    if (locked) {
      setShowCurriculumModal(true);
    } else if (!canBeModified && eSignatureDisplayedAndRequired) {
      setShowResetModal(true);
    } else {
      setShowForm(true);
    }
  }, [
    canBeModified,
    eSignatureDisplayedAndRequired,
    locked,
    setShowResetModal,
    setShowCurriculumModal,
    setShowForm,
  ]);

  const { slug } = useCurrentAccount();
  const {
    step: { singular: stepTerm },
  } = useAccountTerminology();
  const lowerCaseStepTerm = stepTerm.toLowerCase();
  const { resetForm, errors, values, handleChange, handleSubmit, submitCount } = useFormik({
    initialValues: {
      title: '',
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(),
    onSubmit: (values: FormValues, { resetForm }) => submitForm({ ...values, resetForm }),
  });

  const submitForm = useCallback(
    ({ title }) => {
      onSubmit({ title });
    },
    [onSubmit]
  );

  const resetFormToInitialState = useCallback(() => {
    setShowForm(false);
    resetForm();
  }, [resetForm]);

  // "Redirect" to the editor page after creating a new step and clear the form for the step title
  useEffect(() => {
    if (data) {
      routes.editor({ id: data.id, slug }).push();
      resetFormToInitialState();
    }
  }, [data, resetFormToInitialState, slug]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={resetFormToInitialState}>
      <Wrapper>
        {showForm && (
          <InputWrapper className='new-step-wrapper'>
            <InputFieldWithCharacterCounter
              autoFocus
              disabled={isLoading}
              errorText={!!submitCount && errors.title}
              id='step-title'
              inputFor='title'
              maxCharacters={250}
              name='title'
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder={t('placeholder', { step: lowerCaseStepTerm })}
              value={values.title}
            />
          </InputWrapper>
        )}
        {showForm ? (
          <DefaultButton
            buttonType='secondary'
            disabled={isLoading}
            fullWidth
            id='new-step-button'
            loading={isLoading}
            onClick={() => handleSubmit()}
            text={t('create_new_step', { step: lowerCaseStepTerm })}
            type='submit'
          />
        ) : (
          <DefaultButton
            buttonType='secondary'
            fullWidth
            iconName='plus'
            id='open-new-step-button'
            onClick={() => {
              onClickHandler();
              setTimeout(() => {
                scrollRef.current?.scrollTo({
                  top: scrollRef.current.scrollHeight,
                  behavior: 'smooth',
                });
              });
            }}
            text={t('new_step', { step: lowerCaseStepTerm })}
          />
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};

export default NewStepButton;
