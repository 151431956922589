import { Editor } from '@tiptap/react';
import React, { useEffect, useState } from 'react';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useEmbedlyContext } from '../../../../../contexts/EmbedlyProvider';
import useLoom from '../../../../../hooks/useLoom';
import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import { ReactComponent as LoomLogo } from '../../../../../images/loom_logo.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import { htmlEncodedContent } from '../../lib/htmlEncodedContent';
import { insertEditorContent } from '../../lib/insertEditorContent';
import LabelToolbarButton from '../../toolbar/buttons/components/LabelToolbarButton';

const BUTTON_ID = 'loom-record-sdk-button';

interface Props {
  editor?: Editor;
}

const t = initTranslations('loom_record');

const LoomRecordButton = ({ editor: passedEditor }: Props) => {
  const { flash } = useFlashNotification();
  const { configs } = usePrivateConfigs();
  const apiKey = configs['EMBEDLY_KEY'];
  const [isCapturing, setIsCapturing] = useState(false);
  const setupLoomButton = useLoom();
  const {
    trigger,
    errorMessage,
    setResetForm,
    setErrorMessage,
    embedlyData,
    setEmbedlyData,
    setProviderType,
  } = useEmbedlyContext();
  const { editor: contextEditor } = useEditorContext();
  const editor = passedEditor || contextEditor;

  useEffect(() => {
    if (!setupLoomButton) return;

    setProviderType(['video']);
    const button = document.getElementById(BUTTON_ID);

    if (!button) return;

    const sdkButton = setupLoomButton({ element: button });

    sdkButton.on('insert-click', async (video) => {
      trigger({ apiKey, url: video.sharedUrl });
    });

    sdkButton.on('lifecycle-update', (lifecycle) => {
      if (lifecycle === 'closed') {
        setIsCapturing(false);
      }
    });
  }, [apiKey, setProviderType, setupLoomButton, trigger]);

  useEffect(() => {
    if (embedlyData && embedlyData.type === 'video') {
      const jsonString = JSON.stringify(embedlyData);

      insertEditorContent({
        editor,
        content: htmlEncodedContent({
          jsonString,
          html: embedlyData.html,
          originalUrl: embedlyData.url,
        }),
      });

      setEmbedlyData(null);
      setResetForm(true);
    }
  }, [editor, embedlyData, setEmbedlyData, setResetForm]);

  useEffect(() => {
    if (errorMessage) {
      flash('error', errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage, flash, setErrorMessage]);

  return (
    <LabelToolbarButton
      Svg={LoomLogo}
      active={isCapturing}
      ariaLabel={t('loom_aria_label')}
      disabled={!setupLoomButton}
      iconType='svg'
      id={BUTTON_ID}
      label={t('rec')}
      onClick={() => setIsCapturing(true)}
    />
  );
};

export default LoomRecordButton;
