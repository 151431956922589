import * as yup from 'yup';

import initTranslations from '../../initTranslations';
import { getEmailSchema } from '../email';
import { multiErrorPasswordSchema } from '../multiErrorPassword';
import { passwordSchema } from '../password';

const t = initTranslations('public_application.login.form.errors');

export const getSignUpSchema = (multiErrorPasswordEnabled = false) =>
  yup.object().shape({
    ...getEmailSchema(t('email_blank'), t('email_invalid')).fields,
    password: multiErrorPasswordEnabled ? multiErrorPasswordSchema : passwordSchema,
  });
