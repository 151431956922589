export type SmartTestModalDataOption = { selectedCourseIds: number[]; searchValue: string };
export type SmartTestModalAction =
  | { type: 'toggleSelectedCourseId'; selectedCourseId: number }
  | { type: 'setSearchValue'; searchValue: string };

export const initialModalState: SmartTestModalDataOption = {
  selectedCourseIds: [],
  searchValue: '',
};

export const SmartTestModalReducer = (
  state: SmartTestModalDataOption,
  action: SmartTestModalAction
): SmartTestModalDataOption => {
  switch (action.type) {
    case 'setSearchValue': {
      return { ...state, searchValue: action.searchValue };
    }
    case 'toggleSelectedCourseId': {
      const isSelectedId = state.selectedCourseIds.includes(action.selectedCourseId);

      return {
        ...state,
        selectedCourseIds: isSelectedId
          ? state.selectedCourseIds.filter((id) => id !== action.selectedCourseId)
          : [...state.selectedCourseIds, action.selectedCourseId],
      };
    }
  }
};
