import React from 'react';
import styled, { useTheme } from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import { fontSm5 } from '../../../../../../styled/TypeSystem';
import { SkeletonLoader } from '../../../../../shared/SkeletonLoader/styles';

const t = initTranslations('curriculums.ai_outliner.loading');

const LoaderWrapper = styled.div`
  border-top: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerMd2}`};
  flex-grow: 1;
  overflow-y: auto;
`;

const LoadingText = styled.p`
  margin-block: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
`;

const LoadingRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const SkeletonRowWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  padding-block: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const SkeletonRow = () => {
  const {
    constants: { borderRadiusMd, heightXs },
  } = useTheme();

  return (
    <SkeletonRowWrapper>
      <SkeletonLoader
        borderRadius={borderRadiusMd}
        height={heightXs}
        minWidth='1.5rem'
        width='1.5rem'
      />
      <SkeletonLoader borderRadius={borderRadiusMd} height={heightXs} />
    </SkeletonRowWrapper>
  );
};

const LOADING_ROWS_COUNT = 5;

const CourseSuggestionsLoader = () => {
  const {
    topic: { plural: topicPlural },
  } = useAccountTerminology();

  return (
    <LoaderWrapper>
      <LoadingText>{t('courses', { topics: topicPlural.toLowerCase() })}</LoadingText>
      <LoadingRows>
        {[...Array(LOADING_ROWS_COUNT)].map((_, index) => (
          <SkeletonRow key={`course-loader-row-${index}`} />
        ))}
      </LoadingRows>
    </LoaderWrapper>
  );
};

export default CourseSuggestionsLoader;
