import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Icon from '../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';

const StyledCheckmark = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledCheckmarkIcon = styled.span<{ completed?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  ${({ completed, theme: { vars } }) =>
    completed
      ? `
          background-color: ${vars.accentPrimaryDefault};
          color: ${vars.foundationSurface1};
        `
      : `
          background-color: ${vars.foundationBase2};
          color: ${vars.textDisabled};
        `}
`;

const LockIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm1};
`;

export interface Props {
  className: string;
  completed: boolean;
  forceCompletionOrder: boolean;
}

const t = initTranslations('outline_content');

const MarkCompleteCheckmark = ({ className, completed, forceCompletionOrder }: Props) => {
  const {
    step: { plural: stepPlural },
  } = useAccountTerminology();

  return (
    <StyledCheckmark className={className}>
      {!forceCompletionOrder && (
        <StyledCheckmarkIcon completed={completed}>
          <Icon name='check' size='2xs' weight='solid' />
        </StyledCheckmarkIcon>
      )}
      {forceCompletionOrder && (
        <>
          <Tooltip
            id='locked-step-tooltip'
            text={t('forced_order_tooltip', { step: stepPlural })}
          />
          <LockIconWrapper data-for='locked-step-tooltip' data-tip>
            <Icon name='lock' size='xs' />
          </LockIconWrapper>
        </>
      )}
    </StyledCheckmark>
  );
};

export default MarkCompleteCheckmark;
