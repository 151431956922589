import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { BreadcrumbWrapper, EditorBreadcrumbs } from '../../../editor/shared/styles';
import { HeadingWrapper, OutlineContentWrapper, SurveyTitle } from '../../shared/styles';
import SurveyCurriculumTitleLink from '../../shared/SurveyCurriculumTitleLink/SurveyCurriculumTitleLink';
import SurveyEmoji from '../../shared/SurveyEmoji/SurveyEmoji';
import AnsweredQuestionsList from './AnsweredQuestionsList/AnsweredQuestionsList';
import { YouScoredBadge } from './styles';
import { OutlineContentProps } from './types';

const t = initTranslations('survey_results');

const OutlineContent = ({
  answeredQuestions,
  curriculumId,
  curriculumTitle,
  passed,
  score,
  surveyEmoji,
  surveyName,
}: OutlineContentProps) => {
  return (
    <OutlineContentWrapper>
      <HeadingWrapper>
        <EditorBreadcrumbs id='survey-results-breadcrumb'>
          <SurveyCurriculumTitleLink
            curriculumId={curriculumId}
            curriculumTitle={curriculumTitle}
            redirectToEditPage={false}
          />
        </EditorBreadcrumbs>
      </HeadingWrapper>
      <BreadcrumbWrapper>
        <SurveyEmoji emoji={surveyEmoji} />
        <SurveyTitle>{surveyName}</SurveyTitle>
      </BreadcrumbWrapper>
      <YouScoredBadge passed={passed}>{t('you_scored', { score })}</YouScoredBadge>
      <AnsweredQuestionsList questions={answeredQuestions} />
    </OutlineContentWrapper>
  );
};

export default OutlineContent;
