import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import { useAdminUpdateCurriculumMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import InputFieldWithCharacterCounter from '../../../../design_system/Triage/InputFieldWithCharacterCounter';
import { MultilineTruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { routes } from '../../../publicApplication/applicationRouter';
import RouterLink from '../../../publicApplication/RouterLink';

const Wrapper = styled.div<{ isEditing: boolean }>`
  ${({ isEditing }) => isEditing && `width: 100%;`}
`;

const Title = styled(RouterLink)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  text-decoration: none;

  :hover {
    color: ${({ theme: { vars } }) => vars.textDefault};
    text-decoration: underline;
  }

  ${MultilineTruncatedText({})};
  ${fontSm5};
`;

type Props = {
  id: number;
  title: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  maxCharacters?: number;
  route: Route<typeof routes>;
};

const EditableCurriculumTitle = ({
  id,
  isEditing,
  setIsEditing,
  title,
  maxCharacters,
  route,
}: Props) => {
  const [value, setValue] = useState(title);
  const [currentValue, setCurrentValue] = useState(title);
  const { curriculum } = useAccountTerminology();
  const [update, result] = useAdminUpdateCurriculumMutation();
  const { isSuccess } = result;
  const charactersUsed = value.length;

  const setTitle = useCallback(
    (newTitle: string) => {
      setIsEditing(false);

      if (value === currentValue) return;

      const isEmpty = !value || value.trim() === '';
      const overMax = maxCharacters && charactersUsed > maxCharacters;
      if (isEmpty || overMax) {
        setValue(currentValue);
        return;
      }

      update({ title: newTitle, id });
    },
    [charactersUsed, currentValue, id, maxCharacters, setIsEditing, update, value]
  );

  useEffect(() => {
    setValue(title);
    setCurrentValue(title);
  }, [title]);

  useEffect(() => {
    if (isSuccess) {
      setValue(currentValue);
    }
  }, [isSuccess, currentValue]);

  return (
    <Wrapper className='editable-title' isEditing={isEditing}>
      {isEditing ? (
        <InputFieldWithCharacterCounter
          autoFocus
          inputFor={curriculum.singular}
          maxCharacters={maxCharacters}
          name='rename-curriculum'
          onBlur={() => setTitle(value)}
          onChange={(event) => setValue(event.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && setTitle(e.currentTarget.value)}
          value={value}
        />
      ) : (
        <Title to={route}>{title}</Title>
      )}
    </Wrapper>
  );
};

export default EditableCurriculumTitle;
