import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { useSearch } from '../../../../contexts/SearchContext';
import { useUsers } from '../../../../contexts/UsersContext';
import NoResultsGraphicDark from '../../../../images/no_users_empty_state_dark.svg';
import NoResultsGraphic from '../../../../images/no_users_empty_state.svg';
import initTranslations from '../../../../lib/initTranslations';
import { groupsApi } from '../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupKind } from '../../../../types/Group';
import Loader from '../../../design_system/Triage/Loader';
import NoResults from '../../../design_system/Triage/NoResults';
import { mediaBreakpointPxLg, mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import LoadingUserCard from '../LoadingUserCard';
import UserCard from '../UserCard';
import ViewByGroup from '../ViewByGroup/ViewByGroup';
import { VIEW_BY_GROUP_OPTIONS } from '../ViewByGroup/viewByGroupOptions';

export const CardRow = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  margin-left: ${({ theme: { constants } }) => `-${constants.spacerSm3}`};
  margin-right: ${({ theme: { constants } }) => `-${constants.spacerSm3}`};
  @media (min-width: ${mediaBreakpointPxSm}) {
    grid-template-columns: repeat(3, calc(100% / 3));
  }
  @media (min-width: ${mediaBreakpointPxLg}) {
    grid-template-columns: repeat(auto-fill, 15rem);
    grid-gap: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerSm3}`};
    justify-content: flex-start;
  }
`;

const LoaderWrapper = styled.div`
  margin-top: ${({ theme }) => theme.constants.spacerLg1};
  height: ${({ theme }) => theme.constants.heightSm};
`;

const t = initTranslations('people');

const UserCards = () => {
  const {
    people,
    dataIsLoading: peopleDataLoading,
    errorMessage,
    paginationLoading,
    setRequestNextPage,
  } = useUsers();
  const { has_more: hasMore } = people;
  const {
    setSearchValue,
    searchValue,
    selectedGroupByOption,
    selectedSortOption,
    setSelectedGroupByOption,
  } = useSearch();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [getGroups, groupsResult] = groupsApi.useLazyGetDirectoryGroupsQuery();

  const { data: groupsData, isLoading: groupsDataLoading } = groupsResult;

  //pagination
  useEffect(() => {
    if (inView && hasMore && selectedGroupByOption?.groupBy === 'all_employees') {
      setRequestNextPage(true);
    }
  }, [hasMore, inView, selectedGroupByOption?.groupBy, setRequestNextPage]);

  //groups api call depending on group selection state
  useEffect(() => {
    if (selectedGroupByOption?.groupBy === 'all_employees') {
      return;
    }
    if (selectedGroupByOption?.groupBy === 'all_groups') {
      getGroups({
        sort_col_dir: selectedSortOption.sortDirection,
        search_query: searchValue,
      });
    } else {
      const kind = selectedGroupByOption?.groupBy as GroupKind;
      getGroups({
        group_kind: kind,
        sort_col_dir: selectedSortOption.sortDirection,
        search_query: searchValue,
      });
    }
  }, [getGroups, searchValue, selectedGroupByOption, selectedSortOption.sortDirection]);

  //loading state render
  if (peopleDataLoading || groupsDataLoading) {
    return (
      <CardRow id='loading-user-cards'>
        {[...Array(15)].map((_, index) => (
          <LoadingUserCard key={`empty_card_${index}`} />
        ))}
      </CardRow>
    );
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  const { users } = people;

  //empty state render

  const groupsNoResults =
    !groupsResult.isLoading &&
    selectedGroupByOption?.groupBy !== 'all_employees' &&
    groupsData?.length === 0;

  if (
    (!users.length && !paginationLoading && !peopleDataLoading && !groupsData) ||
    groupsNoResults
  ) {
    return (
      <NoResults
        darkImage={NoResultsGraphicDark}
        heading={t('no_results_heading')}
        iconWidth='50%'
        lightImage={NoResultsGraphic}
        minHeight='unset'
        subHeaderCta={{
          action: () => {
            setSearchValue('');
            setSelectedGroupByOption(VIEW_BY_GROUP_OPTIONS[0]);
          },
          text: t('no_results_advice'),
        }}
        subHeaderText={t('no_results_advice_2')}
      />
    );
  }

  if (selectedGroupByOption?.groupBy === 'all_employees') {
    return (
      <>
        <CardRow>
          {users.map((user, i) => (
            <UserCard key={`${user.id}-${i}`} user={user} />
          ))}
        </CardRow>
        <LoaderWrapper ref={ref}>{paginationLoading && <Loader />}</LoaderWrapper>
      </>
    );
  }

  return (
    <>
      {groupsData &&
        groupsData.map((group) => (
          <ViewByGroup
            group={group}
            key={`group-${group.id}`}
            sourceSubtext={
              selectedGroupByOption?.groupBy === 'all_groups'
                ? `(${initTranslations('groups.kinds')(group.kind)})`
                : undefined
            }
          />
        ))}
    </>
  );
};

export default UserCards;
