import { ButtonGroup } from '../index';

const fontStylesBubbleToolbarButtons: ButtonGroup[] = [
  {
    name: 'FontStyles',
    buttons: ['bold', 'italic', 'underline', 'insertLink'],
  },
];

const linkOptionsBubbleToolbarButtons: ButtonGroup[] = [
  {
    name: 'LinkOptions',
    buttons: ['openLink', 'editLink', 'unlink'],
  },
];

const tableBubbleToolbarButtons: ButtonGroup[] = [
  {
    name: 'Table',
    buttons: [
      'addRowBefore',
      'addRowAfter',
      'addColumnBefore',
      'addColumnAfter',
      'cellBackgroundColor',
      'deleteRow',
      'deleteColumn',
    ],
  },
];

export const bubbleToolbarButtons: ButtonGroup[] = [
  ...tableBubbleToolbarButtons,
  ...fontStylesBubbleToolbarButtons,
  ...linkOptionsBubbleToolbarButtons,
];

export const stepEditorBubbleToolbarButtons: ButtonGroup[] = [
  {
    name: 'inlineAI',
    buttons: ['inlineAI'],
  },
  ...bubbleToolbarButtons,
];
