import { ChangeEvent, useCallback } from 'react';

import { RESPONSIBILITY_NAME_MAX_CHARACTERS } from '../../components/application/DelegationPlanner/shared/constants/boards';
import { sanitizeInput } from '../../lib/sanitize';

export const useResponsibilityHeaderTitleHandles = (
  initialValues: { name: string },
  setFieldValue: (field: string, value: string) => void,
  value: string
) => {
  const handleTitleValidation = useCallback(() => {
    const inputLength = value.length;
    const isEmpty = inputLength === 0;
    const overMaxChars = inputLength > RESPONSIBILITY_NAME_MAX_CHARACTERS;

    if (isEmpty || overMaxChars) {
      setFieldValue('name', initialValues.name);
    }
  }, [initialValues.name, setFieldValue, value]);

  const handleBeforeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if ((e.target as HTMLElement).innerText.length >= RESPONSIBILITY_NAME_MAX_CHARACTERS)
      e.preventDefault();
  }, []);

  const handleChange = useCallback(
    (value: string) => {
      setFieldValue('name', sanitizeInput(value, false));
    },
    [setFieldValue]
  );

  return {
    handleTitleValidation,
    handleBeforeInput,
    handleChange,
  };
};
