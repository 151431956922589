import React from 'react';

import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { NavigationButtonProps } from './BaseNavigationButtons';

const t = initTranslations('curriculums_view.flowchart.footer.navigation_buttons');

const NextButton = ({ id, elementKind, mode }: NavigationButtonProps) => {
  const { editRoute, showRoute } = useCurriculumElement()({ elementId: id, elementKind });

  const route = mode === 'edit' ? editRoute : showRoute;

  return (
    <DefaultButton
      behavesAs='a'
      fullWidth
      iconName='arrow-right'
      iconPosition='right'
      id='next-content-button'
      text={t('next')}
      {...route.link}
    />
  );
};

export default NextButton;
