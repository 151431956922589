import { FieldArray, useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Link from '../../../design_system/Link';
import { SPECIALIZATION_LABEL_COLORS } from '../../DelegationPlanner/SpecializationColorPicker/constants';
import { MAX_SPECIALIZATIONS_COUNT } from '../ResponsibilitySpecializations/constants';
import SpecializationFormElement from '../ResponsibilitySpecializations/SpecializationFormElement';
import {
  AddNewSpecializationActionWrapper,
  SpecializationTagsWrapper,
  TextPlaceholder,
} from '../ResponsibilitySpecializations/styles';
import { SpecializationFormValue } from '../ResponsibilitySpecializations/types';
import {
  FormSectionDescription,
  FormSectionTitle,
  SpecializationTagTerminologyWrapper,
} from './styles';
import { SettingsData } from './types';

const t = initTranslations('account_settings.delegation_settings');

const DelegationTabSection = () => {
  const { delegationPlannerEnabled } = useCurrentAccount();
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();

  const { values } = useFormikContext<SettingsData>();

  const isTagsLimitReached = useCallback((specializations: SpecializationFormValue[]) => {
    return specializations.filter((tag) => !tag._destroy).length === MAX_SPECIALIZATIONS_COUNT;
  }, []);

  if (!delegationPlannerEnabled) return <></>;

  return (
    <SpecializationTagTerminologyWrapper id='delegation-tab-section'>
      <FormSectionTitle>
        {t('field_label', { responsibility: responsibilitySingular })}
      </FormSectionTitle>
      <FormSectionDescription
        dangerouslySetInnerHTML={{
          __html: t('field_description'),
        }}
      />
      <FieldArray name='specializations'>
        {({ remove, push }) => (
          <>
            <SpecializationTagsWrapper>
              {values.specializations.map((specialization, index) =>
                specialization._destroy ? null : (
                  <SpecializationFormElement
                    arrayIndex={index}
                    formValue={specialization}
                    key={index}
                    removeFormElement={remove}
                  />
                )
              )}
            </SpecializationTagsWrapper>
            <AddNewSpecializationActionWrapper>
              <Link
                behavesAs='button'
                className='add-specialization-button'
                disabled={isTagsLimitReached(values.specializations)}
                onClick={() =>
                  push({
                    terminology: '',
                    color: SPECIALIZATION_LABEL_COLORS[0],
                  })
                }
                prefixIconName='plus'
                text={t('add_specialization')}
                underlineBehavior='dynamic'
              />
              <TextPlaceholder>
                {t('add_specialization_limit', { max: MAX_SPECIALIZATIONS_COUNT })}
              </TextPlaceholder>
            </AddNewSpecializationActionWrapper>
          </>
        )}
      </FieldArray>
    </SpecializationTagTerminologyWrapper>
  );
};

export default DelegationTabSection;
