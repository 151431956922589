import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  UniqueIdentifier,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { ReactNode, useState } from 'react';

import { DragOverlayItem } from './styles';

type Props = {
  children: ReactNode;
  handleDragEnd: (args: DragEndEvent) => void;
  itemHeight: string;
  items: (
    | UniqueIdentifier
    | {
        id: UniqueIdentifier;
      }
  )[];
};

const DragAndDropListWrapper = ({ children, handleDragEnd, itemHeight, items }: Props) => {
  const [draggedId, setDraggedId] = useState<string | number | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  return (
    <DndContext
      collisionDetection={closestCorners}
      modifiers={[restrictToVerticalAxis]}
      onDragCancel={() => setDraggedId(null)}
      onDragEnd={handleDragEnd}
      onDragStart={({ active }) => setDraggedId(active.id)}
      sensors={sensors}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
        <DragOverlay>{draggedId !== null && <DragOverlayItem height={itemHeight} />}</DragOverlay>
      </SortableContext>
    </DndContext>
  );
};

export default DragAndDropListWrapper;
