import styled, { keyframes } from 'styled-components';

import LoaderBase from '../../../../design_system/Triage/Loader';

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const Loader = styled(LoaderBase)`
  animation: 0.5s ${fadeIn} ease-out;
  min-height: 1.8rem;
`;

export const LoaderWrapper = styled.div`
  min-height: 1.8rem;
`;

export const CompletionWrapper = styled.div`
  min-height: 1.8rem;
  animation: 0.5s ${fadeIn} ease-out;

  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;
