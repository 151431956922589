import React from 'react';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { RestrictByIpSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const RestrictByIpSection = ({ id, restrictByIp }: RestrictByIpSectionProps) => {
  const [updateAccountData] = useUpdateAccountDataMutation();
  const { productTerm } = useAccountTerminology();

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('authorize_by_ip_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={restrictByIp}
            className='restrict-by-ip-setting'
            handleToggle={(e) => updateAccountData({ restrict_by_ip: e.target.checked })}
            id='restrict-by-ip'
            name='restrict_by_ip'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>
          {t('authorize_by_ip_description', { productTerm })}
        </FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default RestrictByIpSection;
