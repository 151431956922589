import styled, { css, keyframes } from 'styled-components';

import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { fontSm5 } from '../../../styled/TypeSystem';

export const SpecializationTagTerminologyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const SpecializationTagsFormWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
`;

export const SpecializationTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const AddNewSpecializationActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 9.5rem;
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
`;

export const TextPlaceholder = styled.span`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  text-wrap: nowrap;
  ${fontSm5};
`;

const appearAnimation = keyframes`
  from {
    height: 0;
  }
  to {
    height: 4.5rem;
  }
`;

export const FormElementWrapper = styled.div<{ animate: boolean }>`
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  ${({ animate }) =>
    animate &&
    css`
      animation: ${appearAnimation} 0.3s ease-in-out;
    `};

  > .input-group {
    width: 100%;
    height: 2.25rem;
    vertical-align: center;
    margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  }

  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 48%;
  }
`;
