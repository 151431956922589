import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import useValidateStripeElements from '../../../../../hooks/billing/useValidateStripeElements';
import initTranslations from '../../../../../lib/initTranslations';
import FieldLabel from '../../../../design_system/core/FieldLabel';
import { ErrorText } from '../../../../design_system/Triage/InputField';
import { RowWithTwoInputField } from '../styles';
import { ReducerAction } from '../types';
import { StripeElementWrapper } from './styles';

const t = initTranslations('update_payment_method_modal.card');

type StripeElementsProps = {
  dispatch: React.Dispatch<React.SetStateAction<ReducerAction>>;
  isFormProcessing: boolean;
};

const StripeElements = ({ dispatch, isFormProcessing }: StripeElementsProps) => {
  const { stripeElementsError, validateStripeElement } = useValidateStripeElements(dispatch);
  const theme = useContext(ThemeContext);

  // We can't use rem for element styles because of stripe iframe
  // has a different font-size for root element than our DS
  const BASE_STRIPE_ELEMENT_OPTIONS: StripeCardElementOptions = {
    disabled: isFormProcessing,
    style: {
      base: {
        fontFamily: '"Poppins", sans-serif',
        fontSize: '12.64px',
        fontWeight: theme.constants.fontLight,
        color: theme.vars.textDefault,
        '::placeholder': {
          color: theme.vars.textPlaceholder,
        },

        ':disabled': {
          color: theme.vars.textDisabled,
        },
      },
    },
  };

  return (
    <>
      <StripeElementWrapper withBottomMargin>
        <FieldLabel required text={t('card_number')} />
        <CardNumberElement
          className='stripe-card-number-element'
          onChange={validateStripeElement}
          options={{
            ...BASE_STRIPE_ELEMENT_OPTIONS,
            showIcon: true,
            iconStyle: 'default',
            placeholder: t('enter_card_number'),
          }}
        />
        {stripeElementsError?.cardNumber && <ErrorText>{stripeElementsError.cardNumber}</ErrorText>}
      </StripeElementWrapper>
      <RowWithTwoInputField>
        <StripeElementWrapper isRowWithTwoFields>
          <FieldLabel required text={t('expiration_date')} />
          <CardExpiryElement
            className='stripe-card-expiry-element'
            onChange={validateStripeElement}
            options={{ ...BASE_STRIPE_ELEMENT_OPTIONS, placeholder: t('date_placeholder') }}
          />
          {stripeElementsError?.cardExpiry && (
            <ErrorText>{stripeElementsError.cardExpiry}</ErrorText>
          )}
        </StripeElementWrapper>
        <StripeElementWrapper isRowWithTwoFields>
          <FieldLabel required text={t('cvc')} />
          <CardCvcElement
            className='stripe-card-cvc-element'
            onChange={validateStripeElement}
            options={{ ...BASE_STRIPE_ELEMENT_OPTIONS, placeholder: t('cvc_placeholder') }}
          />
          {stripeElementsError?.cardCvc && <ErrorText>{stripeElementsError.cardCvc}</ErrorText>}
        </StripeElementWrapper>
      </RowWithTwoInputField>
    </>
  );
};

export default StripeElements;
