import React from 'react';
import { Range } from 'react-range';

import {
  RangeBar,
  RangeContainer,
  RangeMark,
  RangeMarkText,
  RangeMarkTextContainer,
  RangeProgressBar,
  RangeThumb,
  RangeWrapper,
} from './styles';
import { Props } from './types';

const CompanySizeRange = ({ companySizes, defaultValue, setCompanySize }: Props) => {
  const value = companySizes.indexOf(defaultValue);
  const changeValue = (value: number) => {
    setCompanySize(companySizes[value]);
  };

  return (
    <RangeWrapper>
      <Range
        max={companySizes.length - 1}
        min={0}
        onChange={(values) => changeValue(values[0])}
        renderMark={({ props: markProps, index }) => (
          <RangeMark
            {...markProps}
            index={index}
            onClick={() => changeValue(index)}
            style={{
              ...markProps.style,
            }}
            value={value}
          />
        )}
        renderThumb={({ props: thumbProps }) => (
          <RangeThumb
            {...thumbProps}
            style={{
              ...thumbProps.style,
            }}
          />
        )}
        renderTrack={({ props: trackProps, children }) => (
          <RangeContainer
            style={{
              ...trackProps.style,
            }}
          >
            <RangeBar ref={trackProps.ref}>{children}</RangeBar>
            <RangeProgressBar
              rangeValue={[value]}
              valuesCount={companySizes.length}
            ></RangeProgressBar>
          </RangeContainer>
        )}
        step={1}
        values={[value]}
      />
      <RangeMarkTextContainer>
        {companySizes.map((text, index) => (
          <RangeMarkText
            count={companySizes.length}
            isActive={value === index}
            key={index}
            onClick={() => changeValue(index)}
          >
            {text}
          </RangeMarkText>
        ))}
      </RangeMarkTextContainer>
    </RangeWrapper>
  );
};

export default CompanySizeRange;
