import React from 'react';

import VideoPreview from '../../../../../shared/VideoEmptyState/VideoPreview/VideoPreview';
import { FeatureVideoContainer, FeatureVideoWrapper } from './styles';

type FeatureVideoProps = {
  overviewVideoLinks: string;
  videoPreviewSrc: string;
  openPlayerCallback: () => void;
  closePlayerCallback: () => void;
};

const FeatureVideo = ({
  overviewVideoLinks,
  videoPreviewSrc,
  openPlayerCallback,
  closePlayerCallback,
}: FeatureVideoProps) => {
  return (
    <FeatureVideoWrapper className='feature-video'>
      <FeatureVideoContainer>
        <VideoPreview
          closePlayerCallback={closePlayerCallback}
          isResponsive
          openPlayerCallback={openPlayerCallback}
          size='xs'
          videoPreviewSrc={videoPreviewSrc}
          videoUrl={overviewVideoLinks}
        />
      </FeatureVideoContainer>
    </FeatureVideoWrapper>
  );
};

export default FeatureVideo;
