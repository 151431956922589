import { Route } from 'type-route';

import { routes, useRoute } from '../../../publicApplication/applicationRouter';

export const useCurrentBoardId = () => {
  const route = useRoute();
  const {
    params: { id: currentBoardId },
  } = route as Route<typeof routes.delegationPlannerBoard>;

  return currentBoardId;
};
