import React, { useContext, useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import EmptyStateDark from '../../../../../images/reports-empty-state-dark.svg';
import EmptyState from '../../../../../images/reports-empty-state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetCourseReportQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import LoadingTable from '../../../people/UsersOutline/Table/LoadingTable';
import {
  ActionButtons,
  ActionsHeaderContainer,
  RightAlignedHeaderActions,
} from '../../AllUsersReportPage/AllUsersReportTable/ActionsHeader/styles';
import { TableStyled, TableWrapper } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { sortIcon } from '../../SortIcon';
import CourseDownloadCsvReport from '../CourseDownloadCsvReport';
import CourseReportTableHeader from './CourseReportTableHeader';
import TableRow from './TableRow';
import { Action, TableHeaderState } from './types';

const CourseReportTable = ({ courseId }: { courseId: number }) => {
  const t = initTranslations('reports');
  const { topic } = useContext(AccountTerminologyContext);
  const { slug } = useCurrentAccount();
  const cookiePath = `/${slug}/curriculum_report`;
  const [cookies, setCookie] = useCookies(['course_report_selected_sort']);
  const isCookies = cookies.course_report_selected_sort;
  const sortColumn = isCookies && cookies.course_report_selected_sort['column'];
  const sortDirection = isCookies && cookies.course_report_selected_sort['dir'];

  const initialHeaderState: TableHeaderState = {
    sortCol: sortColumn ?? 'position',
    sortColDir: sortDirection ?? 'asc',
    sortIcon: sortIcon(sortDirection),
    page: 1,
  };

  const tableHeaderReducer = (state: TableHeaderState, action: Action): TableHeaderState => {
    switch (action.type) {
      case 'changePage': {
        return { ...state, page: action.page };
      }
      case 'sortColumnChange': {
        const sortDirection =
          state.sortCol === action.sortColumn && state.sortColDir === 'asc' ? 'desc' : 'asc';
        return {
          ...state,
          sortCol: action.sortColumn,
          sortColDir: sortDirection,
          sortIcon: sortIcon(sortDirection),
        };
      }
      case 'restoreCourseReportDefault': {
        if (!action.sortColumn || !action.sortDirection) return state;
        return {
          sortCol: action.sortColumn,
          sortColDir: action.sortDirection,
          sortIcon: sortIcon(action.sortDirection),
          page: 1,
        };
      }
    }
  };

  const [tableHeaderState, dispatch] = useReducer(tableHeaderReducer, initialHeaderState);

  useEffect(() => {
    setCookie(
      'course_report_selected_sort',
      JSON.stringify({ column: tableHeaderState.sortCol, dir: tableHeaderState.sortColDir }),
      { path: cookiePath }
    );
  }, [cookiePath, setCookie, tableHeaderState.sortColDir, tableHeaderState.sortCol]);

  const { data, isFetching, isError } = useGetCourseReportQuery({
    sortCol: tableHeaderState.sortCol,
    sortColDir: tableHeaderState.sortColDir,
    page: tableHeaderState.page,
    id: courseId,
  });

  if (isError) return <div>{t('error')}</div>;
  if (!data) return <LoadingTable />;

  const {
    steps: steps,
    limit_value: limitValue,
    total_pages: totalPages,
    total_steps_count: totalStepsCount,
  } = data;

  return (
    <>
      <ActionsHeaderContainer>
        <RightAlignedHeaderActions>
          <ActionButtons>
            <CourseDownloadCsvReport courseId={courseId} />
          </ActionButtons>
        </RightAlignedHeaderActions>
      </ActionsHeaderContainer>
      {isFetching ? (
        <LoadingTable />
      ) : (
        <>
          {steps.length ? (
            <>
              <TableWrapper>
                <TableStyled className='step-report-table'>
                  <CourseReportTableHeader
                    dispatch={dispatch}
                    tableHeaderState={tableHeaderState}
                  />
                  <tbody>
                    {steps.map((step) => {
                      return <TableRow courses={step} key={step.id} />;
                    })}
                  </tbody>
                </TableStyled>
              </TableWrapper>
              <Pagination
                activePage={tableHeaderState.page}
                itemsCountPerPage={limitValue}
                onChange={(page) => {
                  dispatch({ type: 'changePage', page });
                }}
                showPaginationDetails
                totalItemsCount={totalStepsCount}
                totalPages={totalPages}
              />
            </>
          ) : (
            <NoResults
              darkImage={EmptyStateDark}
              heading={t('empty_state', { item: topic.plural })}
              iconWidth='45%'
              lightImage={EmptyState}
            />
          )}
        </>
      )}
    </>
  );
};

export default CourseReportTable;
