import { useCallback, useEffect } from 'react';

import { deleteQueryParam } from '../../../../../../lib/getQueryParams';
import initTranslations from '../../../../../../lib/initTranslations';
import { LoginResponse } from '../../../../../../redux/services/resourceApis/publicApplication/types';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { useRoute } from '../../../applicationRouter';

const t = initTranslations('public_application.login');

export type ShowNotificationParamValue =
  | 'billing_admin_canceled_html'
  | 'canceled'
  | 'invalid_invitation_token'
  | 'invalid_reset_password_token'
  | 'hold'
  | 'no_reset_password_token'
  | 'password_changed'
  | 'password_successfully_set'
  | 'sso_archived'
  | 'sso_inactive'
  | 'sso_not_found_in_database'
  | 'sso_invalid_credentials'
  | 'trial_ended'
  | 'unauthenticated';

type Params = {
  isSuccessAccountData: boolean;
  accountData: LoginResponse | undefined;
};

const useNotificationAfterRedirect = ({ isSuccessAccountData, accountData }: Params) => {
  const { params } = useRoute();
  const { flash } = useFlashNotification();

  const showNotificationParam = 'show_notification' in params && params.show_notification;

  const showFlashNotification = useCallback(() => {
    deleteQueryParam('show_notification', true);

    switch (showNotificationParam) {
      case 'billing_admin_canceled_html':
      case 'canceled':
      case 'hold':
      case 'password_changed':
      case 'password_successfully_set':
      case 'restricted_ip':
      case 'trial_ended':
        return flash('info', t(`${showNotificationParam}`));
      case 'expired_session':
      case 'unauthenticated':
      case 'no_reset_password_token':
        return flash('warn', t(`${showNotificationParam}`));
      case 'invalid_invitation_token':
      case 'invalid_reset_password_token':
        return flash('error', t(`${showNotificationParam}`));
      case 'sso_archived':
      case 'sso_inactive':
      case 'sso_invalid_credentials':
      case 'sso_not_found_in_database':
        return flash('error', t(`omniauth.${showNotificationParam.replace('sso_', '')}`));
    }
  }, [flash, showNotificationParam]);

  useEffect(() => {
    if (isSuccessAccountData && accountData && showNotificationParam) {
      showFlashNotification();
    }
  }, [accountData, isSuccessAccountData, showFlashNotification, showNotificationParam]);
};

export default useNotificationAfterRedirect;
