import styled from 'styled-components';

import { fontMd3, fontSm4 } from '../../../../../styled/TypeSystem';

export const SurveyStatisticBlockWrapper = styled.div<{ textAlign?: 'left' | 'right' | 'center' }>`
  display: flex;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd3}`};
  flex-direction: column;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  flex: 1 0 0;
  align-self: stretch;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  border-right: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

export const StatisticHeader = styled.div`
  width: 100%;
  ${fontSm4};
`;

export const StatisticBody = styled.div`
  width: 100%;
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd3};
`;

export const StatisticFooter = styled.div`
  width: 100%;
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  ${fontSm4};
`;
