import { WebSocketStatus } from '@hocuspocus/provider';
import React from 'react';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import useContentStyles from '../../../../../hooks/useContentStyles';
import { LimitedStep } from '../../../../../types/Step';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import CollaborationUsers from '../../components/CollaborationUsers/CollaborationUsers';
import StepHeading from '../../components/StepHeading/StepHeading';
import StepTitle from '../../components/StepTitle/StepTitle';
import { useStepRange } from '../../hooks/useStepRange';
import {
  ContentBlock,
  StepContentBody,
  StyledEditorContent,
  StyledHrEdit,
} from '../../shared/styles';
import NavigationButtons from '../components/NavigationButtons/NavigationButtons';
import { EditorProps } from './Editor';

type Props = {
  courseSteps: LimitedStep[];
} & EditorProps;

const StepContent = ({
  collabState,
  collabUsers,
  courseSteps,
  step: { courseId, title, id, emoji },
}: Props) => {
  const { editor } = useEditorContext();
  const contentStyles = useContentStyles();
  const { stepPosition, isLastStep } = useStepRange(courseSteps, id);

  const {
    step: { singular: stepSingular },
  } = useAccountTerminology();

  return (
    <ContentBlock hasPaddingBottom={isLastStep}>
      <StepHeading
        currentItem={stepPosition}
        rangeTitle={stepSingular}
        totalItems={courseSteps.length}
      >
        {collabState === WebSocketStatus.Connected && <CollaborationUsers users={collabUsers} />}
      </StepHeading>
      <StepContentBody className='step-content' isLastStep={isLastStep}>
        <ContentBlock>
          <StepTitle courseId={courseId} emoji={emoji} id={id} title={title} />
          <StyledHrEdit />
          <StyledEditorContent
            $contentStyles={contentStyles}
            className='editor-content'
            editor={editor}
          />
        </ContentBlock>
        <NavigationButtons stepId={id} />
      </StepContentBody>
    </ContentBlock>
  );
};

export default StepContent;
