import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useEditorCourseContext } from '../../../../../../contexts/EditorCourseContext';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateTopicMutation } from '../../../../../../redux/services/resourceApis/courses/topicsApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Icon from '../../../../../design_system/display/icons/Icon';
import EmojiPicker from '../../../../shared/EmojiPicker';
import TopicTitle from '../../../components/TopicTitle/TopicTitle';
import { ReadingTimeProvider } from '../../../shared/ReadTimeFlyout/ReadingTimeContext';
import ReadTimeFlyout from '../../../shared/ReadTimeFlyout/ReadTimeFlyout';
import { BreadcrumbWrapper, TopicEmojiWrapper } from '../../../shared/styles';
import CurriculumBreadcrumb from './CurriculumBreadcrumb';

const t = initTranslations('editor_breadcrumbs');

const CourseTitleWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerSm1} 0`};
  width: 100%;
`;

const Breadcrumbs = () => {
  const {
    course: { id: topicId, title: topicTitle, emoji, readingTime, autoGeneratedReadingTime },
    curriculum,
  } = useEditorCourseContext();
  const { topic: topicTerm } = useAccountTerminology();

  const [showPicker, setShowPicker] = useState(false);
  const [updateTopic, result] = useUpdateTopicMutation();

  const emojiSelected = useCallback(
    (emoji: BaseEmoji) => {
      updateTopic({ id: topicId, emoji: emoji.native });
    },
    [topicId, updateTopic]
  );

  const clearEmoji = useCallback(() => {
    updateTopic({ id: topicId, emoji: null });
  }, [topicId, updateTopic]);

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('emoji_error'),
  });

  return (
    <BreadcrumbWrapper className='breadcrumb-wrapper'>
      <CurriculumBreadcrumb curriculum={curriculum} mode='edit' />
      <EmojiPicker
        ariaLabel={t('edit_topic_emoji_aria', { topicTerm })}
        emojiPresent={emoji}
        isOpen={showPicker}
        onClick={() => setShowPicker(!showPicker)}
        onClickOutside={() => setShowPicker(false)}
        onEmojiSelect={(emoji) => {
          emojiSelected(emoji);
          setShowPicker(false);
        }}
        removeButtonAction={clearEmoji}
        trigger={
          <TopicEmojiWrapper className='icon'>
            {emoji ? <span>{emoji}</span> : <Icon name='file-lines' size='md' weight='regular' />}
          </TopicEmojiWrapper>
        }
      />
      <CourseTitleWrapper>
        <TopicTitle isBreadcrumbTitle title={topicTitle} topicId={topicId} />
      </CourseTitleWrapper>
      <ReadingTimeProvider>
        <ReadTimeFlyout
          autoGeneratedReadingTime={autoGeneratedReadingTime}
          courseId={topicId}
          readingTime={readingTime}
        />
      </ReadingTimeProvider>
    </BreadcrumbWrapper>
  );
};

export default Breadcrumbs;
