import React from 'react';
import styled, { css } from 'styled-components';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import IconButton from '../../../../design_system/buttons/IconButton';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import { routes } from '../../../publicApplication/applicationRouter';

const StyledDiv = styled.div(
  ({ theme: { constants } }) => css`
    margin-left: ${constants.spacerMd2};
    margin-right: ${constants.spacerMd2};
  `
);

const CrossIconButton = styled(IconButton)`
  color: ${({ theme: { vars } }) => vars.textDefault};

  svg {
    pointer-events: none;
  }
`;

const t = initTranslations('training_path.path_config.config_header');

const BackToProfile = () => {
  const { user } = useTrainingPathContext();
  const { slug } = useCurrentAccount();

  return (
    <StyledDiv>
      <Tooltip id='redirect-to-profile-tooltip' place='bottom' text={t('return_to_profile')} />
      <div data-for='redirect-to-profile-tooltip' data-tip='true'>
        <CrossIconButton
          ariaLabel={t('return_to_profile')}
          buttonSize='md'
          id='redirect-to-profile-button'
          name='xmark'
          onClick={() => {
            routes
              .userProfile({
                slug,
                id: user.id,
              })
              .push();
          }}
          weight='regular'
        />
      </div>
    </StyledDiv>
  );
};

export default BackToProfile;
