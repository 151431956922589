import dayjs from 'dayjs';
import React from 'react';

import { usePlanTitle } from '../../../../../../../hooks/billing/usePlanTitle';
import { getFormattedPrice } from '../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../lib/initTranslations';
import {
  NewSimplePricingPlan,
  NewSimplePricingPlanOmitEmployeesSize,
} from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { AddonItems } from '../../../../../../../redux/services/resourceApis/billing/types';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { OverlayContentHeaderTitle } from '../../../../../shared/FullScreenOverlay/styles';
import AddonsSection from '../../../../shared/AddonsSection/AddonsSection';
import {
  AdditionalInfo,
  AddonsSectionWrapper,
  Divider,
  DueTodayWrapper,
  PlanCreditTitle,
  PlanCreditTitleWrapper,
  PlanCreditWrapper,
  StyledIcon,
  SummaryWrapper,
  TooltipIconWrapper,
} from '../../../../shared/BillingFullScreenOverlay/Summary/styles';
import {
  PlanInterval,
  PlanName,
  PlanNameWrapper,
  PlanPrice,
  PlanPriceWrapper,
  PlanText,
} from './styles';

const t = initTranslations('billing.full_screen_overlay_content.summary');

type SummaryProps = {
  addonItems: AddonItems;
  newPlanData: NewSimplePricingPlan | NewSimplePricingPlanOmitEmployeesSize;
  currentDate: Date;
  primaryColor: string;
};

const Summary = ({ addonItems, newPlanData, currentDate, primaryColor }: SummaryProps) => {
  const formattedCurrentDate = dayjs(currentDate).format('MMM D'); // e.g. Jul 1
  const { name, dueToday, nextPaymentDate, credit, totalPrice } = newPlanData;
  const planTitle = usePlanTitle({ name });
  const showCreditSection = credit < 0;
  const showDueTodayAdditionalInfo = dueToday > 0;

  return (
    <SummaryWrapper>
      <OverlayContentHeaderTitle withBottomMargin>{t('title')}</OverlayContentHeaderTitle>
      <PlanNameWrapper>
        <PlanText>
          <PlanName>{planTitle}</PlanName>
          {t('current_plan')}
        </PlanText>
        {totalPrice && (
          <PlanPriceWrapper>
            <PlanPrice>{getFormattedPrice(totalPrice)}</PlanPrice>
            <PlanInterval>/{t('month')}</PlanInterval>
          </PlanPriceWrapper>
        )}
      </PlanNameWrapper>
      {showCreditSection && (
        <PlanCreditWrapper>
          <PlanCreditTitleWrapper>
            <PlanCreditTitle>{t('plan_credit')}</PlanCreditTitle>
            <TooltipIconWrapper data-for='plan-credit-tooltip' data-tip>
              <StyledIcon name='circle-info' weight='regular' />
            </TooltipIconWrapper>
            <Tooltip id='plan-credit-tooltip' place='bottom' text={t('plan_credit_tooltip_text')} />
          </PlanCreditTitleWrapper>
          <span>{getFormattedPrice(credit)}</span>
        </PlanCreditWrapper>
      )}
      {addonItems && (
        <>
          <Divider />
          <AddonsSectionWrapper>
            <AddonsSection addonItems={addonItems} divideItems primaryColor={primaryColor} />
          </AddonsSectionWrapper>
        </>
      )}
      <>
        <Divider />
        <DueTodayWrapper>
          <span>{t('due_today')}</span>
          <span>
            {getFormattedPrice(dueToday)}
            {showDueTodayAdditionalInfo && <AdditionalInfo>{t('plus_taxes')}</AdditionalInfo>}
          </span>
        </DueTodayWrapper>
        <AdditionalInfo>
          {formattedCurrentDate} – {nextPaymentDate}
        </AdditionalInfo>
      </>
    </SummaryWrapper>
  );
};

export default Summary;
