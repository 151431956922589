import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import Avatar from '../../../../../design_system/display/avatar';
import { routes } from '../../../../publicApplication/applicationRouter';
import RouterLink from '../../../../publicApplication/RouterLink';
import {
  BoldText,
  UserDetailsWrapper,
  UserInfoWrapper,
  UserTitle,
} from '../../../AllUsersReportPage/AllUsersReportTable/Columns/styles';
import { AvatarWrapper } from '../../../Styles';
import { ColumnComponentProps } from '../types';

const Name = ({ record, curriculumId, curriculumName }: ColumnComponentProps) => {
  const { slug } = useCurrentAccount();
  const { id, name, avatar, title } = record;

  return (
    <UserInfoWrapper>
      <AvatarWrapper
        to={routes.individualUserReport({
          slug,
          id,
          breadcrumb: routes.curriculumByUserReport({ slug, id: curriculumId }),
          breadcrumbText: curriculumName,
        })}
      >
        <Avatar image={avatar} name={name} shape='circle' size='md' />
      </AvatarWrapper>
      <UserDetailsWrapper>
        <RouterLink
          to={routes.individualUserReport({
            slug,
            id,
            breadcrumb: routes.curriculumByUserReport({ slug, id: curriculumId }),
            breadcrumbText: curriculumName,
          })}
        >
          <BoldText className='notranslate'>{name}</BoldText>
        </RouterLink>
        <UserTitle>{title}</UserTitle>
      </UserDetailsWrapper>
    </UserInfoWrapper>
  );
};

export default Name;
