import React, { useState } from 'react';
import { Route } from 'type-route';

import { CurriculumEditProvider } from '../../../../contexts/CurriculumEditContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import useFlashNoticeCookie from '../../../../hooks/useFlashNoticeCookie';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import PageContent from '../../shared/page_content';
import SuggestedTopicsSlideout from '../CurriculumEdit/AIOutliner/SuggestedTopicsSlideout/SuggestedTopicsSlideout';
import ESignaturePaywallBanner from '../ESignaturePaywallBanner/ESignaturePaywallBanner';
import DeleteCurriculumConfirmationModal from '../modals/DeleteCurriculumConfirmationModal';
import CurriculumAdminBottom from './CurriculumAdminBottom/CurriculumAdminBottom';
import CurriculumAdminTop from './CurriculumAdminTop/CurriculumAdminTop';

export type Props = {
  route: Route<typeof routes.curriculumEdit> | Route<typeof routes.curriculumAdmin>;
  isEdit?: boolean; // Added while both the admin and edit routes exist to allow tabs to work on both
};

const CurriculumAdmin = ({ route }: Props) => {
  const {
    params: { id, tab },
  } = route;
  const ability = useCurrentUserAbilities();
  const { slug } = useCurrentAccount();
  const [showDeleteCurriculumModal, setShowDeleteCurriculumModal] = useState(false);
  const canEditCurriculum = ability.can('update', `EditCurriculum-${id}`);
  useFlashNoticeCookie();

  if (!canEditCurriculum) {
    routeTo(routes.curriculumShow({ slug, id }).href);
    return <></>;
  }

  return (
    <CurriculumEditProvider
      setShowDeleteCurriculumModal={setShowDeleteCurriculumModal}
      showDeleteCurriculumModal={showDeleteCurriculumModal}
    >
      <PageContent id='curriculum-edit'>
        {showDeleteCurriculumModal && (
          <DeleteCurriculumConfirmationModal
            closeModal={() => setShowDeleteCurriculumModal(false)}
            id={id}
          />
        )}
        <CurriculumAdminTop id={id} />
        <CurriculumAdminBottom curriculumId={id} tab={tab} />
        <SuggestedTopicsSlideout curriculumId={id} />
      </PageContent>
      <ESignaturePaywallBanner />
    </CurriculumEditProvider>
  );
};

export default CurriculumAdmin;
