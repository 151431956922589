import { useMemo } from 'react';

import { ConsumptionCourse, ConsumptionCurriculum } from '../../../../../types/Editor';

const NOT_COMPLETED_POSITION = -1;
const INITIAL_POSITION = 1;

type ConsumptionStep = {
  completed: boolean;
  position: number;
  id: number;
};

type Props = {
  course: ConsumptionCourse | undefined;
  curriculum: ConsumptionCurriculum | undefined;
  currentStepId: number;
};

export const useAccessibleSteps = ({ course, curriculum, currentStepId }: Props) => {
  return useMemo(() => {
    if (!curriculum || !course) return { shouldForceOrder: false, nextStepId: undefined };

    const { published, completionRequired, forcedCompletionOrder } = curriculum;
    const { assignedToUser, steps } = course;

    const highestCompletedStep = steps.reduce<ConsumptionStep | null>((acc, stepItem) => {
      if (stepItem.completed && stepItem.position > (acc?.position || NOT_COMPLETED_POSITION)) {
        return stepItem;
      }
      return acc;
    }, null);

    const nextStepPosition = highestCompletedStep
      ? highestCompletedStep.position + 1
      : INITIAL_POSITION;

    const nextStep = steps.find((stepItem) => stepItem.position === nextStepPosition);

    const accessibleSteps = steps
      .filter(
        (stepItem) =>
          stepItem.completed ||
          stepItem.position <= nextStepPosition ||
          stepItem.position === INITIAL_POSITION
      )
      .map((stepItem) => stepItem.id);

    const isAccessible = accessibleSteps.includes(currentStepId);

    return {
      shouldForceOrder:
        !isAccessible && forcedCompletionOrder && published && assignedToUser && completionRequired,
      nextStepId: nextStep?.id,
    };
  }, [course, currentStepId, curriculum]);
};
