import styled from 'styled-components';

import { mediaBreakpointPxXl } from '../../../../../../styled/Breakpoint';

export const PreviewExperienceWrapper = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  padding: ${({ theme: { constants } }) => `${constants.spacerLg1} 0 ${constants.spacerLg2} 0`};
  box-shadow: ${({ theme: { vars } }) =>
    `-1.5rem 0 0 0 ${vars.foundationBase2}, 1.5rem 0 0 0 ${vars.foundationBase2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  @media (min-width: ${mediaBreakpointPxXl}) {
    box-shadow: ${({ theme: { vars } }) =>
      `-2.5rem 0 0 0 ${vars.foundationBase2}, 2.5rem 0 0 0 ${vars.foundationBase2}`};
  }
`;

export const PreviewExperienceItemsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};

  > div {
    flex: ${({ theme: { constants } }) => `1 1 calc(50% - ${constants.spacerMd3})`};
  }
`;
