import React, { useCallback, useMemo, useState } from 'react';

import ActionDropdown from '../ActionDropdown';
import ActionDropdownMenuOption, {
  ActionDropdownMenuOptionData,
} from '../ActionDropdown/ActionDropdownMenuOption';

type Props = {
  initialSelectedOptionIndex: number;
  modeText?: boolean;
  titleBold?: boolean;
  options: ActionDropdownMenuOptionData[];
  style?:
    | 'default'
    | 'toolbar'
    | 'supershare'
    | 'survey'
    | 'generalaccess'
    | 'sector'
    | 'delegationBoard'
    | 'subjectMode';
};

const ViewModeDropdown = ({
  initialSelectedOptionIndex,
  modeText = false,
  options,
  style,
  titleBold = false,
}: Props) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(initialSelectedOptionIndex);

  const componentOptions = useMemo(
    () =>
      options.map((option, index) => (
        <ActionDropdownMenuOption
          description={option.description}
          icon={option.icon}
          isSelected={index === selectedOptionIndex}
          key={option.title}
          optionType={option.optionType}
          title={option.title}
        />
      )),
    [options, selectedOptionIndex]
  );

  const handleOptionClick = useCallback(
    (index: number) => {
      const optionOnClick = options[index].onClick;
      setSelectedOptionIndex(index);
      optionOnClick && optionOnClick();
    },
    [options, setSelectedOptionIndex]
  );

  return (
    <ActionDropdown
      id='view-mode-dropdown'
      modeText={modeText}
      name='view-mode'
      options={componentOptions}
      selectedOptionData={options[selectedOptionIndex]}
      setSelectedOption={handleOptionClick}
      style={style}
      titleBold={titleBold}
    />
  );
};

export default ViewModeDropdown;
