import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import StyleConfiguration from '../../../../types/StyleConfiguration';
import { publicApplicationApi } from '../../publicApplicationService';
import {
  AcceptInvitationParams,
  AccountParams,
  ClearbitData,
  CreateAccountParams,
  CreateCheckoutTokenBasedAccountParams,
  CreateHubspotContactParams,
  CreateIndustryBasedAccountParams,
  CreatePartnerBasedAccountParams,
  LoginResponse,
  PdfExportResponse,
  ResetPasswordParams,
  ResponseWithMessage,
  ResponseWithRedirectLocation,
  UniversalLoginParams,
  UniversalLoginResponse,
  UpdatePasswordParams,
  UserNameByInvitationTokenParams,
  UserNameByInvitationTokenResponse,
  UserNameByResetPasswordTokenParams,
  UserNameByToken,
} from './types';

export const loginApi = publicApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    acceptInvitation: builder.mutation<ResponseWithRedirectLocation, AcceptInvitationParams>({
      query: ({ slug, ...user }) => ({
        url: `${slug}/users/invitation`,
        method: 'PUT',
        body: { user },
      }),
    }),
    brandStyles: builder.query<StyleConfiguration, AccountParams>({
      query: (params: AccountParams) => ({
        url: 'ajax/public_application/brand_styles',
        params,
      }),
      transformResponse: (returnValue: StyleConfiguration) => {
        return toCamelCase<StyleConfiguration>(returnValue);
      },
    }),
    createAccount: builder.mutation<ResponseWithRedirectLocation, CreateAccountParams>({
      query: (account) => ({
        url: 'ajax/accounts/backdrop',
        method: 'POST',
        body: toSnakeCase({ account: { ...account } }),
      }),
    }),
    createPartnerBasedAccount: builder.mutation<
      ResponseWithRedirectLocation,
      CreatePartnerBasedAccountParams
    >({
      query: (account) => ({
        url: 'ajax/accounts/partner_based',
        method: 'POST',
        body: toSnakeCase({ account: { ...account } }),
      }),
    }),
    createIndustryBasedAccount: builder.mutation<
      ResponseWithRedirectLocation,
      CreateIndustryBasedAccountParams
    >({
      query: (account) => ({
        url: 'ajax/accounts/industry_based',
        method: 'POST',
        body: toSnakeCase({ account: { ...account } }),
      }),
    }),
    createCheckoutTokenBasedAccount: builder.mutation<
      ResponseWithRedirectLocation,
      CreateCheckoutTokenBasedAccountParams
    >({
      query: (account) => ({
        url: 'ajax/accounts/checkout_token_based',
        method: 'POST',
        body: toSnakeCase({ account: { ...account } }),
      }),
    }),
    createHubspotContact: builder.mutation<undefined, CreateHubspotContactParams>({
      query: ({ user_email }) => ({
        url: 'accounts/create_contact',
        method: 'POST',
        body: { account: { user_email } },
      }),
    }),
    login: builder.mutation<
      ResponseWithRedirectLocation,
      { slug: string; email: string; password: string; remember_me: boolean }
    >({
      query: ({ slug, ...user }) => ({
        url: `${slug}/ajax/login`,
        method: 'POST',
        body: { user },
      }),
    }),
    pdfExport: builder.query<PdfExportResponse, void>({
      query: () => ({
        url: 'ajax/pdf_export',
      }),
    }),
    getLoginAccountData: builder.query<LoginResponse, AccountParams>({
      query: (params: AccountParams) => ({
        url: 'ajax/public_application/login_account_data',
        params,
      }),
      transformResponse: (returnValue: LoginResponse) => {
        return toCamelCase<LoginResponse>(returnValue);
      },
    }),
    getUserNameByInvitationToken: builder.query<
      UserNameByInvitationTokenResponse,
      UserNameByInvitationTokenParams
    >({
      query: ({ slug, invitation_token }) => {
        return {
          url: `${slug}/ajax/users/invitation/accept`,
          method: 'GET',
          params: { invitation_token },
        };
      },
      transformResponse: (returnValue: UserNameByInvitationTokenResponse) => {
        return toCamelCase<UserNameByInvitationTokenResponse>(returnValue);
      },
    }),
    getUserNameByResetPasswordToken: builder.query<
      UserNameByToken,
      UserNameByResetPasswordTokenParams
    >({
      query: ({ slug, reset_password_token }) => {
        return {
          url: `${slug}/ajax/passwords/edit`,
          method: 'GET',
          params: { reset_password_token },
        };
      },
      transformResponse: (returnValue: UserNameByToken) => {
        return toCamelCase<UserNameByToken>(returnValue);
      },
    }),
    resetPassword: builder.mutation<ResponseWithMessage, ResetPasswordParams>({
      query: ({ slug, email }) => ({
        url: `${slug}/ajax/passwords`,
        method: 'POST',
        body: { user: { email } },
      }),
    }),
    universalLogin: builder.query<UniversalLoginResponse, UniversalLoginParams>({
      query: ({ email, otp_token, account_id }) => ({
        url: 'ajax/public_application/universal_login',
        params: { email, otp_token, account_id },
      }),
      transformResponse: (returnValue: UniversalLoginResponse) => {
        return toCamelCase<UniversalLoginResponse>(returnValue);
      },
    }),
    updatePassword: builder.mutation<ResponseWithRedirectLocation, UpdatePasswordParams>({
      query: ({ slug, ...user }) => {
        return {
          url: `${slug}/ajax/passwords`,
          method: 'PUT',
          body: { user },
        };
      },
      transformResponse: (returnValue: ResponseWithRedirectLocation) => {
        return toCamelCase<ResponseWithRedirectLocation>(returnValue);
      },
    }),
    clearbitData: builder.query<ClearbitData, { email: string }>({
      query: ({ email }) => ({
        url: 'ajax/public_application/clearbit_data',
        params: { email },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAcceptInvitationMutation,
  useBrandStylesQuery,
  useCreateAccountMutation,
  useCreatePartnerBasedAccountMutation,
  useCreateHubspotContactMutation,
  useLoginMutation,
  useCreateIndustryBasedAccountMutation,
  useCreateCheckoutTokenBasedAccountMutation,
  usePdfExportQuery,
  useGetLoginAccountDataQuery,
  useGetUserNameByInvitationTokenQuery,
  useGetUserNameByResetPasswordTokenQuery,
  useResetPasswordMutation,
  useUniversalLoginQuery,
  useUpdatePasswordMutation,
} = loginApi;
