import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { ELEMENT_TYPE_TO_KIND } from '../../../../../../types/CurriculumElement';
import Link from '../../../../../design_system/Link';
import { getCurriculumElementRoute } from '../../../../shared/getCurriculumElementRoute';
import { ColumnComponentProps } from '../types';

const ClickableArrow = ({ record }: ColumnComponentProps) => {
  const { slug } = useCurrentAccount();
  const { element_type } = record;
  const route = getCurriculumElementRoute({ element: record, slug })?.href;

  if (!route) return null;

  return (
    <Link
      className={`element-clickable-arrow-${ELEMENT_TYPE_TO_KIND[element_type]}`}
      color='monochrome'
      href={route}
      prefixIconName='arrow-right'
      text=''
    />
  );
};

export default ClickableArrow;
