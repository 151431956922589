import React, { useCallback, useEffect } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import { CheckboxWrapper, StyledCheckbox } from './styles';

export type AgreeToReceiveSmsCheckboxProps = {
  hasPhoneValue: boolean;
  hasPhoneError: boolean;
  agreeToReceiveSms: boolean;
  setFieldValue: (field: string, value: boolean) => void;
};

const t = initTranslations('home.onboarding.user_info_step.agree_to_receive_sms_checkbox');

const AgreeToReceiveSmsCheckbox = ({
  hasPhoneValue,
  hasPhoneError,
  agreeToReceiveSms,
  setFieldValue,
}: AgreeToReceiveSmsCheckboxProps) => {
  const { productTerm } = useAccountTerminology();
  const disabled = hasPhoneError || !hasPhoneValue;

  const handleCheckboxChange = useCallback(
    (value: boolean) => setFieldValue('agreed_to_receive_sms', value),
    [setFieldValue]
  );

  useEffect(() => {
    if (!hasPhoneValue) {
      handleCheckboxChange(false);
    }
  }, [handleCheckboxChange, hasPhoneValue]);

  return (
    <CheckboxWrapper checked={agreeToReceiveSms}>
      <StyledCheckbox
        checked={agreeToReceiveSms}
        className='agree-to-receive-sms-checkbox'
        disabled={disabled}
        id='agree-to-receive-sms-checkbox'
        label={t('title', { productTerm })}
        name='agree-to-receive-sms-checkbox'
        onCheck={() => handleCheckboxChange(!agreeToReceiveSms)}
      >
        {t('description', { productTerm })}
      </StyledCheckbox>
    </CheckboxWrapper>
  );
};

export default AgreeToReceiveSmsCheckbox;
