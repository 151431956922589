import React from 'react';
import ReactOnRails from 'react-on-rails';
import styled from 'styled-components';

import googlelogo from '../../../../../../../../assets/images/google-auth.png';
import mslogo from '../../../../../../../../assets/images/ms-logo.png';
import oktalogo from '../../../../../../../../assets/images/okta-logo.png';
import ssologo from '../../../../../../../../assets/images/sso_auth.png';
import { RegisteredId } from '../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../lib/initTranslations';
import { SsoSettings } from '../../../../../../redux/services/resourceApis/publicApplication/types';
import { SsoProvider } from '../../../../../../types/SsoProvider';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import FlexContainer from '../../../../../styled/FlexContainer';
import { routes } from '../../../applicationRouter';

const SsoButton = styled(DefaultButton)`
  width: 100%;
  transition: all 0.4s ease-in-out;

  img {
    object-fit: cover;
  }
`;

const SsoButtonWrapper = styled(FlexContainer)`
  margin: ${({ theme }) => theme.constants.spacerSm3};
`;

const SsoLinksContainer = styled.div`
  margin-top: ${({ theme }) => theme.constants.spacerMd3};
`;

const Form = styled.form`
  width: 100%;
`;

type SsoLinksProps = Partial<Omit<SsoSettings, 'ssoOnlyEnabled'>> & {
  adminAccountName: string | undefined;
  adminSlug: string | undefined;
  slug: string;
  redirectPath: string | undefined;
  rememberMe: boolean | undefined;
};

type SsoLinkProps = {
  id: RegisteredId;
  img: string;
  provider: SsoProvider;
};

const t = initTranslations('public_application.login');

const SsoOptions: Array<SsoLinkProps> = [
  {
    id: 'okta-sso-login',
    img: oktalogo,
    provider: 'oktaoauth',
  },
  {
    id: 'ms-sso-login',
    img: mslogo,
    provider: 'microsoft_graph_auth',
  },
  {
    id: 'google-sso-login',
    img: googlelogo,
    provider: 'google_oauth2',
  },
  {
    id: 'saml-sso-login',
    img: ssologo,
    provider: 'saml',
  },
];

const AuthenticityToken = () => {
  return (
    <input name='authenticity_token' type='hidden' value={`${ReactOnRails.authenticityToken()}`} />
  );
};

const SsoLinks = ({
  ableToUseGoogle,
  ableToUseMicrosoft,
  ableToUseOkta,
  ableToUseSaml,
  adminAccountName,
  adminSlug,
  redirectPath,
  oktaSignInText,
  samlSignInText,
  slug,
  rememberMe,
}: SsoLinksProps) => {
  const checkAbilityToRenderLink = (id: RegisteredId) => {
    switch (id) {
      case 'okta-sso-login':
        return ableToUseOkta;
      case 'ms-sso-login':
        return ableToUseMicrosoft;
      case 'google-sso-login':
        return ableToUseGoogle;
      case 'saml-sso-login':
        return ableToUseSaml;
    }
  };

  const getSignInText = (id: RegisteredId, admin_account_name: string | undefined) => {
    switch (id) {
      case 'okta-sso-login':
        return oktaSignInText || t('okta_sso_login_cta');
      case 'ms-sso-login':
        return t('ms_sso_login_cta');
      case 'google-sso-login':
        return t('google_sso_login_cta');
      case 'saml-sso-login':
        return (
          samlSignInText ||
          t('saml_sso_login_cta', { admin_account_name: admin_account_name?.trim() || 'SSO' })
        );
    }
    return '';
  };

  return (
    <SsoLinksContainer>
      {SsoOptions.map(({ id, img, provider }, index) => {
        if (checkAbilityToRenderLink(id)) {
          return (
            <SsoButtonWrapper key={index}>
              <Form
                action={
                  routes.ssoAuth({
                    provider,
                    account_slug: slug,
                    partner_slug: adminSlug || '',
                    redirect_path: redirectPath,
                    remember_me: rememberMe,
                  }).href
                }
                method='post'
              >
                <AuthenticityToken />
                <SsoButton
                  buttonType='secondary'
                  iconImage={img}
                  id={id}
                  key={index}
                  text={getSignInText(id, adminAccountName)}
                  type='submit'
                />
              </Form>
            </SsoButtonWrapper>
          );
        }
      })}
    </SsoLinksContainer>
  );
};

export default SsoLinks;
