import { Draft } from '@reduxjs/toolkit';

import { BoardColumn } from '../../../../components/application/DelegationPlanner/BoardColumn/types';

export const dispatchOptimisticResponsibilityMove = (
  draft: Draft<{ boardColumns: BoardColumn[] }>,
  {
    sourceColumnId,
    targetColumnId,
    responsibilityId,
    position,
  }: {
    sourceColumnId: number;
    targetColumnId: number;
    responsibilityId: string;
    position: number;
  }
) => {
  const sourceColumn = draft.boardColumns.find((column) => column.id === sourceColumnId);
  const targetColumn = draft.boardColumns.find((column) => column.id === targetColumnId);

  if (sourceColumn && targetColumn) {
    const [movedResponsibility] = sourceColumn.boardColumnResponsibilities.splice(
      sourceColumn.boardColumnResponsibilities.findIndex((resp) => resp.id === responsibilityId),
      1
    );
    targetColumn.boardColumnResponsibilities.splice(position - 1, 0, movedResponsibility);
  }
};
