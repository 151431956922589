import React from 'react';

import { ChoiceIcon } from './styles';
import { CustomIconProps } from './types';

const SurveyOptionIcon = ({ correct, iconType }: CustomIconProps) => {
  const iconName =
    iconType === 'checkbox'
      ? correct
        ? 'square-check'
        : 'square'
      : correct
      ? 'circle-dot'
      : 'circle';

  return <ChoiceIcon isCorrect={correct} name={iconName} size='md' weight='regular' />;
};

export default SurveyOptionIcon;
