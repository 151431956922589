import React, { ReactNode, createContext, useContext } from 'react';
import { Route } from 'type-route';

import { routes } from '../components/application/publicApplication/applicationRouter';
import useGroupsIndexState, { GroupsIndexState } from '../hooks/useGroupsIndexState';
import { GroupsModalProvider } from './GroupsModalContext';

interface Props {
  children: ReactNode;
  route: Route<typeof routes.groups>;
}

const GroupsIndexContext = createContext({} as GroupsIndexState);
export const useGroupsIndex = () => useContext(GroupsIndexContext);

export const GroupsIndexProvider = ({ children, route }: Props) => {
  const state = useGroupsIndexState({ route });

  return (
    <GroupsIndexContext.Provider value={state}>
      <GroupsModalProvider>{children}</GroupsModalProvider>
    </GroupsIndexContext.Provider>
  );
};
