import React from 'react';

import { couponToDiscount } from '../../../../../../../lib/billing/stripeCouponToDiscount';
import {
  CurrentSimplePricingPlan,
  NewSimplePricingPlan,
} from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { PlanCardsWrapper } from '../../../../shared/BillingFullScreenOverlay/PlanCards/styles';
import PlanCard from './PlanCard/PlanCard';
import { PlanCardProps } from './types';

const PlanCards = ({ currentPlan, newPlan, coupon }: PlanCardProps) => {
  const discount = couponToDiscount(coupon);

  return (
    <PlanCardsWrapper>
      {[currentPlan, newPlan].map(
        (plan: CurrentSimplePricingPlan | NewSimplePricingPlan, index) => {
          return (
            <PlanCard
              discount={discount}
              isCurrentPlanSameAsNew={currentPlan.stripePlanId === newPlan.stripePlanId}
              key={[plan.name, index].join('-')}
              plan={plan}
            />
          );
        }
      )}
    </PlanCardsWrapper>
  );
};

export default PlanCards;
