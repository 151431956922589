import * as yup from 'yup';

import initTranslations from '../initTranslations';

const t = initTranslations('public_application.login.form.errors');

export const PASSWORD_MIN_CHARACTERS = 10;
export const PASSWORD_MAX_CHARACTERS = 128;

export const passwordSchema = yup
  .string()
  .required(t('password.blank'))
  .min(PASSWORD_MIN_CHARACTERS, t('password.too_short', { count: PASSWORD_MIN_CHARACTERS }))
  .max(PASSWORD_MAX_CHARACTERS, t('password.too_long', { count: PASSWORD_MAX_CHARACTERS }))
  .matches(/.*[0-9].*/, t('password.missing_number'))
  .matches(/.*[A-Z].*/, t('password.missing_uppercase'))
  .matches(/.*[a-z].*/, t('password.missing_lowercase'))
  .matches(/.*[-_+=\\;[\]'!@#$%^&*(),.?":{}|<>].*/, t('password.missing_symbol'));
