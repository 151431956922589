import React, { useState } from 'react';
import styled from 'styled-components';

import {
  TeammateModalProvider,
  useTeammateModal,
} from '../../../../../../../contexts/TeammateContext';
import { useShowVerifyStatusBadge } from '../../../../../../../hooks/curriculum/useShowVerifyStatusBadge';
import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import { ContentType } from '../../../../../../../types/ContentType';
import SuperShareModal from '../../../../../people/SuperShare/SuperShareModal/SuperShareModal';
import AddTeammateWithConfirmation from '../../../../../people/TeammateModal/AddTeammateWithConfirmation';
import ViewModeDropdown from '../../../../../shared/ViewModeDropdown/ViewModeDropdown';
import { viewOptions } from '../../../../../shared/ViewModeDropdown/ViewOptions';
import CurriculumModal from '../../../../modals/CurriculumModal/CurriculumModal';
import AdminVerifyStatusBadge from '../../../../StatusBadges/VerifyStatusBadge/AdminVerifyStatusBadge';
import { TopSurfaceProps } from '../TopSurface';
import OwnedByFlyout from './OwnedByFlyout/OwnedByFlyout';
import PublishDropdown from './PublishDropdown/PublishDropdown';
import ShareButton from './ShareButton/ShareButton';
import TimeEstimatePill from './TimeEstimatePill';

type ModalType = 'exportPdf' | 'shareChanges' | 'superShare';

const MainActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const ViewModeDropdownWrapper = styled.div`
  min-width: 9.25rem;
`;

const TopSurfaceActionRow = ({ curriculum }: TopSurfaceProps) => {
  const {
    emoji,
    title,
    owner,
    minutes_to_read_formatted: minutesToReadFormatted,
    minutes_to_read: minutesToRead,
    can_assign_users: canAssignUsers,
    id,
    published,
    can_publish: canPublish,
    all_users_assigned: allUsersAssigned,
    sector,
    verify_content_status: verifyContentStatus,
    verify_content_frequency_in_days: verifyContentFrequencyInDays,
    verified_content_at: verifiedContentAt,
    next_verified_content_at: nextVerifiedContentAt,
  } = curriculum;

  const { accountPlan, slug } = useCurrentAccount();
  const { teammateModalVisible, teammateConfirmationModalVisible } = useTeammateModal();
  const showAddTeammateWithConfirmation = teammateModalVisible || teammateConfirmationModalVisible;
  const [activeModal, setActiveModal] = useState<null | ModalType>(null);
  const [showCurriculumModal, setShowCurriculumModal] = useState(false);
  const showVerifyStatusBadge = useShowVerifyStatusBadge({ verifyContentStatus, owner });
  const isVerifiedContent = verifyContentStatus === 'verified';

  return (
    <MainActionsWrapper id='top-surface-action-row'>
      <ViewModeDropdownWrapper>
        <ViewModeDropdown
          initialSelectedOptionIndex={0}
          modeText
          options={viewOptions({ id, slug, contentType: ContentType.Curriculum })}
        />
      </ViewModeDropdownWrapper>
      {accountPlan !== 'build' && (
        <>
          <OwnedByFlyout curriculumId={curriculum.id} owner={owner} />
          {showAddTeammateWithConfirmation && <AddTeammateWithConfirmation />}
        </>
      )}
      {canAssignUsers && (
        <ShareButton
          allUsersAssigned={allUsersAssigned}
          onClick={() => setActiveModal('superShare')}
        />
      )}
      {showVerifyStatusBadge && (
        <AdminVerifyStatusBadge
          curriculumId={id}
          isVerifiedContent={isVerifiedContent}
          nextVerifyDate={nextVerifiedContentAt}
          owner={owner}
          setShowCurriculumModal={setShowCurriculumModal}
          verifiedDate={verifiedContentAt}
          verifyPeriod={verifyContentFrequencyInDays}
        />
      )}
      {minutesToRead > 0 && (
        <TimeEstimatePill id={id} minutesToReadFormatted={minutesToReadFormatted} />
      )}
      {activeModal === 'superShare' && (
        <SuperShareModal
          curriculumId={id}
          setShowSuperShareModal={(show: boolean) => {
            setActiveModal(show ? 'superShare' : null);
          }}
          showSuperShareModal={activeModal === 'superShare'}
        />
      )}
      {showCurriculumModal && (
        <TeammateModalProvider>
          <CurriculumModal
            closeRequest={() => setShowCurriculumModal(false)}
            curriculumId={id}
            optionalSettingsOpen
            sector={sector}
          />
        </TeammateModalProvider>
      )}
      {canPublish && (
        <PublishDropdown
          allUsersAssigned={allUsersAssigned}
          id={id}
          published={published}
          subject={{ title, emoji }}
        />
      )}
    </MainActionsWrapper>
  );
};

export default TopSurfaceActionRow;
