import styled from 'styled-components';

import { fontSm5 } from '../../../../../styled/TypeSystem';

export const StyledDropdownMenuOption = styled.span`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

export const ButtonTextWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconWrapper = styled.div<{ isDeleteOption: boolean }>`
  display: flex;
  align-items: center;
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};

  svg {
    color: ${({ isDeleteOption, theme: { vars } }) =>
      isDeleteOption ? vars.stateError : vars.textDefault};
  }
`;

export const Title = styled.p<{ isDeleteOption: boolean }>`
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm3} 0 0;
  ${({ isDeleteOption, theme: { vars } }) =>
    isDeleteOption && {
      color: vars.stateError,
    }};

  ${fontSm5};
`;
