import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import {
  useApplyRequestMutation,
  useDenyRequestMutation,
} from '../../../../../redux/services/resourceApis/requests/requestsApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import TableBodyCell from '../../../people/UsersOutline/Table/Body/TableBodyCell';
import { DefaultDataStyled } from '../../../people/UsersOutline/Table/Styles';
import { ApproveRequestButton } from '../../styles';

const t = initTranslations('requests.status_column');

type Props = {
  requestId: number;
  resolved: boolean;
  status: string;
};

const RequestsStatusColumn = ({ requestId, resolved, status }: Props) => {
  const [denyRequest, resultDenyRequest] = useDenyRequestMutation();
  const { isLoading: isLoadingDeny } = resultDenyRequest;

  const [applyRequest, resultApplyRequest] = useApplyRequestMutation();
  const { isLoading: isLoadingApply } = resultApplyRequest;

  useDisplayFlashOnResponse({
    result: resultApplyRequest,
    errorMessage: t('error'),
    successMessage: t('request_approved'),
  });

  useDisplayFlashOnResponse({
    result: resultDenyRequest,
    errorMessage: t('error'),
    successMessage: t('request_denied'),
  });

  return resolved ? (
    <TableBodyCell data={status} id={`td-requests-table-${requestId}-status`} />
  ) : (
    <DefaultDataStyled id={`td-requests-table-${requestId}-status`}>
      <DefaultButton
        buttonType='secondary'
        id={`deny-request-${requestId}`}
        loading={isLoadingDeny}
        onClick={() => denyRequest(requestId)}
        text={t('deny')}
      />
      <ApproveRequestButton
        buttonType='primary'
        id={`approve-request-${requestId}`}
        loading={isLoadingApply}
        onClick={() => applyRequest(requestId)}
        text={t('approve')}
      />
    </DefaultDataStyled>
  );
};

export default RequestsStatusColumn;
