import styled from 'styled-components';

import { fontLg1, fontSm5 } from '../../../../../../styled/TypeSystem';

const getFooterLinkBgImg = (color: string) =>
  encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" width="67" height="25" viewBox="0 0 67 25" fill="none"><path d="M38.1229 1.27202C26.3799 0.393026 2.21045 6.3476 1.23965 12.88C0.268852 19.4124 19.3636 22.9962 43.8396 19.3073C68.3156 15.6185 70.7504 5.82385 58.7635 3.42906C46.7765 1.03427 20.4919 3.6124 9.99895 12.0498C-0.493986 20.4873 22.7803 24.9337 36.2067 22.7426" stroke="${color}" stroke-width="2" stroke-linecap="round"/></svg>`
  );

export const TrainualPlusFooterWrapper = styled.div`
  max-width: 25rem;
  text-align: center;
  margin: ${({ theme: { constants } }) => `${constants.spacerMd2} auto ${constants.spacerLg3}`};
`;

export const TrainualPlusFooterTitle = styled.h5`
  margin-top: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};

  ${fontLg1};
`;

export const TrainualPlusFooterDescription = styled.p<{
  primaryColor: string;
  secondaryColor: string;
}>`
  margin: 0;
  ${fontSm5};

  span {
    display: inline-block;
    position: relative;
    color: ${({ primaryColor }) => primaryColor};
    font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
    margin-bottom: ${({ theme: { constants } }) => constants.spacerSm1};

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -1px;
      width: 67px;
      height: 25px;
      z-index: -1;
      background: ${({ secondaryColor }) =>
        `url('data:image/svg+xml;utf8,${getFooterLinkBgImg(secondaryColor)}'`}) no-repeat center;
      background-size: contain;
    }
  }
`;
