import React from 'react';

import ESignatureStatus from '../../../../shared/ESignatureStatus/ESignatureStatus';
import { ColumnComponentProps } from '../types';

const ESignature = ({ record }: ColumnComponentProps) => {
  const { e_signatures, element_id, curriculum_id } = record;

  return (
    <ESignatureStatus
      courseId={element_id}
      curriculumId={curriculum_id}
      eSignature={e_signatures}
      kind='by_course'
    />
  );
};

export default ESignature;
