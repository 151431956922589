import React, { useEffect, useState } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import SearchField from '../../../../design_system/Triage/fields/SearchField';
import { TemplatesOutlineSearchWrapper } from './styles';
import { TemplatesSearchProps } from './types';

const t = initTranslations('templates_outline.search');

const TemplatesOutlineSearch = ({
  searchValue,
  setSearchValue,
  onEnterSubmit,
  dispatchSearchState,
}: TemplatesSearchProps) => {
  const debouncedSearchValue = useDebounce<string>(searchValue, 500);
  const hasSufficientLength = debouncedSearchValue.length > 2;
  const [searchValueChanged, setSearchValueChanged] = useState(false);

  const setSearchValueFromDebouncedSearchField =
    (hasSufficientLength || debouncedSearchValue.length === 0) &&
    dispatchSearchState &&
    searchValueChanged;

  useEffect(() => {
    if (setSearchValueFromDebouncedSearchField) {
      const newSearchTerm = searchValue.length === 0 ? '' : debouncedSearchValue;
      dispatchSearchState({ type: 'changeSearchTerm', searchTerm: newSearchTerm });
    }
  }, [
    debouncedSearchValue,
    searchValue,
    dispatchSearchState,
    setSearchValueFromDebouncedSearchField,
  ]);

  const applySearchValue = (value: string) => {
    setSearchValue(value);
    (value.length > 2 || value.length === 0) && setSearchValueChanged(true);
  };

  return (
    <TemplatesOutlineSearchWrapper>
      <SearchField
        onEnterSubmit={onEnterSubmit}
        placeholder={t('placeholder')}
        searchValue={searchValue}
        setSearchValue={applySearchValue}
      />
    </TemplatesOutlineSearchWrapper>
  );
};

export default TemplatesOutlineSearch;
