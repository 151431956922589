import React, { useState } from 'react';

import { TeammateModalProvider } from '../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { getTemplateBackButtonText } from '../../../../../lib/templates/getTemplateBackButtonText';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../publicApplication/applicationRouter';
import { BreadcrumbType } from '../../../publicApplication/route_types/breadcrumb';
import CopyableContainer from '../../../shared/CopyableContainer';
import AddTemplateModal from '../../modals/AddTemplateModal';
import {
  TemplatePreviewBackToTemplatesLabel,
  TemplatePreviewBackToTemplatesLink,
  TemplatePreviewButtonsWrapper,
  TemplatePreviewShareTemplateButton,
  TemplatePreviewTopNavBarWrapper,
} from './styles';

const t = initTranslations('template_preview.top_nav_bar');

export type TemplatePreviewTopNavBarProps = {
  templateId: number;
  breadcrumb?: BreadcrumbType;
};

const TemplatePreviewTopNavBar = ({ breadcrumb, templateId }: TemplatePreviewTopNavBarProps) => {
  const { slug } = useCurrentAccount();
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);

  const backButtonHref = (breadcrumb && breadcrumb.href) || routes.templates({ slug }).href;
  const backButtonText = getTemplateBackButtonText(breadcrumb?.name);

  return (
    <TemplatePreviewTopNavBarWrapper>
      <TemplatePreviewBackToTemplatesLink
        href={backButtonHref}
        prefixIconName='arrow-left'
        text={
          <TemplatePreviewBackToTemplatesLabel>
            {backButtonText}
          </TemplatePreviewBackToTemplatesLabel>
        }
        underlineBehavior='dynamic'
      />
      <TemplatePreviewButtonsWrapper>
        <CopyableContainer toolTipId='share-template-tooltip' valueToCopy={window.location.href}>
          <div data-for='share-template-tooltip' data-tip='true'>
            <TemplatePreviewShareTemplateButton
              buttonType='secondary'
              iconName='share-nodes'
              iconPosition='right'
              id='share-template-button'
              text={t('share_template')}
            />
          </div>
        </CopyableContainer>
        <DefaultButton
          buttonType='primary'
          iconName='plus-square'
          iconPosition='right'
          id='add-and-edit-template'
          onClick={() => setShowAddTemplateModal(true)}
          text={t('add_template')}
        />
      </TemplatePreviewButtonsWrapper>
      {showAddTemplateModal && (
        <TeammateModalProvider>
          <AddTemplateModal
            closeModal={() => setShowAddTemplateModal(false)}
            showModal={showAddTemplateModal}
            templateId={templateId}
          />
        </TeammateModalProvider>
      )}
    </TemplatePreviewTopNavBarWrapper>
  );
};

export default TemplatePreviewTopNavBar;
