import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../lib/initTranslations';
import IconButton from '../../design_system/buttons/IconButton';
import Icon from '../../design_system/display/icons/Icon';
import { fontMd3 } from '../../styled/TypeSystem';

const AvatarUploadControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: ${(props) => props.theme.constants.spacerSm3};
  padding: ${(props) => props.theme.constants.spacerSm2};
  border: ${(props) => props.theme.constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.foundationBase2};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${(props) => props.theme.constants.borderRadiusMd};
`;

const RotateButtonContainer = styled.div`
  display: flex;
`;

const RotateButton = styled(IconButton)`
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const RotateButtonFlip = styled(RotateButton)`
  svg {
    transform: scale(-1, 1);
  }
`;

const Range = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 3.75rem);
`;

const RangeFileIcon = styled(Icon)`
  margin: 0 ${(props) => props.theme.constants.spacerSm3};
  path {
    fill: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

const RangeFileIconLg = styled(RangeFileIcon)`
  margin-right: 0;
  ${fontMd3};
`;

const RangeInput = styled.input`
  width: calc(100% - 5rem);
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  appearance: none;
  &::-moz-range-track {
    height: 0.25rem;
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
    border: none;
    border-radius: ${(props) => props.theme.constants.borderRadiusSm};
  }

  &::-webkit-slider-runnable-track {
    height: 0.25rem;
    background-color: ${({ theme: { vars } }) => vars.borderSurface2};
    border: none;
    border-radius: ${(props) => props.theme.constants.borderRadiusSm};
  }

  &::-moz-range-thumb {
    border: none;
    height: ${(props) => props.theme.constants.height2xs};
    width: 1rem;
    border-radius: ${(props) => props.theme.constants.borderRadiusXl};
    background-color: ${({ theme: { vars } }) => vars.textDefault};
    margin-top: -0.375rem;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: ${(props) => props.theme.constants.height2xs};
    width: 1rem;
    border-radius: ${(props) => props.theme.constants.borderRadiusXl};
    background-color: ${({ theme: { vars } }) => vars.textDefault};
    margin-top: -0.375rem;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    &::-webkit-slider-runnable-track {
      background-color: ${({ theme: { vars } }) => vars.borderSurface2};
    }
  }
`;

const t = initTranslations('avatar_upload_controls');

type Props = {
  setRotation: (e: number) => void;
  setZoom: (e: number) => void;
  rotation: number;
  zoom: number | undefined;
};

const ImageUploadControls = (props: Props) => {
  const { setRotation, setZoom, rotation, zoom } = props;

  return (
    <AvatarUploadControlsContainer>
      <RotateButtonContainer>
        <RotateButton
          ariaLabel={t('aria_label_rotate_counterclockwise')}
          buttonSize='sm'
          name='undo'
          onClick={() => setRotation(rotation - 90)}
          weight='light'
        />
        <RotateButtonFlip
          ariaLabel={t('aria_label_rotate_clockwise')}
          buttonSize='sm'
          name='undo'
          onClick={() => setRotation(rotation + 90)}
          weight='light'
        />
      </RotateButtonContainer>
      <Range>
        <RangeFileIcon name='image' size='xs' weight='regular' />
        <RangeInput
          max={2}
          min={1}
          onChange={(e) => setZoom(Number(e.target.value))}
          step={0.1}
          type='range'
          value={zoom}
        />
        <RangeFileIconLg name='image' size='lg' weight='regular' />
      </Range>
    </AvatarUploadControlsContainer>
  );
};

export default ImageUploadControls;
