import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Route } from 'type-route';

import initTranslations from '../../../../../../lib/initTranslations';
import getLetterByPosition from '../../../../../../lib/surveys/getLetterByPosition';
import {
  useDeleteSurveyQuestionOptionMutation,
  useUpdateSurveyQuestionOptionMutation,
} from '../../../../../../redux/services/resourceApis/surveyQuestionOptions/surveyQuestionOptionsApi';
import IconButton from '../../../../../design_system/buttons/IconButton';
import ConfirmationModal from '../../../../../design_system/overlays/modals/ConfirmationModal';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import SurveyOptionIcon from '../../../shared/SurveyOptionIcon/SurveyOptionIcon';
import ChoiceTextEditor from './ChoiceTextEditor';
import {
  ChoiceElementWrapper,
  ChoiceInputWrapper,
  ChoiceLetter,
  ChoiceRemoveButton,
} from './styles';
import { ChoiceElementProps } from './types';

const t = initTranslations('survey_edit.question.choose_options_form.option');

const ChoiceElement = ({ option, surveyAnswerType }: ChoiceElementProps) => {
  const route = useRoute();
  const {
    params: { id: questionId, surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;
  const { id, position, correct } = option;
  const [updateOption, { isSuccess: isUpdateSuccess, reset: resetUpdate }] =
    useUpdateSurveyQuestionOptionMutation();
  const [
    deleteOption,
    { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading, reset: resetDelete },
  ] = useDeleteSurveyQuestionOptionMutation();
  const [isActionsLocked, setIsActionsLocked] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const resetOptions = ['choose_one_option', 'true_or_false'].includes(surveyAnswerType);
  const iconType = surveyAnswerType === 'multiple_choice' ? 'checkbox' : 'radio_button';

  const setIsActionsLockedDebounced = debounce((value) => {
    setIsActionsLocked(value);
  }, 500);

  const handleOptionCorrectUpdate = () => {
    const isUpdateAllowed: boolean = iconType === 'checkbox' || !correct;

    if (!isActionsLocked && isUpdateAllowed) {
      setIsActionsLocked(true);
      updateOption({ id, surveyId, questionId, correct: !correct, resetOptions });
    }
  };

  const handleOptionDelete = () => {
    if (!isActionsLocked) {
      setIsActionsLocked(true);
      deleteOption({ id, surveyId, questionId });
    }
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      resetUpdate();
      setIsActionsLockedDebounced(false);
    }
  }, [isUpdateSuccess, resetUpdate, setIsActionsLockedDebounced]);

  useEffect(() => {
    if (isDeleteSuccess) {
      resetDelete();
      setIsActionsLockedDebounced(false);
    }
  }, [isDeleteSuccess, resetDelete, setIsActionsLockedDebounced]);

  return (
    <ChoiceElementWrapper id={`survey-question-option-${id}`}>
      <IconButton
        ariaLabel={t('set_correct_aria_label')}
        customIcon={<SurveyOptionIcon correct={correct} iconType={iconType} />}
        id={`set-correct-survey-question-option-${id}`}
        onClick={handleOptionCorrectUpdate}
      />
      <ChoiceInputWrapper isCorrect={correct}>
        <ChoiceLetter className='notranslate' isCorrect={correct}>
          {getLetterByPosition(position)}
        </ChoiceLetter>
        {surveyAnswerType === 'true_or_false' ? option.text : <ChoiceTextEditor option={option} />}
      </ChoiceInputWrapper>
      {surveyAnswerType !== 'true_or_false' && (
        <ChoiceRemoveButton
          ariaLabel={t('remove_aria_label')}
          buttonSize='sm'
          id={`remove-survey-question-option-${id}`}
          name='trash-can'
          onClick={() => setShowDeletionModal(true)}
        />
      )}
      {showDeletionModal && (
        <ConfirmationModal
          actionFunction={handleOptionDelete}
          actionText={t('delete_confirmation.delete')}
          heapModalName='delete-survey-option-modal'
          message={t('delete_confirmation.message')}
          onCloseRequest={() => setShowDeletionModal(false)}
          processing={isDeleteLoading}
          title={t('delete_confirmation.title')}
        />
      )}
    </ChoiceElementWrapper>
  );
};

export default ChoiceElement;
