import React from 'react';

import Skeleton from '../Skeleton/Skeleton';
import { SKELETON_LINE_WIDTHS } from './data';

const LoadingState = () => {
  return (
    <div>
      {SKELETON_LINE_WIDTHS.map((width, index) => (
        <Skeleton key={index} lineWidth={width} />
      ))}
    </div>
  );
};

export default LoadingState;
