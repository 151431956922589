import { useEffect } from 'react';

import { ReduxDisplayResult } from '../../../../hooks/useDisplayFlashOnResponse';
import {
  ResponseWithMessage,
  ResponseWithRedirectLocation,
} from '../../../../redux/services/resourceApis/publicApplication/types';
import { routes } from '../applicationRouter';

type LoginParams = {
  account_id?: number;
  email?: string;
  redirect_path?: string;
  show_notification?: string;
  slug: string;
};

type Result = ReduxDisplayResult & {
  data?: ResponseWithRedirectLocation | ResponseWithMessage;
};

const useRedirectToLoginOnSuccess = (result: Result, loginParams?: LoginParams) => {
  const { data, isSuccess } = result;
  const redirectLocation = data && 'location' in data ? data.location : undefined;
  const slugParam = loginParams && 'slug' in loginParams && loginParams.slug;

  useEffect(() => {
    if (!isSuccess) return;

    if (redirectLocation) {
      window.location.replace(redirectLocation);
    } else if (slugParam) {
      routes
        .login({
          ...loginParams,
        })
        .replace();
    }
  }, [isSuccess, loginParams, redirectLocation, slugParam]);
};

export default useRedirectToLoginOnSuccess;
