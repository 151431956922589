import React from 'react';

import ThreeDotLoaderGif from '../../../../images/loaders/three-dot-loader.gif';
import initTranslations from '../../../../lib/initTranslations';
import { useAutosaveStatus } from '../../../../redux/domains/autoSave/autosaveSlice';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { AutoSaveStatus } from '../../shared/./DeprecatedAutoSaveStatusIndicator/types';
import useDeprecatedAutoSaveIndicatorState from '../../shared/DeprecatedAutoSaveStatusIndicator/useDeprecatedAutoSaveIndicatorState';
import { LoadingIcon, PopupText, StyledLoadingImage, UpdatedAtText, Wrapper } from './styles';
import { BoardSaveStatusProps } from './types';

const t = initTranslations('delegation_planner');
const tooltipId = 'board-save-status-tooltip';

const BoardSaveStatus = ({ currentBoard, isChangesApplying, isFetching }: BoardSaveStatusProps) => {
  const autosaveStatus = useAutosaveStatus();
  const { updatedAt, updatedByName, status: boardStatus, updatedAtInWords } = currentBoard || {};

  const indicatorProps = useDeprecatedAutoSaveIndicatorState({
    autosaveStatus,
    isFetching,
    updatedAtInWords,
  });

  const renderTooltipContent = () => (
    <PopupText
      dangerouslySetInnerHTML={{
        __html: t('saved_on_date_by_user_html', {
          date: updatedAt,
          user: updatedByName,
        }),
      }}
    />
  );

  if (!currentBoard) return <></>;

  return (
    <>
      <Tooltip content={renderTooltipContent()} id={tooltipId} place='left' />

      <Wrapper data-for={tooltipId} data-tip>
        {indicatorProps.status === AutoSaveStatus.Saving && (
          <>
            {isChangesApplying ? (
              <StyledLoadingImage alt='three dot loader' src={ThreeDotLoaderGif} />
            ) : (
              <LoadingIcon fixedWidth name='rotate' spinSpeed='fast' weight='solid' />
            )}

            <UpdatedAtText>
              {isChangesApplying ? t('applying_changes') : t('saving_draft')}
            </UpdatedAtText>
          </>
        )}
        {(indicatorProps.status === AutoSaveStatus.Saved ||
          indicatorProps.status === AutoSaveStatus.NoChanges) && (
          <UpdatedAtText>
            {boardStatus === 'finished'
              ? t('applied_at', { date: indicatorProps.updatedAtInWords })
              : t('updated_on', { date: indicatorProps.updatedAtInWords })}
          </UpdatedAtText>
        )}
      </Wrapper>
    </>
  );
};

export default BoardSaveStatus;
