import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useTrainingPathContext } from '../../../../../../../contexts/TrainingPathContext';
import { useTrainingPathSetContext } from '../../../../../../../contexts/TrainingPathSetContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import {
  DelayType,
  delayTypes,
} from '../../../../../../../redux/services/resourceApis/trainingPaths/types';
import Icon from '../../../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../../../styled/TypeSystem';
import DropdownWithPoppableMenu from '../../../../../shared/DropdownWithPoppableMenu';

const t = initTranslations('training_path.path_config.config_set');

const MenuOption = styled.div(
  ({ theme: { vars } }) => css`
    display: flex;
    align-items: center;
    color: ${vars.textDefault};
    ${fontSm5};
  `
);

const CheckmarkWrapper = styled.div(
  ({ theme: { constants } }) => css`
    width: ${constants.spacerMd3};
    padding: 0 ${constants.spacerSm1};
  `
);

const OptionWrapper = styled.div`
  padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

type DelayTypeOption = {
  label: string;
  isSelected: boolean;
};

const DelayTypeFlyoutMenuOption = ({ label, isSelected }: DelayTypeOption) => {
  return (
    <MenuOption>
      <CheckmarkWrapper>
        {isSelected && <Icon name='check' size='xs' weight='solid' />}
      </CheckmarkWrapper>
      <OptionWrapper>{label}</OptionWrapper>
    </MenuOption>
  );
};

type dynamicDelayLabelFunction = (count: number | null) => string;

const delayTypeLabels: { [key in DelayType]: { label: dynamicDelayLabelFunction } } = {
  days: { label: (count) => t('delay_options.days', { count: count || 2 }) },
  weeks: { label: (count) => t('delay_options.weeks', { count: count || 2 }) },
  months: { label: (count) => t('delay_options.months', { count: count || 2 }) },
};

const DelayTypeFlyout = () => {
  const {
    trainingPathSet: { delayType: activeDelayType, delayValue },
    updateTrainingPathSet,
  } = useTrainingPathSetContext();
  const { updateInProgress } = useTrainingPathContext();

  const updateDelayType = useCallback(
    (delayType: DelayType) => {
      activeDelayType !== delayType &&
        updateTrainingPathSet({
          delayType,
        });
    },
    [activeDelayType, updateTrainingPathSet]
  );

  return (
    <DropdownWithPoppableMenu
      id='delay-type-flyout'
      menuId='delay-type-flyout-menu'
      menuPlacement='bottom-start'
      options={delayTypes.map((delayType) => {
        return (
          <DelayTypeFlyoutMenuOption
            isSelected={delayType === activeDelayType}
            key={`menu-option-${delayType}`}
            label={delayTypeLabels[delayType].label(delayValue)}
          />
        );
      })}
      selectedOption={activeDelayType && delayTypeLabels[activeDelayType]?.label(delayValue)}
      setSelectedOption={(selectedIndex) =>
        !updateInProgress && updateDelayType(delayTypes[selectedIndex])
      }
      style='delayType'
    />
  );
};

export default DelayTypeFlyout;
