import styled from 'styled-components';

import { fontMd3 } from '../../../styled/TypeSystem';

export const EditorInputWrapper = styled.div<{
  $isError: boolean;
  isFocused?: boolean;
}>`
  max-width: 100%;
  width: fit-content;
  height: fit-content;
  text-decoration: none;
  overflow-wrap: break-word;
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid transparent`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  border-color: ${({ $isError, isFocused, theme: { vars } }) =>
    $isError ? vars.stateError : isFocused ? vars.accentPrimaryDefault : 'transparent'};
  outline: none;
  transition: 0.1s ease-out;
  ${fontMd3};

  &:hover {
    border-color: ${({ $isError, isFocused, theme: { vars } }) =>
      $isError ? vars.stateError : isFocused ? vars.accentPrimaryDefault : vars.borderDefault};
  }

  .editor-input {
    outline: none;
    padding: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

// DS Override - to match editor version
export const Title = styled.h3`
  margin: 0.57rem;
  ${fontMd3};
`;
