import { isEqual } from 'lodash';
import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { useTheme } from 'styled-components';

import useSlideout from '../../../../../hooks/useSlideout';
import initTranslations from '../../../../../lib/initTranslations';
import { selectOptions } from '../../../../../lib/selectOptions';
import { getTemplateFiltersCount } from '../../../../../lib/templates/getTemplateFiltersCount';
import {
  useGetTemplatesFilterCategoriesDataQuery,
  useGetTemplatesFilterDataQuery,
} from '../../../../../redux/services/resourceApis/templates/templatesApi';
import { OutlineTemplatesFilersState } from '../../../../../redux/services/resourceApis/templates/types';
import { sectorsArray, sectorsToSelectOptions } from '../../../../../types/Sector';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Badge from '../../../../design_system/display/badge';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import CollapsibleFiltersSection from '../../../shared/CollapsibleFiltersSection/CollapsibleFiltersSection';
import SlideoutPanel from '../../../shared/slideout';
import { TEMPLATES_OUTLINE_SLIDEOUT_TYPE } from '../TemplatesOutlineIndexPage/TemplatesOutlineIndexPage';
import { SearchAction } from '../TemplatesOutlineIndexPage/types';
import { selectedFiltersReducer } from './reducer';
import { TemplatesSlideoutBody, TemplatesSlideoutFooter } from './styles';
import { CheckboxActionType, CheckboxType } from './types';

const t = initTranslations('templates_outline.slideout');

type TemplatesOutlineSlideoutProps = {
  appliedFilters: OutlineTemplatesFilersState;
  dispatchSearchState: React.Dispatch<SearchAction>;
  appliedFiltersCount: number;
};

const TemplatesOutlineSlideout = ({
  appliedFiltersCount,
  appliedFilters,
  dispatchSearchState,
}: TemplatesOutlineSlideoutProps) => {
  const [selectedFiltersState, dispatchFiltersState] = useReducer(
    selectedFiltersReducer,
    appliedFilters
  );
  const {
    constants: { spacerSm3 },
  } = useTheme();
  const { data } = useGetTemplatesFilterDataQuery();
  const { data: categoriesData } = useGetTemplatesFilterCategoriesDataQuery();
  const { close: closeSlideout } = useSlideout(TEMPLATES_OUTLINE_SLIDEOUT_TYPE);
  const accountTerminology = useContext(AccountTerminologyContext);
  const sectors = useMemo(
    () => sectorsToSelectOptions([...sectorsArray].reverse(), accountTerminology),
    [accountTerminology]
  );
  const selectedFiltersCount = getTemplateFiltersCount(selectedFiltersState);
  const existsCategoriesData = Boolean(categoriesData?.length);
  const { author, suggestedGroups, tags } = selectedFiltersState;
  const disableApplyButton = !selectedFiltersCount && isEqual(selectedFiltersState, appliedFilters);

  const checkSelectedValue = (type: CheckboxType, value: string) => {
    return selectedFiltersState[type].filter((selectedValue) => selectedValue === value).length > 0;
  };

  const handleCheckboxChange = (type: CheckboxActionType, value: string) => {
    dispatchFiltersState({ type, value });
  };

  const clearFilters = () => {
    dispatchFiltersState({ type: 'clearSelectedFilters' });
    dispatchSearchState({ type: 'clearFilters' });
    closeSlideout();
  };

  const applyFilters = () => {
    dispatchSearchState({ type: 'applyFilters', filters: selectedFiltersState });
    closeSlideout();
  };

  const arrayToSelectOption = (arr: string[]) => {
    return arr.map((item) => ({ id: item, name: item }));
  };

  useEffect(() => {
    //Clear selected filters if there are no applied filters
    !appliedFiltersCount && dispatchFiltersState({ type: 'clearSelectedFilters' });
  }, [appliedFiltersCount]);

  if (!data || !categoriesData) return <></>;

  return (
    <SlideoutPanel
      headerText={t('title')}
      slideoutType={TEMPLATES_OUTLINE_SLIDEOUT_TYPE}
      slideoutWidth='20rem'
      titleAlign='flex-start'
    >
      <div className='templates-outline-filters'>
        <TemplatesSlideoutBody>
          {existsCategoriesData && (
            <CollapsibleFiltersSection title={t('categories')}>
              {categoriesData.map((category) => (
                <Checkbox
                  bottomMargin={spacerSm3}
                  centeredCheckboxes
                  checked={checkSelectedValue('categoryIds', String(category.id))}
                  id={`templates-outline-filter-category-${category.id}`}
                  key={category.id}
                  label={<Badge clickable size='md' text={category.name} type='general' />}
                  name={`templates-outline-filter-category-${category.id}`}
                  onCheck={() => handleCheckboxChange('changeCategories', String(category.id))}
                />
              ))}
            </CollapsibleFiltersSection>
          )}
          <CollapsibleFiltersSection title={t('author')}>
            <MultiSelectField
              className='templates-outline-authors-select'
              defaultValue={author}
              onNonDefaultSelected={(value: string[]) => {
                dispatchFiltersState({ type: 'changeAuthors', value });
              }}
              options={selectOptions(arrayToSelectOption(data.authors))}
              placeholder={t('choose_author')}
              value={author}
            />
          </CollapsibleFiltersSection>
          <CollapsibleFiltersSection title={t('roles')}>
            <MultiSelectField
              className='templates-outline-roles-select'
              defaultValue={suggestedGroups}
              onNonDefaultSelected={(value: string[]) => {
                dispatchFiltersState({ type: 'changeRoles', value });
              }}
              options={selectOptions(arrayToSelectOption(data.groups))}
              placeholder={t('choose_roles')}
              value={suggestedGroups}
            />
          </CollapsibleFiltersSection>
          <CollapsibleFiltersSection title={t('tags')}>
            <MultiSelectField
              className='templates-outline-tags-select'
              defaultValue={tags}
              onNonDefaultSelected={(value: string[]) => {
                dispatchFiltersState({ type: 'changeTags', value });
              }}
              options={selectOptions(arrayToSelectOption(data.tags))}
              placeholder={t('choose_tags')}
              value={tags}
            />
          </CollapsibleFiltersSection>
          <CollapsibleFiltersSection title={t('sectors')}>
            {sectors.map((sector) => (
              <Checkbox
                bottomMargin={spacerSm3}
                centeredCheckboxes
                checked={checkSelectedValue('sectors', sector.value)}
                id={`templates-outline-filter-sector-${sector.value}`}
                key={sector.value}
                label={sector.label}
                name={`templates-outline-filter-sector-${sector.value}`}
                onCheck={() => handleCheckboxChange('changeSectors', sector.value)}
              />
            ))}
          </CollapsibleFiltersSection>
        </TemplatesSlideoutBody>
        <TemplatesSlideoutFooter>
          <DefaultButton
            buttonType='tertiary'
            fullWidth
            id='templates-outline-filter-clear-button'
            onClick={clearFilters}
            size='md'
            text={t('clear')}
          />
          <DefaultButton
            buttonType='primary'
            disabled={disableApplyButton}
            fullWidth
            id='templates-outline-filter-apply-button'
            onClick={applyFilters}
            size='md'
            text={t('apply')}
          />
        </TemplatesSlideoutFooter>
      </div>
    </SlideoutPanel>
  );
};

export default TemplatesOutlineSlideout;
