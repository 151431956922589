import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useCallback, useState } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { useUpdateSurveyMutation } from '../../../../redux/services/resourceApis/surveys/surveysApi';
import { ContentType } from '../../../../types/ContentType';
import StatusDropdown from '../../editor/components/StatusDropdown/StatusDropdown';
import { BreadcrumbWrapper, EditorBreadcrumbs } from '../../editor/shared/styles';
import EmojiPicker from '../../shared/EmojiPicker';
import ViewModeDropdown from '../../shared/ViewModeDropdown/ViewModeDropdown';
import { viewOptions } from '../../shared/ViewModeDropdown/ViewOptions';
import { HeadingWrapper, OutlineContentWrapper, SurveyTitle } from '../shared/styles';
import SurveyCurriculumTitleLink from '../shared/SurveyCurriculumTitleLink/SurveyCurriculumTitleLink';
import SurveyEmoji from '../shared/SurveyEmoji/SurveyEmoji';
import { ContentDropdownWrapper, DropdownWrapper } from './styles';
import SurveyQuestionsList from './SurveyQuestionsList';
import { OutlineContentProps } from './types';

const OutlineContent = ({ survey, setIsSurveyStatusChangeError }: OutlineContentProps) => {
  const { slug } = useCurrentAccount();
  const [showPicker, setShowPicker] = useState(false);
  const [updateSurvey] = useUpdateSurveyMutation();
  const { id, curriculumElementId, name, status, curriculum, questions, emoji } = survey;

  const emojiSelected = (emoji: BaseEmoji) => {
    updateSurvey({ id, emoji: emoji.native });
    setShowPicker(false);
  };

  const clearEmoji = () => {
    updateSurvey({ id, emoji: null });
  };

  const statusChangeErrorFunction = useCallback(() => {
    setIsSurveyStatusChangeError(true);
  }, [setIsSurveyStatusChangeError]);

  return (
    <OutlineContentWrapper>
      <HeadingWrapper>
        <EditorBreadcrumbs id='survey-editor-breadcrumb'>
          <SurveyCurriculumTitleLink
            curriculumId={curriculum.id}
            curriculumTitle={curriculum.title}
            redirectToEditPage
          />
        </EditorBreadcrumbs>
      </HeadingWrapper>
      <BreadcrumbWrapper>
        <EmojiPicker
          emojiPresent={emoji}
          isOpen={showPicker}
          onClick={() => setShowPicker(!showPicker)}
          onClickOutside={() => setShowPicker(false)}
          onEmojiSelect={emojiSelected}
          removeButtonAction={clearEmoji}
          trigger={<SurveyEmoji emoji={emoji} isClickable />}
        />
        <SurveyTitle>{name}</SurveyTitle>
      </BreadcrumbWrapper>
      <ContentDropdownWrapper id='content-dropdown-wrapper'>
        <DropdownWrapper id='status-dropdown-wrapper'>
          <StatusDropdown
            curriculumId={curriculum.id}
            element={{ curriculumElementId, status }}
            errorFunction={statusChangeErrorFunction}
          />
        </DropdownWrapper>
        <ViewModeDropdown
          initialSelectedOptionIndex={0}
          options={viewOptions({ id, slug, contentType: ContentType.Survey })}
          titleBold
        />
      </ContentDropdownWrapper>
      <SurveyQuestionsList questions={questions} />
    </OutlineContentWrapper>
  );
};

export default OutlineContent;
