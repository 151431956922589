import React, { useCallback, useEffect, useReducer, useState } from 'react';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { useAbilityToManageProfile } from '../../../../../../hooks/users/ProfileSharedAbilities';
import { generateRandomFileName } from '../../../../../../lib/generateRandomFilename';
import initTranslations from '../../../../../../lib/initTranslations';
import { readFile } from '../../../../../../lib/readFile';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { ProfileUser } from '../../../../../../redux/services/resourceApis/users/types';
import { useUploadAvatarMutation } from '../../../../../../redux/services/resourceApis/users/usersApi';
import { AvatarObject } from '../../../../../../types/Avatar';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { StyledAvatar } from '../../../Shared/Person/ClickableAvatar';
import AvatarUploadModal from './AvatarUploadModal/AvatarUploadModal';

type AvatarUploadState = {
  showAvatarUploadModal: boolean;
  loading: boolean;
};

type AvatarUploadAction = 'reset' | 'showModal' | 'closeModal' | 'loading' | 'loaded';

const initialState: AvatarUploadState = { showAvatarUploadModal: false, loading: false };
const reducer = (
  state: AvatarUploadState,
  action: { type: AvatarUploadAction }
): AvatarUploadState => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'showModal':
      return { ...state, showAvatarUploadModal: true };
    case 'closeModal':
      return { ...state, showAvatarUploadModal: false };
    case 'loading':
      return { ...state, loading: true };
    case 'loaded':
      return { ...state, loading: false };
  }
};

const t = initTranslations('user_profile.avatar_upload');

interface Props {
  user: ProfileUser;
}

const AvatarUpload = ({ user }: Props) => {
  const ableToManageProfile = useAbilityToManageProfile(user.id);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [newAvatar, setNewAvatar] = useState<AvatarObject>();
  const [uploadAvatar, uploadAvatarResult] = useUploadAvatarMutation();
  const { data: uploadAvatarData, error: uploadAvatarError } = uploadAvatarResult;
  const { flash } = useFlashNotification();

  const fetchAvatar = useCallback(
    async (url: string): Promise<void> => {
      dispatch({ type: 'loading' });
      const response = await fetch(url, {
        headers: {
          'Access-Control-Allow-Origin': window.location.origin,
          'Access-Control-Request-Method': 'GET',
          'Cache-Control': 'no-cache',
        },
      }).then((res) => {
        if (!res.ok) {
          flash('error', t('fetch_error'));
          dispatch({ type: 'loaded' });
          Promise.reject(res);
          throw new Error('Failed to fetch avatar');
        }
        return res;
      });

      if (response.ok) {
        const blob = await response.blob();
        const file = new File([blob], generateRandomFileName(), { type: blob.type });
        const avatar = await readFile(file);
        setNewAvatar({ avatar });
        dispatch({ type: 'loaded' });
      }
    },
    [flash]
  );

  useEffect(() => {
    user?.avatar && fetchAvatar(user.avatar);
  }, [user?.avatar, fetchAvatar]);

  const saveNewAvatar = async () => {
    dispatch({ type: 'loading' });
    const formData = new FormData();
    formData.append(`user[avatar]`, newAvatar?.avatar as string);
    await uploadAvatar({ user_id: user.id, form_data: formData });
    dispatch({ type: 'loaded' });
  };

  useDisplayFlashOnResponse({
    result: uploadAvatarResult,
    successMessage: uploadAvatarData?.message,
    successFunction: () => {
      uploadAvatarResult.reset();
      dispatch({ type: 'closeModal' });
    },
    errorMessage: messageFromError(uploadAvatarError)?.join(', '),
  });

  return (
    <>
      <StyledAvatar
        ariaLabel={t('edit_user_avatar_aria_label')}
        completionPercent={ableToManageProfile ? user.completion_percentage : undefined}
        image={user.avatar}
        name={user.name}
        onClick={() => dispatch({ type: 'showModal' })}
        shape='circle'
        size='2xl'
      />
      {state.showAvatarUploadModal && (
        <AvatarUploadModal
          imageUrl={newAvatar?.avatar}
          loading={state.loading}
          name={user.name}
          onClose={() => dispatch({ type: 'reset' })}
          onSave={saveNewAvatar}
          setImage={setNewAvatar}
        />
      )}
    </>
  );
};

export default AvatarUpload;
