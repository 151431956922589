import { useEffect } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { FullScreenOverlayType } from '../../../../../../types/FullScreenOverlay';
import { useFlashNotification } from '../../../../../FlashNotificationContext';

const t = initTranslations('per_user_pricing.full_screen_overlay_content');

export const useCheckAbilityToShowOverlay = (type: FullScreenOverlayType) => {
  const { flash } = useFlashNotification();
  const {
    status,
    splitFeatures: { fullScreenManagePlanOverlayEnabled, fullScreenManageTrialPlanOverlayEnabled },
  } = useCurrentAccount();

  const isAccountTrial = ['trialing', 'trial_ended'].includes(status);
  const canManagePlanOverlay = fullScreenManagePlanOverlayEnabled && !isAccountTrial;
  const canManageTrialPlanOverlay = fullScreenManageTrialPlanOverlayEnabled && isAccountTrial;
  const canViewFullScreenOverlay =
    type === 'configure-plan' ? canManageTrialPlanOverlay : canManagePlanOverlay;

  useEffect(() => {
    if (!canViewFullScreenOverlay) {
      flash('error', t('not_authorized_flash'));
    }
  }, [canViewFullScreenOverlay, flash]);

  return canViewFullScreenOverlay;
};
