import { CurriculumElement, CurriculumElementStatus } from './CurriculumElement';
import { Emoji } from './Emoji';
import { LimitedStep, StepType } from './Step';

export const isCourse = (curriculumElement: CurriculumElement) => {
  return curriculumElement.elementKind === 'course';
};

export type Course = {
  id: number;
  emoji: Emoji;
  status: CurriculumElementStatus;
  title: string;
  readingTime: number;
  autoGeneratedReadingTime: number;
  minutesToReadFormatted: string;
  cover: string | null;
};

export type CourseWithSteps<T extends StepType = LimitedStep> = Course & { steps: T[] };

export type CourseType = Course | CourseWithSteps;

export type SimpleCourse = {
  id: number;
  title: string;
  emoji: string;
};

export type CourseWithStepsCount = Course & { stepsCount: number };
