import styled, { css } from 'styled-components';

import { getAddonTrainualPlusFeatureIconStyles } from '../../../../../../../lib/billing/getAddonTrainualPlusFeatureIconStyles';
import { AddonTrainualPlusFeatureKind } from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import P from '../../../../../../design_system/text/P';
import { mediaBreakpointPxXl } from '../../../../../../styled/Breakpoint';
import { fontMd3, fontSm2 } from '../../../../../../styled/TypeSystem';

export const FeatureSectionWrapper = styled.div`
  position: relative;
  max-width: 32.5rem;
  margin: 0 auto 6.8rem;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg1} 3.75rem`};

  &:nth-of-type(odd) {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  }

  &:nth-of-type(1) .feature-video {
    right: 28px;
    bottom: -78px;
  }

  &:nth-of-type(3) .feature-video {
    left: 28px;
    top: -86px;
  }

  &:nth-of-type(4) {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerLg3};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const FeatureIcon = styled.img<{ iconName: AddonTrainualPlusFeatureKind }>`
  position: absolute;
  ${({ iconName }) => getAddonTrainualPlusFeatureIconStyles(iconName)};
`;

export const delegationPlannerFeatureIconStyles = css`
  top: -36px;
  left: 26px;
`;

export const eSignatureFeatureIconStyles = css`
  top: 0;
  left: -4px;
  z-index: -1;

  @media (min-width: ${mediaBreakpointPxXl}) {
    left: 0;
  }
`;

export const trainingPathFeatureIconStyles = css`
  top: 14px;
  right: -24px;

  @media (min-width: ${mediaBreakpointPxXl}) {
    right: -46px;
  }
`;

export const multiLanguageFeatureIconStyles = css`
  top: -55px;
  left: -30px;
  z-index: -1;

  @media (min-width: ${mediaBreakpointPxXl}) {
    top: -18px;
    left: -50px;
  }
`;

export const customDomainsFeatureIconStyles = css`
  top: 4px;
  right: -26px;
`;

export const FeatureTitle = styled.h3`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
  text-align: center;
  ${fontMd3};

  .e-signature-feature-accent {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 3px;
      left: -8px;
      right: 1px;
      height: 3px;
      z-index: -1;
      transform: rotate(-2deg);
      border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
      background-color: ${({ theme: { vars } }) => vars.trainualBrandYellowMedium};
    }
  }

  .multi-language-feature-accent {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 10px;
      left: -3px;
      right: -4px;
      height: 12px;
      z-index: -1;
      transform: rotate(-1.294deg);
      border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
      background-color: ${({ theme: { vars } }) => vars.previewPurpleSecondary};
    }
  }
`;

export const FeatureDescription = styled(P)`
  text-align: center;
  margin: 0;
  ${fontSm2};
`;
