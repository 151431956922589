import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import ConfirmationModal from '../../../../../design_system/overlays/modals/ConfirmationModal';
import { routes } from '../../../../publicApplication/applicationRouter';

const t = initTranslations('e_signature.reached_e_signature_usage_limit_modal');

export type ReachedESignatureUsageLimitModalProps = {
  closeModal: () => void;
};

const ReachedESignatureUsageLimitModal = ({
  closeModal,
}: ReachedESignatureUsageLimitModalProps) => {
  const { slug } = useCurrentAccount();

  return (
    <ConfirmationModal
      actionFunction={() => routes.home({ slug }).push()}
      actionText={t('go_back')}
      desktopSize='md'
      heapModalName='reached-e-signature-usage-limit-modal'
      message={t('description')}
      onCloseRequest={closeModal}
      processing={false}
      secondaryButtonText={t('cancel')}
      title={t('title')}
    />
  );
};

export default ReachedESignatureUsageLimitModal;
