import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import LinkTabs, { LinkTab } from '../../../../design_system/navigation/LinkTabs';
import { routes } from '../../../publicApplication/applicationRouter';

const t = initTranslations('requests.navigation');

const RequestsTabs = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const sector = urlParams.get('sector') || undefined;
  const { slug } = useCurrentAccount();

  const Tab: LinkTab[] = [
    {
      name: t('active'),
      to: routes.requests({ slug, sector }),
      is_beta: false,
    },
    {
      name: t('archived'),
      to: routes.archivedRequests({ slug, sector }),
      is_beta: false,
    },
  ];

  return <LinkTabs id='requests-tabs' isUrlParams={!!sector} tabs={Tab} />;
};

export default RequestsTabs;
