import styled from 'styled-components';

import { mediaBreakpointPxLg, mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { fontMd1, fontSm4 } from '../../../styled/TypeSystem';
import RouterLink from '../../publicApplication/RouterLink';

export const ReportHeadLeftColumn = styled.div`
  display: flex;
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 50%;
    padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
  }
  @media (min-width: ${mediaBreakpointPxLg}) {
    width: 35%;
  }
`;

export const ReportHeadRightColumnWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 50%;
    margin-top: 0;
  }
  @media (min-width: ${mediaBreakpointPxLg}) {
    width: 65%;
  }
`;

export const ReportHeadInfo = styled.div`
  padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
  width: calc(100% - 2.5rem);
`;

export const ReportHeadTitle = styled.h4`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: 0;
  word-break: break-word;
  ${fontMd1};
`;

export const ReportHeadSubtitle = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm4};
`;

export const StyledRouterLink = styled(RouterLink)`
  color: inherit;
`;

export const StyledSpan = styled.span`
  font-style: italic;
  font-weight: ${({ theme: { constants } }) => constants.fontExtralight};
  ${fontSm4};
`;
