import styled, { css, keyframes } from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { fontMd3 } from '../../../../styled/TypeSystem';

export const AddIconWrapper = styled.button<{ isAnimated: boolean }>`
  ${({ isAnimated, theme: { constants, vars } }) => {
    const pulse = keyframes`
            0% {
                transform: scale(1);
                border-width: ${constants.borderWidthSm};
            }

            50% {
                transform: scale(1.2);
                border-width: ${constants.borderWidthLg};
            }

            100% {
                transform: scale(1);
                border-width: ${constants.borderWidthSm};
            }
        `;

    const animation = isAnimated
      ? css`
          animation: ${pulse} 1.3s infinite;
        `
      : 'animation: none';

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      border: ${constants.borderWidthSm} solid ${vars.accentPrimaryDefault};
      background-color: ${vars.foundationSurface1};
      border-radius: ${constants.borderRadiusCircle};
      height: ${constants.heightMd};
      width: 2.25rem;
      ${animation};

      &:hover {
        cursor: pointer;
        background-color: ${vars.accentSubdued1};
      }
    `;
  }}
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  pointer-events: none;
  ${fontMd3};
`;
