import React from 'react';
import { useTheme } from 'styled-components';

import {
  getPrimarySectorColor,
  getSecondarySectorColor,
} from '../../../../../../lib/document_import/getSectorSelectionColors';
import { sectorToAccountTerminology } from '../../../../../../types/Sector';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Icon from '../../../../../design_system/display/icons/Icon';
import { ButtonTextWrapper, IconWrapper, StyledDropdownMenuOption, Title } from './styles';
import { SectorSelectionDropdownMenuOptionProps } from './types';

const DropdownMenuOption = ({ sector, isSelected }: SectorSelectionDropdownMenuOptionProps) => {
  const terminology = useAccountTerminology();
  const { vars } = useTheme();

  return (
    <StyledDropdownMenuOption className={`${sector}-option`}>
      <IconWrapper isSelected={isSelected} sector={sector}>
        <Icon
          name='file-alt'
          primaryColor={getPrimarySectorColor(sector, vars)}
          secondaryColor={getSecondarySectorColor(sector, vars)}
          weight='duotone'
        />
      </IconWrapper>
      <ButtonTextWrapper>
        <Title isSelected={isSelected}>{sectorToAccountTerminology(sector, terminology)}</Title>
      </ButtonTextWrapper>
    </StyledDropdownMenuOption>
  );
};

export default DropdownMenuOption;
