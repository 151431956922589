import React, { ChangeEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import capitalize from '../../../../lib/capitalize';
import initTranslations from '../../../../lib/initTranslations';
import DeprecatedTextareaWithCharacterCount, {
  InputFontSize,
  getFontStyles,
} from '../../ToBeDeprecated/DeprecatedTextareaWithCharacterCount';

const ClickEditable = styled.div`
  cursor: text;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2} ${constants.spacerMd1}`};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid transparent;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  word-break: break-word;
  white-space: normal;
  z-index: 10;

  &:hover {
    border-color: ${({ theme: { vars } }) => vars.borderSurface2};
  }
`;

interface TextDisplay {
  attributeName: string;
  inputFontSize: InputFontSize;
  isPlaceholder: boolean;
  setEditState: () => void;
  text: string | undefined;
}

const StyledTextDisplay = styled.div<{
  isPlaceholder: boolean;
  inputFontSize: InputFontSize;
}>(
  ({ theme: { vars, constants }, isPlaceholder, inputFontSize }) => css`
    color: ${isPlaceholder ? vars.textPlaceholder : vars.textDefault};
    margin-top: ${constants.spacerMd1};
    ${getFontStyles(inputFontSize)}
  `
);

const TextDisplay = ({ inputFontSize, isPlaceholder, setEditState, text }: TextDisplay) => {
  return (
    <ClickEditable className='clickable-edit' onClick={setEditState}>
      <StyledTextDisplay inputFontSize={inputFontSize} isPlaceholder={isPlaceholder}>
        {text}
      </StyledTextDisplay>
    </ClickEditable>
  );
};

const t = initTranslations('groups');

export interface Props {
  attributeName: string;
  initialValue: string | undefined;
  label?: string;
  maxCharacters?: number;
  placeholder: string;
  resourceName: string;
  inputFontSize: InputFontSize;

  submitChanges(value: string | undefined): void;

  onFocus?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

const ClickInlineTextarea = ({
  attributeName,
  initialValue,
  label,
  maxCharacters,
  placeholder,
  resourceName,
  inputFontSize,
  submitChanges,
  onFocus,
  onBlur,
}: Props) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const characters = value ?? '';
  const charactersUsed = characters.length;

  const clearErrors = () => setErrorMessage('');

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    clearErrors();
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (maxCharacters && charactersUsed > maxCharacters) {
      setErrorMessage(
        t('inline_textarea.error_message_character_length', {
          resourceName: capitalize(resourceName),
          attributeName,
          maxCharacters,
        })
      );
    }
  }, [attributeName, resourceName, charactersUsed, maxCharacters]);

  const validateAndSubmit = async () => {
    if (!!errorMessage) return;

    setIsEditing(false);

    if (value === initialValue) return;

    submitChanges(value);
  };

  if (isEditing) {
    return (
      <DeprecatedTextareaWithCharacterCount
        attributeName={attributeName}
        id={`editable-${attributeName}-field`}
        inputFontSize={inputFontSize}
        label={label}
        maxCharacters={maxCharacters}
        noTopMargin
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        resourceName={resourceName}
        submitChanges={validateAndSubmit}
        value={value || ''}
      />
    );
  }

  return (
    <TextDisplay
      attributeName={attributeName}
      inputFontSize={inputFontSize}
      isPlaceholder={!initialValue || placeholder === initialValue}
      setEditState={() => setIsEditing(true)}
      text={initialValue || placeholder}
    />
  );
};

export default ClickInlineTextarea;
