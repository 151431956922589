import { CardType } from 'components/application/searches/SearchResultsPage/search_result_cards/SearchResultsCard/types';

import { FiltersCount } from '../../../../components/application/searches/SearchResultsPage/SearchResultsFilter/types';
import { Emoji } from '../../../../types/Emoji';
import SearchHistory from '../../../../types/SearchHistory';
import { Sector } from '../../../../types/Sector';

type SearchResultFilterTypes =
  | 'subjects'
  | 'topics'
  | 'steps'
  | 'tests'
  | 'test_question'
  | 'people'
  | 'groups';

type BaseSearchResult = {
  id: number;
  title: string;
  titleHighlighted: string;
  unpublished: boolean;
  type: CardType;
};

type BaseSearchResultCurriculum = {
  curriculumId: number;
  curriculumTitle: string;
  curriculumEmoji: string;
};

type SearchResultCurriculum = {
  emoji: Emoji;
  description: string;
  minutesToRead: number;
  ownerName: string;
} & BaseSearchResult;

type SearchResultCurriculumElement = {
  description: string;
  countOfElementsInside: number;
  minutesToRead: number;
  emoji: Emoji;
} & BaseSearchResult &
  BaseSearchResultCurriculum;

export type SearchResultStep = {
  courseId: number;
  courseTitle: string;
  content: string;
  plainTextContent: string | null;
  breadcrumbText: string;
  breadcrumbTitle: string;
  emoji: Emoji;
} & BaseSearchResult &
  BaseSearchResultCurriculum;

type SearchResultSurvey = {
  text: string;
  surveyId: number;
  surveyTitle: string;
} & BaseSearchResult &
  BaseSearchResultCurriculum;

type SearchResultGroup = {
  description: string;
  peopleAssigned: string;
} & Omit<BaseSearchResult, 'unpublished'>;

type SearchResultUser = {
  name: string;
  avatar: string | null;
  groups: { clickable: boolean; text: string };
  responsibilities: { clickable: boolean; text: string };
  description: string;
} & Omit<BaseSearchResult, 'unpublished' | 'title'>;

type GeneralSearchResult = SearchResultCurriculum &
  SearchResultCurriculumElement &
  SearchResultStep &
  SearchResultSurvey &
  SearchResultGroup &
  SearchResultUser;

type FilterProps = {
  all: number;
} & FiltersCount;

export type DefaultSearchResultsParams = {
  page: number;
  search: string;
};

export type SearchResultsParams = {
  filter: SearchResultFilterTypes[] | null;
} & DefaultSearchResultsParams;

type RequiredSearchResult = Pick<BaseSearchResult, 'id' | 'titleHighlighted' | 'type'>;
export type SearchPaginatedResult = Partial<GeneralSearchResult> & RequiredSearchResult;

export type HelpCenterSearchPaginatedResult = {
  title: string;
  summary: string;
  url: string;
};

type ResultCount =
  | {
      templatesResultCount: number;
      companyResultCount?: never;
    }
  | {
      templatesResultCount?: never;
      companyResultCount: number;
    };

type BaseSearchResultsResponse = {
  limitValue: number;
  totalPages: number;
  totalCount: number;
  searchHistory: SearchHistory;
};

export type SearchResultsResponse = {
  paginatedResult: SearchPaginatedResult[];
  filters?: FilterProps;
} & ResultCount &
  BaseSearchResultsResponse;

export type HelpCenterSearchResultsResponse = {
  paginatedResult: HelpCenterSearchPaginatedResult[];
} & BaseSearchResultsResponse;

export enum ContentType {
  Curriculum = 'Curriculum',
  Course = 'Course',
  Step = 'Step',
  Flowchart = 'Flowchart',
}

export type ContentSearchResultsParams = {
  search: string;
};

export type ContentSearchResults = {
  id: number;
  description: string | undefined;
  title: string;
  type: ContentType;
  webLink: string;
};

export type ContentSearchResultsResponse = {
  results: ContentSearchResults[];
};

export type QuickSearchResultsParams = {
  search: string;
};

type BaseQuickSearchResult = {
  emoji: Emoji;
} & Omit<BaseSearchResult, 'title' | 'unpublished'>;

export type CompanyQuickSearchResult = {
  avatar?: string | null;
  surveyId?: number;
  groupMembersCount?: number;
  parentElementName?: string;
  sector?: Sector;
} & BaseQuickSearchResult;

export type TemplatesQuickSearchResult = {
  author: string;
} & BaseQuickSearchResult;

export type QuickSearchResultsResponse = {
  companyResult: Array<CompanyQuickSearchResult>;
  templatesResult: Array<TemplatesQuickSearchResult>;
};
