import React from 'react';

import { ButtonTextWrapper, IconWrapper, StyledDropdownMenuOption, Title } from './styles';
import { SurveyQuestionDropdownMenuOption } from './types';

const DropdownMenuOption = ({ icon, title, optionType }: SurveyQuestionDropdownMenuOption) => {
  const isDeleteOption = optionType === 'destroy';

  return (
    <StyledDropdownMenuOption className={`${optionType}-option`}>
      <ButtonTextWrapper>
        <IconWrapper isDeleteOption={isDeleteOption}>{icon}</IconWrapper>
        <Title isDeleteOption={isDeleteOption}>{title}</Title>
      </ButtonTextWrapper>
    </StyledDropdownMenuOption>
  );
};

export default DropdownMenuOption;
