import { useFormikContext } from 'formik';
import React, { useReducer } from 'react';

import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import CollapsibleSurface from '../../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import { SettingsFormValues } from '../InterfaceAndTypes';
import {
  FieldDescription,
  FieldTitle,
  FormSectionContainer,
  FormSectionWrapper,
  SectionDivider,
} from '../Styles';

const t = initTranslations('settings');

const EmailSettings = () => {
  const ability = useCurrentUserAbilities();
  const canManageSettings = ability.can('read', 'ManageSettings');
  const { values, handleChange } = useFormikContext<SettingsFormValues>();
  const {
    curriculum: { singular: curriculumSingular },
    productTerm,
  } = useAccountTerminology();
  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);

  return (
    <CollapsibleSurface
      collapseDispatch={collapseDispatch}
      id='organization-profile-settings-surface'
      isCollapsed={collapsed}
      title={t('email.title')}
    >
      <FormSectionWrapper className='my-settings-email-section'>
        <FormSectionContainer>
          <FieldTitle>{t('email.setting_1.title')}</FieldTitle>
          <Checkbox
            checked={values.assignment_digest_emails}
            className='analytics-daily-update-check'
            id='daily_update'
            name='assignment_digest_emails'
            onCheck={handleChange}
          />
        </FormSectionContainer>
        <FormSectionContainer>
          <FieldDescription>
            {t('email.setting_1.description', { subject: curriculumSingular })}
          </FieldDescription>
        </FormSectionContainer>

        {canManageSettings && (
          <>
            <SectionDivider />
            <FormSectionContainer>
              <FieldTitle>{t('email.setting_2.title', { productTerm })}</FieldTitle>
              <Checkbox
                checked={values.digest_emails}
                className='analytics-trainual-recap-check'
                id='trainual_recap'
                name='digest_emails'
                onCheck={handleChange}
              />
            </FormSectionContainer>
            <FormSectionContainer>
              <FieldDescription>{t('email.setting_2.description')}</FieldDescription>
            </FormSectionContainer>
          </>
        )}
        <>
          <SectionDivider />
          <FormSectionContainer>
            <FieldTitle>{t('email.setting_3.title')}</FieldTitle>
            <Checkbox
              checked={values.incomplete_reminders_emails}
              className='analytics-trainual-incomplete-reminders-emails'
              id='incomplete_reminders_emails'
              name='incomplete_reminders_emails'
              onCheck={handleChange}
            />
          </FormSectionContainer>
          <FormSectionContainer>
            <FieldDescription>{t('email.setting_3.description')}</FieldDescription>
          </FormSectionContainer>
        </>
      </FormSectionWrapper>
    </CollapsibleSurface>
  );
};

export default EmailSettings;
