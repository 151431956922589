import styled from 'styled-components';

import { fontMd4, fontMd5 } from '../../../../styled/TypeSystem';

const IconWrapper = styled.div<{ isClickable?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  height: 3.5rem;
  width: 3.5rem;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

export const SurveyEmojiWrapper = styled(IconWrapper)`
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  height: 3.5rem;
  width: 3.5rem;
  ${fontMd4};
`;

export const Emoji = styled.span`
  ${fontMd5};
`;
