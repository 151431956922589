import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';
import { CurriculumsAssignmentsHead } from '../styles';
import CurriculumsAssignmentsLoadingSkeleton from './CurriculumAssignmentsLoadingSkeleton';

const TabsLoadingSkeleton = () => {
  const {
    constants: { spacerLg3, borderRadiusXs },
  } = useTheme();

  return (
    <>
      <CurriculumsAssignmentsHead>
        <SkeletonLoader borderRadius={borderRadiusXs} height={spacerLg3} />
      </CurriculumsAssignmentsHead>
      <CurriculumsAssignmentsLoadingSkeleton />
    </>
  );
};

export default TabsLoadingSkeleton;
