import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import AssistiveText from '../../../../../../design_system/core/AssistiveText';
import { ChooseOptionsFormTitle } from '../../../styles';
import { ResultComponentProps } from '../types';
import ChoiceElement from './ChoiceElement/ChoiceElement';

const t = initTranslations('survey_results');

const MultipleChoiceResult = ({ answeredQuestion }: ResultComponentProps) => {
  const { options, optionIds, optionId, correct: answerCorrect } = answeredQuestion;
  const selectedOptionIds: number[] = optionIds.length ? optionIds : optionId ? [optionId] : [];

  return (
    <>
      {!answerCorrect && (
        <AssistiveText
          id='incorrect-survey-answer-text-help'
          text={t('you_did_not_select_all_correct_options')}
          type='help'
        />
      )}
      <ChooseOptionsFormTitle>{t('multiple_choice_title')}</ChooseOptionsFormTitle>
      {options.map((option) => (
        <ChoiceElement
          correct={option.correct}
          iconType='checkbox'
          id={option.id}
          isSelected={selectedOptionIds.includes(option.id)}
          key={option.id}
          position={option.position}
          text={option.text}
        />
      ))}
    </>
  );
};

export default MultipleChoiceResult;
