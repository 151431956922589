import React from 'react';

import { useGroupsIndex } from '../../../../contexts/GroupsIndexContext';
import usePageTitle from '../../../../hooks/usePageTitle';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import PageContent from '../../shared/page_content';
import ChipsContainer from '../ChipsContainer/ChipsContainer';
import GroupsTable from '../GroupsTable';
import { GroupsIndexBanners } from './GroupsIndexBanners';
import Header from './Header/Header';
import TableActionsHeader from './TableActionsHeader';

const GroupsIndexView = () => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'groups', productTerm });
  const { groupsForIndexViewQueryError } = useGroupsIndex();

  return (
    <PageContent>
      <Header />
      {groupsForIndexViewQueryError ? (
        <BasicErrorDisplay error={groupsForIndexViewQueryError} />
      ) : (
        <>
          <ChipsContainer />
          <TableActionsHeader />
          <GroupsIndexBanners />
          <GroupsTable />
        </>
      )}
    </PageContent>
  );
};

export default GroupsIndexView;
