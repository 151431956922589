import _, { debounce } from 'lodash';

import { BoardColumn as TBoardColumn } from '../BoardColumn/types';
import {
  EDragElements,
  GetSourceDataProps,
  GetSourceDataReturn,
  HandleDragOverProps,
  HandleDragStartProps,
  MoveResponsibilityProps,
} from './types';

export const handleDragStart = ({
  event,
  setActiveColumn,
  setActiveResponsibility,
  setHolderHeight,
}: HandleDragStartProps) => {
  if (event.active.data.current?.type === EDragElements.Column) {
    setActiveColumn(event.active.data.current.column);

    return;
  }

  if (event.active.data.current?.type === EDragElements.Responsibility) {
    const { boardColumnResponsibility, holderHeight } = event.active.data.current;

    setActiveResponsibility(boardColumnResponsibility);
    setHolderHeight(holderHeight);

    return;
  }
};

// TODO: Refactor this function to get more performance and add ease animation

const findIndexes = (columns: TBoardColumn[], id: string | number) => {
  for (let ci = 0; ci < columns.length; ci++) {
    const ri = columns[ci].boardColumnResponsibilities.findIndex((r) => r.id === id);
    if (ri !== -1) return [ci, ri];
  }
  return [undefined, undefined] as unknown as [number, number];
};

const moveResponsibility = ({
  columns,
  fromColumnIndex,
  fromResponsibilityIndex,
  toColumnIndex,
  toResponsibilityIndex,
}: MoveResponsibilityProps) => {
  const [movedResponsibility] = columns[fromColumnIndex].boardColumnResponsibilities.splice(
    fromResponsibilityIndex,
    1
  );

  columns[toColumnIndex].boardColumnResponsibilities.splice(
    toResponsibilityIndex,
    0,
    movedResponsibility
  );
};

export const handleDragOver = debounce(
  ({ event, handleTempColumns, tempColumns }: HandleDragOverProps) => {
    const { active, over, delta } = event;
    if (!over || active.id === over.id) return;

    const isActiveAResponsibility = active.data.current?.type === EDragElements.Responsibility;
    const isOverAResponsibility = over.data.current?.type === EDragElements.Responsibility;
    const isOverAColumn = over.data.current?.type === EDragElements.Column;

    if (isOverAColumn && isActiveAResponsibility) {
      const isTargetListIntersected = over.data.current?.isTargetListIntersected;

      if (!isTargetListIntersected) {
        const targetListRef = over.data.current?.targetListRef;

        targetListRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }

    const columns = _.cloneDeep(tempColumns);

    const [activeColumnIndex, activeResponsibilityIndex] = findIndexes(columns, active.id);

    const [overColumnIndex, overResponsibilityIndex] = isOverAColumn
      ? [columns.findIndex((c) => c.id === over.id), 0]
      : findIndexes(columns, over.id);

    if (activeColumnIndex === undefined || (isOverAResponsibility && overColumnIndex === undefined))
      return tempColumns;
    if (
      activeColumnIndex === overColumnIndex &&
      activeResponsibilityIndex === overResponsibilityIndex
    )
      return tempColumns;

    if (isActiveAResponsibility && (isOverAColumn || isOverAResponsibility)) {
      if (isActiveAResponsibility && isOverAColumn && active.data.current?.columnId === over.id)
        return tempColumns;

      let insertIndex = overResponsibilityIndex;

      if (isActiveAResponsibility && isOverAColumn) {
        const overRect = over.rect;
        const activeOffsetTop = active.data.current?.offsetTop;
        const middleOfOverRect = overRect.top + overRect.height / 2;
        const isCursorNearTop = delta.y + activeOffsetTop < middleOfOverRect;

        insertIndex = isCursorNearTop
          ? 0
          : columns[overColumnIndex].boardColumnResponsibilities.length;
      }

      moveResponsibility({
        columns,
        fromColumnIndex: activeColumnIndex,
        fromResponsibilityIndex: activeResponsibilityIndex,
        toColumnIndex: overColumnIndex,
        toResponsibilityIndex: insertIndex,
      });
    }

    handleTempColumns(columns);
  },
  10
);

export const getSourceData = ({
  responsibilityId,
  boardColumns,
}: GetSourceDataProps): GetSourceDataReturn => {
  const sourceColumn = boardColumns.find((column) =>
    column.boardColumnResponsibilities.some(
      (responsibility) => responsibility.id === responsibilityId
    )
  );
  const sourceColumnId = sourceColumn?.id;
  const sourceResponsibility = sourceColumn?.boardColumnResponsibilities.find(
    (responsibility) => responsibility.id === responsibilityId
  );
  const sourceResponsibilityIndex =
    sourceColumn?.boardColumnResponsibilities.findIndex(
      (responsibility) => responsibility.id === responsibilityId
    ) || 0;

  return { sourceColumnId, sourceColumn, sourceResponsibility, sourceResponsibilityIndex };
};
