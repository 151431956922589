import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontSm5 } from '../../../../styled/TypeSystem';

export const CustomizeThemeStepContainer = styled.div`
  max-width: 20rem;

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-top: 4rem;
  }
`;

export const CustomizeThemeTitle = styled.h3`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  ${fontSm5};
`;

export const CustomizeThemeDescription = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};

  ${fontSm5};
`;

export const ButtonsContainer = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg3};

  button {
    margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;
