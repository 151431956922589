import React from 'react';
import { MenuListProps, components } from 'react-select';

import { useGroupsModals } from '../../../../../contexts/GroupsModalContext';
import { useOrgChartConfigSlideout } from '../../../../../contexts/OrgChartConfigSlideoutContext';
import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import { GroupKindValue } from '../../../ContentLibrary/ContentLibraryPage/libraryReducer';
import { StyledCreateResourceDropdownButton, StyledCreateResourceLabel } from './styles';

const MenuListButton = () => {
  const { orgChartResourceType, isGroupType } = useOrgChartConfigSlideout();
  const t = initTranslations(`org_chart.${orgChartResourceType}_config_slideout.set_reports_to`);
  const { setTeammateModalVisible } = useTeammateModal();
  const { dispatch } = useGroupsModals();

  return (
    <StyledCreateResourceDropdownButton
      id={`create-${orgChartResourceType}-dropdown-button`}
      onClick={() => {
        isGroupType
          ? dispatch({ type: 'openGroupNameModal', payload: GroupKindValue.Role })
          : setTeammateModalVisible(true);
      }}
    >
      <Icon name='plus' />
      <StyledCreateResourceLabel>{t('create_new')}</StyledCreateResourceLabel>
    </StyledCreateResourceDropdownButton>
  );
};

export const MenuListButtonCreateNew = (props: MenuListProps) => (
  <components.MenuList {...props}>
    <MenuListButton />
    {props.children}
  </components.MenuList>
);
