import styled, { css } from 'styled-components';

import Badge from '../../../../../design_system/display/badge';
import Icon from '../../../../../design_system/display/icons/Icon';
import RawHtml from '../../../../../design_system/RawHtml';
import {
  MultilineTruncatedText,
  TruncatedText,
} from '../../../../../design_system/Triage/TruncatedText';
import { fontMd1, fontSm4 } from '../../../../../styled/TypeSystem';

const RIGHT_ARROW_ICON_WIDTH = '1.25rem';

export const searchResultsCardLink = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.45s ease-in-out;

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
    border-color: ${({ theme: { vars } }) => vars.accentSubdued4};
  }
`;

export const SearchResultsCardEmojiWrapper = styled.div<{ isHovered: boolean; isCircle: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
  width: 2.5rem;
  height: ${({ theme: { constants } }) => constants.heightLg};
  background: ${({ isHovered, theme: { vars } }) =>
    isHovered ? vars.accentSubdued2 : vars.foundationBase1};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ isHovered, theme: { vars } }) => (isHovered ? vars.accentSubdued2 : vars.borderSurface1)};
  border-radius: ${({ isCircle, theme: { constants } }) =>
    isCircle ? constants.borderRadiusCircle : constants.borderRadiusLg};
  transition: inherit;
`;

export const SearchResultsCardDetailsWrapper = styled.div<{ isHovered: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme: { constants } }) =>
    `calc(100% - ${constants.spacerLg1} - ${RIGHT_ARROW_ICON_WIDTH} - 3rem - ${constants.spacerMd2})`};
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-right: ${({ theme: { constants } }) => constants.spacerLg1};
  color: ${({ isHovered, theme: { vars } }) => (isHovered ? vars.textDefault : vars.textSubdued)};
  transition: color 0.45s ease-in-out;
  ${fontSm4};
`;

export const SearchResultsCardTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const helpCenterSearchResultHighlightedStyles = css`
  .hs-search-highlight {
    font-weight: ${({ theme: { constants } }) => constants.fontBold};
  }
`;

export const SearchResultsCardTitle = styled.h4<{ isSurveyQuestion: boolean }>`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerSm3} 0 0`};
  ${({ isSurveyQuestion }) =>
    isSurveyQuestion ? MultilineTruncatedText({ lineClamp: 4 }) : TruncatedText({})};
  line-height: 1.5rem;
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${helpCenterSearchResultHighlightedStyles};
  ${fontMd1};
`;

const inheritTextStyles = css`
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
`;

export const SearchResultsCardDescription = styled(RawHtml)`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  ${inheritTextStyles};
  ${helpCenterSearchResultHighlightedStyles};
  ${MultilineTruncatedText({})};
`;

export const SearchResultsRightArrowIcon = styled(Icon)<{ isHovered: boolean }>`
  width: ${RIGHT_ARROW_ICON_WIDTH};
  margin-left: auto;
  opacity: ${({ isHovered }) => (isHovered ? 1 : 0)};
  color: ${({ isHovered, theme: { vars } }) => (isHovered ? vars.accentPrimaryHover : 'inherit')};
  transition: inherit;
`;

export const SearchResultsCardBadge = styled(Badge)<{ isHovered: boolean }>`
  cursor: pointer;
  flex-shrink: 0;
  ${({ isHovered, theme: { vars } }) => isHovered && `background: ${vars.accentSubdued2};`}
  transition: background 0.45s ease-in-out;
`;
