import { FormikErrors, useFormik } from 'formik';
import React, { ReactElement, useCallback } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { ReduxResult } from '../../../../../../types/Redux';
import TaskModal, { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';
import InputField from '../../../../../design_system/Triage/InputField';

const InputWrapper = styled.div`
  width: 100%;
`;

const t = initTranslations('duplicate_modal');

export interface Props extends FormValues {
  closeRequest: () => void;
  duplicateElement: ({ id, title }: FormValues) => void;
  result: ReduxResult;
  elementType: string;
}

interface FormValues {
  id: number;
  title: string;
}

const DuplicateCurriculumElementModal = ({
  closeRequest,
  id,
  title,
  duplicateElement,
  result,
  elementType,
}: Props): ReactElement => {
  const validateForm = useCallback((values: FormValues) => {
    const { title } = values;
    const errors: FormikErrors<FormValues> = {};
    if (!title || title.trim() === '') {
      errors.title = t('blank_title_error');
    }
    return errors;
  }, []);

  const submitForm = useCallback((formValues) => duplicateElement(formValues), [duplicateElement]);
  const formik = useFormik({
    initialValues: {
      id,
      title: `${title} Duplicate`,
    },
    validate: (values: FormValues) => validateForm(values),
    onSubmit: (values: FormValues) => submitForm(values),
  });

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('error_flash', { title }),
    successFunction: () => {
      result.reset();
      closeRequest();
    },
    successMessage: t('success_flash'),
  });

  const taskModalArgs: TaskModalProps = {
    title: t('duplicate_element', { element: elementType }),
    onCloseRequest: closeRequest,
    heapModalName: 'duplicate-modal',
    primaryButtonText: t('save'),
    primaryButtonTask: () => formik.handleSubmit(),
    processing: result.isLoading,
    isDisabled: result.isLoading,
    secondaryButtonText: t('cancel'),
  };

  return (
    <TaskModal {...taskModalArgs}>
      <InputWrapper>
        <InputField
          errorText={formik.touched.title && formik.errors.title}
          id='element-title'
          label={t('title_label', { element: elementType })}
          name='title'
          onChange={formik.handleChange}
          value={formik.values.title}
        />
      </InputWrapper>
    </TaskModal>
  );
};

export default DuplicateCurriculumElementModal;
