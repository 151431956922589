import React from 'react';

import { usePlanBrandColors } from '../../../../../../hooks/billing/usePlanBrandColors';
import { usePricePerInterval } from '../../../../../../hooks/billing/usePricePerInterval';
import { couponToDiscount } from '../../../../../../lib/billing/stripeCouponToDiscount';
import { getFormattedPricePerUser } from '../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../../types/BillingInterval';
import { BillingPlanName } from '../../../../../../types/BillingPlanName';
import { Coupon } from '../../../../../../types/Coupon';
import RadioField from '../../../../../design_system/Triage/RadioField';
import {
  PlanCardBillingCycleText,
  PlanCardDivider,
  PlanCardFooterWrapper,
  PlanCardPrice,
  PlanCardSeatsIncludedText,
} from '../PlanCards/styles';
import NewPlanCardTitle from './NewPlanCardTitle/NewPlanCardTitle';
import { PlanCardRadioFieldWrapper, StyledPlanCardWrapper } from './styles';

const t = initTranslations('billing.full_screen_overlay_content.new_plan_card');

type PlanCardProps = {
  setSelectedPlanName: React.Dispatch<React.SetStateAction<BillingPlanName>>;
  coupon: Coupon;
  selectedPlanName: BillingPlanName;
  billingPlanList: BillingPlanName[];
  newPlanName: BillingPlanName;
  seatsIncluded?: number;
  billingInterval: BillingInterval;
  totalPrice: number;
  id: string;
  employeesSize?: string;
  isAnnualOnlyPlan?: boolean;
};

const NewPlanCard = ({
  id,
  newPlanName,
  seatsIncluded,
  billingInterval,
  totalPrice,
  coupon,
  setSelectedPlanName,
  selectedPlanName,
  billingPlanList,
  employeesSize,
  isAnnualOnlyPlan,
}: PlanCardProps) => {
  const discount = couponToDiscount(coupon);
  const isAnnualInterval = billingInterval === 'year';
  const isHoldPlan = newPlanName === 'hold';
  const isCustomPlan = newPlanName === 'custom';
  const isFree3SeatsPlan = newPlanName === 'free';
  const { color: selectedPlanCardDividerColor } = usePlanBrandColors({ name: selectedPlanName });
  const pricePerUser =
    seatsIncluded &&
    getFormattedPricePerUser(totalPrice, seatsIncluded, isAnnualInterval, discount);
  const pricePerInterval = usePricePerInterval({
    isAnnualInterval,
    isHoldPlan,
    isFree3SeatsPlan,
    totalPrice,
    discount,
  });

  const getPlanCardTitle = (planName: BillingPlanName) => {
    return <NewPlanCardTitle isSelected={newPlanName == planName} planName={planName} />;
  };

  const getRadioFieldOptions = () => {
    return billingPlanList.map((planName: BillingPlanName) => ({
      component: getPlanCardTitle(planName),
      value: planName,
    }));
  };

  const handlePlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlanName(e.target.value as BillingPlanName);
  };

  return (
    <StyledPlanCardWrapper id={id}>
      <PlanCardRadioFieldWrapper>
        <RadioField
          isCustomRadioField
          onChange={handlePlanChange}
          options={getRadioFieldOptions()}
          orientation='horizontal'
          value={newPlanName}
        />
      </PlanCardRadioFieldWrapper>
      {isAnnualOnlyPlan ? (
        <>
          <PlanCardPrice text={t('get_a_quote')} />
          <PlanCardSeatsIncludedText
            text={
              isCustomPlan && employeesSize
                ? t('employees_size', { count: employeesSize })
                : t('unlimited_employees')
            }
          />
        </>
      ) : (
        <>
          {pricePerUser && (
            <PlanCardPrice
              text={t('price_per_user_per_month', {
                price: pricePerUser,
              })}
            />
          )}
          {employeesSize && (
            <PlanCardSeatsIncludedText text={t('employees_size', { count: employeesSize })} />
          )}
          {seatsIncluded && (
            <PlanCardSeatsIncludedText text={t('seats_included', { count: seatsIncluded })} />
          )}
        </>
      )}
      <PlanCardBillingCycleText
        text={t('billing_cycle', {
          interval: isAnnualInterval ? t('yearly') : t('monthly'),
        })}
      />
      <PlanCardFooterWrapper>
        <PlanCardDivider planBrandColor={selectedPlanCardDividerColor} />
        <PlanCardPrice text={isAnnualOnlyPlan ? t('talk_to_sales') : pricePerInterval} />
      </PlanCardFooterWrapper>
    </StyledPlanCardWrapper>
  );
};

export default NewPlanCard;
