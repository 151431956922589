import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../Breakpoint';

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SurfaceTitle = styled.h6`
  font-weight: normal;
  overflow: hidden;
  width: auto;
  margin-bottom: 0;
  font-size: 0.875rem;

  @media (min-width: ${mediaBreakpointPxSm}) {
    font-size: 1.125rem;
  }
`;
