import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useEditorToolbarContext } from '../../../../../contexts/EditorToolbarContext';
import initTranslations from '../../../../../lib/initTranslations';
import {
  usePostStepAttachmentMutation,
  usePostSurveyQuestionAttachmentMutation,
} from '../../../../../redux/services/axiosService';
import AssistiveText from '../../../../design_system/core/AssistiveText';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import FileDownload from '../../components/FileDownload/FileDownload';
import FileInputDropzone from '../../components/FileInputDropzone/FileInputDropzone';
import { insertEditorContent } from '../../lib/insertEditorContent';
import { SUPPORTED_FILE_TYPES } from '../../shared/constants/editor';
import { EmbedExtensionName } from '../../tiptap_extensions/types';
import FileToolbarButton from '../../toolbar/buttons/components/insert/FileToolbarButton';
import { EmbedlyFlyoutId } from '../Embedly/EmbedlyFlyout/EmbedlyFlyout';
import { EditorPluginProps } from '../types';
import FileFlyoutIframe from './FileFlyoutIframe/FileFlyoutIframe';
import { StyledFlyout } from './styles';
import { processFile, setFormData } from './utils';

const t = initTranslations('editor.file');

const FilePreviewWrapper = styled.div`
  display: flex;
  place-content: center center;
  height: 100%;
  flex-wrap: wrap;
`;

const InnerPreview = styled.div`
  width: 21.875rem;
`;

const FileFlyout = ({
  editor: passedEditor,
  simpleUpload = false,
  popupPlacement = 'bottom-start',
}: EditorPluginProps) => {
  const { editor: contextEditor } = useEditorContext();
  const editor = passedEditor || contextEditor;
  const [fileError, setFileError] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const menuId = 'file-flyout';
  const { isMenuOpen, setActiveMenuId, closeMenu } = useActiveMenuHandler({ menuId });
  const { selectedEmbedMenuItem, setSelectedEmbedMenuItem } = useEditorToolbarContext();
  const [uploadFile, uploadResult] = usePostStepAttachmentMutation();
  const [uploadSurveyQuestionAttachment, surveyQuestionAttachmentResult] =
    usePostSurveyQuestionAttachmentMutation();
  const { isLoading, isSuccess, reset } = simpleUpload
    ? surveyQuestionAttachmentResult
    : uploadResult;

  const onFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];

        await processFile({ file, setFile, setFileError, selectedEmbedMenuItem });
      }
    },
    [selectedEmbedMenuItem]
  );

  const closeFileFlyout = useCallback(() => {
    setFile(null);
    setSelectedEmbedMenuItem(undefined);
    setFileError('');
    closeMenu();
  }, [closeMenu, setSelectedEmbedMenuItem]);

  const submitFile = useCallback(async () => {
    if (file) {
      const formData = await setFormData(file);

      if (simpleUpload) {
        uploadSurveyQuestionAttachment(formData);
      } else {
        uploadFile(formData);
      }

      closeFileFlyout();
    }
  }, [file, closeFileFlyout, uploadFile, uploadSurveyQuestionAttachment, simpleUpload]);

  useEffect(() => {
    const resultToUse = simpleUpload ? surveyQuestionAttachmentResult : uploadResult;

    if (isSuccess && resultToUse.data) {
      const {
        data: { fileName, fileLink },
      } = resultToUse;

      insertEditorContent({
        editor,
        content: {
          type: EmbedExtensionName.FILE,
          attrs: {
            contentUrl: fileLink,
            fileName,
          },
        },
      });

      // Given the way our Toolbar is setup, we need to reset the redux state here or else the file will
      // be inserted again in another step
      reset();
    }
  }, [editor, simpleUpload, isSuccess, surveyQuestionAttachmentResult, uploadResult, reset]);

  const navigateBackToEmbedlyMenu = useCallback(() => {
    setActiveMenuId(selectedEmbedMenuItem ? EmbedlyFlyoutId : null);
  }, [selectedEmbedMenuItem, setActiveMenuId]);

  if (selectedEmbedMenuItem === 'file') {
    return (
      <FileFlyoutIframe
        closeFileFlyout={closeFileFlyout}
        file={file}
        fileError={fileError}
        navigateBackToEmbedlyMenu={navigateBackToEmbedlyMenu}
        onFileChange={onFileChange}
      />
    );
  }

  return (
    <StyledFlyout
      className='file-flyout'
      id={menuId}
      loading={isLoading}
      onClose={closeFileFlyout}
      placement={popupPlacement}
      primaryButtonDisabled={!file || !!fileError || isLoading}
      primaryButtonTask={submitFile}
      primaryButtonText={t('primary_button')}
      secondaryButtonTask={() => {
        closeFileFlyout();
        navigateBackToEmbedlyMenu();
      }}
      secondaryButtonText={selectedEmbedMenuItem ? t('back') : t('cancel')}
      title={selectedEmbedMenuItem ? t('add_pdf') : t('title')}
      triggerButton={<FileToolbarButton active={isMenuOpen} />}
    >
      {file ? (
        <FilePreviewWrapper>
          <InnerPreview>
            <FileDownload fileName={file.name} isPreview />
          </InnerPreview>
        </FilePreviewWrapper>
      ) : (
        <>
          <FileInputDropzone
            acceptedFiles={SUPPORTED_FILE_TYPES.join(',')}
            ariaLabel={t('aria_label_file_dropzone')}
            label={t('label')}
            onFileChange={onFileChange}
          />
          {fileError ? (
            <AssistiveText id='file-upload-error-text' text={fileError} type='error' />
          ) : (
            <AssistiveText id='file-upload-assistive-text' text={t('info_text')} />
          )}
        </>
      )}
    </StyledFlyout>
  );
};

export default FileFlyout;
