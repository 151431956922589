import { useSortable } from '@dnd-kit/sortable';
import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { StepLink } from '../../editor/shared/styles';
import { routes } from '../../publicApplication/applicationRouter';
import { DragStyle } from '../../shared/DragAndDrop/styles';
import { QuestionText } from '../shared/SurveyQuestionsList/styles';
import { IndexText, SurveyQuestionListItemWrapper } from './styles';

interface Props {
  surveyId: number;
  index: number;
  questionText: string;
  questionId: number;
  isActive: boolean;
}

const SurveyQuestionListItem = ({ surveyId, questionId, questionText, index, isActive }: Props) => {
  const { slug } = useCurrentAccount();

  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id: questionId,
  });

  return (
    <div
      ref={setNodeRef}
      style={DragStyle(transition, transform, isDragging)}
      {...listeners}
      {...attributes}
    >
      <StepLink
        id={`survey-question-${questionId}-title`}
        isActive={isActive}
        isEditable
        onClick={() => routes.surveyQuestionEditor({ id: questionId, surveyId, slug }).push()}
      >
        <SurveyQuestionListItemWrapper>
          <IndexText>{index + 1}</IndexText>
          <QuestionText isActive={isActive} lineClamp={2}>
            {questionText}
          </QuestionText>
        </SurveyQuestionListItemWrapper>
      </StepLink>
    </div>
  );
};

export default SurveyQuestionListItem;
