type ModalState = {
  editModal: boolean;
  shareModal: boolean;
  exportModal: boolean;
};

type Action =
  | { type: 'edit'; showEdit: boolean }
  | { type: 'export'; showExport: boolean }
  | { type: 'share'; showShare: boolean };

const menuReducer = (state: ModalState, action: Action): ModalState => {
  switch (action.type) {
    case 'edit': {
      return { ...state, editModal: action.showEdit };
    }
    case 'export': {
      return { ...state, exportModal: action.showExport };
    }
    case 'share': {
      return { ...state, shareModal: action.showShare };
    }
  }
};

const initialState = {
  editModal: false,
  shareModal: false,
  exportModal: false,
};

export { menuReducer, initialState };
