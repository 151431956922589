import styled from 'styled-components';

import { mediaBreakpointPxLg } from '../../../styled/Breakpoint';
import Scrollbar from '../../../styled/Scrollbar';

export const TEMPLATE_PREVIEW_TOP_NAV_BAR_HEIGHT = '4rem';
export const TEMPLATE_PREVIEW_INFO_PANEL_DESKTOP_WIDTH = '27rem';

export const TemplatePreviewOverlay = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const TemplatePreviewPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-right: ${({ theme: { constants, vars } }) =>
    `${vars.borderSurface1} solid ${constants.borderWidthSm}`};
  overflow: auto;
  ${Scrollbar};

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: ${TEMPLATE_PREVIEW_INFO_PANEL_DESKTOP_WIDTH};
  }
`;

export const TemplatePreviewContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - ${TEMPLATE_PREVIEW_TOP_NAV_BAR_HEIGHT});
`;

export const TemplatePreviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
`;
