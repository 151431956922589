import { useState } from 'react';

import { useCloseFullScreenOverlay } from '../../../../../../hooks/billing/useCloseFullScreenOverlay';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { useSeatsOptions } from '../../../../../../hooks/useSeatsOptions';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useFetchUpcomingInvoiceDataQuery } from '../../../../../../redux/services/resourceApis/billing/billingApi';
import { FetchUpcomingInvoiceDataResponse } from '../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import { FullScreenOverlayRoute } from '../../../../../../types/FullScreenOverlay';

type FullScreenOverlayProps = {
  route: FullScreenOverlayRoute;
  isStorybookEnvironment?: boolean;
  mockData: FetchUpcomingInvoiceDataResponse | undefined;
};

export const useFullScreenOverlay = ({
  route,
  isStorybookEnvironment,
  mockData,
}: FullScreenOverlayProps) => {
  const { plan, interval, quantity } = route.params;
  const { totalUserSlotsCount, usedUserSlotsCount, slug, status } = useCurrentAccount();
  const closeFullScreenOverlay = useCloseFullScreenOverlay();
  const isPastDue = status === 'past_due';
  const isTrialingOrTrialEnded = status === 'trialing' || status === 'trial_ended';
  const [selectedBillingInterval, setSelectedBillingInterval] = useState(
    isTrialingOrTrialEnded && !interval ? 'year' : interval
  );
  const [selectedSeatsCount, setSelectedSeatsCount] = useState(quantity);
  const [selectedPlanName, setSelectedPlanName] = useState(plan);
  const [appliedPromoCode, setAppliedPromoCode] = useState('');
  const showSeatsDecreasingError = selectedSeatsCount < usedUserSlotsCount;
  const upcomingInvoiceResult = useFetchUpcomingInvoiceDataQuery(
    {
      plan: selectedPlanName,
      interval: selectedBillingInterval,
      quantity: selectedSeatsCount,
      coupon: appliedPromoCode,
    },
    { skip: isStorybookEnvironment }
  );
  const upcomingInvoiceData = isStorybookEnvironment ? mockData : upcomingInvoiceResult.data;
  const [range, options] = useSeatsOptions(upcomingInvoiceData?.newPlan.name || 'scale', status);
  const { isLoading, isFetching, error } = upcomingInvoiceResult;
  const isLoadingOrFetching = isLoading || isFetching;
  const isAnnualInterval = selectedBillingInterval === 'year';
  const seatsCount = range.some((v) => v === String(selectedSeatsCount))
    ? String(selectedSeatsCount)
    : range[0];
  const isMaxSeatsCount = seatsCount === range[range.length - 1];
  const showDiscounts = !isMaxSeatsCount && (upcomingInvoiceData?.coupon || isAnnualInterval);

  useDisplayFlashOnResponse({
    result: upcomingInvoiceResult,
    errorMessage: messageFromError(error)?.join(', '),
    errorFunction: closeFullScreenOverlay,
  });

  return {
    upcomingInvoiceData,
    slug,
    totalUserSlotsCount,
    seatsCount,
    showDiscounts,
    seatsOptions: options,
    showSeatsDecreasingError,
    isAnnualInterval,
    isLoadingOrFetching,
    isMaxSeatsCount,
    isPastDue,
    selectedBillingInterval,
    setSelectedBillingInterval,
    selectedSeatsCount,
    setSelectedSeatsCount,
    selectedPlanName,
    setSelectedPlanName,
    setAppliedPromoCode,
    appliedPromoCode,
  };
};
