import React from 'react';

import { useGetElementColumns } from '../../../../../hooks/element_report/useGetElementColumns';
import { HeaderRow } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { SortProps } from '../../TableTypes';
import ElementReportTableHeaderCell from './ElementReportTableHeaderCell';
import { TableColumn, TableHeaderProps } from './types';

const ElementReportTableHeader = ({
  tableHeaderState,
  dispatch,
  signatureEnabled,
}: TableHeaderProps) => {
  const columns = useGetElementColumns(signatureEnabled);
  return (
    <thead>
      <HeaderRow>
        {columns.map((column: TableColumn) => {
          const activeColumn = tableHeaderState.sortCol === column.columnName;
          let sortProps: SortProps = { isSortable: column.sortable };

          if (column.sortable) {
            const sortIcon = activeColumn ? tableHeaderState.sortIcon : 'sort';
            sortProps = { isSortable: true, sortIcon };
          }

          return (
            <ElementReportTableHeaderCell
              activeHeader={activeColumn}
              column={column}
              key={`header-${column.columnName}`}
              setActiveHeader={() => {
                if (column.sortable) {
                  dispatch({ type: 'sortColumnChange', sortCol: column.columnName });
                }
              }}
              {...sortProps}
            />
          );
        })}
      </HeaderRow>
    </thead>
  );
};

export default ElementReportTableHeader;
