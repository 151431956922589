import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useEffect, useMemo, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateFlowchartMutation } from '../../../../../../redux/services/resourceApis/flowcharts/flowchartsApi';
import { MetaUserAccess } from '../../../../../../types/Curriculum';
import { EditableCurriculumElement } from '../../../../../../types/CurriculumElement';
import { Emoji } from '../../../../../../types/Emoji';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { EmojiWrapper } from '../../../../curriculums/shared/CurriculumElementRowStyles';
import {
  RowActionsContainer,
  RowCardBody,
  RowContainer,
} from '../../../../curriculums/shared/CurriculumRowStyles';
import { routes } from '../../../../publicApplication/applicationRouter';
import StatusBadgeActions from '../../../../shared/CurriculumElements/CurriculumElementActionRow/StatusBadgeActions';
import EditableCurriculumElementTitle from '../../../../shared/CurriculumElements/EditableCurriculumElementTitle';
import CurriculumElementThreeDot from '../../../../shared/CurriculumElementThreeDot/CurriculumElementThreeDot';
import { viewableUserAccess } from '../../../../shared/helpers';
import EmojiSelect from '../../../components/SubjectEmoji/EmojiSelect';

export type Props = {
  curriculumId: number;
  curriculumElement: EditableCurriculumElement;
  emoji: Emoji;
  isLocked?: boolean;
  userAccess?: MetaUserAccess;
  blockEditPrivileges: boolean;
  provided: DraggableProvided;
};

const t = initTranslations('curriculums_view');

const FlowchartTableRow = ({
  curriculumId,
  curriculumElement,
  isLocked,
  emoji,
  userAccess,
  blockEditPrivileges,
  provided,
}: Props) => {
  const { slug } = useCurrentAccount();
  const [isEditing, setIsEditing] = useState(false);
  const { elementId: flowchartId, element } = curriculumElement;
  const [updateFlowchart, { isSuccess, error }] = useUpdateFlowchartMutation();
  const { status, title } = element;

  const { flash } = useFlashNotification();

  useEffect(() => {
    if (error) {
      flash('error', t('update_title_error'));
    }
  }, [error, flash]);

  const flowchartUrl = useMemo(() => {
    if (!userAccess || userAccess === 'pending') {
      return routes.curriculumRequestAccess({ id: curriculumId, slug }).href;
    } else if (viewableUserAccess(userAccess)) {
      return routes.flowchartConsume({ slug, id: flowchartId }).href;
    } else {
      return routes.flowchartEditor({ slug, id: flowchartId }).href;
    }
  }, [flowchartId, curriculumId, slug, userAccess]);

  return (
    <RowContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      id={`curriculum-element-flowchart-${flowchartId}`}
    >
      <RowCardBody isLocked={isLocked || blockEditPrivileges}>
        <EmojiWrapper id={`flowchart-${flowchartId}-row-emoji-picker`}>
          <EmojiSelect
            clearEmoji={() => {
              updateFlowchart({ id: flowchartId, emoji: null });
            }}
            emoji={emoji}
            noEmojiIconName='shapes'
            onEmojiSelect={(emoji: BaseEmoji) => {
              updateFlowchart({ emoji: emoji.native, id: flowchartId });
            }}
            usePortal
          />
        </EmojiWrapper>
        <EditableCurriculumElementTitle
          editable={!blockEditPrivileges}
          id={flowchartId}
          isEditing={isEditing}
          isLocked={isLocked}
          isSuccess={isSuccess}
          route={flowchartUrl}
          setIsEditing={setIsEditing}
          title={title}
          updateElement={({ id, title }) => updateFlowchart({ id, title })}
        />
        <RowActionsContainer>
          <StatusBadgeActions
            curriculumElement={{ id: curriculumElement.id, status }}
            curriculumId={curriculumId}
          />
          {!blockEditPrivileges && (
            <CurriculumElementThreeDot
              canBeModified
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              eSignatureDisplayedAndRequired={false}
              isCurriculumLocked={isLocked}
              renameClickHandler={() => setIsEditing(true)}
            />
          )}
        </RowActionsContainer>
      </RowCardBody>
    </RowContainer>
  );
};

export default FlowchartTableRow;
