import { useSortable } from '@dnd-kit/sortable';
import React, { memo, useCallback, useEffect, useState } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import { setSelectedContentType } from '../../../../../redux/domains/curriculums/curriculumsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useCreateElementMutation } from '../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { ElementKind } from '../../../../../types/CurriculumElement';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { ActionRowWrapper } from '../../../curriculums/shared/CurriculumRowStyles';
import { useRouterHelper } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import VideoModal from '../../../videos/VideoModal/VideoModal';
import ActionRow from '../../ActionRow/ActionRow';
import DescriptiveDropdownOption from './CurriculumElementDropdownOption/DescriptiveDropdownOption';
import { ActionRowProps, DropdownElementKind, DropdownText, dropdownElementKinds } from './types';

const t = initTranslations('curriculum_outline_view');

export const determineText = (
  topicTermSingular: string,
  stepTermPlural: string,
  subjectTermSingular: string
): DropdownText => {
  return {
    course: {
      iconName: 'file-lines',
      title: topicTermSingular,
      description: t('curriculum_element_dropdown.course.description', {
        subject: subjectTermSingular.toLowerCase(),
        steps: stepTermPlural.toLowerCase(),
      }),
    },
    survey: {
      iconName: 'clipboard-check',
      title: t('curriculum_element_dropdown.survey.title'),
      description: t('curriculum_element_dropdown.survey.description', {
        subject: subjectTermSingular.toLowerCase(),
      }),
    },
    flowchart: {
      iconName: 'shapes',
      title: t('curriculum_element_dropdown.flowchart.title'),
      description: t('curriculum_element_dropdown.flowchart.description'),
    },
    video: {
      iconName: 'video',
      title: t('curriculum_element_dropdown.video.title'),
      description: t('curriculum_element_dropdown.video.description'),
    },
  };
};

const CurriculumElementActionRow = memo((props: ActionRowProps) => {
  const { autoFocus, curriculumId } = props;
  const {
    accountPlan,
    splitFeatures: { contentFlowchartsEnabled, videoContentTypeEnabled },
  } = useCurrentAccount();
  const routeState = useRouterHelper();
  const isCurriculumAdminRoute =
    routeState?.route.name == 'curriculumAdmin' || routeState?.route.name == 'curriculumEdit';

  const {
    step: { plural: stepTermPlural },
    curriculum: { singular: subjectTermSingular },
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const dispatch = useAppDispatch();
  const selectedContentType = useAppSelector((state) => state.curriculums.selectedContentType);
  const elementAttributes = useCurriculumElement();

  const [showVideoModal, setShowVideoModal] = useState(false);

  const dropdownText = determineText(topicTermSingular, stepTermPlural, subjectTermSingular);
  const filteredDropdownOptions = dropdownElementKinds.filter((kind) => showElementKind(kind));
  const selectedDropdownOption = filteredDropdownOptions[selectedContentType];
  const selectedDropdownOptionTitle = dropdownText[selectedDropdownOption].title;
  const setContentType = useCallback(
    (selected: number) => {
      dispatch(setSelectedContentType(selected));
    },
    [dispatch]
  );

  useEffect(() => {
    setContentType(0);
  }, [setContentType]);

  function showElementKind(kind: DropdownElementKind) {
    if (kind === 'flowchart') return contentFlowchartsEnabled;
    if (kind === 'video') return videoContentTypeEnabled;
    return true;
  }

  const dropdownProps = {
    dropdownId: 'curriculum-element-type-selection-dropdown',
    dropdownMenuId: 'curriculum-element-type-selection-dropdown-menu',
    options: filteredDropdownOptions.map((kind: DropdownElementKind) => {
      const { iconName, title, description } = dropdownText[kind];
      return (
        <DescriptiveDropdownOption
          description={description}
          iconName={iconName}
          id={`curriculum-element-dropdown-type-${kind}`}
          key={kind}
          title={title}
        />
      );
    }),
    selectedOptionIndex: selectedContentType,
    selectedOptionText: selectedDropdownOptionTitle,
    setSelectedOptionIndex: setContentType,
  };
  const singlePlaceholderTranslation = t('enter_content_title', {
    contentType: selectedDropdownOptionTitle.toLowerCase(),
  });
  const [createElement, createElementResult] = useCreateElementMutation();
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const { setNodeRef } = useSortable({ id: `actionRowCourse-${curriculumId}` });

  const createCurriculumElement = useCallback(
    (title: string, signatureRequired: boolean) => {
      const selectedCourseWithSignatureRequired =
        selectedDropdownOption === 'course' && signatureRequired;
      const elementKind = selectedCourseWithSignatureRequired
        ? 'course'
        : (selectedDropdownOption as ElementKind);
      const baseParams = { element: { title }, elementKind, curriculumId };
      const courseWithSignatureParams = { ...baseParams, signatureRequired };

      return createElement(
        selectedCourseWithSignatureRequired ? courseWithSignatureParams : baseParams
      );
    },
    [createElement, curriculumId, selectedDropdownOption]
  );

  const videoSelected =
    selectedDropdownOptionTitle === t('curriculum_element_dropdown.video.title');

  // On "Create" content this will create a curriculum element for all types but Video.
  // In the case of Video we want to open a modal to allow them to choose external or hosted video creation
  const handleCreate = useCallback(
    (title: string, signatureRequired: boolean) => {
      if (selectedDropdownOptionTitle === t('curriculum_element_dropdown.video.title')) {
        setShowVideoModal(true);
      } else {
        return createCurriculumElement(title, signatureRequired);
      }
    },
    [createCurriculumElement, selectedDropdownOptionTitle]
  );

  const handleCreateAndRedirect = useCallback(
    (title: string, signatureRequired: boolean) => {
      handleCreate(title, signatureRequired);
      setShouldNavigate(true);
    },
    [handleCreate]
  );

  useEffect(() => {
    if (shouldNavigate) {
      if (!createElementResult.data) return;

      const { elementKind, id: elementId } = createElementResult.data;
      const { editRoute } = elementAttributes({ elementKind, elementId });

      routeTo(editRoute);
    }
  }, [createElementResult.data, shouldNavigate, elementAttributes]);

  const createVideoAction = () => {
    console.log('create video');
  };

  return (
    <ActionRowWrapper
      id='curriculum-element-action-row-wrapper'
      isSubjectOutline={isCurriculumAdminRoute}
      ref={setNodeRef}
    >
      <ActionRow
        autoFocus={autoFocus}
        curriculumId={curriculumId}
        disablePrimaryButton={!!selectedContentType && 'build' === accountPlan}
        dropdown={dropdownProps}
        errorText={t('enter_title')}
        hasMaxValue
        maxError={t('max_error')}
        name='new-curriculum-element-title'
        placeholder={singlePlaceholderTranslation}
        primaryButtonAction={handleCreateAndRedirect}
        primaryButtonId={`inline-create-curriculum-element-button-${curriculumId}`}
        primaryButtonText={t('create')}
        primaryOnKeyDownAction={handleCreate}
        skipSubmitting={videoSelected}
        withBorder={false}
        withoutTransparentBorder
      />
      {showVideoModal && videoContentTypeEnabled && (
        <VideoModal onClose={() => setShowVideoModal(false)} onCreateVideo={createVideoAction} />
      )}
    </ActionRowWrapper>
  );
});
CurriculumElementActionRow.displayName = 'CurriculumElementActionRow';

export default CurriculumElementActionRow;
