import { useFormik } from 'formik';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import * as yup from 'yup';

import { RegisteredId } from '../../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../../lib/initTranslations';
import { sanitizeInput } from '../../../../../../../lib/sanitize';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import Badge from '../../../../../../design_system/display/badge';
import Icon from '../../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { InputProps } from '../../../../../../design_system/Triage/InputField';
import { StyledErrorMessage } from '../../../../../../styled/Inputs';
import { fontSm4 } from '../../../../../../styled/TypeSystem';
import PaywallButton from '../../../../../curriculums/public_share/PaywallButton';
import CallToActionElementWrapper from '../../../../../home/Onboarding/CallToActionElementWrapper/CallToActionElementWrapper';
import DropdownWithPoppableMenu from '../../../../../shared/DropdownWithPoppableMenu';

const Container = styled.div<{
  withBorder?: boolean;
  isAdminPage: boolean;
  inputFocused?: boolean;
}>(
  ({ theme: { constants, vars }, isAdminPage, withBorder, inputFocused }) => css`
    display: flex;
    flex-direction: column;
    min-height: 3.5rem;
    border-radius: ${isAdminPage ? constants.borderRadiusMd : constants.borderRadiusLg};
    border: ${withBorder ? `${constants.borderRadiusXs} solid ${vars.borderSurface1}` : 'none'};
    background-color: ${vars.foundationBase1};
    transition: box-shadow 0.3s;
    padding: ${constants.spacerSm3};
    padding-left: ${constants.spacerMd2};

    ${inputFocused &&
    css`
      background-color: ${vars.foundationSurface1};
    `};

    &:hover {
      background-color: ${vars.foundationBase1};
    }
  `
);

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const ErrorMessage = styled(StyledErrorMessage)(
  ({ theme: { constants } }) => css`
    display: inline-block;
    margin: ${constants.spacerSm3};
  `
);

const StyledCharacterCount = styled.span<{ hasError: boolean }>`
  color: ${({ theme: { vars }, hasError }) => (hasError ? vars.stateError : vars.textDefault)};
  ${fontSm4};
`;

const StyledInput = styled.input<{ inputFocused?: boolean }>(
  ({ theme: { vars, constants }, inputFocused }) => css`
    border: ${constants.borderWidthLg} transparent solid; /* keep the border transparent to avoid jumping when the border is added */
    outline: none;
    flex: 1;
    color: ${vars.textDefault};
    background-color: ${vars.foundationBase1};
    padding: ${constants.spacerSm3} 0;
    width: 100%;

    ${inputFocused &&
    css`
      background-color: ${vars.foundationSurface1};
      border: ${constants.borderWidthLg} solid ${vars.accentPrimaryDefault};
      border-radius: ${constants.borderRadiusMd};
      border-color: ${vars.accentPrimaryDefault};
      padding: ${constants.spacerSm3} ${constants.spacerMd2};
      transition: width 0.3s;
    `}
  `
);

const SelectedOption = styled.div`
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
`;

const CharCountAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.stateError};
`;

const ErrorDiv = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

type MaxValueProps =
  | { hasMaxValue: boolean; maxError: string; maxCharacters?: number }
  | { hasMaxValue?: never; maxError?: never; maxCharacters?: never };

type DisabledProps =
  | { disabled?: boolean; tooltipText: string; id: string }
  | { disabled?: never; tooltipText?: never; id?: never };

type DropdownProps = {
  options: React.ReactNode[];
  selectedOptionText: string;
  selectedOptionIndex: number;
  setSelectedOptionIndex: (newIndex: number) => void;
  dropdownId: string;
  dropdownMenuId: string;
};

type OutlineActionRowProps = {
  autoFocus?: boolean;
  dropdown?: DropdownProps;
  primaryButtonText: string;
  primaryButtonId: RegisteredId;
  primaryButtonAction: (value: string) => void;
  primaryOnKeyDownAction?: (value: string) => void;
  disablePrimaryButton?: boolean;
  withBorder?: boolean;
  errorText: string;
  name: string;
  isAdminPage?: boolean;
  placeholder: string;
} & MaxValueProps &
  DisabledProps &
  Omit<InputProps, 'name'>;

const MAX_CHARACTERS = 250;

const getValidationSchema = (
  inputName: string,
  errorText: string,
  hasMaxValue: boolean,
  maxError: string,
  maxCharacters: number
) => {
  return yup.object().shape({
    [inputName]: hasMaxValue
      ? yup.string().trim().max(maxCharacters, maxError).required(errorText)
      : yup.string().trim().required(errorText),
  });
};

const t = initTranslations('curriculum_edit');

const OutlineActionRow = ({
  autoFocus,
  dropdown,
  primaryButtonText,
  primaryButtonId,
  primaryButtonAction,
  primaryOnKeyDownAction,
  disablePrimaryButton,
  withBorder = true,
  errorText,
  hasMaxValue = false,
  maxError = '',
  disabled,
  tooltipText,
  id,
  name: inputName,
  isAdminPage = true,
  maxCharacters = MAX_CHARACTERS,
  placeholder,
  ...standardInputProps
}: OutlineActionRowProps) => {
  const [isInputActive, setInputActive] = useState(false);
  const {
    step: { singular: stepTermSingular },
  } = useAccountTerminology();
  const { handleSubmit, setFieldValue, errors, values, isSubmitting, resetForm, submitCount } =
    useFormik({
      initialValues: { [inputName]: '' },
      validationSchema: getValidationSchema(
        inputName,
        errorText,
        hasMaxValue,
        maxError,
        maxCharacters
      ),
      onSubmit: () => submitHandler(),
    });

  const handleInputActivity = (isActive: boolean) => {
    setInputActive(isActive);
    if (!isActive && !values[inputName]) {
      resetForm();
    }
  };

  const submitActionRef = useRef<() => Promise<object> | void>();
  const handleButtonClick = () => {
    submitActionRef.current = () => primaryButtonAction(values[inputName]);

    handleSubmit();
  };

  const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !disablePrimaryButton && !isSubmitting) {
      submitActionRef.current = () =>
        primaryOnKeyDownAction
          ? primaryOnKeyDownAction(values[inputName])
          : primaryButtonAction(values[inputName]);

      handleSubmit();
    }
  };

  const submitHandler = () => {
    submitActionRef.current &&
      submitActionRef.current()?.then(() => {
        resetForm();
      });
  };
  const queryParams = new URLSearchParams(window.location.search);
  const isAddTopicTask = queryParams.get('onboarding_task') === 'add_topic';

  const shouldDisableAddElementBtn = !isAddTopicTask && (isSubmitting || !!disabled);

  useEffect(() => {
    if (isAddTopicTask) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [isAddTopicTask]);

  return (
    <>
      {disabled && tooltipText && <Tooltip id={`disabled-${id}-tooltip`} text={tooltipText} />}
      <Container
        data-for={`disabled-${id}-tooltip`}
        data-tip
        id={id}
        inputFocused={isInputActive}
        isAdminPage={isAdminPage}
        withBorder={withBorder}
      >
        <FlexRow>
          {isInputActive && <Badge text={stepTermSingular} type='general' />}
          <StyledInput
            autoFocus={autoFocus}
            disabled={disabled}
            id='curriculum-element-input-field'
            inputFocused={isInputActive}
            name={inputName}
            onBlur={() => handleInputActivity(false)}
            onChange={(e) => {
              let sanitized = sanitizeInput(e.target.value);
              sanitized = sanitized.replace(/&nbsp;/g, ' ');

              setFieldValue(inputName, sanitized);
            }}
            onFocus={() => handleInputActivity(true)}
            onKeyDown={onKeyDownHandler}
            placeholder={placeholder}
            value={values[inputName]}
            {...standardInputProps}
          />
          <CharCountAndButtonWrapper>
            {isInputActive && (
              <StyledCharacterCount hasError={values[inputName].length > maxCharacters}>
                {`${values[inputName].length}/${maxCharacters}`}
              </StyledCharacterCount>
            )}
            <ButtonWrapper>
              {dropdown && (
                <DropdownWithPoppableMenu
                  activeOptionIndex={dropdown.selectedOptionIndex}
                  id={dropdown.dropdownId}
                  menuId={dropdown.dropdownMenuId}
                  options={dropdown.options}
                  selectedOption={<SelectedOption>{dropdown.selectedOptionText}</SelectedOption>}
                  setSelectedOption={dropdown.setSelectedOptionIndex}
                />
              )}
              {(isInputActive || values[inputName]) && (
                <CallToActionElementWrapper
                  className='create-curriculum-element-button-wrapper'
                  isVisible={isAddTopicTask}
                >
                  {disablePrimaryButton ? (
                    <PaywallButton
                      description={t('curriculum_add_test')}
                      iconName='lock'
                      link={t('update')}
                      size='md'
                      title={primaryButtonText}
                    />
                  ) : (
                    <DefaultButton
                      buttonType='primary'
                      disabled={shouldDisableAddElementBtn}
                      fullWidth
                      id={primaryButtonId}
                      loading={isSubmitting}
                      onClick={() => handleButtonClick()}
                      text={primaryButtonText}
                    />
                  )}
                </CallToActionElementWrapper>
              )}
            </ButtonWrapper>
          </CharCountAndButtonWrapper>
        </FlexRow>
        {!!submitCount && errors[inputName] && (
          <ErrorDiv id='error-container'>
            <StyledIcon name='circle-xmark' weight='solid' />
            <ErrorMessage>{errors[inputName]}</ErrorMessage>
          </ErrorDiv>
        )}
      </Container>
    </>
  );
};

export default OutlineActionRow;
