import styled from 'styled-components';

import { fontSm4 } from '../../../styled/TypeSystem';

export const BrandingContainer = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
  justify-content: center;
  align-items: center;
`;

export const LogoImage = styled.img`
  height: auto;
  max-width: 15rem;
`;

export const BrandColorBlock = styled.div<{ color: string }>`
  width: 3rem;
  height: 3rem;
  background-color: ${({ color }) => color};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusSm};
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  min-height: 13rem;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  resize: vertical;

  ${fontSm4};

  &::placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
  margin: ${({ theme: { constants } }) => constants.spacerMd3} 0;
`;
