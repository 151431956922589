import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useGetTemplatePreviewInfoQuery } from '../../../../../redux/services/resourceApis/templates/templatesApi';
import Icon from '../../../../design_system/display/icons/Icon';
import Loader from '../../../../design_system/Triage/Loader';
import { routes } from '../../../publicApplication/applicationRouter';
import RouterLink from '../../../publicApplication/RouterLink';
import { Title } from '../../../shared/DetailedTitle/Title';
import {
  TemplatePreviewInfoContainer,
  TemplatePreviewInfoDescription,
  TemplatePreviewInfoDownloads,
  TemplatePreviewInfoDownloadsIcon,
  TemplatePreviewInfoHeader,
  TemplatePreviewInfoHeaderLine,
  TemplatePreviewInfoIconWrapper,
  TemplatePreviewInfoProTip,
  TemplatePreviewInfoTitleWrapper,
} from './styles';
import TemplateRoles from './TemplateRoles/TemplateRoles';
import TemplateTags from './TemplateTags/TemplateTags';

const t = initTranslations('template_preview.info');

export type TemplatePreviewInfoProps = {
  templateId: number;
};

const TemplatePreviewInfo = ({ templateId }: TemplatePreviewInfoProps) => {
  const { slug } = useCurrentAccount();

  const result = useGetTemplatePreviewInfoQuery(templateId);
  const { data, error, isLoading } = result;

  useDisplayFlashOnResponse({
    result,
    errorMessage: messageFromError(error)?.join(', '),
  });

  if (isLoading) return <Loader />;

  if (!data) return <></>;

  const {
    author,
    description,
    emoji,
    templateDownloadsCount,
    tagsList,
    title,
    proTip,
    suggestedGroupNames,
  } = data;

  const showTags = !!tagsList.length;
  const showRoles = !!suggestedGroupNames.length;

  return (
    <TemplatePreviewInfoContainer>
      <TemplatePreviewInfoHeader>
        <TemplatePreviewInfoTitleWrapper>
          <TemplatePreviewInfoIconWrapper>
            {emoji || <Icon name='layer-group' size='sm' />}
          </TemplatePreviewInfoIconWrapper>
          <Title fontSize='md5' fontWeight='semibold' title={title} />
        </TemplatePreviewInfoTitleWrapper>
        <TemplatePreviewInfoHeaderLine>
          <span className='template-author'>
            {t('by')}
            <RouterLink to={routes.templatesSearch({ slug, author: [author] })}>
              {author}
            </RouterLink>
          </span>
          <TemplatePreviewInfoDownloads>
            <TemplatePreviewInfoDownloadsIcon name='chart-line-up' size='2xs' />
            {templateDownloadsCount > 4
              ? t('downloads', { count: templateDownloadsCount })
              : t('new')}
          </TemplatePreviewInfoDownloads>
        </TemplatePreviewInfoHeaderLine>
      </TemplatePreviewInfoHeader>
      <TemplatePreviewInfoDescription>{description}</TemplatePreviewInfoDescription>
      {proTip && (
        <TemplatePreviewInfoProTip>
          {t('pro_tip')}
          {proTip}
        </TemplatePreviewInfoProTip>
      )}
      {showRoles && <TemplateRoles roles={suggestedGroupNames} />}
      {showTags && <TemplateTags tagsList={tagsList} />}
    </TemplatePreviewInfoContainer>
  );
};

export default TemplatePreviewInfo;
