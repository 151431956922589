import React from 'react';
import styled from 'styled-components';

import useKeyPress from '../../../../../hooks/useKeyPress';
import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import { RegisteredId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { ModalBodyContainer, ModalFooterContainer } from '../../../../styled/Modals';
import StatusBlockError from '../../BulkUserUploadStatusBlock/StatusBlockError';
import StatusBlockSuccess from '../../BulkUserUploadStatusBlock/StatusBlockSuccess';
import { Result } from '../ResultTypes';

const StyledModalBodyContainer = styled(ModalBodyContainer)`
  display: block;
`;

const StyledImportButton = styled(DefaultButton)`
  margin-top: 1rem;
  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-left: ${({ theme: { constants } }) => constants.spacerMd1};
    margin-top: 0;
  }
`;

const StyledNeedHelpButton = styled(DefaultButton)`
  margin-right: auto;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: center;
  padding: 0.4375rem 1.25rem;
  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-bottom: 0;
    width: auto;
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const t = initTranslations('bulk_user_upload_modal.failed_import');

type Props = {
  closeModal: () => void;
  renderImportTeammatesContent: () => void;
  renderSuccessContent: () => void;
  isPartiallyFailedModal: boolean;
  data: Result;
};

const FailedImport = ({
  data,
  closeModal,
  renderImportTeammatesContent,
  renderSuccessContent,
  isPartiallyFailedModal,
}: Props) => {
  const successTitle = (
    <span
      dangerouslySetInnerHTML={{
        __html:
          data.success.people.length === 1
            ? t('bulk_user_upload_status_block.title_success_singular')
            : t('bulk_user_upload_status_block.title_success_plural', {
                quantity: data.success.amount,
              }),
      }}
    />
  );
  const errorTitle = (
    <span
      dangerouslySetInnerHTML={{
        __html: t('bulk_user_upload_status_block.title_error', { quantity: data.failed.amount }),
      }}
    />
  );

  useKeyPress('Enter', renderImportTeammatesContent);
  const { configs } = usePrivateConfigs();
  const bulkUserUpload = configs['BULK_USER_UPLOAD_HELP_PAGE'];

  return (
    <>
      <StyledModalBodyContainer>
        <StatusBlockError
          linkTextXlsx={data.failed.results_file.xlsx.name}
          resultType='annotated_upload_results'
          subtitle={t('bulk_user_upload_status_block.subtitle_error')}
          title={errorTitle}
        />
        {isPartiallyFailedModal && <StatusBlockSuccess title={successTitle} />}
      </StyledModalBodyContainer>
      <ModalFooterContainer>
        <StyledNeedHelpButton
          buttonType='tertiary'
          iconName='external-link-alt'
          id='failed-import-need-help'
          onClick={() => {
            window.open(bulkUserUpload, '_blank');
          }}
          size='sm'
          text={t('need_help')}
        />
        <DefaultButton
          buttonType='secondary'
          id={`failed-import-${isPartiallyFailedModal ? 'finish-later' : 'close'}` as RegisteredId}
          onClick={isPartiallyFailedModal ? renderSuccessContent : closeModal}
          text={isPartiallyFailedModal ? t('i_will_finish_later') : t('close')}
        />
        <StyledImportButton
          buttonType='primary'
          id='failed-import-back-to-upload'
          onClick={renderImportTeammatesContent}
          text={t('fix_import_file')}
        />
      </ModalFooterContainer>
    </>
  );
};

export default FailedImport;
