import { orderBy } from 'lodash';
import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { IntegrationCard as IntegrationCardType } from '../../../../../types/IntegrationCard';
import { SortDirection } from '../../../../../types/SortDirection';
import Column from '../../../../design_system/Triage/layout/Grid/Column';
import { FINCH, UKG_PRO } from '../../constants';
import DefaultIntegrationCard from '../../DefaultIntegrationCard';
import IntegrationCard from '../../IntegrationCard';

export interface IntegrationCardsProps {
  integrations: IntegrationCardType[];
  searchValue: string;
  sortDirection: SortDirection;
}

const IntegrationCards = ({ integrations, searchValue, sortDirection }: IntegrationCardsProps) => {
  const ability = useCurrentUserAbilities();
  const finchSandboxEnabled = ability.can('read', 'EnableFinchSandbox');
  const sortedIntegrations = orderBy(integrations, ['title'], [sortDirection]);

  const {
    splitFeatures: { integrationsApiTokenEnabled },
  } = useCurrentAccount();

  const cardsFilteringCondition = (card: IntegrationCardType) => {
    const searchFilterCondition =
      card.isEnabled && card.title.toLowerCase().includes(searchValue.toLowerCase());

    if (card.key === FINCH) return finchSandboxEnabled && searchFilterCondition;
    // This should only be visible to customers with the Split feature flag,
    // `integrationsApiTokenEnabled`, enabled.
    // Read More: https://trainual.atlassian.net/browse/GS1-5423
    if (card.key === UKG_PRO) return integrationsApiTokenEnabled && searchFilterCondition;

    return searchFilterCondition;
  };

  const searchedCards = sortedIntegrations.filter((card) => cardsFilteringCondition(card));

  return (
    <>
      {!!searchedCards.length && (
        <>
          {searchedCards.map((card) => (
            <Column
              className='integration-card-column'
              key={card.title}
              lg={3}
              md={4}
              sm={6}
              xl={3}
              xs={12}
            >
              <IntegrationCard card={card} />
            </Column>
          ))}
        </>
      )}
      <Column lg={3} md={4} sm={6} xl={3} xs={12}>
        <DefaultIntegrationCard />
      </Column>
    </>
  );
};

export default IntegrationCards;
