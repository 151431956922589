import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';

import VideoMarketingSidebarHeader from '../../../../images/video-marketing-sidebar-header.svg';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Icon from '../../../design_system/display/icons/Icon';
import Checkbox from '../../../design_system/input/controls/Checkbox';
import { fontMd3, fontSm4 } from '../../../styled/TypeSystem';

const PanelWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: ${constants.borderWidthSm} solid ${vars.trainualBrandBlueStrong};
    border-radius: ${constants.borderRadiusXl};
    background-color: ${vars.foundationSurface1};
    width: 24rem;
    height: 39rem;
  `
);

const HeaderImage = styled('img')(
  ({ theme: { constants } }) => css`
    padding-bottom: ${constants.spacerMd2};
    border-top-left-radius: ${constants.borderRadiusXl};
    border-top-right-radius: ${constants.borderRadiusXl};
  `
);

const Header = styled.header(
  ({ theme: { constants } }) => css`
    font-weight: ${constants.fontBold};
    padding-bottom: ${constants.spacerSm3};
    ${fontMd3}
  `
);
const Sublabel = styled.div(
  ({ theme: { constants } }) => css`
    padding-bottom: ${constants.spacerMd2};
    ${fontSm4}
  `
);

const BodyWrapper = styled.div(
  ({ theme: { constants } }) => css`
    padding: 0 ${constants.spacerLg1};
  `
);

const BannerWrapper = styled.div`
  display: flex;
  width: 17.8rem;
`;

const BannerSection = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd3};
    padding-top: ${constants.spacerMd1};
  `
);

// DS Override: Update icon color/ add spacing to match designs
const StyledIcon = styled(Icon)<{ name: string }>(
  ({ theme: { constants, vars }, name }) => css`
    color: ${name === 'circle-check' ? vars.stateSuccess : vars.stateCaution};
    margin-right: ${constants.spacerSm3};
  `
);

const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BannerTitle = styled.div(
  ({ theme: { constants } }) => css`
    font-weight: ${constants.fontSemibold};
    ${fontSm4}
  `
);

const BannerSubTitle = styled.div(
  ({ theme: { vars } }) => css`
    color: ${vars.textSubdued};
    ${fontSm4}
  `
);

const FooterWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd2};
    padding: 0 ${constants.spacerMd3} ${constants.spacerMd2} ${constants.spacerMd3};
    width: 100%;
  `
);

const ButtonsWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    gap: ${constants.spacerMd1};
    width: 100%;
  `
);

// DS Override: Custom border and hover while palette system variables are not available
//TODO: Add hover background color blue-80 to Learn How Button once palette system variables are available
const LearnHowButton = styled(DefaultButton)(
  ({ theme: { constants, vars } }) => css`
    border: ${constants.borderWidthSm} solid ${vars.trainualBrandBlueStrong};
    &:hover {
      border: ${constants.borderWidthSm} solid ${vars.trainualBrandBlueStrong};
    }
  `
);

const t = initTranslations('video.video_marketing_sidebar');

export type CheckBannerProps = {
  icon?: IconName;
  title: string;
  subtitle: string;
};

export const CheckBanner = ({ icon = 'circle-check', title, subtitle }: CheckBannerProps) => {
  return (
    <BannerWrapper>
      <StyledIcon name={icon} size='sm' />
      <BannerTextWrapper>
        <BannerTitle>{title}</BannerTitle>
        <BannerSubTitle>{subtitle}</BannerSubTitle>
      </BannerTextWrapper>
    </BannerWrapper>
  );
};

const MarketingSidebarBody = () => {
  return (
    <BodyWrapper>
      <Header>{t('header_title')}</Header>
      <Sublabel>{t('subheader_title')}</Sublabel>
      <BannerSection>
        <CheckBanner
          subtitle={t('check_banner.watch_accountability_subtitle')}
          title={t('check_banner.watch_accountability_title')}
        />
        <CheckBanner
          subtitle={t('check_banner.transcripts_subtitle')}
          title={t('check_banner.transcripts_title')}
        />
        <CheckBanner
          subtitle={t('check_banner.search_video_subtitle')}
          title={t('check_banner.search_video_title')}
        />
        <CheckBanner
          subtitle={t('check_banner.auto_read_times_subtitle')}
          title={t('check_banner.auto_read_times_title')}
        />
      </BannerSection>
    </BodyWrapper>
  );
};

const MarketingSidebarFooter = ({ onDismiss }: Props) => {
  const [checked, setChecked] = React.useState(false);

  return (
    <FooterWrapper>
      <ButtonsWrapper>
        <DefaultButton
          buttonType='tertiary'
          fullWidth
          id='video-marketing-sidebar-dismiss'
          onClick={() => onDismiss(checked)}
          text={t('dismiss')}
        />
        <LearnHowButton
          buttonType='brand'
          fullWidth
          id='video-marketing-sidebar-learn-how'
          text={t('learn_how')}
        />
      </ButtonsWrapper>
      <Checkbox
        checked={checked}
        id='marketing-sidebar-dont-show-again'
        label={t('dont_show')}
        name={`don't show again`}
        onCheck={() => setChecked(!checked)}
      />
    </FooterWrapper>
  );
};

export type Props = {
  onDismiss: (checked: boolean) => void;
};
const MarketingSidebar = ({ onDismiss }: Props) => {
  return (
    <PanelWrapper>
      <div>
        <HeaderImage src={VideoMarketingSidebarHeader} />
        <MarketingSidebarBody />
      </div>
      <MarketingSidebarFooter onDismiss={onDismiss} />
    </PanelWrapper>
  );
};
export default MarketingSidebar;
