import React from 'react';
import styled from 'styled-components';

import { useGroupsIndex } from '../../../../contexts/GroupsIndexContext';
import { useGroupsModals } from '../../../../contexts/GroupsModalContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { GroupKindWithAll } from '../../../../types/Group';
import Chip from '../../../design_system/buttons/Chip/Chip';
import Scrollbar from '../../../styled/Scrollbar';
import { routes } from '../../publicApplication/applicationRouter';

const StyledChipsContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: repeat(6, minmax(min-content, max-content));
  grid-gap: ${({ theme: { constants } }) => `${constants.spacerLg3} ${constants.spacerSm3}`};
  justify-content: start;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  ${Scrollbar};

  &::-webkit-scrollbar-thumb {
    border: ${({ theme: { vars, constants } }) =>
      `${constants.borderWidth2xl} solid ${vars.foundationBase1}`};
  }
`;

const t = initTranslations('groups.kinds');

const ChipsContainer = () => {
  const { slug } = useCurrentAccount();
  const { dispatch: groupModalsDispatch } = useGroupsModals();
  const {
    data,
    dispatch,
    queryParams: { group_kind },
  } = useGroupsIndex();
  const { group_kinds } = data;

  const onChipClick = (newGroupKind: GroupKindWithAll) => {
    dispatch({ type: 'setGroupKindParam', payload: newGroupKind });
    groupModalsDispatch({ type: 'setNewGroupKind', payload: newGroupKind });
    routes.groups({ slug, group_kind: newGroupKind }).push();
  };

  if (group_kinds.length <= 1) return <></>;

  const chips: GroupKindWithAll[] = ['all', ...group_kinds];

  return (
    <StyledChipsContainer id='groups-index-chips-container'>
      {chips.map((chip: GroupKindWithAll) => (
        <Chip
          active={group_kind === chip}
          id={`${chip}-chip`}
          key={`${chip}-chip`}
          onClick={() => {
            onChipClick(chip);
          }}
          palette='brand'
          text={t(chip)}
        />
      ))}
    </StyledChipsContainer>
  );
};

export default ChipsContainer;
