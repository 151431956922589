import React from 'react';
import { Route } from 'type-route';

import useCurriculumElement from '../../../../hooks/useCurriculumElement';
import { RegisteredId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { useGetSurveyAttemptResultsQuery } from '../../../../redux/services/resourceApis/surveyAttempts/surveyAttemptsApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import GoBackIconButton from '../../editor/components/GoBackIconButton/GoBackIconButton';
import { routes } from '../../publicApplication/applicationRouter';
import FullScreenOverlay from '../../shared/FullScreenOverlay/FullScreenOverlay';
import { StatisticsWrapper } from '../../shared/preview/PreviewSurveyStatistics/styles';
import SurveyStatisticBlock from '../../shared/preview/PreviewSurveyStatistics/SurveyStatisticBlock/SurveyStatisticBlock';
import { MainContentWrapper, MainWrapper, Toolbar, ToolbarWrapper } from '../shared/styles';
import AnsweredQuestionCard from '../shared/SurveyResults/AnsweredQuestionCard/AnsweredQuestionCard';
import OutlineContent from './OutlineContent/OutlineContent';
import {
  SurveyQuestionCardsWrapper,
  SurveyResultsHeader,
  SurveyResultsMessage,
  SurveyResultsMessageWrapper,
  SurveyResultsStatisticsWrapper,
  SurveyResultsWrapper,
} from './styles';

const t = initTranslations('survey_results');

const SurveyResults = ({ route }: { route: Route<typeof routes.surveyResults> }) => {
  const {
    params: { slug, surveyId, id },
  } = route;
  const elementAttributes = useCurriculumElement();
  const { data } = useGetSurveyAttemptResultsQuery({ surveyId, id });

  const handleRetakeTestClick = () => {
    routes.surveyConsume({ slug, id: surveyId }).push();
  };

  if (!data) return <></>;

  const {
    surveyName,
    surveyEmoji,
    surveyPassingScore,
    curriculumId,
    curriculumTitle,
    score,
    answeredQuestions,
    passed,
    numberOfAttempts,
    nextElement,
  } = data;
  const resultMessageTranslationKey = passed ? 'success_message' : 'failure_message';
  const { id: nextElementId, kind: nextElementKind } = nextElement;

  let nextElementTranslation, nextElementButtonId: RegisteredId, handleNextElementClick;

  if (nextElementKind) {
    const { showRoute, termSingular } = elementAttributes({
      elementKind: nextElementKind,
      elementId: nextElementId,
    });

    nextElementTranslation = t('next_element_button', { element: termSingular.toLowerCase() });
    nextElementButtonId = `next-${nextElementKind}-button`;
    handleNextElementClick = () => showRoute.push();
  } else {
    nextElementTranslation = t('go_home_button');
    nextElementButtonId = 'go-home-button';
    handleNextElementClick = () => routes.home({ slug }).push();
  }

  return (
    <FullScreenOverlay id='survey-results-page-overlay' withoutCloseIcon>
      <MainWrapper>
        <OutlineContent
          answeredQuestions={answeredQuestions}
          curriculumId={curriculumId}
          curriculumTitle={curriculumTitle}
          passed={passed}
          score={score}
          surveyEmoji={surveyEmoji}
          surveyName={surveyName}
        />

        <MainContentWrapper>
          <ToolbarWrapper>
            <Toolbar>
              <GoBackIconButton
                onClick={() => routes.curriculumShow({ slug, id: curriculumId }).push()}
              />
            </Toolbar>
          </ToolbarWrapper>

          <SurveyResultsWrapper>
            <SurveyResultsHeader passed={passed}>
              <SurveyResultsMessageWrapper>
                <SurveyResultsMessage>{t(resultMessageTranslationKey)}</SurveyResultsMessage>

                {passed ? (
                  <DefaultButton
                    buttonType='primary'
                    id={nextElementButtonId}
                    onClick={handleNextElementClick}
                    size='md'
                    text={nextElementTranslation}
                  />
                ) : (
                  <DefaultButton
                    buttonType='primary'
                    id='retake-test-button'
                    onClick={handleRetakeTestClick}
                    size='md'
                    text={t('retake_test')}
                  />
                )}
              </SurveyResultsMessageWrapper>

              <SurveyResultsStatisticsWrapper>
                <StatisticsWrapper>
                  <SurveyStatisticBlock
                    body={`${score}%`}
                    textAlign='left'
                    title={t('your_score')}
                  />
                  <SurveyStatisticBlock
                    body={`${surveyPassingScore}%`}
                    textAlign='left'
                    title={t('score_needed_to_pass')}
                  />
                  <SurveyStatisticBlock
                    body={numberOfAttempts}
                    textAlign='left'
                    title={t('number_of_attempts')}
                  />
                </StatisticsWrapper>
              </SurveyResultsStatisticsWrapper>
            </SurveyResultsHeader>

            <SurveyQuestionCardsWrapper>
              {answeredQuestions.map((answeredQuestion, index) => (
                <AnsweredQuestionCard
                  answeredQuestion={answeredQuestion}
                  key={answeredQuestion.id}
                  position={index + 1}
                />
              ))}
            </SurveyQuestionCardsWrapper>
          </SurveyResultsWrapper>
        </MainContentWrapper>
      </MainWrapper>
    </FullScreenOverlay>
  );
};

export default SurveyResults;
