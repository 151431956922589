import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { SectionDivider, ToggleContainer } from '../../../AdvancedSettings/styles';
import {
  FormSectionDescription,
  FormSectionTitle,
  ToggleInfoSection,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.general_settings');

const BetaFeatures = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <>
      <SectionDivider />
      <ToggleSettingContainer id='beta-features-toggle'>
        <ToggleContainer>
          <Toggle
            checked={values.beta_features}
            className='beta-features-setting'
            handleToggle={() => setFieldValue('beta_features', !values.beta_features)}
            id='beta-features'
            name='beta_features'
          />
        </ToggleContainer>
        <ToggleInfoSection>
          <div>
            <FormSectionTitle>{t('beta_features_title')}</FormSectionTitle>
            <FormSectionDescription>{t('beta_features_description')}</FormSectionDescription>
          </div>
        </ToggleInfoSection>
      </ToggleSettingContainer>
    </>
  );
};

export default BetaFeatures;
