import styled from 'styled-components';

export const BaseRoundedCard = styled.div`
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${(props) => props.theme.constants.borderRadiusMd};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

export const BaseImageInitialsContainer = styled.div<{ hasImage: boolean }>`
  height: 0;
  padding-top: 100%;
  position: relative;
`;

export const Card = styled.div`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerSm3} ${constants.spacerMd2}`};
`;
