import { truncate } from 'lodash';
import React from 'react';

import Badge from '../../../../../design_system/display/badge';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';

type TemplateOutlineRowCategoryBadgeProps = {
  categories: string[];
  id: number;
};

const TemplateOutlineRowCategoryBadge = ({
  categories,
  id,
}: TemplateOutlineRowCategoryBadgeProps) => {
  const categoriesCount = categories.length;

  return (
    <>
      <Badge text={truncate(categories[0], { length: 20 })} type='general' />
      {categoriesCount > 1 && (
        <>
          <Tooltip id={`template-${id}-more-categories-tooltip`} text={categories.join(', ')} />
          <div data-for={`template-${id}-more-categories-tooltip`} data-tip='true'>
            <Badge text={`+${categoriesCount - 1}`} type='general' />
          </div>
        </>
      )}
    </>
  );
};

export default TemplateOutlineRowCategoryBadge;
