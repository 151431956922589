import { PaletteOption, getPrimaryPreviewColor } from 'saguaro';
import styled from 'styled-components';

import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Icon from '../../../design_system/display/icons/Icon';

export const PalettePickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`;

export const PalettePickerIcon = styled.button`
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${(props) => props.theme.constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const PalettePickerIconInner = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color};
  border-radius: ${(props) => props.theme.constants.borderRadiusSm};
`;

export const PalettePickerIconWrapper = styled.div`
  width: max-content;
`;

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  flex: 1 1 0;
  min-width: 0;
  padding-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const PalettePickerTextIconInner = styled.div<{ color: string }>`
  width: 20px;
  height: 5px;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm1};
  background-color: ${({ color }) => color};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXs};
`;

export const PalettePickerTextIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border-radius: ${(props) => props.theme.constants.borderRadiusMd};
  border: none;
  background-color: transparent;
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationHover};
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
  &:focus {
    outline: none;
  }
`;

export const PalettePickerPopupWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  width: 325px;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
`;

export const PaletteSwatchSquareWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PaletteSwatchSquare = styled.div<{ palette: PaletteOption; active: boolean }>`
  width: 45px;
  height: 45px;
  cursor: pointer;
  background-color: ${({ palette, theme: { vars } }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return getPrimaryPreviewColor(palette, vars);
  }};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  ${({ active, theme: { constants, vars } }) =>
    active && `border: ${constants.borderWidthLg} solid ${vars.foundationSurface1};`}
  &+& {
    margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
  }
`;

export const PalettePickerPopupInputRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PalettePickerPopupButton = styled(DefaultButton)`
  min-width: 3rem;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const TextIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;
