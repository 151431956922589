import React, { useContext, useState } from 'react';

import { PaywallContext } from '../../../../../contexts/PaywallContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Hoverable from '../../../../design_system/Hoverable';
import Toggle from '../../../../design_system/input/controls/Toggle';
import PaywallTooltip from '../../../shared/tooltips/PaywallTooltip';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  StyledIcon,
  ToggleContainer,
} from '../styles';
import { LockBrandStylesSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const LockBrandStylesSection = ({ id, lockBrandStyles }: LockBrandStylesSectionProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();
  const paywallCtx = useContext(PaywallContext);
  const brandStylesLocked = paywallCtx.includes('brand_styles');

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  const [isToggleHovered, setToggleHovered] = useState(false);

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>
          {t('lock_brand_styles_title')}
          {brandStylesLocked && <StyledIcon name='lock' weight='regular' />}
        </FormSectionTitle>
        <ToggleContainer>
          <PaywallTooltip
            description={t('lock_brand_styles_paywall.description')}
            modifiers={[{ name: 'offset', options: { offset: [25, 0] } }]}
            shouldBeVisible={isToggleHovered && brandStylesLocked}
            title={t('lock_brand_styles_paywall.title')}
          />
          <Hoverable setIsHovered={setToggleHovered}>
            <Toggle
              checked={lockBrandStyles}
              className='lock-brand-styles-setting'
              disabled={brandStylesLocked}
              handleToggle={(e) => updateAccountData({ lock_brand_styles: e.target.checked })}
              id='lock-brand-styles'
              name='lock_brand_styles'
            />
          </Hoverable>
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('lock_brand_styles_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default LockBrandStylesSection;
