import { FormikErrors, useFormik } from 'formik';
import React, { useCallback, useState } from 'react';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useUpdateCurriculumElementServiceMutation } from '../../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';
import InputField from '../../../../../design_system/Triage/InputField';

interface FormValues {
  title: string;
}

interface FormData {
  title: string;
  curriculumElementId: number;
}

const t = initTranslations('move_element_to_new_curriculum_modal');

export interface Props {
  curriculumElementId: number;
  onCloseRequest: () => void;
  onCreateCloseRequest: () => void;
  movementRestricted: boolean;
}

const MoveElementToNewCurriculumModal = ({
  onCloseRequest,
  curriculumElementId,
  onCreateCloseRequest,
  movementRestricted,
}: Props) => {
  const [createCurriculum, result] = useUpdateCurriculumElementServiceMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { error } = result;
  const {
    curriculum: { singular: subjectTermSingular },
    topic: { singular: topicTermSingular },
    policy: { plural: policyTermPlural },
    company: { singular: companyTermSingular },
    process: { plural: processTermPlural },
  } = useAccountTerminology();

  const subtitle = movementRestricted
    ? t('disabled_subtitle', {
        element: topicTermSingular.toLowerCase(),
        subject: subjectTermSingular.toLowerCase(),
        policy: policyTermPlural,
        company: companyTermSingular,
        process: processTermPlural,
      })
    : t('subtitle', { subject: subjectTermSingular });

  const validateForm = useCallback((values: FormValues) => {
    const { title } = values;
    const errors: FormikErrors<FormValues> = {};
    if (!title || title.trim() === '') {
      errors.title = t('inputs.errors.required_field');
    }
    return errors;
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validate: (values: FormValues) => validateForm(values),
    onSubmit: (values: FormValues) => submitForm(values),
  });

  const submitForm = useCallback(
    (values: FormValues) => {
      const formattedData: FormData = {
        title: values.title,
        curriculumElementId,
      };
      setIsSubmitting(true);
      createCurriculum(formattedData);
    },
    [createCurriculum, curriculumElementId]
  );

  const successFunction = useCallback(() => {
    onCloseRequest();
    onCreateCloseRequest();
  }, [onCloseRequest, onCreateCloseRequest]);

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success'),
    successFunction,
    errorMessage: messageFromError(error)?.join(', '),
  });

  const taskModalArgs: TaskModalProps = {
    title: t('title', { subject: subjectTermSingular }),
    onCloseRequest,
    heapModalName: 'curriculum-modal',
    processing: isSubmitting,
    primaryButtonText: t('create'),
    isDisabled: movementRestricted,
    primaryButtonTask: async () => {
      await formik.handleSubmit();
    },
    secondaryButtonText: t('cancel'),
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg' onCloseRequest={onCloseRequest}>
      <InputField
        disabled={movementRestricted}
        errorText={formik.touched.title && formik.errors.title}
        id='title'
        label={subtitle}
        name='title'
        onChange={formik.handleChange}
        placeholder={t('placeholder')}
        type='text'
        value={formik.values.title}
      />
    </TaskModal>
  );
};

export default MoveElementToNewCurriculumModal;
