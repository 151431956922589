import React, { useCallback, useContext } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useSlideout from '../../../../../../hooks/useSlideout';
import initTranslations from '../../../../../../lib/initTranslations';
import { setElementReportSearchTerm } from '../../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { reportsApi } from '../../../../../../redux/services/resourceApis/reports/reportsApi';
import { AccountTerminologyContext } from '../../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import SearchField from '../../../../../design_system/Triage/fields/SearchField';
import {
  ActionButtons,
  ActionsHeaderContainer,
  ElementsCount,
  RightAlignedHeaderActions,
  SearchFieldWrapper,
} from '../../../AllUsersReportPage/AllUsersReportTable/ActionsHeader/styles';
import EmailCsvSplitButton from '../../../EmailCsvSplitButton/EmailCsvSplitButton';
import { TableActionsHeaderProps } from '../types';
import ElementDownloadCsvReport from './ElementDownloadCsvReport';

const t = initTranslations('reports.element_reports.actions_header');

const TableActionsHeader = ({
  isFetching,
  curriculumId,
  totalElementsCount,
  sortDirection,
  sortColumn,
}: TableActionsHeaderProps) => {
  const dispatch = useAppDispatch();
  const { topic } = useContext(AccountTerminologyContext);
  const { allowSignatures, hasESignatureFeature } = useCurrentAccount();
  const { searchTerm, filters } = useAppSelector((state) => state.reports.elementReport);
  const { completionScoreMin, completionScoreMax, contentTypeFilters } = filters;
  const [getCsv] = reportsApi.useLazyGetElementReportCsvQuery();
  const showEmailCsvSplitButton = allowSignatures && hasESignatureFeature;
  const appliedFiltersCount =
    (completionScoreMin > 0 || completionScoreMax < 100 ? 1 : 0) + contentTypeFilters.length;

  const { close, open, isOpen } = useSlideout('element-report-filter-slideout');

  const toggleFilters = () => {
    isOpen ? close() : open();
  };

  const downloadCsvDocument = useCallback(() => {
    getCsv({
      ...filters,
      searchTerm,
      id: curriculumId,
      sortColumn,
      sortDirection,
    });
  }, [curriculumId, filters, getCsv, searchTerm, sortColumn, sortDirection]);

  return (
    <ActionsHeaderContainer id='element-report-actions'>
      <ElementsCount>
        {t('elements_count', {
          count: totalElementsCount,
          element: totalElementsCount === 1 ? topic.singular : topic.plural,
        })}
      </ElementsCount>
      <RightAlignedHeaderActions>
        <SearchFieldWrapper>
          <SearchField
            dataLoading={isFetching}
            placeholder={t('search_placeholder')}
            searchValue={searchTerm}
            setSearchValue={(value: string) => dispatch(setElementReportSearchTerm(value))}
          />
        </SearchFieldWrapper>
        <ActionButtons>
          <DefaultButton
            buttonType='secondary'
            iconName='sliders'
            iconWeight='solid'
            id='element-report-filter-btn'
            onClick={toggleFilters}
            size='md'
            text={t('filters', { count: appliedFiltersCount })}
          />
          {showEmailCsvSplitButton ? (
            <EmailCsvSplitButton
              curriculumId={curriculumId}
              downloadCsvDocument={downloadCsvDocument}
            />
          ) : (
            <ElementDownloadCsvReport downloadCsvDocument={downloadCsvDocument} />
          )}
        </ActionButtons>
      </RightAlignedHeaderActions>
    </ActionsHeaderContainer>
  );
};

export default TableActionsHeader;
