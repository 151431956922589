import React, { useEffect, useState } from 'react';
import { Route } from 'type-route';

import ThreeDotLoaderGif from '../../../../../../images/loaders/three-dot-loader.gif';
import initTranslations from '../../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../../redux/domains/modalsSlice/modalsSlice';
import { trainualApi } from '../../../../../../redux/services/trainualService';
import { store } from '../../../../../../redux/stores/store';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import CoreModal from '../../../../../design_system/core/CoreModal';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import routeTo from '../../../../publicApplication/routeTo';
import {
  ControlButtonsGroup,
  LoadingTextWrapper,
  LoadingTitle,
  ModalContentWrapper,
  StyledLoadingImage,
  StyledProgressButton,
} from './styles';
import { LoadingModalProps } from './types';

const BUTTON_ANIMATION_TIMEOUT = 4000;

const t = initTranslations('editor.create_survey.loading_modal');

const LoadingModal = ({ curriculumId }: LoadingModalProps) => {
  const route = useRoute();
  const {
    params: { slug, surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;
  const { curriculum } = useAccountTerminology();
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatchShowLoadingModal = useDispatchSetShowModal();

  const handleRedirectToCurriculumEdit = () => {
    store.dispatch(
      trainualApi.util.invalidateTags([
        { type: 'CurriculumElement', id: `Curriculum-${curriculumId}` },
      ])
    );
    routeTo(routes.curriculumEdit({ id: curriculumId, slug, scrollToElementId: surveyId }));
    dispatchShowLoadingModal('surveyLoadingModal', false);
  };

  useEffect(() => {
    setIsDisabled(true);
    const timer = setTimeout(() => setIsDisabled(false), BUTTON_ANIMATION_TIMEOUT);

    return () => clearTimeout(timer);
  }, []);

  return (
    <CoreModal
      closeIconAriaLabel={t('aria_label_cancel')}
      desktopSize='lg'
      heapModalName='smart-test-loading-modal'
      isStaticModal
      onCloseRequest={handleRedirectToCurriculumEdit}
    >
      <ModalContentWrapper>
        <StyledLoadingImage alt='three dot loader' src={ThreeDotLoaderGif} />
        <LoadingTextWrapper>
          <LoadingTitle>{t('title')}</LoadingTitle>
          {t('subtitle')}
        </LoadingTextWrapper>
      </ModalContentWrapper>
      <ControlButtonsGroup>
        <StyledProgressButton
          animationTime={BUTTON_ANIMATION_TIMEOUT}
          disabled={isDisabled}
          id='smart-test-loading-modal-redirect-button'
          onClick={handleRedirectToCurriculumEdit}
          text={t('action_button_text', { subject: curriculum.singular.toLowerCase() })}
        />
      </ControlButtonsGroup>
    </CoreModal>
  );
};

export default LoadingModal;
