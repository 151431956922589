import styled, { css } from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { Wrapper as SkeletonWrapper } from '../Skeleton/styles';

export const Wrapper = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: ${constants.spacerSm2};
  `}
`;

export const Header = styled.div`
  ${({ theme: { vars, constants } }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: ${constants.spacerSm3};
    cursor: pointer;
    border-radius: ${constants.borderRadiusLg};

    &:hover {
      background-color: ${vars.foundationBase1};
    }
  `}
`;

export const Info = styled.div`
  width: 100%;
  max-width: calc(100% - 3.5rem);
  display: flex;
  align-items: center;
`;

export const ExpandIconBox = styled.div`
  ${({ theme: { constants } }) => css`
    flex: none;
    width: ${constants.spacerLg1};
    height: ${constants.spacerLg1};
  `}
`;

export const ContentBadgeBox = styled.div`
  ${({ theme: { constants } }) => css`
    width: 5.8rem;
    display: flex;
    justify-content: center;
    padding: 0 ${constants.spacerMd2};
  `}
`;

export const Title = styled.div`
  width: calc(100% - 7.8rem);
  display: flex;
  align-items: center;
`;

export const Emoji = styled.div`
  ${({ theme: { constants } }) => css`
    margin-right: ${constants.spacerSm3};
    ${fontSm5};
  `}
`;

export const ActionSectionWrapper = styled.div`
  display: flex;
  width: 3.5rem;
  justify-content: flex-end;
`;

export const StatusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
`;

export const StatusIcon = styled(Icon)<{ baseColor?: boolean }>`
  color: ${({ baseColor, theme: { vars } }) =>
    baseColor ? vars.foundationBase4 : vars.stateCaution};
`;

export const Heading = styled.div`
  width: 100%;
  ${fontSm5};
  ${TruncatedText};
`;

export const CheckWrapper = styled.div<{ isPreviouslySelected: boolean }>`
  display: flex;
  ${({ isPreviouslySelected }) =>
    isPreviouslySelected &&
    css`
      cursor: not-allowed;
    `}

  > * {
    height: 1.25rem;
  }
`;

export const CheckIcon = styled(Icon)<{
  isCurrentlySelected?: boolean;
}>`
  color: ${({ isCurrentlySelected, theme: { vars } }) =>
    isCurrentlySelected ? vars.stateSuccess : vars.textSubdued};
`;

export const Content = styled.div<{ isDoubleIndented: boolean }>`
  ${({ isDoubleIndented, theme: { constants } }) => css`
    ${Header}, ${SkeletonWrapper} {
      padding-left: ${isDoubleIndented ? constants.spacerLg3 : constants.spacerMd3};
    }
  `}
`;
