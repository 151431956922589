import React, { useContext } from 'react';
import styled from 'styled-components';

import { AccountRoutesContext } from '../../../../contexts/AccountRoutesContext';
import initTranslations from '../../../../lib/initTranslations';
import Icon from '../../../design_system/display/icons/Icon';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';

const t = initTranslations('home.curriculum_assignments');

const StyledDiv = styled.div`
  cursor: pointer;
`;

const CertificateIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.stateFavorite};
  pointer-events: none;
`;

type CompletionCertIconProps = { subjectId: number; completionId: number };

const CompletionCertIcon = ({ subjectId, completionId }: CompletionCertIconProps) => {
  const routes = useContext(AccountRoutesContext);

  return (
    <>
      <Tooltip
        id={`curriculum-${subjectId}-certificate-tooltip`}
        text={t('view_completion_certificate')}
      />
      <StyledDiv
        data-for={`curriculum-${subjectId}-certificate-tooltip`}
        data-tip
        id={`curriculum-${subjectId}-certificate-icon`}
        onClick={(e) => {
          e.preventDefault();
          window.open(routes.view.completionCertificate({ completionId }), '_blank');
        }}
      >
        <CertificateIcon name='award' size='md' weight='solid' />
      </StyledDiv>
    </>
  );
};

export default CompletionCertIcon;
