import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetRequestsCountBySectorQuery } from '../../../../../redux/services/resourceApis/requests/requestsApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import PageHeader from '../../../../design_system/Triage/headers/PageHeader';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import CreateContentButton from '../CreateContentButton/CreateContentButton';

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
`;

const StyledPageHeader = styled(PageHeader)`
  margin-block: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const t = initTranslations('curriculums.content');

const Header = () => {
  const { slug } = useCurrentAccount();
  const { ownsAnyCurriculums } = useCurrentUser();
  const ability = useCurrentUserAbilities();
  const isAdminOrOwnsAnyCurriculums = useIsAdmin() || ownsAnyCurriculums;
  const { data } = useGetRequestsCountBySectorQuery({}, { skip: !isAdminOrOwnsAnyCurriculums });
  const requestsCount = data ? (data.count > 99 ? '99+' : data.count) : 0;
  const canCreateCurriculum = ability.can('create', 'Curriculum');

  return (
    <HeaderSection id='content-library-header'>
      <StyledPageHeader title={t('content')} />
      <ButtonWrapper>
        {isAdminOrOwnsAnyCurriculums && (
          <DefaultButton
            buttonType='secondary'
            id='request-content-library-button' // TODO: add button functionality
            onClick={() => routeTo(routes.requests({ slug }))}
            text={t('requests', { count: requestsCount })}
          />
        )}
        {useIsAdmin() && (
          <DefaultButton
            buttonType='secondary'
            id='set-curriculum-order-button'
            onClick={() => routes.contentOrder({ slug }).push()}
            text={t('set_order.title')}
          />
        )}
        {canCreateCurriculum && <CreateContentButton />}
      </ButtonWrapper>
    </HeaderSection>
  );
};

export default Header;
