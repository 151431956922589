import { TiptapCollabProvider } from '@hocuspocus/provider';
import React, { useEffect, useState } from 'react';
import { Doc as YDoc } from 'yjs';

import { AccountEditorProvider } from '../../../../contexts/AccountEditorContext';
import { EditorProvider } from '../../../../contexts/EditorContext';
import { EditorCourseProvider } from '../../../../contexts/EditorCourseContext';
import { EditorToolbarProvider } from '../../../../contexts/EditorToolbarContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import {
  useGetEditorCollabTokenQuery,
  useGetEditorTopicQuery,
} from '../../../../redux/services/resourceApis/courses/topicsApi';
import { useGetStepQuery } from '../../../../redux/services/resourceApis/steps/stepsApi';
import { GetStepResponse } from '../../../../redux/services/resourceApis/steps/types';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import BubbleMenuComponent from '../components/BubbleMenu/BubbleMenu';
import { BaseContentWrapper } from '../shared/styles';
import { stepEditorBubbleToolbarButtons } from '../toolbar/buttons/shared';
import ToolbarContainer from '../toolbar/ToolbarContainer';
import EditModeSkeleton from './components/EditModeSkeleton/EditModeSkeleton';
import Editor from './Editor/Editor';
import { useEditableContent } from './hooks/useEditableContent';
import EditorLockedOverlay from './LockedOverlay';
import OutlineContent from './OutlineContent/OutlineContent';

type EditorContentProps = {
  provider: TiptapCollabProvider;
  stepData: GetStepResponse;
};

const EditorContent = ({ provider, stepData }: EditorContentProps) => {
  const { productTerm } = useAccountTerminology();
  const account = useCurrentAccount();
  const { slug } = account;

  const { id: stepId, courseId } = stepData;

  const { collabState, collabUsers, editor, isAILoading } = useEditableContent({
    provider,
    stepId,
  });
  const { data, isLoading, isFetching, error } = useGetEditorTopicQuery({ courseId });

  if (error) return <BasicErrorDisplay error={error} />;
  if (isLoading || !data || !editor) return <EditModeSkeleton />;

  const { curriculum, ...course } = data;
  const lockedBySignatures = course.eSignatureDisplayedAndRequired && !course.canBeModified;
  const isLocked = lockedBySignatures || curriculum.locked;

  if (isLocked) {
    editor.setOptions({ editable: false });
  }

  return (
    <EditorCourseProvider
      course={course}
      curriculum={curriculum}
      productTerm={productTerm}
      stepId={stepId}
    >
      <AccountEditorProvider account={account}>
        <EditorProvider editor={editor} isAILoading={isAILoading}>
          <EditorToolbarProvider buttons={stepEditorBubbleToolbarButtons} context='bubble'>
            <BubbleMenuComponent />
          </EditorToolbarProvider>
          {isLocked && <EditorLockedOverlay course={course} curriculum={curriculum} slug={slug} />}
          <OutlineContent />
          <BaseContentWrapper>
            <ToolbarContainer isFetching={isFetching} isLocked={isLocked} step={stepData} />
            <Editor collabState={collabState} collabUsers={collabUsers} step={stepData} />
          </BaseContentWrapper>
        </EditorProvider>
      </AccountEditorProvider>
    </EditorCourseProvider>
  );
};

type EditorPageBaseProps = {
  stepId: number;
};

const EditorPageBase = ({ stepId }: EditorPageBaseProps) => {
  const {
    data: stepData,
    error,
    isLoading,
  } = useGetStepQuery(stepId, { refetchOnMountOrArgChange: true });
  const { configs: privateConfigs } = usePrivateConfigs();
  const { data: token } = useGetEditorCollabTokenQuery(stepId);

  const [provider, setProvider] = useState<TiptapCollabProvider | null>(null);

  useEffect(() => {
    if (privateConfigs && privateConfigs['TIPTAP_BASE_URL'] && token) {
      const ydoc = new YDoc();

      const provider = new TiptapCollabProvider({
        baseUrl: privateConfigs['TIPTAP_BASE_URL'],
        document: ydoc,
        name: `stepDocument.${stepId}`,
        token,
      });

      setProvider(provider);

      return () => {
        provider.destroy();
      };
    }
  }, [privateConfigs, stepId, token]);

  if (error) return <BasicErrorDisplay error={error} />;
  if (isLoading || !provider || !stepData || !token) return <EditModeSkeleton />;

  return <EditorContent provider={provider} stepData={stepData} />;
};

export default EditorPageBase;
