import * as yup from 'yup';

import isEmail from '../isEmail';
import isValidTopLevelDomain from '../isValidTopLevelDomain';

export const getEmailSchema = (requiredTranslation: string, emailTranslation: string) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(requiredTranslation)
      .trim()
      .test('is-valid-email', emailTranslation, (value) => {
        return !!value && isEmail(value) && isValidTopLevelDomain(value);
      }),
  });
};
