import { useEffect, useState } from 'react';

import { useGetJobStatusQuery } from '../redux/services/resourceApis/jobs/jobsApi';
import { JobDetails, JobStatus } from '../redux/services/resourceApis/jobs/types';

export enum JobCompleteStatus {
  initial,
  polling,
  complete,
  error,
}

const DEFAULT_JOB_POLLING_INTERVAL = 1000;

const useJobsComplete = (
  jobIds: string[] | undefined,
  jobsComplete?: () => void,
  pollingInterval = DEFAULT_JOB_POLLING_INTERVAL
): { jobDetails: JobDetails[] | undefined; status: JobCompleteStatus } => {
  const [status, setStatus] = useState(JobCompleteStatus.initial);

  const { data, error } = useGetJobStatusQuery(jobIds ?? [], {
    skip: !jobIds || status !== JobCompleteStatus.polling,
    pollingInterval,
  });

  // Start testing if new job ids were provided.
  useEffect(() => {
    if (!!jobIds?.length) {
      setStatus(JobCompleteStatus.polling);
    }
  }, [jobIds]);

  // Check all job status from job details api
  useEffect(() => {
    if (error) {
      setStatus(JobCompleteStatus.error);
    } else if (data) {
      const allJobsComplete = !data.some(
        (jobDetails) =>
          jobDetails.status === JobStatus.queued ||
          jobDetails.status === JobStatus.retrying ||
          jobDetails.status === JobStatus.working
      );

      if (allJobsComplete) {
        const success = data.every((jobDetails) => jobDetails.status === JobStatus.complete);
        setStatus(success ? JobCompleteStatus.complete : JobCompleteStatus.error);
      }
    }
  }, [data, error]);

  // Execute callback if job status is set to complete
  useEffect(() => {
    if (status === JobCompleteStatus.complete && jobsComplete) {
      jobsComplete();
    }
  }, [jobsComplete, status]);

  return {
    status,
    jobDetails: data,
  };
};

export default useJobsComplete;
