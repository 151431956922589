import { FormikTouched, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import getWordsCount from '../../../../../../../lib/getTextWordsCount';
import initTranslations from '../../../../../../../lib/initTranslations';
import { sanitizeInput } from '../../../../../../../lib/sanitize';
import { setQuestionAnswered } from '../../../../../../../redux/domains/surveyConsume/surveyConsumeSlice';
import { useAppDispatch } from '../../../../../../../redux/hooks';
import { FormProps, FormValues, WrittenResponseFormValues } from '../types';
import { WordCount, WrittenResponseAnswerEditable } from './styles';

const t = initTranslations('survey_consume.written_response_form');

const WrittenResponseForm = ({ question }: FormProps) => {
  const { setFieldValue, values, touched, setFieldTouched } = useFormikContext<FormValues>();
  const dispatch = useAppDispatch();
  const currentQuestionFormValueIndex = values.answersAttributes.findIndex(
    (answer) => answer.questionId === question.id
  );
  const currentQuestionFormValue = values.answersAttributes[
    currentQuestionFormValueIndex
  ] as WrittenResponseFormValues;
  const minimumWordCount = question.wordcount || 0;
  const inputWordsCount = getWordsCount(currentQuestionFormValue.writtenResponse);
  const isValidAnswer = inputWordsCount >= minimumWordCount && !!inputWordsCount;
  const [isFocused, setIsFocused] = useState(false);

  const handleEditableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedResponse = sanitizeInput(e.target.value, false);
    const path = `answersAttributes[${currentQuestionFormValueIndex}].writtenResponse`;

    setFieldValue(path, sanitizedResponse);
  };

  const handleEditableBlur = () => {
    setFieldTouched(`answersAttributes[${currentQuestionFormValueIndex}].writtenResponse`);
    setIsFocused(false);
  };

  const isTouched =
    touched.answersAttributes &&
    (touched.answersAttributes[
      currentQuestionFormValueIndex
    ] as FormikTouched<WrittenResponseFormValues>);

  useEffect(() => {
    dispatch(setQuestionAnswered({ questionId: question.id, isAnswered: isValidAnswer }));
    // We need to trigger this change only with isValidAnswer attribute change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidAnswer]);

  return (
    <>
      <WrittenResponseAnswerEditable
        html={currentQuestionFormValue.writtenResponse}
        id='written-response-text-editor'
        inputMode='text'
        onBlur={handleEditableBlur}
        onChange={handleEditableChange}
        onFocus={() => setIsFocused(true)}
        placeholder={t('input_placeholder')}
      />
      <WordCount
        isFocused={isFocused}
        isTouched={!!isTouched?.writtenResponse}
        isValid={isValidAnswer}
      >
        {t('word_count_validation_text', { count: minimumWordCount, words: inputWordsCount })}
      </WordCount>
    </>
  );
};

export default WrittenResponseForm;
