import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../../../../shared/SkeletonLoader/styles';
import { CardDetailsSectionWrapper } from '../../../../per_user_pricing/FullScreenOverlayContent/shared/styles';
import { CardDetailsWrapper } from '../../../CardDetails/styles';
import { CardSkeletonDescriptionWrapper } from '../styles';

const CardDetailsSectionSkeleton = () => {
  const {
    constants: { spacerSm2, spacerMd2, borderRadiusSm },
  } = useTheme();

  return (
    <CardDetailsSectionWrapper hasCardDetails>
      <CardDetailsWrapper>
        <SkeletonLoader
          borderRadius={borderRadiusSm}
          height='1.75rem'
          marginRight={spacerMd2}
          width='2.75rem'
        />
        <CardSkeletonDescriptionWrapper>
          <SkeletonLoader height='0.875rem' marginBottom={spacerSm2} width='70%' />
          <SkeletonLoader height='0.875rem' width='50%' />
        </CardSkeletonDescriptionWrapper>
      </CardDetailsWrapper>
    </CardDetailsSectionWrapper>
  );
};

export default CardDetailsSectionSkeleton;
