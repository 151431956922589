const updateSelections = (state: TableState, userId: number) => {
  const isSelected = state.selectedUsers.includes(userId);
  const selectedUsers = isSelected
    ? state.selectedUsers.filter((id) => id !== userId)
    : [...state.selectedUsers, userId];
  const unselectedUsers = isSelected
    ? [...state.unselectedUsers, userId]
    : state.unselectedUsers.filter((id) => id !== userId);

  return {
    ...state,
    selectedUsers,
    unselectedUsers,
    selectAllUsers: isSelected ? false : unselectedUsers.length === 0,
  };
};

const handleSelectAllUsers = (state: TableState, selectAll: boolean) => {
  if (selectAll) {
    return {
      ...state,
      selectAllUsers: true,
      selectedUsers: [...state.unselectedUsers, ...state.selectedUsers].filter(
        (value, index, array) => array.indexOf(value) === index
      ),
      unselectedUsers: [],
    };
  } else {
    return {
      ...state,
      selectAllUsers: false,
      selectedUsers: [],
      unselectedUsers: [...state.unselectedUsers, ...state.selectedUsers].filter(
        (value, index, array) => array.indexOf(value) === index
      ),
    };
  }
};

export type TableAction =
  | { type: 'setSearchValue'; searchValue: string }
  | { type: 'changePage'; page: number }
  | {
      type: 'toggleUserSelect';
      userId: number;
    }
  | {
      type: 'selectAllUsers';
      selectAllUsers: boolean;
    }
  | {
      type: 'setAllIds';
      allIds: number[];
    }
  | { type: 'resetSearchClear' };

export interface TableState {
  page: number;
  searchValue: string;
  selectedUsers: number[];
  unselectedUsers: number[];
  selectAllUsers: boolean;
  searchClear: boolean;
}

export const reducer = (state: TableState, action: TableAction): TableState => {
  switch (action.type) {
    case 'changePage': {
      return { ...state, page: action.page };
    }
    case 'setSearchValue': {
      return {
        ...state,
        searchValue: action.searchValue,
        page: 1,
        selectAllUsers: false,
        selectedUsers: [],
        unselectedUsers: [...state.unselectedUsers, ...state.selectedUsers],
        searchClear: !action.searchValue,
      };
    }
    case 'toggleUserSelect': {
      return updateSelections(state, action.userId);
    }
    case 'selectAllUsers': {
      return handleSelectAllUsers(state, action.selectAllUsers);
    }
    case 'setAllIds': {
      return {
        ...state,
        unselectedUsers: action.allIds,
      };
    }
    case 'resetSearchClear': {
      return {
        ...state,
        searchClear: false,
      };
    }
  }
};
