import { NewPerUserPlan } from '../../redux/services/resourceApis/billing/perUserPricingTypes';
import {
  NewSimplePricingPlan,
  NewSimplePricingPlanOmitEmployeesSize,
} from '../../redux/services/resourceApis/billing/simplePricingTypes';

type NewPlanTotalAmountProps =
  | NewSimplePricingPlan
  | NewPerUserPlan
  | NewSimplePricingPlanOmitEmployeesSize
  | undefined;

export function getNewPlanTotalAmount(newPlan: NewPlanTotalAmountProps) {
  if (newPlan) {
    return newPlan.dueToday > 0 ? newPlan.dueToday : newPlan.totalPrice;
  } else {
    return 0;
  }
}
