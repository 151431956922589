import { useCheckSimplePricingOrSimplePricingV3Plan } from '../billing/useCheckSimplePricingOrSimplePricingV3Plan';
import useCurrentAccount from '../useCurrentAccount';
import useCurrentUserAbilities from '../useCurrentUserAbilities';

export function useCheckManageSignatureAbility(signaturable: boolean | undefined) {
  const { accountPlan, onOneOfUnlimitedPlans, onUnlimitedCompPlan, nonBilling } =
    useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const isAllowedPlan =
    useCheckSimplePricingOrSimplePricingV3Plan() ||
    accountPlan === 'scale' ||
    onOneOfUnlimitedPlans ||
    onUnlimitedCompPlan ||
    nonBilling;

  return ability.can('update', 'ToggleESig') && isAllowedPlan && !!signaturable;
}
