import styled from 'styled-components';

import { fontMd1 } from '../../../../styled/TypeSystem';

export const QuestionCard = styled.div`
  display: flex;
  flex: 1;
  width: calc(100% - 10rem);
  max-width: 80rem;
  flex-direction: column;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 5rem 0 5rem`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border-top-left-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  border-top-right-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
`;

export const CardFooter = styled.div`
  width: 100%;
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerLg2} ${constants.spacerLg2}`};
`;

export const CardContent = styled.div`
  flex: 1;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerLg2} 3.75rem ${constants.spacerLg2}`};
`;

export const OptionsFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const QuestionPosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: ${({ theme: { constants } }) => constants.heightSm};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  color: ${({ theme: { vars } }) => vars.textDefault};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd1};
`;
