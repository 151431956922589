import {
  AddressComponent,
  AddressComponentTypes,
} from '../../components/application/shared/UpdatePaymentMethodForm/AddressField/types';
import { BillingAddressDetails } from '../../types/BillingAddressDetails';

type ParsedGoogleAddress = Omit<BillingAddressDetails, 'addressLine1' | 'addressLine2'> & {
  streetNumber: string;
  route: string;
};
type GoogleAddressToAddressDataMapping = {
  [Key in AddressComponentTypes]: {
    key: keyof ParsedGoogleAddress;
    isShortName: boolean;
  };
};
type AddressDataByGoogleAddressComponents = BillingAddressDetails | null;

const googleAddressToAddressDataMapping: GoogleAddressToAddressDataMapping = {
  street_number: { key: 'streetNumber', isShortName: false },
  route: { key: 'route', isShortName: true },
  locality: { key: 'addressCity', isShortName: false },
  administrative_area_level_1: { key: 'addressState', isShortName: true },
  country: { key: 'addressCountry', isShortName: true },
  postal_code: { key: 'addressZip', isShortName: false },
};

export const getAddressDataByGoogleAddressComponents = (
  address_components?: AddressComponent[]
): AddressDataByGoogleAddressComponents => {
  if (!address_components) return null;

  const parsedAddress = {} as ParsedGoogleAddress;

  address_components.forEach((addressComponents) => {
    const { long_name, short_name, types } = addressComponents;
    const type = types[0];
    const componentData = googleAddressToAddressDataMapping[type];

    if (componentData) {
      const { key, isShortName } = componentData;
      parsedAddress[key] = isShortName ? short_name : long_name;
    }
  });

  const {
    streetNumber = '',
    route = '',
    addressCity = '',
    addressCountry = '',
    addressState = '',
    addressZip = '',
  } = parsedAddress;
  const addressLine1 = `${streetNumber} ${route}`.trim();

  return { addressCity, addressCountry, addressState, addressZip, addressLine1, addressLine2: '' };
};
