import styled from 'styled-components';

import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';
import RouterLink from '../../../publicApplication/RouterLink';

export const ErrorPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { vars } }) => vars.stateBackgroundError};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  margin: ${({ theme: { constants } }) => constants.spacerSm3} 0;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

export const ErrorPreviewMessage = styled.span`
  color: ${({ theme: { vars } }) => vars.stateHoverError};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm4};
`;

export const ErrorPreviewTitleLink = styled(RouterLink)`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.stateHoverError};
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-thickness: ${({ theme: { constants } }) => constants.borderWidthLg} !important;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  &:hover,
  &:focus {
    outline: none;
    color: ${({ theme: { vars } }) => vars.stateHoverError};
    text-decoration: underline;
  }

  ${fontSm5};
`;

export const ErrorPreviewTitle = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.stateHoverError};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  ${fontSm5};
`;
