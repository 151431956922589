import styled, { css } from 'styled-components';

import IconButton from '../../../design_system/buttons/IconButton';
import { MultilineTruncatedText, TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { Card } from '../ResponsibilityCard/styles';
import { TITLE_FIRST_WORD_MAX_LENGTH } from '../shared/constants/boards';

export const GroupResponsibilityCardContainer = styled.div<{ isCollapsed?: boolean }>`
  ${({ isCollapsed, theme: { constants, vars } }) => css`
    max-width: 16.375rem;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${constants.spacerMd2};
    background-color: ${vars.foundationSurface1};
    border-radius: ${constants.borderRadiusMd};
    border: ${constants.borderWidthSm} solid ${isCollapsed ? vars.borderSurface2 : vars.borderHover};
    cursor: pointer;

    &:hover {
      border-color: ${vars.borderHover};
    }
  `};

  ${({ isCollapsed, theme: { constants, vars } }) =>
    isCollapsed &&
    css`
      &:before,
      &:after {
        content: '';
        position: absolute;
        height: 0.188rem;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }

      &:before {
        bottom: -${constants.spacerSm2};
        width: calc(100% - ${constants.spacerSm3} * 2);
        left: ${constants.spacerSm3};
        background-color: ${vars.borderDefault};
      }

      &:after {
        bottom: -0.438rem;
        width: calc(100% - ${constants.spacerMd1} * 2);
        left: ${constants.spacerMd1};
        background-color: ${vars.trainualBrandBlueStrong};
      }
    `};
`;

export const GroupResponsibilityCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const HeaderInfoWrapper = styled.div`
  width: calc(100% - ${({ theme: { constants } }) => constants.spacerLg1});
  display: flex;
  flex-direction: column;
`;

export const GroupKindWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const GroupResponsibilitiesCountPill = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
  padding: 0 ${({ theme: { constants } }) => constants.spacerSm3};
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid transparent`};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  cursor: default;

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  }

  ${fontSm4};
`;

export const StyledIconButton = styled(IconButton)<{ $collapsed: boolean }>`
  ${({ $collapsed, theme: { constants, vars } }) => css`
    transition: transform 0.2s ease-in;
    padding: ${constants.spacerSm2};
    transform: ${$collapsed ? 'rotate(0deg)' : 'rotate(180deg)'};
    border-radius: ${constants.borderRadiusCircle};
    background-color: ${vars.foundationBase2};

    &:hover {
      background-color: ${vars.foundationBase3};
    }
  `};
`;

export const ResponsibilitiesWrapper = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${constants.spacerSm3};
    gap: ${constants.spacerSm3};

    ${Card} {
      cursor: pointer;
    }
  `};
`;

export const Title = styled.p<{ titleFirstWordLength: number }>`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  ${fontSm5};

  ${TruncatedText({})};
  ${({ titleFirstWordLength }) =>
    titleFirstWordLength < TITLE_FIRST_WORD_MAX_LENGTH &&
    MultilineTruncatedText({
      lineClamp: 2,
    })}
`;
