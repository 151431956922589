import React from 'react';
import styled, { css } from 'styled-components';

import Image from '../../../../../images/curriculum/image.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { fontMd1, fontSm5 } from '../../../../styled/TypeSystem';

const Illustration = styled.img`
  width: 9.375rem;
  max-height: 3.5rem;
`;

const Header = styled.div(
  ({ theme: { constants, vars } }) => css`
    color: ${vars.textDefault};
    font-weight: ${constants.fontSemibold};
    ${fontMd1};
  `
);

const Text = styled.div(
  ({ theme: { constants, vars } }) => css`
    color: ${vars.textSubdued};
    padding-top: ${constants.spacerSm3};
    ${fontSm5};
  `
);

const Wrapper = styled.div(
  ({ theme: { constants } }) => css`
    align-items: center;
    align-self: stretch;
    background: #ebf9ff;
    border-radius: ${constants.borderRadiusLg};
    display: flex;
    min-height: 5.5rem;
  `
);

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const t = initTranslations('curriculum_show.unpublished_content');

const UnpublishedContent = () => {
  const {
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();

  return (
    <Wrapper id='unpublished-content-banner'>
      <Illustration alt={t('illustration_aria_label')} src={Image} />
      <TextContainer>
        <Header>{t('header', { subject: curriculumSingular.toLowerCase() })}</Header>
        <Text>{t('body')}</Text>
      </TextContainer>
    </Wrapper>
  );
};

export default UnpublishedContent;
