import styled, { css } from 'styled-components';

import hexToRGBA from '../../../../lib/HexToRGBA';

const DraggingTimingFunction = css`
  transition: 0.3s ease-out;
`;

export const SyntheticPopup = styled.div`
  position: absolute;
  transform: translate(-50%, calc(-1.25rem - 100%));
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  background: ${({ theme: { vars } }) => vars.foundationSurface2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  pointer-events: none;
  z-index: 3;
  ${DraggingTimingFunction};

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: ${({ theme: { constants } }) => constants.spacerSm3} solid transparent;
    border-top-color: ${({ theme: { vars } }) => vars.foundationSurface2};
    border-bottom: 0;
  }
`;

const SensorDragState = css`
  transform: scale(1.3) translate(-40%, -40%);
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.textDefault};
`;

const DraggingState = (isDragging: boolean) => css`
  &:hover {
    ${SensorBox} {
      ${SensorDragState};
    }
  }

  ${isDragging &&
  css`
    ${SensorBox} {
      ${SensorDragState};
    }
  `};
`;

export const DynamicContainer = styled.div<{ isDragging: boolean }>`
  ${({ isDragging }) => {
    return css`
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      outline: none;
      width: 0;
      height: 0;

      ${DraggingTimingFunction};

      ${DraggingState(isDragging)};
    `;
  }};
`;

export const SensorBox = styled.div`
  ${({ theme: { constants, vars } }) => {
    return css`
      width: ${constants.spacerMd1};
      height: ${constants.spacerMd1};
      border: ${constants.borderWidthSm} solid ${vars.borderDefault};
      border-radius: ${constants.borderRadiusCircle};
      background: ${vars.foundationSurface1};
      transform: translate(-50%, -50%);

      ${DraggingTimingFunction};
    `;
  }};
`;

export const Container = styled.div<{ $color?: string }>`
  position: relative;
  width: calc(100% - 0.325rem);
  padding: ${({ theme: { constants } }) => constants.spacerSm2} 0;
  margin: 0 0.325rem;
  cursor: pointer;

  * {
    user-select: none;
  }
`;

export const Line = styled.div<{ $color: string }>`
  width: 100%;
  height: ${({ theme: { constants } }) => constants.spacerSm2};

  background-color: ${({ $color }) => hexToRGBA($color, 0.2)};
  border-radius: 0.625rem;
`;

export const Filler = styled.div<{ value?: number; $color?: string }>`
  height: 100%;
  width: ${({ value = 0 }) => value * 100}%;
  background: ${({ $color }) => $color};
  border-radius: 0.625rem;

  ${DraggingTimingFunction};
`;
