import { useFormikContext } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import Checkbox from '../../../../../../design_system/input/controls/Checkbox';
import { remToPx } from '../../../../../DelegationPlanner/shared/utils/remToPx';
import SpecializationPill from '../../../../../DelegationPlanner/SpecializationPill/SpecializationPill';
import RangeSlider from '../../../../../shared/RangeSlider/RangeSlider';
import { calculatePercentage } from '../../../../../shared/RangeSlider/utils/calculatePercentage';
import { ResponsibilityDetailsFormData } from '../types';
import { NUMBER_HEIGHT_WITH_SUSPENSION, SCORE_STATS } from './_data';
import {
  PopupText,
  ScoreNumber,
  ScoreTrack,
  SpecializationContainer,
  SpecializationHeader,
  SpecializationRangeSliderContainer,
} from './styles';
import { SpecializationProps } from './types';

const { MIN, MAX, STEP } = SCORE_STATS;
const NUMBERS = Array.from({ length: MAX + 1 }, (_, i) => i);

const t = initTranslations('delegation_planner.specialization');

const Specialization: FC<SpecializationProps> = ({
  color,
  responsibilitySpecializationId,
  checked = false,
  terminology,
  specializationScore: initialScore = 0,
}) => {
  const [score, setScore] = useState(initialScore);
  const [isRangeSliderOpen, setIsRangeSliderOpen] = useState(checked);
  const percentage = calculatePercentage(score, MIN, MAX);
  const numberHeightPx = remToPx(NUMBER_HEIGHT_WITH_SUSPENSION);
  const currentNumberPosition = (percentage / 100) * NUMBERS.length * numberHeightPx;
  const { values, setValues } = useFormikContext<ResponsibilityDetailsFormData>() ?? {};

  const handleOpenRangeSlider = useCallback(() => {
    setIsRangeSliderOpen(!isRangeSliderOpen);

    let newSpecializations = values.specializations.map((specialization) => {
      return specialization.responsibilitySpecializationId === responsibilitySpecializationId
        ? {
            ...specialization,
            _destroy: true,
          }
        : specialization;
    });

    if (isRangeSliderOpen) {
      newSpecializations = newSpecializations.map((specialization) =>
        specialization.responsibilitySpecializationId === responsibilitySpecializationId
          ? { ...specialization, score: 0 }
          : specialization
      );

      setScore(0);
    } else {
      newSpecializations = [
        ...new Set([
          ...values.specializations,
          ...[{ responsibilitySpecializationId, score, color, terminology }],
        ]),
      ];
    }

    setValues({ ...values, specializations: newSpecializations });
  }, [
    isRangeSliderOpen,
    values,
    setValues,
    responsibilitySpecializationId,
    score,
    color,
    terminology,
  ]);

  const onRangeSliderSlideMove = (newValue: number) => {
    if (newValue === score) return;

    setScore(newValue);
  };

  const onRangeSliderSlideEnd = (newValue: number) => {
    const roundedValue = Math.round(newValue);

    setScore(roundedValue);
    setValues({
      ...values,
      specializations: values.specializations.map((specialization) => {
        return specialization.responsibilitySpecializationId === responsibilitySpecializationId
          ? {
              ...specialization,
              specializationScore: roundedValue,
            }
          : specialization;
      }),
    });
  };

  useEffect(() => {
    if (checked) {
      setIsRangeSliderOpen(true);
      setScore(initialScore);
    }
    // We need to rerender only when checked changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const renderPopupContent = () => (
    <PopupText>
      {t('rating')} &nbsp;
      <ScoreTrack
        style={{
          marginTop: `-${Math.round(currentNumberPosition)}px`,
        }}
      >
        {NUMBERS.map((number) => (
          <ScoreNumber key={number}>{number}</ScoreNumber>
        ))}
      </ScoreTrack>
      /{MAX}
    </PopupText>
  );

  return (
    <SpecializationContainer>
      <SpecializationHeader>
        <SpecializationPill
          color={color}
          id={responsibilitySpecializationId}
          isAlwaysExpanded
          percentage={percentage}
          score={Math.round(score)}
          title={terminology}
        />

        <Checkbox
          checked={isRangeSliderOpen}
          id={`${terminology}-checkbox`}
          name={terminology}
          onCheck={handleOpenRangeSlider}
        />
      </SpecializationHeader>

      <SpecializationRangeSliderContainer isOpen={isRangeSliderOpen}>
        <RangeSlider
          color={color}
          id={responsibilitySpecializationId.toString()}
          max={MAX}
          min={MIN}
          onSlideEnd={onRangeSliderSlideEnd}
          onSlideMove={onRangeSliderSlideMove}
          popupContent={renderPopupContent()}
          step={STEP}
          value={score}
        />
      </SpecializationRangeSliderContainer>
    </SpecializationContainer>
  );
};

export default Specialization;
