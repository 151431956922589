import { capitalize } from 'lodash';
import React, { useCallback, useState } from 'react';

import {
  addSelectedGroupId,
  removeSelectedGroupId,
} from '../../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import Hoverable from '../../../../design_system/Hoverable';
import AvatarsGroup from '../../../shared/AvatarsGroup/AvatarsGroup';
import { TGroupSelectItemProps } from '../types';
import {
  ActionsWrapper,
  GroupKind,
  GroupLabelWrapper,
  GroupName,
  GroupSelectableElement,
  StyledIcon,
} from './styles';

const GroupSelectItem = ({
  group,
  isInitiallySelected,
  isCurrentlySelected,
}: TGroupSelectItemProps) => {
  const dispatch = useAppDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const isSelected = isCurrentlySelected || isInitiallySelected;
  const { id, name, usersCount, users, kind } = group;

  const handleItemSelectionToggle = useCallback(() => {
    if (isInitiallySelected) return;

    isCurrentlySelected ? dispatch(removeSelectedGroupId(id)) : dispatch(addSelectedGroupId(id));
  }, [dispatch, id, isCurrentlySelected, isInitiallySelected]);

  return (
    <Hoverable setIsHovered={setIsHovered}>
      <GroupSelectableElement
        id={`group-selectable-element-${id}`}
        isInitiallySelected={isInitiallySelected}
        isSelected={isSelected}
        onClick={handleItemSelectionToggle}
      >
        <GroupLabelWrapper>
          <GroupName>{name}</GroupName>
          <GroupKind>{capitalize(kind)}</GroupKind>
        </GroupLabelWrapper>
        <ActionsWrapper>
          {usersCount > 0 && (
            <AvatarsGroup i18Key='avatars' totalUserCount={usersCount} users={users} />
          )}
          <StyledIcon
            className='delegation-planner-select-group-icon'
            isCurrentlySelected={isCurrentlySelected}
            isInitiallySelected={isInitiallySelected}
            name='circle-check'
            size='sm'
            weight={isHovered || isSelected ? 'solid' : 'regular'}
          />
        </ActionsWrapper>
      </GroupSelectableElement>
    </Hoverable>
  );
};

export default GroupSelectItem;
