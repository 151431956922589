import React from 'react';
import styled from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { fontLg2 } from '../../../../../styled/TypeSystem';
import {
  CourseEmojiWrapper as EmojiWrapper,
  CourseTitle as Title,
  TitleWrapper,
} from '../shared/styles';
import { ReadOnlyProps } from './ReadOnlyTitle';

const Emoji = styled.span`
  ${fontLg2};
`;

const CourseTitle = ({ emoji, title }: ReadOnlyProps) => {
  return (
    <TitleWrapper>
      <EmojiWrapper>
        {emoji ? <Emoji>{emoji}</Emoji> : <Icon name='file-lines' size='lg' weight='regular' />}
      </EmojiWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
  );
};

export default CourseTitle;
