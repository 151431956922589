import React, { useState } from 'react';
import styled from 'styled-components';

import { GroupProvider } from '../../../../../contexts/GroupContext';
import initTranslations from '../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { useGetGroupQuery } from '../../../../../redux/services/resourceApis/groups/groupsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import ManageContentModal from '../../../groups/GroupsEditView/ContentTable/ManageContentModal/ManageContentModal';
import CurriculumCountBadge from '../../shared/CurriculumCountBadge';

const CurriculumsCountWrapper = styled.div`
  padding-right: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm1};
`;

type Props = {
  id: number;
  totalCurriculumsCount: number;
};

type ManageContentModalCTAProps = {
  setShowAddSubjectModal: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
};

const ManageContentModalCTA = ({ id, setShowAddSubjectModal }: ManageContentModalCTAProps) => {
  const { data: group, error: groupError, isLoading: groupLoading } = useGetGroupQuery(id);

  if (groupLoading) return <></>;
  if (groupError) return <BasicErrorDisplay error={groupError} />;
  if (!group) return <></>;

  return (
    <GroupProvider group={group}>
      <ManageContentModal closeModal={() => setShowAddSubjectModal(false)} />
    </GroupProvider>
  );
};

const t = initTranslations('curriculums.content.view_by_all_groups');

const ManageContentBadge = ({ id, totalCurriculumsCount }: Props) => {
  const [showAddSubjectModal, setShowAddSubjectModal] = useState(false);
  const {
    curriculum: { singular: curriculumTermSingular },
  } = useAccountTerminology();

  return (
    <CurriculumsCountWrapper>
      {totalCurriculumsCount ? (
        <CurriculumCountBadge totalCurriculumsCount={totalCurriculumsCount} />
      ) : (
        <DefaultButton
          buttonType='tertiary'
          id={`td-add-content-add-button-${id}`}
          onClick={() => setShowAddSubjectModal(true)}
          size='md'
          text={t('add_content', { subject: curriculumTermSingular.toLowerCase() })}
        />
      )}
      {showAddSubjectModal && (
        <ManageContentModalCTA id={id} setShowAddSubjectModal={setShowAddSubjectModal} />
      )}
    </CurriculumsCountWrapper>
  );
};

export default ManageContentBadge;
