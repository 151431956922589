import React from 'react';
import styled from 'styled-components';

import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../lib/initTranslations';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import { fontSm5 } from '../../../styled/TypeSystem';
import { IntegrationModalProps } from './index';

const IntegrationDetailsWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.constants.spacerMd3};
`;

const DetailName = styled.h6`
  margin-bottom: ${({ theme }) => theme.constants.spacerSm3};
  font-weight: ${({ theme }) => theme.constants.fontSemibold};
  ${fontSm5};
`;

const DetailValue = styled.p`
  margin-bottom: ${({ theme }) => theme.constants.spacerMd2};
  color: ${({ theme }) => theme.vars.textSubdued};
  ${fontSm5};
`;

const InfoSection = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.constants.spacerMd2};
  font-weight: ${({ theme }) => theme.constants.fontMedium};
  background-color: ${({ theme }) => theme.vars.foundationBase1};
  ${fontSm5};
`;

const t = initTranslations('view_sso_integration_modal');

interface ViewIntegrationModalProps extends IntegrationModalProps {
  integrationAppId: string;
}

const ViewIntegrationModal = ({
  showModal,
  closeModal,
  provider,
  integrationAppId,
}: ViewIntegrationModalProps) => {
  let guideLink: string | undefined;
  const { configs } = usePrivateConfigs();
  const oktaSetupGuide = configs['OKTA_SETUP_GUIDE_URL'];
  const googleSetupGuide = configs['GOOGLE_SETUP_GUIDE_URL'];
  const microsoftSetupGuide = configs['MICROSOFT_SETUP_GUIDE_URL'];

  if (provider === 'okta') {
    guideLink = oktaSetupGuide;
  } else if (provider === 'google') {
    guideLink = googleSetupGuide;
  } else {
    guideLink = microsoftSetupGuide;
  }

  const taskModalArgs: TaskModalProps = {
    title: t(`${provider}.title`),
    onCloseRequest: closeModal,
    processing: false,
    secondaryButtonText: t('close'),
    tertiaryButton: {
      text: t(`${provider}.open_guide`),
      task: () => {
        window.open(guideLink, '_blank');
      },
    },
    heapModalName: 'view-sso-integration-modal',
  };

  return (
    <>
      {showModal && (
        <TaskModal {...taskModalArgs} desktopSize='lg'>
          <IntegrationDetailsWrapper>
            <DetailName>{t('application_client_id')}</DetailName>
            <DetailValue>{integrationAppId}</DetailValue>
            <DetailName>{t('application_client_secret')}</DetailName>
            <DetailValue>************************</DetailValue>
          </IntegrationDetailsWrapper>
          <InfoSection>{t('helper_text')}</InfoSection>
        </TaskModal>
      )}
    </>
  );
};

export default ViewIntegrationModal;
