import React from 'react';

import ESignatureStatus from '../../../../shared/ESignatureStatus/ESignatureStatus';
import { ColumnComponentProps } from '../types';

const ESignature = ({ record, curriculumId }: ColumnComponentProps) => {
  const { e_signature, id } = record;

  return (
    <ESignatureStatus
      curriculumId={curriculumId}
      eSignature={e_signature}
      kind='by_user'
      userId={id}
    />
  );
};

export default ESignature;
