import React from 'react';
import styled from 'styled-components';
import { EditorUser } from 'types/Editor';

import Icon from '../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

const UsersContainer = styled.div`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  display: flex;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  cursor: default;
  ${fontSm4};
`;

const ToolTipContent = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  align-items: center;
  ${fontSm5};
`;

type Props = {
  additionalUsers: EditorUser[];
};

const AdditionalUsers = ({ additionalUsers }: Props) => {
  const additionalUsersCount = additionalUsers.length;

  return (
    <>
      <Tooltip
        content={
          <>
            {additionalUsers.map((additionalUser) => (
              <ToolTipContent key={`user-${additionalUser.clientId}-label`}>
                <Icon
                  name='circle'
                  secondaryColor={additionalUser.color}
                  size='2xs'
                  weight='duotone'
                />
                <span>{additionalUser.name}</span>
              </ToolTipContent>
            ))}
          </>
        }
        id='additional-users-tooltip'
        place='bottom'
      />
      <UsersContainer data-for='additional-users-tooltip' data-tip>
        +{additionalUsersCount}
      </UsersContainer>
    </>
  );
};

export default AdditionalUsers;
