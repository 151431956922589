import React, { useContext } from 'react';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import { useGetAccountSettingsQuery } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { SectionDivider } from '../AdvancedSettings/styles';
import BetaFeatures from './Forms/GeneralSettings/BetaFeatures';
import CompanyInfo from './Forms/GeneralSettings/CompanyInfo';
import LoginRestrictions from './Forms/GeneralSettings/LoginRestrictions';
import OrganizationLogo from './Forms/GeneralSettings/OrganizationLogo';
import RestrictToSSO from './Forms/GeneralSettings/RestrictToSSO';
import { TabSectionWrapper } from './styles';

const GeneralTabSection = () => {
  const { data, isLoading: isLoadingData } = useGetAccountSettingsQuery();
  const paywallCtx = useContext(PaywallContext);
  const restrictLoginByIpPaywalled = paywallCtx.includes('ip_access_restriction');

  if (isLoadingData || !data) return null;

  const {
    available_organization_sizes: availableOrganizationSizes,
    available_industries: availableIndustries,
    default_logo_url: defaultLogoUrl,
    logo_url: logoUrl,
    advanced_settings: advancedSettings,
  } = data;

  const { any_sso_integration_connected: anySsoIntegrationConnected } = advancedSettings;

  return (
    <TabSectionWrapper id='general-tab-section'>
      <CompanyInfo
        availableIndustries={availableIndustries}
        availableOrganizationSizes={availableOrganizationSizes}
      />
      <SectionDivider />
      <OrganizationLogo defaultLogoUrl={defaultLogoUrl} logoUrl={logoUrl} />
      <BetaFeatures />
      {anySsoIntegrationConnected && <RestrictToSSO />}
      {!restrictLoginByIpPaywalled && <LoginRestrictions />}
    </TabSectionWrapper>
  );
};

export default GeneralTabSection;
