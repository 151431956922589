import React from 'react';

import { SKELETON_CARDS } from '../_data';
import { SkeletonCardList, SkeletonColumn } from '../styles';
import BoardCardSkeleton from './BoardCardSkeleton';
import BoardHeaderSkeleton from './BoardHeaderSkeleton';

const BoardColumnSkeleton = () => {
  return (
    <SkeletonColumn>
      <BoardHeaderSkeleton />

      <SkeletonCardList>
        {SKELETON_CARDS.map((skeletonCard, index) => (
          <BoardCardSkeleton key={index} textTitles={skeletonCard.textTitles} />
        ))}
      </SkeletonCardList>
    </SkeletonColumn>
  );
};

export default BoardColumnSkeleton;
