type HTMLElementType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

type targetValueType = 'asString' | 'asInt';
type OnTargetValueChangeArgs<UnknownType> = [(value: UnknownType) => void, targetValueType?];

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
export default function onTargetValueChange<UnknownType extends any>(
  ...args: OnTargetValueChangeArgs<UnknownType>
) {
  const [callback, targetValueType = 'asString'] = args;
  return (e: React.ChangeEvent<HTMLElementType>) => {
    const value = targetValueType === 'asInt' ? parseInt(e.target.value) : e.target.value;
    callback(value as UnknownType);
  };
}
