import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import Poppable from '../../../../Poppable';
import OutsideClickHandler from '../../../shared/OutsideClickHandler';
import {
  ObjectIslandAction,
  ObjectIslandCloseWrapper,
  ObjectIslandMoreActions,
  ObjectIslandMoreActionsMenu,
  ObjectIslandMoreActionsMenuOption,
  ObjectIslandWrapper,
  SelectedCount,
} from './styles';
import { MenuActionsState, ObjectIslandProps } from './types';

const t = initTranslations('users_table.object_island');

const ObjectIsland = ({ selectedCount, actions, onCloseRequest }: ObjectIslandProps) => {
  const theme = useTheme();
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const { isDesktop, isTablet } = useWindowResize();
  const [menuActions, setMenuActions] = useState<MenuActionsState>();

  const maxMainActions = useMemo(() => {
    return isDesktop ? 3 : isTablet ? 2 : 1;
  }, [isDesktop, isTablet]);

  useEffect(() => {
    setMenuActions({
      displayedActions: actions.slice(0, maxMainActions),
      moreActions: actions.slice(maxMainActions),
    });
    return () => {
      setMenuActions(undefined);
    };
  }, [actions, maxMainActions]);

  if (!menuActions) return <></>;

  const { displayedActions, moreActions } = menuActions;

  return (
    <ObjectIslandWrapper>
      <SelectedCount>{t('selected', { count: selectedCount })}</SelectedCount>
      {displayedActions.map((action, index) => (
        <ObjectIslandAction key={`${action.label}-${index}`} onClick={action.onClick}>
          <Icon name={action.icon} />
          {action.label}
        </ObjectIslandAction>
      ))}
      <OutsideClickHandler onOutsideClick={() => setShowMoreMenu(false)}>
        <Poppable
          isOpen={showMoreMenu}
          item={
            <ObjectIslandMoreActionsMenu className='more-actions-menu'>
              {moreActions.map((action, index) => (
                <ObjectIslandMoreActionsMenuOption
                  className='more-actions-menu-option'
                  key={`${action.label}-${index}`}
                  onClick={action.onClick}
                >
                  <Icon name={action.icon} />
                  {action.label}
                </ObjectIslandMoreActionsMenuOption>
              ))}
            </ObjectIslandMoreActionsMenu>
          }
          menuStyling={{ marginBottom: `${theme.constants.spacerMd3}` }}
          onClick={() => ({})}
          placement='top'
          trigger={
            moreActions.length ? (
              <ObjectIslandMoreActions onClick={() => setShowMoreMenu(!showMoreMenu)}>
                <Icon name='ellipsis' />
                {t('more')}
              </ObjectIslandMoreActions>
            ) : (
              <></>
            )
          }
        />
      </OutsideClickHandler>
      <ObjectIslandCloseWrapper
        className='bulk-user-management-menu-close'
        onClick={onCloseRequest}
      >
        <Icon name='close' />
      </ObjectIslandCloseWrapper>
    </ObjectIslandWrapper>
  );
};

export default ObjectIsland;
