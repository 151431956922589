import React from 'react';

import ColorPicker from '../../../design_system/buttons/DeprecatedColorPicker';
import DropdownWithPoppableMenu from '../../shared/DropdownWithPoppableMenu';
import { DEFAULT_ACCENT_COLORS, HORIZONTAL_LINE_WIDTH } from '../shared/constants/accountSettings';
import { ColorPickerWrapper, HorizontalLineRow, Line, LineItem, SelectedLine } from './styles';
import { HorizontalLineProps } from './types';

const HorizontalLine = ({ brandStylesHr, setHrColor, setHrWidth }: HorizontalLineProps) => {
  const lineOptions = HORIZONTAL_LINE_WIDTH.map((item, index) => (
    <LineItem key={`option-item-${index}`}>
      <Line color={brandStylesHr['border-color']} height={item} />
    </LineItem>
  ));

  const selectedLineIndex = HORIZONTAL_LINE_WIDTH.indexOf(brandStylesHr['border-width']);

  const selectedLine = (
    <SelectedLine>
      <Line color={brandStylesHr['border-color']} height={brandStylesHr['border-width']} />
    </SelectedLine>
  );

  return (
    <HorizontalLineRow>
      <DropdownWithPoppableMenu
        activeOptionIndex={selectedLineIndex}
        id='horizontal-line-dropdown'
        menuId='horizontal-line-menu'
        menuPlacement='bottom-end'
        options={lineOptions}
        selectedOption={selectedLine}
        setSelectedOption={(index) => setHrWidth(HORIZONTAL_LINE_WIDTH[index])}
      />
      <ColorPickerWrapper>
        <ColorPicker
          ariaLabel='horizontal-line-color'
          buttonId='horizontal-line-color-button'
          color={brandStylesHr['border-color']}
          defaultColors={DEFAULT_ACCENT_COLORS}
          menuId='horizontal-line-color'
          name='horizontal-line-color'
          setColor={setHrColor}
          showTextField={false}
          type='background-color'
        />
      </ColorPickerWrapper>
    </HorizontalLineRow>
  );
};

export default HorizontalLine;
