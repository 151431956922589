import { useAccountTerminology } from '../../components/AccountTerminologyProvider';
import { CardType } from '../../components/application/searches/SearchResultsPage/search_result_cards/SearchResultsCard/types';
import initTranslations from '../../lib/initTranslations';

const t = initTranslations('search_results_page.badges');

export function useGetSearchResultCardBadgeText(cardType: CardType) {
  const {
    curriculum: { singular: curriculumTermPlural },
    topic: { singular: topicTermPlural },
    step: { singular: stepTermPlural },
  } = useAccountTerminology();

  switch (cardType) {
    case 'Curriculum':
      return curriculumTermPlural;
    case 'Course':
      return topicTermPlural;
    case 'Step':
      return stepTermPlural;
    case 'Survey::Survey':
      return t('test');
    case 'Survey::Question':
      return t('test_question');
    case 'Group':
      return t('group');
    case 'User':
      return t('teammate');
    case 'Template':
      return t('template');
    case 'Flowchart':
      return t('flowchart');
    default:
      return '';
  }
}
