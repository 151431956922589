import React from 'react';

import DefaultButton from '../../../design_system/buttons/DefaultButton';
import {
  VideoEmptyStateActionDescription,
  VideoEmptyStateActionSection,
  VideoEmptyStateActionTitle,
  VideoEmptyStateContainer,
  VideoEmptyStateTitle,
} from './styles';
import { TVideoEmptyStateProps } from './types';
import VideoPreview from './VideoPreview/VideoPreview';

const VideoEmptyState = ({
  title,
  videoUrl,
  videoPreviewSrc,
  actionTitle,
  actionButtonFunction,
  actionButtonText,
  actionDescription,
  openPlayerCallback,
  showWatchSvg,
}: TVideoEmptyStateProps) => {
  return (
    <VideoEmptyStateContainer>
      {title && <VideoEmptyStateTitle>{title}</VideoEmptyStateTitle>}
      <VideoPreview
        openPlayerCallback={openPlayerCallback}
        showWatchSvg={showWatchSvg}
        videoPreviewSrc={videoPreviewSrc}
        videoUrl={videoUrl}
      />
      {actionButtonFunction && (
        <VideoEmptyStateActionSection>
          <VideoEmptyStateActionTitle>
            <span dangerouslySetInnerHTML={{ __html: actionTitle }} />
          </VideoEmptyStateActionTitle>
          <VideoEmptyStateActionDescription>{actionDescription}</VideoEmptyStateActionDescription>
          <DefaultButton
            buttonType='primary'
            fullWidth
            id='video-empty-state-action-button'
            onClick={actionButtonFunction}
            size='lg'
            text={actionButtonText}
          />
        </VideoEmptyStateActionSection>
      )}
    </VideoEmptyStateContainer>
  );
};

export default VideoEmptyState;
