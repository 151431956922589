import React, { useEffect, useState } from 'react';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import { useGroupsModals } from '../../../../../contexts/GroupsModalContext';
import { useOrgChartConfigSlideout } from '../../../../../contexts/OrgChartConfigSlideoutContext';
import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Line from '../../../../design_system/Triage/layout/Line';
import FlexContainer from '../../../../styled/FlexContainer';
import ComposeButton from '../../../ai/ComposeButton/ComposeButton';
import {
  CreateButtonWrapper,
  HeaderDescription,
  HeaderTitle,
  HeaderWrapper,
  SavingLabel,
  StyledIcon,
} from './styles';

const OrgChartConfigSlideoutHeader = () => {
  const {
    orgChartResourceType,
    isSubmitting,
    hasSubmitted,
    setHasSubmitted,
    isGroupType,
    resources,
    rootResource,
  } = useOrgChartConfigSlideout();
  const t = initTranslations(`org_chart.${orgChartResourceType}_config_slideout.header`);
  const { setTeammateModalVisible } = useTeammateModal();
  const { dispatch } = useGroupsModals();
  const [composeButtonLoading, setComposeButtonLoading] = useState(false);
  const autogenerateEnabled = orgChartResourceType === 'group';
  const { productTerm } = useAccountTerminology();

  const composeButton = (onClick: () => void) => {
    return (
      <DefaultButton
        buttonType='tertiary'
        disabled={resources.length < 3}
        iconName='wand-magic-sparkles'
        id='compose-role-ai-generate-chart'
        onClick={onClick}
        text={
          composeButtonLoading
            ? t('ai.hang_tight')
            : rootResource
            ? t('ai.regenerate_button')
            : t('ai.generate_button')
        }
      />
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => !isSubmitting && setHasSubmitted(false), 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [setHasSubmitted, isSubmitting]);

  return (
    <HeaderWrapper className='header-wrapper'>
      <FlexContainer align='flex-start' direction='column'>
        <SavingLabel visible={hasSubmitted}>
          {isSubmitting ? (
            <>
              <StyledIcon name='rotate' weight='regular' />
              {t('saving')}
            </>
          ) : (
            <>
              <StyledIcon name='check' weight='solid' />
              {t('saved')}
            </>
          )}
        </SavingLabel>
        <HeaderTitle>{t('title')}</HeaderTitle>
        <HeaderDescription>
          {autogenerateEnabled ? t('subtitle', { productTerm }) : t('subtitle')}
        </HeaderDescription>
        <CreateButtonWrapper>
          <DefaultButton
            buttonType='primary'
            id={isGroupType ? 'add-group-button' : 'invite-my-team-button'}
            onClick={() => {
              isGroupType
                ? dispatch({ type: 'openGroupNameModal' })
                : setTeammateModalVisible(true);
            }}
            size='md'
            text={t('create_resource_modal_button', {
              resource_type: orgChartResourceType === 'group' ? 'role' : orgChartResourceType,
            })}
          />
          {autogenerateEnabled && (
            <ComposeButton
              background='gray'
              composeModalType='autogenerateRoleChart'
              customButton={composeButton}
              disabled={resources.length < 3}
              disabledTooltipText={t('ai.generate_button_disabled_tooltip')}
              onComposeButtonLoadingChange={setComposeButtonLoading}
              text={
                composeButtonLoading
                  ? t('ai.hang_tight')
                  : rootResource
                  ? t('ai.regenerate_button')
                  : t('ai.generate_button')
              }
            />
          )}
          <Line margin='1rem 0' />
        </CreateButtonWrapper>
      </FlexContainer>
    </HeaderWrapper>
  );
};

export default OrgChartConfigSlideoutHeader;
