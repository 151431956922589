import React, { useCallback, useEffect, useState } from 'react';

import { useConsumptionCourseContext } from '../../../../../../contexts/ConsumptionCourseContext';
import { usePreventNavigation } from '../../../../../../hooks/usePreventNavigation';
import initTranslations from '../../../../../../lib/initTranslations';
import {
  useGetDocumentViewSessionLinkQuery,
  useSignDocumentMutation,
} from '../../../../../../redux/services/resourceApis/eSignatures/eSignatureApi';
import { CardBackgroundShadowed } from '../../../shared/styles';
import RestrictLeavingSigningStepModal from '../RestrictLeavingSigningStepModal/RestrictLeavingSigningStepModal';
import ESignatureDescriptionText from './ESignatureDescriptionText/ESignatureDescriptionText';
import ESignatureErrorMessage from './ESignatureErrorMessage/ESignatureErrorMessage';
import ESignatureLoader from './ESignatureLoader/ESignatureLoader';
import ESignatureTitle from './ESignatureTitle/ESignatureTitle';
import { Description, ESignatureText, StyledIframe } from './styles';
import { DocumentEventType, ESignatureContainerProps } from './types';

const t = initTranslations('e_signature.container');

const ESignatureContainer = ({ stepId }: ESignatureContainerProps) => {
  const {
    course: { signatureElementId },
    setIsCourseCompleteShowing,
    setIsESignatureShowing,
  } = useConsumptionCourseContext();
  const { data, isLoading, isSuccess } = useGetDocumentViewSessionLinkQuery({ signatureElementId });
  const [showRestrictLeavingSigningStepModal, setShowRestrictLeavingSigningStepModal] =
    useState(false);
  const [signDocument, { isSuccess: isSuccessSign, isError: signDocumentError }] =
    useSignDocumentMutation();
  const isDocumentSuccessfullyLoaded = isSuccess && !signDocumentError;
  const blockNavigation = isLoading || isDocumentSuccessfullyLoaded;

  const handleDocumentResponse = useCallback(
    (event: DocumentEventType) => {
      const type = event.data && event.data.type;

      switch (type) {
        case 'session_view.document.completed':
          if (data?.signable_document_id) {
            signDocument({ signableDocumentId: data?.signable_document_id, stepId });
          }
          break;
        case 'session_view.document.exception':
          setIsESignatureShowing(false);
      }
    },
    [data?.signable_document_id, setIsESignatureShowing, signDocument, stepId]
  );

  const unblockNavigation = usePreventNavigation(({ retry }) => {
    if (blockNavigation) {
      setShowRestrictLeavingSigningStepModal(true);
    } else {
      unblockNavigation();
      setIsESignatureShowing(false);
      retry();
    }
  });

  useEffect(() => {
    if (data?.is_document_signed && data?.signable_document_id) {
      signDocument({ signableDocumentId: data?.signable_document_id, stepId });
    }
  }, [data, signDocument, stepId]);

  useEffect(() => {
    if (isSuccessSign) {
      setIsESignatureShowing(false);
      setIsCourseCompleteShowing(true);
    }
  }, [isSuccessSign, setIsCourseCompleteShowing, setIsESignatureShowing]);

  useEffect(() => {
    window.addEventListener('message', handleDocumentResponse);

    return () => window.removeEventListener('message', handleDocumentResponse);
  }, [handleDocumentResponse]);

  return (
    <CardBackgroundShadowed id='e-signature-container'>
      <ESignatureText text={t('e_signature')} textAlign='right' />
      <ESignatureTitle />
      {isLoading ? (
        <>
          <ESignatureLoader />
          <Description text={<ESignatureDescriptionText />} />
        </>
      ) : isDocumentSuccessfullyLoaded ? (
        <>
          <StyledIframe id='e-signature-iframe' src={data?.document_url} />
          <Description text={<ESignatureDescriptionText />} />
        </>
      ) : (
        <ESignatureErrorMessage />
      )}
      {showRestrictLeavingSigningStepModal && (
        <RestrictLeavingSigningStepModal
          setShowRestrictLeavingSigningStepModal={setShowRestrictLeavingSigningStepModal}
        />
      )}
    </CardBackgroundShadowed>
  );
};

export default ESignatureContainer;
