import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Badge from '../../../../../design_system/display/badge';
import { ColumnComponentProps } from '../types';

const t = initTranslations('reports.element_reports_table.columns');

const UsersCompletedCount = ({ record: { users_completed_count } }: ColumnComponentProps) => {
  const hasCompletedUsers = users_completed_count > 0;
  const statusBadgeType = hasCompletedUsers ? 'success' : 'general';
  const badgeText = t('users_completed_count', { count: users_completed_count || 0 });

  return <Badge text={badgeText} type={statusBadgeType} />;
};

export default UsersCompletedCount;
