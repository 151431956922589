import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { ToggleContainer } from '../../../AdvancedSettings/styles';
import {
  FormSectionDescription,
  FormSectionTitle,
  ToggleInfoSection,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';
import CustomTerminologyForm from './CustomTerminologyForm';

const t = initTranslations('account_settings.content_settings.terminology');

const CustomTerminologySection = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <ToggleContainer>
        <Toggle
          checked={values.custom_terminology}
          className='custom-terminology-setting'
          handleToggle={() => setFieldValue('custom_terminology', !values.custom_terminology)}
          id='enable-custom-terminology'
          name='custom_terminology'
        />
      </ToggleContainer>
      <ToggleInfoSection>
        <div>
          <FormSectionTitle>{t('toggle_title')}</FormSectionTitle>
          <FormSectionDescription>{t('toggle_description')}</FormSectionDescription>
        </div>
        {values.custom_terminology && <CustomTerminologyForm />}
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default CustomTerminologySection;
