import React, { useState } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { useCreateFeedbackMutation } from '../../../../redux/services/resourceApis/aiFeedbacks/aiFeedbacksApi';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import TextareaField from '../../../design_system/Triage/fields/TextAreaField';
import { AiFeedbackModalProps } from './types';

const t = initTranslations('compose.feedback_modal');

const AiFeedbackModal = ({
  setShowAiFeedbackModal,
  aiCompletionId,
  setFeedbackStatus,
  metadata,
}: AiFeedbackModalProps) => {
  const [comment, setComment] = useState<string | undefined>();
  const [createFeedback] = useCreateFeedbackMutation();
  const fixedFeedbackCategory = 'generic_irrelevant'; // Fixed category

  const handleSubmit = () => {
    if (!aiCompletionId) return;

    createFeedback({
      aiCompletionId,
      comment,
      category: fixedFeedbackCategory,
      metadata,
    });
    setShowAiFeedbackModal(false);
    setFeedbackStatus('complete');
  };

  const taskModalArgs: TaskModalProps = {
    processing: false,
    desktopSize: 'lg',
    title: t('report_answer'),
    badgeType: 'success',
    badgeText: '',
    primaryButtonText: t('report_to_trainual'),
    primaryButtonTask: handleSubmit,
    secondaryButtonText: t('cancel'),
    onCloseRequest: () => setShowAiFeedbackModal(false),
    heapModalName: 'ai-feedback-modal',
  };

  return (
    <TaskModal {...taskModalArgs}>
      <TextareaField
        onChange={(e) => setComment(e.target.value)}
        placeholder={t('placeholder')}
        rows={4}
        value={comment}
      />
    </TaskModal>
  );
};

export default AiFeedbackModal;
