import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { ToggleContainer } from '../../../AdvancedSettings/styles';
import {
  FormSectionDescription,
  FormSectionTitle,
  ToggleInfoSection,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings.brand_styles');

const LockContentStyles = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <ToggleContainer>
        <Toggle
          checked={values.lock_brand_styles}
          className='lock-brand-styles-setting'
          handleToggle={() => setFieldValue('lock_brand_styles', !values.lock_brand_styles)}
          id='lock-brand-styles'
          name='lock_brand_styles'
        />
      </ToggleContainer>
      <ToggleInfoSection>
        <div>
          <FormSectionTitle>{t('lock_content_styles_title')}</FormSectionTitle>
          <FormSectionDescription>{t('lock_content_styles_description')}</FormSectionDescription>
        </div>
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default LockContentStyles;
