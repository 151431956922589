import styled, { css } from 'styled-components';

import DefaultUploadAvatarIcon from '../../../../../images/default_upload_avatar_icon.svg';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxMd, mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontLg4, fontSm2 } from '../../../../styled/TypeSystem';
import { ImageForm } from '../../../image_upload/ImageUploadTypes';

export const ImageUploadContainer = styled.div<{ isImageAttached: boolean }>`
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerMd2}`};
  background-color: ${({ isImageAttached, theme: { vars } }) =>
    isImageAttached ? vars.foundationBase1 : vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  border: ${({ isImageAttached, theme: { constants, vars } }) =>
    `${constants.borderWidthSm} ${isImageAttached ? 'solid' : 'dashed'} ${vars.borderSurface2}`};
  max-width: 19rem;

  @media (min-width: ${mediaBreakpointPxSm}) {
    padding: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

export const ImageUploadTopContainer = styled.div<{ imageForm?: ImageForm }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const ImageUploadView = styled.div<{ imageForm?: ImageForm }>`
  position: relative;
  overflow: hidden;
  will-change: transform;
  border-radius: ${({ imageForm, theme: { constants } }) =>
    imageForm === 'circle' ? constants.borderRadiusCircle : '0'};
  width: ${({ imageForm = 'circle' }) => (imageForm === 'circle' ? '4rem' : '17.5rem')};
  height: ${({ imageForm = 'circle' }) => (imageForm === 'circle' ? '4rem' : '8.5rem')};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
    width: ${({ imageForm = 'circle' }) => (imageForm === 'circle' ? '7.5rem' : '17.5rem')};
    height: ${({ imageForm = 'circle' }) => (imageForm === 'circle' ? '7.5rem' : '8.5rem')};
  }
`;

const defaultAvatarPlaceholder = css`
  background-image: url(${DefaultUploadAvatarIcon});
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

export const AvatarPlaceholder = styled.div<{ showInitials?: boolean }>`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  background-color: ${({ theme: { vars } }) => vars.textDisabled};
  color: ${({ theme: { vars } }) => vars.foundationSurface1};
  ${({ showInitials }) => !showInitials && defaultAvatarPlaceholder};

  ${fontLg4};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

export const ImageUploadButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin: ${({ theme: { constants } }) => `${constants.spacerMd2} 0`};
  }
`;

export const DescriptionSection = styled.div`
  display: none;

  @media (min-width: ${mediaBreakpointPxSm}) {
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const DescriptionText = styled.p`
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm2};
`;

export const Dropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;

export const PlaceholdersContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${mediaBreakpointPxMd}) {
    height: 11rem;
  }
`;

export const SvgImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledUploadButton = styled(DefaultButton)<{ isCentered: boolean }>`
  ${({ isCentered }) => isCentered && `margin: auto;`};
`;
