import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import {
  BrandingDataResponse,
  ClearbitDataResponse,
  CreateChatLogsParams,
  GetChatLogsResponse,
  RequestParams,
  WebsiteContentDataResponse,
} from './types';

export const aiBotApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getBrandingData: builder.query<BrandingDataResponse, RequestParams>({
      query: (params) => ({
        url: 'ajax/ai_bot/poc/branding_data',
        params: toSnakeCase(params),
      }),
      transformResponse: (returnValue: BrandingDataResponse) => {
        return toCamelCase<BrandingDataResponse>(returnValue);
      },
    }),
    getWebsiteContent: builder.query<WebsiteContentDataResponse, RequestParams>({
      query: (params) => ({
        url: 'ajax/ai_bot/poc/website_content',
        params: toSnakeCase(params),
      }),
      transformResponse: (returnValue: WebsiteContentDataResponse) => {
        return toCamelCase<WebsiteContentDataResponse>(returnValue);
      },
    }),
    getChatLogs: builder.query<GetChatLogsResponse, void>({
      query: () => ({
        url: 'ajax/ai_bot/chat_logs',
      }),
      transformResponse: (returnValue: GetChatLogsResponse) => {
        return toCamelCase<GetChatLogsResponse>(returnValue);
      },
      providesTags: () => [{ type: 'ChatLog', id: 'LIST' }],
    }),
    createChatLog: builder.mutation<void, CreateChatLogsParams>({
      query: (params) => ({
        method: 'POST',
        url: 'ajax/ai_bot/chat_logs',
        body: toSnakeCase(params),
      }),
      invalidatesTags: [{ type: 'ChatLog', id: 'LIST' }],
    }),
    getClearbitData: builder.query<ClearbitDataResponse, void>({
      query: () => ({
        url: 'ajax/ai_bot/clearbit',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useCreateChatLogMutation, useGetChatLogsQuery, useGetClearbitDataQuery } = aiBotApi;
