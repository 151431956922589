import { ReactFlowProvider } from '@xyflow/react';
import React from 'react';

import { FlowchartConsumptionDataProvider } from '../../../../contexts/FlowchartConsumptionDataContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useMinLoadingTime from '../../../../hooks/useMinLoadingTime';
import { useGetFlowchartQuery } from '../../../../redux/services/resourceApis/flowcharts/flowchartsApi';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import Body from './Body';

type Props = {
  id: number;
};

const FlowchartBase = ({ id }: Props) => {
  const {
    slug,
    splitFeatures: { contentFlowchartsEnabled },
  } = useCurrentAccount();
  const { data, isError, isLoading } = useGetFlowchartQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const isDelayedLoading = useMinLoadingTime(isLoading);

  if (!contentFlowchartsEnabled) {
    routeTo(routes.home({ slug }));
    return null;
  }

  return (
    <ReactFlowProvider>
      <FlowchartConsumptionDataProvider data={data} isError={isError} isLoading={isDelayedLoading}>
        <Body data={data} isLoading={isDelayedLoading} />
      </FlowchartConsumptionDataProvider>
    </ReactFlowProvider>
  );
};

export default FlowchartBase;
