import React from 'react';
import { Route } from 'type-route';

import initTranslations from '../../../../../../lib/initTranslations';
import { useCreateSurveyQuestionOptionMutation } from '../../../../../../redux/services/resourceApis/surveyQuestionOptions/surveyQuestionOptionsApi';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import { ChooseOptionsFormTitle, StyledAddChoiceLink } from '../../../shared/styles';
import ChoiceElement from './ChoiceElement';
import { ChooseOptionsProps } from './types';

const t = initTranslations('survey_edit.question.choose_options_form');

const ChooseOptionsForm = ({ options, surveyAnswerType }: ChooseOptionsProps) => {
  const route = useRoute();
  const {
    params: { id: questionId, surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;
  const [addSurveyOption, result] = useCreateSurveyQuestionOptionMutation();
  const { isLoading } = result;
  const formTitle = t(`${surveyAnswerType}_title`);

  return (
    <>
      <ChooseOptionsFormTitle>{formTitle}</ChooseOptionsFormTitle>
      {options.map((option) => (
        <ChoiceElement
          key={`survey-question-option-${option.id}`}
          option={option}
          surveyAnswerType={surveyAnswerType}
        />
      ))}
      {surveyAnswerType !== 'true_or_false' && (
        <StyledAddChoiceLink
          behavesAs='button'
          disabled={isLoading}
          onClick={() => addSurveyOption({ surveyId, questionId })}
          text={t('add_choice')}
        />
      )}
    </>
  );
};

export default ChooseOptionsForm;
