import { HTMLContent, JSONContent, useEditor } from '@tiptap/react';
import React from 'react';
import { useTheme } from 'styled-components';

import { EditorProvider } from '../../../../contexts/EditorContext';
import useContentStyles from '../../../../hooks/useContentStyles';
import { EditorExtensions } from '../shared/constants/extensions';
import { StyledEditorContent } from '../shared/styles';

type Props = {
  data: JSONContent | HTMLContent;
};

const PDFStepEditor = ({ data }: Props) => {
  const contentStyles = useContentStyles();
  const { palettes } = useTheme();

  const editor = useEditor(
    {
      extensions: EditorExtensions({ isEditable: false, palettes }),
      content: data,
      autofocus: false,
      editable: false,
    },
    [data]
  );

  if (!editor) return <></>;

  return (
    <EditorProvider editor={editor}>
      <StyledEditorContent
        $contentStyles={contentStyles}
        className='editor-content'
        editor={editor}
      />
    </EditorProvider>
  );
};

export default PDFStepEditor;
