import React from 'react';
import styled from 'styled-components';

import { GroupsModalProvider, useGroupsModals } from '../../../../../contexts/GroupsModalContext';
import { useOrgChartConfigSlideout } from '../../../../../contexts/OrgChartConfigSlideoutContext';
import { TeammateModalProvider } from '../../../../../contexts/TeammateContext';
import CreateGroupNameModal from '../../../groups/modals/CreateGroupNameModal/CreateGroupNameModal';
import SlideoutPanel from '../../../shared/slideout';
import AddTeammateWithConfirmation from '../../TeammateModal/AddTeammateWithConfirmation';
import OrgChartConfigSlideoutHeader from './OrgChartConfigSlideoutHeader';
import OrgChartConfigSlideoutHighestRanking from './OrgChartConfigSlideoutHighestRanking';
import OrgChartConfigSlideoutSetReportsTo from './OrgChartConfigSlideoutSetReportsTo';

const SlideoutBodyWrapper = styled.div`
  padding: ${({ theme: { constants } }) =>
    `0 ${constants.spacerMd2} ${constants.spacerMd2} ${constants.spacerMd2}`};
`;

const SlideoutBody = () => {
  return (
    <SlideoutBodyWrapper>
      <OrgChartConfigSlideoutHeader />
      <OrgChartConfigSlideoutHighestRanking />
      <OrgChartConfigSlideoutSetReportsTo />
    </SlideoutBodyWrapper>
  );
};

const GroupSlideoutBody = () => {
  const {
    state: { activeModal },
  } = useGroupsModals();

  return (
    <>
      <SlideoutBody />
      {activeModal === 'groupName' && <CreateGroupNameModal />}
    </>
  );
};

const Slideout = () => {
  const { isGroupType } = useOrgChartConfigSlideout();

  if (isGroupType) {
    return (
      <GroupsModalProvider redirectToNewGroup={false}>
        <GroupSlideoutBody />
      </GroupsModalProvider>
    );
  } else {
    return (
      <TeammateModalProvider>
        <SlideoutBody />
        <AddTeammateWithConfirmation />
      </TeammateModalProvider>
    );
  }
};

const OrgChartConfigSlideout = () => {
  return (
    <SlideoutPanel slideoutType='org-chart-config-slideout' slideoutWidth='23rem'>
      <Slideout />
    </SlideoutPanel>
  );
};

export default OrgChartConfigSlideout;
