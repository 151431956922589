import { Form, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import ActiveSettingsTab from '../ActiveSettingsTab';
import { SaveButtonWrapper } from '../styles';
import { SettingsData } from '../types';

const t = initTranslations('account_settings.general_settings');

type Props = {
  activeTab?: string;
  isLoading: boolean;
};

const AccountSettingsForm = ({ activeTab, isLoading }: Props) => {
  const { values, handleSubmit, initialValues } = useFormikContext<SettingsData>();

  const isValuesUnchanged = isEqual(values, initialValues);

  return (
    <Form
      autoComplete='off'
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSubmit();
        }
      }}
    >
      <ActiveSettingsTab activeTab={activeTab} />
      <SaveButtonWrapper id='account-settings-save-wrapper'>
        <DefaultButton
          disabled={isValuesUnchanged}
          id='save-account-settings-button'
          loading={isLoading}
          text={t('save')}
        />
      </SaveButtonWrapper>
    </Form>
  );
};

export default AccountSettingsForm;
