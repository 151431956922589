import styled from 'styled-components';

import { MainSectionContent } from '../../../../shared/FullScreenOverlay/styles';

export const StyledMainSectionContent = styled(MainSectionContent)`
  max-width: 37.5rem;
  gap: 4rem;
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
