import styled from 'styled-components';

import { fontMd1, fontSm4 } from '../../../styled/TypeSystem';

export const BoardEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 8.5rem);
`;

export const BoardEmptyStateImageContainer = styled.img`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const BoardEmptyStateTitle = styled.p`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontMd1};
`;

export const BoardEmptyStateText = styled.p`
  ${fontSm4};
`;
