import React from 'react';
import { Route } from 'type-route';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import usePageTitle from '../../../../hooks/usePageTitle';
import initTranslations from '../../../../lib/initTranslations';
import { useGetCurriculumBreadcrumbsDataQuery } from '../../../../redux/services/resourceApis/reports/reportsApi';
import Breadcrumbs from '../../../design_system/navigation/Breadcrumbs';
import { routes as routesReact } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import CurriculumReportHeadCard from '../CurriculumReportHeadCard/CurriculumReportHeadCard';
import { TabsWrapper } from '../Styles';
import CurriculumReportsTabs from '../Tabs/CurriculumReportsTabs/CurriculumReportsTabs';
import ElementReportFilters from './ElementReportFilters/index';
import ElementReportTable from './ElementReportTable';

const t = initTranslations('reports.navigation');

const ElementReportPage = ({
  route: {
    params: { id },
  },
}: {
  route: Route<typeof routesReact.curriculumByElementReport>;
}) => {
  const { slug } = useCurrentAccount();
  const { isLoading, data } = useGetCurriculumBreadcrumbsDataQuery({ id });
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceName: data?.curriculumTitle, resourceType: 'report', productTerm });

  if (isLoading) return <></>;
  if (!data) return <></>;

  return (
    <PageContent>
      <Breadcrumbs
        currentPageText={data.curriculumTitle}
        redirectPath={routesReact.allCurriculumsReport({ slug }).href}
        redirectText={t('reports')}
      />
      <CurriculumReportHeadCard id={id} />
      <TabsWrapper>
        <CurriculumReportsTabs curriculumId={id} />
      </TabsWrapper>

      <>
        <ElementReportTable curriculumId={id} />
        <ElementReportFilters curriculumId={id} />
      </>
    </PageContent>
  );
};

export default ElementReportPage;
