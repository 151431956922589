import { differenceInDays, format, isTomorrow } from 'date-fns';

import initTranslations from './initTranslations';

const t = initTranslations('relative_time');

/**
 * Time Helpers
 * Before creating a new time helper, please review the date-fns package
 * Read More: https://date-fns.org/v3.6.0/docs/
 */

/**
 * Calculates the number of days until a given date from today.
 * Returns 0 if the date is in the past.
 * Returns 1 if the date it tomorrow or within 24 hours.
 *
 * @param {Date} date - The target date.
 * @returns {number} - The number of days until the target date (0 or greater).
 */
export const daysUntil = (date: Date): number => {
  const targetDate = new Date(date);
  if (isTomorrow(targetDate)) {
    return 1;
  }

  const today = new Date();
  const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const targetDateUTC = Date.UTC(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate()
  );

  const daysDifference = differenceInDays(targetDateUTC, todayUTC);

  return Math.max(0, daysDifference);
};

/**
 * Formats a UNIX timestamp into a human-readable date string.
 *
 * @param unixTimestamp - The UNIX timestamp to format.
 * @returns The formatted date string in 'MMM DD, YYYY' format.
 */
export const formatDate = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000);

  return format(date, 'MMM d, yyyy');
};

/**
 * Formats a UNIX timestamp into a relative time string (e.g., 'just now', 'today', 'yesterday').
 *
 * @param unixTimestamp - The UNIX timestamp to format.
 * @returns The relative time string.
 */
export const relativeTimeFormat = (unixTimestamp: number) => {
  const timestampMs = unixTimestamp * 1000;
  const now = new Date();
  const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  const yesterdayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  yesterdayMidnight.setDate(todayMidnight.getDate() - 1);

  if (timestampMs >= now.getTime() - 60 * 1000) {
    return t('just_now');
  } else if (timestampMs >= todayMidnight.getTime()) {
    return t('today');
  } else if (timestampMs >= yesterdayMidnight.getTime()) {
    return t('yesterday');
  } else {
    return formatDate(unixTimestamp);
  }
};
