import { DefaultTheme } from 'styled-components';

type GetBackgroundColorProps = {
  isSelected: boolean;
  isCorrect: boolean;
  theme: DefaultTheme;
};

export default function getBackgroundColor({
  isSelected,
  isCorrect,
  theme,
}: GetBackgroundColorProps) {
  if (!isSelected) return theme.vars.foundationSurface1;

  return isCorrect ? theme.vars.stateHoverSuccess : theme.vars.stateHoverError;
}
