import styled, { css } from 'styled-components';

import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import { fontSm5 } from '../../../../styled/TypeSystem';

export const DocumentImportCardContainer = styled.div<{ isError?: boolean }>`
  width: 100%;
  min-height: 3.5rem;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ isError, theme: { vars } }) =>
    isError ? vars.stateBackgroundError : vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants, vars }, isError }) =>
    `${constants.borderWidthSm} solid ${
      isError ? vars.stateBackgroundError : vars.borderSurface2
    }`};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  overflow: hidden;
`;

const SideItemWrapper = css`
  width: 3.5rem;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
`;

export const SourceIconWrapper = styled.div`
  ${SideItemWrapper};
`;

export const DocumentImage = styled.img`
  max-height: 2.5rem;
`;

export const CardBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;

  @media (min-width: ${mediaBreakpointPxMd}) {
    flex-direction: row;
  }
`;

export const DocumentInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 55%;
    padding: ${({ theme: { constants } }) => constants.spacerMd2} 0;
  }
`;

export const DocumentName = styled.div`
  word-break: break-word;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm5};
`;

export const InfoText = styled.span<{ isError?: boolean }>`
  color: ${({ isError, theme: { vars } }) => (isError ? vars.stateError : vars.textSubdued)};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};

  ${fontSm5};
`;

export const DocumentSectorSelectionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  padding: ${({ theme: { constants } }) => constants.spacerMd2} 0;

  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 45%;
  }
`;

export const SelectLabel = styled.span`
  white-space: normal;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxMd}) {
    white-space: nowrap;
  }

  ${fontSm5};
`;

export const ActionsWrapper = styled.div`
  ${SideItemWrapper};
`;
