import { Form } from 'formik';
import styled from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import { fontSm5 } from '../../../../styled/TypeSystem';

export const FormWrapper = styled.div`
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  input {
    width: 100%;
  }

  @media (min-width: ${mediaBreakpointPxMd}) {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
    padding-right: ${({ theme: { constants } }) => constants.spacerLg3};
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FormInputs = styled.div`
  flex: 100%;

  @media (min-width: ${mediaBreakpointPxMd}) {
    flex: 50%;
  }
`;

export const FormImage = styled.div`
  flex: 100%;

  @media (min-width: ${mediaBreakpointPxMd}) {
    flex: 50%;
    padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-flow: row wrap;

  @media (min-width: ${mediaBreakpointPxMd}) {
    margin-top: ${({ theme: { constants } }) => constants.spacerLg3};
  }
`;

export const UploadImageLabel = styled.label`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5};
`;

export const StepButton = styled(DefaultButton)`
  ${({ theme: { constants } }) => ({
    marginBottom: constants.spacerMd2,
    marginTop: constants.spacerMd2,
    paddingTop: constants.spacerSm3,
    paddingBottom: constants.spacerSm3,
  })};
  justify-content: center;

  @media (min-width: ${mediaBreakpointPxMd}) {
    margin-top: 0;
  }
`;
