import * as yup from 'yup';

import initTranslations from '../../initTranslations';

const t = initTranslations('public_application.login.form.errors');

export const getOtpSchema = () =>
  yup.object().shape({
    otp_attempt: yup
      .string()
      .required(t('otp.blank'))
      .matches(/^[0-9]*$/, t('otp.invalid')),
  });
