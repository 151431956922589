import React from 'react';

import DisplayEmailToggle from './Forms/PeopleSettings/DisplayEmailToggle';
import DisplayPhoneNumbersToggle from './Forms/PeopleSettings/DisplayPhoneNumbersToggle';
import ShareDirectory from './Forms/PeopleSettings/ShareDirectory';
import ShareOrgChart from './Forms/PeopleSettings/ShareOrgChart';
import ShareRoleChart from './Forms/PeopleSettings/ShareRoleChart';
import { TabSectionWrapper } from './styles';

const PeopleTabSection = () => {
  return (
    <TabSectionWrapper id='people-tab-section'>
      <ShareDirectory />
      <ShareOrgChart />
      <ShareRoleChart />
      <DisplayPhoneNumbersToggle />
      <DisplayEmailToggle />
    </TabSectionWrapper>
  );
};

export default PeopleTabSection;
