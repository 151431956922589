type ManageRequestsOption = { page: number };
type Action = { type: 'changePage'; page: number };

export const tableReducer = (state: ManageRequestsOption, action: Action): ManageRequestsOption => {
  switch (action.type) {
    case 'changePage': {
      return { ...state, page: action.page };
    }
  }
};
