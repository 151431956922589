import { darken } from 'polished';
import styled from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { SuccessContainer } from '../../../ai/Feedback/Feedback';

export const FeedbackCtaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: ${({ theme: { constants } }) => `${constants.spacerMd1} 0 ${constants.spacerMd2}`};
`;

export const StyledSuccessContainer = styled(SuccessContainer)`
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  margin: ${({ theme: { constants } }) => `${constants.spacerMd1} 0 ${constants.spacerMd2}`};
`;

export const FeedbackCTA = styled(DefaultButton)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};

  svg {
    color: ${({ theme: { vars } }) => vars.stateError};
  }

  &:hover {
    svg {
      color: ${({ theme: { vars } }) => darken(0.2, vars.stateError)};
    }
  }

  ${fontSm5};
`;
