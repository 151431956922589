import React from 'react';
import { useTheme } from 'styled-components';

import { useGetSearchKinds } from '../../../../../hooks/search/useGetSearchKinds';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Chip from '../../../../design_system/buttons/Chip/Chip';
import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';
import { SearchKind } from '../types';
import SearchResultsChipText from './SearchResultsChipText/SearchResultsChipText';
import { SearchResultsChipsContainer } from './styles';
import { SearchResultsChipsProps } from './types';

const t = initTranslations('search_results_page.chips');

const SearchResultsChips = ({
  chipsCount,
  showLoader,
  setSelectedChip,
  selectedChip,
}: SearchResultsChipsProps) => {
  const {
    name,
    splitFeatures: { newSearchUiEnabled },
  } = useCurrentAccount();
  const chips = useGetSearchKinds();
  const { constants } = useTheme();
  // TODO: GS3 Remove isFullWidth when help_center_search FF will be deprecated
  const isFullWidth = chips.length !== 1;
  const { productTerm } = useAccountTerminology();

  return (
    <SearchResultsChipsContainer id='search-results-chips' isFullWidth={isFullWidth}>
      {chips.map((chip: SearchKind) => {
        const isNotHelpCenter = chip !== 'help_center';
        const showChipLoader = showLoader && isNotHelpCenter;
        const text = chip === 'company' ? name : t(`${chip}.text`);
        const count = isNotHelpCenter ? chipsCount[chip] : null;
        return (
          <Chip
            active={selectedChip === chip}
            fullWidth={isFullWidth}
            id={`${chip}-chip`}
            key={`${chip}-chip`}
            onClick={() => setSelectedChip(chip)}
            palette='brand'
            subText={t(`${chip}.subtext`, { productTerm })}
            text={
              <SearchResultsChipText
                count={
                  showChipLoader ? (
                    <SkeletonLoader borderRadius={constants.borderRadiusXs} width='1rem' />
                  ) : (
                    count
                  )
                }
                text={text}
              />
            }
            textAlignment='start'
            withGreyBackground={newSearchUiEnabled}
          />
        );
      })}
    </SearchResultsChipsContainer>
  );
};

export default SearchResultsChips;
