import styled, { css } from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import IconButton from '../../../../design_system/buttons/IconButton';
import Icon from '../../../../design_system/display/icons/Icon/Icon';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontMd1, fontSm2, fontSm4 } from '../../../../styled/TypeSystem';

export const BannerWrapper = styled.div`
  position: relative;
  z-index: 5;
`;

export const BannerContainer = styled.div`
  ${({ theme: { constants, vars } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${constants.spacerMd2};
    border-radius: ${constants.borderRadiusLg};
    border: ${constants.borderWidth2xl} solid ${vars.trainualBrandPurpleStrong};
    background-color: ${vars.foundationSurface1};

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: -9px;
      left: ${constants.spacerSm1};
      width: 100%;
      height: 100%;
      border: inherit;
      border-radius: inherit;
      background-color: ${vars.trainualBrandPurpleStrong};
    }
  `};
`;

const bannerImageStyles = css`
  height: auto;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
`;

export const InfoImage = styled.img`
  width: 9rem;
  left: -28px;
  ${bannerImageStyles};
`;

export const OptedInImage = styled.img`
  width: 5rem;
  ${bannerImageStyles};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 7rem;
`;

export const Title = styled.h2`
  ${({ theme: { constants, vars } }) => css`
    color: ${vars.trainualBrandPurpleStrong};
    font-weight: ${constants.fontBold};
    margin-bottom: ${constants.spacerSm2};

    ${fontMd1};
  `};
`;

export const Subtitle = styled.p`
  ${({ theme: { constants, vars } }) => css`
    color: ${vars.textDefault};
    font-weight: ${constants.fontRegular};
    margin-bottom: ${constants.spacerSm3};

    ${fontSm4};
  `};
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const AcknowledgementLabel = styled.label`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  width: 100%;

  cursor: pointer;

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: 60%;
  }
`;

// DS Override: Updating the color of the icon based on the isChecked prop
export const CheckboxIcon = styled(Icon)<{ isChecked: boolean }>`
  color: ${({ isChecked, theme: { vars } }) =>
    isChecked ? vars.trainualBrandPurpleMedium : vars.trainualBrandPurpleSubdued};
`;

export const StyledIconButton = styled(IconButton)`
  padding: ${({ theme: { constants } }) => constants.spacerSm1};
`;

export const AcknowledgementText = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm2};
`;

// DS Override: Updating the color of the button and border to match designs
export const ActionButton = styled(DefaultButton)`
  ${({ theme: { constants, vars } }) => css`
    background-color: ${vars.trainualBrandPurpleStrong};
    border-color: ${vars.trainualBrandGreenMedium};
    border: ${constants.borderRadiusSm} solid ${vars.trainualBrandGreenMedium};

    &:hover,
    &:active,
    &:focus {
      background-color: ${vars.trainualBrandPurpleStrong};
      border-color: ${vars.trainualBrandGreenMedium};
    }

    &:disabled {
      background-color: ${vars.foundationBase4};
      color: ${vars.foundationBase3};
      border-color: ${vars.borderDefault};
    }
  `};
`;
