import React from 'react';
import styled from 'styled-components';

import { MultilineTruncatedText } from '../../../../../../design_system/Triage/TruncatedText';
import { People } from '../../../ResultTypes';

const TableRow = styled.div`
  display: flex;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  &:last-child {
    border-bottom: none;
  }
`;

const TableItem = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  height: 3.25rem;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerMd2}`};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  font-size: 0.85rem;
  &:first-child {
    padding-left: ${({ theme: { constants } }) => constants.spacerLg1};
  }
  &:last-child {
    padding-right: ${({ theme: { constants } }) => constants.spacerLg1};
    width: 40%;
  }
`;

const TableItemInner = styled.span`
  width: 100%;
  ${MultilineTruncatedText({})};
`;

const TableItemName = styled(TableItemInner)`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;

const TableItemEmail = styled(TableItemInner)`
  word-break: break-all;
`;

interface Props {
  user: People;
}

const ResultTableHead = ({ user: { name, title, email } }: Props) => {
  return (
    <TableRow>
      <TableItem>
        <TableItemName>{name}</TableItemName>
      </TableItem>
      <TableItem>
        <TableItemInner>{title}</TableItemInner>
      </TableItem>
      <TableItem>
        <TableItemEmail>{email}</TableItemEmail>
      </TableItem>
    </TableRow>
  );
};

export default ResultTableHead;
