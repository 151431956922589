import { GroupByMenuOption } from '../../types/GroupByMenuOption';
import initTranslations from '../initTranslations';

const t = initTranslations('templates_outline.sort_dropdown');

const getTemplatesSortDropdownOptions = () => {
  const options: GroupByMenuOption[] = [
    {
      label: t('popularity'),
      sortColumn: 'most_popular',
      sortDirection: 'desc',
      viewByLabel: t('sort'),
      groupBy: 'other',
    },
    {
      label: t('alphabet'),
      sortColumn: 'alpha',
      sortDirection: 'asc',
      viewByLabel: t('sort'),
      groupBy: 'other',
    },
    {
      label: t('trending'),
      sortColumn: 'trending',
      sortDirection: 'desc',
      viewByLabel: t('sort'),
      groupBy: 'other',
    },
  ];

  return {
    options,
  };
};

export default getTemplatesSortDropdownOptions;
