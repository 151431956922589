import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import useAnalyticsUserEvents from '../../../../hooks/useAnalyticsUserEvents';
import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import GroupsVideoImage from './GroupsVideoImage';
import LibraryVideoImage from './LibraryVideoImage';
import {
  Container,
  DismissLink,
  PlayButton,
  RoundedCard,
  Title,
  TitleSection,
  VideoPlayer,
  VideoSection,
} from './styles';
import { ExplainAndInspireVideoPreviewProps } from './types';

const t = initTranslations('explain_and_inspire_videos');

const ExplainAndInspireVideoPreviewElement = ({ resource }: ExplainAndInspireVideoPreviewProps) => {
  const { cdpEmptyStateVideoClicked } = useAnalyticsUserEvents();
  const [cookies, setCookie] = useCookies();
  const { curriculum } = useAccountTerminology();
  const { configs } = usePublicConfigs();
  const [displayVideoPlayer, setDisplayVideoPlayer] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(true);
  const videoLink =
    resource == 'groups'
      ? configs['GROUPS_EXPLAIN_AND_INSPIRE_VIDEO_URL']
      : configs['LIBRARY_EXPLAIN_AND_INSPIRE_VIDEO_URL'];

  const handleDismissButtonClick = () => {
    setCookie(`dismissed-inspire-${resource}-video`, JSON.stringify({ value: true }));
    setIsPreviewVisible(false);
    cdpEmptyStateVideoClicked({ action: 'dismissed', video_type: resource });
  };

  const handlePlayButtonClick = () => {
    setDisplayVideoPlayer(true);
    cdpEmptyStateVideoClicked({ action: 'played', video_type: resource });
  };

  useEffect(() => {
    const dismissedVideo = cookies[`dismissed-inspire-${resource}-video`];
    const hideVideoPreview = dismissedVideo && dismissedVideo.value;

    setIsPreviewVisible(!hideVideoPreview);
  }, [cookies, resource]);

  if (!isPreviewVisible) return <></>;

  return (
    <Container id={`${resource}-explain-and-inspire-video`}>
      <Title>{t(`${resource}.title`, { subjects: curriculum.plural.toLowerCase() })}</Title>
      <VideoPlayer
        allow='autoplay; fullscreen; picture-in-picture'
        allowFullScreen
        id={`${resource}-video-player`}
        isVisible={displayVideoPlayer}
        src={`${videoLink}&autoplay=${displayVideoPlayer}`}
        title={t(`${resource}.video_title`)}
      />
      {!displayVideoPlayer && (
        <RoundedCard id={`${resource}-video-player-preview`}>
          <VideoSection>
            <PlayButton
              ariaLabel={t(`${resource}.aria_label`)}
              buttonSize='sm'
              className='explain-inspire-video-play-button'
              name='play'
              onClick={handlePlayButtonClick}
              weight='solid'
            />
            {resource == 'groups' ? <GroupsVideoImage /> : <LibraryVideoImage />}
          </VideoSection>
          <TitleSection>
            {t(`${resource}.message`, { subjects: curriculum.plural.toLowerCase() })}
            <DismissLink
              behavesAs='button'
              color='monochrome'
              onClick={handleDismissButtonClick}
              text={t('dismiss')}
            />
          </TitleSection>
        </RoundedCard>
      )}
    </Container>
  );
};

export default ExplainAndInspireVideoPreviewElement;
