import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledElementTopNav = styled.nav`
  align-items: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  box-shadow: ${({ theme: { vars } }) => `0 5px 10px 0 ${vars.shadowColorTopSmall}`};
  box-sizing: border-box;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
`;

type Props = {
  children: ReactNode;
};

const BaseElementTopNav = ({ children }: Props) => {
  return <StyledElementTopNav>{children}</StyledElementTopNav>;
};

export default BaseElementTopNav;
