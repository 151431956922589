import React from 'react';

import useCopyUrlLink from '../../../../../../hooks/useCopyUrlLink';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { useIsAdmin } from '../../../../../../hooks/users/useIsPermission';
import { RegisteredMenuId } from '../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../lib/initTranslations';
import { routes } from '../../../../publicApplication/applicationRouter';
import useActiveMenuHandler from '../../../../publicApplication/utils/useActiveMenuHandler';
import useActiveModalHandler from '../../../../publicApplication/utils/useActiveModalHandler';
import CopyLink from '../../../ThreeDotOptions/CopyLink';
import Delete from '../../../ThreeDotOptions/Delete';
import Duplicate from '../../../ThreeDotOptions/Duplicate';
import Edit from '../../../ThreeDotOptions/Edit';
import Move from '../../../ThreeDotOptions/Move';
import Rename from '../../../ThreeDotOptions/Rename';
import { ViewingFrom } from '../../StepThreeDot';
import AddToChrome from './AddToChrome';

type Options = 'edit' | 'rename' | 'duplicate' | 'copy' | 'move' | 'addToChrome' | 'delete';

type DisabledOptions = { isDisabled?: boolean; tooltipText?: string };
type OptionsAttributes = {
  [key in `${Options}State`]?: DisabledOptions;
};

type StepThreeDotMenuOptionProps = {
  stepId: number;
  viewingFrom: ViewingFrom;
  menuId: RegisteredMenuId;
  renameClick: () => void;
} & OptionsAttributes;

const t = initTranslations('curriculums.three_dot_menu');

const Options = ({
  stepId,
  viewingFrom,
  renameClick,
  menuId,
  ...optionsAttributes
}: StepThreeDotMenuOptionProps) => {
  const { closeMenu } = useActiveMenuHandler({ menuId });
  const { slug } = useCurrentAccount();

  const consumeStepRoute = routes.consume({ slug, id: stepId }).href;
  const editStepRoute = routes.editor({ slug, id: stepId });
  const viewingFromEditor = viewingFrom === 'editor';
  const isAdminUser = useIsAdmin();
  const { copyUrlLink } = useCopyUrlLink();

  const copy = () => {
    copyUrlLink({
      path: consumeStepRoute,
      flashText: t('copy_link_flash'),
    });
  };

  const { setActiveModal } = useActiveModalHandler();

  const withCloseMenu = (action: () => void) => {
    action();
    closeMenu();
  };

  return (
    <>
      {!viewingFromEditor && (
        <Edit
          id={`step-${stepId}-edit`}
          onClick={() => withCloseMenu(() => editStepRoute.push())}
          {...optionsAttributes.editState}
        />
      )}
      <Rename
        id={`step-${stepId}-rename`}
        onClick={() => withCloseMenu(renameClick)}
        {...optionsAttributes.renameState}
      />
      <Duplicate
        id={`step-${stepId}-duplicate`}
        onClick={() => withCloseMenu(() => setActiveModal(`duplicate-step-modal-${stepId}`))}
        {...optionsAttributes.duplicateState}
      />
      <CopyLink
        id={`step-${stepId}-copy-link`}
        onClick={() => withCloseMenu(copy)}
        {...optionsAttributes.copyState}
      />
      {isAdminUser && (
        <Move
          id={`step-${stepId}-move`}
          onClick={() => withCloseMenu(() => setActiveModal(`move-step-modal-${stepId}`))}
          {...optionsAttributes.moveState}
        />
      )}
      <AddToChrome
        id={`step-${stepId}-add-to-chrome`}
        onClick={() => withCloseMenu(() => setActiveModal(`manage-extensions-modal-${stepId}`))}
        {...optionsAttributes.addToChromeState}
      />
      <Delete
        id={`step-${stepId}-delete`}
        onClick={() => withCloseMenu(() => setActiveModal(`delete-step-modal-${stepId}`))}
        {...optionsAttributes.deleteState}
      />
    </>
  );
};

export default Options;
