import React from 'react';

import { SkeletonLoader } from '../../../../../shared/SkeletonLoader/styles';
import { ToolbarSkeletonWrapper } from './styles';

const ToolbarSkeleton = () => {
  return (
    <ToolbarSkeletonWrapper>
      <SkeletonLoader height='1.25rem' width='10.5rem' />
    </ToolbarSkeletonWrapper>
  );
};

export default ToolbarSkeleton;
