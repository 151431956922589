import styled from 'styled-components';

import { Wrapper } from '../MagicWandIcon/styles';

export const MainResultsTitleBox = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  ${Wrapper} {
    flex: none;
    margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  }

  .search-results-header {
    margin-bottom: 0;

    > * {
      white-space: normal;
    }
  }
`;
