import { useEffect, useMemo, useState } from 'react';

import { Props as PaginationProps } from '../components/design_system/Triage/Paginate/Pagination';

interface UseClientPagination<Type> {
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  activePage: number;
  filteredItems: Type[];
  pagination: PaginationProps;
}

function useClientPagination<Type>(
  items: Type[],
  itemsCountPerPage: number
): UseClientPagination<Type> {
  const [activePage, setActivePage] = useState<number>(1);
  const totalPages = useMemo(
    () => Math.ceil(items.length / itemsCountPerPage),
    [items.length, itemsCountPerPage]
  );

  useEffect(() => {
    // If active page is greater than total pages, set active page to last page. Important especially when
    // deleting the last item on the last page.
    if (activePage > totalPages && totalPages > 0) {
      setActivePage(totalPages);
    }
  }, [activePage, totalPages]);

  const pagination: PaginationProps = useMemo(() => {
    return {
      activePage,
      totalPages,
      totalItemsCount: items.length,
      itemsCountPerPage,
      onChange: (page: number) => setActivePage(page),
    };
  }, [activePage, items.length, itemsCountPerPage, totalPages]);

  const startIndex = (activePage - 1) * itemsCountPerPage;
  const endIndex = activePage * itemsCountPerPage;
  const filteredItems = items.slice(startIndex, endIndex);

  return { activePage, setActivePage, filteredItems, pagination };
}

export default useClientPagination;
