import React, { FC } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { setTargetColumnName } from '../../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useDispatchSetShowModal } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';

const t = initTranslations('delegation_planner.modals.cant_move_responsibility_modal');

const CantMoveResponsibilityModal: FC = () => {
  const dispatch = useAppDispatch();

  const { targetColumnName } = useAppSelector((state) => state.delegationPlanner);

  const dispatchShowModal = useDispatchSetShowModal();

  const handleCloseModal = () => {
    dispatch(setTargetColumnName(''));
    dispatchShowModal('cantMoveResponsibilityModal', false);
  };

  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();

  return (
    <ConfirmationModal
      actionFunction={handleCloseModal}
      actionText={t('okay')}
      hasSecondaryButton={false}
      heapModalName='cant-move-responsibility-modal'
      message={t('message', {
        responsibility: responsibilitySingular.toLowerCase(),
        column: targetColumnName,
      })}
      onCloseRequest={handleCloseModal}
      primaryButtonAnimationTimeout={3000}
      processing={false}
      title={t('title', { responsibility: responsibilitySingular.toLowerCase() })}
    />
  );
};

export default CantMoveResponsibilityModal;
