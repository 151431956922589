import React from 'react';

import { RegisteredMenuId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { LimitedStep } from '../../../../types/Step';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import ControlledThreeDotMenu from '../../../design_system/menus/ControlledThreeDotMenu/ControlledThreeDotMenu';
import { ThreeDotWrapper } from '../../curriculums/shared/CurriculumRowStyles';
import useActiveModalHandler from '../../publicApplication/utils/useActiveModalHandler';
import ModalController from './StepThreeDotMenu/ModalController';
import Options from './StepThreeDotMenu/Options/Options';

export type ViewingFrom = 'editor' | 'curriculum';

export type StepThreeDotProps = {
  isLocked?: boolean;
  step: LimitedStep;
  canBeModified: boolean;
  viewingFrom: ViewingFrom;
  renameClick: () => void;
  isLastStep: boolean;
};

const t = initTranslations('curriculums');

const StepThreeDot = ({
  isLocked,
  step,
  canBeModified,
  viewingFrom,
  renameClick,
  isLastStep,
}: StepThreeDotProps) => {
  const {
    curriculum: { singular: curriculumTermSingular },
    step: { singular: stepTermSingular },
    topic: { singular: topicTermSingular, plural: topicTermPlural },
  } = useAccountTerminology();
  const menuId: RegisteredMenuId = `step-three-dot-${step.id}`;
  const { activeModal } = useActiveModalHandler();

  const activeModalStepId = activeModal && parseInt(activeModal.split('-')[3], 10);
  const renderModalController = activeModal && activeModalStepId === step.id;

  const unmodifiableOptionsState = {
    isDisabled: !canBeModified,
    tooltipText: !canBeModified
      ? t('three_dot_menu.disabled_by_e_signature', { step: stepTermSingular })
      : undefined,
  };

  const lastStepOptionState = (translation: string) => {
    return {
      isDisabled: isLastStep,
      tooltipText: isLastStep ? translation : undefined,
    };
  };

  const moveState = isLastStep
    ? lastStepOptionState(
        t('three_dot_menu.move_disabled_on_last_step', {
          step: stepTermSingular,
          topics: topicTermPlural,
          topic: topicTermSingular,
        })
      )
    : unmodifiableOptionsState;

  const deleteState = isLastStep
    ? lastStepOptionState(
        t('three_dot_menu.delete_disabled_on_last_step', {
          step: stepTermSingular,
          topic: topicTermSingular,
        })
      )
    : unmodifiableOptionsState;

  return (
    <>
      {isLocked && (
        <Tooltip
          id={`locked-step-${step.id}`}
          text={t('tooltips.subject_locked', { subject: curriculumTermSingular })}
        />
      )}

      <ThreeDotWrapper
        className={`step-${step.id}-three-dot-wrapper`}
        isLocked={isLocked}
        {...(isLocked && {
          'data-for': `locked-step-${step.id}`,
          'data-tip': true,
        })}
      >
        <ControlledThreeDotMenu id={menuId} isDisabled={isLocked}>
          <Options
            deleteState={deleteState}
            duplicateState={unmodifiableOptionsState}
            menuId={menuId}
            moveState={moveState}
            renameClick={renameClick}
            renameState={unmodifiableOptionsState}
            stepId={step.id}
            viewingFrom={viewingFrom}
          />
        </ControlledThreeDotMenu>
        {renderModalController && (
          <ModalController activeModal={activeModal} step={step} viewingFrom={viewingFrom} />
        )}
      </ThreeDotWrapper>
    </>
  );
};

export default StepThreeDot;
