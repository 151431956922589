import { GoogleOAuthProvider } from '@react-oauth/google';
import { Formik, FormikErrors } from 'formik';
import React, { useCallback, useReducer } from 'react';

import { useCloseFullScreenOverlay } from '../../../../hooks/billing/useCloseFullScreenOverlay';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import isValidGoogleDocsUrl from '../../../../lib/document_import/isValidGoogleDocsUrl';
import initTranslations from '../../../../lib/initTranslations';
import FullScreenOverlay from '../../shared/FullScreenOverlay/FullScreenOverlay';
import { MAX_FILE_SIZE } from '../modals/ImportFromDocumentModal/shared/constants/documentImport';
import FileUploadForm from './FileUploadForm/FileUploadForm';
import { bulkImportFilesReducer } from './reducer';
import {
  ActionButton,
  CancelButton,
  FooterContainer,
  FormHeaderContainer,
  FormSubtitle,
  FormSubtitleContainer,
  FormTitle,
  FullScreenOverlayWrapper,
  MaxFilesMessageWrapper,
  OverlayContentWrapper,
  StyledIcon,
  UploadFormWrapper,
} from './styles';
import { FormValues } from './types';
import UrlFormInput from './UrlFormInput/UrlFormInput';

const t = initTranslations('bulk_document_import');

const BulkDocumentImportFullScreenOverlay = () => {
  const { configs: privateConfigs } = usePrivateConfigs();

  const initialValues: FormValues = {
    documentLink: '',
    currentFormStep: 'fileUpload',
  };

  const validateForm = useCallback((values: FormValues) => {
    const { documentLink } = values;
    const errors: FormikErrors<FormValues> = {};
    if (!isValidGoogleDocsUrl(documentLink)) {
      errors.documentLink = t('errors.invalid_link');
    }
    return errors;
  }, []);

  const [{ bulkDocumentImportData }, dispatch] = useReducer(bulkImportFilesReducer, {
    bulkDocumentImportData: [],
  });

  const maxFilesReached = bulkDocumentImportData.length >= MAX_FILE_SIZE;
  const isBulkImportDataEmpty = bulkDocumentImportData.length === 0;
  const formTitle =
    bulkDocumentImportData.length === 0
      ? t('title')
      : `${t('title')}: ${bulkDocumentImportData.length} / ${MAX_FILE_SIZE}`;

  const closeFullScreenOverlay = useCloseFullScreenOverlay();
  return (
    <FullScreenOverlay fixedCloseIcon id='bulk-document-import-full-screen-overlay'>
      <FullScreenOverlayWrapper>
        <OverlayContentWrapper>
          <UploadFormWrapper>
            <FormHeaderContainer>
              <FormTitle>{formTitle}</FormTitle>
              <FormSubtitleContainer>
                <FormSubtitle>{t('subtitle')}</FormSubtitle>
              </FormSubtitleContainer>
            </FormHeaderContainer>
            <GoogleOAuthProvider clientId={privateConfigs['GOOGLE_OAUTH_CLIENT_ID']}>
              <Formik
                initialValues={initialValues}
                onSubmit={() => {
                  // ToDo: Add submit handler
                }}
                validate={validateForm}
                validateOnMount
              >
                <>
                  <UrlFormInput dispatch={dispatch} maxFilesReached={maxFilesReached} />
                  <FileUploadForm
                    bulkDocumentImportData={bulkDocumentImportData}
                    dispatch={dispatch}
                    isBulkImportDataEmpty={isBulkImportDataEmpty}
                    maxFilesReached={maxFilesReached}
                  />
                  {maxFilesReached && (
                    <MaxFilesMessageWrapper>
                      <StyledIcon name='circle-info' />
                      {t('max_files_reached_message', { max_files_size: MAX_FILE_SIZE })}
                    </MaxFilesMessageWrapper>
                  )}
                </>
              </Formik>
            </GoogleOAuthProvider>
          </UploadFormWrapper>
        </OverlayContentWrapper>
        <FooterContainer>
          <CancelButton
            buttonType='secondary'
            id='cancel-documents-import-button'
            onClick={closeFullScreenOverlay}
            text={t('cancel')}
          />
          <ActionButton
            buttonType='primary'
            disabled={isBulkImportDataEmpty}
            id='import-all-documents-button'
            onClick={() => {
              // ToDo: add submit handler
            }}
            text={t('import_all')}
          />
        </FooterContainer>
      </FullScreenOverlayWrapper>
    </FullScreenOverlay>
  );
};

export default BulkDocumentImportFullScreenOverlay;
