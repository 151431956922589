import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { Dispatch, SetStateAction } from 'react';

import {
  BoardColumn as TBoardColumn,
  TCurrentBoardColumnResponsibility,
} from '../BoardColumn/types';

export interface BoardTrackProps {
  boardColumns: TBoardColumn[];
  setBoardTrackElement: (element: HTMLDivElement | null) => void;
}

export enum EDragElements {
  Responsibility = 'Responsibility',
  Column = 'Column',
}

export interface GetSourceDataProps {
  responsibilityId: string;
  boardColumns: TBoardColumn[];
}

export interface HandleDragOverProps {
  event: DragOverEvent;
  handleTempColumns: (columns: TBoardColumn[]) => void;
  tempColumns: TBoardColumn[];
}

export interface GetSourceDataReturn {
  sourceColumnId: number | undefined;
  sourceColumn: TBoardColumn | undefined;
  sourceResponsibility: TCurrentBoardColumnResponsibility | undefined;
  sourceResponsibilityIndex: number;
}

interface Handlers {
  setActiveColumn: (column: TBoardColumn | null) => void;
  setActiveResponsibility: (responsibility: TCurrentBoardColumnResponsibility | null) => void;
}

export interface HandleDragStartProps extends Handlers {
  event: DragStartEvent;
  setHolderHeight: Dispatch<SetStateAction<number | undefined>>;
}

export interface HandleDragEndProps extends Handlers {
  event: DragEndEvent;
}

export interface MoveResponsibilityProps {
  columns: TBoardColumn[];
  fromColumnIndex: number;
  fromResponsibilityIndex: number;
  toColumnIndex: number;
  toResponsibilityIndex: number;
}
