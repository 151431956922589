import styled from 'styled-components';

import { mediaBreakpointPx2xl } from '../../../styled/Breakpoint';

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${mediaBreakpointPx2xl}) {
    gap: ${({ theme: { constants } }) => constants.spacerMd3};
  }
`;
