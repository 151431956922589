import React, { FC } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import { FeedbackCTA, FeedbackCtaWrapper, StyledSuccessContainer } from './styles';
import { ResultsFeedbackProps } from './types';

const t = initTranslations('compose.search');

const ResultsFeedback: FC<ResultsFeedbackProps> = ({
  feedbackStatus,
  completionResponse,
  steps,
  handleOpenAiFeedbackModal,
}) => {
  if (feedbackStatus === 'complete') {
    return (
      <StyledSuccessContainer>
        <Icon name='circle-check' weight='regular' />
        {t('submit_success')}
      </StyledSuccessContainer>
    );
  }

  if (completionResponse?.status === 'completed' && steps.length > 0) {
    return (
      <FeedbackCtaWrapper>
        <FeedbackCTA
          buttonType='tertiary'
          iconName='exclamation-circle'
          id='compose-feedback-cancelled'
          onClick={handleOpenAiFeedbackModal}
          text={t('report_answer')}
        />
      </FeedbackCtaWrapper>
    );
  }

  return null;
};

export default ResultsFeedback;
