import React from 'react';
import styled from 'styled-components';

import { CurriculumOwner } from '../../../../../types';
import { Emoji } from '../../../../../types/Emoji';
import { Sector } from '../../../../../types/Sector';
import { Option } from '../../../../design_system/core/SelectOption/types';
import Icon from '../../../../design_system/display/icons/Icon/Icon';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField/SingleSelectField';
import { MultilineTruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../styled/TypeSystem';
import GroupBadge from '../../components/GroupBadge/GroupBadge';
import OwnerAvatar from '../../components/OwnerAvatar/OwnerAvatar';
import SectorLabel from '../../components/SectorLabel/SectorLabel';
import SubjectEmoji from '../../components/SubjectEmoji/SubjectEmoji';

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4.75rem;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  :hover {
    cursor: move;
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  padding-inline: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
`;

// DS Override: Updated color to match designs
const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const SelectWrapper = styled.div`
  flex-shrink: 0;
  width: 4.6rem;
`;

const EmojiWrapper = styled.span`
  flex-shrink: 0;
  margin-inline: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const Title = styled.h2`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm5};
  ${MultilineTruncatedText({})};
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
`;

export type Props = {
  id: number;
  position: number;
  positionOptions: Option[];
  emoji: Emoji;
  title: string;
  sector: Sector;
  groupsCount: number;
  owner: CurriculumOwner | null;
  updatePosition: (position: number) => void;
};

const SubjectOrderRow = ({
  id,
  position,
  positionOptions,
  emoji,
  title,
  groupsCount,
  owner,
  sector,
  updatePosition,
}: Props) => {
  return (
    <RowContainer className='subject-order-row'>
      <LeftSide>
        <StyledIcon fixedWidth name='grip-vertical' weight='solid' />
        <SelectWrapper>
          <SingleSelectField
            defaultValue={position.toString()}
            expandMenuToFitOptions
            hideSelectedOptions={false}
            isSearchable
            onNonDefaultSelected={(value: string) => {
              updatePosition(Number(value));
            }}
            options={positionOptions}
            size='sm'
            value={position.toString()}
          />
        </SelectWrapper>
        <EmojiWrapper>
          <SubjectEmoji emoji={emoji} noEmojiIconName='file-lines' />
        </EmojiWrapper>
        <Title>{title}</Title>
      </LeftSide>
      <RightSide>
        <SectorLabel sector={sector} />
        <GroupBadge canAssignUsers={false} groupsCount={groupsCount} id={id} />
        <OwnerAvatar canEditOwner={false} curriculumId={id} owner={owner} />
      </RightSide>
    </RowContainer>
  );
};

export default SubjectOrderRow;
