import styled from 'styled-components';

import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import IconButton from '../../../../../design_system/buttons/IconButton';
import Icon from '../../../../../design_system/display/icons/Icon';
import { fontMd1, fontSm4, fontSm5 } from '../../../../../styled/TypeSystem';

export const AllDoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const AllDoneMessage = styled.p`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd1};
`;

export const TaskListCloseIconButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme: { constants } }) => constants.spacerSm3};
  right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  svg {
    pointer-events: none;
  }
`;

export const StyledButton = styled(DefaultButton)`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const Subheader = styled.p`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm4};
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const ProgressText = styled.span`
  ${({ theme: { constants } }) => constants.fontBold};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd1};
`;

export const TaskListDismissIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => `-${constants.spacerSm1}`};
`;

export const TaskList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
`;

export const TaskEmoji = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const Task = styled.li<{ isClickable: boolean; completed: boolean; skipped: boolean }>`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerSm3}`};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ completed, skipped, theme: { vars } }) =>
    completed ? vars.textDisabled : skipped ? vars.textSubdued : vars.textDefault};

  ${({ isClickable, theme: { vars } }) =>
    isClickable && {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: vars.foundationHover,
        color: vars.textHover,
      },
    }};
  ${fontSm5};
`;

export const TaskText = styled.span<{ completed: boolean }>`
  text-decoration: ${({ completed }) => (completed ? 'line-through' : 'none')};
  color: inherit;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
`;
