import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useToggleAccountOptOutFeatureMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { PolicySectorSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const PolicySectorSection = ({ id, policySector }: PolicySectorSectionProps) => {
  const [toggleAccountFeature, result] = useToggleAccountOptOutFeatureMutation();
  const { isLoading, error } = result;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
    errorMessage: messageFromError(error)?.join(', '),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>
          {policySector ? t('policy_sector_on_title') : t('policy_sector_off_title')}
        </FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={policySector}
            className='policy-sector'
            disabled={isLoading}
            handleToggle={() => toggleAccountFeature({ feature: 'policy_sector' })}
            id='enable-policy-sector'
            name='policy_sector'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('policy_sector_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default PolicySectorSection;
