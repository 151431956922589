import React, { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react';

import ThreeDotLoaderGif from '../../../../images/loaders/three-dot-loader.gif';
import initTranslations from '../../../../lib/initTranslations';
import useDebounce from '../../../../lib/useDebounce';
import { relevantContentApi } from '../../../../redux/services/resourceApis/relevantContent/relevantContentApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import SearchField from '../../../design_system/Triage/fields/SearchField';
import ContentList from './ContentList/ContentList';
import { ContentFlyoutProvider } from './context/ContentFlyoutContext';
import EmptyState from './EmptyState/EmptyState';
import LoadingState from './LoadingState/LoadingState';
import {
  Controls,
  Header,
  Inner,
  ListTitle,
  LoadingImage,
  PaginationLoadingContainer,
  Wrapper,
} from './styles';
import {
  ContentFlyoutProps,
  Course,
  Curriculum,
  ExpandedEntityKeys,
  SelectedItem,
  Step,
  TypeName,
} from './types';

const t = initTranslations('content_flyout');

const ContentFlyout: FC<ContentFlyoutProps> = ({
  previouslySelectedItems,
  onCancel,
  onSubmit,
  contentAssigneeId,
  contentAssigneeType,
}) => {
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [getCurriculumsData, { isFetching, isLoading }] =
    relevantContentApi.useLazyGetDataListQuery();
  const isDataLoading = isLoading || isFetching;
  const displayFetchingLoader = isFetching && page > 1;
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [contextData, setContextData] = useState<Curriculum[]>([]);
  const [currentlySelectedItems, setCurrentlySelectedItems] = useState<SelectedItem[]>([]);
  const isDisabledSubmit = !currentlySelectedItems.length;
  const hasNextPage = page < totalPages;
  const isSearchable = !!debouncedSearchTerm && !isFetching;

  const handleSetNextPage = useCallback(() => {
    hasNextPage && setPage((prevPage) => prevPage + 1);
  }, [hasNextPage]);

  const handleAddFetchedCourses = useCallback(
    (targetCurriculumId: number, dataList: Course[]) => {
      const updatedData = contextData.map((curriculum) => {
        const isTargetCurriculum = curriculum.id === targetCurriculumId;

        return isTargetCurriculum
          ? { ...curriculum, [ExpandedEntityKeys.Curriculum]: dataList }
          : curriculum;
      });

      setContextData(updatedData);
    },
    [contextData]
  );

  const handleAddFetchedSteps = useCallback(
    (targetCourseId: number, dataList: Step[]) => {
      const updatedData = contextData.map((curriculum) => {
        const updatedCourses = curriculum[ExpandedEntityKeys.Curriculum]?.map((course) => {
          if (course.id === targetCourseId) {
            return {
              ...course,
              [ExpandedEntityKeys.Course]: dataList,
            };
          }

          return course;
        });

        return {
          ...curriculum,
          [ExpandedEntityKeys.Curriculum]: updatedCourses,
        };
      });

      setContextData(updatedData);
    },
    [contextData]
  );

  const handleAddSelectedItem = useCallback((item: SelectedItem) => {
    setCurrentlySelectedItems((prev) => {
      const isItemAlreadySelected = prev.some(
        (selectedItem) => selectedItem.id === item.id && selectedItem.__typename === item.__typename
      );

      if (isItemAlreadySelected) {
        return prev.filter(
          (selectedItem) =>
            selectedItem.id !== item.id || selectedItem.__typename !== item.__typename
        );
      }

      return [...prev, item];
    });
  }, []);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onSubmit(currentlySelectedItems);
  };

  useEffect(() => {
    (async () => {
      if (searchTerm && searchTerm !== debouncedSearchTerm) return;

      const { data } = await getCurriculumsData({
        dataType: TypeName.Curriculum,
        page,
        searchTerm: debouncedSearchTerm,
        assigneeId: contentAssigneeId,
        assigneeType: contentAssigneeType,
      });

      if (!!data) {
        setContextData((prevState) =>
          page === 1 ? data.curriculums : [...prevState, ...data.curriculums]
        );
        setTotalPages(data.total_pages);
      }
    })();
    // Execute on mount and page/debouncedSearchTerm change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedSearchTerm]);

  const resetPagination = () => {
    if (page !== 1) setPage(1);
    if (totalPages !== 1) setTotalPages(1);
  };

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
    resetPagination();
  };

  return (
    <Wrapper className='connect-content-flyout'>
      <Header>
        <SearchField
          placeholder={t('search_placeholder')}
          searchValue={searchTerm}
          setSearchValue={handleSearchTermChange}
        />
      </Header>

      {!!contextData.length && <ListTitle>{t('content_list_title')}</ListTitle>}

      <Inner>
        {!contextData.length && (
          <>
            {isDataLoading ? (
              <LoadingState />
            ) : (
              <EmptyState
                currentSearchTerm={debouncedSearchTerm}
                isSearchEmptyState={isSearchable}
              />
            )}
          </>
        )}
        {!isLoading && (
          <ContentFlyoutProvider
            contextData={contextData}
            currentlySelectedItems={currentlySelectedItems}
            handleAddFetchedCourses={handleAddFetchedCourses}
            handleAddFetchedSteps={handleAddFetchedSteps}
            handleAddSelectedItem={handleAddSelectedItem}
            handleSetNextPage={handleSetNextPage}
            isSearchable={isSearchable}
            previouslySelectedItems={previouslySelectedItems || []}
          >
            <ContentList />
          </ContentFlyoutProvider>
        )}
        {displayFetchingLoader && (
          <PaginationLoadingContainer>
            <LoadingImage alt='three dot loader' src={ThreeDotLoaderGif} />
          </PaginationLoadingContainer>
        )}
      </Inner>

      <Controls>
        <DefaultButton
          buttonType='secondary'
          id='content-flyout-cancel-button'
          onClick={handleCancel}
          size='md'
          text={t('cancel')}
        />

        {(isDataLoading || isSearchable || !!contextData.length) && (
          <DefaultButton
            buttonType='primary'
            disabled={isDisabledSubmit}
            id='content-flyout-connect-content-button'
            onClick={handleSubmit}
            size='md'
            text={t('connect_content')}
          />
        )}
      </Controls>
    </Wrapper>
  );
};

export default ContentFlyout;
