import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { getLoginSchema } from '../../../../../../lib/validation_schemas/authorization/login';
import { getOtpSchema } from '../../../../../../lib/validation_schemas/authorization/otp';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useLoginMutation } from '../../../../../../redux/services/resourceApis/publicApplication/loginApi';
import { LoginResponse } from '../../../../../../redux/services/resourceApis/publicApplication/types';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import Checkbox from '../../../../../design_system/input/controls/Checkbox';
import Link from '../../../../../design_system/Link';
import InputField from '../../../../../design_system/Triage/InputField';
import PasswordInputField from '../../../../../design_system/Triage/PasswordInputField';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { routes } from '../../../applicationRouter';
import {
  ActionWrapper,
  FormHeader,
  FormWrapper,
  H1,
  Logo,
  LogoWrapper,
} from '../../../styles/shared';
import useRedirectToLoginOnSuccess from '../../../utils/useRedirectToLoginOnSuccess';
import SsoLinks from '../SsoLinks/SsoLinks';

const CheckboxWrapper = styled.div`
  width: 100%;
`;

const t = initTranslations('public_application.login');

type FormValues = {
  email: string;
  otp_attempt?: string;
  otp_from_universal_login?: string;
  password: string;
  remember_me: boolean;
};

type resendOTPValues = {
  email: string;
  password: string;
};

type Props = {
  accountData: LoginResponse | undefined;
  route: Route<typeof routes.login>;
};

const LoginForm = ({ accountData, route }: Props) => {
  const { flash } = useFlashNotification();
  const [login, result] = useLoginMutation();

  const { isLoading: loginIsLoading, isSuccess: loginIsSuccess, error: loginError, reset } = result;

  const [isOtpPending, setIsOtpPending] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (loginIsSuccess && result?.data?.otp_pending) setIsOtpPending(true);
  }, [loginIsSuccess, result]);

  const isActionInProgress = loginIsLoading;

  const { errors, touched, values, handleChange, handleSubmit, resetForm, setFieldValue } =
    useFormik({
      initialValues: {
        email: route.params.email || '',
        otp_attempt: route.params.otp_token || '',
        otp_from_universal_login: route.params.otp_token || '',
        password: '',
        remember_me: false,
      },
      validationSchema: isOtpPending ? getOtpSchema() : getLoginSchema(),
      onSubmit: async (values: FormValues) => {
        await submitForm(values);
        resetForm({
          values: {
            ...values,
            otp_attempt: '',
          },
        });
      },
    });

  const submitForm = useCallback(
    (formValues) => {
      login({ slug: route.params.slug, ...formValues });
    },
    [login, route.params.slug]
  );

  const returnToPreviousForm = () => {
    setIsOtpPending(false);
    setFieldValue('otp_attempt', '');
  };

  const resendOTP = (formData: resendOTPValues) => {
    const { slug } = route.params;
    const { email } = formData;
    const url = new URL(`${slug}/ajax/send_otp`, window.location.origin);
    url.searchParams.append('user[email]', email);

    fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => {
      if (response.ok) flash('info', t('otp_sent'));
    });
  };

  const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    setRememberMe(event.target.checked);
  };

  useDisplayFlashOnResponse({
    result,
    successMessage: result?.data?.message,
    errorMessage: messageFromError(loginError)?.join(', '),
  });

  useRedirectToLoginOnSuccess(result, { slug: route.params.slug });

  return (
    <>
      <FormHeader>
        <LogoWrapper>
          <Logo src={accountData?.logo} />
        </LogoWrapper>
        <H1>{t('title')}</H1>
      </FormHeader>

      {!accountData?.ssoOnlyEnabled ? (
        !isOtpPending ? (
          <form onSubmit={handleSubmit}>
            <FormWrapper>
              <InputField
                autoFocus={!values.email}
                errorText={touched.email && errors.email}
                iconName='envelope'
                label={t('email_label')}
                name='email'
                onChange={(event) => {
                  if (loginError) reset();
                  handleChange(event);
                }}
                placeholder={t('email_placeholder')}
                value={values.email}
              />
              <PasswordInputField
                autoFocus={!!values.email}
                errorText={touched.password && errors.password}
                iconName='lock'
                label={t('password_label')}
                name='password'
                onChange={(event) => {
                  if (loginError) reset();
                  handleChange(event);
                }}
                placeholder={t('password_placeholder')}
                value={values.password}
              />
              <CheckboxWrapper>
                <Checkbox
                  checked={values.remember_me}
                  id='remember-me'
                  label={t('remember_me_label')}
                  name='remember_me'
                  onCheck={handleRememberMeChange}
                />
              </CheckboxWrapper>
              <ActionWrapper>
                <div>
                  <Link
                    href={
                      routes.forgotPassword({
                        slug: route.params.slug,
                        redirect_path: route.params.redirect_path,
                      }).href
                    }
                    text={t('forgot_password_cta')}
                  />
                </div>
                <DefaultButton
                  disabled={isActionInProgress || !!loginError}
                  id='login-submit-button'
                  loading={isActionInProgress}
                  text={t('sign_in_cta')}
                  type='submit'
                />
              </ActionWrapper>
            </FormWrapper>
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormWrapper>
              <InputField
                autoFocus={!values.otp_attempt}
                errorText={errors.otp_attempt}
                iconName='lock'
                label={t('otp_label')}
                maxLength={6}
                name='otp_attempt'
                onChange={(event) => {
                  handleChange(event);
                }}
                placeholder={t('otp_placeholder')}
                value={values.otp_attempt}
              />
              <AssistiveText
                id='otp-token-description'
                text={t('otp_sent_description')}
                type='help'
              />
              <ActionWrapper>
                <Link
                  behavesAs='button'
                  onClick={() => {
                    const { email, password } = values;
                    resendOTP({ email, password });
                  }}
                  text={t('resend_otp')}
                />
              </ActionWrapper>
              <ActionWrapper>
                <div>
                  <Link behavesAs='button' onClick={returnToPreviousForm} text={t('back')} />
                </div>
                <DefaultButton
                  disabled={isActionInProgress || !!loginError}
                  id='login-submit-button'
                  loading={isActionInProgress}
                  text={t('sign_in_cta')}
                  type='submit'
                />
              </ActionWrapper>
            </FormWrapper>
          </form>
        )
      ) : null}

      <SsoLinks
        ableToUseGoogle={accountData?.ableToUseGoogle}
        ableToUseMicrosoft={accountData?.ableToUseMicrosoft}
        ableToUseOkta={accountData?.ableToUseOkta}
        ableToUseSaml={accountData?.ableToUseSaml}
        adminAccountName={accountData?.adminAccountName}
        adminSlug={accountData?.adminSlug}
        oktaSignInText={accountData?.oktaSignInText}
        redirectPath={route.params.redirect_path}
        rememberMe={rememberMe}
        samlSignInText={accountData?.samlSignInText}
        slug={route.params.slug}
      />
    </>
  );
};

export default LoginForm;
