import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import usePageTitle from '../../../../hooks/usePageTitle';
import initTranslations from '../../../../lib/initTranslations';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import PageContent from '../../shared/page_content';
import HrisBanner from '../HrisBanner';
import UsersTable from './Table/UsersTable';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('navigation.header');

const UsersOutline = () => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'manage_users', productTerm });

  return (
    <PageContent>
      <HeaderWrapper>
        <PageHeader subtitle={t('users_subtitle')} title={t('users_title')} />
        <HrisBanner />
      </HeaderWrapper>
      <UsersTable />
    </PageContent>
  );
};

export default UsersOutline;
