import { RefObject, useEffect, useRef } from 'react';

const useScrollTopOnChange = (dependency: unknown): RefObject<HTMLDivElement> => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }, 100);
  }, [dependency]);

  return contentRef;
};

export default useScrollTopOnChange;
