import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import NoUsersEmptyStateDark from '../../../../../../../assets/images/dark_mode/empty_state/no-users-exist-groups-empty-state.svg';
import NoUsersEmptyStateLight from '../../../../../../../assets/images/empty_state/no-users-exist-groups-empty-state.svg';
import convertToOption from '../../../../../lib/convertToOption';
import initTranslations from '../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import {
  useGetGroupQuery,
  usePatchGroupUsersMutation,
} from '../../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupWithAssociations } from '../../../../../redux/services/resourceApis/groups/types';
import { useGetModalUsersQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import { User } from '../../../../../types/User';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import { OptionWithSourceBadgeProps } from '../../../../design_system/core/SelectOption/types';
import DetailedAvatar from '../../../../design_system/display/DetailedAvatar/DetailedAvatar';
import Hoverable from '../../../../design_system/Hoverable';
import Link from '../../../../design_system/Link';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import ThemedImage from '../../../../design_system/ThemedImage';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import { fontSm5 } from '../../../../styled/TypeSystem';

const t = initTranslations('groups.modals.group_members');

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 21rem;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationHover};
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

type UserRowProps = {
  member: User;
  removeMember: (memberId: number) => void;
};

const MemberRow = ({ member, removeMember }: UserRowProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { avatar, id, name, title } = member;

  return (
    <Hoverable setIsHovered={setIsHovered}>
      <FlexRow id={`member-row-${id}`}>
        <DetailedAvatar
          avatarImage={avatar}
          id={`user-detailed-avatar-${id}`}
          label={name}
          subLabel={title}
        />
        {isHovered && (
          <Link
            behavesAs='button'
            onClick={() => {
              removeMember(member.id);
            }}
            text={t('remove')}
          />
        )}
      </FlexRow>
    </Hoverable>
  );
};

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextWrapper = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const EmptyState = () => {
  return (
    <EmptyStateWrapper>
      <ThemedImage
        alt={t('empty_state.heading')}
        darkImage={NoUsersEmptyStateDark}
        height='auto'
        lightImage={NoUsersEmptyStateLight}
        width='50%'
      />
      <TextWrapper>{t('empty_state.heading')}</TextWrapper>
    </EmptyStateWrapper>
  );
};

type ModalProps = Pick<GroupMembersModalProps, 'onCloseRequest'> & {
  group: GroupWithAssociations;
  users: User[];
};

const Modal = ({ group, users, onCloseRequest }: ModalProps) => {
  const [groupMembers, setGroupMembers] = useState<User[]>(group.users);
  const [removedMemberIds, setRemovedMemberIds] = useState<number[]>([]);
  const [addedMemberIds, setAddedMemberIds] = useState<number[]>([]);
  const [assignUsersToGroup, result] = usePatchGroupUsersMutation();
  const { isSuccess: isSubmitSuccess, isLoading: isSubmitting } = result;

  useEffect(() => {
    if (isSubmitSuccess) {
      onCloseRequest();
    }
  }, [isSubmitSuccess, onCloseRequest]);

  const formDisabled = useMemo(() => {
    return removedMemberIds.length === 0 && addedMemberIds.length === 0;
  }, [removedMemberIds, addedMemberIds]);

  useEffect(() => {
    setGroupMembers(group.users);
  }, [group.users]);

  const unremovedGroupMembers = useMemo(() => {
    return groupMembers.filter(({ id }) => !removedMemberIds.includes(id));
  }, [groupMembers, removedMemberIds]);

  const addableUsers = useMemo(() => {
    const filteredUsers = users.filter(
      (user) =>
        !groupMembers.find(({ id }) => id === user.id && !removedMemberIds.includes(user.id))
    );

    const sortedUsers = [...filteredUsers].sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'case' })
    );
    return sortedUsers;
  }, [users, groupMembers, removedMemberIds]);

  const userOptions: OptionWithSourceBadgeProps[] = useMemo(() => {
    return formatOptions(convertToOption(addableUsers)).map((option) => {
      const user = addableUsers.find((user) => user.id.toString() === option.value);
      return {
        ...option,
        hasAvatar: true,
        metaValue: user?.title,
        avatarName: option.label as string,
        avatarImage: user?.avatar,
        sourceName: 'users',
      };
    });
  }, [addableUsers]);

  const renderEmptyState = useMemo(() => {
    return unremovedGroupMembers.length === 0;
  }, [unremovedGroupMembers]);

  const addedMembers = useMemo(() => {
    return users.filter((user) => addedMemberIds.includes(user.id));
  }, [users, addedMemberIds]);

  const updatedGroup = useMemo(() => {
    return groupMembers.filter((user) => !removedMemberIds.includes(user.id)).concat(addedMembers);
  }, [addedMembers, groupMembers, removedMemberIds]);

  const taskModalArgs: TaskModalProps = {
    heapModalName: 'group-members-modal',
    isDisabled: formDisabled,
    onCloseRequest,
    primaryButtonTask: () => {
      assignUsersToGroup({
        groupId: group.id,
        user_ids: updatedGroup.map((user) => user.id),
      });
    },
    primaryButtonText: t('save'),
    processing: isSubmitting,
    title: t('title'),
    subtitle: t('subtitle'),
    scrollInsideBody: !renderEmptyState,
    headerChildren: (
      <MultiSelectField
        className='notranslate'
        defaultValue={null}
        disabled={false}
        isClearable
        isMulti
        isSearchable
        isValid
        loading={false}
        menuPlacement='bottom'
        menuPosition='absolute'
        onNonDefaultSelected={(memberIds: string[]) => setAddedMemberIds(memberIds.map(Number))}
        placeholder={t('search_by_name')}
        size='md'
        sourceBadgeOptions={userOptions}
        useSourceBadge
        value={addedMemberIds.map(String)}
      />
    ),
  };

  return (
    <TaskModal {...taskModalArgs}>
      {renderEmptyState ? (
        <EmptyState />
      ) : (
        <FlexContainer>
          {unremovedGroupMembers.map((member) => (
            <MemberRow
              key={member.id}
              member={member}
              removeMember={(memberId: number) => {
                setRemovedMemberIds([...new Set([...removedMemberIds, memberId])]);
              }}
            />
          ))}
        </FlexContainer>
      )}
    </TaskModal>
  );
};

export type GroupMembersModalProps = {
  onCloseRequest: () => void;
  groupId: number;
};

const GroupMembersModal = ({ groupId, onCloseRequest }: GroupMembersModalProps) => {
  const { data: group, error: groupError, isLoading: isGroupLoading } = useGetGroupQuery(groupId);
  const { data: users, isLoading: isUsersLoading, error: usersError } = useGetModalUsersQuery();

  const isLoading = isGroupLoading || isUsersLoading;
  const error = groupError || usersError;

  if (isLoading) return <></>;
  if (error) return <BasicErrorDisplay error={error} />;
  if (!group || !users) return <></>;

  return <Modal group={group} onCloseRequest={onCloseRequest} users={users} />;
};

export default GroupMembersModal;
