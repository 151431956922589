import React, { useCallback, useReducer } from 'react';

import { useEditorContext } from '../../../../../../../../../contexts/EditorContext';
import { EditorFlyoutProvider } from '../../../../../../../../../contexts/EditorFlyoutContext';
import { EmbedlyProvider } from '../../../../../../../../../contexts/EmbedlyProvider';
import useMutationObservable from '../../../../../../../../../hooks/useMutationObservable';
import videoResponseEmptyStateImage from '../../../../../../../../../images/empty_state/video-response-empty-state.svg';
import LoomLogo from '../../../../../../../../../images/loom_logo.svg';
import initTranslations from '../../../../../../../../../lib/initTranslations';
import isBrowserSafariOrFirefox from '../../../../../../../../../lib/isBrowserSafariOrFirefox';
import Icon from '../../../../../../../../design_system/display/icons/Icon';
import ConfirmationModal from '../../../../../../../../design_system/overlays/modals/ConfirmationModal';
import LoomRecordButton from '../../../../../../../editor/plugins/Loom/LoomRecordButton';
import VideoFlyout from '../../../../../../../editor/plugins/Video/VideoFlyout';
import { ToolbarProps } from './props';
import { LoomVideoToolbarState, initialLoomVideoToolbarState } from './reducer';
import {
  ActionButtonsWrapper,
  EmptyStateDescription,
  EmptyStateImage,
  HiddenElementsWrapper,
  RecordingInfo,
  StyledRecordButton,
  ToolbarItemsWrapper,
  ToolbarWrapper,
} from './styles';

const t = initTranslations('survey_consume.video_response_form');

const Toolbar = ({ isEmpty, isRecording, setIsRecording }: ToolbarProps) => {
  const { editor } = useEditorContext();
  const recorderActionButtonId = isEmpty
    ? 'record-video-response-answer-button'
    : 're-record-video-response-answer-button';
  const [toolbarState, dispatch] = useReducer(LoomVideoToolbarState, initialLoomVideoToolbarState);
  const { isConfirmationModalOpen, insertionType } = toolbarState;
  const isInsertionTypeSelected = insertionType === 'insert';
  const hideLoomRecorderButton = isBrowserSafariOrFirefox(navigator.userAgent);

  const loomBtnRef = React.useRef<HTMLButtonElement>(null);
  const videoBtnRef = React.useRef<HTMLButtonElement>(null);

  const startLoomRecording = () => {
    loomBtnRef?.current?.click();
  };

  const openVideoInsertModal = () => {
    videoBtnRef?.current?.click();
  };

  const handleAddVideoResponse = useCallback(
    (actionType, addVideoAction) => {
      if (isEmpty) {
        addVideoAction();
      } else {
        dispatch({
          type: 'setToolbarState',
          isConfirmationModalOpen: true,
          insertionType: actionType,
        });
      }
    },
    [isEmpty, dispatch]
  );

  const handleChangeVideoResponse = () => {
    dispatch({ type: 'setIsConfirmationModalOpen', isConfirmationModalOpen: false });
    isInsertionTypeSelected ? openVideoInsertModal() : startLoomRecording();
  };

  const onLoomBtnRefMutation = useCallback(
    (mutationList) => {
      const latestDataAttributeMutation = mutationList.find(
        (mutation: MutationRecord) =>
          mutation.type === 'attributes' && mutation.attributeName === 'data-active'
      );

      const isActive = latestDataAttributeMutation.target.getAttribute('data-active') === 'true';
      setIsRecording(isActive);
    },
    [setIsRecording]
  );
  useMutationObservable(loomBtnRef.current, onLoomBtnRefMutation);

  return (
    <ToolbarWrapper>
      <HiddenElementsWrapper>
        <EditorFlyoutProvider triggerBtnRef={loomBtnRef}>
          <EmbedlyProvider>
            <LoomRecordButton editor={editor} />
          </EmbedlyProvider>
        </EditorFlyoutProvider>
        <EditorFlyoutProvider triggerBtnRef={videoBtnRef}>
          <EmbedlyProvider>
            <VideoFlyout displayMenuItems={false} editor={editor} initialMenuItemId='upload' />
          </EmbedlyProvider>
        </EditorFlyoutProvider>
      </HiddenElementsWrapper>
      <ToolbarItemsWrapper>
        {isEmpty ? (
          <>
            <EmptyStateImage src={videoResponseEmptyStateImage} />
            <EmptyStateDescription>
              {t(hideLoomRecorderButton ? 'empty_state_add_url' : 'empty_state_record_or_add')}
            </EmptyStateDescription>
          </>
        ) : (
          <RecordingInfo>
            <Icon name='circle-info' size='2xs' />
            {t('record_new_video_info')}
          </RecordingInfo>
        )}
        <ActionButtonsWrapper>
          {!hideLoomRecorderButton && (
            <StyledRecordButton
              buttonType='secondary'
              disabled={isRecording}
              iconImage={LoomLogo}
              id={recorderActionButtonId}
              onClick={() => handleAddVideoResponse('record', startLoomRecording)}
              size='lg'
              text={t(isEmpty ? 'start_recording' : 'record_new_video')}
            />
          )}
          <StyledRecordButton
            buttonType='secondary'
            disabled={isRecording}
            id='insert-video-response-url-button'
            onClick={() => handleAddVideoResponse('insert', openVideoInsertModal)}
            size='lg'
            text={t('insert_url')}
          />
        </ActionButtonsWrapper>
      </ToolbarItemsWrapper>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          actionFunction={handleChangeVideoResponse}
          actionText={t(
            isInsertionTypeSelected
              ? 'confirmation_modal.insert_new_video'
              : 'confirmation_modal.start_recording'
          )}
          desktopSize='md'
          heapModalName='record-video-answer-confirmation-modal'
          message={t('confirmation_modal.message')}
          onCloseRequest={() =>
            dispatch({ type: 'setIsConfirmationModalOpen', isConfirmationModalOpen: false })
          }
          processing={false}
          secondaryButtonText={t('confirmation_modal.cancel')}
          title={t('confirmation_modal.title')}
        />
      )}
    </ToolbarWrapper>
  );
};

export default Toolbar;
