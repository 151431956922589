import styled from 'styled-components';

export const WrittenResponseContainer = styled.div<{ isCorrect: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: ${({ theme: { constants } }) => constants.heightLg};
  border-bottom: ${({ isCorrect, theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${
      isCorrect ? vars.stateHoverSuccess : vars.stateHoverError
    }`};
  background-color: ${({ isCorrect, theme: { vars } }) =>
    isCorrect ? vars.stateBackgroundSuccess : vars.stateBackgroundError};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
`;
