import React, { Dispatch } from 'react';
import styled from 'styled-components';

import useSlideout from '../../../../../hooks/useSlideout';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton/DefaultButton';
import SearchField from '../../../../design_system/Triage/fields/SearchField';
import ContentLibraryFilterSlideout from '../ContentLibraryFilterSlideout/ContentLibraryFilterSlideout';
import { GROUP_VIEW_BY_OPTIONS, SORT_OPTIONS, TableAction } from '../libraryReducer';
import SetDefaultDropdownMenu, {
  DropdownMenuOption,
} from './SetDefaultDropdownMenu/SetDefaultDropdownMenu';

const SearchFilterWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const FilterSearchSection = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DropdownWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
`;

const SearchFieldWrapper = styled.div`
  width: 15rem;
`;

const t = initTranslations('curriculums.content');

export interface Props {
  searchValue: string;
  tableDispatch: Dispatch<TableAction>;
  isLoading: boolean;
  viewBySelection: DropdownMenuOption;
  sortBySelection: DropdownMenuOption;
}

const FilterSection = ({
  searchValue,
  tableDispatch,
  isLoading,
  viewBySelection,
  sortBySelection,
}: Props) => {
  const {
    curriculum: { plural: curriculumTermPlural },
  } = useAccountTerminology();
  const { open, close, isOpen } = useSlideout('content-library');

  return (
    <FiltersContainer id='content-library-filter-row'>
      <DropdownWrapper>
        <SetDefaultDropdownMenu
          defaultCookieName='view-by-content-option'
          id='content-library-view-by-button'
          label={`${t('view_by')} ${viewBySelection.label.toLowerCase()}`}
          menuHeader={t('view_by_headers.your_default_view')}
          menuSubHeader={t('view_by_headers.other_views')}
          options={GROUP_VIEW_BY_OPTIONS}
          selectedOption={viewBySelection}
          setSelectedOption={(value: DropdownMenuOption) => {
            tableDispatch({ type: 'setViewBySelection', viewBy: value });
          }}
        />
        <SetDefaultDropdownMenu
          defaultCookieName='sort-by-content-option'
          id='content-library-sort-by-button'
          label={`${t('sort')} ${sortBySelection.label}`}
          menuHeader={t('sort_by_headers.your_default_sort')}
          menuSubHeader={t('sort_by_headers.other_options')}
          options={SORT_OPTIONS}
          selectedOption={sortBySelection}
          setSelectedOption={(value: DropdownMenuOption) => {
            tableDispatch({ type: 'setSortBy', sortBy: value });
          }}
        />
      </DropdownWrapper>
      <FilterSearchSection>
        <SearchFilterWrapper>
          <SearchFieldWrapper>
            <SearchField
              dataLoading={isLoading}
              placeholder={t('search_placeholder', { subject: curriculumTermPlural.toLowerCase() })}
              searchValue={searchValue}
              setSearchValue={(value: string) =>
                tableDispatch({ type: 'setSearchValue', searchValue: value })
              }
            />
          </SearchFieldWrapper>
        </SearchFilterWrapper>
        <DefaultButton
          buttonType='tertiary'
          iconName='sliders'
          iconWeight='solid'
          id='filter-panel-slideout-button'
          onClick={() => {
            isOpen ? close() : open();
          }}
          size='md'
          text={t('filters')}
        />
      </FilterSearchSection>
      <ContentLibraryFilterSlideout outsideTableDispatch={tableDispatch} />
    </FiltersContainer>
  );
};

export default FilterSection;
