import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { OverlayContentHeaderTitle } from '../../../../../shared/FullScreenOverlay/styles';
import { UpdatePaymentMethodFormProps } from '../../../../../shared/UpdatePaymentMethodForm/types';
import UpdatePaymentMethodForm from '../../../../../shared/UpdatePaymentMethodForm/UpdatePaymentMethodForm';
import { Subtitle } from '../styles';
import {
  ArrowIconButton,
  BackToConfigurePlanLinkWrapper,
  PaymentMethodHeaderWrapper,
  PaymentMethodSectionContent,
  PaymentMethodSectionWrapper,
  StyledLink,
} from './styles';

type PaymentMethodSectionProps = {
  resetErrorState: () => void;
  setShowPaymentSection: React.Dispatch<React.SetStateAction<boolean>>;
  simplePricingEnabled?: boolean;
} & Omit<UpdatePaymentMethodFormProps, 'columnDirectionOnMd'>;

const t = initTranslations(
  'per_user_pricing.configure_plan_overlay_content.payment_method_section'
);

const PaymentMethodSection = ({
  resetErrorState,
  clearFieldError,
  validateFields,
  formData,
  dispatch,
  isFormProcessing,
  setShowPaymentSection,
  validateField,
  paymentMethodFormErrors,
  billingDetailsData,
  simplePricingEnabled,
}: PaymentMethodSectionProps) => {
  const backButtonText = simplePricingEnabled ? t('back') : t('back_to_configure_plan');
  const handleClickOnBackToConfigurePlan = () => {
    resetErrorState();
    dispatch({ type: 'setInitialState' });
    setShowPaymentSection(false);
  };

  return (
    <PaymentMethodSectionWrapper>
      <BackToConfigurePlanLinkWrapper>
        <ArrowIconButton
          ariaLabel={backButtonText}
          buttonSize='md'
          name='arrow-left'
          onClick={handleClickOnBackToConfigurePlan}
          weight='solid'
        />
        <StyledLink
          behavesAs='button'
          onClick={handleClickOnBackToConfigurePlan}
          text={backButtonText}
          underlineBehavior='dynamic'
        />
      </BackToConfigurePlanLinkWrapper>

      <PaymentMethodSectionContent>
        <PaymentMethodHeaderWrapper>
          <OverlayContentHeaderTitle>{t('title')}</OverlayContentHeaderTitle>
          <Subtitle text={t('subtitle')} />
        </PaymentMethodHeaderWrapper>

        <UpdatePaymentMethodForm
          billingDetailsData={billingDetailsData}
          clearFieldError={clearFieldError}
          columnDirectionOnMd='column'
          dispatch={dispatch}
          formData={formData}
          isFormProcessing={isFormProcessing}
          paymentMethodFormErrors={paymentMethodFormErrors}
          validateField={validateField}
          validateFields={validateFields}
        />
      </PaymentMethodSectionContent>
    </PaymentMethodSectionWrapper>
  );
};

export default PaymentMethodSection;
