import { useCallback } from 'react';

export default function usePartnerstack() {
  const createSignup = useCallback((email: string) => {
    if (email && window.growsumo) {
      // Populate the growsumo data object
      window.growsumo.data.email = email;
      // Email is a way to uniquely identify customers
      window.growsumo.data.customer_key = email;
      // Register the signup with PartnerStack
      window.growsumo.createSignup();
    }
  }, []);

  return {
    partnerstackCreateSignup: createSignup,
  };
}
