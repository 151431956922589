import React from 'react';
import { useTheme } from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import { OverlayContentHeaderTitle } from '../../../../../shared/FullScreenOverlay/styles';
import { SkeletonLoader } from '../../../../../shared/SkeletonLoader/styles';
import { SpaceBetweenWrapper } from '../../../styles';
import { Divider, SummaryWrapper } from '../styles';

const t = initTranslations('per_user_pricing.full_screen_overlay_content.summary');

const SummarySkeleton = () => {
  const {
    constants: { spacerSm3, spacerMd3 },
  } = useTheme();

  return (
    <SummaryWrapper>
      <OverlayContentHeaderTitle withBottomMargin>{t('title')}</OverlayContentHeaderTitle>

      <SkeletonLoader marginBottom={spacerSm3} width='25%' />
      <SkeletonLoader marginBottom={spacerMd3} width='45%' />

      <SpaceBetweenWrapper>
        <SkeletonLoader width='50%' />
        <SkeletonLoader width='30%' />
      </SpaceBetweenWrapper>
      <Divider />

      <SpaceBetweenWrapper>
        <SkeletonLoader marginBottom={spacerSm3} marginTop={spacerSm3} width='30%' />
        <SkeletonLoader marginBottom={spacerSm3} marginTop={spacerSm3} width='20%' />
      </SpaceBetweenWrapper>

      <SkeletonLoader width='45%' />
    </SummaryWrapper>
  );
};

export default SummarySkeleton;
