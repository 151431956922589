import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TopicTitle from '../../components/TopicTitle/TopicTitle';
import { LabelWrapper } from '../../shared/styles';
import { CardBackgroundTopicTitle } from './styles';

const TitleWrapper = styled.div`
  width: 100%;
  padding: ${({ theme: { constants } }) => constants.spacerSm2} 0;
`;

type Props = {
  isFlatTop: boolean;
  title: string;
  id: number;
};

const TopicTitleContainer = ({ isFlatTop, title, id }: Props) => {
  const {
    topic: { singular: topicSingular },
  } = useAccountTerminology();

  return (
    <CardBackgroundTopicTitle id='topic-title-header-edit' isFlatTop={isFlatTop}>
      <TitleWrapper>
        <TopicTitle title={title} topicId={id} />
      </TitleWrapper>
      <LabelWrapper>{topicSingular}</LabelWrapper>
    </CardBackgroundTopicTitle>
  );
};

export default TopicTitleContainer;
