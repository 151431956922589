import { Content } from '@tiptap/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { setQuestionAnswered } from '../../../../../../../redux/domains/surveyConsume/surveyConsumeSlice';
import { useAppDispatch } from '../../../../../../../redux/hooks';
import { FormProps, FormValues, VideoResponseFormValues } from '../types';
import LoomVideoResponseEditor from './LoomVideoResponseEditor/LoomVideoResponseEditor';

const VideoResponseForm = ({ question }: FormProps) => {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const dispatch = useAppDispatch();
  const currentQuestionFormValueIndex = values.answersAttributes.findIndex(
    (answer) => answer.questionId === question.id
  );
  const currentQuestionFormValue = values.answersAttributes[
    currentQuestionFormValueIndex
  ] as VideoResponseFormValues;

  const handleLoomInsert = (responseJson: Content) => {
    const path = `answersAttributes[${currentQuestionFormValueIndex}].responseJson`;

    dispatch(setQuestionAnswered({ questionId: question.id, isAnswered: true }));
    setFieldValue(path, responseJson);
  };

  return (
    <LoomVideoResponseEditor
      content={currentQuestionFormValue.responseJson}
      editable
      handleLoomInsert={handleLoomInsert}
      questionId={question.id}
    />
  );
};

export default VideoResponseForm;
