import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useGetLoginAccountDataQuery } from '../../../../redux/services/resourceApis/publicApplication/loginApi';

const useAccountData = (slug: string, account_id?: number) => {
  const loginQueryResult = useGetLoginAccountDataQuery({
    slug,
    account_id,
  });
  const {
    isLoading: isLoadingAccountData,
    isSuccess: isSuccessAccountData,
    error: accountDataError,
    data: accountData,
  } = loginQueryResult;

  useDisplayFlashOnResponse({
    result: loginQueryResult,
    errorMessage: messageFromError(accountDataError)?.join(', '),
  });

  return { isLoadingAccountData, isSuccessAccountData, accountData };
};

export default useAccountData;
