import BambooHrDark from '../../../assets/images/dark_mode/integrations/bamboo_hr.png';
import FinchDark from '../../../assets/images/dark_mode/integrations/finch.png';
import InsperityDark from '../../../assets/images/dark_mode/integrations/insperity.png';
import JustworksDark from '../../../assets/images/dark_mode/integrations/justworks.png';
import NamelyDark from '../../../assets/images/dark_mode/integrations/namely.png';
import PaycomDark from '../../../assets/images/dark_mode/integrations/paycom.png';
import PaylocityDark from '../../../assets/images/dark_mode/integrations/paylocity.png';
import QuickbooksDark from '../../../assets/images/dark_mode/integrations/quickbooks.png';
import RipplingDark from '../../../assets/images/dark_mode/integrations/rippling-logo.png';
import SlackDark from '../../../assets/images/dark_mode/integrations/slack-logo.png';
import SquarePayrollDark from '../../../assets/images/dark_mode/integrations/square_payroll.png';
import TimeDoctorDark from '../../../assets/images/dark_mode/integrations/time-doctor.png';
import TrinetDark from '../../../assets/images/dark_mode/integrations/trinet.png';
import WorkdayDark from '../../../assets/images/dark_mode/integrations/workday.png';
import ZenefitsDark from '../../../assets/images/dark_mode/integrations/zenefits.png';
import GoogleLogo from '../../../assets/images/google.png';
import GustoLogo from '../../../assets/images/gusto-logo.png';
import AdpRun from '../../../assets/images/integrations/adp_run.png';
import AdpWorkforceNow from '../../../assets/images/integrations/adp_workforce_now.png';
import BambooHrLight from '../../../assets/images/integrations/bamboo_hr.png';
import FinchLight from '../../../assets/images/integrations/finch.png';
import InsperityLight from '../../../assets/images/integrations/insperity.png';
import JustworksLight from '../../../assets/images/integrations/justworks.png';
import MicrosoftEntraLogo from '../../../assets/images/integrations/microsoft-entra.png';
import NamelyLight from '../../../assets/images/integrations/namely.png';
import PaycomLight from '../../../assets/images/integrations/paycom.png';
import PaylocityLight from '../../../assets/images/integrations/paylocity.png';
import QuickbooksLight from '../../../assets/images/integrations/quickbooks.png';
import RipplingLight from '../../../assets/images/integrations/rippling-logo.png';
import SlackLight from '../../../assets/images/integrations/slack-logo.png';
import SquarePayrollLight from '../../../assets/images/integrations/square_payroll.png';
import TimeDoctorLight from '../../../assets/images/integrations/time-doctor.png';
import TrinetLight from '../../../assets/images/integrations/trinet.png';
import UKGProLight from '../../../assets/images/integrations/ulti_pro.png';
import WorkdayLight from '../../../assets/images/integrations/workday.png';
import ZenefitsLight from '../../../assets/images/integrations/zenefits.png';
import OktaLogo from '../../../assets/images/okta.png';
import ZapierLogo from '../../../assets/images/zapier-logo.png';
import { IntegrationCardData } from '../types/IntegrationCard';

const useIntegrationCardsImages = () => {
  const cardsData: IntegrationCardData = {
    gusto: {
      lightImage: GustoLogo,
      darkImage: GustoLogo,
    },
    slack: {
      lightImage: SlackLight,
      darkImage: SlackDark,
    },
    bamboo_hr: {
      lightImage: BambooHrLight,
      darkImage: BambooHrDark,
    },
    justworks: {
      lightImage: JustworksLight,
      darkImage: JustworksDark,
    },
    paylocity: {
      lightImage: PaylocityLight,
      darkImage: PaylocityDark,
    },
    quickbooks: {
      lightImage: QuickbooksLight,
      darkImage: QuickbooksDark,
    },
    finch: {
      lightImage: FinchLight,
      darkImage: FinchDark,
    },
    google: {
      lightImage: GoogleLogo,
      darkImage: GoogleLogo,
    },
    insperity: {
      lightImage: InsperityLight,
      darkImage: InsperityDark,
    },
    microsoft: {
      lightImage: MicrosoftEntraLogo,
      darkImage: MicrosoftEntraLogo,
    },
    namely: {
      lightImage: NamelyLight,
      darkImage: NamelyDark,
    },
    okta: {
      lightImage: OktaLogo,
      darkImage: OktaLogo,
    },
    paycom: {
      lightImage: PaycomLight,
      darkImage: PaycomDark,
    },
    rippling: {
      lightImage: RipplingLight,
      darkImage: RipplingDark,
    },
    square_payroll: {
      lightImage: SquarePayrollLight,
      darkImage: SquarePayrollDark,
    },
    time_doctor: {
      lightImage: TimeDoctorLight,
      darkImage: TimeDoctorDark,
    },
    trinet: {
      lightImage: TrinetLight,
      darkImage: TrinetDark,
    },
    ulti_pro: {
      lightImage: UKGProLight,
      darkImage: UKGProLight,
    },
    workday: {
      lightImage: WorkdayLight,
      darkImage: WorkdayDark,
    },
    zapier: {
      lightImage: ZapierLogo,
      darkImage: ZapierLogo,
    },
    zenefits: {
      lightImage: ZenefitsLight,
      darkImage: ZenefitsDark,
    },
    adp_run: {
      lightImage: AdpRun,
      darkImage: AdpRun,
    },
    adp_workforce_now: {
      lightImage: AdpWorkforceNow,
      darkImage: AdpWorkforceNow,
    },
  };

  return { cardsData };
};

export default useIntegrationCardsImages;
