import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { formatUserOptions } from '../../../../../lib/formatUserOptions';
import initTranslations from '../../../../../lib/initTranslations';
import { User } from '../../../../../types/User';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import Scrollbar from '../../../../styled/Scrollbar';
import { fontSm5 } from '../../../../styled/TypeSystem';
import ConfirmAssignmentModal from '../ConfirmAssignmentModal';
import AssignedUserItem from './AssignedUserItem';

export interface NotifiedUsers extends User {
  new?: boolean;
}

export interface Users {
  candidates: User[];
  notified_users: NotifiedUsers[];
}

const SelectField = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;
const AssignedUserList = styled.div`
  ${Scrollbar};
  overflow-y: auto;
  max-height: 20rem;
`;

const StyledSelect = styled(MultiSelectField)`
  margin: 0 !important;
`;

const Subtitle = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;

const t = initTranslations('people_assigned_modal');

interface Props {
  curriculumId: number | undefined;
  showPeopleAssignedModal: boolean;
  setShowShareChangesModal: () => void;
  users: Users;
  setUsers: (users: Users) => void;
}

const PeopleAssignedModal = ({
  curriculumId,
  showPeopleAssignedModal,
  setShowShareChangesModal,
  users,
  setUsers,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationAssignedModal, setShowConfirmationAssignedModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [showAbandonModal, setShowAbandonModal] = useState(false);
  const [deletedUserId, setDeletedUserId] = useState<number | null>(null);

  const closeModal = () => {
    if (newUsersCount === 0) {
      setShowModal(false);
      setShowShareChangesModal();
    } else {
      setShowAbandonModal(true);
      setShowModal(false);
    }
  };

  useEffect(() => {
    setShowModal(showPeopleAssignedModal);
  }, [showPeopleAssignedModal]);

  useEffect(() => {
    setNewUsersCount(users.notified_users.filter((i) => i.new).length);
  }, [users]);

  useEffect(() => {
    if (deletedUserId !== null) {
      const candidates = users.candidates;
      const notifiedUsers = users.notified_users;
      const userIndex = notifiedUsers.findIndex((x) => x.id === deletedUserId);
      const user = notifiedUsers[userIndex];
      notifiedUsers.splice(userIndex, 1);
      candidates.unshift(user);
      setUsers({
        candidates,
        notified_users: notifiedUsers,
      });
      setDeletedUserId(null);
    }
  }, [deletedUserId, setUsers, users.candidates, users.notified_users]);

  const taskModalArgs: TaskModalProps = {
    secondaryButtonText: t('cancel_label'),
    title: t('title'),
    subtitle: t('subtitle'),
    processing: false,
    onCloseRequest: closeModal,
    heapModalName: 'people-assigned-modal',
    isDisabled: newUsersCount < 0,
    primaryButtonText: t('save'),
    primaryButtonTask: () => {
      setShowConfirmationAssignedModal(true);
      setShowModal(false);
    },
  };

  const addNewUsers = () => {
    const candidates = [...users.candidates];
    const notifiedUsers = [...users.notified_users];
    selectedUsers.forEach((i) => {
      const userIndex = candidates.findIndex((x) => x.id === +i);
      const user: NotifiedUsers = candidates[userIndex];
      candidates.splice(userIndex, 1);
      const newUser: NotifiedUsers = { ...user, new: true };
      notifiedUsers.unshift(newUser);
    });
    setUsers({
      candidates,
      notified_users: notifiedUsers,
    });
    setSelectedUsers([]);
  };

  return (
    <>
      {showModal && (
        <TaskModal {...taskModalArgs} desktopSize='lg'>
          <SelectField>
            <Subtitle>{t('select_label')}</Subtitle>
            <StyledSelect
              className='assign-users-select'
              defaultValue={null}
              onMenuClose={addNewUsers}
              onNonDefaultSelected={(value: string[]) => {
                setSelectedUsers(value);
              }}
              options={formatUserOptions(users.candidates)}
              placeholder={t('placeholder')}
              value={selectedUsers}
            />
          </SelectField>

          <Subtitle>{t('list_title', { count: users.notified_users.length })}</Subtitle>
          <AssignedUserList>
            {users.notified_users.map((user: NotifiedUsers) => (
              <AssignedUserItem
                isNew={user.new}
                key={user.id}
                setDeletedUserId={setDeletedUserId}
                {...user}
              />
            ))}
          </AssignedUserList>
        </TaskModal>
      )}
      {showConfirmationAssignedModal && (
        <ConfirmAssignmentModal
          curriculumId={curriculumId}
          newUsersCount={newUsersCount}
          setShowConfirmationAssignedModal={setShowConfirmationAssignedModal}
          setShowModal={setShowModal}
          setShowShareChangesModal={setShowShareChangesModal}
          users={users.notified_users}
        />
      )}
      {showAbandonModal && (
        <ConfirmationModal
          actionFunction={() => {
            setShowAbandonModal(false);
            setShowModal(true);
          }}
          actionText={t('abandon_people_assigned_modal.action_text')}
          heapModalName='abandon-people-assigned-modal'
          message={t('abandon_people_assigned_modal.message')}
          onCloseRequest={() => {
            setShowAbandonModal(false);
            setShowShareChangesModal();
          }}
          processing={false}
          secondaryButtonText={t('abandon_people_assigned_modal.secondary_button_text')}
          title={t('abandon_people_assigned_modal.title')}
        />
      )}
    </>
  );
};

export default PeopleAssignedModal;
