import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import Link from '../../../design_system/Link/index';
import P from '../../../design_system/text/P/P';
import { fontSm4 } from '../../../styled/TypeSystem';

export const CertificateText = styled(P)`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  pointer-events: none;
  text-decoration: underline;
  ${fontSm4};
`;

export const CertificateLink = styled(Link)`
  display: none;
  flex-direction: row;
  align-items: flex-end;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  text-decoration: none;
  position: relative;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
  &:hover {
    text-decoration: none;
  }
  &::before {
    content: '';
    display: block;
    background-color: ${({ theme: { vars } }) => vars.textSubdued};
    width: 3px;
    height: 3px;
    border-radius: 3px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -13px;
    margin: auto;
  }
`;

export const CertificateIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  pointer-events: none;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;
