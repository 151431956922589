import React, { useEffect, useState } from 'react';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import { useRedirectToBillingOverlay } from '../../../../../../../hooks/useRedirectToBillingOverlay';
import initTranslations from '../../../../../../../lib/initTranslations';
import {
  useBulkActivateMutation,
  useFetchRestrictedBulkActivateUsersQuery,
} from '../../../../../../../redux/services/resourceApis/users/usersApi';
import ConfirmationModal from '../../../../../../design_system/overlays/modals/ConfirmationModal';
import PastDueModal from '../../../../../billing/per_user_pricing/AccountStatusModal/PastDueModal/PastDueModal';
import { BulkUnrchiveModalProps } from '../types';
import BulkUserUpdateModal from './BulkUserUpdateModal';

const t = initTranslations('users_table.bulk_user_management_menu');

const BulkUnarchiveModal = ({
  onClose,
  selectedUsersIds,
  onSuccess,
  type,
}: BulkUnrchiveModalProps) => {
  const { data, isLoading, isSuccess, isError, error } = useFetchRestrictedBulkActivateUsersQuery({
    userIds: selectedUsersIds,
  });
  const { nonBilling, noFreeSlots, status } = useCurrentAccount();
  const fetchRestrictedResult = { isLoading, isSuccess, isError };
  const [bulkActivate, result] = useBulkActivateMutation();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPastDueModal, setShowPastDueModal] = useState(false);
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const { totalUserSlotsCount, usedUserSlotsCount } = useCurrentAccount();
  const { permission } = useCurrentUser();

  useEffect(() => {
    if (noFreeSlots && status === 'past_due') {
      setShowPastDueModal(true);
    } else {
      data && setShowUpgradeModal(data.noFreeSlots);
    }
  }, [data, noFreeSlots, status]);

  const closePastDueModal = () => {
    setShowPastDueModal(false);
    onClose();
  };

  if (fetchRestrictedResult.isLoading) {
    return <></>;
  }

  const isExceedAvailableSeats = usedUserSlotsCount + selectedUsersIds.length > totalUserSlotsCount;
  const showUpgradeNowButton = !(permission === 'admin' && isExceedAvailableSeats) && !nonBilling;

  return (
    <>
      {showPastDueModal ? (
        <PastDueModal closeModal={closePastDueModal} showPastDueModal={showPastDueModal} />
      ) : showUpgradeModal ? (
        <ConfirmationModal
          actionFunction={() => {
            onClose();
            navigateToBillingOverlay();
          }}
          actionText={t('bulk_unarchive_disabled.cta')}
          hasPrimaryButton={showUpgradeNowButton}
          heapModalName='bulk-unarchive-upgrade-modal'
          message={t('bulk_unarchive_disabled.message')}
          onCloseRequest={onClose}
          processing={false}
          title={t('bulk_unarchive_disabled.header')}
        />
      ) : (
        <BulkUserUpdateModal
          bulkUpdate={bulkActivate}
          bulkUpdateResult={result}
          data={data}
          fetchRestrictedError={error}
          fetchRestrictedResult={fetchRestrictedResult}
          onClose={onClose}
          onSuccess={onSuccess}
          selectedUsersIds={selectedUsersIds}
          type={type}
        />
      )}
    </>
  );
};

export default BulkUnarchiveModal;
