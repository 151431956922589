import { useFormikContext } from 'formik';
import React, { FC, useCallback, useState } from 'react';

import useAnalyticsUserEvents from '../../../../hooks/useAnalyticsUserEvents';
import initTranslations from '../../../../lib/initTranslations';
import Checkbox from '../../../design_system/input/controls/Checkbox';
import RangeSlider from '../../shared/RangeSlider/RangeSlider';
import { calculatePercentage } from '../../shared/RangeSlider/utils/calculatePercentage';
import { ResponsibilityDetailsFormData } from '../modals/ResponsibilityDetailsModal/ResponsibilityDetails/types';
import { remToPx } from '../shared/utils/remToPx';
import SpecializationPill from '../SpecializationPill/SpecializationPill';
import { SelectedSpecialization } from '../types';
import { NUMBER_HEIGHT_WITH_SUSPENSION, SCORE_STATS } from './_data';
import {
  PopupText,
  ScoreNumber,
  ScoreTrack,
  SpecializationContainer,
  SpecializationHeader,
  SpecializationRangeSliderContainer,
} from './styles';
import { SpecializationProps } from './types';

const { MIN, MAX, STEP } = SCORE_STATS;
const NUMBERS = Array.from({ length: MAX + 1 }, (_, i) => i);

const t = initTranslations('delegation_planner.specialization');

const Specialization: FC<SpecializationProps> = ({
  id,
  color,
  checked = false,
  terminology,
  score: initialScore = 0,
}) => {
  const [score, setScore] = useState(initialScore);
  const [isRangeSliderOpen, setIsRangeSliderOpen] = useState(checked);
  const { cdpResponsibilityDetailsElementClicked } = useAnalyticsUserEvents();
  const percentage = calculatePercentage(score, MIN, MAX);
  const numberHeightPx = remToPx(NUMBER_HEIGHT_WITH_SUSPENSION);
  const currentNumberPosition = (percentage / 100) * NUMBERS.length * numberHeightPx;
  const { values, setValues } = useFormikContext<ResponsibilityDetailsFormData>() ?? {};

  const handleOpenRangeSlider = useCallback(() => {
    setIsRangeSliderOpen(!isRangeSliderOpen);

    let newSpecializations: SelectedSpecialization[];

    if (isRangeSliderOpen) {
      newSpecializations = values.specializations.filter(
        (specialization) => specialization.id !== id
      );

      setScore(0);
    } else {
      newSpecializations = [...new Set([...values.specializations, ...[{ id, score }]])];
    }

    setValues({ ...values, specializations: newSpecializations });
    cdpResponsibilityDetailsElementClicked({ element: 'rating' });
  }, [isRangeSliderOpen, values, setValues, cdpResponsibilityDetailsElementClicked, id, score]);

  const onRangeSliderSlideMove = (newValue: number) => {
    if (newValue === score) return;

    setScore(newValue);
  };

  const onRangeSliderSlideEnd = (newValue: number) => {
    const roundedValue = Math.round(newValue);

    setScore(roundedValue);
    setValues({
      ...values,
      specializations: values.specializations.map((specialization) => {
        return specialization.id === id ? { id, score: roundedValue } : specialization;
      }),
    });
  };

  return (
    <SpecializationContainer>
      <SpecializationHeader>
        <SpecializationPill
          color={color}
          id={id}
          isAlwaysExpanded
          percentage={percentage}
          score={Math.round(score)}
          title={terminology}
        />

        <Checkbox
          checked={isRangeSliderOpen}
          id={`${terminology}-checkbox`}
          name={terminology}
          onCheck={handleOpenRangeSlider}
        />
      </SpecializationHeader>

      <SpecializationRangeSliderContainer isOpen={isRangeSliderOpen}>
        <RangeSlider
          color={color}
          id={id}
          max={MAX}
          min={MIN}
          onSlideEnd={onRangeSliderSlideEnd}
          onSlideMove={onRangeSliderSlideMove}
          popupContent={
            <PopupText>
              {t('rating')} &nbsp;
              <ScoreTrack
                style={{
                  marginTop: `-${Math.round(currentNumberPosition)}px`,
                }}
              >
                {NUMBERS.map((number) => (
                  <ScoreNumber key={number}>{number}</ScoreNumber>
                ))}
              </ScoreTrack>
              /{MAX}
            </PopupText>
          }
          step={STEP}
          value={score}
        />
      </SpecializationRangeSliderContainer>
    </SpecializationContainer>
  );
};

export default Specialization;
