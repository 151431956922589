import { Form, useFormikContext } from 'formik';
import React from 'react';

import { useAccountTerminology } from '../../../../../../components/AccountTerminologyProvider';
import initTranslations from '../../../../../../lib/initTranslations';
import TaskModal, { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';
import CollapsibleFormElementsList from '../CollapsibleCurriculumFormElement/CollapsibleFormElementsList';
import { FormValues, SelectCourseModalProps } from './types';

const t = initTranslations('steps.modals.trainual_capture.save_capture_to_step.select_course');

const SelectCourseModal = ({ showSelectCourseModal, dispatch }: SelectCourseModalProps) => {
  const { values, isSubmitting, handleSubmit } = useFormikContext<FormValues>();
  const { productTerm } = useAccountTerminology();

  const taskModalArgs: TaskModalProps = {
    title: t('title', { productTerm }),
    onCloseRequest: () => {
      dispatch({ type: 'setShowModal', showModal: true });
      dispatch({ type: 'setShowSelectCourseModal', showSelectCourseModal: false });
    },
    processing: isSubmitting,
    primaryButtonText: t('save'),
    primaryButtonTask: handleSubmit,
    secondaryButtonText: t('go_back'),
    isDisabled: isSubmitting || !values.courseId,
    heapModalName: 'save-capture-to-step-modal',
  };

  return (
    <>
      {showSelectCourseModal && (
        <Form autoComplete='off'>
          <TaskModal {...taskModalArgs} desktopSize='lg'>
            <CollapsibleFormElementsList />
          </TaskModal>
        </Form>
      )}
    </>
  );
};

export default SelectCourseModal;
