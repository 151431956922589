import { CourseSuggestion } from '../CourseSuggestions/CourseSuggestionsTable/CourseSuggestionsTable';

type SuggestedTopicsAction =
  | { type: 'setSelectedCourse'; selectedCourse: CourseSuggestion | null }
  | { type: 'storeCompletion'; completion: string }
  | { type: 'resetContentError' }
  | { type: 'removeCourseSuggestion'; courseSuggestion: CourseSuggestion }
  | { type: 'clearCompletions' };

type SuggestedTopicsSlideoutState = {
  hasContentError: boolean;
  courseCompletions: string[];
  courseSuggestions: CourseSuggestion[];
  selectedCourse: CourseSuggestion | null;
  stepCompletionByCourse: { [key: string]: string };
};

export const reducer = (
  state: SuggestedTopicsSlideoutState,
  action: SuggestedTopicsAction
): SuggestedTopicsSlideoutState => {
  switch (action.type) {
    case 'setSelectedCourse': {
      return { ...state, selectedCourse: action.selectedCourse };
    }
    case 'storeCompletion': {
      if (state.selectedCourse) {
        const isValidFormat = action.completion
          .split('\n')
          .every((suggestion) => suggestion.split('|').length === 3);
        if (isValidFormat) {
          const stepCompletionByCourse = {
            ...state.stepCompletionByCourse,
            [state.selectedCourse.title]: action.completion,
          };
          return { ...state, stepCompletionByCourse };
        } else {
          return { ...state, hasContentError: true };
        }
      } else {
        const isValidFormat = action.completion
          .split('\n')
          .every((suggestion) => suggestion.split('|').length === 2);
        if (isValidFormat) {
          const newCourseCompletions = [...state.courseCompletions, action.completion];
          const courseSuggestions = action.completion.split('\n').map((courseData) => {
            const [emoji, title] = courseData.split('|');
            return { emoji, title };
          });
          return { ...state, courseCompletions: newCourseCompletions, courseSuggestions };
        } else {
          return { ...state, hasContentError: true };
        }
      }
    }
    case 'resetContentError': {
      return { ...state, hasContentError: false };
    }
    case 'clearCompletions': {
      return { ...state, courseCompletions: [], courseSuggestions: [], stepCompletionByCourse: {} };
    }
    case 'removeCourseSuggestion': {
      const removeIndex = state.courseSuggestions.indexOf(action.courseSuggestion);
      const courseSuggestions = [...state.courseSuggestions];
      courseSuggestions.splice(removeIndex, 1);

      return { ...state, courseSuggestions };
    }
  }
};
