import styled from 'styled-components';

import P from '../../../../../../design_system/text/P';
import { fontSm4 } from '../../../../../../styled/TypeSystem';

export const EmptyStateCardWrapper = styled.div`
  width: 15rem;
  height: 16.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const EmptyStateCardImage = styled.img`
  width: 8.625rem;
  height: 5.375rem;
`;

export const EmptyStateCardBody = styled(P)`
  text-align: center;
  ${fontSm4};
`;
