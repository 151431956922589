import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon/Icon';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { routes } from '../../../publicApplication/applicationRouter';

const t = initTranslations('curriculums.content');

const Breadcrumb = styled.a`
  display: flex;
  padding-block: ${({ theme: { constants } }) => constants.spacerSm2};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  align-items: center;
  width: fit-content;
  text-decoration: none;
`;

const ArrowIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const BreadcrumbText = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};

  :hover {
    text-decoration: underline;
  }

  ${fontSm5};
`;

const BackBreadcrumb = () => {
  const { slug } = useCurrentAccount();

  return (
    <Breadcrumb {...routes.content({ slug }).link}>
      <ArrowIcon fixedWidth name='arrow-left' size='sm' />
      <BreadcrumbText>{t('back_breadcrumb')}</BreadcrumbText>
    </Breadcrumb>
  );
};

export default BackBreadcrumb;
