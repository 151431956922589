import styled from 'styled-components';

import Checkbox from '../../../../../design_system/input/controls/Checkbox';

export const CheckboxWrapper = styled.div<{ checked: boolean }>`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: ${({ theme: { vars }, checked }) =>
    checked ? vars.accentSubdued1 : vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
`;

// DS override - Need to match design
export const StyledCheckbox = styled(Checkbox)<{ disabled: boolean; checked: boolean }>`
  color: ${({ theme: { vars }, disabled }) => (disabled ? vars.textDisabled : vars.textDefault)};

  label {
    font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  }

  .checkbox-container {
    border-color: ${({ theme: { vars }, disabled, checked }) =>
      !disabled && (checked ? vars.accentPrimaryDefault : vars.textDefault)};
  }
`;
