import styled from 'styled-components';

import Link from '../../../design_system/Link';
import SingleSelectField from '../../../design_system/Triage/fields/SingleSelectField';
import InputField from '../../../design_system/Triage/InputField';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { fontSm4 } from '../../../styled/TypeSystem';
import { ColumnDirection } from './types';

export const Form = styled.form<{ columnDirectionOnMd: ColumnDirection }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ columnDirectionOnMd, theme: { constants } }) =>
    columnDirectionOnMd === 'column' ? constants.spacerLg3 : constants.spacerMd3};

  @media (min-width: ${mediaBreakpointPxMd}) {
    flex-direction: ${({ columnDirectionOnMd }) => columnDirectionOnMd};
  }
`;

export const FormColumn = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
`;

// DS Override - to match design
export const StyledInputField = styled(InputField)`
  ${fontSm4};
`;

// DS Override - to match design
export const SelectField = styled(SingleSelectField)`
  ${fontSm4};
  .react-select__placeholder {
    ${TruncatedText({})};
  }
`;

export const InputFieldWrapper = styled.div<{ withMarginBottom?: boolean }>`
  flex-grow: 1;
  flex-basis: 50%;
  ${({ withMarginBottom, theme: { constants } }) =>
    withMarginBottom && {
      marginBottom: constants.spacerMd2,
    }}
`;

export const RowWithTwoInputField = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const StyledEditLink = styled(Link)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  position: absolute;
  top: 2.1rem;
  right: 1rem;
  cursor: pointer;
  text-decoration: initial;
`;

export const InputFieldWithEditLinkWrapper = styled(InputFieldWrapper)`
  position: relative;
`;
