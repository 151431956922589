import React from 'react';

import FullScreenOverlay from '../../shared/FullScreenOverlay/FullScreenOverlay';
import CreateCurriculumOverlayContent from '../FullScreenOverlayContent/CreateCurriculumOverlayContent/CreateCurriculumOverlayContent';

const CreateCurriculumFullScreenOverlay = () => {
  return (
    <FullScreenOverlay fixedCloseIcon id='create-curriculum-full-screen-overlay'>
      <CreateCurriculumOverlayContent />
    </FullScreenOverlay>
  );
};

export default CreateCurriculumFullScreenOverlay;
