import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useGroupsIndex } from '../../../../contexts/GroupsIndexContext';
import { useGroupsModals } from '../../../../contexts/GroupsModalContext';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import SearchField from '../../../design_system/Triage/fields/SearchField';
import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';

const SearchFieldWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  @media (min-width: ${mediaBreakpointPxMd}) {
    max-width: 16rem;
    margin-top: 0;
    flex: 1;
  }
`;

const StyleSearchField = styled(SearchField)`
  width: 100%;
`;

const TableActionsHeaderElementsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    flex-direction: row;
  }
`;

const AddGroupButtonWrapper = styled.div`
  white-space: nowrap;
  height: 100%;
  margin-left: auto;
`;

const t = initTranslations('groups');

const TableActionsHeader = () => {
  const ability = useCurrentUserAbilities();
  const { dispatch: groupsModalsDispatch } = useGroupsModals();
  const {
    isLoading,
    searchQuery,
    dispatch,
    data: { groups },
    isFetching,
    queryParams: { search_query },
  } = useGroupsIndex();

  const displaySearchField = useMemo(() => {
    return groups.length > 0 || isFetching || search_query;
  }, [groups, isFetching, search_query]);

  return (
    <TableActionsHeaderElementsWrapper>
      {displaySearchField && (
        <SearchFieldWrapper>
          <StyleSearchField
            dataLoading={isLoading}
            placeholder='Search'
            searchValue={searchQuery}
            setSearchValue={(value) => dispatch({ type: 'setSearchValue', payload: value })}
          />
        </SearchFieldWrapper>
      )}
      {ability.can('create', 'Group') && (
        <AddGroupButtonWrapper className='add-group-wrapper'>
          <DefaultButton
            buttonType='primary'
            id='open-create-group-modal'
            onClick={() => {
              groupsModalsDispatch({ type: 'openGroupKindModal' });
            }}
            size='md'
            text={t('add_group_button')}
          />
        </AddGroupButtonWrapper>
      )}
    </TableActionsHeaderElementsWrapper>
  );
};

export default TableActionsHeader;
