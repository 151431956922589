import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import useIntegrationCardsImages from '../../../../hooks/useIntegrationCardsImages';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useGetSsoIntegrationDataQuery } from '../../../../redux/services/resourceApis/settings/settingsApi';
import { SsoIntegrationProvider } from '../../../../types/IntegrationProvider';
import { SsoProviderName } from '../../../../types/SsoProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Hoverable from '../../../design_system/Hoverable';
import ThemedImage from '../../../design_system/ThemedImage';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import PaywallTooltip from '../../shared/tooltips/PaywallTooltip';
import PageWrapper from '../IntegrationShowPage/PageWrapper';
import {
  ButtonsContainer,
  DescriptionSection,
  LogoSection,
  StyledButton,
} from '../IntegrationShowPage/styles';
import ConnectIntegrationModal from './ConnectIntegrationModal';
import DisconnectIntegrationModal from './DisconnectIntegrationModal';
import ViewIntegrationModal from './ViewIntegrationModal';

const StyledIntegrationLogo = styled(ThemedImage)`
  max-width: 12.5rem;
  max-height: 6rem;
`;

const StyledPaywallButton = styled(DefaultButton)`
  justify-content: center;
  @media (max-width: ${mediaBreakpointPxSm}) {
    width: 100%;
  }
`;

const t = initTranslations('integrations');

export interface IntegrationModalProps {
  showModal: boolean;
  closeModal: () => void;
  provider: SsoProviderName;
}

const SsoIntegrationsShowPage = ({ provider }: { provider: SsoIntegrationProvider }) => {
  const theme = useTheme();
  const { cardsData } = useIntegrationCardsImages();
  const { slug, nonBilling } = useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const canUpdateBillingPlan = ability.can('update', 'BillingPlan');
  const [isConnectionButtonHovered, setIsConnectionButtonHovered] = useState(false);
  const [viewIntegrationModalOpen, setViewIntegrationModalOpen] = useState(false);
  const [disconnectIntegrationModalOpen, setDisconnectIntegrationModalOpen] = useState(false);
  const [connectOktaIntegrationModalOpen, setConnectOktaIntegrationModalOpen] = useState(false);
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const { darkImage, lightImage } = cardsData[provider];
  const { productTerm } = useAccountTerminology();
  const { data, isSuccess, isLoading, isFetching, isError, error } =
    useGetSsoIntegrationDataQuery(provider);

  useDisplayFlashOnResponse({
    result: { isSuccess, isLoading, isFetching, isError },
    errorFunction: () => routeTo(routes.integrations({ slug })),
    errorMessage: messageFromError(error)?.join(', '),
  });

  const getDescription = () => {
    switch (provider) {
      case 'okta':
        return (
          <>
            <p>
              {t('okta.description.p1')}
              {t('okta.description.p2')}
            </p>
            <p>{t('okta.list_title')}</p>
            <ul>
              <li>{t('okta.bullet_1')}</li>
              <li>{t('okta.bullet_2')}</li>
              <li>{t('okta.bullet_3')}</li>
              <li>{t('okta.bullet_4')}</li>
            </ul>
          </>
        );
      case 'microsoft':
        return (
          <>
            <p>{t('microsoft.text_1', { productTerm })}</p>
            <p>{t('microsoft.text_2')}</p>
          </>
        );
      case 'google':
        return (
          <>
            <p>{t('google.text_1', { productTerm })}</p>
            <p>{t('google.text_2')}</p>
          </>
        );
      default:
        return null;
    }
  };

  if (!data) return <></>;

  const { applicationId, isConnected, isEnabled } = data;
  const ableToConnect = isEnabled || nonBilling;

  return (
    <PageWrapper integrationName={t(`${provider}.title`)} isBeta={false}>
      <LogoSection>
        <StyledIntegrationLogo alt={provider} darkImage={darkImage} lightImage={lightImage} />
        <ButtonsContainer>
          {isConnected && (
            <>
              <StyledButton
                buttonType='primary'
                id='view-sso-button'
                onClick={() => setViewIntegrationModalOpen(true)}
                size='lg'
                text={t('view_integration')}
              />
              <StyledButton
                buttonType='secondary'
                id='disconnect-sso-button'
                onClick={() => setDisconnectIntegrationModalOpen(true)}
                size='lg'
                text={t('disconnect')}
              />
            </>
          )}
          {!isConnected &&
            (ableToConnect ? (
              <StyledButton
                buttonType='primary'
                id='connect-sso-button'
                onClick={() => setConnectOktaIntegrationModalOpen(true)}
                size='lg'
                text={t(`${provider}.connect`)}
              />
            ) : (
              <>
                <PaywallTooltip
                  description={t(`paywall_tooltip_description`)}
                  modifiers={[{ name: 'offset', options: { offset: [145, 0] } }]}
                  shouldBeVisible={isConnectionButtonHovered}
                  title={t(`${provider}.title`)}
                />
                <Hoverable setIsHovered={(value) => setIsConnectionButtonHovered(value)}>
                  <StyledPaywallButton
                    className='finch-connect-btn'
                    disabled={!canUpdateBillingPlan}
                    iconColor={theme.vars.textDefault}
                    iconName='lock'
                    iconWeight='regular'
                    id='paywalled-sso-connect-btn'
                    onClick={() =>
                      canUpdateBillingPlan && navigateToBillingOverlay({ plan: 'scale' })
                    }
                    size='lg'
                    text={t(`${provider}.connect`)}
                    type='button'
                  />
                </Hoverable>
              </>
            ))}
        </ButtonsContainer>
      </LogoSection>
      <DescriptionSection>{getDescription()}</DescriptionSection>

      {connectOktaIntegrationModalOpen && (
        <ConnectIntegrationModal
          closeModal={() => setConnectOktaIntegrationModalOpen(false)}
          provider={provider}
          showModal={connectOktaIntegrationModalOpen}
        />
      )}

      <ViewIntegrationModal
        closeModal={() => setViewIntegrationModalOpen(false)}
        integrationAppId={applicationId}
        provider={provider}
        showModal={viewIntegrationModalOpen}
      />
      <DisconnectIntegrationModal
        closeModal={() => setDisconnectIntegrationModalOpen(false)}
        provider={provider}
        showModal={disconnectIntegrationModalOpen}
      />
    </PageWrapper>
  );
};

export default SsoIntegrationsShowPage;
