import styled from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';

export const ObjectIslandWrapper = styled.div`
  display: flex;
  width: fit-content;
  background-color: ${({ theme: { vars, mode } }) =>
    mode === 'dark' ? vars.foundationBase2 : vars.foundationSurface2};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  align-items: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  ${fontSm5};
`;

export const SelectedCount = styled.div`
  color: ${({ theme: { vars, mode } }) => (mode === 'dark' ? vars.textDefault : '#B9B9B9')};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const ObjectIslandAction = styled.button`
  display: flex;
  align-items: center;
  border: unset;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  color: ${({ theme: { vars, mode } }) => (mode === 'dark' ? vars.textDefault : '#E5E5E5')};
  background-color: ${({ theme: { vars, mode } }) =>
    mode === 'dark' ? vars.foundationBase2 : vars.foundationSurface2};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerSm3}`};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
  svg {
    padding-right: ${({ theme: { constants } }) => constants.spacerSm2};
  }
  &:focus {
    outline: unset;
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme: { vars, mode } }) =>
      mode === 'dark' ? vars.foundationHover : vars.textDefault};
  }
  ${fontSm5};
`;

export const ObjectIslandMoreActions = styled(ObjectIslandAction)`
  &:focus {
    background-color: ${({ theme: { vars, mode } }) =>
      mode === 'dark' ? vars.borderDefault : vars.textDefault};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
    outline: unset;
  }
`;

export const ObjectIslandCloseWrapper = styled.div`
  color: ${({ theme: { vars, mode } }) => (mode === 'dark' ? vars.textDefault : '#B9B9B9')};
  svg {
    vertical-align: ${({ theme: { constants } }) => `-${constants.spacerSm2}`};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ObjectIslandMoreActionsMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderRadiusXs} solid ${vars.borderSurface1}`};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  color: ${({ theme: { vars, mode } }) => (mode === 'dark' ? vars.textDefault : vars.textDefault)};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
`;

export const ObjectIslandMoreActionsMenuOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} ${constants.spacerLg1} ${constants.spacerSm3} ${constants.spacerMd2}`};
  svg {
    padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
  }
  &:hover {
    cursor: pointer;
  }
`;
