import React, { ReactNode } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import Breadcrumbs from '../../../design_system/navigation/Breadcrumbs';
import { routes } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import { BadgeBeta, StyledSurface } from './styles';

const t = initTranslations('integrations');

interface IntegrationShowPageProps {
  integrationName: string;
  isBeta?: boolean;
  children: ReactNode;
}

const PageWrapper = ({ integrationName, isBeta = false, children }: IntegrationShowPageProps) => {
  const { slug } = useCurrentAccount();

  return (
    <PageContent>
      <Breadcrumbs
        currentPageText={integrationName}
        redirectPath={routes.integrations({ slug }).href}
        redirectText={t('page_title')}
      />
      <StyledSurface>
        {isBeta && <BadgeBeta>{t('beta')}</BadgeBeta>}
        {children}
      </StyledSurface>
    </PageContent>
  );
};

export default PageWrapper;
