import { Formik, FormikProps } from 'formik';
import React from 'react';
import { CustomPicker } from 'react-color';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { validateHexCodeSchema } from '../../../application/editor/shared/validators';
import { isRGBorRGBA, rgbToHex } from '../../helpers';
import InputFieldWithCharacterCounter from '../../Triage/InputFieldWithCharacterCounter';
import DefaultButton from '../DefaultButton';
import {
  CustomColorPickerButton,
  CustomColorPickerInputRow,
  CustomColorPickerWrapper,
  SwatchSquare,
  SwatchSquareWrapper,
} from './styles';
import { CustomColorPickerProps } from './types';

const t = initTranslations('account_settings.color_picker');

const ClearButtonWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const getInitialColor = (color: string) => {
  if (isRGBorRGBA(color)) {
    return rgbToHex(color);
  }

  return color;
};

const CustomColorPicker = ({
  onChange,
  color,
  colors,
  hideColorPicker,
  clearButtonVisible,
  clearButtonClick,
  clearButtonText = t('none'),
}: CustomColorPickerProps) => {
  const normalizeColor = (colorItem: string) => colorItem.replace('#', '');

  const handleSubmit = (values: { color: string }) => {
    if (!values.color) return;

    onChange(values.color);
    hideColorPicker();
  };

  return (
    <CustomColorPickerWrapper id='color-picker-wrapper'>
      {clearButtonVisible && (
        <ClearButtonWrapper>
          <DefaultButton
            buttonType='tertiary'
            fullWidth
            iconName='droplet-slash'
            id='clear-selected-color-button'
            onClick={clearButtonClick}
            text={clearButtonText}
          />
        </ClearButtonWrapper>
      )}
      <Formik
        initialValues={{ color: getInitialColor(color) }}
        onSubmit={handleSubmit}
        validationSchema={validateHexCodeSchema}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          values,
          setFieldValue,
        }: FormikProps<{ color: string }>) => (
          <>
            <SwatchSquareWrapper>
              {colors.map((colorItem: string) => (
                <SwatchSquare
                  active={colorItem.toLowerCase() === values.color.toLowerCase()}
                  color={colorItem}
                  id={`color-${normalizeColor(colorItem)}-button`}
                  key={colorItem}
                  onClick={() => {
                    setFieldValue('color', colorItem);
                    onChange(colorItem);
                  }}
                />
              ))}
            </SwatchSquareWrapper>

            <CustomColorPickerInputRow>
              <InputFieldWithCharacterCounter
                errorText={errors.color}
                name='color'
                onChange={handleChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
                placeholder={t('hex_color')}
                value={values.color}
              />

              <CustomColorPickerButton
                disabled={!!errors.color}
                id='save-color-picker-changes'
                loading={false}
                onClick={() => handleSubmit()}
                text={t('save')}
                type='button'
              />
            </CustomColorPickerInputRow>
          </>
        )}
      </Formik>
    </CustomColorPickerWrapper>
  );
};
export default CustomPicker(CustomColorPicker);
