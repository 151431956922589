import styled from 'styled-components';

import IconButton from '../../../../design_system/buttons/IconButton';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';
import { FrequenciesPopupContainer } from '../../FrequenciesPopup/styles';

export const FrequencyDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.625rem;
  cursor: pointer;

  ${FrequenciesPopupContainer} {
    transform: ${({ theme: { constants } }) =>
      `translate(calc(-50% - ${constants.spacerSm3}), ${constants.spacerSm2})`};
  }
`;

export const FrequencyValue = styled.span`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  width: ${({ theme: { constants } }) => constants.spacerLg1};

  ${fontSm5};
`;

export const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd1} 0 0;
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2} 0 0;
  height: 1.875rem;

  svg {
    ${fontSm4};
  }
`;
