import React from 'react';

import getTemplatesSortDropdownOptions from '../../../../../lib/templates/getTemplatesSortDropdownOptions';
import { OutlineTemplatesSort } from '../../../../../redux/services/resourceApis/templates/types';
import DropdownMenu, {
  DropdownMenuOption,
} from '../../../../design_system/Triage/menus/DropdownMenu';
import { SearchAction } from '../TemplatesOutlineIndexPage/types';

type TemplatesOutlineSortDropdownProps = {
  sort: OutlineTemplatesSort;
  dispatchSearchState: React.Dispatch<SearchAction>;
};

const TemplatesOutlineSortDropdown = ({
  sort,
  dispatchSearchState,
}: TemplatesOutlineSortDropdownProps) => {
  const { options } = getTemplatesSortDropdownOptions();
  const selectedOption = options.find((option) => option.sortColumn === sort) || options[0];

  const setSelectedOption = (value: DropdownMenuOption) => {
    dispatchSearchState({
      type: 'changeSort',
      sort: value.sortColumn as OutlineTemplatesSort,
    });
  };

  return (
    <DropdownMenu
      options={options}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      size='md'
    />
  );
};

export default TemplatesOutlineSortDropdown;
