import { ManageNotificationsOption } from '../../../../redux/services/resourceApis/notifications/types';

type Action = { type: 'changePage'; page: number };

export const pageReducer = (
  state: ManageNotificationsOption,
  action: Action
): ManageNotificationsOption => {
  switch (action.type) {
    case 'changePage': {
      return { ...state, page: action.page };
    }
  }
};
