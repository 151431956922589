import { formatOptions } from '../components/design_system/core/CoreSelectField/CoreSelectField';
import { Option } from '../components/design_system/core/SelectOption/types';

interface OptionName {
  name: string;
  id: number | string;
}

export const selectOptions = (options: OptionName[]): Option[] => {
  const optionsData = options.map((option) => ({
    label: option.name,
    value: option.id.toString(),
  }));
  return formatOptions(optionsData);
};
