import { BadgeType } from 'components/design_system/display/badge/BadgeTypes';
import { useMemo } from 'react';

import { ContentSearchResults, ContentType } from '../redux/services/resourceApis/searches/types';

type BadgeProperties = {
  [key: string]: {
    type: BadgeType;
    text: string;
  };
};

const useSearchResultsWithBadge = (
  results: ContentSearchResults[] | undefined,
  curriculumTerm: string,
  topicTerm: string,
  stepTerm: string,
  flowchartTerm: string
) => {
  const badgeProperties: BadgeProperties = useMemo(
    () => ({
      [ContentType.Curriculum]: { type: 'brand', text: curriculumTerm },
      [ContentType.Course]: { type: 'info', text: topicTerm },
      [ContentType.Step]: { type: 'general', text: stepTerm },
      [ContentType.Flowchart]: { type: 'info', text: flowchartTerm },
    }),
    [curriculumTerm, topicTerm, stepTerm, flowchartTerm]
  );

  return useMemo(() => {
    return (
      results?.map((result) => ({
        ...result,
        badge: badgeProperties[result.type],
      })) || []
    );
  }, [results, badgeProperties]);
};

export default useSearchResultsWithBadge;
