import React from 'react';

import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';
import {
  DropdownMenusWrapper,
  SidebarSkeletonWrapper,
  SkeletonHeadingWrapper,
  StepTitleWrapper,
} from './styles';

const SidebarSkeleton = () => {
  return (
    <SidebarSkeletonWrapper>
      <SkeletonHeadingWrapper>
        <SkeletonLoader height='0.875rem' width='8.75rem' />
        <DropdownMenusWrapper>
          <SkeletonLoader height='1.25rem' marginTop='4rem' width='10.563rem' />
        </DropdownMenusWrapper>
      </SkeletonHeadingWrapper>

      <StepTitleWrapper>
        <SkeletonLoader height='1.25rem' width='12.563rem' />
      </StepTitleWrapper>
      <StepTitleWrapper>
        <SkeletonLoader height='1.25rem' width='12.563rem' />
      </StepTitleWrapper>
      <StepTitleWrapper>
        <SkeletonLoader height='1.25rem' width='12.563rem' />
      </StepTitleWrapper>
    </SidebarSkeletonWrapper>
  );
};

export default SidebarSkeleton;
