import React from 'react';

import { useEditorToolbarContext } from '../../../../../../../contexts/EditorToolbarContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import LabelToolbarButton from '../LabelToolbarButton';

const t = initTranslations('editor_toolbar.insert_options');

interface Props {
  active: boolean;
}

const FileToolbarButton = ({ active }: Props) => {
  const { setSelectedEmbedMenuItem } = useEditorToolbarContext();

  return (
    <LabelToolbarButton
      active={active}
      ariaLabel={t('aria_for_file')}
      className='flyout-trigger'
      dataTestId='file-button'
      iconName='file-lines'
      iconType='name'
      id='file-button'
      label={t('file')}
      onClick={() => setSelectedEmbedMenuItem(undefined)}
    />
  );
};

export default FileToolbarButton;
