import React, { useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import initTranslations from '../../../../../lib/initTranslations';
import Flyout from '../../../../design_system/overlays/flyout';
import { fontMd1 } from '../../../../styled/TypeSystem';
import TablePicker from '../../../grid';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import TableToolbarButton from '../../toolbar/buttons/components/insert/TableToolbarButton';
import { EditorPluginProps } from '../types';

const t = initTranslations('editor.table');

//DS Override: match width of design in Figma
const StyledFlyout = styled(Flyout)`
  width: 12.75rem;
  height: 13.75rem;
`;

const TableSize = styled.p`
  display: flex;
  justify-content: center;
  ${fontMd1};
`;

const TableFlyout = ({ editor: passedEditor, popupPlacement = 'top-start' }: EditorPluginProps) => {
  const { editor: contextEditor } = useEditorContext();
  const editor = passedEditor || contextEditor;
  const menuId = 'table-flyout';
  const { isMenuOpen, closeMenu } = useActiveMenuHandler({ menuId });
  const [selectedGrid, setSelectedGrid] = useState({ rows: 0, columns: 0 });

  const insertTable = () => {
    editor
      .chain()
      .focus()
      .insertTable({
        rows: selectedGrid.rows,
        cols: selectedGrid.columns,
        withHeaderRow: false,
      })
      .run();

    closeMenu();
  };

  const resetFlyout = () => {
    setSelectedGrid({ rows: 0, columns: 0 });
  };

  return (
    <StyledFlyout
      className='table-flyout'
      id={menuId}
      onClose={resetFlyout}
      placement={popupPlacement}
      title={t('title')}
      triggerButton={<TableToolbarButton active={isMenuOpen} className='flyout-trigger' />}
    >
      <TablePicker
        numCols={6}
        numRows={4}
        onSelect={() => {
          insertTable();
          resetFlyout();
        }}
        setSelectedGrid={setSelectedGrid}
      />
      <TableSize>
        {selectedGrid.rows} x {selectedGrid.columns}
      </TableSize>
    </StyledFlyout>
  );
};

export default TableFlyout;
