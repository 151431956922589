import React, { Dispatch, useCallback, useReducer } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useSlideout from '../../../../../hooks/useSlideout';
import initTranslations from '../../../../../lib/initTranslations';
import { selectOptions } from '../../../../../lib/selectOptions';
import {
  ContentAccess,
  ESignature,
  GeneralAccess,
  Kind,
  Status,
} from '../../../../../redux/domains/curriculums/curriculumsSlice';
import { useGetGroupsAndUsersQuery } from '../../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import { filterOptions } from '../../../curriculums/CurriculumFilters/filterOptions';
import CollapsibleFiltersSection from '../../../shared/CollapsibleFiltersSection/CollapsibleFiltersSection';
import SlideoutPanel from '../../../shared/slideout';
import { TableAction } from '../libraryReducer';
import { contentReducer } from './contentReducer';

const t = initTranslations('curriculums.content.filter_panel');

const FiltersFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderRadiusXs} solid ${vars.borderSurface2}`};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

const SlideoutBody = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
`;

type Props = {
  outsideTableDispatch: Dispatch<TableAction>;
};

export const initialContentState = {
  groupIds: null,
  roleIds: null,
  teamIds: null,
  userId: null,
  access: null,
  status: null,
  kind: null,
  generalAccess: null,
  userOptions: [],
  eSignature: null,
};

const ContentLibraryFilterSlideout = ({ outsideTableDispatch }: Props) => {
  const { close } = useSlideout('content-library');
  const [contentState, contentDispatch] = useReducer(contentReducer, initialContentState);
  const { allowSignatures } = useCurrentAccount();

  const applyFilters = useCallback(() => {
    close();
    return outsideTableDispatch({
      type: 'applyFilters',
      groupIds: contentState.groupIds,
      teamIds: contentState.teamIds,
      roleIds: contentState.roleIds,
      userId: contentState.userId,
      access: contentState.access,
      status: contentState.status,
      kind: contentState.kind,
      generalAccess: contentState.generalAccess,
      eSignature: contentState.eSignature,
    });
  }, [
    close,
    outsideTableDispatch,
    contentState.groupIds,
    contentState.teamIds,
    contentState.roleIds,
    contentState.userId,
    contentState.access,
    contentState.status,
    contentState.kind,
    contentState.generalAccess,
    contentState.eSignature,
  ]);

  const resetFilters = useCallback(() => {
    close();
    outsideTableDispatch({ type: 'resetFilters' });
    return contentDispatch({ type: 'resetFilters' });
  }, [close, outsideTableDispatch]);

  const { data } = useGetGroupsAndUsersQuery();

  if (!data) return <></>;

  const { groups, users } = data;
  const {
    access,
    generalAccess,
    groupIds,
    status,
    kind,
    userId: tableUserId,
    eSignature,
  } = contentState;

  const userSelectOptions = (users: { name: string; id: number }[]) => {
    const userOptions: { label: string; value: string }[] = users.map((option) => ({
      label: option.name,
      value: option.id.toString(),
    }));
    return formatOptions(userOptions);
  };

  const footer = (
    <FiltersFooter>
      <DefaultButton
        buttonType='secondary'
        disabled={false}
        fullWidth
        id='report-filter-clear-button'
        onClick={resetFilters}
        size='md'
        text={t('clear')}
      />
      <DefaultButton
        buttonType='primary'
        disabled={false}
        fullWidth
        id='report-filter-apply-button'
        onClick={applyFilters}
        size='md'
        text={t('apply')}
      />
    </FiltersFooter>
  );

  return (
    <SlideoutPanel
      dock='right'
      footer={footer}
      headerText={t('title')}
      id='content-library-filter-panel'
      scrollable
      slideoutType='content-library'
      slideoutWidth='17.5rem'
      titleAlign='flex-start'
    >
      <SlideoutBody>
        <CollapsibleFiltersSection title={t('group')}>
          <MultiSelectField
            className='group-select'
            defaultValue={[]}
            onNonDefaultSelected={(value) => {
              contentDispatch({ type: 'setGroupIds', groupIds: value });
            }}
            options={selectOptions(groups)}
            placeholder={t('group_placeholder')}
            size='md'
            value={groupIds}
          />
        </CollapsibleFiltersSection>
        <CollapsibleFiltersSection title={t('owner')}>
          <SingleSelectField
            className='owner-select notranslate'
            defaultValue={null}
            isClearable
            isSearchable
            onNonDefaultSelected={(value) => {
              contentDispatch({ type: 'setOwner', userId: value });
            }}
            options={userSelectOptions(users)}
            placeholder={t('owner_placeholder')}
            size='md'
            value={tableUserId}
          />
        </CollapsibleFiltersSection>
        <CollapsibleFiltersSection title={t('access')}>
          <SingleSelectField
            className='access-select'
            defaultValue={null}
            isClearable
            isSearchable
            onNonDefaultSelected={(value: ContentAccess) => {
              contentDispatch({ type: 'setAccess', access: value });
            }}
            options={formatOptions(filterOptions.accesses)}
            placeholder={t('access_placeholder')}
            size='md'
            value={access}
          />
        </CollapsibleFiltersSection>
        <CollapsibleFiltersSection title={t('status')}>
          <SingleSelectField
            className='status-select'
            defaultValue={null}
            isClearable
            isSearchable
            onNonDefaultSelected={(value: Status) => {
              contentDispatch({ type: 'setStatus', status: value });
            }}
            options={formatOptions(filterOptions.statuses)}
            placeholder={t('status_placeholder')}
            size='md'
            value={status}
          />
        </CollapsibleFiltersSection>
        <CollapsibleFiltersSection title={t('type')}>
          <SingleSelectField
            className='type-select'
            defaultValue={null}
            isClearable
            isSearchable
            onNonDefaultSelected={(value: Kind) => {
              contentDispatch({ type: 'setType', kind: value });
            }}
            options={formatOptions(filterOptions.types)}
            placeholder={t('type_placeholder')}
            size='md'
            value={kind}
          />
        </CollapsibleFiltersSection>
        <CollapsibleFiltersSection title={t('general_access')}>
          <SingleSelectField
            className='general-access-select'
            defaultValue={null}
            isClearable
            isSearchable
            onNonDefaultSelected={(value: GeneralAccess) => {
              contentDispatch({ type: 'setGeneralAccess', generalAccess: value });
            }}
            options={formatOptions(filterOptions.generalAccesses)}
            placeholder={t('general_access_placeholder')}
            size='md'
            value={generalAccess}
          />
        </CollapsibleFiltersSection>
        {allowSignatures && (
          <CollapsibleFiltersSection title={t('e_signature')}>
            <SingleSelectField
              className='e-signature-select'
              defaultValue={null}
              isClearable
              isSearchable
              onNonDefaultSelected={(value: ESignature) => {
                contentDispatch({ type: 'setESignature', eSignature: value });
              }}
              options={formatOptions(filterOptions.eSignatures)}
              placeholder={t('e_signature_placeholder')}
              size='md'
              value={eSignature}
            />
          </CollapsibleFiltersSection>
        )}
      </SlideoutBody>
    </SlideoutPanel>
  );
};

export default ContentLibraryFilterSlideout;
