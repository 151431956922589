import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import Surface from '../../design_system/Triage/layout/Surface';

type SurfacePosition = 'top' | 'center';

const PageContainer = styled.div<{ surfacePosition: SurfacePosition }>`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: ${({ surfacePosition }) => (surfacePosition === 'center' ? 'center' : 'start')};
  overflow: hidden;
  top: 0;
  left: 0;

  * {
    box-sizing: border-box;
  }
`;

//DS Override - Needed box-shadow on Surface
const StyledSurface = styled(Surface)<{
  boxShadow: boolean;
  surfacePosition: SurfacePosition;
  maxWidth: string;
  withPaddingTop: boolean;
}>`
  max-width: ${({ maxWidth }) => maxWidth};
  margin: 0 ${({ theme: { constants } }) => constants.spacerMd2};

  ${({ withPaddingTop }) =>
    !withPaddingTop &&
    css`
      padding-top: 0;
    `};

  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    `};

  ${({ surfacePosition }) =>
    surfacePosition === 'top' &&
    css`
      margin: ${({
        theme: {
          constants: { spacerLg3, spacerMd2, spacerMd3 },
        },
      }) => `${spacerLg3} ${spacerMd2} ${spacerMd3}`};
    `};
`;

type Props = {
  children: ReactNode;
  className?: string;
  boxShadow?: boolean;
  id: string;
  surfacePosition?: SurfacePosition;
};

const PAGES_WITH_PADDING_TOP = ['sign-up-page', 'accept-invitation-page'];

const PublicPage = ({
  boxShadow = false,
  children,
  className,
  id,
  surfacePosition = 'top',
}: Props) => {
  const getSurfaceMaxWidth = () => {
    switch (id) {
      case 'account-selector-page':
        return '46rem';
      case 'accept-invitation-page':
        return '30rem';
      case 'sign-up-page':
        return '24rem';
      default:
        return '26rem';
    }
  };

  return (
    <PageContainer className={className} id={id} surfacePosition={surfacePosition}>
      <StyledSurface
        boxShadow={boxShadow}
        maxWidth={getSurfaceMaxWidth()}
        surfacePosition={surfacePosition}
        withPaddingTop={PAGES_WITH_PADDING_TOP.includes(id)}
      >
        {children}
      </StyledSurface>
    </PageContainer>
  );
};

export default PublicPage;
