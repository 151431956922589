import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import initTranslations from '../../../../lib/initTranslations';
import ConfirmationModal from '../../../design_system/overlays/modals/ConfirmationModal';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';

const t = initTranslations('bulk_user_upload_payroll_modal');

type Props = {
  showModal: boolean;
  usedUserSlots: number;
  userSlots: number;
  setShowModal: (showModal: boolean) => void;
};

const OutOfSeatsConfirmationModal = ({
  showModal,
  setShowModal,
  usedUserSlots,
  userSlots,
}: Props) => {
  const { onFree3SeatsPlan, nonBilling, slug } = useCurrentAccount();
  const { permission } = useCurrentUser();
  const isBillingAdmin = permission === 'billing_admin';
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const showPrimaryButton = isBillingAdmin && !nonBilling;

  const redirectToBillingOverlay = () => {
    setShowModal(false);
    onFree3SeatsPlan ? routeTo(routes.billing({ slug })) : navigateToBillingOverlay();
  };

  return showModal ? (
    <ConfirmationModal
      actionFunction={redirectToBillingOverlay}
      actionText={t('out_of_seats.action_text')}
      hasPrimaryButton={showPrimaryButton}
      heapModalName='confirmation-out-of-seats-modal'
      message={
        isBillingAdmin
          ? t('out_of_seats.billing_admin_message', { usedSlots: usedUserSlots, slots: userSlots })
          : t('out_of_seats.message', { usedSlots: usedUserSlots, slots: userSlots })
      }
      onCloseRequest={() => setShowModal(false)}
      processing={false}
      secondaryButtonText={isBillingAdmin ? t('out_of_seats.cancel') : t('out_of_seats.close')}
      title={t('out_of_seats.title')}
    />
  ) : (
    <></>
  );
};

export default OutOfSeatsConfirmationModal;
