import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useCallback, useState } from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import EmojiPicker from '../../../../../shared/EmojiPicker';
import { insertEditorContent } from '../../../../lib/insertEditorContent';
import LabelToolbarButton from '../LabelToolbarButton';

const t = initTranslations('editor_toolbar.insert_options');

const EmojiToolbarButton = () => {
  const [showPicker, setShowPicker] = useState(false);
  const { editor } = useEditorContext();

  const emojiSelected = useCallback(
    (emoji: BaseEmoji) => {
      insertEditorContent({
        editor,
        content: emoji.native,
      });
    },
    [editor]
  );

  const toolbarButton = (
    <LabelToolbarButton
      active={showPicker}
      ariaLabel={t('aria_for_emoji')}
      iconName='smile'
      iconType='name'
      id='emoji-button'
      label={t('emoji')}
    />
  );

  return (
    <EmojiPicker
      isOpen={showPicker}
      onClick={() => setShowPicker(!showPicker)}
      onClickOutside={() => setShowPicker(false)}
      onEmojiSelect={(emoji) => {
        emojiSelected(emoji);
        setShowPicker(false);
      }}
      trigger={toolbarButton}
    />
  );
};

export default EmojiToolbarButton;
