import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import Link from '../../../../../design_system/Link';
import { routes } from '../../../../publicApplication/applicationRouter';
import { ColumnComponentProps } from '../types';

const Title = ({ record }: ColumnComponentProps) => {
  const { slug } = useCurrentAccount();
  const { id, title } = record;

  return (
    <div>
      <Link href={routes.consume({ slug, id }).href} text={title} />
    </div>
  );
};

export default Title;
