import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { ColumnComponentProps } from '../types';

const t = initTranslations('reports.course_reports_table.columns');

const UsersAssigned = ({ record: { users_assigned_count } }: ColumnComponentProps) => {
  return <div>{t('users_assigned', { count: users_assigned_count || 0 })}</div>;
};

export default UsersAssigned;
