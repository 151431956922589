import React, { SetStateAction } from 'react';

import { useAccountTerminology } from '../../../../../../../components/AccountTerminologyProvider';
import initTranslations from '../../../../../../../lib/initTranslations';
import { AvatarObject } from '../../../../../../../types/Avatar';
import TaskModal from '../../../../../../design_system/overlays/modals/TaskModal';
import ImageUpload from '../../../../../image_upload';

const t = initTranslations('user_profile');

export interface Props {
  imageUrl?: string;
  onClose: () => void;
  onSave: () => void;
  loading: boolean;
  setImage: (e: SetStateAction<AvatarObject | undefined>) => void;
  name: string;
}

const AvatarUploadModal = ({ imageUrl, loading, onClose, onSave, setImage, name }: Props) => {
  const { productTerm } = useAccountTerminology();

  return (
    <TaskModal
      desktopSize='md'
      heapModalName='avatar-upload-modal'
      isDisabled={loading}
      onCloseRequest={onClose}
      primaryButtonTask={onSave}
      primaryButtonText={t('avatar_upload_modal.save')}
      processing={loading}
      secondaryButtonText={t('avatar_upload_modal.cancel')}
      title={t('avatar_upload_modal.title')}
    >
      <ImageUpload
        imageSize='lg'
        imageUrl={imageUrl}
        name={name}
        resetButtonText={t('avatar_upload.remove_photo')}
        setImage={setImage}
        showInitials
        text={t('avatar_upload.photos_help_your_teammates', { productTerm })}
        title={t('avatar_upload.set_your_profile_pic')}
        uploadButtonText={t('avatar_upload.upload_photo')}
        verticalAlign='center'
      />
    </TaskModal>
  );
};

export default AvatarUploadModal;
