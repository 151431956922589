import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import useWindowResize from '../../../../hooks/useWindowResize';
import initTranslations from '../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Hoverable from '../../../design_system/Hoverable';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import FlexContainer from '../../../styled/FlexContainer';
import PaywallTooltip from '../../shared/tooltips/PaywallTooltip';
import OrgChartTabs from '../OrgChartShared/OrgChartTabs';
import OrgChartZoomControl from '../OrgChartShared/ZoomControl/OrgChartZoomControl';
import { GroupChartHeaderProps } from './types';

const t = initTranslations('role_chart');

const GroupChartHeader = ({
  isRoleChartPaywalled,
  rootNodeSet,
  showConfigure,
  zoomDispatch,
  zoomState,
}: GroupChartHeaderProps) => {
  const ability = useCurrentUserAbilities();
  const canReadGroupChartConfigSlideout = ability.can('read', 'GroupChartConfigSlideout');
  const showGroupChart = ability.can('read', 'GroupChart');
  const showOrgChart = ability.can('read', 'OrgChart');
  const canUpdateBillingPlan = ability.can('update', 'BillingPlan');
  const theme = useTheme();
  const { isExtraSmWindow } = useWindowResize();
  const { configs } = usePrivateConfigs();
  const roleChartLearnMoreUrl = configs['ROLE_CHART_LEARN_MORE_URL'];
  const [showUpgradeTooltip, setShowUpgradeTooltip] = useState(false);
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();

  return (
    <>
      <PageHeader
        learnMoreExternal
        learnMoreHref={roleChartLearnMoreUrl || ''}
        learnMoreText={t('learn_more')}
        subtitle={t('subtitle', { responsibilities: responsibilityPlural.toLowerCase() })}
        subtitleAddOnText={t('groups_subtitle_addon')}
        title={t('title')}
      />
      <FlexContainer
        align={isExtraSmWindow ? 'flex-start' : 'flex-end'}
        direction={isExtraSmWindow ? 'column' : 'row'}
        justifyContent={showGroupChart && showOrgChart ? 'space-between' : 'flex-end'}
      >
        <OrgChartTabs />
        <FlexContainer gap={theme.constants.spacerMd2} justifyContent='flex-end'>
          {zoomDispatch && zoomState && rootNodeSet && (
            <OrgChartZoomControl
              resource='group-chart'
              zoomDispatch={zoomDispatch}
              zoomState={zoomState}
            />
          )}
          {canReadGroupChartConfigSlideout &&
            (isRoleChartPaywalled ? (
              <>
                <PaywallTooltip
                  description={t(`paywall.description`)}
                  modifiers={[{ name: 'offset', options: { offset: [130, 0] } }]}
                  shouldBeVisible={showUpgradeTooltip}
                  title={t('paywall.title')}
                />
                <Hoverable setIsHovered={(value) => setShowUpgradeTooltip(value)}>
                  <DefaultButton
                    disabled={!canUpdateBillingPlan}
                    iconName='lock'
                    id='paywalled-role-chart-btn'
                    onClick={() => canUpdateBillingPlan && navigateToBillingOverlay()}
                    text={t('paywall.title')}
                  />
                </Hoverable>
              </>
            ) : (
              <DefaultButton
                buttonType='primary'
                id='edit-group-chart'
                onClick={showConfigure}
                text={t(rootNodeSet ? 'edit_role_chart_button' : 'build_role_chart_button')}
              />
            ))}
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default GroupChartHeader;
