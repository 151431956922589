import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import { CompletionTrackable } from '../../activities/types';
import { TextFragment } from '../styles';

const t = initTranslations('notifications');

export interface CompletionCommonTemplateProps {
  activityKey: string;
  trackable: CompletionTrackable;
}

const CommonTemplate = ({ trackable, activityKey }: CompletionCommonTemplateProps) => {
  const { curriculum } = useContext(AccountTerminologyContext);
  const { curriculum_id, curriculum_title } = trackable.associated_params;
  const { slug } = useCurrentAccount();

  return (
    <>
      {t(activityKey)}
      <TextFragment>
        <SourceBadge
          sourceName='curriculum'
          sourceRoute={routes.curriculumShow({ slug, id: curriculum_id })}
          sourceText={curriculum_title}
        />
      </TextFragment>
      {t('completion.please_re_complete', { curriculum: curriculum.singular.toLowerCase() })}
    </>
  );
};

export default CommonTemplate;
