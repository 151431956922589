import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { UseQueryResult } from '../../../../../redux/baseQueries/types';
import { UserGroupResponsibilitiesResponse } from '../../../../../redux/services/resourceApis/responsibilities/types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import BaseIcon from '../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import {
  ResponsibilitiesListWrapper,
  StyledProfileSurface,
  StyledTitleRow,
  TitleWrapper,
} from '../../../responsibilities/SurfaceResponsibilities/shared/styles';
import Title from '../../../responsibilities/SurfaceResponsibilities/Title';
import GroupResponsibilitiesList from './GroupResponsibilitiesList';

const TooltipWrapper = styled.div`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;

// DS Override: Required to allow pointer events on the tooltip
const Icon = styled(BaseIcon)`
  pointer-events: none;
`;

const t = initTranslations('users_profile');

interface Props {
  groupResponsibilitiesResult: UseQueryResult<UserGroupResponsibilitiesResponse>;
  adminView: boolean;
  isAdmin: boolean;
  title: string;
  userFirstName?: string;
}

const SurfaceUserGroupResponsibilities = ({
  groupResponsibilitiesResult,
  adminView,
  isAdmin,
  title,
  userFirstName,
}: Props) => {
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();
  const tooltipText = isAdmin
    ? t('group_responsibility_info_tooltip_admin', {
        responsibilities: responsibilityPlural.toLowerCase(),
      })
    : t('group_responsibility_info_tooltip', {
        responsibilities: responsibilityPlural.toLowerCase(),
      });

  return (
    <StyledProfileSurface id='group-responsibilities'>
      <StyledTitleRow>
        <TitleWrapper>
          <Title firstName={userFirstName} titleText={title}>
            {adminView && (
              <>
                <Tooltip id='icon-tooltip' place='bottom' text={tooltipText} />
                <TooltipWrapper className='tooltip-wrapper' data-for='icon-tooltip' data-tip>
                  <Icon fixedWidth name='info-circle' weight='regular' />
                </TooltipWrapper>
              </>
            )}
          </Title>
          {adminView && <p>{t('group_responsibilities_description')}</p>}
        </TitleWrapper>
      </StyledTitleRow>
      <ResponsibilitiesListWrapper>
        <GroupResponsibilitiesList
          adminView={adminView && isAdmin}
          groupResponsibilitiesResult={groupResponsibilitiesResult}
        />
      </ResponsibilitiesListWrapper>
    </StyledProfileSurface>
  );
};

export default SurfaceUserGroupResponsibilities;
