import { useState } from 'react';

import { messageFromError } from '../../redux/errors/helpers';
import { useFetchUpcomingSimplePricingInvoiceDataQuery } from '../../redux/services/resourceApis/billing/billingApi';
import { FetchUpcomingSimplePricingInvoiceDataResponse } from '../../redux/services/resourceApis/billing/simplePricingTypes';
import { SimplePricingFullScreenOverlayRoute } from '../../types/SimplePricing';
import useCurrentAccount from '../useCurrentAccount';
import useDisplayFlashOnResponse from '../useDisplayFlashOnResponse';
import { useCloseFullScreenOverlay } from './useCloseFullScreenOverlay';

type FullScreenOverlayProps = {
  route: SimplePricingFullScreenOverlayRoute;
  isStorybookEnvironment?: boolean;
  mockData: FetchUpcomingSimplePricingInvoiceDataResponse | undefined;
};

export const useSimplePricingFullScreenOverlay = ({
  route,
  isStorybookEnvironment,
  mockData,
}: FullScreenOverlayProps) => {
  const { plan, interval } = route.params;
  const { slug } = useCurrentAccount();
  const closeFullScreenOverlay = useCloseFullScreenOverlay();
  const [selectedBillingInterval, setSelectedBillingInterval] = useState(interval);
  const [selectedPlanName, setSelectedPlanName] = useState(plan);
  const [appliedPromoCode, setAppliedPromoCode] = useState('');
  const upcomingInvoiceResult = useFetchUpcomingSimplePricingInvoiceDataQuery(
    {
      plan: selectedPlanName,
      interval: selectedBillingInterval,
      coupon: appliedPromoCode,
    },
    { skip: isStorybookEnvironment }
  );
  const upcomingInvoiceData = isStorybookEnvironment ? mockData : upcomingInvoiceResult.data;
  const { isLoading, isFetching, error } = upcomingInvoiceResult;
  const isLoadingOrFetching = isLoading || isFetching;
  const isAnnualInterval = selectedBillingInterval === 'year';
  const showDiscounts = upcomingInvoiceData?.coupon || isAnnualInterval;

  useDisplayFlashOnResponse({
    result: upcomingInvoiceResult,
    errorMessage: messageFromError(error)?.join(', '),
    errorFunction: closeFullScreenOverlay,
  });

  return {
    upcomingInvoiceData,
    slug,
    showDiscounts,
    isAnnualInterval,
    isLoadingOrFetching,
    selectedBillingInterval,
    setSelectedBillingInterval,
    selectedPlanName,
    setSelectedPlanName,
    setAppliedPromoCode,
    appliedPromoCode,
  };
};
