import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import { fontMd2, fontSm4 } from '../../../../styled/TypeSystem';
import { useAssignedSubjects } from './AssignedSubjectsContext';

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};

  button {
    align-self: flex-start;
  }

  @media (min-width: ${mediaBreakpointPxMd}) {
    flex-direction: row;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const Title = styled.h2`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontMd2};
`;

const Description = styled.p`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
`;

const t = initTranslations('profile');

type SurfaceHeaderProps = {
  isDisabled: boolean;
  seeAllLink: string;
};

const SurfaceHeader = ({ isDisabled = false, seeAllLink }: SurfaceHeaderProps) => {
  const {
    curriculum: { plural: subjectTermPlural },
  } = useAccountTerminology();
  const { userProfilePage, ableToManage } = useAssignedSubjects();

  return (
    <Section>
      <TitleWrapper>
        <Title>{t('title', { subjects: subjectTermPlural })}</Title>
        <Description>{t('description', { subjects: subjectTermPlural })}</Description>
      </TitleWrapper>
      {userProfilePage && ableToManage && (
        <DefaultButton
          behavesAs='a'
          buttonType='secondary'
          disabled={isDisabled}
          href={seeAllLink}
          id='assign-new-curriculums-modal-button'
          text={t('manage')}
        />
      )}
    </Section>
  );
};

export default SurfaceHeader;
