import React, { useMemo } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import {
  BaseElementCompletion,
  SurveyElementCompletion,
} from '../../../../redux/services/resourceApis/curriculums/types';
import { ElementKind } from '../../../../types/CurriculumElement';
import Badge from '../../../design_system/display/badge';
import { BadgeType } from '../../../design_system/display/badge/BadgeTypes';

const t = initTranslations('curriculum_show');

type BadgeInfo = {
  label: string;
  type: BadgeType;
};

type Props = {
  elementCompletion: BaseElementCompletion | SurveyElementCompletion;
  elementKind: ElementKind;
};

const baseElementBadge = ({
  elementCompletion,
}: {
  elementCompletion: BaseElementCompletion;
}): BadgeInfo => {
  switch (elementCompletion?.score) {
    case undefined:
    case 0:
      return { label: t('badges.not_started'), type: 'caution' };
    case 100:
      return { label: t('badges.completed'), type: 'success' };
    default:
      return { label: t('badges.in_progress'), type: 'info' };
  }
};

const surveyBadge = ({
  elementCompletion,
}: {
  elementCompletion: SurveyElementCompletion;
}): BadgeInfo => {
  switch (elementCompletion?.completionStatus) {
    case 'in_progress':
      return { label: t('badges.in_progress'), type: 'info' };
    case 'failed':
      return { label: t('badges.failed', { score: elementCompletion.score }), type: 'error' };
    case 'passed':
      return { label: t('badges.passed', { score: elementCompletion.score }), type: 'success' };
    default:
      return { label: t('badges.not_started'), type: 'caution' };
  }
};

const CurriculumElementBadge = ({ elementCompletion, elementKind }: Props) => {
  const badgeData = useMemo(() => {
    switch (elementKind) {
      case 'course':
      case 'flowchart':
        return baseElementBadge({ elementCompletion });
      case 'survey':
        return surveyBadge({ elementCompletion: elementCompletion as SurveyElementCompletion });
    }
  }, [elementCompletion, elementKind]);

  const { label, type } = badgeData;
  return <Badge text={label} type={type} />;
};

export default CurriculumElementBadge;
