import styled from 'styled-components';

export const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const PaginationWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const TemplatesSearchAndFilterWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const TemplatesOutlineDropdownsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const TemplatesOutlineLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;
