import React from 'react';
import styled from 'styled-components';

import Badge from '../../../../design_system/display/badge';
import { BadgeType, Size } from '../../../../design_system/display/badge/BadgeTypes';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';

// DS Override: Need display flex, but defaults to inline-block
const StyledBadge = styled(Badge)`
  display: flex;
  align-items: center;
`;

interface TooltipBadgeProps {
  tooltipId: string;
  tooltipText: string | string[];
  badgeType: BadgeType;
  badgeSize: Size;
  badgeText?: string;
}

const TooltipBadge = ({
  tooltipId,
  tooltipText,
  badgeType,
  badgeSize,
  badgeText,
}: TooltipBadgeProps) => {
  return (
    // This preventDefault is needed so that mobile Users can "click" this, otherwise the click registers to the other closeby buttons, being the chevron or the percentage, and clicks them instead
    <div onClick={(event) => event.preventDefault()}>
      <Tooltip id={tooltipId} text={tooltipText} />
      <div data-for={tooltipId} data-tip>
        <StyledBadge size={badgeSize} text={badgeText} type={badgeType} />
      </div>
    </div>
  );
};

export default TooltipBadge;
