import styled from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../../styled/TypeSystem';

export const DropdownMenuOption = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const DropdownMenuOptionIcon = styled.div`
  display: flex;
  align-items: center;
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const DropdownMenuOptionText = styled.p`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;
