import styled from 'styled-components';

import Icon from '../../../../../../design_system/display/icons/Icon';
import { mediaBreakpointPxLg, mediaBreakpointPxMd } from '../../../../../../styled/Breakpoint';
import { fontMd4, fontSm5 } from '../../../../../../styled/TypeSystem';

const LEFT_COLUMN_FLEX_BASIS = '40%';
const RIGHT_COLUMN_FLEX_BASIS = '50%';
const LEFT_COLUMN_FLEX_BASIS_LG_BREAKPOINT = '36%';
const RIGHT_COLUMN_FLEX_BASIS_LG_BREAKPOINT = '63%';

export const OverlayContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const PlansAndFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.constants.spacerMd2} 3.625rem`};
  max-width: 62rem;
  width: 100%;
`;

export const PlansWrapper = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  min-height: 9.5rem;
  margin-bottom: ${({ theme: { constants } }) =>
    `calc(${constants.spacerLg2} + ${constants.spacerSm2})`};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const PlansTitle = styled.div`
  align-items: center;
  display: flex;
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
  flex-basis: ${LEFT_COLUMN_FLEX_BASIS};
  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-basis: ${LEFT_COLUMN_FLEX_BASIS_LG_BREAKPOINT};
  }
  ${fontMd4};
`;

export const PlansSection = styled.div`
  display: flex;
  flex-basis: ${RIGHT_COLUMN_FLEX_BASIS};
  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-basis: ${RIGHT_COLUMN_FLEX_BASIS_LG_BREAKPOINT};
  }
`;

export const PlanSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerLg1} ${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerSm3}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  width: 100%;
`;

export const FeatureRowWrapper = styled.div`
  display: flex;
  &:nth-of-type(2n + 1) {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  }
`;

export const FeatureWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} 0 ${constants.spacerMd2} ${constants.spacerMd2}`};
  flex-basis: ${LEFT_COLUMN_FLEX_BASIS};
  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-basis: ${LEFT_COLUMN_FLEX_BASIS_LG_BREAKPOINT};
  }
  ${fontSm5};
`;

export const FeatureTitle = styled.p`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const TooltipIconWrapper = styled.div`
  @media (max-width: ${mediaBreakpointPxMd}) {
    display: none;
  }
`;

export const InfoIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  pointer-events: none;
`;

export const FeatureIconsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-basis: ${RIGHT_COLUMN_FLEX_BASIS};
  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-basis: ${RIGHT_COLUMN_FLEX_BASIS_LG_BREAKPOINT};
  }
`;

export const FeatureIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
