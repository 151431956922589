import React from 'react';
import { Route } from 'type-route';

import { routes } from '../../../publicApplication/applicationRouter';
import PageContent from '../../../shared/page_content';
import ProfileSubjects from './ProfileSubjects';

type Props = {
  route: Route<typeof routes.userAssignedCurriculums>;
};

const ProfileSubjectsPage = ({
  route: {
    params: { id },
  },
}: Props) => {
  return (
    <PageContent>
      <ProfileSubjects id={id} userProfilePage={false} />
    </PageContent>
  );
};

export default ProfileSubjectsPage;
