import styled from 'styled-components';

import { fontLg2, fontMd1 } from '../../../../../styled/TypeSystem';

export const InlineEditTitleWrapper = styled.div`
  margin: ${({ theme: { constants } }) => `${constants.spacerMd2} 0 ${constants.spacerMd3} 0`};
  width: 100%;

  #editable-title-hover-wrapper > h6 {
    word-break: break-word;
    color: ${({ theme: { vars } }) => vars.textDefault};
    font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
    ${fontLg2};
  }
`;

export const InlineEditDescriptionWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;

  #editable-description-hover-wrapper > p {
    ${fontMd1};
  }
`;
