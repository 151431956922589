import { SortByValue } from '../../../../components/application/ContentLibrary/ContentLibraryPage/libraryReducer';
import { toCamelCase } from '../../../../lib/keyFormatConverter';
import { BulkCurriculumActionParams } from '../../../../types/Curriculum';
import { GroupKind } from '../../../../types/Group';
import { trainualApi } from '../../trainualService';
import { detailedCurriculumListTags } from '../curriculums/helpers';
import { GroupResponse } from '../groups/types';
import {
  BulkExportParams,
  BulkMoveParams,
  ContentLibraryResponse,
  GetTrainingOrderParams,
  LibraryFilterParams,
  LibraryFilterResponse,
  SectorSettingsResponse,
  TrainingOrderResponse,
  UpdateTrainingOrderParams,
} from './types';

export const contentLibraryApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getContentLibraryCurriculums: builder.query<ContentLibraryResponse, LibraryFilterParams>({
      query: ({ filtersQuery, page }) => ({
        url: `ajax/curriculums/content?${filtersQuery}`,
        method: 'GET',
        params: { page },
      }),
      providesTags: () => [
        { type: 'Curriculum', id: 'LIST' },
        { type: 'Request', id: 'LIST' },
        { type: 'Group', id: 'LIST' },
      ],
      transformResponse: (returnValue: ContentLibraryResponse) =>
        toCamelCase<ContentLibraryResponse>(returnValue),
    }),
    getGroupsAndUsers: builder.query<LibraryFilterResponse, void>({
      query: () => ({
        url: `ajax/curriculums/content/groups_and_users`,
        method: 'GET',
        params: {},
      }),
      transformResponse: (returnValue: LibraryFilterResponse) =>
        toCamelCase<LibraryFilterResponse>(returnValue),
    }),
    getTrainingOrderCurriculums: builder.query<TrainingOrderResponse, GetTrainingOrderParams>({
      query: ({ page }) => ({
        url: `ajax/curriculums/training_order`,
        method: 'GET',
        params: { page },
      }),
      providesTags: () => [{ type: 'Curriculum', id: 'LIST' }],
      transformResponse: (returnValue: TrainingOrderResponse) =>
        toCamelCase<TrainingOrderResponse>(returnValue),
    }),
    updateCurriculumTrainingOrder: builder.mutation<undefined, UpdateTrainingOrderParams>({
      query: ({ id, position }: UpdateTrainingOrderParams) => ({
        url: `ajax/curriculums/${id}/training_order`,
        method: 'PUT',
        body: { position },
      }),
      invalidatesTags: () => [{ type: 'Curriculum', id: 'LIST' }],
      onQueryStarted({ id, position, page }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contentLibraryApi.util.updateQueryData(
            'getTrainingOrderCurriculums',
            { page },
            (draft) => {
              const currentIndex = draft.curriculums.findIndex(
                (curriculum) => curriculum.id === id
              );
              const curriculum = draft.curriculums.splice(currentIndex, 1)[0];
              draft.curriculums.splice(position, 0, curriculum);
            }
          )
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getSectorSettings: builder.query<SectorSettingsResponse, void>({
      query: () => ({
        url: 'ajax/curriculums/content/sector_settings',
        method: 'GET',
      }),
      transformResponse: (returnValue: SectorSettingsResponse) =>
        toCamelCase<SectorSettingsResponse>(returnValue),
    }),
    getGroupsForViewBy: builder.query<GroupResponse[], { kind?: GroupKind | SortByValue }>({
      query: (params) => ({
        url: `ajax/curriculums/content/fetch_groups`,
        method: 'GET',
        params,
      }),
      providesTags: (result) => {
        return result ? [{ type: 'Group', id: 'LIST' }] : [];
      },
    }),
    bulkToggleArchive: builder.mutation<undefined, BulkCurriculumActionParams>({
      query: (params: BulkCurriculumActionParams) => ({
        url: 'ajax/curriculums/bulk_toggle_archiving',
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: (result) => detailedCurriculumListTags(result),
    }),
    bulkDeleteCurriculums: builder.mutation<undefined, BulkCurriculumActionParams>({
      query: (params: BulkCurriculumActionParams) => ({
        url: 'ajax/curriculums/bulk_destroy',
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: (result) => detailedCurriculumListTags(result),
    }),
    bulkExportPdf: builder.mutation<undefined, BulkExportParams>({
      query: (args) => ({
        url: `ajax/curriculums/bulk_print`,
        method: 'POST',
        body: {
          ids: args.curriculumIds,
        },
      }),
    }),
    bulkMoveCurriculums: builder.mutation<undefined, BulkMoveParams>({
      query: (args) => ({
        url: `ajax/curriculums/bulk_move`,
        method: 'POST',
        body: {
          ids: args.curriculumIds,
          sector: args.sector,
        },
      }),
      invalidatesTags: (result) => detailedCurriculumListTags(result),
    }),
  }),
});
export const {
  useGetContentLibraryCurriculumsQuery,
  useGetGroupsAndUsersQuery,
  useGetTrainingOrderCurriculumsQuery,
  useUpdateCurriculumTrainingOrderMutation,
  useGetSectorSettingsQuery,
  useBulkToggleArchiveMutation,
  useBulkDeleteCurriculumsMutation,
  useBulkExportPdfMutation,
  useBulkMoveCurriculumsMutation,
} = contentLibraryApi;
