import React from 'react';

import BulkContentExport from './BulkContentActions/BulkExport/BulkContentExport';
import BulkContentMove from './BulkContentActions/BulkMove/BulkContentMove';
import ESigErrorModal from './BulkContentActions/BulkMove/ESigErrorModal';
import BulkContentArchive from './BulkContentActions/BulkUpdate/BulkContentArchive';
import BulkContentDelete from './BulkContentActions/BulkUpdate/BulkContentDelete';
import { BulkContentActionsModalsFactoryProps } from './types';

const BulkContentActionsModalsFactory = ({
  modal = 'none',
  setBulkActionsModal,
  selectedIds,
  onCloseRequest,
  curriculumsChecked,
}: BulkContentActionsModalsFactoryProps) => {
  const closeBulkContentActionsModal = () => {
    setBulkActionsModal('none');
  };
  const closeBulkContentActionsMenu = () => {
    closeBulkContentActionsModal();
    onCloseRequest();
  };

  switch (modal) {
    case 'archive':
    case 'unarchive':
      return (
        <BulkContentArchive
          curriculumsChecked={curriculumsChecked}
          onClose={closeBulkContentActionsMenu}
          onSuccess={closeBulkContentActionsMenu}
          selectedIds={selectedIds}
          type={modal}
        />
      );
    case 'export':
      return (
        <BulkContentExport
          onClose={closeBulkContentActionsMenu}
          onSuccess={closeBulkContentActionsMenu}
          selectedIds={selectedIds}
          type={modal}
        />
      );
    case 'delete':
      return (
        <BulkContentDelete
          curriculumsChecked={curriculumsChecked}
          onClose={closeBulkContentActionsMenu}
          onSuccess={closeBulkContentActionsMenu}
          selectedIds={selectedIds}
          type={modal}
        />
      );
    case 'move':
      return curriculumsChecked.every((curriculum) => curriculum.canBeMoved) ? (
        <BulkContentMove
          onClose={closeBulkContentActionsMenu}
          onSuccess={closeBulkContentActionsMenu}
          selectedIds={selectedIds}
          type={modal}
        />
      ) : (
        <ESigErrorModal onClose={closeBulkContentActionsMenu} />
      );
    case 'none':
      return <></>;
  }
};

export default BulkContentActionsModalsFactory;
