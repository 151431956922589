import React, { useMemo } from 'react';

import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import { useAppSelector } from '../../../../../redux/hooks';
import { useGetSurveyConsumptionQuery } from '../../../../../redux/services/resourceApis/surveys/surveysApi';
import { ContentType } from '../../../../../types/ContentType';
import ProgressBar from '../../../../design_system/Triage/ProgressBar';
import { BreadcrumbWrapper, EditorBreadcrumbs } from '../../../editor/shared/styles';
import ViewModeDropdown from '../../../shared/ViewModeDropdown/ViewModeDropdown';
import { viewOptions } from '../../../shared/ViewModeDropdown/ViewOptions';
import { HeadingWrapper, OutlineContentWrapper, SurveyTitle } from '../../shared/styles';
import SurveyCurriculumTitleLink from '../../shared/SurveyCurriculumTitleLink/SurveyCurriculumTitleLink';
import SurveyEmoji from '../../shared/SurveyEmoji/SurveyEmoji';
import {
  ConsumptionProgressBarContainer,
  PassingScoreBadge,
  ProgressPercentage,
  ViewModeToggleWrapper,
} from './styles';
import SurveyQuestionsList from './SurveyQuestions/SurveyQuestionsList';

const t = initTranslations('survey_consume');

type Props = {
  id: number;
  slug: string;
};

const OutlineContent = ({ slug, id }: Props) => {
  const ability = useCurrentUserAbilities();
  const { questionAnswersData } = useAppSelector((state) => state.surveyConsume);

  const { data } = useGetSurveyConsumptionQuery({ id });

  const currentAttemptPercentage = useMemo(() => {
    if (!data) return;

    const answeredQuestionsCount = questionAnswersData.filter((data) => data.isAnswered).length;
    const percentage = (answeredQuestionsCount * 100) / data.questions.length;
    return Number(percentage.toFixed(2));
  }, [questionAnswersData, data]);

  if (!data || currentAttemptPercentage === undefined) return <></>;

  const {
    name,
    curriculumId,
    curriculumTitle,
    emoji,
    minimumScore,
    questions,
    lastInProgressAttemptId,
  } = data;

  const canEditCurriculum = ability.can('update', `EditCurriculum-${curriculumId}`);

  return (
    <OutlineContentWrapper>
      <HeadingWrapper>
        <EditorBreadcrumbs id='survey-consumption-breadcrumb'>
          <SurveyCurriculumTitleLink
            curriculumId={curriculumId}
            curriculumTitle={curriculumTitle}
            redirectToEditPage={false}
          />
        </EditorBreadcrumbs>
      </HeadingWrapper>
      <BreadcrumbWrapper>
        <SurveyEmoji emoji={emoji} />
        <SurveyTitle>{name}</SurveyTitle>
      </BreadcrumbWrapper>
      <ConsumptionProgressBarContainer>
        <ProgressBar percent={currentAttemptPercentage} thickness='lg' />
        <ProgressPercentage>{currentAttemptPercentage}%</ProgressPercentage>
      </ConsumptionProgressBarContainer>
      {canEditCurriculum && (
        <ViewModeToggleWrapper>
          <ViewModeDropdown
            initialSelectedOptionIndex={1}
            options={viewOptions({ id, slug, contentType: ContentType.Survey })}
            titleBold
          />
        </ViewModeToggleWrapper>
      )}
      <PassingScoreBadge>{t('required_passing_score', { score: minimumScore })}</PassingScoreBadge>
      <SurveyQuestionsList
        lastInProgressAttemptId={lastInProgressAttemptId}
        questions={questions}
      />
    </OutlineContentWrapper>
  );
};

export default OutlineContent;
