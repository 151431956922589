import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import DurationTimeSetter from '../../../../../DelegationPlanner/DurationTimeSetter/DurationTimeSetter';
import { SectionWrapper } from '../../styles';
import { ResponsibilityDetailsFormData } from '../types';
import { StyledTitle, TimeSetterClickableWrapper } from './styles';
import { ResponsibilityDurationTimeSetterProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.time_setter');

const ResponsibilityDurationTimeSetter: FC<ResponsibilityDurationTimeSetterProps> = ({
  responsibility,
}) => {
  const { values, errors, setFieldValue } = useFormikContext<ResponsibilityDetailsFormData>();

  if (!responsibility) return <></>;

  return (
    <SectionWrapper>
      <StyledTitle>{t('time_commitment')}</StyledTitle>
      <TimeSetterClickableWrapper>
        <DurationTimeSetter
          elementId={responsibility.id}
          errorText={errors.time}
          frequency={values.frequency}
          hours={values.time}
          setFrequency={(frequency) => setFieldValue('frequency', frequency)}
          setHours={(hours) => setFieldValue('time', hours)}
        />
      </TimeSetterClickableWrapper>
    </SectionWrapper>
  );
};

export default ResponsibilityDurationTimeSetter;
