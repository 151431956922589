import { useReactFlow, useViewport } from '@xyflow/react';
import React, { ReactNode, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';

import { useFlowchart } from '../../../../contexts/FlowchartControlProvider';
import initTranslations from '../../../../lib/initTranslations';
import IconButton from '../../../design_system/buttons/IconButton';
import ZoomControl from '../../../design_system/core/ZoomControl/ZoomControl';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { SkeletonLoader } from '../../shared/SkeletonLoader/styles';
import { nextZoomLevel, prevZoomLevel } from '../lib/zoom';

const StyledFooter = styled.footer`
  align-items: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  box-shadow: ${({ theme: { vars } }) => `0 -5px 10px 0 ${vars.shadowColorTopSmall}`};
  box-sizing: border-box;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

// DS Override: IconButton sets border as none
const StyledIconButton = styled(IconButton)<{ active: boolean }>`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthXs} solid ${vars.borderDefault}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ active, theme: { vars } }) => active && vars.accentSubdued1};

  svg {
    color: ${({ active, theme: { vars } }) => active && vars.trainualBrandPurpleMedium};
  }

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    svg {
      color: ${({ theme: { vars } }) => vars.textDefault};
    }
  }
`;

const t = initTranslations('curriculums_view.flowchart.footer');

const BaseFooterLoader = () => {
  const {
    constants: { borderRadiusMd, heightMd },
  } = useTheme();

  return <SkeletonLoader borderRadius={borderRadiusMd} height={heightMd} width='7.625rem' />;
};

type BaseContentProps = {
  isLoading: boolean;
};

const BaseFooterContent = ({ isLoading }: BaseContentProps) => {
  const { flowchartHandlers } = useFlowchart();
  const reactFlow = useReactFlow();
  const { zoom } = useViewport();
  const { displayMiniMap, setDisplayMiniMap } = flowchartHandlers;

  const handleZoomIn = useCallback(() => {
    reactFlow.zoomTo(nextZoomLevel(reactFlow.getZoom()));
  }, [reactFlow]);

  const handleZoomOut = useCallback(() => {
    reactFlow.zoomTo(prevZoomLevel(reactFlow.getZoom()));
  }, [reactFlow]);

  const handleFitView = useCallback(() => reactFlow.fitView(), [reactFlow]);

  const miniMapTooltipId = displayMiniMap ? 'unpin-mini-map-tooltip' : 'pin-mini-map-tooltip';
  const tooltipText = displayMiniMap ? t('mini_map.tooltip.unpin') : t('mini_map.tooltip.pin');

  if (isLoading) return <BaseFooterLoader />;

  return (
    <ActionWrapper>
      <ZoomControl
        label={`${Math.round(zoom * 100)}%`}
        onClickLabel={handleFitView}
        onDecrement={handleZoomOut}
        onIncrement={handleZoomIn}
      />
      <div key={miniMapTooltipId}>
        <Tooltip id={miniMapTooltipId} place='top' text={tooltipText} />
        <StyledIconButton
          active={displayMiniMap}
          ariaLabel='Map'
          data-for={miniMapTooltipId}
          data-tip
          name='map'
          onClick={() => setDisplayMiniMap(!displayMiniMap)}
          tooltipId={miniMapTooltipId}
        />
      </div>
    </ActionWrapper>
  );
};

type Props = {
  children: ReactNode;
  isLoading: boolean;
};

const BaseFooter = ({ children, isLoading }: Props) => {
  return (
    <StyledFooter>
      <BaseFooterContent isLoading={isLoading} />
      <ActionWrapper>{children}</ActionWrapper>
    </StyledFooter>
  );
};

export default BaseFooter;
