import React from 'react';
import styled from 'styled-components';

import ProgressTimeline, { ProgressAvatar } from '././ProgressTimeline/ProgressTimeline';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const ProgressBody = styled.div`
  flex-grow: 1;
`;

export type ProgressItemProps = {
  children: React.ReactNode;
  hasLine: boolean;
  hideProgress?: boolean;
} & ProgressAvatar;

const ProgressItem = ({
  avatar,
  name,
  completionStatus,
  children,
  hasLine,
  hideProgress = false,
}: ProgressItemProps) => {
  if (hideProgress) return <>{children}</>;

  return (
    <Wrapper>
      <ProgressTimeline
        avatar={avatar}
        completionStatus={completionStatus}
        hasLine={hasLine}
        name={name}
      />
      <ProgressBody>{children}</ProgressBody>
    </Wrapper>
  );
};

export default ProgressItem;
