import React from 'react';

import { ResultIcon, ResultText } from '../styles';
import { ResultComponentProps } from '../types';
import { WrittenResponseContainer } from './styles';

const WrittenResponseResult = ({ answeredQuestion }: ResultComponentProps) => {
  const { correct, writtenResponse } = answeredQuestion;

  return (
    <WrittenResponseContainer isCorrect={correct}>
      <ResultText>{writtenResponse}</ResultText>
      <ResultIcon isCorrect={correct} name={correct ? 'check' : 'xmark'} size='sm' weight='solid' />
    </WrittenResponseContainer>
  );
};

export default WrittenResponseResult;
