import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateFormattedAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import RestrictGroupsSelect from '../RestrictGroupsSelect';
import { FormSectionContainer, FormSectionDescription, FormSectionTitle } from '../styles';
import { GroupChartGroupsFormProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const GroupChartGroupsForm = ({
  accountGroups,
  groupsRestrictedFromRoleChart,
}: GroupChartGroupsFormProps) => {
  const [updateAccountData, result] = useUpdateFormattedAccountDataMutation();
  const { isLoading } = result;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <>
      <FormSectionContainer>
        <FormSectionTitle>{t('groups_restricted_from_role_chart.title')}</FormSectionTitle>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>
          {t('groups_restricted_from_role_chart.description')}
        </FormSectionDescription>
      </FormSectionContainer>
      <RestrictGroupsSelect
        accountGroups={accountGroups}
        elementClassName='group-chart-groups-select'
        elementId='save-group-chart-groups'
        featureType='role_chart'
        isLoading={isLoading}
        restrictedGroups={groupsRestrictedFromRoleChart}
        updateAccountAction={updateAccountData}
      />
    </>
  );
};

export default GroupChartGroupsForm;
