import { LoadingSize } from '../../components/application/people/UsersOutline/Table/Column';
import { TableColumn } from '../../components/application/reports/UserAssignmentReportPage/UserAssignmentReportTable/types';
import useCurrentAccount from '../useCurrentAccount';

export function useGetUserAssigmentColumns(signatureRequired: boolean): TableColumn[] {
  const { hasESignatureFeature } = useCurrentAccount();

  const showESignaturesColumn = hasESignatureFeature && signatureRequired;

  const eSignaturesColumn: TableColumn = {
    columnName: 'e_signatures',
    loadingSize: LoadingSize.medium,
    sortable: false,
  };

  return [
    { columnName: 'name', loadingSize: LoadingSize.large, sortable: true },
    { columnName: 'completion', loadingSize: LoadingSize.medium, sortable: true },
    { columnName: 'due_date', loadingSize: LoadingSize.medium, sortable: true },
    { columnName: 'date_completed', loadingSize: LoadingSize.medium, sortable: true },
    ...(showESignaturesColumn ? [eSignaturesColumn] : []),
  ];
}
