import { isEqual } from 'lodash';
import React, { useReducer } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import { Title } from '../../../shared/DetailedTitle/Title';
import { ButtonRightPositionWrapper } from '../../OrganizationProfile/styles';
import { InputRow } from '../styles';
import { GroupsReducer } from './reducer';
import { RestrictGroupsSelectProps, RestrictGroupsState } from './types';

const t = initTranslations('account_settings.advanced_settings');

const OptionWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const RestrictGroupsSelect = ({
  accountGroups,
  elementClassName,
  elementId,
  featureType,
  updateAccountAction,
  isLoading,
  restrictedGroups,
}: RestrictGroupsSelectProps) => {
  const initialState: RestrictGroupsState = {
    selectedGroups: restrictedGroups,
  };

  const formattedOptions = formatOptions(accountGroups);
  const groupOptions = formattedOptions.map((option, index) => {
    if (!option.label) {
      return option;
    }

    const kind = accountGroups[index]?.kind;
    const label = (
      <OptionWrapper>
        <Title fontWeight='regular' title={option.label.toString()} />
        <Title
          fontColor='placeholder'
          fontWeight='regular'
          title={`(${t(`group_kinds.${kind}`)})`}
        />
      </OptionWrapper>
    );

    return { ...option, label };
  });

  const [localState, dispatchState] = useReducer(GroupsReducer, initialState);
  const { selectedGroups } = localState;

  const isValuesUnchanged =
    isEqual(restrictedGroups, selectedGroups) ||
    (isEqual(restrictedGroups, ['']) && isEqual(selectedGroups, []));

  const submitForm = () => {
    const formattedData = selectedGroups.length ? selectedGroups : [''];

    switch (featureType) {
      case 'directory':
        return updateAccountAction({ groups_restricted_from_directory: formattedData });
      case 'org_chart':
        return updateAccountAction({ groups_restricted_from_org_chart: formattedData });
      case 'role_chart':
        return updateAccountAction({ groups_restricted_from_role_chart: formattedData });
      default:
        return null;
    }
  };

  return (
    <>
      <InputRow>
        <MultiSelectField
          className={elementClassName}
          defaultValue={[]}
          onNonDefaultSelected={(value: string[]) => {
            dispatchState({
              type: 'changeSelectedGroups',
              selectedGroups: value,
            });
          }}
          options={groupOptions}
          placeholder={t('restrict_groups_placeholder')}
          value={selectedGroups}
        />
      </InputRow>
      <ButtonRightPositionWrapper>
        <DefaultButton
          disabled={isValuesUnchanged}
          id={elementId}
          loading={isLoading}
          onClick={() => submitForm()}
          text={t('save_groups')}
        />
      </ButtonRightPositionWrapper>
    </>
  );
};

export default RestrictGroupsSelect;
