import React from 'react';
import styled from 'styled-components';

import ContextCTA from './ContextCTA/ContextCTA';
import CourseSuggestionsLoader from './CourseSuggestionsLoader/CourseSuggestionsLoader';
import CourseSuggestionsTable, {
  CourseSuggestion,
} from './CourseSuggestionsTable/CourseSuggestionsTable';
import RefreshCourseSuggestions from './RefreshCourseSuggestions/RefreshCourseSuggestions';

const ContextCTAWrapper = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export type Props = {
  isLoading: boolean;
  hasContextQuestions: boolean;
  setIsEditingContext: React.Dispatch<React.SetStateAction<boolean>>;
  courseSuggestions: CourseSuggestion[];
  setSelectedCourse: (selectedCourse: CourseSuggestion | null) => void;
  refreshCourseCompletions: () => void;
};

const CourseSuggestions = ({
  isLoading,
  hasContextQuestions,
  setIsEditingContext,
  courseSuggestions,
  setSelectedCourse,
  refreshCourseCompletions,
}: Props) => {
  return (
    <>
      {isLoading || courseSuggestions.length === 0 ? (
        <CourseSuggestionsLoader />
      ) : (
        <>
          <ContextCTAWrapper>
            <ContextCTA
              hasCustomizations={hasContextQuestions}
              onClick={() => setIsEditingContext(true)}
            />
          </ContextCTAWrapper>
          <CourseSuggestionsTable
            courses={courseSuggestions}
            setSelectedCourse={setSelectedCourse}
          />
        </>
      )}
      {courseSuggestions.length > 0 && (
        <RefreshCourseSuggestions isLoading={isLoading} onClick={refreshCourseCompletions} />
      )}
    </>
  );
};

export default CourseSuggestions;
