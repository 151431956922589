import styled from 'styled-components';

import { fontSm5 } from '../../../../../styled/TypeSystem';
import RouterLink from '../../../../publicApplication/RouterLink';

export const TemplateTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;

export const TemplateTag = styled(RouterLink)`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};
`;
