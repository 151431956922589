import { Formik, FormikErrors } from 'formik';
import React, { useCallback } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import ImportCaptureModal from './ImportCaptureModal';
import { FormValues } from './types';

const t = initTranslations('steps.modals.trainual_capture.save_capture_to_step');

const SaveCaptureToStepModal = () => {
  const { step } = useAccountTerminology();

  const initialValues: FormValues = {
    stepTitle: '',
    courseId: null,
    searchValue: '',
    importOption: 'topic',
  };

  const validateForm = useCallback(
    (values: FormValues) => {
      const { stepTitle } = values;
      const errors: FormikErrors<FormValues> = {};
      if (!stepTitle) {
        errors.stepTitle = t('errors.title.required', { step: step.singular });
      }
      return errors;
    },
    [step.singular]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
        /* API request occurs in `/SaveCaptureModal.tsx` */
      }}
      validate={validateForm}
      validateOnMount
    >
      <ImportCaptureModal />
    </Formik>
  );
};

export default SaveCaptureToStepModal;
