import styled, { css } from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Icon from '../../../../design_system/display/icons/Icon';
import { MultilineTruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontMd1, fontMd3, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';
import { CurriculumsAssignmentsItemKind } from './types';

export const sharedCurriculumItemStyles = css(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${constants.spacerSm3};
    background-color: ${vars.foundationSurface1};
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: inherit;

    &:hover {
      border-color: ${vars.borderSurface2};
      background-color: ${vars.foundationBase1};
    }

    @media (min-width: ${mediaBreakpointPxSm}) {
      padding: ${constants.spacerMd2};
    }
  `
);

export const CurriculumItem = styled.a<{ hideOnHover: boolean }>`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  ${sharedCurriculumItemStyles};

  &:hover {
    .curriculum-assignments-button {
      opacity: 1;
      visibility: visible;
    }
    .curriculum-assigned-action {
      opacity: 0;
      visibility: hidden;
    }
    .cert-link {
      display: flex;
    }
    .favorite-icon {
      color: ${({ theme: { vars } }) => vars.stateFavorite};
    }
    ${({ hideOnHover }) =>
      hideOnHover &&
      css`
        .hidden-on-parent-hover {
          display: none;
        }
      `};
  }
`;

export const CurriculumEmoji = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
    margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
  }
`;

export const CurriculumInner = styled.div<{ kind?: CurriculumsAssignmentsItemKind }>`
  margin-right: auto;
  width: ${({ kind }) => `calc(100% - ${kind === 'owned_by_you' ? '250px' : '380px'})`};
`;

export const CurriculumTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
`;
export const CurriculumTitle = styled.h5`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin: 0;
  overflow-wrap: anywhere;
  ${MultilineTruncatedText({ lineClamp: 2 })};
  ${fontSm5};
`;

export const FavoriteIconWrapper = styled.div`
  padding: 0 ${({ theme: { constants } }) => constants.spacerSm2};
  cursor: pointer;
`;

export const FavoriteIcon = styled(Icon)<{ isFavorite: boolean }>`
  color: ${({ theme: { vars }, isFavorite }) =>
    isFavorite ? vars.stateFavorite : vars.borderSurface2};
`;

export const CurriculumInfo = styled.div<{ hasNoBadges?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  max-height: ${({ theme: { constants } }) => constants.heightXs};
  overflow: hidden;
  ${fontSm4};

  ${({ hasNoBadges }) =>
    hasNoBadges &&
    css`
      margin-top: ${({ theme: { constants } }) => `-${constants.spacerSm2}`};

      div[class*='CurriculumInfoItem'] {
        margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
      }
    `}
`;

export const CurriculumInfoItem = styled.div<{ followedByHidden?: boolean; hideOnHover?: boolean }>`
  position: relative;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
  &:before {
    content: '';
    display: ${({ followedByHidden, hideOnHover }) =>
      followedByHidden && hideOnHover ? 'none' : 'block'};
    background-color: ${({ theme: { vars } }) => vars.textSubdued};
    width: 3px;
    height: 3px;
    border-radius: 3px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -14px;
    margin: auto;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const CurriculumProgressBar = styled.div`
  display: flex;
  align-items: center;
  width: 130px;
`;

export const CurriculumReference = styled.div`
  width: 130px;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm4};
`;

export const CurriculumReferenceIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const CurriculumCompletion = styled.div`
  width: 2.5rem;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd1};
`;

export const CurriculumAssignedActionIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const CurriculumAssignmentType = styled.div`
  word-wrap: break-word;
  ${MultilineTruncatedText({ lineClamp: 3 })};
`;

export const CurriculumAssignedActionInner = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  transition: 0.2s ease-in-out;
  ${fontSm4};
`;

export const CurriculumActionWrapper = styled.div`
  position: relative;
  width: 160px;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const StyledButton = styled(DefaultButton)`
  position: absolute;
  inset: 0;
  margin: auto;
  transition: 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
`;
