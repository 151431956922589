import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';

export const TabsWrapper = styled.div`
  width: 100%;
  margin-bottom: -1px;

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: calc(100% - 1.625rem);
  }
`;
