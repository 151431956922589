import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useIntegrationCardsImages from '../../../../hooks/useIntegrationCardsImages';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../lib/initTranslations';
import {
  IntegrationCard as IntegrationCardType,
  IntegrationKey,
} from '../../../../types/IntegrationCard';
import TooltipHoverIcon from '../../../design_system/core/Labels/ControlLabel/TooltipHoverIcon';
import { routes } from '../../publicApplication/applicationRouter';
import { ADP_WORKFORCE_NOW, RIPPLING, TIME_DOCTOR } from '../constants';
import {
  BadgeBeta,
  CardBody,
  CardDescription,
  CardHeader,
  CardLink,
  CardLogoWrapper,
  CardReadMore,
  CardRouterLink,
  CardTitle,
  CardTooltip,
  StyledCard,
  StyledCardLogo,
  StyledCheckmark,
} from './styles';

const t = initTranslations('integrations');

export interface IntegrationCardProps {
  card: IntegrationCardType;
}

const IntegrationCard = ({ card }: IntegrationCardProps) => {
  const { configs } = usePrivateConfigs();
  const { slug } = useCurrentAccount();
  const { title, description, key, isConnected, isBeta } = card;
  const { cardsData } = useIntegrationCardsImages();
  const { darkImage, lightImage } = cardsData[key];
  const useExternalRoute = key === RIPPLING || key === TIME_DOCTOR;
  const isAssisted = key === ADP_WORKFORCE_NOW;

  const getLinkData = (key: IntegrationKey) => {
    switch (key) {
      case TIME_DOCTOR:
        return { href: configs['TIME_DOCTOR_INTEGRATION_URL'] };
      case RIPPLING:
        return { href: configs['RIPPLING_INTEGRATION_URL'] };
      default:
        return {};
    }
  };

  const linkData = getLinkData(key);

  const cardBody = (
    <StyledCard>
      <CardLogoWrapper>
        <StyledCardLogo alt={title} darkImage={darkImage} lightImage={lightImage} />
      </CardLogoWrapper>
      <CardBody>
        <CardHeader>
          <CardTitle className='notranslate'>{title}</CardTitle>
          {isAssisted && (
            <CardTooltip>
              <TooltipHoverIcon
                tooltipId='assisted-integration-tooltip'
                tooltipText={t('assisted_integration_disclaimer', { title })}
              />
            </CardTooltip>
          )}
        </CardHeader>
        <CardDescription>{description}</CardDescription>
        <CardReadMore>{t('read_more')}</CardReadMore>
        {isConnected && <StyledCheckmark text={t('connected')} />}
        {isBeta && <BadgeBeta>{t('beta')}</BadgeBeta>}
      </CardBody>
    </StyledCard>
  );

  return useExternalRoute ? (
    <CardLink href={linkData.href} target='_blank'>
      {cardBody}
    </CardLink>
  ) : (
    <CardRouterLink to={routes.integration({ slug, provider: key })}>{cardBody}</CardRouterLink>
  );
};

export default IntegrationCard;
