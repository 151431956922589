import React, { FC } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import Icon from '../../../../../../design_system/display/icons/Icon';
import { ConnectedContentInfoCard } from './styles';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.info');

const ConnectedContentInfo: FC = () => {
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();

  return (
    <ConnectedContentInfoCard>
      <Icon name='circle-info' size='2xs' />
      {t('connect_content', { responsibility: responsibilitySingular.toLowerCase() })}
    </ConnectedContentInfoCard>
  );
};

export default ConnectedContentInfo;
