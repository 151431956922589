import { NullableBillingDetails } from '../../redux/services/resourceApis/billing/types';
import { BillingCardDetails } from '../../types/BillingCardDetails';

export function checkCreditCardData(cardDetails?: NullableBillingDetails<BillingCardDetails>) {
  if (!cardDetails) return false;

  const { brand, expMonth, expYear, last4 } = cardDetails;

  return Boolean(brand && expMonth && expYear && last4);
}
