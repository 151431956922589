import { Action, ImageImportOption } from './types';

export const imageUploadReducer = (state: ImageImportOption, action: Action): ImageImportOption => {
  switch (action.type) {
    case 'setImageSrc': {
      return { ...state, imageSrc: action.imageSrc };
    }
    case 'setUploadedImageSize': {
      return { ...state, uploadedImageSize: action.uploadedImageSize };
    }
    case 'setRotation': {
      return { ...state, rotation: action.rotation };
    }
    case 'setZoom': {
      return { ...state, zoom: action.zoom };
    }
    case 'setCrop': {
      return { ...state, crop: action.crop };
    }
    case 'setCroppedAreaPixels': {
      return { ...state, croppedAreaPixels: action.croppedAreaPixels };
    }
    case 'resetImage': {
      return {
        ...state,
        imageSrc: undefined,
        crop: { x: 0, y: 0 },
        rotation: 0,
        zoom: 1,
      };
    }
  }
};
