import { WhitelistedIpAddressesFormAction, WhitelistedIpAddressesFormState } from './types';

export const IpAddressesReducer = (
  state: WhitelistedIpAddressesFormState,
  action: WhitelistedIpAddressesFormAction
): WhitelistedIpAddressesFormState => {
  switch (action.type) {
    case 'changeSelectedIpAddresses': {
      return { ...state, selectedIpAddresses: action.selectedIpAddresses };
    }
  }
};
