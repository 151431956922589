import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';

import { useAppSelector } from '../../../../redux/hooks';
import { reportsApi } from '../../../../redux/services/resourceApis/reports/reportsApi';
import DownloadCsvReport from '../DownloadCsvReport/DownloadCsvReport';

const UserAssignmentDownloadCsvReport = ({ curriculumId }: { curriculumId: number }) => {
  const { filters, searchTerm } = useAppSelector((state) => state.reports.userAssignmentReport);
  const [getCsv] = reportsApi.useLazyGetUserAssignmentReportCsvQuery();
  const [cookies] = useCookies(['users_assignment_selected_reports_sort']);
  const isCookies = cookies.users_assignment_selected_reports_sort;
  const sortCol = isCookies && cookies.users_assignment_selected_reports_sort['column'];
  const sortColDir = isCookies && cookies.users_assignment_selected_reports_sort['dir'];

  const downloadCsvDocument = useCallback(() => {
    getCsv({ ...filters, searchTerm, id: curriculumId, sortCol, sortColDir });
  }, [curriculumId, filters, getCsv, searchTerm, sortCol, sortColDir]);

  return (
    <DownloadCsvReport
      btnSize='md'
      downloadCsvDocument={downloadCsvDocument}
      id='user-assignment-button-download-csv'
    />
  );
};

export default UserAssignmentDownloadCsvReport;
