import { toSnakeCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import { surveysApi } from '../surveys/surveysApi';
import {
  SurveyQuestionOptionCreateParams,
  SurveyQuestionOptionDeleteParams,
  SurveyQuestionOptionUpdateParams,
} from './types';

const surveyQuestionOptionsApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    createSurveyQuestionOption: builder.mutation<undefined, SurveyQuestionOptionCreateParams>({
      query: ({ surveyId, questionId }) => ({
        url: `/ajax/surveys/${surveyId}/questions/${questionId}/options`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { surveyId }) => [{ type: 'Survey', id: surveyId }],
    }),
    updateSurveyQuestionOption: builder.mutation<undefined, SurveyQuestionOptionUpdateParams>({
      query: (params) => ({
        url: `/ajax/surveys/${params.surveyId}/questions/${params.questionId}/options/${params.id}`,
        method: 'PUT',
        body: toSnakeCase(params),
      }),
      async onQueryStarted(
        { surveyId, questionId, id, resetOptions, ...updateParams },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          surveysApi.util.updateQueryData('getEditSurvey', { id: surveyId }, (draft) => {
            const question = draft.questions.find((q) => q.id === questionId);
            const option = question?.options.find((option) => option.id === id);
            if (!option) return;

            if (resetOptions && question) {
              const correctOption = question.options.find((o) => o.correct === true);
              if (correctOption) correctOption.correct = false;
            }

            Object.assign(option, updateParams);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (_result, _error, { surveyId }) => [{ type: 'Survey', id: surveyId }],
    }),
    deleteSurveyQuestionOption: builder.mutation<undefined, SurveyQuestionOptionDeleteParams>({
      query: ({ id, surveyId, questionId }) => ({
        url: `/ajax/surveys/${surveyId}/questions/${questionId}/options/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ surveyId, questionId, id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveysApi.util.updateQueryData('getEditSurvey', { id: surveyId }, (draft) => {
            const question = draft.questions.find((q) => q.id === questionId);
            if (question) {
              const optionIndex = question.options.findIndex((option) => option.id === id);
              if (optionIndex !== -1) question.options.splice(optionIndex, 1);
            }
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (_result, _error, { surveyId }) => [{ type: 'Survey', id: surveyId }],
    }),
  }),
});

export const {
  useCreateSurveyQuestionOptionMutation,
  useUpdateSurveyQuestionOptionMutation,
  useDeleteSurveyQuestionOptionMutation,
} = surveyQuestionOptionsApi;
