import React from 'react';

import BoardEmptyStateImage from '../../../../images/empty_state/delegation-planner-board-empty-state.svg';
import initTranslations from '../../../../lib/initTranslations';
import {
  BoardEmptyStateContainer,
  BoardEmptyStateImageContainer,
  BoardEmptyStateText,
  BoardEmptyStateTitle,
} from './styles';

const t = initTranslations('delegation_planner.board_empty_state');

const BoardEmptyState = () => {
  return (
    <BoardEmptyStateContainer>
      <BoardEmptyStateImageContainer src={BoardEmptyStateImage} />

      <BoardEmptyStateTitle>{t('title')}</BoardEmptyStateTitle>

      <BoardEmptyStateText>{t('description')}</BoardEmptyStateText>
    </BoardEmptyStateContainer>
  );
};

export default BoardEmptyState;
