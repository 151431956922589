import { Action, State } from './types';

export const curriculumsAssignmentsReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setPage': {
      return { ...state, page: action.page };
    }
    case 'setSorting': {
      return { ...state, sorting: action.sorting };
    }
    case 'setSortDirection': {
      return { ...state, sortDirection: action.sortDirection };
    }
  }
};
