import React, { ReactElement, createContext, useCallback, useState } from 'react';

type ReadingTimeContextType = {
  newHours: number;
  newMinutes: number;
  updateReadingTime: (hours: number, minutes: number) => void;
};

const ReadingTimeContext = createContext<ReadingTimeContextType>({
  newHours: 0,
  newMinutes: 0,
  updateReadingTime: () => ({}),
});

const ReadingTimeProvider = ({ children }: { children: ReactElement }) => {
  const [newHours, setNewHours] = useState(0);
  const [newMinutes, setNewMinutes] = useState(0);

  const updateReadingTime = useCallback((hours: number, minutes: number) => {
    setNewHours(hours);
    setNewMinutes(minutes);
  }, []);

  return (
    <ReadingTimeContext.Provider value={{ newHours, newMinutes, updateReadingTime }}>
      {children}
    </ReadingTimeContext.Provider>
  );
};

export { ReadingTimeContext, ReadingTimeProvider };
