import { BillingInterval } from '../../types/BillingInterval';
import { BillingPlanName } from '../../types/BillingPlanName';
import { setQueryParam } from '../getQueryParams';

type QueryParamsFromResponseProps = {
  selectedBillingInterval: BillingInterval;
  selectedSeatsCount?: number;
  selectedPlanName?: BillingPlanName;
};

export const setQueryParamsFromResponse = ({
  selectedBillingInterval,
  selectedSeatsCount,
  selectedPlanName,
}: QueryParamsFromResponseProps) => {
  if (selectedBillingInterval) {
    setQueryParam('interval', selectedBillingInterval);
  }

  if (selectedSeatsCount) {
    setQueryParam('quantity', String(selectedSeatsCount));
  }

  if (selectedPlanName) {
    setQueryParam('plan', selectedPlanName);
  }
};
