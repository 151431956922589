import { useFormikContext } from 'formik';
import React from 'react';

import { OnboardingData, useOnboarding } from '../../../../../contexts/OnboardingContext';
import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Icon from '../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import SingleSelect from '../../../../design_system/Triage/fields/SingleSelectField/SingleSelectField';
import { ErrorText } from '../../../../design_system/Triage/InputField';
import CompanySizeRange from '../CompanySizeRange/CompanySizeRange';
import { PreFillableInputField } from '../styles';
import {
  ButtonsWrapper,
  FormContainer,
  InfoIconWrapper,
  InfoText,
  InputRow,
  InputWrapper,
  Label,
  SelectWrapper,
  StyledForm,
} from './styles';

const t = initTranslations('home.onboarding.customize_your_experience_step');

const CustomizeYourExperienceStepForm = () => {
  const { values, touched, errors, handleSubmit, handleChange, setFieldValue } =
    useFormikContext<OnboardingData>();

  const { isMobile } = useWindowResize();
  const { sliderRef } = useOnboarding();
  const { data, isLoadingData, initialFormData, isInputFieldPrefilled } = useOnboarding();

  const formattedOptions = (option: Array<string> | undefined) => {
    return option
      ? option.map((i) => {
          return {
            label: i,
            value: i,
            searchableTerm: i,
          };
        })
      : [];
  };

  const handlePrevSlide = (e: React.MouseEvent<HTMLButtonElement>) => {
    sliderRef?.current?.slickPrev();
    e.currentTarget.blur();
  };

  return (
    <FormContainer>
      <StyledForm
        autoComplete='off'
        id='customize-experience-step-form'
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        onSubmit={handleSubmit}
      >
        <InputRow>
          <InputWrapper>
            <PreFillableInputField
              displayPrefilled={isInputFieldPrefilled(initialFormData.name, values.name)}
              errorText={touched.name && errors.name}
              isError={!!(touched.name && errors.name)}
              label={t('labels.what_is_your_company_name')}
              name='name'
              onChange={handleChange}
              placeholder={t('placeholders.company_name')}
              required
              value={values.name}
            />
          </InputWrapper>
          <InputWrapper>
            <PreFillableInputField
              displayPrefilled={isInputFieldPrefilled(
                initialFormData.user_title,
                values.user_title
              )}
              errorText={touched.user_title && errors.user_title}
              isError={!!(touched.user_title && errors.user_title)}
              label={t('labels.what_do_you_do')}
              name='user_title'
              onChange={handleChange}
              placeholder={t('placeholders.job_title')}
              required
              value={values.user_title}
            />
          </InputWrapper>
        </InputRow>
        <InputRow>
          <SelectWrapper>
            <SingleSelect
              className='industry-select'
              defaultValue={values.industry}
              disabled={isLoadingData}
              fieldLabelText={t('labels.what_industry_are_you_in')}
              isValid={!(touched.industry && errors.industry)}
              loading={isLoadingData}
              name='source'
              onNonDefaultSelected={(industry: string) => setFieldValue('industry', industry)}
              options={formattedOptions(data?.industries)}
              placeholder={t('placeholders.industry')}
              required
              size='lg'
              value={values.industry}
            />
            {errors.industry && touched.industry && <ErrorText>{errors.industry}</ErrorText>}
          </SelectWrapper>
          <InfoText>
            <InfoIconWrapper data-for='industry-tooltip' data-tip>
              <Icon name='info-circle' weight='regular' />
            </InfoIconWrapper>
            <Tooltip
              id='industry-tooltip'
              place={isMobile ? 'right' : 'top'}
              text={t('info.tooltip')}
            />
            {t('info.text')}
          </InfoText>
        </InputRow>
        <Label>{t('labels.how_big_is_your_company')}</Label>
        {data && (
          <>
            {isMobile ? (
              <SingleSelect
                defaultValue={values.company_size}
                disabled={isLoadingData}
                name='company_size'
                onNonDefaultSelected={(size: string) => setFieldValue('company_size', size)}
                options={formattedOptions(data?.organizationSize)}
                value={values.company_size}
              />
            ) : (
              <CompanySizeRange
                companySizes={data.organizationSize}
                defaultValue={values.company_size}
                setCompanySize={(size) => setFieldValue('company_size', size)}
              />
            )}
          </>
        )}
        <ButtonsWrapper>
          <DefaultButton
            buttonType='tertiary'
            id='onboarding-customize-experience-step-back'
            onClick={handlePrevSlide}
            size='md'
            text={t('back')}
          />
          <DefaultButton
            buttonType='primary'
            id='onboarding-customize-experience-step-submit'
            size='md'
            text={t('next')}
            type='submit'
          />
        </ButtonsWrapper>
      </StyledForm>
    </FormContainer>
  );
};

export default CustomizeYourExperienceStepForm;
