import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { setCurrentQuestionIndex } from '../../../../../../redux/domains/surveyConsume/surveyConsumeSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { ControlButtonsContainer, TooltipOverlay } from '../../../shared/styles';
import { ConsumptionFormControlButtonsProps, FormValues } from './types';

const t = initTranslations('survey_consume');

const ConsumptionFormControlButtons = ({
  isSubmitLoading,
  isSurveyFinished,
}: ConsumptionFormControlButtonsProps) => {
  const dispatch = useAppDispatch();
  const { handleSubmit, values, isSubmitting } = useFormikContext<FormValues>();
  const { currentQuestionIndex, questionAnswersData } = useAppSelector(
    (state) => state.surveyConsume
  );
  const isAllQuestionsAnswered = questionAnswersData.every((data) => data.isAnswered);
  const isLastQuestion = currentQuestionIndex === values.answersAttributes.length - 1;
  const isSubmitDisabled = !isSurveyFinished || isSubmitting;
  const displayNextQuestionButton = !isLastQuestion && !isAllQuestionsAnswered;

  return (
    <ControlButtonsContainer>
      {!!currentQuestionIndex && (
        <DefaultButton
          buttonType='tertiary'
          iconName='arrow-up'
          id='previous-survey-question-button'
          onClick={() => dispatch(setCurrentQuestionIndex(currentQuestionIndex - 1))}
          size='lg'
          text={t('previous_question')}
        />
      )}
      {displayNextQuestionButton && (
        <DefaultButton
          buttonType='primary'
          iconName='arrow-down'
          id='next-survey-question-button'
          onClick={() => dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1))}
          size='lg'
          text={t('next_question')}
        />
      )}
      {isAllQuestionsAnswered && (
        <>
          {!isSurveyFinished && (
            <Tooltip
              id='test-result-submit-disabled-tooltip'
              place='top'
              text={t('unfinished_test_submit_tooltip')}
            />
          )}

          <TooltipOverlay data-for='test-result-submit-disabled-tooltip' data-tip>
            <DefaultButton
              buttonType='primary'
              disabled={isSubmitDisabled}
              iconName='check'
              id='submit-survey-consumption-button'
              loading={isSubmitLoading}
              onClick={() => handleSubmit()}
              size='lg'
              text={t('submit_answers')}
            />
          </TooltipOverlay>
        </>
      )}
    </ControlButtonsContainer>
  );
};

export default ConsumptionFormControlButtons;
