import React, { Dispatch, useCallback } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateCompletionsMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CompletionAction } from '../../../../../redux/services/resourceApis/curriculums/types';
import { SimpleUserWithCompletion } from '../../../../../types/Curriculum';
import ProgressBar from '../../../../design_system/Triage/ProgressBar';
import BulkCompletionsActionsMenu from './BulkCompletionsActionsMenu';
import ActionCell from './ManageCompletionsTable/ActionCell';
import NameCell from './ManageCompletionsTable/NameCell';
import NameHeader from './ManageCompletionsTable/NameHeader';
import { TableAction, TableState } from './reducer';
import {
  CompletionCell,
  CompletionPercent,
  CompletionPercentWrapper,
  HeaderRow,
  TableHeader,
  TableRow,
  TableStyled,
  TableWrapper,
} from './styles';

const t = initTranslations('curriculum_edit.manage_completions.table');

interface Props {
  curriculumId: number;
  tableDispatch: Dispatch<TableAction>;
  tableState: TableState;
  users: SimpleUserWithCompletion[];
}

const ManageCompletionsTable = ({ curriculumId, tableDispatch, tableState, users }: Props) => {
  const [updateCompletions, result] = useUpdateCompletionsMutation();

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
    errorMessage: t('error_message'),
    successFunction: () => {
      result.reset();
      tableDispatch({ type: 'selectAllUsers', selectAllUsers: false });
    },
  });

  const handleBulkActionSelection = useCallback(
    (action: CompletionAction) => {
      updateCompletions({
        id: curriculumId,
        userIds: tableState.selectedUsers,
        actionType: action,
        search: tableState.searchValue,
        page: tableState.page,
      });
    },
    [
      curriculumId,
      tableState.page,
      tableState.searchValue,
      tableState.selectedUsers,
      updateCompletions,
    ]
  );

  const handleSingleActionSelection = useCallback(
    (action: CompletionAction, userId: number) => {
      updateCompletions({
        id: curriculumId,
        userIds: [userId],
        actionType: action,
        search: tableState.searchValue,
        page: tableState.page,
      });
    },
    [curriculumId, tableState.page, tableState.searchValue, updateCompletions]
  );

  return (
    <>
      <TableWrapper id='manage-completions-table'>
        <TableStyled>
          <HeaderRow>
            <tr>
              <NameHeader
                bulkChecked={tableState.selectAllUsers}
                handleBulkCheckboxChange={() =>
                  tableDispatch({
                    type: 'selectAllUsers',
                    selectAllUsers: !tableState.selectAllUsers,
                  })
                }
              />
              <TableHeader>{t('completion_score_header')}</TableHeader>
              <TableHeader>{t('actions_header')}</TableHeader>
            </tr>
          </HeaderRow>
          <tbody>
            {users.map((user, index) => {
              return (
                <TableRow
                  id={`manage-completions-table-row-${user.id}`}
                  key={`${user.name}-${index}`}
                >
                  <NameCell
                    avatar={user.avatar}
                    id={user.id}
                    jobTitle={user.title}
                    name={user.name}
                    selectedUsers={tableState.selectedUsers}
                    tableDispatch={tableDispatch}
                  />
                  <CompletionCell>
                    <CompletionPercentWrapper>
                      <CompletionPercent className='completion-score-wrapper'>
                        {user.completion.score}%
                      </CompletionPercent>
                      <ProgressBar percent={user.completion.score} thickness='md' />
                    </CompletionPercentWrapper>
                  </CompletionCell>
                  <ActionCell id={user.id} updateCompletion={handleSingleActionSelection} />
                </TableRow>
              );
            })}
          </tbody>
        </TableStyled>
      </TableWrapper>
      {(tableState.selectedUsers.length || tableState.selectAllUsers) && (
        <BulkCompletionsActionsMenu
          handleBulkActionSelection={handleBulkActionSelection}
          onCloseRequest={() =>
            tableDispatch({
              type: 'selectAllUsers',
              selectAllUsers: false,
            })
          }
          selectedCount={tableState.selectedUsers.length}
        />
      )}
    </>
  );
};

export default ManageCompletionsTable;
