import React from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../../lib/initTranslations';
import titleize from '../../../../../../../lib/titleize';
import { messageFromError } from '../../../../../../../redux/errors/helpers';
import { ReduxResult } from '../../../../../../../types/Redux';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import Icon from '../../../../../../design_system/display/icons/Icon';
import SourceBadge from '../../../../../../design_system/navigation/SourceBadge/SourceBadge';
import TaskModal, {
  TaskModalProps,
} from '../../../../../../design_system/overlays/modals/TaskModal';
import { TruncatedText } from '../../../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../../../styled/TypeSystem';
import { BulkContentUpdateModalProps } from '../../types';

const SelectedContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
  height: ${({ theme: { constants } }) => constants.spacerLg1};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ContentTitle = styled.div`
  ${TruncatedText({})};
  ${fontSm5};
`;

const SourceBadgeContainer = styled.div`
  margin-left: auto;
`;

const Emoji = styled.span`
  ${fontSm5};
`;

const BulkContentUpdateModal = ({
  selectedIds,
  type,
  onClose,
  bulkUpdate,
  bulkUpdateResult,
  curriculumsChecked,
}: BulkContentUpdateModalProps) => {
  const t = initTranslations(`curriculums.content.bulk_actions.${type.replace(/-/g, '_')}_modal`);

  const {
    curriculum: { singular: subjectTermSingular, plural: subjectTermPlural },
  } = useAccountTerminology();

  useDisplayFlashOnResponse({
    result: bulkUpdateResult || ({} as ReduxResult),
    successMessage: t('success_flash', { subjects: subjectTermPlural.toLowerCase() }),
    successFunction: onClose,
    errorMessage: messageFromError(bulkUpdateResult?.error)?.join(', '),
  });

  const count = selectedIds.length;

  const taskModalArgs: TaskModalProps = {
    title: t('title', {
      count,
      subject: subjectTermSingular.toLowerCase(),
      subjects: subjectTermPlural.toLowerCase(),
    }),
    onCloseRequest: onClose,
    primaryButtonText: t('button_text', { type }),
    primaryButtonTask: bulkUpdate ? () => bulkUpdate({ curriculumIds: selectedIds }) : () => ({}),
    secondaryButtonText: t('cancel'),
    subtitle: t('subtitle', {
      count,
      subject: subjectTermSingular.toLowerCase(),
      subjects: subjectTermPlural.toLowerCase(),
    }),
    heapModalName: `bulk-content-${type}-modal`,
    processing: false,
    scrollInsideBody: true,
    desktopSize: 'lg',
  };

  return (
    <TaskModal {...taskModalArgs}>
      {curriculumsChecked &&
        curriculumsChecked.map((curriculum) => {
          return (
            <SelectedContent key={`${curriculum.title}-${curriculum.id}`}>
              {curriculum.emoji ? (
                <Emoji>{curriculum.emoji}</Emoji>
              ) : (
                <Icon name='file-lines' size='xs' />
              )}
              <ContentTitle>{curriculum.title}</ContentTitle>
              <SourceBadgeContainer>
                <SourceBadge
                  readOnly
                  sourceName={curriculum.sector}
                  sourceText={titleize(curriculum.sector)}
                />
              </SourceBadgeContainer>
            </SelectedContent>
          );
        })}
    </TaskModal>
  );
};

export default BulkContentUpdateModal;
