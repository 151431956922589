import React from 'react';

import {
  SearchResultsChipsCount,
  SearchResultsChipsText,
  SearchResultsChipsWrapper,
} from './styles';

type SearchResultsChipTextProps = {
  text: string;
  count: number | null | React.ReactNode;
};

const SearchResultsChipText = ({ text, count }: SearchResultsChipTextProps) => {
  return (
    <SearchResultsChipsWrapper>
      <SearchResultsChipsText>{text}</SearchResultsChipsText>
      {count !== null && <SearchResultsChipsCount>({count})</SearchResultsChipsCount>}
    </SearchResultsChipsWrapper>
  );
};

export default SearchResultsChipText;
