import React, { MouseEvent } from 'react';
import styled from 'styled-components';

import useSlideout from '../../../../hooks/useSlideout';
import initTranslations from '../../../../lib/initTranslations';
import { userInitials } from '../../../../lib/userNaming';
import { SimpleUser } from '../../../../types/User';
import Badge from '../../../design_system/display/badge';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { BaseImageInitialsContainer, BaseRoundedCard, Card } from '../../../styled/BaseUserCard';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';

const RoundedCard = styled(BaseRoundedCard)`
  &:hover,
  &:active {
    box-shadow: ${({ theme: { vars } }) => vars.shadowTopMedium};
  }
`;

const CardLink = styled.a`
  text-decoration: none;
`;

const ImageInitialsContainer = styled(BaseImageInitialsContainer)`
  background: ${({ hasImage, theme: { vars } }) =>
    hasImage ? vars.foundationSurface1 : vars.textDisabled};
`;

const Initials = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 6.25rem;
  color: white;

  @media (min-width: ${mediaBreakpointPxSm}) {
    font-size: 2.5rem;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
`;

const CardTitle = styled.div`
  ${TruncatedText({})};
  font-weight: 600;
  font-size: 1rem;
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin: ${({ theme: { constants } }) =>
    `${constants.spacerMd1} ${constants.spacerMd2} 0 ${constants.spacerMd2}`};

  @media (min-width: 75rem) {
    font-size: 0.875rem;
  }
`;

const CardSubtitle = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  color: ${(props) => props.theme.vars.textDefault};
  margin: ${({ theme: { constants } }) =>
    `0 ${constants.spacerMd2} ${constants.spacerMd1} ${constants.spacerMd2}`};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const BadgeWrapper = styled.div`
  position: absolute;
  right: 0.35rem;
  bottom: 0.55rem;
`;

const NewUserBadge = (userCreationDate: string, badgeTitle: string) => {
  const createdAt = new Date(userCreationDate);
  const date30DaysAgo = new Date(new Date().setDate(new Date().getDate() - 30));

  if (createdAt > date30DaysAgo) {
    return (
      <BadgeWrapper>
        <Badge size='md' text={badgeTitle} type='success' />
      </BadgeWrapper>
    );
  } else {
    return null;
  }
};

const t = initTranslations('people');

export interface Props {
  user: SimpleUser;
}

const UserCard = (props: Props) => {
  const {
    user: { id, avatar, name, email, title, created_at: createdAt },
  } = props;
  const { open } = useSlideout('user-profile');

  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    open({ userId: id.toString() });
  };

  return (
    <Card className='user-card col'>
      <CardLink
        aria-label={`${name} ${title || ''} ${t('profile')}`}
        className='user-card-link'
        href='#'
        onClick={handleOnClick}
      >
        <RoundedCard id={`user-card-${id}`}>
          <ImageInitialsContainer hasImage={!!avatar}>
            {avatar ? (
              <Image className='img-fluid' src={avatar} />
            ) : (
              <Initials className='notranslate'>{userInitials(name)}</Initials>
            )}
            {NewUserBadge(createdAt, t('new'))}
          </ImageInitialsContainer>
          <CardTitle className='notranslate'>{name || email}</CardTitle>
          <CardSubtitle>{title || <span>&nbsp;</span>}</CardSubtitle>
        </RoundedCard>
      </CardLink>
    </Card>
  );
};

export default UserCard;
