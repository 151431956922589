import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useBulkUploadMutation } from '../../../../../redux/services/resourceApis/users/usersApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { ModalBodyContainer, ModalFooterContainer } from '../../../../styled/Modals';
import StatusBlockSuccess from '../../BulkUserUploadStatusBlock/StatusBlockSuccess';
import { SuccessResult } from '../ResultTypes';
import ResultTable from './ResultTable';

const StyledModalBodyContainer = styled(ModalBodyContainer)`
  display: block;
`;

const StyledInviteButton = styled(DefaultButton)`
  margin-top: 1rem;
  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-left: ${({ theme: { constants } }) => constants.spacerMd1};
    margin-top: 0;
  }
`;

const t = initTranslations('bulk_user_upload_modal.success_import');

type Props = {
  closeModal: () => void;
  data: SuccessResult;
  uploadFileData: FormData;
  setImportJobIds: React.Dispatch<React.SetStateAction<string[] | undefined>>;
};

const SuccessImport = ({ closeModal, data, uploadFileData, setImportJobIds }: Props) => {
  const [fileData, setFileData] = useState(uploadFileData);
  const [shouldSendInvites, setShouldSendInvites] = useState<'true' | 'false' | null>(null);
  const [bulkUpload, result] = useBulkUploadMutation();
  const { isLoading, error, data: bulkUploadData } = result;

  useEffect(() => {
    if (bulkUploadData?.job_ids) {
      setImportJobIds(bulkUploadData.job_ids);
    }
  }, [setImportJobIds, bulkUploadData]);

  useDisplayFlashOnResponse({
    result,
    successMessage:
      shouldSendInvites === 'true'
        ? t('invitation_emails')
        : t('just_upload', { count: bulkUploadData && bulkUploadData.success.people.length }),
    successFunction: () => {
      closeModal();
    },
    errorMessage: messageFromError(error)?.join(', '),
  });

  const successTitle = (
    <span
      dangerouslySetInnerHTML={{
        __html:
          data.people.length === 1
            ? t('bulk_user_upload_status_block.title_singular')
            : t('bulk_user_upload_status_block.title_plural', { quantity: data.amount }),
      }}
    />
  );

  const uploadWithInvites = useCallback(
    (shouldSendInvites) => {
      setShouldSendInvites(shouldSendInvites);
      setFileData((prevData) => {
        prevData.append('invite_users', shouldSendInvites);
        return prevData;
      });
      bulkUpload(fileData);
    },
    [bulkUpload, fileData]
  );

  return (
    <>
      <StyledModalBodyContainer>
        <StatusBlockSuccess title={successTitle} />
        <ResultTable data={data.people} />
      </StyledModalBodyContainer>
      <ModalFooterContainer>
        <DefaultButton
          buttonType='secondary'
          disabled={isLoading}
          id='success-import-skip-invites'
          loading={shouldSendInvites === 'false' && isLoading}
          onClick={() => uploadWithInvites('false')}
          text={t('skip_invites')}
        />
        <StyledInviteButton
          buttonType='primary'
          disabled={isLoading}
          id='success-import-invite-all'
          loading={shouldSendInvites === 'true' && isLoading}
          onClick={() => uploadWithInvites('true')}
          text={t('invite_all')}
        />
      </ModalFooterContainer>
    </>
  );
};

export default SuccessImport;
