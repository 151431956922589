import React from 'react';

import { useAppSelector } from '../../../../../redux/hooks';
import { TGroupsSelectListProps } from '../types';
import GroupSelectItem from './GroupSelectItem';
import { GroupSelectableElementsContainer } from './styles';

const GroupsSelectList = ({ groups, initiallySelectedGroupIds }: TGroupsSelectListProps) => {
  const { selectedGroupIds } = useAppSelector((state) => state.delegationPlanner);

  return (
    <GroupSelectableElementsContainer>
      {groups.map((group) => {
        const { id } = group;
        const isInitiallySelected = initiallySelectedGroupIds.includes(id);
        const isCurrentlySelected = selectedGroupIds.includes(id);

        return (
          <GroupSelectItem
            group={group}
            isCurrentlySelected={isCurrentlySelected}
            isInitiallySelected={isInitiallySelected}
            key={`group-option-${id}`}
          />
        );
      })}
    </GroupSelectableElementsContainer>
  );
};

export default GroupsSelectList;
