import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import initTranslations from '../../../../lib/initTranslations';
import Tag from '../../../design_system/display/Tag';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import AiSearchResult from './AiSearchResult';
import { ResultsWrapper, SearchResultsHeaderContainer } from './styles';

const t = initTranslations('search_results_page');

export type Props = {
  searchTerm: string;
  totalResultCount?: number;
};

const AiResultsWrapper = styled.div`
  min-height: 7.7246rem;
`;

const ExperimentalSearchResults = ({ searchTerm, totalResultCount }: Props) => {
  const {
    textEmbeddingModels,
    splitFeatures: { helpCenterSearchEnabled, newSearchUiEnabled },
  } = useCurrentAccount();
  const { showExperimentalSearch } = useCurrentUser();

  const models = showExperimentalSearch ? textEmbeddingModels : [textEmbeddingModels[0]];
  const subtitle = !helpCenterSearchEnabled
    ? t('header.items_found', { count: totalResultCount })
    : '';

  return (
    <ResultsWrapper isNewSearchUiEnabled={newSearchUiEnabled}>
      {!newSearchUiEnabled && (
        <SearchResultsHeaderContainer>
          <PageHeader subtitle={subtitle} title={`“${searchTerm}“`} />
          <Tag size='md' type='accent' />
        </SearchResultsHeaderContainer>
      )}

      <AiResultsWrapper>
        {models.map((modelId) => (
          <AiSearchResult key={modelId} modelId={modelId} searchTerm={searchTerm} />
        ))}
      </AiResultsWrapper>
    </ResultsWrapper>
  );
};

export default ExperimentalSearchResults;
