import React from 'react';

import {
  DropdownMenuOption,
  DropdownMenuOptionIcon,
  DropdownMenuOptionText,
  StyledIcon,
} from './styles';
import { SortDropdownMenuOptionProps } from './types';

const SortingDropdownMenuOption = ({ title, isSelected, onClick }: SortDropdownMenuOptionProps) => {
  return (
    <DropdownMenuOption onClick={onClick}>
      <DropdownMenuOptionIcon>{isSelected && <StyledIcon name='check' />}</DropdownMenuOptionIcon>
      <DropdownMenuOptionText>{title}</DropdownMenuOptionText>
    </DropdownMenuOption>
  );
};

export default SortingDropdownMenuOption;
