import { useEffect } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { useAppDispatch } from '../../../../../redux/hooks';
import { delegationPlannerApi } from '../../../../../redux/services/resourceApis/delegationPlanner/delegationPlannerApi';
import useLocalStorageState, {
  LocalStorageKey,
} from '../../../publicApplication/utils/useLocalStorageState';
import { useCurrentBoardId } from './useCurrentBoardId';

export const useBoardLocalStorageEffects = () => {
  const currentBoardId = useCurrentBoardId();

  const dispatch = useAppDispatch();

  const { id: accountId } = useCurrentAccount();

  const [storedBoardId, setStoredBoardId] = useLocalStorageState({
    key: `currentDelegationBoardId_${accountId}`,
  });

  useEffect(() => {
    if (currentBoardId !== storedBoardId) {
      setStoredBoardId(currentBoardId);
    }
  }, [accountId, currentBoardId, setStoredBoardId, storedBoardId]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === ('invalidateDPSpecializationsTags' as LocalStorageKey)) {
        dispatch(delegationPlannerApi.util.invalidateTags(['ResponsibilitySpecialization']));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);
};
