import styled from 'styled-components';

import Icon from '../../../../../../design_system/display/icons/Icon';
import { fontMd1, fontSm5 } from '../../../../../../styled/TypeSystem';

export const ElementSelectionFieldsWrapper = styled.div`
  width: 100%;
`;

export const SelectionFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: ${({ theme: { constants } }) => constants.heightSm};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  &:last-child {
    padding-bottom: 0;
  }
`;

export const StyledInput = styled.input`
  opacity: 0;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  cursor: pointer;
`;

export const SelectionField = styled.div`
  height: inherit;
  width: auto;
  position: relative;
  display: flex;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  width: 100%;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin-bottom: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  cursor: pointer;

  ${fontSm5};
`;

export const ChoiceWrapper = styled.div<{ isChecked: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  min-height: ${({ theme: { constants } }) => constants.heightLg};
  border: ${({ isChecked, theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${isChecked ? vars.stateHoverInfo : vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ isChecked, theme: { vars } }) =>
    isChecked ? vars.stateBackgroundInfo : vars.foundationBase1};

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.stateBackgroundInfo};
    border-color: ${({ theme: { vars } }) => vars.stateHoverInfo};
  }
`;

export const ChoiceTextWrapper = styled.div`
  width: 100%;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};

  ${fontMd1};
`;

export const ChoiceLetter = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  min-width: 1.5rem;
  height: ${({ theme: { constants } }) => constants.heightXs};
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerSm3}`};
  background-color: ${({ isChecked, theme: { vars } }) =>
    isChecked ? vars.stateInfo : vars.foundationSurface1};
  color: ${({ isChecked, theme: { vars } }) => (isChecked ? vars.textSurface : vars.textDefault)};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ isChecked, theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${isChecked ? vars.stateInfo : vars.borderSurface2}`};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd1};
`;

export const StyledIcon = styled(Icon)<{ isChecked: boolean }>`
  color: ${({ isChecked, theme: { vars } }) => (isChecked ? vars.stateInfo : vars.textDefault)};
`;
