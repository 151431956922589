import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useToggleSsoOnlyAccountMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { SsoOnlyAccountSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const SsoOnlyAccountSection = ({ id, ssoOnlyAccount }: SsoOnlyAccountSectionProps) => {
  const [toggleSsoOnlyAccount, result] = useToggleSsoOnlyAccountMutation();
  const { isLoading, error } = result;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
    errorMessage: messageFromError(error)?.join(', '),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('sso_only_account_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={ssoOnlyAccount}
            className='sso-only-account-setting'
            disabled={isLoading}
            handleToggle={() => toggleSsoOnlyAccount()}
            id='sso-only-account-setting'
            name='sso_only_account'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('sso_only_account_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default SsoOnlyAccountSection;
