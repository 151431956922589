import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import titleize from '../../../../../lib/titleize';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { CurriculumElementTrackable, Owner } from '../../activities/types';
import NotificationUserName from '../NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';

const t = initTranslations('notifications');

export type CourseStatusChangedProps = {
  curriculumHref: string;
  activityKey: string;
  trackable: CurriculumElementTrackable;
  parameters: { status_was: string; status_now: string };
  owner: Owner;
};

const StatusChanged = ({
  activityKey,
  curriculumHref,
  owner,
  parameters,
  trackable,
}: CourseStatusChangedProps) => {
  return (
    <>
      <NotificationUserName user={owner} />
      <TextFragment>{t(activityKey)}</TextFragment>
      <SourceBadge
        sourceHref={curriculumHref}
        sourceName='curriculum'
        sourceText={trackable.name}
      />
      <TextFragment>
        <span
          dangerouslySetInnerHTML={{
            __html: t('course.status_changes', {
              was: titleize(parameters.status_was),
              now: titleize(parameters.status_now),
            }),
          }}
        />
      </TextFragment>
    </>
  );
};

export default StatusChanged;
