import initTranslations from '../../../../lib/initTranslations';
import { GroupByMenuOption } from '../../../../types/GroupByMenuOption';

const t = initTranslations('sort_groups_dropdown');

export const VIEW_BY_GROUP_OPTIONS: GroupByMenuOption[] = [
  {
    label: t('all_employees'),
    sortColumn: 'name',
    sortDirection: 'asc',
    groupBy: 'all_employees',
    viewByLabel: t('view_by'),
  },
  {
    label: t('all_groups'),
    sortColumn: 'name',
    sortDirection: 'desc',
    groupBy: 'all_groups',
    viewByLabel: t('view_by'),
  },
  {
    label: t('roles'),
    sortColumn: 'roles',
    sortDirection: 'asc',
    groupBy: 'role',
    viewByLabel: t('view_by_all'),
  },
  {
    label: t('teams'),
    sortColumn: 'teams',
    sortDirection: 'asc',
    groupBy: 'team',
    viewByLabel: t('view_by_all'),
  },
  {
    label: t('departments'),
    sortColumn: 'departments',
    sortDirection: 'asc',
    groupBy: 'department',
    viewByLabel: t('view_by_all'),
  },
  {
    label: t('locations'),
    sortColumn: 'locations',
    sortDirection: 'asc',
    groupBy: 'location',
    viewByLabel: t('view_by_all'),
  },
  {
    label: t('other'),
    sortColumn: 'other',
    sortDirection: 'asc',
    groupBy: 'other',
    viewByLabel: t('view_by_all'),
  },
];
