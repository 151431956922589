import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateFormattedAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import RestrictGroupsSelect from '../RestrictGroupsSelect';
import { FormSectionContainer, FormSectionDescription, FormSectionTitle } from '../styles';
import { DirectoryGroupsFormProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const DirectoryGroupsForm = ({
  accountGroups,
  groupsRestrictedFromDirectory,
}: DirectoryGroupsFormProps) => {
  const [updateAccountData, result] = useUpdateFormattedAccountDataMutation();
  const { isLoading } = result;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <>
      <FormSectionContainer>
        <FormSectionTitle>{t('groups_restricted_from_directory.title')}</FormSectionTitle>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>
          {t('groups_restricted_from_directory.description')}
        </FormSectionDescription>
      </FormSectionContainer>
      <RestrictGroupsSelect
        accountGroups={accountGroups}
        elementClassName='directory-groups-select'
        elementId='save-directory-groups'
        featureType='directory'
        isLoading={isLoading}
        restrictedGroups={groupsRestrictedFromDirectory}
        updateAccountAction={updateAccountData}
      />
    </>
  );
};

export default DirectoryGroupsForm;
