import styled from 'styled-components';

import { fontSm5 } from '../../../styled/TypeSystem';

export const CheckOptionLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerMd2}`};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  color: ${({ theme: { vars } }) => vars.textDefault};
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  }
`;

export const CheckOptionTitle = styled.span`
  ${fontSm5};
`;
