import React from 'react';
import styled from 'styled-components';

import { fontSm5 } from '../../../../../../../styled/TypeSystem';
import { StatusIcon } from '../../../../../../editor/components/StatusDropdown/StatusDropdown';
import { OptionType } from '../../../../../../shared/ActionDropdown/ActionDropdownMenuOption/ActionDropdownMenuOption';

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24rem;
`;

const Description = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd3};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
`;

const Title = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  width: 100%;
`;

type PublishOptionProps = {
  title: string;
  description: string;
  badgeType: OptionType.Finished | OptionType.PendingReview;
};

const PublishOption = ({ title, description, badgeType }: PublishOptionProps) => {
  return (
    <OptionWrapper className='publish-option'>
      <TitleWrapper>
        <StatusIcon name='circle' size='xs' status={badgeType} weight='solid' />
        <Title>{title}</Title>
      </TitleWrapper>
      <Description>{description}</Description>
    </OptionWrapper>
  );
};

export default PublishOption;
