import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import LibraryEmptyStateColored from '../../../../../../../assets/images/empty_state/library-empty-state-colored.svg';
import { useGroup } from '../../../../../contexts/GroupContext';
import { PaywallContext } from '../../../../../contexts/PaywallContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Badge from '../../../../design_system/display/badge';
import FormattedDate from '../../../../design_system/display/date/FormattedDate';
import NoResults from '../../../../design_system/Triage/NoResults';
import CompletionDetails from '../../../people/Profile/SubjectsAssigned/CompletionDetails';
import { routes } from '../../../publicApplication/applicationRouter';
import DetailedTitle from '../../../shared/DetailedTitle/DetailedTitle';
import { Title } from '../../../shared/DetailedTitle/Title';
import {
  ActionMenuWrapper,
  DataCell,
  DataRow,
  HeaderCell,
  HeaderRow,
  LargeDataCell,
  LargeHeaderCell,
  MediumDataCell,
  MediumHeaderCell,
  SmallDataCell,
  SmallHeaderCell,
  StyledTable,
  TableAndActionsWrapper,
  TableNoResultsWrapper,
  TableWrapper,
} from '../../shared/table_styles';
import ActionMenu from './ActionMenu';
import ManageContentModal from './ManageContentModal/ManageContentModal';

const t = initTranslations('groups.groups_edit.content.table');

const TableManageButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContentTable = () => {
  const group = useGroup();
  const { curriculums: subjects } = group;
  const { isMobile } = useWindowResize();
  const hasSubjects = !!subjects.length;
  const {
    slug,
    splitFeatures: { groupsCompletionsEnabled },
  } = useCurrentAccount();
  const paywallCtx = useContext(PaywallContext);
  const completionsPaywalled = paywallCtx.includes('completions');
  const showCompletions = groupsCompletionsEnabled && !completionsPaywalled;
  const ContentHeader = isMobile ? LargeHeaderCell : HeaderCell;
  const ContentData = isMobile ? LargeDataCell : DataCell;
  const [isManagingGroupContent, setIsManagingGroupContent] = useState(false);

  return (
    <TableAndActionsWrapper id='groups-content-table'>
      {isManagingGroupContent && (
        <ManageContentModal closeModal={() => setIsManagingGroupContent(false)} />
      )}
      <TableManageButtonWrapper>
        <DefaultButton
          id='groups-content-table-manage-button'
          onClick={() => setIsManagingGroupContent(true)}
          text={t('manage_group_content_modal.manage')}
        />
      </TableManageButtonWrapper>
      <TableWrapper>
        <StyledTable>
          <thead>
            <HeaderRow>
              <ContentHeader>
                <Title fontSize='sm4' fontWeight='semibold' title={t('header_titles.content')} />
              </ContentHeader>
              <MediumHeaderCell>
                <Title fontSize='sm4' fontWeight='semibold' title={t('header_titles.status')} />
              </MediumHeaderCell>
              {showCompletions && (
                <LargeHeaderCell>
                  <Title
                    fontSize='sm4'
                    fontWeight='semibold'
                    title={t('header_titles.completion')}
                  />
                </LargeHeaderCell>
              )}
              <MediumHeaderCell>
                <Title fontSize='sm4' fontWeight='semibold' title={t('header_titles.updated')} />
              </MediumHeaderCell>
              <SmallHeaderCell>
                <Title fontSize='sm4' fontWeight='semibold' title='' />
              </SmallHeaderCell>
            </HeaderRow>
          </thead>
          <tbody>
            {hasSubjects ? (
              subjects.map((subject) => {
                return (
                  <DataRow
                    id={`groups-content-table-subject-${subject.id}`}
                    key={`groups-content-table-subject-${subject.id}`}
                  >
                    <ContentData>
                      <DetailedTitle
                        emoji={subject.emoji}
                        fontSize='sm5'
                        fontWeight='regular'
                        id={`groups-content-table-view-subject-${subject.id}`}
                        route={routes.curriculumShow({ slug, id: subject.id }).href}
                        title={subject.title}
                        truncate='oneLine'
                      />
                    </ContentData>
                    <MediumDataCell>
                      <Badge
                        text={t(`badges.${subject.published ? '' : 'un'}published`)}
                        type={subject.published ? 'success' : 'caution'}
                      />
                    </MediumDataCell>
                    {showCompletions && (
                      <LargeDataCell>
                        {subject.reference && <Badge text={t('badges.reference')} type='general' />}
                        {!subject.reference && (
                          <CompletionDetails
                            className='subject-completion-percentage'
                            percent={subject.total_score}
                            to={routes.curriculumByUserReport({ slug, id: subject.id })}
                          />
                        )}
                      </LargeDataCell>
                    )}
                    <MediumDataCell>
                      <FormattedDate date={subject.updated_at} />
                    </MediumDataCell>
                    <SmallDataCell>
                      <ActionMenuWrapper>
                        <ActionMenu subject={subject} />
                      </ActionMenuWrapper>
                    </SmallDataCell>
                  </DataRow>
                );
              })
            ) : (
              <DataRow>
                <td colSpan={showCompletions ? 5 : 4}>
                  <TableNoResultsWrapper>
                    <NoResults
                      className='groups-content-table-no-results'
                      darkImage={LibraryEmptyStateColored}
                      heading={t('empty_state.header')}
                      iconWidth='16%'
                      lightImage={LibraryEmptyStateColored}
                      minHeight='unset'
                      showBorder={false}
                      subHeaderCta={{
                        action: () => setIsManagingGroupContent(true),
                        text: t('empty_state.action_link'),
                      }}
                    />
                  </TableNoResultsWrapper>
                </td>
              </DataRow>
            )}
          </tbody>
        </StyledTable>
      </TableWrapper>
    </TableAndActionsWrapper>
  );
};

export default ContentTable;
