import styled, { css } from 'styled-components';

import { fontSm5 } from '../../../styled/TypeSystem';
import { FULLSCREEN_TRANSFORMS, POSITIONS } from './_data';
import { getPositionValue } from './utils';

//TODO: Replace it by existing Tooltip component when bug with focus will be fixed
export const SyntheticPopup = styled.div`
  ${({ theme: { constants, vars } }) => css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-${constants.spacerMd1} - 100%));

    padding: ${constants.spacerSm3};

    background: ${vars.foundationSurface2};
    border-radius: ${constants.borderRadiusMd};
    pointer-events: none;
    opacity: 0;
    z-index: 3;
    transition: 0.3s ease-out;

    &::before {
      content: '';

      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);

      border: ${constants.spacerSm3} solid transparent;
      border-top-color: ${vars.foundationSurface2};
      border-bottom: 0;
    }
  `};
`;

export const Wrapper = styled.div`
  ${({ theme: { constants } }) => css`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: ${constants.spacerMd2};
    height: ${constants.spacerMd2};

    &:hover > ${SyntheticPopup} {
      opacity: 1;
    }
  `};
`;

export const SquareButton = styled.button<{ isFullscreenModeEnabled: boolean }>`
  ${({ isFullscreenModeEnabled, theme: { constants, vars } }) => css`
    position: relative;

    width: ${constants.spacerMd2};
    height: ${constants.spacerMd2};

    outline: none;
    background-color: transparent;
    border: none;

    cursor: pointer;

    ${isFullscreenModeEnabled &&
    css`
      transform: rotate(90deg);
      transition: 1s ease-in-out;
    `}

    & > span {
      position: absolute;
      width: ${constants.spacerSm1};
      height: 6px;
      background-color: ${vars.textDefault};
      pointer-events: none;

      ${isFullscreenModeEnabled &&
      css`
        transition: 1s ease-in-out;
      `}

      ${POSITIONS.map(
        (pos, index) => css`
          &:nth-of-type(${index + 1}) {
            inset: ${getPositionValue(pos.top)} ${getPositionValue(pos.right)}
              ${getPositionValue(pos.bottom)} ${getPositionValue(pos.left)};
            transform: ${pos.transform};

            ${isFullscreenModeEnabled &&
            css`
              transform: ${FULLSCREEN_TRANSFORMS[index]};
            `}
          }
        `
      )}
    }
  `};
`;

export const PopupText = styled.p`
  color: ${({ theme: { vars } }) => vars.textSurface};
  white-space: nowrap;
  ${fontSm5};
`;
