import { Formik, FormikErrors } from 'formik';
import React from 'react';

import { useOnboarding } from '../../../../../contexts/OnboardingContext';
import initTranslations from '../../../../../lib/initTranslations';
import CustomizeYourExperienceStepForm from './CustomizeYourExperienceStepForm';
import { FormWrapper } from './styles';
import { CustomizeYourExperienceStepData } from './types';

const t = initTranslations('home.onboarding.customize_your_experience_step');

const CustomizeYourExperienceStep = () => {
  const { formData, setFormData, sliderRef } = useOnboarding();

  const initialValues = {
    name: formData.name,
    user_title: formData.user_title,
    industry: formData.industry,
    company_size: formData.company_size,
  };

  const onSubmit = (values: CustomizeYourExperienceStepData) => {
    setFormData({ ...formData, ...values });
    sliderRef?.current?.slickNext();
  };

  const validate = ({ name, user_title, industry }: CustomizeYourExperienceStepData) => {
    const errors: FormikErrors<CustomizeYourExperienceStepData> = {};
    if (!name) errors.name = t('errors.company_name_required');
    if (!user_title) errors.user_title = t('errors.job_title_required');
    if (!industry) errors.industry = t('errors.industry_required');

    return errors;
  };

  return (
    <FormWrapper>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
        <CustomizeYourExperienceStepForm />
      </Formik>
    </FormWrapper>
  );
};

export default CustomizeYourExperienceStep;
