import { routes } from '../../../../publicApplication/applicationRouter';
import { TypeName } from '../../../../shared/ContentFlyout/types';
import { RouteArgs, RouteFunction } from './types';

const originLocation = window.location.origin;

const makeUrl = ({ route, id, slug }: { route: RouteFunction; id: number; slug: string }): string =>
  `${originLocation}${route({ id, slug }).href}`;

export const CONTENTABLE_URLS = ({ id, slug }: RouteArgs): Record<TypeName, string> => {
  const urlConfig: Record<TypeName, RouteFunction> = {
    [TypeName.Curriculum]: routes.curriculumShow,
    [TypeName.Course]: routes.courseConsume,
    [TypeName.Step]: routes.consume,
  };

  return Object.entries(urlConfig).reduce((acc, [typeName, route]) => {
    acc[typeName as TypeName] = makeUrl({ route, id, slug });
    return acc;
  }, {} as Record<TypeName, string>);
};
