import React, { FC } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { userFirstName } from '../../../../../../../lib/userNaming';
import { useGetSpecializationsDataQuery } from '../../../../../../../redux/services/resourceApis/delegationPlanner/delegationPlannerApi';
import SpecializationsThreeDot from '../../SpecializationsThreeDot/SpecializationsThreeDot';
import { AssignedResponsibilitySpecialization } from '../../types';
import Specialization from '../Specialization/Specialization';
import {
  AddSpecializationsHeader,
  AddSpecializationsRoot,
  SpecializationsList,
  Title,
} from './styles';
import { AddSpecializationsProps } from './types';

const t = initTranslations('delegation_planner.specializations');

const AddSpecializations: FC<AddSpecializationsProps> = ({
  isGroup,
  responsibiliableName = '',
  responsibilityId,
  responsibilitySpecializations,
}) => {
  const { data: globalSpecializations } = useGetSpecializationsDataQuery(undefined);

  const mergedData: AssignedResponsibilitySpecialization[] =
    globalSpecializations?.map((specialization) => {
      const targetSpecialization = responsibilitySpecializations?.find(
        (item) => item.responsibilitySpecializationId === specialization.id
      );

      return {
        responsibilitySpecializationId: specialization.id,
        color: specialization.color,
        terminology: specialization.terminology,
        __typename: specialization.terminology,
        ...(targetSpecialization && {
          specializationScore: targetSpecialization.specializationScore,
          checked: true,
          id: targetSpecialization.id,
        }),
      };
    }) ?? [];

  const name = userFirstName(responsibiliableName);

  const title = isGroup ? t('group_title') : t('named_title', { name });

  return (
    <AddSpecializationsRoot>
      <AddSpecializationsHeader>
        <Title>{title}</Title>

        <SpecializationsThreeDot responsibilityId={responsibilityId} />
      </AddSpecializationsHeader>

      <SpecializationsList>
        {mergedData.map((specialization) => (
          <Specialization key={specialization.responsibilitySpecializationId} {...specialization} />
        ))}
      </SpecializationsList>
    </AddSpecializationsRoot>
  );
};

export default AddSpecializations;
