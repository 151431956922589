import { SkeletonCard, SkeletonTextWidths } from './types';

export const GENERATED_COLUMNS_ARRAY = () => Array.from({ length: 3 });

export const SKELETON_CARDS: SkeletonCard[] = [
  {
    textTitles: [SkeletonTextWidths.Big, SkeletonTextWidths.Medium],
  },
  {
    textTitles: [SkeletonTextWidths.Bigger, SkeletonTextWidths.Big],
  },
  {
    textTitles: [SkeletonTextWidths.Biggest],
  },
  {
    textTitles: [SkeletonTextWidths.Big, SkeletonTextWidths.Medium],
  },
  {
    textTitles: [SkeletonTextWidths.Bigger, SkeletonTextWidths.Big],
  },
];
