import { isEqual } from 'lodash';
import React, { useReducer } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateFormattedAccountDataMutation } from '../../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import { formatOptions } from '../../../../../design_system/core/CoreSelectField/CoreSelectField';
import P from '../../../../../design_system/text/P';
import MultiSelectField from '../../../../../design_system/Triage/fields/MultiSelectField';
import { routes } from '../../../../publicApplication/applicationRouter';
import { ButtonRightPositionWrapper } from '../../../OrganizationProfile/styles';
import { InputRow } from '../../styles';
import { PermissionsReducer } from './reducer';
import { BillingDetails, InfoTitle, SignatureInfoBlock, StyledLink } from './styles';
import { SignaturePermissionsFormProps, SignaturePermissionsFormState } from './types';

const t = initTranslations('account_settings.advanced_settings.signature_permissions_form');

const SignaturePermissionsForm = ({
  userPermissions,
  signaturePermissions,
  accountPlanName,
}: SignaturePermissionsFormProps) => {
  const initialState: SignaturePermissionsFormState = {
    selectedPermissions: signaturePermissions,
  };
  const { stripePlanId, slug, onOneOfAnnualUnlimitedPlans, onCustomPlan, hasESignatureFeature } =
    useCurrentAccount();
  const [localState, dispatchState] = useReducer(PermissionsReducer, initialState);
  const { selectedPermissions } = localState;
  const unlimitedCompanyPlan = stripePlanId == 'unlimited_comp';
  const showYearlyESignature = onOneOfAnnualUnlimitedPlans || onCustomPlan;

  const isValuesUnchanged = isEqual(signaturePermissions, selectedPermissions);

  const [updateAccountData, result] = useUpdateFormattedAccountDataMutation();
  const { isLoading } = result;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  const userPermissionOptions = userPermissions.map((permission) => ({
    ...permission,
    isFixed: permission.value === 'billing_admin',
  }));

  return (
    <div id='e-signatures-form'>
      <InputRow>
        {!hasESignatureFeature && (
          <SignatureInfoBlock>
            <InfoTitle>{t('plan_name', { name: accountPlanName || 'Scale' })}</InfoTitle>
            <P
              text={
                showYearlyESignature
                  ? t('billing_info_annual')
                  : unlimitedCompanyPlan
                  ? t('unlimited_info')
                  : t('billing_info_monthly')
              }
            />
            {!unlimitedCompanyPlan && (
              <BillingDetails>
                <span>{t('see')}</span>
                <StyledLink href={routes.billing({ slug }).href} text={t('billing_page')} />
                <span>{t('more_details')}</span>
              </BillingDetails>
            )}
          </SignatureInfoBlock>
        )}
        <MultiSelectField
          className='signature_permissions_select'
          defaultValue={[]}
          fieldLabelText={t('label')}
          onNonDefaultSelected={(value: string[]) => {
            dispatchState({
              type: 'changeSelectedPermissions',
              selectedPermissions: value,
            });
          }}
          options={formatOptions(userPermissionOptions)}
          value={selectedPermissions}
        />
        <AssistiveText
          id='signature-permissions-assistive-text-help'
          text={t('assistive_text')}
          type='help'
        />
      </InputRow>
      <ButtonRightPositionWrapper>
        <DefaultButton
          disabled={isValuesUnchanged}
          id='save-signature-permissions'
          loading={isLoading}
          onClick={() => updateAccountData({ signature_permissions: selectedPermissions })}
          text={t('save')}
        />
      </ButtonRightPositionWrapper>
    </div>
  );
};

export default SignaturePermissionsForm;
