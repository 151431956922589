import React from 'react';

import { useGetElementColumns } from '../../../../../hooks/element_report/useGetElementColumns';
import { BodyRow } from '../../../people/UsersOutline/Table/Styles';
import { StyledColumn } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import TableColumn from './Columns/TableColumn';
import { TableRowProps } from './types';

const TableRow = ({ curriculum_element, signatureEnabled }: TableRowProps) => {
  const { id, element_type } = curriculum_element;
  const columns = useGetElementColumns(signatureEnabled);

  return (
    <>
      <BodyRow elementType={element_type} id={`element-report-tr-${id}`}>
        {columns.map(({ columnName, contentAlignment }) => (
          <StyledColumn
            contentAlignment={contentAlignment}
            id={`element-report-column-${id}-${columnName}`}
            key={`element-report-column-${id}-${columnName}`}
          >
            <TableColumn columnName={columnName} record={curriculum_element} />
          </StyledColumn>
        ))}
      </BodyRow>
    </>
  );
};

export default TableRow;
