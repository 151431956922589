import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { TopicHeaderContainer } from './styles';

const Button = styled.button`
  display: flex;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerSm3}`};
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  ${TopicHeaderContainer}:hover & {
    opacity: 1;
  }
`;

type Props = {
  onClick?: () => void;
  text: string;
  iconName: IconName;
};

const ActionToggleButton = ({ onClick, text, iconName }: Props) => {
  return (
    <Button onClick={onClick}>
      <span>{text}</span>
      <Icon name={iconName} />
    </Button>
  );
};

export default ActionToggleButton;
