import React from 'react';

import {
  TrainualPlusHeaderDescription,
  TrainualPlusHeaderTitle,
  TrainualPlusHeaderWrapper,
} from './styles';

type TrainualPlusHeaderProps = {
  title: string;
  description: string;
  primaryColor: string;
  secondaryColor: string;
};

const TrainualPlusHeader = ({
  title,
  description,
  secondaryColor,
  primaryColor,
}: TrainualPlusHeaderProps) => {
  return (
    <TrainualPlusHeaderWrapper id='addon-trainual-plus-overlay-header'>
      <TrainualPlusHeaderTitle
        dangerouslySetInnerHTML={{ __html: title }}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
      <TrainualPlusHeaderDescription text={description} />
    </TrainualPlusHeaderWrapper>
  );
};

export default TrainualPlusHeader;
