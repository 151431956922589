import React, { FC } from 'react';

import useKeyPress from '../../../../../hooks/useKeyPress';
import Portal from '../../../Portal';
import {
  CloseButtonContainer,
  CloseIconButton,
  OverlayContainer,
  VideoPlayerContent,
  VideoPlayerIframe,
} from './styles';
import { TLightBoxVideoPlayer } from './types';

const LightboxVideoPlayer: FC<TLightBoxVideoPlayer> = ({ videoUrl, onCloseRequest }) => {
  useKeyPress('Escape', onCloseRequest);

  return (
    <Portal>
      <OverlayContainer>
        <VideoPlayerContent>
          <CloseButtonContainer>
            <CloseIconButton ariaLabel='Close video player' name='times' onClick={onCloseRequest} />
          </CloseButtonContainer>
          <VideoPlayerIframe
            allow='autoplay; fullscreen'
            allowFullScreen
            id='video-empty-state-lightbox-player'
            src={`${videoUrl}&autoplay=true&pip=false`}
          />
        </VideoPlayerContent>
      </OverlayContainer>
    </Portal>
  );
};

export default LightboxVideoPlayer;
