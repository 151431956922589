import { DragEndEvent } from '@dnd-kit/core';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import GroupManagersEmptyStateSvg from '../../../../../images/empty_state/group-managers-empty-state-colored.svg';
import { relativeTimeFormat } from '../../../../../lib/dateUtils';
import initTranslations from '../../../../../lib/initTranslations';
import { openCurriculumModal } from '../../../../../redux/domains/curriculumModal/curriculumModalSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useUpdateCurriculumTrainingOrderMutation } from '../../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import { TrainingOrderCurriculum } from '../../../../../types/Curriculum';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination, {
  Props as PaginationProps,
} from '../../../../design_system/Triage/Paginate/Pagination';
import { fontSm5 } from '../../../../styled/TypeSystem';
import DragAndDropListWrapper from '../../../shared/DragAndDrop/DragAndDropListWrapper';
import DraggableListItemWrapper from '../../../shared/DragAndDrop/DraggableListItemWrapper';
import SubjectOrderRow from '../SubjectOrderRow/SubjectOrderRow';

const t = initTranslations('curriculums.content.set_order');

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const SubjectsCount = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm5};
`;

const UpdatedTimestamp = styled.span`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5};
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const PaginationWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export type Props = {
  curriculums: TrainingOrderCurriculum[];
  pagination: PaginationProps;
  updatedAt: number | null;
};

const SubjectOrderTable = ({ curriculums, pagination, updatedAt }: Props) => {
  const { activePage, itemsCountPerPage, totalItemsCount } = pagination;
  const {
    curriculum: { singular: subjectTermSingular, plural: subjectTermPlural },
  } = useAccountTerminology();
  const dispatch = useAppDispatch();

  const [updatePosition, updatePositionResult] = useUpdateCurriculumTrainingOrderMutation();
  const { error: updateError, isLoading: isUpdatePositionLoading } = updatePositionResult;

  const errorMessage =
    updateError && (updateError as FetchBaseQueryError).status === 'PARSING_ERROR'
      ? t('error.log_out')
      : t('error.generic');
  useDisplayFlashOnResponse({
    result: updatePositionResult,
    errorMessage,
  });

  const handleDragEnd = ({ over, active }: DragEndEvent): void => {
    if (over === null || over.id === active.id) {
      return;
    }

    const overIndex = curriculums.findIndex((curriculum) => curriculum.id === over.id);
    updatePosition({ id: Number(active.id), position: overIndex, page: activePage });
  };

  const positionOptions = useMemo(() => {
    return [...Array(totalItemsCount)].map((_, index) => {
      const positionLabel = (index + 1).toString();
      return { value: index.toString(), label: positionLabel, searchableTerm: positionLabel };
    });
  }, [totalItemsCount]);

  if (curriculums.length === 0) {
    return (
      <NoResults
        darkImage={GroupManagersEmptyStateSvg}
        heading={t('empty_state.heading', { subjects: subjectTermPlural.toLowerCase() })}
        iconWidth='20%'
        lightImage={GroupManagersEmptyStateSvg}
        minHeight='unset'
        showBackground={false}
        showBorder={false}
        subHeaderCta={{
          action: () => dispatch(openCurriculumModal()),
          text: t('empty_state.action_link_text'),
        }}
        subHeaderText={t('empty_state.action_text')}
      />
    );
  }

  const positionOffset = (activePage - 1) * itemsCountPerPage;

  return (
    <>
      <TableHeader>
        <SubjectsCount>
          {t('subjects_count', {
            count: totalItemsCount,
            subject: subjectTermSingular.toLowerCase(),
            subjects: subjectTermPlural.toLowerCase(),
          })}
        </SubjectsCount>
        {isUpdatePositionLoading ? (
          <UpdatedTimestamp>{t('updating')}</UpdatedTimestamp>
        ) : (
          updatedAt && (
            <UpdatedTimestamp>
              {t('content_updated_at', { date: relativeTimeFormat(updatedAt) })}
            </UpdatedTimestamp>
          )
        )}
      </TableHeader>
      <DragAndDropListWrapper
        handleDragEnd={handleDragEnd}
        itemHeight='4.75rem'
        items={curriculums}
      >
        <RowContainer>
          {curriculums.map((curriculum, index) => {
            const { id, emoji, title, sector, groupsCount, owner } = curriculum;

            return (
              <DraggableListItemWrapper id={id} key={id}>
                <SubjectOrderRow
                  emoji={emoji}
                  groupsCount={groupsCount}
                  id={id}
                  owner={owner}
                  position={positionOffset + index}
                  positionOptions={positionOptions}
                  sector={sector}
                  title={title}
                  updatePosition={(position: number) =>
                    updatePosition({ id, position, page: activePage })
                  }
                />
              </DraggableListItemWrapper>
            );
          })}
        </RowContainer>
      </DragAndDropListWrapper>
      <PaginationWrapper>
        <Pagination {...pagination} showPaginationDetails />
      </PaginationWrapper>
    </>
  );
};

export default SubjectOrderTable;
