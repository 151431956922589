import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { EmbedlyProvider } from '../../../../contexts/EmbedlyProvider';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import TaskModal from '../../../design_system/overlays/modals/TaskModal';
import { CheckBanner, CheckBannerProps } from '../MarketingSidebar/MarketingSidebar';
import ExternalLinkModalBody from './ExternalLinkModalBody';
import UploadModalBody from './UploadModalBody';

const ChipButtonsWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    gap: ${constants.spacerMd1};
  `
);

// DS Override: Update button styles to match designs - update border color, text color, and background color
const StyledDefaultButton = styled(DefaultButton)<{ $selected: boolean }>(
  ({ theme: { constants, vars }, $selected }) => css`
    width: 100%;
    height: 3.5rem;
    border: ${$selected
      ? `${constants.borderWidthSm} solid ${vars.accentPrimaryDefault}`
      : `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
    border-radius: ${constants.borderRadiusLg};
    color: ${$selected ? vars.accentPrimaryDefault : vars.textDefault};
    background-color: ${$selected ? vars.accentSubdued1 : vars.foundationSurface1};

    &:hover,
    &:focus {
      color: ${$selected ? vars.accentPrimaryDefault : vars.textDefault};
      border: ${constants.borderWidthSm} solid ${vars.accentPrimaryDefault};
    }
  `
);

const CheckBannerWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd2};
    padding-bottom: ${constants.spacerMd3};
    align-items: flex-start;
  `
);

const CheckBannerRow = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    gap: ${constants.spacerMd1};
  `
);

const t = initTranslations('video.video_modal');

type CheckBannerSectionProps = {
  checkBannerOptions: Array<CheckBannerProps>;
};

export const VideoModalCheckBannerSection = ({ checkBannerOptions }: CheckBannerSectionProps) => {
  return (
    <CheckBannerWrapper>
      <CheckBannerRow>
        <CheckBanner {...checkBannerOptions[0]} />
        <CheckBanner {...checkBannerOptions[1]} />
      </CheckBannerRow>
      <CheckBannerRow>
        <CheckBanner {...checkBannerOptions[2]} />
        <CheckBanner {...checkBannerOptions[3]} />
      </CheckBannerRow>
    </CheckBannerWrapper>
  );
};

type SelectedView = 'upload' | 'external-link';

export type Props = {
  onClose: () => void;
  onCreateVideo: () => void;
};

const VideoModal = ({ onClose, onCreateVideo }: Props) => {
  const [selectedOption, setSelectedOption] = useState<SelectedView>('upload');
  const theme = useTheme();
  const {
    splitFeatures: { hostedVideoEnabled },
  } = useCurrentAccount();

  const VideoModalBodyComponent = {
    upload: <UploadModalBody hostedVideoEnabled={hostedVideoEnabled} />,
    'external-link': (
      <EmbedlyProvider>
        <ExternalLinkModalBody />
      </EmbedlyProvider>
    ),
  }[selectedOption];

  const buttonText =
    selectedOption === 'upload'
      ? hostedVideoEnabled
        ? t('upload_video')
        : t('join_beta')
      : t('insert_external_link');

  const buttonAction = () => {
    if (selectedOption === 'upload' && !hostedVideoEnabled) {
      // TODO: update this once we add the beta form iframe
      console.log('join beta');
    } else {
      onCreateVideo();
    }
  };

  return (
    <TaskModal
      desktopSize='xl'
      heapModalName='video-modal'
      onCloseRequest={onClose}
      primaryButtonTask={buttonAction}
      primaryButtonText={buttonText}
      processing={false}
      title={t('title')}
    >
      <ChipButtonsWrapper>
        <StyledDefaultButton
          $selected={selectedOption === 'upload'}
          buttonType='secondary'
          iconColor={
            selectedOption === 'upload' ? theme.vars.accentPrimaryDefault : theme.vars.textDefault
          }
          iconName='upload'
          id='video-modal-upload'
          onClick={() => {
            setSelectedOption('upload');
          }}
          text={t('upload_chip_label')}
        />
        <StyledDefaultButton
          $selected={selectedOption === 'external-link'}
          buttonType='secondary'
          iconColor={
            selectedOption === 'external-link'
              ? theme.vars.accentPrimaryDefault
              : theme.vars.textDefault
          }
          iconName='link'
          id='video-modal-external-link'
          onClick={() => {
            setSelectedOption('external-link');
          }}
          text={t('external_link_chip_label')}
        />
      </ChipButtonsWrapper>
      {VideoModalBodyComponent}
    </TaskModal>
  );
};

export default VideoModal;
