import React, { useEffect } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import {
  useDeleteGoogleIntegrationMutation,
  useDeleteMicrosoftIntegrationMutation,
  useDeleteOktaIntegrationMutation,
} from '../../../../redux/services/resourceApis/integrations/integrationsApi';
import ConfirmationModal from '../../../design_system/overlays/modals/ConfirmationModal';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { IntegrationModalProps } from './index';

const t = initTranslations('disconnect_sso_integration_modal');

const DisconnectIntegrationModal = ({ showModal, closeModal, provider }: IntegrationModalProps) => {
  const { flash } = useFlashNotification();

  const [deleteOktaIntegration, resultDeleteOktaIntegration] = useDeleteOktaIntegrationMutation();
  const {
    isSuccess: isSuccessOkta,
    isLoading: isLoadingOkta,
    isError: isErrorOkta,
    error: errorOkta,
  } = resultDeleteOktaIntegration;

  const [deleteGoogleIntegration, resultDeleteGoogleIntegration] =
    useDeleteGoogleIntegrationMutation();
  const {
    isSuccess: isSuccessGoogle,
    isLoading: isLoadingGoogle,
    isError: isErrorGoogle,
    error: errorGoogle,
  } = resultDeleteGoogleIntegration;

  const [deleteMicrosoftIntegration, resultDeleteMicrosoftIntegration] =
    useDeleteMicrosoftIntegrationMutation();
  const {
    isSuccess: isSuccessMicrosoft,
    isLoading: isLoadingMicrosoft,
    isError: isErrorMicrosoft,
    error: errorMicrosoft,
  } = resultDeleteMicrosoftIntegration;

  const isSuccess = isSuccessOkta || isSuccessGoogle || isSuccessMicrosoft;
  const isLoading = isLoadingOkta || isLoadingGoogle || isLoadingMicrosoft;
  const isError = isErrorOkta || isErrorGoogle || isErrorMicrosoft;
  const error = errorOkta || errorGoogle || errorMicrosoft;

  const disconnectIntegration = () => {
    switch (provider) {
      case 'okta':
        return deleteOktaIntegration();
      case 'microsoft':
        return deleteMicrosoftIntegration();
      case 'google':
      default:
        return deleteGoogleIntegration();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      flash('info', t('integration_successfully_disconnected'), { autoClose: 5000 });
      closeModal();
    }
    if (isError) {
      flash('warn', messageFromError(error)?.join(', '), { autoClose: 5000 });
    }
    // We don't want to include closeModal in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flash, error, isError, isSuccess]);

  return (
    <>
      {showModal && (
        <ConfirmationModal
          actionFunction={() => {
            disconnectIntegration();
          }}
          actionText={t('disconnect')}
          heapModalName='disconnect-sso-modal'
          message={t('message')}
          onCloseRequest={closeModal}
          processing={isLoading}
          title={t('title')}
        />
      )}
    </>
  );
};

export default DisconnectIntegrationModal;
