export type CollapseState = {
  collapsed: boolean;
};

export type CollapseAction = {
  type: 'toggle-chevron' | 'expand' | 'collapse';
};

export const initialCollapsedState: CollapseState = {
  collapsed: false,
};

export const collapseReducer = (state: CollapseState, action: CollapseAction): CollapseState => {
  const { type } = action;

  switch (type) {
    case 'toggle-chevron':
      return { collapsed: !state.collapsed };
    case 'expand':
      return { collapsed: false };
    case 'collapse':
      return { collapsed: true };
  }
};
