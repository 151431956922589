import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { fontMd1, fontSm4 } from '../../../../../styled/TypeSystem';
import CelebrationAnimation from '../../../../home/Onboarding/task_driven/CelebrationAnimation/CelebrationAnimation';

const t = initTranslations('curriculums.ai_outliner.add_topic');

const StyledSuccessState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme: { constants } }) => constants.spacerLg2};
  text-align: center;
  border-top: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

const SuccessHeader = styled.p`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-block: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerSm2}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontMd1};
`;

const SuccessLabel = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  width: 11.75rem;
  margin-block: ${({ theme: { constants } }) => `0 ${constants.spacerMd3}`};
  ${fontSm4};
`;

const StyledDefaultButton = styled(DefaultButton)`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  border-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  &:hover {
    color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
    background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleSurfaceLight};
  }
`;

const AnimationWrapper = styled.div`
  height: 8.125rem;
`;

export type Props = {
  onClick: () => void;
};

const SuccessState = ({ onClick }: Props) => {
  const {
    topic: { singular: topicSingular },
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();
  return (
    <StyledSuccessState id='success-state-container'>
      <AnimationWrapper>
        <CelebrationAnimation />
      </AnimationWrapper>
      <SuccessHeader>{t('success_header')}</SuccessHeader>
      <SuccessLabel>
        {t('success_label', {
          topic: topicSingular.toLowerCase(),
          curriculum: curriculumSingular.toLowerCase(),
        })}
      </SuccessLabel>
      <StyledDefaultButton
        buttonType='secondary'
        id='smart-outline-success-state-button'
        onClick={onClick}
        text={t('success_button')}
      />
    </StyledSuccessState>
  );
};

export default SuccessState;
