import React from 'react';

import { usePlanBrandColors } from '../../../../../../../hooks/billing/usePlanBrandColors';
import capitalize from '../../../../../../../lib/capitalize';
import { BillingPlanName } from '../../../../../../../types/BillingPlanName';
import { StyledNewPlanCardTitle } from '../styles';

type Props = {
  planName: BillingPlanName;
  isSelected: boolean;
};

const NewPlanCardTitle = ({ planName, isSelected }: Props) => {
  const { color: planBrandColor } = usePlanBrandColors({ name: planName });

  return (
    <StyledNewPlanCardTitle
      isSelected={isSelected}
      planBrandColor={planBrandColor}
      text={capitalize(planName)}
    />
  );
};

export default NewPlanCardTitle;
