import { UpdatePaymentMethodParams } from '../../redux/services/resourceApis/billing/types';
import { CombinedBillingDetails } from '../../types/CombinedBillingDetails';

export const mapBillingDetailsToStripeParams = (
  billingDetails: CombinedBillingDetails
): UpdatePaymentMethodParams => {
  return {
    stripe_id: billingDetails.id,
    card_holder: billingDetails.name,
    address: {
      street_address: billingDetails.addressLine1,
      address_2: billingDetails.addressLine2,
      city: billingDetails.addressCity,
      state: billingDetails.addressState,
      postal_code: billingDetails.addressZip,
      country: billingDetails.addressCountry,
    },
  };
};
