import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledStatusBlockWrapper = styled.div<{ isSuccess: boolean }>`
  width: 100%;
  padding: 1.5rem 2rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  background-color: ${(props) =>
    props.isSuccess ? props.theme.vars.chartGreen1 : props.theme.vars.chartRed1};
`;

interface StatusBlockWrapperProps {
  children: ReactNode;
  isSuccess: boolean;
}

const StatusBlockWrapper = ({ children, isSuccess }: StatusBlockWrapperProps) => {
  return <StyledStatusBlockWrapper isSuccess={isSuccess}>{children}</StyledStatusBlockWrapper>;
};

export default StatusBlockWrapper;
