import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { openCurriculumModal } from '../../../../../redux/domains/curriculumModal/curriculumModalSlice';
import { openDocumentImportModal } from '../../../../../redux/domains/documentUpload/documentUploadSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import SplitButton from '../../../../design_system/buttons/SplitButton';
import { MenuOptionProps } from '../../../../design_system/core/MenuOptions';
import useCurriculumParams from '../../../curriculums/hooks/useCurriculumParams';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';

const ButtonWrapper = styled.div`
  max-width: fit-content;
`;

const t = initTranslations('curriculums.content');

const CreateContentButton = () => {
  const dispatch = useAppDispatch();
  const {
    slug,
    splitFeatures: { bulkDocImporterEnabled },
  } = useCurrentAccount();
  const { curriculum } = useAccountTerminology();
  const { sector } = useCurriculumParams();

  const handleImportFromDocument = useCallback(() => {
    if (bulkDocImporterEnabled) {
      routeTo(routes.bulkDocumentImport({ slug, sector }));
    } else {
      dispatch(openDocumentImportModal('subjects_split_button'));
    }
  }, [dispatch, bulkDocImporterEnabled, slug, sector]);

  const options = useMemo(() => {
    const baseOptions: MenuOptionProps[] = [
      {
        title: t('from_scratch'),
        onClick: () => dispatch(openCurriculumModal()),
        iconName: 'pen-to-square',
        iconWeight: 'regular',
        id: 'from-scratch-option',
      },
      {
        title: t('from_template'),
        onClick: () => routeTo(routes.templates({ slug }).href),
        iconName: 'layer-group',
        iconWeight: 'regular',
        id: 'from-template-option',
      },
      {
        title: t('import_document'),
        onClick: handleImportFromDocument,
        iconName: 'file-export',
        iconWeight: 'regular',
        id: 'import-curriculum-from-document',
      },
    ];

    return baseOptions;
  }, [dispatch, slug, handleImportFromDocument]);

  return (
    <ButtonWrapper>
      <SplitButton
        mainButtonOnClick={() => dispatch(openCurriculumModal())}
        mainMenuButtonId='create-content-button'
        menuId='create-content-menu'
        menuOptions={options}
        text={t('create_subject', { subject: curriculum.singular.toLowerCase() })}
      />
    </ButtonWrapper>
  );
};

export default CreateContentButton;
