import React, { ReactElement, createContext, useCallback, useState } from 'react';

import { ContentSearchResults } from '../redux/services/resourceApis/searches/types';

type ContentSearchContextType = {
  searchValue: string;
  selectedValue: ContentSearchResults | null;
  searchResults: ContentSearchResults[] | undefined;
  updateSearchResults: (results: ContentSearchResults[] | undefined) => void;
  updateContentSearchValue: (value: string) => void;
  updateContentSelectedValue: (value: ContentSearchResults | null) => void;
};

const ContentSearchContext = createContext<ContentSearchContextType>({
  searchValue: '',
  selectedValue: null,
  searchResults: undefined,
  updateSearchResults: () => ({}),
  updateContentSearchValue: () => ({}),
  updateContentSelectedValue: () => ({}),
});

const ContentSearchProvider = ({ children }: { children: ReactElement }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<ContentSearchResults | null>(null);
  const [searchResults, setSearchResults] = useState<ContentSearchResults[] | undefined>();

  const updateContentSearchValue = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const updateContentSelectedValue = useCallback((value: ContentSearchResults | null) => {
    setSelectedValue(value);
  }, []);

  const updateSearchResults = useCallback((results: ContentSearchResults[] | undefined) => {
    setSearchResults(results);
  }, []);

  return (
    <ContentSearchContext.Provider
      value={{
        searchValue,
        selectedValue,
        searchResults,
        updateContentSearchValue,
        updateContentSelectedValue,
        updateSearchResults,
      }}
    >
      {children}
    </ContentSearchContext.Provider>
  );
};

export { ContentSearchContext, ContentSearchProvider };
