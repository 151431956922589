import * as yup from 'yup';

import initTranslations from '../../initTranslations';
import { getEmailSchema } from '../email';

const t = initTranslations('public_application.login.form.errors');

export const getLoginSchema = () =>
  yup.object().shape({
    ...getEmailSchema(t('email_blank'), t('email_invalid')).fields,
    password: yup.string().trim().required(t('password.blank')),
  });
