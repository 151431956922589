import React, { useCallback, useEffect, useState } from 'react';

import { useCloseFullScreenOverlay } from '../../../../../../hooks/billing/useCloseFullScreenOverlay';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { checkCreditCardData } from '../../../../../../lib/billing/checkCreditCardData';
import { setQueryParamsFromResponse } from '../../../../../../lib/billing/setQueryParamsFromResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateSubscriptionMutation } from '../../../../../../redux/services/resourceApis/subscriptions/subscriptionsApi';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../../publicApplication/applicationRouter';
import routeTo from '../../../../publicApplication/routeTo';
import DiscountsSection from '../../../../shared/DiscountsSection/DiscountsSection';
import {
  MainSectionContent,
  MainSectionWrapper,
  OverlayContentWrapper,
} from '../../../../shared/FullScreenOverlay/styles';
import CardDetailsSection from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSection';
import CardDetailsSkeleton from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSectionSkeleton/CardDetailsSectionSkeleton';
import NextPayment from '../../../shared/BillingFullScreenOverlay/NextPayment/NextPayment';
import NextPaymentSkeleton from '../../../shared/BillingFullScreenOverlay/NextPayment/NextPaymentSkeleton/NextPaymentSkeleton';
import PlanCardsSkeleton from '../../../shared/BillingFullScreenOverlay/PlanCards/PlanCardsSkeleton/PlanCardsSkeleton';
import {
  ButtonsGroup,
  PaymentSummaryFooterWrapper,
  PaymentSummaryWrapper,
} from '../../../shared/BillingFullScreenOverlay/styles';
import SubmitButton from '../../../shared/BillingFullScreenOverlay/SubmitButton/SubmitButton';
import SummarySkeleton from '../../../shared/BillingFullScreenOverlay/Summary/SummarySkeleton/SummarySkeleton';
import BillingIntervalToggle from '../../../shared/BillingIntervalToggler/BillingIntervalToggle';
import { isCurrentPlanSameAsNewOne } from '../../utils/isCurrentPlanSameAsNewOne';
import AnnualSavings from '../AnnualSavings/AnnualSavings';
import AnnualSavingsSkeleton from '../AnnualSavings/AnnualSavingsSkeleton/AnnualSavingsSkeleton';
import SeatsDecreasingError from '../SeatsDecreasingError/SeatsDecreasingError';
import { BillingIntervalWrapper, SeatsAndBillingIntervalWrapper } from '../shared/styles';
import Summary from '../Summary/Summary';
import TotalSeatsSelect from '../TotalSeatsSelect/TotalSeatsSelect';
import { useCheckAbilityToShowOverlay } from '../utils/useCheckAbilityToShowOverlay';
import { useFullScreenOverlay } from '../utils/useFullScreenOverlay';
import LosingKeyFeatures from './LosingKeyFeatures/LosingKeyFeatures';
import NeedMoreSeats from './NeedMoreSeats/NeedMoreSeats';
import OverlayContentHeader from './OverlayContentHeader/OverlayContentHeader';
import PlanCards from './PlanCards/PlanCards';
import { ManagePlanOverlayContentProps } from './types';

const t = initTranslations('per_user_pricing.manage_plan_overlay_content');

const ManagePlanOverlayContent = ({
  route,
  currentDate = new Date(),
  type,
  isStorybookEnvironment,
  mockData,
}: ManagePlanOverlayContentProps) => {
  const fullScreenOverlayState = useFullScreenOverlay({
    route,
    isStorybookEnvironment,
    mockData,
  });
  const closeFullScreenOverlay = useCloseFullScreenOverlay();
  const isDowngradeOverlay = type === 'downgrade';
  const isManageSeatsOverlay = type === 'manage-seats';
  const defaultSubmitButtonText = isDowngradeOverlay ? 'downgrade' : 'upgrade';
  const [submitButtonText, setSubmitButtonText] = useState(defaultSubmitButtonText);
  const [updateSubscription, updateSubscriptionResult] = useUpdateSubscriptionMutation();
  const {
    upcomingInvoiceData,
    totalUserSlotsCount,
    selectedBillingInterval,
    selectedSeatsCount,
    slug,
    seatsCount,
    seatsOptions,
    isLoadingOrFetching,
    isAnnualInterval,
    isMaxSeatsCount,
    isPastDue,
    showDiscounts,
    showSeatsDecreasingError,
    setSelectedBillingInterval,
    setSelectedSeatsCount,
  } = fullScreenOverlayState;
  const updateSubscriptionIsLoading = updateSubscriptionResult.isLoading;
  const isLoadingSubmitButton = isLoadingOrFetching || updateSubscriptionIsLoading;
  const isAvailableOverlayData = !isLoadingOrFetching && !!upcomingInvoiceData;
  const isCurrentPlanSameAsNewPlan =
    isAvailableOverlayData &&
    isCurrentPlanSameAsNewOne({
      currentPlan: upcomingInvoiceData.currentPlan,
      newPlan: upcomingInvoiceData.newPlan,
    });
  const hasCardDetails = checkCreditCardData(upcomingInvoiceData?.billingDetails.cardDetails);
  const showLosingKeyFeatures =
    isDowngradeOverlay &&
    isAvailableOverlayData &&
    upcomingInvoiceData.currentPlan.name === 'scale' &&
    upcomingInvoiceData.newPlan.name === 'train';
  const isAnyDataChanges = isAvailableOverlayData && !isCurrentPlanSameAsNewPlan;
  const showNextPayment = !isMaxSeatsCount && isAnyDataChanges;
  const isDisabledSubmitButton =
    !isAnyDataChanges || updateSubscriptionIsLoading || showSeatsDecreasingError || !hasCardDetails;
  const canViewFullScreenOverlay = useCheckAbilityToShowOverlay(type);

  const handleSubmit = () => {
    !isDisabledSubmitButton &&
      updateSubscription({
        plan: upcomingInvoiceData.newPlan.name,
        interval: selectedBillingInterval,
        quantity: selectedSeatsCount,
        totalAmount: upcomingInvoiceData.newPlan.dueToday,
      });
  };

  const changeSubmitButtonText = useCallback(() => {
    if (isManageSeatsOverlay) {
      if (selectedSeatsCount < totalUserSlotsCount) {
        setSubmitButtonText('downgrade');
      } else if (selectedSeatsCount > totalUserSlotsCount) {
        setSubmitButtonText('upgrade');
      } else {
        setSubmitButtonText(defaultSubmitButtonText);
      }
    }
  }, [defaultSubmitButtonText, isManageSeatsOverlay, selectedSeatsCount, totalUserSlotsCount]);

  useEffect(() => {
    changeSubmitButtonText();
    setQueryParamsFromResponse({ selectedBillingInterval, selectedSeatsCount });
  }, [changeSubmitButtonText, selectedBillingInterval, selectedSeatsCount]);

  useDisplayFlashOnResponse({
    result: updateSubscriptionResult,
    successMessage: t('flash.success'),
    successFunction: () => {
      routeTo(routes.billing({ slug }));
    },
    errorMessage: t('flash.error'),
  });

  useEffect(() => {
    !canViewFullScreenOverlay && routeTo(routes.billing({ slug }));
  }, [canViewFullScreenOverlay, slug]);

  return (
    <OverlayContentWrapper>
      <MainSectionWrapper>
        <MainSectionContent>
          <div>
            <OverlayContentHeader type={type} />
            {showLosingKeyFeatures && <LosingKeyFeatures />}
          </div>

          <SeatsAndBillingIntervalWrapper>
            <TotalSeatsSelect
              handleChange={setSelectedSeatsCount}
              isLoading={isLoadingOrFetching}
              isValid={!showSeatsDecreasingError}
              options={seatsOptions}
              seatsCount={seatsCount}
            />
            <BillingIntervalWrapper
              className='manage-plan-billing-interval-toggle-wrapper'
              isLoading={isLoadingOrFetching}
            >
              {isAvailableOverlayData ? (
                <AnnualSavings newPlanName={upcomingInvoiceData.newPlan.name} />
              ) : (
                <AnnualSavingsSkeleton />
              )}
              <BillingIntervalToggle
                handleChange={setSelectedBillingInterval}
                isAnnualChecked={isAnnualInterval}
                isAnnualValueFirst
                isDisabled={isPastDue}
                isLoading={isLoadingOrFetching}
              />
            </BillingIntervalWrapper>
            {showSeatsDecreasingError && (
              <SeatsDecreasingError selectedSeatsCount={selectedSeatsCount} />
            )}
          </SeatsAndBillingIntervalWrapper>
          {isAvailableOverlayData ? (
            <PlanCards
              coupon={upcomingInvoiceData.coupon}
              currentPlan={upcomingInvoiceData.currentPlan}
              isManageSeatsOverlay={isManageSeatsOverlay}
              newPlan={upcomingInvoiceData.newPlan}
            />
          ) : (
            <PlanCardsSkeleton isManageSeatsOverlay={isManageSeatsOverlay} />
          )}
        </MainSectionContent>
      </MainSectionWrapper>
      <PaymentSummaryWrapper className='manage-plan-overlay-payment-summary'>
        {isAvailableOverlayData ? (
          <Summary
            addonItems={upcomingInvoiceData.addonItems}
            coupon={upcomingInvoiceData.coupon}
            currentDate={currentDate}
            currentPlan={upcomingInvoiceData.currentPlan}
            isMaxSeatsCount={isMaxSeatsCount}
            newPlanData={upcomingInvoiceData.newPlan}
          />
        ) : (
          <SummarySkeleton />
        )}
        <PaymentSummaryFooterWrapper>
          {showDiscounts && isAvailableOverlayData && (
            <DiscountsSection
              coupon={upcomingInvoiceData.coupon}
              isManagePlanOverlayContent
              productName={upcomingInvoiceData.newPlan.name}
              showYearlyBillingPerk={isAnnualInterval}
            />
          )}
          {isAvailableOverlayData ? (
            <CardDetailsSection
              cardDetails={upcomingInvoiceData.billingDetails.cardDetails}
              isMaxSeatsCount={isMaxSeatsCount}
            />
          ) : (
            <CardDetailsSkeleton />
          )}
          {isAvailableOverlayData ? (
            <>{showNextPayment && <NextPayment newPlanData={upcomingInvoiceData.newPlan} />}</>
          ) : (
            <NextPaymentSkeleton />
          )}
          {isMaxSeatsCount ? (
            <NeedMoreSeats maxSeatsCount={seatsCount} />
          ) : (
            <ButtonsGroup>
              <DefaultButton
                buttonType='secondary'
                id='manage-plan-cancel-button'
                onClick={closeFullScreenOverlay}
                text={t('cta.cancel')}
              />
              <SubmitButton
                handleSubmit={handleSubmit}
                hasCardDetails={hasCardDetails}
                id='manage-plan-primary-button'
                isDisabled={isDisabledSubmitButton}
                isLoading={isLoadingSubmitButton}
                text={t(`cta.${submitButtonText}`)}
              />
            </ButtonsGroup>
          )}
        </PaymentSummaryFooterWrapper>
      </PaymentSummaryWrapper>
    </OverlayContentWrapper>
  );
};

export default ManagePlanOverlayContent;
