import React from 'react';
import { Route } from 'type-route';

import EmptyStateDark from '../../../../../../images/empty_state/curriculums-empty-state-dark.svg';
import EmptyState from '../../../../../../images/empty_state/curriculums-empty-state.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import useDebounce from '../../../../../../lib/useDebounce';
import { useGetSmartTestModalDataQuery } from '../../../../../../redux/services/resourceApis/surveys/surveysApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Icon from '../../../../../design_system/display/icons/Icon';
import Checkbox from '../../../../../design_system/input/controls/Checkbox';
import SearchField from '../../../../../design_system/Triage/fields/SearchField';
import NoResults from '../../../../../design_system/Triage/NoResults';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import {
  CheckboxElementContainer,
  CheckboxLabelContainer,
  Emoji,
  FormElementsContainer,
  SearchContainer,
  StyledLoader,
} from './styles';
import { SelectCoursesFormProps } from './types';

const t = initTranslations('editor.create_survey.smart_test_modal');

const SelectCoursesForm = ({ formData, dispatch }: SelectCoursesFormProps) => {
  const route = useRoute();
  const {
    params: { surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;
  const { searchValue, selectedCourseIds } = formData;
  const debouncedSearchValue = useDebounce<string>(searchValue, 500);
  const { data, isFetching } = useGetSmartTestModalDataQuery({
    id: surveyId,
    search: debouncedSearchValue,
  });
  const { topic } = useAccountTerminology();

  const handleCheckboxClicked = (id: number) => {
    dispatch({ type: 'toggleSelectedCourseId', selectedCourseId: id });
  };

  return (
    <>
      <SearchContainer>
        <SearchField
          className='smart-test-modal-courses-search'
          dataLoading={isFetching}
          placeholder={t('search')}
          searchValue={searchValue}
          setSearchValue={(value: string) =>
            dispatch({ type: 'setSearchValue', searchValue: value })
          }
        />
      </SearchContainer>
      {data?.courses.length ? (
        <FormElementsContainer>
          {data.courses.map(({ id, title, emoji }) => {
            return (
              <CheckboxElementContainer key={id}>
                <CheckboxLabelContainer onClick={() => handleCheckboxClicked(id)}>
                  <Emoji>{!!emoji ? emoji : <Icon name='file-alt' size='xs' />}</Emoji>
                  {title}
                </CheckboxLabelContainer>
                <Checkbox
                  checked={selectedCourseIds.includes(id)}
                  id={`smart-test-course-checkbox-${id}`}
                  name={`smart-test-course-checkbox-${id}`}
                  onCheck={() => handleCheckboxClicked(id)}
                />
              </CheckboxElementContainer>
            );
          })}
        </FormElementsContainer>
      ) : isFetching ? (
        <StyledLoader />
      ) : (
        <NoResults
          darkImage={EmptyStateDark}
          heading={t('no_results', { courses: topic.plural.toLowerCase() })}
          iconWidth='50%'
          lightImage={EmptyState}
          minHeight='14rem'
          showBorder={false}
        />
      )}
    </>
  );
};

export default SelectCoursesForm;
