import React from 'react';

import LoomVideoResponseEditor from '../../../../SurveyConsumption/OutlineContent/SurveyQuestionCard/VideoResponseForm/LoomVideoResponseEditor/LoomVideoResponseEditor';
import { ResultComponentProps } from '../types';

const VideoResponseResult = ({ answeredQuestion }: ResultComponentProps) => {
  return <LoomVideoResponseEditor content={answeredQuestion.responseJson} />;
};

export default VideoResponseResult;
