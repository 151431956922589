import styled, { css } from 'styled-components';

export const BoardControllerContainer = styled.div`
  ${({ theme: { constants, vars } }) => css`
    position: fixed;
    z-index: 4;
    bottom: 5rem;
    transform: translateY(${constants.height2xs});
    right: ${constants.spacerLg2};
    background-color: ${vars.foundationSurface1};
    padding: ${constants.spacerSm3};
    border-radius: ${constants.borderRadiusLg};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    box-shadow: ${vars.shadowTopMedium};
  `};
`;

export const BoardControllerColumnsHolder = styled.div`
  ${({ theme: { constants } }) => css`
    position: relative;
    display: flex;
    gap: ${constants.spacerSm2};
    width: 100%;
    max-width: 12rem;
    height: 100%;
  `}
`;

export const MiniColumnSkeleton = styled.div`
  ${({ theme: { constants, vars } }) => css`
    width: ${constants.spacerMd2};
    height: ${constants.heightMd};
    background-color: ${vars.foundationBase2};
    border-radius: ${constants.borderRadiusSm};
  `};
`;

export const SensorBox = styled.div<{ sensorWidth: number; isDragging: boolean }>`
  ${({ sensorWidth, isDragging, theme: { constants, vars } }) => css`
    position: absolute;
    width: ${sensorWidth}px;
    height: 100%;
    z-index: 2;
    border-radius: ${constants.borderRadiusMd};
    border: ${constants.borderWidthLg} solid ${vars.accentPrimaryDefault};
    cursor: ${isDragging ? 'grabbing' : 'grab'};
    box-shadow: ${isDragging
      ? `0 0 0 ${constants.spacerSm1} ${vars.accentPrimaryDefault}40`
      : 'none'};
    transition: 0.1s;
  `};
`;
