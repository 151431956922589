import React from 'react';

import {
  StatisticBody,
  StatisticFooter,
  StatisticHeader,
  SurveyStatisticBlockWrapper,
} from './styles';
import { SurveyStatisticBlockProps } from './types';

const SurveyStatisticBlock = ({ title, body, infoText, textAlign }: SurveyStatisticBlockProps) => {
  return (
    <SurveyStatisticBlockWrapper textAlign={textAlign}>
      <StatisticHeader>{title}</StatisticHeader>
      <StatisticBody>{body}</StatisticBody>
      <StatisticFooter>{infoText}</StatisticFooter>
    </SurveyStatisticBlockWrapper>
  );
};

export default SurveyStatisticBlock;
