import { routes } from '../../components/application/publicApplication/applicationRouter';
import routeTo from '../../components/application/publicApplication/routeTo';
import { useTeammateModal } from '../../contexts/TeammateContext';
import { openCurriculumModal } from '../../redux/domains/curriculumModal/curriculumModalSlice';
import { openDocumentImportModal } from '../../redux/domains/documentUpload/documentUploadSlice';
import { useAppDispatch } from '../../redux/hooks';
import { TaskDrivenOnboardingActionId } from '../../types/TaskDrivenOnboarding';
import useAnalyticsUserEvents from '../useAnalyticsUserEvents';
import useCurrentAccount from '../useCurrentAccount';

export function useHandlerForTaskDrivenOnboardingAction() {
  const {
    slug,
    splitFeatures: { bulkDocImporterEnabled },
  } = useCurrentAccount();
  const dispatch = useAppDispatch();
  const { setTeammateModalVisible } = useTeammateModal();
  const { cdpTaskDrivenOnboardingClicked } = useAnalyticsUserEvents();

  return (actionId: TaskDrivenOnboardingActionId) => {
    cdpTaskDrivenOnboardingClicked({ TaskDrivenOnboardingProps: actionId });

    switch (actionId) {
      case 'choose-template':
        routeTo(routes.templates({ slug }));
        break;
      case 'import-document':
        if (bulkDocImporterEnabled) {
          routeTo(routes.bulkDocumentImport({ slug, sector: 'process' }));
        } else {
          dispatch(openDocumentImportModal('task_driven_onboarding'));
        }
        break;
      case 'start-from-scratch':
        dispatch(openCurriculumModal());
        break;
      case 'create-role-chart':
        routeTo(routes.roleChart({ slug, slideout: 'org-chart-config-slideout' }));
        break;
      case 'create-groups':
        routeTo(routes.groups({ slug, show_create_group_kind_modal: true }));
        break;
      case 'add-individually':
        setTeammateModalVisible(true);
        break;
      case 'bulk-add':
        routeTo(routes.manageUsers({ slug, open_bulk_user_upload_modal: true }));
        break;
      case 'auto-add-hr':
        routeTo(routes.integrations({ slug }));
        break;
    }
  };
}
