import { FormState, ReducerAction } from './types';

export const initState: FormState = {
  data: {
    id: '',
    addressCity: '',
    addressCountry: '',
    addressLine1: '',
    addressLine2: '',
    addressState: '',
    addressZip: '',
    brand: '',
    expMonth: '',
    expYear: '',
    last4: '',
    name: '',
  },
  isFormError: false,
  isStripeError: false,
  paymentDetailsChanged: false,
};

export const reducer = (state: FormState, action: ReducerAction): FormState => {
  switch (action.type) {
    case 'updateFormData':
      return {
        ...state,
        paymentDetailsChanged: action.payload.paymentDetailsChanged,
        data: {
          ...state.data,
          ...action.payload.updatedData,
        },
      };
    case 'setFormData':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case 'setStripeError':
      return {
        ...state,
        isStripeError: action.payload,
      };
    case 'setFormError':
      return {
        ...state,
        isFormError: action.payload,
      };
    case 'setPaymentDetailsChanged':
      return {
        ...state,
        paymentDetailsChanged: action.payload,
      };
    case 'setInitialState':
      return initState;
    default:
      return state;
  }
};
