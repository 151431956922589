import React from 'react';

import { AddonDataFeature } from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import P from '../../../../../../design_system/text/P';
import { AddonSectionTitle } from '../styles';
import { FeatureListItem, FeatureListItemTitle, FeatureListItems } from './styles';

type FeatureListProps = {
  featureDescriptions: AddonDataFeature[];
  itemTitleColor: string;
  title: string;
};

const FeatureList = ({ featureDescriptions, itemTitleColor, title }: FeatureListProps) => {
  return (
    <div id='addon-overlay-feature-list'>
      <AddonSectionTitle id='addon-overlay-feature-list-title'>{title}</AddonSectionTitle>
      <FeatureListItems id='addon-overlay-feature-list-items'>
        {featureDescriptions.map((featureDescription) => (
          <FeatureListItem key={featureDescription.title}>
            <FeatureListItemTitle itemTitleColor={itemTitleColor} text={featureDescription.title} />
            <P text={featureDescription.description} />
          </FeatureListItem>
        ))}
      </FeatureListItems>
    </div>
  );
};

export default FeatureList;
