import React from 'react';
import { Route } from 'type-route';

import RichTextareaWithInsert from '../../../../editor/components/RichTextareaWithInsert';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import { CardHeader, OptionsFormWrapper, QuestionPosition } from '../../SurveyQuestion/styles';
import ChooseOptionResult from './ChooseOptionsResult/ChooseOptionResult';
import MultipleChoiceResult from './ChooseOptionsResult/MultipleChoiceResult';
import {
  CardContent,
  QuestionCard,
  QuestionTextContainer,
  ResultIcon,
  ResultIconWrapper,
} from './styles';
import { AnsweredQuestionCardProps, ResultTypeComponentProps } from './types';
import VideoResponseResult from './VideoResponseResult/VideoResponseResult';
import WrittenResponseResult from './WrittenResponseResult/WrittenResponseResult';

const AnsweredQuestionCard = ({ position, answeredQuestion }: AnsweredQuestionCardProps) => {
  const route = useRoute();
  const {
    params: { surveyId },
  } = route as Route<typeof routes.surveyResults>;
  const { correct, text, id, textJson } = answeredQuestion;

  const RESPONSE_RESULTS: ResultTypeComponentProps = {
    true_or_false: ChooseOptionResult,
    choose_one_option: ChooseOptionResult,
    written_response: WrittenResponseResult,
    multiple_choice: MultipleChoiceResult,
    video_response: VideoResponseResult,
  };
  const ResponseResult =
    RESPONSE_RESULTS[answeredQuestion.answerType as keyof ResultTypeComponentProps];

  return (
    <QuestionCard id={`answered-question-card-${id}`}>
      <CardHeader>
        <QuestionPosition>{position}</QuestionPosition>
      </CardHeader>
      <CardContent>
        <OptionsFormWrapper>
          <QuestionTextContainer>
            <ResultIconWrapper>
              <ResultIcon
                isCorrect={correct}
                name={correct ? 'check' : 'xmark'}
                size='sm'
                weight='solid'
              />
            </ResultIconWrapper>
            <RichTextareaWithInsert
              content={textJson || text}
              questionId={id}
              surveyId={surveyId}
            />
          </QuestionTextContainer>
          <ResponseResult answeredQuestion={answeredQuestion} />
        </OptionsFormWrapper>
      </CardContent>
    </QuestionCard>
  );
};

export default AnsweredQuestionCard;
