import React from 'react';
import styled from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { fontMd1 } from '../../../../../styled/TypeSystem';
import {
  StepEmojiWrapper as EmojiWrapper,
  StepTitle as Title,
  TitleWrapper,
} from '../shared/styles';
import { ReadOnlyProps } from './ReadOnlyTitle';

const Emoji = styled.span`
  ${fontMd1};
`;

const StepTitle = ({ emoji, title }: ReadOnlyProps) => {
  return (
    <TitleWrapper>
      <EmojiWrapper>
        {emoji ? <Emoji>{emoji}</Emoji> : <Icon name='list-ol' size='sm' weight='regular' />}
      </EmojiWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
  );
};

export default StepTitle;
