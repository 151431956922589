import React, { Dispatch, MouseEvent, SetStateAction, useMemo } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import {
  useRequestAccessMutation,
  useToggleFavoriteCurriculumMutation,
} from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { MetaUserAccess } from '../../../../../types/Curriculum';
import { Emoji } from '../../../../../types/Emoji';
import Icon from '../../../../design_system/display/icons/Icon';
import Link from '../../../../design_system/Link';
import { fontSm3 } from '../../../../styled/TypeSystem';
import { FavoriteIconWrapper } from '../../../home/CurriculumsAssignments/CurriculumsAssignmentstItem/styles';
import { routes } from '../../../publicApplication/applicationRouter';
import ContentMetadata from '../ContentMetadata/ContentMetadata';
import EditableCurriculumTitle from '../EditableTitles/EditableCurriculumTitle';

const MAX_CHARACTERS = 100;

const PendingAccessWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm3};
`;

const TitleWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

const InlineDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const FavoriteIcon = styled(Icon)<{ isFavorite: boolean; isAdminHovered?: boolean }>`
  cursor: pointer;
  color: ${({ theme: { vars }, isFavorite, isAdminHovered }) =>
    isFavorite || isAdminHovered ? vars.stateFavorite : vars.borderSurface2};
`;

type Props = {
  assignmentId?: number;
  canEdit: boolean;
  emoji: Emoji;
  favorited: boolean;
  id: number;
  isAdminHovered: boolean;
  isEditing: boolean;
  isBulkActionsMenuOpen?: boolean;
  isOpen: boolean;
  published: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  slug: string;
  title: string;
  updatedAt: string;
  userAccess?: MetaUserAccess;
};

const t = initTranslations('curriculums.content.library_row');

const LibraryRowTitle = ({
  assignmentId,
  canEdit,
  favorited,
  id,
  isAdminHovered,
  isEditing,
  published,
  setIsEditing,
  slug,
  title,
  updatedAt,
  userAccess,
}: Props) => {
  const noAccess = useMemo(() => !userAccess || userAccess === 'pending', [userAccess]);

  const [toggleFavoriteAssignment] = useToggleFavoriteCurriculumMutation();
  const [requestAccess] = useRequestAccessMutation();

  const toggleFavorite = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    toggleFavoriteAssignment(id);
  };

  return (
    <TitleWrapper>
      <InlineDiv>
        <EditableCurriculumTitle
          id={id}
          isEditing={isEditing}
          maxCharacters={MAX_CHARACTERS}
          route={
            canEdit
              ? routes.curriculumEdit({ slug, id })
              : noAccess
              ? routes.curriculumRequestAccess({ slug, id })
              : routes.curriculumShow({ slug, id })
          }
          setIsEditing={setIsEditing}
          title={title}
        />
        {assignmentId && (
          <FavoriteIconWrapper
            className='favorite-wrapper'
            onClick={(event) => toggleFavorite(event)}
          >
            <FavoriteIcon
              className={`favorite-icon${favorited ? ' is-favorite' : ''}`}
              isAdminHovered={isAdminHovered}
              isFavorite={favorited}
              name='star'
              weight={favorited ? 'solid' : 'regular'}
            />
          </FavoriteIconWrapper>
        )}
      </InlineDiv>
      {!userAccess ? (
        <Link
          behavesAs='button'
          onClick={() => requestAccess({ curriculumId: id })}
          text={t('request_access')}
        />
      ) : userAccess === 'pending' ? (
        <PendingAccessWrapper>{t(`access.${userAccess}`)}</PendingAccessWrapper>
      ) : (
        <ContentMetadata published={published} updatedAt={updatedAt} userAccess={userAccess} />
      )}
    </TitleWrapper>
  );
};

export default LibraryRowTitle;
