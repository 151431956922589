import React from 'react';
import styled from 'styled-components';

import { useAbilityToManageProfile } from '../../../../../hooks/users/ProfileSharedAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import Link from '../../../../design_system/Link';
import { fontSm5 } from '../../../../styled/TypeSystem';

const Section = styled.section`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  display: flex;
  ${fontSm5};
`;

const LinkWrapper = styled.section`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const t = initTranslations('user_profile.bio.content.empty');

interface EmptyBioProps {
  isTrialEndedOrOnHold: boolean;
  userId: number;
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmptyBio = ({ isTrialEndedOrOnHold, setEditable, userId }: EmptyBioProps) => {
  const canCreateBio = useAbilityToManageProfile(userId) && !isTrialEndedOrOnHold;

  return (
    <Section>
      {t('main')}
      {canCreateBio && (
        <LinkWrapper>
          <Link
            behavesAs='button'
            className='analytics-profile-add-bio'
            onClick={() => {
              setEditable(true);
            }}
            text={t('cta')}
          />
        </LinkWrapper>
      )}
    </Section>
  );
};

export default EmptyBio;
