import styled, { css } from 'styled-components';

import { fontSm3, fontSm5 } from '../../../styled/TypeSystem';

export const AddResponsibilityCardWrapper = styled.div<{ isError?: boolean }>`
  ${({ theme: { constants, vars }, isError }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerSm2};
    padding: ${constants.spacerMd2};
    border: ${constants.borderWidthSm} solid
      ${isError ? vars.stateError : vars.accentPrimaryDefault};
    background-color: ${vars.foundationSurface1};
    border-radius: ${constants.borderRadiusMd};
    margin-bottom: ${isError ? 0 : constants.spacerSm3};
    z-index: 10;
  `};
`;

export const AddResponsibilityContainer = styled.div<{ isVisible: boolean; isOpen: boolean }>`
  ${({ isVisible, isOpen }) => {
    return css`
      position: relative;
      overflow: ${isVisible ? 'visible' : 'hidden'};
      height: auto;
      max-height: ${isOpen ? '12rem' : 0};
      opacity: ${isOpen ? 1 : 0};
      transition: 0.3s ease;
      transform-origin: top;
    `;
  }}
`;

export const Textarea = styled.textarea<{ isError?: boolean }>`
  width: 100%;
  height: 100%;
  color: ${({ theme: { vars } }) => vars.textDefault};
  border: none;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  resize: none;

  ${fontSm5};

  &::placeholder {
    color: ${({ isError, theme: { vars } }) => (isError ? vars.stateError : vars.textPlaceholder)};
  }

  &:focus {
    outline: none;
  }
`;

export const CharacterCounter = styled.div<{ isError?: boolean }>`
  color: ${({ isError, theme: { vars } }) => (isError ? vars.stateError : vars.textDisabled)};
  position: absolute;
  cursor: default;
  z-index: 1;
  right: ${({ theme: { constants } }) => constants.spacerSm2};
  top: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm3};
`;

export const FormButtonsContainer = styled.div<{ isError?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  z-index: 5;
  padding-top: ${({ isError, theme: { constants } }) => (isError ? constants.spacerSm3 : 0)};
`;
