import styled from 'styled-components';

import { StatusPillContainer } from '../../shared/StatusPill/styles';
import { DropdownMenuOption } from '../../shared/styles';

export const BoardSelectionDropdownMenuOptionContainer = styled(DropdownMenuOption)`
  ${StatusPillContainer} {
    margin: ${({ theme: { constants } }) => constants.spacerSm2} 0 0
      ${({ theme: { constants } }) => constants.spacerMd3};
  }
`;
