import React from 'react';

import { useConsumptionCourseContext } from '../../../../../../contexts/ConsumptionCourseContext';
import initTranslations from '../../../../../../lib/initTranslations';
import ConfirmationModal from '../../../../../design_system/overlays/modals/ConfirmationModal';

const t = initTranslations('e_signature.move_to_signing_step_confirmation_modal');

export type MoveToSigningStepConfirmationProps = {
  closeModal: () => void;
};

const MoveToSigningStepConfirmationModal = ({ closeModal }: MoveToSigningStepConfirmationProps) => {
  const { setIsESignatureShowing } = useConsumptionCourseContext();

  return (
    <ConfirmationModal
      actionFunction={() => setIsESignatureShowing(true)}
      actionText={t('continue')}
      desktopSize='md'
      heapModalName='move-to-signing-step-confirmation-modal'
      message={t('description')}
      onCloseRequest={closeModal}
      processing={false}
      secondaryButtonText={t('cancel')}
      title={t('title')}
    />
  );
};

export default MoveToSigningStepConfirmationModal;
