import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { headerContainerStyles, paddingBox } from '../Board/styles';

export const HeaderActionsBlock = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const PageToolsContainer = styled.div`
  ${headerContainerStyles};
  ${paddingBox};
  padding-inline-end: 0 !important;
  flex-direction: column;

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: column;
  }

  .delegation-planner-header {
    margin-bottom: 0;
  }
`;
