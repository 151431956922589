import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import {
  StyledButton,
  ZoomControlWrapperStyles,
} from '../../../../design_system/core/ZoomControl/ZoomControl';
import Icon from '../../../../design_system/display/icons/Icon';

const StyledZoomControl = styled.div`
  ${ZoomControlWrapperStyles};
`;

export interface ZoomState {
  action: ZoomAction | null;
  eventTime: number | null;
}

type ZoomAction = 'decrement' | 'increment' | 'fit' | 'user' | 'fitToPrint';

interface ZoomActionProps {
  type: ZoomAction;
}

export const zoomReducer = (_state: ZoomState, action: ZoomActionProps): ZoomState => {
  const { type } = action;
  const eventTime = new Date().getTime();
  switch (type) {
    case 'decrement':
      return { action: 'decrement', eventTime };
    case 'increment':
      return { action: 'increment', eventTime };
    case 'fit':
      return { action: 'fit', eventTime };
    case 'user':
      return { action: 'user', eventTime };
    case 'fitToPrint':
      return { action: 'fitToPrint', eventTime };
  }
};

const t = initTranslations('zoom_control');

export type ZoomProps = {
  zoomDispatch: React.Dispatch<ZoomActionProps>;
  zoomState: ZoomState;
};

export type ZoomControlProps = {
  resource: string;
} & ZoomProps;

const OrgChartZoomControl = (props: ZoomControlProps) => {
  const { resource, zoomDispatch } = props;

  return (
    <StyledZoomControl className='zoom-control'>
      <StyledButton
        $alignment='left'
        className={`${resource}-zoom-decrease`}
        id='zoom-decrease'
        onClick={() => zoomDispatch({ type: 'decrement' })}
      >
        <Icon name='minus' weight='regular' />
      </StyledButton>
      <StyledButton
        $alignment='center'
        className={`${resource}-zoom-fit`}
        id='zoom-fit'
        onClick={() => zoomDispatch({ type: 'fit' })}
      >
        {t('fit')}
      </StyledButton>
      <StyledButton
        $alignment='right'
        className={`${resource}-zoom-increase`}
        id='zoom-increase'
        onClick={() => zoomDispatch({ type: 'increment' })}
      >
        <Icon name='plus' weight='regular' />
      </StyledButton>
    </StyledZoomControl>
  );
};

export default OrgChartZoomControl;
