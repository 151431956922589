import React from 'react';
import { Route } from 'type-route';

import { routes } from '../../publicApplication/applicationRouter';
import { FlowchartLayout } from '../shared/BaseFlowchart/styles';
import FlowchartBase from './FlowchartBase';

export type Props = {
  route: Route<typeof routes.flowchartConsume>;
};

const FlowchartConsume = ({ route }: Props) => {
  const {
    params: { id },
  } = route;

  return (
    <FlowchartLayout>
      <FlowchartBase id={id} key={id} />
    </FlowchartLayout>
  );
};

export default FlowchartConsume;
