import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import NoResultsGraphic from '../../../../../images/searches_empty_state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { useAppSelector } from '../../../../../redux/hooks';
import {
  HelpCenterSearchPaginatedResult,
  SearchPaginatedResult,
} from '../../../../../redux/services/resourceApis/searches/types';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import SearchHelpCenterResultsCard from '../search_result_cards/SearchHelpCenterResultsCard/SearchHelpCenterResultsCard';
import SearchResultsCard from '../search_result_cards/SearchResultsCard/SearchResultsCard';
import { SearchResultsContentProps } from './types';

const t = initTranslations('search_results_page.no_results_found');

const SearchResultsContent = ({
  data,
  helpCenterData,
  handlePaginate,
  page,
  searchKind,
  searchTerm: term,
}: SearchResultsContentProps) => {
  const { slug } = useCurrentAccount();
  const isHelpCenterKind = searchKind === 'help_center';
  const { semanticSteps } = useAppSelector((state) => state.searchResults);
  const { paginatedResult, limitValue, totalCount, totalPages } =
    isHelpCenterKind && helpCenterData ? helpCenterData : data;

  const activePage = page || 1;
  const isTemplateKind = searchKind === 'templates';
  const noResultHeading = isTemplateKind
    ? t('not_found_templates', { term })
    : t('not_found_content', { term });
  const displaySemanticResults = searchKind === 'company' && semanticSteps.length;
  const companyData = paginatedResult?.length ? data.paginatedResult : semanticSteps;

  return (
    <>
      {paginatedResult?.length || displaySemanticResults ? (
        <>
          <div id='search-results-list'>
            {isHelpCenterKind
              ? helpCenterData?.paginatedResult.map(
                  (item: HelpCenterSearchPaginatedResult, index) => (
                    <SearchHelpCenterResultsCard
                      content={item.summary}
                      key={index}
                      titleHighlighted={item.title}
                      url={item.url}
                    />
                  )
                )
              : companyData.map((item: SearchPaginatedResult, index) => (
                  <SearchResultsCard
                    avatar={item.avatar}
                    breadcrumbText={item.breadcrumbText}
                    breadcrumbTitle={item.breadcrumbTitle}
                    cardType={item.type}
                    content={item.description || item.surveyTitle || item.content || ''}
                    countOfElementsInside={item.countOfElementsInside}
                    curriculumTitle={item.curriculumTitle}
                    emoji={item.emoji}
                    itemId={item.id}
                    key={item.id}
                    minutesToRead={item.minutesToRead}
                    ownerName={item.ownerName}
                    rankingNumber={index + 1}
                    searchHistoryId={data.searchHistory.id}
                    surveyId={item.surveyId}
                    titleHighlighted={item.titleHighlighted}
                  />
                ))}
          </div>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={limitValue}
            onChange={handlePaginate}
            showPaginationDetails
            totalItemsCount={totalCount}
            totalPages={totalPages}
          />
        </>
      ) : (
        <NoResults
          className='no-search-results'
          darkImage={NoResultsGraphic}
          heading={noResultHeading}
          iconWidth='320px'
          lightImage={NoResultsGraphic}
          minHeight='auto'
          showBorder={false}
          subHeaderCta={
            isTemplateKind
              ? {
                  action: () => routeTo(routes.templates({ slug })),
                  text: t('browse_templates'),
                }
              : undefined
          }
        />
      )}
    </>
  );
};

export default SearchResultsContent;
