import React, { FC } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { CloseButton, Title, TitledPopupContainer, TitledPopupHeader } from './styles';
import { TitledPopupProps } from './types';

const t = initTranslations('titled_popup');

const TitledPopup: FC<TitledPopupProps> = ({ title, children, onClose }) => {
  return (
    <TitledPopupContainer onClick={(e) => e.stopPropagation()}>
      {title && (
        <TitledPopupHeader>
          <Title>{title}</Title>

          <CloseButton
            ariaLabel={t('aria_label_close', { title })}
            buttonSize='md'
            className='close-titled-popup'
            name='xmark'
            onClick={onClose}
            weight='solid'
          />
        </TitledPopupHeader>
      )}

      {children}
    </TitledPopupContainer>
  );
};

export default TitledPopup;
