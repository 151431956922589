import React from 'react';
import styled, { useTheme } from 'styled-components';

import DoubleSpinArrowImage from '../../../../../../../images/billing/double_spin_arrow.svg';
import initTranslations from '../../../../../../../lib/initTranslations';
import { SkeletonLoader } from '../../../../../shared/SkeletonLoader/styles';
import { AnnualSavingsBaseStyles, AnnualSavingsWrapper } from '../styles';

const t = initTranslations('per_user_pricing.full_screen_overlay_content.annual_savings');

const AnnualSavingsSkeletonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${AnnualSavingsBaseStyles};
`;

const AnnualSavingsSkeleton = () => {
  const {
    constants: { borderRadiusSm },
  } = useTheme();

  return (
    <AnnualSavingsWrapper>
      <img alt={t('image_alt')} src={DoubleSpinArrowImage} />
      <AnnualSavingsSkeletonWrapper>
        <SkeletonLoader borderRadius={borderRadiusSm} height='0.7rem' width='65%' />
      </AnnualSavingsSkeletonWrapper>
    </AnnualSavingsWrapper>
  );
};

export default AnnualSavingsSkeleton;
