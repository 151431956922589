import useCurrentAccount from '../useCurrentAccount';
import useCurrentUserAbilities from '../useCurrentUserAbilities';

export function useGetSearchKinds() {
  const ability = useCurrentUserAbilities();
  const {
    splitFeatures: { helpCenterSearchEnabled },
  } = useCurrentAccount();
  const canShowTemplates = ability.can('read', 'SearchTemplates');

  const availableKinds = ['company'];
  canShowTemplates && availableKinds.push('templates');
  helpCenterSearchEnabled && availableKinds.push('help_center');

  return availableKinds;
}
