import styled from 'styled-components';

import {
  mediaBreakpointPx2xl,
  mediaBreakpointPxMd,
  mediaBreakpointPxSm,
} from '../../../../styled/Breakpoint';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const OnboardingStepWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  height: 100vh;
`;

export const OnboardingStepHeader = styled.div`
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;

  @media (min-width: ${mediaBreakpointPxSm}) {
    transform: translateY(-3rem);
  }

  @media (min-width: ${mediaBreakpointPxMd}) {
    transform: translateY(-5rem);
  }

  @media (min-width: ${mediaBreakpointPx2xl}) {
    transform: translateY(-8rem);
  }
`;

export const OnboardingStepBullet = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidth2xl} solid ${vars.borderDisabled}`};
  position: relative;
  &:after,
  &:before {
    content: '';
    display: block;
    width: 0.125rem;
    height: 60vh;
    background-color: ${({ theme: { vars } }) => vars.borderSurface1};
    position: absolute;
  }
  &:after {
    top: 54px;
    left: 11px;
  }
  &:before {
    bottom: 48px;
    left: 11px;

    @media (min-width: ${mediaBreakpointPxSm}) {
      bottom: 54px;
    }
  }
`;

export const OnboardingStepBulletSm = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  border: ${({ theme: { vars } }) => `6px solid ${vars.borderDisabled}`};
  position: relative;
  margin-right: ${({ theme: { constants } }) => constants.spacerLg1};
  left: 11px;
  &:before {
    content: '';
    display: block;
    width: 0.125rem;
    height: 120vh;
    background-color: ${({ theme: { vars } }) => vars.borderSurface1};
    position: absolute;
    bottom: 23px;
    left: -2px;

    @media (min-width: ${mediaBreakpointPxSm}) {
      height: 160vh;
    }

    @media (min-width: ${mediaBreakpointPx2xl}) {
      height: 154vh;
    }
  }
  &:after {
    @media (min-width: ${mediaBreakpointPxSm}) {
      content: '';
      display: block;
      width: 0.125rem;
      background-color: ${({ theme: { vars } }) => vars.borderSurface1};
      position: absolute;
      top: 24px;
      left: -2px;
      height: 10vh;
    }

    @media (min-width: ${mediaBreakpointPxMd}) {
      height: 13vh;
    }
  }
`;

export const OnboardingStepBulletWrapper = styled.div`
  width: 3.5rem;
`;

export const OnboardingStepNumber = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm4};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

export const OnboardingStepHeaderInner = styled.div`
  opacity: 0.5;
  transition: 0.2s ease-in-out;
`;

export const OnboardingStepTitle = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  transition: 0.35s ease-in;
  ${fontSm5};
`;

export const OnboardingStepContainer = styled.div`
  margin: auto;
  padding-left: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
  max-width: 60rem;
`;

export const OnboardingStepInner = styled.div`
  padding-left: ${({ theme: { constants } }) => constants.spacerLg1};
  display: flex;
  align-items: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    height: calc(100vh - 8.5rem);
  }

  @media (min-width: ${mediaBreakpointPxMd}) {
    padding: 6rem 0 6rem 3.5rem;
    height: calc(100vh - 10rem);
  }

  @media (min-width: ${mediaBreakpointPx2xl}) {
    padding: 0 0 6rem 3.5rem;
    height: calc(100vh - 8rem);
  }
`;

export const OnboardingSubmitSection = styled.div`
  display: flex;
  align-items: center;
  max-width: 20rem;

  button {
    margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;
