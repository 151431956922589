import React, { useEffect } from 'react';
import { Route } from 'type-route';

import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useGetTopicFirstStepIdQuery } from '../../../../redux/services/resourceApis/courses/topicsApi';
import ViewModeSkeleton from '../../editor/ConsumptionPage/components/ViewModeSkeleton/ViewModeSkeleton';
import { routes } from '../../publicApplication/applicationRouter';

type Props = {
  route: Route<typeof routes.courseConsume>;
};

// This component is only setup to support our current handling of redirecting to a first step in a course to the consumption page
const CourseConsume = ({ route }: Props) => {
  const {
    params: { id, slug },
  } = route;
  const { data, error } = useGetTopicFirstStepIdQuery(id);

  useEffect(() => {
    if (data) {
      routes.consume({ id: data.firstStepId, slug }).replace();
    }
  }, [data, slug]);

  if (error) return <BasicErrorDisplay error={error} />;
  return <ViewModeSkeleton />;
};

export default CourseConsume;
