import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { FormSubSection, SectionDivider } from '../styles';
import SignaturePermissionForm from './SignaturePermissionForm/SignaturePermissionForm';
import SignatureToggle from './SignatureToggle/SignatureToggle';
import { SignaturesSectionProps } from './types';

const SignaturesSection = ({ allowSignatures, accountSettingsState }: SignaturesSectionProps) => {
  const {
    hasESignatureFeature,
    splitFeatures: { eSignatureToggleSectionEnabled },
  } = useCurrentAccount();
  const showSignaturesSectionWithToggle = eSignatureToggleSectionEnabled && !hasESignatureFeature;

  return (
    <>
      {showSignaturesSectionWithToggle ? (
        <>
          <SectionDivider />
          <SignatureToggle allowSignatures={allowSignatures} />
          {allowSignatures && (
            <FormSubSection>
              <SignaturePermissionForm accountSettingsState={accountSettingsState} />
            </FormSubSection>
          )}
        </>
      ) : (
        allowSignatures && (
          <>
            <SectionDivider />
            <SignaturePermissionForm accountSettingsState={accountSettingsState} />
          </>
        )
      )}
    </>
  );
};

export default SignaturesSection;
