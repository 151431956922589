import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { useOrgChartConfigSlideout } from '../../../../../contexts/OrgChartConfigSlideoutContext';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import capitalize from '../../../../../lib/capitalize';
import { stringifyNumber } from '../../../../../lib/convertValues';
import initTranslations from '../../../../../lib/initTranslations';
import { selectOptions } from '../../../../../lib/selectOptions';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useUpdateRootResourceMutation } from '../../../../../redux/services/resourceApis/orgChart/orgChartApi';
import Icon from '../../../../design_system/display/icons/Icon';
import Link from '../../../../design_system/Link';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import Line from '../../../../design_system/Triage/layout/Line';
import FlexContainer from '../../../../styled/FlexContainer';
import { MenuListButtonCreateNew } from './MenuListButtonCreateNew';
import OrgChartConfigSlideoutReplaceRootResourceModal from './OrgChartConfigSlideoutReplaceRootResourceModal';
import {
  HeaderDescription,
  HighestRankingTitle,
  HighestRankingTitleWrapper,
  SelectWrapper,
} from './styles';

const OrgChartConfigSlideoutHighestRanking = () => {
  const theme = useTheme();
  const ability = useCurrentUserAbilities();
  const { orgChartResourceType } = useOrgChartConfigSlideout();
  const canCreateResource = ability.can('create', capitalize(orgChartResourceType));
  const t = initTranslations(`org_chart.${orgChartResourceType}_config_slideout.highest_ranking`);
  const { rootResource, resources, dataIsLoading } = useOrgChartConfigSlideout();
  const [openModal, setOpenModal] = useState(false);
  const defaultValue = stringifyNumber(rootResource?.id);
  const defaultOrLoading = !!defaultValue || dataIsLoading;
  const [currentValue, setCurrentValue] = useState<string | null>(null);
  const options = selectOptions(resources);

  const [updateRootResource, result] = useUpdateRootResourceMutation();
  const { isLoading: isSubmittingRootResource, error: rootResourceSubmittingError } = result;

  useEffect(() => {
    setCurrentValue(stringifyNumber(rootResource?.id));
  }, [rootResource?.id]);

  useDisplayFlashOnResponse({
    errorMessage: messageFromError(rootResourceSubmittingError)?.join(', '),
    result,
  });

  return (
    <>
      <FlexContainer align='flex-start' direction='column' id='highest-ranking-block'>
        <HighestRankingTitleWrapper>
          <Icon name='star' weight='solid' />
          <HighestRankingTitle>{t('title')}</HighestRankingTitle>
        </HighestRankingTitleWrapper>
        <HeaderDescription>{t('description')}</HeaderDescription>
        <SelectWrapper>
          <SingleSelectField
            MenuList={canCreateResource ? MenuListButtonCreateNew : undefined}
            className={`root-${orgChartResourceType}-select`}
            defaultValue={defaultValue}
            disabled={defaultOrLoading}
            loading={dataIsLoading || isSubmittingRootResource}
            onDefaultSelected={() => {
              if (defaultValue) {
                updateRootResource({
                  resourceId: defaultValue,
                  resourceType: orgChartResourceType,
                });
                setCurrentValue(defaultValue);
              }
            }}
            onNonDefaultSelected={(value: string) => {
              setCurrentValue(value);
              updateRootResource({ resourceId: value, resourceType: orgChartResourceType });
            }}
            options={options}
            placeholder={t('select_placeholder')}
            value={currentValue}
          />
        </SelectWrapper>
        {rootResource && resources.length > 1 && (
          <Link behavesAs='button' onClick={() => setOpenModal(true)} text={t('replace_link')} />
        )}
      </FlexContainer>
      {openModal && (
        <OrgChartConfigSlideoutReplaceRootResourceModal
          closeModal={() => setOpenModal(false)}
          defaultValue={currentValue}
          options={options}
        />
      )}
      <Line margin={`${theme.constants.spacerMd2} 0`} />
    </>
  );
};

export default OrgChartConfigSlideoutHighestRanking;
