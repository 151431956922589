import React from 'react';
import { CustomNodeElementProps } from 'react-d3-tree/lib/types/common';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { OrgChartData, OrgChartTranslationPrefix } from '../../../../types/OrgChart';
import FlexContainer from '../../../styled/FlexContainer';
import OrgChartButton from './OrgChartButton';

type OrgChartCardWrapperProps<T> = {
  event: CustomNodeElementProps;
  onClick?: (data: T, event: CustomNodeElementProps) => void;
  translationPrefix: OrgChartTranslationPrefix;
};

const OrgChartCardWrapperButton = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  overflow: visible;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd3} ${constants.spacerSm3} ${constants.spacerLg2} ${constants.spacerSm3}`};

  :focus {
    outline: 0;
  }
`;

const ButtonContainer = styled(FlexContainer)`
  margin-top: -${({ theme: { constants } }) => `${constants.spacerLg2}`};
`;

const OrgChartCardWrapper = function <T extends OrgChartData>({
  children,
  event,
  onClick,
  translationPrefix,
}: React.PropsWithChildren<OrgChartCardWrapperProps<T>>) {
  const nodeData = event.hierarchyPointNode.data as unknown as T;
  const t = initTranslations(translationPrefix);

  return (
    <foreignObject
      height={event.hierarchyPointNode.height}
      width={event.hierarchyPointNode.width}
      x={event.hierarchyPointNode.width ? -event.hierarchyPointNode.width / 2 : 0}
      y={0}
    >
      <OrgChartCardWrapperButton
        aria-label={t('card_aria_label', { name: nodeData.name })}
        onClick={() => {
          onClick && onClick(nodeData, event);
        }}
      >
        {children}
      </OrgChartCardWrapperButton>
      <ButtonContainer id={`node-${nodeData.id}-org-chart-button`} justifyContent='center'>
        <OrgChartButton
          descendantsCount={nodeData.descendantsCount}
          node={event}
          toggleNode={event.toggleNode}
        />
      </ButtonContainer>
    </foreignObject>
  );
};

export default OrgChartCardWrapper;
