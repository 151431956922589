import { animated, useSpring } from '@react-spring/web';
import React, { Dispatch, useState } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import IconButton from '../../../../design_system/buttons/IconButton';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontMd1, fontSm5 } from '../../../../styled/TypeSystem';
import AvatarsGroup from '../../../shared/AvatarsGroup/AvatarsGroup';
import { getChevronIconName } from '../../../shared/helpers';
import CurriculumCountBadge from '../../shared/CurriculumCountBadge';
import { TableAction, ViewByGroup } from '../libraryReducer';
import LibraryTable from '../LibraryTable/LibraryTable';
import ManageContentBadge from './ManageContentBadge';

const ViewByHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  padding-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const Title = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  ${TruncatedText({})};
  ${fontMd1};
`;

const ChevronWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme: { constants } }) => constants.heightSm};
  width: 2rem;
  flex-shrink: 0;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ theme: { vars } }) => vars.foundationHover};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
`;

const LeftSideHeader = styled.div`
  display: flex;
  align-items: center;
`;

const EmptyState = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: ${({ theme: { constants } }) => constants.spacerSm3} 0 0
    ${({ theme: { constants } }) => constants.spacerMd2};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-style: italic;
  ${fontSm5};
`;

export type Props = ViewByGroup & {
  filtersQuery: string;
  tableDispatch: Dispatch<TableAction>;
};

const t = initTranslations('curriculums.content.view_by_all_groups');

const ViewBySection = (props: Props) => {
  const { id, title, filtersQuery, tableDispatch, totalCurriculumsCount, totalUserCount, users } =
    props;
  const ability = useCurrentUserAbilities();
  const canAddContent = ability.can('update', { id, __typename: 'Group' });
  const [isOpen, setIsOpen] = useState(false);
  const [ref, bounds] = useMeasure();
  const containerStyle = useSpring({
    height: isOpen ? bounds.height : 0,
    config: { tension: 400, friction: 40 },
  });

  const isFiltering = /.+\b(?=sort_by)/.test(filtersQuery);

  return (
    <>
      <ViewByHeaderContainer className={`view-by-header-${id}`}>
        <LeftSideHeader>
          <ChevronWrapper>
            <IconButton
              ariaLabel={t('aria_label', {
                curriculumTitle: title,
                options: isOpen ? t('collapse') : t('expand'),
              })}
              buttonSize='sm'
              id={`toggle-expand-view-by-row-${id}`}
              name={getChevronIconName({ isActive: !isOpen, initialDirection: 'right' })}
              onClick={() => setIsOpen(!isOpen)}
              weight='light'
            />
          </ChevronWrapper>
          <Title>{title}</Title>
          {!!totalUserCount && (
            <AvatarsGroup
              i18Key='avatars'
              totalUserCount={totalUserCount}
              users={users.slice(0, 3)}
            />
          )}
        </LeftSideHeader>
        {canAddContent ? (
          <ManageContentBadge id={id} totalCurriculumsCount={totalCurriculumsCount} />
        ) : (
          <CurriculumCountBadge totalCurriculumsCount={totalCurriculumsCount} />
        )}
      </ViewByHeaderContainer>
      <animated.div id={`child-container-${id}`} style={containerStyle}>
        <div id={`child-container-contents-${id}`} ref={ref}>
          {isOpen ? (
            totalCurriculumsCount === 0 ? (
              isFiltering ? (
                <EmptyState>{t('empty_group.no_content_with_filter')}</EmptyState>
              ) : (
                <EmptyState>{t('empty_group.no_content_in_group')}</EmptyState>
              )
            ) : (
              <LibraryTable
                filtersQuery={filtersQuery}
                hideEmptyState
                isFiltering={!!filtersQuery.replace(/&*sector=.+?(?=&|$)/, '')}
                tableDispatch={tableDispatch}
              />
            )
          ) : null}
        </div>
      </animated.div>
    </>
  );
};

export default ViewBySection;
