import styled from 'styled-components';

import { fontSm5 } from '../../../styled/TypeSystem';
import { LogoBackgroundColor } from './types';

export const Title = styled.h3`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm5};
  font-weight: 400;
`;

export const AccountSettingsSection = styled.section`
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  padding-top: ${({ theme: { constants } }) => constants.spacerMd3};
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const BrandStylesSection = styled.section`
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  padding-top: ${({ theme: { constants } }) => constants.spacerMd3};
  &:first-child {
    border-top: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LogoWrapper = styled.div<LogoBackgroundColor>`
  display: flex;
  justify-content: center;
  background-color: ${({ logoBackgroundColor }) => logoBackgroundColor};
  text-align: center;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  max-width: 24rem;
  max-height: 12rem;
  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
`;

export const InputsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const TextStyleBarWrapper = styled.div`
  margin: ${({ theme: { constants } }) => constants.spacerMd3} 0;
`;

export const BrandStylesText = styled.p`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin: 0;
`;
