import React, { useReducer } from 'react';

import EmptyStateDark from '../../../../images/reports-empty-state-dark.svg';
import EmptyState from '../../../../images/reports-empty-state.svg';
import initTranslations from '../../../../lib/initTranslations';
import { useGetNotificationsQuery } from '../../../../redux/services/resourceApis/notifications/notificationsApi';
import { ManageNotificationsOption } from '../../../../redux/services/resourceApis/notifications/types';
import NoResults from '../../../design_system/Triage/NoResults';
import Pagination from '../../../design_system/Triage/Paginate';
import MessageCardLoader from './shared/./MessageCardLoader/MessageCardLoader';
import NotificationCard from './NotificationCard/NotificationCard';
import { pageReducer } from './reducer';
import { NotificationsDate, NotificationsGroupWrapper } from './shared/styles';

const t = initTranslations('notifications');

const initialState: ManageNotificationsOption = {
  page: 1,
};

const NotificationsIndexView = () => {
  const [pageState, dispatch] = useReducer(pageReducer, initialState);
  const { data, isFetching, isError } = useGetNotificationsQuery({
    page: pageState.page,
  });

  if (isFetching) return <MessageCardLoader />;
  if (isError) return <div>{t('error')}</div>;
  if (!data) return <></>;

  const {
    notifications: notifications,
    limit_value: limitValue,
    total_pages: totalPages,
    total_notifications_count: totalNotificationsCount,
  } = data;

  const groupKeys = Object.keys(notifications);

  return (
    <>
      {groupKeys.length ? (
        <>
          {groupKeys.map((groupKey) => (
            <NotificationsGroupWrapper key={`notifications-on-${groupKey}`}>
              <NotificationsDate>{groupKey}</NotificationsDate>
              {notifications[groupKey].map((notification) => (
                <NotificationCard
                  key={`notification-${notification.id}`}
                  notification={notification}
                />
              ))}
            </NotificationsGroupWrapper>
          ))}
          <Pagination
            activePage={pageState.page}
            itemsCountPerPage={limitValue}
            onChange={(page) => {
              dispatch({ type: 'changePage', page });
            }}
            showPaginationDetails
            totalItemsCount={totalNotificationsCount}
            totalPages={totalPages}
          />
        </>
      ) : (
        <NoResults
          darkImage={EmptyStateDark}
          heading={t('empty_state')}
          iconWidth='45%'
          lightImage={EmptyState}
        />
      )}
    </>
  );
};

export default NotificationsIndexView;
