import styled, { css } from 'styled-components';

import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import InputField from '../../../../../../design_system/Triage/InputField';
import InputFieldWithCharacterCounter from '../../../../../../design_system/Triage/InputFieldWithCharacterCounter';
import { fontSm4 } from '../../../../../../styled/TypeSystem';

export const FormContentWrapper = styled.div`
  ${({ theme: { constants, vars } }) => css`
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusLg};
    padding: ${constants.spacerMd2};
  `};
`;

export const StyledButton = styled(DefaultButton)<{ isDisabled?: boolean }>`
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.vars.foundationBase3 : theme.vars.accentPrimaryDefault};
`;

export const StyledEditButton = styled(StyledButton)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  color: ${({ theme: { vars } }) => vars.textDefault};
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
`;

export const EditFormWrapper = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd1};

    & label {
      ${fontSm4};
    }
  `};
`;

export const CTAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

export const StyledInput = styled(InputField)`
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.foundationBase3}`};

  ::placeholder {
    ${fontSm4};
  }
`;

export const StyledInputFieldWithCharacterCounter = styled(InputFieldWithCharacterCounter)`
  ::placeholder {
    ${fontSm4};
  }
`;
