import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useEffect, useMemo, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

import useCurrentAccount from '../../../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../../../lib/initTranslations';
import { useUpdateFlowchartMutation } from '../../../../../../../../redux/services/resourceApis/flowcharts/flowchartsApi';
import { MetaUserAccess } from '../../../../../../../../types/Curriculum';
import { FlowchartCurriculumElement } from '../../../../../../../../types/CurriculumElement';
import { Emoji } from '../../../../../../../../types/Emoji';
import Icon from '../../../../../../../design_system/display/icons/Icon';
import { useFlashNotification } from '../../../../../../../FlashNotificationContext';
import EmojiSelect from '../../../../../../ContentLibrary/components/SubjectEmoji/EmojiSelect';
import { routes } from '../../../../../../publicApplication/applicationRouter';
import routeTo from '../../../../../../publicApplication/routeTo';
import useActiveMenuHandler from '../../../../../../publicApplication/utils/useActiveMenuHandler';
import StatusBadgeActions from '../../../../../../shared/CurriculumElements/CurriculumElementActionRow/StatusBadgeActions';
import EditableCurriculumElementTitle from '../../../../../../shared/CurriculumElements/EditableCurriculumElementTitle';
import CurriculumElementThreeDot from '../../../../../../shared/CurriculumElementThreeDot/CurriculumElementThreeDot';
import { viewableUserAccess } from '../../../../../../shared/helpers';
import {
  ArrowIconWrapper,
  CurriculumElementThreeDotWrapper,
  EmojiWrapper,
  GripVerticalIconWrapper,
  OutlineCurriculumElementRowContainer,
  OutlineRowContainer,
  StyledBadge,
} from '../../../../../shared/CurriculumElementRowStyles';
import { RowActionsContainer, RowCardBody } from '../../../../../shared/CurriculumRowStyles';

const t = initTranslations('curriculums_view');

export type Props = {
  emoji: Emoji;
  isLocked?: boolean;
  blockEditPrivileges: boolean;
  curriculumElement: FlowchartCurriculumElement;
  curriculumId: number;
  userAccess?: MetaUserAccess;
  provided: DraggableProvided;
};

const OutlineFlowchartTableRow = ({
  emoji,
  isLocked,
  blockEditPrivileges,
  curriculumElement,
  curriculumId,
  userAccess,
  provided,
}: Props) => {
  const { slug } = useCurrentAccount();
  const { elementId: flowchartId, element } = curriculumElement;
  const { title, status } = element;
  const [isEditing, setIsEditing] = useState(false);
  const [updateFlowchart, { isSuccess, error }] = useUpdateFlowchartMutation();
  const { isMenuOpen } = useActiveMenuHandler({
    menuId: `curriculum-element-three-dot-${curriculumElement.id}`,
  });

  const { flash } = useFlashNotification();

  useEffect(() => {
    if (error) {
      flash('error', t('update_title_error'));
    }
  }, [error, flash]);

  const flowchartUrl = useMemo(() => {
    if (!userAccess || userAccess === 'pending') {
      return routes.curriculumRequestAccess({ id: curriculumId, slug }).href;
    } else if (viewableUserAccess(userAccess)) {
      return routes.flowchartConsume({ slug, id: flowchartId }).href;
    } else {
      return routes.flowchartEditor({ slug, id: flowchartId }).href;
    }
  }, [flowchartId, curriculumId, slug, userAccess]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='curriculum-element-item'
      id={`curriculum-element-flowchart-${flowchartId}`}
    >
      <OutlineCurriculumElementRowContainer className='outline-curriculum-element-container'>
        <GripVerticalIconWrapper remainVisible={isMenuOpen}>
          <Icon name='grip-vertical' weight='solid' />
        </GripVerticalIconWrapper>
        <OutlineRowContainer>
          <RowCardBody isLocked={isLocked || blockEditPrivileges}>
            <StyledBadge text={t('flowchart.badge')} type='info' />
            <EmojiWrapper id={`flowchart-${flowchartId}-row-emoji-picker`}>
              <EmojiSelect
                clearEmoji={() => {
                  updateFlowchart({ id: flowchartId, emoji: null });
                }}
                emoji={emoji}
                noEmojiIconName='shapes'
                onEmojiSelect={(emoji: BaseEmoji) => {
                  updateFlowchart({ emoji: emoji.native, id: flowchartId });
                }}
                usePortal
              />
            </EmojiWrapper>
            <EditableCurriculumElementTitle
              editable={!blockEditPrivileges}
              id={flowchartId}
              isEditing={isEditing}
              isLocked={isLocked}
              isSuccess={isSuccess}
              route={flowchartUrl}
              setIsEditing={setIsEditing}
              title={title}
              updateElement={({ id, title }) => updateFlowchart({ id, title })}
            />
            <RowActionsContainer>
              <StatusBadgeActions
                curriculumElement={{ id: curriculumElement.id, status }}
                curriculumId={curriculumId}
              />
              <ArrowIconWrapper onClick={() => routeTo(flowchartUrl)}>
                <Icon name='arrow-right' />
              </ArrowIconWrapper>
            </RowActionsContainer>
          </RowCardBody>
        </OutlineRowContainer>
        <CurriculumElementThreeDotWrapper remainVisible={isMenuOpen}>
          {!blockEditPrivileges && (
            <CurriculumElementThreeDot
              canBeModified
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              eSignatureDisplayedAndRequired={false}
              isCurriculumLocked={isLocked}
              renameClickHandler={() => setIsEditing(true)}
            />
          )}
        </CurriculumElementThreeDotWrapper>
      </OutlineCurriculumElementRowContainer>
    </div>
  );
};

export default OutlineFlowchartTableRow;
