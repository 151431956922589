import React from 'react';
import { useTheme } from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';

const t = initTranslations('editor_toolbar.notify');

export type Props = {
  setShowNotifyModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotifyButton = ({ setShowNotifyModal }: Props) => {
  const theme = useTheme();

  return (
    <DefaultButton
      buttonType='tertiary'
      iconColor={theme.vars.textDefault}
      iconName='bell-on'
      id='notify-team-changes-button'
      onClick={() => {
        setShowNotifyModal(true);
      }}
      text={t('notify_button')}
    />
  );
};

export default NotifyButton;
