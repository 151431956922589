import styled from 'styled-components';

import {
  PlanCardStyles,
  StyledPlanCardTitle,
} from '../../BillingFullScreenOverlay/PlanCards/styles';

export const PlanCardRadioFieldWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const StyledNewPlanCardTitle = styled(StyledPlanCardTitle)<{
  isSelected?: boolean;
}>`
  ${({ isSelected }) => !isSelected && `opacity: 0.5;`};

  &:after {
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
  }
`;

export const StyledPlanCardWrapper = styled.div`
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd3} ${constants.spacerLg1} ${constants.spacerLg1}`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};

  ${PlanCardStyles};
`;
