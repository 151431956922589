import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import GroupManagersEmptyState from '../../../../../../../assets/images/empty_state/group-managers-empty-state.svg';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import convertToOption from '../../../../../lib/convertToOption';
import initTranslations from '../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import {
  useGetGroupManagersQuery,
  useGetGroupQuery,
  useUpdateGroupManagersMutation,
} from '../../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupWithAssociations } from '../../../../../redux/services/resourceApis/groups/types';
import { useGetModalUsersQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import { User } from '../../../../../types/User';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import { OptionWithSourceBadgeProps } from '../../../../design_system/core/SelectOption/types';
import DetailedAvatar from '../../../../design_system/display/DetailedAvatar/DetailedAvatar';
import Hoverable from '../../../../design_system/Hoverable';
import Link from '../../../../design_system/Link';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import ThemedImage from '../../../../design_system/ThemedImage';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import { fontSm5 } from '../../../../styled/TypeSystem';

const t = initTranslations('groups.modals.group_managers');

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 21rem;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationHover};
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

type UserRowProps = {
  member: User;
  removeManager: (memberId: number) => void;
};

const ManagerRow = ({ member, removeManager }: UserRowProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { id: currentUserId } = useCurrentUser();
  const { avatar, id, name, title } = member;
  const canRemove = useIsAdmin() || id !== currentUserId;

  return (
    <Hoverable setIsHovered={setIsHovered}>
      <FlexRow className='manager-row' id={`manager-row-${id}`}>
        <DetailedAvatar
          avatarImage={avatar}
          id={`user-detailed-avatar-${id}`}
          label={name}
          subLabel={title}
        />
        {isHovered && canRemove && (
          <Link
            behavesAs='button'
            onClick={() => {
              removeManager(member.id);
            }}
            text={t('remove')}
          />
        )}
      </FlexRow>
    </Hoverable>
  );
};

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextWrapper = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const EmptyState = () => {
  return (
    <EmptyStateWrapper>
      <ThemedImage
        alt={t('empty_state.heading')}
        darkImage={GroupManagersEmptyState}
        height='auto'
        lightImage={GroupManagersEmptyState}
        width='50%'
      />
      <TextWrapper>{t('empty_state.heading')}</TextWrapper>
    </EmptyStateWrapper>
  );
};

type ModalProps = Pick<GroupManagersModalProps, 'onCloseRequest'> & {
  group: GroupWithAssociations;
  users: User[];
  managerIds: number[];
};

const Modal = ({ group, users, managerIds, onCloseRequest }: ModalProps) => {
  const [updateGroupManagers, result] = useUpdateGroupManagersMutation();
  const { isSuccess: isSubmitSuccess, isLoading: isSubmitting } = result;

  const [hasChanges, setHasChanges] = useState(false);
  const [currentGroupManagerIds, setCurrentGroupManagerIds] = useState<number[]>(managerIds);
  const [addedManagerIds, setAddedManagerIds] = useState<number[]>([]);

  const newManagerState = useMemo(
    () => [...currentGroupManagerIds, ...addedManagerIds],
    [currentGroupManagerIds, addedManagerIds]
  );

  const assignedUserIds = useMemo(() => {
    return newManagerState.filter((id) => !managerIds.includes(id));
  }, [newManagerState, managerIds]);

  const unassignedUserIds = useMemo(() => {
    return managerIds.filter((id) => !newManagerState.includes(id));
  }, [newManagerState, managerIds]);

  const currentGroupManagers = useMemo(() => {
    return users.filter((user) => currentGroupManagerIds.includes(user.id));
  }, [currentGroupManagerIds, users]);

  const assignableUsers = useMemo(() => {
    return users.filter((user) => !currentGroupManagerIds.includes(user.id));
  }, [currentGroupManagerIds, users]);

  const userOptions: OptionWithSourceBadgeProps[] = useMemo(() => {
    return formatOptions(convertToOption(assignableUsers)).map((option) => {
      const user = users.find((user) => user.id.toString() === option.value);
      return {
        ...option,
        hasAvatar: true,
        metaValue: user?.title,
        avatarName: option.label as string,
        avatarImage: user?.avatar,
        sourceName: 'users',
      };
    });
  }, [assignableUsers, users]);

  const renderEmptyState = !currentGroupManagers.length;

  // Close the modal on save
  useEffect(() => {
    if (isSubmitSuccess) {
      onCloseRequest();
    }
  }, [isSubmitSuccess, onCloseRequest]);

  const taskModalArgs: TaskModalProps = {
    heapModalName: 'group-managers-modal',
    isDisabled: !hasChanges,
    onCloseRequest,
    primaryButtonTask: () => {
      setHasChanges(true);
      updateGroupManagers({
        id: group.id,
        assigned_user_ids: assignedUserIds,
        unassigned_user_ids: unassignedUserIds,
      });
    },
    primaryButtonText: t('save'),
    processing: isSubmitting,
    title: t('title'),
    subtitle: t('subtitle'),
    scrollInsideBody: !renderEmptyState,
    headerChildren: (
      <MultiSelectField
        defaultValue={null}
        disabled={false}
        isClearable
        isMulti
        isSearchable
        isValid
        loading={isSubmitting}
        menuPlacement='bottom'
        menuPosition='absolute'
        onNonDefaultSelected={(memberIds: string[]) => {
          setHasChanges(true);
          setAddedManagerIds([...memberIds.map(Number)]);
        }}
        placeholder={t('search_by_name')}
        required
        size='md'
        sourceBadgeOptions={userOptions}
        useSourceBadge
        value={addedManagerIds.map(String)}
      />
    ),
  };

  return (
    <TaskModal {...taskModalArgs}>
      {renderEmptyState ? (
        <EmptyState />
      ) : (
        <FlexContainer>
          {currentGroupManagers.map((member) => (
            <ManagerRow
              key={member.id}
              member={member}
              removeManager={(memberId: number) => {
                setHasChanges(true);
                setCurrentGroupManagerIds((prev) => prev.filter((id) => id !== memberId));
              }}
            />
          ))}
        </FlexContainer>
      )}
    </TaskModal>
  );
};

export type GroupManagersModalProps = {
  onCloseRequest: () => void;
  groupId: number;
};

const GroupManagersModal = ({ groupId, onCloseRequest }: GroupManagersModalProps) => {
  const { data: group, error: groupError, isLoading: isGroupLoading } = useGetGroupQuery(groupId);
  const { data: users, isLoading: isUsersLoading, error: usersError } = useGetModalUsersQuery();
  const { data: managers, isLoading: isGroupsManagersLoading } = useGetGroupManagersQuery(groupId);

  const isLoading = isGroupLoading || isUsersLoading || isGroupsManagersLoading;
  const error = groupError || usersError;

  if (isLoading) return <></>;
  if (error) return <BasicErrorDisplay error={error} />;
  if (!group || !users || !managers) return <></>;

  return (
    <Modal
      group={group}
      managerIds={managers.map((m) => m.id)}
      onCloseRequest={onCloseRequest}
      users={users}
    />
  );
};

export default GroupManagersModal;
