import { Formik, FormikErrors } from 'formik';
import _ from 'lodash';
import React from 'react';

import { useOnboarding } from '../../../../../contexts/OnboardingContext';
import initTranslations from '../../../../../lib/initTranslations';
import isValidFullName from '../../../../../lib/isValidName';
import isValidPhoneNumber from '../../../../../lib/isValidPhoneNumber';
import { CUSTOM_SOURCES } from '../shared/constants/onboarding';
import { FormWrapper } from './styles';
import { UserOnboardingInfoStepData } from './types';
import UserInfoStepForm from './UserInfoStepForm';

const t = initTranslations('home.onboarding.user_info_step');

export const UserInfoStep = () => {
  const { formData, setFormData, sliderRef } = useOnboarding();

  const initialValues = _.omit(formData, 'avatar', 'logo');

  const onSubmit = (values: UserOnboardingInfoStepData) => {
    setFormData({ ...formData, ...values });
    sliderRef?.current?.slickNext();
  };

  const validate = ({ user_name, phone, source, custom_source }: UserOnboardingInfoStepData) => {
    const errors: FormikErrors<UserOnboardingInfoStepData> = {};

    if (!user_name) {
      errors.user_name = t('errors.name.required');
    } else if (!isValidFullName(user_name)) {
      errors.user_name = t('errors.name.full_name');
    }

    if (!source) {
      errors.source = t('errors.source.required');
    }

    if (phone) {
      if (!isValidPhoneNumber(phone)) {
        errors.phone = t('errors.phone.invalid');
      }
    }

    if (!custom_source) {
      if (CUSTOM_SOURCES.includes(source)) {
        errors.custom_source = t('errors.custom_source.required');
      }
    }

    return errors;
  };

  return (
    <FormWrapper>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
        <UserInfoStepForm />
      </Formik>
    </FormWrapper>
  );
};
