import React, { Dispatch, SetStateAction, useState } from 'react';

import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import { SimpleCurriculum } from '../../../../../types/Curriculum';
import { ElementKind } from '../../../../../types/CurriculumElement';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import MoveElementToNewCurriculumModal from './MoveElementToNewCurriculumModal/MoveElementToNewCurriculumModal';

const t = initTranslations('move_element_modal');

function setCurriculumOptions(curriculums: SimpleCurriculum[]) {
  return curriculums.map((cur) => ({
    label: cur.title,
    value: `${cur.id}`,
    hasSubOption: false,
    searchableTerm: cur.title,
  }));
}

export interface Props {
  disabled: boolean;
  curriculums: SimpleCurriculum[];
  curriculumElementId: number;
  processing: boolean;
  moveElement: () => void;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  selectedValue: string | null;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  elementName: string;
  closeModal: () => void;
  elementKind: ElementKind;
  movementRestricted: boolean;
}

const MoveCurriculumElementModal = ({
  disabled,
  curriculums,
  curriculumElementId,
  closeModal,
  elementName,
  processing,
  moveElement,
  selectedValue,
  setDisabled,
  setSelectedValue,
  elementKind,
  movementRestricted,
}: Props) => {
  const options = setCurriculumOptions(curriculums);
  const [openCreateCurriculumModal, setOpenCreateCurriculumModal] = useState(false);
  const {
    curriculum: { singular: curriculumTermSingular },
  } = useAccountTerminology();

  const { termSingular } = useCurriculumElement()({ elementKind });

  const taskModalArgs: TaskModalProps = {
    isDisabled: disabled,
    heapModalName: 'move-curriculum-modal',
    title: t('title', { element: termSingular }),
    onCloseRequest: closeModal,
    processing,
    primaryButtonText: t('move'),
    primaryButtonTask: () => moveElement(),
    secondaryButtonText: t('cancel'),
    tertiaryButton: {
      text: t('add_element_to_new_curriculum', {
        element: termSingular.toLowerCase(),
        subject: curriculumTermSingular.toLowerCase(),
      }),
      task: () => {
        setOpenCreateCurriculumModal(true);
      },
    },
  };
  return (
    <>
      {openCreateCurriculumModal ? (
        <MoveElementToNewCurriculumModal
          curriculumElementId={curriculumElementId}
          movementRestricted={movementRestricted}
          onCloseRequest={() => {
            setOpenCreateCurriculumModal(false);
          }}
          onCreateCloseRequest={closeModal}
        />
      ) : (
        <TaskModal {...taskModalArgs} desktopSize='lg'>
          <SingleSelectField
            className='target-curriculum-select'
            defaultValue={null}
            fieldLabelText={t('label', { element: elementName })}
            isSearchable
            onDefaultSelected={() => {
              setDisabled(true);
            }}
            onNonDefaultSelected={(value: string) => {
              setDisabled(false);
              setSelectedValue(value);
            }}
            options={formatOptions(options)}
            placeholder={t('placeholder', { subject: curriculumTermSingular.toLowerCase() })}
            value={selectedValue}
          />
        </TaskModal>
      )}
    </>
  );
};

export default MoveCurriculumElementModal;
