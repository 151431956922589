import React from 'react';

import {
  StyledDateColumn,
  StyledTime,
} from '../../../AllUsersReportPage/AllUsersReportTable/Columns/styles';
import { ColumnComponentProps } from '../types';

const DateCompleted = ({ record: { date_completed } }: ColumnComponentProps) => {
  return date_completed.date ? (
    <StyledDateColumn>
      <span>{date_completed.date}</span>
      <StyledTime>{date_completed.time}</StyledTime>
    </StyledDateColumn>
  ) : (
    <StyledDateColumn>-</StyledDateColumn>
  );
};

export default DateCompleted;
