import React from 'react';
import styled from 'styled-components';

import useUserPermissionSettings from '../../../../hooks/users/useUserPermissionSettings';
import { Permission, PermissionKeys } from '../../../../types/Permission';
import Checkbox from '../../../design_system/input/controls/Checkbox';
import SingleSelect from '../../../design_system/Triage/fields/SingleSelectField';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontSm5 } from '../../../styled/TypeSystem';
import { UpdatedCandidates } from './BulkUserUploadPayrollModal';

const TableRow = styled.div`
  width: 100%;
  display: flex;
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-left: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  &:last-child {
    border-bottom: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  }
`;

export const TableCol = styled.div`
  display: flex;
  align-items: center;
  width: 33.33%;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerSm3}`};
  border-right: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  color: ${(props) => props.theme.vars.textDefault};

  @media (min-width: ${mediaBreakpointPxSm}) {
    padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  }
  ${fontSm5};

  label {
    margin-bottom: 0;
  }
`;

const NameInner = styled.div`
  @media (min-width: ${mediaBreakpointPxSm}) {
    padding-left: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

const ColName = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${(props) => props.theme.vars.textDefault};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColEmail = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledSingleSelectField = styled(SingleSelect)`
  width: 100%;
  margin-top: 0 !important;
`;

type Props = {
  candidates: UpdatedCandidates[];
  setCandidates: (user: UpdatedCandidates[]) => void;
  index: number;
  user: UpdatedCandidates;
};

const ModalTableRow = ({ candidates, setCandidates, index, user }: Props) => {
  const { permissionSettings } = useUserPermissionSettings();
  const permissions = Object.keys(permissionSettings);

  const PermissionOptions = () => {
    return permissions.map((key: PermissionKeys) => {
      return {
        label: Permission[key],
        value: Permission[key],
        searchableTerm: Permission[key],
      };
    });
  };

  const EmailsOptions = (arr: string[]) => {
    return arr.map((email: string) => {
      return {
        label: email,
        value: email,
        searchableTerm: email,
      };
    });
  };

  const handleCheckbox = (index: number) => {
    setCandidates(
      candidates.map((item, i) => ({
        ...item,
        selected: i === index ? !item.selected : item.selected,
      }))
    );
  };

  const handlePermissions = (index: number, permission: string) => {
    setCandidates(
      candidates.map((item, i) => ({
        ...item,
        permission: i === index ? permission : item.permission,
      }))
    );
  };

  const handleEmails = (index: number, email: string) => {
    setCandidates(
      candidates.map((item, i) => ({
        ...item,
        email: i === index ? email : item.email,
      }))
    );
  };

  return (
    <TableRow>
      <TableCol>
        <Checkbox
          checked={user.selected}
          id={`bulk_user_upload_${user.id}`}
          name='name'
          onCheck={() => handleCheckbox(index)}
        />
        <NameInner>
          <ColName>{user.name}</ColName>
          <ColTitle>{user.title}</ColTitle>
        </NameInner>
      </TableCol>
      <TableCol>
        {user.emails.length === 1 ? (
          <ColEmail>{user.emails[0]}</ColEmail>
        ) : (
          <StyledSingleSelectField
            defaultValue={user.email}
            isSearchable={false}
            name='emails'
            onNonDefaultSelected={(email: string) => handleEmails(index, email)}
            options={EmailsOptions(user.emails)}
            value={user.email}
          />
        )}
      </TableCol>
      <TableCol>
        <StyledSingleSelectField
          className='bulk-user-upload-permission-select'
          defaultValue={user.permission}
          isSearchable={false}
          name='permission'
          onNonDefaultSelected={(permission: string) => handlePermissions(index, permission)}
          options={PermissionOptions()}
          value={user.permission}
        />
      </TableCol>
    </TableRow>
  );
};

export default ModalTableRow;
