import React from 'react';

import Icon from '../../../../../design_system/display/icons/Icon';
import StatusPill from '../../shared/StatusPill/StatusPill';
import { IconWrapper, TextWrapper, Title } from '../../shared/styles';
import { BoardSelectionDropdownMenuOptionContainer } from './styles';
import { BoardSelectionDropdownMenuOptionProps } from './types';

const BoardSelectionDropdownMenuOption = ({
  board,
  isSelected,
  onSelect,
}: BoardSelectionDropdownMenuOptionProps) => {
  const isDraft = board.status === 'draft';

  return (
    <BoardSelectionDropdownMenuOptionContainer
      className='board-selection-menu-option'
      id={`${board.id}-option`}
      onClick={onSelect}
    >
      <TextWrapper textAlign='flex-start'>
        <IconWrapper>{isSelected && <Icon name='check' size='xs' weight='regular' />}</IconWrapper>
        <Title isSelected={isSelected}>{board.name}</Title>
      </TextWrapper>

      <StatusPill isDraft={isDraft} />
    </BoardSelectionDropdownMenuOptionContainer>
  );
};

export default BoardSelectionDropdownMenuOption;
