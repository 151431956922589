import React from 'react';
import { PaletteOption, getPrimaryPreviewColor } from 'saguaro';
import { useTheme } from 'styled-components';

import {
  PickerContainer,
  SimplePalettePickerWrapper,
  StyledIcon,
  SwatchSquare,
  SwatchSquareWrapper,
} from './styles';

export interface Props {
  id: string;
  className?: string;
  activePalette: PaletteOption | null;
  palettes: PaletteOption[];
  setPalette: (accent: PaletteOption) => void;
}

const SimplePalettePicker = ({ className, activePalette, palettes, setPalette, id }: Props) => {
  const { vars } = useTheme();

  return (
    <PickerContainer className={className} id={id}>
      <SimplePalettePickerWrapper>
        <SwatchSquareWrapper>
          {palettes?.map((p: PaletteOption) => (
            <SwatchSquare
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              color={getPrimaryPreviewColor(p, vars)}
              key={p}
              onClick={() => {
                setPalette(p);
              }}
            >
              {p === activePalette && <StyledIcon name='check' size='md' weight='solid' />}
            </SwatchSquare>
          ))}
        </SwatchSquareWrapper>
      </SimplePalettePickerWrapper>
    </PickerContainer>
  );
};

export default SimplePalettePicker;
