import React from 'react';

import { SkeletonCircle, SkeletonHeader, SkeletonTextLine } from '../styles';
import { SkeletonTextWidths } from '../types';

const BoardHeaderSkeleton = () => {
  return (
    <SkeletonHeader>
      <SkeletonCircle />

      <SkeletonTextLine width={SkeletonTextWidths.Bigger} />

      <SkeletonTextLine width={SkeletonTextWidths.Medium} />
    </SkeletonHeader>
  );
};

export default BoardHeaderSkeleton;
