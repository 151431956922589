export enum SkeletonTextWidths {
  Biggest = 5.875,
  Bigger = 4.625,
  Big = 4,
  Medium = 2.625,
  Small = 2.125,
}

export interface SkeletonCard {
  textTitles: SkeletonTextWidths[];
}

export type BoardCardSkeletonProps = SkeletonCard;
