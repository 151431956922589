import { IconName } from '@fortawesome/fontawesome-svg-core';

import { elementKinds } from '../../../../../types/CurriculumElement';

// TODO: Once we can create a video remove video from here and update elementKinds instead.
export const dropdownElementKinds = [...elementKinds, 'video'];
export type DropdownElementKind = (typeof dropdownElementKinds)[number];
export type DropdownText = {
  [key in DropdownElementKind]: { iconName: IconName; title: string; description: string };
};
export type ActionRowProps = {
  autoFocus?: boolean;
  curriculumId: number;
  signaturable?: boolean;
};
