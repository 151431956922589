import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import ObjectIsland from '../../../people/Shared/ObjectIsland/ObjectIsland';
import { ObjectIslandAction } from '../../../people/Shared/ObjectIsland/types';
import BulkContentActionsModalsFactory from './BulkContentActionsModalFactory';
import { BulkContentActionsMenuProps, BulkContentActionsModal } from './types';

const BulkActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 3rem;
  width: 100%;
  z-index: 999;
`;

const t = initTranslations('curriculums.content.bulk_actions.menu');

const BulkContentActionsMenu = ({
  selectedIds,
  selectedCount,
  onCloseRequest,
  curriculumsChecked,
  archiveFilterOn,
}: BulkContentActionsMenuProps) => {
  const { exportPdfEnabled } = useCurrentAccount();
  const [modal, setModal] = useState<BulkContentActionsModal>('none');

  const archiveAction: ObjectIslandAction | undefined = useMemo(() => {
    return archiveFilterOn
      ? {
          label: t('unarchive'),
          icon: 'arrow-rotate-left',
          onClick: () => {
            setModal('unarchive');
          },
        }
      : {
          label: t('archive'),
          icon: 'box-archive',
          onClick: () => {
            setModal('archive');
          },
        };
  }, [archiveFilterOn]);

  const exportAction: ObjectIslandAction | undefined = useMemo(() => {
    if (!exportPdfEnabled) return;

    return {
      label: t('export'),
      icon: 'file-export',
      onClick: () => {
        setModal('export');
      },
    };
  }, [exportPdfEnabled]);

  const actions: ObjectIslandAction[] = useMemo(() => {
    const baseActions: ObjectIslandAction[] = [
      {
        label: t('move'),
        icon: 'square-arrow-right',
        onClick: () => {
          setModal('move');
        },
      },
      archiveAction,
      {
        label: t('delete'),
        icon: 'trash-can',
        onClick: () => {
          setModal('delete');
        },
      },
    ];

    if (exportAction) {
      baseActions.unshift(exportAction);
    }

    return baseActions;
  }, [archiveAction, exportAction]);

  return (
    <BulkActionsContainer className='content-bulk-actions-menu'>
      <ObjectIsland
        actions={actions}
        onCloseRequest={onCloseRequest}
        selectedCount={selectedCount}
      />
      <BulkContentActionsModalsFactory
        curriculumsChecked={curriculumsChecked}
        modal={modal}
        onCloseRequest={onCloseRequest}
        selectedIds={selectedIds}
        setBulkActionsModal={setModal}
      />
    </BulkActionsContainer>
  );
};

export default BulkContentActionsMenu;
