import { useCallback, useEffect } from 'react';
import { Route } from 'type-route';
import { AccountState } from 'types/AccountState';
import { CurrentUser } from 'types/CurrentUser';

import { routes } from '../components/application/publicApplication/applicationRouter';
import { InternalIndexFeature } from '../components/application/publicApplication/route_types/internalIndex';
import useAppcues from './useAppcues';
import useDataDog from './useDataDog';
import useDelightedSurveys from './useDelightedSurveys';
import useFullStory from './useFullStory';
import useGoogleTagManager from './useGoogleTagManager';
import useHeap from './useHeap';
import useHightouch from './useHightouch';
import useMutiny from './useMutiny';

export default function useIntegrations(
  route: Route<typeof routes>,
  user: CurrentUser | undefined,
  account: AccountState | undefined
) {
  const { identify: heapIdentify } = useHeap(user, account);
  const { analyticsPageLoadEvent, analyticsIdentify, analyticsGroup } = useHightouch(user, account);

  const page = useCallback(
    (pageName: string) => {
      analyticsPageLoadEvent(pageName);
    },
    [analyticsPageLoadEvent]
  );
  const identify = useCallback(() => {
    analyticsIdentify();
    heapIdentify();
  }, [analyticsIdentify, heapIdentify]);

  const group = useCallback(() => {
    analyticsGroup();
  }, [analyticsGroup]);

  const { init: initFullStory } = useFullStory(route, user, account);
  const { initHeap } = useHeap(user, account);
  const { ddInit, ddPage } = useDataDog();
  const { identify: appcuesIdentify } = useAppcues(user);
  const { mutinyIdentify } = useMutiny(user, account);
  const { pushAccountStatusToDataLayer } = useGoogleTagManager(account);
  const { considerShowingNetPromoter, considerShowingUsability } = useDelightedSurveys(
    user,
    account
  );
  const isLoginPage = route.name === 'login' || route.name === 'universalLogin';
  const isPdfGenerator =
    route.name === 'internalAuthIndex' && route.params.feature === InternalIndexFeature.PDFExport;

  // Site Load Events - Load once. Don't fire with every route.name update.
  useEffect(() => {
    initFullStory();
  }, [initFullStory]);

  useEffect(() => {
    initHeap();
  }, [initHeap]);

  useEffect(() => {
    considerShowingNetPromoter();
  }, [considerShowingNetPromoter]);

  useEffect(() => {
    considerShowingUsability();
  }, [considerShowingUsability]);

  useEffect(() => {
    ddInit();
  }, [ddInit]);

  // Page Load (Click) Events. Update dynamically based on route.name or href.
  useEffect(() => {
    ddPage(route.name || '');
  }, [route.name, ddPage]);

  useEffect(() => {
    appcuesIdentify();
  }, [route.name, appcuesIdentify]);

  useEffect(() => {
    if (isLoginPage) {
      return;
    }
    identify();
  }, [route.name, isLoginPage, identify]);

  useEffect(() => {
    if (isLoginPage) {
      return;
    }
    group();
  }, [route.name, isLoginPage, group]);

  useEffect(() => {
    mutinyIdentify();
  }, [route.name, mutinyIdentify]);

  useEffect(() => {
    pushAccountStatusToDataLayer();
  }, [route.name, pushAccountStatusToDataLayer]);

  useEffect(() => {
    if (!route.name || isLoginPage || isPdfGenerator) {
      return;
    }
    page(route.name);
  }, [route.name, isLoginPage, isPdfGenerator, page]);
}
