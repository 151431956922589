import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { useResponsibilityHeaderTitleHandles } from '../../../../../../hooks/responsibility/useResponsibilityHeaderTitleHandlers';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import Avatar from '../../../../../design_system/display/avatar';
import { Props as AvatarProps } from '../../../../../design_system/display/avatar/Avatar';
import EditorInput from '../../../../shared/EditorInput/EditorInput';
import { ResponsibilityDetailsFormData } from '../ResponsibilityDetails/types';
import { EditResponsibilityDetails, ModalHeaderData } from '../types';
import { AssistiveTextContainer, HeaderAvatarWrapper } from './styles';

type ModalHeaderProps = {
  responsibility?: EditResponsibilityDetails;
  type: 'Group' | 'User';
  headerData: ModalHeaderData;
};

const ModalHeader: FC<ModalHeaderProps> = ({ responsibility, type, headerData }) => {
  const { initialValues, setFieldValue, values } =
    useFormikContext<ResponsibilityDetailsFormData>();
  const { handleTitleValidation, handleBeforeInput, handleChange } =
    useResponsibilityHeaderTitleHandles(initialValues, setFieldValue, values.name);
  const isError = headerData.name === values.name && !!headerData.error;

  const isGroupTypeCard = type === 'Group';
  const avatarProps = (image: string | undefined): AvatarProps => {
    const commonProps = {
      name: responsibility?.responsibiliableName,
      shape: 'circle',
      size: 'xs',
    } as AvatarProps;

    if (isGroupTypeCard) {
      return { ...commonProps, icon: 'users' };
    } else {
      return { ...commonProps, image };
    }
  };

  return (
    <>
      <EditorInput
        $isError={isError}
        className='responsibility-title-header-input'
        onBeforeInput={handleBeforeInput}
        onBlur={handleTitleValidation}
        onChange={handleChange}
        spellCheck={false}
        value={values?.name || ''}
      />
      {isError && (
        <AssistiveTextContainer>
          <AssistiveText id='input-error-text' text={headerData.error} type='error' />
        </AssistiveTextContainer>
      )}
      <HeaderAvatarWrapper isGroupAvatar={isGroupTypeCard}>
        <Avatar {...avatarProps(responsibility?.avatarUrl)} />
        <span>{responsibility?.responsibiliableName}</span>
      </HeaderAvatarWrapper>
    </>
  );
};

export default ModalHeader;
