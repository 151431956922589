import React from 'react';

import { setCurrentQuestionIndex } from '../../../../../../redux/domains/surveyConsume/surveyConsumeSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Icon from '../../../../../design_system/display/icons/Icon';
import {
  ListItemContainer,
  PositionText,
  QuestionText,
} from '../../../shared/SurveyQuestionsList/styles';
import { StyledCheckmarkIcon } from './styles';
import { SurveyQuestionListItemProps } from './types';

const SurveyQuestionListItem = ({
  questionText,
  questionId,
  index,
  displayCheckmarks = true,
}: SurveyQuestionListItemProps) => {
  const dispatch = useAppDispatch();
  const { questionAnswersData, currentQuestionIndex } = useAppSelector(
    (state) => state.surveyConsume
  );
  const isActive = displayCheckmarks && currentQuestionIndex === index;
  const currentQuestionAnswerData = questionAnswersData.find(
    (data) => data.questionId === questionId
  );

  const handleListItemClick = () => {
    if (displayCheckmarks && currentQuestionIndex !== index) {
      dispatch(setCurrentQuestionIndex(index));
    }
  };

  return (
    <ListItemContainer
      id={`survey-question-text-${questionId}`}
      isActive={isActive}
      onClick={handleListItemClick}
    >
      <PositionText>{index + 1}</PositionText>
      <QuestionText isActive={isActive}>{questionText}</QuestionText>
      {displayCheckmarks && (
        <StyledCheckmarkIcon completed={currentQuestionAnswerData?.isAnswered}>
          <Icon name='check' size='2xs' weight='solid' />
        </StyledCheckmarkIcon>
      )}
    </ListItemContainer>
  );
};

export default SurveyQuestionListItem;
