import styled from 'styled-components';

import { mediaBreakpointPxLg } from '../../../../styled/Breakpoint';
import { TEMPLATE_PREVIEW_INFO_PANEL_DESKTOP_WIDTH } from '../styles';

export const TemplatePreviewContentWrapper = styled.div`
  margin: 0 auto;
  overflow-y: scroll;
  width: 55%;
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: calc(100% - ${TEMPLATE_PREVIEW_INFO_PANEL_DESKTOP_WIDTH});
    flex-direction: row;
    text-align: left;
    padding: ${({ theme: { constants } }) => `${constants.spacerLg2} ${constants.spacerLg3}`};
  }
`;

export const TemplatePreviewContentContainer = styled.div`
  max-width: 80rem;
  margin: 0 auto;
`;

export const TemplatePreviewCurriculumElementWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const TemplatePreviewElementContainer = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} auto`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const TemplatePreviewElementContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2} ${constants.spacerMd2}`};

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding: ${({ theme: { constants } }) => `0 ${constants.spacerLg2} ${constants.spacerMd2}`};
  }
`;
