import styled from 'styled-components';

import { fontSm5 } from '../../../styled/TypeSystem';

export const SpecializationContainer = styled.div``;

export const SpecializationHeader = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span[class*='InputWrapper'] {
    margin-right: 0;
  }
`;

export const SpecializationRangeSliderContainer = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen, theme: { constants } }) => (isOpen ? constants.spacerLg1 : '0')};
  margin-top: ${({ isOpen, theme: { constants } }) => (isOpen ? constants.spacerMd1 : '0')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: visible ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: 0.3s ease-in;
`;

export const PopupText = styled.p`
  display: flex;
  color: ${({ theme: { vars } }) => vars.textSurface};
  white-space: nowrap;
  height: 1.125rem;
  overflow: hidden;

  ${fontSm5};
`;

export const ScoreTrack = styled.span`
  display: flex;
  flex-direction: column;
  transition: 0.5s ease;
`;

export const ScoreNumber = styled.span`
  display: inline-block;
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;
