import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { BetaFeaturesSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const BetaFeaturesSection = ({ betaFeatures, id }: BetaFeaturesSectionProps) => {
  const [updateAccountData] = useUpdateAccountDataMutation();

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('beta_features_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={betaFeatures}
            className='beta-features-setting'
            handleToggle={(e) => updateAccountData({ beta_features: e.target.checked })}
            id='beta-features'
            name='beta_features'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('beta_features_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default BetaFeaturesSection;
