import React from 'react';
import styled from 'styled-components';

import { User } from '../../../../../types/User';
import IconButton from '../../../../design_system/buttons/IconButton';
import Avatar from '../../../../design_system/display/avatar';
import Badge from '../../../../design_system/display/badge';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

const AssignedUserItemWrapper = styled.div<{ isNew?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  position: relative;
  padding-right: ${({ isNew, theme: { constants } }) => isNew && constants.spacerLg1};
  &:last-child {
    margin-bottom: 0;
  }
`;

const AvatarWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const UserDetails = styled.div`
  width: calc(100% - 40px - 0.5rem);
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
`;

const UserNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${TruncatedText({})};
  ${fontSm5};
`;

const UserTitle = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${TruncatedText({})};
  ${fontSm4};
`;

const StyledBadge = styled(Badge)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

interface Props extends User {
  isNew?: boolean;
  setDeletedUserId: (id: number) => void;
}

const AssignedUserItem = (props: Props) => {
  const { avatar, name, title, isNew = false, id, setDeletedUserId } = props;

  return (
    <AssignedUserItemWrapper id={`user-${id}-assignment-wrapper`} isNew={isNew}>
      <AvatarWrapper>
        <Avatar image={avatar} name={name} shape='circle' size='md' />
      </AvatarWrapper>
      <UserDetails>
        <UserNameWrapper>
          <UserName className='notranslate'>{name}</UserName>
          {isNew && <StyledBadge text='New' type='trainual-purple' />}
        </UserNameWrapper>
        <UserTitle>{title}</UserTitle>
      </UserDetails>
      {isNew && (
        <StyledIconButton
          //This aria-label may not be correct. Once this functionality is finished, please edit the label to the appropriate thing.
          ariaLabel={`Remove ${name} from this subject.`}
          name='times'
          onClick={() => {
            setDeletedUserId(id);
          }}
        />
      )}
    </AssignedUserItemWrapper>
  );
};

export default AssignedUserItem;
