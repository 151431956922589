import React, { FC, useCallback, useContext, useEffect, useRef } from 'react';

import RecursiveComponent from '../Accordion/core/RecursiveComponent';
import { ContentFlyoutContext } from '../context/ContentFlyoutContext';
import { ContentListContainer } from './styles';

const ContentList: FC = () => {
  const { contextData: curriculums, handleSetNextPage } = useContext(ContentFlyoutContext);
  const infiniteScrollLoaderRef = useRef(null);

  const handleIntersection = useCallback(
    (entities: IntersectionObserverEntry[]) => {
      const target = entities[0];

      if (target.isIntersecting) handleSetNextPage();
    },
    [handleSetNextPage]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });
    if (infiniteScrollLoaderRef.current) observer.observe(infiniteScrollLoaderRef.current);

    return () => observer.disconnect();
  }, [handleIntersection]);

  return (
    <ContentListContainer>
      {curriculums.map((curriculum) => (
        <RecursiveComponent data={curriculum} key={curriculum.id} />
      ))}
      <div ref={infiniteScrollLoaderRef} />
    </ContentListContainer>
  );
};

export default ContentList;
