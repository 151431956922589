import styled from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { fontMd1 } from '../../../../../styled/TypeSystem';

export const QuestionCard = styled.div`
  display: flex;
  flex: 1;
  max-width: 80rem;
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

export const CardContent = styled.div`
  flex: 1;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerLg1} ${constants.spacerLg2} ${constants.spacerLg2} 3.75rem`};
`;

export const QuestionTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  div:last-child {
    margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
    padding: 0;
  }
`;

export const ResultIcon = styled(Icon)<{ isCorrect: boolean }>`
  color: ${({ isCorrect, theme: { vars } }) =>
    isCorrect ? vars.stateHoverSuccess : vars.stateHoverError};
`;

export const ResultText = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd1};
`;

export const ResultIconWrapper = styled.div`
  align-self: flex-start;
  padding-top: 0.7rem;
`;
