import React from 'react';

import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { HeadingWrapper, OutlineContentWrapper, StepLabel } from '../../shared/styles';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import StepOptions from './Steps/StepOptions';

const OutlineContent = () => {
  const {
    step: { plural: stepTermPlural },
  } = useAccountTerminology();

  return (
    <OutlineContentWrapper>
      <HeadingWrapper>
        <Breadcrumbs />
      </HeadingWrapper>
      <StepLabel>{stepTermPlural}</StepLabel>
      <StepOptions />
    </OutlineContentWrapper>
  );
};

export default OutlineContent;
