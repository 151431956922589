import React from 'react';

import {
  TrainualPlusFooterDescription,
  TrainualPlusFooterTitle,
  TrainualPlusFooterWrapper,
} from './styles';

type TrainualPlusFooterProps = {
  title: string;
  description: string;
  primaryColor: string;
  secondaryColor: string;
};

const TrainualPlusFooter = ({
  title,
  description,
  primaryColor,
  secondaryColor,
}: TrainualPlusFooterProps) => {
  return (
    <TrainualPlusFooterWrapper id='addon-trainual-plus-overlay-footer'>
      <TrainualPlusFooterTitle dangerouslySetInnerHTML={{ __html: title }} />
      <TrainualPlusFooterDescription
        dangerouslySetInnerHTML={{ __html: description }}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
    </TrainualPlusFooterWrapper>
  );
};

export default TrainualPlusFooter;
