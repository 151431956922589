import { AccountState } from '../../../types/AccountState';
import { routes } from '../publicApplication/applicationRouter';
import { ElementReport } from '../reports/ElementReportPage/ElementReportTable/types';

type GetCurriculumElementRouteParams = { element: ElementReport } & Pick<AccountState, 'slug'>;

// Given an element return the route to the element report page.
// Note: flowcharts do not have a report page route.
export const getCurriculumElementRoute = ({
  element: { curriculum_id, element_id, element_type },
  slug,
}: GetCurriculumElementRouteParams) => {
  if (element_type === 'Course')
    return routes.courseReport({
      slug,
      curriculumId: curriculum_id,
      courseId: element_id,
    });
  if (element_type === 'Survey::Survey')
    return routes.surveyReport({
      slug,
      curriculumId: curriculum_id,
      surveyId: element_id,
    });
};
