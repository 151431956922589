import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { ShareOrgChartSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const ShareOrgChartSection = ({ enableOrgChart, id }: ShareOrgChartSectionProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('org_chart_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={enableOrgChart}
            className='org-chart-setting'
            handleToggle={(e) => updateAccountData({ enable_org_chart: e.target.checked })}
            id='enable-org-chart'
            name='enable_org_chart'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('org_chart_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default ShareOrgChartSection;
