import React from 'react';

import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import initTranslations from '../../../../../../lib/initTranslations';
import { useGetAllUsersCSVQuery } from '../../../../../../redux/services/resourceApis/reports/reportsApi';
import ConfirmationModal from '../../../../../design_system/overlays/modals/ConfirmationModal';

const t = initTranslations('reports.people_csv');

type PeopleDownloadCsvReportProps = {
  userIds: number[];
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const PeopleDownloadCsvReport = ({
  userIds,
  setShowConfirmationModal,
}: PeopleDownloadCsvReportProps) => {
  const { isLoading } = useGetAllUsersCSVQuery({ userIds }, { refetchOnMountOrArgChange: true });
  const { email } = useCurrentUser();

  return (
    <ConfirmationModal
      actionFunction={() => {
        setShowConfirmationModal(false);
      }}
      desktopSize='lg'
      hasPrimaryButton={false}
      heapModalName='confirm-csv-modal'
      message={t('message', { email })}
      onCloseRequest={() => {
        setShowConfirmationModal(false);
      }}
      processing={isLoading}
      secondaryButtonText={t('secondary_button')}
      title={t('title')}
    />
  );
};

export default PeopleDownloadCsvReport;
