import React from 'react';

import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import useDisplayFlashOnResponse from '../../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../../redux/errors/helpers';
import { ReduxResult } from '../../../../../../../types/Redux';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import ConfirmationModal from '../../../../../../design_system/overlays/modals/ConfirmationModal';
import { BulkContentUpdateModalProps } from '../../types';

const t = initTranslations('curriculums.content.bulk_actions.export_modal');
const BulkContentExportModal = ({
  selectedIds,
  type,
  onClose,
  bulkUpdate,
  bulkUpdateResult,
}: BulkContentUpdateModalProps) => {
  const {
    curriculum: { singular: subjectTermSingular, plural: subjectTermPlural },
  } = useAccountTerminology();
  const { email } = useCurrentUser();
  const count = selectedIds.length;

  useDisplayFlashOnResponse({
    result: bulkUpdateResult || ({} as ReduxResult),
    successMessage: t('success_flash', { email, subjects: subjectTermPlural.toLowerCase() }),
    successFunction: onClose,
    errorMessage: messageFromError(bulkUpdateResult?.error)?.join(', '),
  });

  return (
    <ConfirmationModal
      actionFunction={bulkUpdate ? () => bulkUpdate({ curriculumIds: selectedIds }) : () => ({})}
      actionText={t('button_text')}
      heapModalName={`bulk-content-${type}-modal`}
      message={t('subtitle', {
        email,
      })}
      onCloseRequest={onClose}
      processing={false}
      title={t('title', {
        count,
        subject: subjectTermSingular.toLowerCase(),
        subjects: subjectTermPlural.toLowerCase(),
      })}
    />
  );
};

export default BulkContentExportModal;
