import React, { useEffect, useState } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import getQueryParams from '../../../../../lib/getQueryParams';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useGetTemplatePreviewOutlineElementsQuery } from '../../../../../redux/services/resourceApis/templates/templatesApi';
import useCurriculumParams from '../../../curriculums/hooks/useCurriculumParams';
import { routes } from '../../../publicApplication/applicationRouter';
import { BreadcrumbType } from '../../../publicApplication/route_types/breadcrumb';
import RouteTo from '../../../publicApplication/routeTo';
import OutlineElement from './OutlineElement/OutlineElement';
import { TemplatePreviewOutlineContainer } from './styles';

export type TemplatePreviewOutlineProps = {
  templateId: number;
  breadcrumb?: BreadcrumbType;
};

const TemplatePreviewOutline = ({ templateId, breadcrumb }: TemplatePreviewOutlineProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const { sector } = useCurriculumParams();
  const [curriculumElementIdParam] = getQueryParams(['curriculum_element_id']);
  const { slug } = useCurrentAccount();

  const handleToggle = (index: number, curriculumElementId: number) => {
    const targetIndex = activeIndex === index ? null : index;
    setActiveIndex(targetIndex);
    if (targetIndex !== null) {
      RouteTo(
        routes.template({
          slug,
          id: templateId,
          curriculum_element_id: curriculumElementId,
          sector,
          breadcrumb,
        })
      );
    }
  };
  const result = useGetTemplatePreviewOutlineElementsQuery(templateId);
  const { data, error } = result;

  useDisplayFlashOnResponse({
    result,
    errorMessage: messageFromError(error)?.join(', '),
  });

  useEffect(() => {
    if (data && curriculumElementIdParam) {
      const activeIndexFromParam = data.findIndex(
        (element) => element.curriculumElementId === +curriculumElementIdParam
      );
      setActiveIndex(activeIndexFromParam);
    }
  }, [data, curriculumElementIdParam]);

  if (!data) return <></>;

  return (
    <TemplatePreviewOutlineContainer>
      {data.map(({ title, curriculumElementId, elementKind, elements, emoji }, index) => (
        <OutlineElement
          activeIndex={activeIndex}
          curriculumElementId={curriculumElementId}
          elementKind={elementKind}
          elements={elements}
          emoji={emoji}
          handleToggle={() => handleToggle(index, curriculumElementId)}
          index={index}
          key={curriculumElementId}
          title={title}
        />
      ))}
    </TemplatePreviewOutlineContainer>
  );
};

export default TemplatePreviewOutline;
