import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontMd3, fontSm5 } from '../../../../styled/TypeSystem';

export const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 15rem;
  max-width: 23rem;
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd3};
  ${fontSm5};
`;

export const Title = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontMd3};
`;

export const ControlButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: ${({ theme: { constants } }) => constants.spacerMd3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
    align-items: center;
  }
`;
