import styled from 'styled-components';

import { getSectorBackgroundColor } from '../../../../../../lib/document_import/getSectorSelectionColors';
import { Sector } from '../../../../../../types/Sector';
import { fontSm5 } from '../../../../../styled/TypeSystem';

export const StyledDropdownMenuOption = styled.span`
  display: flex;
  flex-direction: row;
`;

export const ButtonTextWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.p<{ isSelected?: boolean }>`
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ isSelected, theme: { constants } }) =>
    isSelected ? constants.fontMedium : constants.fontRegular};

  ${fontSm5};
`;

export const IconWrapper = styled.div<{ sector: Sector; isSelected?: boolean }>`
  display: flex;
  min-width: 2rem;
  align-items: center;
  justify-content: center;
  background-color: ${({ sector, isSelected, theme: { vars } }) =>
    isSelected ? vars.foundationSurface1 : getSectorBackgroundColor(sector, vars)};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  min-height: ${({ theme: { constants } }) => constants.heightSm};
`;
