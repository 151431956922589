import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import { fontSm5 } from '../../../../../styled/TypeSystem';
import { CourseSuggestion } from '../CourseSuggestions/CourseSuggestionsTable/CourseSuggestionsTable';
import { StepSuggestion } from '../SuggestedTopicsSlideout/SuggestedTopicsSlideout';
import AddTopicComponent from './AddTopicComponent/AddTopicComponent';
import CourseTitle from './CourseTitle/CourseTitle';
import StepSuggestionsLoader from './StepSuggestionsLoader/StepSuggestionsLoader';
import StepSuggestionsTable from './StepSuggestionsTable/StepSuggestionsTable';

const t = initTranslations('curriculums.ai_outliner.step_table');

const StepSuggestionsWrapper = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  border-top: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  overflow-y: auto;
`;

const Header = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const TopicHeader = styled.p`
  display: flex;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const AddTopicWrapper = styled.div`
  position: absolute;
  bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  right: ${({ theme: { constants } }) => constants.spacerMd2};
  left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export type Props = {
  isLoading: boolean;
  selectedCourse: CourseSuggestion;
  setSelectedCourse: (selectedCourse: CourseSuggestion | null) => void;
  stepSuggestions: StepSuggestion[] | undefined;
  addCourseToCurriculum: () => void;
};

const StepSuggestions = ({
  isLoading,
  stepSuggestions,
  selectedCourse,
  setSelectedCourse,
  addCourseToCurriculum,
}: Props) => {
  const {
    topic: { singular: topicTerm },
  } = useAccountTerminology();

  return (
    <StepSuggestionsWrapper>
      <Header>
        <TopicHeader>{t('topic_header', { topic: topicTerm.toLowerCase() })}</TopicHeader>
        <CourseTitle emoji={selectedCourse.emoji} title={selectedCourse.title} />
      </Header>
      {isLoading || !stepSuggestions ? (
        <StepSuggestionsLoader />
      ) : (
        <>
          <StepSuggestionsTable
            steps={stepSuggestions.map((step) => ({
              title: step.title,
              id: step.title,
              content: step.contentJson.htmlText,
            }))}
          />
          <AddTopicWrapper>
            <AddTopicComponent
              primaryButtonClick={addCourseToCurriculum}
              secondaryButtonClick={() => setSelectedCourse(null)}
            />
          </AddTopicWrapper>
        </>
      )}
    </StepSuggestionsWrapper>
  );
};

export default StepSuggestions;
