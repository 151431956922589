import { GroupKind } from '../../../../types/Group';
import { TypeName } from '../../shared/ContentFlyout/types';

export type TResponsibilityContentUrl = {
  id?: number;
  url: string;
  title?: string;
  _destroy?: boolean;
  contentableType?: TypeName;
  contentableId?: number;
};

export enum EDurationTimeFrequency {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export type TDurationTimeFrequency = 'day' | 'week' | 'month' | 'quarter' | 'year';

export type TWorkingHours = {
  responsibilityId: string;
  time: number;
  frequency: TDurationTimeFrequency;
};

export type TSpecialization = {
  responsibilityId: string;
  id: number;
  score: number;
  terminology: string;
  color: string;
};

export type TBoardColumnResponsibility = {
  id: string;
  name: string;
  responsibilityId: string;
  description: string;
  contentUrls: TResponsibilityContentUrl[];
  workingHours: TWorkingHours;
  specializations: TSpecialization[];
};

export type TCurrentBoardColumnResponsibility = TBoardColumnResponsibility & {
  groupId?: number;
  isPositionChanged?: boolean;
};

export type TGroupResponsibilyCard = {
  groupId: number;
  groupName: string;
  groupKind: GroupKind;
  workingTimeTotalPerWeek: number;
  boardColumnResponsibilities: TBoardColumnResponsibility[];
};

export type BoardColumn = {
  id: number;
  columnType: 'User' | 'Group';
  columnLabel: string;
  columnableId: number;
  avatarUrl: string | undefined;
  workingTimeTotalPerWeek: number;
  boardColumnResponsibilities: TBoardColumnResponsibility[];
  groupResponsibilities: TGroupResponsibilyCard[];
};

export type BoardColumnProps = {
  column: BoardColumn;
  holderHeight: number;
  isDragging?: boolean;
};
