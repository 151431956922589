import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { FC, useEffect, useState } from 'react';

import { EditorInputWrapper, Title } from './styles';
import { EditorInputProps } from './types';

const EditorInput: FC<EditorInputProps> = ({
  $isError,
  value,
  onBeforeInput,
  onBlur,
  onChange,
  spellCheck = false,
  className,
  disabled = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const editor = useEditor({
    extensions: [StarterKit],
    content: value,
    onBlur: () => setIsFocused(false),
    onFocus: () => setIsFocused(true),
    onUpdate: ({ editor }) => {
      onChange(editor.getText());
    },
    editorProps: {
      attributes: {
        class: 'editor-input',
      },
      handleKeyDown(view, event) {
        if (event.key === 'Enter') {
          event.preventDefault();
          return true;
        }
        return false;
      },
    },
  });

  useEffect(() => {
    if (editor && editor.getText() !== value) {
      editor.commands.setContent(value, false);
    }
  }, [value, editor]);

  return (
    <>
      {disabled ? (
        <Title>{value}</Title>
      ) : (
        <EditorInputWrapper
          $isError={$isError}
          inputMode='text'
          isFocused={isFocused}
          onBeforeInput={onBeforeInput}
          onBlur={onBlur}
          spellCheck={spellCheck}
        >
          <EditorContent className={className} editor={editor} />
        </EditorInputWrapper>
      )}
    </>
  );
};

export default EditorInput;
