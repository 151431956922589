import React, { useCallback, useMemo, useReducer, useRef, useState } from 'react';

import { OrgChartConfigSlideoutProvider } from '../../../../contexts/OrgChartConfigSlideoutContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import useDownloadOrgChart from '../../../../hooks/useDownloadOrgChart';
import useSlideout from '../../../../hooks/useSlideout';
import { useGetOrgChartQuery } from '../../../../redux/services/resourceApis/orgChart/orgChartApi';
import OrgChart from '../OrgChartShared';
import OrgChartConfigSlideout from '../OrgChartShared/OrgChartConfigSlideout';
import createNestedStructure from '../OrgChartShared/utils/createNestedStructure';
import { ZoomState, zoomReducer } from '../OrgChartShared/ZoomControl/OrgChartZoomControl';
import OrgChartBody from './OrgChartBody';
import OrgChartHeader from './OrgChartHeader';

const initialZoomState: ZoomState = {
  action: null,
  eventTime: null,
};

const UserChart = () => {
  const ability = useCurrentUserAbilities();
  const orgChartContainerRef = useRef<HTMLDivElement>(null);
  const [zoomState, zoomDispatch] = useReducer(zoomReducer, initialZoomState);
  const { downloadOrgChart, setDownloadOrgChartType, isLoadingOrgChart } =
    useDownloadOrgChart(orgChartContainerRef);

  const canUpdateOrgChart = ability.can('update', 'OrgChart');
  const canReadOrgChartConfigPanel = ability.can('read', 'OrgChartConfigPanel');
  const { close, isOpen, open } = useSlideout('org-chart-config-slideout');
  const { rootNodeId } = useCurrentAccount();
  const [resetCounter, setResetCounter] = useState(0);
  const [searchOrgChartUser, setSearchOrgChartUser] = useState<string | null>(null);

  const showConfigure = () => {
    if (canReadOrgChartConfigPanel) isOpen ? close() : open();
  };

  const { data: orgChartResponse, error, isLoading } = useGetOrgChartQuery();

  const resetOrgChart = useCallback(() => {
    setResetCounter((prev) => prev + 1);
  }, [setResetCounter]);

  // orgChartDataClone is to avoid error: Cannot add property _directSubordinates, object is not extensible
  const orgChartDataClone = useMemo(() => {
    return createNestedStructure(orgChartResponse?.orgChartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgChartResponse?.orgChartData, resetCounter]);

  return (
    <OrgChart
      body={
        <>
          <OrgChartBody
            canUpdateOrgChart={canUpdateOrgChart}
            data={orgChartDataClone}
            downloadOrgChart={downloadOrgChart}
            isLoading={isLoading}
            orgChartContainerRef={orgChartContainerRef}
            resetOrgChart={resetOrgChart}
            searchOrgChartUser={searchOrgChartUser}
            setSearchOrgChartUser={setSearchOrgChartUser}
            showConfigure={showConfigure}
            translationPrefix='org_chart'
            zoomState={zoomState}
          />

          {canReadOrgChartConfigPanel && (
            <OrgChartConfigSlideoutProvider orgChartResourceType='user'>
              <OrgChartConfigSlideout />
            </OrgChartConfigSlideoutProvider>
          )}
        </>
      }
      error={error}
      header={
        <OrgChartHeader
          data={orgChartDataClone}
          isLoadingOrgChart={isLoadingOrgChart}
          rootNodeSet={!!rootNodeId}
          searchOrgChartUser={searchOrgChartUser}
          setDownloadOrgChartType={setDownloadOrgChartType}
          setSearchOrgChartUser={setSearchOrgChartUser}
          showConfigure={showConfigure}
          zoomDispatch={zoomDispatch}
          zoomState={zoomState}
        />
      }
      isLoading={isLoading}
      pageId='org_chart_index'
      rootNodeSet={!!rootNodeId}
    />
  );
};

export default UserChart;
