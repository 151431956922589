import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Route } from 'type-route';

import { TrainingPathProvider } from '../../../../contexts/TrainingPathContext';
import { useEditTrainingPathQuery } from '../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import { useGetUserPrimaryDetailsQuery } from '../../../../redux/services/resourceApis/users/usersApi';
import Portal from '../../Portal';
import { routes } from '../../publicApplication/applicationRouter';
import ConfigHeader from './ConfigHeader/ConfigHeader';
import ConfigBody from './ConfigSets/ConfigBody';

const PageContainer = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd3};
    background-color: ${vars.foundationBase1};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
  `
);

const BodyContainer = styled.div<{ headerHeight?: number }>(
  ({ theme: { constants }, headerHeight = '0' }) => css`
    position: relative;
    top: ${headerHeight}px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 0 ${constants.spacerLg2};
    padding-bottom: calc(${headerHeight}px + ${constants.spacerLg2});
  `
);

export type PathConfigProps = {
  route: Route<typeof routes.userTrainingPathConfig>;
};

const PathConfig = ({
  route: {
    params: { id },
  },
}: PathConfigProps) => {
  const { data: userData } = useGetUserPrimaryDetailsQuery(id);
  const { data: trainingPath } = useEditTrainingPathQuery({ userId: id });
  const [headerHeight, setHeaderHeight] = useState<number>();
  if (!trainingPath || !userData) return <PageContainer />;

  return (
    <Portal>
      <PageContainer>
        <TrainingPathProvider trainingPath={trainingPath} user={userData}>
          <ConfigHeader onRef={(node) => setHeaderHeight(node?.clientHeight)} />
          <BodyContainer headerHeight={headerHeight}>
            <ConfigBody />
          </BodyContainer>
        </TrainingPathProvider>
      </PageContainer>
    </Portal>
  );
};

export default PathConfig;
