import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';

import { fontMd1, fontSm5 } from '../../../../../../styled/TypeSystem';

export const WrittenResponseAnswerEditable = styled(ContentEditable)<{ placeholder: string }>`
  width: 100%;
  min-height: ${({ theme: { constants } }) => constants.heightLg};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDefault}`};

  :empty:before {
    content: attr(placeholder);
    display: block;
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    border-bottom: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.accentPrimaryDefault}`};
  }

  ${fontMd1};
`;

export const WordCount = styled.span<{ isTouched: boolean; isValid: boolean; isFocused: boolean }>`
  align-self: flex-end;
  color: ${({ isTouched, isValid, isFocused, theme: { vars } }) =>
    isValid && isFocused
      ? vars.stateHoverSuccess
      : isFocused
      ? vars.textDefault
      : isTouched && !isValid
      ? vars.stateHoverError
      : vars.textPlaceholder};
  ${fontSm5};
`;
