import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { routes } from '../../../../publicApplication/applicationRouter';
import routeTo from '../../../../publicApplication/routeTo';
import { SeatsDecreasingErrorWrapper, StyledLink } from './styles';

const t = initTranslations('per_user_pricing.full_screen_overlay_content.seats_decreasing_error');

export type SeatsDecreasingErrorProps = {
  selectedSeatsCount: number;
};

const SeatsDecreasingError = ({ selectedSeatsCount }: SeatsDecreasingErrorProps) => {
  const { slug, usedUserSlotsCount } = useCurrentAccount();
  const usersToDelete = usedUserSlotsCount - selectedSeatsCount;

  const handleOnClick = () => {
    routeTo(routes.manageUsers({ slug }));
  };

  return (
    <SeatsDecreasingErrorWrapper>
      <StyledLink behavesAs='button' onClick={handleOnClick} text={t('link')} />
      {t('text', { count: usersToDelete, seats: selectedSeatsCount })}
    </SeatsDecreasingErrorWrapper>
  );
};

export default SeatsDecreasingError;
