import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect } from 'react';

import { AccountEditorProvider } from '../../../../../contexts/AccountEditorContext';
import { ConsumptionCourseProvider } from '../../../../../contexts/ConsumptionCourseContext';
import { EditorProvider } from '../../../../../contexts/EditorContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { useGetConsumptionTopicQuery } from '../../../../../redux/services/resourceApis/courses/topicsApi';
import { trainualApi } from '../../../../../redux/services/trainualService';
import { store } from '../../../../../redux/stores/store';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { useEditorContent } from '../../hooks/useEditorContent';
import { BaseContentWrapper } from '../../shared/styles';
import ViewModeSkeleton from '../components/ViewModeSkeleton/ViewModeSkeleton';
import OutlineContent from '../OutlineContent/OutlineContent';
import ToolbarContainer from '../ToolbarContainer';
import DeprecatedEditor from './DeprecatedEditor';

type Props = {
  stepId: number;
};

const DeprecatedConsumptionPageBase = ({ stepId }: Props) => {
  const account = useCurrentAccount();
  const { productTerm } = useAccountTerminology();
  const { editor, stepData } = useEditorContent({ stepId, isEditable: false });
  const { data, isLoading, error, isSuccess } = useGetConsumptionTopicQuery(
    stepData?.courseId ? { courseId: stepData.courseId } : skipToken
  );

  useEffect(() => {
    if (isSuccess) {
      store.dispatch(
        trainualApi.util.invalidateTags([{ type: 'AssignmentsCurriculums', id: 'LIST' }])
      );
    }
  }, [isSuccess]);

  if (error) return <BasicErrorDisplay error={error} />;
  if (isLoading || !editor || !stepData || !data) return <ViewModeSkeleton />;

  const { curriculum, ...course } = data;

  return (
    <ConsumptionCourseProvider
      course={course}
      curriculum={curriculum}
      productTerm={productTerm}
      stepId={stepId}
    >
      <AccountEditorProvider account={account}>
        <EditorProvider editor={editor}>
          <OutlineContent />
          <BaseContentWrapper>
            <ToolbarContainer />
            <DeprecatedEditor step={stepData} />
          </BaseContentWrapper>
        </EditorProvider>
      </AccountEditorProvider>
    </ConsumptionCourseProvider>
  );
};

export default DeprecatedConsumptionPageBase;
