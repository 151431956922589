import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';
import { CurriculumsAssignmentsListWrapper } from '../styles';

const CurriculumsAssignmentsLoadingSkeleton = ({ ItemsCount = 4 }: { ItemsCount?: number }) => {
  const {
    constants: { spacerSm3, borderRadiusLg },
  } = useTheme();

  return (
    <CurriculumsAssignmentsListWrapper>
      {[...Array(ItemsCount)].map((_, index) => (
        <SkeletonLoader
          borderRadius={borderRadiusLg}
          height='5.5rem'
          key={`assignment-loading-item-${index}`}
          marginBottom={spacerSm3}
        />
      ))}
    </CurriculumsAssignmentsListWrapper>
  );
};

export default CurriculumsAssignmentsLoadingSkeleton;
