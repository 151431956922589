import React from 'react';
import styled, { useTheme } from 'styled-components';

import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import { DownloadOrgChartType } from '../../../../hooks/useDownloadOrgChart';
import useSlideout from '../../../../hooks/useSlideout';
import useWindowResize from '../../../../hooks/useWindowResize';
import initTranslations from '../../../../lib/initTranslations';
import sortArray, { SortOrder } from '../../../../lib/sortArray';
import { OrgChartUser } from '../../../../types/OrgChart';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import SplitButton from '../../../design_system/buttons/SplitButton';
import { Option } from '../../../design_system/core/SelectOption/types';
import SingleSelectField from '../../../design_system/Triage/fields/SingleSelectField';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import FlexContainer from '../../../styled/FlexContainer';
import OrgChartTabs from '../OrgChartShared/OrgChartTabs';
import OrgChartZoomControl, { ZoomProps } from '../OrgChartShared/ZoomControl/OrgChartZoomControl';

const t = initTranslations('org_chart');

const Wrapper = styled.div`
  width: 15rem;
`;

const getUserOptions = (user?: OrgChartUser, usersToFormat: Option[] = []): Option[] => {
  if (!user) return [];

  usersToFormat.push({
    label: user.name,
    value: String(user.id),
    searchableTerm: String(user.name),
  });

  if (user.children) {
    for (const child of user.children) {
      getUserOptions(child as OrgChartUser, usersToFormat);
    }
  }

  return sortArray({ array: usersToFormat, sortKey: 'label', sortOrder: SortOrder.asc });
};

type OrgChartHeaderProps = {
  data: OrgChartUser | undefined;
  searchOrgChartUser: string | null;
  setSearchOrgChartUser: React.Dispatch<React.SetStateAction<string | null>>;
  showConfigure: () => void;
  rootNodeSet: boolean;
  isLoadingOrgChart: boolean;
  setDownloadOrgChartType: React.Dispatch<React.SetStateAction<DownloadOrgChartType>>;
} & ZoomProps;

const OrgChartHeader = ({
  data,
  searchOrgChartUser,
  setSearchOrgChartUser,
  showConfigure,
  rootNodeSet,
  zoomDispatch,
  zoomState,
  isLoadingOrgChart,
  setDownloadOrgChartType,
}: OrgChartHeaderProps) => {
  const ability = useCurrentUserAbilities();
  const canUpdateOrgChart = ability.can('update', 'OrgChart');
  const subtitle = canUpdateOrgChart ? t('subtitle_admin') : t('subtitle_non_admin');
  const {
    constants: { spacerMd2 },
  } = useTheme();
  const { isExtraSmWindow } = useWindowResize();
  const userProfileSlideout = useSlideout('user-profile');

  const showGroupChart = ability.can('read', 'GroupChart');
  const showOrgChart = ability.can('read', 'OrgChart');

  const handleDownloadOrgChart = (type: DownloadOrgChartType) => {
    setDownloadOrgChartType(type);
    zoomDispatch({ type: 'fitToPrint' });
  };

  return (
    <>
      <PageHeader subtitle={subtitle} title={t('title')} />
      <FlexContainer
        align={isExtraSmWindow ? 'flex-start' : 'flex-end'}
        direction={isExtraSmWindow ? 'column' : 'row'}
        flexWrap='wrap'
        gap={spacerMd2}
        justifyContent={showGroupChart && showOrgChart ? 'space-between' : 'flex-end'}
      >
        <OrgChartTabs />
        <FlexContainer flexWrap='wrap' gap={spacerMd2}>
          <FlexContainer gap={spacerMd2} justifyContent='flex-end'>
            <Wrapper>
              <SingleSelectField
                className='org-chart-user-select-field'
                defaultValue={null}
                hideSelectedOptions={false}
                isClearable
                isSearchable
                onNonDefaultSelected={(value) => {
                  value && userProfileSlideout.open({ userId: value });
                  setSearchOrgChartUser(value);
                  zoomDispatch({ type: 'user' });
                }}
                options={getUserOptions(data)}
                placeholder={t('search_placeholder')}
                searchControlIcon
                value={searchOrgChartUser}
              />
            </Wrapper>
            {zoomDispatch && zoomState && rootNodeSet && (
              <OrgChartZoomControl
                resource='org-chart'
                zoomDispatch={zoomDispatch}
                zoomState={zoomState}
              />
            )}
          </FlexContainer>
          <FlexContainer gap={spacerMd2} justifyContent='flex-end'>
            {rootNodeSet && (
              <SplitButton
                buttonType='tertiary'
                disabled={isLoadingOrgChart}
                loading={isLoadingOrgChart}
                mainButtonOnClick={() => handleDownloadOrgChart('pdf')}
                mainMenuButtonId='download-org-chart-button'
                menuId='download-org-chart-menu'
                menuOptions={[
                  {
                    title: t('download_pdf'),
                    onClick: () => handleDownloadOrgChart('pdf'),
                    iconName: 'file-pdf',
                    id: 'td-org-chart-download-pdf',
                  },
                  {
                    title: t('download_png'),
                    onClick: () => handleDownloadOrgChart('png'),
                    iconName: 'file-arrow-down',
                    id: 'td-org-chart-download-png',
                  },
                ]}
                text={t('download_chart')}
              />
            )}
            {canUpdateOrgChart && (
              <DefaultButton
                id={rootNodeSet ? 'edit-org-chart-button' : 'build-org-chart-button'}
                onClick={showConfigure}
                text={t(rootNodeSet ? 'edit_button_title' : 'build_button_title')}
              />
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default OrgChartHeader;
