import { DefaultPrivacyLevel, RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { useCallback, useMemo } from 'react';

import usePublicConfigs from './usePublicConfigs';

export default function useDataDog() {
  const { configs } = usePublicConfigs();

  const ddEnabled = configs['DATADOG_RUM_ENABLED'] == 'true';
  const ddService = configs['APP_NAME'];
  const ddVersion = configs['COMMIT_SHA_SHORT'];

  const properties = useMemo<RumInitConfiguration>(() => {
    if (!ddEnabled) {
      return { applicationId: '', clientToken: '' };
    }

    const props: RumInitConfiguration = {
      applicationId: configs['DATADOG_APP_ID'],
      clientToken: configs['DATADOG_CLIENT_TOKEN'],
      defaultPrivacyLevel: configs['DATADOG_PRIVACY_LEVEL'] as DefaultPrivacyLevel,
      env: configs['APP_TIER'],
      service: ddService,
      sampleRate: +configs['DATADOG_SAMPLE_RATE'],
      sessionReplaySampleRate: +configs['DATADOG_REPLAY_SAMPLE_RATE'],
      site: configs['DATADOG_SITE'],
      trackFrustrations: configs['DATADOG_TRACK_FRUSTRATIONS'] == 'true',
      trackInteractions: configs['DATADOG_TRACK_INTERACTIONS'] == 'true',
      trackLongTasks: configs['DATADOG_TRACK_LONG_TASKS'] == 'true',
      trackResources: configs['DATADOG_TRACK_RESOURCES'] == 'true',
      trackViewsManually: true,
      silentMultipleInit: true,
      version: ddVersion,
      allowedTracingUrls: [/https:\/\/.*\.trainual\.com/],
    };

    return props;
  }, [configs, ddEnabled, ddService, ddVersion]);

  const ddInit = useCallback(() => {
    if (!ddEnabled) {
      return;
    }

    datadogRum.init(properties);

    datadogRum.startSessionReplayRecording();
  }, [properties, ddEnabled]);

  const ddPage = useCallback(
    (routeName: string) => {
      if (!ddEnabled) {
        return;
      }
      datadogRum.startView({
        name: routeName,
        service: ddService,
        version: ddVersion,
      });
    },
    [ddEnabled, ddService, ddVersion]
  );

  return {
    ddInit,
    ddPage,
  };
}
