import React, { MouseEvent } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import { useIsAdmin } from '../../../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../../../lib/initTranslations';
import { useToggleFavoriteCurriculumMutation } from '../../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { TrainingPathContent } from '../../../../../../../redux/services/resourceApis/trainingPaths/types';
import { ProfileUser } from '../../../../../../../redux/services/resourceApis/users/types';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import Icon from '../../../../../../design_system/display/icons/Icon';
import ProgressBar from '../../../../../../design_system/Triage/ProgressBar';
import {
  CurriculumActionWrapper,
  CurriculumAssignedActionIcon,
  CurriculumAssignedActionInner,
  CurriculumCompletion,
  CurriculumEmoji,
  CurriculumInfo,
  CurriculumInfoItem,
  CurriculumInner,
  CurriculumProgressBar,
  CurriculumTitle,
  CurriculumTitleWrapper,
  FavoriteIcon,
  FavoriteIconWrapper,
  sharedCurriculumItemStyles,
} from '../../../../../home/CurriculumsAssignments/CurriculumsAssignmentstItem/styles';
import { routes } from '../../../../../publicApplication/applicationRouter';

const Wrapper = styled.div<{ clickable: boolean }>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'not-allowed')};
  ${sharedCurriculumItemStyles};
`;

export type SetContentRowProps = {
  trainingPathContent: TrainingPathContent;
  blocked: boolean;
  user: Pick<ProfileUser, 'id' | 'name'>;
  showFavorite?: boolean;
};

const t = initTranslations(
  'training_path.path_overview.overview_sets.overview_set.set_content_row'
);

const SetContentRow = ({
  trainingPathContent: {
    curriculum,
    completionPercentage,
    requiredForUser,
    requiredGroups,
    favorite,
  },
  blocked,
  user,
  showFavorite = false,
}: SetContentRowProps) => {
  const { slug } = useCurrentAccount();
  const terminology = useAccountTerminology();
  const { id, emoji, title, sector, minutesToReadFormatted } = curriculum;
  const { id: currentUserId } = useCurrentUser();
  const isAdmin = useIsAdmin();
  const viewingSelf = user.id === currentUserId;
  const isClickable = isAdmin || !blocked || completionPercentage === 100;
  const [toggleFavoriteAssignment] = useToggleFavoriteCurriculumMutation();

  const toggleFavorite = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    toggleFavoriteAssignment(id);
  };

  return (
    <Wrapper
      clickable={isClickable}
      id={`set-curriculum-${id}`}
      onClick={() =>
        isClickable &&
        routes
          .curriculumShow({
            slug,
            id,
            breadcrumb: routes.userTrainingPath({ slug, id: user.id }),
          })
          .push()
      }
    >
      <CurriculumEmoji className='curriculum-emoji-wrapper'>
        {blocked ? (
          <Icon name='lock' size='sm' weight='regular' />
        ) : emoji ? (
          emoji
        ) : (
          <Icon name='file-alt' size='sm' weight='regular' />
        )}
      </CurriculumEmoji>
      <CurriculumInner className='curriculum-data-wrapper'>
        <CurriculumTitleWrapper>
          <CurriculumTitle>{title}</CurriculumTitle>
          {showFavorite && (
            <FavoriteIconWrapper onClick={toggleFavorite}>
              <FavoriteIcon
                className={`favorite-icon${favorite ? ' is-favorite' : ''}`}
                isFavorite={favorite}
                name='star'
                weight={favorite ? 'solid' : 'regular'}
              />
            </FavoriteIconWrapper>
          )}
        </CurriculumTitleWrapper>
        <CurriculumInfo>
          <CurriculumInfoItem>{terminology[`${sector}`].singular}</CurriculumInfoItem>
          <CurriculumInfoItem>{minutesToReadFormatted}</CurriculumInfoItem>
        </CurriculumInfo>
      </CurriculumInner>
      <CurriculumProgressBar className='progress-bar-container'>
        <ProgressBar percent={completionPercentage} thickness='lg' />
        <CurriculumCompletion className='notranslate'>{completionPercentage}%</CurriculumCompletion>
      </CurriculumProgressBar>
      <CurriculumActionWrapper className='assigned-to-wrapper'>
        <CurriculumAssignedActionInner>
          <CurriculumAssignedActionIcon name='user' size='2xs' weight='solid' />
          {requiredForUser
            ? viewingSelf
              ? t('required_for_you')
              : t('required_for_user', { user: user.name })
            : t('required_for_group', { group: requiredGroups[0] })}
        </CurriculumAssignedActionInner>
      </CurriculumActionWrapper>
    </Wrapper>
  );
};

export default SetContentRow;
