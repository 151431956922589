import React, { useCallback, useRef, useState } from 'react';

import { ImageFlyoutProvider } from '../../../../../contexts/ImageFlyoutContext';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateTopicCoverPhotoMutation } from '../../../../../redux/services/resourceApis/courses/topicsApi';
import {
  STOCK_IMAGE_INITIAL_SEARCH,
  STOCK_IMAGE_MIN_HEIGHT,
  STOCK_IMAGE_MIN_WIDTH,
} from '../../shared/constants/editor';
import CoverImage from './CoverImage';
import HeaderCropper from './HeaderCropper';
import { ImageWrapper } from './styles';
import { TOPIC_HEADER_FLYOUT_MENU_ID } from './TopicHeader';
import TopicHeaderActions from './TopicHeaderActions';

const t = initTranslations('editor.topic.header');

type Props = {
  topicEmoji: string | null;
  coverImage: string | null;
  topicId: number;
  showCropper: boolean;
  setShowCropper: (show: boolean) => void;
};

const TopicCoverImage = ({
  topicEmoji,
  coverImage,
  topicId,
  showCropper,
  setShowCropper,
}: Props) => {
  const cropContainerRef = useRef<HTMLDivElement>(null);
  const [updateCoverPhoto, updateCoverPhotoResult] = useUpdateTopicCoverPhotoMutation();
  const { isLoading, isError } = updateCoverPhotoResult;
  const { id: userId } = useCurrentUser();
  const [isRemovingCover, setIsRemovingCover] = useState(false);

  const handleImageChange = useCallback(
    ({ clearImage = false, image = null }) => {
      const formData = new FormData();
      formData.append('user_id', userId.toString());

      if (image) {
        setShowCropper(false);
        formData.append('file', image);
      }

      if (clearImage) {
        formData.append('remove_cover', 'true');
        setIsRemovingCover(true);
      } else {
        setIsRemovingCover(false);
      }

      updateCoverPhoto({ course_id: topicId, image: formData });
    },
    [setShowCropper, topicId, updateCoverPhoto, userId]
  );

  const setErrorMessage = useCallback(() => {
    if (isRemovingCover) {
      return t('removal_error');
    } else if (coverImage && isError) {
      return t('replace_error');
    } else {
      return t('upload_error');
    }
  }, [coverImage, isError, isRemovingCover]);

  useDisplayFlashOnResponse({
    result: updateCoverPhotoResult,
    errorMessage: setErrorMessage(),
    skip: !isError,
    successFunction: () => setIsRemovingCover(false),
    errorFunction: () => setIsRemovingCover(false),
  });

  return (
    <ImageFlyoutProvider
      stockImageInitialSearch={STOCK_IMAGE_INITIAL_SEARCH}
      stockImageMinHeight={STOCK_IMAGE_MIN_HEIGHT}
      stockImageMinWidth={STOCK_IMAGE_MIN_WIDTH}
      uploadImage={(newImage) => handleImageChange({ image: newImage })}
      uploadResult={updateCoverPhotoResult}
    >
      <ImageWrapper
        applyAdditionalMargin={!!topicEmoji && (!!coverImage || showCropper)}
        ref={cropContainerRef}
      >
        {showCropper ? (
          <HeaderCropper
            cancel={() => setShowCropper(false)}
            cropContainerRef={cropContainerRef}
            handleImageChange={handleImageChange}
            isLoading={isLoading}
            setShowCropper={setShowCropper}
          />
        ) : (
          <>
            {coverImage && (
              <CoverImage
                coverImage={coverImage}
                isLoading={isLoading}
                menuId={TOPIC_HEADER_FLYOUT_MENU_ID}
                message={isRemovingCover ? t('removing_cover_photo') : t('replacing_cover_photo')}
                removeCover={() => handleImageChange({ clearImage: true })}
              />
            )}
          </>
        )}
      </ImageWrapper>
      <TopicHeaderActions
        isCoverImagePresent={!!coverImage}
        setShowCropper={setShowCropper}
        showCropper={showCropper}
        topicEmoji={topicEmoji}
        topicId={topicId}
      />
    </ImageFlyoutProvider>
  );
};

export default TopicCoverImage;
