import React from 'react';
import styled from 'styled-components';

import { CurriculumElement, CurriculumElementStatus } from '../../../../../types/CurriculumElement';
import { StatusBadgeWrapper } from '../../../curriculums/shared/CurriculumRowStyles';
import StatusDropdown from '../../../editor/components/StatusDropdown/StatusDropdown';
import { Title } from '../../DetailedTitle/Title';

const Wrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

type StatusBadgeActionsProps = {
  infoText?: string;
  curriculumElement: Pick<CurriculumElement, 'id'> & {
    status: CurriculumElementStatus;
  };
  curriculumId: number;
};

const StatusBadgeActions = ({
  curriculumElement,
  infoText,
  curriculumId,
}: StatusBadgeActionsProps) => {
  const { id, status } = curriculumElement;

  return (
    <StatusBadgeWrapper>
      <>
        {!!infoText && (
          <Wrapper>
            <Title fontColor='placeholder' fontSize='sm5' fontWeight='regular' title={infoText} />
          </Wrapper>
        )}
        <StatusDropdown curriculumId={curriculumId} element={{ curriculumElementId: id, status }} />
      </>
    </StatusBadgeWrapper>
  );
};

export default StatusBadgeActions;
