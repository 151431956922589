import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import { surveysApi } from '../surveys/surveysApi';
import {
  SurveyQuestionCreateParams,
  SurveyQuestionCreateResponse,
  SurveyQuestionUpdateParams,
  SurveyQuestionUpdatePositionParams,
  SurveyQuestionUrlParams,
} from './types';

const surveyQuestionsApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    createSurveyQuestion: builder.mutation<
      SurveyQuestionCreateResponse,
      SurveyQuestionCreateParams
    >({
      query: (params) => ({
        url: `ajax/surveys/${params.surveyId}/questions`,
        method: 'POST',
      }),
      transformResponse: (returnValue: SurveyQuestionCreateResponse) => {
        return toCamelCase<SurveyQuestionCreateResponse>(returnValue);
      },
      invalidatesTags: (_result, _error, { surveyId }) => [{ type: 'Survey', id: surveyId }],
    }),
    updateSurveyQuestion: builder.mutation<undefined, SurveyQuestionUpdateParams>({
      query: (params) => ({
        url: `ajax/surveys/${params.surveyId}/questions/${params.id}`,
        method: 'PUT',
        body: toSnakeCase(params),
      }),
      async onQueryStarted({ id, surveyId, ...updateParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveysApi.util.updateQueryData('getEditSurvey', { id: surveyId }, (draft) => {
            const question = draft.questions.find((question) => question.id === id);
            if (question) Object.assign(question, updateParams);
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (_result, _error, { surveyId }) => [
        { type: 'Survey', id: surveyId },
        { type: 'SurveyQuestion', id: 'LIST' },
      ],
    }),
    updateSurveyQuestionPosition: builder.mutation<undefined, SurveyQuestionUpdatePositionParams>({
      query: ({ surveyId, id, position }: SurveyQuestionUpdatePositionParams) => ({
        url: `ajax/surveys/${surveyId}/questions/${id}/update_position`,
        method: 'PUT',
        body: { position },
      }),
      async onQueryStarted({ id, surveyId, position }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveysApi.util.updateQueryData('getEditSurvey', { id: surveyId }, (draft) => {
            const questions = draft.questions;
            const destinationIndex = position - 1;
            const [reorderedQuestion] = questions.splice(
              questions.findIndex((question) => question.id === id),
              1
            );

            questions.splice(destinationIndex, 0, reorderedQuestion);
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (_result, _error, { surveyId }) => [{ type: 'Survey', id: surveyId }],
    }),
    deleteSurveyQuestion: builder.mutation<undefined, SurveyQuestionUrlParams>({
      query: ({ id, surveyId }) => ({
        url: `ajax/surveys/${surveyId}/questions/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, surveyId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          surveysApi.util.updateQueryData('getEditSurvey', { id: surveyId }, (draft) => {
            const questionIndex = draft.questions.findIndex((question) => question.id === id);
            if (questionIndex !== -1) draft.questions.splice(questionIndex, 1);
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (_result, _error, { surveyId }) => [{ type: 'Survey', id: surveyId }],
    }),
  }),
});

export const {
  useUpdateSurveyQuestionMutation,
  useDeleteSurveyQuestionMutation,
  useUpdateSurveyQuestionPositionMutation,
  useCreateSurveyQuestionMutation,
} = surveyQuestionsApi;
