import React from 'react';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import Portal from '../../Portal';
import { routes } from '../../publicApplication/applicationRouter';
import { EditorWrapper } from '../shared/styles';
import ConsumptionPageBase from './ConsumptionPageBase';
import DeprecatedConsumptionPageBase from './deprecated/DeprecatedConsumptionPageBase';

type Props = {
  route: Route<typeof routes.consume>;
};

const ConsumptionPage = ({ route }: Props) => {
  const {
    params: { id: stepId },
  } = route;

  const {
    splitFeatures: { stepCollaborationEnabled },
  } = useCurrentAccount();

  if (stepCollaborationEnabled) {
    return (
      <EditorWrapper className='consumption-step'>
        <ConsumptionPageBase stepId={stepId} />
      </EditorWrapper>
    );
  }

  return (
    <Portal>
      <EditorWrapper className='consumption-step'>
        <DeprecatedConsumptionPageBase stepId={stepId} />
      </EditorWrapper>
    </Portal>
  );
};

export default ConsumptionPage;
