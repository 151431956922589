import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { BillingCardDetails } from '../../../../../types/BillingCardDetails';
import { InputFieldWithEditLinkWrapper, StyledEditLink, StyledInputField } from '../styles';

type CurrentCreditCardFieldProps = {
  cardDetails: Omit<BillingCardDetails, 'id' | 'name'>;
  setShowStripeElements: React.Dispatch<React.SetStateAction<boolean>>;
};

const t = initTranslations('update_payment_method_modal.card');

const CurrentCreditCardField = ({
  cardDetails,
  setShowStripeElements,
}: CurrentCreditCardFieldProps) => {
  const { brand, last4, expMonth, expYear } = cardDetails;
  const currentCreditCardPlaceholder = `${getBrandName()} - ${last4} (Exp: ${expMonth}/${expYear})`;

  function getBrandName() {
    // Return short name for American Express to prevent overlapping Edit links
    return brand === 'American Express' ? t('amex') : brand;
  }

  return (
    <InputFieldWithEditLinkWrapper>
      <StyledInputField
        disabled
        id='current-credit-card'
        label={t('card_number')}
        required
        type='text'
        value={currentCreditCardPlaceholder}
      />
      <StyledEditLink
        behavesAs='button'
        className='edit-current-credit-card'
        onClick={() => setShowStripeElements(true)}
        text={t('edit')}
      />
    </InputFieldWithEditLinkWrapper>
  );
};

export default CurrentCreditCardField;
