import React, { ReactNode, createContext, useContext, useMemo } from 'react';

import useDisplayFlashOnResponse from '../hooks/useDisplayFlashOnResponse';
import useOrgChartConfigSlideoutState, {
  OrgChartConfigSlideoutState,
  OrgChartResource,
} from '../hooks/useOrgChartConfigSlideoutState';
import { stringifyNumber } from '../lib/convertValues';
import initTranslations from '../lib/initTranslations';
import { messageFromError } from '../redux/errors/helpers';
import {
  useGetOrgChartConfigSlideoutGroupsQuery,
  useGetOrgChartConfigSlideoutUsersQuery,
} from '../redux/services/resourceApis/orgChart/orgChartApi';
import { OrgChartResourceType } from '../redux/services/resourceApis/orgChart/types';

interface OrgChartConfigSlideoutContext extends OrgChartConfigSlideoutState {
  rootResource: OrgChartResource | null;
  resources: OrgChartResource[];
  orgChartResourceType: OrgChartResourceType;
  isGroupType: boolean;
}

interface Props {
  children: ReactNode;
  orgChartResourceType: OrgChartResourceType;
}

const t = initTranslations('error');

const OrgChartConfigSlideoutContext = createContext({} as OrgChartConfigSlideoutContext);
export const useOrgChartConfigSlideout = () => useContext(OrgChartConfigSlideoutContext);

export const OrgChartConfigSlideoutProvider = ({ children, orgChartResourceType }: Props) => {
  const state = useOrgChartConfigSlideoutState();
  const isGroupType = orgChartResourceType === 'group';
  const orgChartConfigSlideoutGroupsResult = useGetOrgChartConfigSlideoutGroupsQuery(undefined, {
    skip: !isGroupType,
  });
  const orgChartConfigSlideoutUsersResult = useGetOrgChartConfigSlideoutUsersQuery(undefined, {
    skip: isGroupType,
  });
  const result = isGroupType
    ? orgChartConfigSlideoutGroupsResult
    : orgChartConfigSlideoutUsersResult;
  const { isLoading: dataIsLoading, error, data } = result;

  useDisplayFlashOnResponse({
    result,
    errorMessage: messageFromError(error)?.join(', ') || t('something_went_wrong'),
  });

  const updatedState = useMemo(() => {
    if (data) {
      const { root_resource: rootResource, resources } = data;

      return {
        ...state,
        rootResource,
        resources: resources.map((resource) => {
          return {
            ...resource,
            selectedParentId: stringifyNumber(resource.parent?.id),
            isSelectedRoot: resource.id === rootResource?.id,
          };
        }),
      };
    } else {
      return state;
    }
  }, [data, state]);

  return (
    <OrgChartConfigSlideoutContext.Provider
      value={{
        ...updatedState,
        dataIsLoading,
        isGroupType,
        orgChartResourceType,
      }}
    >
      {children}
    </OrgChartConfigSlideoutContext.Provider>
  );
};
