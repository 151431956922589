import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import { HeaderSubtitle } from '../../../design_system/overlays/modals/TaskModal/TaskModalTypes';
import Checklist, { ChecklistOption } from '../../../design_system/Triage/Checklist';
import SearchField from '../../../design_system/Triage/fields/SearchField';
import Loader from '../../../design_system/Triage/Loader';
import { Title } from '../DetailedTitle/Title';

const StyledSearchField = styled(SearchField)`
  border: ${({ theme: { constants, vars } }) => `${constants.borderWidthSm} solid
    ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  &:focus-within {
    border: ${({ theme: { constants, vars } }) => `${constants.borderWidthSm} solid
    ${vars.accentPrimaryDefault}`};
  }
`;

const ModalContentWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type SearchableChecklistModalProps = {
  taskModalProps: {
    primaryButtonText: string;
    primaryButtonTask(): void;
  } & Pick<
    TaskModalProps,
    'title' | 'isDisabled' | 'processing' | 'onCloseRequest' | 'heapModalName'
  > &
    HeaderSubtitle;
  onSelectionChanged(selectedOptions: ChecklistOption[]): void;
  isLoading: boolean;
  errorMessage: string | null;
  checkboxOptions: ChecklistOption[];
  numberSelected: number;
  search_placeholder?: string;
};

const t = initTranslations('task_modal');

const SearchableChecklistModal = ({
  taskModalProps,
  onSelectionChanged,
  isLoading,
  errorMessage,
  checkboxOptions,
  numberSelected,
  search_placeholder,
}: SearchableChecklistModalProps) => {
  const theme = useTheme();
  const [filterValue, setFilterValue] = useState('');

  const taskModalArgs: TaskModalProps = {
    ...taskModalProps,
    minMaxModalHeight: { min: '34rem' },
    scrollInsideBody: true,
    headerChildren: (
      <StyledSearchField
        autoFocus
        placeholder={search_placeholder ?? t('search_placeholder')}
        searchValue={filterValue}
        setSearchValue={setFilterValue}
        showBorder={false}
      />
    ),
    footerContent: <Title title={t('selected', { count: numberSelected })} />,
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      {isLoading ? (
        <ModalContentWrapper>
          <Loader />
        </ModalContentWrapper>
      ) : !!errorMessage ? (
        <ModalContentWrapper>
          <Title title={errorMessage} />
        </ModalContentWrapper>
      ) : (
        <Checklist
          centeredCheckboxes
          checkboxGap={theme.constants.spacerLg1}
          checkboxOptions={checkboxOptions}
          filterValue={filterValue}
          onSelectionChanged={onSelectionChanged}
        />
      )}
    </TaskModal>
  );
};

export default SearchableChecklistModal;
