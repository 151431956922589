import React, { ReactNode, useContext, useState } from 'react';
import styled from 'styled-components';

import { AccountRoutesContext } from '../../../../contexts/AccountRoutesContext';
import useAjaxWrite, { RequestMethod } from '../../../../hooks/useAjaxWrite';
import { AsyncData } from '../../../../hooks/useAsyncData';
import initTranslations from '../../../../lib/initTranslations';
import Icon from '../../../design_system/display/icons/Icon';
import Loader from '../../../design_system/Triage/Loader';
import StatusBlockText from './StatusBlockText';
import StatusBlockWrapper from './StatusBlockWrapper';

const StyleIcon = styled(Icon)`
  margin-right: 0.5rem;
  color: ${({ theme: { vars } }) => vars.chartRed3};
`;

const FailedResultLinkXlsx = styled.a`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.vars.accentPrimaryDefault};
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.438rem;
  text-decoration-line: underline;
`;

const FailedResultLinkCsv = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 0.625rem;
  margin-bottom: 0;
  a {
    text-decoration-line: underline;
    color: ${(props) => props.theme.vars.accentPrimaryDefault};
  }
`;

const StyledFileAltIcon = styled(Icon)`
  width: 15px !important;
  height: 1.25rem;
  margin-right: 0.531rem;
`;
const ResultLoader = styled.div`
  display: none;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.125rem;
  min-height: 3.1875rem;
  span {
    margin-left: 0.5rem;
  }
  &.show {
    display: flex;
  }
`;

const LinksWrapper = styled.div`
  &.hide {
    display: none;
  }
`;

const t = initTranslations('bulk_user_upload_modal.status_block_error');

interface Props {
  title: ReactNode;
  subtitle: string;
  resultType: string;
  linkTextXlsx: string;
}

interface FilePath {
  link: string;
}

const StatusBlockError = ({ title, subtitle, resultType, linkTextXlsx }: Props) => {
  const routes = useContext(AccountRoutesContext);

  const xlsxFilePath = useAjaxWrite<FilePath>({
    requestMethod: RequestMethod.Get,
    path: routes.ajax.bulkUploadResultFiles({ file_format: 'xlsx', result_type: resultType }),
    dataItem: {},
  });

  const csvFilePath = useAjaxWrite<FilePath>({
    requestMethod: RequestMethod.Get,
    path: routes.ajax.bulkUploadResultFiles({ file_format: 'csv', result_type: resultType }),
    dataItem: {},
  });

  const [isLoading, setIsLoading] = useState(false);

  const getFilePath = async (e: React.MouseEvent, fileType: 'xlsx' | 'csv') => {
    e.preventDefault();
    setIsLoading(false);

    let result: AsyncData<FilePath>;

    if (fileType === 'xlsx') {
      result = await xlsxFilePath();
    } else {
      result = await csvFilePath();
    }

    if (result.status === 'success') {
      window.location.href = result.data.link;
    }

    if (result.status === 'error') {
      setIsLoading(true);
    }
  };

  return (
    <StatusBlockWrapper isSuccess={false}>
      <StatusBlockText>
        <StyleIcon name='exclamation-triangle' weight='solid' />
        {title}
      </StatusBlockText>
      <StatusBlockText>{subtitle}</StatusBlockText>
      <LinksWrapper className={isLoading ? 'hide' : ''} id='result-links-wrapper'>
        <FailedResultLinkXlsx href='#' id='xlsx-link' onClick={(e) => getFilePath(e, 'xlsx')}>
          <StyledFileAltIcon name='file-alt' weight='regular' />
          {linkTextXlsx}
        </FailedResultLinkXlsx>
        <FailedResultLinkCsv>
          {t('or')}
          <a href='#' id='csv-link' onClick={(e) => getFilePath(e, 'csv')}>
            {t('csv_results')}
          </a>
          .
        </FailedResultLinkCsv>
      </LinksWrapper>
      <ResultLoader className={isLoading ? 'show' : ''} id='result-loader'>
        <Loader />
        <span>{t('generating_your_results')}</span>
      </ResultLoader>
    </StatusBlockWrapper>
  );
};

export default StatusBlockError;
