import React from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateCurriculumDefaultSettingsMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { AccessControl, accessControls } from '../../../../../types/SuperShare';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { Option } from '../../../../design_system/core/SelectOption/types';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import { FormSectionContainer, FormSectionDescription, FormSectionTitle } from '../styles';

const Wrapper = styled.div`
  width: 100%;
`;

const t = initTranslations('account_settings.advanced_settings');

interface DefaultCurriculumAccessControlSectionProps {
  defaultCurriculumAccessControl: AccessControl;
  id: string;
}

const DefaultCurriculumAccessControlSection = ({
  defaultCurriculumAccessControl,
  id,
}: DefaultCurriculumAccessControlSectionProps) => {
  const {
    curriculum: { singular: curriculumTermSingular, plural: curriculumTermPlural },
  } = useAccountTerminology();
  const [updateAccountData, result] = useUpdateCurriculumDefaultSettingsMutation();
  const options: Option[] = accessControls.map((setting: AccessControl): Option => {
    return {
      label: t(`access_control.${setting}.title`),
      metaValue: t(`access_control.${setting}.description`, {
        curriculum: curriculumTermSingular.toLowerCase(),
      }),
      searchableTerm: '',
      value: setting,
    };
  });

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('access_control.title')}</FormSectionTitle>
      </FormSectionContainer>

      <FormSectionContainer>
        <FormSectionDescription>
          {t('access_control.description', { curriculums: curriculumTermPlural })}
        </FormSectionDescription>
      </FormSectionContainer>

      <FormSectionContainer>
        <Wrapper>
          <SingleSelectField
            className='account-settings-default-curriculum-access-control-dropdown'
            defaultValue={defaultCurriculumAccessControl}
            hideSelectedOptions={false}
            isSearchable={false}
            menuPosition='fixed'
            name='feedback-type'
            onNonDefaultSelected={(value: AccessControl) => {
              updateAccountData({
                default_access_control: value,
              });
            }}
            options={options}
            placeholder={t('category_placeholder')}
            value={defaultCurriculumAccessControl}
          />
        </Wrapper>
      </FormSectionContainer>
    </div>
  );
};

export default DefaultCurriculumAccessControlSection;
