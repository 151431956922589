import styled from 'styled-components';

import { HeadingWrapper, OutlineContentWrapper } from '../../shared/styles';

export const SidebarSkeletonWrapper = styled(OutlineContentWrapper)`
  padding: ${({ theme }) => theme.constants.spacerMd3};
`;

export const SkeletonHeadingWrapper = styled(HeadingWrapper)`
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const DropdownMenusWrapper = styled.div`
  height: 8.563rem;
  display: flex;
  justify-content: center;
`;

export const StepTitleWrapper = styled.div`
  display: flex;
  height: ${({ theme: { constants } }) => constants.heightLg};
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  align-self: stretch;
`;
