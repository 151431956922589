import React from 'react';
import styled from 'styled-components';

import { useFlowchartEditorData } from '../../../../contexts/FlowchartEditorDataProvider';
import BaseFooter from '../shared/BaseFooter';
import FooterLoader from '../shared/FooterLoader';
import NavigationButtons from './NavigationButtons/NavigationButtons';

const NavigationButtonsWrapper = styled.div`
  width: 22rem;
`;

const FooterContent = () => {
  const { data, isLoading } = useFlowchartEditorData();

  if (isLoading) return <FooterLoader />;
  if (!data) return null;

  const {
    adjacentCurriculumElements,
    curriculum: { id: curriculumId, emoji: curriculumEmoji },
  } = data;

  return (
    <NavigationButtonsWrapper>
      <NavigationButtons
        curriculumEmoji={curriculumEmoji}
        curriculumId={curriculumId}
        {...adjacentCurriculumElements}
      />
    </NavigationButtonsWrapper>
  );
};

const Footer = () => {
  const { isLoading } = useFlowchartEditorData();

  return (
    <BaseFooter isLoading={isLoading}>
      <FooterContent />
    </BaseFooter>
  );
};

export default Footer;
