import React, { useMemo } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import LinkTabs, { LinkTab } from '../../../../design_system/navigation/LinkTabs';
import { routes } from '../../../publicApplication/applicationRouter';

const t = initTranslations('reports.navigation');

const CurriculumReportsTabs = ({ curriculumId }: { curriculumId: number }) => {
  const { slug } = useCurrentAccount();

  const tabs: LinkTab[] = useMemo(() => {
    return [
      {
        name: t('by_user'),
        to: routes.curriculumByUserReport({ slug, id: curriculumId }),
        is_beta: false,
      },
      {
        name: t('by_content'),
        to: routes.curriculumByElementReport({ slug, id: curriculumId }),
        is_beta: false,
      },
    ];
  }, [curriculumId, slug]);

  return <LinkTabs id='curriculum-reports-tab' isUrlParams={false} tabs={tabs} />;
};

export default CurriculumReportsTabs;
