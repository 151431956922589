import React, { useEffect } from 'react';
import { Route } from 'type-route';

import useScrollTopOnChange from '../../../../hooks/useScrollTopOnChange';
import { useAppSelector } from '../../../../redux/hooks';
import { useGetSurveyConsumptionQuery } from '../../../../redux/services/resourceApis/surveys/surveysApi';
import { trainualApi } from '../../../../redux/services/trainualService';
import { store } from '../../../../redux/stores/store';
import GoBackIconButton from '../../editor/components/GoBackIconButton/GoBackIconButton';
import { routes } from '../../publicApplication/applicationRouter';
import FullScreenOverlay from '../../shared/FullScreenOverlay/FullScreenOverlay';
import PreviewCurriculumElementHeader from '../../shared/preview/PreviewCurriculumElementHeader/PreviewCurriculumElementHeader';
import PreviewSurveyStatistics from '../../shared/preview/PreviewSurveyStatistics/PreviewSurveyStatistics';
import {
  MainContentWrapper,
  MainWrapper,
  QuestionCardWrapper,
  SurveyDataPreviewWrapper,
  SurveyDataWrapper,
  Toolbar,
  ToolbarWrapper,
} from '../shared/styles';
import OutlineContent from './OutlineContent/OutlineContent';
import SurveyQuestionCard from './OutlineContent/SurveyQuestionCard/SurveyQuestionCard';
import ViewModeSurveySkeleton from './ViewModeSurveySkeleton/ViewModeSurveySkeleton';

export type SurveyConsumptionProps = {
  route: Route<typeof routes.surveyConsume>;
};

const SurveyConsumption = ({ route }: SurveyConsumptionProps) => {
  const {
    params: { slug, id },
  } = route;

  const { data, isSuccess } = useGetSurveyConsumptionQuery({ id });
  const { currentQuestionIndex } = useAppSelector((state) => state.surveyConsume);
  const currentQuestionRef = useScrollTopOnChange(currentQuestionIndex);

  useEffect(() => {
    if (isSuccess) {
      store.dispatch(
        trainualApi.util.invalidateTags([{ type: 'AssignmentsCurriculums', id: 'LIST' }])
      );
    }
  }, [isSuccess]);

  if (!data) return <ViewModeSurveySkeleton />;

  const {
    name,
    emoji,
    cover,
    status,
    description,
    attemptsCount,
    lastInProgressAttemptId,
    lastFinishedAttemptTimestamp,
    minimumScore,
    questions,
    curriculumId,
  } = data;

  return (
    <FullScreenOverlay id='survey-consumption-page-overlay' withoutCloseIcon>
      <MainWrapper>
        <OutlineContent id={id} slug={slug} />

        <MainContentWrapper ref={currentQuestionRef}>
          <ToolbarWrapper>
            <Toolbar>
              <GoBackIconButton
                onClick={() => routes.curriculumShow({ slug, id: curriculumId }).push()}
              />
            </Toolbar>
          </ToolbarWrapper>
          <SurveyDataWrapper>
            <SurveyDataPreviewWrapper>
              <PreviewCurriculumElementHeader
                cover={cover}
                description={description}
                displayEmoji
                elementType='survey'
                emoji={emoji}
                id={id}
                title={name}
              >
                {!!lastInProgressAttemptId ? undefined : (
                  <PreviewSurveyStatistics
                    attemptsCount={attemptsCount}
                    displayStartSurveyButton
                    lastFinishedAttemptTimestamp={lastFinishedAttemptTimestamp}
                    minimumScore={minimumScore}
                    questionsCount={questions.length}
                  />
                )}
              </PreviewCurriculumElementHeader>
            </SurveyDataPreviewWrapper>
          </SurveyDataWrapper>
          {!!lastInProgressAttemptId && (
            <QuestionCardWrapper>
              <SurveyQuestionCard
                lastInProgressAttemptId={lastInProgressAttemptId}
                questions={questions}
                surveyStatus={status}
              />
            </QuestionCardWrapper>
          )}
        </MainContentWrapper>
      </MainWrapper>
    </FullScreenOverlay>
  );
};

export default SurveyConsumption;
