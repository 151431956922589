import React, { ReactElement, ReactNode, useContext, useRef, useState } from 'react';
import Slider from 'react-slick';
import { ClearbitData } from 'redux/services/resourceApis/publicApplication/types';

import { OnboardingVariationType } from '../components/application/home/Onboarding/ChooseOnboardingVariationStep/types';
import {
  AccentColor,
  AccountLogo,
} from '../components/application/home/Onboarding/CustomizeThemeStep/types';
import { CustomizeYourExperienceStepData } from '../components/application/home/Onboarding/CustomizeYourExperienceStep/types';
import { UserOnboardingInfoStepData } from '../components/application/home/Onboarding/UserInfoStep/types';
import { useCheckCustomAccentColor } from '../hooks/useCheckCustomAccentColor';
import useCurrentAccount from '../hooks/useCurrentAccount';
import { useGetOnboardingDataQuery } from '../redux/services/resourceApis/onboarding/onboardingApi';
import { AccountOnboardingData } from '../redux/services/resourceApis/onboarding/types';
import { AccountState } from '../types/AccountState';
import { AvatarObject } from '../types/Avatar';

export type OnboardingData = UserOnboardingInfoStepData &
  AvatarObject &
  CustomizeYourExperienceStepData &
  AccountLogo &
  AccentColor &
  OnboardingVariationType;

type OnboardingState = {
  currentSlide: number;
  setCurrentSlide: (num: number) => void;
  formData: OnboardingData;
  initialFormData: OnboardingData;
  isInputFieldPrefilled: (initialValue: string, currentValue: string) => boolean;
  setFormData: (data: OnboardingData | object) => void;
  data: AccountOnboardingData | undefined;
  isLoadingData: boolean;
  sliderRef: React.RefObject<Slider>;
};

const OnboardingContext = React.createContext<OnboardingState>({} as OnboardingState);
export const useOnboarding = () => useContext(OnboardingContext);

const getInitialFormData = (
  currentAccount: AccountState,
  hasCustomAccentColor: boolean
): OnboardingData => {
  const storedData = localStorage.getItem('clearbitData');
  const {
    industry,
    logoUrl: accountLogoUrl,
    accentPalette: accountAccentPalette,
    accentColor,
    withDefaultLogo,
  } = currentAccount;
  const logoUrl = !withDefaultLogo && accountLogoUrl ? accountLogoUrl : undefined;
  const accentPalette = hasCustomAccentColor ? null : accountAccentPalette || 'purple';

  const defaultFormData: OnboardingData = {
    avatar: undefined,
    user_name: '',
    phone: '',
    source: '',
    custom_source: '',
    name: '',
    user_title: '',
    industry: industry || '',
    company_size: '10 or fewer',
    logo: undefined,
    logoUrl,
    accent_color: accentColor || null,
    accent_palette: accentPalette,
    onboarding_variation: 'explore_my_own',
    agreed_to_receive_sms: false,
  };

  if (storedData) {
    const clearbitData: ClearbitData = JSON.parse(storedData);
    return {
      ...defaultFormData,
      avatar: clearbitData.person?.avatar || '',
      logoUrl: clearbitData.company?.logo || '',
      name: clearbitData.company?.name || '',
      user_name: clearbitData.person?.name?.fullName || '',
      user_title: clearbitData.person?.employment?.title || '',
    };
  }
  return defaultFormData;
};

const OnboardingProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const currentAccount = useCurrentAccount();
  const hasCustomAccentColor = useCheckCustomAccentColor();
  const initialFormData = getInitialFormData(currentAccount, hasCustomAccentColor);
  const [formData, setFormData] = useState<OnboardingData>(initialFormData);
  const { data: data, isLoading: isLoadingData } = useGetOnboardingDataQuery(null);
  const sliderRef = useRef(null) as React.RefObject<Slider>;

  function isInputFieldPrefilled(initialValue: string, currentValue: string) {
    return !!initialValue.length && initialValue === currentValue;
  }

  const state: OnboardingState = {
    currentSlide,
    setCurrentSlide,
    formData,
    initialFormData,
    isInputFieldPrefilled,
    setFormData,
    data,
    isLoadingData,
    sliderRef,
  };

  return <OnboardingContext.Provider value={{ ...state }}>{children}</OnboardingContext.Provider>;
};

export { OnboardingProvider };
