import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';

import { AccountEditorProvider } from '../../../../../contexts/AccountEditorContext';
import { EditorProvider } from '../../../../../contexts/EditorContext';
import { EditorCourseProvider } from '../../../../../contexts/EditorCourseContext';
import { EditorToolbarProvider } from '../../../../../contexts/EditorToolbarContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { useGetEditorTopicQuery } from '../../../../../redux/services/resourceApis/courses/topicsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import BubbleMenuComponent from '../../components/BubbleMenu/BubbleMenu';
import { useEditorContent } from '../../hooks/useEditorContent';
import { BaseContentWrapper } from '../../shared/styles';
import { stepEditorBubbleToolbarButtons } from '../../toolbar/buttons/shared';
import ToolbarContainer from '../../toolbar/ToolbarContainer';
import EditModeSkeleton from '../components/EditModeSkeleton/EditModeSkeleton';
import EditorLockedOverlay from '../LockedOverlay';
import OutlineContent from '../OutlineContent/OutlineContent';
import DeprecatedEditor from './DeprecatedEditor';

type Props = {
  stepId: number;
};

const DeprecatedEditorPageBase = ({ stepId }: Props) => {
  const { productTerm } = useAccountTerminology();
  const account = useCurrentAccount();
  const { slug } = account;

  const { editor, isAILoading, stepData } = useEditorContent({
    stepId,
    isEditable: true,
  });
  const { data, isLoading, isFetching, error } = useGetEditorTopicQuery(
    stepData?.courseId ? { courseId: stepData.courseId } : skipToken
  );

  if (error) return <BasicErrorDisplay error={error} />;
  if (isLoading || !editor || !stepData || !data) return <EditModeSkeleton />;

  const { curriculum, ...course } = data;
  const lockedBySignatures = course.eSignatureDisplayedAndRequired && !course.canBeModified;
  const isLocked = lockedBySignatures || curriculum.locked;

  if (isLocked) {
    editor.setOptions({ editable: false });
  }

  return (
    <EditorCourseProvider
      course={course}
      curriculum={curriculum}
      productTerm={productTerm}
      stepId={stepId}
    >
      <AccountEditorProvider account={account}>
        <EditorProvider editor={editor} isAILoading={isAILoading}>
          <EditorToolbarProvider buttons={stepEditorBubbleToolbarButtons} context='bubble'>
            <BubbleMenuComponent />
          </EditorToolbarProvider>
          {isLocked && <EditorLockedOverlay course={course} curriculum={curriculum} slug={slug} />}
          <OutlineContent />
          <BaseContentWrapper>
            <ToolbarContainer isFetching={isFetching} isLocked={isLocked} step={stepData} />
            <DeprecatedEditor step={stepData} />
          </BaseContentWrapper>
        </EditorProvider>
      </AccountEditorProvider>
    </EditorCourseProvider>
  );
};

export default DeprecatedEditorPageBase;
