import styled from 'styled-components';

import P from '../../../../../../design_system/text/P';
import { fontMd1 } from '../../../../../../styled/TypeSystem';

export const LoadingStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 48px 73px;
`;

export const LoaderWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  .main-text {
    stroke: ${({ theme: { vars } }) => vars.textSubdued};
    stroke-dasharray: 2000;
    stroke-dashoffset: 0;
    animation: dash 5s linear infinite;
  }

  .dot-over-i {
    stroke: ${({ theme: { vars } }) => vars.textSubdued};
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash2 5s linear infinite;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 2000;
    }
    90% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash2 {
    60% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 266px;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd1};

  #signature-progress-bar {
    background-color: ${({ theme: { vars } }) => vars.stateInfo};
  }
`;

export const Subtitle = styled.h6`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontMd1};
`;

export const Text = styled(P)`
  ${fontMd1};
`;
