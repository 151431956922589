import styled from 'styled-components';

import P from '../../../../../design_system/text/P';
import { fontMd1, fontSm2 } from '../../../../../styled/TypeSystem';
import { infoTextStyles } from '../../../shared/styles';

export const StyledIframe = styled.iframe`
  display: block;
  width: 100%;
  max-width: 30rem;
  height: 37.5rem;
  margin: 0 auto;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDisabled}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
`;

export const Description = styled(P)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm2};
`;

export const ESignatureText = styled(P)`
  ${infoTextStyles};
  ${fontMd1};
`;
