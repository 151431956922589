import React from 'react';

import {
  StyledDateColumn,
  StyledTime,
} from '../../../AllUsersReportPage/AllUsersReportTable/Columns/styles';
import { ColumnComponentProps } from '../types';

const DueDate = ({ record: { due_date } }: ColumnComponentProps) => {
  return due_date.date ? (
    <StyledDateColumn>
      <span>{due_date.date}</span>
      <StyledTime>{due_date.time}</StyledTime>
    </StyledDateColumn>
  ) : (
    <StyledDateColumn>-</StyledDateColumn>
  );
};

export default DueDate;
