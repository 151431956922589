import React from 'react';

import { Indicator, Message, StatusIcon } from './styles';
import { IndicatorItemProps } from './types';

const IndicatorItem = ({
  errorMessage,
  validationErrors,
  isTouched,
  errorIdentifier,
}: IndicatorItemProps) => {
  const isError = isTouched && validationErrors.includes(errorIdentifier);
  const className = !isTouched
    ? 'validation-indicator'
    : isError
    ? 'validation-error-indicator'
    : 'validation-success-indicator';

  return (
    <Indicator className={className}>
      <StatusIcon
        isError={isError}
        isTouched={isTouched}
        name={isError ? 'circle-xmark' : 'circle-check'}
        size='xs'
      />
      <Message>{errorMessage}</Message>
    </Indicator>
  );
};

export default IndicatorItem;
