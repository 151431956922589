import React, { ChangeEvent, FC } from 'react';

import Icon from '../../../design_system/display/icons/Icon';
import { CheckOptionLabel, CheckOptionTitle } from './styles';
import { CheckOptionProps } from './types';

const CheckOption: FC<CheckOptionProps> = ({
  selectedValue,
  onSelectValue,
  value,
  label,
  name,
}) => {
  const isSelected = selectedValue === value;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSelectValue(e.target.value);
  };

  return (
    <CheckOptionLabel>
      <CheckOptionTitle>{label}</CheckOptionTitle>

      {isSelected && <Icon name='check' size='xs' weight='solid' />}

      <input
        checked={isSelected}
        hidden
        name={name}
        onChange={handleOnChange}
        type='radio'
        value={value}
      />
    </CheckOptionLabel>
  );
};

export default CheckOption;
