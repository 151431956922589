import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';

import { useSignUpPageFormAction } from '../../../../../hooks/public_application/useSignUpPageFormAction';
import useAnalyticsEvents from '../../../../../hooks/useAnalyticsEvents';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import useFieldErrorsFromFormik from '../../../../../hooks/useFieldErrorsFromFormik';
import usePartnerstack from '../../../../../hooks/usePartnerstack';
import {
  loginApi,
  useCreateHubspotContactMutation,
} from '../../../../../redux/services/resourceApis/publicApplication/loginApi';
import useRedirectToLoginOnSuccess from '../../utils/useRedirectToLoginOnSuccess';
import { SignUpPageProps } from '../types';
import CreateAccountLoader from './CreateAccountLoader';
import EmailPasswordForm from './EmailPasswordForm/EmailPasswordForm';
import { FormWrapper } from './styles';
import { FormValues } from './types';

const SignUpPageForm = ({ route }: SignUpPageProps) => {
  const { cdpSignUpClicked, cdpSignUpCompleted } = useAnalyticsEvents();
  const { partnerstackCreateSignup } = usePartnerstack();
  const [createHubspotContact] = useCreateHubspotContactMutation();
  const [clearbitTrigger, clearbitResult] = loginApi.useLazyClearbitDataQuery();
  const { data: clearbitData } = clearbitResult;

  const {
    params: {
      tg: templateGroupIdParam,
      tv: tvParam,
      no_trial: noTrialParam,
      partner_source: partnerSourceParam,
      industry: industryParam,
      checkout_token: checkoutTokenParam,
    },
  } = route;
  const { values, isSubmitting, setFieldError, setStatus, setSubmitting, isValid, handleSubmit } =
    useFormikContext<FormValues>();
  const { email, password } = values;
  const {
    result,
    error,
    messages,
    isFieldErrorMessage,
    isLoadingCreateAccount,
    isSuccessCreateAccount,
    submitHandler,
  } = useSignUpPageFormAction({
    data: {
      userEmail: email,
      userPassword: password,
      templateGroupId: templateGroupIdParam,
      tv: tvParam,
      noTrial: noTrialParam,
      partnerSource: partnerSourceParam,
      industry: industryParam,
      checkoutToken: checkoutTokenParam,
    },
  });

  const handleAccountCreation = useCallback(() => {
    cdpSignUpClicked();
    submitHandler();
  }, [cdpSignUpClicked, submitHandler]);

  const handleAfterCreateAccountActions = useCallback(() => {
    cdpSignUpCompleted();
    partnerstackCreateSignup(email);
    if (clearbitData?.company?.domain) {
      const emailDomain = email.split('@')[1];

      if (emailDomain === clearbitData.company.domain) {
        localStorage.setItem('clearbitData', JSON.stringify(clearbitData));
      }
    }
  }, [cdpSignUpCompleted, clearbitData, email, partnerstackCreateSignup]);

  useDisplayFlashOnResponse({
    result,
    errorMessage: isFieldErrorMessage ? messages?.join(', ') : undefined,
    successFunction: handleAfterCreateAccountActions,
  });

  useFieldErrorsFromFormik({
    error: isFieldErrorMessage ? undefined : error,
    setFieldError,
    setStatus,
    setSubmitting,
    wrapErrors: true,
  });

  const handleBeforeUnload = useCallback(() => {
    if (email.length && !isSubmitting) {
      createHubspotContact({ user_email: email });
    }
  }, [createHubspotContact, email, isSubmitting]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [handleBeforeUnload]);

  useRedirectToLoginOnSuccess(result);

  useEffect(() => {
    if (isSubmitting && isValid) {
      handleAccountCreation();
      setSubmitting(false);
    }
  }, [handleAccountCreation, isSubmitting, isValid, setSubmitting, values]);

  return (
    <>
      {isLoadingCreateAccount || isSuccessCreateAccount ? (
        <FormWrapper>
          <CreateAccountLoader />
        </FormWrapper>
      ) : (
        <form onSubmit={handleSubmit}>
          <EmailPasswordForm
            checkoutTokenParam={checkoutTokenParam}
            clearbitTrigger={clearbitTrigger}
          />
        </form>
      )}
    </>
  );
};

export default SignUpPageForm;
