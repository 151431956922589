import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TeammateModalProvider } from '../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import { getSavedOnboardingState } from '../../../../lib/home/getSavedOnboardingState';
import { getTaskDrivenOnboardingState } from '../../../../lib/home/getTaskDrivenOnboardingState';
import initTranslations from '../../../../lib/initTranslations';
import { useGetTaskListQuery } from '../../../../redux/services/resourceApis/taskLists/taskListsApi';
import { useGetTrainingPathQuery } from '../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import { TaskDrivenOnboardingState } from '../../../../types/TaskDrivenOnboarding';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import AddTeammateWithConfirmation from '../../people/TeammateModal/AddTeammateWithConfirmation';
import { routes } from '../../publicApplication/applicationRouter';
import GappedAccordion from '../../shared/GappedAccordion/GappedAccordion';
import OverviewSet from '../../TrainingPath/PathOverview/OverviewSets/OverviewSet/OverviewSet';
import TaskDrivenOnboardingCard from '../Onboarding/./task_driven/TaskDrivenOnboardingCard/TaskDrivenOnboardingCard';
import CurriculumsAssignments from '../CurriculumsAssignments/CurriculumsAssignments';
import FavoriteCurriculums from '../FavoriteCurriculums/FavoriteCurriculums';
import FollowingWidget from '../FollowingWidget/FollowingWidget';
import { CurrentTaskName } from '../Onboarding/task_driven/TaskDrivenOnboardingCard/types';
import TasksList from '../Onboarding/task_driven/TasksList/TasksList';
import { TaskData } from '../Onboarding/task_driven/TasksList/types';
import ProgressBlock from '../ProgressBlock/ProgressBlock';
import TrainualPlusWidget from '../TrainualPlusWidget/TrainualPlusWidget';
import TrainualUCard from '../TrainualUCard/TrainualUCard';
import { HeaderWrapper, LeftContainer, PageContent, RightContainer, StyledLoader } from './styles';

const UpcomingTrainingPathWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  flex-direction: column;
`;

const TrainingPathButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const t = initTranslations('home');

const StyledDiv = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const HomePageContent = () => {
  const { accountPlan, backdropSetupStatus, slug, trainingPathsEnabled } = useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const canSeeFollowingWidget = ability.can('read', 'FollowingWidget');
  const { documenting, invite, organize } = getSavedOnboardingState();
  const initialShowOnboardingTasks = !documenting && !invite && !organize;
  const [showOwnerOnboardingTasks, setShowOwnerOnboardingTasks] = useState(
    initialShowOnboardingTasks
  );
  const { permission, name, id: userId } = useCurrentUser();
  const isBillingAdmin = permission === 'billing_admin';
  const isBackdropSetupFinished = backdropSetupStatus === 'backdrop_setup_finished';
  const displayOnboarding = isBackdropSetupFinished && isBillingAdmin;
  const { data, isLoading } = useGetTaskListQuery(displayOnboarding ? null : skipToken);
  const [onboardingState, setOnboardingState] = useState<TaskDrivenOnboardingState>(
    getTaskDrivenOnboardingState(data?.tasks, data?.onboardingVariation)
  );
  const [currentTaskNameState, setCurrentTaskNameState] = useState<CurrentTaskName>();
  const showOnboardingTitle = displayOnboarding && showOwnerOnboardingTasks;
  const showTaskDrivenOnboarding = displayOnboarding && data;
  const { productTerm } = useAccountTerminology();
  const { data: trainingPathData } = useGetTrainingPathQuery({ userId, coming_up: true });

  useEffect(() => {
    setShowOwnerOnboardingTasks(!!currentTaskNameState);
  }, [currentTaskNameState, setShowOwnerOnboardingTasks]);

  useEffect(() => {
    if (data?.tasks && data?.onboardingVariation) {
      const { tasks, onboardingVariation } = data;
      setOnboardingState(getTaskDrivenOnboardingState(tasks, onboardingVariation));
    }
  }, [data]);

  if (!trainingPathData) return <></>;

  const { trainingPathSets } = trainingPathData;
  const trainingPathHasUpcomingSet = trainingPathsEnabled && !!trainingPathSets.length;

  const queryParams = new URLSearchParams(window.location.search);
  const kind = queryParams.get('kind');
  const trainingPathIncomplete = kind === null || kind === 'incomplete';
  const showTrainingPathComingUp =
    trainingPathIncomplete && !!trainingPathData.trainingPathSets.length && trainingPathsEnabled;

  return (
    <TeammateModalProvider>
      <LeftContainer>
        <PageContent>
          {!isLoading && (
            <>
              <HeaderWrapper>
                <PageHeader
                  title={
                    showOnboardingTitle
                      ? t('onboarding.title', { user: name, productTerm })
                      : t('home')
                  }
                />
              </HeaderWrapper>
              {showTaskDrivenOnboarding && (
                <TaskDrivenOnboardingCard
                  currentTaskNameState={currentTaskNameState}
                  onboardingState={onboardingState}
                  setCurrentTaskNameState={setCurrentTaskNameState}
                  setOnboardingState={setOnboardingState}
                />
              )}
            </>
          )}
          <CurriculumsAssignments
            trainingPathHasUpcomingSet={trainingPathHasUpcomingSet}
            userId={userId}
          />
          {showTrainingPathComingUp && (
            <StyledDiv>
              <GappedAccordion
                id='home-page-training-path-up-next-accordion'
                initialCollapsedState
                title={t('training_path.coming_up_next_accordion')}
              >
                <UpcomingTrainingPathWrapper>
                  <OverviewSet
                    set={trainingPathSets[0]}
                    setStatus='not started'
                    showFavorite
                    user={{ name, id: userId }}
                  />
                  <TrainingPathButtonWrapper>
                    <DefaultButton
                      buttonType='tertiary'
                      id='training-path-see-entire-tp-button'
                      onClick={() => {
                        routes.userTrainingPath({ slug, id: userId }).push();
                      }}
                      text={t('training_path.see_entire_training_path')}
                    />
                  </TrainingPathButtonWrapper>
                </UpcomingTrainingPathWrapper>
              </GappedAccordion>
            </StyledDiv>
          )}
        </PageContent>
      </LeftContainer>
      <RightContainer>
        {isLoading ? (
          <StyledLoader />
        ) : (
          <>
            <TrainualUCard />
            <TrainualPlusWidget />
            {showTaskDrivenOnboarding && (
              <TasksList
                onboardingState={onboardingState}
                setCurrentTaskNameState={setCurrentTaskNameState}
                tasks={data.tasks as TaskData[]}
              />
            )}
            {accountPlan !== 'build' && <ProgressBlock />}
            {canSeeFollowingWidget && <FollowingWidget />}
            <FavoriteCurriculums />
          </>
        )}
      </RightContainer>
      <AddTeammateWithConfirmation showAddConfirmation />
    </TeammateModalProvider>
  );
};

export default HomePageContent;
