import React from 'react';

import { useBulkMoveCurriculumsMutation } from '../../../../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import { BulkContentMoveModalProps } from '../../types';
import BulkContentMoveModal from './BulkContentMoveModal';

const BulkContentMove = ({
  onClose,
  selectedIds,
  onSuccess,
  type,
  curriculumsChecked,
}: BulkContentMoveModalProps) => {
  const [bulkMoveCurriculum, result] = useBulkMoveCurriculumsMutation();

  return (
    <BulkContentMoveModal
      bulkUpdate={({ curriculumIds, sector }) => bulkMoveCurriculum({ curriculumIds, sector })}
      bulkUpdateResult={result}
      curriculumsChecked={curriculumsChecked}
      onClose={onClose}
      onSuccess={onSuccess}
      selectedIds={selectedIds}
      type={type}
    />
  );
};

export default BulkContentMove;
