import React, { ReactNode, createContext, useState } from 'react';

import { RegisteredModalId } from '../lib/idRegistry';

type ActiveModal = RegisteredModalId | null;

type ActiveModalType = {
  setActiveModal: React.Dispatch<React.SetStateAction<ActiveModal>>;
  activeModal: ActiveModal;
};

const ActiveModalContext = createContext({} as ActiveModalType);

const ActiveModalProvider = ({ children }: { children: ReactNode }) => {
  const [activeModal, setActiveModal] = useState<ActiveModal>(null);

  return (
    <ActiveModalContext.Provider value={{ activeModal, setActiveModal }}>
      {children}
    </ActiveModalContext.Provider>
  );
};

// ActiveModalContext should not be used directly. Instead use useActiveModalHandler to interact with modals
export { ActiveModalProvider, ActiveModalContext };
