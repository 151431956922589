import { RefObject } from 'react';

export const isNonGroupListIntersected = (ref: RefObject<HTMLDivElement> | null) => {
  const el = ref?.current;

  if (!el) return;

  const elTop = el.getBoundingClientRect().top;
  const parentHeight = el.parentElement?.getBoundingClientRect().height || 0;
  const parentTop = el.parentElement?.getBoundingClientRect().top || 0;

  return elTop < parentTop + parentHeight;
};
