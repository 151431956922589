import React from 'react';

import getLetterByPosition from '../../../../../../../../lib/surveys/getLetterByPosition';
import { SurveyChoiceLetter } from '../../../../styles';
import { ResultIcon, ResultText } from '../../styles';
import {
  ChoiceElementWrapper,
  ChoiceIcon,
  ChoiceInputWrapper,
  ChoiceTextContainer,
} from './styles';
import { ChoiceElementProps } from './types';

const ChoiceElement = ({
  correct,
  isSelected,
  text,
  id,
  position,
  iconType,
}: ChoiceElementProps) => {
  const iconName =
    iconType === 'checkbox'
      ? isSelected
        ? 'square-check'
        : 'square'
      : isSelected
      ? 'circle-dot'
      : 'circle';

  return (
    <ChoiceElementWrapper id={`answered-question-option-${id}`}>
      <ChoiceIcon
        isCorrect={correct}
        isSelected={isSelected}
        name={iconName}
        size='md'
        weight='regular'
      />
      <ChoiceInputWrapper isCorrect={correct} isSelected={isSelected}>
        <ChoiceTextContainer>
          <SurveyChoiceLetter isCorrect={correct} isSelected={isSelected}>
            {getLetterByPosition(position)}
          </SurveyChoiceLetter>
          <ResultText>{text}</ResultText>
        </ChoiceTextContainer>
        {isSelected && (
          <ResultIcon
            isCorrect={correct}
            name={correct ? 'check' : 'xmark'}
            size='sm'
            weight='solid'
          />
        )}
      </ChoiceInputWrapper>
    </ChoiceElementWrapper>
  );
};

export default ChoiceElement;
