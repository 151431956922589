import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Route } from 'type-route';

import { useDispatchSetShowOverlay } from '../../../../hooks/useDispatchSetShowOverlay';
import useWindowResize from '../../../../hooks/useWindowResize';
import initTranslations from '../../../../lib/initTranslations';
import { useGetCurriculumPdfExportQuery } from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import Portal from '../../../application/Portal';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import PDFPageContent from '../../editor/PDFExportPage/PDFPageContent';
import { routes } from '../../publicApplication/applicationRouter';

const t = initTranslations('curriculums.print');

const paperSize = 'A4';
const paperWidth = '21cm';
const margin = '1cm';

const FullPageWrapper = styled.div`
  ${({ theme: { vars } }) => css`
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    background: ${vars.foundationBase2};
    z-index: 1001;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    @page {
      margin: ${margin};
      size: ${paperSize};
    }
  `}
`;

const ControlBar = styled.div`
  ${({ theme: { constants, vars } }) => css`
    background: ${vars.foundationBase2};
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media print {
      display: none;
    }
    padding: ${constants.spacerMd1};
    margin-bottom: ${constants.spacerMd1};
  `}
`;

const PaperView = styled.div`
  ${({ theme: { vars } }) => css`
    width: ${paperWidth};
    min-width: ${paperWidth};
    max-width: ${paperWidth};
    padding: ${margin};
    background: ${vars.foundationSurface1};

    @media print {
      padding: 0 !important;
      color: red !important;
      min-width: 100% !important;
      max-width: 100% !important;
      transform: none !important;
    }
  `}
`;

type Props = {
  route: Route<typeof routes.curriculumPrint>;
};

const print = () => window.print();

const CurriculumPrint = ({ route }: Props) => {
  const { id, course_ids } = route.params;
  const { isLoading, data: curriculum } = useGetCurriculumPdfExportQuery({
    id,
    courseIds: course_ids,
  });
  const paperRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowResize();

  const dispatchShowOverlay = useDispatchSetShowOverlay();
  useEffect(() => {
    // Hides the nav/sidebar
    dispatchShowOverlay('curriculum-print', true);
  }, [dispatchShowOverlay]);

  const minMargin = 20;
  const paperStyles = { transformOrigin: 'top', transform: 'none' };

  if (paperRef.current) {
    const div = paperRef.current;
    if (div.clientWidth + minMargin * 2 >= width) {
      const desiredSize = width - minMargin * 2;
      const scale = desiredSize / div.clientWidth;
      paperStyles.transform = `scale(${scale})`;
    }
  }

  return (
    <>
      <Portal>
        <FullPageWrapper>
          <ControlBar>
            <DefaultButton
              disabled={isLoading}
              id='print-curriculum-button'
              onClick={() => print()}
              text={t('print')}
            />
          </ControlBar>
          <PaperView ref={paperRef} style={paperStyles}>
            <PDFPageContent curriculum={curriculum} isLoading={isLoading} signatureExport={false} />
          </PaperView>
        </FullPageWrapper>
      </Portal>
    </>
  );
};

export default CurriculumPrint;
