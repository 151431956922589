import { useSortable } from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import useWindowResize from '../../../../hooks/useWindowResize';
import { RegisteredMenuId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { openResponsibilityDetailsModal } from '../../../../redux/domains/responsibilityDetailsModal/responsibilityDetailsModalSlice';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useDeleteResponsibilityMutation } from '../../../../redux/services/resourceApis/responsibilities/responsibilitiesApi';
import { AssignedResponsibility } from '../../../../types/AssignedResponsibility';
import Icon from '../../../design_system/display/icons/Icon';
import ThreeDotMenu from '../../../design_system/Triage/menus/ThreeDotMenu';
import { mediaBreakpointPxLg, mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { DragStyle } from '../../shared/DragAndDrop/styles';

const SpacedOutLi = styled.li`
  padding-bottom: 0.125rem;
  border-bottom: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};
  cursor: move;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  margin: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-right: 0;
  flex-shrink: 0;
`;

const StyledP = styled.p`
  max-width: 90%;
  margin-bottom: 0;

  ${({ theme }) =>
    `
    &:hover {
      color: ${theme.vars.accentPrimaryDefault};
      cursor: pointer;
      text-decoration: underline;
    }
  `}
`;

const HoverableDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} 0`};

  &:hover {
    div {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }
    svg {
      opacity: 1;
    }
  }
`;

const IconWrapper = styled.div`
  display: none;
  color: ${({ theme: { vars } }) => vars.textDefault};
  border-radius: 50%;
  border: ${({ theme: { constants } }) => constants.borderWidthSm} dashed
    ${({ theme: { vars } }) => vars.textDefault};
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    border: ${({ theme: { constants } }) => constants.borderWidthSm} solid transparent;
  }

  &:hover {
    border: ${({ theme: { constants } }) => constants.borderWidthSm} dashed
      ${({ theme: { vars } }) => vars.textDefault};
    cursor: pointer;
  }
`;

const DragAndDropIcon = styled(Icon)`
  display: none;
  @media (min-width: ${mediaBreakpointPxLg}) {
    display: block;
    margin-right: ${(props) => props.theme.constants.spacerSm3};
    margin-left: ${(props) => props.theme.constants.spacerSm1};
    color: ${({ theme: { vars } }) => vars.textDefault};
    opacity: 0;
  }
`;

const t = initTranslations('responsibilities');

export interface ListItemProps {
  assignedResponsibility: AssignedResponsibility;
  setAssignedResponsibilityEditId: () => void;
}

const ResponsibilityListItem = ({
  assignedResponsibility,
  setAssignedResponsibilityEditId,
}: ListItemProps) => {
  const dispatch = useDispatch();
  const {
    id,
    responsibility: { name, id: responsibilityId },
  } = assignedResponsibility;
  const threeDotMenuId: RegisteredMenuId = `group-three-dot-assigned-responsibility-${id}`;

  const { onHoldPlan, status: accountStatus } = useCurrentAccount();
  const [deleteResponsibility, result] = useDeleteResponsibilityMutation();
  const { isDesktop } = useWindowResize();
  const isDragDisabled = useMemo(() => {
    return !isDesktop || accountStatus === 'trial_ended';
  }, [isDesktop, accountStatus]);

  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id,
    disabled: isDragDisabled,
  });

  const isTrialEndedOrOnHold = accountStatus === 'trial_ended' || onHoldPlan;

  useDisplayFlashOnResponse({
    result,
    errorMessage: messageFromError(result.error)?.join(', ') || t('remove_failure'),
  });

  return (
    <SpacedOutLi
      ref={setNodeRef}
      style={DragStyle(transition, transform, isDragging)}
      {...listeners}
      {...attributes}
    >
      <FlexWrapper>
        <HoverableDiv id={`editable-${id}-hover-wrapper`}>
          {!isTrialEndedOrOnHold && <DragAndDropIcon name='grip-vertical' weight='solid' />}
          <StyledP
            onClick={() =>
              dispatch(openResponsibilityDetailsModal({ id: responsibilityId, mode: 'edit' }))
            }
          >
            {name}
          </StyledP>
          {!isTrialEndedOrOnHold && (
            <IconWrapper
              id={`editable-${id}-icon-wrapper`}
              onClick={setAssignedResponsibilityEditId}
            >
              <Icon name='pencil' weight='regular' />
            </IconWrapper>
          )}
        </HoverableDiv>
        {!isTrialEndedOrOnHold && (
          <ActionsWrapper>
            <ThreeDotMenu
              id={threeDotMenuId}
              menuOptions={[
                {
                  title: t('edit'),
                  onClick: () => {
                    setAssignedResponsibilityEditId();
                  },
                  iconName: 'pencil',
                  id: 'td-groups-edit-responsibilities-table-edit-responsibility',
                  visible: true,
                },
                {
                  title: t('remove'),
                  onClick: () => deleteResponsibility(id),
                  iconName: 'trash-alt',
                  id: 'td-groups-edit-responsibilities-table-remove-responsibility',
                  visible: true,
                },
              ]}
            />
          </ActionsWrapper>
        )}
      </FlexWrapper>
    </SpacedOutLi>
  );
};

export default ResponsibilityListItem;
