import * as FullStory from '@fullstory/browser';
import { useCallback, useEffect, useState } from 'react';
import { Route } from 'type-route';
import { AccountState } from 'types/AccountState';
import { CurrentUser } from 'types/CurrentUser';

import { loginGroup, routes } from '../components/application/publicApplication/applicationRouter';
import usePublicConfigs from './usePublicConfigs';

export default function useFullStory(
  route: Route<typeof routes>,
  user: CurrentUser | undefined,
  account: AccountState | undefined
) {
  const { configs } = usePublicConfigs();
  const [isInitialized, setIsInitialized] = useState(FullStory.isInitialized());

  const init = useCallback(() => {
    const fullstoryOrgId = configs['FULLSTORY_ORG_ID'];

    if (loginGroup.has(route) || !fullstoryOrgId || FullStory.isInitialized()) {
      return;
    }

    FullStory.init({ orgId: fullstoryOrgId });
    setIsInitialized(true);
  }, [route, configs]);

  useEffect(() => {
    if (!user || !account || !isInitialized) {
      return;
    }

    FullStory.setUserVars({
      displayName: user.name,
      email: user.email,
      permission: user.permission,
      slug: account.slug,
      stripePlan: account.stripePlanId,
      stripeCustomerId: account.stripeCustomerId,
      total_seats: account.totalUserSlotsCount,
      used_seats: account.usedUserSlotsCount,
      accountStatus: account.status,
      companySize: account.employeeSize,
      industry: account.industry,
    });
  }, [user, account, isInitialized]);

  if (user && user.permission == 'general') {
    return {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      init: () => {},
    };
  }

  return { init };
}
