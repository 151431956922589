import React, { useEffect, useState } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { RegisteredModalId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import {
  useDeleteStepMutation,
  useDuplicateStepMutation,
  useMoveStepMutation,
} from '../../../../../redux/services/resourceApis/steps/stepsApi';
import { LimitedStep } from '../../../../../types/Step';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import MoveStepModal from '../../../curriculums/modals/MoveStepModal/MoveStepModal';
import RestrictMoveCurriculumModal from '../../../curriculums/modals/RestrictMoveCurriculumModal/RestrictMoveCurriculumModal';
import { ElementType } from '../../../curriculums/modals/RestrictMoveCurriculumModal/utils';
import DeleteCurriculumElementModal from '../../../curriculums/shared/Modals/DeleteCurriculumElementModal';
import DuplicateCurriculumElementModal from '../../../curriculums/shared/Modals/DuplicateCurriculumElementModal';
import { routes } from '../../../publicApplication/applicationRouter';
import useActiveModalHandler from '../../../publicApplication/utils/useActiveModalHandler';
import ManageStepExternalLinksModal from '../../../steps/modals/stepExternalLinks/ManageStepExternalLinksModal/ManageStepExternalLinksModal';
import { ViewingFrom } from '../StepThreeDot';

type Props = {
  step: LimitedStep;
  viewingFrom: ViewingFrom;
  activeModal: RegisteredModalId;
};

const t = initTranslations('curriculums.three_dot_menu');

const ModalController = ({ step, viewingFrom, activeModal }: Props) => {
  const viewingFromEditor = viewingFrom === 'editor';
  const { id, title, courseId } = step;

  const [warningType, setWarningType] = useState<ElementType>();

  const {
    step: { singular: stepTerm },
  } = useAccountTerminology();
  const { slug } = useCurrentAccount();
  const { setActiveModal, closeActiveModal } = useActiveModalHandler();

  const [duplicateStep, duplicateResult] = useDuplicateStepMutation();
  const [deleteStep, deleteResult] = useDeleteStepMutation();
  const [moveStep, moveResult] = useMoveStepMutation();

  useEffect(() => {
    if (viewingFromEditor && deleteResult.isSuccess) {
      routes.courseEditor({ slug, id: courseId, redirecting: true }).push();
    }
  }, [courseId, deleteResult.isSuccess, id, slug, viewingFromEditor]);

  useEffect(() => {
    if (viewingFromEditor && moveResult.isSuccess) {
      routes.courseEditor({ slug, id: courseId, redirecting: true }).push();
    }
  }, [courseId, moveResult.isSuccess, slug, viewingFromEditor]);

  useEffect(() => {
    if (warningType) {
      setActiveModal(`restrict-move-modal-${id}`);
    }
  }, [id, setActiveModal, warningType]);

  switch (activeModal) {
    case `duplicate-step-modal-${id}`:
      return (
        <DuplicateCurriculumElementModal
          closeRequest={closeActiveModal}
          duplicateElement={(values) =>
            duplicateStep({ stepId: id, courseId, title: values.title })
          }
          elementType={stepTerm}
          id={id}
          result={duplicateResult}
          title={title}
        />
      );
    case `delete-step-modal-${id}`:
      return (
        <DeleteCurriculumElementModal
          closeRequest={closeActiveModal}
          deleteElement={() =>
            deleteStep({ stepId: id, courseId, skipInvalidateTags: viewingFromEditor })
          }
          elementTitle={title}
          elementType={stepTerm}
          result={deleteResult}
        />
      );
    case `move-step-modal-${id}`:
      return (
        <MoveStepModal
          closeRequest={closeActiveModal}
          currentCourseId={courseId}
          moveElement={(newCourseId: string) => {
            newCourseId && moveStep({ stepId: id, newCourseId, exisingCourseId: courseId });
          }}
          result={moveResult}
          setWarningType={setWarningType}
        />
      );
    case `manage-extensions-modal-${id}`:
      return <ManageStepExternalLinksModal onClose={closeActiveModal} stepId={id} />;
    case `restrict-move-modal-${id}`:
      return (
        <RestrictMoveCurriculumModal
          elementType={warningType}
          onCloseRequest={() => {
            closeActiveModal();
            setWarningType(undefined);
          }}
        />
      );
    default:
      throw new Error(t('unknown_modal_error', { activeModal }));
  }
};

export default ModalController;
