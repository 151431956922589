import {
  CellPosition,
  ContentAlignment,
  Display,
} from '../../../people/UsersOutline/Table/TableTypes';
import { LoadingSize } from '../../TableTypes';
import { SurveyReportColumn } from './types';

export interface Column {
  columnName: SurveyReportColumn;
  loadingSize: LoadingSize;
  stickyPosition?: CellPosition;
  display?: Display;
  contentAlignment?: ContentAlignment;
  sortable: boolean;
}

export const columns: Column[] = [
  {
    columnName: 'position',
    stickyPosition: 'left',
    display: 'static',
    loadingSize: LoadingSize.large,
    sortable: true,
  },
  { columnName: 'text', loadingSize: LoadingSize.medium, sortable: true },
  { columnName: 'avg_correct_answers', loadingSize: LoadingSize.medium, sortable: true },
];
