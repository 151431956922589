import React, { Dispatch, useEffect, useState } from 'react';
import styled from 'styled-components';

import { CurriculumElementsProvider } from '../../../../../contexts/CurriculumElementsContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { useEmptyStateData } from '../../../../../hooks/useEmptyStateData';
import { openCurriculumModal } from '../../../../../redux/domains/curriculumModal/curriculumModalSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useGetContentLibraryCurriculumsQuery } from '../../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import Pagination from '../../../../design_system/Triage/Paginate';
import { routes } from '../../../publicApplication/applicationRouter';
import TableLoader from '../../../shared/Loaders/TableLoader';
import BulkContentActionsMenu from '.././BulkContentActionsMenu/BulkContentActionsMenu';
import { TableAction } from '../libraryReducer';
import LibraryRow from '../LibraryRow/LibraryRow';
import LibraryTableEmptyState from './LibraryTableEmptyState';

const TableWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export interface Props {
  tableDispatch: Dispatch<TableAction>;
  isFiltering: boolean;
  filtersQuery: string;
  curriculumDeleted?: boolean;
  hideEmptyState?: boolean;
}

const LibraryTable = ({
  tableDispatch,
  isFiltering,
  filtersQuery,
  curriculumDeleted,
  hideEmptyState = false,
}: Props) => {
  // Local state
  const dispatch = useAppDispatch();
  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [isBulkActionsMenuOpen, setIsBulkActionsMenuOpen] = useState(false);
  const {
    slug,
    splitFeatures: { contentBulkActionsEnabled },
  } = useCurrentAccount();

  // Pagination
  const [page, setPage] = useState(1);
  const {
    isFetching,
    isLoading,
    data,
    refetch: refetchContentLibraryCurriculums,
  } = useGetContentLibraryCurriculumsQuery({
    filtersQuery,
    page,
  });

  const { curriculums, totalPages, totalCurriculumsCount, limitValue } = data || {
    curriculums: [],
    totalPages: 0,
    totalCurriculumsCount: 0,
    limitValue: 0,
  };

  // Redirect after curriculum deletion
  useEffect(() => {
    if (curriculumDeleted) {
      refetchContentLibraryCurriculums();
      routes.content({ slug }).push();
    }
  }, [slug, curriculumDeleted, refetchContentLibraryCurriculums]);

  // Loading and empty state
  useEffect(() => {
    tableDispatch({ type: 'setLoading', loading: isFetching });
  }, [isFetching, tableDispatch]);

  const emptyStateData = useEmptyStateData(
    curriculums,
    isFiltering,
    () => dispatch(openCurriculumModal()),
    tableDispatch
  );

  if (isLoading) return <TableLoader />;
  if (emptyStateData)
    return (
      <LibraryTableEmptyState emptyStateData={emptyStateData} hideEmptyState={hideEmptyState} />
    );

  // Event handlers
  const closeBulkActionsMenu = () => {
    setIsBulkActionsMenuOpen(false);
    setCheckedRows([]);
  };

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setIsBulkActionsMenuOpen(true);
      setCheckedRows((prevCheckedRows) => [...prevCheckedRows, id]);
    } else {
      setCheckedRows((prevCheckedRows) => prevCheckedRows.filter((rowId) => rowId !== id));
    }
  };

  const numberOfCheckedRows = checkedRows.length;

  return (
    <TableWrapper id='content-library-table'>
      <CurriculumElementsProvider>
        {curriculums.map(
          ({
            id,
            owner,
            groupsCount,
            title,
            sector,
            published,
            updatedAt,
            archived,
            locked,
            description,
            emoji,
            signaturable,
            favorited,
            assignmentId,
            userAccess,
            userCanEdit,
          }) => {
            const rowIsChecked = checkedRows.includes(id);

            return (
              <LibraryRow
                archived={archived}
                assignmentId={assignmentId}
                canEdit={userCanEdit}
                description={description}
                emoji={emoji}
                favorited={favorited}
                groupsCount={groupsCount}
                id={id}
                isBulkActionsMenuOpen={isBulkActionsMenuOpen}
                isChecked={rowIsChecked}
                isLocked={locked}
                key={`curriculum-${id}`}
                onCheckboxChange={handleCheckboxChange}
                owner={owner}
                published={published}
                sector={sector}
                setIsBulkActionsOpen={() => setIsBulkActionsMenuOpen(!isBulkActionsMenuOpen)}
                signaturable={signaturable}
                title={title}
                updatedAt={updatedAt}
                userAccess={userAccess}
              />
            );
          }
        )}
        <Pagination
          activePage={page}
          itemsCountPerPage={limitValue}
          onChange={(page) => setPage(page)}
          showPaginationDetails
          totalItemsCount={totalCurriculumsCount}
          totalPages={totalPages}
        />
        {contentBulkActionsEnabled && numberOfCheckedRows > 0 && (
          <BulkContentActionsMenu
            archiveFilterOn={filtersQuery.includes('kind=archived')}
            curriculumsChecked={curriculums.filter((curriculum) =>
              checkedRows.includes(curriculum.id)
            )}
            onCloseRequest={closeBulkActionsMenu}
            selectedCount={numberOfCheckedRows}
            selectedIds={checkedRows}
          />
        )}
      </CurriculumElementsProvider>
    </TableWrapper>
  );
};

export default LibraryTable;
