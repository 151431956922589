import { SearchAction, SearchState } from './types';

export const initialFiltersState = {
  author: [],
  suggestedGroups: [],
  tags: [],
  sectors: [],
  categoryIds: [],
};

export const searchReducer = (state: SearchState, action: SearchAction): SearchState => {
  switch (action.type) {
    case 'changeSort': {
      return { ...state, sort: action.sort };
    }
    case 'changePage': {
      return { ...state, page: action.page };
    }
    case 'applyFilters': {
      return { ...state, filters: action.filters, page: 1 };
    }
    case 'clearFilters': {
      return { ...state, filters: initialFiltersState, page: 1, viewBy: 'all' };
    }
    case 'changeSearchTerm': {
      return { ...state, searchTerm: action.searchTerm, page: 1 };
    }
    case 'changeViewBy': {
      return { ...state, viewBy: action.viewBy, page: 1 };
    }
  }
};
