import React, { useContext, useState } from 'react';

import { PaywallContext } from '../../../../../contexts/PaywallContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useToggleAccountOptOutFeatureMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Hoverable from '../../../../design_system/Hoverable';
import Toggle from '../../../../design_system/input/controls/Toggle';
import PaywallTooltip from '../../../shared/tooltips/PaywallTooltip';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  StyledIcon,
  ToggleContainer,
} from '../styles';
import { SectorLibrariesSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const SectorLibrariesSection = ({ enableSectorLibraries }: SectorLibrariesSectionProps) => {
  const paywallCtx = useContext(PaywallContext);
  const cardViewLibraryLocked = paywallCtx.includes('curriculum_advanced_settings');
  const [toggleAccountFeature, result] = useToggleAccountOptOutFeatureMutation();
  const { isLoading } = result;
  const [isToggleHovered, setToggleHovered] = useState(false);

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id='enable-sector-libraries-toggle'>
      <FormSectionContainer>
        <FormSectionTitle>
          {enableSectorLibraries ? t('content_on_title') : t('content_off_title')}
          {cardViewLibraryLocked && <StyledIcon name='lock' weight='regular' />}
        </FormSectionTitle>
        <ToggleContainer>
          {cardViewLibraryLocked && (
            <PaywallTooltip
              description={t('content_paywall.description')}
              modifiers={[{ name: 'offset', options: { offset: [25, 0] } }]}
              shouldBeVisible={isToggleHovered}
              title={t('content_paywall.title')}
            />
          )}
          <Hoverable setIsHovered={setToggleHovered}>
            <Toggle
              checked={enableSectorLibraries}
              className='sector-libraries'
              disabled={cardViewLibraryLocked || isLoading}
              handleToggle={() => toggleAccountFeature({ feature: 'sector_libraries' })}
              id='enable-sector-libraries'
              name='enable_sector_libraries'
            />
          </Hoverable>
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>
          {enableSectorLibraries
            ? t('sector_libraries_on_description')
            : t('sector_libraries_off_description')}
        </FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default SectorLibrariesSection;
