import React, { useCallback, useState } from 'react';

import { useAccountTerminology } from '../../../../../../components/AccountTerminologyProvider';
import { useTeammateModal } from '../../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { RegisteredMenuId } from '../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../lib/initTranslations';
import isBrowserSafariOrFirefox from '../../../../../../lib/isBrowserSafariOrFirefox';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import {
  useArchiveUserMutation,
  useDeleteUserMutation,
  useResendInviteMutation,
  useUnarchiveUserMutation,
} from '../../../../../../redux/services/resourceApis/users/usersApi';
import { User } from '../../../../../../types/User';
import ConfirmationModal from '../../../../../design_system/overlays/modals/ConfirmationModal';
import ThreeDotMenu from '../../../../../design_system/Triage/menus/ThreeDotMenu';
import { ThreeDotMenuOptionProps } from '../../../../../design_system/Triage/menus/ThreeDotMenu/types';
import OutOfSeatsConfirmationModal from '../../../OutOfSeatsConfirmationModal';
import TeammateModal from '../../../TeammateModal/TeammateModal';
import { RightAlignedDataStyled, ThreeDotCell } from '../Styles';

const t = initTranslations('users_table.three_dot_menu');

interface Props {
  changePageToPreviousIfLastDeleted: () => void;
  user: User;
}

const Menu = ({ changePageToPreviousIfLastDeleted, user }: Props) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const closeDeleteConfirmationModal = () => setShowDeleteConfirmation(false);
  const { setTeammateModalVisible } = useTeammateModal();
  const {
    noFreeSlots,
    usedUserSlotsCount,
    totalUserSlotsCount,
    onHoldPlan,
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [archiveUser, archiveUserResult] = useArchiveUserMutation();
  const { error: archiveUserError, reset: archiveUserReset } = archiveUserResult;
  const [unarchiveUser, unarchiveUserResult] = useUnarchiveUserMutation();
  const { error: unarchiveUserError, reset: unarchiveUserReset } = unarchiveUserResult;
  const [destroyUser, destroyUserResult] = useDeleteUserMutation();
  const {
    isLoading: destroyUserIsLoading,
    error: destroyUserError,
    reset: destroyUserReset,
  } = destroyUserResult;
  const menuId: RegisteredMenuId = `user-${user.id}`;

  const showResendInvite = ability.can('resend_invite', user) && !onHoldPlan;
  const showSendInvite = ability.can('invite', user) && !onHoldPlan;

  const deleteSuccessFunction = useCallback(() => {
    closeDeleteConfirmationModal();
    changePageToPreviousIfLastDeleted();
    destroyUserReset();
  }, [changePageToPreviousIfLastDeleted, destroyUserReset]);

  const [resendInvite, inviteResult] = useResendInviteMutation();
  const { error: resetInviteError, reset: resendInviteReset } = inviteResult;

  useDisplayFlashOnResponse({
    result: destroyUserResult,
    successMessage: t('delete_success', { name: user.name }),
    successFunction: deleteSuccessFunction,
    errorMessage: messageFromError(destroyUserError)?.join(', '),
  });

  useDisplayFlashOnResponse({
    result: inviteResult,
    successMessage: t('invite_success'),
    successFunction: resendInviteReset,
    errorMessage: messageFromError(resetInviteError)?.join(', '),
  });

  useDisplayFlashOnResponse({
    result: archiveUserResult,
    successMessage: t('archive_success', { name: user.name }),
    successFunction: archiveUserReset,
    errorMessage: messageFromError(archiveUserError)?.join(', '),
  });

  useDisplayFlashOnResponse({
    result: unarchiveUserResult,
    successMessage: t('unarchive_success', { name: user.name }),
    successFunction: unarchiveUserReset,
    errorMessage: messageFromError(unarchiveUserError)?.join(', '),
  });

  const sendInvite = () => {
    if (perUserPricingEnabled && noFreeSlots) {
      setShowConfirmationModal(true);
    } else {
      resendInvite(user.id);
    }
  };

  const options: ThreeDotMenuOptionProps[] = [
    {
      title: t('send_invite'),
      onClick: sendInvite,
      iconName: 'paper-plane',
      iconWeight: 'regular',
      id: 'td-users-outline-send-invite-to-user',
      visible: showSendInvite,
    },
    {
      title: t('resend_invite'),
      onClick: () => {
        resendInvite(user.id);
      },
      iconName: 'paper-plane',
      iconWeight: 'regular',
      id: 'td-users-outline-resend-invite-to-user',
      visible: showResendInvite,
    },
    {
      title: t('edit'),
      onClick: () => {
        setTeammateModalVisible(true);
      },
      iconName: 'pencil',
      iconWeight: 'regular',
      id: 'td-users-outline-edit-user',
      visible: ability.can('edit', user),
    },
    {
      title: t('archive'),
      onClick: () => {
        archiveUser(user.id);
      },
      iconName: 'archive',
      iconWeight: 'regular',
      id: 'td-users-outline-archive-user',
      visible: ability.can('archive', user),
    },
    {
      title: t('unarchive'),
      onClick: () => {
        unarchiveUser(user.id);
      },
      iconName: 'archive',
      iconWeight: 'regular',
      id: 'td-users-outline-unarchive-user',
      visible: ability.can('unarchive', user),
    },
    {
      title: t('delete'),
      onClick: () => {
        setShowDeleteConfirmation(true);
      },
      iconName: 'trash-alt',
      iconWeight: 'regular',
      id: 'td-users-outline-delete-user',
      visible: ability.can('destroy', user),
    },
  ];

  const { productTerm } = useAccountTerminology();

  return (
    <>
      {showDeleteConfirmation && (
        <ConfirmationModal
          actionFunction={() => {
            destroyUser(user.id);
          }}
          actionText={t('delete_modal.confirm_button')}
          hasPrimaryButton
          heapModalName='delete-user-confirmation-modal'
          message={t('delete_modal.body', { productTerm })}
          onCloseRequest={closeDeleteConfirmationModal}
          processing={destroyUserIsLoading}
          title={t('delete_modal.header')}
        />
      )}
      <RightAlignedDataStyled>
        <ThreeDotCell>
          <ThreeDotMenu
            id={menuId}
            menuModifiers={[{ name: 'offset', options: { offset: [10, 20] } }]}
            menuOptions={options}
            menuPlacement='left-end'
            menuStrategy={isBrowserSafariOrFirefox(navigator.userAgent) ? 'absolute' : 'fixed'}
          />
        </ThreeDotCell>
        <TeammateModal action='edit' />
        {perUserPricingEnabled && (
          <OutOfSeatsConfirmationModal
            setShowModal={setShowConfirmationModal}
            showModal={showConfirmationModal}
            usedUserSlots={usedUserSlotsCount}
            userSlots={totalUserSlotsCount}
          />
        )}
      </RightAlignedDataStyled>
    </>
  );
};

export default Menu;
