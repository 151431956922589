import { useCallback, useEffect, useState } from 'react';

import { UpdatePaymentMethodNameOfRequiredFields } from '../../../components/application/shared/UpdatePaymentMethodForm/types';
import initTranslations from '../../../lib/initTranslations';
import {
  FocusHandler,
  UpdatePaymentMethodFieldsError,
  ValidateFieldsParams,
  ValidateUpdatePaymentMethodFormProp,
} from './types';

const t = initTranslations('update_payment_method_modal');

const initialState: UpdatePaymentMethodFieldsError = {
  name: false,
  addressLine1: false,
  addressCity: false,
  addressState: false,
  addressZip: false,
  addressCountry: false,
};

const fieldsLabel = {
  addressLine1: t('address.billing_address'),
  name: t('card.cardholder_name'),
  addressCountry: t('address.country'),
  addressCity: t('address.city'),
  addressState: t('address.state'),
  addressZip: t('address.zip_postal_code'),
};

export const useValidateUpdatePaymentMethodForm = (
  dispatch: ValidateUpdatePaymentMethodFormProp
) => {
  const [paymentMethodFormErrors, setPaymentMethodFormErrors] = useState(initialState);

  const updateState = useCallback((newState: Partial<UpdatePaymentMethodFieldsError>) => {
    setPaymentMethodFormErrors((prevState) => {
      return { ...prevState, ...newState };
    });
  }, []);

  const getErrorByFieldName = (
    fieldName: keyof UpdatePaymentMethodFieldsError,
    fieldValue: string | undefined
  ) => {
    return !fieldValue?.trim() && t('required_field', { fieldLabel: fieldsLabel[fieldName] });
  };

  const validateField = useCallback(
    ({ event, name = '', value = '' }: FocusHandler) => {
      const fieldName = (event ? event.target.name : name) as keyof UpdatePaymentMethodFieldsError;
      const fieldValue = event ? event.target.value : value;
      const updatedErrorState = {
        [fieldName]: getErrorByFieldName(fieldName, fieldValue),
      };

      updateState(updatedErrorState);
    },
    [updateState]
  );

  const validateFields = useCallback(
    ({ fieldsToValidate, withStateUpdate = true }: ValidateFieldsParams) => {
      const updatedErrorState = {} as UpdatePaymentMethodFieldsError;

      for (const key in fieldsToValidate) {
        const fieldName = key as UpdatePaymentMethodNameOfRequiredFields;
        const fieldValue = fieldsToValidate[fieldName];

        updatedErrorState[fieldName] = getErrorByFieldName(fieldName, fieldValue);
      }

      withStateUpdate && updateState(updatedErrorState);

      // Return validation result immediately to prevent calling updatePaymentMethod in the UpdatePaymentMethodModal.tsx
      return Object.values(updatedErrorState).every((value) => value === false);
    },
    [updateState]
  );

  const clearFieldError = useCallback(
    (fieldName: UpdatePaymentMethodNameOfRequiredFields) => {
      fieldName && updateState({ [fieldName]: false });
    },
    [updateState]
  );

  const resetErrorState = () => setPaymentMethodFormErrors(initialState);

  useEffect(() => {
    const isUpdatePaymentMethodFormError = Object.values(paymentMethodFormErrors).some(
      (value) => typeof value === 'string'
    );

    dispatch({
      type: 'setFormError',
      payload: isUpdatePaymentMethodFormError,
    });
  }, [dispatch, paymentMethodFormErrors]);

  return {
    formErrors: paymentMethodFormErrors,
    validateField,
    validateFields,
    clearFieldError,
    resetErrorState,
  };
};
