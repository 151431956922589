import React from 'react';

import {
  MultiErrorPasswordErrorIdentifiersType,
  multiErrorPasswordErrorsData,
} from '../../../../../lib/validation_schemas/multiErrorPassword';
import IndicatorItem from './IndicatorItem';
import { ValidationContainer, ValidationPreviewWrapper } from './styles';
import { PasswordValidationPreviewProps } from './types';

const PasswordValidationPreview = ({
  validationErrors,
  isTouched,
  children,
}: PasswordValidationPreviewProps) => {
  return (
    <ValidationPreviewWrapper isError={isTouched && !!validationErrors.length}>
      {children}
      <ValidationContainer id='password-validation-preview'>
        {Object.keys(multiErrorPasswordErrorsData).map(
          (key: keyof MultiErrorPasswordErrorIdentifiersType) => {
            const { identifier, message } = multiErrorPasswordErrorsData[key];

            return (
              <IndicatorItem
                errorIdentifier={identifier}
                errorMessage={message}
                isTouched={isTouched}
                key={identifier}
                validationErrors={validationErrors}
              />
            );
          }
        )}
      </ValidationContainer>
    </ValidationPreviewWrapper>
  );
};

export default PasswordValidationPreview;
