import styled, { css } from 'styled-components';

import Surface from '../../../../design_system/Triage/layout/Surface';
import { TitleRow } from '../../../../styled/Application/Groups';

export const StyledTitleRow = styled(TitleRow)`
  align-items: start;
`;

export const StyledSurface = styled(Surface)`
  padding-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const GroupsStyledSurface = styled(Surface)(
  ({ theme: { constants } }) => css`
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: ${constants.borderRadiusLg};
  `
);

export const StyledProfileSurface = styled(Surface)`
  padding-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResponsibilitiesListWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const AddResponsibilitiesWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;
