import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { openResponsibilityDetailsModal } from '../../../../../redux/domains/responsibilityDetailsModal/responsibilityDetailsModalSlice';
import { Responsibility } from '../../../../../types/Responsibility';

const StyledUl = styled.ul`
  list-style-type: none;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-left: ${({ theme: { constants } }) => constants.spacerMd3};

  li:first-child {
    border-top: none;
  }
`;

const StyledRespLi = styled.li`
  padding-top: ${({ theme: { constants } }) => constants.spacerMd1};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
  margin-left: ${({ theme: { constants } }) => `-${constants.spacerMd2}`};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.foundationHover}`};
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  &:last-child {
    padding-bottom: 0;
  }
`;

const StyledRespName = styled.div`
  ${({ theme: { vars } }) =>
    css`
      cursor: pointer;

      &:hover {
        color: ${vars.accentPrimaryDefault};
        text-decoration: underline;
      }
    `}
`;

const StyledRespWrapper = styled.div`
  margin: 0;
  display: flex;
`;

interface ResponsibilityListProps {
  responsibilities: Responsibility[];
  isGroup: boolean;
}

const ResponsibilityNameList = ({ responsibilities, isGroup }: ResponsibilityListProps) => {
  const dispatch = useDispatch();

  return (
    <StyledUl>
      {responsibilities.map((responsibility) => {
        return (
          <StyledRespLi key={(isGroup ? 'group' : 'user') + `_responsibility_${responsibility.id}`}>
            <StyledRespWrapper>
              <StyledRespName
                className='user-group-responsibility-name'
                onClick={() =>
                  dispatch(
                    openResponsibilityDetailsModal({
                      id: responsibility.id,
                      mode: isGroup ? 'view' : 'edit',
                    })
                  )
                }
              >
                {responsibility.name}
              </StyledRespName>
            </StyledRespWrapper>
          </StyledRespLi>
        );
      })}
    </StyledUl>
  );
};

export default ResponsibilityNameList;
