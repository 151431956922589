import styled, { createGlobalStyle } from 'styled-components';

import { mediaBreakpointPxLg } from '../../../styled/Breakpoint';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

export const SignUpPageContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  top: 0;
  left: 0;
  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: row;
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  min-height: 55vh;

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-basis: 50%;
    min-height: 100vh;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  position: relative;
  flex-basis: 100%;
  background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  min-height: 45vh;
  aspect-ratio: 83 / 52;

  @media (min-width: ${mediaBreakpointPxLg}) {
    aspect-ratio: unset;
    min-height: 100vh;
    flex-basis: 50%;
  }
`;

export const SvgImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const TopSvgImageWrapper = styled.div`
  width: 100%;
  max-width: 60%;
  display: block;
  position: absolute;
  top: 4%;
  left: 40%;
  transform: translateX(-50%);
  z-index: 10;

  @media (min-width: ${mediaBreakpointPxLg}) {
    top: 7%;
    left: 46%;
  }
`;

export const CenterSvgImageWrapper = styled.div`
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (min-width: ${mediaBreakpointPxLg}) {
    top: 55%;
    bottom: unset;
    transform: translateY(-50%);
    max-width: 88%;
  }
`;

export const BottomSvgImageWrapper = styled.div`
  width: 40%;
  display: block;
  position: absolute;
  bottom: 0;
`;
