import { messageFromError } from '../../redux/errors/helpers';
import {
  useCreateAccountMutation,
  useCreateCheckoutTokenBasedAccountMutation,
  useCreateIndustryBasedAccountMutation,
  useCreatePartnerBasedAccountMutation,
} from '../../redux/services/resourceApis/publicApplication/loginApi';
import {
  CreateAccountParams,
  CreateIndustryBasedAccountParams,
  CreatePartnerBasedAccountParams,
} from '../../redux/services/resourceApis/publicApplication/types';

type SignUpPageFormActionArgs = {
  data: CreateAccountParams & CreatePartnerBasedAccountParams & CreateIndustryBasedAccountParams;
};

export function useSignUpPageFormAction({ data }: SignUpPageFormActionArgs) {
  const {
    userEmail,
    userPassword,
    templateGroupId,
    tv,
    partnerSource,
    industry,
    checkoutToken,
    noTrial,
  } = data;
  const [createPartnerBasedAccount, createPartnerBasedAccountResult] =
    useCreatePartnerBasedAccountMutation();
  const [createIndustryBasedAccount, createIndustryBasedAccountResult] =
    useCreateIndustryBasedAccountMutation();
  const [createCheckoutTokenBasedAccount, createCheckoutTokenBasedAccountResult] =
    useCreateCheckoutTokenBasedAccountMutation();
  const [createAccount, createAccountResult] = useCreateAccountMutation();
  const isPartnerActionType = !!partnerSource;
  const isIndustryActionType = !!industry;
  const isCheckoutTokenActionType = !!checkoutToken;
  const paramsBasedResult = () => {
    if (isPartnerActionType) {
      return createPartnerBasedAccountResult;
    } else if (isIndustryActionType) {
      return createIndustryBasedAccountResult;
    } else if (isCheckoutTokenActionType) {
      return createCheckoutTokenBasedAccountResult;
    } else {
      return createAccountResult;
    }
  };
  const result = paramsBasedResult();
  const { error, isLoading: isLoadingCreateAccount, isSuccess: isSuccessCreateAccount } = result;
  const messages = error && messageFromError(error);
  const isFieldErrorMessage = messages && typeof messages[0] === 'string';

  const handleSubmit = () => {
    if (isPartnerActionType) {
      createPartnerBasedAccount({
        userEmail,
        userPassword,
        templateGroupId,
        partnerSource,
      });
    } else if (isIndustryActionType) {
      createIndustryBasedAccount({
        userEmail,
        userPassword,
        templateGroupId,
        tv,
        industry,
      });
    } else if (isCheckoutTokenActionType) {
      createCheckoutTokenBasedAccount({
        userEmail,
        userPassword,
        templateGroupId,
        tv,
        checkoutToken,
      });
    } else {
      createAccount({
        userEmail,
        userPassword,
        templateGroupId,
        tv,
        noTrial,
      });
    }
  };

  return {
    result,
    error,
    messages,
    isFieldErrorMessage,
    isLoadingCreateAccount,
    isSuccessCreateAccount,
    submitHandler: handleSubmit,
  };
}
