import React, { useCallback, useEffect } from 'react';

import { useConsumptionCourseContext } from '../../../../../../contexts/ConsumptionCourseContext';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import createAndDownloadPDF from '../../../../../../lib/createAndDownloadPDF';
import getQueryParams, { deleteQueryParam } from '../../../../../../lib/getQueryParams';
import initTranslations from '../../../../../../lib/initTranslations';
import { eSignatureApi } from '../../../../../../redux/services/resourceApis/eSignatures/eSignatureApi';
import ESignatureLabel from '../../../EditorPage/OutlineContent/ESignatureLabel/ESignatureLabel';
import SignedLabel from '../../../EditorPage/OutlineContent/ESignatureLabel/SignedLabel';
import { Scrollable } from '../../../shared/styles';
import StepOption from './StepOption';

const t = initTranslations('outline_content');

const StepOptions = () => {
  const {
    course: { steps, signedByUser, eSignatureDisplayedAndRequiredForUser, signableDocumentId },
  } = useConsumptionCourseContext();

  const [downloadSignedDocumentParam, documentIdParam] = getQueryParams([
    'download_signed_document',
    'document_id',
  ]);
  const [downloadSignedDocument, result] = eSignatureApi.useLazyDownloadSignedDocumentQuery();
  const { isFetching, data } = result;

  const successFunction = useCallback(() => {
    if (!data) return;

    const { pdf_document, filename } = data;

    if (downloadSignedDocumentParam) {
      deleteQueryParam('download_signed_document');
    }

    if (documentIdParam) {
      deleteQueryParam('document_id');
    }

    createAndDownloadPDF(pdf_document, filename);
  }, [data, documentIdParam, downloadSignedDocumentParam]);

  useDisplayFlashOnResponse({
    result,
    successMessage: t('document_downloaded', { filename: data ? data.filename : t('document') }),
    errorMessage: t('document_failed_to_download'),
    successFunction,
  });

  useEffect(() => {
    if (downloadSignedDocumentParam === 'true' && documentIdParam) {
      downloadSignedDocument({ signableDocumentId: parseInt(documentIdParam) });
    }
  }, [documentIdParam, downloadSignedDocument, downloadSignedDocumentParam]);

  return (
    <Scrollable className='step-wrapper'>
      {steps.map((step) => {
        return <StepOption key={`step-${step.id}`} step={step} />;
      })}
      {eSignatureDisplayedAndRequiredForUser &&
        (signedByUser ? (
          <SignedLabel
            downloadSignedDocument={downloadSignedDocument}
            isFetching={isFetching}
            signableDocumentId={signableDocumentId}
          />
        ) : (
          <ESignatureLabel />
        ))}
    </Scrollable>
  );
};

export default StepOptions;
