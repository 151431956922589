import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import {
  useCheckModalState,
  useDispatchSetShowModal,
} from '../../../../../redux/domains/modalsSlice/modalsSlice';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import DropdownWithPoppableMenu from '../../../shared/DropdownWithPoppableMenu';
import { DelegationPlannerBoard } from '../../Board/types';
import BoardManageModal from '../../modals/BoardManageModal/BoardManageModal';
import StatusPill from '../shared/StatusPill/StatusPill';
import { Title } from '../shared/styles';
import BoardSelectionDropdownMenuOption from './BoardSelectionDropdownMenuOption/BoardSelectionDropdownMenuOption';
import { SelectedOptionTextContainer } from './styles';

const t = initTranslations('delegation_planner.board_selection_dropdown');

const BoardSelectionDropdown = ({
  boards,
  currentBoard,
}: {
  boards: DelegationPlannerBoard[];
  currentBoard?: DelegationPlannerBoard;
}) => {
  const dispatchShowLoadingModal = useDispatchSetShowModal();
  const showBoardManageModal = useCheckModalState('delegationBoardManageModal');
  const { constants } = useTheme();
  const { slug } = useCurrentAccount();

  const options = useMemo(
    () =>
      boards.map((board) => {
        return (
          <BoardSelectionDropdownMenuOption
            board={board}
            isSelected={board.id === currentBoard?.id}
            key={`board-selection-dropdown-option-${board.id}`}
            onSelect={() => routeTo(routes.delegationPlannerBoard({ slug, id: board.id }))}
          />
        );
      }),
    [boards, currentBoard, slug]
  );

  const selectedOption = (
    <SelectedOptionTextContainer textAlign='center'>
      {currentBoard && (
        <>
          <Title isSelected truncated>
            {currentBoard.name}
          </Title>

          <StatusPill isDraft={currentBoard.status === 'draft'} />
        </>
      )}
    </SelectedOptionTextContainer>
  );

  const addNewBoardButton = (
    <DefaultButton
      id='delegation-planner-add-new-board-button'
      onClick={() => dispatchShowLoadingModal('delegationBoardManageModal', true)}
      text={t('add_board')}
    />
  );

  return (
    <>
      <DropdownWithPoppableMenu
        id='delegation-board-selection-dropdown'
        menuFooterElement={addNewBoardButton}
        menuId='delegation-board-selection-dropdown-menu'
        menuPlacement='bottom-start'
        menuStyling={{ top: constants.spacerSm2 }}
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={() => {
          /* do nothing */
        }}
        style='delegationBoard'
        useMenuWidth
      />
      {showBoardManageModal && (
        <BoardManageModal
          mode='add'
          setShowModal={(value) => dispatchShowLoadingModal('delegationBoardManageModal', value)}
          showModal={showBoardManageModal}
        />
      )}
    </>
  );
};

export default BoardSelectionDropdown;
