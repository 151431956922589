import React, { KeyboardEvent } from 'react';

import useActiveMenuHandler from '../../../application/publicApplication/utils/useActiveMenuHandler';
import OutsideClickHandler from '../../../application/shared/OutsideClickHandler';
import InputField from '../../../design_system/Triage/InputField';
import Poppable from '../../../Poppable';
import Tooltip from '../../display/Tooltip/Tooltip';
import ColorPickerTrigger from './ColorPickerTrigger';
import CustomColorPicker from './CustomColorPicker';
import { ColorPickerContainer, ColorPickerIconWrapper, InputFieldWrapper } from './styles';
import { ColorPickerProps } from './types';

const ColorPicker = ({
  ariaLabel,
  defaultColors,
  color,
  className = 'color-picker',
  setColor,
  showTextField = true,
  onChange,
  menuId,
  errorText,
  name,
  type = 'text-color',
  buttonId,
  clearButtonClick,
  clearButtonVisible = false,
  clearOnSelection = false,
  clearButtonText,
  strategy,
  tooltipId,
  tooltipPlace,
  tooltipText,
  delayTooltipShow = 0,
}: ColorPickerProps) => {
  const { closeMenu, handleMenuClick, isMenuOpen } = useActiveMenuHandler({
    menuId,
  });

  const showTooltip = tooltipId && tooltipText && !isMenuOpen;

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  return (
    <ColorPickerContainer className={className} id={menuId}>
      {type === 'background-color' && showTextField && (
        <InputFieldWrapper>
          <InputField
            errorText={errorText}
            name={name}
            onChange={onChange}
            onKeyPress={handleEnterKeyPress}
            value={color}
          />
        </InputFieldWrapper>
      )}
      <ColorPickerIconWrapper>
        <Poppable
          isOpen={isMenuOpen}
          item={
            <OutsideClickHandler onOutsideClick={closeMenu}>
              <CustomColorPicker
                clearButtonClick={() => {
                  clearButtonClick && clearButtonClick();
                  closeMenu();
                }}
                clearButtonText={clearButtonText}
                clearButtonVisible={clearButtonVisible}
                color={color}
                colors={defaultColors}
                hideColorPicker={closeMenu}
                onChange={(color: { hex: string }) => {
                  setColor(color.hex);
                  clearOnSelection && closeMenu();
                }}
              />
            </OutsideClickHandler>
          }
          onClick={() => handleMenuClick()}
          placement='bottom-start'
          strategy={strategy}
          trigger={
            <>
              {showTooltip && (
                <Tooltip
                  delayShow={delayTooltipShow}
                  id={tooltipId}
                  place={tooltipPlace}
                  text={tooltipText}
                />
              )}
              <div data-for={tooltipId} data-tip>
                <ColorPickerTrigger
                  ariaLabel={ariaLabel}
                  buttonId={buttonId}
                  color={color}
                  type={type}
                />
              </div>
            </>
          }
        />
      </ColorPickerIconWrapper>
    </ColorPickerContainer>
  );
};

export default ColorPicker;
