import { RestrictGroupsAction, RestrictGroupsState } from './types';

export const GroupsReducer = (
  state: RestrictGroupsState,
  action: RestrictGroupsAction
): RestrictGroupsState => {
  switch (action.type) {
    case 'changeSelectedGroups': {
      return { ...state, selectedGroups: action.selectedGroups };
    }
  }
};
