import { Form, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import useFieldErrorsFromFormik from '../../../../hooks/useFieldErrorsFromFormik';
import initTranslations from '../../../../lib/initTranslations';
import { useChangeUserSettingsMutation } from '../../../../redux/services/resourceApis/users/usersApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { SettingsFormProps, SettingsFormValues } from './InterfaceAndTypes';
import AdvancedSettings from './Sections/AdvancedSettings';
import BasicSettings from './Sections/BasicSettings';
import EmailSettings from './Sections/EmailSettings';
import PasswordSettings from './Sections/PasswordSettings';
import { ButtonWrapper, FormWrapper } from './Styles';

const t = initTranslations('settings');

const SettingsForm = ({ openModal }: SettingsFormProps) => {
  const {
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    setStatus,
    setFieldError,
    isValid,
    dirty,
  } = useFormikContext<SettingsFormValues>();
  const [updateSettings, result] = useChangeUserSettingsMutation();
  const { isSuccess, error } = result;
  const { flash } = useFlashNotification();

  useEffect(() => {
    if (isSubmitting && isValid) {
      updateSettings(values);
    }
  }, [isSubmitting, isValid, updateSettings, values]);

  useEffect(() => {
    if (isSuccess) {
      setSubmitting(false);
      flash('info', t('saving.success_flash'));
    }
  }, [flash, isSuccess, setSubmitting]);

  useFieldErrorsFromFormik({
    error,
    setFieldError,
    setStatus,
    setSubmitting,
  });

  return (
    <Form autoComplete='off'>
      <FormWrapper id='my-settings-form-wrapper'>
        <BasicSettings />
        <PasswordSettings openModal={openModal} />
        <AdvancedSettings />
        <EmailSettings />

        <ButtonWrapper>
          <DefaultButton
            buttonType='primary'
            disabled={isSubmitting || !dirty}
            id='save-my-settings-button'
            onClick={() => handleSubmit()}
            text={t('saving.save_button')}
          />
        </ButtonWrapper>
      </FormWrapper>
    </Form>
  );
};

export default SettingsForm;
