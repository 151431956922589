import React, { useState } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { CurriculumOwner } from '../../../../../types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Avatar from '../../../../design_system/display/avatar';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import CurriculumModal from '../../../curriculums/modals/CurriculumModal/CurriculumModal';

const OwnerAvatarWrapper = styled.div<{ canEditOwner: boolean }>`
  margin: 0 ${({ theme: { constants } }) => constants.spacerMd2};

  ${({ canEditOwner }) => canEditOwner && `cursor: pointer;`}

  @media (max-width: ${mediaBreakpointPxMd}) {
    display: none;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
`;

export type Props = {
  canEditOwner: boolean;
  curriculumId: number;
  owner: CurriculumOwner | null;
};

const t = initTranslations('curriculums.content.library_row');

const OwnerAvatar = ({ canEditOwner, curriculumId, owner }: Props) => {
  const [showCurriculumModal, setShowCurriculumModal] = useState(false);
  const {
    curriculum: { singular: curriculumTermSingular },
  } = useAccountTerminology();

  return (
    <>
      <OwnerAvatarWrapper
        canEditOwner={canEditOwner}
        id='owner-avatar'
        onClick={() => {
          canEditOwner && setShowCurriculumModal(true);
        }}
      >
        <Tooltip
          id={`owner-tooltip-${curriculumId}`}
          place='top'
          text={
            owner ? owner.name : t('no_owner', { subject: curriculumTermSingular.toLowerCase() })
          }
        />
        <AvatarWrapper data-for={`owner-tooltip-${curriculumId}`} data-tip>
          <Avatar
            icon={owner ? undefined : 'user'}
            image={owner?.avatar}
            name={owner?.name || ''}
            shape='circle'
            size='xs'
          />
        </AvatarWrapper>
      </OwnerAvatarWrapper>
      {canEditOwner && showCurriculumModal && (
        <CurriculumModal
          closeRequest={() => setShowCurriculumModal(false)}
          curriculumId={curriculumId}
        />
      )}
    </>
  );
};

export default OwnerAvatar;
