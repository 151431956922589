import React from 'react';

import { useAppSelector } from '../../../../../redux/hooks';
import { GroupSelectableElementsContainer as UsersSelectableElementsContainer } from '../SelectGroupModal/styles';
import { TUsersSelectListProps } from '../types';
import UserSelectItem from './UserSelectItem';

const UsersSelectList = ({ users, initiallySelectedUserIds }: TUsersSelectListProps) => {
  const { selectedUserIds } = useAppSelector((state) => state.delegationPlanner);

  return (
    <UsersSelectableElementsContainer>
      {users.map((user) => {
        const { id } = user;
        const isInitiallySelected = initiallySelectedUserIds.includes(user.id);
        const isCurrentlySelected = selectedUserIds.includes(user.id);

        return (
          <UserSelectItem
            isCurrentlySelected={isCurrentlySelected}
            isInitiallySelected={isInitiallySelected}
            key={`user-option-${id}`}
            user={user}
          />
        );
      })}
    </UsersSelectableElementsContainer>
  );
};

export default UsersSelectList;
