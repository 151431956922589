import React from 'react';
import { Route } from 'type-route';

import { usePdfExportQuery } from '../../../../redux/services/resourceApis/publicApplication/loginApi';
import { routes } from '../../publicApplication/applicationRouter';
import PDFPageContent from './PDFPageContent';

type Props = {
  route: Route<typeof routes.internalAuthIndex>;
};

const PDFExportPage = ({
  route: {
    params: { signature_export = false },
  },
}: Props) => {
  const { isLoading, data } = usePdfExportQuery();

  return (
    <PDFPageContent curriculum={data} isLoading={isLoading} signatureExport={signature_export} />
  );
};

export default PDFExportPage;
