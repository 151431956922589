import React from 'react';

import { HeaderRow } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { SortProps } from '../../TableTypes';
import { Column, columns } from './CourseReportColumn';
import CourseReportTableHeaderCell from './CourseReportTableHeaderCell';
import { Action, TableHeaderState } from './types';

type Props = {
  tableHeaderState: TableHeaderState;
  dispatch: React.Dispatch<Action>;
};

const CourseReportTableHeader = ({ tableHeaderState, dispatch }: Props) => {
  return (
    <thead>
      <HeaderRow>
        {columns
          .filter(({ display }) => display !== 'hidden')
          .map((column: Column) => {
            const activeColumn = tableHeaderState.sortCol === column.columnName;
            let sortProps: SortProps = { isSortable: column.sortable };

            if (column.sortable) {
              const sortIcon = activeColumn ? tableHeaderState.sortIcon : 'sort';
              sortProps = { isSortable: true, sortIcon };
            }

            return (
              <CourseReportTableHeaderCell
                activeHeader={activeColumn}
                column={column}
                key={`header-${column.columnName}`}
                setActiveHeader={() => {
                  if (column.sortable) {
                    dispatch({ type: 'sortColumnChange', sortColumn: column.columnName });
                  }
                }}
                {...sortProps}
              />
            );
          })}
      </HeaderRow>
    </thead>
  );
};

export default CourseReportTableHeader;
