import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { useToggleFavoriteCurriculumMutation } from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import Icon from '../../../design_system/display/icons/Icon';
import { routes } from '../../publicApplication/applicationRouter';
import {
  FavoriteCurriculum,
  FavoriteCurriculumEmoji,
  FavoriteCurriculumIcon,
  FavoriteCurriculumTitle,
  FavoriteCurriculumWrapper,
} from './styles';
import { FavoriteAssignmentProps } from './types';

const FavoriteCurriculumItem = ({ curriculumId, id, title, emoji }: FavoriteAssignmentProps) => {
  const { slug } = useCurrentAccount();
  const curriculumPath = routes.curriculumShow({ slug, id: curriculumId }).href;
  const [toggleFavoriteAssignment] = useToggleFavoriteCurriculumMutation();

  return (
    <FavoriteCurriculum id={`favorite-curriculum-${id}`}>
      <FavoriteCurriculumEmoji href={curriculumPath}>
        {emoji ? (
          emoji
        ) : (
          <Icon className='default-emoji' name='file-alt' size='xs' weight='regular' />
        )}
      </FavoriteCurriculumEmoji>
      <FavoriteCurriculumTitle href={curriculumPath} id={`favorite-curriculum-title-${id}`}>
        {title}
      </FavoriteCurriculumTitle>
      <FavoriteCurriculumWrapper
        className='unfavorite-curriculum-button'
        id={`unfavorite-curriculum-button-${id}`}
        onClick={() => toggleFavoriteAssignment(curriculumId)}
      >
        <FavoriteCurriculumIcon name='star' weight='solid' />
      </FavoriteCurriculumWrapper>
    </FavoriteCurriculum>
  );
};

export default FavoriteCurriculumItem;
