import styled from 'styled-components';

import IconButton from '../../../../../design_system/buttons/IconButton';
import P from '../../../../../design_system/text/P';
import { mediaBreakpointPxMd } from '../../../../../styled/Breakpoint';
import { fontMd1, fontSm5 } from '../../../../../styled/TypeSystem';

export const TaskDrivenOnboardingCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthLg} solid ${vars.accentStrong2}`};
  box-shadow: ${({ theme: { vars } }) => vars.shadowCenterMedium};

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 0.5rem;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &:before {
    bottom: -12px;
    width: ${({ theme: { constants } }) => `calc(100% - ${constants.spacerMd2} * 2)`};
    left: ${({ theme: { constants } }) => constants.spacerMd2};
    background-color: ${({ theme: { vars } }) => vars.accentSubdued3};
    border: ${({ theme: { vars, constants } }) =>
      `${constants.borderWidthLg} solid ${vars.accentStrong2}`};
  }

  &:after {
    bottom: -18px;
    width: ${({ theme: { constants } }) => `calc(100% - ${constants.spacerLg1} * 2)`};
    left: ${({ theme: { constants } }) => constants.spacerLg1};
    background-color: ${({ theme: { vars } }) => vars.accentStrong2};
  }
`;

export const TaskDrivenOnboardingCloseIconButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme: { constants } }) => constants.spacerSm3};
  right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textSubdued};

  svg {
    pointer-events: none;
  }
`;

export const TaskDrivenOnboardingCardTitle = styled.h2`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
  color: ${({ theme: { vars } }) => vars.accentStrong2};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd1};
`;

export const TaskDrivenOnboardingCardDescription = styled(P)`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const TaskDrivenOnboardingActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  @media (max-width: ${mediaBreakpointPxMd}) {
    flex-direction: column;
  }
`;

export const TaskDrivenOnboardingActionButton = styled.button`
  display: flex;
  flex-grow: 1;
  flex-basis: 50%;
  align-items: center;
  max-width: 50%;
  min-height: 3.5rem;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border: none;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  outline: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  text-align: left;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }

  &:hover,
  &:active {
    cursor: pointer;

    > img {
      transform: rotate(-5deg);
    }
  }

  &:hover {
    color: ${({ theme: { vars } }) => vars.accentPrimaryHover};
    background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
    outline: ${({ theme: { vars, constants } }) =>
      `${constants.borderWidthLg} solid ${vars.accentPrimaryHover}`};
  }

  &:active {
    color: ${({ theme: { vars } }) => vars.accentPrimaryPressed};
    background-color: ${({ theme: { vars } }) => vars.accentSubdued2};
    outline: ${({ theme: { vars, constants } }) =>
      `${constants.borderWidthLg} solid ${vars.accentPrimaryPressed}`};
  }

  @media (max-width: ${mediaBreakpointPxMd}) {
    margin-right: 0;
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
    max-width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${fontSm5};
`;

export const TaskDrivenOnboardingActionIcon = styled.img`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;
