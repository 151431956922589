import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { parseTagName } from '../../../../../../lib/parseTagName';
import { routes } from '../../../../publicApplication/applicationRouter';
import { TemplateTag, TemplateTagsWrapper } from './styles';

const t = initTranslations('template_preview.info');

type TemplateTagsProps = {
  tagsList: string[];
};

const TemplateTags = ({ tagsList }: TemplateTagsProps) => {
  const { slug } = useCurrentAccount();

  return (
    <TemplateTagsWrapper>
      {t('tags')}
      {tagsList.map((tag: string) => (
        <TemplateTag key={tag} to={routes.templatesSearch({ slug, tags: [parseTagName(tag)] })}>
          {tag}
        </TemplateTag>
      ))}
    </TemplateTagsWrapper>
  );
};

export default TemplateTags;
