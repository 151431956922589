import { FormikErrors, useFormik } from 'formik';
import { isEqual } from 'lodash';
import React, { useCallback } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import InputFieldWithCharacterCounter from '../../../../design_system/Triage/InputFieldWithCharacterCounter';
import { ButtonRightPositionWrapper } from '../../OrganizationProfile/styles';
import { CUSTOM_TERMINOLOGY_MAX_CHARACTERS } from '../../shared/constants/accountSettings';
import { TerminologyInputRow, TerminologySettingWrapper } from '../styles';
import TerminologyPluralsSettingToggle from './TerminologyPluralsSettingToggle';
import { CustomTerminologiesFormValues, CustomTerminologyFormProps } from './types';

const t = initTranslations('account_settings.advanced_settings.custom_terminology_form');

const CustomTerminologyForm = ({
  customTerminologies,
  customTerminologySettings,
}: CustomTerminologyFormProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();
  const { isLoading } = result;
  const {
    company_term: companyTerm,
    policy_term: policyTerm,
    process_term: processTerm,
    step_term: stepTerm,
    subject_term: subjectTerm,
    topic_term: topicTerm,
    responsibility_term: responsibilityTerm,
  } = customTerminologies;
  const {
    pluralize_company_term: pluralizeCompanyTerm,
    pluralize_process_term: pluralizeProcessTerm,
    pluralize_responsibility_term: pluralizeResponsibilityTerm,
    pluralize_step_term: pluralizeStepTerm,
    pluralize_policy_term: pluralizePolicyTerm,
    pluralize_subject_term: pluralizeSubjectTerm,
    pluralize_topic_term: pluralizeTopicTerm,
  } = customTerminologySettings;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  const formik = useFormik({
    initialValues: {
      company_term: companyTerm,
      policy_term: policyTerm,
      process_term: processTerm,
      step_term: stepTerm,
      subject_term: subjectTerm,
      topic_term: topicTerm,
      responsibility_term: responsibilityTerm,
    },
    validate: (values: CustomTerminologiesFormValues) => validateForm(values),
    onSubmit: (values: CustomTerminologiesFormValues) => submitForm(values),
  });

  const validateForm = useCallback((values: CustomTerminologiesFormValues) => {
    const {
      company_term,
      process_term,
      step_term,
      subject_term,
      topic_term,
      policy_term,
      responsibility_term,
    } = values;
    const errors: FormikErrors<CustomTerminologiesFormValues> = {};
    if (company_term && company_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.company_term = t('errors.max_characters_exceeded');
    }
    if (process_term && process_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.process_term = t('errors.max_characters_exceeded');
    }
    if (step_term && step_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.step_term = t('errors.max_characters_exceeded');
    }
    if (subject_term && subject_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.subject_term = t('errors.max_characters_exceeded');
    }
    if (topic_term && topic_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.topic_term = t('errors.max_characters_exceeded');
    }
    if (policy_term && policy_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.policy_term = t('errors.max_characters_exceeded');
    }
    if (responsibility_term && responsibility_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.responsibility_term = t('errors.max_characters_exceeded');
    }
    return errors;
  }, []);

  const submitForm = useCallback(
    (formValues) => {
      updateAccountData(formValues);
    },
    [updateAccountData]
  );

  const { values, errors, handleChange, initialValues } = formik;

  const isValuesUnchanged = isEqual(values, initialValues);

  return (
    <>
      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.subject_term}
            id='subject-term'
            inputFor='subject_term'
            label={t('curriculum.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='subject_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.subject_term}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeSubjectTerm}
          className='subject-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_subject_term: e.target.checked })}
          id='enable-subject-term-pluralize'
          name='pluralize_subject_term'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.topic_term}
            id='topic-term'
            inputFor='topic_term'
            label={t('course.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='topic_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.topic_term}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeTopicTerm}
          className='topic-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_topic_term: e.target.checked })}
          id='enable-topic-term-pluralize'
          name='pluralize_topic_term'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.step_term}
            id='step-term'
            inputFor='step_term'
            label={t('step.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='step_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.step_term}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeStepTerm}
          className='step-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_step_term: e.target.checked })}
          id='enable-step-term-pluralize'
          name='pluralize_step_term'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.company_term}
            id='company-term'
            inputFor='company_term'
            label={t('company.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='company_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.company_term}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeCompanyTerm}
          className='company-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_company_term: e.target.checked })}
          id='enable-company-term-pluralize'
          name='pluralize_company_term'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.policy_term}
            id='policy-term'
            inputFor='policy_term'
            label={t('policy.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='policy_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.policy_term}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizePolicyTerm}
          className='policy-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_policy_term: e.target.checked })}
          id='enable-policy-term-pluralize'
          name='pluralize_policy_term'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.process_term}
            id='process-term'
            inputFor='process_term'
            label={t('process.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='process_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.process_term}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeProcessTerm}
          className='process-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_process_term: e.target.checked })}
          id='enable-process-term-pluralize'
          name='pluralize_process_term'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.responsibility_term}
            id='responsibility-term'
            inputFor='responsibility_term'
            label={t('responsibility.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='responsibility_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.responsibility_term}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeResponsibilityTerm}
          className='responsibility-term-pluralize'
          handleToggle={(e) =>
            updateAccountData({ pluralize_responsibility_term: e.target.checked })
          }
          id='enable-responsibility-term-pluralize'
          name='pluralize_responsibility_term'
        />
      </TerminologySettingWrapper>

      <ButtonRightPositionWrapper>
        <DefaultButton
          buttonType='primary'
          disabled={isValuesUnchanged}
          id='save-custom-terminology'
          loading={isLoading}
          onClick={() => formik.handleSubmit()}
          text={t('save_changes')}
        />
      </ButtonRightPositionWrapper>
    </>
  );
};

export default CustomTerminologyForm;
