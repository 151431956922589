import React from 'react';

import { useAddonFullScreenOverlay } from '../../../../../../hooks/billing/useAddonFullScreenOverlay';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAddonTrainualPlusDataQuery } from '../../../../../../redux/services/resourceApis/billing/billingApi';
import { AddonTrainualPlusDataResponse } from '../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { FullScreenOverlayStorybookProps } from '../../../../../../types/FullScreenOverlay';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import FullScreenOverlay from '../../../../shared/FullScreenOverlay/FullScreenOverlay';
import { OverlayContentWrapper } from '../../../../shared/FullScreenOverlay/styles';
import CardDetailsSection from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSection';
import CardDetailsSkeleton from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSectionSkeleton/CardDetailsSectionSkeleton';
import NextPayment from '../../../shared/BillingFullScreenOverlay/NextPayment/NextPayment';
import NextPaymentSkeleton from '../../../shared/BillingFullScreenOverlay/NextPayment/NextPaymentSkeleton/NextPaymentSkeleton';
import {
  ButtonsGroup,
  PaymentSummaryFooterWrapper,
} from '../../../shared/BillingFullScreenOverlay/styles';
import SubmitButton from '../../../shared/BillingFullScreenOverlay/SubmitButton/SubmitButton';
import SummarySkeleton from '../../../shared/BillingFullScreenOverlay/Summary/SummarySkeleton/SummarySkeleton';
import Summary from '../../AddonFullScreenOverlays/AddonFullScreenOverlay/Summary/Summary';
import { StyledMainSectionWrapper, StyledPaymentSummaryWrapper } from '../shared/styles';
import AddonOverlayContentSkeleton from './AddonOverlayContentSkeleton/AddonOverlayContentSkeleton';
import FeatureSection from './FeatureSection/FeatureSection';
import { FeaturesWrapper, StyledMainSectionContent } from './styles';
import TrainualPlusFooter from './TrainualPlusFooter/TrainualPlusFooter';
import TrainualPlusHeader from './TrainualPlusHeader/TrainualPlusHeader';

export type AddonTrainualPlusFullScreenOverlayProps = {
  currentDate?: Date;
} & FullScreenOverlayStorybookProps<AddonTrainualPlusDataResponse>;

const t = initTranslations('simple_pricing.addon_overlay_content');

const AddonTrainualPlusFullScreenOverlay = ({
  isStorybookEnvironment,
  mockData,
  currentDate = new Date(),
}: AddonTrainualPlusFullScreenOverlayProps) => {
  const queryResult = useAddonTrainualPlusDataQuery(undefined, { skip: isStorybookEnvironment });
  const {
    isOpenedModals,
    setIsOpenedLightboxVideoPlayer,
    hasCardDetails,
    isLoadingSubmitButton,
    isDisabledSubmitButton,
    handleSubmit,
    closeFullScreenOverlay,
    data,
    isFetching,
  } = useAddonFullScreenOverlay(queryResult, isStorybookEnvironment, mockData);
  const addonData = data as AddonTrainualPlusDataResponse;
  const isAvailableOverlayData = !isFetching && !!addonData;

  return (
    <FullScreenOverlay
      fixedCloseIcon
      id='addon-trainual-plus-full-screen-overlay'
      withOpenedModal={isOpenedModals}
    >
      <OverlayContentWrapper>
        <StyledMainSectionWrapper>
          <StyledMainSectionContent>
            {isAvailableOverlayData ? (
              <>
                <TrainualPlusHeader
                  description={addonData.info.header.description}
                  primaryColor={addonData.info.primaryColor}
                  secondaryColor={addonData.info.secondaryColor}
                  title={addonData.info.header.title}
                />
                <FeaturesWrapper>
                  {addonData.info.features.map((feature) => (
                    <FeatureSection
                      key={feature.title}
                      {...feature}
                      setIsOpenedLightboxVideoPlayer={setIsOpenedLightboxVideoPlayer}
                    />
                  ))}
                </FeaturesWrapper>
                <TrainualPlusFooter
                  description={addonData.info.footer.description}
                  primaryColor={addonData.info.primaryColor}
                  secondaryColor={addonData.info.secondaryColor}
                  title={addonData.info.footer.title}
                />
              </>
            ) : (
              <AddonOverlayContentSkeleton />
            )}
          </StyledMainSectionContent>
        </StyledMainSectionWrapper>
        <StyledPaymentSummaryWrapper>
          {isAvailableOverlayData ? (
            <Summary
              addonItems={addonData.addonItems}
              currentDate={currentDate}
              newPlanData={addonData.newPlan}
              primaryColor={addonData.info.primaryColor}
            />
          ) : (
            <SummarySkeleton />
          )}
          <PaymentSummaryFooterWrapper>
            {isAvailableOverlayData ? (
              <>
                <CardDetailsSection
                  cardDetails={addonData.billingDetails.cardDetails}
                  isMaxSeatsCount={false}
                />
                <NextPayment newPlanData={addonData.newPlan} withDetails={false} />
              </>
            ) : (
              <>
                <CardDetailsSkeleton />
                <NextPaymentSkeleton withDetails={false} />
              </>
            )}
            <ButtonsGroup>
              <DefaultButton
                buttonType='secondary'
                id='addon-trainual-plus-full-screen-overlay-cancel-button'
                onClick={closeFullScreenOverlay}
                text={t('cta.cancel')}
              />
              <SubmitButton
                handleSubmit={handleSubmit}
                hasCardDetails={hasCardDetails}
                id='addon-trainual-plus-full-screen-overlay-primary-button'
                isDisabled={isDisabledSubmitButton}
                isLoading={isLoadingSubmitButton}
                text={t('cta.buy_now')}
              />
            </ButtonsGroup>
          </PaymentSummaryFooterWrapper>
        </StyledPaymentSummaryWrapper>
      </OverlayContentWrapper>
    </FullScreenOverlay>
  );
};

export default AddonTrainualPlusFullScreenOverlay;
