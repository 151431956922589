import React from 'react';

import {
  useBulkUpdatePermissionsMutation,
  useFetchRestrictedBulkUpdatePermissionsQuery,
} from '../../../../../../../redux/services/resourceApis/users/usersApi';
import { BulkUpdatePermissionsModalProps } from '../types';
import BulkUserUpdateModal from './BulkUserUpdateModal';

const BulkUpdatePermissions = ({
  onClose,
  selectedUsersIds,
  onSuccess,
  type,
}: BulkUpdatePermissionsModalProps) => {
  const { data, isLoading, isSuccess, isError, error } =
    useFetchRestrictedBulkUpdatePermissionsQuery({
      userIds: selectedUsersIds,
    });
  const fetchRestrictedResult = { isLoading, isSuccess, isError };
  const [bulkUpdatePermissions, result] = useBulkUpdatePermissionsMutation();

  return (
    <BulkUserUpdateModal
      bulkUpdate={bulkUpdatePermissions}
      bulkUpdateResult={result}
      data={data}
      fetchRestrictedError={error}
      fetchRestrictedResult={fetchRestrictedResult}
      onClose={onClose}
      onSuccess={onSuccess}
      selectedUsersIds={selectedUsersIds}
      type={type}
    />
  );
};

export default BulkUpdatePermissions;
