import React from 'react';

import getLetterByPosition from '../../../../../../../lib/surveys/getLetterByPosition';
import { TemplatePreviewOptionElement } from '../../../../../../../redux/services/resourceApis/templates/types';
import { SurveyQuestionAnswerType } from '../../../../../../../types/SurveyQuestion';
import { SurveyChoiceLetter } from '../../../../../surveys/shared/styles';
import SurveyOptionIcon from '../../../../../surveys/shared/SurveyOptionIcon/SurveyOptionIcon';
import {
  TemplatePreviewOptionText,
  TemplatePreviewOptionTextWrapper,
  TemplatePreviewOptionWrapper,
} from '../styles';

type TemplatePreviewOptionProps = {
  answerType: SurveyQuestionAnswerType;
  options: Array<TemplatePreviewOptionElement>;
};

const TemplatePreviewOptions = ({ options, answerType }: TemplatePreviewOptionProps) => {
  const iconType = answerType === 'multiple_choice' ? 'checkbox' : 'radio_button';

  return (
    <>
      {options.map(({ id, position, text }) => {
        return (
          <TemplatePreviewOptionWrapper id={`template-preview-option-${id}`} key={id}>
            <SurveyOptionIcon correct={false} iconType={iconType} />
            <TemplatePreviewOptionTextWrapper>
              <SurveyChoiceLetter isCorrect={false} isSelected={false}>
                {getLetterByPosition(position)}
              </SurveyChoiceLetter>
              <TemplatePreviewOptionText text={text} />
            </TemplatePreviewOptionTextWrapper>
          </TemplatePreviewOptionWrapper>
        );
      })}
    </>
  );
};

export default TemplatePreviewOptions;
