import styled from 'styled-components';

import Icon from '../../../../../../../design_system/display/icons/Icon';

export const ChoiceElementWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ChoiceInputWrapper = styled.div<{ isCorrect: boolean; isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  min-height: ${({ theme: { constants } }) => constants.heightLg};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid`};
  border-radius: ${(props) => props.theme.constants.borderRadiusMd};
  border-color: ${({ isCorrect, isSelected, theme: { vars } }) =>
    !isSelected ? vars.borderSurface2 : isCorrect ? vars.stateHoverSuccess : vars.stateHoverError};
  background-color: ${({ isSelected, isCorrect, theme: { vars } }) =>
    !isSelected
      ? vars.foundationBase1
      : isCorrect
      ? vars.stateBackgroundSuccess
      : vars.stateBackgroundError};
`;

export const ChoiceIcon = styled(Icon)<{ isCorrect: boolean; isSelected: boolean }>`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ isCorrect, isSelected, theme: { vars } }) =>
    !isSelected ? vars.textDefault : isCorrect ? vars.stateHoverSuccess : vars.stateHoverError};
`;

export const ChoiceTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
