import dayjs from 'dayjs';
import * as htmlToImage from 'html-to-image';
import { Options } from 'html-to-image/lib/options';
import jspdf from 'jspdf';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import useCurrentAccount from './useCurrentAccount';

export type DownloadOrgChartType = 'pdf' | 'png';

type DownloadOrgChartResult = {
  isLoadingOrgChart: boolean;
  downloadOrgChart: () => void;
  setDownloadOrgChartType: React.Dispatch<React.SetStateAction<DownloadOrgChartType>>;
};

export default function useDownloadOrgChart(
  orgChartContainerRef: React.RefObject<HTMLDivElement>
): DownloadOrgChartResult {
  const theme = useTheme();
  const { name } = useCurrentAccount();
  const [downloadOrgChartType, setDownloadOrgChartType] = useState<DownloadOrgChartType>();
  const [isLoadingOrgChart, setIsLoadingOrgChart] = useState(false);
  const backgroundColor = theme.vars.foundationBase1;
  const imgOptions: Options = useMemo(
    () => ({
      quality: 1,
      backgroundColor,
      pixelRatio: 3,
    }),
    [backgroundColor]
  );
  const formattedCurrentDate = dayjs(new Date()).format('YYYY-MM-DD');
  const formattedAccountName = name.split(' ').join('-');
  const orgChartFileName = `${formattedAccountName}-Org-Chart-${formattedCurrentDate}`;

  const downloadPdf = useCallback(() => {
    htmlToImage
      .toCanvas(orgChartContainerRef.current as HTMLElement, imgOptions)
      .then(function (canvas) {
        const canvasData = canvas.toDataURL('image/webp', 1);
        const pdf = new jspdf({
          orientation: 'landscape',
          unit: 'mm',
          format: 'a4',
          compress: false,
        });

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const widthRatio = pdf.internal.pageSize.getWidth() / canvas.width;
        const heightRatio = pdf.internal.pageSize.getHeight() / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        const marginX = (pageWidth - canvasWidth) / 2;
        const marginY = (pageHeight - canvasHeight) / 2;

        // Set background for PDF using rectangle
        pdf.setDrawColor(0);
        pdf.setFillColor(backgroundColor);
        pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // 'F' means fill the rectangle without a border

        pdf.addImage(
          canvasData,
          'WEBP',
          marginX,
          marginY,
          canvas.width * ratio,
          canvas.height * ratio,
          undefined,
          'NONE'
        );
        pdf.save(`${orgChartFileName}.pdf`);

        setDownloadOrgChartType(undefined);
      });
  }, [backgroundColor, imgOptions, orgChartContainerRef, orgChartFileName]);

  const downloadPng = useCallback(() => {
    htmlToImage
      .toPng(orgChartContainerRef.current as HTMLElement, imgOptions)
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = `${orgChartFileName}.png`;
        link.href = dataUrl;
        link.click();

        setDownloadOrgChartType(undefined);
      });
  }, [imgOptions, orgChartContainerRef, orgChartFileName]);

  const downloadOrgChart = () => {
    if (!orgChartContainerRef.current) return;

    if (downloadOrgChartType) {
      downloadOrgChartType === 'pdf' ? downloadPdf() : downloadPng();
    }
  };

  useEffect(() => {
    // We should immediately set isLoadingOrgChart to true when downloadOrgChartType is set (which means the button was clicked)
    // Also, we can't do it in downloadOrgChart function because this function will trigger with a delay, when the org chart is fitted to the screen
    setIsLoadingOrgChart(Boolean(downloadOrgChartType));
  }, [downloadOrgChartType]);

  return {
    isLoadingOrgChart,
    downloadOrgChart,
    setDownloadOrgChartType,
  };
}
