import { TransitionGroup as BaseTransitionGroup } from 'react-transition-group';
import styled, { css, keyframes } from 'styled-components';

import DefaultButton from '../../../design_system/buttons/DefaultButton';
import InputField from '../../../design_system/Triage/InputField';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { fontSm4 } from '../../../styled/TypeSystem';

export const ContentDropdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const DropdownWrapper = styled.div`
  width: 8.5rem;
`;

export const TransitionGroup = styled(BaseTransitionGroup)`
  .survey-enter {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 750ms, transform 750ms;
  }

  .survey-enter-active {
    opacity: 1;
    transform: translateX(0);
  }

  .survey-exit {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 750ms, transform 750ms;
  }

  .survey-exit-active {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

export const SurveyQuestionListItemWrapper = styled.div`
  max-width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme: { constants } }) => constants.spacerSm2} 0;
`;

export const IndexText = styled.strong`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme: { constants } }) => constants.spacerLg3};
`;

export const SlideoutWrapper = styled.div<{ isOpen: boolean }>`
  flex: ${({ isOpen }) => (isOpen ? '0 0 20rem' : '0 0 0')};
  /* Transition timing is set to match the transition timing of the Slideout component */
  transition: flex 0.5s ease;
`;

export const StyledButton = styled(DefaultButton)<{ width?: string }>`
  display: flex;
  flex-direction: column;
  color: ${({ theme: { vars } }) => vars.textDefault};
  border-color: ${({ theme: { vars } }) => vars.borderSurface2};
  border-right: none;
  min-width: 4rem;
  width: 4rem;
  height: 3.5rem;
  border-radius: ${({ theme: { constants } }) =>
    `${constants.borderRadiusLg} 0 0 ${constants.borderRadiusLg}`};

  svg {
    color: ${({ theme: { vars } }) => vars.textDefault};
  }

  div[class*='IconWrapper'] {
    margin-left: auto;
    margin-right: auto;
  }

  ${fontSm4};
  ${TruncatedText({})};
  ${({ width }) =>
    width &&
    css`
      &:has(font) {
        font {
          width: ${width};
        }
      }
    `}
`;

export const SurveyOptionsDataWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2} 4.25rem`};
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const StyledSurveyOptionsWrapper = styled.div`
  border-top: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} 0`};

  div[class*='RadioWrapper'] {
    min-height: 0;
  }
`;

export const StyledInputField = styled(InputField)`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const SurveySlideoutFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 1px;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerLg1}`};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
`;

export const Form = styled.form`
  width: 100%;
`;

export const AddQuestionButtonWrapper = styled.div`
  margin: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
`;

export const SmartTestButton = styled(DefaultButton)`
  ${() => {
    const blinking = keyframes`
        0% {
            opacity: 1;
        }

        60% {
            opacity: 0.75;
        }

        100% {
            opacity: 1;
        }
    `;

    return css`
      margin-right: auto;
      animation: ${blinking} 1.8s ease infinite;
      box-shadow: 0 0 16px 0 ${({ theme: { vars } }) => vars.trainualBrandGreenMedium};

      &:hover,
      &:focus {
        animation: none;
        box-shadow: none;
      }
    `;
  }}
`;

export const AutoComposeDescriptionButton = styled(DefaultButton)`
  margin: ${({ theme: { constants } }) => `0 0 ${constants.spacerMd3} ${constants.spacerLg2}`};
`;
