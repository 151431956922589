import React from 'react';
import styled from 'styled-components';

import { useGroup } from '../../../../../../contexts/GroupContext';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import ThreeDotMenu from '../../../../../design_system/Triage/menus/ThreeDotMenu';
import { ThreeDotMenuProps } from '../../../../../design_system/Triage/menus/ThreeDotMenu/types';
import { Title } from '../../../../shared/DetailedTitle/Title';

const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerLg2};
`;

const TitleDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  align-items: center;
`;

export type GroupHeaderProps = {
  buttonEnabled?: boolean;
  threeDotOptions?: ThreeDotMenuProps;
};

const GroupHeader = ({ threeDotOptions, buttonEnabled = false }: GroupHeaderProps) => {
  const { name } = useGroup();

  return (
    <GroupTitle>
      <TitleDetails>
        <TitleWrapper>
          <Title fontSize='lg1' fontWeight='semibold' title={name} />
          {!!threeDotOptions && (
            <ThreeDotMenu id={threeDotOptions.id} menuOptions={threeDotOptions.menuOptions} />
          )}
        </TitleWrapper>
      </TitleDetails>
      {buttonEnabled && (
        /* TODO: Adjust props to accept button info as necessary */
        <DefaultButton buttonType='secondary' id='groups-header-button' text='Button' />
      )}
    </GroupTitle>
  );
};

export default GroupHeader;
