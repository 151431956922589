import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { ProfilePhoneNumberSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const ProfilePhoneNumberSection = ({
  enableProfilePhoneNumber,
  id,
}: ProfilePhoneNumberSectionProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('enable_profile_phone_number_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={enableProfilePhoneNumber}
            className='enable-profile-phone-number-setting'
            handleToggle={(e) =>
              updateAccountData({ enable_profile_phone_number: e.target.checked })
            }
            id='enable-profile-phone-number'
            name='enable_profile_phone_number'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>
          {t('enable_profile_phone_number_description')}
        </FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default ProfilePhoneNumberSection;
