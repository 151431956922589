import React from 'react';

import { useAccountTerminology } from '../../../../../../../../components/AccountTerminologyProvider';
import { useCheckNoTrialFlow } from '../../../../../../../../hooks/billing/useCheckNoTrialFlow';
import useCurrentAccount from '../../../../../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../../../design_system/buttons/DefaultButton';
import {
  ConsiderThisPlanButtonsWrapper,
  ConsiderThisPlanTitle,
  ConsiderThisPlanWrapper,
  StyledP,
} from './styles';

type ConsiderThisPlanProps = {
  closeFullScreenOverlay: () => void;
};

const t = initTranslations('simple_pricing.pick_a_plan_overlay_content.consider_this_plan');

const ConsiderThisPlan = ({ closeFullScreenOverlay }: ConsiderThisPlanProps) => {
  const {
    splitFeatures: { simplePricingGrowthV2Enabled, simplePricingV3Enabled },
  } = useCurrentAccount();
  const isNoTrialFlow = useCheckNoTrialFlow();
  const { configs: publicConfigs } = usePublicConfigs();
  const employeesAmount = simplePricingGrowthV2Enabled || simplePricingV3Enabled ? 100 : 250;
  const { productTerm } = useAccountTerminology();

  return (
    <ConsiderThisPlanWrapper className='consider-this-plan'>
      <ConsiderThisPlanTitle>{t('title')}</ConsiderThisPlanTitle>
      <StyledP text={t('body_first_paragraph', { employeesAmount, productTerm })} />
      <br />
      <StyledP text={t('body_second_paragraph')} />
      <ConsiderThisPlanButtonsWrapper>
        {!isNoTrialFlow && (
          <DefaultButton
            buttonType='secondary'
            id='consider-this-plan-cancel-button'
            onClick={closeFullScreenOverlay}
            text={t('cta.cancel')}
          />
        )}
        <DefaultButton
          behavesAs='a'
          buttonType='primary'
          href={publicConfigs['TALK_TO_SALES_TRIAL']}
          id='consider-this-plan-talk-to-sales-link'
          target='_blank'
          text={t('cta.talk_to_sales')}
        />
      </ConsiderThisPlanButtonsWrapper>
    </ConsiderThisPlanWrapper>
  );
};

export default ConsiderThisPlan;
