import React, { useMemo } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useWindowResize from '../../../../hooks/useWindowResize';
import initTranslations from '../../../../lib/initTranslations';
import {
  useDismissHrisBannerMutation,
  useHrisInfoQuery,
} from '../../../../redux/services/resourceApis/settings/settingsApi';
import { FinchProvider } from '../../../../types/IntegrationProvider';
import IconButton from '../../../design_system/buttons/IconButton';
import ThemedImage from '../../../design_system/ThemedImage';
import { routes } from '../../publicApplication/applicationRouter';
import RouterLink from '../../publicApplication/RouterLink';

const StyledHrisBanner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 6px;
  background-color: ${(props) => props.theme.vars.foundationBase2};
`;

const StyledHrisBannerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledHrisBannerHeaderTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: 700;
  font-style: normal;
  line-height: 1.333rem;
  margin: 0;
  color: ${(props) => props.theme.vars.textDefault};
`;

const StyledHrisBannerHeaderSubtitle = styled.h5`
  font-size: 0.75rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.185rem;
  margin: 0;
  color: ${(props) => props.theme.vars.textDefault};
`;

const StyledHrisBannerList = styled.div`
  display: flex;
`;

const StyledHrisBannerIntegrationLink = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  width: 4rem;
  margin: 0 1rem;
  &:hover {
    color: ${(props) => props.theme.vars.textDefault};
  }
`;

const StyledHrisBannerIntegrationLogo = styled(ThemedImage)`
  height: 2rem;
`;

const StyledHrisBannerIntegrationTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: ${(props) => props.theme.vars.textDefault};
`;

const StyledHrisBannerLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledHrisBannerDismissButton = styled(IconButton)`
  width: 0.75rem;
  height: 0.75rem;
  font-size: 0.875rem;
  color: ${(props) => props.theme.vars.textDefault};
`;

const StyledHrisBannerViewAllIntegrationsLink = styled(RouterLink)`
  font-size: 0.889rem;
  font-weight: 600;
  font-style: normal;
  line-height: 1.333rem;
`;

export type HrisItem = {
  provider: FinchProvider;
  image: string;
  title: string;
};

const t = initTranslations('hris_banner');

const HrisBanner = () => {
  const { slug } = useCurrentAccount();
  const { isDesktop } = useWindowResize();
  const { dismissedHrisBanner, permission } = useCurrentUser();
  const { nonLocalizedCreatedAtString, status: accountStatus } = useCurrentAccount();
  const [dismissHrisBanner, { isUninitialized }] = useDismissHrisBannerMutation();

  const displayHrisBanner = useMemo(() => {
    const isAdmin = permission === 'admin' || permission === 'billing_admin';
    const sevenDays = 7 * 24 * 60 * 60 * 1000;
    const createdLessThan7DaysAgo =
      new Date().getTime() - new Date(nonLocalizedCreatedAtString).getTime() < sevenDays;

    return (
      !dismissedHrisBanner && accountStatus !== 'trial_ended' && isAdmin && createdLessThan7DaysAgo
    );
  }, [permission, nonLocalizedCreatedAtString, dismissedHrisBanner, accountStatus]);

  const { data } = useHrisInfoQuery(undefined, { skip: !displayHrisBanner });

  if (displayHrisBanner && isDesktop && data && isUninitialized) {
    return (
      <StyledHrisBanner>
        <StyledHrisBannerHeader>
          <StyledHrisBannerHeaderTitle>
            {t('invite_people_fast_with_integrations')}
          </StyledHrisBannerHeaderTitle>
          <StyledHrisBannerHeaderSubtitle>
            {t('choose_one_and_we_will_show_you_how')}
          </StyledHrisBannerHeaderSubtitle>
        </StyledHrisBannerHeader>
        <StyledHrisBannerList>
          {data.hris_list.map((integration, index) => (
            <StyledHrisBannerIntegrationLink
              id={`hris-banner-${integration.title}`}
              key={index}
              to={routes.integration({ slug, provider: integration.provider })}
            >
              <StyledHrisBannerIntegrationLogo
                alt={integration.title}
                darkImage={integration.image}
                lightImage={integration.image}
              />
              <StyledHrisBannerIntegrationTitle className='notranslate'>
                {integration.title}
              </StyledHrisBannerIntegrationTitle>
            </StyledHrisBannerIntegrationLink>
          ))}
        </StyledHrisBannerList>
        <StyledHrisBannerLinks>
          <StyledHrisBannerDismissButton
            ariaLabel={t('aria_label_close')}
            id='hris-banner-dismiss'
            name='times'
            onClick={dismissHrisBanner}
          />
          <StyledHrisBannerViewAllIntegrationsLink
            id='hris-banner-view-all'
            to={routes.integrations({ slug })}
          >
            {t('view_all_integrations')}
          </StyledHrisBannerViewAllIntegrationsLink>
        </StyledHrisBannerLinks>
      </StyledHrisBanner>
    );
  } else {
    return null;
  }
};

export default HrisBanner;
