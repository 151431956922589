import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { useRoute } from '../../../../publicApplication/applicationRouter';
import StripeElementsProvider from '../../../../shared/UpdatePaymentMethodForm/StripeElementsProvider/StripeElementsProvider';
import ComparePlansOverlayContent from '../../../shared/BillingPage/BillingPagePlans/ComparePlansOverlayContent/ComparePlansOverlayContent';
import PickAPlanOverlayContent from '../../../simple_pricing/BillingFullScreenOverlay/PickAPlanOverlayContent/PickAPlanOverlayContent';
import UpgradePlanOverlayContent from '../../../simple_pricing/BillingFullScreenOverlay/UpgradePlanOverlayContent/UpgradePlanOverlayContent';
import ConfigurePlanOverlayContent from '../../FullScreenOverlayContent/ConfigurePlanOverlayContent/ConfigurePlanOverlayContent';
import ManagePlanOverlayContent from '../../FullScreenOverlayContent/ManagePlanOverlayContent/ManagePlanOverlayContent';

export const useGetFullScreenOverlayContent = () => {
  const route = useRoute();
  const {
    splitFeatures: { simplePricingEnabled, simplePricingV3Enabled },
  } = useCurrentAccount();

  const getFullScreenOverlayContent = () => {
    if (simplePricingEnabled || simplePricingV3Enabled) {
      switch (route.name) {
        case 'upgradeSimplePricingPlan':
          return <UpgradePlanOverlayContent route={route} />;
        case 'pickASimplePricingPlan':
          return <PickAPlanOverlayContent route={route} />;
      }
    } else {
      switch (route.name) {
        case 'configurePlan':
          return <ConfigurePlanOverlayContent route={route} />;
        case 'downgradePlan':
          return <ManagePlanOverlayContent route={route} type='downgrade' />;
        case 'manageSeats':
          return <ManagePlanOverlayContent route={route} type='manage-seats' />;
        case 'upgradePlan':
          return <ManagePlanOverlayContent route={route} type='upgrade' />;
      }
    }
  };

  return route.name === 'comparePlans' ? (
    <ComparePlansOverlayContent />
  ) : (
    <StripeElementsProvider>{getFullScreenOverlayContent()}</StripeElementsProvider>
  );
};
