import React, { FC } from 'react';

import emptyStateImage from '../../../../../../../images/empty_state/responsibility-linked-content.svg';
import { Container, Image, Title } from './styles';
import { EmptyStateProps } from './types';

const EmptyState: FC<EmptyStateProps> = ({ title }) => {
  return (
    <Container>
      <Image src={emptyStateImage} />

      <Title>{title}</Title>
    </Container>
  );
};

export default EmptyState;
