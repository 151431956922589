import { Form, Formik, FormikErrors, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { Route } from 'type-route';

import TrainualLogo from '../../../../../../../assets/images/trainual-purple.png';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import isEmail from '../../../../../lib/isEmail';
import { loginApi } from '../../../../../redux/services/resourceApis/publicApplication/loginApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Link from '../../../../design_system/Link';
import InputField from '../../../../design_system/Triage/InputField';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import { routes } from '../../applicationRouter';
import PublicPage from '../../PublicPage';
import { ActionWrapper, FormWrapper, H1, Logo, LogoWrapper } from '../../styles/shared';

type FormValues = {
  email: string;
};

const initialValues: FormValues = {
  email: '',
};

const t = initTranslations('public_application.universal_login');

const validateForm = ({ email }: FormValues) => {
  const errors: FormikErrors<FormValues> = {};
  if (!email || email.trim() === '') {
    errors.email = t('form.errors.blank');
  } else if (!isEmail(email)) {
    errors.email = t('form.errors.invalid');
  }
  return errors;
};

export type LoginFormProps = {
  route: Route<typeof routes.universalLogin>;
};

const LoginForm = ({ route }: LoginFormProps) => {
  const { flash } = useFlashNotification();
  const { isValid, isValidating, values, touched, errors, handleChange } =
    useFormikContext<FormValues>();
  const [universalLoginTrigger, result] = loginApi.useLazyUniversalLoginQuery();
  const { isLoading, isFetching, data } = result;
  const isActionInProgress = isLoading || isFetching;

  const successFunction = useCallback(() => {
    if (!data) return;
    const { users, universalLoginWithOtpEnabled } = data;
    if (!users) return;

    if (universalLoginWithOtpEnabled) {
      return flash('info', t('form.selection_request'));
    }

    if (users.length === 0) {
      flash('info', t('form.selection_request'));
    } else if (users.length === 1) {
      routes
        .login({
          email: users[0].email,
          slug: users[0].account.slug,
          redirect_path: route.params.redirect_path,
        })
        .push();
    } else {
      routes
        .accountSelection({ email: users[0].email, redirect_path: route.params.redirect_path })
        .push();
    }
  }, [flash, route.params.redirect_path, data]);

  useDisplayFlashOnResponse({
    result,
    successFunction,
  });

  const submit = useCallback(() => {
    if (isValid && !isValidating) {
      universalLoginTrigger({ email: values.email });
    }
  }, [isValid, isValidating, universalLoginTrigger, values.email]);

  return (
    <Form autoComplete='off'>
      <FormWrapper>
        <LogoWrapper>
          <Logo src={TrainualLogo} />
        </LogoWrapper>
        <H1>{t('title')}</H1>
        <InputField
          autoFocus
          errorText={touched.email && errors.email}
          iconName='envelope'
          label={t('email_label')}
          name='email'
          onChange={handleChange}
          placeholder={t('email_placeholder')}
          value={values.email}
        />
        <ActionWrapper>
          <span>
            {t('register_prompt')}
            <Link href={routes.root().href} text={t('register_cta')} />
          </span>
          <DefaultButton
            disabled={isActionInProgress}
            id='universal-login-submit-button'
            loading={isActionInProgress}
            onClick={submit}
            text={t('sign_in_cta')}
            type='submit'
          />
        </ActionWrapper>
      </FormWrapper>
    </Form>
  );
};

const UniversalLogin = ({ route }: LoginFormProps) => {
  return (
    <PublicPage id='universal-login-page'>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          /* API request occurs in `LoginForm` */
        }}
        validate={validateForm}
        validateOnMount
      >
        <LoginForm route={route} />
      </Formik>
    </PublicPage>
  );
};

export default UniversalLogin;
