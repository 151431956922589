import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import Toggle from '../../../design_system/input/controls/Toggle';

const ToggleWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export type ESignatureToggleProps = {
  curriculumId: number;
  handleToggle: () => void;
  requireSignature: boolean;
};

const t = initTranslations('curriculum_edit.e_signature_toggle');

const ESignatureToggle = ({
  curriculumId,
  handleToggle,
  requireSignature,
}: ESignatureToggleProps) => {
  const {
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const topicTerm = { topic: topicTermSingular.toLowerCase() };

  return (
    <>
      <Tooltip
        id={`curriculum-${curriculumId}-e-signature-toggle-tooltip`}
        text={requireSignature ? t('on', topicTerm) : t('off', topicTerm)}
      />
      <ToggleWrapper data-for={`curriculum-${curriculumId}-e-signature-toggle-tooltip`} data-tip>
        <Toggle
          checked={requireSignature}
          checkedIcon='signature'
          handleToggle={handleToggle}
          id={`curriculum-${curriculumId}-e-signature-toggle`}
          name='e-signature'
          uncheckedIcon='signature-slash'
        />
      </ToggleWrapper>
    </>
  );
};

export default ESignatureToggle;
