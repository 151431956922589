import styled from 'styled-components';

import { AssistiveTextWrapper } from '../../../../../design_system/core/AssistiveText/styles';
import { StyledAvatar } from '../../../../../design_system/display/avatar/Avatar';
import { getIconSize } from '../../../../../design_system/display/icons/Icon/Icon';
import { fontSm5 } from '../../../../../styled/TypeSystem';

export const HeaderAvatarWrapper = styled.div<{ isGroupAvatar: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm5};

  // DS Override: Update group avatar placeholder background color and icon size to match designs
  ${StyledAvatar} {
    ${({ isGroupAvatar, theme: { vars } }) =>
      isGroupAvatar && `background-color: ${vars.trainualBrandBlueSubdued}`};

    svg {
      ${getIconSize('2xs')}
    }
  }
`;

export const AssistiveTextContainer = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  // DS override - changed wrapper padding to match designs

  ${AssistiveTextWrapper} {
    padding-top: 0;

    & > span {
      margin-top: 0;
    }
  }
`;
