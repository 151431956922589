import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import React, { FC } from 'react';

import { SensorBox } from './styles';
import { BoardControllerSensorProps } from './types';

const Sensor: FC<BoardControllerSensorProps> = ({
  sensorWidth,
  setSensorElement,
  sensorPositionX,
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: 'board-controller-sensor',
  });

  const draggingStyles = {
    transform: CSS.Transform.toString(transform),
    left: sensorPositionX,
  };

  return (
    <SensorBox
      {...attributes}
      {...listeners}
      isDragging={isDragging}
      ref={(el) => {
        setNodeRef(el);
        setSensorElement(el);
      }}
      sensorWidth={sensorWidth}
      style={draggingStyles}
    />
  );
};

export default Sensor;
