import React from 'react';

import Icon from '../../../../design_system/display/icons/Icon';
import { Emoji, SurveyEmojiWrapper } from './styles';
import { SurveyEmojiProps } from './types';

const SurveyEmoji = ({ emoji, isClickable }: SurveyEmojiProps) => {
  return (
    <SurveyEmojiWrapper isClickable={isClickable}>
      {!!emoji ? (
        <Emoji>{emoji}</Emoji>
      ) : (
        <Icon name='clipboard-check' size='md' weight='regular' />
      )}
    </SurveyEmojiWrapper>
  );
};

export default SurveyEmoji;
