import React, { ReactNode } from 'react';
import styled from 'styled-components';

import isEmail from '../../../../lib/isEmail';
import { SurfaceTitle } from '../../../styled/Application/Groups';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontMd1, fontMd2 } from '../../../styled/TypeSystem';

const StyledSurfaceTitle = styled(SurfaceTitle)`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  display: flex;

  @media (min-width: ${mediaBreakpointPxSm}) {
    ${fontMd2};
  }
  ${fontMd1};
`;

interface Props {
  children?: ReactNode;
  className?: string;
  firstName?: string;
  titleText: string;
}

const displayTitle = (firstName: string | undefined, titleText: string) => {
  if (!firstName || isEmail(firstName)) {
    return titleText;
  }

  return `${firstName}'s ${titleText}`;
};

const Title = ({ titleText, firstName, children, className = '' }: Props) => {
  return (
    <StyledSurfaceTitle className={`surface-title ${className}`}>
      {displayTitle(firstName, titleText)}
      {children}
    </StyledSurfaceTitle>
  );
};

export default Title;
