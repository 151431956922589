import styled from 'styled-components';

import P from '../../../../../design_system/text/P';
import { fontSm4 } from '../../../../../styled/TypeSystem';

export const NextPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NextPaymentTitle = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm1};
`;

export const NextPaymentDetails = styled(P)`
  ${fontSm4};
`;
