import styled from 'styled-components';

import P from '../../../../../../design_system/text/P';
import { fontMd3, fontSm4 } from '../../../../../../styled/TypeSystem';

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const Title = styled.h6`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd3};
`;

export const Text = styled(P)`
  ${fontSm4};
`;
