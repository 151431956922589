import styled from 'styled-components';

export const BillingIntervalWrapper = styled.div`
  position: relative;
  align-items: end;
  display: flex;
  flex-direction: column;
  justify-content: end;
  max-width: 10.375rem;
  min-height: 4.5rem;
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
`;

export const SavingsImg = styled.img`
  position: absolute;
  z-index: 1;
  left: calc(100% - 3rem);
  top: 0;
  width: 14rem;
  height: 2.75rem;
  object-fit: cover;
`;

export const NotificationTitle = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
`;

export const NotificationPlan = styled.b<{ color: string }>`
  color: ${({ color }) => color};
`;
