import React, { useCallback, useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';

import { ImageFlyoutProvider } from '../../../../../contexts/ImageFlyoutContext';
import useDeleteBioConfirmation from '../../../../../hooks/useDeleteBioConfirmation';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { useAbilityToManageProfile } from '../../../../../hooks/users/ProfileSharedAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import { userFirstName } from '../../../../../lib/userNaming';
import { usePostUserBioImageMutation } from '../../../../../redux/services/axiosService';
import {
  useEditBioMutation,
  useGetBioQuery,
} from '../../../../../redux/services/resourceApis/users/usersApi';
import {
  CollapsibleMenuOptionProps,
  MenuOptions,
} from '../../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import clearFix from '../../../../helpers/clearFix';
import RichTextarea from '../../../editor/components/RichTextarea';
import { ButtonGroup, ToolbarSimple } from '../../../editor/toolbar';
import { bubbleToolbarButtons } from '../../../editor/toolbar/buttons/shared';
import BioCollapsibleSurface from './BioCollapsibleSurface/BioCollapsibleSurface';
import EmptyBio from './EmptyBio';

// Added clearfix here to ensure content does not appear out of the
// CollapsibleSurface. Please do not remove as this is in effort to fix
// https://trainual.atlassian.net/browse/GS1-3178
// Please review the above mentioned ticket if we consider removing this
const BioWrapper = styled.div`
  ${clearFix};
`;

const t = initTranslations('user_profile.bio');

interface BioProps {
  isTrialEndedOrOnHold: boolean;
  name: string;
  userId: number;
}

const bioToolbar: ButtonGroup[] = [
  ...ToolbarSimple,
  {
    name: 'AddImage',
    buttons: ['addImage', 'insertLink'],
  },
];

const Bio = ({ isTrialEndedOrOnHold, name, userId }: BioProps) => {
  const canUpdateBio = useAbilityToManageProfile(userId);
  const { showDeleteBioModal, confirmationModal } = useDeleteBioConfirmation(userId);
  const { isError, isLoading, data } = useGetBioQuery(userId);
  const firstName = userFirstName(name);
  const [editable, setEditable] = useState(false);
  const [editBio, editBioResult] = useEditBioMutation();
  const { isSuccess: isEditSuccess } = editBioResult;
  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);

  useDisplayFlashOnResponse({
    result: editBioResult,
    successMessage: t('edit.success'),
    errorMessage: t('edit.error'),
  });

  useEffect(() => {
    if (isEditSuccess) {
      if (data) {
        collapseDispatch({ type: 'expand' });
      } else {
        collapseDispatch({ type: 'collapse' });
      }
    }
  }, [data, isEditSuccess]);

  const handleSave = useCallback(
    (content: object) => {
      editBio({ id: userId, bio_json: content });
      setEditable(false);
    },
    [editBio, userId]
  );

  const handleCancel = useCallback(() => {
    setEditable(false);
  }, []);

  const [uploadImage, result] = usePostUserBioImageMutation();
  const uploadImageCallback = useCallback(
    (image: FormData) => {
      uploadImage({ userId, image });
    },
    [uploadImage, userId]
  );

  if (isLoading) return <></>;
  if (isError) return <div>{t('error', { name: firstName })}</div>;

  const threeDotMenuOptions: CollapsibleMenuOptionProps[] = [
    {
      title: t('three_dot_menu.edit'),
      onClick: () => setEditable(true),
      iconName: 'edit',
      shouldToggleCollapse: true,
      id: 'td-profile-bio-edit',
      visible: true,
    },
    {
      title: t('three_dot_menu.delete'),
      onClick: showDeleteBioModal,
      iconName: 'trash-can',
      id: 'td-profile-bio-delete',
      visible: true,
    },
  ];

  const threeDotIconProps: MenuOptions =
    data && canUpdateBio && !isTrialEndedOrOnHold
      ? { threeDotMenuId: 'about-me-bio', threeDotMenuOptions }
      : {};

  return (
    <>
      {confirmationModal}
      <section>
        <BioCollapsibleSurface
          chevronClassName='surface-collapse-chevron-about-me'
          className='about-me'
          collapseDispatch={collapseDispatch}
          id='bio-collapsible-surface'
          isCollapsed={collapsed}
          isCollapsible={!!data}
          title={t('name', { name: firstName })}
          {...threeDotIconProps}
        >
          <BioWrapper>
            {data || editable ? (
              <ImageFlyoutProvider uploadImage={uploadImageCallback} uploadResult={result}>
                <RichTextarea
                  bubbleToolbarButtons={bubbleToolbarButtons}
                  content={data || ''}
                  dockedToolbarButtons={bioToolbar}
                  editable={editable}
                  onCancel={handleCancel}
                  onSave={handleSave}
                />
              </ImageFlyoutProvider>
            ) : (
              <EmptyBio
                isTrialEndedOrOnHold={isTrialEndedOrOnHold}
                setEditable={setEditable}
                userId={userId}
              />
            )}
          </BioWrapper>
        </BioCollapsibleSurface>
      </section>
    </>
  );
};

export default Bio;
