import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { fontMd5 } from '../../../styled/TypeSystem';
import Icon from '../../display/icons/Icon';
import { ErrorText } from '../InputFieldWithCharacterCounter';
import { TruncatedText } from '../TruncatedText';
import { ErrorMessage, Validator } from './types';

// Override: Line height normal is to fix a dragging bug that occurred with fontMd5's line height
export const SharedFontCss = css`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd5};
  line-height: normal;
`;

const DynamicInput = styled.input.attrs(
  ({ width, maxWidth }: { width: number; maxWidth: number }) => ({
    style: {
      width: `${width}px`,
      maxWidth: `${maxWidth}px`,
    },
  })
)<{ hasError?: boolean; width: number; maxWidth: number }>`
  min-width: 10rem;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  box-sizing: border-box;
  background: transparent;

  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid transparent`};
  width: ${({ maxWidth }) => `${maxWidth}px`};
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1.0')};
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  ${TruncatedText({})};

  &:hover,
  &:focus {
    text-overflow: inherit;
    border: ${({ theme: { constants, vars }, hasError }) =>
      hasError
        ? `${constants.borderWidthSm} solid ${vars.stateError}`
        : `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  }

  :focus-within {
    outline: none;
    border-color: ${({ theme: { vars }, hasError }) =>
      hasError ? vars.stateError : vars.accentPrimaryDefault};
    color: ${({ theme: { vars } }) => vars.textDefault};
    -webkit-box-shadow: 0 0 0 50px ${({ theme: { vars } }) => vars.foundationSurface1} inset;
    caret-color: ${({ theme: { vars } }) => vars.textDefault};
  }

  ${SharedFontCss};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.stateError};
`;

const HiddenSizingSpan = styled.span<{ maxWidth: number }>`
  visibility: hidden;
  position: absolute;
  white-space: pre;
  overflow: hidden;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};

  ${SharedFontCss};
`;

export type Props = {
  attributeName: string;
  initialValue: string;
  isSuccess: boolean;
  allowEmpty?: boolean;

  focusText?: boolean;
  maxCharacters?: number;
  maxWidth?: number;
  placeholder?: string;
  responseError: string | undefined;

  submitChanges(value: string): void;
  validate?: Validator;
  errorMessage?: ErrorMessage;
};

const ResizableInput = ({
  attributeName,
  placeholder,
  initialValue,
  submitChanges,
  maxWidth,
  validate = () => null,
  errorMessage = {},
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [inputWidth, setInputWidth] = useState(50);
  const [error, setError] = useState<string | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const calcMaxWidth = useMemo(
    () => (maxWidth && window.innerWidth > maxWidth ? maxWidth : window.innerWidth),
    [maxWidth]
  );

  const defaultErrorMessages: ErrorMessage = {
    default: 'Invalid input',
  };

  const validateAndSubmit = async () => {
    setError(null);

    const validationError = validate(inputValue);
    if (validationError) {
      setError(errorMessage[validationError] || defaultErrorMessages.default);
      return;
    }

    await submitChanges(inputValue);
    if (inputRef.current) inputRef.current.blur();
  };

  // Returns the input to the original position when the mouse leaves the input so truncation is correct
  const handleMouseLeave = () => {
    if (!isFocused && inputRef.current) {
      inputRef.current.scrollLeft = 0;
    }
  };

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const updateWidthAfterFontsLoad = async () => {
      await document.fonts.ready; // Wait for fonts to load so sizing is correct
      if (spanRef.current) {
        setInputWidth(spanRef.current.offsetWidth + 20);
      }
    };
    updateWidthAfterFontsLoad();
  }, [inputValue]);

  return (
    <Wrapper>
      <DynamicInput
        hasError={!!error}
        id={`${attributeName}-resizable-field`}
        maxWidth={calcMaxWidth}
        name={attributeName}
        onBlur={() => {
          validateAndSubmit();
          setIsFocused(false);
        }}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onKeyDown={(e) => e.key === 'Enter' && validateAndSubmit()}
        onMouseLeave={handleMouseLeave}
        placeholder={placeholder}
        ref={inputRef}
        value={inputValue}
        width={inputWidth}
      />
      <HiddenSizingSpan maxWidth={calcMaxWidth} ref={spanRef}>
        {inputValue || placeholder}
      </HiddenSizingSpan>
      {error && (
        <ErrorWrapper>
          <Icon name='circle-x' size='2xs' weight='solid' />
          <ErrorText>{error}</ErrorText>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default ResizableInput;
