import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import setMultiSelectOptions from '../../../../lib/multiSelectOptions';
import {
  useGetUnassignedSubjectsQuery,
  useSubjectAssignmentForUserMutation,
} from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import { HeaderSubtitle } from '../../../design_system/overlays/modals/TaskModal/TaskModalTypes';
import { ChecklistOption } from '../../../design_system/Triage/Checklist';
import SearchableChecklistModal from '../../shared/SearchableChecklistModal/SearchableChecklistModal';
import AssignSubject from './AssignSubject';

const t = initTranslations('assign_curriculums_modal');

export type AssignSubjectsModalProps = {
  closeModal: () => void;
  userId: number;
  title?: string;
  trainingPathSetUuid?: string;
} & HeaderSubtitle;

const AssignSubjectsModal = ({
  closeModal,
  userId,
  title = t('add_content'),
  subtitle,
  subtitleLink,
  subtitleWithBoldedText,
  trainingPathSetUuid,
}: AssignSubjectsModalProps) => {
  const headerSubtitleProps: HeaderSubtitle = subtitle
    ? {
        subtitle,
      }
    : subtitleLink
    ? { subtitleLink }
    : subtitleWithBoldedText
    ? { subtitleWithBoldedText }
    : {};
  const [checkboxOptions, setCheckboxOptions] = useState<ChecklistOption[]>([]);
  const [isLoadingCheckboxOptions, setIsLoadingCheckboxOptions] = useState(true);
  const selectedCurriculums = useMemo(() => {
    return checkboxOptions.filter(({ checked }) => checked === true).map(({ id }) => id);
  }, [checkboxOptions]);
  const { curriculum } = useAccountTerminology();
  const {
    data: curriculums,
    isLoading,
    error,
    isSuccess,
  } = useGetUnassignedSubjectsQuery({ id: userId });

  const [curriculumAssignment, curriculumAssignmentResult] = useSubjectAssignmentForUserMutation();
  const assignSubjects = useCallback(() => {
    curriculumAssignment({
      addSubjectIds: selectedCurriculums,
      removeSubjectIds: [],
      userId,
      trainingPathSetUuid,
    });
  }, [curriculumAssignment, userId, selectedCurriculums, trainingPathSetUuid]);
  const onSelectionChanged = useCallback(
    (options: ChecklistOption[]) => {
      const updateOptions = checkboxOptions.map((option) => {
        option.checked = !!options.find((checkedOption) => checkedOption.id === option.id);
        return option;
      });
      setCheckboxOptions(updateOptions);
    },
    [checkboxOptions]
  );

  useEffect(() => {
    const sortedCurriculums = curriculums?.length
      ? [...curriculums].sort((a, b) =>
          a.title.localeCompare(b.title, undefined, { sensitivity: 'base' })
        )
      : [];

    if (isSuccess) {
      setCheckboxOptions(
        setMultiSelectOptions(
          sortedCurriculums.map((curriculum) => ({
            ...curriculum,
            title: <AssignSubject curriculum={curriculum} />,
            filterOn: curriculum.title,
          })),
          [],
          'subject'
        )
      );
      setIsLoadingCheckboxOptions(false);
    }
  }, [curriculums, isSuccess]);

  useDisplayFlashOnResponse({
    result: curriculumAssignmentResult,
    successFunction: closeModal,
    successMessage: t('flash_assignment'),
  });

  const taskModalArgs: TaskModalProps = {
    ...headerSubtitleProps,
    title,
    isDisabled: curriculumAssignmentResult.isLoading || !selectedCurriculums.length,
    processing: curriculumAssignmentResult.isLoading,
    onCloseRequest: closeModal,
    primaryButtonText: t('add'),
    primaryButtonTask: assignSubjects,
    heapModalName: 'assign-subjects-to-user-modal',
  };

  return (
    <SearchableChecklistModal
      checkboxOptions={checkboxOptions}
      errorMessage={error ? t('error_message', { curriculum: curriculum.plural }) : null}
      isLoading={isLoading || isLoadingCheckboxOptions}
      numberSelected={selectedCurriculums.length}
      onSelectionChanged={onSelectionChanged}
      taskModalProps={taskModalArgs}
    />
  );
};

export default AssignSubjectsModal;
