import React, { FC } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { closeResponsibilityDetailsModal } from '../../../../../../../redux/domains/responsibilityDetailsModal/responsibilityDetailsModalSlice';
import { useAppDispatch } from '../../../../../../../redux/hooks';
import { useDeleteResponsibilityMutation } from '../../../../../../../redux/services/resourceApis/responsibilities/responsibilitiesApi';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import Icon from '../../../../../../design_system/display/icons/Icon';
import useAutoSaveStatusUpdater from '../../../../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import { SectionItemContainer, SectionItemTitle } from '../../styles';
import { MenuItemContainer } from './styles';
import { OptionsMenuProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.options');

const OptionsMenu: FC<OptionsMenuProps> = ({ responsibilityId }) => {
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();
  const dispatch = useAppDispatch();

  const [deleteResponsibility, result] = useDeleteResponsibilityMutation();
  const { isLoading, isSuccess } = result;

  const handleDeleteBoardColumnResponsibility = async () => {
    await deleteResponsibility(responsibilityId);
    dispatch(closeResponsibilityDetailsModal());
  };

  useAutoSaveStatusUpdater([
    {
      isSaving: isLoading,
      isSavedSuccessfully: isSuccess,
    },
  ]);

  return (
    <SectionItemContainer>
      <SectionItemTitle>{t('title')}</SectionItemTitle>
      <MenuItemContainer
        id='remove-responsibility-option'
        onClick={handleDeleteBoardColumnResponsibility}
      >
        <Icon name='trash-can' size='xs' />
        {t('remove', { responsibility: responsibilitySingular.toLowerCase() })}
      </MenuItemContainer>
    </SectionItemContainer>
  );
};

export default OptionsMenu;
