import React, { useEffect } from 'react';
import { Route } from 'type-route';

import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useGetTopicFirstStepIdQuery } from '../../../../redux/services/resourceApis/courses/topicsApi';
import { trainualApi } from '../../../../redux/services/trainualService';
import { store } from '../../../../redux/stores/store';
import EditModeSkeleton from '../../editor/EditorPage/components/EditModeSkeleton/EditModeSkeleton';
import { routes } from '../../publicApplication/applicationRouter';

// This component is only setup to support our current handling of redirecting to a first step in a course to the editor page
const CourseEdit = ({ route }: { route: Route<typeof routes.courseEditor> }) => {
  const {
    params: { id, slug, redirecting },
  } = route;
  const { data, error } = useGetTopicFirstStepIdQuery(id);

  useEffect(() => {
    if (redirecting) {
      store.dispatch(
        trainualApi.util.invalidateTags([
          { type: 'Topic', id },
          { type: 'Curriculum', id: 'LIST' },
        ])
      );
    }
  }, [id, redirecting]);

  useEffect(() => {
    if (data) {
      routes.editor({ id: data.firstStepId, slug }).replace();
    }
  }, [data, slug]);

  if (error) return <BasicErrorDisplay error={error} />;
  return <EditModeSkeleton />;
};

export default CourseEdit;
