import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { HorizontalLineStylesProps } from './types';

export const ColorPickerWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  width: auto;
`;

export const HorizontalLineRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Line = styled.div<HorizontalLineStylesProps>`
  width: 10rem;
  background-color: ${({ color }) => color};
  height: ${({ height }) => height};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: 16rem;
  }
`;

export const LineItem = styled.div`
  display: flex;
  height: 1.5rem;
  align-items: center;
`;

export const SelectedLine = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;
