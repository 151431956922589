import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';

import Square from './Square';

const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme: { constants } }) => constants.spacerSm1} 0
    ${({ theme: { constants } }) => constants.spacerSm3} 0;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
`;

export type Props = {
  numRows: number;
  numCols: number;
  setSelectedGrid: Dispatch<SetStateAction<{ rows: number; columns: number }>>;
  onSelect: () => void;
};

const TablePicker = ({ numRows, numCols, setSelectedGrid, onSelect }: Props) => {
  const [grid, setGrid] = useState(() => {
    const rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(Array(numCols).fill(false));
    }
    return rows;
  });

  const handleClick = (row: number, col: number) => {
    const newGrid = grid.map((rows, i) =>
      rows.map((value, j) => (i <= row && j <= col ? true : i > row || j > col ? false : value))
    );
    setGrid(newGrid);
    onSelect();
  };

  const handleMouseOver = (row: number, col: number) => {
    setSelectedGrid({ rows: row + 1, columns: col + 1 });
    const newGrid = grid.map((rows, i) =>
      rows.map((value, j) => (i <= row && j <= col ? true : i > row || j > col ? false : value))
    );
    setGrid(newGrid);
  };

  return (
    <TableContainer>
      <GridContainer>
        {grid.map((rows, i) => (
          <RowContainer key={i}>
            {rows.map((selected, j) => (
              <Square
                id={`square-${i}-${j}`}
                key={j}
                onClick={() => handleClick(i, j)}
                onMouseOver={() => handleMouseOver(i, j)}
                selected={selected}
              />
            ))}
          </RowContainer>
        ))}
      </GridContainer>
    </TableContainer>
  );
};

export default TablePicker;
