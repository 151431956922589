import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { SurveyError } from '../../../../../redux/services/resourceApis/surveys/types';
import { ErrorPreviewContainer, ErrorPreviewMessage, ErrorPreviewTitle } from './styles';

const t = initTranslations('editor.create_survey.errors_preview');

const SurveyErrorPreview = ({ error }: { error: SurveyError }) => {
  return (
    <ErrorPreviewContainer>
      <ErrorPreviewTitle>{t('test')}</ErrorPreviewTitle>
      <ErrorPreviewMessage>{t(error.attribute)}</ErrorPreviewMessage>
    </ErrorPreviewContainer>
  );
};

export default SurveyErrorPreview;
