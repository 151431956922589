import React, { useRef } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useTruncatedText from '../../../../../hooks/useTruncatedText';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import CurriculumTitle from '../../../editor/components/CurriculumTitle/CurriculumTitle';
import { CurriculumTitleLink } from '../../../editor/shared/styles';
import { routes } from '../../../publicApplication/applicationRouter';
import { SurveyCurriculumTitleLinkProps } from './types';

const SurveyCurriculumTitleLink = ({
  curriculumId,
  curriculumTitle,
  redirectToEditPage,
}: SurveyCurriculumTitleLinkProps) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const showTooltip = useTruncatedText(textRef);
  const { slug } = useCurrentAccount();
  const linkToCurriculum = redirectToEditPage
    ? routes.curriculumEdit({ slug, id: curriculumId }).href
    : routes.curriculumShow({ slug, id: curriculumId }).href;

  return (
    <>
      {showTooltip && (
        <Tooltip id='curriculum-title-tooltip' place='bottom' text={curriculumTitle} />
      )}
      <CurriculumTitleLink
        dataFor='curriculum-title-tooltip'
        href={linkToCurriculum}
        prefixIconName='arrow-left'
        text={
          <CurriculumTitle
            id={`curriculum-title-${curriculumId}`}
            title={curriculumTitle}
            titleRef={textRef}
          />
        }
        underlineBehavior='dynamic'
      />
    </>
  );
};

export default SurveyCurriculumTitleLink;
