import { useEffect } from 'react';

import {
  CreateSubscriptionProps,
  CreateSubscriptionResult,
} from '../../components/application/billing/per_user_pricing/FullScreenOverlayContent/ConfigurePlanOverlayContent/types';
import { useGetStripeId } from '../../lib/billing/getStripeId';
import { mapBillingDetailsToStripeParams } from '../../lib/billing/mapBillingDetailsToStripeParams';
import { messageFromError } from '../../redux/errors/helpers';
import { useCreateSubscriptionMutation } from '../../redux/services/resourceApis/subscriptions/subscriptionsApi';
import { CreateSubscriptionParams } from '../../redux/services/resourceApis/subscriptions/types';
import useCurrentAccount from '../useCurrentAccount';
import useDisplayFlashOnResponse from '../useDisplayFlashOnResponse';
import { useCloseFullScreenOverlay } from './useCloseFullScreenOverlay';

export function useCreateSubscription(): CreateSubscriptionResult {
  const [createSubscriptionMutation, createSubscriptionResult] = useCreateSubscriptionMutation();
  const { isLoading, error, isSuccess } = createSubscriptionResult;
  const getStripeIdPromise = useGetStripeId();
  const { status } = useCurrentAccount();
  const closeFullScreenOverlay = useCloseFullScreenOverlay();

  const createSubscription = async ({
    validRequiredFields,
    billingDetails,
    overlayParams,
    coupon,
    totalAmount,
  }: CreateSubscriptionProps) => {
    const stripeId = await getStripeIdPromise(billingDetails);
    const stripeParams = mapBillingDetailsToStripeParams(billingDetails);
    const { plan, interval, quantity } = overlayParams;
    const createSubscriptionParams: CreateSubscriptionParams = {
      plan,
      interval,
      quantity,
      coupon,
      totalAmount,
      ...stripeParams,
    };

    if (stripeId !== undefined && validRequiredFields) {
      createSubscriptionMutation({ ...createSubscriptionParams, stripe_id: stripeId });
    }
  };

  useDisplayFlashOnResponse({
    result: createSubscriptionResult,
    errorMessage: messageFromError(error)?.join(', '),
  });

  useEffect(() => {
    if (isSuccess && status === 'active') {
      // We have to use a search parameter to display the welcome modal after page reload in case of non-SPA routes
      closeFullScreenOverlay(undefined, true);
    }
  }, [closeFullScreenOverlay, isSuccess, status]);

  return {
    createSubscription,
    isLoadingCreateSubscription: isLoading,
    isSuccessCreateSubscription: isSuccess,
  };
}
