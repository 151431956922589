import { Size } from '../../types/design_system/Size';

export const getVideoPreviewContainerSize = (size: Size) => {
  switch (size) {
    case 'xs':
      return {
        'max-width': '17.5rem',
        'min-height': '10.5rem',
      };
    case 'md':
      return {
        'max-width': '52.25rem',
        'min-height': '19.5rem',
      };
  }
};
