import styled from 'styled-components';

import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { WidgetContainer } from '../shared/styles';

export const StyledWidgetContainer = styled(WidgetContainer)`
  height: fit-content;
`;

export const Inner = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const InnerImage = styled.img`
  width: 3.75rem;
  height: 4.55rem;
  flex: none;
`;

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const Title = styled.h3`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontSm5};
`;

export const Description = styled.p`
  ${fontSm4};
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
`;
