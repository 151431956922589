import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useIsAdmin } from '../../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../../lib/initTranslations';
import ObjectIsland from '../../../Shared/ObjectIsland/ObjectIsland';
import { ObjectIslandAction } from '../../../Shared/ObjectIsland/types';
import BulkUserManagementModalsFactory from './BulkUserManagementModalsFactory';
import { BulkUserManagementMenuProps, BulkUserManagementModal } from './types';

const BulkUserManagementMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 3rem;
  width: 100%;
  z-index: 999;
`;

const t = initTranslations('users_table.bulk_user_management_menu');

const BulkUserManagementMenu = ({
  selectedCount,
  onCloseRequest,
  selectedUsersIds,
}: BulkUserManagementMenuProps) => {
  const [modal, setModal] = useState<BulkUserManagementModal>('none');
  const isAdmin = useIsAdmin();

  const unarchiveAction: ObjectIslandAction | undefined = useMemo(() => {
    if (!isAdmin) return;

    return {
      label: t('bulk_unarchive_modal.cta'),
      icon: 'arrow-rotate-left',
      onClick: () => {
        setModal('unarchive');
      },
    };
  }, [isAdmin]);

  const archiveAction: ObjectIslandAction | undefined = useMemo(() => {
    if (!isAdmin) return;

    return {
      label: t('bulk_archive_modal.cta'),
      icon: 'box-archive',
      onClick: () => {
        setModal('archive');
      },
    };
  }, [isAdmin]);

  const exportAction: ObjectIslandAction | undefined = useMemo(() => {
    if (!isAdmin) return;

    return {
      label: t('bulk_export_csv.cta'),
      icon: 'file-export',
      onClick: () => {
        setModal('export');
      },
    };
  }, [isAdmin]);

  const actions: ObjectIslandAction[] = useMemo(() => {
    const baseActions: ObjectIslandAction[] = [
      {
        label: t('edit_permission'),
        icon: 'user-gear',
        onClick: () => {
          setModal('update-permissions');
        },
      },
    ];

    if (exportAction) {
      baseActions.push(exportAction);
    }

    if (archiveAction) {
      baseActions.push(archiveAction);
    }

    if (unarchiveAction) {
      baseActions.push(unarchiveAction);
    }

    return baseActions;
  }, [exportAction, archiveAction, unarchiveAction]);

  return (
    <>
      <BulkUserManagementMenuWrapper className='bulk-user-management-menu'>
        <ObjectIsland
          actions={actions}
          onCloseRequest={onCloseRequest}
          selectedCount={selectedCount}
        />
      </BulkUserManagementMenuWrapper>
      <BulkUserManagementModalsFactory
        modal={modal}
        onCloseRequest={onCloseRequest}
        selectedUsersIds={selectedUsersIds}
        setBulkUserManagementModal={setModal}
      />
    </>
  );
};

export default BulkUserManagementMenu;
