import React from 'react';

import { useGroup } from '../../../../../contexts/GroupContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import { usePatchGroupCurriculumsMutation } from '../../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupSubject } from '../../../../../types/GroupSubject';
import ThreeDotMenu from '../../../../design_system/Triage/menus/ThreeDotMenu';
import { routes } from '../../../publicApplication/applicationRouter';

const t = initTranslations('groups.groups_edit.content.table.three_dot_actions');

type ActionMenuProps = {
  subject: GroupSubject;
};

const ActionMenu = ({ subject }: ActionMenuProps) => {
  const { id: groupId, name: groupName } = useGroup();
  const menuId: RegisteredMenuId = `group-content-subject-${subject.id}`;
  const { slug } = useCurrentAccount();
  const [unassignSubjectFromGroup, result] = usePatchGroupCurriculumsMutation();

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('remove_error', { groupName, subjectName: subject.title }),
  });

  return (
    <ThreeDotMenu
      id={menuId}
      menuOptions={[
        {
          title: t('edit_content'),
          onClick: () => {
            routes.curriculumEdit({ slug, id: subject.id }).push();
          },
          iconName: 'books',
          id: 'td-groups-edit-content-table-edit-subject',
          visible: true,
        },
        {
          title: t('remove_from_group'),
          onClick: () => {
            unassignSubjectFromGroup({
              groupId,
              added_curriculum_ids: [],
              removed_curriculum_ids: [Number(subject.id)],
            });
          },
          iconName: 'xmark-circle',
          id: 'td-groups-edit-content-table-remove-subject',
          visible: true,
        },
      ]}
    />
  );
};

export default ActionMenu;
