import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { ToggleContainer } from '../../../AdvancedSettings/styles';
import {
  FormSectionDescription,
  FormSectionTitle,
  ToggleInfoSection,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings');

const PublicShare = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <ToggleContainer>
        <Toggle
          checked={values.public_curriculums}
          className='public-curriculums-setting'
          handleToggle={() => setFieldValue('public_curriculums', !values.public_curriculums)}
          id='public-curriculums-toggle'
          name='public_curriculums'
        />
      </ToggleContainer>
      <ToggleInfoSection>
        <div>
          <FormSectionTitle>{t('public_share_title')}</FormSectionTitle>
          <FormSectionDescription>{t('public_share_description')}</FormSectionDescription>
        </div>
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default PublicShare;
