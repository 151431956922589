import React, { FC } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { StyledIcon, TooltipIconWrapper } from '../BoardTools/styles';
import DelegationPlannerHeader from '../DelegationPlannerHeader/DelegationPlannerHeader';
import BoardSelectionDropdown from '../dropdownMenus/BoardSelectionDropdown/BoardSelectionDropdown';
import BoardThreeDot from '../dropdownMenus/BoardThreeDot/BoardThreeDot';
import { HeaderActionsBlock, PageToolsContainer } from './styles';
import { PageToolsProps } from './types';

const t = initTranslations('delegation_planner');

const PageTools: FC<PageToolsProps> = ({ currentBoard, boards, setCurrentBoardIsDeleted }) => {
  return (
    <PageToolsContainer>
      <DelegationPlannerHeader />

      <HeaderActionsBlock>
        <BoardSelectionDropdown boards={boards} currentBoard={currentBoard} />
        {currentBoard && (
          <BoardThreeDot board={currentBoard} setCurrentBoardIsDeleted={setCurrentBoardIsDeleted} />
        )}
        <TooltipIconWrapper data-for='board-admin-access-tooltip' data-tip>
          <StyledIcon name='circle-info' />
        </TooltipIconWrapper>
        <Tooltip
          id='board-admin-access-tooltip'
          place='top'
          text={t('modals.board_manage_modal.board_admin_access_info')}
        />
      </HeaderActionsBlock>
    </PageToolsContainer>
  );
};

export default PageTools;
