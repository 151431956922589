import React from 'react';
import styled, { css } from 'styled-components';
import { Route } from 'type-route';

import { useGroup } from '../../../../../contexts/GroupContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon/Icon';
import { LinkTab } from '../../../../design_system/navigation/LinkTabs';
import { POPPABLE_Z_INDEX } from '../../../../Poppable';
import { FontSize, FontSizeSelected } from '../../../people/Slideouts/Shared/FontSize';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import RouterLink from '../../../publicApplication/RouterLink';
import GroupsTabs from '../../GroupsTabs/GroupsTabs';
import GroupHeader, { GroupHeaderProps } from './GroupHeader/GroupHeader';

const t = initTranslations('groups.groups_edit');

const HeaderContent = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${vars.foundationSurface1};
    border-bottom: ${constants.borderWidthXs} solid ${vars.borderSurface2};
    position: sticky;
    top: 0;
    z-index: ${POPPABLE_Z_INDEX + 1};
  `
);

const NameAndActions = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerSm3};
    margin: ${constants.spacerMd1} ${constants.spacerLg2};
  `
);

const Link = styled(RouterLink)<{ fontSize?: FontSize }>(
  ({ theme: { constants, vars } }) => css`
    color: ${vars.textDefault};
    display: flex;
    overflow: visible;
    max-width: fit-content;
    gap: ${constants.spacerSm3};
    font-weight: ${constants.fontMedium};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    ${FontSizeSelected};
  `
);

const IconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-self: center;
  height: 1rem;
  width: 1rem;
`;

export type GroupsStickyHeaderProps = GroupHeaderProps;

const GroupsStickyHeader = ({ threeDotOptions, buttonEnabled }: GroupsStickyHeaderProps) => {
  const { id: groupId } = useGroup();
  const { slug } = useCurrentAccount();
  const route = useRoute() as Route<typeof routes.group>;
  const breadcrumb = route.params.breadcrumb;
  const group_kind = new URLSearchParams(breadcrumb?.href.split('?')[1]).get('group_kind') || 'all';

  const contentTab: LinkTab = {
    name: t('tabs.content'),
    to: routes.group({
      breadcrumb,
      id: groupId,
      slug,
      tab: 'content',
    }),
    is_beta: false,
    tabId: 'group-edit-content-tab',
  };
  const overviewTab: LinkTab = {
    name: t('tabs.overview'),
    to: routes.group({
      breadcrumb,
      id: groupId,
      slug,
      tab: 'overview',
    }),
    is_beta: false,
    tabId: 'group-edit-overview-tab',
  };

  const tabs: LinkTab[] = [overviewTab, contentTab];

  return (
    <HeaderContent id='groups-sticky-header'>
      <NameAndActions>
        <Link fontSize='sm5' to={routes.groups({ slug, group_kind })}>
          <IconWrapper>
            <Icon name='arrow-left' weight='regular' />
          </IconWrapper>
          {t(`header.back.${group_kind}`)}
        </Link>
        <GroupHeader buttonEnabled={buttonEnabled} threeDotOptions={threeDotOptions} />
      </NameAndActions>
      <GroupsTabs id='group-edit-tabs' isUrlParams={false} tabs={tabs} />
    </HeaderContent>
  );
};

export default GroupsStickyHeader;
