import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import bulkAddTemplate from '../../../../../../../assets/bulk_add_template.txt';
import { AccountRoutesContext } from '../../../../../contexts/AccountRoutesContext';
import useAjaxWrite, { RequestMethod } from '../../../../../hooks/useAjaxWrite';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import useKeyPress from '../../../../../hooks/useKeyPress';
import initTranslations from '../../../../../lib/initTranslations';
import { useBulkUploadMutation } from '../../../../../redux/services/resourceApis/users/usersApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Icon from '../../../../design_system/display/icons/Icon';
import Loader from '../../../../design_system/Triage/Loader';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { ModalBodyContainer, ModalFooterContainer } from '../../../../styled/Modals';
import { fontMd1, fontMd2, fontMd3, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

const StyledModalBodyContainer = styled(ModalBodyContainer)`
  justify-content: space-between;
`;

const Steps = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const Step = styled.li`
  display: flex;
  &:first-of-type {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
  }
`;

const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  font-weight: ${({ theme: { constants } }) => constants.fontExtrabold};
  color: ${({ theme: { vars } }) => vars.textSurface};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm5};
`;

const StepDescription = styled.div`
  width: 100%;
`;

const StepDescriptionLink = styled.a`
  display: flex;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  text-decoration-line: underline;
  ${fontMd1};
`;

const StepDescriptionText = styled.div`
  margin: ${({ theme: { constants } }) => `${constants.spacerSm2} 0 ${constants.spacerMd2} 0`};
  ${fontMd1};
`;

const StepDescriptionSubtextLink = styled.a`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  text-decoration-line: underline;
`;

const StepDescriptionSubtext = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm4};
`;

const StyledFileAltIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd3};
`;

const StepDropzoneWrapper = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const StepDropzoneWrapperBorder = styled(StepDropzoneWrapper)<{ fileReady: boolean }>`
  border: ${({ theme: { constants, vars }, fileReady }) =>
    fileReady
      ? `${constants.borderWidthSm} dashed ${vars.borderDefault}`
      : `${constants.borderWidthSm} dashed ${vars.stateSuccess}`};
`;

const StepDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;

const StyledArrowToTopIcon = styled(Icon)`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd1};
`;

const StepDropzoneText = styled.p`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  ${fontMd1};
`;

const StepDropzoneLink = styled.a`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  text-decoration: underline !important;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  cursor: pointer;
  ${fontSm4};
`;

const StepDropzoneFileAltIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd2};
`;

const StepDropzoneFileName = styled.p`
  color: ${({ theme: { vars } }) => vars.stateSuccess};
  margin: ${({ theme: { constants } }) => constants.spacerMd2} 0 0 0;
  text-align: center;
  ${fontMd1};
`;

const StyleModalFooterContainer = styled(ModalFooterContainer)`
  width: 100%;
`;

const StyledImportButton = styled(DefaultButton)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-left: ${({ theme: { constants } }) => constants.spacerMd1};
    margin-top: 0;
  }
`;

const StyledPreviousButton = styled(DefaultButton)`
  margin-right: auto;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-bottom: 0;
    width: auto;
  }
`;

const StyledStepDescriptionLink = styled(StepDescriptionLink)`
  &.hide {
    display: none;
  }
`;

const StyledLoader = styled.div`
  display: none;
  div {
    justify-content: flex-start;
  }
  &.show {
    display: flex;
  }
`;

const t = initTranslations('bulk_user_upload_modal.import_teammates');

type Props = {
  closeModal: () => void;
  renderBeforeYouGetStartedContent: () => void;
  renderResultContent: (e: string) => void;
  renderRanOutOfSeats: () => void;
  setUploadFileData: (e: object) => void;
  setData: (e: object) => void;
};

const ImportTeammates = ({
  closeModal,
  renderBeforeYouGetStartedContent,
  renderRanOutOfSeats,
  renderResultContent,
  setData,
  setUploadFileData,
}: Props) => {
  const [bulkUpload, result] = useBulkUploadMutation();
  const { isLoading, data: bulkUploadData } = result;
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: '.csv, .xlsx',
    onDropAccepted: () => setFileReady(true),
  });
  const [fileReady, setFileReady] = useState(false);
  const routes = useContext(AccountRoutesContext);

  const files = acceptedFiles.map((file, index) => (
    <StepDropzoneFileName key={index}>
      <StepDropzoneFileAltIcon name='file-alt' weight='regular' />
      {file.name}
    </StepDropzoneFileName>
  ));

  const formData = new FormData();
  formData.append('file', acceptedFiles[0]);

  useDisplayFlashOnResponse({
    result,
    successFunction: () => {
      if (bulkUploadData) {
        setData(bulkUploadData);
        setUploadFileData(formData);
        if (bulkUploadData.success.need_to_update_plan) {
          renderRanOutOfSeats();
        } else {
          renderResultContent(bulkUploadData.import_status);
        }
      }
    },
    errorFunction: () => {
      setFileReady(false);
      acceptedFiles.splice(0, acceptedFiles.length);
    },
    errorMessage: t('error_message'),
  });

  const canSubmitUploadFile = () => {
    fileReady && bulkUpload(formData);
  };

  useKeyPress('Enter', canSubmitUploadFile);

  const xlsxTemplateFilePath = useAjaxWrite<{ link: string }>({
    requestMethod: RequestMethod.Get,
    path: routes.ajax.bulkUploadResultFiles({
      file_format: 'xlsx',
      result_type: 'bulk_add_template',
    }),
    dataItem: {},
  });

  const getXlsxTemplateFilePath = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    const templateLink = document.getElementById('bulk-add-template-link');
    const templateLoader = document.getElementById('bulk-add-template-loader');

    if (templateLink && templateLoader) {
      templateLink.classList.add('hide');
      templateLoader.classList.add('show');

      const result = await xlsxTemplateFilePath();

      if (result.status === 'success') {
        window.location.href = result.data.link;
        templateLink.classList.remove('hide');
        templateLoader.classList.remove('show');
      }

      if (result.status === 'error') {
        templateLink.classList.remove('hide');
        templateLoader.classList.remove('show');
      }
    }
  };

  return (
    <>
      <StyledModalBodyContainer>
        <Steps>
          <Step>
            <StepNumber>1</StepNumber>
            <StepDescription>
              <StepDescriptionText>{t('download_and_fill_template')}</StepDescriptionText>
              <StyledStepDescriptionLink
                href='#'
                id='bulk-add-template-link'
                onClick={(e) => getXlsxTemplateFilePath(e)}
              >
                <StyledFileAltIcon name='file-alt' weight='regular' />
                {t('bulk_add_template')}
              </StyledStepDescriptionLink>
              <StyledLoader id='bulk-add-template-loader'>
                <Loader />
              </StyledLoader>
              <StepDescriptionSubtext>
                {t('or')}
                <StepDescriptionSubtextLink download='bulk_add_template.csv' href={bulkAddTemplate}>
                  {t('download_as_csv')}
                </StepDescriptionSubtextLink>
                .
              </StepDescriptionSubtext>
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>2</StepNumber>
            <StepDescription>
              <StepDescriptionText>{t('upload_file')}</StepDescriptionText>
              <StepDescriptionText>{t('accepted_file_types')}</StepDescriptionText>
              <StepDropzoneWrapperBorder fileReady={fileReady}>
                <StepDropzone {...getRootProps({ className: 'dropzone' })}>
                  <input className='dz-hidden-input' {...getInputProps()} />
                  <StyledArrowToTopIcon name='arrow-to-top' weight='regular' />
                  {!fileReady && <StepDropzoneText>{t('drop_file_here')}</StepDropzoneText>}
                  <StepDropzoneLink onClick={open}>
                    {fileReady ? t('select_different_file') : t('click_here_to_select_file')}
                  </StepDropzoneLink>
                </StepDropzone>
                <aside>{fileReady && files}</aside>
              </StepDropzoneWrapperBorder>
            </StepDescription>
          </Step>
        </Steps>
      </StyledModalBodyContainer>
      <StyleModalFooterContainer>
        <StyledPreviousButton
          buttonType='tertiary'
          id='import-teammates-previous-step'
          onClick={renderBeforeYouGetStartedContent}
          text={t('go_back')}
        />
        <DefaultButton
          buttonType='secondary'
          id='import-teammates-close'
          onClick={closeModal}
          text={t('cancel')}
        />
        <StyledImportButton
          buttonType='primary'
          disabled={!fileReady}
          id='submit-import-teammates'
          loading={isLoading}
          onClick={() => bulkUpload(formData)}
          text={t('import')}
        />
      </StyleModalFooterContainer>
    </>
  );
};

export default ImportTeammates;
