import styled, { css } from 'styled-components';

import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { HighlightedBlock } from '../../billing/shared/BillingPage/shared/styles';

export const DiscountsSectionWrapper = styled(HighlightedBlock)<{
  isManagePlanOverlayContent: boolean;
}>`
  ${({ theme: { constants, vars }, isManagePlanOverlayContent }) =>
    isManagePlanOverlayContent
      ? css`
          display: flex;
          align-items: center;
          padding: ${constants.spacerSm3} calc(${constants.spacerMd2} + ${constants.spacerSm2});
          margin: 0;
          background-color: ${vars.anIcecreamShopClosesEverytimeYouUseThisColor};
          border: ${constants.borderRadiusXs} solid ${vars.youWillMakeMattCryIfYouUseThis};
        `
      : css`
          padding: ${constants.spacerSm2};
          margin: ${constants.spacerMd2} 0;
        `}
`;

export const DiscountsImage = styled.img<{
  isManagePlanOverlayContent: boolean;
}>`
  ${({ theme: { vars }, isManagePlanOverlayContent }) =>
    isManagePlanOverlayContent
      ? css`
          width: 2.375rem;
          height: 2.625rem;
        `
      : css`
          width: 1.875rem;
          height: 1.875rem;
          background: ${vars.foundationSurface1};
        `}
`;

export const DiscountsListWrapper = styled.ul<{
  isManagePlanOverlayContent: boolean;
}>`
  ${({ theme: { constants }, isManagePlanOverlayContent }) =>
    css`
      display: grid;
      align-items: center;
      margin: 0;
      padding: 0 0 0 ${isManagePlanOverlayContent ? constants.spacerMd2 : constants.spacerSm3};
      li {
        list-style-type: none;
        width: 100%;
        &:nth-of-type(3n) {
          margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
        }
        ${TruncatedText({})};
      }
      li:before {
        content: '•';
        padding-right: ${constants.spacerSm2};
      }
    `}
`;

export const DiscountsCouponCode = styled.li`
  padding-left: ${({ theme: { constants } }) => constants.spacerSm2};
  &:before {
    content: '' !important;
  }
  ${fontSm4};
`;

export const DiscountDetails = styled.li`
  ${fontSm5};
`;
