import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import useCurrentUser from '../../../../../../../../hooks/useCurrentUser';
import initTranslations from '../../../../../../../../lib/initTranslations';
import { CurriculumOwner } from '../../../../../../../../types';
import Avatar from '../../../../../../../design_system/display/avatar';
import { TruncatedText } from '../../../../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../../../../styled/TypeSystem';

const t = initTranslations('curriculums.owner');

export type Props = {
  owner: CurriculumOwner | null;
};

const StyledDiv = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    gap: ${constants.spacerSm2};
    align-items: center;
    justify-content: center;
    background: ${vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusEndcap};
    padding: 0.375rem ${constants.spacerMd1};
    outline: none;
    max-width: 20rem;
    color: ${vars.textDefault};
    height: ${constants.heightMd};

    ${fontSm5};
  `};
`;

const TruncateSpan = styled.span`
  pointer-events: none;
  ${TruncatedText({})};
`;

const NoShrinkSpan = styled.span`
  display: flex;
  align-items: center;
  pointer-events: none;
  flex-shrink: 0;
`;
const OwnedByViewOnly = ({ owner }: Props) => {
  const { id } = useCurrentUser();

  const ownerText = useMemo(() => {
    const isCurrentUserOwner = owner?.id === id;

    if (isCurrentUserOwner) {
      return t('owned_by_you');
    } else if (!owner) {
      return t('no_owner');
    } else {
      return t('owned_by', { owner: owner.name });
    }
  }, [id, owner]);

  const avatar = owner ? (
    <Avatar image={owner.avatar} name={owner.name} shape='circle' size='xs' />
  ) : (
    <Avatar icon='user' name='' shape='circle' size='xs' />
  );

  return (
    <StyledDiv>
      <NoShrinkSpan>{avatar}</NoShrinkSpan>
      <TruncateSpan>{ownerText}</TruncateSpan>
    </StyledDiv>
  );
};

export default OwnedByViewOnly;
