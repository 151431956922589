import { truncate } from 'lodash';
import React from 'react';

import { Emoji } from '../../../../../types/Emoji';
import Icon from '../../../../design_system/display/icons/Icon';

type Props = {
  emoji: Emoji;
  title: string;
  truncateLength?: number;
};

const getCurrentPageText = ({ emoji, title, truncateLength = 30 }: Props) => {
  return emoji ? (
    `${emoji} ${truncate(title, { length: truncateLength })}`
  ) : (
    <>
      <span>
        <Icon name='file-lines' size='xs' weight='regular' />
      </span>{' '}
      <span>{truncate(title, { length: truncateLength })}</span>
    </>
  );
};

export default getCurrentPageText;
