import React from 'react';

import { useGetTrainingPathQuery } from '../../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import { useGetUserPrimaryDetailsQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import ProgressItem from '../../../shared/ProgressItem/ProgressItem';
import { getSetStatus } from '../../shared';
import Divider from './Divider/Divider';
import OverviewSet from './OverviewSet/OverviewSet';

export type OverviewSetsProps = {
  userId: number;
};

const OverviewSets = ({ userId }: OverviewSetsProps) => {
  const { data } = useGetTrainingPathQuery({ userId });
  const { data: user } = useGetUserPrimaryDetailsQuery(userId);

  if (!data || !user) {
    return null;
  }

  const { trainingPathSets } = data;
  const firstIncompleteSet = trainingPathSets.find((set) => set.completionPercentage < 100);

  return (
    <div id='training-path-overview-sets-wrapper'>
      {trainingPathSets.map((set, index) => {
        const setStatus = getSetStatus(set, firstIncompleteSet);

        return (
          <div key={`overview-set-${set.trainingPathSetUuid}`}>
            <ProgressItem
              avatar={user.avatar}
              completionStatus={setStatus}
              hasLine={trainingPathSets.length - 1 !== index}
              name={user.name}
            >
              <OverviewSet set={set} setStatus={setStatus} user={user} />
              {index < trainingPathSets.length - 1 && <Divider />}
            </ProgressItem>
          </div>
        );
      })}
    </div>
  );
};

export default OverviewSets;
