import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Checkbox from '../../../../../design_system/input/controls/Checkbox';
import { NameCellWrapper, StyledNameHeader } from '../styles';

const t = initTranslations('curriculum_edit.manage_completions.table');

interface Props {
  handleBulkCheckboxChange: () => void;
  bulkChecked: boolean;
}

const NameHeader = ({ bulkChecked, handleBulkCheckboxChange }: Props) => {
  return (
    <StyledNameHeader className='manage-completions-name-header'>
      <NameCellWrapper>
        <Checkbox
          checkboxStyle='half'
          checked={bulkChecked}
          id='manage-completions-name-header-checkbox'
          name='name-header-checkbox'
          onCheck={handleBulkCheckboxChange}
        />
      </NameCellWrapper>
      {t('name_header')}
    </StyledNameHeader>
  );
};

export default NameHeader;
