import React from 'react';
import styled from 'styled-components';

import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { CurriculumOwner } from '../../../../../types';
import { Sector } from '../../../../../types/Sector';
import CurriculumThreeDot from '../../../curriculums/shared/CurriculumsTable/CurriculumThreeDot/CurriculumThreeDot';
import GroupBadge from '../../components/GroupBadge/GroupBadge';
import OwnerAvatar from '../../components/OwnerAvatar/OwnerAvatar';
import SectorLabel from '../../components/SectorLabel/SectorLabel';

const Spacer = styled.div`
  width: 2rem;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

type Props = {
  archived: boolean;
  canEdit: boolean;
  description: string | undefined;
  groupsCount: number;
  id: number;
  isLocked: boolean;
  owner: CurriculumOwner | null;
  renameClickHandler: () => void;
  sector: Sector;
  title: string;
};

const LibraryRowRightSide = ({
  archived,
  canEdit,
  description,
  groupsCount,
  id,
  isLocked,
  renameClickHandler,
  owner,
  sector,
  title,
}: Props) => {
  const ability = useCurrentUserAbilities();

  return (
    <RightSide className='library-row-right-side'>
      <SectorLabel sector={sector} />
      <GroupBadge
        canAssignUsers={ability.can('update', `ManageCurriculum-${id}`)}
        groupsCount={groupsCount}
        id={id}
      />
      <OwnerAvatar
        canEditOwner={ability.can('update', `ManageCurriculum-${id}`)}
        curriculumId={id}
        owner={owner}
      />
      {canEdit ? (
        <CurriculumThreeDot
          archived={archived}
          curriculumId={id}
          description={description}
          isLocked={isLocked}
          renameClickHandler={renameClickHandler}
          title={title}
        />
      ) : (
        <Spacer />
      )}
    </RightSide>
  );
};

export default LibraryRowRightSide;
