import React from 'react';
import { CustomNodeElementProps } from 'react-d3-tree/lib/types/common';
import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import FlexContainer from '../../../styled/FlexContainer';
import { fontSm4 } from '../../../styled/TypeSystem';

const StyledChildNode = styled(FlexContainer)`
  background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  color: ${({ theme: { vars } }) => vars.textSurface};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  width: ${({ theme: { constants } }) => constants.heightLg};
  height: ${({ theme: { constants } }) => constants.heightLg};
  margin-top: calc(-${({ theme: { constants } }) => constants.heightLg} / 5);
  ${fontSm4};
`;

const IconWrapper = styled(FlexContainer)`
  padding-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;

interface Props {
  node: CustomNodeElementProps;
  toggleNode: () => void;
  descendantsCount: number;
}

const OrgChartButton = ({ node, toggleNode, descendantsCount }: Props) => {
  const children = node.hierarchyPointNode.data.children;

  if (!children?.length || descendantsCount === 0) return null;

  const collapsed = node.nodeDatum.__rd3t.collapsed;
  const iconName = collapsed ? 'angle-down' : 'angle-up';

  return (
    <StyledChildNode align='center' justifyContent='center' onClick={toggleNode}>
      {collapsed ? descendantsCount : children.length}
      <IconWrapper>
        <Icon name={iconName} size='xs' weight='solid' />
      </IconWrapper>
    </StyledChildNode>
  );
};

export default OrgChartButton;
