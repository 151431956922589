import { useMemo } from 'react';

import { ConsumptionStep } from '../../../../types/Editor';
import { LimitedStep } from '../../../../types/Step';

export const useStepRange = (
  steps: ConsumptionStep[] | LimitedStep[],
  currentStepId: number
): {
  isLastStep: boolean;
  isFirstStep: boolean;
  currentStepIndex: number;
  stepPosition: number;
} => {
  return useMemo(() => {
    const currentStepIndex = steps.findIndex(({ id }) => id === currentStepId);

    const isFirstStep = currentStepIndex === 0;
    const isLastStep = currentStepIndex === steps.length - 1;
    const stepPosition = currentStepIndex + 1;

    return { isFirstStep, isLastStep, currentStepIndex, stepPosition };
  }, [steps, currentStepId]);
};
