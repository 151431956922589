import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { ToggleContainer } from '../../../AdvancedSettings/styles';
import { FormSectionDescription, FormSectionTitle, ToggleSettingContainer } from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.people_settings');

const DisplayPhoneNumbersToggle = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <ToggleContainer>
        <Toggle
          checked={values.enable_profile_phone_number}
          className='enable-profile-phone-number-setting'
          handleToggle={() =>
            setFieldValue('enable_profile_phone_number', !values.enable_profile_phone_number)
          }
          id='enable-profile-phone-number'
          name='enable_profile_phone_number'
        />
      </ToggleContainer>
      <div>
        <FormSectionTitle>{t('enable_profile_phone_number_title')}</FormSectionTitle>
        <FormSectionDescription>
          {t('enable_profile_phone_number_description')}
        </FormSectionDescription>
      </div>
    </ToggleSettingContainer>
  );
};

export default DisplayPhoneNumbersToggle;
