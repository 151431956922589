import { DropdownMenuOption } from '../components/design_system/Triage/menus/DropdownMenu';
import initTranslations from '../lib/initTranslations';

const t = initTranslations('sort_dropdown');

const useUserSortDropdownOptions = () => {
  const options: DropdownMenuOption[] = [
    {
      label: t('name_asc'),
      sortColumn: 'name',
      sortDirection: 'asc',
      useInGroups: true,
    },
    {
      label: t('name_desc'),
      sortColumn: 'name',
      sortDirection: 'desc',
      useInGroups: true,
    },
    {
      label: t('title_asc'),
      sortColumn: 'title',
      sortDirection: 'asc',
      useInGroups: false,
    },
    {
      label: t('title_desc'),
      sortColumn: 'title',
      sortDirection: 'desc',
      useInGroups: false,
    },
    {
      label: t('newest'),
      sortColumn: 'created_at',
      sortDirection: 'desc',
      useInGroups: false,
    },
    {
      label: t('oldest'),
      sortColumn: 'created_at',
      sortDirection: 'asc',
      useInGroups: false,
    },
  ];

  return {
    options,
  };
};

export default useUserSortDropdownOptions;
