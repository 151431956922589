import * as yup from 'yup';
import { AnySchema, ValidationError } from 'yup';

import initTranslations from '../initTranslations';
import { PASSWORD_MAX_CHARACTERS, PASSWORD_MIN_CHARACTERS } from './password';

const t = initTranslations('public_application.password_validation_indicator');

type ErrorMessage = string | string[] | undefined;

type ErrorsObject<T> = {
  [K in keyof T]?: ErrorMessage;
};

type MultiErrorPasswordErrorsData = {
  identifier: string;
  message: string;
};

export type MultiErrorPasswordErrorIdentifiersType = typeof multiErrorPasswordErrorsData;

export const multiErrorPasswordErrorsData: Record<string, MultiErrorPasswordErrorsData> = {
  tooShort: {
    identifier: 'too_short',
    message: t('too_short', { count: PASSWORD_MIN_CHARACTERS }),
  },
  missingNumber: {
    identifier: 'missing_number',
    message: t('missing_number'),
  },
  missingSymbol: {
    identifier: 'missing_symbol',
    message: t('missing_symbol'),
  },
  missingUppercase: {
    identifier: 'missing_uppercase',
    message: t('missing_uppercase'),
  },
  missingLowercase: {
    identifier: 'missing_lowercase',
    message: t('missing_lowercase'),
  },
  tooLong: {
    identifier: 'too_long',
    message: t('too_long', { count: PASSWORD_MAX_CHARACTERS }),
  },
};

const {
  tooShort: { identifier: tooShortIdentifier },
  tooLong: { identifier: tooLongIdentifier },
  missingNumber: { identifier: missingNumberIdentifier },
  missingUppercase: { identifier: missingUppercaseIdentifier },
  missingLowercase: { identifier: missingLowercaseIdentifier },
  missingSymbol: { identifier: missingSymbolIdentifier },
} = multiErrorPasswordErrorsData;

export const multiErrorPasswordSchema = yup
  .string()
  .required('blank')
  .min(PASSWORD_MIN_CHARACTERS, tooShortIdentifier)
  .max(PASSWORD_MAX_CHARACTERS, tooLongIdentifier)
  .matches(/.*[0-9].*/, missingNumberIdentifier)
  .matches(/.*[A-Z].*/, missingUppercaseIdentifier)
  .matches(/.*[a-z].*/, missingLowercaseIdentifier)
  .matches(/.*[-_+=\\;[\]'!@#$%^&*(),.?":{}|<>].*/, missingSymbolIdentifier);

export const multiErrorValidatorForSchema = <T>(
  schema: AnySchema,
  fieldsWithMultipleErrors: (keyof T)[]
) => {
  return (values: T): Promise<ErrorsObject<T>> =>
    schema
      .validate(values, {
        abortEarly: false,
        strict: false,
      })
      .then(() => ({}))
      .catch(({ inner }: { inner: ValidationError[] }) =>
        inner.reduce((errorObject: ErrorsObject<T>, { path, message }) => {
          const errorPath = path as keyof T;

          if (fieldsWithMultipleErrors.includes(errorPath)) {
            const currentErrors: ErrorMessage = errorObject[errorPath];

            if (!!currentErrors) {
              errorObject[errorPath] = [...currentErrors, message];
            } else {
              errorObject[errorPath] = [message];
            }
          } else if (!errorObject[errorPath]) {
            errorObject[errorPath] = message;
          }

          return errorObject;
        }, {})
      );
};
