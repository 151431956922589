import React from 'react';
import styled from 'styled-components';

import { useConsumptionCourseContext } from '../../../../contexts/ConsumptionCourseContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { routes } from '../../publicApplication/applicationRouter';
import GoBackIconButton from '../components/GoBackIconButton/GoBackIconButton';
import { SharedToolbarStyles } from '../shared/styles';

export const CONSUMPTION_TOOLBAR_HEIGHT = '4rem';

export const ToolbarWrapper = styled.div`
  ${SharedToolbarStyles};

  height: ${CONSUMPTION_TOOLBAR_HEIGHT};
  align-items: center;
`;

const ImageContainer = styled.div`
  display: flex;
  margin: auto;
  height: 100%;
  align-items: center;
`;

const Image = styled.img`
  height: 80%;
`;

const GoBackButtonWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('editor_toolbar');
const ToolbarContainer = () => {
  const {
    curriculum: { id },
  } = useConsumptionCourseContext();
  const { slug, logoUrl } = useCurrentAccount();

  return (
    <ToolbarWrapper>
      <ImageContainer>{logoUrl && <Image alt={t('alt_text_logo')} src={logoUrl} />}</ImageContainer>
      <GoBackButtonWrapper>
        <GoBackIconButton onClick={() => routes.curriculumShow({ slug, id }).push()} />
      </GoBackButtonWrapper>
    </ToolbarWrapper>
  );
};

export default ToolbarContainer;
