import styled from 'styled-components';

import { Coords, Size } from './types';

export const DynamicOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  z-index: 1000;
`;

export const DynamicOverlayContent = styled.div<{ position: Coords; size?: Size }>`
  position: absolute;
  top: ${({ position }) => position.y}px;
  left: ${({ position }) => position.x}px;
  width: ${({ size }) => size?.width}px;
  height: ${({ size }) => size?.height}px;
`;
