import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { DefaultHeaderStyled } from '../../../people/UsersOutline/Table/Styles';
import { StyledHeaderCell } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { StyledSortIcon } from '../../Styles';
import { TableHeaderCellProps } from './types';

const t = initTranslations('reports.user_assignment_report_table.headers');

const UserAssignmentReportTableHeaderCell = ({
  column: { columnName, stickyPosition = 'center' },
  activeHeader,
  setActiveHeader,
  sortIcon,
  isSortable,
}: TableHeaderCellProps) => {
  return (
    <DefaultHeaderStyled>
      <StyledHeaderCell
        active={activeHeader}
        className={isSortable ? `sortable-column ${columnName}` : undefined}
        isSortable={isSortable}
        onClick={() => setActiveHeader && setActiveHeader()}
        stickyPosition={stickyPosition}
      >
        {t(`${columnName}`)}
        {sortIcon && <StyledSortIcon name={sortIcon} size='2xs' weight='solid' />}
      </StyledHeaderCell>
    </DefaultHeaderStyled>
  );
};

export default UserAssignmentReportTableHeaderCell;
