import React, { useEffect, useMemo, useState } from 'react';

import { useGetSearchResultsFilters } from '../../../../../hooks/search/useGetSearchResultsFilters';
import initTranslations from '../../../../../lib/initTranslations';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import Flyout from '../../../../design_system/overlays/flyout/Flyout';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import SearchResultsFilterButton from './SearchResultsFilterButton';
import { FilterList, FilterName, SearchResultsFilterProps } from './types';

const t = initTranslations('search_results_page.filter');

const menuId = 'search-results-filter-flyout';

const SearchResultsFilter = ({
  filtersCount,
  appliedFilters,
  setAppliedFilters,
}: SearchResultsFilterProps) => {
  const [filters, setFilters] = useState<FilterName[]>(appliedFilters);
  const { closeMenu } = useActiveMenuHandler({ menuId });
  const filterList = useGetSearchResultsFilters();
  const emptyInitialFilters = appliedFilters.length === 0;

  const toggleFilterCheckbox = (name: FilterName) => {
    setFilters((filters) =>
      filters.includes(name)
        ? filters.filter((item: FilterName) => item !== name)
        : [...filters, name]
    );
  };

  const clearFilter = () => {
    closeMenu();
    setFilters([]);
    setAppliedFilters([]);
  };

  const applyFilter = () => {
    closeMenu();
    setAppliedFilters(filters);
  };

  useEffect(() => {
    emptyInitialFilters && setFilters([]);
  }, [emptyInitialFilters]);

  const filterCheckboxes = useMemo(
    () =>
      Object.entries(filtersCount)
        .filter(([key]) => filterList[key as keyof FilterList])
        .map(([key, value]) => ({
          count: value,
          ...filterList[key as keyof FilterList],
        }))
        .sort((a, b) => a.order - b.order),
    [filtersCount, filterList]
  );

  return (
    <Flyout
      className={menuId}
      id={menuId}
      primaryButtonTask={applyFilter}
      primaryButtonText={t('apply')}
      secondaryButtonTask={clearFilter}
      secondaryButtonText={t('clear')}
      title={t('result_type')}
      triggerButton={<SearchResultsFilterButton />}
    >
      {filterCheckboxes.map(({ count, name, title }) => (
        <Checkbox
          checked={filters.includes(name)}
          id={name}
          key={name}
          label={`${title} (${count})`}
          name={name}
          onCheck={() => toggleFilterCheckbox(name)}
        />
      ))}
    </Flyout>
  );
};

export default SearchResultsFilter;
