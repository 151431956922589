import styled, { css } from 'styled-components';

import { TruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { fontMd2 } from '../../../../../styled/TypeSystem';

const sharedFlexProperties = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ResourcesUl = styled.ul`
  padding: 0;
  width: 100%;
`;

export const StyledResourceListItem = styled.li`
  ${sharedFlexProperties};
  min-height: 60px;
`;

export const ResourceName = styled.h4`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${TruncatedText({ noWrap: 'normal' })};
  ${fontMd2};
`;

export const SelectWrapper = styled.div`
  width: 100%;
`;
