import React from 'react';

import { useAnnualPercentageOfSavings } from '../../../../../../hooks/billing/useAnnualPercentageOfSavings';
import DoubleSpinArrowImage from '../../../../../../images/billing/double_spin_arrow.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import { BillingPlanName } from '../../../../../../types/BillingPlanName';
import { AnnualSavingsBadge, AnnualSavingsWrapper } from './styles';

const t = initTranslations('per_user_pricing.full_screen_overlay_content.annual_savings');

type AnnualSavingsProps = {
  newPlanName: BillingPlanName;
};

const AnnualSavings = ({ newPlanName }: AnnualSavingsProps) => {
  const percent = useAnnualPercentageOfSavings(newPlanName);

  return (
    <AnnualSavingsWrapper>
      <img alt={t('image_alt')} src={DoubleSpinArrowImage} />
      <AnnualSavingsBadge text={t('badge', { percent })} type='general' />
    </AnnualSavingsWrapper>
  );
};

export default AnnualSavings;
