import React from 'react';

import StatusChanged, { StatusChangedProps } from '../../activities/StatusChanged';
import { Activity, CurriculumElementTrackable } from '../../activities/types';
import CommonTemplate, { SurveyCommonTemplateProps } from './CommonTemplate';
import GeneratedWithAi, { GeneratedWithAiProps } from './GeneratedWithAi';

interface SurveyActivitiesProps {
  'survey_survey.create': React.FC<SurveyCommonTemplateProps>;
  'survey_survey.update': React.FC<SurveyCommonTemplateProps>;
  'survey_survey.status_changed': React.FC<StatusChangedProps>;
  'survey_survey.generated_with_ai': React.FC<GeneratedWithAiProps>;
}

const ACTIVITY_KEYS: SurveyActivitiesProps = {
  'survey_survey.create': CommonTemplate,
  'survey_survey.update': CommonTemplate,
  'survey_survey.status_changed': StatusChanged,
  'survey_survey.generated_with_ai': GeneratedWithAi,
};

interface SurveysActivity extends Omit<Activity, 'trackable'> {
  trackable: CurriculumElementTrackable;
  parameters: { status_was: string; status_now: string };
}

interface SurveyCardProps {
  activity: SurveysActivity;
}

const SurveyNotificationsDetails = ({ activity }: SurveyCardProps) => {
  const NotificationMessage = ACTIVITY_KEYS[activity.key as keyof SurveyActivitiesProps];

  return (
    <NotificationMessage
      activityKey={activity.key}
      owner={activity.owner}
      parameters={activity.parameters}
      trackable={activity.trackable}
    />
  );
};

export default SurveyNotificationsDetails;
