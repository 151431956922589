import React from 'react';
import Lottie from 'react-lottie';

import initTranslations from '../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import CoreModal from '../../../../design_system/core/CoreModal';
import DelegationCelebratoryData from './delegation_celebratory_data.json';
import { ControlButtonsGroup, ModalContentWrapper, TextWrapper, Title } from './styles';

const t = initTranslations('delegation_planner.modals.celebratory_modal');

const CelebratoryModal = () => {
  const dispatchShowModal = useDispatchSetShowModal();

  const handleCloseRequest = () => {
    dispatchShowModal('delegationCelebratoryModal', false);
  };

  const handleAddNewBoard = () => {
    handleCloseRequest();
    dispatchShowModal('delegationBoardManageModal', true);
  };

  return (
    <CoreModal
      closeIconAriaLabel={t('aria_label_cancel')}
      desktopSize='md'
      disabledPadding={{ horizontal: { all: true }, vertical: { all: true } }}
      heapModalName='delegation-planner-celebratory-modal'
      isStaticModal
      onCloseRequest={handleCloseRequest}
    >
      <ModalContentWrapper className='chromatic-ignore'>
        <Lottie
          options={{ loop: true, autoplay: true, animationData: DelegationCelebratoryData }}
        />
        <TextWrapper>
          <Title>{t('title')}</Title>
          {t('subtitle')}
        </TextWrapper>
      </ModalContentWrapper>
      <ControlButtonsGroup>
        <DefaultButton
          buttonType='tertiary'
          fullWidth
          id='delegation-planner-celebratory-modal-close-button'
          onClick={handleCloseRequest}
          text={t('close_modal')}
        />
        <DefaultButton
          fullWidth
          id='delegation-planner-celebratory-modal-create-board-button'
          onClick={handleAddNewBoard}
          text={t('create_board')}
        />
      </ControlButtonsGroup>
    </CoreModal>
  );
};

export default CelebratoryModal;
