import styled from 'styled-components';

import Surface from '../../../../design_system/Triage/layout/Surface';
import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';

export const SearchResultsLoaderItem = styled(Surface)`
  margin: ${({ theme: { constants } }) => constants.spacerMd2} 0;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  display: flex;
  align-items: center;
`;

export const SearchResultsLoaderRowWrapper = styled.div`
  width: calc(100% - 3rem);
`;

export const SearchResultsLoaderRow = styled.div`
  height: 1.25rem;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  &:nth-of-type(1n) {
    max-width: 50%;
  }
  &:nth-of-type(2n) {
    max-width: 75%;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SearchResultsLoaderIconSkeleton = styled(SkeletonLoader)`
  background: ${({ theme: { vars } }) => vars.foundationBase2};
  height: ${({ theme: { constants } }) => constants.heightLg};
  width: 2.5rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const SearchResultsLoaderSkeleton = styled(SkeletonLoader)`
  background: ${({ theme: { vars } }) => vars.foundationBase2};
  height: 100%;
`;
