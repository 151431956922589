import { Active, DndContext, DragOverlay, Over, rectIntersection } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import {
  setCurrentDragArgs,
  setTargetColumnName,
  setTempColumns,
} from '../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useDispatchSetShowModal } from '../../../../redux/domains/modalsSlice/modalsSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useUpdateBoardColumnResponsibilityPositionMutation } from '../../../../redux/services/resourceApis/boardColumnResponsibilities/boardColumnResponsibilitiesApi';
import {
  useMoveBoardColumnResponsibilityMutation,
  useUpdateBoardColumnPositionMutation,
} from '../../../../redux/services/resourceApis/boardColumns/boardColumnsApi';
import useLocalStorageState from '../../publicApplication/utils/useLocalStorageState';
import useAutoSaveStatusUpdater from '../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import { useCurrentBoardId } from '../Board/hooks/useCurrentBoardId';
import { BoardContainer } from '../Board/styles';
import BoardColumn from '../BoardColumn/BoardColumn';
import {
  BoardColumn as TBoardColumn,
  TCurrentBoardColumnResponsibility,
} from '../BoardColumn/types';
import ResponsibilityDraggableComponent from '../ResponsibilityDraggableComponent/ResponsibilityDraggableComponent';
import { setGrabCursor } from '../shared/utils/setGrabCursor';
import { modifiers } from './_data';
import { useBoardSensors } from './hooks/useBoardSensors';
import { BoardTrackProps, EDragElements, HandleDragEndProps } from './types';
import { getSourceData, handleDragOver, handleDragStart } from './utils';

const BoardTrack: FC<BoardTrackProps> = ({ boardColumns, setBoardTrackElement }) => {
  const currentBoardId = useCurrentBoardId();
  const { id: accountId } = useCurrentAccount();

  const dispatch = useAppDispatch();
  const dispatchShowModal = useDispatchSetShowModal();
  const sensors = useBoardSensors();

  const tempColumns = useAppSelector((state) => state.delegationPlanner.tempColumns);

  const [activeColumn, setActiveColumn] = useState<TBoardColumn | null>(null);
  const [activeResponsibility, setActiveResponsibility] =
    useState<TCurrentBoardColumnResponsibility | null>(null);
  const [holderHeight, setHolderHeight] = useState<number>(0);

  const [updateBoardColumnPosition, updateBoardColumnPositionResult] =
    useUpdateBoardColumnPositionMutation({});

  const { isLoading: isPositionUpdating, isSuccess: isPositionUpdatedSuccessfully } =
    updateBoardColumnPositionResult;

  const [updateBoardColumnResponsibilityPosition, updateBoardColumnResponsibilityPositionResult] =
    useUpdateBoardColumnResponsibilityPositionMutation({});

  const {
    isLoading: isBoardColumnResponsibilityPositionUpdating,
    isSuccess: isBoardColumnResponsibilityPositionUpdatedSuccessfully,
  } = updateBoardColumnResponsibilityPositionResult;

  const [moveBoardColumnResponsibility, moveBoardColumnResponsibilityResult] =
    useMoveBoardColumnResponsibilityMutation({});

  const {
    isLoading: isResponsibilityMoving,
    isSuccess: isResponsibilityMovedSuccessfully,
    isError: isResponsibilityMoveError,
  } = moveBoardColumnResponsibilityResult;

  useAutoSaveStatusUpdater([
    { isSaving: isResponsibilityMoving, isSavedSuccessfully: isResponsibilityMovedSuccessfully },
    { isSaving: isPositionUpdating, isSavedSuccessfully: isPositionUpdatedSuccessfully },
    {
      isSaving: isBoardColumnResponsibilityPositionUpdating,
      isSavedSuccessfully: isBoardColumnResponsibilityPositionUpdatedSuccessfully,
    },
  ]);

  const [isMoveResponsibilityDontShowAgainEnabled] = useLocalStorageState({
    key: `isMoveResponsibilityDontShowAgainEnabled_${accountId}`,
    initialValue: false,
  });

  const tempColumnsIds = useMemo(() => tempColumns.map((column) => column.id), [tempColumns]);

  const handleMoveResponsibility = (active: Active) => {
    const activeId = active.id as string;

    const { sourceColumn, sourceColumnId, sourceResponsibility, sourceResponsibilityIndex } =
      getSourceData({
        responsibilityId: activeId,
        boardColumns,
      });

    if (!sourceResponsibility || !sourceColumnId || !sourceColumn) return;

    const targetColumnId = active.data.current?.columnId;
    const position = active.data.current?.sortable.index + 1;

    if (targetColumnId === sourceColumnId && position === sourceResponsibilityIndex + 1) return;

    const isDragInsideColumn = active.data.current?.columnId === sourceColumnId;

    const actionParams = {
      id: activeId,
      position,
      sourceColumnId,
      targetColumnId,
    };

    if (isDragInsideColumn) {
      updateBoardColumnResponsibilityPosition({
        id: activeId as string,
        boardId: currentBoardId,
        boardColumnId: sourceColumnId,
        position,
      });

      return;
    }

    const isDragFromGroup = sourceColumn.columnType === 'Group';

    if (isDragFromGroup && !isMoveResponsibilityDontShowAgainEnabled) {
      dispatch(setCurrentDragArgs(actionParams));
      dispatchShowModal('moveResponsibilityConfirmationModal', true);
    } else {
      const targetColumnName =
        boardColumns.find((column) => column.id === targetColumnId)?.columnLabel || '';

      dispatch(setTargetColumnName(targetColumnName));

      moveBoardColumnResponsibility({ boardId: currentBoardId, ...actionParams });
    }
  };

  const handleMoveColumn = (active: Active, over: Over) => {
    const activeId = active.id as number;
    const overId = over.id as number;

    const activeIndex = tempColumns.findIndex((col) => col.id === activeId);
    const overIndex = tempColumns.findIndex((col) => col.id === overId);

    dispatch(setTempColumns(arrayMove(tempColumns, activeIndex, overIndex)));

    updateBoardColumnPosition({
      boardId: currentBoardId,
      id: +activeId,
      position: over.data.current?.sortable.index + 1,
    });
  };

  const handleDragEnd = ({
    event,
    setActiveColumn,
    setActiveResponsibility,
  }: HandleDragEndProps) => {
    setActiveResponsibility(null);
    setActiveColumn(null);

    const { active, over } = event;
    if (!over) return;

    const activeIsAColumn = active.data.current?.type === EDragElements.Column;

    if (activeIsAColumn) {
      handleMoveColumn(active, over);
      setGrabCursor(false);

      return;
    }

    handleMoveResponsibility(active);
  };

  const handleTempColumns = (columns: TBoardColumn[]) => dispatch(setTempColumns(columns));

  useEffect(() => {
    if (boardColumns) {
      dispatch(setTempColumns(boardColumns));
    }
  }, [boardColumns, tempColumns.length, dispatch]);

  useEffect(() => {
    if (isResponsibilityMoveError) {
      dispatchShowModal('cantMoveResponsibilityModal', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResponsibilityMoveError]);

  return (
    <DndContext
      collisionDetection={rectIntersection}
      modifiers={modifiers(activeColumn)}
      onDragCancel={(event) => handleDragEnd({ event, setActiveColumn, setActiveResponsibility })}
      onDragEnd={(event) => handleDragEnd({ event, setActiveColumn, setActiveResponsibility })}
      onDragOver={(event) =>
        handleDragOver({
          event,
          handleTempColumns,
          tempColumns,
        })
      }
      onDragStart={(event) =>
        handleDragStart({
          event,
          setActiveColumn,
          setActiveResponsibility,
          setHolderHeight,
        })
      }
      sensors={sensors}
    >
      <BoardContainer ref={setBoardTrackElement}>
        <SortableContext items={tempColumnsIds}>
          {tempColumns.map((column) => (
            <BoardColumn column={column} holderHeight={holderHeight} key={column?.id} />
          ))}
        </SortableContext>
      </BoardContainer>

      {createPortal(
        <DragOverlay>
          {activeColumn && (
            <BoardColumn column={activeColumn} holderHeight={holderHeight} isDragging />
          )}

          {activeResponsibility && (
            <ResponsibilityDraggableComponent
              boardColumnResponsibility={activeResponsibility}
              columnId={activeColumn?.id}
              holderHeight={holderHeight}
              isDragging
            />
          )}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

export default BoardTrack;
