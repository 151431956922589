import React from 'react';
import styled, { css } from 'styled-components';

import useAnalyticsUserEvents from '../../../../../hooks/useAnalyticsUserEvents';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useKeyPress from '../../../../../hooks/useKeyPress';
import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import ModalFooterContainer from '../../../../design_system/core/Layout/ModalContainers/ModalFooterContainer';
import Icon from '../../../../design_system/display/icons/Icon';
import Link from '../../../../design_system/Link';
import { LinkAsLinkProps } from '../../../../design_system/Link/LinkTypes';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { ModalBodyContainer } from '../../../../styled/Modals';
import { routes as applicationRouterRoutes } from '../../../publicApplication/applicationRouter';

const StyledModalBodyContainer = styled(ModalBodyContainer)`
  justify-content: flex-start;
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const StyledListItem = styled.li`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} 0`};
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const StyledImage = styled.span`
  display: inline-block;
  margin-right: 0.625rem;
  width: 1.875rem;
  text-align: center;
`;

const StyledLockIcon = styled(Icon)`
  height: 1.625rem;
  width: 1.422rem !important;
`;

const StyledQuestionCircleIcon = styled(Icon)`
  height: 1.667rem;
  width: 1.667rem !important;
`;

const StyledUsersClassIcon = styled(Icon)`
  height: 1.5rem;
  width: 1.875rem !important;
`;

const StyledLink = styled(Link)`
  ${({ theme: { constants } }) => css`
    padding-left: ${constants.spacerSm2};

    &:last-of-type {
      padding-right: ${constants.spacerSm2};
    }
  `};
`;

const PaddedDefaultButton = styled(DefaultButton)`
  margin-top: 1rem;
  width: 100%;
  display: block;

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-left: ${({ theme: { constants } }) => constants.spacerMd1};
    margin-top: 0;
  }
`;

const StyledButton = styled(DefaultButton)`
  width: 100%;
  display: block;

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
  }
`;

const t = initTranslations('bulk_user_upload_modal.before_you_get_started');

interface BeforeYouGetStartedProps {
  closeModal: () => void;
  renderImportTeammatesContent: () => void;
}

const BeforeYouGetStarted = ({
  closeModal,
  renderImportTeammatesContent,
}: BeforeYouGetStartedProps) => {
  const { slug } = useCurrentAccount();
  const { cdpBulkUploadModalDisplayed } = useAnalyticsUserEvents();
  const type = 'import_teammates_modal';
  const openModalAndFireSegmentEvent = () => {
    renderImportTeammatesContent();
    cdpBulkUploadModalDisplayed({ type });
  };
  const createGroupPath = `${
    applicationRouterRoutes.groups({ slug }).href
  }?show_create_group_kind_modal=true`;
  const groupLinkProps: LinkAsLinkProps = {
    color: 'accent',
    href: createGroupPath,
    target: '_blank',
    text: undefined,
  };

  useKeyPress('Enter', renderImportTeammatesContent);
  const { configs } = usePrivateConfigs();
  const permissionLevel = configs['PERMISSION_LEVELS_HELP_PAGE'];
  const bulkUserUpload = configs['BULK_USER_UPLOAD_HELP_PAGE'];

  return (
    <>
      <StyledModalBodyContainer>
        <StyledList>
          <StyledListItem>
            <StyledImage>
              <StyledUsersClassIcon name='users-class' size='lg' weight='regular' />
            </StyledImage>
            {t('add_all')}
            <>
              <StyledLink {...groupLinkProps} text={t('roles')} />
              ,
              <StyledLink {...groupLinkProps} text={t('teams')} />
              ,
              <StyledLink {...groupLinkProps} text={t('departments')} />, {t('and')}
              <StyledLink {...groupLinkProps} text={t('locations')} />
            </>
            {t('to_your_account')}
          </StyledListItem>
          <StyledListItem>
            <StyledImage>
              <StyledLockIcon name='lock' size='lg' weight='regular' />
            </StyledImage>
            {t('review_the')}
            <StyledLink href={permissionLevel} target='_blank' text={t('permission_levels')} />
            {t('you_can_choose_from')}
          </StyledListItem>
          <StyledListItem>
            <StyledImage>
              <StyledQuestionCircleIcon name='question-circle' size='lg' weight='regular' />
            </StyledImage>
            {t('reference_the')}
            <StyledLink href={bulkUserUpload} target='_blank' text={t('bulk_upload_guide')} />
            {t('for_help_along_the_way')}
          </StyledListItem>
        </StyledList>
      </StyledModalBodyContainer>
      <ModalFooterContainer>
        <StyledButton
          buttonType='secondary'
          id='close-before-you-get-started-bulk-upload-modal'
          onClick={closeModal}
          text={t('cancel')}
        />
        <PaddedDefaultButton
          buttonType='primary'
          id='show-bulk-user-upload-modal'
          onClick={openModalAndFireSegmentEvent}
          text={t('get_started')}
        />
      </ModalFooterContainer>
    </>
  );
};

export default BeforeYouGetStarted;
