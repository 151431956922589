import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import useFieldErrorsFromFormik from '../../../../hooks/useFieldErrorsFromFormik';
import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../lib/initTranslations';
import { userFirstName } from '../../../../lib/userNaming';
import { getResetPasswordSchema } from '../../../../lib/validation_schemas/authorization/resetPassword';
import { multiErrorValidatorForSchema } from '../../../../lib/validation_schemas/multiErrorPassword';
import { messageFromError } from '../../../../redux/errors/helpers';
import {
  useAcceptInvitationMutation,
  useGetUserNameByInvitationTokenQuery,
} from '../../../../redux/services/resourceApis/publicApplication/loginApi';
import { AvatarObject } from '../../../../types/Avatar';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Loader from '../../../design_system/Triage/Loader';
import PasswordInputField from '../../../design_system/Triage/PasswordInputField';
import { fontLg1, fontMd1 } from '../../../styled/TypeSystem';
import { LoaderWrapper } from '../../curriculums/shared/styles';
import ImageUpload from '../../image_upload';
import { routes } from '../applicationRouter';
import PasswordValidationPreview from '../passwords/PasswordValidationPreview/PasswordValidationPreview';
import {
  FormStyles,
  FormWrapper,
  PageWithBackgroundImage,
  TermsLink,
  TermsWrapper,
} from '../styles/shared';
import useRedirectToLogin from '../utils/useRedirectToLogin';
import useRedirectToLoginOnSuccess from '../utils/useRedirectToLoginOnSuccess';

const ImageUploadContainer = styled.div`
  h4,
  p {
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

const H1 = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontExtrabold};
  margin: 0 0 ${({ theme: { constants } }) => constants.spacerSm3};

  ${fontLg1};
`;

const H5 = styled.h5`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin: 0 0 ${({ theme: { constants } }) => constants.spacerMd3};

  ${fontMd1};
`;

const SubmitButton = styled(DefaultButton)`
  width: 100%;
`;

const Form = styled.form`
  width: 100%;

  ${FormStyles};
`;

const t = initTranslations('authorization.accept_invitation');

type FormValues = {
  password: string;
  confirm_password: string;
};

export type Props = {
  route: Route<typeof routes.acceptInvitation>;
};

const AcceptInvitation = ({ route }: Props) => {
  const { slug, invitation_token } = route.params;
  const { configs } = usePublicConfigs();
  const multiErrorFormFields = ['password'] as (keyof FormValues)[];
  const updatedPasswordValidationPreviewEnabled = !!configs['UPDATED_PASSWORD_VALIDATION_PREVIEW'];
  const multiErrorFields = updatedPasswordValidationPreviewEnabled ? multiErrorFormFields : [];
  const userQueryResult = useGetUserNameByInvitationTokenQuery({ slug, invitation_token });
  const [acceptInvitation, result] = useAcceptInvitationMutation();
  const validationSchema = getResetPasswordSchema(updatedPasswordValidationPreviewEnabled);
  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    setFieldError,
    setStatus,
    setSubmitting,
  } = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validate: multiErrorValidatorForSchema<FormValues>(validationSchema, multiErrorFields),
    onSubmit: (values: FormValues) => submitForm(values),
  });
  const [avatar, setAvatar] = useState<AvatarObject>({ avatar: undefined });
  const { data: userData, isError: isUserError, isLoading: isLoadingUser } = userQueryResult;
  const { password, confirm_password } = values;
  const {
    error: acceptInvitationError,
    isLoading: isAcceptInvitationLoading,
    isSuccess: isSuccessAcceptInvitation,
  } = result;
  const messages = acceptInvitationError && messageFromError(acceptInvitationError);
  const isFieldErrorMessage = messages && typeof messages[0] === 'string';
  const isActionInProgress = isAcceptInvitationLoading || isSuccessAcceptInvitation;

  const submitForm = useCallback(
    (formValues) => {
      acceptInvitation({
        ...avatar,
        ...route.params,
        ...formValues,
      });
    },
    [acceptInvitation, avatar, route.params]
  );

  useDisplayFlashOnResponse({
    result,
    errorMessage: isFieldErrorMessage ? messages?.join(', ') : undefined,
  });

  useFieldErrorsFromFormik({
    error: isFieldErrorMessage ? undefined : acceptInvitationError,
    setFieldError,
    setStatus,
    setSubmitting,
    wrapErrors: true,
  });

  useRedirectToLogin(isUserError);

  useRedirectToLoginOnSuccess(result, { slug, show_notification: 'password_successfully_set' });

  return (
    <>
      {isLoadingUser ? (
        <LoaderWrapper>
          <Loader size='lg' />
        </LoaderWrapper>
      ) : (
        <PageWithBackgroundImage boxShadow id='accept-invitation-page' surfacePosition='center'>
          <H1>
            {t('hi_html', { userFirstName: userData?.name ? userFirstName(userData.name) : '' })}
          </H1>
          <H5>{t('title')}</H5>
          <FormWrapper>
            {!userData?.createdWithSaasAdmin && (
              <ImageUploadContainer>
                <ImageUpload
                  imageSize='sm'
                  imageUrl={avatar.avatar}
                  resetButtonText={t('avatar_upload.remove_photo')}
                  setImage={setAvatar}
                  text={t('avatar_upload.photos_help_your_teammates_recognize_you_in_trainual')}
                  title={t('avatar_upload.set_your_profile_photo')}
                  uploadButtonText={t('avatar_upload.upload_photo')}
                  verticalAlign='center'
                />
              </ImageUploadContainer>
            )}
            <Form onSubmit={handleSubmit}>
              {updatedPasswordValidationPreviewEnabled ? (
                <PasswordValidationPreview
                  isTouched={!!password.length || !!touched.password}
                  validationErrors={errors.password || []}
                >
                  <PasswordInputField
                    autoFocus
                    iconName='lock'
                    label={t('password')}
                    name='password'
                    onChange={handleChange}
                    placeholder={t('placeholder')}
                    type='password'
                    value={password}
                  />
                </PasswordValidationPreview>
              ) : (
                <PasswordInputField
                  autoFocus
                  errorText={touched.password && errors.password}
                  iconName='lock'
                  label={t('password')}
                  name='password'
                  onChange={handleChange}
                  placeholder={t('password_placeholder')}
                  type='password'
                  value={password}
                  wrapErrors
                />
              )}
              <PasswordInputField
                errorText={touched.confirm_password && errors.confirm_password}
                iconName='lock'
                label={t('confirm_password')}
                name='confirm_password'
                onChange={handleChange}
                placeholder={t(
                  updatedPasswordValidationPreviewEnabled ? 'placeholder' : 'password_placeholder'
                )}
                type='password'
                value={confirm_password}
                wrapErrors
              />
              <SubmitButton
                disabled={isActionInProgress}
                id='submit-accept-invitation'
                loading={isActionInProgress}
                text={t('join_your_team')}
                type='submit'
              />
            </Form>
            <TermsWrapper>
              <div>
                {t('terms.by_signing_up')}{' '}
                <TermsLink
                  color='monochrome'
                  external
                  href={configs['PRIVACY_URL']}
                  text={t('terms.privacy_policy')}
                />{' '}
                {t('terms.and')}
              </div>
              <TermsLink
                color='monochrome'
                external
                href={configs['TERMS_URL']}
                text={t('terms.terms_of_services')}
              />
            </TermsWrapper>
          </FormWrapper>
        </PageWithBackgroundImage>
      )}
    </>
  );
};

export default AcceptInvitation;
