import React, { useEffect } from 'react';

import { useEditorCourseContext } from '../../../../contexts/EditorCourseContext';
import initTranslations from '../../../../lib/initTranslations';
import { EditorCourse, EditorCurriculum } from '../../../../types/Editor';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import { useFlashNotification } from '../../../FlashNotificationContext';
import CurriculumModal from '../../curriculums/modals/CurriculumModal/CurriculumModal';
import ResetCompletionSignatureModal from '../../curriculums/modals/ResetCompletionSignatureModal/ResetCompletionModal';
import { routes } from '../../publicApplication/applicationRouter';
import GoBackIconButton from '../components/GoBackIconButton/GoBackIconButton';
import { GoBackButtonWrapper, LockedOverlay } from '../shared/styles';

type Props = {
  slug: string;
  course: EditorCourse;
  curriculum: EditorCurriculum;
};

const t = initTranslations('editor');

const EditorLockedOverlay = ({ slug, course, curriculum }: Props) => {
  const { flash } = useFlashNotification();
  const {
    curriculum: { singular: curriculumTerm },
    step: { singular: stepTerm },
  } = useAccountTerminology();
  const {
    setShowCurriculumModal,
    setShowResetModal,
    showCurriculumModal,
    showResetModal,
    curriculum: { locked },
  } = useEditorCourseContext();

  useEffect(() => {
    if (curriculum.locked) {
      flash(
        'warning',
        t('unlock_curriculum', {
          curriculum: curriculumTerm.toLowerCase(),
          element: stepTerm.toLowerCase(),
        }),
        {
          autoClose: 5000,
        }
      );
    }
  }, [curriculum.locked, curriculumTerm, flash, stepTerm]);

  return (
    <LockedOverlay
      className='locked-curriculum-overlay'
      onClick={() => (locked ? setShowCurriculumModal(true) : setShowResetModal(true))}
    >
      <GoBackButtonWrapper>
        <GoBackIconButton
          onClick={() => routes.curriculumEdit({ slug, id: curriculum.id }).push()}
        />
      </GoBackButtonWrapper>
      {showResetModal && !curriculum.locked && (
        <ResetCompletionSignatureModal
          closeRequest={() => setShowResetModal(false)}
          courseId={course.id}
          courseTitle={course.title}
        />
      )}
      {showCurriculumModal && curriculum.locked && (
        <CurriculumModal
          closeRequest={() => setShowCurriculumModal(false)}
          curriculumId={curriculum.id}
          sector={curriculum.sector}
        />
      )}
    </LockedOverlay>
  );
};

export default EditorLockedOverlay;
