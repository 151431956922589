import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontMd1 } from '../../../styled/TypeSystem';

export const CurriculumsAssignmentsHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CurriculumsAssignmentsListWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const CurriculumsAssignmentsList = styled.div`
  min-width: 700px;

  @media (min-width: ${mediaBreakpointPxSm}) {
    min-width: auto;
  }
`;

export const CurriculumsAssignmentsSubtitle = styled.h5`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-top: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontMd1};
`;
