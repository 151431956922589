import React, { useCallback } from 'react';

import { useOrgChartConfigSlideout } from '../../../../../../contexts/OrgChartConfigSlideoutContext';
import { OrgChartResource } from '../../../../../../hooks/useOrgChartConfigSlideoutState';
import { formatUserOptions } from '../../../../../../lib/formatUserOptions';
import { selectOptions } from '../../../../../../lib/selectOptions';
import { User } from '../../../../../../types/User';
import { DisabledOverlay, SurfaceWrapper } from '../styles';
import ResourceList from './ResourceList';

const OrgChartConfigSlideoutSetReportsTo = () => {
  const { resources, rootResource, isGroupType } = useOrgChartConfigSlideout();
  const hasSelectedRootValue = !!rootResource;
  const isDisabled = !hasSelectedRootValue;

  const formatResourceOptions = useCallback(
    (resources: unknown[]) => {
      if (isGroupType) {
        return selectOptions(resources as OrgChartResource[]);
      }

      return formatUserOptions(resources as User[]);
    },
    [isGroupType]
  );

  if (!resources.length || (hasSelectedRootValue && resources.length == 1)) return <></>;

  return (
    <SurfaceWrapper id='set-reports-to-block'>
      {isDisabled && <DisabledOverlay className='disabled-overlay' />}
      <ResourceList formatResourceOptions={formatResourceOptions} />
    </SurfaceWrapper>
  );
};

export default OrgChartConfigSlideoutSetReportsTo;
