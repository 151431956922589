import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';

const t = initTranslations('e_signature.iframe');

const HighlightedText = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

const ESignatureDescriptionText = () => (
  <>
    <HighlightedText>{t('description_1')}</HighlightedText>
    {t('description_2')}
    <HighlightedText>{t('description_3')}</HighlightedText>
    {t('description_4')}
  </>
);

export default ESignatureDescriptionText;
