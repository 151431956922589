import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../styled/TypeSystem';

export const LoadingIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};

  ${fontSm5};
`;

export const UpdatedAtText = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-left: auto;

  ${fontSm5};
`;
