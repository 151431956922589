import { FormikErrors } from 'formik';

import initTranslations from '../../../../lib/initTranslations';
import isEmail from '../../../../lib/isEmail';
import isValidFullName from '../../../../lib/isValidName';
import { SettingsFormValues } from './InterfaceAndTypes';

const t = initTranslations('settings');

const validateForm = ({ name, email, title }: SettingsFormValues) => {
  const errors: FormikErrors<SettingsFormValues> = {};

  if (!name || !isValidFullName(name)) {
    errors.name = t('errors.name.required');
  }

  if (!email) {
    errors.email = t('errors.email.required');
  } else if (!isEmail(email)) {
    errors.email = t('errors.email.invalid');
  }

  if (!title) {
    errors.title = t('errors.title.required');
  }

  return errors;
};

export default validateForm;
