import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import NoResultsGraphicDark from '../../../../../images/empty_state/curriculums-empty-state-dark.svg';
import NoResultsGraphic from '../../../../../images/empty_state/curriculums-empty-state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { useGetUserPrimaryDetailsQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Link from '../../../../design_system/Link';
import Breadcrumbs from '../../../../design_system/navigation/Breadcrumbs';
import Loader from '../../../../design_system/Triage/Loader';
import NoResults from '../../../../design_system/Triage/NoResults';
import { routes } from '../../../publicApplication/applicationRouter';
import { AssignedSubjectsProvider, useAssignedSubjects } from './AssignedSubjectsContext';
import AssignedSubjectsHeader from './AssignedSubjectsHeader';
import Subject from './Subject';
import SurfaceHeader from './SurfaceHeader';

const SubjectsAssignedCard = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerLg1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.foundationBase1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXs};
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  padding-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const SubjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const SeeAllWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const t = initTranslations('user_profile');

type ContentProps = {
  id: number;
  limit?: number;
};

const BodyContent = ({ limit, id }: ContentProps) => {
  const { onHoldPlan, slug, status: accountStatus } = useCurrentAccount();
  const seeAllRoute = routes.userAssignedCurriculums({ id, slug });
  const userProfileResult = useGetUserPrimaryDetailsQuery(id);
  const { checkableSubjects, ableToManage, userProfilePage } = useAssignedSubjects();
  const {
    curriculum: { plural: subjectTermPlural },
  } = useAccountTerminology();
  const { data: user, isLoading, error } = userProfileResult;
  const isTrialEndedOrOnHold = accountStatus === 'trial_ended' || onHoldPlan;

  return (
    <>
      {!userProfilePage &&
        (isLoading ? (
          <Loader />
        ) : error ? (
          <BasicErrorDisplay error={error} />
        ) : (
          user && (
            <Breadcrumbs
              currentPageText={t('breadcrumbs.subjects_assigned', { subjects: subjectTermPlural })}
              isRedirectTextSentenceCase={false}
              redirectPath={routes.userProfile({ id, slug }).href}
              redirectText={user.name}
            />
          )
        ))}
      <SubjectsAssignedCard className='assigned-subjects'>
        <SurfaceHeader isDisabled={isTrialEndedOrOnHold} seeAllLink={seeAllRoute.href} />
        <Wrapper>
          {!userProfilePage && ableToManage && <AssignedSubjectsHeader />}
          {checkableSubjects.length ? (
            <SubjectsWrapper>
              {checkableSubjects.map(
                (checkableSubject) =>
                  (ableToManage || checkableSubject.published) && (
                    <Subject
                      className={`assigned-subject-${checkableSubject.id}`}
                      key={`subject-${checkableSubject.id}`}
                      subject={checkableSubject}
                    />
                  )
              )}
            </SubjectsWrapper>
          ) : (
            <NoResults
              darkImage={NoResultsGraphicDark}
              heading={t('assigned_curriculums.empty_state.heading')}
              iconWidth='45%'
              lightImage={NoResultsGraphic}
            />
          )}
        </Wrapper>
        {!!checkableSubjects.length && limit && (
          <SeeAllWrapper>
            <Link
              behavesAs='a'
              disabled={isTrialEndedOrOnHold}
              href={seeAllRoute.href}
              text={t('assigned_curriculums.see_all_curriculums', {
                subjects: subjectTermPlural.toLowerCase(),
              })}
            />
          </SeeAllWrapper>
        )}
      </SubjectsAssignedCard>
    </>
  );
};

export type Props = {
  id: number;
  limit?: number;
  userProfilePage: boolean;
};

const ProfileSubjects = ({ limit, id, userProfilePage }: Props) => {
  return (
    <AssignedSubjectsProvider limit={limit} userId={id} userProfilePage={userProfilePage}>
      <BodyContent id={id} limit={limit} />
    </AssignedSubjectsProvider>
  );
};

export default ProfileSubjects;
