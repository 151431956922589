import React, { useContext, useState } from 'react';

import { PaywallContext } from '../../../../../../contexts/PaywallContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Hoverable from '../../../../../design_system/Hoverable';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import PaywallTooltip from '../../../../shared/tooltips/PaywallTooltip';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  StyledIcon,
  ToggleContainer,
} from '../../styles';

const t = initTranslations('account_settings.advanced_settings');

type SignatureToggleProps = {
  allowSignatures: boolean;
};

const SignatureToggle = ({ allowSignatures }: SignatureToggleProps) => {
  const [isCheckboxHovered, setCheckboxHovered] = useState(false);
  const {
    onFree3SeatsPlan,
    splitFeatures: { simplePricingEnabled },
  } = useCurrentAccount();
  const {
    policy: { plural: policyTermPlural },
    topic: { singular: TopicTermSingular },
  } = useAccountTerminology();
  const paywallCtx = useContext(PaywallContext);
  const signaturesLocked = paywallCtx.includes('e_signature');
  const [updateAccountData, result] = useUpdateAccountDataMutation();
  const { isLoading } = result;

  const getPaywallDescriptionKey = () => {
    if (onFree3SeatsPlan) {
      return 'free_plan_description';
    } else if (simplePricingEnabled) {
      return 'simple_pricing_description';
    } else {
      return 'description';
    }
  };

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
    errorMessage: t('error_message'),
  });

  return (
    <div id='e-signatures-toggle'>
      <FormSectionContainer>
        <FormSectionTitle>
          {t('signatures_title')}
          {signaturesLocked && <StyledIcon name='lock' weight='regular' />}
        </FormSectionTitle>
        <ToggleContainer>
          <PaywallTooltip
            description={t(`signatures_paywall.${getPaywallDescriptionKey()}`)}
            modifiers={[{ name: 'offset', options: { offset: [25, 0] } }]}
            shouldBeVisible={isCheckboxHovered && signaturesLocked}
            title={t('signatures_paywall.title')}
          />
          <Hoverable setIsHovered={setCheckboxHovered}>
            <Toggle
              checked={allowSignatures}
              className='signatures-setting'
              disabled={signaturesLocked || isLoading}
              handleToggle={(e) => updateAccountData({ allow_signatures: e.target.checked })}
              id='allow-signatures'
              name='allow_signatures'
            />
          </Hoverable>
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>
          {t('signatures_description', {
            policy: policyTermPlural,
            topic: TopicTermSingular.toLowerCase(),
          })}
        </FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default SignatureToggle;
