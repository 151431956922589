import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import EditorViewContent from '../EditorViewContent/EditorViewContent';
import { TemplatePreviewElementContainer, TemplatePreviewElementContentWrapper } from '../styles';
import {
  TemplatePreviewStepCounter,
  TemplatePreviewStepHead,
  TemplatePreviewStepTitle,
} from './styles';
import { TemplatePreviewStepProps } from './types';

const t = initTranslations('template_preview.content');

const TemplatePreviewStep = ({
  totalCount,
  number,
  title,
  id,
  content,
}: TemplatePreviewStepProps) => {
  return (
    <TemplatePreviewElementContainer id={`template-preview-element-${id}`}>
      <TemplatePreviewStepCounter>
        {t('step_of', { number, totalCount })}
      </TemplatePreviewStepCounter>
      <TemplatePreviewStepHead>
        <TemplatePreviewStepTitle>{title}</TemplatePreviewStepTitle>
      </TemplatePreviewStepHead>
      <TemplatePreviewElementContentWrapper>
        <EditorViewContent content={content} />
      </TemplatePreviewElementContentWrapper>
    </TemplatePreviewElementContainer>
  );
};

export default TemplatePreviewStep;
