import {
  CurrentPerUserPlan,
  NewPerUserPlan,
} from '../../../../../redux/services/resourceApis/billing/perUserPricingTypes';

export const isCurrentPlanSameAsNewOne = ({
  currentPlan,
  newPlan,
}: {
  currentPlan: CurrentPerUserPlan;
  newPlan: NewPerUserPlan;
}) => {
  return (
    currentPlan.stripePlanId === newPlan.stripePlanId &&
    currentPlan.seatsIncluded === newPlan.seatsIncluded
  );
};
