import React, { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

import { useGroupsIndex } from '../../../../contexts/GroupsIndexContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import { useIsAdmin } from '../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Pagination from '../../../design_system/Triage/Paginate/Pagination';
import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import ExplainAndInspireVideoPreviewElement from '../../ExplainAndInspireVideos/ExplainAndInspireVideoPreviewElement/ExplainAndInspireVideoPreviewElement';
import { routes } from '../../publicApplication/applicationRouter';
import RouterLink from '../../publicApplication/RouterLink';
import AvatarsGroup from '../../shared/AvatarsGroup/AvatarsGroup';
import EmptyState from '../GroupsIndexView/EmptyState';
import {
  ActionMenuWrapper,
  DataCell,
  DataRow,
  HeaderCell,
  HeaderRow,
  LargeDataCell,
  LargeHeaderCell,
  SmallDataCell,
  SmallHeaderCell,
  StyledTable,
  TableWrapper,
} from '../shared/table_styles';
import ActionMenu from './ActionMenu';
import GroupsTableNoResults from './GroupsTableNoResults';
import LoadingGroups from './LoadingGroups';

const GroupNameDataCell = styled(DataCell)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const HoverableGroupName = styled(RouterLink)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  &:hover,
  &:focus {
    outline: none;
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: ${({ theme: { constants } }) => constants.borderWidthLg} !important;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const SubjectsDataCell = styled(LargeDataCell)`
  margin-right: ${({ theme: { constants } }) => constants.spacerLg2};
  text-align: left;
`;

const GroupNameHeaderCell = styled(HeaderCell)`
  width: 11rem;

  @media (min-width: ${mediaBreakpointPxXl}) {
    width: auto;
  }
`;

const t = initTranslations('groups');

const GroupsPagination = () => {
  const { data, queryParams, dispatch } = useGroupsIndex();
  const { limit_value, total_groups_count, total_pages } = data;
  const onPageChange = useCallback(
    (page: number) => {
      dispatch({ type: 'setPageParam', payload: page });
    },
    [dispatch]
  );

  return (
    <PaginationWrapper>
      <Pagination
        activePage={queryParams.page}
        itemsCountPerPage={limit_value}
        onChange={onPageChange}
        showPaginationDetails
        totalItemsCount={total_groups_count}
        totalPages={total_pages}
      />
    </PaginationWrapper>
  );
};

const Table = () => {
  const {
    data,
    isFetching,
    queryParams: { search_query, group_kind },
  } = useGroupsIndex();
  const { groups } = data;
  const { curriculum } = useAccountTerminology();
  const { slug } = useCurrentAccount();
  const admin = useIsAdmin();

  const groupsToMap = useMemo(() => {
    if (admin) return groups;

    return groups.filter((group) => !group.everyone);
  }, [admin, groups]);

  const displayNoResults = useMemo(() => {
    return search_query && groups.length === 0;
  }, [groups.length, search_query]);

  return (
    <>
      <TableWrapper id='groups-index-table'>
        <StyledTable>
          <thead>
            <HeaderRow>
              <GroupNameHeaderCell>{t('table.name')}</GroupNameHeaderCell>
              <LargeHeaderCell>{t('table.managers')}</LargeHeaderCell>
              <LargeHeaderCell>{t('table.members')}</LargeHeaderCell>
              <LargeHeaderCell>{t('table.type')}</LargeHeaderCell>
              <LargeHeaderCell>{curriculum.plural}</LargeHeaderCell>
              <SmallHeaderCell />
            </HeaderRow>
          </thead>
          <tbody>
            {isFetching && !search_query ? (
              <LoadingGroups />
            ) : (
              groupsToMap.map((group) => (
                <DataRow id={`group-row-${group.id}`} key={group.id}>
                  <GroupNameDataCell className='group-name'>
                    <HoverableGroupName
                      color='monochrome'
                      to={routes.group({
                        slug,
                        id: group.id,
                        breadcrumb: routes.groups({ slug, group_kind }),
                        tab: 'overview',
                      })}
                    >
                      {group.name}
                    </HoverableGroupName>
                  </GroupNameDataCell>
                  <LargeDataCell className='group-managers'>
                    {!group.everyone && (
                      <AvatarsGroup
                        i18Key='groups.table'
                        totalUserCount={group.manager_count}
                        users={group.managers.slice(0, 4)}
                      />
                    )}
                  </LargeDataCell>
                  <LargeDataCell className='group-members'>
                    <AvatarsGroup
                      i18Key='groups.table'
                      totalUserCount={group.user_count}
                      users={group.users.slice(0, 4)}
                    />
                  </LargeDataCell>
                  <LargeDataCell>{t(`kinds.${group.kind}`)}</LargeDataCell>
                  <SubjectsDataCell className='group-subjects'>
                    {group.curriculum_count}
                  </SubjectsDataCell>
                  <SmallDataCell>
                    <ActionMenuWrapper>
                      <ActionMenu group={group} />
                    </ActionMenuWrapper>
                  </SmallDataCell>
                </DataRow>
              ))
            )}
          </tbody>
        </StyledTable>
        {displayNoResults && <GroupsTableNoResults />}
      </TableWrapper>
      {!displayNoResults && <GroupsPagination />}
    </>
  );
};

const GroupsTable = () => {
  const {
    splitFeatures: { explainAndInspireVideosEnabled },
  } = useCurrentAccount();
  const {
    data,
    isFetching,
    queryParams: { search_query, group_kind },
  } = useGroupsIndex();
  const { groups, total_groups_count } = data;
  const abilities = useCurrentUserAbilities();
  const canCreateGroup = abilities.can('create', 'Group');
  const [cookies] = useCookies();

  const displayInspireVideo = useMemo(() => {
    if (!canCreateGroup) return false;
    if (!explainAndInspireVideosEnabled) return false;

    const inspireVideoCookie = cookies['dismissed-inspire-groups-video'];
    const inspireVideoDismissed = inspireVideoCookie && inspireVideoCookie.value;

    return !inspireVideoDismissed;
  }, [canCreateGroup, cookies, explainAndInspireVideosEnabled]);

  const displayEmptyState = useMemo(() => {
    return (
      canCreateGroup &&
      !displayInspireVideo &&
      group_kind === 'all' &&
      !isFetching &&
      !search_query &&
      total_groups_count <= 1
    );
  }, [
    canCreateGroup,
    displayInspireVideo,
    group_kind,
    isFetching,
    search_query,
    total_groups_count,
  ]);

  const displayTable = useMemo(() => {
    return groups.length > 0 || isFetching || search_query;
  }, [groups.length, isFetching, search_query]);

  return (
    <>
      {displayTable && <Table />}
      {displayInspireVideo && <ExplainAndInspireVideoPreviewElement resource='groups' />}
      {displayEmptyState && <EmptyState />}
    </>
  );
};

export default GroupsTable;
