import React from 'react';
import { CurriculumEdit } from 'redux/services/resourceApis/curriculums/types';
import styled from 'styled-components';

import { TeammateModalProvider } from '../../../../../../contexts/TeammateContext';
import Description from './Description';
import Header from './Header';
import TopSurfaceActionRow from './TopSurfaceActionRow/TopSurfaceActionRow';

const TopSection = styled.div`
  display: flex;
  align-items: center;
`;

export interface TopSurfaceProps {
  curriculum: CurriculumEdit;
}

const TopSurface = ({ curriculum }: TopSurfaceProps) => {
  const {
    emoji,
    title,
    description,
    locked,
    id,
    curriculum_can_be_modified: curriculumCanBeModified,
  } = curriculum;

  return (
    <div id='edit-curriculum-top-surface'>
      <TopSection>
        <Header
          containsSignature={!curriculumCanBeModified}
          curriculum={curriculum}
          emoji={emoji}
          id={id}
          isLocked={locked}
          title={title}
        />
      </TopSection>
      <Description
        description={description}
        id={id}
        isLocked={locked || !curriculumCanBeModified}
      />
      <TeammateModalProvider>
        <TopSurfaceActionRow curriculum={curriculum} />
      </TeammateModalProvider>
    </div>
  );
};

export default TopSurface;
