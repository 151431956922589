import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import { RESPONSIBILITY_DESCRIPTION_MAX_CHARACTERS } from '../../../../shared/constants/boards';
import { SectionItemContainer, SectionItemTitle } from '../../styles';
import { ResponsibilityDetailsFormData } from '../types';
import { DescriptionWrapper, Textarea } from './styles';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.description');

const Description: FC = () => {
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();
  const { initialValues, setFieldValue, handleChange, values } =
    useFormikContext<ResponsibilityDetailsFormData>();
  const { currentBoardColumnResponsibility } = useAppSelector((state) => state.delegationPlanner);
  const isEditable = !currentBoardColumnResponsibility?.groupId;

  const handleDescriptionValidation = async () => {
    const inputLength = values.description.trim().length;
    const overMaxChars = inputLength > RESPONSIBILITY_DESCRIPTION_MAX_CHARACTERS;

    if (overMaxChars) {
      setFieldValue('description', initialValues.description);
    }
  };

  return (
    <SectionItemContainer>
      <DescriptionWrapper>
        <SectionItemTitle>
          {t('title', { responsibility: responsibilitySingular })}
        </SectionItemTitle>
        <Textarea
          maxLength={RESPONSIBILITY_DESCRIPTION_MAX_CHARACTERS}
          name='description'
          onBlur={handleDescriptionValidation}
          onChange={handleChange}
          placeholder={t(isEditable ? 'placeholder' : 'readonly_placeholder', {
            responsibility: responsibilitySingular.toLowerCase(),
          })}
          readOnly={!isEditable}
          value={values.description}
        />
      </DescriptionWrapper>
    </SectionItemContainer>
  );
};

export default Description;
