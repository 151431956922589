import React, { KeyboardEvent, useState } from 'react';
import styled from 'styled-components';

import InputField from '../../../design_system/Triage/InputField';
import Poppable from '../../../Poppable';
import OutsideClickHandler from '../../shared/OutsideClickHandler';
import PalettePickerPopup from './PalettePickerPopup';
import PalettePickerTrigger from './PalettePickerTrigger';
import { InputFieldWrapper, PalettePickerContainer, PalettePickerIconWrapper } from './styles';
import { PalettePickerProps } from './types';

const StyledInputField = styled(InputField)<{ capitalize: boolean }>`
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : '')};
`;

const PalettePicker = ({
  ariaLabel,
  buttonId,
  hex,
  palette,
  palettes,
  className = 'color-picker',
  setColor,
  showTextFiled = true,
  openedPalettePicker = false,
  onChange,
  id,
  errorText,
  name,
  type = 'text-color',
}: PalettePickerProps) => {
  const [showPalettePicker, setShowPalettePicker] = useState(openedPalettePicker);

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <PalettePickerContainer className={className} id={id}>
      {type === 'background-color' && showTextFiled && (
        <InputFieldWrapper>
          <StyledInputField
            capitalize={!!palette}
            errorText={errorText}
            name={name}
            onChange={onChange}
            onKeyPress={handleEnterKeyPress}
            value={palette || hex}
          />
        </InputFieldWrapper>
      )}
      <PalettePickerIconWrapper>
        <Poppable
          isOpen={showPalettePicker}
          item={
            <OutsideClickHandler onOutsideClick={() => setShowPalettePicker(false)}>
              <PalettePickerPopup
                hex={hex}
                hidePalettePicker={() => setShowPalettePicker(!showPalettePicker)}
                onChange={setColor}
                palette={palette}
                palettes={palettes}
              />
            </OutsideClickHandler>
          }
          onClick={() => setShowPalettePicker(!showPalettePicker)}
          placement='bottom-start'
          trigger={
            <PalettePickerTrigger
              ariaLabel={ariaLabel}
              buttonId={buttonId}
              hex={hex}
              palette={palette}
              type={type}
            />
          }
        />
      </PalettePickerIconWrapper>
    </PalettePickerContainer>
  );
};

export default PalettePicker;
