import styled, { css, keyframes } from 'styled-components';

import { SkeletonTextWidths } from './types';

const shimmer = keyframes`
        0% {
          background-position: -1200px 0;
        }
        100% {
          background-position: 1200px 0;
        }
      `;

export const FlexCenteredContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const BoardSkeletonContainer = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  height: calc(100% - 8.5rem);
  overflow: hidden;
`;

export const SkeletonColumn = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 18.5rem;
    max-height: 100%;
    height: fit-content;
    background-color: ${vars.foundationBase1};
    border-radius: ${constants.borderRadiusMd};
    padding: ${constants.spacerMd2};
  `};
`;

export const SkeletonCardList = styled.div`
  ${({ theme: { constants } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerSm3};
    overflow-y: hidden;
  `};
`;

export const SkeletonCard = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd3};
    padding: ${constants.spacerMd3} ${constants.spacerMd2} ${constants.spacerMd2};
    background-color: ${vars.foundationSurface1};
    border-radius: ${constants.borderRadiusMd};
  `};

  svg {
    color: ${({ theme: { vars } }) => vars.borderDefault};
    padding: 0;
  }
`;

export const SkeletonHeader = styled(FlexCenteredContainer)`
  ${({ theme: { constants } }) => css`
    margin-bottom: ${constants.spacerMd2};
  `};
`;

export const SkeletonCircle = styled.div`
  ${({ theme: { constants, vars } }) => css`
    width: ${constants.spacerMd3};
    height: ${constants.spacerMd3};
    border-radius: ${constants.borderRadiusCircle};
    background-color: ${vars.foundationBase3};
  `};
`;

export const SkeletonTextLine = styled.div<{ width: SkeletonTextWidths }>`
  ${({ width, theme: { constants, vars } }) => css`
    width: ${width}rem;
    height: ${constants.spacerSm2};
    background: ${vars.foundationBase3};
    background: linear-gradient(
      to right,
      ${vars.foundationBase2} 8%,
      ${vars.foundationBase1} 18%,
      ${vars.foundationBase2} 33%
    );
    border-radius: ${constants.borderRadiusLg};
    animation: ${shimmer} 2.2s infinite forwards;
  `};
`;
