import styled, { css } from 'styled-components';

import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../styled/TypeSystem';

export const DropdownMenuOption = styled.span`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

export const TextWrapper = styled.div<{ textAlign?: 'center' | 'flex-start' }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ textAlign }) => textAlign || 'center'};
`;

export const IconWrapper = styled.div`
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme: { constants } }) => constants.height2xs};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const Title = styled.span<{ isSelected?: boolean; truncated?: boolean }>`
  ${({ truncated, isSelected, theme: { constants, vars } }) => css`
    max-width: ${truncated ? '15.5rem' : '90%'};
    word-break: break-word;
    color: ${vars.textDefault};
    font-weight: ${isSelected ? constants.fontMedium : constants.fontRegular};
    ${truncated && TruncatedText({})};
    ${fontSm5};
  `};
`;
