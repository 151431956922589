import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { TemplatePreviewQuestionElement } from '../../../../../../redux/services/resourceApis/templates/types';
import RichTextareaWithInsert from '../../../../editor/components/RichTextareaWithInsert';
import { TemplatePreviewElementContainer, TemplatePreviewElementContentWrapper } from '../styles';
import {
  TemplatePreviewAnswerType,
  TemplatePreviewQuestionHead,
  TemplatePreviewQuestionNumber,
} from './styles';
import TemplatePreviewElementContentBody from './TemplatePreviewElementContentBody/TemplatePreviewElementContentBody';

type TemplatePreviewQuestionProps = {
  surveyId: number;
} & TemplatePreviewQuestionElement;

const t = initTranslations('template_preview.content');

const TemplatePreviewQuestion = ({
  id,
  text,
  position,
  answerType,
  options,
  textJson,
  surveyId,
}: TemplatePreviewQuestionProps) => {
  return (
    <TemplatePreviewElementContainer id={`template-preview-element-${id}`}>
      <TemplatePreviewQuestionHead>
        <TemplatePreviewQuestionNumber>{position}</TemplatePreviewQuestionNumber>
        <TemplatePreviewAnswerType text={t(`answer_type.${answerType}`)} />
      </TemplatePreviewQuestionHead>
      <TemplatePreviewElementContentWrapper>
        <RichTextareaWithInsert content={textJson || text} questionId={id} surveyId={surveyId} />
        <TemplatePreviewElementContentBody answerType={answerType} options={options} />
      </TemplatePreviewElementContentWrapper>
    </TemplatePreviewElementContainer>
  );
};

export default TemplatePreviewQuestion;
