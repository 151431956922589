import styled, { css } from 'styled-components';

import { fontSm5 } from '../../../../../styled/TypeSystem';
import { RowContainer } from '../../../../curriculums/shared/CurriculumRowStyles';

const SharedWrapperStyles = css`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DownloadWrapper = styled.div`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.textSubdued}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  ${SharedWrapperStyles};
`;

export const SignatureLoaderWrapper = styled.div`
  ${SharedWrapperStyles};
`;

export const ESignatureRowContainer = styled(RowContainer)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0;
  min-height: 2.75rem;
  background-color: ${({ theme: { vars } }) => vars.foundationHover};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  width: 100%;

  &:hover {
    ${DownloadWrapper} {
      cursor: pointer;
      background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
      color: ${({ theme: { vars } }) => vars.foundationSurface1};
    }
  }
`;

export const ESignatureRowContent = styled.div`
  display: flex;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd3};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  width: 100%;
  ${fontSm5};
`;
