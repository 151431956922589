import React from 'react';

import { useGroup } from '../../../../contexts/GroupContext';
import { useGetSuggestionsResponsibilitiesQuery } from '../../../../redux/services/resourceApis/suggestionsResponsibilities/suggestionsResponsibilitiesApi';
import SuggestionsResponsibilitiesModal from '../modals/SuggestionsResponsibilitiesModal';

interface Props {
  closeModal: () => void;
}

const SuggestionsResponsibilitiesFactory = ({ closeModal }: Props) => {
  const { id: groupId } = useGroup();
  const { data, isError, isFetching, isSuccess, isLoading } =
    useGetSuggestionsResponsibilitiesQuery(groupId, { refetchOnMountOrArgChange: true });

  return (
    <SuggestionsResponsibilitiesModal
      closeModal={closeModal}
      isError={isError}
      isLoading={isLoading || isFetching}
      isSuccess={isSuccess}
      suggestedResponsibilities={data?.suggested_responsibilities}
    />
  );
};

export default SuggestionsResponsibilitiesFactory;
