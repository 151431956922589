import { Modifier, SensorOptions } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';

import { BoardColumn } from '../BoardColumn/types';

export const BOARD_POINTER_SENSOR_OPTIONS: SensorOptions = {
  activationConstraint: {
    distance: 1,
  },
};

export const BOARD_TOUCH_SENSOR_OPTIONS: SensorOptions = {
  activationConstraint: {
    delay: 250,
    tolerance: 5,
  },
};

export const modifiers = (activeColumn?: BoardColumn | null): Modifier[] =>
  activeColumn ? [restrictToHorizontalAxis, restrictToParentElement] : [];
