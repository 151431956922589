import { Action, PageState } from './types';

export const pageReducer = (state: PageState, action: Action): PageState => {
  switch (action.type) {
    case 'setSkipToken': {
      return { ...state, skipToken: action.skipToken };
    }
    case 'setIsConnect': {
      return { ...state, isConnect: action.isConnect };
    }
  }
};
