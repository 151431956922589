import styled from 'styled-components';

export const TemplatesSlideoutBody = styled.div`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2} 3.75rem`};
`;

export const TemplatesSlideoutFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 1px;
  right: 0;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerLg1}`};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: ${({ theme: { vars } }) => vars.shadowCenterLarge};
`;
