import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useEditorToolbarContext } from '../../../../../contexts/EditorToolbarContext';
import { useEmbedlyContext } from '../../../../../contexts/EmbedlyProvider';
import initTranslations from '../../../../../lib/initTranslations';
import Flyout from '../../../../design_system/overlays/flyout';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import { htmlEncodedContent } from '../../lib/htmlEncodedContent';
import { insertEditorContent } from '../../lib/insertEditorContent';
import { GOOGLE_DOCS_BASE_URL } from '../../shared/constants/editor';
import { SharedFlyoutStyles } from '../../shared/styles';
import { EmbedExtensionName } from '../../tiptap_extensions/types';
import LabelToolbarButton from '../../toolbar/buttons/components/LabelToolbarButton';
import { EmbedlyFlyoutId } from '../Embedly/EmbedlyFlyout/EmbedlyFlyout';
import EmbedlyInput from '../Embedly/EmbedlyInput';
import { EditorPluginProps } from '../types';

const t = initTranslations('editor.embed_link');

//DS Override: match width of design in Figma
const StyledFlyout = styled(Flyout)`
  width: 25rem;
  ${SharedFlyoutStyles};

  .flyout-footer {
    margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
  }
`;

const EmbedLinkFlyout = ({
  editor: passedEditor,
  popupPlacement = 'bottom-start',
}: EditorPluginProps) => {
  const { editor: contextEditor } = useEditorContext();
  const editor = passedEditor || contextEditor;
  const { selectedEmbedMenuItem, setSelectedEmbedMenuItem } = useEditorToolbarContext();
  const menuId = 'embed-link-flyout';
  const { isMenuOpen, setActiveMenuId, closeMenu } = useActiveMenuHandler({ menuId });
  const {
    embedlyData,
    errorMessage,
    setEmbedlyData,
    setErrorMessage,
    setResetForm,
    setProviderType,
    inputUrl,
    setInputUrl,
  } = useEmbedlyContext();

  useEffect(() => {
    setProviderType(['rich', 'link']);
  }, [setProviderType]);

  const handleEmbedlyResponse = () => {
    if (embedlyData) {
      const { type, url, description, thumbnail_url, title } = embedlyData;

      if (type === 'rich') {
        const jsonString = JSON.stringify(embedlyData);

        if (inputUrl && inputUrl.includes(GOOGLE_DOCS_BASE_URL)) {
          insertEditorContent({
            editor,
            content: {
              type: EmbedExtensionName.EMBED_CARD,
              attrs: {
                contentType: 'media',
                contentUrl: inputUrl,
                description,
                imgUrl: thumbnail_url,
                size: 'medium',
                title,
              },
            },
          });
        } else {
          insertEditorContent({
            editor,
            content: htmlEncodedContent({ jsonString, html: embedlyData.html, originalUrl: url }),
          });
        }
      }

      if (type === 'link') {
        insertEditorContent({
          editor,
          content: {
            type: EmbedExtensionName.EMBED_CARD,
            attrs: {
              contentType: 'media',
              contentUrl: url,
              description,
              imgUrl: thumbnail_url,
              size: 'medium',
              title,
            },
          },
        });
      }
    }
  };

  const navigateBackToEmbedlyMenu = useCallback(() => {
    setActiveMenuId(selectedEmbedMenuItem ? EmbedlyFlyoutId : null);
  }, [selectedEmbedMenuItem, setActiveMenuId]);

  const clearDataAndHandleFlyout = useCallback(() => {
    setResetForm(true);
    setInputUrl(null);
    setEmbedlyData(null);
    setErrorMessage(null);
    setSelectedEmbedMenuItem(undefined);
  }, [setResetForm, setInputUrl, setEmbedlyData, setErrorMessage, setSelectedEmbedMenuItem]);

  return (
    <StyledFlyout
      className='embed-link-flyout'
      id={menuId}
      onClose={clearDataAndHandleFlyout}
      placement={popupPlacement}
      primaryButtonDisabled={!embedlyData || !!errorMessage}
      primaryButtonTask={() => {
        handleEmbedlyResponse();
        closeMenu();
        clearDataAndHandleFlyout();
      }}
      primaryButtonText={t('primary_button')}
      secondaryButtonTask={() => {
        clearDataAndHandleFlyout();
        navigateBackToEmbedlyMenu();
      }}
      secondaryButtonText={selectedEmbedMenuItem ? t('back') : t('cancel')}
      title={t('embedly_title', {
        title:
          selectedEmbedMenuItem && selectedEmbedMenuItem !== 'generic'
            ? selectedEmbedMenuItem
            : t('title'),
      })}
      triggerButton={
        <LabelToolbarButton
          active={isMenuOpen}
          ariaLabel={t('aria_label_embed_button')}
          className='flyout-trigger'
          dataTestId='embed-link-flyout-button'
          iconName='link'
          iconType='name'
          id='embed-link-flyout-button'
          label={t('link')}
          onClick={() => setSelectedEmbedMenuItem(undefined)}
        />
      }
    >
      <EmbedlyInput />
    </StyledFlyout>
  );
};

export default EmbedLinkFlyout;
