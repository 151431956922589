import React, { useCallback } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import ComposeButton from '../../../../../ai/ComposeButton/ComposeButton';
import LabelToolbarButton from '../LabelToolbarButton';

const t = initTranslations('editor_toolbar.insert_options');

type Props = {
  onClick: () => void;
};

const ComposeLabelToolbarButton = ({ onClick }: Props) => {
  return (
    <LabelToolbarButton
      active={false}
      ariaLabel={t('aria_for_compose')}
      iconName='magic-wand-sparkles'
      iconType='name'
      id='compose-button'
      label={t('compose')}
      onClick={onClick}
    />
  );
};

const ComposeToolbarButton = () => {
  const customButton = useCallback((onClick) => {
    return <ComposeLabelToolbarButton onClick={onClick} />;
  }, []);

  return (
    <ComposeButton
      composeModalType='editorComposeStep'
      customButton={customButton}
      disabled={false}
    />
  );
};

export default ComposeToolbarButton;
