import React, { useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import { OnboardingProvider, useOnboarding } from '../../../../contexts/OnboardingContext';
import initTranslations from '../../../../lib/initTranslations';
import ChooseOnboardingVariationStep from './ChooseOnboardingVariationStep/ChooseOnboardingVariationStep';
import CustomizeAccentColorStep from './CustomizeThemeStep/CustomizeAccentColorStep';
import CustomizeLogoStep from './CustomizeThemeStep/CustomizeLogoStep';
import CustomizeYourExperienceStep from './CustomizeYourExperienceStep/CustomizeYourExperienceStep';
import OnboardingLivePreviewImage from './OnboardingLivePreviewImage/OnboardingLivePreviewImage';
import OnboardingStep from './OnboardingStep/OnboardingStep';
import { OnboardingWrapper } from './styles';
import { UserInfoStep } from './UserInfoStep/UserInfoStep';

const t = initTranslations('home.onboarding.step_titles');

const OnboardingSlider = () => {
  const { sliderRef, setCurrentSlide } = useOnboarding();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setInnerHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const sliderSettings: Settings = {
    accessibility: false,
    beforeChange: (oldIndex: number, newSlider: number) => setCurrentSlide(newSlider),
    dots: false,
    speed: 500,
    arrows: false,
    infinite: false,
    vertical: true,
    verticalSwiping: true,
    draggable: false,
    swipe: false,
  };

  return (
    <OnboardingWrapper innerHeight={innerHeight}>
      <Slider {...sliderSettings} ref={sliderRef}>
        <OnboardingStep number={1} title={t('tell_us_about_yourself')}>
          <UserInfoStep />
        </OnboardingStep>
        <OnboardingStep number={2} title={t('customize_your_experience')}>
          <CustomizeYourExperienceStep />
        </OnboardingStep>
        <OnboardingStep number={3} title={t('customize_your_theme')}>
          <CustomizeLogoStep />
        </OnboardingStep>
        <CustomizeAccentColorStep />
        <ChooseOnboardingVariationStep />
      </Slider>
      <OnboardingLivePreviewImage />
    </OnboardingWrapper>
  );
};

export const Onboarding = () => (
  <OnboardingProvider>
    <OnboardingSlider />
  </OnboardingProvider>
);
