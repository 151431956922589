import React, { ReactElement } from 'react';

import useDisplayFlashOnResponse, {
  ReduxDisplayResult,
} from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import ConfirmationModal from '../../../../../design_system/overlays/modals/ConfirmationModal';

const t = initTranslations('delete_modal');

export interface Props {
  closeRequest: () => void;
  deleteElement: () => void;
  result: ReduxDisplayResult;
  elementType: string;
  elementTitle: string;
}

const DeleteCurriculumElementModal = ({
  closeRequest,
  elementTitle,
  result,
  elementType,
  deleteElement,
}: Props): ReactElement => {
  useDisplayFlashOnResponse({
    result,
    errorMessage: t('error_flash', { title: elementTitle }),
    successFunction: () => closeRequest(),
  });

  return (
    <ConfirmationModal
      actionFunction={deleteElement}
      actionText={t('delete')}
      heapModalName='delete-curriculum-element-modal'
      message={t('element_title', {
        element: elementTitle === '' ? t('this_element', { elementType }) : elementTitle,
      })}
      onCloseRequest={closeRequest}
      processing={result.isLoading}
      title={t('delete_element', { element: elementType })}
    />
  );
};

export default DeleteCurriculumElementModal;
