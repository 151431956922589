import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { Route } from 'type-route';
import { v4 as uuidv4 } from 'uuid';

import fetchFilenameFromResponseHeader from '../../../../../lib/document_import/fetchFilenameFromResponseHeader';
import getFileExportUrl from '../../../../../lib/document_import/getFileExportUrl';
import initTranslations from '../../../../../lib/initTranslations';
import { Sector } from '../../../../../types/Sector';
import AssistiveText from '../../../../design_system/core/AssistiveText';
import FieldLabel from '../../../../design_system/core/FieldLabel';
import InputField from '../../../../design_system/Triage/InputField';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import { GOOGLE_DRIVE_SCOPE } from '../../modals/ImportFromDocumentModal/shared/constants/documentImport';
import { FormValues } from '../types';
import { InputWrapper, StyledButton, UrlFormWrapper } from './styles';
import { UrlFormInputProps } from './types';

const t = initTranslations('bulk_document_import');

const UrlFormInput = ({
  dispatch,
  maxFilesReached,
}: UrlFormInputProps & { maxFilesReached: boolean }) => {
  const route = useRoute();
  const {
    params: { sector },
  } = route as Route<typeof routes.bulkDocumentImport>;
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldError,
    isValid,
  } = useFormikContext<FormValues>();

  const handleOAuthSuccess = async (googleUser: TokenResponse) => {
    const accessToken = googleUser.access_token;
    const exportUrl = getFileExportUrl(values.documentLink);

    const response = await fetch(exportUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    if (response.status === 200) {
      const blob = await response.blob();
      const file = new File([blob], 'tmpFile.html', { type: 'text/html' });
      const contentDispositionHeader = response.headers.get('Content-Disposition') || '';
      const fileData = {
        file,
        id: uuidv4(),
        sector: (sector || 'process') as Sector,
        title: fetchFilenameFromResponseHeader(contentDispositionHeader),
      };
      dispatch({ type: 'addImportFiles', bulkDocumentImportData: [fileData] });
    } else if (response.status === 404) {
      setFieldError('documentLink', t('file_not_found'));
    } else {
      setFieldError('documentLink', t('unauthorized'));
    }
  };

  const fetchFileByDocumentLink = useGoogleLogin({
    scope: GOOGLE_DRIVE_SCOPE,
    onSuccess: handleOAuthSuccess,
    onError: () => setFieldError('documentLink', t('unauthorized')),
  });

  useEffect(() => {
    if (isSubmitting && isValid) {
      fetchFileByDocumentLink();
    }
  }, [isSubmitting, isValid, fetchFileByDocumentLink]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <FieldLabel text={t('input_label')} />
      <UrlFormWrapper>
        <InputWrapper>
          <InputField
            disabled={maxFilesReached}
            id='google-document-link'
            name='documentLink'
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder={t('input_placeholder')}
            type='text'
            value={values.documentLink}
          />
          {touched.documentLink && errors.documentLink && (
            <AssistiveText
              id='google-document-link-error'
              text={errors.documentLink}
              type='error'
            />
          )}
        </InputWrapper>
        <StyledButton
          buttonType='secondary'
          disabled={!values.documentLink || maxFilesReached}
          id='add-google-doc-file-by-link-button'
          onClick={() => handleSubmit()}
          text={t('add_file')}
        />
      </UrlFormWrapper>
    </>
  );
};

export default UrlFormInput;
