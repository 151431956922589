import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useEmbedlyContext } from '../../../../contexts/EmbedlyProvider';
import initTranslations from '../../../../lib/initTranslations';
import EmbedlyInput from '../../editor/plugins/Embedly/EmbedlyInput';
import { CheckBannerProps } from '../MarketingSidebar/MarketingSidebar';
import { VideoModalCheckBannerSection } from './VideoModal';

const EmbedlyInputWrapper = styled.div(
  ({ theme: { constants } }) => css`
    padding-top: ${constants.spacerMd3};
    padding-bottom: ${constants.spacerLg1};
  `
);

const t = initTranslations('video.video_modal.check_banner_text');

const checkBannerOptions: Array<CheckBannerProps> = [
  {
    subtitle: t('embed_external_videos.subtitle'),
    title: t('embed_external_videos.title'),
  },
  {
    subtitle: t('watch_accountability.subtitle'),
    title: t('watch_accountability.title'),
    icon: 'circle-xmark',
  },
  {
    subtitle: t('search_within_video.subtitle'),
    title: t('search_within_video.title'),
    icon: 'circle-xmark',
  },
  {
    subtitle: t('time_stamped_transcripts.subtitle'),
    title: t('time_stamped_transcripts.title'),
    icon: 'circle-xmark',
  },
];
const ExternalLinkModalBody = () => {
  const { setProviderType } = useEmbedlyContext();

  useEffect(() => {
    setProviderType(['video']);
  }, [setProviderType]);

  return (
    <>
      <EmbedlyInputWrapper>
        <EmbedlyInput />
      </EmbedlyInputWrapper>
      <VideoModalCheckBannerSection checkBannerOptions={checkBannerOptions} />
    </>
  );
};
export default ExternalLinkModalBody;
