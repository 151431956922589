import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { ChooseOptionsFormTitle } from '../../../styles';
import { ResultComponentProps } from '../types';
import ChoiceElement from './ChoiceElement/ChoiceElement';

const t = initTranslations('survey_results');

const ChooseOptionResult = ({ answeredQuestion }: ResultComponentProps) => {
  const { options, correct, optionId, optionIds } = answeredQuestion;
  const selectedOptionId: number = optionId ?? optionIds[0];

  return (
    <>
      <ChooseOptionsFormTitle>{t('choose_option_title')}</ChooseOptionsFormTitle>
      {options.map((option) => (
        <ChoiceElement
          correct={correct}
          id={option.id}
          isSelected={selectedOptionId === option.id}
          key={option.id}
          position={option.position}
          text={option.text}
        />
      ))}
    </>
  );
};

export default ChooseOptionResult;
