import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import { fontSm4 } from '../../../../../styled/TypeSystem';

const DividerContainer = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    background-color: ${vars.foundationSurface1};
    padding: ${`${constants.spacerMd2} ${constants.spacerMd3}`};
    justify-content: center;
    align-items: center;
    gap: ${constants.spacerSm3};
    align-self: stretch;
    color: ${vars.textSubdued};
    width: 100%;
  `
);

const DividerText = styled.span`
  line-height: ${({ theme: { constants } }) => constants.height2xs};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm4};
`;

const t = initTranslations('training_path.path_overview.overview_sets.divider');

const Divider = () => {
  return (
    <DividerContainer className='training-path-divider'>
      <Icon name='clipboard-check' size='xs' />
      <DividerText>{t('divider_text')}</DividerText>
    </DividerContainer>
  );
};

export default Divider;
