import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../../styled/Breakpoint';

export const ResponsibilityDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerLg3};
  width: 100%;

  @media (min-width: ${mediaBreakpointPxSm}) {
    &:first-child {
      padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
      flex: 0 0 60%;
    }
    &:last-child {
      flex: 1;
      margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
    }
  }
`;
