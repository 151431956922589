import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useMemo, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../../../lib/initTranslations';
import { useUpdateStepMutation } from '../../../../../../../../redux/services/resourceApis/steps/stepsApi';
import { MetaUserAccess } from '../../../../../../../../types/Curriculum';
import { LimitedStep } from '../../../../../../../../types/Step';
import { useAccountTerminology } from '../../../../../../../AccountTerminologyProvider';
import Badge from '../../../../../../../design_system/display/badge';
import Icon from '../../../../../../../design_system/display/icons/Icon';
import EmojiSelect from '../../../../../../ContentLibrary/components/SubjectEmoji/EmojiSelect';
import { routes } from '../../../../../../publicApplication/applicationRouter';
import useActiveMenuHandler from '../../../../../../publicApplication/utils/useActiveMenuHandler';
import EditableCurriculumElementTitle from '../../../../../../shared/CurriculumElements/EditableCurriculumElementTitle';
import { viewableUserAccess } from '../../../../../../shared/helpers';
import StepThreeDot from '../../../../../../shared/StepThreeDot/StepThreeDot';
import { OutlineRowContainer } from '../../../../../shared/CurriculumElementRowStyles';
import { RowCardBody } from '../../../../../shared/CurriculumRowStyles';

const EmojiWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-inline: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const StyledBadge = styled(Badge)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const OutlineStepRowContainer = styled.div`
  display: flex;
`;

const GripVerticalIconWrapper = styled.div<{ remainVisible: boolean }>`
  margin: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerSm3} ${constants.spacerMd2} 0`};
  min-width: 1rem;
  opacity: ${({ remainVisible }) => (remainVisible ? 1 : 0)};

  ${OutlineStepRowContainer}:hover & {
    opacity: 1;
  }
`;

const StepThreeDotWrapper = styled.div<{ remainVisible: boolean }>`
  opacity: ${({ remainVisible }) => (remainVisible ? 1 : 0)};
  margin: 0.75rem 0;
  min-width: 2rem;

  ${OutlineStepRowContainer}:hover & {
    opacity: 1;
  }
`;

export type OutlineStepTableRowProps = {
  curriculumId: number;
  isLocked?: boolean;
  step: LimitedStep;
  canBeModified: boolean;
  provided: DraggableProvided;
  blockEditPrivileges: boolean;
  userAccess?: MetaUserAccess;
  isLastStep: boolean;
};

const t = initTranslations('curriculums_view');

const OutlineStepTableRow = ({
  canBeModified,
  curriculumId,
  isLocked,
  step,
  provided,
  blockEditPrivileges,
  userAccess,
  isLastStep,
}: OutlineStepTableRowProps) => {
  const { id, title, emoji, courseId } = step;
  const { slug } = useCurrentAccount();
  const [isEditing, setIsEditing] = useState(false);
  const {
    step: { singular: stepTermSingular },
  } = useAccountTerminology();

  const { isMenuOpen } = useActiveMenuHandler({
    menuId: `step-three-dot-${step.id}`,
  });

  const [updateStep, result] = useUpdateStepMutation();
  const { isSuccess } = result;

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('update_title_error'),
  });

  const stepUrl = useMemo(() => {
    if (!userAccess || userAccess === 'pending') {
      return routes.curriculumRequestAccess({ id: curriculumId, slug }).href;
    } else if (viewableUserAccess(userAccess)) {
      return routes.consume({ slug, id }).href;
    } else {
      return routes.editor({ slug, id }).href;
    }
  }, [curriculumId, id, slug, userAccess]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='outline-step-row'
      id={`step-row-${id}`}
    >
      <OutlineStepRowContainer className='outline-step-container'>
        <GripVerticalIconWrapper remainVisible={isMenuOpen}>
          <Icon name='grip-vertical' weight='solid' />
        </GripVerticalIconWrapper>
        <OutlineRowContainer>
          <RowCardBody isLocked={isLocked || blockEditPrivileges}>
            <StyledBadge text={stepTermSingular} type='general' />
            <EmojiWrapper id={`step-${id}-row-emoji-picker`}>
              <EmojiSelect
                clearEmoji={() => {
                  updateStep({ id, emoji: null, courseId });
                }}
                emoji={emoji}
                noEmojiIconName='list-ol'
                noEmojiIconWeight='light'
                onEmojiSelect={(emoji: BaseEmoji) => {
                  updateStep({ emoji: emoji.native, id, courseId });
                }}
              />
            </EmojiWrapper>
            <EditableCurriculumElementTitle
              editable={!blockEditPrivileges}
              id={id}
              isEditing={isEditing}
              isLocked={isLocked}
              isSuccess={isSuccess}
              maxCharacters={250}
              route={stepUrl}
              setIsEditing={setIsEditing}
              title={title}
              updateElement={({ id, title }) => updateStep({ id, title, courseId })}
            />
          </RowCardBody>
        </OutlineRowContainer>
        <StepThreeDotWrapper remainVisible={isMenuOpen}>
          {!blockEditPrivileges && (
            <StepThreeDot
              canBeModified={canBeModified}
              isLastStep={isLastStep}
              isLocked={isLocked}
              renameClick={() => setIsEditing(true)}
              step={step}
              viewingFrom='curriculum'
            />
          )}
        </StepThreeDotWrapper>
      </OutlineStepRowContainer>
    </div>
  );
};

export default OutlineStepTableRow;
