import React, { useState } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDefaultSettingsMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { Option } from '../../../../design_system/core/SelectOption/types';
import Toggle from '../../../../design_system/input/controls/Toggle';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import { GROUP_VIEW_BY_OPTIONS } from '../../../ContentLibrary/ContentLibraryPage/libraryReducer';
import { ButtonRightPositionWrapper } from '../../OrganizationProfile/styles';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { ViewByOption } from '../types';

const Wrapper = styled.div`
  width: 100%;
`;

const t = initTranslations('account_settings.advanced_settings');

interface ContentPageDefaultViewSectionProps {
  contentPageDefaultViewBy: ViewByOption;
}

const ContentPageDefaultViewSection = ({
  contentPageDefaultViewBy,
}: ContentPageDefaultViewSectionProps) => {
  const viewBySectionOpened = contentPageDefaultViewBy !== null;
  const {
    curriculum: { plural: curriculumTermPlural },
  } = useAccountTerminology();
  const [updateAccountDefaultData, result] = useUpdateAccountDefaultSettingsMutation();
  const [currentViewByValue, setCurrentViewByValue] =
    useState<ViewByOption>(contentPageDefaultViewBy);
  const { isLoading } = result;

  const options: Option[] = GROUP_VIEW_BY_OPTIONS.map((viewByOption): Option => {
    return {
      label: viewByOption.label,
      searchableTerm: viewByOption.label,
      value: viewByOption.value,
    };
  });

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div className='content-view-by-section'>
      <FormSectionContainer>
        <FormSectionTitle>{t('content_page_settings.title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={viewBySectionOpened}
            className='account-settings-default-view-by-toggle'
            disabled={isLoading}
            handleToggle={() => {
              updateAccountDefaultData({
                content_page_default_view_by: viewBySectionOpened ? null : 'all_content',
              });
              setCurrentViewByValue(viewBySectionOpened ? null : 'all_content');
            }}
            id='enable-view-by-sector'
            name='enable_vie_by_sector'
          />
        </ToggleContainer>
      </FormSectionContainer>
      {viewBySectionOpened && (
        <>
          <FormSectionContainer>
            <FormSectionDescription>
              {t('content_page_settings.description', { curriculums: curriculumTermPlural })}
            </FormSectionDescription>
          </FormSectionContainer>
          <FormSectionContainer>
            <Wrapper>
              <SingleSelectField
                className='account-settings-default-view-by-dropdown'
                defaultValue={currentViewByValue}
                hideSelectedOptions={false}
                isSearchable={false}
                menuPosition='fixed'
                onNonDefaultSelected={(value: ViewByOption) => setCurrentViewByValue(value)}
                options={options}
                placeholder={t('content_page_settings.placeholder')}
                value={currentViewByValue}
              />
            </Wrapper>
          </FormSectionContainer>
          <ButtonRightPositionWrapper>
            <DefaultButton
              disabled={isLoading || currentViewByValue === contentPageDefaultViewBy}
              id='save-content-page-default-view'
              loading={isLoading}
              onClick={() =>
                updateAccountDefaultData({ content_page_default_view_by: currentViewByValue })
              }
              text={t('save_default_view')}
            />
          </ButtonRightPositionWrapper>
        </>
      )}
    </div>
  );
};

export default ContentPageDefaultViewSection;
