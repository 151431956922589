import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { PdfDownloadsSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const PdfDownloadsSection = ({ enablePrinting, id }: PdfDownloadsSectionProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('pdf_downloads_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={enablePrinting}
            className='pdf-downloads-setting'
            handleToggle={(e) => updateAccountData({ enable_printing: e.target.checked })}
            id='enable-pdf-downloads'
            name='enable_pdf_downloads'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('pdf_downloads_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default PdfDownloadsSection;
