import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import usePageTitle from '../../../../hooks/usePageTitle';
import initTranslations from '../../../../lib/initTranslations';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import PageContent from '../../shared/page_content';
import NotificationsIndexView from '../NotificationsIndexView/NotificationsIndexView';

const HeaderWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const t = initTranslations('navigation.header');

const NotificationsPage = () => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'notifications', productTerm });

  return (
    <PageContent id='notifications-page'>
      <HeaderWrapper>
        <PageHeader title={t('notifications_title')} />
      </HeaderWrapper>
      <NotificationsIndexView />
    </PageContent>
  );
};

export default NotificationsPage;
