import React from 'react';

import { useBulkToggleArchiveMutation } from '../../../../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import { BulkContentArchiveModalProps } from '../../types';
import BulkContentUpdateModal from './BulkContentUpdateModal';

const BulkContentArchive = ({
  onClose,
  selectedIds,
  onSuccess,
  type,
  curriculumsChecked,
}: BulkContentArchiveModalProps) => {
  const [toggleArchive, toggleArchiveResult] = useBulkToggleArchiveMutation();

  return (
    <BulkContentUpdateModal
      bulkUpdate={({ curriculumIds }) => toggleArchive({ ids: curriculumIds })}
      bulkUpdateResult={toggleArchiveResult}
      curriculumsChecked={curriculumsChecked}
      onClose={onClose}
      onSuccess={onSuccess}
      selectedIds={selectedIds}
      type={type}
    />
  );
};

export default BulkContentArchive;
