import React, { FC } from 'react';

import { DEFAULT_LINE_WIDTH } from './data';
import { Circle, TextLine, Wrapper } from './styles';
import { SkeletonProps } from './types';

const Skeleton: FC<SkeletonProps> = ({ lineWidth = DEFAULT_LINE_WIDTH }) => {
  return (
    <Wrapper>
      <Circle />
      <TextLine lineWidth={lineWidth} />
    </Wrapper>
  );
};

export default Skeleton;
