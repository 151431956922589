import styled from 'styled-components';

import Link from '../../../../../design_system/Link';
import { fontSm4 } from '../../../../../styled/TypeSystem';

export const SeatsDecreasingErrorWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { vars } }) => vars.stateBackgroundError};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => `calc(${constants.spacerSm3} + ${constants.spacerSm2})`};
  ${fontSm4};
`;

export const StyledLink = styled(Link)`
  ${fontSm4};
`;
