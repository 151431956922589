import React from 'react';

import { useGroupsModals } from '../../../../contexts/GroupsModalContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import { RegisteredMenuId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { Group } from '../../../../types/Group';
import ThreeDotMenu from '../../../design_system/Triage/menus/ThreeDotMenu';
import { routes } from '../../publicApplication/applicationRouter';

const t = initTranslations('groups');

interface Props {
  group: Group;
}

const ActionMenu = ({ group }: Props) => {
  const { dispatch } = useGroupsModals();
  const { slug } = useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const menuId: RegisteredMenuId = `group-three-dot-${group.id}`;
  const navigateToGroup = () => {
    routes
      .group({
        slug,
        id: group.id,
        breadcrumb: routes.groups({ slug }),
        tab: 'overview',
      })
      .push();
  };

  return (
    <ThreeDotMenu
      id={menuId}
      menuOptions={[
        {
          title: t('edit'),
          onClick: () => navigateToGroup(),
          iconName: 'edit',
          id: 'td-groups-index-edit-group',
          visible: true,
        },
        {
          title: t('delete'),
          onClick: () => dispatch({ type: 'openDeleteGroupModal', payload: group }),
          iconName: 'trash-alt',
          id: 'td-groups-index-delete-group',
          visible: ability.can('destroy', group),
        },
      ]}
      menuPlacement='bottom-end'
    />
  );
};

export default ActionMenu;
