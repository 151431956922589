import React, { useCallback, useEffect, useState } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateCurriculumElementServiceMutation } from '../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { useGetSimpleCurriculumsQuery } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { ElementKind } from '../../../../../types/CurriculumElement';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import MoveCurriculumElementModal from './index';

const t = initTranslations('move_element_modal');

interface Props {
  closeModal: () => void;
  curriculumElementId: number;
  curriculumId: number;
  elementName: string;
  elementKind: ElementKind;
  movementRestricted: boolean;
  showRestrictModal: () => void;
}

const MoveCurriculumElementModalWrapper = ({
  closeModal,
  curriculumElementId,
  curriculumId,
  elementName,
  elementKind,
  movementRestricted,
  showRestrictModal,
}: Props) => {
  const [moveElement, result] = useUpdateCurriculumElementServiceMutation();
  const [disabled, setDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const { flash } = useFlashNotification();
  const { isSuccess } = result;

  const moveElementCallback = useCallback(() => {
    if (!selectedValue) return;
    if (movementRestricted) {
      showRestrictModal();
      closeModal();
    }
    moveElement({
      newCurriculumId: selectedValue,
      curriculumElementId,
    });
  }, [
    closeModal,
    curriculumElementId,
    moveElement,
    movementRestricted,
    selectedValue,
    showRestrictModal,
  ]);

  // Fetch curriculum options for move dropdown
  const { data, isFetching, isError } = useGetSimpleCurriculumsQuery();
  useEffect(() => {
    if (isSuccess) {
      closeModal();
      flash('info', t('success'));
    }
  }, [closeModal, flash, elementName, data, selectedValue, isSuccess]);
  if (isFetching) return null;
  if (isError) return <div>{t('error')}</div>;
  if (!data) return <></>;
  const curriculums = data.filter((cur) => cur.id != curriculumId);

  return (
    <MoveCurriculumElementModal
      closeModal={closeModal}
      curriculumElementId={curriculumElementId}
      curriculums={curriculums}
      disabled={disabled}
      elementKind={elementKind}
      elementName={elementName}
      moveElement={moveElementCallback}
      movementRestricted={movementRestricted}
      processing={false}
      selectedValue={selectedValue}
      setDisabled={setDisabled}
      setSelectedValue={setSelectedValue}
    />
  );
};

export default MoveCurriculumElementModalWrapper;
