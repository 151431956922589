import styled, { css } from 'styled-components';

import { MultilineTruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontMd1, fontSm5 } from '../../../../styled/TypeSystem';

export const ListItemContainer = styled.div<{ isActive?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  ${({ isActive, theme: { vars } }) =>
    isActive &&
    css`
      background-color: ${vars.foundationHover};
    `}
`;

export const SurveyQuestionsLabel = styled.h4`
  margin: ${({ theme: { constants } }) =>
    `0 ${constants.spacerMd3} ${constants.spacerMd2} ${constants.spacerMd3}`};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;

export const QuestionsWrapper = styled.div`
  width: 100%;
`;

export const PositionText = styled.span`
  min-width: 0.75rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd1};
`;

export const QuestionText = styled.span<{ isActive?: boolean; lineClamp?: number }>`
  width: 100%;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  overflow-wrap: break-word;
  cursor: pointer;
  ${({ isActive, theme: { vars, constants } }) =>
    isActive &&
    css`
      color: ${vars.accentPrimaryDefault};
      font-weight: ${constants.fontMedium};
    `}

  ${({ lineClamp }) => MultilineTruncatedText({ lineClamp: !!lineClamp ? lineClamp : 1 })};
  ${fontSm5};
`;
