import React, { FC } from 'react';

import { LOW_LEVELS_SEARCH_RESULT_KEYS } from '../../_data';
import { Course, Curriculum, ExpandedEntityKeys, Step, TypeName } from '../../types';
import Accordion from '../Accordion';
import { RecursiveComponentProps } from './types';

const RecursiveComponent: FC<RecursiveComponentProps> = ({ data }) => {
  const targetItemsKey = LOW_LEVELS_SEARCH_RESULT_KEYS[data.__typename] as ExpandedEntityKeys;

  if (data.__typename === TypeName.Step) {
    return <Accordion data={data} />;
  }

  return (
    <Accordion data={data}>
      {data[targetItemsKey]?.map((item: Curriculum | Course | Step, index) => (
        // TODO: Remove literal key because its on backend
        <RecursiveComponent data={item} key={`${item.id}-${index}`} />
      ))}
    </Accordion>
  );
};

export default RecursiveComponent;
