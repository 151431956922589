import AddonCardInfoESignature from '../../images/addons/e_signature/addon_logo_e_signature.svg';
import initTranslations from '../../lib/initTranslations';
import { AddonName } from '../../types/Addon';

const t = initTranslations(
  'simple_pricing.addon_overlay_content.info_card.images_alt.addon_info_card_icon'
);

interface AddonInfoCardIcon {
  altText: string;
  src: string;
}

const useAddonInfoCardIcon = (addonName: AddonName): AddonInfoCardIcon => {
  const getAddonInfoCardIcon = () => {
    switch (addonName) {
      default:
        return AddonCardInfoESignature;
    }
  };

  const altText = t(addonName);
  const src = getAddonInfoCardIcon();

  return { altText, src };
};

export default useAddonInfoCardIcon;
