import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import NoUsersEmptyStateDark from '../../../../../../../assets/images/dark_mode/empty_state/no-users-exist-groups-empty-state.svg';
import NoUsersEmptyStateLight from '../../../../../../../assets/images/empty_state/no-users-exist-groups-empty-state.svg';
import { useGroup } from '../../../../../contexts/GroupContext';
import { PaywallContext } from '../../../../../contexts/PaywallContext';
import { TeammateModalProvider } from '../../../../../contexts/TeammateContext';
import useClientPagnation from '../../../../../hooks/useClientPagnation';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import DetailedAvatar from '../../../../design_system/display/DetailedAvatar/DetailedAvatar';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import CompletionDetails from '../../../people/Profile/SubjectsAssigned/CompletionDetails';
import { routes } from '../../../publicApplication/applicationRouter';
import { Title } from '../../../shared/DetailedTitle/Title';
import GroupMembersModal from '../../modals/GroupMembersModal/GroupMembersModal';
import {
  ActionMenuWrapper,
  DataCell,
  DataRow,
  HeaderCell,
  HeaderRow,
  LargeDataCell,
  LargeHeaderCell,
  MediumDataCell,
  MediumHeaderCell,
  SmallDataCell,
  SmallHeaderCell,
  StyledTable,
  TableAndActionsWrapper,
  TableNoResultsWrapper,
  TableWrapper,
} from '../../shared/table_styles';
import ActionMenu from './ActionMenu';

const t = initTranslations('groups.groups_edit.members.table');

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaginationWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const MembersTable = () => {
  const group = useGroup();
  const { users: members } = group;
  const [groupMembersModalOpen, setGroupMembersModalOpen] = useState(false);
  const { isMobile } = useWindowResize();
  const hasMembers = !!members.length;
  const {
    slug,
    splitFeatures: { groupsCompletionsEnabled },
  } = useCurrentAccount();
  const paywallCtx = useContext(PaywallContext);
  const completionsPaywalled = paywallCtx.includes('completions');
  const isEveryoneGroup = group.everyone;
  const showCompletions = groupsCompletionsEnabled && !completionsPaywalled;
  const memberIds = members.map((member) => {
    return member.id;
  });
  const MemberDetailsHeader = isMobile ? LargeHeaderCell : HeaderCell;
  const MemberDetails = isMobile ? LargeDataCell : DataCell;
  const itemCountPerPage = 50;
  const { filteredItems, pagination } = useClientPagnation(members, itemCountPerPage);

  return (
    <TableAndActionsWrapper>
      <ActionsWrapper>
        <Title fontSize='md2' fontWeight='semibold' title={t('title')} />
        {!isEveryoneGroup && (
          <DefaultButton
            id='groups-members-table-manage-button'
            onClick={() => setGroupMembersModalOpen(true)}
            text={hasMembers ? t('buttons.manage') : t('buttons.add_members')}
          />
        )}
      </ActionsWrapper>
      <TableWrapper>
        <StyledTable>
          <thead>
            <HeaderRow>
              <MemberDetailsHeader>
                <Title
                  fontSize='sm4'
                  fontWeight='semibold'
                  title={t('header_titles.name_and_title')}
                />
              </MemberDetailsHeader>
              <LargeHeaderCell>
                <Title fontSize='sm4' fontWeight='semibold' title={t('header_titles.reports_to')} />
              </LargeHeaderCell>
              {showCompletions && (
                <LargeHeaderCell>
                  <Title
                    fontSize='sm4'
                    fontWeight='semibold'
                    title={t('header_titles.completion')}
                  />
                </LargeHeaderCell>
              )}
              <MediumHeaderCell>
                <Title fontSize='sm4' fontWeight='semibold' title={t('header_titles.permission')} />
              </MediumHeaderCell>
              <SmallHeaderCell>
                <Title fontSize='sm4' fontWeight='semibold' title='' />
              </SmallHeaderCell>
            </HeaderRow>
          </thead>
          <tbody>
            {hasMembers ? (
              filteredItems.map((member) => {
                return (
                  <DataRow
                    id={`groups-members-table-member-${member.id}`}
                    key={`groups-members-table-member-${member.id}`}
                  >
                    <MemberDetails>
                      <DetailedAvatar
                        avatarImage={member.avatar}
                        id={`user-detailed-avatar-${member.id}`}
                        label={member.name}
                        labelLink={routes.userProfile({ id: member.id, slug }).href}
                        onAvatarClick={() =>
                          routes
                            .userProfile({
                              slug,
                              id: member.id,
                            })
                            .push()
                        }
                        subLabel={member.title}
                      />
                    </MemberDetails>
                    <LargeDataCell>
                      {member.parent && (
                        <Title fontWeight='regular' title={member.parent.name} truncate='oneLine' />
                      )}
                    </LargeDataCell>
                    {showCompletions && (
                      <LargeDataCell>
                        <CompletionDetails
                          className='member-completion-percentage'
                          percent={member.total_score}
                          to={routes.userAssignedCurriculums({ id: member.id, slug })}
                        />
                      </LargeDataCell>
                    )}
                    <MediumDataCell>
                      {member.permission && t(`users.permissions.${member.permission}`)}
                    </MediumDataCell>
                    <SmallDataCell>
                      <ActionMenuWrapper>
                        <TeammateModalProvider userId={member.id}>
                          <ActionMenu member={member} memberIds={memberIds} />
                        </TeammateModalProvider>
                      </ActionMenuWrapper>
                    </SmallDataCell>
                  </DataRow>
                );
              })
            ) : (
              <DataRow>
                <td colSpan={showCompletions ? 5 : 4}>
                  <TableNoResultsWrapper>
                    <NoResults
                      className='groups-members-table-no-results'
                      darkImage={NoUsersEmptyStateDark}
                      heading={t('empty_state.header')}
                      iconWidth='20%'
                      lightImage={NoUsersEmptyStateLight}
                      minHeight='unset'
                      showBorder={false}
                      subHeaderCta={{
                        action: () => setGroupMembersModalOpen(true),
                        text: t('empty_state.action_link'),
                      }}
                    />
                  </TableNoResultsWrapper>
                </td>
              </DataRow>
            )}
          </tbody>
        </StyledTable>
      </TableWrapper>
      {hasMembers && (
        <PaginationWrapper>
          <Pagination {...pagination} showPaginationDetails />
        </PaginationWrapper>
      )}

      {groupMembersModalOpen && (
        <GroupMembersModal
          groupId={group.id}
          onCloseRequest={() => setGroupMembersModalOpen(false)}
        />
      )}
    </TableAndActionsWrapper>
  );
};

export default MembersTable;
