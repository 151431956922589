import React, { FC } from 'react';

import { RegisteredMenuId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import Badge from '../../../design_system/display/badge';
import useActiveMenuHandler from '../../publicApplication/utils/useActiveMenuHandler';
import BoardColumnThreeDot from '../dropdownMenus/BoardColumnThreeDot/BoardColumnThreeDot';
import { StyledIcon } from '../ResponsibilityCard/styles';
import { BoardColumnToolsContainer } from './styles';
import { BoardColumnToolsProps } from './types';

const t = initTranslations('delegation_planner.board_column_tools');

const BoardColumnTools: FC<BoardColumnToolsProps> = ({
  totalWorkingTime,
  columnId,
  hideThreeDot,
}) => {
  const menuId: RegisteredMenuId = `column-three-dot-${columnId}`;

  const { isMenuOpen } = useActiveMenuHandler({
    menuId,
  });

  return (
    <BoardColumnToolsContainer isVisibleThreeDot={isMenuOpen}>
      <Badge
        icon={<StyledIcon name='clock' size='2xs' weight='regular' />}
        size='md'
        text={t('total_hours', { count: totalWorkingTime })}
        type='light'
      />

      {!hideThreeDot && <BoardColumnThreeDot columnId={columnId} menuId={menuId} />}
    </BoardColumnToolsContainer>
  );
};

export default BoardColumnTools;
