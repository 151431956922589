import React from 'react';
import styled, { css } from 'styled-components';

import Image from '../../../../../images/training_paths/illustration.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { fontSm5 } from '../../../../styled/TypeSystem';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ theme: { constants } }) => css`
    padding: ${constants.spacerMd1} ${constants.spacerMd3};
    gap: ${constants.spacerLg2};
  `};
`;

const Illustration = styled.img(
  ({ theme: { constants } }) => css`
    width: 9.375rem;
    margin: ${constants.spacerSm3} 0;
  `
);

const Text = styled.div`
  ${fontSm5};
  line-height: normal;
`;

const t = initTranslations('training_path.path_config.education_banner');

export interface EducationBannerProps {
  userName: string;
}

const EducationBanner = ({ userName }: EducationBannerProps) => {
  const { curriculum } = useAccountTerminology();

  return (
    <StyledDiv>
      <Illustration alt={t('illustration_aria_label')} src={Image} />
      <Text>
        {t('description', { name: userName, curriculums: curriculum.plural.toLowerCase() })}
      </Text>
    </StyledDiv>
  );
};

export default EducationBanner;
