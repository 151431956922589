import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { SendAllInvitesResponse } from '../../../../redux/services/resourceApis/users/types';
import ConfirmationModal from '../../../design_system/overlays/modals/ConfirmationModal';
import OutOfSeatsModal from './OutOfSeatsModal';

const t = initTranslations('users_table.add_teammate_split_button.send_all_invites_modal');

type Props = {
  data: SendAllInvitesResponse | undefined;
  setShowSendAllInvitesModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const SendAllInvitesModal = ({ data, setShowSendAllInvitesModal }: Props) => {
  const {
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();
  const numberOfInvites = data?.numberOfInvites || 0;

  const getSendAllInvitesModalMessage = () => {
    return perUserPricingEnabled && data?.numberOfInvites
      ? t('per_user_pricing.message')
      : t('message', { count: numberOfInvites });
  };

  return (
    <>
      {data?.numberOfNotInvited ? (
        <OutOfSeatsModal data={data} setShowSendAllInvitesModal={setShowSendAllInvitesModal} />
      ) : (
        <ConfirmationModal
          actionFunction={() => setShowSendAllInvitesModal(false)}
          hasPrimaryButton={false}
          heapModalName='send-all-invites-modal'
          message={getSendAllInvitesModalMessage()}
          onCloseRequest={() => setShowSendAllInvitesModal(false)}
          processing={false}
          secondaryButtonText={t('secondary_button_text')}
          title={t('title', { count: numberOfInvites })}
        />
      )}
    </>
  );
};

export default SendAllInvitesModal;
