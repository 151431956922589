import React, { useContext, useReducer } from 'react';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../lib/initTranslations';
import { useGetAccountSettingsQuery } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import CollapsibleSurface from '../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import DefaultCurriculumAccessControlSection from '././DefaultCurriculumAccessControlSection/DefaultCurriculumAccessControlSection';
import BetaFeaturesSection from './BetaFeaturesSection';
import CompanySectorSection from './CompanySectorSection';
import ContentPageDefaultViewSection from './ContentPageDefaultViewSection/ContentPageDefaultViewSection';
import CustomTerminologySection from './CustomTerminologySection';
import CustomTerminologyForm from './CustomTerminologySection/CustomTerminologyForm';
import LockBrandStylesSection from './LockBrandStylesSection';
import PdfDownloadsSection from './PdfDownloadsSection';
import PolicySectorSection from './PolicySectorSection';
import ProfileEmailSection from './ProfileEmailSection/ProfileEmailSection';
import ProfilePhoneNumberSection from './ProfilePhoneNumbersSection';
import PublicCurriculumsSection from './PublicCurriculumsSection/PublicCurriculumsSection';
import RestrictByIpSection from './RestrictByIpSection';
import WhitelistedIpAddressesForm from './RestrictByIpSection/WhitelistedIpAddressesForm';
import SectorLibrariesSection from './SectorLibrariesSection';
import ShareDirectorySection from './ShareDirectorySection';
import DirectoryGroupsForm from './ShareDirectorySection/DirectoryGroupsForm';
import ShareGroupChartSection from './ShareGroupsChartSection';
import GroupChartGroupsForm from './ShareGroupsChartSection/GroupChartGroupsForm';
import ShareOrgChartSection from './ShareOrgChartSection';
import OrgChartGroupsForm from './ShareOrgChartSection/OrgChartGroupsForm';
import SignaturesSection from './SignaturesSection';
import SsoOnlyAccountSection from './SsoOnlyAccountSection';
import { FormSubSection, SectionDivider } from './styles';
import TraditionalMfaSection from './TraditionalMfaSection';

const t = initTranslations('account_settings.advanced_settings');

const AdvancedSettings = () => {
  const { data, isLoading } = useGetAccountSettingsQuery();
  const ability = useCurrentUserAbilities();
  const { allowSignatures } = useCurrentAccount();
  const paywallCtx = useContext(PaywallContext);
  const showGroupChartSettings = ability.can('read', 'GroupChart');
  const restrictLoginByIpPaywalled = paywallCtx.includes('ip_access_restriction');
  const publicCurriculumsPaywalled = paywallCtx.includes('public_curriculums');
  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);
  const {
    splitFeatures: { toggleForMfaEnabled },
  } = useCurrentAccount();

  if (isLoading) return <></>;
  if (!data) return <></>;

  const { advanced_settings: advancedSettings, account_groups: accountGroups } = data;

  const {
    enable_printing: enablePrinting,
    enable_people_directory: enablePeopleDirectory,
    groups_restricted_from_directory: groupsRestrictedFromDirectory,
    enable_org_chart: enableOrgChart,
    groups_restricted_from_org_chart: groupsRestrictedFromOrgChart,
    custom_terminology: customTerminology,
    custom_terminologies: customTerminologies,
    custom_terminology_settings: customTerminologySettings,
    company_sector: companySector,
    policy_sector: policySector,
    public_curriculums: publicCurriculums,
    lock_brand_styles: lockBrandStyles,
    beta_features: betaFeatures,
    sso_only_account: ssoOnlyAccount,
    any_sso_integration_connected: anySsoIntegrationConnected,
    restrict_by_ip: restrictByIp,
    ip_whitelist: ipWhitelist,
    remote_ip: remoteIp,
    enable_profile_phone_number: enableProfilePhoneNumber,
    enable_profile_email: enableProfileEmail,
    enable_role_chart: enableGroupChart,
    groups_restricted_from_role_chart: groupsRestrictedFromRoleChart,
    sector_libraries: enableSectorLibraries,
    default_access_control: defaultAccessControl,
    content_page_default_view_by: contentPageDefaultViewBy,
    mfa_on: mfaOn,
  } = advancedSettings;

  return (
    <CollapsibleSurface
      collapseDispatch={collapseDispatch}
      id='advanced-settings-surface'
      isCollapsed={collapsed}
      title={t('title')}
    >
      {toggleForMfaEnabled && <TraditionalMfaSection id='mfa-on-toggle' mfaOn={mfaOn} />}
      <SectionDivider />
      <PdfDownloadsSection enablePrinting={enablePrinting} id='pdf-downloads-toggle' />
      <SectionDivider />
      <ShareDirectorySection enablePeopleDirectory={enablePeopleDirectory} id='directory-toggle' />
      {enablePeopleDirectory && (
        <FormSubSection id='directory-groups-form'>
          <DirectoryGroupsForm
            accountGroups={accountGroups}
            groupsRestrictedFromDirectory={groupsRestrictedFromDirectory}
          />
        </FormSubSection>
      )}
      <SectionDivider />
      <>
        <ShareOrgChartSection enableOrgChart={enableOrgChart} id='org-chart-toggle' />
        {enableOrgChart && (
          <FormSubSection id='org-chart-groups-form'>
            <OrgChartGroupsForm
              accountGroups={accountGroups}
              groupsRestrictedFromOrgChart={groupsRestrictedFromOrgChart}
            />
          </FormSubSection>
        )}
        <SectionDivider />
      </>
      {showGroupChartSettings && (
        <>
          <ShareGroupChartSection enableGroupChart={enableGroupChart} id='role-chart-toggle' />
          {enableGroupChart && (
            <FormSubSection id='group-chart-groups-form'>
              <GroupChartGroupsForm
                accountGroups={accountGroups}
                groupsRestrictedFromRoleChart={groupsRestrictedFromRoleChart}
              />
            </FormSubSection>
          )}
          <SectionDivider />
        </>
      )}
      <SectorLibrariesSection enableSectorLibraries={enableSectorLibraries} />
      <SectionDivider />
      <ContentPageDefaultViewSection contentPageDefaultViewBy={contentPageDefaultViewBy} />
      <SectionDivider />
      <CompanySectorSection companySector={companySector} id='company-page-toggle' />
      <SectionDivider />
      <PolicySectorSection id='policy-page-toggle' policySector={policySector} />
      <SectionDivider />
      <DefaultCurriculumAccessControlSection
        defaultCurriculumAccessControl={defaultAccessControl}
        id='content-discoverability-select'
      />
      <SectionDivider />
      <CustomTerminologySection
        enableCustomTerminology={customTerminology}
        id='custom-terminology-toggle'
      />
      {customTerminology && (
        <FormSubSection id='custom-terminology-form'>
          <CustomTerminologyForm
            customTerminologies={customTerminologies}
            customTerminologySettings={customTerminologySettings}
          />
        </FormSubSection>
      )}
      <SignaturesSection accountSettingsState={data} allowSignatures={allowSignatures} />
      {!publicCurriculumsPaywalled && (
        <>
          <SectionDivider />
          <PublicCurriculumsSection publicCurriculums={publicCurriculums} />
        </>
      )}
      <SectionDivider />
      <LockBrandStylesSection id='lock-brand-styles-toggle' lockBrandStyles={lockBrandStyles} />
      <SectionDivider />
      <BetaFeaturesSection betaFeatures={betaFeatures} id='beta-features-toggle' />
      <SectionDivider />
      {anySsoIntegrationConnected && (
        <>
          <SsoOnlyAccountSection id='sso-only-login-toggle' ssoOnlyAccount={ssoOnlyAccount} />
          <SectionDivider />
        </>
      )}
      {!restrictLoginByIpPaywalled && (
        <>
          <RestrictByIpSection id='restrict-by-ip-toggle' restrictByIp={restrictByIp} />
          {restrictByIp && (
            <FormSubSection id='restrict-by-ip-form'>
              <WhitelistedIpAddressesForm ipWhitelist={ipWhitelist} remoteIp={remoteIp} />
            </FormSubSection>
          )}
          <SectionDivider />
        </>
      )}
      <ProfilePhoneNumberSection
        enableProfilePhoneNumber={enableProfilePhoneNumber}
        id='display-phone-numbers-toggle'
      />
      <SectionDivider />
      <ProfileEmailSection enableProfileEmail={enableProfileEmail} id='display-emails-toggle' />
    </CollapsibleSurface>
  );
};

export default AdvancedSettings;
