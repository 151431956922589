import styled from 'styled-components';

import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Icon from '../../../design_system/display/icons/Icon';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontMd5, fontSm5 } from '../../../styled/TypeSystem';

export const OverlayContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UploadFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  height: 100%;
  width: 80%;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg3} 0`};
`;

export const FormHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd5};
`;

export const FormSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
  }
`;

export const FormSubtitle = styled.h5`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm5};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-bottom: 0;
  }
`;

export const StyledAddMoreFilesButton = styled(DefaultButton)<{ disabled: boolean }>`
  margin-left: auto;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: 0 ${({ theme: { constants } }) => constants.spacerLg1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

export const MaxFilesMessageWrapper = styled.div`
  background-color: ${({ theme: { vars } }) => vars.trainualBrandYellowSurfaceLight};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants } }) => constants.borderWidthSm};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const FooterContainer = styled.div`
  width: 100%;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  display: flex;
  justify-content: flex-end;
`;

export const ActionButton = styled(DefaultButton)`
  border: ${({ theme: { constants } }) => constants.borderWidthSm};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd3}`};
`;

export const CancelButton = styled(ActionButton)`
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid #8B4FFF`};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const FullScreenOverlayWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const FooterActionButtonsWrapper = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: flex-start;
`;
