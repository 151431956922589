import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { AccountState } from 'types/AccountState';
import { CurrentUser } from 'types/CurrentUser';

function formatDate(unixTimestamp: number) {
  // For changes, the formatting guide is here...
  // https://day.js.org/docs/en/display/format
  return dayjs(unixTimestamp).format('YYYY-MM-DD[T]H:mm:ss[Z]');
}

function daysToSeconds(days: number) {
  return days * 24 * 60 * 60;
}

export default function useDelightedSurveys(
  user: CurrentUser | undefined,
  account: AccountState | undefined
) {
  const properties = useMemo(() => {
    return account && user
      ? {
          plan: account.stripePlanId,
          company: account.name,
          userPermission: user.permission,
          organizationSize: account.employeeSize,
          daysSinceSignUp: account.daysSinceSignUp,
          numberOfPublishedSubjects: account.publishedCurriculumCount.toString(),
        }
      : null;
  }, [user, account]);

  const netPromoter = useCallback(() => {
    if (!window.delightedNetPromoter || !user || !account) {
      return;
    }

    window.delightedNetPromoter.survey({
      email: user.email,
      name: user.name,
      createdAt: formatDate(user.createdAt),
      properties,
    });
  }, [user, account, properties]);

  const usability = useCallback(() => {
    if (!window.delightedUsability || !user || !account) {
      return;
    }

    window.delightedUsability.survey({
      email: user.email,
      name: user.name,
      createdAt: formatDate(user.createdAt + daysToSeconds(7)),
      properties,
    });
  }, [user, account, properties]);

  return {
    considerShowingNetPromoter: netPromoter,
    considerShowingUsability: usability,
  };
}
