import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { FC, useMemo, useRef } from 'react';

import { setCurrentResponsibility } from '../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import { CardPlaceholder } from '../BoardColumn/styles';
import { TCurrentBoardColumnResponsibility } from '../BoardColumn/types';
import { EDragElements } from '../BoardTrack/types';
import ResponsibilityCard from '../ResponsibilityCard/ResponsibilityCard';
import { DraggingWrapper, ResponsibilityDraggableContainer } from './styles';
import { ResponsibilityDraggableComponentProps } from './types';

const ResponsibilityDraggableComponent: FC<ResponsibilityDraggableComponentProps> = ({
  boardColumnResponsibility,
  columnId,
  isDragging: onPropsIsDragging,
  holderHeight,
}) => {
  const dispatch = useAppDispatch();

  const draggingWrapperRef = useRef<HTMLDivElement>(null);

  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id: boardColumnResponsibility.id,
    data: {
      type: EDragElements.Responsibility,
      boardColumnResponsibility,
      columnId,
      holderHeight: draggingWrapperRef.current?.clientHeight,
      offsetTop: draggingWrapperRef.current?.offsetTop,
    },
  });

  const handleResponsibilityCardClick = (responsibility: TCurrentBoardColumnResponsibility) => {
    dispatch(setCurrentResponsibility(responsibility));
  };

  const filteredContentUrls = useMemo(() => {
    return boardColumnResponsibility.contentUrls.filter((contentUrl) => !contentUrl._destroy);
  }, [boardColumnResponsibility.contentUrls]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (isDragging) {
    return <CardPlaceholder height={holderHeight} ref={setNodeRef} style={style} />;
  }

  return (
    <ResponsibilityDraggableContainer ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <DraggingWrapper isDragging={onPropsIsDragging || isDragging} ref={draggingWrapperRef}>
        <ResponsibilityCard
          boardColumnResponsibilityId={boardColumnResponsibility.id}
          cardType='personal'
          columnId={columnId}
          contentUrlsCount={filteredContentUrls.length}
          name={boardColumnResponsibility.name}
          onClick={() => handleResponsibilityCardClick(boardColumnResponsibility)}
          specializations={boardColumnResponsibility.specializations}
          workingHours={boardColumnResponsibility.workingHours}
        />
      </DraggingWrapper>
    </ResponsibilityDraggableContainer>
  );
};

export default ResponsibilityDraggableComponent;
