import styled from 'styled-components';

import P from '../../../../design_system/text/P';
import { fontLg2, fontMd1, fontMd5 } from '../../../../styled/TypeSystem';

export const CheckoutTotalSectionWrapper = styled.div<{ withDiscountSection: boolean }>`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  justify-content: space-between;
  align-items: ${({ withDiscountSection }) => (withDiscountSection ? 'flex-start' : 'center')};
`;

export const CheckoutTotalTitle = styled(P)<{ withDiscountSection: boolean }>`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${({ theme: { constants }, withDiscountSection }) =>
    withDiscountSection && { marginTop: constants.spacerSm2 }};
  ${fontMd1};
`;

export const CheckoutPriceDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckoutPriceDetails = styled(P)<{ strike: boolean; withDiscountSection?: boolean }>`
  color: ${({ theme: { vars }, strike }) =>
    strike ? vars.trainualBrandPurpleSurface : vars.trainualBrandPurpleMedium};
  font-weight: ${({ theme: { constants }, strike }) =>
    strike ? constants.fontRegular : constants.fontSemibold};
  text-align: right;
  ${({ strike }) =>
    strike && {
      'text-decoration-line': 'line-through',
    }}

  ${({ strike, withDiscountSection }) => (strike || withDiscountSection ? fontMd5 : fontLg2)};
`;
