import React from 'react';

import NavigationButtonsFactory from '../../../components/NavigationButtonsFactory/NavigationButtonsFactory';
import { usePreviousAndNextResource } from '../../hooks/usePreviousAndNextResource';

type Props = {
  stepId: number;
};

const NavigationButtons = ({ stepId }: Props) => {
  const { previous, next } = usePreviousAndNextResource(stepId);

  return (
    <div id='edit-navigation-buttons'>
      <NavigationButtonsFactory modeAttrs={{ mode: 'edit' }} next={next} previous={previous} />
    </div>
  );
};

export default NavigationButtons;
