import {
  CellPosition,
  ContentAlignment,
  Display,
} from '../../../people/UsersOutline/Table/TableTypes';
import { LoadingSize } from '../../TableTypes';
import { CourseReportColumn } from './types';

export interface Column {
  columnName: CourseReportColumn;
  loadingSize: LoadingSize;
  stickyPosition?: CellPosition;
  display?: Display;
  contentAlignment?: ContentAlignment;
  sortable: boolean;
  customColumn?: boolean;
}

export const columns: Column[] = [
  {
    columnName: 'position',
    display: 'static',
    loadingSize: LoadingSize.large,
    sortable: true,
  },
  { columnName: 'title', loadingSize: LoadingSize.medium, sortable: true, customColumn: true },
  { columnName: 'average_completion', loadingSize: LoadingSize.medium, sortable: true },
  { columnName: 'updated_at', loadingSize: LoadingSize.medium, sortable: true },
  { columnName: 'users_assigned_count', loadingSize: LoadingSize.medium, sortable: true },
  { columnName: 'users_completed_count', loadingSize: LoadingSize.medium, sortable: true },
];
