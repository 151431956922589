import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../../styled/Breakpoint';

export const CurrentIpAddressWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;

  @media (min-width: ${mediaBreakpointPxSm}) {
    justify-content: space-between;
  }
`;

export const CurrentIpInputSection = styled.div`
  width: 100%;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  input,
  input:focus {
    width: 100%;
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
    padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    max-width: 30%;
    margin-bottom: 0;
  }
`;
