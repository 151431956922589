import { isEmpty } from 'lodash';

import { SortDirection } from '../../../../../types/SortDirection';
import { Column, TableAction, TableState, UsersTableFilters } from './TableTypes';
import { DEFAULT_STATUSES } from './UsersTable';

const sortIcon = (sortDirection: SortDirection) =>
  sortDirection === 'asc' ? 'sort-up' : 'sort-down';

export function getAppliedFiltersCount(appliedFilters: UsersTableFilters) {
  const {
    groupIds: selectedGroups,
    status: selectedStatus,
    permissions: selectedPermissions,
  } = appliedFilters;

  const appliedStatus = selectedStatus === DEFAULT_STATUSES ? [] : selectedStatus;

  const filtersToObserve = [
    isEmpty(selectedGroups),
    isEmpty(appliedStatus),
    isEmpty(selectedPermissions),
  ];

  return filtersToObserve.filter((empty) => !empty).length;
}

const initialCheckboxState = {
  selectAllUsers: false,
  unselectedUsers: [],
  selectedUsers: [],
};

export const reducer = (state: TableState, action: TableAction): TableState => {
  switch (action.type) {
    case 'changePage': {
      return { ...state, page: action.page };
    }
    case 'changePageToPrevious': {
      return { ...state, page: state.page > 1 ? state.page - 1 : 1 };
    }
    case 'setSearchValue': {
      return {
        ...state,
        ...initialCheckboxState,
        searchValue: action.searchValue,
        page: 1,
      };
    }
    case 'sortColumnChange': {
      const sortDirection =
        state.sortColumn === action.sortColumn && state.sortDirection === 'asc' ? 'desc' : 'asc';
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortDirection,
        sortIcon: sortIcon(sortDirection),
      };
    }
    case 'resetTableState': {
      if (!action.sortColumn || !action.sortDirection) return state;
      return {
        ...state,
        hiddenFields: [],
        page: 1,
        searchValue: '',
        sortColumn: action.sortColumn,
        sortDirection: action.sortDirection,
        sortIcon: sortIcon(action.sortDirection),
        toggledColumns: state.tableColumns,
      };
    }
    case 'toggleColumnVisibility': {
      const updatedColumns = state.toggledColumns.map((column): Column => {
        if (column.hideable && column.columnName === action.column) {
          const display = column.display === 'visible' ? 'hidden' : 'visible';
          return { ...column, display };
        }
        return column;
      });
      return { ...state, toggledColumns: updatedColumns };
    }
    case 'toggleUserSelect': {
      if (state.selectAllUsers) {
        // If we used the Select all checkbox
        // Remove the user id from the unselectedUsers array if we uncheck the checkbox, or add to the unselectedUsers array if we check
        // But selectAllUsers still should be true since we do not have access to users on other pages and can't collect all selected users ids
        return {
          ...state,
          selectAllUsers: true,
          unselectedUsers: state.unselectedUsers.includes(action.userId)
            ? state.unselectedUsers.filter((user) => user !== action.userId)
            : [...state.unselectedUsers, action.userId],
        };
      } else {
        // If we didn't use the Select all checkbox
        // Remove the user id from the selectedUsers array if we uncheck the checkbox, or add to the selectedUsers array if we check
        return {
          ...state,
          selectedUsers: state.selectedUsers.includes(action.userId)
            ? state.selectedUsers.filter((user) => user !== action.userId)
            : [...state.selectedUsers, action.userId],
          unselectedUsers: [],
        };
      }
    }
    case 'selectAllUsers': {
      if (action.selectAllUsers && state.unselectedUsers.length) {
        // If we previously used the Select All checkbox and had unselectedUsers
        // Clear selectedUsers and unselectedUsers arrays since we do not have access to users on other pages and can't collect all selected users ids
        return {
          ...state,
          selectAllUsers: true,
          selectedUsers: [],
          unselectedUsers: [],
        };
      } else {
        // If we previously used the Select All checkbox and hadn't unselectedUsers
        // Toggle Select All checkbox
        return {
          ...state,
          selectAllUsers: action.selectAllUsers,
          selectedUsers: [],
          unselectedUsers: [],
        };
      }
    }
    case 'tableFilters': {
      if (action.resetToggles) {
        return {
          ...state,
          toggledColumns: state.tableColumns,
        };
      } else {
        return { ...state };
      }
    }
    case 'clearFilters': {
      return {
        ...state,
        ...initialCheckboxState,
        appliedFilters: {
          permissions: [],
          groupIds: [],
          roleIds: [],
          teamIds: [],
          status: DEFAULT_STATUSES,
        },
        flyout: {
          currentFilterSelection: {
            permissions: [],
            groupIds: [],
            roleIds: [],
            teamIds: [],
            status: [],
          },
        },
        page: 1,
        toggledColumns: state.tableColumns,
      };
    }
    case 'applyFilters': {
      return {
        ...state,
        ...initialCheckboxState,
        appliedFilters: {
          permissions: action.permissions,
          groupIds: action.groupIds,
          roleIds: action.roleIds,
          teamIds: action.teamIds,
          status: !!action.status?.length ? action.status : DEFAULT_STATUSES,
        },
        flyout: {
          currentFilterSelection: {
            permissions: action.permissions,
            groupIds: action.groupIds,
            roleIds: action.roleIds,
            teamIds: action.teamIds,
            status: action.status,
          },
        },
        page: 1,
        tableColumns: state.toggledColumns,
      };
    }
  }
};
