import React, { useEffect } from 'react';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import Tooltip from '../../../../components/design_system/display/Tooltip/Tooltip';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import useIntegrationCardsImages from '../../../../hooks/useIntegrationCardsImages';
import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import {
  useFinchDisconnectMutation,
  useFinchUpdateMutation,
} from '../../../../redux/services/resourceApis/integrations/integrationsApi';
import { useGetFinchIntegrationDataQuery } from '../../../../redux/services/resourceApis/settings/settingsApi';
import { FinchProvider } from '../../../../types/IntegrationProvider';
import { useFlashNotification } from '../../../FlashNotificationContext';
import FinchConnect from '../../finch/FinchConnect';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import { ADP_WORKFORCE_NOW, FINCH, GUSTO, UKG_PRO } from '../constants';
import PageWrapper from '../IntegrationShowPage/PageWrapper';
import {
  ButtonsContainer,
  DescriptionSection,
  LogoSection,
  StyledButton,
} from '../IntegrationShowPage/styles';
import {
  ActionButtons,
  InviteMyTeamSection,
  StyledCheckmark,
  StyledIntegrationLogo,
  UpdateButton,
} from './styles';

const t = initTranslations();

export interface FinchIntegrationsShowPageProps {
  provider: FinchProvider;
}

const FinchIntegrationsShowPage = ({ provider }: FinchIntegrationsShowPageProps) => {
  const ability = useCurrentUserAbilities();
  const { flash } = useFlashNotification();
  const { cardsData } = useIntegrationCardsImages();
  const integrationName = t(`finch_connect.provider.${provider}`);
  const { gustoActivated, slug, nonBilling } = useCurrentAccount();
  const { productTerm } = useAccountTerminology();
  const finchSandboxEnabled = ability.can('read', 'EnableFinchSandbox');
  const updateButtonEnabled = ability.can('read', 'EnableFinchUpdateButton');

  const [updateFinch, updateFinchResult] = useFinchUpdateMutation();
  const isDisabledOnUpdate = updateFinchResult.isSuccess;

  const [disconnectFinch, disconnectFinchResult] = useFinchDisconnectMutation();
  const {
    splitFeatures: { integrationsApiTokenEnabled },
  } = useCurrentAccount();

  useDisplayFlashOnResponse({
    result: updateFinchResult,
    successMessage: t('finch_connect.success'),
    errorMessage: t('finch_connect.error'),
  });

  useDisplayFlashOnResponse({
    result: disconnectFinchResult,
    successMessage: t('finch_connect.disconnected'),
    errorMessage: t('finch_connect.error'),
  });

  useEffect(() => {
    if (provider === FINCH && !finchSandboxEnabled) {
      routeTo(routes.integrations({ slug }));
      flash('error', t('not_authorized'));
    }
    // As component unmounts, closeModal was changing and causing flash
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finchSandboxEnabled, provider, slug]);

  const { data, isLoading, isSuccess, isFetching, isError, error } =
    useGetFinchIntegrationDataQuery(provider);

  useDisplayFlashOnResponse({
    result: { isSuccess, isLoading, isFetching, isError },
    errorFunction: () => routeTo(routes.integrations({ slug })),
    errorMessage: messageFromError(error)?.join(', '),
  });

  if (!data) return <></>;

  const { isConnected, isEnabled, finchConnect } = data;
  const connected = isConnected || (provider === GUSTO && gustoActivated);
  const { darkImage, lightImage, isBeta } = cardsData[provider];
  const canOpenFinchConnect = isEnabled || nonBilling;
  const isAssisted = provider === ADP_WORKFORCE_NOW;

  // This should only be visible to customers with the Split feature flag,
  // `integrationsApiTokenEnabled`, enabled.
  // Read More: https://trainual.atlassian.net/browse/GS1-5423
  const isUKGPro = provider === UKG_PRO;
  if (isUKGPro && !integrationsApiTokenEnabled) {
    return <></>;
  }

  return (
    <PageWrapper integrationName={integrationName} isBeta={isBeta}>
      <LogoSection>
        <StyledIntegrationLogo alt={provider} darkImage={darkImage} lightImage={lightImage} />
        <ButtonsContainer>
          {connected && !isDisabledOnUpdate && (
            <>
              <StyledCheckmark text={t('integrations.connected')} />
              <ActionButtons>
                {isConnected && updateButtonEnabled && (
                  <>
                    <Tooltip
                      id='finch-update-label-tooltip'
                      text={t('integrations.update_tooltip', { integration: integrationName })}
                    />
                    <UpdateButton
                      buttonType='primary'
                      dataFor='finch-update-label-tooltip'
                      id='update-finch-button'
                      loading={updateFinchResult.isLoading}
                      onClick={() => updateFinch({ provider })}
                      size='lg'
                      text={t('integrations.update')}
                    />
                  </>
                )}
                <StyledButton
                  buttonType='secondary'
                  id='disconnect-finch-button'
                  loading={disconnectFinchResult.isLoading}
                  onClick={() => disconnectFinch({ provider })}
                  size='lg'
                  text={t('integrations.disconnect')}
                />
              </ActionButtons>
            </>
          )}
          {(!connected || isDisabledOnUpdate) && (
            <FinchConnect
              canOpen={canOpenFinchConnect}
              connectedProvider={finchConnect.connectedProvider}
              disabled={finchConnect.disabled || isDisabledOnUpdate}
              isFinchSandboxEnabled={finchSandboxEnabled}
              provider={provider}
            />
          )}
          {!connected && provider === GUSTO && (
            <StyledButton
              buttonType='secondary'
              fullWidth
              id='try-gusto-free-button'
              onClick={() =>
                window.open('https://go.gusto.com/trainual-DS.html?utm_source=trainual', '_blank')
              }
              size='lg'
              text={t('integrations.try_gusto_free')}
            />
          )}
        </ButtonsContainer>
      </LogoSection>
      {isConnected && !isDisabledOnUpdate && (
        <InviteMyTeamSection>
          {t('integrations.finch_show_page.invite_section_text', {
            integrationName,
          })}
          <StyledButton
            buttonType='primary'
            id='invite-my-team-button'
            onClick={() =>
              routeTo(routes.manageUsers({ slug, open_react_finch_upload_modal: true }))
            }
            size='lg'
            text={t('integrations.finch_show_page.invite_my_team')}
          />
        </InviteMyTeamSection>
      )}
      <DescriptionSection>
        {t('integrations.finch_show_page.description', {
          integrationName,
          productTerm,
        })}
      </DescriptionSection>
      {isAssisted && (
        <DescriptionSection>
          {t('integrations.assisted_integration_disclaimer', { title: integrationName })}
        </DescriptionSection>
      )}
    </PageWrapper>
  );
};

export default FinchIntegrationsShowPage;
