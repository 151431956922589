import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import { useTrainingPathSetContext } from '../../../../../contexts/TrainingPathSetContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useSplitTrainingPathSetMutation } from '../../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import Icon from '../../../../design_system/display/icons/Icon/Icon';
import LoadingContainer from '../../../../design_system/LoadingContainer';
import { fontSm5 } from '../../../../styled/TypeSystem';

const Container = styled.div`
  display: flex;
  height: ${({ theme: { constants } }) => constants.heightXs};
  align-items: center;
`;

const Line = styled.div`
  width: 100%;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} dashed ${vars.borderSurface2}`};
`;

const Wrapper = styled.div(
  ({ theme: { vars, constants } }) => css`
    display: flex;
    align-items: center;
    background: ${vars.foundationSurface1};
    color: ${vars.borderDefault};
    height: ${constants.heightXs};
    padding: 0 ${constants.spacerSm2};
    border-radius: ${constants.borderRadiusEndcap};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};

    span {
      display: none;
      ${fontSm5};
    }

    &:hover {
      color: ${vars.textDefault};
      border: none;
      cursor: pointer;
      padding: ${constants.spacerSm2} ${constants.spacerSm3};
      span {
        display: block;
        margin-left: ${constants.spacerSm2};
      }
    }
  `
);

const t = initTranslations('training_path.path_config.create_split');

export type Props = {
  curriculumId: number;
};

const CreateSplit = ({ curriculumId }: Props) => {
  const {
    userId,
    trainingPathSet: { trainingPathSetUuid },
  } = useTrainingPathSetContext();

  const { updateInProgress, setUpdateInProgress } = useTrainingPathContext();
  const [splitSet, splitResult] = useSplitTrainingPathSetMutation();
  const { isLoading } = splitResult;

  const splitTrainingPathSet = useCallback(
    (curriculumId: number) => {
      if (updateInProgress || isLoading) return;

      setUpdateInProgress(true);
      splitSet({ userId, trainingPathSetUuid, curriculumId });
    },
    [updateInProgress, isLoading, setUpdateInProgress, splitSet, userId, trainingPathSetUuid]
  );

  useDisplayFlashOnResponse({
    result: splitResult,
    errorMessage: t('update_failed'),
    successFunction: () => {
      setUpdateInProgress(false);
      splitResult.reset();
    },
    errorFunction: () => {
      setUpdateInProgress(false);
      splitResult.reset();
    },
  });

  return (
    <Container id={`create-split-wrapper-${curriculumId}`}>
      <Line />
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <Wrapper onClick={() => splitTrainingPathSet(curriculumId)}>
          <Icon name='plus' size='xs' />
          <span>{t('label')}</span>
        </Wrapper>
      )}
      <Line />
    </Container>
  );
};

export default CreateSplit;
