import styled from 'styled-components';

import P from '../../../../../../design_system/text/P';
import { fontLg2 } from '../../../../../../styled/TypeSystem';

const getTrainualPlusBgImg = (color: string) =>
  encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" width="165" height="59" viewBox="0 0 165 59" fill="none"><path d="M94.1667 2.50596C64.8474 0.311334 4.50264 15.1784 2.07881 31.4881C-0.345019 47.7978 47.3297 56.7456 108.44 47.5355C169.55 38.3253 175.629 13.8707 145.701 7.89152C115.773 1.91236 50.1466 8.34929 23.9485 29.4154C-2.24963 50.4814 55.8603 61.583 89.3824 56.1123" stroke="${color}" stroke-width="3" stroke-linecap="round"/></svg>`
  );

export const TrainualPlusHeaderWrapper = styled.div`
  max-width: 29rem;
  text-align: center;
  margin: ${({ theme: { constants } }) => `0 auto ${constants.spacerLg2}`};
`;

export const TrainualPlusHeaderTitle = styled.div<{ primaryColor: string; secondaryColor: string }>`
  margin-top: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
  color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};

  ${fontLg2};

  .trainual-plus {
    display: inline-block;
    position: relative;
    color: ${({ primaryColor }) => primaryColor};
    margin-top: ${({ theme: { constants } }) => constants.spacerSm3};

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: -3px;
      width: 165px;
      height: 58px;
      z-index: -1;
      background: ${({ secondaryColor }) =>
        `url('data:image/svg+xml;utf8,${getTrainualPlusBgImg(secondaryColor)}'`}) no-repeat center;
    }
  }

  .playbook {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 16px;
      left: 0;
      right: -4px;
      height: 23px;
      z-index: -1;
      background-color: ${({ secondaryColor }) => secondaryColor};
    }
  }
`;

export const TrainualPlusHeaderDescription = styled(P)`
  max-width: 25rem;
  margin: 0 auto;
`;
