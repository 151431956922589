import { useMemo } from 'react';

import { ConsumptionStep } from '../../../../types/Editor';

type IncompleteStepInfo = {
  incompleteStepsCount: number;
  isCurrentStepIncomplete: boolean;
  onlyIncompleteStep: boolean;
};

export const useGetIncompleteStepInfo = (
  steps: ConsumptionStep[],
  currentStepId: number
): IncompleteStepInfo => {
  return useMemo(() => {
    const currentStepIndex = steps.findIndex(({ id }) => id === currentStepId);

    const incompleteStepsCount = steps.filter((step) => !step.completed).length;
    const isCurrentStepIncomplete = !steps[currentStepIndex]?.completed;

    const onlyIncompleteStep = incompleteStepsCount === 1 && isCurrentStepIncomplete;

    return { incompleteStepsCount, isCurrentStepIncomplete, onlyIncompleteStep };
  }, [steps, currentStepId]);
};
