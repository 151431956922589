import { inRange } from 'lodash';
import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import useWindowResize from '../../../../hooks/useWindowResize';
import trainualULogo from '../../../../images/home/trainual_u_logo.png';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { mediaBreakpointLg, mediaBreakpointSm } from '../../../styled/Breakpoint';
import useLocalStorageState from '../../publicApplication/utils/useLocalStorageState';
import useTrainualUniversity from '../../publicApplication/utils/useTrainualUniversity';
import {
  Controls,
  Description,
  Inner,
  InnerContent,
  InnerImage,
  StyledWidgetContainer,
  Title,
} from './styles';

const t = initTranslations('home.trainual_u_card');

const TrainualUCard = () => {
  const { configs } = usePublicConfigs();
  const {
    id: accountId,
    slug,
    adminAccount: { accountType },
  } = useCurrentAccount();
  const { width } = useWindowResize();
  const trainualUniversityAccountSlug = configs['TRAINUAL_UNIVERSITY_ACCOUNT_SLUG'];
  const isNotTrainualUniversityAccountSlug = slug != trainualUniversityAccountSlug;
  const isPartnerAccount = accountType === 'partner';
  const productTerm = 'Trainual';
  const [isTrainualUCardVisible, setIsTrainualUCardVisible] = useLocalStorageState({
    key: `isShowTrainualUCard_${accountId}`,
    initialValue: true,
  });

  const { isLoading, handleEnroll } = useTrainualUniversity();

  const shouldDisplayTrainualUCard = isTrainualUCardVisible && isNotTrainualUniversityAccountSlug;

  const handleDismiss = () => {
    setIsTrainualUCardVisible(false);
  };

  if (!shouldDisplayTrainualUCard || isPartnerAccount) return null;

  return (
    <StyledWidgetContainer id='home-trainual-u-card'>
      <Inner>
        <InnerImage alt={t('logo_alt_text', { productTerm })} src={trainualULogo} />

        <InnerContent>
          <Title>{t('title', { productTerm })}</Title>

          <Description>{t('description', { productTerm })}</Description>
        </InnerContent>
      </Inner>

      <Controls>
        <DefaultButton
          buttonType='tertiary'
          fullWidth={inRange(width, mediaBreakpointSm, mediaBreakpointLg)}
          id='dismiss-trainual-u-card-button'
          onClick={handleDismiss}
          text={t('dismiss')}
        />

        <DefaultButton
          buttonType='secondary'
          disabled={isLoading}
          fullWidth
          id='go-to-trainual-u-card-button'
          loading={isLoading}
          onClick={handleEnroll}
          text={t('go_to_trainual_you', { productTerm })}
        />
      </Controls>
    </StyledWidgetContainer>
  );
};

export default TrainualUCard;
