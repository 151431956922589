import React from 'react';

import { couponToDiscount } from '../../../../../../../lib/billing/stripeCouponToDiscount';
import {
  CurrentPerUserPlan,
  NewPerUserPlan,
} from '../../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import { PlanCardsWrapper } from '../../../../shared/BillingFullScreenOverlay/PlanCards/styles';
import { isCurrentPlanSameAsNewOne } from '../../../utils/isCurrentPlanSameAsNewOne';
import PlanCard from './PlanCard/PlanCard';
import { PlanCardProps } from './types';

const PlanCards = ({ currentPlan, newPlan, coupon, isManageSeatsOverlay }: PlanCardProps) => {
  const discount = couponToDiscount(coupon);

  const isCurrentPlanSameAsNew = isCurrentPlanSameAsNewOne({ currentPlan, newPlan });

  return (
    <PlanCardsWrapper>
      {[currentPlan, newPlan].map((plan: CurrentPerUserPlan | NewPerUserPlan, index) => {
        return (
          <PlanCard
            discount={discount}
            isCurrentPlanSameAsNew={isCurrentPlanSameAsNew}
            isManageSeatsOverlay={isManageSeatsOverlay}
            key={[plan.name, index].join('-')}
            plan={plan}
          />
        );
      })}
    </PlanCardsWrapper>
  );
};

export default PlanCards;
