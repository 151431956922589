import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';

const TableHeadRow = styled.div`
  display: flex;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
`;

const TableHeadItem = styled.div`
  width: 30%;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  font-size: 0.85rem;
  height: 3.25rem;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  &:first-child {
    padding-left: ${({ theme: { constants } }) => constants.spacerLg1};
  }
  &:last-child {
    padding-right: ${({ theme: { constants } }) => constants.spacerLg1};
    width: 40%;
  }
`;

const t = initTranslations('bulk_user_upload_modal.success_import.table');

const ResultTableHead = () => {
  return (
    <TableHeadRow>
      <TableHeadItem>{t('name')}</TableHeadItem>
      <TableHeadItem>{t('title')}</TableHeadItem>
      <TableHeadItem>{t('email')}</TableHeadItem>
    </TableHeadRow>
  );
};

export default ResultTableHead;
