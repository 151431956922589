import { Action, ModalState } from './types';

export const initialModalReducer: ModalState = {
  showModal: false,
  showSelectCourseModal: false,
  captureId: '',
  captureHeight: '',
};

export const modalReducer = (state: ModalState, action: Action): ModalState => {
  switch (action.type) {
    case 'setModalData': {
      return { ...state, captureId: action.captureId, captureHeight: action.captureHeight };
    }
    case 'setShowModal': {
      return { ...state, showModal: action.showModal };
    }
    case 'setShowSelectCourseModal': {
      return { ...state, showSelectCourseModal: action.showSelectCourseModal };
    }
  }
};
