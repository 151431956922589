import { FormikErrors, useFormik } from 'formik';
import React, { ChangeEvent, useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import capitalize from '../../../../../lib/capitalize';
import initTranslations from '../../../../../lib/initTranslations';
import { addSelectedGroupId } from '../../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useDispatchSetShowModal } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import {
  groupsApi,
  useAddSuggestedGroupsMutation,
} from '../../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupKind, groupKinds } from '../../../../../types/Group';
import { Suggestion } from '../../../../../types/Suggestion';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import InputField from '../../../../design_system/Triage/InputField';
import { GroupKindValue } from '../../../ContentLibrary/ContentLibraryPage/libraryReducer';
import GroupKindButton from '../../../groups/modals/CreateGroupKindModal/GroupKindButton';
import { ButtonsContainer } from '../../../groups/modals/CreateGroupKindModal/styles';
import { NAME_MAX_CHARACTERS } from '../../../groups/shared/constants/groups';
import { InputLabel, InputWrapper } from './styles';
import { FormProps } from './types';

const t = initTranslations('delegation_planner.modals.create_and_select_group_modal');

const mapDispatch = {
  getValidateGroup: groupsApi.endpoints.getValidateGroup.initiate,
};

const connector = connect(undefined, mapDispatch);
type CreateAndSelectGroupModalProps = ConnectedProps<typeof connector>;

const CreateAndSelectGroupModal = ({ getValidateGroup }: CreateAndSelectGroupModalProps) => {
  const dispatchShowModal = useDispatchSetShowModal();
  const dispatch = useAppDispatch();
  const [createGroup, createGroupResult] = useAddSuggestedGroupsMutation();
  const { error, data } = createGroupResult;
  const { groupsModalSearchTerm } = useAppSelector((state) => state.delegationPlanner);

  const handleFormSubmit = useCallback(
    (name: string, kind: GroupKind) => {
      // mirror existing custom group creation logic from CreateGroupNameModal.tsx
      const newGroupParams = [
        {
          id: `${uuidv4()}-custom-${name}`,
          name,
          frequency: 1,
          kind: 'custom',
        },
      ] as Suggestion[];

      createGroup({
        groups: newGroupParams,
        group_kind: kind,
      });
    },
    [createGroup]
  );

  const handleSelectGroupModalChange = useCallback(() => {
    if (data) {
      const [group] = data;
      dispatch(addSelectedGroupId(group.id));
      dispatchShowModal('delegationSelectGroupModal', true);
      dispatchShowModal('delegationCreateAndSelectGroupModal', false);
    }
  }, [data, dispatch, dispatchShowModal]);

  const formik = useFormik({
    initialValues: {
      name: groupsModalSearchTerm,
      kind: GroupKindValue.Role as GroupKind,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: async ({ name, kind }) => {
      const trimmedName = name.trim();
      const errors: FormikErrors<FormProps> = {};
      const { error } = await getValidateGroup({ name: trimmedName, kind }, { forceRefetch: true });

      if (!trimmedName) {
        errors.name = t('inputs.error.empty');
      } else if (error) {
        errors.name = messageFromError(error)?.join(', ');
      } else if (trimmedName.length > NAME_MAX_CHARACTERS) {
        errors.name = t('inputs.error.too_long', { max: NAME_MAX_CHARACTERS });
      }

      return errors;
    },
    onSubmit: ({ name, kind }, { resetForm }) => {
      handleFormSubmit(name, kind);
      resetForm();
    },
  });

  useDisplayFlashOnResponse({
    result: createGroupResult,
    errorMessage: messageFromError(error)?.join(', '),
    successFunction: handleSelectGroupModalChange,
  });

  const taskModalArgs: TaskModalProps = {
    heapModalName: 'create-and-select-group-delegation-modal',
    onCloseRequest: () => {
      dispatchShowModal('delegationCreateAndSelectGroupModal', false);
      dispatchShowModal('delegationSelectGroupModal', true);
    },
    primaryButtonTask: formik.handleSubmit,
    primaryButtonText: t('create_and_select'),
    processing: false,
    title: t('title'),
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    formik.setErrors({});
  };

  return (
    <TaskModal {...taskModalArgs}>
      <InputLabel>{t('group_name_input_label')}</InputLabel>
      <InputWrapper>
        <InputField
          autoFocus
          errorText={formik.touched.name && formik.errors.name}
          id='create-and-select-group-input'
          name='name'
          onChange={handleInputChange}
          placeholder={t('group_name_input_placeholder')}
          required
          type='text'
          value={formik.values.name}
        />
      </InputWrapper>
      <InputLabel>{t('group_kind_input_label')}</InputLabel>
      <ButtonsContainer>
        {groupKinds.map((groupKind) => (
          <GroupKindButton
            autoFocus={false}
            displayText={capitalize(groupKind)}
            key={groupKind}
            onClick={() => formik.setFieldValue('kind', groupKind)}
            selected={formik.values.kind === groupKind}
          />
        ))}
      </ButtonsContainer>
    </TaskModal>
  );
};

export default connector(CreateAndSelectGroupModal);
