import React from 'react';
import { Route } from 'type-route';

import { routes } from '../../publicApplication/applicationRouter';
import FinchIntegrationsShowPage from '../FinchIntegrationsShowPage';
import SlackIntegrationShowPage from '../SlackIntegrationShowPage';
import SsoIntegrationsShowPage from '../SsoIntegrationsShowPage';
import ZapierIntegrationShowPage from '../ZapierIntegrationShowPage';

const IntegrationShowPage = ({
  route: {
    params: { provider },
  },
}: {
  route: Route<typeof routes.integration>;
}) => {
  switch (provider) {
    case 'microsoft':
    case 'google':
    case 'okta':
      return <SsoIntegrationsShowPage provider={provider} />;
    case 'slack':
      return <SlackIntegrationShowPage />;
    case 'zapier':
      return <ZapierIntegrationShowPage />;
    default:
      return <FinchIntegrationsShowPage provider={provider} />;
  }
};

export default IntegrationShowPage;
