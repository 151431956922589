import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import ConfirmationModal from '../../../../../design_system/overlays/modals/ConfirmationModal';

const t = initTranslations('e_signature.restrict_leaving_signing_step_modal');

export type RestrictLeavingSigningStepProps = {
  setShowRestrictLeavingSigningStepModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const RestrictLeavingSigningStepModal = ({
  setShowRestrictLeavingSigningStepModal,
}: RestrictLeavingSigningStepProps) => {
  const handleCloseModal = () => setShowRestrictLeavingSigningStepModal(false);

  return (
    <ConfirmationModal
      actionFunction={handleCloseModal}
      desktopSize='md'
      hasPrimaryButton={false}
      heapModalName='restrict-leaving-signing-step-modal'
      isStaticModal
      message={t('description')}
      onCloseRequest={handleCloseModal}
      processing={false}
      secondaryButtonText={t('finish_signing')}
      title={t('title')}
    />
  );
};

export default RestrictLeavingSigningStepModal;
