import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../shared/SkeletonLoader/styles';

const FooterLoader = () => {
  const {
    constants: { heightMd },
  } = useTheme();

  return <SkeletonLoader height={heightMd} width='7.625rem' />;
};

export default FooterLoader;
