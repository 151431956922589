import GrowthPlanPriceArrow from '../../images/checkout_overlay/growth_plan_price_arrow.svg';
import MediumPlanPriceArrow from '../../images/checkout_overlay/medium_plan_price_arrow.svg';
import SmallPlanPriceArrow from '../../images/checkout_overlay/small_plan_price_arrow.svg';
import TrainualCustomLogo from '../../images/checkout_overlay/trainual_custom.svg';
import TrainualGrowthLogo from '../../images/checkout_overlay/trainual_growth.svg';
import TrainualMediumLogo from '../../images/checkout_overlay/trainual_medium.svg';
import TrainualSmallLogo from '../../images/checkout_overlay/trainual_small.svg';
import TrainualUnlimitedLogo from '../../images/checkout_overlay/trainual_unlimited.svg';
import UnlimitedPlanPriceArrow from '../../images/checkout_overlay/unlimited_plan_price_arrow.svg';
import { SimplePricingBillingPlanName } from '../../types/BillingPlanName';

export function getImagesByPlanName(planName: SimplePricingBillingPlanName) {
  switch (planName) {
    case 'small':
      return {
        trainualLogoSrc: TrainualSmallLogo,
        priceArrowSrc: SmallPlanPriceArrow,
      };
    case 'medium':
      return {
        trainualLogoSrc: TrainualMediumLogo,
        priceArrowSrc: MediumPlanPriceArrow,
      };
    case 'growth':
      return {
        trainualLogoSrc: TrainualGrowthLogo,
        priceArrowSrc: GrowthPlanPriceArrow,
      };
    case 'unlimited':
      return {
        trainualLogoSrc: TrainualUnlimitedLogo,
        priceArrowSrc: UnlimitedPlanPriceArrow,
      };
    case 'custom':
      return {
        trainualLogoSrc: TrainualCustomLogo,
        priceArrowSrc: UnlimitedPlanPriceArrow,
      };
  }
}
