import { JSONContent } from '@tiptap/core';
import { useCallback } from 'react';

import { EmbedExtensionName } from '../components/application/editor/tiptap_extensions/types';
import { useEditorContext } from '../contexts/EditorContext';
import { ContentMetaData } from '../redux/services/resourceApis/steps/types';

const useContentMetrics = (): (() => ContentMetaData) => {
  const { editor } = useEditorContext();

  const calculateElementCounts = useCallback((node: JSONContent) => {
    let imageCount = 0;
    let iframeCount = 0;
    let embedCardCount = 0;

    if (!node) return { imageCount, iframeCount, embedCardCount };

    switch (node.type) {
      case EmbedExtensionName.IMAGE:
        imageCount++;
        break;
      case EmbedExtensionName.IFRAME:
        iframeCount++;
        break;
      case EmbedExtensionName.EMBED_CARD:
        embedCardCount++;
        break;
      default:
        break;
    }

    if (node.content) {
      node.content.forEach((child) => {
        const childCounts = calculateElementCounts(child);
        imageCount += childCounts.imageCount;
        iframeCount += childCounts.iframeCount;
        embedCardCount += childCounts.embedCardCount;
      });
    }

    return { imageCount, iframeCount, embedCardCount };
  }, []);

  const calculateWordCount = useCallback((text: string) => {
    return text.split(/\s/).filter(Boolean).length;
  }, []);

  return useCallback(() => {
    if (editor) {
      const contentJson = editor.getJSON();
      const { imageCount, iframeCount, embedCardCount } = calculateElementCounts(contentJson);
      const wordCount = calculateWordCount(editor.getText());

      return { wordCount, imageCount, iframeCount, embedCardCount };
    }
    return { wordCount: 0, imageCount: 0, iframeCount: 0, embedCardCount: 0 };
  }, [editor, calculateElementCounts, calculateWordCount]);
};

export default useContentMetrics;
