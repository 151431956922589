import { OnboardTaskKeys } from '../../components/application/home/Onboarding/task_driven/TasksList/types';

const taskEmojis = {
  choose_accent_color: '🤩',
  publish_subject: '📚',
  create_group: '👥',
  invite_teammate: '💌',
};

export function getTaskDrivenOnboardingTaskListEmoji(
  key: OnboardTaskKeys,
  completed: boolean,
  skipped: boolean
) {
  if (completed) return '✅';
  if (skipped) return '❌';

  return taskEmojis[key];
}
