import React from 'react';
import styled from 'styled-components';

import { BreadcrumbWrapper } from '../../../editor/shared/styles';
import FullScreenOverlay from '../../../shared/FullScreenOverlay/FullScreenOverlay';
import {
  CurriculumElementHeaderWrapper,
  HeaderBody,
  HeaderBodyWrapper,
} from '../../../shared/preview/PreviewCurriculumElementHeader/styles';
import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';
import {
  HeadingWrapper,
  MainContentWrapper,
  MainWrapper,
  OutlineContentWrapper,
  QuestionCardWrapper,
  SurveyDataWrapper,
  SurveyEditDataPreviewWrapper,
  Toolbar,
  ToolbarWrapper,
} from '../../shared/styles';
import { CardContent, CardHeader, QuestionCard } from '../../shared/SurveyQuestion/styles';
import { AddQuestionButtonWrapper, ButtonWrapper, ContentDropdownWrapper } from '../styles';

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

const EditModeSurveySkeleton = () => {
  return (
    <FullScreenOverlay id='edit-mode-survey-skeleton' withoutCloseIcon>
      <MainWrapper>
        <OutlineContentWrapper>
          <HeadingWrapper>
            <SkeletonLoader width='8.5rem' />
          </HeadingWrapper>
          <BreadcrumbWrapper>
            <SkeletonLoader height='1.25rem' marginTop='3.5rem' width='10.625rem' />
          </BreadcrumbWrapper>
          <ContentDropdownWrapper></ContentDropdownWrapper>
          <AddQuestionButtonWrapper>
            <SkeletonLoader height='1.25rem' marginTop='2rem' width='12.5rem' />
            <SkeletonLoader height='1.25rem' marginTop='2rem' width='12.5rem' />
            <SkeletonLoader height='1.25rem' marginTop='2rem' width='12.5rem' />
          </AddQuestionButtonWrapper>
        </OutlineContentWrapper>
        <MainContentWrapper>
          <ToolbarWrapper>
            <StyledToolbar>
              <SkeletonLoader height='2rem' width='12rem' />
              <SkeletonLoader width='17rem' />
            </StyledToolbar>
          </ToolbarWrapper>
          <SurveyDataWrapper>
            <SurveyEditDataPreviewWrapper>
              <CurriculumElementHeaderWrapper>
                <HeaderBodyWrapper>
                  <HeaderBody>
                    <SkeletonLoader height='1.5rem' marginTop='0.5rem' width='15.625rem' />
                    <SkeletonLoader height='1.25rem' marginTop='1rem' width='100%' />
                    <SkeletonLoader
                      height='1.25rem'
                      marginBottom='2.5rem'
                      marginTop='1rem'
                      width='80%'
                    />
                    <SkeletonLoader height='2.5rem' marginTop='1rem' width='10.5rem' />
                  </HeaderBody>
                </HeaderBodyWrapper>
              </CurriculumElementHeaderWrapper>
            </SurveyEditDataPreviewWrapper>
            <ButtonWrapper>
              <SkeletonLoader height='3.5rem' width='4rem' />
            </ButtonWrapper>
          </SurveyDataWrapper>
          <QuestionCardWrapper>
            <QuestionCard>
              <CardHeader>&nbsp;</CardHeader>
              <CardContent>
                <SkeletonLoader height='2.125rem' marginTop='2rem' width='15.625rem' />
                <SkeletonLoader height='1.25rem' marginTop='1rem' width='12.625rem' />
                <SkeletonLoader
                  height='1.25rem'
                  marginBottom='10rem'
                  marginTop='1rem'
                  width='12.625rem'
                />
              </CardContent>
            </QuestionCard>
          </QuestionCardWrapper>
        </MainContentWrapper>
      </MainWrapper>
    </FullScreenOverlay>
  );
};

export default EditModeSurveySkeleton;
