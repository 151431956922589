import { FormikState } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ContentSearchContext } from '../../../../contexts/ContentSearchContext';
import initTranslations from '../../../../lib/initTranslations';
import { searchesApi } from '../../../../redux/services/resourceApis/searches/searchesApi';
import { ContentSearchResults } from '../../../../redux/services/resourceApis/searches/types';
import SearchField from '../../../design_system/Triage/fields/SearchField';
import Loader from '../../../design_system/Triage/Loader';
import Scrollbar from '../../../styled/Scrollbar';
import { fontSm5 } from '../../../styled/TypeSystem';
import EmbedCard from '../../editor/components/EmbedCard/EmbedCard';
import { Values } from '../../editor/plugins/EmbedTrainual/EmbedTrainualFlyout';
import ContentSearchResultsList from './ContentSearchResultsList';

const ContentSearchBackground = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  width: 100%;
  height: 100%;
`;

const SearchFieldLabel = styled.label`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  width: 100%;
  white-space: nowrap;

  ${fontSm5};
`;

const SearchWrapper = styled.div`
  width: 100%;
  min-height: 19rem;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5.5rem;
`;

const ListWrapper = styled.div`
  max-height: 15rem;
  overflow: auto;

  ${Scrollbar};
`;

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

type Props = {
  setValues: (values: React.SetStateAction<Values>, shouldValidate?: boolean | undefined) => void;
  resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void;
};

const t = initTranslations('content_search');

const ContentSearch = ({ setValues, resetForm }: Props) => {
  const {
    updateContentSearchValue,
    updateContentSelectedValue,
    updateSearchResults,
    searchValue,
    selectedValue,
    searchResults,
  } = useContext(ContentSearchContext);
  const [searchTrigger, searchResult] = searchesApi.useLazyGetContentSearchResultsQuery();
  const { isFetching: isLoading, data } = searchResult;

  // Get search value and clear results when search value is empty
  useEffect(() => {
    if (searchValue.length > 2) {
      const timer = setTimeout(() => {
        searchTrigger({ search: searchValue });
      }, 500);

      return () => clearTimeout(timer);
    } else {
      updateSearchResults(undefined);
      resetForm();
    }
  }, [searchValue, searchTrigger, updateSearchResults, resetForm]);

  useEffect(() => {
    if (data) updateSearchResults(data.results);
  }, [data, updateSearchResults]);

  const handleSearchInputChange = useCallback(
    (value: string) => {
      updateContentSearchValue(value);
      updateContentSelectedValue(null);
    },
    [updateContentSearchValue, updateContentSelectedValue]
  );

  const handleSelect = useCallback(
    (value: ContentSearchResults | null) => {
      updateContentSelectedValue(value);

      if (value) {
        setValues(() => ({
          description: value.description,
          title: value.title,
          type: value.type,
          url: value.webLink,
        }));
      }
    },
    [setValues, updateContentSelectedValue]
  );

  return (
    <ContentSearchBackground>
      <SearchWrapper>
        <SearchFieldLabel>{t('cta')}</SearchFieldLabel>
        <SearchField
          autoFocus
          dataLoading={isLoading}
          placeholder={t('placeholder')}
          searchValue={searchValue}
          setSearchValue={handleSearchInputChange}
        />
        {isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : selectedValue ? (
          <PreviewWrapper>
            <EmbedCard
              contentType='media'
              contentUrl={selectedValue.webLink}
              description={selectedValue.description}
              isEditable={false}
              size='small'
              title={selectedValue.title}
            />
          </PreviewWrapper>
        ) : (
          <ListWrapper>
            <ContentSearchResultsList
              hasSearched={!!searchResults}
              onSelect={handleSelect}
              results={searchResults}
              selectedValue={selectedValue}
            />
          </ListWrapper>
        )}
      </SearchWrapper>
    </ContentSearchBackground>
  );
};

export default ContentSearch;
