import { Editor, JSONContent, useEditor } from '@tiptap/react';
import { useTheme } from 'styled-components';

import { EditorExtensions } from '../../shared/constants/extensions';

type ConsumeContent = {
  editor: Editor | null;
};

type Props = {
  content: JSONContent | string | null;
  stepId: number;
};

export const useConsumeContent = ({ content, stepId }: Props): ConsumeContent => {
  const { palettes } = useTheme();

  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          translate: 'yes',
        },
      },
      content,
      extensions: EditorExtensions({ collaborationEnabled: true, isEditable: false, palettes }),
      editable: false,
    },
    [content, stepId]
  );

  return { editor };
};
