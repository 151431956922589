import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { routes } from '../../../publicApplication/applicationRouter';
import { CourseCardProps } from '../../activities/types';
import CommonTemplate, { CourseCommonTemplateProps } from './CommonTemplate';
import StatusChanged, { CourseStatusChangedProps } from './StatusChanged';

interface CourseActivitiesProps {
  'course.create': React.FC<CourseCommonTemplateProps>;
  'course.update': React.FC<CourseCommonTemplateProps>;
  'course.status_changed': React.FC<CourseStatusChangedProps>;
}

const ACTIVITY_KEYS: CourseActivitiesProps = {
  'course.create': CommonTemplate,
  'course.update': CommonTemplate,
  'course.status_changed': StatusChanged,
};

const CourseNotificationsDetails = ({ activity }: CourseCardProps) => {
  const { slug } = useCurrentAccount();
  const {
    trackable: { id },
    key,
    owner,
    parameters,
  } = activity;
  const NotificationMessage = ACTIVITY_KEYS[activity.key as keyof CourseActivitiesProps];
  // TODO: update to use RouterLink after course page conversion to SPA
  const curriculumHref = routes.courseConsume({ slug, id }).href;

  return (
    <NotificationMessage
      activityKey={key}
      curriculumHref={curriculumHref}
      owner={owner}
      parameters={parameters}
      trackable={activity.trackable}
    />
  );
};

export default CourseNotificationsDetails;
