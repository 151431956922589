import React, { useMemo } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { useRedirectToBillingOverlay } from '../../../../../hooks/useRedirectToBillingOverlay';
import initTranslations from '../../../../../lib/initTranslations';
import { SendAllInvitesResponse } from '../../../../../redux/services/resourceApis/users/types';
import TaskModal from '../../../../design_system/overlays/modals/TaskModal';
import {
  Description,
  NotInvitedBlock,
  StyledErrorIcon,
  StyledLink,
  StyledP,
  StyledSuccessIcon,
  SubtitleBlock,
  SuccessInvitedBlock,
  SuccessTitleBlock,
  TitleBlock,
} from './styles';

const t = initTranslations('users_table.add_teammate_split_button.send_all_invites_modal');

type Props = {
  data: SendAllInvitesResponse;
  setShowSendAllInvitesModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const OutOfSeatsModal = ({ data, setShowSendAllInvitesModal }: Props) => {
  const { usedUserSlotsCount, totalUserSlotsCount } = useCurrentAccount();
  const { permission } = useCurrentUser();
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const isBillingAdmin = permission === 'billing_admin';
  const { numberOfInvites, numberOfNotInvited } = data;
  const isInvitesSent = Boolean(numberOfInvites);
  const successBlockData = useMemo(() => {
    return isBillingAdmin
      ? {
          title: t('per_user_pricing.invited_billing', { count: numberOfInvites }),
          message: t('per_user_pricing.message'),
        }
      : {
          title: t('per_user_pricing.invited', { count: numberOfInvites }),
          message: t('per_user_pricing.not_appear_people'),
        };
  }, [isBillingAdmin, numberOfInvites]);

  const redirectToBillingOverlay = () => {
    setShowSendAllInvitesModal(false);
    navigateToBillingOverlay();
  };

  const getUpgradeDescription = () => {
    if (isBillingAdmin) {
      return isInvitesSent ? (
        <>
          <StyledLink
            behavesAs='button'
            onClick={redirectToBillingOverlay}
            text={t('per_user_pricing.upgrade_now')}
          />
          <span>{t('per_user_pricing.upgrade_now_description_1')}</span>
        </>
      ) : (
        <>
          {t('per_user_pricing.upgrade_now')}
          {t('per_user_pricing.upgrade_now_description_1')}
        </>
      );
    } else {
      return numberOfInvites ? null : t('per_user_pricing.unable_to_invite');
    }
  };

  const getNotInvitedDescription = () => {
    return isBillingAdmin ? (
      <>
        <StyledLink
          behavesAs='button'
          onClick={redirectToBillingOverlay}
          text={t('per_user_pricing.upgrade_now')}
        />
        <span>{t('per_user_pricing.upgrade_now_description_2')}</span>
      </>
    ) : (
      t('per_user_pricing.out_of_seats_details', {
        usedUserSlotsCount,
        totalUserSlotsCount,
      })
    );
  };

  return (
    <TaskModal
      desktopSize='xl'
      heapModalName='send-all-invites-out-of-seats-modal'
      onCloseRequest={() => setShowSendAllInvitesModal(false)}
      primaryButtonTask={isBillingAdmin ? redirectToBillingOverlay : () => ({})}
      primaryButtonText={isBillingAdmin ? t('per_user_pricing.primary_button_text') : ''}
      processing={false}
      secondaryButtonText={t('per_user_pricing.secondary_button_text')}
      title={t('per_user_pricing.title')}
    >
      <SubtitleBlock>
        <Description text={getUpgradeDescription()} />
      </SubtitleBlock>

      <NotInvitedBlock>
        <TitleBlock isInvitesSent={isInvitesSent}>
          <StyledErrorIcon name='triangle-exclamation' weight='solid' />
          <StyledP
            dangerouslySetInnerHTML={{
              __html: t('per_user_pricing.not_invited', { count: numberOfNotInvited }),
            }}
          />
        </TitleBlock>

        {!isInvitesSent && <Description text={getNotInvitedDescription()} />}
      </NotInvitedBlock>

      {isInvitesSent && (
        <SuccessInvitedBlock>
          <SuccessTitleBlock>
            <StyledSuccessIcon name='circle-check' size='sm' weight='solid' />
            <StyledP dangerouslySetInnerHTML={{ __html: successBlockData.title }} />
          </SuccessTitleBlock>

          <StyledP>{successBlockData.message}</StyledP>
        </SuccessInvitedBlock>
      )}
    </TaskModal>
  );
};

export default OutOfSeatsModal;
