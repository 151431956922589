import React, { useCallback, useEffect, useRef } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import {
  setCurrentColumnId,
  setIsCurrentResponsibilityPositionChanged,
  setShouldScrollToCurrentResponsibility,
} from '../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useDispatchSetShowModal } from '../../../../redux/domains/modalsSlice/modalsSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { DEFAULT_FREQUENCY, DEFAULT_WORKING_TIME } from '../shared/constants/modals';
import SpecializationPill from '../SpecializationPill/SpecializationPill';
import { Card, InfoContainer, InfoText, PillWrapper, StyledIcon, Title } from './styles';
import { ResponsibilityCardProps } from './types';

const t = initTranslations('delegation_planner.responsibility_card');

const ResponsibilityCard = ({
  columnId,
  name,
  workingHours,
  boardColumnResponsibilityId,
  specializations,
  onClick,
  cardType,
  contentUrlsCount,
}: ResponsibilityCardProps) => {
  const dispatch = useAppDispatch();
  const dispatchShowModal = useDispatchSetShowModal();
  const cardRef = useRef<HTMLDivElement>(null);
  const { currentBoardColumnResponsibility, shouldScrollToCurrentResponsibility } = useAppSelector(
    (state) => state.delegationPlanner
  );
  const workingTime = workingHours?.time || DEFAULT_WORKING_TIME;
  const workingFrequency = workingHours?.frequency || DEFAULT_FREQUENCY;
  const shouldHighlightWithScrollTo =
    boardColumnResponsibilityId === currentBoardColumnResponsibility?.id &&
    shouldScrollToCurrentResponsibility &&
    cardType === 'personal';

  const handleCardClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!columnId) return;

      onClick && onClick(e);
      dispatch(setCurrentColumnId(columnId));
      dispatchShowModal('responsibilityDetailsModal', true);
    },
    [columnId, dispatch, dispatchShowModal, onClick]
  );

  useEffect(() => {
    if (!columnId) return;

    if (shouldHighlightWithScrollTo && cardRef.current) {
      dispatch(setIsCurrentResponsibilityPositionChanged(false));
      dispatch(setCurrentColumnId(columnId));

      cardRef.current.scrollIntoView({
        behavior: 'smooth',
      });

      setTimeout(() => {
        dispatch(setShouldScrollToCurrentResponsibility(false));
      }, 4000);
    }
  }, [columnId, dispatch, shouldHighlightWithScrollTo]);

  return (
    <Card
      className={shouldHighlightWithScrollTo ? 'highlighter-animation' : ''}
      isUserGroupCard={cardType === 'userGroup'}
      onClick={handleCardClick}
      ref={cardRef}
    >
      <PillWrapper>
        {specializations.map((specialization) => (
          <SpecializationPill
            color={specialization.color}
            id={`${columnId}-${boardColumnResponsibilityId}-${specialization.id}`}
            key={specialization.id}
            score={specialization.score}
            title={specialization.terminology}
          />
        ))}
      </PillWrapper>

      <Title>{name}</Title>
      <InfoContainer>
        <InfoText>
          <StyledIcon name='clock' size='2xs' weight='regular' />
          {t('working_hours', { count: workingTime, frequency: workingFrequency })}
        </InfoText>
        {!!contentUrlsCount && (
          <InfoText data-for={`content-urls-count-tooltip-${boardColumnResponsibilityId}`} data-tip>
            <Tooltip
              id={`content-urls-count-tooltip-${boardColumnResponsibilityId}`}
              text={t('connected_content', { count: contentUrlsCount })}
            />
            <StyledIcon name='books' size='2xs' weight='regular' />
            {contentUrlsCount}
          </InfoText>
        )}
      </InfoContainer>
    </Card>
  );
};

export default ResponsibilityCard;
