import { useFormikContext } from 'formik';
import React, { useReducer } from 'react';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import { LOCALE_OPTIONS, Locales } from '../../../../../components/application/locales/types';
import { useLocalesContext } from '../../../../../contexts/LocalesContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetTimezonesQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import InputFieldWithCharacterCounter from '../../../../design_system/Triage/InputFieldWithCharacterCounter';
import CollapsibleSurface from '../../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import { FormGroup } from '../../../shared/FormGroup';
import { SettingsFormValues } from '../InterfaceAndTypes';
import { FormSectionWrapper } from '../Styles';

const t = initTranslations('settings');

const BasicSettings = () => {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext<SettingsFormValues>();
  const { data: timezones } = useGetTimezonesQuery();
  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);
  const { productTerm } = useAccountTerminology();
  const { locale, updateLocale } = useLocalesContext();
  const { multiLanguageEnabled } = useCurrentAccount();

  return (
    <CollapsibleSurface
      collapseDispatch={collapseDispatch}
      id='organization-profile-settings-surface'
      isCollapsed={collapsed}
      title={t('profile.title')}
    >
      <FormSectionWrapper className='my-settings-profile-section'>
        <FormGroup className='my-settings-name-group'>
          <InputFieldWithCharacterCounter
            className='analytics-my-settings-name-field'
            errorText={touched.name && errors.name}
            id='name'
            label={t('profile.input.name')}
            name='name'
            onChange={handleChange}
            placeholder={t('profile.input.placeholders.name')}
            type='text'
            value={values.name}
          />
        </FormGroup>
        <FormGroup className='my-settings-title-group'>
          <InputFieldWithCharacterCounter
            className='analytics-my-settings-title-field'
            errorText={touched.title && errors.title}
            id='title'
            label={t('profile.input.title')}
            name='title'
            onChange={handleChange}
            placeholder={t('profile.input.placeholders.title')}
            type='text'
            value={values.title || undefined}
          />
        </FormGroup>
        <FormGroup className='my-settings-email-group'>
          <InputFieldWithCharacterCounter
            className='analytics-my-settings-email-field'
            errorText={touched.email && errors.email}
            id='email'
            label={t('profile.input.email')}
            name='email'
            onChange={handleChange}
            placeholder={t('profile.input.placeholders.email')}
            type='email'
            value={values.email}
          />
        </FormGroup>
        <FormGroup className='my-settings-phone-number-group'>
          <InputFieldWithCharacterCounter
            className='analytics-my-settings-phone-number-field'
            errorText={touched.phone_number && errors.phone_number}
            id='phone_number'
            label={t('profile.input.phone_number')}
            name='phone_number'
            onChange={handleChange}
            placeholder={t('profile.input.placeholders.phone_number')}
            type='text'
            value={values.phone_number || undefined}
          />
        </FormGroup>
        <FormGroup className='my-settings-pronouns-group'>
          <InputFieldWithCharacterCounter
            className='analytics-my-settings-pronouns-field'
            errorText={touched.pronouns && errors.pronouns}
            id='pronouns'
            label={t('profile.input.pronouns')}
            name='pronouns'
            onChange={handleChange}
            placeholder={t('profile.input.placeholders.pronouns')}
            type='text'
            value={values.pronouns || undefined}
          />
        </FormGroup>
        {timezones && (
          <FormGroup className='my-settings-time-zone-group'>
            <SingleSelectField
              className='analytics-my-settings-timezone-field'
              defaultValue={values.time_zone}
              fieldLabelText={t('profile.input.time_zone')}
              name='time_zone'
              onNonDefaultSelected={(timezone: string) => {
                setFieldValue('time_zone', timezone);
              }}
              options={formatOptions(timezones)}
              value={values.time_zone}
            />
          </FormGroup>
        )}
        {multiLanguageEnabled && (
          <FormGroup className='my-settings-languages-group'>
            <SingleSelectField
              className='analytics-my-settings-languages-field'
              defaultValue={locale.value}
              fieldLabelText={t('advanced.language.label', { productTerm })}
              name='user_configuration_attributes.language'
              onNonDefaultSelected={(language: Locales) => {
                updateLocale(language);
              }}
              options={formatOptions(LOCALE_OPTIONS)}
              value={locale.value}
            />
          </FormGroup>
        )}
      </FormSectionWrapper>
    </CollapsibleSurface>
  );
};

export default BasicSettings;
