import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import NoResultsGraphicDark from '../../../../images/telescope_dark.svg';
import NoResultsGraphic from '../../../../images/telescope.svg';
import initTranslations from '../../../../lib/initTranslations';
import Checkbox from '../../input/controls/Checkbox';
import NoResults from '../NoResults';

const Container = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerMd2} 0;
`;

const ListItemsContainer = styled.div<{ noMarginTop?: boolean; checkboxGap: string }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.noMarginTop ? 0 : '1.563rem')};
  gap: ${({ checkboxGap }) => checkboxGap};
`;

const StyledHr = styled.hr`
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

const StyledNoResults = styled(NoResults)`
  margin-top: 0;
  max-width: 18rem;
`;

export interface ChecklistOption {
  id: number;
  name: string;
  label: ReactNode;
  checked?: boolean;
  filterOn: string;
}

const t = initTranslations('check_list');

export interface Props {
  centeredCheckboxes?: boolean;
  checkboxOptions: ChecklistOption[];
  onSelectionChanged: (selectedOptions: ChecklistOption[]) => void;
  allCheckboxTitle?: string;
  className?: string;
  checkboxGap?: string;
  filterValue?: string;
}

type Id = number | string;

function isListAllChecked(list: ChecklistOption[]) {
  return list.every((item) => item.checked);
}

const Checklist = (props: Props) => {
  const {
    checkboxOptions,
    onSelectionChanged,
    allCheckboxTitle,
    className,
    centeredCheckboxes = false,
    checkboxGap = '0rem',
    filterValue = '',
  } = props;
  const [options, setOptions] = useState<{ isAllSelected: boolean; list: ChecklistOption[] }>({
    isAllSelected: false,
    list: [],
  });
  const visibleOptions = options.list.filter((opt) =>
    opt.filterOn.toLowerCase().includes(filterValue.toLowerCase())
  );

  useEffect(() => {
    if (checkboxOptions) {
      setOptions({
        isAllSelected: isListAllChecked(checkboxOptions),
        list: checkboxOptions,
      });
    }
  }, [checkboxOptions]);

  const onCheckboxChange = (checkId: Id | 'all', isChecked: boolean) => {
    const isAllChecked = checkId === 'all' && isChecked;
    const isAllUnChecked = checkId === 'all' && !isChecked;

    const list = options.list.map((item) => {
      if (!visibleOptions.includes(item)) {
        return item;
      }

      if (isAllChecked || item.id === checkId) {
        return { ...item, checked: isChecked };
      }

      if (isAllUnChecked) {
        return { ...item, checked: false };
      }
      return item;
    });

    onSelectionChanged(list.filter((item) => item.checked));

    const isAllSelected = isAllChecked || isListAllChecked(list);
    setOptions({ isAllSelected, list });
  };

  const listItemsContainer = (
    <ListItemsContainer checkboxGap={checkboxGap} noMarginTop={!allCheckboxTitle}>
      {visibleOptions.map((option, index) => {
        return (
          <Checkbox
            centeredCheckboxes={centeredCheckboxes}
            checked={option.checked || false}
            fullWidthLabel
            id={option.name}
            key={`${option.name}-${index}`}
            label={option?.label}
            name={option.name}
            onCheck={(e) => onCheckboxChange(option.id, e.target.checked)}
          />
        );
      })}
    </ListItemsContainer>
  );

  const isSelectAllChecked = visibleOptions.every((opt) => !!opt.checked);

  return (
    <div className={className}>
      <Container className='checkbox-list'>
        {visibleOptions.length > 0 ? (
          allCheckboxTitle && (
            <>
              <Checkbox
                checked={isSelectAllChecked}
                id='select-all'
                label={allCheckboxTitle}
                name='select-all'
                onCheck={(e) => onCheckboxChange('all', e.target.checked)}
              />
              <StyledHr />
            </>
          )
        ) : (
          <StyledNoResults
            darkImage={NoResultsGraphicDark}
            heading={t('no_result_found')}
            lightImage={NoResultsGraphic}
            minHeight='10rem'
            showBorder={false}
          />
        )}
        {listItemsContainer}
      </Container>
    </div>
  );
};

export default Checklist;
