import React from 'react';

import { useCloseFullScreenOverlay } from '../../../../../hooks/billing/useCloseFullScreenOverlay';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import ImportDocument from '../../../../../images/create_curriculum_overlay/import_document.svg';
import CreateFromScratchImage from '../../../../../images/create_curriculum_overlay/pen.svg';
import CreateFromTemplate from '../../../../../images/create_curriculum_overlay/templates.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { openCurriculumModal } from '../../../../../redux/domains/curriculumModal/curriculumModalSlice';
import { openDocumentImportModal } from '../../../../../redux/domains/documentUpload/documentUploadSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import {
  CreateCurriculumOptions,
  OptionImage,
  OptionImageWrapper,
  OptionItem,
  OptionText,
  OverlayContentWrapper,
  OverlaySubtitle,
  OverlayTitle,
} from './styles';

const t = initTranslations('create_curriculum_fullscreen_overlay');

const CreateCurriculumOverlayContent = () => {
  const {
    slug,
    splitFeatures: { bulkDocImporterEnabled },
  } = useCurrentAccount();
  const {
    curriculum: { singular: curriculum },
  } = useAccountTerminology();
  const closeFullScreenOverlay = useCloseFullScreenOverlay();
  const dispatch = useAppDispatch();

  const handleImportFromScratch = () => {
    closeFullScreenOverlay();
    dispatch(openCurriculumModal());
  };

  const handleImportFromDocument = () => {
    closeFullScreenOverlay();
    if (bulkDocImporterEnabled) {
      routeTo(routes.bulkDocumentImport({ slug, sector: 'process' }));
    } else {
      dispatch(openDocumentImportModal('universal_add'));
    }
  };

  return (
    <OverlayContentWrapper>
      <OverlayTitle>{t('title', { curriculum: curriculum.toLowerCase() })}</OverlayTitle>
      <OverlaySubtitle>{t('subtitle')}</OverlaySubtitle>
      <CreateCurriculumOptions>
        <OptionItem onClick={handleImportFromScratch}>
          <OptionImageWrapper>
            <OptionImage className='create-from-scratch' src={CreateFromScratchImage} />
          </OptionImageWrapper>
          <OptionText>{t('from_scratch')}</OptionText>
        </OptionItem>
        <OptionItem onClick={() => routeTo(routes.templates({ slug }))}>
          <OptionImageWrapper>
            <OptionImage className='create-from-template' src={CreateFromTemplate} />
          </OptionImageWrapper>
          <OptionText>{t('from_template')}</OptionText>
        </OptionItem>
        <OptionItem onClick={handleImportFromDocument}>
          <OptionImageWrapper>
            <OptionImage className='create-from-document' src={ImportDocument} />
          </OptionImageWrapper>
          <OptionText>{t('import_document')}</OptionText>
        </OptionItem>
      </CreateCurriculumOptions>
      <DefaultButton
        buttonType='tertiary'
        id='cancel-create-curriculum-fullscreen-overlay'
        onClick={closeFullScreenOverlay}
        text={t('cancel')}
      />
    </OverlayContentWrapper>
  );
};

export default CreateCurriculumOverlayContent;
