import styled, { css } from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../styled/TypeSystem';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const PopupText = styled.div`
  color: ${({ theme: { vars } }) => vars.textSurface};
  ${fontSm5};

  span {
    font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  }
`;

export const UpdatedAtText = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd1};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-left: auto;

  ${fontSm5};
`;

export const LoadingIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const StyledLoadingImage = styled.img`
  ${({ theme: { constants } }) => css`
    width: 3.5rem;
    transform: translate(${constants.spacerSm3}, -${constants.spacerSm3});
  `};
`;
