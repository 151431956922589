import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { TraditionalMfaSectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const TraditionalMfaSection = ({ mfaOn, id }: TraditionalMfaSectionProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('mfa_on_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={mfaOn}
            className='mfa-on-setting'
            handleToggle={(e) => updateAccountData({ mfa_on: e.target.checked })}
            id='mfa-on'
            name='enable_mfa_on'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('mfa_on_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default TraditionalMfaSection;
