import React from 'react';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import RouteTo from '../../../publicApplication/routeTo';
import {
  TemplateOutlineRowCategoryBadgeWrapper,
  TemplateOutlineRowContent,
  TemplateOutlineRowDescription,
  TemplateOutlineRowIconWrapper,
  TemplateOutlineRowMetadata,
  TemplateOutlineRowMetadataIcon,
  TemplateOutlineRowTitle,
  TemplateOutlineRowTitleWrapper,
  TemplateOutlineRowViewButton,
  TemplateOutlineRowWrapper,
} from './styles';
import TemplateOutlineRowCategoryBadge from './TemplateOutlineRowCategoryBadge/TemplateOutlineRowCategoryBadge';
import { TemplateOutlineRowProps } from './types';

const t = initTranslations('templates_outline.template_outline_row');

const TemplateOutlineRow = ({ template }: TemplateOutlineRowProps) => {
  const { slug } = useCurrentAccount();
  const route = useRoute();
  const {
    params: { author: authors, category_ids, page, tags, search, sort, suggested_groups, sectors },
  } = route as Route<typeof routes.templates>;
  const templatesBreadcrumb = routes.templates({
    slug,
    author: authors,
    category_ids,
    tags,
    suggested_groups,
    sectors,
    search,
    sort,
    page,
  });
  const { id, title, description, templateDownloadsCount, author, badge, emoji } = template;
  const templatePath = routes.template({ slug, id, breadcrumb: templatesBreadcrumb });

  return (
    <TemplateOutlineRowWrapper id={`template-outline-${id}`}>
      <TemplateOutlineRowIconWrapper>
        {emoji ? emoji : <Icon name='layer-group' size='sm' />}
      </TemplateOutlineRowIconWrapper>
      <TemplateOutlineRowContent>
        <TemplateOutlineRowTitleWrapper>
          <TemplateOutlineRowTitle to={templatePath}>{title}</TemplateOutlineRowTitle>
        </TemplateOutlineRowTitleWrapper>
        <TemplateOutlineRowDescription>{description}</TemplateOutlineRowDescription>
        <TemplateOutlineRowMetadata>
          {t('by', { author })} •
          <TemplateOutlineRowMetadataIcon name='arrow-down-to-line' size='2xs' />
          {t('downloads', { count: templateDownloadsCount })}
        </TemplateOutlineRowMetadata>
      </TemplateOutlineRowContent>
      {!!badge.text.length && (
        <TemplateOutlineRowCategoryBadgeWrapper>
          <TemplateOutlineRowCategoryBadge categories={badge.text} id={id} />
        </TemplateOutlineRowCategoryBadgeWrapper>
      )}
      <TemplateOutlineRowViewButton
        buttonType='secondary'
        id={`template-outline-link-${id}`}
        onClick={() => RouteTo(templatePath)}
        text={t('preview')}
      />
    </TemplateOutlineRowWrapper>
  );
};

export default TemplateOutlineRow;
