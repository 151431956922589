import * as yup from 'yup';

import initTranslations from '../../initTranslations';
import { getConfirmPasswordSchema } from '../confirmPassword';
import { multiErrorPasswordSchema } from '../multiErrorPassword';

const t = initTranslations('public_application.reset_password.validation');

export const getResetPasswordSchema = (multiErrorPasswordEnabled = false) =>
  yup.object().shape({
    ...getConfirmPasswordSchema(t('required'), t('password_confirmation_error')).fields,
    password: multiErrorPasswordEnabled
      ? multiErrorPasswordSchema
      : yup.string().trim().required(t('required')),
  });
