import { Formik, FormikErrors } from 'formik';
import { isEqual } from 'lodash';
import React, { useCallback, useContext, useReducer } from 'react';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import initTranslations from '../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { BrandStyleData } from '../../../../redux/services/resourceApis/accountSettings/types';
import { isHexColor } from '../../../design_system/helpers';
import Link from '../../../design_system/Link';
import HorizontalLine from '../HorizontalLine/HorizontalLine';
import TextStyleBar from '../TextStyleBar';
import BrandStyleForm from './BrandStyleForm';
import {
  AccountSettingsSection,
  BrandStylesSection,
  BrandStylesText,
  TextStyleBarWrapper,
  Title,
} from './styles';
import { BrandStyleAction, BrandStyleProps } from './types';
import UpdateLogo from './UpdateLogo';

const t = initTranslations('account_settings.brand_styles');

const reducer = (state: BrandStyleData, action: BrandStyleAction): BrandStyleData => {
  switch (action.type) {
    case 'changeLogoBackground':
      return { ...state, logo_background_color: action.logo_background_color };
    case 'changeAccentColor':
      return { ...state, accent_color: action.accent_color, accent_palette: null };
    case 'changeAccentPalette':
      return { ...state, accent_palette: action.accent_palette };
    case 'changeH1':
      return { ...state, brand_styles: { ...state.brand_styles, h1: action.h1 } };
    case 'changeH2':
      return { ...state, brand_styles: { ...state.brand_styles, h2: action.h2 } };
    case 'changeH3':
      return { ...state, brand_styles: { ...state.brand_styles, h3: action.h3 } };
    case 'changeH4':
      return { ...state, brand_styles: { ...state.brand_styles, h4: action.h4 } };
    case 'changeA':
      return { ...state, brand_styles: { ...state.brand_styles, a: action.a } };
    case 'changeP':
      return { ...state, brand_styles: { ...state.brand_styles, p: action.p } };
    case 'changeHrWidth':
      return {
        ...state,
        brand_styles: {
          ...state.brand_styles,
          hr: { ...state.brand_styles.hr, 'border-width': action.hr['border-width'] },
        },
      };
    case 'changeHrColor':
      return {
        ...state,
        brand_styles: {
          ...state.brand_styles,
          hr: { ...state.brand_styles.hr, 'border-color': action.hr['border-color'] },
        },
      };
    case 'resetStyles':
      return {
        ...state,
        brand_styles: action.brand_styles,
        previousStyles: action.previousStyles,
        isResetStyle: action.isResetStyle,
      };
    case 'undoStyles':
      return { ...state, brand_styles: action.brand_styles, isResetStyle: action.isResetStyle };
  }
};

const BrandStylesInner = ({
  accentColor,
  accentPalette,
  defaultLogoUrl,
  defaultBrandStyles,
  logoUrl,
  logoBackgroundColor,
  brandStyles,
}: BrandStyleProps) => {
  const initialState = {
    accent_color: accentColor,
    accent_palette: accentPalette,
    logo_background_color: logoBackgroundColor,
    brand_styles: brandStyles,
    previousStyles: brandStyles,
    isResetStyle: false,
  };
  const initialValue = {
    accent_color: accentColor,
    accent_palette: accentPalette,
    logo_background_color: logoBackgroundColor,
  };

  const paywallCtx = useContext(PaywallContext);
  const brandStylesLocked = paywallCtx.includes('brand_styles');
  const navigateToBillingOverlay = useRedirectToBillingOverlay();

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    accent_color,
    accent_palette,
    logo_background_color,
    brand_styles,
    previousStyles,
    isResetStyle,
  } = state;

  const [updateBrandStyle, result] = useUpdateAccountDataMutation();
  const { isLoading } = result;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  const validate = ({ logo_background_color, accent_color }: BrandStyleData) => {
    const errors: FormikErrors<BrandStyleData> = {};
    if (!logo_background_color) {
      errors.logo_background_color = t('errors.required');
    } else if (!isHexColor(logo_background_color)) {
      errors.logo_background_color = t('errors.invalid');
    }
    if (!accent_color) {
      errors.accent_color = t('errors.required');
    } else if (!isHexColor(accent_color)) {
      errors.accent_color = t('errors.invalid');
    }
    return errors;
  };

  const onSubmit = (value: BrandStyleData) => {
    brandStylesLocked ? navigateToBillingOverlay() : updateBrandStyle({ ...value, brand_styles });
  };

  const setH1 = useCallback((data) => {
    dispatch({ type: 'changeH1', h1: data });
  }, []);

  const setH2 = useCallback((data) => {
    dispatch({ type: 'changeH2', h2: data });
  }, []);

  const setH3 = useCallback((data) => {
    dispatch({ type: 'changeH3', h3: data });
  }, []);

  const setH4 = useCallback((data) => {
    dispatch({ type: 'changeH4', h4: data });
  }, []);

  const setP = useCallback((data) => {
    dispatch({ type: 'changeP', p: data });
  }, []);

  const setA = useCallback((data) => {
    dispatch({ type: 'changeA', a: data });
  }, []);

  const setHrWidth = useCallback((width) => {
    dispatch({ type: 'changeHrWidth', hr: { 'border-width': width } });
  }, []);

  const setHrColor = useCallback((color) => {
    dispatch({ type: 'changeHrColor', hr: { 'border-color': color } });
  }, []);

  const resetStyles = () => {
    dispatch({
      type: 'resetStyles',
      brand_styles: defaultBrandStyles,
      previousStyles: brand_styles,
      isResetStyle: true,
    });
  };

  const undoStyles = () => {
    dispatch({ type: 'undoStyles', brand_styles: previousStyles, isResetStyle: false });
  };

  const isValuesUnchanged = isEqual(
    {
      accentColor,
      accentPalette,
      logoBackgroundColor,
      brandStyles,
    },
    {
      accentColor: accent_color,
      accentPalette: accent_palette,
      logoBackgroundColor: logo_background_color,
      brandStyles: brand_styles,
    }
  );

  return (
    <>
      <AccountSettingsSection>
        <UpdateLogo
          defaultLogoUrl={defaultLogoUrl}
          logoBackgroundColor={logo_background_color}
          logoUrl={logoUrl}
        />
      </AccountSettingsSection>
      <Formik initialValues={initialValue} onSubmit={onSubmit} validate={validate} validateOnBlur>
        <AccountSettingsSection>
          <BrandStyleForm
            isLoading={isLoading}
            isValuesUnchanged={isValuesUnchanged}
            setAccentHex={(color) => dispatch({ type: 'changeAccentColor', accent_color: color })}
            setAccentPalette={(palette) =>
              dispatch({ type: 'changeAccentPalette', accent_palette: palette })
            }
            setLogoBgColor={(color) =>
              dispatch({ type: 'changeLogoBackground', logo_background_color: color })
            }
          >
            <div>
              <BrandStylesSection>
                <Title>{t('custom_brand_style.title')}</Title>
                <BrandStylesText>{t('resetStyles.set_custom_paragraph')}</BrandStylesText>
                {isResetStyle ? (
                  <Link behavesAs='button' onClick={undoStyles} text={t('resetStyles.undo')} />
                ) : (
                  <Link behavesAs='button' onClick={resetStyles} text={t('resetStyles.reset')} />
                )}
                <TextStyleBarWrapper>
                  <TextStyleBar
                    fontData={brand_styles.h1}
                    setFontData={setH1}
                    triggerElement={<h1>{t('heading', { size: 1 })}</h1>}
                  />
                </TextStyleBarWrapper>
                <TextStyleBarWrapper>
                  <TextStyleBar
                    fontData={brand_styles.h2}
                    setFontData={setH2}
                    triggerElement={<h2>{t('heading', { size: 2 })}</h2>}
                  />
                </TextStyleBarWrapper>
                <TextStyleBarWrapper>
                  <TextStyleBar
                    fontData={brand_styles.h3}
                    setFontData={setH3}
                    triggerElement={<h3>{t('heading', { size: 3 })}</h3>}
                  />
                </TextStyleBarWrapper>
                <TextStyleBarWrapper>
                  <TextStyleBar
                    fontData={brand_styles.h4}
                    setFontData={setH4}
                    triggerElement={<h4>{t('heading', { size: 4 })}</h4>}
                  />
                </TextStyleBarWrapper>
                <TextStyleBarWrapper>
                  <TextStyleBar
                    fontData={brand_styles.p}
                    setFontData={setP}
                    triggerElement={<p>{t('normal')}</p>}
                  />
                </TextStyleBarWrapper>
                <TextStyleBarWrapper>
                  <TextStyleBar
                    fontData={brand_styles.a}
                    setFontData={setA}
                    triggerElement={<a href='#'>{t('link_text')}</a>}
                  />
                </TextStyleBarWrapper>
              </BrandStylesSection>
              <BrandStylesSection>
                <Title>{t('horizontal_line')}</Title>
                <HorizontalLine
                  brandStylesHr={brand_styles.hr}
                  setHrColor={setHrColor}
                  setHrWidth={setHrWidth}
                />
              </BrandStylesSection>
            </div>
          </BrandStyleForm>
        </AccountSettingsSection>
      </Formik>
    </>
  );
};

export default BrandStylesInner;
