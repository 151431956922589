import React, { useState } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import Badge from '../../../../design_system/display/badge';
import Link from '../../../../design_system/Link';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import SuperShareModal from '../../../people/SuperShare/SuperShareModal/SuperShareModal';

const GroupsWrapper = styled.div`
  width: 8.3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${mediaBreakpointPxMd}) {
    display: none;
  }
`;

const GroupsLink = styled(Link)`
  margin: 0 ${({ theme: { constants } }) => constants.spacerLg1};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  white-space: nowrap;
`;

// DS Override: Updated cursor to pointer
const StyledBadge = styled(Badge)`
  cursor: pointer;
`;

export type Props = {
  canAssignUsers: boolean;
  id: number;
  groupsCount: number;
};

const t = initTranslations('curriculums.content.group_badges');

const GroupBadge = ({ canAssignUsers, groupsCount, id }: Props) => {
  const [superShareModalVisible, setSuperShareModalVisible] = useState(false);

  return (
    <GroupsWrapper>
      {canAssignUsers ? (
        <>
          {groupsCount > 0 ? (
            <a id={`group-badge-curriculum-${id}`} onClick={() => setSuperShareModalVisible(true)}>
              <StyledBadge text={t('group', { count: groupsCount })} type='general' />
            </a>
          ) : (
            <GroupsLink
              behavesAs='button'
              onClick={() => setSuperShareModalVisible(true)}
              text={t('share_with_groups')}
            />
          )}
          {superShareModalVisible && (
            <SuperShareModal
              curriculumId={id}
              setShowSuperShareModal={setSuperShareModalVisible}
              showSuperShareModal={superShareModalVisible}
            />
          )}
        </>
      ) : (
        <Badge text={t('group', { count: groupsCount })} type='general' />
      )}
    </GroupsWrapper>
  );
};

export default GroupBadge;
