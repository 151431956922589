import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import Checkbox from '../../../../../../design_system/input/controls/Checkbox';
import { fontSm4 } from '../../../../../../styled/TypeSystem';

const AddTopicContainer = styled.div`
  display: flex;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerMd2}`};
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderRadiusSm} solid ${vars.trainualBrandBlueStrong}`};
  background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

// DS Override - to match design
const StyledPrimaryButton = styled(DefaultButton)`
  display: flex;
  flex: 1;
  box-shadow: 0 0 16px 0 rgba(6, 212, 174, 0.5);
`;

// DS Override - to match design
const StyledCheckbox = styled(Checkbox)<{ checked: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  label {
    color: ${({ theme: { vars } }) => vars.textSurface};
    ${fontSm4};
  }
  .checkbox-container {
    ${({ checked, theme: { vars } }) =>
      checked
        ? {
            'background-color': `${vars.trainualBrandMagentaMedium}`,
            'border-color': `${vars.trainualBrandMagentaMedium}`,
          }
        : { 'border-color': `${vars.foundationSurface1}` }}
  }
`;

// DS Override - to match design
const StyledSecondaryButton = styled(DefaultButton)`
  display: flex;
  flex: 1;
  border-color: ${({ theme: { vars } }) => vars.trainualBrandYellowSurface};
  box-shadow: 0 0 16px 0 rgba(253, 243, 147, 0.5);
  &:hover,
  &:focus {
    border-color: ${({ theme: { vars } }) => vars.trainualBrandYellowSurfaceLight};
  }
`;

const t = initTranslations('curriculums.ai_outliner.add_topic');

export type Props = {
  secondaryButtonClick: () => void;
  primaryButtonClick: () => void;
};

const AddTopicComponent = ({ primaryButtonClick, secondaryButtonClick }: Props) => {
  const [checkedBox, setCheckedBox] = useState<boolean>(false);
  const theme = useTheme();
  return (
    <AddTopicContainer>
      <StyledCheckbox
        checked={checkedBox}
        id='ai-outline-add-checkbox'
        label={t('checkbox_label')}
        name='ai-outline-add-checkbox'
        onCheck={(e) => setCheckedBox(e.target.checked)}
      />
      <ButtonWrapper>
        <StyledSecondaryButton
          buttonType='brand'
          iconColor={theme.vars.textSurface}
          iconName='arrow-left'
          id='ai-outline-add-topic-secondary'
          onClick={secondaryButtonClick}
          text={t('secondary_button')}
        />
        <StyledPrimaryButton
          buttonType='brand'
          disabled={!checkedBox}
          id='ai-outline-add-topic-primary'
          onClick={primaryButtonClick}
          text={t('primary_button')}
        />
      </ButtonWrapper>
    </AddTopicContainer>
  );
};

export default AddTopicComponent;
