import React, { FC } from 'react';

import CheckOption from '../../shared/CheckOption/CheckOption';
import TitledPopup from '../../shared/TitledPopup/TitledPopup';
import { DURATION_FREQUENCIES } from './_data';
import { FrequenciesPopupContainer } from './styles';
import { FrequenciesPopupProps } from './types';

const FrequenciesPopup: FC<FrequenciesPopupProps> = ({ selectedValue, onSelectValue, onClose }) => {
  return (
    <FrequenciesPopupContainer>
      <TitledPopup onClose={onClose}>
        {Object.entries(DURATION_FREQUENCIES).map(([frequency, label]) => (
          <CheckOption
            key={frequency}
            label={label}
            name='frequency'
            onSelectValue={onSelectValue}
            selectedValue={selectedValue}
            value={frequency}
          />
        ))}
      </TitledPopup>
    </FrequenciesPopupContainer>
  );
};

export default FrequenciesPopup;
