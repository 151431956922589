import { OutlineTemplatesFilersState } from '../../../../../redux/services/resourceApis/templates/types';
import { initialFiltersState } from '../TemplatesOutlineIndexPage/reducer';
import { FiltersAction, OutlineTemplatesFilters } from './types';

const getStateAccordingToCheckedValue = (
  relatedFilters: OutlineTemplatesFilters,
  checkboxValue: string
) => {
  const isNotInitialState = relatedFilters.length;
  const isCheckedValue = isNotInitialState && (relatedFilters as string[]).includes(checkboxValue);

  if (isCheckedValue) {
    return relatedFilters.filter((filter) => filter !== checkboxValue);
  } else {
    return [...relatedFilters, checkboxValue];
  }
};

export const selectedFiltersReducer = (
  state: OutlineTemplatesFilersState,
  action: FiltersAction
): OutlineTemplatesFilersState => {
  switch (action.type) {
    case 'changeAuthors': {
      return { ...state, author: action.value };
    }
    case 'changeRoles': {
      return { ...state, suggestedGroups: action.value };
    }
    case 'changeTags': {
      return { ...state, tags: action.value };
    }
    case 'changeSectors': {
      return { ...state, sectors: getStateAccordingToCheckedValue(state.sectors, action.value) };
    }
    case 'changeCategories': {
      return {
        ...state,
        categoryIds: getStateAccordingToCheckedValue(state.categoryIds, action.value),
      };
    }
    case 'clearSelectedFilters': {
      return { ...initialFiltersState };
    }
  }
};
