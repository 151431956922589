import { useFormik } from 'formik';
import React, { FC, KeyboardEvent } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { CONTENT_URL_TITLE_MAX_CHARACTERS } from '../../../../../DelegationPlanner/shared/constants/modals';
import { responsibilityContentUrlValidationSchema } from '../../../../../DelegationPlanner/shared/utils/validators';
import {
  CTAWrapper,
  EditFormWrapper,
  FormContentWrapper,
  StyledButton,
  StyledEditButton,
  StyledInput,
  StyledInputFieldWithCharacterCounter,
} from './styles';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.linked_content');

interface ContentUrlFormProps {
  initialTitle: string;
  initialUrl: string;
  handleSave: (title: string, url: string) => void;
  handleCancel: () => void;
  submitButtonText?: string;
  showCancelButton?: boolean;
}

const ContentUrlForm: FC<ContentUrlFormProps> = ({
  initialTitle,
  initialUrl,
  handleSave,
  handleCancel,
  submitButtonText = t('connect_content'),
  showCancelButton = true,
}) => {
  const formik = useFormik({
    initialValues: {
      contentTitle: initialTitle,
      contentUrl: initialUrl,
    },
    validationSchema: responsibilityContentUrlValidationSchema,
    onSubmit: () => {
      handleSave(values.contentTitle, values.contentUrl);
    },
  });

  const { handleChange, handleBlur, handleSubmit, values, errors, touched } = formik;

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!isSubmitDisabled()) {
        handleSubmit();
      }
    }
  };

  const isSubmitDisabled = () => {
    const contentTitlePresent = values.contentTitle.trim().length > 0;
    const contentUrlPresent = values.contentUrl.trim().length > 0;
    const hasErrors = !!errors.contentUrl || !!errors.contentTitle;

    return hasErrors || !(contentTitlePresent && contentUrlPresent);
  };

  return (
    <FormContentWrapper>
      <EditFormWrapper>
        <StyledInputFieldWithCharacterCounter
          errorText={touched.contentTitle ? errors.contentTitle : ''}
          id='content-title-input'
          inputFor='Title'
          label={t('content_title')}
          maxCharacters={CONTENT_URL_TITLE_MAX_CHARACTERS}
          name='contentTitle'
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyPress={handleEnterKeyPress}
          placeholder={t('input.placeholders.content_title')}
          value={values.contentTitle}
        />
        <StyledInput
          errorText={touched.contentUrl ? errors.contentUrl : ''}
          id='content-url-input'
          label={t('content_url')}
          name='contentUrl'
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyPress={handleEnterKeyPress}
          placeholder={t('input.placeholders.content_url')}
          value={values.contentUrl}
        />
        <CTAWrapper>
          {showCancelButton && (
            <StyledEditButton
              buttonType='tertiary'
              id='delegation-planner-cancel-responsibility-content-button'
              onClick={handleCancel}
              size='md'
              text={t('cancel')}
            />
          )}
          <StyledButton
            buttonType='primary'
            disabled={isSubmitDisabled()}
            id='delegation-planner-save-responsibility-content-button'
            isDisabled={isSubmitDisabled()}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            size='md'
            text={submitButtonText}
          />
        </CTAWrapper>
      </EditFormWrapper>
    </FormContentWrapper>
  );
};

export default ContentUrlForm;
