import React from 'react';
import { Route } from 'type-route';

import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import { AdjacentCurriculumElementProps } from '../../../../../types/Editor';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import { ControlButtonsContainer } from '../../shared/styles';
import { QuestionNavigationParams } from './types';

const t = initTranslations('survey_edit.question');

const EditorFormControlButtons = ({
  prevQuestionId,
  nextQuestionId,
  previous,
  next,
}: QuestionNavigationParams) => {
  const route = useRoute();
  const {
    params: { slug, surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;
  const elementAttributes = useCurriculumElement();

  const elementNavigationTerm = (navigationElement: AdjacentCurriculumElementProps) => {
    const { elementKind } = navigationElement;
    return elementAttributes({ elementKind }).termSingular.toLowerCase();
  };

  const elementNavigationRoute = (navigationElement: AdjacentCurriculumElementProps) => {
    const { elementKind, id: elementId } = navigationElement;
    return elementAttributes({ elementKind, elementId }).editRoute;
  };

  return (
    <ControlButtonsContainer>
      {prevQuestionId != undefined ? (
        <DefaultButton
          behavesAs='a'
          buttonType='tertiary'
          iconName='arrow-up'
          id='previous-survey-question-button'
          size='lg'
          text={t('previous_question')}
          {...routes.surveyQuestionEditor({ id: prevQuestionId, surveyId, slug }).link}
        />
      ) : (
        previous && (
          <DefaultButton
            behavesAs='a'
            buttonType='tertiary'
            iconName='arrow-up'
            id='previous-editor-button'
            size='lg'
            text={t('previous_element', { element: elementNavigationTerm(previous) })}
            {...elementNavigationRoute(previous).link}
          />
        )
      )}
      {!!nextQuestionId && (
        <DefaultButton
          behavesAs='a'
          buttonType='tertiary'
          iconName='arrow-down'
          id='next-survey-question-button'
          size='lg'
          text={t('next_question')}
          {...routes.surveyQuestionEditor({ id: nextQuestionId, surveyId, slug }).link}
        />
      )}
      {!nextQuestionId && next && (
        <DefaultButton
          behavesAs='a'
          buttonType='tertiary'
          iconName='arrow-down'
          id='next-editor-button'
          size='lg'
          text={t('next_element', { element: elementNavigationTerm(next) })}
          {...elementNavigationRoute(next).link}
        />
      )}
    </ControlButtonsContainer>
  );
};

export default EditorFormControlButtons;
