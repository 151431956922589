import styled, { css } from 'styled-components';

import SearchField from '../../../../design_system/Triage/fields/SearchField';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';

export const UserSelectableElement = styled.div<{
  isSelected?: boolean;
  isInitiallySelected?: boolean;
}>`
  ${({ isSelected, isInitiallySelected, theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${constants.spacerSm3};
    padding: ${constants.spacerSm3} ${constants.spacerMd2};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusMd};
    background-color: ${isSelected ? vars.foundationBase1 : vars.foundationSurface1};

    pointer-events: ${isInitiallySelected ? 'none' : 'auto'};
    cursor: pointer;
  `};
`;

export const UserLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const AvatarAndNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const StyledSearchField = styled(SearchField)`
  width: 100%;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  &:focus-within {
    border: ${({ theme: { constants, vars } }) => `${constants.borderWidthSm} solid
    ${vars.accentPrimaryDefault}`};
  }
`;
