import { LoadingSize } from '../../../people/UsersOutline/Table/Column';
import {
  CellPosition,
  ContentAlignment,
  Display,
} from '../../../people/UsersOutline/Table/TableTypes';

export type RequestColumn = 'requester_name' | 'curriculum_title' | 'request_date' | 'status';

export interface Column {
  columnName: RequestColumn;
  loadingSize: LoadingSize;
  stickyPosition?: CellPosition;
  display?: Display;
  customColumn?: boolean;
  statusColumn?: boolean;
  contentAlignment?: ContentAlignment;
}

export const columns: Column[] = [
  {
    columnName: 'requester_name',
    stickyPosition: 'left',
    display: 'static',
    loadingSize: LoadingSize.large,
  },
  { columnName: 'curriculum_title', loadingSize: LoadingSize.medium, customColumn: true },
  { columnName: 'request_date', loadingSize: LoadingSize.medium },
  { columnName: 'status', loadingSize: LoadingSize.medium, statusColumn: true },
];
