import { Editor } from '@tiptap/react';
import DOMPurify from 'dompurify';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import { useEditorToolbarContext } from '../../../../../../../contexts/EditorToolbarContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import Flyout from '../../../../../../design_system/overlays/flyout';
import useActiveMenuHandler from '../../../../../publicApplication/utils/useActiveMenuHandler';
import { insertEditorContent } from '../../../../lib/insertEditorContent';
import { EmbedlyFlyoutId } from '../../../../plugins/Embedly/EmbedlyFlyout/EmbedlyFlyout';
import { StyledTextAreaField } from '../../../../shared/styles';
import { iframeValidationSchema } from '../../../../shared/validators';
import LabelToolbarButton from '../LabelToolbarButton';

const StyledFlyout = styled(Flyout)`
  width: 30rem;
`;

type Values = {
  embeddedCode: string | undefined;
};

const menuId = 'embed-iframe-flyout';

interface Props {
  editor?: Editor;
}

const t = initTranslations('editor.embed.iframe');

const IframeButton = ({ editor: passedEditor }: Props) => {
  const { selectedEmbedMenuItem, setSelectedEmbedMenuItem } = useEditorToolbarContext();
  const { isMenuOpen, closeMenu, setActiveMenuId } = useActiveMenuHandler({ menuId });
  const { editor: contextEditor } = useEditorContext();
  const editor = passedEditor || contextEditor;

  const onSubmit = ({ embeddedCode }: Values, { setErrors, resetForm }: FormikHelpers<Values>) => {
    if (!embeddedCode) return;

    const sanitizedCode = DOMPurify.sanitize(embeddedCode, { ADD_TAGS: ['iframe'] });

    // DOMPurify will strip out everything that contains dangerous HTM and thereby prevent XSS attacks and other nastiness.
    // If the user tries to send an iframe with dangerous HTML,
    // DOMPurify will return an empty iframe and we don't want to insert it.
    if (sanitizedCode === '<iframe></iframe>') {
      return setErrors({ embeddedCode: t('invalid_src') });
    }

    insertEditorContent({ editor, content: sanitizedCode });
    closeMenu();
    resetForm();
  };

  const navigateBackToEmbedlyMenu = useCallback(() => {
    setActiveMenuId(selectedEmbedMenuItem ? EmbedlyFlyoutId : null);
  }, [selectedEmbedMenuItem, setActiveMenuId]);

  const closeButtonHandler = (resetForm: () => void) => {
    resetForm();
    closeMenu();
    navigateBackToEmbedlyMenu();
    setSelectedEmbedMenuItem(undefined);
  };

  return (
    <Formik<Values>
      enableReinitialize
      initialValues={{ embeddedCode: undefined }}
      onSubmit={onSubmit}
      validationSchema={iframeValidationSchema}
    >
      {({ handleChange, handleSubmit, resetForm, errors, isValid, dirty }) => (
        <StyledFlyout
          className='embed-iframe-flyout'
          id={menuId}
          onClose={resetForm}
          placement='top-start'
          primaryButtonDisabled={!(isValid && dirty)}
          primaryButtonTask={handleSubmit}
          primaryButtonText={t('add_iframe')}
          secondaryButtonTask={() => closeButtonHandler(resetForm)}
          secondaryButtonText={selectedEmbedMenuItem ? t('back') : t('cancel')}
          title={t('add_iframe')}
          triggerButton={
            <LabelToolbarButton
              active={isMenuOpen}
              ariaLabel='aria-label'
              className='flyout-trigger'
              iconName='file-code'
              iconType='name'
              id='embed-button'
              label={t('iframe')}
            />
          }
        >
          <Form>
            <StyledTextAreaField
              data-testid='embedded-code-textarea'
              errorText={errors.embeddedCode}
              id='embedded-code-textarea'
              name='embeddedCode'
              onChange={handleChange}
              placeholder={t('placeholder')}
            />
          </Form>
        </StyledFlyout>
      )}
    </Formik>
  );
};

export default IframeButton;
