import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import SearchField from '../../../../design_system/Triage/fields/SearchField';
import PageHeader from '../../../../design_system/Triage/headers/PageHeader';
import { fontSm5 } from '../../../../styled/TypeSystem';
import Breadcrumb from './Breadcrumb/Breadcrumb';

const HeaderWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const SearchFieldWrapper = styled.div`
  width: 15rem;
`;

const SelectedCount = styled.p`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const SearchFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const t = initTranslations('curriculum_edit.manage_completions');

type Props = {
  id: number;
  curriculumTitle: string;
  searchValue: string;
  setSearch: (search: string) => void;
  isLoading: boolean;
  usersSelected: number;
};

const Header = ({
  id,
  curriculumTitle,
  searchValue,
  setSearch,
  isLoading,
  usersSelected,
}: Props) => {
  return (
    <HeaderWrapper className='manage-completions-header'>
      <Breadcrumb curriculumTitle={curriculumTitle} id={id} />
      <PageHeader subtitle={t('subtitle')} title={t('title', { curriculumTitle })} />
      <SearchFilterWrapper>
        <SelectedCount>{t('number_of_selected', { count: usersSelected })}</SelectedCount>
        <SearchFieldWrapper>
          <SearchField
            dataLoading={isLoading}
            placeholder={t('search_placeholder')}
            searchValue={searchValue}
            setSearchValue={(value: string) => setSearch(value)}
          />
        </SearchFieldWrapper>
      </SearchFilterWrapper>
    </HeaderWrapper>
  );
};

export default Header;
