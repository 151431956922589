import '@xyflow/react/dist/style.css';

import { ReactFlowProvider } from '@xyflow/react';
import React from 'react';

import { FlowchartEditorDataProvider } from '../../../../contexts/FlowchartEditorDataProvider';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useMinLoadingTime from '../../../../hooks/useMinLoadingTime';
import { useGetEditFlowchartQuery } from '../../../../redux/services/resourceApis/flowcharts/flowchartsApi';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import Body from './Body';

type Props = {
  id: number;
};

const FlowchartBase = ({ id }: Props) => {
  const {
    slug,
    splitFeatures: { contentFlowchartsEnabled },
  } = useCurrentAccount();
  const { data, isLoading, isError } = useGetEditFlowchartQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const isDelayedLoading = useMinLoadingTime(isLoading);

  if (!contentFlowchartsEnabled) {
    routeTo(routes.home({ slug }));
    return null;
  }

  return (
    <ReactFlowProvider>
      <FlowchartEditorDataProvider data={data} isError={isError} isLoading={isDelayedLoading}>
        <Body data={data} isLoading={isDelayedLoading} />
      </FlowchartEditorDataProvider>
    </ReactFlowProvider>
  );
};

export default FlowchartBase;
