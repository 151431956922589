import { StripeElementChangeEvent } from '@stripe/stripe-js';
import React, { useState } from 'react';

import { ReducerAction } from '../../components/application/shared/UpdatePaymentMethodForm/types';

type StripeElementName = 'cardNumber' | 'cardExpiry' | 'cardCvc';
type StripeElementsError = Record<StripeElementName, string | null>;

const useValidateStripeElements = (
  dispatch: React.Dispatch<React.SetStateAction<ReducerAction>>
) => {
  const [stripeElementsError, setStripeElementsError] = useState<StripeElementsError>({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });

  const validateStripeElement = (event: StripeElementChangeEvent) => {
    const updatedErrorState = {
      ...stripeElementsError,
      [event.elementType]: event.error ? event.error.message : null,
    };
    const isStripeError = Object.values(updatedErrorState).some(
      (value) => typeof value === 'string'
    );

    setStripeElementsError((prevState) => {
      return { ...prevState, ...updatedErrorState };
    });

    dispatch({
      type: 'setStripeError',
      payload: isStripeError || event.empty,
    });

    dispatch({
      type: 'setPaymentDetailsChanged',
      payload: true,
    });
  };

  return { stripeElementsError, validateStripeElement };
};

export default useValidateStripeElements;
