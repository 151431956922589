import React from 'react';
import styled from 'styled-components';

import CourseTitleButton from '../CourseTitleButton/CourseTitleButton';

const TableWrapper = styled.div`
  display: flex;
  border-top: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  flex-direction: column;
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
  flex-grow: 1;
  overflow-y: auto;
`;

export type CourseSuggestion = {
  emoji: string;
  title: string;
};

export interface Props {
  courses: CourseSuggestion[];
  setSelectedCourse: (selectedCourse: CourseSuggestion | null) => void;
}

const CourseSuggestionsTable = ({ courses, setSelectedCourse }: Props) => {
  return (
    <TableWrapper id='ai-outliner-course-suggestions-table'>
      {courses.map((course) => {
        const courseTitle = course.title.toLowerCase().split(' ').join('-');
        const { emoji, title } = course;
        return (
          <CourseTitleButton
            emoji={emoji}
            id={`open-ai-course-option-${courseTitle}`}
            key={course.title}
            onClick={() => setSelectedCourse(course)}
            title={title}
          />
        );
      })}
    </TableWrapper>
  );
};

export default CourseSuggestionsTable;
