import React, { FC, createContext } from 'react';

import { ContentFlyoutContextProps, ContentFlyoutProviderProps } from './types';

const initialContext: ContentFlyoutContextProps = {
  previouslySelectedItems: [],
  isSearchable: false,
  contextData: [],
  currentlySelectedItems: [],
  handleAddSelectedItem: () => null,
  handleAddFetchedSteps: () => null,
  handleAddFetchedCourses: () => null,
  handleSetNextPage: () => null,
};

export const ContentFlyoutContext = createContext<ContentFlyoutContextProps>(initialContext);

export const ContentFlyoutProvider: FC<ContentFlyoutProviderProps> = ({
  previouslySelectedItems,
  children,
  contextData,
  currentlySelectedItems,
  handleAddSelectedItem,
  handleAddFetchedCourses,
  handleAddFetchedSteps,
  isSearchable,
  handleSetNextPage,
}) => {
  return (
    <ContentFlyoutContext.Provider
      value={{
        previouslySelectedItems,
        handleAddSelectedItem,
        contextData,
        isSearchable,
        handleAddFetchedCourses,
        handleAddFetchedSteps,
        currentlySelectedItems,
        handleSetNextPage,
      }}
    >
      {children}
    </ContentFlyoutContext.Provider>
  );
};
