import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { DefaultHeaderStyled } from '../../../people/UsersOutline/Table/Styles';
import { StyledHeaderCell } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { StyledSortIcon } from '../../Styles';
import { TableHeaderCellProps } from './types';

const ElementReportTableHeaderCell = ({
  column: { columnName, stickyPosition = 'center', contentAlignment = 'start' },
  activeHeader,
  setActiveHeader,
  sortIcon,
  isSortable,
}: TableHeaderCellProps) => {
  const t = initTranslations('reports.element_reports_table');

  const getTitle = () => {
    if (columnName === 'clickable_arrow') return;

    return t(`headers.${columnName}`);
  };

  return (
    <DefaultHeaderStyled>
      <StyledHeaderCell
        active={activeHeader}
        className={isSortable ? `sortable-column ${columnName}` : `column-${columnName}`}
        contentAlignment={contentAlignment}
        isSortable={isSortable}
        onClick={() => setActiveHeader && setActiveHeader()}
        stickyPosition={stickyPosition}
      >
        {getTitle()}
        {sortIcon && <StyledSortIcon name={sortIcon} size='2xs' weight='solid' />}
      </StyledHeaderCell>
    </DefaultHeaderStyled>
  );
};

export default ElementReportTableHeaderCell;
