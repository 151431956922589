import styled, { css } from 'styled-components';

import { getVideoPreviewButtonSize } from '../../../../../lib/video_preview/getVideoPreviewButtonSize';
import { getVideoPreviewContainerSize } from '../../../../../lib/video_preview/getVideoPreviewContainerSize';
import { Size } from '../../../../../types/design_system/Size';
import IconButton from '../../../../design_system/buttons/IconButton';
import { mediaBreakpointPxLg } from '../../../../styled/Breakpoint';

export const VideoPreviewContainer = styled.div<{
  size: Size;
  isResponsive: boolean;
}>`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  justify-content: center;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};

  @media (min-width: ${mediaBreakpointPxLg}) {
    ${({ isResponsive }) =>
      !isResponsive &&
      css`
        width: 50%;
      `};
  }

  ${({ size }) => getVideoPreviewContainerSize(size)};
  ${({ isResponsive }) =>
    isResponsive &&
    css`
      max-width: 100%;
      min-height: auto;
    `};
`;

export const VideoPreviewPlayButtonWrapper = styled.div<{ size: Size; showWatchSvg: boolean }>`
  position: absolute;
  top: ${({ showWatchSvg }) => (showWatchSvg ? '25%' : '0')};
  left: 0;
  right: 0;
  margin: auto;

  ${({ showWatchSvg }) =>
    !showWatchSvg && {
      bottom: 0,
    }};

  ${({ size }) => getVideoPreviewButtonSize(size)};
`;

export const VideoPreviewPlayButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme: { vars } }) => vars.textSurface};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.5s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  }

  & > * {
    transition: transform 0.5s ease-in-out;
  }

  &:hover {
    transform: scale(1.2);

    & > * {
      transform: scale(0.85);
    }
  }
`;

export const Preview = styled.video`
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  filter: brightness(0.75);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
`;

export const VideoPreviewWatchSvg = styled.img`
  top: 47%;
  left: calc(50% - 2rem);
  width: 4.5rem;
  height: 4rem;
  position: absolute;
`;
