import { format } from 'date-fns';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import { useTrainingPathSetContext } from '../../../../../../../contexts/TrainingPathSetContext';
import CalendarIllustrationIcon from '../../../../../../../images/calendar_illustration_icon.svg';
import { daysUntil } from '../../../../../../../lib/dateUtils';
import initTranslations from '../../../../../../../lib/initTranslations';
import Badge from '../../../../../../design_system/display/badge';
import Icon from '../../../../../../design_system/display/icons/Icon';
import Link from '../../../../../../design_system/Link';
import { fontSm5 } from '../../../../../../styled/TypeSystem';
import { Title } from '../../../../../shared/DetailedTitle/Title';

const Card = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    border-radius: ${constants.borderRadiusLg};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    background-color: ${vars.foundationBase1};
    padding: 0 ${constants.spacerMd3};
    height: 3.5rem;
    align-items: center;
    width: 100%;
    gap: ${constants.spacerSm3};
    margin-bottom: ${constants.spacerMd1};
    ${fontSm5};
  `
);

const t = initTranslations('training_path.path_config.set_delay_message');

const SetDelayMessage = () => {
  const {
    vars: { stateSuccess, foundationSurface1 },
  } = useTheme();
  const {
    trainingPathSet: { availableAt, trainingPathSetUuid, delayValue, delayType, delayState },
    updateTrainingPathSet,
  } = useTrainingPathSetContext();

  if (delayState.status == 'no_delay') return null;

  const daysRemaining = availableAt ? daysUntil(new Date(availableAt)) : delayValue;

  const isInProgress = delayState.status == 'in_progress';
  const isComplete = delayState.status == 'complete' && availableAt;
  const isNotStarted = delayState.status == 'not_started' && daysRemaining;

  if (isInProgress) {
    return (
      <Card id={`delay-msg-set-${trainingPathSetUuid}`}>
        <div className='set-delay-msg-icon'>
          <Icon name='rotate' size='md' weight='solid' />
        </div>
        <Title fontWeight='regular' title={t('in_progress')} />
        <Badge
          text={t('badge_message', { availableAtInWords: delayState.availableAtInWords })}
          type='trainual-purple'
        />
        <Link
          behavesAs='button'
          className='reset-delay-button'
          onClick={() => updateTrainingPathSet({ resetDelay: true })}
          text={t('reset_delay')}
          underlineBehavior='dynamic'
        />
      </Card>
    );
  }

  if (isComplete) {
    return (
      <Card id={`delay-msg-set-${trainingPathSetUuid}`}>
        <div className='set-delay-msg-icon'>
          <Icon
            name='circle-check'
            primaryColor={foundationSurface1}
            secondaryColor={stateSuccess}
            size='md'
            weight='duotone'
          />
        </div>
        <Title
          fontWeight='regular'
          title={t('complete', {
            date: format(new Date(availableAt), 'M/d/yyyy'),
          })}
        />
      </Card>
    );
  }

  if (isNotStarted) {
    return (
      <Card id={`delay-msg-set-${trainingPathSetUuid}`}>
        <div className='set-delay-msg-icon'>
          <img alt={t('calendar_icon')} src={CalendarIllustrationIcon} />
        </div>
        <Title
          fontWeight='regular'
          title={t('not_started', {
            count: daysRemaining,
            delayType: t(`delay_type.${delayType}`),
          })}
        />
      </Card>
    );
  }

  return null;
};

export default SetDelayMessage;
