import { ElementReportPageFilterState } from '../../../../../redux/domains/reports/reportsSlice';
import { Action } from './types';

export const filterReducer = (
  state: ElementReportPageFilterState,
  action: Action
): ElementReportPageFilterState => {
  switch (action.type) {
    case 'setMinCompletionScore': {
      return {
        ...state,
        completionScoreMin: action.completionScoreMin,
      };
    }
    case 'setMaxCompletionScore': {
      return {
        ...state,
        completionScoreMax: action.completionScoreMax,
      };
    }
    case 'setContentTypeFilters': {
      return {
        ...state,
        contentTypeFilters: action.contentTypeFilters,
      };
    }
    case 'setDefaultFilters': {
      return {
        ...state,
        completionScoreMin: 0,
        completionScoreMax: 100,
        contentTypeFilters: [],
      };
    }
  }
};
