import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Route } from 'type-route';

import { GroupProvider } from '../../../../contexts/GroupContext';
import usePageTitle from '../../../../hooks/usePageTitle';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import { useIsAdmin } from '../../../../hooks/users/useIsPermission';
import EveryoneGroupImage from '../../../../images/everyone_group.svg';
import { RegisteredMenuId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useAppSelector } from '../../../../redux/hooks';
import { useGetGroupQuery } from '../../../../redux/services/resourceApis/groups/groupsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DismissableBanner from '../../../design_system/banners/DismissableBanner/DismissableBanner';
import { ThreeDotMenuProps } from '../../../design_system/Triage/menus/ThreeDotMenu/types';
import { routes } from '../../publicApplication/applicationRouter';
import ResponsibilityDetailsModal from '../../responsibilities/modals/ResponsibilityDetailsModal/ResponsibilityDetailsModal';
import ViewOnlyResponsibilityDetailsModal from '../../responsibilities/modals/ViewOnlyResponsibilityDetailsModal/ViewOnlyResponsibilityDetailsModal';
import ResponsibilitiesGroupShow from '../../responsibilities/SurfaceResponsibilities/ResponsibilitiesGroupShow';
import PageContent from '../../shared/page_content';
import GroupEditModal from '../GroupEditModal/GroupEditModal';
import GroupsStickyHeader from '../shared/GroupsStickyHeader/GroupsStickyHeader';
import ContentTable from './ContentTable/ContentTable';
import GroupDescription from './Description/GroupDescription';
import GroupManagers from './GroupManagers/GroupManagers';
import MembersTable from './MembersTable/MembersTable';

const t = initTranslations('groups.groups_edit');

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerLg2};
`;

export type Props = {
  route: Route<typeof routes.group>;
};

const GroupsEditView = ({
  route: {
    params: { id, tab },
  },
}: Props) => {
  const isAdmin = useIsAdmin();
  const menuId: RegisteredMenuId = 'group-edit-trigger-edit-group-modal';
  const [editGroupModalOpen, setEditGroupModalOpen] = useState(false);
  const headerThreeDotOptions: ThreeDotMenuProps = {
    id: menuId,
    menuOptions: [
      {
        title: t('header.edit_three_dot'),
        onClick: () => {
          setEditGroupModalOpen(true);
        },
        iconName: 'edit',
        iconWeight: 'regular',
        id: 'td-groups-edit-header-trigger-edit-modal',
        visible: true,
      },
    ],
  };
  const { data: group, error, isLoading } = useGetGroupQuery(id);
  const {
    responsibility: { plural: responsibilityPlural },
    productTerm,
  } = useAccountTerminology();
  usePageTitle({ resourceName: group?.name, resourceType: 'group', productTerm });
  const { configs } = usePrivateConfigs();
  const { constants, vars } = useTheme();

  const groupsUsingHelpPage = configs['GROUPS_USING_HELP_PAGE'];
  const isEveryoneGroup = group?.everyone;

  const { isResponsibilityDetailsModalOpen, selectedResponsibilityId } = useAppSelector(
    (state) => state.detailedResponsibilityModal
  );
  const showResponsibilityModal = isResponsibilityDetailsModalOpen && selectedResponsibilityId;

  const ResponsibilityModal = isAdmin
    ? ResponsibilityDetailsModal
    : ViewOnlyResponsibilityDetailsModal;

  if (isLoading) return <></>;
  if (error) return <BasicErrorDisplay error={error} />;
  if (!group) return <></>;

  return (
    <GroupProvider group={group}>
      {editGroupModalOpen && <GroupEditModal setEditGroupModalOpen={setEditGroupModalOpen} />}
      <GroupsStickyHeader threeDotOptions={headerThreeDotOptions} />
      <PageContent customTopPadding={constants.spacerMd3}>
        {isEveryoneGroup && (
          <DismissableBanner
            backgroundColor={vars.trainualBrandPurpleSurfaceLight}
            body={t('banner_everyone_group.body', { productTerm })}
            image={EveryoneGroupImage}
            linkProps={{
              title: t('banner_everyone_group.link_title'),
              href: groupsUsingHelpPage,
            }}
            title={t('banner_everyone_group.title')}
            type='everyone-group-edit'
          />
        )}

        {tab === 'overview' && (
          <TabWrapper id='groups-edit-overview-tab-wrapper'>
            <GroupDescription />
            {!group.everyone && (
              <ResponsibilitiesGroupShow
                description={t('content.responsibilities.description', {
                  responsibilities: responsibilityPlural.toLowerCase(),
                })}
                title={t('content.responsibilities.title', {
                  responsibilities: responsibilityPlural,
                })}
              />
            )}
            {!isEveryoneGroup && <GroupManagers groupId={group.id} />}
            <MembersTable />
          </TabWrapper>
        )}

        {tab === 'content' && (
          <TabWrapper id='groups-edit-content-tab-wrapper'>
            <ContentTable />
          </TabWrapper>
        )}
      </PageContent>
      {showResponsibilityModal && (
        <ResponsibilityModal
          id={selectedResponsibilityId}
          responsibiliableId={group.id}
          type='Group'
        />
      )}
    </GroupProvider>
  );
};

export default GroupsEditView;
