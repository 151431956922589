import styled from 'styled-components';

import ThemedImage from '../../../design_system/ThemedImage';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontSm5 } from '../../../styled/TypeSystem';
import Checkmark from '../Checkmark';
import { StyledButton } from '../IntegrationShowPage/styles';

export const StyledIntegrationLogo = styled(ThemedImage)`
  max-height: 6rem;
  max-width: 18.125rem;
`;

export const ActionButtons = styled.div`
  display: flex;
  @media (max-width: ${mediaBreakpointPxSm}) {
    width: 100%;
    flex-direction: column;
  }
`;

export const UpdateButton = styled(StyledButton)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  @media (max-width: ${mediaBreakpointPxSm}) {
    margin-right: 0;
  }
`;

export const StyledCheckmark = styled(Checkmark)`
  align-self: flex-end;

  @media (max-width: ${mediaBreakpointPxSm}) {
    align-self: center;
  }
`;

export const InviteMyTeamSection = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  padding: ${({ theme: { constants } }) => constants.spacerMd2} 0;
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
  border-top: solid ${({ theme: { vars } }) => vars.borderSurface2};
  border-bottom: solid ${({ theme: { vars } }) => vars.borderSurface2};
  ${fontSm5};
`;
