import { useFormikContext } from 'formik';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import { PaywallContext } from '../../../../../../contexts/PaywallContext';
import initTranslations from '../../../../../../lib/initTranslations';
import { BrandStyles } from '../../../../../../redux/services/resourceApis/accountSettings/types';
import Icon from '../../../../../design_system/display/icons/Icon';
import Hoverable from '../../../../../design_system/Hoverable';
import Link from '../../../../../design_system/Link';
import PaywallTooltip from '../../../../shared/tooltips/PaywallTooltip';
import { Title } from '../../../BrandStyles/styles';
import HorizontalLine from '../../../HorizontalLine/HorizontalLine';
import TextStyleBar from '../../../TextStyleBar';
import { FormSectionDescription, FormSectionTitle, PageHeader } from '../../styles';
import { SettingsData } from '../../types';
import LockContentStyles from './LockContentStyles';

const BrandStylesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
`;

const TitleInner = styled.div`
  width: 18rem;
`;

const TextStyleBarWrapper = styled.div`
  margin: ${({ theme: { constants } }) => constants.spacerMd3} 0;
`;

const t = initTranslations('account_settings.content_settings.brand_styles');

type BrandProps = {
  brandStyles: BrandStyles;
  defaultBrandStyles: BrandStyles;
};

const BrandStylesSection = ({ brandStyles, defaultBrandStyles }: BrandProps) => {
  const [isToggleHovered, setIsToggleHovered] = useState(false);
  const [isResetStyle, setIsResetStyle] = useState(false);
  const paywallCtx = useContext(PaywallContext);
  const brandStylesLocked = paywallCtx.includes('brand_styles');
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  const setH1 = useCallback((data) => setFieldValue('brand_styles.h1', data), [setFieldValue]);
  const setH2 = useCallback((data) => setFieldValue('brand_styles.h2', data), [setFieldValue]);
  const setH3 = useCallback((data) => setFieldValue('brand_styles.h3', data), [setFieldValue]);
  const setH4 = useCallback((data) => setFieldValue('brand_styles.h4', data), [setFieldValue]);
  const setP = useCallback((data) => setFieldValue('brand_styles.p', data), [setFieldValue]);
  const setA = useCallback((data) => setFieldValue('brand_styles.a', data), [setFieldValue]);
  const setHrWidth = useCallback(
    (data) => setFieldValue('brand_styles.hr.border-width', data),
    [setFieldValue]
  );
  const setHrColor = useCallback(
    (data) => setFieldValue('brand_styles.hr.border-color', data),
    [setFieldValue]
  );

  const resetStyles = () => {
    setFieldValue('brand_styles', defaultBrandStyles);
    setFieldValue('previousStyles', brandStyles);
    setIsResetStyle(true);
  };

  const undoStyles = () => {
    setFieldValue('brand_styles', values.previousStyles);
    setIsResetStyle(false);
  };

  const header = brandStylesLocked ? (
    <>
      <PaywallTooltip
        description={t('lock_brand_styles_paywall.description')}
        shouldBeVisible={isToggleHovered}
        title={t('lock_brand_styles_paywall.title')}
      />
      <Hoverable setIsHovered={setIsToggleHovered}>
        <TitleInner>
          <PageHeader>
            {t('header')} <Icon name='lock' />
          </PageHeader>
        </TitleInner>
      </Hoverable>
    </>
  ) : (
    <PageHeader>{t('header')}</PageHeader>
  );

  return (
    <BrandStylesContainer>
      {header}
      <div>
        <FormSectionTitle>{t('title')}</FormSectionTitle>
        <FormSectionDescription>
          {t('description')}
          {t('space')}
          <Link
            behavesAs='button'
            onClick={isResetStyle ? undoStyles : resetStyles}
            text={isResetStyle ? t('reset_styles.undo') : t('reset_styles.reset')}
          />
        </FormSectionDescription>
      </div>
      <div>
        <TextStyleBarWrapper>
          <TextStyleBar
            fontData={values.brand_styles.h1}
            setFontData={setH1}
            triggerElement={<h1>{t('heading', { size: 1 })}</h1>}
          />
        </TextStyleBarWrapper>
        <TextStyleBarWrapper>
          <TextStyleBar
            fontData={values.brand_styles.h2}
            setFontData={setH2}
            triggerElement={<h2>{t('heading', { size: 2 })}</h2>}
          />
        </TextStyleBarWrapper>
        <TextStyleBarWrapper>
          <TextStyleBar
            fontData={values.brand_styles.h3}
            setFontData={setH3}
            triggerElement={<h3>{t('heading', { size: 3 })}</h3>}
          />
        </TextStyleBarWrapper>
        <TextStyleBarWrapper>
          <TextStyleBar
            fontData={values.brand_styles.h4}
            setFontData={setH4}
            triggerElement={<h4>{t('heading', { size: 4 })}</h4>}
          />
        </TextStyleBarWrapper>
        <TextStyleBarWrapper>
          <TextStyleBar
            fontData={values.brand_styles.p}
            setFontData={setP}
            triggerElement={<p>{t('normal')}</p>}
          />
        </TextStyleBarWrapper>
        <TextStyleBarWrapper>
          <TextStyleBar
            fontData={values.brand_styles.a}
            setFontData={setA}
            triggerElement={<a href='#'>{t('link_text')}</a>}
          />
        </TextStyleBarWrapper>
        <Title>{t('horizontal_line')}</Title>
        <HorizontalLine
          brandStylesHr={values.brand_styles.hr}
          setHrColor={setHrColor}
          setHrWidth={setHrWidth}
        />
      </div>
      <LockContentStyles />
    </BrandStylesContainer>
  );
};

export default BrandStylesSection;
