import styled, { css } from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { fontSm4 } from '../../../../styled/TypeSystem';

export const ValidationPreviewWrapper = styled.div<{ isError?: boolean }>`
  ${({ isError, theme: { vars } }) =>
    isError &&
    css`
      input {
        border-color: ${vars.stateError};

        &:focus {
          border-color: ${vars.stateError};
        }
      }
    `};
`;

export const ValidationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  align-items: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const Indicator = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const StatusIcon = styled(Icon)<{ isTouched: boolean; isError: boolean }>`
  color: ${({ theme: { vars }, isError, isTouched }) =>
    !isTouched ? vars.textPlaceholder : isError ? vars.stateError : vars.stateSuccess};
`;

export const Message = styled.span`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};

  ${fontSm4}
`;
