import styled, { css } from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import SearchField from '../../../../design_system/Triage/fields/SearchField';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const HeaderActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const StyledSearchField = styled(SearchField)`
  width: calc(100% - 8.5rem);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  &:focus-within {
    border: ${({ theme: { constants, vars } }) => `${constants.borderWidthSm} solid
    ${vars.accentPrimaryDefault}`};
  }
`;

export const StyledLoaderWrapper = styled.div`
  display: flex;
  height: 16.5rem;
  justify-content: center;
  align-items: center;
`;

export const StyledLoadingImage = styled.img`
  width: 5rem;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const GroupSelectableElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 16.5rem;
  overflow: hidden auto;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const GroupSelectableElement = styled.div<{
  isSelected?: boolean;
  isInitiallySelected?: boolean;
}>`
  ${({ isSelected, isInitiallySelected, theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${constants.spacerSm3};
    padding: ${constants.spacerSm3} ${constants.spacerMd2};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusMd};
    background-color: ${isSelected ? vars.foundationBase1 : vars.foundationSurface1};

    pointer-events: ${isInitiallySelected ? 'none' : 'auto'};
    cursor: pointer;

    ${ActionsWrapper} {
      align-self: flex-end;
    }

    &:hover {
      background-color: ${vars.foundationBase1};
    }

    @media (min-width: ${mediaBreakpointPxSm}) {
      flex-direction: row;
      align-items: center;

      ${ActionsWrapper} {
        align-self: center;
      }
    }
  `};
`;

export const GroupLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  ${TruncatedText({})};
`;

export const GroupName = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  max-width: 18rem;
  ${TruncatedText({})};
  ${fontSm5};
`;

export const GroupKind = styled.div`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  ${fontSm4};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4.5rem;
`;

export const StyledIcon = styled(Icon)<{
  isInitiallySelected?: boolean;
  isCurrentlySelected?: boolean;
}>`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ isInitiallySelected, isCurrentlySelected, theme: { vars } }) =>
    isInitiallySelected
      ? vars.textDisabled
      : isCurrentlySelected
      ? vars.stateSuccess
      : vars.textSubdued};
`;
