import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { routes } from 'components/application/publicApplication/applicationRouter';
import { useEffect, useReducer } from 'react';
import { Route } from 'type-route';

import useDebounce from '../lib/useDebounce';
import { useGetGroupsForIndexViewQuery } from '../redux/services/resourceApis/groups/groupsApi';
import {
  GroupIndexResponse,
  GroupsIndexQueryParams,
} from '../redux/services/resourceApis/groups/types';
import { GroupKindWithAll, groupKinds } from '../types/Group';

type State = {
  queryParams: GroupsIndexQueryParams;
  searchQuery: string;
};

type Action =
  | { type: 'resetState' }
  | { type: 'resetPageAndKindParams' }
  | { type: 'setPageParam'; payload: number }
  | { type: 'setGroupKindParam'; payload: GroupKindWithAll }
  | { type: 'setSearchQueryParam'; payload: string }
  | { type: 'setSearchValue'; payload: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'resetState': {
      return {
        ...state,
        queryParams: defaultQueryParams,
        searchQuery: '',
      };
    }
    case 'resetPageAndKindParams': {
      const {
        queryParams: { search_query },
      } = state;

      return {
        ...state,
        queryParams: { ...defaultQueryParams, search_query },
      };
    }
    case 'setPageParam': {
      return {
        ...state,
        queryParams: { ...state.queryParams, page: action.payload },
      };
    }
    case 'setGroupKindParam': {
      return {
        ...state,
        queryParams: { ...state.queryParams, page: 1, group_kind: action.payload },
      };
    }
    case 'setSearchQueryParam': {
      return {
        ...state,
        queryParams: { ...state.queryParams, page: 1, search_query: action.payload },
      };
    }
    case 'setSearchValue': {
      const searchQuery = action.payload.trim() === '' ? '' : action.payload;
      return {
        ...state,
        searchQuery,
      };
    }
  }
};

export type GroupsIndexState = {
  data: GroupIndexResponse;
  groupsForIndexViewQueryError: FetchBaseQueryError | SerializedError | undefined;
  isFetching: boolean;
  isLoading: boolean;
  queryParams: GroupsIndexQueryParams;
  searchQuery: string;
  dispatch: (value: Action) => void;
};

const mockGroupIndexResponse: GroupIndexResponse = {
  groups: [],
  group_kinds: [],
  total_groups_count: 0,
  total_pages: 0,
  limit_value: 0,
  counts_by_kind: {
    all: {
      count: 0,
      pages: 0,
      remainder: 0,
    },
    role: {
      count: 0,
      pages: 0,
      remainder: 0,
    },
    team: {
      count: 0,
      pages: 0,
      remainder: 0,
    },
    department: {
      count: 0,
      pages: 0,
      remainder: 0,
    },
    location: {
      count: 0,
      pages: 0,
      remainder: 0,
    },
    other: {
      count: 0,
      pages: 0,
      remainder: 0,
    },
  },
};

const defaultQueryParams: GroupsIndexQueryParams = {
  group_kind: 'all',
  page: 1,
  search_query: '',
};

interface Props {
  route: Route<typeof routes.groups>;
}

export default function useGroupsIndexState({ route }: Props): GroupsIndexState {
  const groupKindQueryParam = groupKinds.find((kind) => kind === route.params.group_kind);
  const initialState: State = groupKindQueryParam
    ? {
        queryParams: { ...defaultQueryParams, group_kind: groupKindQueryParam },
        searchQuery: '',
      }
    : {
        queryParams: defaultQueryParams,
        searchQuery: '',
      };
  const [{ queryParams, searchQuery }, dispatch] = useReducer(reducer, initialState);
  const debouncedSearchValue = useDebounce<string>(searchQuery, 500);
  const { data, error, isLoading, isFetching, isSuccess } =
    useGetGroupsForIndexViewQuery(queryParams);

  useEffect(() => {
    if (isSuccess && data) {
      const noResults = data.groups.length === 0;
      const queryParamsChanged = queryParams !== defaultQueryParams;
      const needToUpdateQueryParams = noResults && queryParamsChanged;

      // Whem no results are returned, and the query params have changed, reset the query params
      if (needToUpdateQueryParams) {
        const noMatchingGroups =
          queryParams.group_kind !== 'all' && !data.group_kinds.includes(queryParams.group_kind);
        const onLastPage = queryParams.page === data.total_pages;
        const pageExceedingTotal = queryParams.page > data.total_pages;

        // When the last group of a specific kind is deleted, and the page would be empty, go to first page of all groups
        if (noMatchingGroups && onLastPage) {
          return dispatch({ type: 'resetPageAndKindParams' });
        }

        // If on a page greater than the total pages of a specific group, go to last page of that group
        if (pageExceedingTotal) {
          return dispatch({ type: 'setPageParam', payload: data.total_pages });
        }
      }
    }
  }, [data, isSuccess, queryParams]);

  useEffect(() => {
    dispatch({ type: 'setSearchQueryParam', payload: debouncedSearchValue });
  }, [debouncedSearchValue]);

  return {
    data: data || mockGroupIndexResponse,
    groupsForIndexViewQueryError: error,
    isFetching,
    isLoading,
    queryParams,
    searchQuery,
    dispatch,
  };
}
