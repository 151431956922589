import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { mediaBreakpointPxXl } from '../../../../../styled/Breakpoint';
import { fontSm5 } from '../../../../../styled/TypeSystem';

const TerminologySettingLabel = styled.span`
  margin: ${({ theme: { constants } }) => `0 0 ${constants.spacerSm2} ${constants.spacerSm3}`};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  white-space: nowrap;

  ${fontSm5};
`;

const TerminologyPluralsSettingWrapper = styled.div`
  width: 25%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};

  @media (min-width: ${mediaBreakpointPxXl}) {
    width: 17%;
  }
`;

const t = initTranslations('account_settings.content_settings.custom_terminology_form');

type TerminologyPluralsSettingToggleProps = {
  id: string;
  name: string;
  checked: boolean;
  handleToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  displaySettingLabel?: boolean;
};

const TerminologyPluralsSettingToggle = ({
  checked,
  id,
  handleToggle,
  name,
  className,
  displaySettingLabel,
}: TerminologyPluralsSettingToggleProps) => {
  return (
    <TerminologyPluralsSettingWrapper>
      {displaySettingLabel && (
        <TerminologySettingLabel>{t('plural_adjustments')}</TerminologySettingLabel>
      )}
      <Tooltip
        id={`${id}-tooltip`}
        place='top'
        text={t(checked ? 'disable_plurals' : 'enable_plurals')}
      />
      <div data-for={`${id}-tooltip`} data-tip>
        <Toggle
          checked={checked}
          className={className}
          handleToggle={handleToggle}
          id={id}
          name={name}
        />
      </div>
    </TerminologyPluralsSettingWrapper>
  );
};

export default TerminologyPluralsSettingToggle;
