import React, { FC } from 'react';

import Completion from '../Completion/Completion';
import ResultsFeedback from '../ResultsFeedback/ResultsFeedback';
import { ResultsMainBodyProps } from './types';

const ResultsMainBody: FC<ResultsMainBodyProps> = ({
  completionResponse,
  feedbackStatus,
  handleOpenAiFeedbackModal,
  steps,
  publishedStepsPresent,
}) => {
  return (
    <>
      <Completion
        completionResponse={completionResponse}
        publishedStepsPresent={publishedStepsPresent}
      />

      <ResultsFeedback
        completionResponse={completionResponse}
        feedbackStatus={feedbackStatus}
        handleOpenAiFeedbackModal={handleOpenAiFeedbackModal}
        steps={steps}
      />
    </>
  );
};

export default ResultsMainBody;
