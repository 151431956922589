import React from 'react';

import {
  TemplatePreviewContainer,
  TemplatePreviewContentWrapper,
  TemplatePreviewOverlay,
  TemplatePreviewPanel,
} from './styles';
import TemplatePreviewContent from './TemplatePreviewContent/TemplatePreviewContent';
import TemplatePreviewInfo from './TemplatePreviewInfo/TemplatePreviewInfo';
import TemplatePreviewOutline from './TemplatePreviewOutline/TemplatePreviewOutline';
import TemplatePreviewTopNavBar from './TemplatePreviewTopNavBar/TemplatePreviewTopNavBar';
import { TemplatePreviewPageProps } from './types';

const TemplatePreviewPage = ({
  route: {
    params: { id, curriculum_element_id: curriculumElementId, breadcrumb },
  },
}: TemplatePreviewPageProps) => {
  return (
    <TemplatePreviewOverlay id='template-preview-page'>
      <TemplatePreviewContentWrapper>
        <TemplatePreviewTopNavBar breadcrumb={breadcrumb} templateId={id} />
        <TemplatePreviewContainer>
          <TemplatePreviewPanel>
            <TemplatePreviewInfo templateId={id} />
            <TemplatePreviewOutline breadcrumb={breadcrumb} templateId={id} />
          </TemplatePreviewPanel>
          <TemplatePreviewContent curriculumElementId={curriculumElementId} id={id} />
        </TemplatePreviewContainer>
      </TemplatePreviewContentWrapper>
    </TemplatePreviewOverlay>
  );
};

export default TemplatePreviewPage;
