import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

import Icon from '../../../../../design_system/display/icons/Icon';
import {
  BoldText,
  ElementTitle,
  EmojiWrapper,
} from '../../../IndividualUserReportPage/IndividualUserReportTable/Columns/styles';

type Props = {
  id: number;
  title: string;
  element_type: string;
  iconName: IconName;
};

const ElementContent = ({ iconName, id, title }: Props) => {
  return (
    <>
      <EmojiWrapper id={`curriculum-element-${id}-emoji`}>
        <Icon name={iconName} size='sm' weight='regular' />
      </EmojiWrapper>
      <ElementTitle>
        <BoldText>{title}</BoldText>
      </ElementTitle>
    </>
  );
};

export default ElementContent;
