import { upperCase } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import { AccountEditorProvider } from '../../../../contexts/AccountEditorContext';
import { PdfExportResponse } from '../../../../redux/services/resourceApis/publicApplication/types';
import Loader from '../../../design_system/Triage/Loader';
import { fontMd3, fontSm3, fontSm4 } from '../../../styled/TypeSystem';
import PDFStepEditor from './PDFStepEditor';

const ExportContainer = styled.div`
  /* Use 'Noto Color Emoji' for PDF emoji rendering; fallback to 'Poppins' for other text. */
  font-family: 'Noto Color Emoji', 'Poppins', sans-serif;

  * {
    font-family: inherit;
  }

  hr {
    margin: ${({ theme: { constants } }) => constants.spacerMd2} 0;
    border: none;
    border-top: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderDefault}`};
  }
`;

const sharedStyles = css`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const CurriculumTitle = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin: 0;
  ${sharedStyles};
  ${fontSm3};
`;

const CourseTitle = styled.h2`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin: 0 0 ${({ theme: { constants } }) => constants.spacerMd2} 0;
  ${sharedStyles};
  ${fontSm4};
`;

const StepTitle = styled.h3`
  margin: ${({ theme: { constants } }) => constants.spacerMd2} 0
    ${({ theme: { constants } }) => constants.spacerMd2} 0;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${sharedStyles};
  ${fontMd3};
`;

const Emoji = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
`;

type Props = {
  isLoading: boolean;
  signatureExport?: boolean;
  curriculum: PdfExportResponse | undefined;
};

const PDFExportPage = ({ isLoading, curriculum, signatureExport = false }: Props) => {
  const { productTerm } = useAccountTerminology();

  if (isLoading) return <Loader />;
  if (!curriculum) return <></>;

  return (
    // Default values are used, as account data is not used in PDF export
    <AccountEditorProvider
      account={{ logoUrl: '', name: '', id: 0, logoBackgroundColor: null, productTerm }}
    >
      <ExportContainer>
        <CurriculumTitle>
          {curriculum.emoji && <Emoji>{curriculum.emoji}</Emoji>}
          {upperCase(curriculum.title)}
        </CurriculumTitle>
        {curriculum.courses.map((course) => {
          return (
            <div key={course.id}>
              <CourseTitle>
                {course.emoji && <Emoji>{course.emoji}</Emoji>}
                {course.title}
              </CourseTitle>
              <hr />
              {course.steps.map((step) => (
                <div key={step.id}>
                  <StepTitle>
                    {step.emoji && <Emoji>{step.emoji}</Emoji>}
                    {step.title}
                  </StepTitle>
                  <PDFStepEditor data={step.content} />
                </div>
              ))}
            </div>
          );
        })}
        {signatureExport && '{signature:user}'}
      </ExportContainer>
    </AccountEditorProvider>
  );
};

export default PDFExportPage;
