import React from 'react';
import styled, { css } from 'styled-components';

import LinkTabs, { LinkTab } from '../../../design_system/navigation/LinkTabs';

const TabsWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    padding-left: ${constants.spacerLg2};
    border-top: ${constants.borderWidthSm} solid ${vars.borderSurface1};
  `
);

export type GroupsTabsProps = {
  id: string;
  tabs: LinkTab[];
  isUrlParams?: boolean;
};

const GroupsTabs = ({ id, tabs, isUrlParams }: GroupsTabsProps) => {
  return (
    <TabsWrapper>
      <LinkTabs id={id} isUrlParams={isUrlParams} tabs={tabs} />
    </TabsWrapper>
  );
};

export default GroupsTabs;
