import styled from 'styled-components';

import { mediaBreakpointPxLg } from '../../../../../../styled/Breakpoint';
import { fontMd4 } from '../../../../../../styled/TypeSystem';

export const NeedMoreSeatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  padding: ${({ theme: { constants } }) =>
    `calc(${constants.spacerSm3} + ${constants.spacerSm2}) ${constants.spacerMd2} ${constants.spacerMd2} ${constants.spacerMd2}`};
`;

export const NeedMoreSeatsTitle = styled.h2`
  padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd4};
`;

export const NeedMoreSeatsButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding-top: ${({ theme: { constants } }) => constants.spacerLg1};
  gap: ${({ theme: { constants } }) => `calc(${constants.spacerMd2} + ${constants.spacerSm2})`};
  @media (max-width: ${mediaBreakpointPxLg}) {
    gap: ${({ theme: { constants } }) => constants.spacerMd2};
    flex-direction: column;
  }
`;
