import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { OrgChartResourceParent } from '../types/OrgChart';

export type OrgChartConfigSlideoutState = {
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  hasSubmitted: boolean;
  setHasSubmitted: Dispatch<SetStateAction<boolean>>;
  dataIsLoading: boolean;
  rootResource: OrgChartResource | null;
  resources: OrgChartResource[];
};

export type OrgChartResource = {
  description?: string;
  id: number;
  isSelectedRoot?: boolean;
  name: string;
  parent?: OrgChartResourceParent;
  selectedParentId: string | null;
  descendant_of: boolean;
};

export default function useOrgChartConfigSlideoutState(): OrgChartConfigSlideoutState {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!hasSubmitted && isSubmitting) setHasSubmitted(true);
  }, [hasSubmitted, isSubmitting]);

  return {
    dataIsLoading: false,
    isSubmitting,
    setIsSubmitting,
    hasSubmitted,
    setHasSubmitted,
    resources: [],
    rootResource: null,
  };
}
