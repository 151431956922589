import styled from 'styled-components';

import { fontSm5 } from '../../../../../../styled/TypeSystem';

export const TextWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm1};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5};
`;
