import styled from 'styled-components';

import P from '../../../../../design_system/text/P';
import { fontMd1, fontSm4 } from '../../../../../styled/TypeSystem';

export const TemplatePreviewQuestionHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  border-radius: ${({ theme: { constants } }) =>
    `${constants.borderRadiusXl} ${constants.borderRadiusXl} 0 0`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
`;

export const TemplatePreviewQuestionNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: ${({ theme: { constants } }) => constants.heightSm};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  color: ${({ theme: { vars } }) => vars.textDefault};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd1};
`;

export const TemplatePreviewAnswerType = styled(P)`
  color: ${({ theme: { vars } }) => vars.textDisabled};
  ${fontSm4};
`;

export const TemplatePreviewOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  &:last-child {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  }
`;

export const TemplatePreviewOptionTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  min-height: ${({ theme: { constants } }) => constants.heightLg};
  width: 100%;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${(props) => props.theme.constants.borderRadiusMd};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const TemplatePreviewOptionText = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd1};
`;
