interface NavigatorWithMsMaxTouchPoints extends Navigator {
  msMaxTouchPoints?: number;
}

const isTouchDevice = () => {
  const { ontouchstart } = window;
  const { maxTouchPoints } = navigator as NavigatorWithMsMaxTouchPoints;
  const { msMaxTouchPoints } = navigator as NavigatorWithMsMaxTouchPoints;

  return !!ontouchstart || maxTouchPoints > 0 || (msMaxTouchPoints ?? 0) > 0;
};

export default isTouchDevice;
