import React, { useContext } from 'react';
import ReactDOM from 'react-dom';

import { DynamicOverlayContext } from './DynamicOverlayContext';
import { DynamicOverlayContainer, DynamicOverlayContent } from './styles';
import { DynamicOverlayTargetProps } from './types';

const DynamicOverlay = () => {
  const { overlay, closeOverlay } = useContext(DynamicOverlayContext);

  const rect = overlay.targetRef?.current?.getBoundingClientRect();

  if (!rect) return null;

  const { top, left, width, height } = rect;

  const targetProperties: DynamicOverlayTargetProps = {
    position: {
      x: left + window.scrollX,
      y: top + window.scrollY,
    },
    size: {
      width,
      height,
    },
  };

  return ReactDOM.createPortal(
    <DynamicOverlayContainer onClick={closeOverlay}>
      <DynamicOverlayContent onClick={(e) => e.stopPropagation()} {...targetProperties}>
        {overlay.content}
      </DynamicOverlayContent>
    </DynamicOverlayContainer>,
    document.body
  );
};

export default DynamicOverlay;
