import React, { useCallback, useMemo } from 'react';

import { RegisteredId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import { useAssignedSubjects } from './AssignedSubjectsContext';

const t = initTranslations('profile');

const BulkUpdateButton = () => {
  const {
    managing,
    setManaging,
    unassignSubjects,
    selectedSubjectsNumber,
    selectedTopicsNumber,
    clearCompletion,
    markComplete,
  } = useAssignedSubjects();
  const { flash } = useFlashNotification();

  const clickHandler = useCallback(
    (
      actionType: 'clear-completion' | 'mark-complete' | 'unassign-subject',
      flashTranslation?: string
    ) => {
      const action = () => {
        switch (actionType) {
          case 'clear-completion':
            return clearCompletion();
          case 'mark-complete':
            return markComplete();
          case 'unassign-subject':
            return unassignSubjects();
        }
      };
      action();
      setManaging('none');
      flashTranslation && flash('info', flashTranslation);
    },
    [clearCompletion, flash, markComplete, setManaging, unassignSubjects]
  );

  type ButtonIdText =
    | {
        id: RegisteredId;
        buttonText: string;
        onClick: () => void;
      }
    | undefined;

  const buttonIdTextMap: ButtonIdText = useMemo(() => {
    switch (managing) {
      case 'clear-completion':
        return {
          id: 'subjects-assigned-clear-completion-button',
          buttonText: 'buttons.clear_completion',
          onClick: () => {
            !!selectedSubjectsNumber || !!selectedTopicsNumber
              ? clickHandler(managing)
              : setManaging('none');
          },
        };
      case 'mark-complete':
        return {
          id: 'subjects-assigned-mark-complete-button',
          buttonText: 'buttons.mark_complete',
          onClick: () => {
            !!selectedSubjectsNumber || !!selectedTopicsNumber
              ? clickHandler(managing)
              : setManaging('none');
          },
        };
      case 'unassign-subject':
        return {
          id: 'subjects-assigned-unassign-subjects-button',
          buttonText: 'buttons.unassign',
          onClick: () => {
            !!selectedSubjectsNumber
              ? clickHandler(managing, t('flash_notifications.unassignment'))
              : setManaging('none');
          },
        };
    }
  }, [clickHandler, managing, selectedSubjectsNumber, selectedTopicsNumber, setManaging]);

  if (!buttonIdTextMap) return <></>;

  return (
    <DefaultButton
      buttonType='primary'
      id={buttonIdTextMap.id}
      onClick={buttonIdTextMap.onClick}
      size='md'
      text={t(buttonIdTextMap.buttonText)}
    />
  );
};

export default BulkUpdateButton;
