import { useFormikContext } from 'formik';
import React, { useReducer } from 'react';

import EmptyStateDark from '../../../../../../images/empty_state/curriculums-empty-state-dark.svg';
import EmptyState from '../../../../../../images/empty_state/curriculums-empty-state.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import useDebounce from '../../../../../../lib/useDebounce';
import { useGetImportCaptureModalDataQuery } from '../../../../../../redux/services/resourceApis/steps/stepsApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import FieldLabel from '../../../../../design_system/core/FieldLabel';
import SearchField from '../../../../../design_system/Triage/fields/SearchField';
import NoResults from '../../../../../design_system/Triage/NoResults';
import RadioField from '../../../../../design_system/Triage/RadioField';
import { FormValues } from '../SaveCaptureToStepModal/types';
import CollapsibleFormElement from './CollapsibleFormElement';
import { collapseReducer, initialCollapseState } from './reducer';
import { CollapsibleFormElementsContainer, SearchContainer, StyledLoader } from './styles';

const t = initTranslations('steps.modals.trainual_capture.save_capture_to_step.select_course');

const CollapsibleFormElementsList = () => {
  const [{ searchValue }, dispatch] = useReducer(collapseReducer, initialCollapseState);
  const { values, handleChange } = useFormikContext<FormValues>();
  const debouncedSearchValue = useDebounce<string>(searchValue, 500);
  const { data, isFetching } = useGetImportCaptureModalDataQuery({ search: debouncedSearchValue });
  const { curriculum, topic } = useAccountTerminology();
  const lowercaseCurriculumTerm = curriculum.singular.toLowerCase();

  return (
    <>
      <SearchContainer>
        <FieldLabel text={t('where_to_save', { subject: lowercaseCurriculumTerm })} />
        <SearchField
          className='import-capture-modal-curriculums-search'
          dataLoading={isFetching}
          placeholder={t('search', { subject: lowercaseCurriculumTerm })}
          searchValue={searchValue}
          setSearchValue={(value: string) =>
            dispatch({ type: 'setSearchValue', searchValue: value })
          }
        />
      </SearchContainer>
      <FieldLabel
        required
        text={t('select_from_the_list', {
          subject: lowercaseCurriculumTerm,
          topic: topic.singular.toLowerCase(),
        })}
      />
      {data?.curriculums.length ? (
        <CollapsibleFormElementsContainer>
          {data.curriculums.map((curriculum) => {
            return (
              <CollapsibleFormElement
                id={`form-element-curriculum-${curriculum.id}`}
                key={curriculum.id}
                title={curriculum.title}
              >
                <RadioField
                  name='courseId'
                  onChange={handleChange}
                  options={curriculum.courses.map((course) => ({
                    text: course.title,
                    value: String(course.id),
                  }))}
                  orientation='vertical'
                  value={values.courseId}
                />
              </CollapsibleFormElement>
            );
          })}
        </CollapsibleFormElementsContainer>
      ) : isFetching ? (
        <StyledLoader />
      ) : (
        <NoResults
          darkImage={EmptyStateDark}
          heading={t('no_results', { subjects: curriculum.plural.toLowerCase() })}
          iconWidth='50%'
          lightImage={EmptyState}
          minHeight='14rem'
          showBorder={false}
        />
      )}
    </>
  );
};

export default CollapsibleFormElementsList;
