import React, { useCallback } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { ContentAccess } from '../../../../../redux/domains/curriculums/curriculumsSlice';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useToggleUsersAssignedMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import Icon from '../../../../design_system/display/icons/Icon';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { NotifiedUsers } from '../PeopleAssignedModal';

const StatusBlockWrapper = styled.div`
  width: 100%;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerLg1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ theme: { vars } }) => vars.chartGreen1};
  ${fontSm5};
`;

const StyleIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.chartGreen2};
`;

const t = initTranslations('people_confirmation_assigned_modal');

interface Props {
  curriculumId: number | undefined;
  newUsersCount: number;
  setShowConfirmationAssignedModal: (isShow: boolean) => void;
  setShowModal: (isShow: boolean) => void;
  setShowShareChangesModal: (isShow: boolean) => void;
  users: NotifiedUsers[];
}

const ConfirmAssignmentModal = ({
  curriculumId,
  newUsersCount,
  setShowConfirmationAssignedModal,
  setShowModal,
  setShowShareChangesModal,
  users,
}: Props) => {
  const closeModal = () => {
    setShowModal(true);
    setShowConfirmationAssignedModal(false);
  };

  const newUsers: {
    id: number;
    customPermission: ContentAccess;
    modified: boolean;
    shareChangesModal: boolean;
  }[] = users
    .filter((i) => i.new === true)
    .map((user) => {
      return {
        id: user.id,
        customPermission: 'default',
        modified: false,
        shareChangesModal: true,
      };
    });

  const [toggleUserAssignments, result] = useToggleUsersAssignedMutation();

  const sendNewUserAssigned = useCallback(() => {
    if (newUsers.length > 0) {
      toggleUserAssignments({ curriculumId, users: newUsers });
    }
    setShowConfirmationAssignedModal(false);
    setShowShareChangesModal(true);
  }, [
    newUsers,
    setShowConfirmationAssignedModal,
    setShowShareChangesModal,
    toggleUserAssignments,
    curriculumId,
  ]);

  const taskModalArgs: TaskModalProps = {
    secondaryButtonText: t('cancel_label'),
    title: t('title'),
    onCloseRequest: closeModal,
    heapModalName: 'people-confirm-assigned-modal',
    isDisabled: false,
    processing: result.isLoading,
    primaryButtonText: t('confirm'),
    primaryButtonTask: sendNewUserAssigned,
  };

  useDisplayFlashOnResponse({
    result,
    successMessage: t('flash_notification'),
    errorMessage: messageFromError(result.error)?.join(', '),
  });

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <StatusBlockWrapper>
        <StyleIcon name='check-circle' weight='solid' />
        <span
          dangerouslySetInnerHTML={{
            __html: t('text', { count: newUsersCount }),
          }}
        />
      </StatusBlockWrapper>
    </TaskModal>
  );
};

export default ConfirmAssignmentModal;
