import styled, { css } from 'styled-components';

import { Card } from '../ResponsibilityCard/styles';

export const DraggingWrapper = styled.div<{ isDragging: boolean }>`
  cursor: pointer;
  ${({ isDragging }) =>
    isDragging &&
    css`
      transform: rotate(3.3deg);
      cursor: grabbing;

      ${Card} {
        border: ${({ theme: { constants, vars } }) =>
          `${constants.borderWidthSm} solid ${vars.accentPrimaryDefault}`};
      }
    `};
`;

export const ResponsibilityDraggableContainer = styled.div`
  display: block;
  flex: none;
  touch-action: manipulation;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
`;
