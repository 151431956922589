import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { MetaUserAccess } from '../../../../../types/Curriculum';
import { fontSm3 } from '../../../../styled/TypeSystem';

const MetaData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm3};
`;

const AccessBadge = styled.div`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusSm};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm1} ${constants.spacerSm2}`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const CircleSpacer = styled.span`
  height: ${({ theme: { constants } }) => constants.borderWidthXl};
  width: ${({ theme: { constants } }) => constants.borderWidthXl};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  background-color: ${({ theme: { vars } }) => vars.textPlaceholder};
`;

type Props = {
  published: boolean;
  updatedAt: string;
  userAccess?: MetaUserAccess;
};

const t = initTranslations('curriculums.content.library_row');

const ContentMetadata = ({ published, updatedAt, userAccess }: Props) => {
  const lastUpdated = (updatedAt: string) => {
    return new Date(updatedAt).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    });
  };

  return (
    <MetaData className={published ? 'published' : 'unpublished'}>
      <AccessBadge>{t(`access.${userAccess}`)}</AccessBadge>
      {published && (
        <>
          <CircleSpacer />
          <div>{t('published')}</div>
        </>
      )}
      <CircleSpacer />
      <div>Updated {lastUpdated(updatedAt)}</div>
    </MetaData>
  );
};

export default ContentMetadata;
