import styled from 'styled-components';

import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { StatusPillContainer } from '../shared/StatusPill/styles';
import { TextWrapper } from '../shared/styles';

export const SelectedOptionTextContainer = styled(TextWrapper)`
  ${StatusPillContainer} {
    margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  }
  ${TruncatedText({})}
`;
