import React, { FC } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { CompletionWrapper, Loader, LoaderWrapper } from './styles';
import { CompletionProps } from './types';

const t = initTranslations('compose.search');

const Completion: FC<CompletionProps> = ({ completionResponse, publishedStepsPresent }) => {
  if (['pending', undefined].includes(completionResponse?.status) && publishedStepsPresent) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (completionResponse?.status === 'completed' && publishedStepsPresent) {
    return (
      <CompletionWrapper dangerouslySetInnerHTML={{ __html: completionResponse.completion }} />
    );
  }

  return <p>{t('no_results')}</p>;
};

export default Completion;
