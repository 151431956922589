import React, { useContext } from 'react';
import styled from 'styled-components';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import { useShowVerifyStatusBadge } from '../../../../hooks/curriculum/useShowVerifyStatusBadge';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../lib/initTranslations';
import { CurriculumShow } from '../../../../redux/services/resourceApis/curriculums/types';
import { ContentType } from '../../../../types/ContentType';
import Badge from '../../../design_system/display/badge';
import Icon from '../../../design_system/display/icons/Icon';
import P from '../../../design_system/text/P';
import ProgressBar from '../../../design_system/Triage/ProgressBar';
import { fontLg1 } from '../../../styled/TypeSystem';
import { EmojiWrapper } from '../../shared/styles';
import ViewModeDropdown from '../../shared/ViewModeDropdown/ViewModeDropdown';
import { viewOptions } from '../../shared/ViewModeDropdown/ViewOptions';
import OwnedByViewOnly from '../CurriculumAdmin/CurriculumAdminTop/TopSurface/TopSurfaceActionRow/OwnedByViewOnly/OwnedByViewOnly';
import TimeEstimatePill from '../CurriculumAdmin/CurriculumAdminTop/TopSurface/TopSurfaceActionRow/TimeEstimatePill';
import AdminVerifyStatusBadge from '../StatusBadges/VerifyStatusBadge/AdminVerifyStatusBadge';
import { ContentRow, ContentWrapper, DueDateWrapper, TitleWrapper } from './CurriculumShowStyles';

const TitleContainer = styled.div``;

const Title = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontLg1};
`;

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  align-items: center;
`;

const CompletionWrapper = styled.div`
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} 0`};
  max-width: 50%;
`;

const Description = styled(P)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const ContentRowWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const t = initTranslations('curriculum_show');

type TopSurfaceProps = {
  curriculum: CurriculumShow;
};

const TopSurface = ({ curriculum }: TopSurfaceProps) => {
  const {
    access_control,
    assignment: { due_date_message, completion },
    advanced_settings: { display_owner: displayOwner },
    completion_required,
    description,
    emoji,
    id,
    minutes_to_read: minutesToRead,
    minutes_to_read_formatted: minutesToReadFormatted,
    overdue,
    owner,
    title,
    verify_content_status: verifyContentStatus,
    verify_content_frequency_in_days: verifyContentFrequencyInDays,
    verified_content_at: verifiedContentAt,
    next_verified_content_at: nextVerifiedContentAt,
  } = curriculum;
  const ability = useCurrentUserAbilities();
  const canEditCurriculum = ability.can('update', `EditCurriculum-${curriculum.id}`);
  const blockedFeatures = useContext(PaywallContext);
  const { slug } = useCurrentAccount();
  const isVerifiedContent = verifyContentStatus === 'verified';
  const showVerifyStatusBadge = useShowVerifyStatusBadge({ verifyContentStatus, owner });

  return (
    <ContentWrapper id='curriculum-info'>
      <TitleWrapper>
        <EmojiWrapper>
          {emoji || <Icon name='file-lines' size='md' weight='regular' />}
        </EmojiWrapper>
        <TitleContainer>
          <Title text={title} />
        </TitleContainer>
      </TitleWrapper>
      {description && <Description id='curriculum-description' text={description} />}
      <ContentRowWrapper>
        <ContentRow>
          {canEditCurriculum && (
            <ViewModeDropdown
              initialSelectedOptionIndex={1}
              modeText
              options={viewOptions({ id, slug, contentType: ContentType.Curriculum })}
              style='subjectMode'
              titleBold
            />
          )}
          {displayOwner && <OwnedByViewOnly owner={owner} />}
          {minutesToRead > 0 && (
            <TimeEstimatePill id={id} minutesToReadFormatted={minutesToReadFormatted} />
          )}
          {showVerifyStatusBadge && (
            <AdminVerifyStatusBadge
              curriculumId={id}
              isVerifiedContent={isVerifiedContent}
              nextVerifyDate={nextVerifiedContentAt}
              owner={owner}
              showOwnerFlyout={false}
              verifiedDate={verifiedContentAt}
              verifyPeriod={verifyContentFrequencyInDays}
            />
          )}
          <Wrapper>
            {!completion_required && access_control !== 'discoverable' && (
              <Badge text={t('badges.reference')} type='general' />
            )}
          </Wrapper>
        </ContentRow>
      </ContentRowWrapper>
      {completion_required && (
        <CompletionWrapper id='completion-score'>
          <ProgressBar
            labelText={t('my_completion')}
            percent={completion?.score || 0}
            thickness='md'
          />
          {due_date_message &&
            completion?.score !== 100 &&
            !blockedFeatures.includes('due_date') && (
              <DueDateWrapper overdue={overdue}>
                <Icon name='calendar-alt' size='xs' />
                <span>{due_date_message}</span>
              </DueDateWrapper>
            )}
        </CompletionWrapper>
      )}
    </ContentWrapper>
  );
};

export default TopSurface;
