import React, { useState } from 'react';
import { Route } from 'type-route';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import { useIntegrationsParamsToUrl } from '../../../../hooks/integrations/useIntegrationsParamsToUrl';
import useIntegrationSortDropdownOptions from '../../../../hooks/useIntegrationSortDropdownOptions';
import usePageTitle from '../../../../hooks/usePageTitle';
import initTranslations from '../../../../lib/initTranslations';
import { useGetIntegrationsDataQuery } from '../../../../redux/services/resourceApis/settings/settingsApi';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import Column from '../../../design_system/Triage/layout/Grid/Column';
import Row from '../../../design_system/Triage/layout/Grid/Row';
import { DropdownMenuOption } from '../../../design_system/Triage/menus/DropdownMenu';
import { routes, useRoute } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import IntegrationCards from './IntegrationCards';
import { SearchHeader, StyledDropdownMenu, StyledLoader, StyledSearchField } from './styles';

const t = initTranslations('integrations');

const IntegrationPage = () => {
  const route = useRoute();
  const {
    params: { search, sort },
  } = route as Route<typeof routes.integrations>;

  const applyIntegrationsParamsToUrl = useIntegrationsParamsToUrl();
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'integrations', productTerm });

  const { data, isLoading } = useGetIntegrationsDataQuery();

  const { options } = useIntegrationSortDropdownOptions();
  const initialSortOption = options[sort === 'desc' ? 1 : 0];
  const [selectedSortOption, setSelectedSortOption] = useState(initialSortOption);
  const [searchValue, setSearchValue] = useState(search || '');

  const handleSearchInputChange = (value: string) => {
    setSearchValue(value);

    applyIntegrationsParamsToUrl({
      search: value,
      sort: selectedSortOption.sortDirection,
    });
  };

  const handleSorting = (option: DropdownMenuOption) => {
    setSelectedSortOption(option);

    applyIntegrationsParamsToUrl({
      search: searchValue,
      sort: option.sortDirection,
    });
  };

  return (
    <PageContent>
      <PageHeader subtitle={t('page_subtitle', { productTerm })} title={t('page_title')} />
      <Row>
        <Column>
          <SearchHeader>
            <StyledSearchField
              dataLoading={isLoading}
              placeholder={t('search.placeholder')}
              searchValue={searchValue}
              setSearchValue={handleSearchInputChange}
            />
            <StyledDropdownMenu
              options={options}
              selectedOption={selectedSortOption}
              setSelectedOption={handleSorting}
            />
          </SearchHeader>
        </Column>
      </Row>

      <Row className='integration-card-list'>
        {isLoading ? (
          <StyledLoader />
        ) : (
          <IntegrationCards
            integrations={data || []}
            searchValue={searchValue}
            sortDirection={selectedSortOption.sortDirection}
          />
        )}
      </Row>
    </PageContent>
  );
};

export default IntegrationPage;
