import React from 'react';

import { useBulkDeleteCurriculumsMutation } from '../../../../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import { BulkContentArchiveModalProps } from '../../types';
import BulkContentUpdateModal from './BulkContentUpdateModal';

const BulkContentDelete = ({
  onClose,
  selectedIds,
  onSuccess,
  type,
  curriculumsChecked,
}: BulkContentArchiveModalProps) => {
  const [bulkDelete, result] = useBulkDeleteCurriculumsMutation();

  return (
    <BulkContentUpdateModal
      bulkUpdate={({ curriculumIds }) => bulkDelete({ ids: curriculumIds })}
      bulkUpdateResult={result}
      curriculumsChecked={curriculumsChecked}
      onClose={onClose}
      onSuccess={onSuccess}
      selectedIds={selectedIds}
      type={type}
    />
  );
};

export default BulkContentDelete;
