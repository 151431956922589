import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useActionCableChannel from '../../../../hooks/useActionCableChannel';
import { messageFromError } from '../../../../redux/errors/helpers';
import { aiBotApi } from '../../../../redux/services/resourceApis/aiBot/aiBotApi';
import { useCreateChatCompletionMutation } from '../../../../redux/services/resourceApis/openAI/openAiAPI';
import {
  ChatMessage,
  CompletionResponse,
  TChatRole,
} from '../../../../redux/services/resourceApis/openAI/types';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import InputField from '../../../design_system/Triage/InputField';
import Loader from '../../../design_system/Triage/Loader';
import PageContent from '../../shared/page_content';
import { CompletionResponses } from '../GroupDescriptionModal/types';
import { BrandColorBlock, BrandingContainer, ButtonsWrapper, LogoImage, Textarea } from './styles';

const AI_BOT_COMPANY_URL_PROMPT_MESSAGES: ChatMessage[] = [
  {
    role: 'user',
    content: `You are an AI assistant tasked with extracting information from a website to answer specific questions.
Use the provided data to answer the following questions as fully as possible, sticking only to the content provided.
If there is no content in the provided text to answer a question, fill in 'No answer found'. The questions are:

1. Founding Story (Origin Story)
2. Company Mission & Vision
3. Company History
4. Products & Services
5. Writing Style/Tone
6. Culture & Values
7. Department Overviews
8. Key People
9. Customer Journey
10. Company Perks & Benefits

Please provide detailed and accurate answers based on the available data.
`,
  },
  {
    role: 'assistant',
    content:
      'Understood. Please provide the data scraped from the website, and I will begin extracting the relevant information to answer each of the questions.',
  },
];

const AI_BOT_COMPANY_NAME_PROMPT_MESSAGES: ChatMessage[] = [
  {
    role: 'user',
    content: `You are an AI assistant tasked with providing detailed answers to specific questions about a company based on your knowledge.
Use the company name provided to answer the following questions as fully as possible. If you cannot find an answer, fill in 'No answer found'. The questions are:

1. Founding Story (Origin Story)
2. Company Mission & Vision
3. Company History
4. Products & Services
5. Writing Style/Tone
6. Culture & Values
7. Department Overviews
8. Key People
9. Customer Journey
10. Company Perks & Benefits.
`,
  },
  {
    role: 'assistant',
    content:
      'Understood. Please provide the company name, and I will begin generating answers to each of the questions based on my knowledge.',
  },
  {
    role: 'user',
    content: 'The company name is: Trainual',
  },
];

// TODO: it's just a POC testing tool so we'll remove the code after POC tested and approved
const AiBotPage = () => {
  const [companyUrl, setCompanyUrl] = useState('');
  const [inputErrorMessage, setInputErrorMessage] = useState<string | null>(null);
  const [currentCompletionResponse, setCurrentCompletionResponse] = useState<CompletionResponses>({
    completion: undefined,
    prevCompletion: undefined,
    hasGeneratedSuccessfully: false,
  });
  const { completion, prevCompletion, hasGeneratedSuccessfully } = currentCompletionResponse;
  const isLoading = useMemo(() => completion?.status === 'pending', [completion]);
  const [trigger, { error }] = useCreateChatCompletionMutation();

  const [
    getBranding,
    { isFetching: isBrandingFetching, data: brandingResultData, error: brandingResultError },
  ] = aiBotApi.useLazyGetBrandingDataQuery({});
  const [getWebsiteContent, { isFetching: isWebsiteContentFetching }] =
    aiBotApi.useLazyGetWebsiteContentQuery({});

  const buildWebsiteContentPromptMessage = () => {
    (async () => {
      const { data } = await getWebsiteContent({
        companyUrl,
      });

      if (!!data) {
        setPromptMessages([...promptMessages, { role: 'user', content: data.websiteContent }]);
      }
    })();
  };

  const [promptType, setPromptType] = useState<'withCompanyUrl' | 'withCompanyName'>(
    'withCompanyUrl'
  );
  const [promptMessages, setPromptMessages] = useState<ChatMessage[]>(
    AI_BOT_COMPANY_URL_PROMPT_MESSAGES
  );

  const handleAddPrompt = () => {
    setPromptMessages([...promptMessages, { role: 'user', content: '' }]);
  };

  const handleRemovePrompt = (index: number) => {
    const newPrompts = promptMessages.filter((_, i) => i !== index);
    setPromptMessages(newPrompts);
  };

  const handleRoleChange = (index: number, role: TChatRole) => {
    const newPrompts = promptMessages.map((prompt, i) =>
      i === index ? { ...prompt, role } : prompt
    );
    setPromptMessages(newPrompts);
  };

  const handleContentChange = (index: number, content: string) => {
    const newPrompts = promptMessages.map((prompt, i) =>
      i === index ? { ...prompt, content } : prompt
    );
    setPromptMessages(newPrompts);
  };

  const handlePromptTypeChange = (promptType: 'withCompanyUrl' | 'withCompanyName') => {
    const promptMessagesByType =
      promptType == 'withCompanyUrl'
        ? AI_BOT_COMPANY_URL_PROMPT_MESSAGES
        : AI_BOT_COMPANY_NAME_PROMPT_MESSAGES;
    setPromptMessages(promptMessagesByType);
    setPromptType(promptType);
  };

  const generateTrigger = useCallback(() => {
    trigger({
      user_input: 'Test large input',
      model: 'gpt-4o-mini',
      messages: promptMessages,
      feature_name: 'AI-bot POC',
      temperature: 0,
      max_tokens: 2000,
      frequency_penalty: 0.1,
      presence_penalty: 0.1,
    });
  }, [promptMessages, trigger]);

  const channelProps = useMemo(() => {
    return {
      channel: 'AiCompletionsChannel',
      compose_feature: 'AI-bot POC',
    };
  }, []);

  const received = useCallback((completionResponse: CompletionResponse) => {
    setCurrentCompletionResponse((prevState) => {
      let hasGeneratedSuccessfully = prevState.hasGeneratedSuccessfully;
      if (completionResponse.status === 'completed') {
        hasGeneratedSuccessfully = true;
      }

      return {
        completion: completionResponse,
        prevCompletion: prevState.completion,
        hasGeneratedSuccessfully,
      };
    });
  }, []);

  useActionCableChannel<CompletionResponse>(channelProps, received);

  const errorMessage = useMemo(() => {
    if (error) {
      return messageFromError(error)?.join(', ');
    } else if (completion?.status === 'failed') {
      return completion.error_message;
    }
  }, [completion, error]);

  useEffect(() => {
    if (isLoading) {
      setInputErrorMessage(null);
    } else if (errorMessage) {
      setInputErrorMessage(errorMessage);
    }
  }, [errorMessage, isLoading]);

  return (
    <PageContent id='ai-bot-poc-page'>
      <InputField
        autoFocus
        errorText={messageFromError(brandingResultError)?.join(', ')}
        id='ai-bot-poc-company-url'
        label='Company url:'
        name='companyUrl'
        onChange={(e) => setCompanyUrl(e.target.value)}
        placeholder='Insert company url'
        type='text'
        value={companyUrl}
      />
      <br />
      {brandingResultData && (
        <BrandingContainer>
          <span>{brandingResultData.brandColor}</span>
          <BrandColorBlock color={brandingResultData.brandColor} />
          <LogoImage src={brandingResultData.logoUrl} />
        </BrandingContainer>
      )}
      <br />
      <DefaultButton
        fullWidth
        id='ai-bot-extract-logo-branding-button'
        loading={isBrandingFetching}
        onClick={() => getBranding({ companyUrl })}
        text='Get logo & branding'
      />
      <br />
      <br />
      <ButtonsWrapper>
        <h3>Prompt testing tool:</h3>
        <select
          onChange={(e) =>
            handlePromptTypeChange(e.target.value as 'withCompanyUrl' | 'withCompanyName')
          }
          value={promptType}
        >
          <option value='withCompanyUrl'>With company Url</option>
          <option value='withCcompanyName'>With company name</option>
        </select>
      </ButtonsWrapper>
      {promptMessages.map((prompt, index) => (
        <div key={index}>
          <select
            onChange={(e) => handleRoleChange(index, e.target.value as TChatRole)}
            value={prompt.role}
          >
            <option value='user'>User</option>
            <option value='system'>System</option>
            <option value='assistant'>Assistant</option>
          </select>
          <button onClick={() => handleRemovePrompt(index)}>Remove prompt fragment</button>
          <Textarea
            onChange={(e) => handleContentChange(index, e.target.value)}
            placeholder='Enter content'
            value={prompt.content}
          />
        </div>
      ))}
      <ButtonsWrapper>
        <DefaultButton
          buttonType='tertiary'
          fullWidth
          id='ai-bot-add-prompt-fragment-button'
          onClick={handleAddPrompt}
          text='Add new prompt fragment'
        />
        <DefaultButton
          buttonType='secondary'
          fullWidth
          id='ai-bot-scrape-website-to-prompt-fragment-button'
          loading={isWebsiteContentFetching}
          onClick={buildWebsiteContentPromptMessage}
          text='Scrape website content into prompt fragment'
        />
      </ButtonsWrapper>
      <DefaultButton
        fullWidth
        id='ai-bot-generate-completion-button'
        onClick={generateTrigger}
        text='Generate completion'
      />
      <br />
      <br />
      {isLoading && <Loader />}
      {hasGeneratedSuccessfully ? (
        <Textarea
          id='generated-content'
          value={completion?.completion || prevCompletion?.completion || ''}
        />
      ) : (
        <>{inputErrorMessage}</>
      )}
    </PageContent>
  );
};

export default AiBotPage;
