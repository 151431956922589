import { useDraggable } from '@dnd-kit/core';
import React, { FC, useState } from 'react';

import { DynamicContainer, SensorBox } from '../styles';
import { SensorProps } from '../types';
import { toPercents } from '../utils/toPercents';

const Sensor: FC<SensorProps> = ({ ratio, id, handleMouseLeave, handleMouseEnter }) => {
  const [dynamicContainerElement, setDynamicContainerElement] = useState<HTMLDivElement | null>(
    null
  );

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `range-slider-sensor-${id}`,
    data: {
      parentClientWidth: dynamicContainerElement?.offsetParent?.clientWidth,
    },
  });

  return (
    <DynamicContainer
      {...attributes}
      {...listeners}
      isDragging={isDragging}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={(el) => {
        setDynamicContainerElement(el);
        setNodeRef(el);
      }}
      style={{
        left: `${toPercents(ratio || 0)}`,
      }}
    >
      <SensorBox />
    </DynamicContainer>
  );
};

export default Sensor;
