import { useAccountTerminology } from '../../components/AccountTerminologyProvider';
import BulkIcon from '../../images/home/task_driven/bulk_icon.svg';
import DocumentIcon from '../../images/home/task_driven/document_icon.svg';
import HrIcon from '../../images/home/task_driven/hr_tool_icon.svg';
import RoleChartIcon from '../../images/home/task_driven/role_chart_icon.svg';
import RolesIcon from '../../images/home/task_driven/roles_icon.svg';
import ScratchIcon from '../../images/home/task_driven/scratch_icon.svg';
import TemplateIcon from '../../images/home/task_driven/template_icon.svg';
import UserIcon from '../../images/home/task_driven/user_icon.svg';
import initTranslations from '../../lib/initTranslations';
import {
  TaskDrivenOnboardingActionId,
  TaskDrivenOnboardingTaskName,
} from '../../types/TaskDrivenOnboarding';

const t = initTranslations('home.onboarding.task_driven');

type TaskDrivenOnboardingCardData = {
  [key in TaskDrivenOnboardingTaskName]: {
    title: string;
    description: string;
    actions: Array<{
      id: TaskDrivenOnboardingActionId;
      title: string;
      icon: string;
    }>;
  };
};

export function useTaskDrivenOnboardingCardData(): TaskDrivenOnboardingCardData {
  const {
    curriculum: { singular: curriculumTerm },
    productTerm,
  } = useAccountTerminology();

  return {
    documenting: {
      title: t('documenting_task.title'),
      description: t('documenting_task.description', {
        curriculumTerm: curriculumTerm.toLowerCase(),
      }),
      actions: [
        {
          id: 'choose-template',
          title: t('documenting_task.choose_template_action'),
          icon: TemplateIcon,
        },
        {
          id: 'import-document',
          title: t('documenting_task.import_document_action'),
          icon: DocumentIcon,
        },
        {
          id: 'start-from-scratch',
          title: t('documenting_task.start_from_scratch_action'),
          icon: ScratchIcon,
        },
      ],
    },
    organize: {
      title: t('organize_task.title'),
      description: t('organize_task.description'),
      actions: [
        {
          id: 'create-role-chart',
          title: t('organize_task.create_role_chart_action'),
          icon: RoleChartIcon,
        },
        {
          id: 'create-groups',
          title: t('organize_task.create_groups_action'),
          icon: RolesIcon,
        },
      ],
    },
    invite: {
      title: t('invite_task.title'),
      description: t('invite_task.description', { productTerm }),
      actions: [
        {
          id: 'add-individually',
          title: t('invite_task.add_individually_action'),
          icon: UserIcon,
        },
        {
          id: 'bulk-add',
          title: t('invite_task.bulk_add_action'),
          icon: BulkIcon,
        },
        {
          id: 'auto-add-hr',
          title: t('invite_task.auto_add_hr_action'),
          icon: HrIcon,
        },
      ],
    },
  };
}
