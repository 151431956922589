import styled, { css } from 'styled-components';

import { fontSm5 } from '../../../../../styled/TypeSystem';

export const SharedCourseTitle = styled.p`
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
  text-align: left;
  margin-block: 0;
  ${fontSm5};
`;

export const SharedCourseTitleWrapper = css`
  display: flex;
  width: 100%;
  min-height: 2.75rem;
  align-items: center;
`;

export const CourseTitleEmoji = styled.div`
  display: flex;
  align-items: center;
  width: 1.2rem;
  flex-shrink: 0;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;
