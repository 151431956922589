import React from 'react';

import { CallToActionWrapper } from './styles';
import { Props } from './types';

const CallToActionElementWrapper = ({ children, className, isVisible }: Props) => {
  return (
    <CallToActionWrapper className={className} isVisible={isVisible}>
      {children}
    </CallToActionWrapper>
  );
};

export default CallToActionElementWrapper;
