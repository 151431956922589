import React from 'react';

import CourseTitle from './CourseTitle';
import StepTitle from './StepTitle';

type ElementKind = 'course' | 'step';
export type ReadOnlyProps = {
  emoji: string | null | undefined;
  title: string;
};

export type Props = {
  resource: ElementKind;
} & ReadOnlyProps;

const ReadOnlyTitle = ({ emoji, title, resource }: Props) => {
  if (resource === 'course') return <CourseTitle emoji={emoji} title={title} />;

  return <StepTitle emoji={emoji} title={title} />;
};

export default ReadOnlyTitle;
