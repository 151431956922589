import styled from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';

export const CheckoutCustomLinkTimerWrapper = styled.div<{ isWarningState: boolean }>`
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  text-align: center;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ isWarningState, theme: { vars } }) =>
    isWarningState ? vars.stateHoverError : vars.trainualBrandPurpleStrong};
  background-color: ${({ isWarningState, theme: { vars } }) =>
    isWarningState ? vars.stateBackgroundError : vars.trainualBrandPurpleSurfaceLight};
  ${fontSm5};
`;
