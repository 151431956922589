import styled from 'styled-components';

import IconButton from '../../../design_system/buttons/IconButton';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';

export const TextStyleBarWrapper = styled.div`
  display: block;
  position: relative;
`;

export const TextStyleBarContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  max-width: max-content;
`;

export const ColorPickerWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerSm1} ${constants.spacerSm3}`};
`;

export const Button = styled(IconButton)<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  color: ${({
    active,
    disabled,
    theme: {
      vars: { accentPrimaryDefault, textDefault, textDisabled },
    },
  }) => (active ? accentPrimaryDefault : disabled ? textDisabled : textDefault)};
  &:focus {
    color: ${({
      active,
      disabled,
      theme: {
        vars: { accentPrimaryDefault, textDefault, textDisabled },
      },
    }) => (active ? accentPrimaryDefault : disabled ? textDisabled : textDefault)};
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme: { vars } }) => vars.foundationHover};
    color: ${({
      disabled,
      theme: {
        vars: { accentPrimaryDefault, textDisabled },
      },
    }) => (disabled ? textDisabled : accentPrimaryDefault)};
  }
`;

export const TextStyleBarButtonWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: fit-content;
  cursor: pointer;
`;

export const TriggerWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  h1,
  h2,
  h3,
  h4,
  p,
  a {
    font: inherit;
    color: inherit;
    pointer-events: none;
    line-height: 1.2;
    ${TruncatedText({})};
  }
`;

export const DropdownMenuValue = styled.div`
  padding-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const DropdownWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerSm1} ${constants.spacerSm2}`};
`;
