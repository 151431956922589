import styled from 'styled-components';

import { ToolbarWrapper } from '../../../ToolbarContainer';

export const ToolbarSkeletonWrapper = styled(ToolbarWrapper)`
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;
