import styled from 'styled-components';

import IconButton from '../../../../design_system/buttons/IconButton';

export const StyledIconButton = styled(IconButton)`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};

  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }
`;
