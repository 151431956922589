import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { routes } from '../../publicApplication/applicationRouter';
import { AttemptsWrapper, StyledLink } from './styles';
import { AttemptsProps } from './types';

const Attempts = ({ attempts, curriculumId, surveyId }: AttemptsProps) => {
  const { slug } = useCurrentAccount();
  return (
    <AttemptsWrapper>
      {attempts.map((attempt, index) => (
        <StyledLink
          key={`attempt-${index}`}
          to={routes.surveyByUserReport({ slug, attemptId: attempt.id, curriculumId, surveyId })}
        >
          {attempt.title}
        </StyledLink>
      ))}
    </AttemptsWrapper>
  );
};

export default Attempts;
