import React from 'react';

import useCurriculumElement from '../../../../../../hooks/useCurriculumElement';
import Badge from '../../../../../design_system/display/badge';
import { ColumnComponentProps } from '../types';

const ContentType = ({ record: { element_type } }: ColumnComponentProps) => {
  const { termSingular } = useCurriculumElement()({ elementType: element_type });

  return <Badge text={termSingular} type='info' />;
};

export default ContentType;
