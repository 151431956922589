import styled from 'styled-components';

import { fontMd5 } from '../../../styled/TypeSystem';

export const SurveyResultsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SurveyResultsHeader = styled.div<{ passed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 100%;
  background-color: ${({ passed, theme: { vars } }) =>
    passed ? vars.trainualBrandGreenSurfaceLight : vars.stateBackgroundError};
`;

export const SurveyResultsMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} 5rem 0 5rem`};
`;

export const SurveyResultsMessage = styled.div`
  display: flex;
  padding: 0.313rem 0;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  flex: 1 0 0;
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd5};
`;

export const SurveyResultsStatisticsWrapper = styled.div`
  width: 100%;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} 5rem`};
`;

export const SurveyQuestionCardsWrapper = styled.div`
  width: 100%;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg2} 5rem`};
`;
