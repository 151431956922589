import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { useCallback, useRef, useState } from 'react';
import { TransitionGroup as BaseTransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { useEditorCourseContext } from '../../../../../contexts/EditorCourseContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import {
  useCreateStepMutation,
  useUpdateStepMutation,
} from '../../../../../redux/services/resourceApis/steps/stepsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { DragOverlayItem } from '../../../shared/DragAndDrop/styles';
import { handleDragCancel, handleDragStart } from '../../../shared/DragAndDrop/utils';
import NewStepButton from '../../components/NewStepButton/NewStepButton';
import { Scrollable } from '../../shared/styles';
import ESignatureDisclaimer from './ESignatureLabel/ESignatureDisclaimer';
import ESignatureLabel from './ESignatureLabel/ESignatureLabel';
import StepOption from './StepOption';

const TransitionGroup = styled(BaseTransitionGroup)`
  .step-enter {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 750ms, transform 750ms;
  }
  .step-enter-active {
    opacity: 1;
    transform: translateX(0);
  }
  .step-exit {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 750ms, transform 750ms;
  }
  .step-exit-active {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

const t = initTranslations('outline_content');

const StepOptions = () => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const {
    step: { singular: stepSingular },
  } = useAccountTerminology();
  const [activeId, setActiveId] = useState<string | null>(null);
  const {
    course: { id, steps, eSignatureDisplayedAndRequired, canBeModified },
  } = useEditorCourseContext();
  const [createStep, result] = useCreateStepMutation();
  const [updateStep, updateResult] = useUpdateStepMutation();
  const { isLoading, data, error } = result;
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleOnDragEnd = useCallback(
    ({ over, active }: DragEndEvent): void => {
      if (over) {
        const overIndex = steps.map((step) => step.id).indexOf(over.id as number);
        updateStep({ id: active.id as number, position: overIndex + 1, courseId: id });
      }
    },
    [id, steps, updateStep]
  );

  const onSubmit = useCallback(
    (values) => {
      createStep({ courseId: id, title: values.title });
    },
    [id, createStep]
  );

  useDisplayFlashOnResponse({
    result: updateResult,
    errorMessage: t('drag_failure', { step: stepSingular }),
  });

  return (
    <Scrollable className='step-wrapper' ref={scrollRef}>
      <DndContext
        collisionDetection={closestCorners}
        onDragCancel={() => handleDragCancel(setActiveId)}
        onDragEnd={handleOnDragEnd}
        onDragStart={({ active }) => handleDragStart({ active, setActiveId })}
        sensors={sensors}
      >
        <SortableContext items={steps} strategy={verticalListSortingStrategy}>
          <TransitionGroup className='step-list'>
            {steps.map((step) => (
              <CSSTransition classNames='step' key={`step-${step.id}`} timeout={500}>
                <StepOption
                  canBeModified={canBeModified}
                  eSignatureDisplayedAndRequired={eSignatureDisplayedAndRequired}
                  isLastStep={steps.length === 1}
                  step={step}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
          <DragOverlay>{activeId && <DragOverlayItem height='3.5rem' />}</DragOverlay>
        </SortableContext>
      </DndContext>
      {eSignatureDisplayedAndRequired && (
        <>
          <ESignatureLabel />
          {!canBeModified && <ESignatureDisclaimer />}
        </>
      )}
      <NewStepButton
        data={data}
        error={error}
        isLoading={isLoading}
        onSubmit={onSubmit}
        result={result}
        scrollRef={scrollRef}
      />
    </Scrollable>
  );
};

export default StepOptions;
