import styled, { css } from 'styled-components';

import SingleSelectField from '../../../design_system/Triage/fields/SingleSelectField';
import { fontSm4 } from '../../../styled/TypeSystem';

export const FormWrapper = styled.div`
  ${({ theme: { constants, vars } }) => css`
    width: 22rem;
    display: flex;
    flex-direction: column;
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    background-color: ${vars.foundationSurface1};
    border-radius: ${constants.borderRadiusLg};
    margin-bottom: ${constants.spacerSm3};
    padding: ${constants.spacerMd2};
    gap: ${constants.spacerMd2};
  `};
`;

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// DS Override - set input border radius to match designs
export const StyledSingleSelectField = styled(SingleSelectField)`
  .react-select__control {
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  }
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const BaseInfo = css`
  display: flex;
  align-items: center;
  margin: ${({ theme: { constants } }) => constants.spacerSm3} 0;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  ${fontSm4};
`;

export const AlertInfo = styled.div`
  ${BaseInfo};
  background-color: ${({ theme: { vars } }) => vars.stateBackgroundInfo};

  & > svg {
    color: ${({ theme: { vars } }) => vars.stateInfo};
  }
`;

export const WarningInfo = styled.div`
  ${BaseInfo};
  background-color: ${({ theme: { vars } }) => vars.trainualBrandYellowSurfaceLight};

  & > svg {
    color: ${({ theme: { vars } }) => vars.stateCaution};
  }
`;
