import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Route } from 'type-route';
import { v4 as uuidv4 } from 'uuid';

import documentImportImage from '../../../../../images/document_import.svg';
import googleDriveLogo from '../../../../../images/google_drive_logo.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { Sector } from '../../../../../types/Sector';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import {
  ACCEPTED_FILE_TYPES,
  FILE_EXTENSIONS_REGEX,
  MAX_FILE_SIZE,
} from '../../modals/ImportFromDocumentModal/shared/constants/documentImport';
import { FormValues } from '../../modals/ImportFromDocumentModal/types';
import { BulkDocumentImportScreenProps } from '../types';
import UploadedFilesAndActions from '../UploadedFilesAndActions';
import {
  DividerText,
  DropZoneButtonWrapper,
  DropZoneContainer,
  Dropzone,
  DropzoneAssistiveText,
  DropzoneDescription,
  DropzoneHint,
  DropzoneImage,
  GoogleDrivePickerButton,
  OptionsDivider,
} from './styles';

const t = initTranslations('bulk_document_import');

const FileUploadForm = ({
  dispatch,
  bulkDocumentImportData,
  maxFilesReached,
  isBulkImportDataEmpty,
}: BulkDocumentImportScreenProps) => {
  const route = useRoute();
  const {
    params: { sector },
  } = route as Route<typeof routes.bulkDocumentImport>;
  const { values } = useFormikContext<FormValues>();
  const isDisabled = !!values.documentLink || maxFilesReached;
  const isDropZoneMode = values.currentFormStep === 'fileUpload' && isBulkImportDataEmpty;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const remainingSlots = MAX_FILE_SIZE - (bulkDocumentImportData?.length ?? 0);
      const filesToAdd = acceptedFiles.slice(0, remainingSlots);

      const files = filesToAdd.map((file: File) => ({
        file,
        id: uuidv4(),
        sector: (sector || 'process') as Sector,
        title: file.name.replace(FILE_EXTENSIONS_REGEX, ''),
      }));

      dispatch({ type: 'addImportFiles', bulkDocumentImportData: files });
    },
    [bulkDocumentImportData?.length, dispatch, sector]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    noKeyboard: true,
    multiple: true,
    accept: ACCEPTED_FILE_TYPES,
    disabled: isDisabled,
    onDrop,
  });

  return (
    <>
      {isDropZoneMode ? (
        <>
          <DropZoneContainer isDisabled={isDisabled}>
            <Dropzone {...getRootProps({ className: 'dropzone' })}>
              <input className='dz-hidden-input' {...getInputProps()} />
              <DropzoneImage src={documentImportImage} />
              <DropzoneDescription>{t('dropzone_description')}</DropzoneDescription>
              <DropzoneHint>{t('dropzone_hint_text')}</DropzoneHint>
            </Dropzone>
          </DropZoneContainer>
          <DropzoneAssistiveText>{t('dropzone_assistive_text')}</DropzoneAssistiveText>
          <OptionsDivider>
            <DividerText>{t('or')}</DividerText>
          </OptionsDivider>
          <GoogleDrivePickerButton
            buttonType='secondary'
            iconImage={googleDriveLogo}
            id='pick-files-from-google-drive-button'
            size='lg'
            text={t('add_from_google_drive')}
          />
        </>
      ) : (
        <>
          <DropZoneButtonWrapper>
            <Dropzone {...getRootProps({ className: 'dropzone' })}>
              <input className='dz-hidden-input' {...getInputProps()} />
            </Dropzone>
          </DropZoneButtonWrapper>
          <UploadedFilesAndActions
            bulkDocumentImportData={bulkDocumentImportData}
            dispatch={dispatch}
            handleOpenDropZonePicker={open}
            maxFilesReached={maxFilesReached}
          />
        </>
      )}
    </>
  );
};

export default FileUploadForm;
