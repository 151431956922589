import styled from 'styled-components';

import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import { fontSm4 } from '../../../styled/TypeSystem';

export const ESignaturePaywallBannerWrapper = styled.div<{ leftPosition: string }>`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} ${constants.spacerLg2} ${constants.spacerSm3} calc(${constants.spacerLg3} + ${constants.spacerMd2})`};
  box-shadow: 0 0 15px 0 ${({ theme: { vars } }) => vars.shadowBackground1};
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  transition: 0.5s ease;
  color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};

  @media (min-width: ${mediaBreakpointPxXl}) {
    left: ${({ leftPosition }) => leftPosition};
  }
`;

export const ESignaturePaywallBannerImage = styled.img`
  position: absolute;
  left: ${({ theme: { constants } }) => constants.spacerSm2};
  z-index: 1;
  width: 4.375rem;
`;

export const ESignaturePaywallBannerMessage = styled.span`
  z-index: 2;
  ${fontSm4};
`;

export const ESignaturePaywallBannerActionsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;
