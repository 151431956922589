import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import { ActivityAssociationParams, Owner } from '../../activities/types';
import NotificationUserName from '../NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';

const t = initTranslations('notifications');

export type CurriculumUploadWithAiProps = {
  activityKey: string;
  trackable: ActivityAssociationParams;
  owner: Owner;
};

const UploadWithAi = ({ activityKey, trackable, owner }: CurriculumUploadWithAiProps) => {
  const { curriculum } = useContext(AccountTerminologyContext);
  const { slug } = useCurrentAccount();

  const trackableText = (
    <>
      {trackable.is_active ? (
        <SourceBadge
          sourceName='curriculum'
          sourceRoute={routes.curriculumEdit({ slug, id: trackable.id })}
          sourceText={trackable.name}
        />
      ) : (
        <b>{trackable.name}</b>
      )}
    </>
  );

  return (
    <>
      <NotificationUserName user={owner} />
      <TextFragment>
        {t(activityKey, { curriculum: curriculum.singular.toLowerCase() })}
      </TextFragment>
      {trackableText}
      <TextFragment>{t('curriculum.from_document')}</TextFragment>
    </>
  );
};

export default UploadWithAi;
