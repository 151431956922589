import React from 'react';
import countryList from 'react-select-country-list';

import { FocusHandler } from '../../../../../hooks/billing/useValidateUpdatePaymentMethodForm/types';
import initTranslations from '../../../../../lib/initTranslations';
import { scrollToSelectedOptionInSelect } from '../../../../../lib/scrollToSelectedOptionInSelect';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import { InputFieldWrapper, SelectField } from '../styles';
import { ChangeHandler } from '../types';

const t = initTranslations('update_payment_method_modal.address');

type CountrySelectProps = {
  value: string;
  handleChange: ({ event, name, value }: ChangeHandler) => void;
  validateField: ({ event, name, value }: FocusHandler) => void;
  errorText: string | false;
  isFormProcessing: boolean;
};

const CountrySelect = ({
  value,
  handleChange,
  errorText,
  validateField,
  isFormProcessing,
}: CountrySelectProps) => {
  const handleSelection = (value: string) => {
    handleChange({ value, name: 'addressCountry' });
    validateCountrySelect(value);
  };

  const validateCountrySelect = (value: string) => {
    validateField({ value, name: 'addressCountry' });
  };

  return (
    <InputFieldWrapper>
      <SelectField
        className='country-select'
        defaultValue={null}
        disabled={isFormProcessing}
        errorText={errorText || undefined}
        fieldLabelText={t('country')}
        hideSelectedOptions={false}
        onMenuClose={() => validateCountrySelect(value)}
        onMenuOpen={() => scrollToSelectedOptionInSelect('country-select')}
        onNonDefaultSelected={handleSelection}
        options={formatOptions(countryList().getData())}
        placeholder={t('enter_country')}
        required
        size='md'
        value={value}
      />
    </InputFieldWrapper>
  );
};

export default CountrySelect;
