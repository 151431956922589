import React from 'react';

import { GENERATED_COLUMNS_ARRAY } from './_data';
import BoardColumnSkeleton from './particles/BoardColumnSkeleton';
import { BoardSkeletonContainer } from './styles';

const BoardSkeleton = () => {
  return (
    <BoardSkeletonContainer>
      {GENERATED_COLUMNS_ARRAY().map((_, index) => (
        <BoardColumnSkeleton key={index} />
      ))}
    </BoardSkeletonContainer>
  );
};

export default BoardSkeleton;
