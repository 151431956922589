import { TrainingPathSet } from '../../../redux/services/resourceApis/trainingPaths/types';
import { CompletionStatus } from '../shared/ProgressItem/ProgressTimeline/ProgressTimeline';

export const getSetStatus = (
  trainingPathSet: TrainingPathSet,
  firstIncompleteSet: TrainingPathSet | undefined
): CompletionStatus => {
  const { completionPercentage, trainingPathSetUuid } = trainingPathSet;

  if (trainingPathSetUuid === firstIncompleteSet?.trainingPathSetUuid) return 'in progress';
  if (completionPercentage === 100) return 'completed';
  return 'not started';
};
