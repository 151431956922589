import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import EditableSurveyHeaderElements from './EditableSurveyHeaderElements/EditableSurveyHeaderElements';
import {
  CoverWrapper,
  CurriculumElementHeaderWrapper,
  Description,
  Emoji,
  EmojiWrapper,
  HeaderBody,
  HeaderBodyWrapper,
  HeaderLabel,
  Title,
} from './styles';
import { PreviewCurriculumElementHeaderProps } from './types';

const t = initTranslations('curriculum_element_header');

const PreviewCurriculumElementHeader = ({
  id,
  title,
  description,
  elementType,
  displayEmoji = false,
  emoji,
  cover,
  children,
  editable,
}: PreviewCurriculumElementHeaderProps) => {
  const isCourse = elementType === 'course';

  return (
    <CurriculumElementHeaderWrapper className={`curriculum-element-${elementType}-${id}`}>
      {cover && (
        <CoverWrapper>
          <img alt={t('cover_alt_image')} src={cover} />
        </CoverWrapper>
      )}

      <HeaderBodyWrapper>
        <HeaderBody>
          {displayEmoji && (
            <EmojiWrapper>
              {!!emoji ? (
                <Emoji>{emoji}</Emoji>
              ) : (
                <Icon
                  name={isCourse ? 'file-lines' : 'clipboard-check'}
                  size='lg'
                  weight='regular'
                />
              )}
            </EmojiWrapper>
          )}
          {editable && !isCourse ? (
            <EditableSurveyHeaderElements description={description || ''} id={id} title={title} />
          ) : (
            <>
              <Title>{title}</Title>
              <Description>{description}</Description>
            </>
          )}
        </HeaderBody>
        {isCourse && <HeaderLabel>{t('course')}</HeaderLabel>}
      </HeaderBodyWrapper>
      {children}
    </CurriculumElementHeaderWrapper>
  );
};

export default PreviewCurriculumElementHeader;
