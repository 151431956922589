import React from 'react';

import { couponToDiscount } from '../../../../../lib/billing/stripeCouponToDiscount';
import { getFormattedPrice } from '../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../types/BillingInterval';
import { Coupon } from '../../../../../types/Coupon';
import { Discount } from '../../../../../types/Discount';
import {
  CheckoutPriceDetails,
  CheckoutPriceDetailsWrapper,
  CheckoutTotalSectionWrapper,
  CheckoutTotalTitle,
} from './styles';

type CheckoutTotalSectionProps = {
  totalPrice: number;
  coupon: Coupon;
  billingInterval: BillingInterval;
};

const t = initTranslations('checkout_overlay');

const CheckoutTotalSection = ({
  totalPrice,
  coupon,
  billingInterval,
}: CheckoutTotalSectionProps) => {
  const discount = couponToDiscount(coupon);
  const withDiscountSection = !!discount;

  const getCheckoutPriceDetails = (totalPrice: number, discount?: Discount) => {
    const price = getFormattedPrice(totalPrice, discount);

    return t(billingInterval === 'year' ? 'price_per_year' : 'price_per_month', { price });
  };

  return (
    <CheckoutTotalSectionWrapper withDiscountSection={!!discount}>
      <CheckoutTotalTitle text={t('total')} withDiscountSection={withDiscountSection} />
      <CheckoutPriceDetailsWrapper>
        <CheckoutPriceDetails
          strike={!!discount}
          text={getCheckoutPriceDetails(totalPrice)}
          withDiscountSection={withDiscountSection}
        />

        {discount && (
          <CheckoutPriceDetails
            id='checkout-price-with-discount'
            strike={false}
            text={getCheckoutPriceDetails(totalPrice, discount)}
          />
        )}
      </CheckoutPriceDetailsWrapper>
    </CheckoutTotalSectionWrapper>
  );
};

export default CheckoutTotalSection;
