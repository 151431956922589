import React, { useCallback, useMemo } from 'react';
import { CustomNodeElementProps } from 'react-d3-tree/lib/types/common';
import styled from 'styled-components';

import useCurrentUser from '../../../../hooks/useCurrentUser';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import useSlideout from '../../../../hooks/useSlideout';
import useWindowResize from '../../../../hooks/useWindowResize';
import NoResultsGraphicDark from '../../../../images/org_chart_dark.svg';
import NoResultsGraphic from '../../../../images/org_chart.svg';
import initTranslations from '../../../../lib/initTranslations';
import { OrgChartGroup } from '../../../../types/OrgChart';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import NoResults from '../../../design_system/Triage/NoResults';
import { OrgChartBodyProps } from '../OrgChartShared/OrgChartBodyProps';
import OrgChartCardWrapper from '../OrgChartShared/OrgChartCardWrapper';
import OrgChartSharedBody from '../OrgChartShared/OrgChartSharedBody';
import GroupChartCard from './GroupChartCard';

const Body = styled.div<{ disabled: boolean }>`
  position: relative;
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
`;

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
`;

type GroupChartBodyProps = {
  disabled?: boolean;
} & OrgChartBodyProps<OrgChartGroup>;

const t = initTranslations('role_chart');

const GroupChartBody = ({
  canUpdateOrgChart,
  zoomState,
  data,
  disabled = false,
  isLoading,
  translationPrefix,
  orgChartContainerRef,
  showConfigure,
}: GroupChartBodyProps) => {
  const groupSlideout = useSlideout('group-slideout');
  const { isExtraSmWindow } = useWindowResize();
  const { permission } = useCurrentUser();
  const isChartDisabled = useMemo(() => disabled && Boolean(data), [data, disabled]);
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();
  const handleOnClick = useCallback(
    ({ id: groupId }: OrgChartGroup) => {
      groupSlideout.open({ groupId: groupId.toString() });
    },
    [groupSlideout]
  );

  const roleChartCardView = useCallback(
    (rd3tNodeProps: CustomNodeElementProps) => {
      const nodeDatum = rd3tNodeProps.nodeDatum as unknown as OrgChartGroup;
      return (
        <OrgChartCardWrapper
          event={rd3tNodeProps}
          onClick={handleOnClick}
          translationPrefix={translationPrefix}
        >
          <GroupChartCard canUpdateOrgChart={canUpdateOrgChart} group={nodeDatum} />
        </OrgChartCardWrapper>
      );
    },
    [canUpdateOrgChart, handleOnClick, translationPrefix]
  );

  return (
    <Body disabled={isChartDisabled}>
      <OrgChartSharedBody<OrgChartGroup>
        NoResultsComponent={
          <NoResults
            darkImage={NoResultsGraphicDark}
            heading={
              disabled
                ? t('empty_state.no_results_upgrade_title')
                : t('empty_state.no_results_heading')
            }
            iconWidth={isExtraSmWindow ? '100%' : '590px'}
            lightImage={NoResultsGraphic}
            showSubheader={
              canUpdateOrgChart && (permission === 'billing_admin' || permission === 'admin')
            }
            subHeaderCta={{
              action: () => {
                disabled
                  ? navigateToBillingOverlay({
                      plan: 'scale',
                    })
                  : showConfigure();
              },
              text: t('empty_state.no_results_advice', {
                responsibilities: responsibilityPlural.toLowerCase(),
              }),
            }}
            subHeaderText={
              disabled
                ? t('empty_state.no_results_upgrade_advice')
                : t('empty_state.no_results_advice_2')
            }
          />
        }
        canUpdateOrgChart={canUpdateOrgChart}
        cardSize={{ height: 165, width: 240 }}
        cardView={roleChartCardView}
        data={data}
        isLoading={isLoading}
        orgChartContainerRef={orgChartContainerRef}
        translationPrefix={translationPrefix}
        zoomState={zoomState}
      />
      {isChartDisabled && <DisabledOverlay className='chart-disabled-overlay' />}
    </Body>
  );
};

export default GroupChartBody;
