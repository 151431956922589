import React, { useEffect, useReducer, useState } from 'react';

import { useCheckNoTrialFlow } from '../../../../../../hooks/billing/useCheckNoTrialFlow';
import { useCloseFullScreenOverlay } from '../../../../../../hooks/billing/useCloseFullScreenOverlay';
import { useCreateSubscription } from '../../../../../../hooks/billing/useCreateSubscription';
import { useSimplePricingFullScreenOverlay } from '../../../../../../hooks/billing/useSimplePricingFullScreenOverlay';
import { useValidateUpdatePaymentMethodForm } from '../../../../../../hooks/billing/useValidateUpdatePaymentMethodForm/useValidateUpdatePaymentMethodForm';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import GoYearlyAndSaveImage from '../../../../../../images/billing/simple_pricing_go_yearly_and_save.svg';
import { checkCreditCardData } from '../../../../../../lib/billing/checkCreditCardData';
import { checkRequiredPaymentFields } from '../../../../../../lib/billing/checkRequiredPaymentFields';
import { getNewPlanTotalAmount } from '../../../../../../lib/billing/getNewPlanTotalAmount';
import { parseBillingDetailsResponse } from '../../../../../../lib/billing/parseBillingDetailsResponse';
import { setQueryParamsFromResponse } from '../../../../../../lib/billing/setQueryParamsFromResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { FetchUpcomingSimplePricingInvoiceDataResponse } from '../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import {
  simplePricingBillingPlanTypes,
  simplePricingV3BillingPlanTypes,
} from '../../../../../../types/BillingPlanName';
import { FullScreenOverlayStorybookProps } from '../../../../../../types/FullScreenOverlay';
import { SimplePricingFullScreenOverlayRoute } from '../../../../../../types/SimplePricing';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import DiscountsSection from '../../../../shared/DiscountsSection/DiscountsSection';
import {
  MainSectionContent,
  MainSectionWrapper,
  OverlayContentHeaderTitle,
  OverlayContentWrapper,
} from '../../../../shared/FullScreenOverlay/styles';
import { initState, reducer } from '../../../../shared/UpdatePaymentMethodForm/reducer';
import PaymentMethodSection from '../../../per_user_pricing/FullScreenOverlayContent/ConfigurePlanOverlayContent/PaymentMethodSection/PaymentMethodSection';
import CardDetailsSection from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSection';
import CardDetailsSkeleton from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSectionSkeleton/CardDetailsSectionSkeleton';
import NewPlanCard from '../../../shared/BillingFullScreenOverlay/NewPlanCard/NewPlanCard';
import NewPlanCardSkeleton from '../../../shared/BillingFullScreenOverlay/NewPlanCard/NewPlanCardSkeleton/NewPlanCardSkeleton';
import {
  ButtonsGroup,
  PaymentSummaryFooterWrapper,
  PaymentSummaryWrapper,
} from '../../../shared/BillingFullScreenOverlay/styles';
import SummarySkeleton from '../../../shared/BillingFullScreenOverlay/Summary/SummarySkeleton/SummarySkeleton';
import BillingIntervalToggle from '../../../shared/BillingIntervalToggler/BillingIntervalToggle';
import { BillingIntervalWrapper, SavingsImg } from './styles';
import ConsiderThisPlan from './Summary/ConsiderThisPlan/ConsiderThisPlan';
import Summary from './Summary/Summary';

export type UpgradePlanOverlayContentProps = {
  route: SimplePricingFullScreenOverlayRoute;
  currentDate?: Date;
} & FullScreenOverlayStorybookProps<FetchUpcomingSimplePricingInvoiceDataResponse>;

const t = initTranslations('simple_pricing.pick_a_plan_overlay_content');

const PickAPlanOverlayContent = ({
  route,
  currentDate = new Date(),
  isStorybookEnvironment,
  mockData,
}: UpgradePlanOverlayContentProps) => {
  const fullScreenOverlayState = useSimplePricingFullScreenOverlay({
    route,
    isStorybookEnvironment,
    mockData,
  });
  const {
    createSubscription,
    isLoadingCreateSubscription,
    isSuccessCreateSubscription: isRedirectInProgress,
  } = useCreateSubscription();
  const closeFullScreenOverlay = useCloseFullScreenOverlay();
  const {
    upcomingInvoiceData,
    isLoadingOrFetching,
    isAnnualInterval,
    showDiscounts,
    setSelectedBillingInterval,
    selectedBillingInterval,
    selectedPlanName,
    setSelectedPlanName,
    setAppliedPromoCode,
    appliedPromoCode,
  } = fullScreenOverlayState;
  const {
    splitFeatures: { simplePricingEnabled, simplePricingV3Enabled },
  } = useCurrentAccount();
  const billingPlanList = simplePricingV3Enabled
    ? simplePricingV3BillingPlanTypes
    : simplePricingBillingPlanTypes;
  const isNoTrialFlow = useCheckNoTrialFlow();
  const [showPaymentSection, setShowPaymentSection] = useState(false);
  const [isLoadingSubmitButton, setIsLoadingSubmitButton] = useState(false);
  const [formState, dispatch] = useReducer(reducer, initState);
  const { data: formData, isFormError, isStripeError, paymentDetailsChanged } = formState;
  const { formErrors, validateFields, validateField, clearFieldError, resetErrorState } =
    useValidateUpdatePaymentMethodForm(dispatch);
  const isAvailableOverlayData = !isLoadingOrFetching && !!upcomingInvoiceData;
  const billingDetails = upcomingInvoiceData?.billingDetails;
  const isPaymentError =
    showPaymentSection && (isFormError || isStripeError || !paymentDetailsChanged);
  const cardDetails = billingDetails?.cardDetails;
  const hasCardDetails = checkCreditCardData(cardDetails);
  const validRequiredFields = checkRequiredPaymentFields({
    formData,
    validateFields,
    withStateUpdate: false,
  });
  const showCardDetailsSection = hasCardDetails && validRequiredFields;
  const isPurchaseStep = showPaymentSection || showCardDetailsSection;
  const submitButtonTranslationKey = isPurchaseStep ? 'purchase' : 'go_to_payment';
  const isDisabledSubmitButton = isLoadingSubmitButton || isPaymentError;
  const isAnnualOnlyPlan =
    !!upcomingInvoiceData && ['unlimited', 'custom'].includes(selectedPlanName);

  const handleSubmit = () => {
    if (isPurchaseStep) {
      setIsLoadingSubmitButton(true);

      createSubscription({
        overlayParams: {
          interval: selectedBillingInterval,
          plan: selectedPlanName,
        },
        billingDetails: formData,
        coupon: appliedPromoCode,
        totalAmount: getNewPlanTotalAmount(upcomingInvoiceData?.newPlan),
        validRequiredFields: checkRequiredPaymentFields({ formData, validateFields }),
      }).then(() => setIsLoadingSubmitButton(false));
    } else {
      setShowPaymentSection(true);
    }
  };

  useEffect(() => {
    const result = billingDetails && parseBillingDetailsResponse(billingDetails);
    result && dispatch({ type: 'setFormData', payload: result });
  }, [billingDetails]);

  useEffect(() => {
    setQueryParamsFromResponse({ selectedBillingInterval, selectedPlanName });
  }, [selectedBillingInterval, selectedPlanName]);

  useEffect(() => {
    setIsLoadingSubmitButton(
      isLoadingCreateSubscription || isLoadingOrFetching || isRedirectInProgress
    );
  }, [isLoadingCreateSubscription, isLoadingOrFetching, isRedirectInProgress]);

  useEffect(() => {
    if (isAnnualOnlyPlan) {
      // unlimited or custom plan has only annual interval
      setSelectedBillingInterval('year');
    }
  }, [isAnnualOnlyPlan, setSelectedBillingInterval]);

  return (
    <OverlayContentWrapper>
      {showPaymentSection ? (
        <PaymentMethodSection
          billingDetailsData={billingDetails}
          clearFieldError={clearFieldError}
          dispatch={dispatch}
          formData={formData}
          isFormProcessing={isLoadingCreateSubscription}
          paymentMethodFormErrors={formErrors}
          resetErrorState={resetErrorState}
          setShowPaymentSection={setShowPaymentSection}
          simplePricingEnabled={simplePricingEnabled}
          validateField={validateField}
          validateFields={validateFields}
        />
      ) : (
        <MainSectionWrapper>
          <MainSectionContent>
            <OverlayContentHeaderTitle>{t('header.title')}</OverlayContentHeaderTitle>
            <BillingIntervalWrapper>
              <SavingsImg
                alt={t('billing_interval_toggle.alt_annual_savings')}
                src={GoYearlyAndSaveImage}
              />
              <BillingIntervalToggle
                handleChange={setSelectedBillingInterval}
                isAnnualChecked={isAnnualInterval}
                isAnnualValueFirst={false}
                isDisabled={isAnnualOnlyPlan}
                isLoading={isLoadingOrFetching}
              />
            </BillingIntervalWrapper>
            {isAvailableOverlayData ? (
              <NewPlanCard
                billingInterval={upcomingInvoiceData.newPlan.billingInterval}
                billingPlanList={billingPlanList}
                coupon={upcomingInvoiceData.coupon}
                employeesSize={upcomingInvoiceData.newPlan.employeesSize}
                id='pick-a-plan-card'
                isAnnualOnlyPlan={isAnnualOnlyPlan}
                newPlanName={upcomingInvoiceData.newPlan.name}
                selectedPlanName={selectedPlanName}
                setSelectedPlanName={setSelectedPlanName}
                totalPrice={upcomingInvoiceData.newPlan.totalPrice}
              />
            ) : (
              <NewPlanCardSkeleton />
            )}
          </MainSectionContent>
        </MainSectionWrapper>
      )}
      <PaymentSummaryWrapper>
        {isAvailableOverlayData ? (
          <Summary
            addonItems={upcomingInvoiceData.addonItems}
            appliedPromoCode={appliedPromoCode}
            coupon={upcomingInvoiceData.coupon}
            currentDate={currentDate}
            currentPlan={upcomingInvoiceData.currentPlan}
            isAnnualOnlyPlan={isAnnualOnlyPlan}
            newPlanData={upcomingInvoiceData.newPlan}
            setAppliedPromoCode={setAppliedPromoCode}
          />
        ) : (
          <SummarySkeleton />
        )}
        {isAnnualOnlyPlan ? (
          <ConsiderThisPlan closeFullScreenOverlay={closeFullScreenOverlay} />
        ) : (
          <PaymentSummaryFooterWrapper>
            {showDiscounts && isAvailableOverlayData && (
              <DiscountsSection
                coupon={upcomingInvoiceData.coupon}
                isManagePlanOverlayContent
                productName={upcomingInvoiceData.newPlan.name}
                showYearlyBillingPerk={isAnnualInterval}
              />
            )}
            {isAvailableOverlayData ? (
              showCardDetailsSection && (
                <CardDetailsSection
                  cardDetails={upcomingInvoiceData.billingDetails.cardDetails}
                  isMaxSeatsCount={false}
                />
              )
            ) : (
              <CardDetailsSkeleton />
            )}
            <ButtonsGroup>
              {!isNoTrialFlow && (
                <DefaultButton
                  buttonType='secondary'
                  id='pick-a-plan-cancel-button'
                  onClick={closeFullScreenOverlay}
                  text={t('cta.cancel')}
                />
              )}
              <DefaultButton
                buttonType='primary'
                disabled={isDisabledSubmitButton}
                id='pick-a-plan-primary-button'
                loading={isLoadingSubmitButton}
                onClick={handleSubmit}
                text={t(`cta.${submitButtonTranslationKey}`)}
              />
            </ButtonsGroup>
          </PaymentSummaryFooterWrapper>
        )}
      </PaymentSummaryWrapper>
    </OverlayContentWrapper>
  );
};

export default PickAPlanOverlayContent;
