import { VerifyContentStatus } from '../../redux/services/resourceApis/reports/types';
import { CurriculumOwner } from '../../types';
import useCurrentAccount from '../useCurrentAccount';

type VerifyStatusBadgeDataArgs = {
  owner: CurriculumOwner | null;
  verifyContentStatus: VerifyContentStatus | undefined;
};

export function useShowVerifyStatusBadge({
  verifyContentStatus,
  owner,
}: VerifyStatusBadgeDataArgs) {
  const { paywalledFeatures } = useCurrentAccount();
  const advancedSettingsUnlocked = !paywalledFeatures.includes('curriculum_advanced_settings');

  return (
    advancedSettingsUnlocked &&
    owner &&
    verifyContentStatus &&
    verifyContentStatus !== 'not_available'
  );
}
