import React from 'react';
import styled from 'styled-components';

import { CourseTitleEmoji, SharedCourseTitle, SharedCourseTitleWrapper } from '../../shared/styles';

const TitleWrapper = styled.div`
  ${SharedCourseTitleWrapper};
`;

export interface Props {
  title: string;
  emoji: string;
}
const CourseTitle = ({ emoji, title }: Props) => {
  return (
    <TitleWrapper>
      <CourseTitleEmoji>{emoji}</CourseTitleEmoji>
      <SharedCourseTitle>{title}</SharedCourseTitle>
    </TitleWrapper>
  );
};

export default CourseTitle;
