import React from 'react';
import styled from 'styled-components';

import { RegisteredId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { forceCapitalization } from '../../../helpers/cssTranslation';
import { mediaBreakpointPxXl, mediaBreakpointXl } from '../../../styled/Breakpoint';
import { fontSm5 } from '../../../styled/TypeSystem';
import DropdownWithPoppableMenu from '../DropdownWithPoppableMenu';
import { ActionDropdownMenuOptionData } from './ActionDropdownMenuOption';
import { OptionType } from './ActionDropdownMenuOption/ActionDropdownMenuOption';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};

  @media (min-width: ${mediaBreakpointPxXl}) {
    margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  ${TruncatedText({})};
`;

const Title = styled.p<{ bold: boolean }>`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants }, bold }) =>
    bold ? constants.fontMedium : constants.fontRegular};
  ${fontSm5};
  ${TruncatedText({})};
  ${forceCapitalization};
`;

export interface ActionDropdownProps {
  id: RegisteredId;
  modeText?: boolean;
  titleBold?: boolean;
  name: 'view-mode' | 'status';
  options: React.ReactElement[];
  selectedOptionData: ActionDropdownMenuOptionData;
  setSelectedOption: (indexOfSelectedOption: number) => void;
  style?:
    | 'default'
    | 'toolbar'
    | 'supershare'
    | 'survey'
    | 'generalaccess'
    | 'sector'
    | 'delegationBoard'
    | 'subjectMode';
}

const t = initTranslations('action_dropdown');

const ActionDropdown = ({
  id,
  modeText = false,
  name,
  options,
  selectedOptionData,
  setSelectedOption,
  style,
  titleBold = true,
}: ActionDropdownProps) => {
  const isOptionTypeEdit = selectedOptionData.optionType === OptionType.Edit;
  const isOptionTypeView = selectedOptionData.optionType === OptionType.View;

  const title = isOptionTypeEdit
    ? t(modeText ? 'edit_mode' : 'editing')
    : isOptionTypeView
    ? t(modeText ? 'view_mode' : 'viewing')
    : selectedOptionData.title;

  const SelectedOption = (
    <TextWrapper>
      <IconWrapper>{selectedOptionData.icon}</IconWrapper>
      <Title bold={titleBold}>{title}</Title>
    </TextWrapper>
  );

  return (
    <DropdownWithPoppableMenu
      id={id}
      menuId={`action-${name}-dropdown-menu`}
      menuPlacement='bottom-start'
      options={options}
      selectedOption={SelectedOption}
      setSelectedOption={setSelectedOption}
      style={style}
      tooltipBreakpoint={mediaBreakpointXl}
      tooltipId={`action-${name}-dropdown-tooltip`}
      tooltipPlace='bottom'
      tooltipText={selectedOptionData.title}
    />
  );
};

export default ActionDropdown;
