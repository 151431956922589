import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import Icon from '../../../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../../../styled/TypeSystem';

const TimeWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    align-items: center;
    gap: ${({ theme: { constants } }) => constants.spacerSm3};
    background-color: ${vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    color: ${vars.textDefault};
    border-radius: ${constants.borderRadiusEndcap};
    padding: 7px ${({ theme: { constants } }) => constants.spacerSm3};
    flex-shrink: 0;
    cursor: default;

    ${fontSm5};
  `
);

const t = initTranslations('curriculums.edit_bottom_section');

type Props = {
  id: number;
  minutesToReadFormatted: string;
};

const TimeEstimatePill = ({ id, minutesToReadFormatted }: Props) => {
  return (
    <TimeWrapper id={`minutes-to-read-curriculum-${id}`}>
      <Icon name='clock' size='sm' />
      {t('read', { time: minutesToReadFormatted })}
    </TimeWrapper>
  );
};

export default TimeEstimatePill;
