import React from 'react';

import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import Toggle from '../../../../design_system/input/controls/Toggle';
import { TerminologyPluralsSettingWrapper, TerminologySettingLabel } from '../styles';
import { TerminologyPluralsSettingToggleProps } from './types';

const t = initTranslations('account_settings.advanced_settings.custom_terminology_form');

const TerminologyPluralsSettingToggle = ({
  checked,
  id,
  handleToggle,
  name,
  className,
}: TerminologyPluralsSettingToggleProps) => {
  const { isMobile } = useWindowResize();
  const displaySettingLabel = !isMobile && id === 'enable-subject-term-pluralize';

  return (
    <TerminologyPluralsSettingWrapper>
      {displaySettingLabel && (
        <TerminologySettingLabel>{t('plural_adjustments')}</TerminologySettingLabel>
      )}
      <Tooltip
        id={`${id}-tooltip`}
        place='top'
        text={t(checked ? 'disable_plurals' : 'enable_plurals')}
      />
      <div data-for={`${id}-tooltip`} data-tip>
        <Toggle
          checked={checked}
          className={className}
          handleToggle={handleToggle}
          id={id}
          name={name}
        />
      </div>
    </TerminologyPluralsSettingWrapper>
  );
};

export default TerminologyPluralsSettingToggle;
