import React, { useReducer } from 'react';

import { useCheckManageSignatureAbility } from '../../../../../hooks/curriculum/useCheckManageSignatureAbility';
import useCopyUrlLink from '../../../../../hooks/useCopyUrlLink';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useESignatureToggleMutation } from '../../../../../redux/services/resourceApis/courses/topicsApi';
import {
  useDeleteCurriculumElementMutation,
  useDuplicateCurriculumElementMutation,
} from '../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { isCourse } from '../../../../../types/Course';
import { CurriculumElement } from '../../../../../types/CurriculumElement';
import ThreeDotMenu from '../../../../design_system/Triage/menus/ThreeDotMenu';
import {
  ThreeDotMenuOptionProps,
  ThreeDotMenuProps,
} from '../../../../design_system/Triage/menus/ThreeDotMenu/types';
import MoveCurriculumElementModalWrapper from '../../../curriculums/modals/MoveCurriculumElementModal/MoveCurriculumElementModal';
import RestrictMoveCurriculumModal from '../../../curriculums/modals/RestrictMoveCurriculumModal/RestrictMoveCurriculumModal';
import DeleteCurriculumElementModal from '../../../curriculums/shared/Modals/DeleteCurriculumElementModal';
import DuplicateCurriculumElementModal from '../../../curriculums/shared/Modals/DuplicateCurriculumElementModal';
import ExportCourseModal from '../../../curriculums/shared/Modals/ExportCourseModal/ExportCourseModal';

type ModalState = {
  moveModal: boolean;
  duplicateModal: boolean;
  pdfModal: boolean;
  printModal: boolean;
  deleteModal: boolean;
  restrictModal: boolean;
};

type ActionType = 'move' | 'duplicate' | 'pdf' | 'delete' | 'restrict' | 'print';

type Action = {
  type: ActionType;
  show: boolean;
};

const modalReducer = (state: ModalState, action: Action): ModalState => {
  switch (action.type) {
    case 'move': {
      return { ...state, moveModal: action.show };
    }
    case 'duplicate': {
      return { ...state, duplicateModal: action.show };
    }
    case 'pdf': {
      return { ...state, pdfModal: action.show };
    }
    case 'print': {
      return { ...state, printModal: action.show };
    }
    case 'delete': {
      return { ...state, deleteModal: action.show };
    }
    case 'restrict': {
      return { ...state, restrictModal: action.show };
    }
  }
};

interface Props extends Omit<ThreeDotMenuProps, 'menuOptions'> {
  curriculumElement: CurriculumElement;
  curriculumId: number;
  eSignatureDisplayedAndRequired: boolean;
  signaturable?: boolean;
  canBeModified: boolean;
  renameClickHandler: () => void;
}

const t = initTranslations('curriculums.three_dot_menu');

const CurriculumElementThreeDotMenu = ({
  curriculumElement,
  curriculumId,
  eSignatureDisplayedAndRequired,
  signaturable,
  canBeModified,
  renameClickHandler,
  ...propsToPass
}: Props) => {
  const {
    id,
    elementId: elementId,
    elementKind,
    element: { title },
  } = curriculumElement;
  const { showRoute, editRoute, termSingular } = useCurriculumElement()({ elementKind, elementId });
  const ability = useCurrentUserAbilities();
  const ableToToggleSignature =
    useCheckManageSignatureAbility(signaturable) && isCourse(curriculumElement);

  const {
    hasESignatureFeature,
    splitFeatures: { curriculumPrintViewEnabled },
  } = useCurrentAccount();
  const movementRestricted = hasESignatureFeature ? false : eSignatureDisplayedAndRequired;

  const initialModalState = {
    moveModal: false,
    duplicateModal: false,
    pdfModal: false,
    printModal: false,
    deleteModal: false,
    restrictModal: false,
  };
  const [modalState, dispatch] = useReducer(modalReducer, initialModalState);
  const { copyUrlLink } = useCopyUrlLink();
  const [duplicateCurriculumElement, duplicateResult] = useDuplicateCurriculumElementMutation();
  const [deleteCurriculumElement, deleteResult] = useDeleteCurriculumElementMutation();
  const [toggleESignature, signatureResult] = useESignatureToggleMutation();

  const { moveModal, duplicateModal, pdfModal, printModal, deleteModal, restrictModal } =
    modalState;

  const toggleSignature = () => {
    toggleESignature({
      id,
      signatureRequired: !eSignatureDisplayedAndRequired,
    });
  };

  useDisplayFlashOnResponse({
    result: signatureResult,
    successMessage: eSignatureDisplayedAndRequired
      ? t('e_sig_requirement_removed')
      : t('e_signature_required'),
    errorMessage: messageFromError(signatureResult.error)?.join(', '),
    successFunction: () => signatureResult.reset(),
  });

  const copy = () => {
    showRoute && copyUrlLink({ path: showRoute.href, flashText: t('copy_link_flash') });
  };

  const immutableCourse = !canBeModified && isCourse(curriculumElement);

  const options: ThreeDotMenuOptionProps[] = [
    {
      title: t('edit'),
      onClick: () => editRoute.push(),
      iconName: 'edit',
      iconWeight: 'regular',
      id: 'td-curriculum-element-edit',
      visible: true,
    },
    {
      title: t('rename'),
      onClick: () => {
        renameClickHandler();
      },
      iconName: 'pen',
      iconWeight: 'regular',
      id: 'td-curriculum-element-rename',
      visible: true,
      isDisabled: immutableCourse,
      tooltipText: immutableCourse ? t('disabled_by_e_signature') : undefined,
    },
    {
      title: t('duplicate'),
      onClick: () => {
        dispatch({ type: 'duplicate', show: true });
      },
      iconName: 'copy',
      iconWeight: 'regular',
      id: 'td-curriculum-element-duplicate',
      visible: true,
    },
    {
      title: t('copy_link'),
      onClick: copy,
      iconName: 'link',
      iconWeight: 'regular',
      id: 'td-curriculum-element-copy-link',
      visible: true,
    },
    {
      title: t('print'),
      onClick: () => {
        dispatch({ type: 'print', show: true });
      },
      iconName: 'file-export',
      iconWeight: 'regular',
      id: 'td-curriculum-element-show-print-pdf',
      visible:
        curriculumPrintViewEnabled &&
        ability.can('create', `ExportCurriculum-${curriculumId}`) &&
        isCourse(curriculumElement),
    },
    {
      title: t('email_pdf'),
      onClick: () => {
        dispatch({ type: 'pdf', show: true });
      },
      iconName: 'envelope',
      iconWeight: 'regular',
      id: 'td-curriculum-element-export-pdf',
      visible:
        ability.can('create', `ExportCurriculum-${curriculumId}`) && isCourse(curriculumElement),
    },
    {
      title: t('move_to'),
      onClick: () => {
        dispatch({ type: 'move', show: true });
      },
      iconName: 'square-arrow-right',
      iconWeight: 'regular',
      id: 'td-curriculum-element-move',
      visible: useIsAdmin(),
    },
    {
      title: eSignatureDisplayedAndRequired ? t('remove_e_signature') : t('require_e_signature'),
      onClick: toggleSignature,
      iconName: 'signature',
      iconWeight: 'regular',
      id: 'td-curriculum-element-signature',
      visible: ableToToggleSignature,
    },
    {
      title: 'Delete',
      onClick: () => {
        dispatch({ type: 'delete', show: true });
      },
      iconName: 'trash-alt',
      iconWeight: 'regular',
      id: 'td-curriculum-element-delete',
      isDisabled: immutableCourse,
      tooltipText: immutableCourse ? t('disabled_by_e_signature') : undefined,
      visible: true,
    },
  ];

  return (
    <>
      <ThreeDotMenu menuOptions={options} {...propsToPass} />
      {moveModal && (
        <MoveCurriculumElementModalWrapper
          closeModal={() => dispatch({ type: 'move', show: false })}
          curriculumElementId={id}
          curriculumId={curriculumId}
          elementKind={elementKind}
          elementName={title}
          movementRestricted={movementRestricted}
          showRestrictModal={() => dispatch({ type: 'restrict', show: true })}
        />
      )}
      {restrictModal && (
        <RestrictMoveCurriculumModal
          elementType='course'
          onCloseRequest={() => dispatch({ type: 'restrict', show: false })}
        />
      )}
      {duplicateModal && (
        <DuplicateCurriculumElementModal
          closeRequest={() => dispatch({ type: 'duplicate', show: false })}
          duplicateElement={(values) => {
            duplicateCurriculumElement({
              id,
              title: values.title,
            });
          }}
          elementType={termSingular}
          id={id}
          result={duplicateResult}
          title={title}
        />
      )}
      {(pdfModal || printModal) && (
        <ExportCourseModal
          action={pdfModal ? 'pdf' : 'print'}
          closeRequest={() => {
            dispatch({ type: 'pdf', show: false });
            dispatch({ type: 'print', show: false });
          }}
          courseId={elementId}
          curriculumId={curriculumId}
        />
      )}
      {deleteModal && (
        <DeleteCurriculumElementModal
          closeRequest={() => dispatch({ type: 'delete', show: false })}
          deleteElement={() => deleteCurriculumElement({ id })}
          elementTitle={title}
          elementType={termSingular}
          result={deleteResult}
        />
      )}
    </>
  );
};

export default CurriculumElementThreeDotMenu;
