import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import P from '../../../design_system/text/P';
import { fontMd1, fontSm4 } from '../../../styled/TypeSystem';

export const IncludesWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const IncludesTitle = styled(P)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm4};
`;

export const IncludesFeatureWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme: { constants } }) => constants.spacerSm1} 0;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontMd1};
`;

export const StyledFeatureIcon = styled(Icon)<{ planName: string }>`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ planName, theme: { vars } }) =>
    planName === 'growth'
      ? vars.trainualBrandGreenMedium
      : planName === 'small'
      ? vars.trainualBrandPurpleSubdued
      : planName === 'medium'
      ? vars.trainualBrandBlueMedium
      : vars.trainualBrandYellowMedium};
`;
