import React from 'react';

import SlideoutPanel from '../../shared/slideout';
import { SurveySlideoutProps } from './types';

const SurveySlideout = ({ children, slideoutType, title }: SurveySlideoutProps) => {
  return (
    <SlideoutPanel
      customCloseButtonIcon='chevrons-right'
      displayInOverlay
      headerText={title}
      id={slideoutType}
      slideoutType={slideoutType}
      slideoutWidth='20rem'
      titleAlign='flex-start'
    >
      {children}
    </SlideoutPanel>
  );
};

export default SurveySlideout;
