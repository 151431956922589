import React from 'react';
import { Coupon } from 'types/Coupon';

import { SimplePricingBillingPlanName } from '../../../../../types/BillingPlanName';
import DiscountsSection from '../../../shared/DiscountsSection/DiscountsSection';
import {
  CheckoutDiscountsSectionWrapper,
  CheckoutPriceArrow,
  CheckoutPriceArrowWrapper,
} from './styles';

type CheckoutDiscountsSectionProps = {
  coupon: Coupon;
  priceArrowSrc: string;
  planName: SimplePricingBillingPlanName;
  withDiscountSection: boolean;
};

const CheckoutDiscountsSection = ({
  coupon,
  priceArrowSrc,
  planName,
  withDiscountSection,
}: CheckoutDiscountsSectionProps) => {
  return (
    <>
      {coupon ? (
        <CheckoutDiscountsSectionWrapper>
          <DiscountsSection
            coupon={coupon}
            isManagePlanOverlayContent
            productName={planName}
            showYearlyBillingPerk={false}
          />
          <CheckoutPriceArrow src={priceArrowSrc} withDiscountSection={withDiscountSection} />
        </CheckoutDiscountsSectionWrapper>
      ) : (
        <CheckoutPriceArrowWrapper>
          <CheckoutPriceArrow src={priceArrowSrc} withDiscountSection={withDiscountSection} />
        </CheckoutPriceArrowWrapper>
      )}
    </>
  );
};

export default CheckoutDiscountsSection;
