import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { NewPerUserPlan } from '../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import {
  NewSimplePricingPlan,
  NewSimplePricingPlanOmitEmployeesSize,
} from '../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { NextPaymentDetails, NextPaymentTitle, NextPaymentWrapper } from './styles';

const t = initTranslations('per_user_pricing.manage_plan_overlay_content.next_payment');

type NextPaymentProps = {
  newPlanData: NewPerUserPlan | NewSimplePricingPlan | NewSimplePricingPlanOmitEmployeesSize;
  withDetails?: boolean;
};

const NextPayment = ({ newPlanData, withDetails = true }: NextPaymentProps) => {
  const { credit, dueToday, nextPaymentDate } = newPlanData;
  const getNextPaymentDetails = () => {
    if (dueToday > 0) {
      if (credit < 0) {
        return t('charge_partially', { due_today: dueToday, credit: Math.abs(credit) });
      } else {
        return t('charge_full_amount', { due_today: dueToday });
      }
    } else {
      return t('zero_charge', { due_today: dueToday });
    }
  };

  return (
    <NextPaymentWrapper id='next-payment-section'>
      <NextPaymentTitle text={t('title', { date: nextPaymentDate })} />
      {withDetails && <NextPaymentDetails text={getNextPaymentDetails()} />}
    </NextPaymentWrapper>
  );
};

export default NextPayment;
