import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import {
  SurveyAttemptCreateParams,
  SurveyAttemptCreateResponse,
  SurveyAttemptResultsParams,
  SurveyAttemptResultsResponse,
  SurveyAttemptUpdateParams,
} from './types';

const surveyAttemptsApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    createSurveyAttempt: builder.mutation<SurveyAttemptCreateResponse, SurveyAttemptCreateParams>({
      query: ({ surveyId }) => ({ url: `ajax/surveys/${surveyId}/attempts`, method: 'POST' }),
      invalidatesTags: (_result, _errors, { surveyId }) => [
        { type: 'Curriculum', id: 'LIST' },
        { type: 'Survey', surveyId },
      ],
      transformResponse: (returnValue: SurveyAttemptCreateResponse) => {
        return toCamelCase<SurveyAttemptCreateResponse>(returnValue);
      },
    }),
    updateSurveyAttempt: builder.mutation<undefined, SurveyAttemptUpdateParams>({
      query: (params) => ({
        url: `ajax/surveys/${params.surveyId}/attempts/${params.id}`,
        method: 'PUT',
        body: toSnakeCase(params),
      }),
      invalidatesTags: (_result, _error, { surveyId, id }) => [
        { type: 'Curriculum', id: 'LIST' },
        { type: 'Survey', id: surveyId },
        { type: 'SurveyAttempt', id },
      ],
    }),
    getSurveyAttemptResults: builder.query<
      SurveyAttemptResultsResponse,
      SurveyAttemptResultsParams
    >({
      query: ({ surveyId, id }) => ({
        url: `ajax/surveys/${surveyId}/attempts/${id}`,
      }),
      providesTags: (_result, _errors, { id }) => [{ type: 'SurveyAttempt', id }],
      transformResponse: (returnValue: SurveyAttemptResultsResponse) => {
        return toCamelCase<SurveyAttemptResultsResponse>(returnValue);
      },
    }),
  }),
});

export const {
  useCreateSurveyAttemptMutation,
  useUpdateSurveyAttemptMutation,
  useGetSurveyAttemptResultsQuery,
} = surveyAttemptsApi;
