import { useAccountTerminology } from '../../components/AccountTerminologyProvider';
import { CardType } from '../../components/application/searches/SearchResultsPage/search_result_cards/SearchResultsCard/types';
import initTranslations from '../../lib/initTranslations';

type getSearchResultCardContentArgs = [string, CardType];

const t = initTranslations('search_results_page.result_card.content');

export const useGetSearchResultCardContent = (...args: getSearchResultCardContentArgs) => {
  const [content, cardType] = args;
  const {
    curriculum: { singular: curriculum },
  } = useAccountTerminology();

  function getContentText() {
    switch (cardType) {
      case 'Curriculum':
        return t('description', { curriculum }) + content;
      case 'User':
        return content ? t('about') + content : '';
      case 'Survey::Question':
        return t('test') + content;
      case 'Course':
        return '';
      default:
        return content;
    }
  }

  return getContentText();
};
