import React, { useMemo } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import { ElementType } from '../../../../../types/CurriculumElement';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import IconButton from '../../../../design_system/buttons/IconButton';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import { routes } from '../../../publicApplication/applicationRouter';
import DetailedTitle, { ClickBehavior } from '../../../shared/DetailedTitle/DetailedTitle';
import {
  AssignedCheckableSubject,
  AssignedCheckableTopic,
  ManagingAssignedSubjects,
  useAssignedSubjects,
} from './AssignedSubjectsContext';

const TitleWrapper = styled.div<{ userProfilePage: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

// DS Override: Need smaller padding to maintain consistent height of row when chevron icon is not shown
const StyledIconButton = styled(IconButton)`
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} 0`};
`;

const OrientationWrapper = styled.div<{ chevronOrientation: boolean }>`
  display: flex;
  transform: rotate(${({ chevronOrientation }) => (chevronOrientation ? '90deg' : 0)});
  transition: transform 0.25s;
`;

const subjectElementType = (
  topic: AssignedCheckableTopic | undefined,
  curriculumSingular: string,
  topicSingular: string
) => {
  const elementTypeMapping: { [key in ElementType]: string } = {
    Course: topicSingular.toLowerCase(),
    'Survey::Survey': t('tooltips.test'),
    Flowchart: t('tooltips.flowchart'),
  };

  if (!topic) {
    return curriculumSingular.toLowerCase();
  }

  return elementTypeMapping[topic.element_type as ElementType] || elementTypeMapping.Flowchart;
};

const determineTooltipText = (
  subject: AssignedCheckableSubject,
  topic: AssignedCheckableTopic | undefined,
  item: AssignedCheckableSubject | AssignedCheckableTopic,
  managing: Omit<ManagingAssignedSubjects, 'assign-subject, none'>,
  topicSingular: string,
  curriculumSingular: string
) => {
  if (item.disabled) {
    switch (managing) {
      case 'unassign-subject':
        // Disabled subject due to it being assigned through group
        return t('tooltips.assigned_through_groups_tooltip', {
          roles:
            subject.assigned_through_groups.length > 1
              ? t('tooltips.group_plural')
              : t('tooltips.group_singular'),
          roles_list: subject.assigned_through_groups.map((name) => name).join(', '),
        });
      case 'clear-completion':
      case 'mark-complete':
        if (subject.reference) {
          // Disabled subject due to being unable to complete Reference subjects
          return t('tooltips.reference_tooltip');
        } else if (topic && topic.status !== 'finished') {
          // Disabled topic due to status not being finished
          return t('tooltips.status_not_finished', {
            topic_or_test: subjectElementType(topic, curriculumSingular, topicSingular),
          });
        } else {
          // Disabled subject/topic due to having no steps or survey questions to mark complete
          return t('tooltips.nothing_to_complete_in_this_subject', {
            subject_or_topic_or_test: subjectElementType(topic, curriculumSingular, topicSingular),
          });
        }
      default:
        return '';
    }
  } else {
    // Empty string for no tooltip display
    return '';
  }
};

type IncomingType =
  | {
      topic?: never;
      isDisplayingTopics: boolean;
      setIsDisplayingTopics: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | {
      topic: AssignedCheckableTopic;
      isDisplayingTopics?: never;
      setIsDisplayingTopics?: never;
    };

type Props = {
  subject: AssignedCheckableSubject;
} & IncomingType;

const t = initTranslations('profile');

const Title = ({ isDisplayingTopics = false, setIsDisplayingTopics, subject, topic }: Props) => {
  const { selectSubject, selectTopic, managing, isManaging, userProfilePage } =
    useAssignedSubjects();
  const {
    topic: { singular: topicSingular, plural: topicPlural },
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();
  const { onHoldPlan, slug, status: accountStatus } = useCurrentAccount();
  const isTrialEndedOrOnHold = accountStatus === 'trial_ended' || onHoldPlan;
  const item = topic ? topic : subject;

  const elementAttributes = useCurriculumElement();

  const clickBehavior: ClickBehavior = useMemo(() => {
    if (isTrialEndedOrOnHold) {
      return {};
    }

    if (!topic) {
      return { route: routes.curriculumShow({ slug, id: subject.id }).href };
    }

    const elementType = topic.element_type;
    const elementId = topic.id;
    const attributes = elementAttributes({ elementType, elementId });

    if (attributes && attributes.showRoute) {
      return {
        route: attributes.showRoute.href,
      };
    }

    return {};
  }, [isTrialEndedOrOnHold, topic, slug, subject.id, elementAttributes]);
  return (
    <TitleWrapper userProfilePage={userProfilePage}>
      {(topic && (managing === 'clear-completion' || managing === 'mark-complete')) ||
      (!topic && isManaging) ? (
        <Checkbox
          bottomMargin='0'
          checked={item.checkboxChecked}
          disabled={item.disabled}
          id={item.checkboxId}
          label={item.title}
          name={item.checkboxName}
          onCheck={() => {
            topic
              ? selectTopic(subject, topic, !item.checkboxChecked)
              : selectSubject(subject, !item.checkboxChecked);
          }}
          tooltipHoverCheckbox
          tooltipHoverCheckboxPlacement='right'
          tooltipId={`disabled-from-group-${item.checkboxId}`}
          tooltipText={determineTooltipText(
            subject,
            topic,
            item,
            managing,
            topicSingular,
            curriculumSingular
          )}
          truncate
        />
      ) : (
        <DetailedTitle
          {...clickBehavior}
          emoji={item.emoji}
          fontWeight='regular'
          title={item.title}
          truncate='oneLine'
        />
      )}
      {!topic && !!subject.assigned_subject_elements.length && (
        <OrientationWrapper chevronOrientation={isDisplayingTopics}>
          <StyledIconButton
            ariaLabel={
              isDisplayingTopics
                ? t('topic_dropdown.collapse', { topics: topicPlural })
                : t('topic_dropdown.expand', { topics: topicPlural })
            }
            buttonSize='sm'
            id={`subject-${subject.id}-chevron-${isDisplayingTopics ? 'opened' : 'closed'}`}
            name='chevron-right'
            onClick={() => setIsDisplayingTopics && setIsDisplayingTopics(!isDisplayingTopics)}
          />
        </OrientationWrapper>
      )}
    </TitleWrapper>
  );
};

export default Title;
