import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { Option } from '../../../../design_system/core/SelectOption/types';
import Icon from '../../../../design_system/display/icons/Icon';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import { ReadingTimeContext } from './ReadingTimeContext';

const InputWrapper = styled.div<{ showPlaceHolderColor: boolean; leftAlign?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  justify-content: ${({ leftAlign }) => (leftAlign ? 'left' : 'center')};
  width: 100%;
  align-items: center;
  color: ${({ theme: { vars }, showPlaceHolderColor }) =>
    showPlaceHolderColor ? vars.textPlaceholder : vars.textDefault};

  svg {
    color: ${({ theme: { vars }, showPlaceHolderColor }) =>
      showPlaceHolderColor ? vars.textPlaceholder : vars.textDefault};
  }
`;

const InputWidth = styled.div`
  width: 7rem;
`;

// DS Override - to set placeholder color when value is 0
const StyledSingleSelectField = styled(SingleSelectField)<{ showPlaceHolderColor: boolean }>`
  .react-select__single-value {
    color: ${({ theme: { vars }, showPlaceHolderColor }) =>
      showPlaceHolderColor ? vars.textPlaceholder : vars.textDefault};
  }
`;

const t = initTranslations('course.reading_time_flyout');

type Props = {
  customOptionSelected: boolean;
  hours: number;
  minutes: number;
  leftAlign?: boolean;
};

const CustomReadingTimeInputs = ({ customOptionSelected, hours, minutes, leftAlign }: Props) => {
  const minuteOptions: Option[] = [...Array(60)].map((_, index) => ({
    label: t('min_option_label', { minutes: index === 0 ? '00' : index }),
    searchableTerm: index === 0 ? '00' : index.toString(),
    value: index.toString(),
  }));

  const hourOptions: Option[] = Array.from({ length: 60 }, (_, index) => ({
    label: t('hour_option_label', { hours: index === 0 ? '00' : index }),
    searchableTerm: index === 0 ? '00' : index.toString(),
    value: index.toString(),
  }));

  const { updateReadingTime } = useContext(ReadingTimeContext);

  const [selectedHour, setSelectedHour] = useState(hours.toString());
  const [selectedMinute, setSelectedMinute] = useState(minutes.toString());

  const handleHourSelection = (value: string | null) => {
    const newValue = value !== null ? value : '0';
    setSelectedHour(newValue);
    updateReadingTime(parseInt(newValue), parseInt(selectedMinute));
  };

  const handleMinuteSelection = (value: string | null) => {
    const newValue = value !== null ? value : '0';
    setSelectedMinute(newValue);
    updateReadingTime(parseInt(selectedHour), parseInt(newValue));
  };

  return (
    <InputWrapper leftAlign={leftAlign} showPlaceHolderColor={!customOptionSelected}>
      <InputWidth>
        <StyledSingleSelectField
          className='hour-select'
          defaultValue={selectedHour}
          disabled={!customOptionSelected}
          expandMenuToFitOptions
          hideSelectedOptions={false}
          isSearchable
          onNonDefaultSelected={handleHourSelection}
          options={hourOptions}
          showPlaceHolderColor={!customOptionSelected}
          size='sm'
          value={selectedHour}
        />
      </InputWidth>
      <Icon name='colon' weight='solid' />
      <InputWidth>
        <StyledSingleSelectField
          className='minute-select'
          defaultValue={selectedMinute}
          disabled={!customOptionSelected}
          expandMenuToFitOptions
          hideSelectedOptions={false}
          isSearchable
          onNonDefaultSelected={handleMinuteSelection}
          options={minuteOptions}
          showPlaceHolderColor={!customOptionSelected}
          size='sm'
          value={selectedMinute}
        />
      </InputWidth>
    </InputWrapper>
  );
};

export default CustomReadingTimeInputs;
