import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useFlowchartConsumptionData } from '../../../../contexts/FlowchartConsumptionDataContext';
import useCurriculumElement from '../../../../hooks/useCurriculumElement';
import { useCompleteFlowchartMutation } from '../../../../redux/services/resourceApis/flowcharts/flowchartsApi';
import { AdjacentCurriculumElementProps } from '../../../../types/Editor';
import BaseFooter from '../shared/BaseFooter';
import FooterLoader from '../shared/FooterLoader';
import NavigationButtons from './NavigationButtons/NavigationButtons';

const NavigationButtonsWrapper = styled.div`
  width: 22rem;
`;

const FooterContent = () => {
  const { data, isLoading } = useFlowchartConsumptionData();
  const [triggerCompletion, { isLoading: isCompletionLoading, isSuccess: isCompletionSuccess }] =
    useCompleteFlowchartMutation();
  const elementAttributes = useCurriculumElement();

  const navigateAfterCompletion = useCallback(
    (nextElement: AdjacentCurriculumElementProps | null) => {
      if (!nextElement) return;

      const { id: elementId, elementKind } = nextElement;
      const { showRoute } = elementAttributes({ elementId, elementKind });

      showRoute.push();
    },
    [elementAttributes]
  );

  useEffect(() => {
    if (isCompletionSuccess && data) {
      navigateAfterCompletion(data.adjacentCurriculumElements.next);
    }
  }, [isCompletionSuccess, data, navigateAfterCompletion]);

  if (isLoading) return <FooterLoader />;
  if (!data) return null;

  const {
    id,
    status,
    adjacentCurriculumElements,
    completed,
    curriculum: { completionRequired, published },
  } = data;

  return (
    <NavigationButtonsWrapper>
      <NavigationButtons
        completionRequired={completionRequired}
        curriculumPublished={published}
        elementStatusFinished={status === 'finished'}
        isCompleted={completed}
        isLoading={isCompletionLoading}
        markComplete={() => triggerCompletion(id)}
        {...adjacentCurriculumElements}
      />
    </NavigationButtonsWrapper>
  );
};

const Footer = () => {
  const { isLoading } = useFlowchartConsumptionData();

  return (
    <BaseFooter isLoading={isLoading}>
      <FooterContent />
    </BaseFooter>
  );
};

export default Footer;
