import React from 'react';
import styled from 'styled-components';

import { useEditorCourseContext } from '../../../../../contexts/EditorCourseContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { ContentType } from '../../../../../types/ContentType';
import ViewModeDropdown from '../../../shared/ViewModeDropdown/ViewModeDropdown';
import { viewOptions } from '../../../shared/ViewModeDropdown/ViewOptions';
import StatusDropdown from '../StatusDropdown/StatusDropdown';

const ContentDropdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin: ${({ theme: { constants } }) => `0 0 ${constants.spacerMd3} 0`};
`;

const DropdownWrapper = styled.div`
  width: 8.5rem;
`;

const DropdownMenus = () => {
  const { stepId, course, curriculum } = useEditorCourseContext();
  const { slug } = useCurrentAccount();

  const ability = useCurrentUserAbilities();
  const canEditCurriculum = ability.can('update', `EditCurriculum-${curriculum.id}`);

  return (
    <ContentDropdownWrapper id='content-dropdown-wrapper'>
      {canEditCurriculum && (
        <DropdownWrapper id='status-dropdown-wrapper'>
          <StatusDropdown
            curriculumId={curriculum.id}
            element={{ curriculumElementId: course.curriculumElementId, status: course.status }}
          />
        </DropdownWrapper>
      )}

      <DropdownWrapper id='view-mode-wrapper'>
        <ViewModeDropdown
          initialSelectedOptionIndex={0}
          options={viewOptions({ id: stepId, slug, contentType: ContentType.Step })}
          titleBold
        />
      </DropdownWrapper>
    </ContentDropdownWrapper>
  );
};

export default DropdownMenus;
