import React from 'react';
import styled from 'styled-components';

import Avatar from '../../../../design_system/display/avatar';
import CompletionStatusCircle from './CompletionStatusCircle';
import { ProgressAvatar } from './ProgressTimeline';

const StyledAvatar = styled(Avatar)`
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
`;

const ProgressTimelineCircle = ({ name, avatar, completionStatus }: ProgressAvatar) => {
  if (completionStatus === 'in progress') {
    return <StyledAvatar image={avatar} name={name} shape='circle' size='sm' />;
  }

  return <CompletionStatusCircle completed={completionStatus === 'completed'} />;
};

export default ProgressTimelineCircle;
