import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import Loader from '../../../../../design_system/Triage/Loader';
import {
  DownloadWrapper,
  ESignatureRowContainer,
  ESignatureRowContent,
  SignatureLoaderWrapper,
} from './signatureLabelStyles';

const SignedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const t = initTranslations('outline_content');

type SignedLabelProps = {
  signableDocumentId: number | null;
  isFetching: boolean;
  downloadSignedDocument: (params: { signableDocumentId: number }) => void;
};

const SignedLabel = ({
  signableDocumentId,
  isFetching,
  downloadSignedDocument,
}: SignedLabelProps) => {
  return (
    <ESignatureRowContainer id='e-signature-label'>
      <ESignatureRowContent>
        <SignedWrapper>
          <LabelWrapper>
            <Icon name='signature' size='xs' weight='solid' />
            <span>{t('signed')}</span>
          </LabelWrapper>
          <Tooltip id='download-signed-document-tooltip' text={t('download')} />
          {isFetching && (
            <SignatureLoaderWrapper>
              <Loader />
            </SignatureLoaderWrapper>
          )}
          {!isFetching && signableDocumentId && (
            <DownloadWrapper
              data-for='download-signed-document-tooltip'
              data-tip
              id='download-signed-document'
              onClick={() => downloadSignedDocument({ signableDocumentId })}
            >
              <Icon name='arrow-to-bottom' size='2xs' weight='solid' />
            </DownloadWrapper>
          )}
        </SignedWrapper>
      </ESignatureRowContent>
    </ESignatureRowContainer>
  );
};

export default SignedLabel;
