import React, { useState } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { setCurrentResponsibility } from '../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { CollapsibleContent } from '../../../design_system/Triage/layout/CollapsibleSurface';
import { TCurrentBoardColumnResponsibility, TGroupResponsibilyCard } from '../BoardColumn/types';
import ResponsibilityCard from '../ResponsibilityCard/ResponsibilityCard';
import { InfoContainer, InfoText, StyledIcon } from '../ResponsibilityCard/styles';
import { DEFAULT_FREQUENCY } from '../shared/constants/modals';
import {
  GroupKindWrapper,
  GroupResponsibilitiesCountPill,
  GroupResponsibilityCardContainer,
  GroupResponsibilityCardHeader,
  HeaderInfoWrapper,
  ResponsibilitiesWrapper,
  StyledIconButton,
  Title,
} from './styles';

const t = initTranslations('delegation_planner.responsibility_card');

export type GroupResponsibilityCardProps = {
  columnId: number;
  groupResponsibilityCard: TGroupResponsibilyCard;
};

const GroupResponsibilityCard = ({
  columnId,
  groupResponsibilityCard,
}: GroupResponsibilityCardProps) => {
  const dispatch = useAppDispatch();
  const {
    responsibility: { plural: responsibilityPlural, singular: responsibilitySingular },
  } = useAccountTerminology();
  const { groupId, groupName, boardColumnResponsibilities, workingTimeTotalPerWeek, groupKind } =
    groupResponsibilityCard;
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleResponsibilityCardClick = (
    boardColumnResponsibility: TCurrentBoardColumnResponsibility
  ) => {
    dispatch(setCurrentResponsibility(boardColumnResponsibility));
  };
  const handleGroupResponsibilityCardClick = () => setIsCollapsed(!isCollapsed);

  const titleFirstWordLength = groupName.split(' ')[0].length;

  return (
    <GroupResponsibilityCardContainer
      isCollapsed={isCollapsed}
      onClick={handleGroupResponsibilityCardClick}
    >
      <GroupResponsibilityCardHeader>
        <HeaderInfoWrapper>
          <GroupKindWrapper>
            <StyledIcon name='users' size='2xs' weight='regular' />
            <InfoText>{t(`group_kinds.${groupKind}`)}</InfoText>
          </GroupKindWrapper>
          <Title titleFirstWordLength={titleFirstWordLength}>{groupName}</Title>
          <InfoContainer>
            <InfoText>
              <StyledIcon name='clock' size='2xs' weight='regular' />
              {t('working_hours', {
                count: workingTimeTotalPerWeek,
                frequency: DEFAULT_FREQUENCY,
              })}
            </InfoText>
            <Tooltip
              id={`group-responsibilities-count-${columnId}-${groupId}`}
              place='top'
              text={t('responsibilities_count', {
                count: boardColumnResponsibilities.length,
                responsibility: responsibilitySingular.toLowerCase(),
                responsibilities: responsibilityPlural.toLowerCase(),
              })}
            />
            <GroupResponsibilitiesCountPill
              data-for={`group-responsibilities-count-${columnId}-${groupId}`}
              data-tip
            >
              <StyledIcon name='list' size='2xs' weight='regular' />
              {boardColumnResponsibilities.length}
            </GroupResponsibilitiesCountPill>
          </InfoContainer>
        </HeaderInfoWrapper>
        <StyledIconButton
          $collapsed={isCollapsed}
          ariaLabel={isCollapsed ? t('aria_label.show_content') : t('aria_label.hide_content')}
          buttonSize='sm'
          className='group-responsibility-collapsible-chevron'
          name='chevron-down'
          onClick={handleGroupResponsibilityCardClick}
          weight='solid'
        />
      </GroupResponsibilityCardHeader>
      <CollapsibleContent isCollapsed={isCollapsed} isCollapsible>
        <ResponsibilitiesWrapper>
          {boardColumnResponsibilities.map((boardColumnResponsibility) => (
            <ResponsibilityCard
              boardColumnResponsibilityId={boardColumnResponsibility.id}
              cardType='userGroup'
              columnId={columnId}
              contentUrlsCount={boardColumnResponsibility.contentUrls.length}
              key={boardColumnResponsibility.responsibilityId}
              name={boardColumnResponsibility.name}
              onClick={() =>
                handleResponsibilityCardClick({ ...boardColumnResponsibility, groupId })
              }
              specializations={boardColumnResponsibility.specializations}
              workingHours={boardColumnResponsibility.workingHours}
            />
          ))}
        </ResponsibilitiesWrapper>
      </CollapsibleContent>
    </GroupResponsibilityCardContainer>
  );
};

export default GroupResponsibilityCard;
