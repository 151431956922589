import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { useGetAccountSettingsQuery } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { SectionDivider } from '../AdvancedSettings/styles';
import BrandStylesSection from './Forms/ContentSettings/BrandStylesSection';
import CompanySectorToggle from './Forms/ContentSettings/CompanySectorToggle';
import ContentPageDefaultView from './Forms/ContentSettings/ContentPageDefaultView';
import ContentPageToggle from './Forms/ContentSettings/ContentPageToggle';
import CustomTerminologySection from './Forms/ContentSettings/CustomTerminologySection';
import DefaultCurriculumAccessControlSection from './Forms/ContentSettings/DefaultCurriculumAccessControlSection';
import DownloadPDF from './Forms/ContentSettings/DownloadPDF';
import PolicySectorToggle from './Forms/ContentSettings/PolicySectorToggle';
import PublicShare from './Forms/ContentSettings/PublicShare';
import SignaturePermissions from './Forms/ContentSettings/SignaturePermissions';
import { PageHeader } from './styles';

const t = initTranslations('account_settings.content_settings');

const ContentTabSection = () => {
  const { allowSignatures } = useCurrentAccount();
  const { data, isLoading } = useGetAccountSettingsQuery();

  if (!data || isLoading) return null;

  const { brand_styles: brandStyles, default_brand_styles: defaultBrandStyles } = data;
  return (
    <div id='content-tab-section'>
      <PageHeader>{t('header')}</PageHeader>
      <PublicShare />
      <SectionDivider />
      <DownloadPDF />
      {allowSignatures && (
        <>
          <SectionDivider />
          <SignaturePermissions />
        </>
      )}
      <SectionDivider />
      <DefaultCurriculumAccessControlSection />
      <SectionDivider />
      <BrandStylesSection brandStyles={brandStyles} defaultBrandStyles={defaultBrandStyles} />
      <PageHeader>{t('content_page.subheader')}</PageHeader>
      <ContentPageToggle />
      <SectionDivider />
      <CompanySectorToggle />
      <SectionDivider />
      <PolicySectorToggle />
      <SectionDivider />
      <ContentPageDefaultView />
      <SectionDivider />
      <CustomTerminologySection />
    </div>
  );
};

export default ContentTabSection;
