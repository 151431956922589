import styled from 'styled-components';

import Icon from '../../../../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../../../../styled/TypeSystem';

export const CollapsibleItemWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerMd2}`};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: ${({ theme: { vars } }) => `0 0 4px 0 ${vars.shadowColorCenterLarge}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CollapsibleItemHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const CollapsibleItemTitle = styled.h6`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd1};
  margin: 0 auto 0 0;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
`;

export const CollapsibleItemContent = styled.div<{ isCollapsed: boolean }>`
  transition: margin 0.3s ease-in-out;
  padding: 0 ${({ theme: { constants } }) => constants.spacerLg1};
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};
  margin-top: ${({ theme: { constants }, isCollapsed }) => (isCollapsed ? 0 : constants.spacerSm3)};
  a {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }
  .ReactCollapse--collapse {
    transition: height 0.3s ease-in-out;
  }
  ${fontSm5};
`;

export const StyledChevronIcon = styled(Icon)<{ isCollapsed: boolean }>`
  transition: transform 0.3s ease-in;
  transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(0)' : 'rotate(180deg)')};
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const StyledQuestionIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;
