export enum JobStatus {
  // In Progress
  queued = 'queued',
  retrying = 'retrying',
  working = 'working',

  // Success
  complete = 'complete',

  // Incomplete/Error
  failed = 'failed',
  interrupted = 'interrupted',
  stopped = 'stopped',
  unknown = 'unknown',
}

export type JobDetails = {
  id: string;
  status: JobStatus;
};
