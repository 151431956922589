import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { ToggleContainer } from '../../../AdvancedSettings/styles';
import {
  FormSectionDescription,
  FormSectionTitle,
  ToggleInfoSection,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings.content_page');

const CompanySectorToggle = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <ToggleContainer>
        <Toggle
          checked={values.company_sector}
          className='company-sector'
          handleToggle={() => setFieldValue('company_sector', !values.company_sector)}
          id='enable-company-sector'
          name='company_sector'
        />
      </ToggleContainer>
      <ToggleInfoSection>
        <div>
          <FormSectionTitle>{t('company_toggle_title')}</FormSectionTitle>
          <FormSectionDescription>{t('company_toggle_title')}</FormSectionDescription>
        </div>
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default CompanySectorToggle;
