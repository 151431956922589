import styled, { css } from 'styled-components';

import P from '../../../../../design_system/text/P';
import { mediaBreakpointPxLg } from '../../../../../styled/Breakpoint';
import { fontMd1 } from '../../../../../styled/TypeSystem';
import { PlanCardTitle } from '../../../per_user_pricing/shared/styles';
import { PlanCardDividerProps } from './types';

export const MoveToNewPlanImg = styled.img`
  position: absolute;
  z-index: 1;
  left: calc(100% - 4.2rem);
  width: 5.6rem;
  height: 3.9rem;
  object-fit: cover;

  @media (min-width: ${mediaBreakpointPxLg}) {
    top: ${({ theme: { constants } }) => constants.spacerSm2};
    left: 8.7rem;
    width: 8rem;
    height: 5rem;
  }
`;

export const PlanCardDetailsWrapper = styled.div<{ isNewPlan: boolean }>`
  opacity: ${({ isNewPlan }) => (isNewPlan ? 1 : 0.5)};
`;

export const PlanCardBillingCycleText = styled(P)`
  font-style: italic;
`;
export const PlanCardsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const PlanCardStyles = css`
  position: relative;
  width: 100%;
  height: 16.5rem;
  display: flex;
  flex-direction: column;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

export const StyledPlanCardWrapper = styled.div<{ isNewPlan?: boolean }>`
  max-width: 15rem;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ isNewPlan, theme: { vars } }) =>
    isNewPlan ? vars.foundationBase1 : vars.foundationSurface1};

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding: ${({ theme: { constants } }) =>
      `${constants.spacerMd2} ${constants.spacerMd3} ${constants.spacerLg1}`};
  }

  ${PlanCardStyles};
`;

export const PlanCardStatus = styled(P)<{ withMarginBottom?: boolean }>`
  font-weight: ${({ theme }) => theme.constants.fontBold};
  ${({ withMarginBottom, theme: { constants } }) =>
    withMarginBottom && `margin-bottom: ${constants.spacerLg1};`};

  ${fontMd1};
`;

export const PlanCardFooterWrapper = styled.div`
  margin-top: auto;
`;

const planCardDividerStyles = css`
  width: auto;
  height: 0.188rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
`;

export const PlanCardDivider = styled.div<PlanCardDividerProps>`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${({ planBrandColor }) => `background-color: ${planBrandColor};`}
  ${planCardDividerStyles};
`;

export const SkeletonDivider = styled.div`
  margin-bottom: ${({ theme: { constants } }) =>
    `calc(${constants.spacerSm1} + ${constants.spacerMd2})`};
  background-color: ${({ theme: { vars } }) => vars.borderDisabled};

  ${planCardDividerStyles};
`;

export const PlanCardPrice = styled(P)`
  font-weight: ${({ theme }) => theme.constants.fontSemibold};
`;

export const PlanCardSeatsIncludedText = styled(P)`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const StyledPlanCardTitle = styled(PlanCardTitle)`
  text-align: left !important;
  width: max-content;
  ${fontMd1};
`;
