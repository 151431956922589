import styled from 'styled-components';

import { mediaBreakpointPxLg } from '../../../../../styled/Breakpoint';
import { fontMd5, fontSm4 } from '../../../../../styled/TypeSystem';

export const TemplatePreviewStepCounter = styled.div`
  text-align: right;
  color: ${({ theme: { vars } }) => vars.textDisabled};
  margin: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerMd2} ${constants.spacerSm2}`};
  ${fontSm4};

  @media (min-width: ${mediaBreakpointPxLg}) {
    margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

export const TemplatePreviewStepHead = styled.div`
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};

  @media (min-width: ${mediaBreakpointPxLg}) {
    margin: ${({ theme: { constants } }) => `0 ${constants.spacerLg2}`};
  }
`;

export const TemplatePreviewStepTitle = styled.h4`
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontMd5};
`;
