import React, { ReactElement } from 'react';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import { useDeleteGroupMutation } from '../../../../redux/services/resourceApis/groups/groupsApi';
import { Group } from '../../../../types/Group';
import TaskModal from '../../../design_system/overlays/modals/TaskModal';
import P from '../../../design_system/text/P';

const t = initTranslations('groups');

interface Props {
  closeModal: () => void;
  group: Group;
}

const DeleteGroupConfirmationModal = ({ closeModal, group }: Props): ReactElement => {
  const [deleteGroup, result] = useDeleteGroupMutation();

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('modals.delete_group.failure'),
    successFunction: closeModal,
  });

  return (
    <TaskModal
      desktopSize='lg'
      heapModalName='delete-group-confirmation-modal'
      isDisabled={result.isLoading}
      onCloseRequest={closeModal}
      primaryButtonTask={() => deleteGroup(group.id)}
      primaryButtonText={t('modals.delete_group.confirm')}
      processing={result.isLoading}
      secondaryButtonText={t('modals.delete_group.cancel')}
      title={t('modals.delete_group.title', { group_kind: t(`kinds.${group.kind}`) })}
    >
      <P
        text={t('modals.delete_group.subtitle', {
          group_name: group.name,
          group_kind: t(`kinds.${group.kind}`),
        })}
      />
    </TaskModal>
  );
};

export default DeleteGroupConfirmationModal;
