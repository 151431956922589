import styled, { css } from 'styled-components';

import InputField from '../../../design_system/Triage/InputField';
import { mediaBreakpointPx2xl, mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontMd1, fontMd2 } from '../../../styled/TypeSystem';

export const OnboardingWrapper = styled.div<{ innerHeight: number }>`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  position: fixed;
  inset: 0;
  z-index: 1100;
  .slick-slide {
    height: ${({ innerHeight }) => `${innerHeight}px !important`};
    min-height: 100vh;
    &.slick-active {
      .onboarding-step-header {
        transform: translateY(1rem);

        @media (min-width: ${mediaBreakpointPx2xl}) {
          transform: translateY(5rem);
          margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
        }
      }
      .onboarding-step-header-inner {
        opacity: 1;
      }
      .onboarding-step-title {
        ${fontMd1};

        @media (min-width: ${mediaBreakpointPxSm}) {
          ${fontMd2};
        }
      }
      .onboarding-step-inner {
        padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
      }
    }
    &:first-child {
      .onboarding-step-bullet {
        &:before {
          display: none;
        }
      }
    }
  }
`;

export const PreFillableInputField = styled(InputField)<{
  displayPrefilled?: boolean;
  isError?: boolean;
}>`
  ${({ isError, displayPrefilled, theme: { vars } }) =>
    displayPrefilled &&
    !isError &&
    css`
      border-color: ${vars.trainualBrandPurpleSurface};
      background-color: ${vars.trainualBrandPurpleSurfaceLight};
      box-shadow: 0 0 0 50px ${vars.trainualBrandPurpleSurfaceLight} inset;

      &:focus {
        border-color: ${vars.trainualBrandPurpleSurface};
        box-shadow: 0 0 0 50px ${vars.trainualBrandPurpleSurfaceLight} inset;
      }
    `}
`;
