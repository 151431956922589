import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import { SearchProvider, useSearch } from '../../../../contexts/SearchContext';
import { TeammateModalProvider, useTeammateModal } from '../../../../contexts/TeammateContext';
import { UsersProvider, useUsers } from '../../../../contexts/UsersContext';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import usePageTitle from '../../../../hooks/usePageTitle';
import useUserSortDropdownOptions from '../../../../hooks/useUserSortDropdownOptions';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import SearchField from '../../../design_system/Triage/fields/SearchField';
import useHeaderRoute from '../../../design_system/Triage/headers/Header/useHeaderRoute';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import DropdownMenu from '../../../design_system/Triage/menus/DropdownMenu';
import { mediaBreakpointPxLg, mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import PageContent from '../../shared/page_content';
import AddTeammateWithConfirmation from '../TeammateModal/AddTeammateWithConfirmation';
import UserCards from '../UserCards';
import { VIEW_BY_GROUP_OPTIONS } from '../ViewByGroup/viewByGroupOptions';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: row;
  }
`;

const SearchFieldWrapper = styled.div`
  width: 100%;
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
  }
`;

const AddTeammateButton = styled(DefaultButton)`
  min-width: 9.875rem;
  order: 3;
`;

const t = initTranslations('people');

const SearchRow = () => {
  const { dataIsLoading } = useUsers();
  const {
    searchValue,
    setSearchValue,
    selectedSortOption,
    selectedGroupByOption,
    setSelectedSortOption,
    setSelectedGroupByOption,
  } = useSearch();
  const { options } = useUserSortDropdownOptions();
  const { setTeammateModalVisible } = useTeammateModal();
  const ability = useCurrentUserAbilities();
  const canInviteUser = ability.can('create', 'User');
  const filteredOptions =
    selectedGroupByOption?.groupBy !== 'all_employees'
      ? options.filter((option) => option.useInGroups)
      : options;

  return (
    <HeaderWrapper>
      <SearchFieldWrapper>
        <SearchField
          dataLoading={dataIsLoading}
          placeholder={t('search_placeholder')}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </SearchFieldWrapper>
      <FiltersWrapper>
        <DropdownMenu
          id='group-sort-order-dropdown-button'
          options={VIEW_BY_GROUP_OPTIONS}
          selectedOption={selectedGroupByOption ? selectedGroupByOption : VIEW_BY_GROUP_OPTIONS[0]}
          setSelectedOption={setSelectedGroupByOption}
          size='max-content'
        />
        <DropdownMenu
          options={filteredOptions}
          selectedOption={selectedSortOption}
          setSelectedOption={setSelectedSortOption}
        />
      </FiltersWrapper>
      {canInviteUser && (
        <AddTeammateButton
          buttonType='primary'
          id='add-teammate-button'
          onClick={() => {
            setTeammateModalVisible(true);
          }}
          size='md'
          text={t('add_teammate')}
        />
      )}
    </HeaderWrapper>
  );
};

const Cards = () => {
  return (
    <>
      <TeammateModalProvider>
        <SearchRow />
        <AddTeammateWithConfirmation isDirectoryPage />
      </TeammateModalProvider>
      <div className='user-cards'>
        <UserCards />
      </div>
    </>
  );
};

const CardView = () => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'directory', productTerm });
  const { options } = useUserSortDropdownOptions();
  const { title, subtitle } = useHeaderRoute();

  return (
    <SearchProvider groupByOptions={VIEW_BY_GROUP_OPTIONS} sortOptions={options}>
      <UsersProvider>
        <PageContent>
          <PageHeader subtitle={subtitle} title={title} />
          <Cards />
        </PageContent>
      </UsersProvider>
    </SearchProvider>
  );
};

export default CardView;
