import { DragStartEvent } from '@dnd-kit/core';
import { Dispatch } from 'react';
import { DragStart, DragUpdate } from 'react-beautiful-dnd';

import { Placeholder } from './types';

export const handleDragStart = ({
  active,
  setActiveId,
}: DragStartEvent & { setActiveId: Dispatch<string | null> }) => {
  setActiveId(active.id as string);
};

export const handleDragCancel = (setActiveId: Dispatch<string | null>) => {
  setActiveId(null);
};

const getDraggedDom = (draggableId: string) => {
  const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
  return document.querySelector(domQuery);
};

export const handleStartDrag = (event: DragStart, setPlaceholder: Dispatch<Placeholder>): void => {
  const draggedDOM = getDraggedDom(event.draggableId);
  const parentNode = draggedDOM?.parentNode;

  if (!draggedDOM || !parentNode) {
    return;
  }

  const { clientHeight, clientWidth } = draggedDOM;
  const sourceIndex = event.source.index;
  const clientY =
    parseFloat(window.getComputedStyle(parentNode as Element).paddingTop) +
    Array.from(parentNode.children)
      .slice(0, sourceIndex)
      .reduce((total, curr) => {
        const style = window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

  setPlaceholder({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft),
  });
};

export const handleDragUpdate = (
  event: DragUpdate,
  setPlaceholder: Dispatch<Placeholder>
): void => {
  if (!event.destination) {
    return;
  }
  const draggedDOM = getDraggedDom(event.draggableId);
  const parentNode = draggedDOM?.parentNode;

  if (!draggedDOM || !parentNode) {
    return;
  }

  const { clientHeight, clientWidth } = draggedDOM;
  const destinationIndex = event.destination.index;
  const sourceIndex = event.source.index;

  const childrenArray = Array.from(parentNode.children);
  const movedItem = childrenArray[sourceIndex];
  childrenArray.splice(sourceIndex, 1);

  const updatedArray = [
    ...childrenArray.slice(0, destinationIndex),
    movedItem,
    ...childrenArray.slice(destinationIndex + 1),
  ];

  const clientY =
    parseFloat(window.getComputedStyle(parentNode as Element).paddingTop) +
    updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
      if (!curr) return total;

      const style = window.getComputedStyle(curr);
      const marginBottom = parseFloat(style.marginBottom);
      return total + curr.clientHeight + marginBottom;
    }, 0);

  setPlaceholder({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft),
  });
};
