import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import usePageTitle from '../../../../hooks/usePageTitle';
import { deleteQueryParam, fetchQueryParameters } from '../../../../lib/getQueryParams';
import initTranslations from '../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useGetUserSettingsQuery } from '../../../../redux/services/resourceApis/users/usersApi';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import Loader from '../../../design_system/Triage/Loader';
import PageContent from '../../shared/page_content';
import SettingsForm from './SettingsForm';
import { SectionWrapper } from './Styles';
import PasswordModalWrapper from './UpdatePasswordModal';
import validateForm from './ValidateForm';

const t = initTranslations('settings');

const Settings = () => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'my_settings', productTerm });

  const queryParams = fetchQueryParameters();
  const displayUpdatePasswordModal = queryParams.get('open_update_password_modal') === 'true';

  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const { data: user, isLoading, error } = useGetUserSettingsQuery();

  useEffect(() => {
    if (displayUpdatePasswordModal) {
      setPasswordModalOpen(displayUpdatePasswordModal);
      deleteQueryParam('open_update_password_modal', true);
    }
  }, [displayUpdatePasswordModal]);

  if (isLoading) return <Loader />;
  if (error) return <BasicErrorDisplay error={error} />;
  if (!user) return <></>;

  return (
    <PageContent>
      <SectionWrapper>
        <PageHeader title={t('page_title')} />
        <Formik
          initialValues={user}
          key={JSON.stringify(user)}
          onSubmit={() => {
            /* API request occurs in `SettingsForm` */
          }}
          validate={validateForm}
        >
          <SettingsForm openModal={() => setPasswordModalOpen(true)} />
        </Formik>
      </SectionWrapper>
      {passwordModalOpen && <PasswordModalWrapper closeModal={() => setPasswordModalOpen(false)} />}
    </PageContent>
  );
};

export default Settings;
