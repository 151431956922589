import { Form, useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import { PaletteOption } from 'saguaro';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import initTranslations from '../../../../lib/initTranslations';
import { BrandStyleData } from '../../../../redux/services/resourceApis/accountSettings/types';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import ColorPicker from '../../../design_system/buttons/DeprecatedColorPicker';
import Hoverable from '../../../design_system/Hoverable';
import PaywallTooltip from '../../shared/tooltips/PaywallTooltip';
import { ToggleContainer } from '../AdvancedSettings/styles';
import { ButtonRightPositionWrapper, StyledFormGroup } from '../OrganizationProfile/styles';
import PalettePicker from '../PalettePicker/PalettePicker';
import {
  DEFAULT_LOGO_BG_COLORS,
  OFFERED_ACCENT_PALETTES,
} from '../shared/constants/accountSettings';
import { InputsRow, Title } from './styles';
import { BrandStyleFormProps } from './types';

const t = initTranslations('account_settings.brand_styles');

const BrandStyleForm = ({
  setAccentHex,
  setAccentPalette,
  setLogoBgColor,
  children,
  isLoading,
  isValuesUnchanged,
}: BrandStyleFormProps) => {
  const paywallCtx = useContext(PaywallContext);
  const brandStylesLocked = paywallCtx.includes('brand_styles');
  const [isToggleHovered, setIsToggleHovered] = useState(false);

  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext<BrandStyleData>();

  return (
    <Form autoComplete='off'>
      <InputsRow>
        <StyledFormGroup>
          <Title>{t('logo_background_color')}</Title>
          <ColorPicker
            ariaLabel={t('logo_background_color')}
            buttonId='color-picker-logo-bg-button'
            color={values.logo_background_color}
            defaultColors={DEFAULT_LOGO_BG_COLORS}
            errorText={(touched.logo_background_color && errors.logo_background_color) || undefined}
            menuId='color-picker-logo-bg'
            name='logo_background_color'
            onChange={(e) => {
              handleChange(e);
              setLogoBgColor(e.target.value);
            }}
            setColor={(color: string) => {
              setFieldValue('logo_background_color', color);
              setLogoBgColor(color);
            }}
            type='background-color'
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <Title>{t('accent_color')}</Title>
          <PalettePicker
            ariaLabel={t('accent_color')}
            buttonId='brand-styles-color-picker-button'
            errorText={(touched.accent_color && errors.accent_color) || undefined}
            hex={values.accent_color}
            id='color-picker-accent-color'
            name='accent_color'
            onChange={(e) => {
              handleChange(e);
              setAccentHex(e.target.value);
            }}
            palette={values.accent_palette}
            palettes={OFFERED_ACCENT_PALETTES}
            setColor={(color: string) => {
              const isHex = color.includes('#');
              if (isHex) {
                setFieldValue('accent_color', color);
                setFieldValue('accent_palette', null);
                setAccentHex(color);
                setAccentPalette(null);
              } else if ((OFFERED_ACCENT_PALETTES as string[]).includes(color)) {
                setFieldValue('accent_palette', color);
                setAccentPalette(color as PaletteOption);
              }
            }}
            type='background-color'
          />
        </StyledFormGroup>
      </InputsRow>
      {children}
      <ButtonRightPositionWrapper>
        {brandStylesLocked ? (
          <ToggleContainer>
            <PaywallTooltip
              description={t('lock_brand_styles_paywall.description')}
              modifiers={[{ name: 'offset', options: { offset: [80, 0] } }]}
              shouldBeVisible={isToggleHovered && brandStylesLocked}
              title={t('lock_brand_styles_paywall.title')}
            />
            <Hoverable setIsHovered={(value) => !isValuesUnchanged && setIsToggleHovered(value)}>
              <DefaultButton
                disabled={isValuesUnchanged}
                iconName='lock'
                id='save-brand-styles'
                loading={isLoading}
                text={t('save_changes')}
              />
            </Hoverable>
          </ToggleContainer>
        ) : (
          <DefaultButton
            disabled={isValuesUnchanged}
            id='save-brand-styles'
            loading={isLoading}
            text={t('save_changes')}
          />
        )}
      </ButtonRightPositionWrapper>
    </Form>
  );
};

export default BrandStyleForm;
