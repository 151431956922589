import { Content, useEditor } from '@tiptap/react';
import React from 'react';
import { useTheme } from 'styled-components';

import { EditorProvider } from '../../../../../../contexts/EditorContext';
import useContentStyles from '../../../../../../hooks/useContentStyles';
import { EditorExtensions } from '../../../../editor/shared/constants/extensions';
import { StyledEditorContent } from '../../../../editor/shared/styles';

type EditorViewContentProps = {
  content: Content;
};

const EditorViewContent = ({ content }: EditorViewContentProps) => {
  const contentStyles = useContentStyles();
  const { palettes } = useTheme();

  const editor = useEditor(
    {
      extensions: EditorExtensions({ isEditable: false, palettes }),
      content,
      editable: false,
    },
    [content]
  );

  if (!editor) return <></>;

  return (
    <EditorProvider editor={editor}>
      <StyledEditorContent $contentStyles={contentStyles} editor={editor} />
    </EditorProvider>
  );
};

export default EditorViewContent;
