import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateTopicMutation } from '../../../../../redux/services/resourceApis/courses/topicsApi';
import EmojiPicker from '../../../shared/EmojiPicker';
import ImageFlyout from '../../plugins/Image/ImageFlyout';
import { RANDOM_EMOJI_LIST } from '../../shared/constants/editor';
import { getImageFlyoutItems } from '../../shared/helpers';
import ActionToggleButton from './ActionToggleButton';
import { HeaderEmojiWrapper } from './styles';
import { TOPIC_HEADER_FLYOUT_MENU_ID } from './TopicHeader';

const ActionToggleContainer = styled.div`
  width: fit-content;
  display: flex;
  margin: ${({ theme: { constants } }) => `0 0 -${constants.spacerMd3} ${constants.spacerLg2}`};
  z-index: 1;
`;

const ButtonsWrapper = styled.div<{ onlyEmojiPresent: boolean }>`
  display: flex;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  align-items: ${({ onlyEmojiPresent }) => (onlyEmojiPresent ? 'flex-end' : 'center')};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

type Props = {
  topicEmoji: string | null;
  isCoverImagePresent: boolean;
  topicId: number;
  showCropper: boolean;
  setShowCropper: (show: boolean) => void;
};

const t = initTranslations('editor.topic.header');

const TopicHeaderActions = ({
  topicEmoji,
  topicId,
  isCoverImagePresent,
  showCropper,
  setShowCropper,
}: Props) => {
  const [showPicker, setShowPicker] = useState(false);
  const [update] = useUpdateTopicMutation();

  const emojiSelected = useCallback(
    (emoji: BaseEmoji) => {
      update({ id: topicId, emoji: emoji.native });
    },
    [topicId, update]
  );

  const clearEmoji = useCallback(() => {
    update({ id: topicId, emoji: null });
  }, [topicId, update]);

  const getRandomEmoji = () => {
    const randomIndex = Math.floor(Math.random() * RANDOM_EMOJI_LIST.length);
    return RANDOM_EMOJI_LIST[randomIndex];
  };

  return (
    <ActionToggleContainer>
      {topicEmoji && (
        <EmojiPicker
          emojiPresent={topicEmoji}
          isOpen={showPicker}
          onClick={() => setShowPicker(!showPicker)}
          onClickOutside={() => setShowPicker(false)}
          onEmojiSelect={(emoji: BaseEmoji) => {
            emojiSelected(emoji);
            setShowPicker(false);
          }}
          removeButtonAction={clearEmoji}
          trigger={<HeaderEmojiWrapper id='emoji-picker-trigger'>{topicEmoji}</HeaderEmojiWrapper>}
        />
      )}
      {!showCropper && (
        <ButtonsWrapper onlyEmojiPresent={!isCoverImagePresent && !!topicEmoji}>
          {!topicEmoji && (
            <ActionToggleButton
              iconName='face-laugh'
              onClick={() => update({ emoji: getRandomEmoji(), id: topicId })}
              text={t('add_emoji')}
            />
          )}
          <ImageFlyout
            menuId={TOPIC_HEADER_FLYOUT_MENU_ID}
            menuItems={getImageFlyoutItems(['stock_image', 'upload'], t)}
            trigger={
              <>
                {!isCoverImagePresent && (
                  <ActionToggleButton iconName='image-landscape' text={t('add_cover_photo')} />
                )}
              </>
            }
            uploadAfterClosed={() => setShowCropper(true)}
          />
        </ButtonsWrapper>
      )}
    </ActionToggleContainer>
  );
};

export default TopicHeaderActions;
