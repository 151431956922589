import React from 'react';
import styled, { css } from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { Title } from '../../shared/DetailedTitle/Title';

const HoverableDiv = styled.div<{ truncate: boolean; onClick?: () => void }>`
  display: flex;
  align-items: center;
  max-width: 100%;
  ${({ onClick }) => onClick && 'width: fit-content;'}
  ${({ truncate, theme: { constants } }) => truncate && `height: ${constants.heightMd}};`};

  &:hover div {
    visibility: visible;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  visibility: hidden;
  display: flex;
`;

const IconWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    margin-left: 1.08rem;
    margin-right: ${constants.spacerSm3};
    color: ${vars.textDefault};
    border-radius: 50%;
    border: ${constants.borderWidthSm} solid transparent;
    padding: ${constants.spacerSm2};

    @media (max-width: ${mediaBreakpointPxSm}) {
      border: ${constants.borderWidthSm} dashed ${vars.textDefault};
    }

    &:hover {
      border: ${constants.borderWidthSm} dashed ${vars.textDefault};
      cursor: pointer;
    }
  `
);

interface Props {
  attributeName: string;
  children: React.ReactElement;
  setEditState: () => void;
  className?: string;
  truncate: boolean;
  infoText: string;
  isTextClickable?: boolean;
}

const Editable = ({
  attributeName,
  children,
  setEditState,
  className = '',
  truncate,
  infoText,
  isTextClickable = false,
}: Props) => {
  return (
    <HoverableDiv
      className={className}
      id={`editable-${attributeName}-hover-wrapper`}
      truncate={truncate}
      {...(isTextClickable && { onClick: setEditState })}
    >
      {children}

      {!isTextClickable && (
        <ButtonWrapper>
          <IconWrapper id={`editable-${attributeName}-icon-wrapper`} onClick={setEditState}>
            <Icon name='pencil' weight='regular' />
          </IconWrapper>
          <Title fontColor='placeholder' title={infoText} />
        </ButtonWrapper>
      )}
    </HoverableDiv>
  );
};

export default Editable;
