import React, { ReactNode, createContext, useContext, useState } from 'react';

import { LimitedStep } from '../types/Step';

interface CurriculumElementsProviderContext {
  setCourseSteps: React.Dispatch<React.SetStateAction<CourseSteps | undefined>>;
  courseSteps: CourseSteps;
}

const CurriculumElementsContext = createContext<CurriculumElementsProviderContext>(
  {} as CurriculumElementsProviderContext
);

export const useCurriculumElements = () => useContext(CurriculumElementsContext);

interface ProviderProps {
  children: ReactNode;
}

type CourseSteps = {
  [key: number]: LimitedStep[];
};

export const CurriculumElementsProvider = ({ children }: ProviderProps) => {
  const [courseSteps, setCourseSteps] = useState<CourseSteps>({});

  return (
    <CurriculumElementsContext.Provider
      value={{
        setCourseSteps,
        courseSteps,
      }}
    >
      {children}
    </CurriculumElementsContext.Provider>
  );
};
