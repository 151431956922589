import { isEmpty } from 'lodash';
import React, { Dispatch, useCallback, useMemo, useState } from 'react';
import {
  DragDropContext,
  DragStart,
  DragUpdate,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import { TrainingPathSetProvider } from '../../../../../contexts/TrainingPathSetContext';
import {
  useUpdateTrainingPathContentMutation,
  useUpdateTrainingPathSetMutation,
} from '../../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import { PlaceholderBlock } from '../../../shared/DragAndDrop/styles';
import { Placeholder } from '../../../shared/DragAndDrop/types';
import ProgressItem from '../../../shared/ProgressItem/ProgressItem';
import { getSetStatus } from '../../shared';
import DeleteSplit from '../DeleteSplit/DeleteSplit';
import EducationBanner from '../EducationBanner/EducationBanner';
import EmptyState from '../EmptyState/EmptyState';
import ConfigSet from './ConfigSet/ConfigSet';

const BannerWrapper = styled.div(
  ({ theme: { constants } }) => css`
    margin-bottom: ${constants.spacerMd3};
  `
);

const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const getDraggedDom = (draggableId: string) => {
  const domQuery = `[data-rbd-draggable-id='${draggableId}']`;
  return document.querySelector(domQuery);
};

const handleStartDrag = (event: DragStart, setPlaceholder: Dispatch<Placeholder>): void => {
  if (event.draggableId.split('+')[0] !== 'configSet') {
    return;
  }

  const draggedDOM = getDraggedDom(event.draggableId);
  const parentNode = draggedDOM?.parentNode;

  if (!draggedDOM || !parentNode) {
    return;
  }

  const { clientHeight, clientWidth } = draggedDOM;
  const sourceIndex = event.source.index;
  const clientY =
    parseFloat(window.getComputedStyle(parentNode as Element).paddingTop) +
    Array.from(parentNode.children)
      .slice(0, sourceIndex)
      .reduce((total, curr) => {
        const style = window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0) +
    143;

  setPlaceholder({
    clientHeight,
    clientWidth,
    clientY,
    clientX: 88,
  });
};

const handleDragUpdate = (event: DragUpdate, setPlaceholder: Dispatch<Placeholder>): void => {
  if (event.draggableId.split('+')[0] !== 'configSet') {
    return;
  }

  if (!event.destination) {
    return;
  }
  const draggedDOM = getDraggedDom(event.draggableId);
  const parentNode = draggedDOM?.parentNode;

  if (!draggedDOM || !parentNode) {
    return;
  }

  const { clientHeight, clientWidth } = draggedDOM;
  const destinationIndex = event.destination.index;
  const sourceIndex = event.source.index;

  const childrenArray = Array.from(parentNode.children);
  childrenArray.splice(sourceIndex, 1);
  childrenArray.splice(destinationIndex, 0, draggedDOM);

  const parentPaddingTop = parseFloat(window.getComputedStyle(parentNode as Element).paddingTop);
  const additionalOffset = 143;

  const clientY = childrenArray.slice(0, destinationIndex).reduce((total, curr, index) => {
    if (!curr) return total;

    const style = window.getComputedStyle(curr);
    const marginBottom = parseFloat(style.marginBottom);

    const height = index === destinationIndex ? clientHeight : curr.clientHeight;

    return total + height + marginBottom;
  }, parentPaddingTop + additionalOffset);

  setPlaceholder({
    clientHeight,
    clientWidth,
    clientY,
    clientX: 88,
  });
};

const ConfigBody = () => {
  const [draggingType, setDraggingType] = useState<string>();
  const [placeholder, setPlaceholder] = useState<Placeholder>({});
  const {
    user,
    trainingPath: { trainingPathSets },
    updateInProgress,
  } = useTrainingPathContext();
  const [updateTrainingPathSet] = useUpdateTrainingPathSetMutation({
    fixedCacheKey: 'shared-update-training-path-set',
  });
  const [updateTrainingPathContent] = useUpdateTrainingPathContentMutation({
    fixedCacheKey: 'shared-update-training-path-content',
  });

  const handleOnDragEnd = useCallback(
    async (result: DropResult) => {
      const { destination, draggableId, source } = result;

      setDraggingType(undefined);
      if (!destination) return;

      const destinationTag = destination.droppableId.split('+')[0];
      const dragId = draggableId.split('+')[1];
      const position = destination.index + 1;

      if (destinationTag === 'configSet') {
        const set = trainingPathSets.find((set) => set.trainingPathSetUuid === dragId);

        if (!set || source.index === destination.index) return;

        updateTrainingPathSet({
          ...set,
          trainingPathSetUuid: dragId,
          userId: user.id,
          position,
          resetDelay: false,
        });
      } else if (destinationTag === 'content') {
        const oldSetUuid = draggableId.split('+')[2];
        const trainingPathSetUuid = destination.droppableId.split('+')[1];
        if (source.index === destination.index && oldSetUuid === trainingPathSetUuid) return;

        updateTrainingPathContent({
          position,
          userId: user.id,
          curriculumId: parseInt(dragId),
          trainingPathSetUuid,
          oldSetUuid,
        });
      }
    },
    [trainingPathSets, updateTrainingPathContent, updateTrainingPathSet, user.id]
  );

  const moreThanOneSet = useMemo(() => trainingPathSets.length > 1, [trainingPathSets]);
  const firstIncompleteSet = useMemo(
    () => trainingPathSets.find((set) => set.completionPercentage < 100),
    [trainingPathSets]
  );

  if (trainingPathSets.length === 0) {
    return (
      <EmptyStateWrapper>
        <EmptyState name={user.name} userId={user.id} />
      </EmptyStateWrapper>
    );
  }

  return (
    <DragDropContext
      onDragEnd={handleOnDragEnd}
      onDragStart={(event) => {
        setDraggingType(event.draggableId.split('+')[0]);
        handleStartDrag(event, setPlaceholder);
      }}
      onDragUpdate={(event) => handleDragUpdate(event, setPlaceholder)}
    >
      <>
        <BannerWrapper>
          <EducationBanner userName={user.name} />
        </BannerWrapper>
        <Droppable droppableId='configSet' type='config-set'>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              className='config-set-wrapper'
              ref={provided.innerRef}
              style={{ marginLeft: draggingType === 'configSet' ? 48 : 0 }}
            >
              {trainingPathSets.map((set, index) => (
                <TrainingPathSetProvider
                  key={`config-set-${set.trainingPathSetUuid}`}
                  trainingPathSet={set}
                  userId={user.id}
                >
                  <ProgressItem
                    avatar={user.avatar}
                    completionStatus={getSetStatus(set, firstIncompleteSet)}
                    hasLine={trainingPathSets.length - 1 !== index}
                    hideProgress={draggingType === 'configSet'}
                    name={user.name}
                  >
                    <Draggable
                      draggableId={`configSet+${set.trainingPathSetUuid}`}
                      index={index}
                      isDragDisabled={updateInProgress}
                      key={set.trainingPathSetUuid}
                    >
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <ConfigSet provided={provided} />

                          {moreThanOneSet && index < trainingPathSets.length - 1 && (
                            <DeleteSplit
                              trainingPathSetUuid={trainingPathSets[index + 1].trainingPathSetUuid}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  </ProgressItem>
                </TrainingPathSetProvider>
              ))}
              {provided.placeholder}
              {!isEmpty(placeholder) && snapshot.isDraggingOver && (
                <PlaceholderBlock
                  style={{
                    top: placeholder.clientY,
                    left: placeholder.clientX,
                    height: placeholder.clientHeight,
                    width: placeholder.clientWidth,
                  }}
                />
              )}
            </div>
          )}
        </Droppable>
      </>
    </DragDropContext>
  );
};

export default ConfigBody;
