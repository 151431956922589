import { truncate } from 'lodash';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import useCurriculumElement from '../../../../hooks/useCurriculumElement';
import useTruncatedText from '../../../../hooks/useTruncatedText';
import initTranslations from '../../../../lib/initTranslations';
import { CurriculumElementWithElementCompletion } from '../../../../redux/services/resourceApis/curriculums/types';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Icon from '../../../design_system/display/icons/Icon';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import CurriculumElementBadge from '../CurriculumElementBadge';
import { BadgeWrapper, StyledBadge } from '../shared/CurriculumElementRowStyles';

const ContainerStyles = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  text-decoration: none;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderRadiusXs} solid ${vars.borderSurface2}`};
  ${fontSm5};
`;

const ElementLink = styled.a`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${ContainerStyles};
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  }
`;

const ElementSpan = styled.span`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  color: ${({ theme: { vars } }) => vars.textDisabled};
  ${ContainerStyles};
`;

const LockIconWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  * {
    pointer-events: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const ESignatureText = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  font-style: italic;
  ${fontSm4};
`;

const DisabledOverlay = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.6;
`;

const ArrowIconWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('curriculums.curriculum_elements');

type CurriculumElementRowProps = {
  curriculumElement: CurriculumElementWithElementCompletion;
};

const CurriculumElementRow = ({ curriculumElement }: CurriculumElementRowProps) => {
  const {
    elementKind,
    elementId,
    element: { title, emoji },
    elementCompletion,
    esignatureRequiredText,
    previousIncomplete,
    id,
  } = curriculumElement;
  const {
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const badgeRef = useRef<HTMLSpanElement>(null);
  const showTooltip = useTruncatedText(badgeRef, { maxLines: 1 });
  const { showRoute, termSingular } = useCurriculumElement()({ elementKind, elementId });

  return (
    <div id={`curriculum-element-${id}`}>
      {!previousIncomplete ? (
        <ElementLink {...showRoute.link}>
          <TitleWrapper>
            <BadgeWrapper>
              {showTooltip && (
                <Tooltip id={`${elementKind}-badge-${elementId}`} text={termSingular} />
              )}
              <div data-for={`${elementKind}-badge-${elementId}`} data-tip>
                <StyledBadge forwardRef={badgeRef} text={termSingular} type='info' />
              </div>
            </BadgeWrapper>
            {emoji && <IconWrapper>{emoji}</IconWrapper>}
            {truncate(title, { length: 75 })}
          </TitleWrapper>
          <InfoWrapper>
            {esignatureRequiredText && <ESignatureText>{esignatureRequiredText}</ESignatureText>}
            <CurriculumElementBadge
              elementCompletion={elementCompletion}
              elementKind={elementKind}
            />
            <ArrowIconWrapper>
              <Icon name='arrow-right' />
            </ArrowIconWrapper>
          </InfoWrapper>
        </ElementLink>
      ) : (
        <ElementSpan>
          <TitleWrapper>
            <>
              <Tooltip
                id='locked-curriculum-element-tip'
                text={t('forced_order_tip', { element: topicTermSingular })}
              />
              <LockIconWrapper data-for='locked-curriculum-element-tip' data-tip>
                <Icon name='lock' />
              </LockIconWrapper>
            </>
            <DisabledOverlay>
              <BadgeWrapper>
                {showTooltip && (
                  <Tooltip id={`${elementKind}-badge-${elementId}`} text={termSingular} />
                )}
                <div data-for={`${elementKind}-badge-${elementId}`} data-tip>
                  <StyledBadge forwardRef={badgeRef} text={termSingular} type='info' />
                </div>
              </BadgeWrapper>
              {emoji && <IconWrapper>{emoji}</IconWrapper>}
            </DisabledOverlay>
            {truncate(title, { length: 75 })}
          </TitleWrapper>
          <InfoWrapper>
            <DisabledOverlay>
              {esignatureRequiredText && <ESignatureText>{esignatureRequiredText}</ESignatureText>}
              <CurriculumElementBadge
                elementCompletion={elementCompletion}
                elementKind={elementKind}
              />
              <ArrowIconWrapper>
                <Icon name='arrow-right' />
              </ArrowIconWrapper>
            </DisabledOverlay>
          </InfoWrapper>
        </ElementSpan>
      )}
    </div>
  );
};

export default CurriculumElementRow;
