import React from 'react';

import { OnboardingData, useOnboarding } from '../../../../../contexts/OnboardingContext';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import ImageUpload from '../ImageUpload';
import { ButtonsContainer, CustomizeThemeStepContainer, CustomizeThemeTitle } from './styles';

const t = initTranslations('home.onboarding.customize_logo_step');

const CustomizeLogoStep = () => {
  const { formData, sliderRef, setFormData } = useOnboarding();
  const handlePrevSlide = (e: React.MouseEvent<HTMLButtonElement>) => {
    sliderRef?.current?.slickPrev();
    e.currentTarget.blur();
  };
  const handleNextSlide = (e: React.MouseEvent<HTMLButtonElement>) => {
    sliderRef?.current?.slickNext();
    e.currentTarget.blur();
  };

  return (
    <CustomizeThemeStepContainer id='customize-account-logo-step'>
      <CustomizeThemeTitle>{t('title')}</CustomizeThemeTitle>
      <ImageUpload
        descriptionText={t('uploader.description')}
        imageForm='rectangle'
        imageSource={formData.logoUrl}
        resetButtonId='onboarding-remove-logo-button'
        resetButtonText={t('uploader.remove')}
        setImage={(image) => setFormData((prev: OnboardingData) => ({ ...prev, logo: image }))}
        uploadButtonId='onboarding-upload-logo-button'
        uploadButtonText={t('uploader.upload_image')}
      />
      <ButtonsContainer>
        <DefaultButton
          buttonType='tertiary'
          id='onboarding-customize-logo-step-back'
          onClick={handlePrevSlide}
          size='md'
          text={t('back')}
        />
        <DefaultButton
          buttonType='primary'
          id='onboarding-customize-logo-step-submit'
          onClick={handleNextSlide}
          size='md'
          text={t(!!formData.logo ? 'next' : 'customize_later')}
        />
      </ButtonsContainer>
    </CustomizeThemeStepContainer>
  );
};

export default CustomizeLogoStep;
