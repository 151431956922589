import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useGroupsModals } from '../../../../../contexts/GroupsModalContext';
import initTranslations from '../../../../../lib/initTranslations';
import { GroupKind, groupKinds } from '../../../../../types/Group';
import PageHeader from '../../../../design_system/Triage/headers/PageHeader';
import DeleteGroupConfirmationModal from '../../DeleteGroupConfirmationModal/DeleteGroupConfirmationModal';
import CreateGroupKindModal from '../../modals/CreateGroupKindModal/CreateGroupKindModal';
import CreateGroupNameModal from '../../modals/CreateGroupNameModal/CreateGroupNameModal';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.constants.borderRadiusXs};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

// DS Override: Override margin to align header text with inline button; rely on
// HeaderWrapper margin for separation from content
const StyledPageHeader = styled(PageHeader)`
  margin-block: auto;
`;

const t = initTranslations('groups');

const isValidGroupKind = (value: string): value is GroupKind => {
  return groupKinds.includes(value as GroupKind);
};

const Header = () => {
  const {
    state: { groupToDelete, activeModal },
    dispatch,
  } = useGroupsModals();

  const queryParams = new URLSearchParams(window.location.search);
  const displayGroupCreationModal = queryParams.get('show_create_group_kind_modal');
  const groupCreationModalKind = queryParams.get('group_kind');

  useEffect(() => {
    if (groupCreationModalKind && isValidGroupKind(groupCreationModalKind)) {
      dispatch({ type: 'setNewGroupKind', payload: groupCreationModalKind });
    }
  }, [dispatch, groupCreationModalKind]);

  useEffect(() => {
    if (displayGroupCreationModal === 'true') {
      dispatch({ type: 'openGroupKindModal' });
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [dispatch, displayGroupCreationModal]);

  return (
    <HeaderWrapper>
      <StyledPageHeader title={t('header_title')} />
      {activeModal === 'groupKind' && <CreateGroupKindModal />}
      {activeModal === 'groupName' && <CreateGroupNameModal />}
      {groupToDelete && activeModal === 'deleteGroup' && (
        <DeleteGroupConfirmationModal
          closeModal={() => dispatch({ type: 'closeDeleteGroupModal' })}
          group={groupToDelete}
        />
      )}
    </HeaderWrapper>
  );
};

export default Header;
