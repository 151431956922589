import { useFormikContext } from 'formik';
import React from 'react';
import { Route } from 'type-route';

import usePublicConfigs from '../../../../../../hooks/usePublicConfigs';
import useWindowResize from '../../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import Link from '../../../../../design_system/Link';
import InputField from '../../../../../design_system/Triage/InputField';
import PasswordInputField from '../../../../../design_system/Triage/PasswordInputField';
import { routes, useRoute } from '../../../applicationRouter';
import PasswordValidationPreview from '../../../passwords/PasswordValidationPreview/PasswordValidationPreview';
import {
  FormWrapper,
  InfoText,
  SignInSection,
  SubmitButtonWrapper,
  Subtitle,
  TermsLink,
  Title,
  TitlesSection,
} from '../styles';
import { FormValues } from '../types';
import { EmailPasswordFormProps } from './types';

const t = initTranslations('public_application.registration.sign_up_page');

const EmailPasswordForm = ({ checkoutTokenParam, clearbitTrigger }: EmailPasswordFormProps) => {
  const { isExtraSmWindow } = useWindowResize();
  const { configs } = usePublicConfigs();
  const route = useRoute();
  const updatedPasswordValidationPreviewEnabled = !!configs['UPDATED_PASSWORD_VALIDATION_PREVIEW'];
  const {
    params: { no_trial: noTrialParam },
  } = route as Route<typeof routes.root>;
  const { touched, errors, handleChange, values, validateForm } = useFormikContext<FormValues>();
  const { email, password } = values;
  const getTitleKey = () => {
    if (noTrialParam) {
      return 'no_trial';
    } else if (checkoutTokenParam) {
      return 'checkout_token_based';
    } else {
      return 'default';
    }
  };

  const getSubtitleKey = () => {
    if (isExtraSmWindow) {
      return 'mobile';
    } else if (checkoutTokenParam) {
      return 'checkout_token_based';
    } else {
      return 'default';
    }
  };

  const handleClearbitDataLoad = () => {
    validateForm().then((errors) => {
      if (!errors.email) {
        clearbitTrigger({ email });
      }
    });
  };

  return (
    <FormWrapper id='email-password-form'>
      <TitlesSection>
        <Title>{t(`title.${getTitleKey()}`)}</Title>
        {!noTrialParam && <Subtitle>{t(`subtitle.${getSubtitleKey()}`)}</Subtitle>}
      </TitlesSection>
      <InputField
        autoFocus
        errorText={touched.email && errors.email}
        iconName='envelope'
        label={t('email_label')}
        name='email'
        onBlur={handleClearbitDataLoad}
        onChange={(event) => handleChange(event)}
        placeholder={t('email_placeholder')}
        required
        value={email}
      />
      {updatedPasswordValidationPreviewEnabled ? (
        <PasswordValidationPreview
          isTouched={!!password.length || !!touched.password}
          validationErrors={errors.password || []}
        >
          <PasswordInputField
            iconName='lock'
            label={t('password_label')}
            name='password'
            onChange={(event) => handleChange(event)}
            placeholder={t('enter_password')}
            required
            value={password}
            wrapErrors
          />
        </PasswordValidationPreview>
      ) : (
        <PasswordInputField
          errorText={touched.password && errors.password}
          iconName='lock'
          label={t('password_label')}
          name='password'
          onChange={(event) => handleChange(event)}
          placeholder={t('password_placeholder')}
          required
          value={password}
          wrapErrors
        />
      )}
      <SubmitButtonWrapper>
        <DefaultButton
          fullWidth
          id='registration-submit-button' // id used by google tag manager
          text={t('register_cta')}
          type='submit'
        />
      </SubmitButtonWrapper>
      <SignInSection>
        {t('sign_in_prompt')}
        <Link href={routes.universalLogin().href} text={t('sign_in_cta')} />
      </SignInSection>
      <InfoText>
        {t('terms_1')}
        <TermsLink
          color='monochrome'
          external
          href={configs['PRIVACY_URL']}
          text={t('privacy_cta')}
        />
        {t('terms_2')}
        <TermsLink color='monochrome' external href={configs['TERMS_URL']} text={t('tos_cta')} />
      </InfoText>
    </FormWrapper>
  );
};

export default EmailPasswordForm;
