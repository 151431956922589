import { pickBy } from 'lodash';
import { Route } from 'type-route';

import { routes, useRoute } from '../../components/application/publicApplication/applicationRouter';
import RouteTo from '../../components/application/publicApplication/routeTo';
import { SortDirection } from '../../types/SortDirection';

type QueryParams = {
  search: string;
  sort: SortDirection;
};

export function useIntegrationsParamsToUrl() {
  const route = useRoute();
  const { params } = route as Route<typeof routes.integrations>;

  return (param: QueryParams) => {
    const defaultSort = param.sort === 'asc' ? undefined : param.sort;
    const queryParams = {
      search: param.search,
      sort: defaultSort,
    };

    const filteredQueryParams = pickBy(queryParams, (value) => value !== undefined && value !== '');

    RouteTo(
      routes.integrations({
        slug: params.slug,
        ...filteredQueryParams,
      })
    );
  };
}
