export const SPECIALIZATION_LABEL_COLORS = [
  '#d5d5d5',
  '#fcd573',
  '#f9ad77',
  '#f4a4a6',
  '#40ddc1',
  '#c2edff',
  '#5cceff',
  '#f99fda',
  '#e5dafb',
  '#bea2f6',
];
