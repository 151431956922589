import React, { useState } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import InputField, { Props as InputFieldProps } from '../InputField';

const t = initTranslations('design_system.inputs.password_input_field');

export type Props = {
  name: string;
} & InputFieldProps;

const PasswordInputField = (props: Props) => {
  const [isInputMasked, setIsInputMasked] = useState(true);
  const { name } = props;

  return (
    <InputField
      {...props}
      iconButtonAriaLabel={isInputMasked ? t('show_icon_aria_label') : t('hide_icon_aria_label')}
      iconButtonIsDataTip
      iconButtonName={isInputMasked ? 'eye-slash' : 'eye'}
      iconButtonOnClick={() => setIsInputMasked(!isInputMasked)}
      iconButtonTooltipId={`show-hide-${name}`}
      iconButtonTooltipText={isInputMasked ? t('tooltip_show_text') : t('tooltip_hide_text')}
      type={isInputMasked ? 'password' : 'text'}
    />
  );
};

export default PasswordInputField;
