import React, { useCallback, useMemo, useState } from 'react';

import ConfirmationModal from '../components/design_system/overlays/modals/ConfirmationModal';
import initTranslations from '../lib/initTranslations';
import { useEditBioMutation } from '../redux/services/resourceApis/users/usersApi';
import useDisplayFlashOnResponse from './useDisplayFlashOnResponse';

const t = initTranslations('user_profile.bio.delete_confirmation');

interface DeleteBioConfirmation {
  showDeleteBioModal: () => void;
  confirmationModal: JSX.Element;
}

export default function useDeleteBioConfirmation(userId: number): DeleteBioConfirmation {
  const [editBio, editBioResult] = useEditBioMutation();
  const { isLoading } = editBioResult;
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const successFunction = useCallback(
    () => setShowDeleteConfirmationModal(false),
    [setShowDeleteConfirmationModal]
  );

  useDisplayFlashOnResponse({
    result: editBioResult,
    successMessage: t('success'),
    successFunction,
    errorMessage: t('error'),
  });

  const confirmationModal = useMemo(
    () =>
      showDeleteConfirmationModal ? (
        <ConfirmationModal
          actionFunction={() => {
            editBio({ id: userId, bio_json: null });
          }}
          actionText={t('action_text')}
          heapModalName='delete-bio-modal'
          message={t('message')}
          onCloseRequest={() => {
            setShowDeleteConfirmationModal(false);
          }}
          processing={isLoading}
          secondaryButtonText={t('secondary_button_text')}
          title={t('title')}
        />
      ) : (
        <></>
      ),
    [editBio, isLoading, showDeleteConfirmationModal, userId]
  );

  const showDeleteBioModal = useCallback(
    () => setShowDeleteConfirmationModal(true),
    [setShowDeleteConfirmationModal]
  );

  return {
    showDeleteBioModal,
    confirmationModal,
  };
}
