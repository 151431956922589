export interface AutoSaveCondition {
  isSaving: boolean;
  isSavedSuccessfully: boolean;
}

export enum AutoSaveStatus {
  Saving = 'saving',
  Saved = 'saved',
  NoChanges = 'no-changes',
}
