import { useEditorCourseContext } from '../../../../../contexts/EditorCourseContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import { Navigation, NavigationResult } from '../../../../../types/Editor';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { routes } from '../../../publicApplication/applicationRouter';
import { useStepRange } from '../../hooks/useStepRange';

export const usePreviousAndNextResource = (currentStepId: number): NavigationResult => {
  const { slug } = useCurrentAccount();
  const { step } = useAccountTerminology();
  const {
    course: { adjacentCurriculumElements, steps },
  } = useEditorCourseContext();
  const { previous: previousCurriculumElement, next: nextCurriculumElement } =
    adjacentCurriculumElements;
  const elementAttributes = useCurriculumElement();
  const { isFirstStep, isLastStep, currentStepIndex } = useStepRange(steps, currentStepId);

  let previousResource: Navigation | null = null;
  let nextResource: Navigation | null = null;

  if (isFirstStep && previousCurriculumElement) {
    const { id: elementId, elementKind } = previousCurriculumElement;
    const { editRoute, termSingular } = elementAttributes({ elementKind, elementId });

    previousResource = {
      route: editRoute.href,
      term: termSingular.toLowerCase(),
    };
  } else if (currentStepIndex > 0) {
    const previousStepId = steps[currentStepIndex - 1].id;

    previousResource = {
      route: routes.editor({ id: previousStepId, slug }).href,
      term: step.singular,
    };
  }

  if (isLastStep && nextCurriculumElement) {
    const { id: elementId, elementKind } = nextCurriculumElement;
    const { editRoute, termSingular } = elementAttributes({ elementKind, elementId });

    nextResource = {
      route: editRoute.href,
      term: termSingular.toLowerCase(),
    };
  } else if (currentStepIndex < steps.length - 1) {
    const nextStepId = steps[currentStepIndex + 1].id;

    nextResource = {
      route: routes.editor({ id: nextStepId, slug }).href,
      term: step.singular,
    };
  }

  return { previous: previousResource, next: nextResource };
};
