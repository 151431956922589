import React, { useCallback, useContext, useEffect, useReducer } from 'react';

import { PaywallContext } from '../../../../../contexts/PaywallContext';
import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { formatUserOptions } from '../../../../../lib/formatUserOptions';
import { fetchQueryParameters } from '../../../../../lib/getQueryParams';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import {
  useAddTemplateToAccountMutation,
  useGetAddTemplateModalDataQuery,
} from '../../../../../redux/services/resourceApis/templates/templatesApi';
import { Sector, isValidSector, sectorsToSelectOptions } from '../../../../../types/Sector';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import Loader from '../../../../design_system/Triage/Loader';
import AddTeammateMenuList from '../../../people/AddTeammateMenuList';
import AddTeammateWithConfirmation from '../../../people/TeammateModal/AddTeammateWithConfirmation';
import RouteTo from '../../../publicApplication/routeTo';
import { FormGroup } from '../../../shared/FormGroup';
import { addTemplateFormReducer } from './reducer';
import { StyledLoaderContainer } from './styles';
import { AddTemplateFormOption, AddTemplateModalProps } from './types';

const t = initTranslations('template_preview.add_template_modal');

const AddTemplateModal = ({ templateId, showModal, closeModal }: AddTemplateModalProps) => {
  const ability = useCurrentUserAbilities();
  const paywallCtx = useContext(PaywallContext);
  const curriculumOwnershipLocked = paywallCtx.includes('curriculum_ownership');
  const canInviteUser = ability.can('create', 'User');
  const { teammateModalVisible, teammateConfirmationModalVisible } = useTeammateModal();
  const showAddTeammateWithConfirmation = teammateModalVisible || teammateConfirmationModalVisible;
  const sectorQueryParams = fetchQueryParameters().get('sector');
  let defaultSector = 'process';
  if (sectorQueryParams && isValidSector(sectorQueryParams)) {
    defaultSector = sectorQueryParams;
  }

  const initialAddTemplateFormState: AddTemplateFormOption = {
    selectedSector: defaultSector as Sector,
    selectedOwnerId: '',
  };

  const [formState, dispatch] = useReducer(addTemplateFormReducer, initialAddTemplateFormState);
  const { selectedSector, selectedOwnerId } = formState;
  const { name } = useCurrentUser();
  const { data, isLoading } = useGetAddTemplateModalDataQuery(templateId);
  const [addTemplateToAccount, result] = useAddTemplateToAccountMutation();
  const { data: addTemplateData, isLoading: isLoadingAddTemplate } = result;
  const accountTerminology = useContext(AccountTerminologyContext);

  const sectorOptions = sectorsToSelectOptions(data?.enabledSectors || [], accountTerminology);

  useEffect(() => {
    if (data?.sector && isValidSector(data.sector) && !sectorQueryParams) {
      dispatch({ type: 'selectedSectorChanged', selectedSector: data.sector });
    }
  }, [data, sectorQueryParams]);

  const sectorParams = isValidSector(selectedSector)
    ? { sector: selectedSector }
    : { sector: 'process' };

  const taskModalArgs: TaskModalProps = {
    secondaryButtonText: t('cancel'),
    title: t('modal_title'),
    onCloseRequest: closeModal,
    heapModalName: 'templates-download-modal',
    processing: isLoadingAddTemplate,
    primaryButtonText: t('add_template'),
    primaryButtonTask: () =>
      addTemplateToAccount({
        templateId,
        ownerId: selectedOwnerId,
        ...sectorParams,
      }),
    visible: !showAddTeammateWithConfirmation,
  };

  const successFunction = useCallback(() => {
    closeModal();
    !!addTemplateData ? RouteTo(addTemplateData.curriculum_url) : {};
  }, [closeModal, addTemplateData]);

  useDisplayFlashOnResponse({
    result,
    errorMessage: messageFromError(result.error)?.join(', '),
    successFunction: () => successFunction(),
    successMessage: t('success_message'),
  });

  return (
    <>
      {showModal && (
        <>
          <TaskModal {...taskModalArgs} desktopSize='lg'>
            {isLoading ? (
              <StyledLoaderContainer>
                <Loader />
              </StyledLoaderContainer>
            ) : (
              <>
                <FormGroup>
                  <SingleSelectField
                    defaultValue={selectedSector}
                    fieldLabelText={t('add_template_subtext', {
                      template: data?.title || '',
                    })}
                    labelTooltipId='add-template-tooltip'
                    labelTooltipText={t('add_template_tooltip')}
                    name='sector'
                    onNonDefaultSelected={(value: Sector) => {
                      dispatch({ type: 'selectedSectorChanged', selectedSector: value });
                    }}
                    options={sectorOptions}
                    value={selectedSector}
                  />
                </FormGroup>

                {!curriculumOwnershipLocked && (
                  <FormGroup>
                    <SingleSelectField
                      MenuList={canInviteUser ? AddTeammateMenuList : undefined}
                      className='subject-owner-select'
                      defaultValue={selectedOwnerId}
                      fieldLabelText={t('assign_owner')}
                      isClearable
                      labelTooltipId='assign-owner-tooltip'
                      labelTooltipText={t('assign_owner_tooltip')}
                      name='owner_id'
                      onNonDefaultSelected={(value: string) =>
                        dispatch({ type: 'selectedOwnerIdChanged', selectedOwnerId: value })
                      }
                      options={formatUserOptions(data?.owners || [], name)}
                      placeholder={t('select_owner')}
                      value={selectedOwnerId}
                    />
                  </FormGroup>
                )}
              </>
            )}
          </TaskModal>
          {showAddTeammateWithConfirmation && <AddTeammateWithConfirmation />}
        </>
      )}
    </>
  );
};

export default AddTemplateModal;
