import React, { FC, useState } from 'react';

import { initialDynamicOverlayValue } from './_data';
import DynamicOverlay from './DynamicOverlay';
import { DynamicOverlayContext } from './DynamicOverlayContext';
import { DynamicOverlayType, OverlayProviderProps } from './types';

const DynamicOverlayProvider: FC<OverlayProviderProps> = ({ children }) => {
  const [overlay, setOverlay] = useState<DynamicOverlayType>(initialDynamicOverlayValue);

  const openOverlay = (args: DynamicOverlayType) => setOverlay({ ...args, isOpen: true });

  const closeOverlay = () => setOverlay(initialDynamicOverlayValue);

  return (
    <DynamicOverlayContext.Provider value={{ overlay, openOverlay, closeOverlay }}>
      {children}

      {overlay?.isOpen && <DynamicOverlay />}
    </DynamicOverlayContext.Provider>
  );
};

export default DynamicOverlayProvider;
