import { useTheme } from 'styled-components';

export const useNumberSizes = () => {
  const theme = useTheme();

  const { constants } = theme;

  const COLUMN_MARGIN = parseFloat(constants.spacerMd2);
  const MINI_COLUMN_SKELETON_MARGIN = parseFloat(constants.spacerSm2);

  return {
    COLUMN_MARGIN,
    MINI_COLUMN_SKELETON_MARGIN,
  };
};
