import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Route } from 'type-route';

import initTranslations from '../../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../../redux/domains/modalsSlice/modalsSlice';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { CompletionResponse } from '../../../../../../redux/services/resourceApis/openAI/types';
import {
  surveysApi,
  useComposeQuestionsMutation,
} from '../../../../../../redux/services/resourceApis/surveys/surveysApi';
import TaskModal, { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import { SmartTestModalReducer, initialModalState } from './reducer';
import SelectCoursesForm from './SelectCoursesForm';
import { SmartTestModalDisclaimer } from './styles';
import { SmartTestModalProps, SubjectContentProps } from './types';

const t = initTranslations('editor.create_survey.smart_test_modal');

const SmartTestModal = ({ setShowModal }: SmartTestModalProps) => {
  const { flash } = useFlashNotification();
  const [modalState, dispatch] = useReducer(SmartTestModalReducer, initialModalState);
  const { selectedCourseIds } = modalState;
  const isSubmitting = false;
  const route = useRoute();
  const {
    params: { surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;
  const [triggerComposeQuestions, composeQuestionsResult] = useComposeQuestionsMutation();
  const { error: composeQuestionsError } = composeQuestionsResult;
  const [currentAiCompletion] = useState<CompletionResponse | null>(null);
  const [getSurveyData, surveyDataResult] = surveysApi.useLazyGetAiSurveyComposerModalDataQuery();
  const dispatchShowLoadingModal = useDispatchSetShowModal();

  const formatSubjectContent = (data: SubjectContentProps[]) =>
    data
      .map((subject) => {
        const subjectName = Object.keys(subject)[0];
        const steps = subject[subjectName]
          .map((step) => {
            const stepName = Object.keys(step)[0];
            return `Step "${stepName}": ${step[stepName]}`;
          })
          .join(', ');
        return `Topic "${subjectName}": ${steps}`;
      })
      .join('\n');

  const generateTrigger = useCallback(() => {
    if (surveyDataResult && surveyDataResult.data) {
      const formattedData = formatSubjectContent(surveyDataResult.data.content);

      triggerComposeQuestions({
        id: surveyId,
        userInput: formattedData,
      });
    }
  }, [surveyDataResult, triggerComposeQuestions, surveyId]);

  const handleSubmit = () => {
    getSurveyData({ id: surveyId, course_ids: selectedCourseIds });
  };

  useEffect(() => {
    if (surveyDataResult.isLoading) {
      dispatchShowLoadingModal('surveyLoadingModal', true);
    } else if (surveyDataResult.isSuccess) {
      setShowModal(false);
      generateTrigger();
    } else if (surveyDataResult.isError) {
      dispatchShowLoadingModal('surveyLoadingModal', false);
    }
  }, [surveyDataResult, dispatchShowLoadingModal, setShowModal, generateTrigger]);

  const errorMessage = useMemo(() => {
    if (composeQuestionsError) {
      return messageFromError(composeQuestionsError)?.join(', ');
    } else if (currentAiCompletion?.status === 'failed') {
      return currentAiCompletion.error_message;
    }
  }, [currentAiCompletion, composeQuestionsError]);

  useEffect(() => {
    if (errorMessage) {
      flash('error', errorMessage);
    }
    // We don't need a flash to be included in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const taskModalArgs: TaskModalProps = {
    withBetaTag: true,
    title: t('title'),
    onCloseRequest: () => setShowModal(false),
    processing: isSubmitting,
    primaryButtonText: t('auto_generate_test'),
    primaryButtonTask: handleSubmit,
    secondaryButtonText: t('cancel'),
    subtitle: t('subtitle'),
    isDisabled: isSubmitting || !selectedCourseIds.length,
    heapModalName: 'smart-test-modal',
    footerContent: <SmartTestModalDisclaimer>{t('disclaimer')}</SmartTestModalDisclaimer>,
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <SelectCoursesForm dispatch={dispatch} formData={modalState} />
    </TaskModal>
  );
};

export default SmartTestModal;
