import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetCurriculumsAndTopicsQuery } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CurriculumsAndTopics } from '../../../../../types/Curriculum';
import { ReduxResult } from '../../../../../types/Redux';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import { BaseOption } from '../../../../design_system/core/SelectOption/types';
import LoadingContainer from '../../../../design_system/LoadingContainer';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import { ElementType } from '../RestrictMoveCurriculumModal/utils';

const t = initTranslations('move_step_modal');

const setMoveStepOptions = (curriculumsAndTopics: CurriculumsAndTopics[]) => {
  const options: BaseOption[] = [];

  curriculumsAndTopics.forEach((cur) => {
    options.push({
      disabled: true,
      label: cur.title,
      value: `${cur.id}`,
      hasSubOption: false,
    });

    cur.courses.forEach((course) => {
      options.push({
        label: course.title,
        value: `${course.id}`,
        hasSubOption: true,
      });
    });
  });

  return options;
};

export interface Props {
  closeRequest: () => void;
  moveElement: (newCourseId: string | null) => void;
  result: ReduxResult;
  setWarningType: Dispatch<SetStateAction<ElementType | undefined>>;
  currentCourseId: number;
}

const MoveStepModal = ({
  closeRequest,
  moveElement,
  result,
  setWarningType,
  currentCourseId,
}: Props): ReactElement => {
  const {
    topic: { singular: topicTermSingular, plural: topicTermPlural },
    step: { singular: stepTerm },
  } = useAccountTerminology();

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('error_flash', { element: stepTerm }),
    successFunction: () => {
      result.reset();
      closeRequest();
    },
    successMessage: t('success_flash'),
  });

  const [disabled, setDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const { data, isLoading, isError, isSuccess } = useGetCurriculumsAndTopicsQuery();

  useDisplayFlashOnResponse({
    result: { isError, isLoading, isSuccess },
    errorMessage: t('load_error', { elements: topicTermPlural }),
    errorFunction: closeRequest,
  });

  if (isLoading) return <LoadingContainer />;
  if (!data) return <></>;

  const moveElementWithCheck = () => {
    let selectedCourseCanBeMoved: boolean | undefined;
    let currentCourseCanBeMoved: boolean | undefined;

    data.forEach((cur) => {
      cur.courses.forEach((course) => {
        if (course.id.toString() === selectedValue) {
          selectedCourseCanBeMoved = course.canBeMoved;
        }
        if (course.id === currentCourseId) {
          currentCourseCanBeMoved = course.canBeMoved;
        }
      });
    });

    if (!currentCourseCanBeMoved) {
      setWarningType('step_with_signed_topic');
    } else if (selectedCourseCanBeMoved) {
      moveElement(selectedValue);
    } else {
      setWarningType('step_with_signed_new_topic');
    }
  };

  const options = setMoveStepOptions(data);

  const taskModalArgs: TaskModalProps = {
    isDisabled: disabled,
    heapModalName: 'move-step-modal',
    title: t('title', { element: stepTerm }),
    onCloseRequest: closeRequest,
    processing: result.isLoading,
    primaryButtonText: t('move'),
    primaryButtonTask: moveElementWithCheck,
    secondaryButtonText: t('cancel'),
  };
  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <SingleSelectField
        className='target-course-select'
        defaultValue={null}
        fieldLabelText={t('label', { element: stepTerm })}
        isSearchable
        onDefaultSelected={() => setDisabled(true)}
        onNonDefaultSelected={(value: string) => {
          setDisabled(false);
          setSelectedValue(value);
        }}
        options={formatOptions(options)}
        placeholder={t('placeholder', { topic: topicTermSingular.toLowerCase() })}
        value={selectedValue}
      />
    </TaskModal>
  );
};

export default MoveStepModal;
