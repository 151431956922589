import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import AssignSubjectsModal from '../../AssignSubjectsModal/AssignSubjectsModal';
import { AssignedCheckableSubject, useAssignedSubjects } from './AssignedSubjectsContext';
import BulkUpdateButton from './BulkUpdateButton';

const Header = styled.div<{ checkboxesVisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* 1px border that is the same color as the background so that it aligns the select all checkbox to the rest of the checkboxes */
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.foundationSurface1}`};
  gap: ${({ theme: { constants }, checkboxesVisible }) =>
    checkboxesVisible ? constants.spacerMd2 : 0};

  @media (min-width: ${mediaBreakpointPxMd}) {
    flex-direction: row;
    padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
    gap: unset;
  }
`;

const CheckboxWrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  align-items: center;
  white-space: nowrap;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  max-height: 0;
  order: 2;
  padding-left: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    order: unset;
    padding-left: 0;
    max-height: unset;
  }
`;

const Actions = styled.div<{ isManaging: boolean }>`
  display: flex;
  flex-wrap: wrap;
  order: 1;

  ${({ isManaging }) =>
    isManaging
      ? css`
          justify-content: space-between;
        `
      : css`
          gap: ${({ theme: { constants } }) => constants.spacerSm3};
        `};

  @media (min-width: ${mediaBreakpointPxMd}) {
    order: unset;
    gap: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

const t = initTranslations('profile');

const anyTopicsSelected = (subjects: AssignedCheckableSubject[]) => {
  return subjects.some(
    (subject) =>
      subject.checkboxChecked ||
      subject.assigned_subject_elements.some((topic) => topic.checkboxChecked)
  );
};

const isAllSubjectsSelected = (subjects: AssignedCheckableSubject[]) => {
  return subjects
    .filter((subject) => !subject.disabled)
    .every((subject) => subject.checkboxChecked);
};

const AssignedSubjectsHeader = () => {
  const { checkableSubjects, selectAllSubjects, setManaging, isManaging, userId } =
    useAssignedSubjects();

  const currentlySelecting = useMemo(
    () => anyTopicsSelected(checkableSubjects),
    [checkableSubjects]
  );

  const allSelected = useMemo(() => isAllSubjectsSelected(checkableSubjects), [checkableSubjects]);

  const numSubjectsSelected = useMemo(
    () => checkableSubjects.filter((subject) => subject.checkboxChecked).length,
    [checkableSubjects]
  );

  const [showAssignSubjectsModal, setShowAssignSubjectsModal] = useState(false);

  const selectAllText = useMemo(() => {
    if (allSelected) return t('buttons.deselect_all');
    if (currentlySelecting && !allSelected && numSubjectsSelected > 0)
      return t('subjects_selection', { count: numSubjectsSelected });
    return t('buttons.select_all');
  }, [allSelected, currentlySelecting, numSubjectsSelected]);

  return (
    <Header checkboxesVisible={isManaging}>
      <CheckboxWrapper isVisible={isManaging}>
        <Checkbox
          checkboxStyle='half'
          checked={allSelected}
          id='select-all-subjects'
          label={selectAllText}
          name='select-all-subjects'
          onCheck={() => selectAllSubjects(!allSelected)}
          tooltipHoverCheckbox
          tooltipId='select-all-subjects'
          tooltipText={allSelected ? t('buttons.deselect_all') : t('buttons.select_all')}
        />
      </CheckboxWrapper>
      <Actions isManaging={isManaging}>
        {!isManaging && checkableSubjects.length > 0 && (
          <>
            <DefaultButton
              buttonType='tertiary'
              id='subjects-assigned-enter-clear-completion-mode-button'
              onClick={() => setManaging('clear-completion')}
              size='md'
              text={t('buttons.clear_completion')}
            />
            <DefaultButton
              buttonType='tertiary'
              id='subjects-assigned-enter-mark-complete-mode-button'
              onClick={() => setManaging('mark-complete')}
              size='md'
              text={t('buttons.mark_complete')}
            />
            <DefaultButton
              buttonType='tertiary'
              id='subjects-assigned-enter-unassign-subjects-mode-button'
              onClick={() => setManaging('unassign-subject')}
              size='md'
              text={t('buttons.unassign')}
            />
          </>
        )}
        {!isManaging && (
          <DefaultButton
            buttonType='primary'
            id='subjects-assigned-enter-assign-subjects-mode-button'
            onClick={() => {
              setManaging('assign-subject');
              setShowAssignSubjectsModal(true);
            }}
            size='md'
            text={t('buttons.add')}
          />
        )}
        {isManaging && (
          <>
            <DefaultButton
              buttonType='secondary'
              id='subjects-assigned-done-button'
              onClick={() => {
                selectAllSubjects(false);
                setManaging('none');
              }}
              size='md'
              text={t('buttons.done')}
            />
            <BulkUpdateButton />
          </>
        )}
        {showAssignSubjectsModal && (
          <AssignSubjectsModal
            closeModal={() => setShowAssignSubjectsModal(false)}
            userId={userId}
          />
        )}
      </Actions>
    </Header>
  );
};

export default AssignedSubjectsHeader;
