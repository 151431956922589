import { useSortable } from '@dnd-kit/sortable';
import React, { ReactNode } from 'react';

import { DragStyle } from './styles';

type Props = {
  children: ReactNode;
  id: string | number;
};

const DraggableListItemWrapper = ({ children, id }: Props) => {
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      style={DragStyle(transition, transform, isDragging)}
      {...listeners}
      {...attributes}
    >
      {children}
    </div>
  );
};

export default DraggableListItemWrapper;
