import {
  DndContext,
  DragEndEvent,
  Modifier,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { debounce, throttle } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { COLUMN_WIDTH } from '../shared/constants/sizes';
import { pxToRem } from '../shared/utils/pxToRem';
import { remToPx } from '../shared/utils/remToPx';
import { setGrabCursor } from '../shared/utils/setGrabCursor';
import { useNumberSizes } from './hooks/useNumberSizes';
import Sensor from './Sensor';
import {
  BoardControllerColumnsHolder,
  BoardControllerContainer,
  MiniColumnSkeleton,
} from './styles';
import { BoardControllerProps } from './types';
import { calculateScrollPercentage, calculateSensorPosition } from './utils';

const BoardController: FC<BoardControllerProps> = ({
  columnsCount,
  boardTrackElement,
  sensorWidthRecalculateDependencies,
}) => {
  const [sensorPositionX, setSensorPositionX] = useState(0);
  const [miniColumnsHolderElement, setMiniColumnsHolderElement] = useState<HTMLDivElement | null>(
    null
  );
  const miniColumnsHolderElementWidth = miniColumnsHolderElement?.offsetWidth || 0;
  const [sensorElement, setSensorElement] = useState<HTMLDivElement | null>(null);
  const [maxSensorPosition, setMaxSensorPosition] = useState(0);

  const { COLUMN_MARGIN, MINI_COLUMN_SKELETON_MARGIN } = useNumberSizes();

  const miniColumnSkeletonWidth = useMemo(() => {
    return (
      (miniColumnsHolderElementWidth - remToPx(MINI_COLUMN_SKELETON_MARGIN) * columnsCount) /
      columnsCount
    );
  }, [miniColumnsHolderElementWidth, columnsCount, MINI_COLUMN_SKELETON_MARGIN]);

  const visibleColumns = useMemo(() => {
    return (boardTrackElement?.clientWidth || 0) / remToPx(COLUMN_WIDTH + COLUMN_MARGIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardTrackElement?.clientWidth, COLUMN_MARGIN, sensorWidthRecalculateDependencies]);

  const sensorWidth = useMemo(() => {
    return parseInt(
      remToPx(
        (pxToRem(miniColumnSkeletonWidth) + MINI_COLUMN_SKELETON_MARGIN) * visibleColumns
      ).toString()
    );
  }, [visibleColumns, MINI_COLUMN_SKELETON_MARGIN, miniColumnSkeletonWidth]);

  const sensors = useSensors(useSensor(PointerSensor));

  const throttledContainerScroll = throttle(() => {
    if (!boardTrackElement) return;

    const { scrollLeft: scrollX, scrollWidth, clientWidth } = boardTrackElement;
    const scrollPercentage = calculateScrollPercentage(scrollX, scrollWidth, clientWidth);

    const { newSensorPositionX } = calculateSensorPosition({
      mouseX: scrollPercentage,
      miniColumnsHolderWidth: miniColumnsHolderElement?.offsetWidth || 0,
      sensorWidth,
    });

    if (sensorElement) {
      sensorElement.style.left = `${newSensorPositionX}px`;
    }

    setNewSensorPositionX(newSensorPositionX);
  }, 10);

  const handleContainerScroll = useCallback(throttledContainerScroll, [
    boardTrackElement,
    calculateScrollPercentage,
    calculateSensorPosition,
    setSensorPositionX,
    sensorElement,
    throttledContainerScroll,
  ]);

  const handleDragStart = () => {
    resetScrollObserver();
    setGrabCursor(true);
  };

  const handleDragMove = useCallback(
    (event) => {
      requestAnimationFrame(() => {
        const currentSensorPosition = sensorPositionX + event.delta.x;

        const sensorScrollProgress = +((currentSensorPosition / maxSensorPosition) * 100).toFixed();

        if (boardTrackElement) {
          boardTrackElement.scrollLeft =
            (boardTrackElement.scrollWidth - boardTrackElement.clientWidth) *
            (sensorScrollProgress / 100);
        }
      });
    },
    [sensorPositionX, maxSensorPosition, boardTrackElement]
  );

  const handleDragEnd = (event: DragEndEvent) => {
    setScrollObserver();
    setSensorPositionX((prevX) => prevX + event.delta.x);
    setGrabCursor(false);
  };

  const setNewSensorPositionX = debounce(
    (newSensorPositionX) => setSensorPositionX(newSensorPositionX),
    200
  );

  const setScrollObserver = useCallback(() => {
    boardTrackElement?.addEventListener('scroll', handleContainerScroll);
  }, [boardTrackElement, handleContainerScroll]);

  const resetScrollObserver = useCallback(() => {
    boardTrackElement?.removeEventListener('scroll', handleContainerScroll);
    handleContainerScroll.cancel();
  }, [boardTrackElement, handleContainerScroll]);

  useEffect(() => {
    setScrollObserver();

    return resetScrollObserver;
  }, [boardTrackElement, handleContainerScroll, resetScrollObserver, setScrollObserver]);

  useEffect(() => {
    if (!boardTrackElement || !miniColumnsHolderElement) return;

    setMaxSensorPosition(miniColumnsHolderElement.offsetWidth - sensorWidth);
  }, [
    boardTrackElement,
    miniColumnsHolderElement,
    sensorWidth,
    resetScrollObserver,
    setScrollObserver,
  ]);

  useEffect(() => {
    const isSensorPositionInitial = sensorPositionX === 0;

    if (isSensorPositionInitial) return;

    setTimeout(handleContainerScroll, 150);

    // We need to recalculate the sensor position only when the sensor width changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorWidth]);

  const modifiers: Modifier[] = [restrictToHorizontalAxis, restrictToParentElement];

  return (
    <DndContext
      modifiers={modifiers}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <BoardControllerContainer>
        <BoardControllerColumnsHolder ref={setMiniColumnsHolderElement}>
          {Array.from({ length: columnsCount }, (_, i) => (
            <MiniColumnSkeleton key={i} />
          ))}

          <Sensor
            sensorPositionX={sensorPositionX}
            sensorWidth={sensorWidth}
            setSensorElement={setSensorElement}
          />
        </BoardControllerColumnsHolder>
      </BoardControllerContainer>
    </DndContext>
  );
};

export default BoardController;
