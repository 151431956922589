import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import useWindowResize from '../../../../hooks/useWindowResize';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Poppable from '../../../Poppable';
import OutsideClickHandler from '../OutsideClickHandler';

const RemoveButton = styled(DefaultButton)`
  position: absolute;
  bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  right: ${({ theme: { constants } }) => constants.spacerMd2};
  z-index: 1000;
`;

type CurrentEmojiProps =
  | {
      removeButtonAction: () => void;
      emojiPresent: string | null;
    }
  | {
      removeButtonAction?: () => void;
      emojiPresent?: never;
    };

export type Props = {
  ariaLabel?: string;
  onEmojiSelect?: (emoji: BaseEmoji) => void;
  perLine?: number;
  onClickOutside: (e: MouseEvent & { target: Element }) => void;
  onClick: () => void;
  isOpen: boolean;
  trigger: JSX.Element;
  usePortal?: boolean;
} & CurrentEmojiProps;

const t = initTranslations('emoji_picker');

const EmojiPicker = ({
  onEmojiSelect,
  perLine,
  onClick,
  onClickOutside,
  isOpen,
  removeButtonAction,
  trigger,
  usePortal = false,
  emojiPresent,
  ariaLabel,
}: Props) => {
  const { mode } = useTheme();
  const { isExtraSmWindow } = useWindowResize();
  return (
    <Poppable
      isOpen={isOpen}
      item={
        <OutsideClickHandler onOutsideClick={(e) => onClickOutside(e)}>
          <Picker
            ariaLabel={ariaLabel || t('default_aria_label')}
            autoFocus
            data={data}
            onClickOutside={onClickOutside}
            onEmojiSelect={onEmojiSelect}
            perLine={isExtraSmWindow ? 8 : perLine}
            previewEmoji={emojiPresent}
            previewPosition='bottom'
            skinTonePosition='search'
            theme={mode}
          />
          {emojiPresent && (
            <RemoveButton
              buttonType='tertiary'
              id='remove-emoji-button'
              onClick={removeButtonAction}
              text={t('remove')}
            />
          )}
        </OutsideClickHandler>
      }
      menuStyling={{ zIndex: 101 }}
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['right'],
          },
        },
      ]}
      onClick={onClick}
      placement='bottom-start'
      trigger={trigger}
      usePortal={usePortal}
    />
  );
};

export default EmojiPicker;
