import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';

export const PickerContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
  max-width: 20rem;
`;

export const SimplePalettePickerWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const SwatchSquareWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SwatchSquare = styled.div<{ color: string }>`
  width: 3.25rem;
  height: 3.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;
