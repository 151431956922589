import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import VideoPreview from '../../../../../shared/VideoEmptyState/VideoPreview/VideoPreview';
import { AddonSectionTitle } from '../styles';
import { PreviewExperienceItemsWrapper, PreviewExperienceWrapper } from './styles';

const t = initTranslations('simple_pricing.addon_overlay_content');

type PreviewExperienceProps = {
  overviewVideoLinks: string[];
  videoPreviewSrc: string[];
  openPlayerCallback: () => void;
  closePlayerCallback: () => void;
};

const PreviewExperience = ({
  overviewVideoLinks,
  videoPreviewSrc,
  openPlayerCallback,
  closePlayerCallback,
}: PreviewExperienceProps) => {
  return (
    <PreviewExperienceWrapper id='addon-overlay-preview-experience'>
      <AddonSectionTitle>{t('preview_experience_title')}</AddonSectionTitle>
      <PreviewExperienceItemsWrapper>
        {overviewVideoLinks.map((videoLink, index) => (
          <VideoPreview
            closePlayerCallback={closePlayerCallback}
            key={index}
            openPlayerCallback={openPlayerCallback}
            size='xs'
            videoPreviewSrc={videoPreviewSrc[index]}
            videoUrl={videoLink}
          />
        ))}
      </PreviewExperienceItemsWrapper>
    </PreviewExperienceWrapper>
  );
};

export default PreviewExperience;
