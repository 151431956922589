import { IconName } from '@fortawesome/fontawesome-svg-core';
import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import Icon from '../../../../design_system/display/icons/Icon/Icon';
import { IconWeight } from '../../../../design_system/display/icons/Icon/IconTypes';
import { fontSm5 } from '../../../../styled/TypeSystem';
import EmojiPicker from '../../../shared/EmojiPicker';

const ClickableWrapper = styled.div`
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 1rem;
`;

const Emoji = styled.span`
  ${fontSm5};
`;

// DS Override: Update icon color to match designs
const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

type Props = {
  emoji?: string | null;
  onEmojiSelect: (emoji: BaseEmoji) => void;
  clearEmoji: () => void;
  usePortal?: boolean;
  noEmojiIconName: IconName;
  noEmojiIconWeight?: IconWeight;
};

const EmojiSelect = ({
  emoji,
  onEmojiSelect,
  clearEmoji,
  usePortal = false,
  noEmojiIconName,
  noEmojiIconWeight = 'regular',
}: Props) => {
  const emojiPickerAccess = useIsAdmin();
  const [showPicker, setShowPicker] = useState(false);

  const handleEmojiSelect = (selectedEmoji: BaseEmoji) => {
    onEmojiSelect(selectedEmoji);
    setShowPicker(false);
  };

  const currentEmoji = useMemo(() => {
    return (
      <Wrapper>
        {emoji ? (
          <Emoji>{emoji}</Emoji>
        ) : (
          <StyledIcon name={noEmojiIconName} size='xs' weight={noEmojiIconWeight} />
        )}
      </Wrapper>
    );
  }, [emoji, noEmojiIconName, noEmojiIconWeight]);

  return (
    <div>
      {emojiPickerAccess ? (
        <EmojiPicker
          emojiPresent={emoji}
          isOpen={showPicker}
          onClick={() => setShowPicker(!showPicker)}
          onClickOutside={() => setShowPicker(false)}
          onEmojiSelect={handleEmojiSelect}
          removeButtonAction={clearEmoji}
          trigger={<ClickableWrapper>{currentEmoji}</ClickableWrapper>}
          usePortal={usePortal}
        />
      ) : (
        currentEmoji
      )}
    </div>
  );
};

export default EmojiSelect;
