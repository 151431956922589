import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import { routes } from '../../../publicApplication/applicationRouter';
import { Owner } from '../../activities/types';
import NotificationUserName from '../NotificationUserName/NotificationUserName';
import { NotificationLink, TextFragment } from '../styles';

const t = initTranslations('notifications');

export type UserOverdueCurriculumsProps = {
  activityKey: string;
  owner: Owner;
  parameters: { assignment_ids: number[]; curriculum_id: number };
};

const OverdueCurriculumsTemplate = ({
  activityKey,
  owner,
  parameters,
}: UserOverdueCurriculumsProps) => {
  const { curriculum } = useContext(AccountTerminologyContext);
  const { slug } = useCurrentAccount();

  return (
    <>
      <NotificationUserName user={owner} />
      <TextFragment>{t('user.due_date.has')}</TextFragment>
      <NotificationLink to={routes.curriculumShow({ slug, id: parameters.curriculum_id })}>
        {t(activityKey, {
          count: parameters.assignment_ids.length,
          curriculum: curriculum.singular.toLowerCase(),
        })}
      </NotificationLink>
    </>
  );
};

export default OverdueCurriculumsTemplate;
