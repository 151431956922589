import { AddonName } from '../../types/Addon';
import ESignaturePreviewSrc1 from '../../videos/Unlimited-E-signature-Preview-1.mp4';
import ESignaturePreviewSrc2 from '../../videos/Unlimited-E-signature-Preview-2.mp4';

export function getAddonOverlayVideoPreview(addonName: AddonName) {
  switch (addonName) {
    case 'e_signature':
      return [ESignaturePreviewSrc1, ESignaturePreviewSrc2];
  }
}
