import React from 'react';
import { Route } from 'type-route';

import initTranslations from '../../../../../lib/initTranslations';
import { SurveyQuestionError } from '../../../../../redux/services/resourceApis/surveys/types';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import { ErrorPreviewContainer, ErrorPreviewMessage, ErrorPreviewTitleLink } from './styles';

const t = initTranslations('editor.create_survey.errors_preview');

const SurveyQuestionErrorPreview = ({ error }: { error: SurveyQuestionError }) => {
  const route = useRoute();
  const {
    params: { slug, surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;

  return (
    <ErrorPreviewContainer>
      <ErrorPreviewTitleLink
        to={routes.surveyQuestionEditor({ slug, surveyId, id: error.questionId })}
      >
        {t('question', { position: error.questionPosition })}
      </ErrorPreviewTitleLink>
      {error.errorKeys.map((key) => (
        <ErrorPreviewMessage key={`question-error-message-${key}`}>{t(key)}</ErrorPreviewMessage>
      ))}
    </ErrorPreviewContainer>
  );
};

export default SurveyQuestionErrorPreview;
