import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction } from 'react';

import { usePlanBrandColors } from '../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../hooks/billing/usePlanTitle';
import { couponToDiscount } from '../../../../../../lib/billing/stripeCouponToDiscount';
import {
  getFormattedPrice,
  getFormattedPricePerUser,
} from '../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../lib/initTranslations';
import {
  CurrentPerUserPlan,
  NewPerUserPlan,
} from '../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import { AddonItems } from '../../../../../../redux/services/resourceApis/billing/types';
import { Coupon } from '../../../../../../types/Coupon';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { OverlayContentHeaderTitle } from '../../../../shared/FullScreenOverlay/styles';
import AddonsSection from '../../../shared/AddonsSection/AddonsSection';
import {
  AdditionalInfo,
  AddonsSectionWrapper,
  Divider,
  DueTodayWrapper,
  PlanCreditTitle,
  PlanCreditTitleWrapper,
  PlanCreditWrapper,
  PlanName,
  PlanPrice,
  StyledIcon,
  SummaryWrapper,
  TooltipIconWrapper,
} from '../../../shared/BillingFullScreenOverlay/Summary/styles';
import { PerBillingPeriodText } from '../../../shared/BillingPage/BillingPagePlans/styles';
import PromoCodeField from '../../../shared/PromoCodeField/PromoCodeField';
import { PlanNameWrapper, PlanPriceWrapper } from '../../../shared/styles';
import { isCurrentPlanSameAsNewOne } from '../../utils/isCurrentPlanSameAsNewOne';

const t = initTranslations('billing.full_screen_overlay_content.summary');

type SummaryProps = {
  addonItems: AddonItems;
  newPlanData: NewPerUserPlan;
  coupon: Coupon;
  currentPlan: CurrentPerUserPlan;
  currentDate: Date;
  isConfigurePlan?: boolean;
  isMaxSeatsCount: boolean;
  setAppliedPromoCode?: Dispatch<SetStateAction<string>>;
  appliedPromoCode?: string;
};

const Summary = ({
  addonItems,
  newPlanData,
  coupon,
  currentDate,
  currentPlan,
  isConfigurePlan = false,
  isMaxSeatsCount,
  setAppliedPromoCode,
  appliedPromoCode,
}: SummaryProps) => {
  const formattedCurrentDate = dayjs(currentDate).format('MMM D'); // e.g. Jul 1
  const { name, dueToday, nextPaymentDate, credit, seatsIncluded, totalPrice, billingInterval } =
    newPlanData;
  const discount = couponToDiscount(coupon);
  const isAnnualInterval = billingInterval === 'year';
  const showDueTodaySection =
    !isMaxSeatsCount &&
    (!isCurrentPlanSameAsNewOne({
      currentPlan,
      newPlan: newPlanData,
    }) ||
      isConfigurePlan);
  const showCreditSection = !isMaxSeatsCount && credit < 0;
  const { color: planBrandColor } = usePlanBrandColors({ name });
  const planTitle = usePlanTitle({ name });

  return (
    <SummaryWrapper>
      <OverlayContentHeaderTitle withBottomMargin>{t('title')}</OverlayContentHeaderTitle>
      <PlanNameWrapper>
        <PlanName planBrandColor={planBrandColor}>{planTitle}</PlanName>
        {seatsIncluded && (
          <PlanPriceWrapper className='full-screen-overlay-summary-price-per-user'>
            <PlanPrice>
              {getFormattedPricePerUser(totalPrice, seatsIncluded, isAnnualInterval, discount)}
            </PlanPrice>
            <PerBillingPeriodText>{t('user_per_month')}</PerBillingPeriodText>
          </PlanPriceWrapper>
        )}
      </PlanNameWrapper>
      {showCreditSection && (
        <PlanCreditWrapper>
          <PlanCreditTitleWrapper>
            <PlanCreditTitle>{t('plan_credit')}</PlanCreditTitle>
            <TooltipIconWrapper data-for='plan-credit-tooltip' data-tip>
              <StyledIcon name='circle-info' weight='regular' />
            </TooltipIconWrapper>
            <Tooltip id='plan-credit-tooltip' place='bottom' text={t('plan_credit_tooltip_text')} />
          </PlanCreditTitleWrapper>
          <span>{getFormattedPrice(credit)}</span>
        </PlanCreditWrapper>
      )}
      {addonItems && (
        <>
          <Divider />
          <AddonsSectionWrapper>
            <AddonsSection addonItems={addonItems} />
          </AddonsSectionWrapper>
        </>
      )}
      {showDueTodaySection && (
        <>
          <Divider />
          <DueTodayWrapper>
            <span>{t('due_today')}</span>
            <span>
              {getFormattedPrice(dueToday)}
              {dueToday > 0 && <AdditionalInfo>{t('plus_taxes')}</AdditionalInfo>}
            </span>
          </DueTodayWrapper>
          <AdditionalInfo>
            {formattedCurrentDate} – {nextPaymentDate}
          </AdditionalInfo>
        </>
      )}
      {setAppliedPromoCode && (
        <PromoCodeField
          appliedPromoCode={appliedPromoCode}
          couponName={coupon?.name}
          setAppliedPromoCode={setAppliedPromoCode}
        />
      )}
    </SummaryWrapper>
  );
};

export default Summary;
