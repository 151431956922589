import { truncate } from 'lodash';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { StyledBadge, TemplateRolesWrapper } from './styles';

const t = initTranslations('template_preview.info');

const SHOWED_ROLES_COUNT = 3;

type TemplateRolesProps = {
  roles: string[];
};

const TemplateRoles = ({ roles }: TemplateRolesProps) => {
  const rolesCount = roles.length;
  const showedRoles = roles.slice(0, SHOWED_ROLES_COUNT);
  const slicedRoles = roles.slice(SHOWED_ROLES_COUNT);
  const tooltipText = slicedRoles.join(', ');
  const slicedRolesCount = slicedRoles.length;

  return (
    <TemplateRolesWrapper>
      {t('roles')}
      {showedRoles.map((role_name, index) => (
        <StyledBadge key={index} text={truncate(role_name)} type='general' />
      ))}
      {rolesCount > SHOWED_ROLES_COUNT && (
        <>
          <Tooltip id='template-roles-tooltip' text={tooltipText} />
          <div data-for='template-roles-tooltip' data-tip>
            <StyledBadge text={`+${slicedRolesCount}`} type='general' />
          </div>
        </>
      )}
    </TemplateRolesWrapper>
  );
};

export default TemplateRoles;
