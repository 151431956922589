import styled from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';

export const FlyoutTextWrapper = styled.div<{ reverse: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  margin-bottom: ${({ theme }) => theme.constants.spacerMd1};
  ${fontSm5};
`;

export const FlyoutText = styled.p`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-bottom: ${({ theme }) => theme.constants.spacerSm3};
`;

export const FlyoutSubtext = styled.p`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-bottom: ${({ theme }) => theme.constants.spacerSm3};
`;
