import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import Separator from '../../../../../images/checkout_overlay/separator.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { SimplePricingBillingPlanName } from '../../../../../types/BillingPlanName';
import {
  CheckoutImageContainer,
  CheckoutLogosWrapper,
  CheckoutPartnerLogo,
  CheckoutTrainualLogo,
} from './styles';

type CheckoutLogosProps = {
  trainualLogoSrc: string;
  planName: SimplePricingBillingPlanName;
};

const t = initTranslations('checkout_overlay.logo_alt');

const CheckoutLogos = ({ trainualLogoSrc, planName }: CheckoutLogosProps) => {
  const { logoUrl, withDefaultLogo } = useCurrentAccount();

  return (
    <CheckoutLogosWrapper>
      <CheckoutImageContainer>
        <CheckoutTrainualLogo
          alt={t('plan_name', { planName })}
          src={trainualLogoSrc}
          withDefaultLogo={withDefaultLogo}
        />
      </CheckoutImageContainer>

      {!withDefaultLogo && (
        <>
          <img alt={t('separator')} src={Separator} />
          <CheckoutImageContainer>
            <CheckoutPartnerLogo alt={t('partner')} src={logoUrl} />
          </CheckoutImageContainer>
        </>
      )}
    </CheckoutLogosWrapper>
  );
};

export default CheckoutLogos;
