import React, { FC } from 'react';
import { Route } from 'type-route';

import initTranslations from '../../../../../lib/initTranslations';
import { useDeleteBoardColumnMutation } from '../../../../../redux/services/resourceApis/boardColumns/boardColumnsApi';
import ThreeDotMenu from '../../../../design_system/Triage/menus/ThreeDotMenu';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import useAutoSaveStatusUpdater from '../../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import { BoardColumnThreeDotProps } from './types';

const t = initTranslations('delegation_planner.board_column_tools.three_dot_menu');

const BoardColumnThreeDot: FC<BoardColumnThreeDotProps> = ({ menuId, columnId }) => {
  const {
    params: { id: boardId },
  } = useRoute() as Route<typeof routes.delegationPlannerBoard>;
  const [deleteBoardColumn, deleteBoardColumnResult] = useDeleteBoardColumnMutation();
  const { isLoading, isSuccess } = deleteBoardColumnResult;

  useAutoSaveStatusUpdater([{ isSaving: isLoading, isSavedSuccessfully: isSuccess }]);

  const handleColumnDelete = async () => {
    if (boardId && columnId) await deleteBoardColumn({ boardId, id: columnId });
  };

  return (
    <ThreeDotMenu
      id={menuId}
      menuOptions={[
        {
          title: t('remove_column'),
          onClick: handleColumnDelete,
          iconName: 'trash-alt',
          id: 'td-delegation-board-column-remove',
          visible: true,
        },
      ]}
      menuPlacement='bottom-end'
    />
  );
};

export default BoardColumnThreeDot;
