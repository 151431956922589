import { debounce } from 'lodash';
import { useEffect } from 'react';

import {
  setLastAddedColumnableId,
  setShouldScrollBoardLeft,
  setShouldScrollToCurrentResponsibility,
} from '../../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { UseBoardUIEffects } from '../types';

export const useBoardUIEffects = ({
  boardTrackHasScroll,
  boardTrackElement,
  handleResize,
  isLoading,
  isFetching,
}: UseBoardUIEffects) => {
  const dispatch = useAppDispatch();
  const { shouldScrollBoardLeft, currentBoardColumnResponsibility, isFullscreenModeEnabled } =
    useAppSelector((state) => state.delegationPlanner);

  useEffect(() => {
    if (boardTrackHasScroll && shouldScrollBoardLeft) {
      boardTrackElement?.scrollTo({
        left: boardTrackElement?.scrollWidth,
        behavior: 'smooth',
      });
      dispatch(setShouldScrollBoardLeft(false));
      dispatch(setLastAddedColumnableId(null));
    }
  }, [boardTrackElement, boardTrackHasScroll, dispatch, shouldScrollBoardLeft]);

  const debouncedResizeHandler = debounce(handleResize, 100);

  useEffect(() => {
    window.addEventListener('resize', debouncedResizeHandler);

    return () => {
      window.removeEventListener('resize', debouncedResizeHandler);
    };
  }, [handleResize, debouncedResizeHandler]);

  useEffect(() => {
    if (isLoading) return;

    debouncedResizeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreenModeEnabled]);

  useEffect(() => {
    if (!(isLoading || isFetching) && !!currentBoardColumnResponsibility?.isPositionChanged) {
      dispatch(setShouldScrollToCurrentResponsibility(true));
    }
  }, [currentBoardColumnResponsibility?.isPositionChanged, dispatch, isFetching, isLoading]);
};
