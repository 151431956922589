import initTranslations from '../initTranslations';

const t = initTranslations('template_preview.top_nav_bar.back_button');

type getTemplateBackButtonTextArgs = string | undefined;

export function getTemplateBackButtonText(routeName: getTemplateBackButtonTextArgs) {
  const backButtonText = () => {
    switch (routeName) {
      case 'search':
        return t('back_to_search');
      case 'templates':
      case undefined:
        return t('back_to_templates');
      default:
        return t('back');
    }
  };
  return backButtonText();
}
