import { HierarchyNode } from 'd3-hierarchy';
import React, { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { RenderCustomNodeElementFn } from 'react-d3-tree/lib/types/common';
import { isMobile } from 'react-device-detect';
import { SaguaroTheme } from 'saguaro';
import styled, { createGlobalStyle } from 'styled-components';

import { OrgChartData, OrgChartTranslationPrefix } from '../../../../types/OrgChart';
import { ZoomState } from './ZoomControl/OrgChartZoomControl';

import Timeout = NodeJS.Timeout;

const FIT_TO_ZOOM_DELAY = 500;

const OrgChartGlobalStyle = createGlobalStyle<{ theme: SaguaroTheme }>`
  .node-foreign-object-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .org-chart-link {
    &:hover {
     text-decoration: none;
    }
  }
  
  .rd3t-link {
    stroke-width: ${({ theme: { constants } }) => constants.borderWidthLg};
    stroke: lightgrey !important;
  }
`;
const OrgChartContainer = styled.div`
  height: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  .svg-chart-container {
    cursor: grab !important;
    &:active:hover {
      cursor: grabbing !important;
    }
    width: 100% !important;
    height: 100% !important;
  }
`;
interface OrgChartSharedBodyProps<DatumType> {
  canUpdateOrgChart: boolean;
  data: DatumType | undefined;
  isLoading: boolean;
  zoomState: ZoomState;
  translationPrefix: OrgChartTranslationPrefix;
  buttonView?: (data: HierarchyNode<DatumType>) => string;
  cardView: RenderCustomNodeElementFn;
  NoResultsComponent: JSX.Element;
  cardSize: { width: number; height: number };
  //resetOrgChart, downloadOrgChart, searchOrgChartUser & setSearchOrgChartUser only need to be optional as long as the GroupChart is not using them
  resetOrgChart?: () => void;
  searchOrgChartUser?: string | null;
  setSearchOrgChartUser?: React.Dispatch<React.SetStateAction<string | null>>;
  orgChartContainerRef: React.RefObject<HTMLDivElement>;
  downloadOrgChart?: () => void;
}

const OrgChartSharedBody = function <DatumType extends OrgChartData>({
  cardView,
  cardSize,
  data,
  isLoading,
  NoResultsComponent,
  zoomState,
  searchOrgChartUser,
  setSearchOrgChartUser,
  orgChartContainerRef,
  resetOrgChart,
  downloadOrgChart,
}: OrgChartSharedBodyProps<DatumType>) {
  const [zoom, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const orgChartTopPadding = 20;
  const [orgChartUpdateTimeout, setOrgChartUpdateTimeout] = useState<Timeout | undefined>();

  // This is a way for us to communicate when the org chart is updating. This is mostly useful for our spec coverage.
  useEffect(() => {
    orgChartContainerRef?.current?.classList.add('org-chart-updating');
    orgChartContainerRef?.current?.classList.remove('org-chart-stable');

    if (orgChartUpdateTimeout) {
      clearTimeout(orgChartUpdateTimeout);
    }

    const newTimeout = setTimeout(() => {
      orgChartContainerRef?.current?.classList.add('org-chart-stable');
      orgChartContainerRef?.current?.classList.remove('org-chart-updating');
    }, 500);

    setOrgChartUpdateTimeout(newTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgChartContainerRef, position, zoom]);

  // The center point of the node is not the center point of the visible card. We need an offset to
  // adjust the link paths so they intercept the card in the middle.
  const yCardMidpointOffset = -12;
  // This constant will adjust how the links visually intercept the collapse button
  const yLinkAdjustment = 15;
  // We only want to center the org chart if there's a change from no data (null or undefined)
  // We cannot use `data` in the dependencies directly because this will break centering on a card directly
  const hasData = !!data;

  // Required to center the chart initially.
  useEffect(() => {
    if (orgChartContainerRef?.current) {
      const { width } = orgChartContainerRef.current.getBoundingClientRect();
      setPosition({ x: width / 2, y: orgChartTopPadding });
    }
  }, [hasData, orgChartContainerRef]);

  useEffect(() => {
    const { action } = zoomState;
    let fitUserTimeout: Timeout | undefined;
    let fitPositionTimeout: Timeout | undefined;
    let fitZoomTimeout: Timeout | undefined;
    let zoomValue = 0;

    if (!action) return;

    if (['increment', 'decrement'].includes(action)) {
      zoomValue = zoomState.action === 'increment' ? 0.3 : -0.3;

      setSearchOrgChartUser && setSearchOrgChartUser(null);
      setZoomLevel((z) => z + zoomValue);
    } else if (['fit', 'fitToPrint'].includes(action)) {
      const isFitToPrintAction = action === 'fitToPrint';

      // If a user was searched/centered on, clear it
      setSearchOrgChartUser && setSearchOrgChartUser(null);
      isFitToPrintAction && resetOrgChart && resetOrgChart();

      // We need to delay fit to zoom to allow transition animations to complete prior to calculating the sizes
      fitZoomTimeout = setTimeout(() => {
        if (orgChartContainerRef?.current) {
          const boundingClientRect = orgChartContainerRef.current.getBoundingClientRect();
          const svgBoundingRect = orgChartContainerRef.current
            .querySelector('g')
            ?.getBoundingClientRect();

          if (svgBoundingRect) {
            const horizontalRatio = boundingClientRect.width / svgBoundingRect.width;
            const verticalRatio = boundingClientRect.height / svgBoundingRect.height;
            const horizontallyConstrained = horizontalRatio < verticalRatio;

            if (horizontallyConstrained) {
              setZoomLevel(
                (z) =>
                  boundingClientRect.width / (svgBoundingRect.width / z + 2 * orgChartTopPadding)
              );
            } else {
              setZoomLevel(
                (z) =>
                  boundingClientRect.height / (svgBoundingRect.height / z + 2 * orgChartTopPadding)
              );
            }
          }
        }
      }, FIT_TO_ZOOM_DELAY);

      // We need to delay fit to zoom to allow transition animations to complete prior to calculating the sizes
      fitPositionTimeout = setTimeout(() => {
        if (orgChartContainerRef?.current) {
          const boundingClientRect = orgChartContainerRef.current.getBoundingClientRect();
          const svgBoundingRect = orgChartContainerRef.current
            .querySelector('g')
            ?.getBoundingClientRect();

          if (svgBoundingRect) {
            const currentX = position.x;
            const offsetToSvgX = svgBoundingRect.x - boundingClientRect.x;
            const offsetToSvgCenterX = boundingClientRect.width / 2 - svgBoundingRect.width / 2;
            const newPos = currentX - offsetToSvgX + offsetToSvgCenterX;

            setPosition({
              x: newPos,
              y: orgChartTopPadding,
            });
          }

          isFitToPrintAction && downloadOrgChart && downloadOrgChart();
        }
      }, FIT_TO_ZOOM_DELAY);
    } else if (action === 'user') {
      resetOrgChart && resetOrgChart();

      // We need to delay to allow transition animations to complete prior to calculating the sizes
      fitUserTimeout = setTimeout(() => {
        if (orgChartContainerRef?.current && searchOrgChartUser) {
          setZoomLevel(2);

          const node = document.getElementById(`user-card-${searchOrgChartUser}`)?.parentElement;

          if (node) {
            const boundingClientRect = orgChartContainerRef.current.getBoundingClientRect();
            const nodeBoundingRect = node.getBoundingClientRect();
            const currentX = position.x;
            const currentY = position.y;
            const offsetToNodeX = nodeBoundingRect.x - boundingClientRect.x;
            const offsetToNodeY = nodeBoundingRect.y - boundingClientRect.y;
            const offsetToCenterNodeX = boundingClientRect.width / 2 - nodeBoundingRect.width / 2;
            const offsetToCenterNodeY = boundingClientRect.height / 2 - nodeBoundingRect.height / 2;

            setPosition({
              x: currentX - offsetToNodeX + offsetToCenterNodeX,
              y:
                currentY -
                offsetToNodeY +
                offsetToCenterNodeY -
                // This is used because the top of the node has 1.5rem padding and bottom has 2.5rem padding, and it looks better to be a little higher since the orgChart page has a header above as well
                16 * 2,
            });
          }
        }
      }, FIT_TO_ZOOM_DELAY);
    }

    return () => {
      fitPositionTimeout && clearTimeout(fitPositionTimeout);
      fitZoomTimeout && clearTimeout(fitZoomTimeout);
      fitUserTimeout && clearTimeout(fitUserTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgChartContainerRef, resetOrgChart, searchOrgChartUser, zoomState]);

  if (isLoading) return <></>;
  if (!data) return NoResultsComponent;

  return (
    <>
      <OrgChartGlobalStyle />
      <OrgChartContainer className='orgchart-container' ref={orgChartContainerRef}>
        <Tree
          collapseChildrenOnToggle={false}
          collapsible
          compact
          compactLayout={{
            childrenMargin: () => 0,
            siblingsMargin: () => 80,
            compactMarginBetween: () => 0,
            compactLinkMidY: (node) => {
              const firstCompactNode = node.data.__rd3t.compact.firstCompactNode;
              return firstCompactNode
                ? firstCompactNode.y + node.height / 2 + yCardMidpointOffset
                : 0;
            },
            linkParentY: (node) => node.y + node.height / 2 + yLinkAdjustment,
            linkY: (node) => node.y + node.height / 2 + yCardMidpointOffset,
            linkCompactXStart: (node) => node.x,
            linkCompactYStart: (node) => node.y + node.height / 2 + yCardMidpointOffset,
          }}
          data={data}
          enableLegacyTransitions
          nodeSize={{ y: cardSize.height, x: cardSize.width }}
          onUpdate={({ zoom, translate }) => {
            setZoomLevel(zoom);
            setPosition(translate);
          }}
          orientation='vertical'
          pathFunc='rounded-step'
          renderCustomNodeElement={cardView}
          scaleExtent={{ min: 0.1, max: 2 }}
          shouldCollapseNeighborNodes={false}
          translate={position}
          zoom={zoom}
          zoomable={isMobile}
        />
      </OrgChartContainer>
    </>
  );
};
export default OrgChartSharedBody;
