import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { ShareDirectorySectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const ShareDirectorySection = ({ enablePeopleDirectory, id }: ShareDirectorySectionProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('people_directory_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={enablePeopleDirectory}
            className='people-directory-setting'
            handleToggle={(e) => updateAccountData({ enable_people_directory: e.target.checked })}
            id='enable-people-directory'
            name='enable_people_directory'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('people_directory_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default ShareDirectorySection;
