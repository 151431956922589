import React from 'react';
import { GetStepResponse } from 'redux/services/resourceApis/steps/types';
import { LimitedStep } from 'types/Step';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import useContentStyles from '../../../../../hooks/useContentStyles';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import StepHeading from '../../components/StepHeading/StepHeading';
import { useStepRange } from '../../hooks/useStepRange';
import { ContentBlock, StepContentBody, StyledEditorContent, StyledHr } from '../../shared/styles';
import NavigationButtons from '../components/NavigationButtons/NavigationButtons';
import ReadOnlyTitle from '../components/ReadOnlyTitle/ReadOnlyTitle';
import { StepTitleWrapper } from './Editor';

type Props = {
  step: GetStepResponse;
  courseSteps: LimitedStep[];
};

const StepContent = ({ courseSteps, step }: Props) => {
  const {
    step: { singular: stepSingular },
  } = useAccountTerminology();
  const contentStyles = useContentStyles();
  const { editor } = useEditorContext();
  const { isLastStep, stepPosition } = useStepRange(courseSteps, step.id);

  return (
    <ContentBlock hasPaddingBottom={isLastStep}>
      <StepHeading
        currentItem={stepPosition}
        rangeTitle={stepSingular}
        totalItems={courseSteps.length}
      />
      <StepContentBody className='step-content' isLastStep={isLastStep}>
        <ContentBlock>
          <StepTitleWrapper>
            <ReadOnlyTitle emoji={step.emoji} resource='step' title={step.title} />
          </StepTitleWrapper>
          <StyledHr />
          <StyledEditorContent
            $contentStyles={contentStyles}
            className='editor-content'
            editor={editor}
          />
        </ContentBlock>
        <NavigationButtons stepId={step.id} />
      </StepContentBody>
    </ContentBlock>
  );
};

export default StepContent;
