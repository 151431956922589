import React from 'react';

import { useGroupsIndex } from '../../../../contexts/GroupsIndexContext';
import DarkNoResultsImage from '../../../../images/searches_empty_state_dark.svg';
import LightNoResultsImage from '../../../../images/searches_empty_state.svg';
import initTranslations from '../../../../lib/initTranslations';
import NoResults from '../../../design_system/Triage/NoResults';

const t = initTranslations('groups');

const GroupsTableNoResults = () => {
  const { dispatch } = useGroupsIndex();

  return (
    <NoResults
      className='groups-members-table-no-results'
      darkImage={DarkNoResultsImage}
      heading={t('no_results_found')}
      iconWidth='20%'
      lightImage={LightNoResultsImage}
      minHeight='unset'
      showBorder={false}
      subHeaderCta={{
        action: () => dispatch({ type: 'resetState' }),
        text: t('no_results_advice'),
      }}
    />
  );
};

export default GroupsTableNoResults;
