import { useFormik } from 'formik';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import * as yup from 'yup';

import { useCheckManageSignatureAbility } from '../../../../hooks/curriculum/useCheckManageSignatureAbility';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { RegisteredId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { sanitizeInput } from '../../../../lib/sanitize';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Icon from '../../../design_system/display/icons/Icon';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { InputProps } from '../../../design_system/Triage/InputField';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { StyledErrorMessage } from '../../../styled/Inputs';
import { fontSm4 } from '../../../styled/TypeSystem';
import PaywallButton from '../../curriculums/public_share/PaywallButton';
import CallToActionElementWrapper from '../../home/Onboarding/CallToActionElementWrapper/CallToActionElementWrapper';
import { useRoute } from '../../publicApplication/applicationRouter';
import DropdownWithPoppableMenu from '../DropdownWithPoppableMenu';
import ESignatureToggle from '../ESignatureToggle/ESignatureToggle';

const Container = styled.div<{
  withBorder?: boolean;
  isAdminPage: boolean;
  showSignatureToggle?: boolean;
}>(
  ({ theme: { constants, vars }, isAdminPage, withBorder, showSignatureToggle }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 3.5rem;
    border-radius: ${isAdminPage ? constants.borderRadiusMd : constants.borderRadiusLg};
    border: ${withBorder ? `${constants.borderRadiusXs} solid ${vars.borderSurface1}` : 'none'};
    background-color: ${vars.foundationSurface1};
    transition: box-shadow 0.3s;
    padding: ${constants.spacerSm3};
    padding-left: ${constants.spacerMd2};
    gap: ${showSignatureToggle ? constants.spacerSm3 : constants.spacerMd2};

    @media (min-width: ${mediaBreakpointPxSm}) {
      flex-direction: row;
    }
  `
);

const ErrorMessage = styled(StyledErrorMessage)(
  ({ theme: { constants } }) => css`
    display: inline-block;
    margin: ${constants.spacerSm3};
  `
);

const StyledCharacterCount = styled.span<{ hasError: boolean }>`
  display: none;

  @media (min-width: ${mediaBreakpointPxSm}) {
    display: block;
    align-self: center;
    white-space: nowrap;
    color: ${({ theme: { vars }, hasError }) => (hasError ? vars.stateError : vars.textDefault)};
    ${fontSm4};
  }
`;

const StyledInput = styled.input<{
  inputFocused?: boolean;
  withoutTransparentBorder?: boolean;
  showSignatureToggle?: boolean;
}>(
  ({
    theme: { vars, constants },
    inputFocused,
    withoutTransparentBorder,
    showSignatureToggle,
  }) => css`
    border: ${constants.borderWidthLg} solid
      ${withoutTransparentBorder ? vars.borderSurface1 : 'transparent'}; /* keep the border transparent to avoid jumping when the border is added */
    border-radius: ${constants.borderRadiusLg};
    outline: none;
    flex: 1;
    color: ${vars.textDefault};
    background-color: ${vars.foundationSurface1};
    padding: ${constants.spacerSm3} ${constants.spacerMd2};
    width: 100%;

    ${inputFocused &&
    css`
      border: ${constants.borderWidthLg} solid ${vars.accentPrimaryDefault};
      padding: ${constants.spacerSm3} ${constants.spacerMd2};
      transition: width 0.3s;
    `}

    ${showSignatureToggle &&
    css`
      margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
    `}
  `
);

const SelectedOption = styled.div`
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
`;

const CharCountAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.stateError};
`;

const ErrorDiv = styled.div`
  padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

type MaxValueProps =
  | { hasMaxValue: boolean; maxError: string; maxCharacters?: number }
  | { hasMaxValue?: never; maxError?: never; maxCharacters?: never };

type DisabledProps =
  | { disabled?: boolean; tooltipText: string; id: string }
  | { disabled?: never; tooltipText?: never; id?: never };

type DropdownProps = {
  options: React.ReactNode[];
  selectedOptionText: string;
  selectedOptionIndex: number;
  setSelectedOptionIndex: (newIndex: number) => void;
  dropdownId: string;
  dropdownMenuId: string;
};

export type ActionRowProps = {
  autoFocus?: boolean;
  dropdown?: DropdownProps;
  primaryButtonText: string;
  primaryButtonId: RegisteredId;
  primaryButtonAction: (value: string, signatureRequired: boolean) => void;
  primaryOnKeyDownAction?: (value: string, signatureRequired: boolean) => void;
  disablePrimaryButton?: boolean;
  withBorder?: boolean;
  errorText: string;
  name: string;
  isAdminPage?: boolean;
  skipSubmitting?: boolean;
  withoutTransparentBorder?: boolean;
  curriculumId: number;
} & MaxValueProps &
  DisabledProps &
  Omit<InputProps, 'name'>;

const MAX_CHARACTERS = 250;

const getValidationSchema = (
  inputName: string,
  errorText: string,
  hasMaxValue: boolean,
  maxError: string,
  maxCharacters: number
) => {
  return yup.object().shape({
    [inputName]: hasMaxValue
      ? yup.string().trim().max(maxCharacters, maxError).required(errorText)
      : yup.string().trim().required(errorText),
  });
};

const t = initTranslations('curriculum_edit');

const ActionRow = ({
  autoFocus,
  dropdown,
  primaryButtonText,
  primaryButtonId,
  primaryButtonAction,
  primaryOnKeyDownAction,
  disablePrimaryButton,
  withBorder = true,
  errorText,
  hasMaxValue = false,
  maxError = '',
  disabled,
  tooltipText,
  id,
  name: inputName,
  isAdminPage = true,
  maxCharacters = MAX_CHARACTERS,
  withoutTransparentBorder = false,
  curriculumId,
  skipSubmitting = false,
  ...standardInputProps
}: ActionRowProps) => {
  const [isInputActive, setInputActive] = useState(false);
  const [requireSignature, setRequireSignature] = useState(false);
  const { hasESignatureFeature } = useCurrentAccount();
  const {
    topic: { singular: topicSingular },
  } = useAccountTerminology();
  const currentRoute = useRoute();
  const isTopicSelected = dropdown?.selectedOptionText === topicSingular;
  const showSignatureToggle =
    useCheckManageSignatureAbility(hasESignatureFeature) && isTopicSelected;
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    handleSubmit,
    setFieldValue,
    errors,
    values,
    isSubmitting,
    resetForm,
    submitCount,
    setSubmitting,
  } = useFormik({
    initialValues: { [inputName]: '' },
    validationSchema: getValidationSchema(
      inputName,
      errorText,
      hasMaxValue,
      maxError,
      maxCharacters
    ),
    onSubmit: () => submitHandler(),
  });
  const submitActionRef = useRef<() => Promise<object> | void>();
  const handleButtonClick = () => {
    submitActionRef.current = () => primaryButtonAction(values[inputName], requireSignature);

    handleSubmit();
  };

  const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !disablePrimaryButton && !isSubmitting) {
      submitActionRef.current = () =>
        primaryOnKeyDownAction
          ? primaryOnKeyDownAction(values[inputName], requireSignature)
          : primaryButtonAction(values[inputName], requireSignature);

      handleSubmit();
    }
  };

  const handleInputActivity = (isActive: boolean) => {
    setInputActive(isActive);
    if (!isActive && !values[inputName]) {
      resetForm();
    }
  };

  const submitHandler = () => {
    submitActionRef.current &&
      submitActionRef.current()?.then(() => {
        resetForm();
      });

    if (skipSubmitting) setSubmitting(false);
  };
  const queryParams = new URLSearchParams(window.location.search);
  const isAddTopicTask = queryParams.get('onboarding_task') === 'add_topic';

  const isLoading = skipSubmitting ? false : isSubmitting;
  const shouldDisableAddElementBtn = !isAddTopicTask && (isLoading || !!disabled);

  useEffect(() => {
    if (isAddTopicTask) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [isAddTopicTask]);

  useEffect(() => {
    resetForm();
  }, [currentRoute.href, resetForm]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
    if (errors[inputName]) {
      inputRef.current?.focus();
    }
  }, [autoFocus, currentRoute.href, errors, inputName]);

  return (
    <>
      {disabled && tooltipText && <Tooltip id={`disabled-${id}-tooltip`} text={tooltipText} />}
      <Container
        data-for={`disabled-${id}-tooltip`}
        data-tip
        id={id}
        isAdminPage={isAdminPage}
        showSignatureToggle={showSignatureToggle}
        withBorder={withBorder}
      >
        {dropdown && (
          <DropdownWithPoppableMenu
            activeOptionIndex={dropdown.selectedOptionIndex}
            id={dropdown.dropdownId}
            menuId={dropdown.dropdownMenuId}
            options={dropdown.options}
            selectedOption={<SelectedOption>{dropdown.selectedOptionText}</SelectedOption>}
            setSelectedOption={dropdown.setSelectedOptionIndex}
          />
        )}
        <StyledInput
          autoFocus={autoFocus}
          disabled={disabled}
          id='curriculum-element-input-field'
          inputFocused={isInputActive}
          name={inputName}
          onBlur={() => handleInputActivity(false)}
          onChange={(e) => {
            let sanitized = sanitizeInput(e.target.value);
            sanitized = sanitized.replace(/&nbsp;/g, ' ');

            setFieldValue(inputName, sanitized);
          }}
          onFocus={() => handleInputActivity(true)}
          onKeyDown={onKeyDownHandler}
          ref={inputRef}
          showSignatureToggle={showSignatureToggle}
          value={values[inputName]}
          withoutTransparentBorder={withoutTransparentBorder}
          {...standardInputProps}
        />
        <CharCountAndButtonWrapper>
          {isInputActive && (
            <StyledCharacterCount hasError={values[inputName].length > maxCharacters}>
              {`${values[inputName].length}/${maxCharacters}`}
            </StyledCharacterCount>
          )}
          {showSignatureToggle && (
            <ESignatureToggle
              curriculumId={curriculumId}
              handleToggle={() => setRequireSignature(!requireSignature)}
              requireSignature={requireSignature}
            />
          )}
          <ButtonWrapper>
            <CallToActionElementWrapper
              className='create-curriculum-element-button-wrapper'
              isVisible={isAddTopicTask}
            >
              {disablePrimaryButton ? (
                <PaywallButton
                  description={t('curriculum_add_test')}
                  iconName='lock'
                  link={t('update')}
                  size='md'
                  title={primaryButtonText}
                />
              ) : (
                <DefaultButton
                  buttonType='primary'
                  disabled={shouldDisableAddElementBtn}
                  fullWidth
                  id={primaryButtonId}
                  loading={isLoading}
                  onClick={() => handleButtonClick()}
                  text={primaryButtonText}
                />
              )}
            </CallToActionElementWrapper>
          </ButtonWrapper>
        </CharCountAndButtonWrapper>
      </Container>
      {!!submitCount && errors[inputName] && (
        <ErrorDiv id='error-container'>
          <StyledIcon name='circle-xmark' weight='solid' />
          <ErrorMessage>{errors[inputName]}</ErrorMessage>
        </ErrorDiv>
      )}
    </>
  );
};

export default ActionRow;
