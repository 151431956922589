import { FormikErrors } from 'formik';

import initTranslations from '../../../../lib/initTranslations';
import { NAME_MAX_CHARACTERS } from '../shared/constants/groups';
import { EditGroupFormValues } from './GroupEditModal';

const t = initTranslations('groups.groups_edit.edit_modal.inputs');

const validateForm = ({ name }: EditGroupFormValues) => {
  const errors: FormikErrors<EditGroupFormValues> = {};
  const trimmedName = name.trim();

  if (!trimmedName) {
    errors.name = t('name.required_error');
  } else if (trimmedName.length > NAME_MAX_CHARACTERS) {
    errors.name = t('name.too_long', { NAME_MAX_CHARACTERS });
  }

  return errors;
};

export default validateForm;
