import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import NoGroupsGraphicDark from '../../../../../images/add_content_state_dark.svg';
import NoGroupsGraphic from '../../../../../images/add_content_state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { UseQueryResult } from '../../../../../redux/baseQueries/types';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { UserGroupResponsibilitiesResponse } from '../../../../../redux/services/resourceApis/responsibilities/types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import LoadingContainer from '../../../../design_system/LoadingContainer';
import NoResults from '../../../../design_system/Triage/NoResults';
import { routes as applicationRouterRoutes } from '../../../publicApplication/applicationRouter';
import GroupLi from './GroupLi';

export const StyledNoResults = styled(NoResults)`
  margin-top: 0;
  padding-bottom: ${({ theme: { constants } }) => constants.spacerLg3};

  img {
    height: 17.5rem;
  }
`;

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const t = initTranslations('users_profile');

interface Props {
  groupResponsibilitiesResult: UseQueryResult<UserGroupResponsibilitiesResponse>;
  adminView: boolean;
}

const GroupResponsibilitiesList = (props: Props) => {
  const { groupResponsibilitiesResult, adminView } = props;
  const { slug, onHoldPlan } = useCurrentAccount();
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();
  const groupHref = applicationRouterRoutes.groups({ slug });
  const navigateToGroupsIndexPage = () => groupHref.push();
  const showNoResultsSubheader = adminView && !onHoldPlan;

  if (groupResponsibilitiesResult.isLoading) return <LoadingContainer />;
  if (groupResponsibilitiesResult.error)
    return <BasicErrorDisplay error={groupResponsibilitiesResult.error} />;
  if (!groupResponsibilitiesResult.data) return <></>;
  const { groupsResponsibilities } = groupResponsibilitiesResult.data;

  const groupsResponsibilitiesWithoutEveryoneGroup = groupsResponsibilities.filter(
    (group) => !group.everyone
  );

  if (groupsResponsibilitiesWithoutEveryoneGroup.length === 0) {
    return (
      <StyledNoResults
        darkImage={NoGroupsGraphicDark}
        heading={t('no_groups_heading', {
          responsibilities: responsibilityPlural.toLowerCase(),
        })}
        lightImage={NoGroupsGraphic}
        minHeight={showNoResultsSubheader ? '30rem' : '18.5rem'}
        showSubheader={showNoResultsSubheader}
        subHeaderCta={{
          action: navigateToGroupsIndexPage,
          text: t('no_groups_link'),
        }}
        subHeaderText={t('no_groups_description', {
          responsibilities: responsibilityPlural.toLowerCase(),
        })}
        subheaderMargin='1rem'
      />
    );
  }

  return (
    <StyledUl>
      {groupsResponsibilitiesWithoutEveryoneGroup.map((group) => {
        return <GroupLi adminView={adminView} group={group} key={group.id} />;
      })}
    </StyledUl>
  );
};

export default GroupResponsibilitiesList;
