import React from 'react';

import scrollToElement from '../../../../../../../lib/scrollToElement';
import Icon from '../../../../../../design_system/display/icons/Icon';
import {
  OutlineElementItemIconWrapper,
  OutlineElementItemTitle,
  OutlineElementItemWrapper,
} from './styles';
import { OutlineElementItemProps } from './types';

const OutlineElementItem = ({ title, text, id, emoji }: OutlineElementItemProps) => {
  const handleClickScroll = () => {
    scrollToElement(`template-preview-element-${id}`);
  };

  return (
    <OutlineElementItemWrapper onClick={handleClickScroll}>
      <OutlineElementItemIconWrapper>
        {emoji || <Icon name='list-ol' size='xs' />}
      </OutlineElementItemIconWrapper>
      <OutlineElementItemTitle>{title || text}</OutlineElementItemTitle>
    </OutlineElementItemWrapper>
  );
};

export default OutlineElementItem;
