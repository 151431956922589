import { LoadingSize } from '../../components/application/people/UsersOutline/Table/Column';
import { TableColumn } from '../../components/application/reports/ElementReportPage/ElementReportTable/types';
import useCurrentAccount from '../useCurrentAccount';

export function useGetElementColumns(signatureRequired: boolean): TableColumn[] {
  const { hasESignatureFeature } = useCurrentAccount();

  const showESignaturesColumn = hasESignatureFeature && signatureRequired;

  const eSignaturesColumn: TableColumn = {
    columnName: 'e_signatures',
    loadingSize: LoadingSize.medium,
    sortable: false,
  };

  return [
    { columnName: 'title', loadingSize: LoadingSize.large, sortable: true },
    { columnName: 'element_type', loadingSize: LoadingSize.medium, sortable: false },
    { columnName: 'completion_percentage', loadingSize: LoadingSize.medium, sortable: true },
    { columnName: 'users_completed_count', loadingSize: LoadingSize.medium, sortable: false },
    { columnName: 'updated_at', loadingSize: LoadingSize.medium, sortable: true },
    ...(showESignaturesColumn ? [eSignaturesColumn] : []),
    {
      columnName: 'clickable_arrow',
      loadingSize: LoadingSize.small,
      sortable: false,
      contentAlignment: 'end',
    },
  ];
}
