import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useIntegrationCardsImages from '../../../../hooks/useIntegrationCardsImages';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import {
  useDisconnectSlackMutation,
  useGetSlackAuthorizeUrlQuery,
} from '../../../../redux/services/resourceApis/settings/settingsApi';
import ThemedImage from '../../../design_system/ThemedImage';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import PageWrapper from '../IntegrationShowPage/PageWrapper';
import {
  ButtonsContainer,
  DescriptionSection,
  LogoSection,
  StyledButton,
} from '../IntegrationShowPage/styles';
import { pageReducer } from './reducer';
import { PageState } from './types';

const StyledIntegrationLogo = styled(ThemedImage)`
  width: 16rem;
`;

const t = initTranslations('integrations.slack');

const SlackIntegrationShowPage = () => {
  const { flash } = useFlashNotification();
  const { configs } = usePrivateConfigs();
  const { cardsData } = useIntegrationCardsImages();
  const { darkImage, lightImage } = cardsData['slack'];
  const { slug, slackTeamId, id } = useCurrentAccount();
  const { productTerm } = useAccountTerminology();
  const slackIntegrationEnabled =
    configs['SLACK_INTEGRATION_ACCOUNTS'] === 'ALL' ||
    configs['SLACK_INTEGRATION_ACCOUNTS'].split(', ').includes(String(id));

  useEffect(() => {
    if (!slackIntegrationEnabled) {
      routeTo(routes.integrations({ slug }));
      flash('error', t('not_authorized'));
    }
  });

  const initialPageReducer: PageState = {
    skipToken: true,
    isConnect: !!slackTeamId,
  };

  const [modalState, dispatch] = useReducer(pageReducer, initialPageReducer);
  const { skipToken, isConnect } = modalState;

  const { data, isSuccess, isLoading, error } = useGetSlackAuthorizeUrlQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: skipToken,
  });
  const [disconnectSlack, disconnectSlackResult] = useDisconnectSlackMutation();
  const { isSuccess: disconnectSlackIsSuccess, isLoading: disconnectSlackIsLoading } =
    disconnectSlackResult;

  const submitText = isConnect ? t('disconnect_slack') : t('add_to_slack');
  const submitAction = () => {
    isConnect ? disconnectSlack() : dispatch({ type: 'setSkipToken', skipToken: false });
  };

  useEffect(() => {
    if (error) {
      dispatch({ type: 'setSkipToken', skipToken: true });
      flash('warn', messageFromError(error)?.join(','));
    }
  }, [error, flash]);

  useEffect(() => {
    if (data && isSuccess) {
      dispatch({ type: 'setSkipToken', skipToken: true });
      routeTo(data.redirect_url);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (disconnectSlackIsSuccess) {
      flash('info', t('slack_is_disconnected', { productTerm }), { autoClose: 2500 });
      dispatch({ type: 'setIsConnect', isConnect: false });
    }
  }, [disconnectSlackIsSuccess, flash, productTerm]);

  return (
    <PageWrapper integrationName={t('title')} isBeta={false}>
      <LogoSection>
        <StyledIntegrationLogo alt={t('title')} darkImage={darkImage} lightImage={lightImage} />
        <ButtonsContainer>
          <StyledButton
            buttonType='primary'
            id='connect-slack-button'
            loading={isLoading || disconnectSlackIsLoading}
            onClick={submitAction}
            size='lg'
            text={submitText}
          />
        </ButtonsContainer>
      </LogoSection>
      <DescriptionSection>
        <>
          <p>{t('description.p1', { productTerm })}</p>
          <p>{t('description.p2', { productTerm })}</p>
          <p>{t('list_title')}</p>
          <ul>
            <li>{t('bullet_1')}</li>
            <li>{t('bullet_2')}</li>
            <li>{t('bullet_3')}</li>
          </ul>
        </>
      </DescriptionSection>
    </PageWrapper>
  );
};

export default SlackIntegrationShowPage;
