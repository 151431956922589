import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';

const t = initTranslations('bulk_user_upload_payroll_modal.success_modal');

type Props = {
  usersCount: number;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};

const SuccessModal = ({ usersCount, setShowModal, showModal }: Props) => {
  const closeModal = () => {
    setShowModal(false);
  };

  const taskModalArgs: TaskModalProps = {
    title: t('title'),
    onCloseRequest: closeModal,
    processing: false,
    heapModalName: 'bulk-user-upload-payroll-success-modal',
    secondaryButtonText: t('close'),
  };

  return (
    <>
      {showModal && (
        <TaskModal {...taskModalArgs}>
          {t('message')}
          <br />
          <br />
          {t('people_added', { count: usersCount })}
        </TaskModal>
      )}
    </>
  );
};

export default SuccessModal;
