import React from 'react';
import styled from 'styled-components';

import { BaseImageInitialsContainer, BaseRoundedCard, Card } from '../../../styled/BaseUserCard';

const ImageInitialsContainer = styled(BaseImageInitialsContainer)`
  background-color: ${({ theme: { vars } }) => vars.textDisabled};
`;

const CardTitle = styled.div`
  background-color: ${({ theme: { vars } }) => vars.textDisabled};
  margin: ${({ theme: { constants } }) =>
    `${constants.spacerMd1} ${constants.spacerMd2} ${constants.spacerSm3} ${constants.spacerMd2}`};
  width: 80%;
  height: 1rem;
  border-radius: 5px;
`;

const CardSubtitle = styled.div`
  background-color: ${({ theme: { vars } }) => vars.textDisabled};
  margin: ${({ theme: { constants } }) =>
    `0 ${constants.spacerMd2} ${constants.spacerMd1} ${constants.spacerMd2}`};
  width: 80%;
  height: 1rem;
  border-radius: 5px;
`;

const LoadingUserCard = () => {
  return (
    <Card className='col'>
      <BaseRoundedCard>
        <ImageInitialsContainer className='notranslate' hasImage={false} />
        <CardTitle className='notranslate' />
        <CardSubtitle />
      </BaseRoundedCard>
    </Card>
  );
};

export default LoadingUserCard;
