import React from 'react';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import initTranslations from '../../../../../lib/initTranslations';
import {
  useBatchCreateFollowingMutation,
  useBatchDeleteFollowingMutation,
  useGetFollowingQuery,
} from '../../../../../redux/services/resourceApis/followings/followingsApi';
import { StyledDefaultButton } from './UserProfileCard';

const t = initTranslations('user_profile.following');

const FollowUserProfileButton = ({ userId }: { userId: number }) => {
  const { id: currentUserId } = useCurrentUser();
  const [followUser] = useBatchCreateFollowingMutation();
  const [unfollowUser] = useBatchDeleteFollowingMutation();
  const { data: currentlyFollowing } = useGetFollowingQuery(currentUserId);
  const isFollowingUser = !!currentlyFollowing?.some((following) => following.id === userId);

  return (
    <StyledDefaultButton
      buttonType='secondary'
      iconName={isFollowingUser ? 'user-xmark' : 'user-plus'}
      id='follow-user-button'
      onClick={() =>
        isFollowingUser
          ? unfollowUser({ id: currentUserId, userIds: [userId] })
          : followUser({ id: currentUserId, userIds: [userId] })
      }
      size='sm'
      text={isFollowingUser ? t('unfollow') : t('follow')}
    />
  );
};

export default FollowUserProfileButton;
