import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import StatusBlockText from './StatusBlockText';
import StatusBlockWrapper from './StatusBlockWrapper';

const StyleIcon = styled(Icon)`
  margin-right: 0.5rem;
  color: ${({ theme: { vars } }) => vars.chartGreen1};
`;

interface Props {
  title: ReactNode;
}

const StatusBlockSuccess = ({ title }: Props) => {
  return (
    <StatusBlockWrapper isSuccess>
      <StatusBlockText>
        <StyleIcon name='check-circle' weight='solid' />
        {title}
      </StatusBlockText>
    </StatusBlockWrapper>
  );
};

export default StatusBlockSuccess;
