import { FormikErrors, useFormikContext } from 'formik';
import React, { FC, useState } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import Icon from '../../../../../../design_system/display/icons/Icon';
import ThreeDotMenu from '../../../../../../design_system/Triage/menus/ThreeDotMenu';
import { ThreeDotMenuOptionProps } from '../../../../../../design_system/Triage/menus/ThreeDotMenu/types';
import { TResponsibilityContentUrl } from '../../../../../DelegationPlanner/BoardColumn/types';
import {
  LinkedContentWrapper,
  StyledParagraph,
} from '../../../../../DelegationPlanner/modals/ResponsibilityDetailsModal/ResponsibilityLinkedContent/styles';
import { extractDomainName } from '../../../../../DelegationPlanner/modals/utils';
import { CONTENT_URL_TITLE_MAX_CHARACTERS } from '../../../../../DelegationPlanner/shared/constants/modals';
import ContentBadge from '../../../../../shared/ContentFlyout/ContentBadge/ContentBadge';
import { ResponsibilityDetailsFormData } from '../../ResponsibilityDetails/types';
import {
  CTAWrapper,
  EditFormWrapper,
  FormContentWrapper,
  StyledButton,
  StyledEditButton,
  StyledInput,
  StyledInputFieldWithCharacterCounter,
} from '../Forms/styles';
import { ContentTypeWrapper, LinkedContentBlock } from '../styles';
import { ContentUrlsListProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.linked_content');

const ContentUrlsList: FC<ContentUrlsListProps> = ({ urls, isEditable }) => {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [originalValues, setOriginalValues] = useState<TResponsibilityContentUrl | null>(null);
  const { values, setValues, errors, touched, handleBlur } =
    useFormikContext<ResponsibilityDetailsFormData>();

  const handleRemoveContentUrl = (index: number) => {
    const contentUrls = [...urls];
    const [element] = contentUrls.splice(index, 1);

    if (!!element.id) contentUrls.push({ ...element, _destroy: true });

    setValues({
      ...values,
      contents_urls: contentUrls,
    });
  };

  const handleEditContentUrl = (index: number) => {
    setEditIndex(index);
    setOriginalValues(values.contents_urls[index]);
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const updatedContentUrls = [...values.contents_urls];
    updatedContentUrls[index] = { ...updatedContentUrls[index], [name]: value };
    setValues({
      ...values,
      contents_urls: updatedContentUrls,
    });
  };

  const handleCancelEdit = () => {
    const updatedContentUrls = [...values.contents_urls];

    if (editIndex !== null && originalValues) {
      updatedContentUrls[editIndex] = originalValues;
      setValues({
        ...values,
        contents_urls: updatedContentUrls,
      });
    }
    setEditIndex(null);
    setOriginalValues(null);
  };

  const isSubmitButtonDisabled = (index: number): boolean => {
    const contentUrlErrors = errors.contents_urls?.[
      index
    ] as FormikErrors<TResponsibilityContentUrl>;
    const contentUrl = values.contents_urls[index];

    const contentIsPresent = !!(contentUrl.title || contentUrl.url);
    const hasErrors = !!contentUrlErrors?.url || !!contentUrlErrors?.title;

    return hasErrors || !contentIsPresent;
  };

  const threeDotMenuOptions = (
    index: number,
    contentUrl: TResponsibilityContentUrl
  ): ThreeDotMenuOptionProps[] => [
    {
      title: t('edit'),
      onClick: () => handleEditContentUrl(index),
      iconName: 'pen',
      id: `td-delegation-board-responsibility-edit-${index}`,
      visible: !contentUrl.contentableType,
    },
    {
      title: t('remove'),
      onClick: () => handleRemoveContentUrl(index),
      iconName: 'trash-can',
      id: `td-delegation-board-responsibility-remove-${index}`,
      visible: true,
    },
  ];

  const redirectToUrl = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <LinkedContentBlock>
      {urls.map((contentUrl, index) => {
        if (contentUrl._destroy) return null;

        return editIndex === index ? (
          <FormContentWrapper key={index}>
            <EditFormWrapper>
              <StyledInputFieldWithCharacterCounter
                errorText={
                  touched.title
                    ? (errors.contents_urls?.[index] as FormikErrors<TResponsibilityContentUrl>)
                        ?.title
                    : ''
                }
                id={`content-title-input-${index}`}
                inputFor='Title'
                label={t('content_title')}
                maxCharacters={CONTENT_URL_TITLE_MAX_CHARACTERS}
                name='title'
                onBlur={handleBlur}
                onChange={(e) => handleFormChange(e, index)}
                value={contentUrl.title}
              />
              <StyledInput
                errorText={
                  touched.url
                    ? (errors.contents_urls?.[index] as FormikErrors<TResponsibilityContentUrl>)
                        ?.url
                    : ''
                }
                id={`content-url-input-${index}`}
                label={t('content_url')}
                name='url'
                onBlur={handleBlur}
                onChange={(e) => handleFormChange(e, index)}
                value={contentUrl.url}
              />
              <CTAWrapper>
                <StyledEditButton
                  buttonType='tertiary'
                  id={`delegation-planner-cancel-responsibility-content-button-${index}`}
                  onClick={handleCancelEdit}
                  size='md'
                  text={t('cancel')}
                />
                <StyledButton
                  buttonType='primary'
                  disabled={isSubmitButtonDisabled(index)}
                  id={`delegation-planner-save-responsibility-content-button-${index}`}
                  isDisabled={isSubmitButtonDisabled(index)}
                  onClick={() => setEditIndex(null)}
                  size='md'
                  text={t('save')}
                />
              </CTAWrapper>
            </EditFormWrapper>
          </FormContentWrapper>
        ) : (
          <LinkedContentWrapper key={index} onClick={() => redirectToUrl(contentUrl.url)}>
            <ContentTypeWrapper>
              {contentUrl.contentableType ? (
                <ContentBadge typeName={contentUrl.contentableType} />
              ) : (
                <Icon name='link-simple' />
              )}
            </ContentTypeWrapper>
            <StyledParagraph>
              {contentUrl.title || extractDomainName(contentUrl.url)}
            </StyledParagraph>
            {isEditable && (
              <ThreeDotMenu
                id={`delegation-planner-three-dot-menu-${index}`}
                menuOptions={threeDotMenuOptions(index, contentUrl)}
                menuPlacement='bottom-start'
              />
            )}
          </LinkedContentWrapper>
        );
      })}
    </LinkedContentBlock>
  );
};

export default ContentUrlsList;
