import React, { useEffect } from 'react';

import { useHandlerForTaskDrivenOnboardingAction } from '../../../../../../hooks/home/useHandlerForTaskDrivenOnboardingAction';
import { useSkipTaskDrivenOnboardingAction } from '../../../../../../hooks/home/useSkipTaskDrivenOnboardingAction';
import { useTaskDrivenOnboardingCardData } from '../../../../../../hooks/home/useTaskDrivenOnboardingCardData';
import { TASK_DRIVEN_ONBOARDING_STATE_KEY } from '../../../../../../lib/home/getSavedOnboardingState';
import { TaskDrivenOnboardingTaskName } from '../../../../../../types/TaskDrivenOnboarding';
import {
  TaskDrivenOnboardingActionButton,
  TaskDrivenOnboardingActionButtonsWrapper,
  TaskDrivenOnboardingActionIcon,
  TaskDrivenOnboardingCardDescription,
  TaskDrivenOnboardingCardTitle,
  TaskDrivenOnboardingCardWrapper,
  TaskDrivenOnboardingCloseIconButton,
} from './styles';
import { CurrentTaskName, TaskDrivenOnboardingCardProps } from './types';

const TaskDrivenOnboardingCard = ({
  currentTaskNameState,
  setCurrentTaskNameState,
  onboardingState,
  setOnboardingState,
}: TaskDrivenOnboardingCardProps) => {
  const taskDrivenOnboardingCardData = useTaskDrivenOnboardingCardData();
  const handleClickOnActionButton = useHandlerForTaskDrivenOnboardingAction();
  const { skipInviteTeammateCard, shouldSkipActionButton } =
    useSkipTaskDrivenOnboardingAction(currentTaskNameState);

  useEffect(() => {
    const nextTaskName = Object.keys(onboardingState).find(
      (key: TaskDrivenOnboardingTaskName) => !onboardingState[key]
    ) as CurrentTaskName;

    setCurrentTaskNameState(nextTaskName);
  }, [onboardingState, setCurrentTaskNameState]);

  const handleCloseTask = () => {
    if (!(onboardingState && currentTaskNameState)) return;

    const newOnboardingState = { ...onboardingState, [currentTaskNameState]: true };

    setOnboardingState(newOnboardingState);
    localStorage.setItem(TASK_DRIVEN_ONBOARDING_STATE_KEY, JSON.stringify(newOnboardingState));
  };

  if (!currentTaskNameState || skipInviteTeammateCard) return <></>;

  const { title, description, actions } = taskDrivenOnboardingCardData[currentTaskNameState];

  return (
    <>
      <TaskDrivenOnboardingCardWrapper
        className={`task-driven-onboarding-${currentTaskNameState}-card`}
      >
        <TaskDrivenOnboardingCardTitle>{title}</TaskDrivenOnboardingCardTitle>
        <TaskDrivenOnboardingCardDescription text={description} />
        <TaskDrivenOnboardingActionButtonsWrapper>
          {actions.map(({ id, title, icon }) => {
            if (shouldSkipActionButton(id)) return;

            return (
              <TaskDrivenOnboardingActionButton
                key={id}
                onClick={() => handleClickOnActionButton(id)}
              >
                <TaskDrivenOnboardingActionIcon alt={title} src={icon} />
                {title}
              </TaskDrivenOnboardingActionButton>
            );
          })}
        </TaskDrivenOnboardingActionButtonsWrapper>
        <TaskDrivenOnboardingCloseIconButton
          ariaLabel='Close'
          buttonSize='md'
          name='xmark'
          onClick={handleCloseTask}
          weight='regular'
        />
      </TaskDrivenOnboardingCardWrapper>
    </>
  );
};

export default TaskDrivenOnboardingCard;
