import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useKeyPress from '../../../../../hooks/useKeyPress';
import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { ModalBodyContainer, ModalFooterContainer } from '../../../../styled/Modals';
import StatusBlockError from '../../BulkUserUploadStatusBlock/StatusBlockError';
import StatusBlockSuccess from '../../BulkUserUploadStatusBlock/StatusBlockSuccess';
import { SuccessResult } from '../ResultTypes';

const StyledModalBodyContainer = styled(ModalBodyContainer)`
  display: block;
`;

const StyledImportButton = styled(DefaultButton)`
  margin-top: 1rem;
  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-left: ${({ theme: { constants } }) => constants.spacerMd1};
    margin-top: 0;
  }
`;

const StyledNeedHelpButton = styled(DefaultButton)`
  margin-right: auto;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: center;
  padding: 0.4375rem 1.25rem;
  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-bottom: 0;
    width: auto;
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const t = initTranslations('bulk_user_upload_modal.ran_out_of_seats');

type Props = {
  closeModal: () => void;
  renderSuccessContent: () => void;
  showUpdatePlanModal: () => void;
  data: SuccessResult;
};

const RanOutOfSeats = ({ closeModal, data, renderSuccessContent, showUpdatePlanModal }: Props) => {
  const { permission } = useCurrentUser();
  const { nonBilling, status } = useCurrentAccount();
  const isBillingAdmin = permission === 'billing_admin';
  const showUpdateCardButton = isBillingAdmin && status === 'past_due';
  const showUpdatePlanButton = isBillingAdmin && !nonBilling && !showUpdateCardButton;
  const { ran_out_of_seats, people } = data;
  const dispatchShowModal = useDispatchSetShowModal();
  const { configs } = usePrivateConfigs();
  const bulkUserUpload = configs['BULK_USER_UPLOAD_HELP_PAGE'];
  let secondaryOnClick: () => void;
  let secondaryText: string;
  const successTitle = (
    <span
      dangerouslySetInnerHTML={{
        __html:
          ran_out_of_seats.success === '1 person'
            ? t('bulk_user_upload_status_block.title_success_singular')
            : t('bulk_user_upload_status_block.title_success_plural', {
                quantity: ran_out_of_seats.success,
              }),
      }}
    />
  );
  const errorTitle = (
    <span
      dangerouslySetInnerHTML={{
        __html: t('bulk_user_upload_status_block.title_error', {
          quantity: ran_out_of_seats.failed,
        }),
      }}
    />
  );

  if (people.length) {
    secondaryOnClick = renderSuccessContent;
    secondaryText = t('upgrade_later');
  } else {
    secondaryOnClick = closeModal;
    secondaryText = t('close');
  }

  const openUpdatePaymentMethod = () => {
    dispatchShowModal('updatePaymentMethod', true);
    closeModal();
  };

  const handlePressEnter = () => {
    showUpdatePlanButton ? showUpdatePlanModal() : openUpdatePaymentMethod();
  };

  useKeyPress('Enter', handlePressEnter);

  return (
    <>
      <StyledModalBodyContainer>
        <StatusBlockError
          linkTextXlsx={ran_out_of_seats.results_file.xlsx.name}
          resultType='invite_results'
          subtitle={t('bulk_user_upload_status_block.subtitle_error')}
          title={errorTitle}
        />
        {ran_out_of_seats.show_success_message && <StatusBlockSuccess title={successTitle} />}
      </StyledModalBodyContainer>
      <ModalFooterContainer>
        <StyledNeedHelpButton
          buttonType='tertiary'
          iconName='external-link-alt'
          id='ran-out-of-seats-need-help'
          onClick={() => {
            window.open(bulkUserUpload, '_blank');
          }}
          size='sm'
          text={t('need_help')}
        />
        <DefaultButton
          buttonType='secondary'
          id='ran-out-of-seats-upgrade-later'
          onClick={secondaryOnClick}
          text={secondaryText}
        />
        {showUpdatePlanButton && (
          <StyledImportButton
            buttonType='primary'
            id='ran-out-of-seats-upgrade-plan'
            onClick={showUpdatePlanModal}
            text={t('upgrade_plan')}
          />
        )}
        {showUpdateCardButton && (
          <StyledImportButton
            buttonType='primary'
            id='update-payment-method-button'
            onClick={openUpdatePaymentMethod}
            text={t('update_card')}
          />
        )}
      </ModalFooterContainer>
    </>
  );
};

export default RanOutOfSeats;
