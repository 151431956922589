import React from 'react';

import Icon from '../../../../design_system/display/icons/Icon';
import { Wrapper } from './styles';

const MagicWandIcon = () => {
  return (
    <Wrapper>
      <Icon name='magic-wand-sparkles' size='xs' />
    </Wrapper>
  );
};

export default MagicWandIcon;
