import React from 'react';

import { Activity } from '../../activities/types';
import ESignaturesUsageTemplate, {
  ESignaturesUsageTemplateProps,
} from './ESignatures/ESignaturesUsageTemplate';

interface AccountActivitiesProps {
  'account.e_signatures.monthly_limit_expired': React.FC<ESignaturesUsageTemplateProps>;
  'account.e_signatures.monthly_limit_upcoming_expire': React.FC<ESignaturesUsageTemplateProps>;
}

const ACTIVITY_KEYS: AccountActivitiesProps = {
  'account.e_signatures.monthly_limit_expired': ESignaturesUsageTemplate,
  'account.e_signatures.monthly_limit_upcoming_expire': ESignaturesUsageTemplate,
};

const AccountNotificationsDetails = ({ activity }: { activity: Activity }) => {
  const NotificationMessage = ACTIVITY_KEYS[activity.key as keyof AccountActivitiesProps];

  return <NotificationMessage activityKey={activity.key} />;
};

export default AccountNotificationsDetails;
