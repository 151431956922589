import React, { useState } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import {
  AssignedCheckableSubject,
  AssignedCheckableTopic,
  useAssignedSubjects,
} from './AssignedSubjectsContext';
import Info from './Info';
import Title from './Title';

const SubjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerMd2} 0 ${constants.spacerMd2}`};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  /* This fixes a bug where opening/closing topics will cause the chevrons to be odd/wonkily placed on mobile or Safari browsers. See https://trainual.atlassian.net/browse/GS1-3722 */
  -webkit-transform: translate3d(0, 0, 0);
`;

const SubjectInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const TopicsWrapper = styled.div<{ isVisible: boolean; height: number }>`
  padding: ${({ isVisible, theme: { constants } }) =>
    isVisible ? `0 0 0 ${constants.spacerMd2}` : 0};
  height: ${({ isVisible, height }) => (isVisible ? height : 0)}px;
  overflow: hidden;
  transition: height 0.25s linear, padding 0.25s ease-in-out;

  &:last-child {
    margin-bottom: ${({ isVisible, theme: { constants } }) =>
      isVisible ? constants.spacerMd2 : 0};
  }

  @media (min-width: ${mediaBreakpointPxMd}) {
    padding: ${({ isVisible, theme: { constants } }) =>
      isVisible ? `0 0 0 ${constants.spacerLg1}` : 0};
  }
`;

const Topics = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const TopicWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  subject: AssignedCheckableSubject;
  className: string;
}

const Topic = ({
  subject,
  topic,
}: {
  subject: AssignedCheckableSubject;
  topic: AssignedCheckableTopic;
}) => {
  return (
    <TopicWrapper>
      <Title subject={subject} topic={topic} />
      <Info subject={subject} topic={topic} />
    </TopicWrapper>
  );
};

const Subject = ({ className, subject }: Props) => {
  const { ableToManage } = useAssignedSubjects();
  const [isDisplayingTopics, setIsDisplayingTopics] = useState(false);
  const [topicRef, bounds] = useMeasure();

  return (
    <SubjectWrapper className={className}>
      <SubjectInfoWrapper>
        <Title
          isDisplayingTopics={isDisplayingTopics}
          setIsDisplayingTopics={setIsDisplayingTopics}
          subject={subject}
        />
        <Info subject={subject} />
      </SubjectInfoWrapper>
      <TopicsWrapper height={bounds.height} isVisible={isDisplayingTopics}>
        <Topics ref={topicRef}>
          {subject.assigned_subject_elements.map(
            (topic: AssignedCheckableTopic) =>
              (ableToManage || topic.status === 'finished') && (
                <Topic
                  key={`topic-${topic.curriculum_element_id}`}
                  subject={subject}
                  topic={topic}
                />
              )
          )}
        </Topics>
      </TopicsWrapper>
    </SubjectWrapper>
  );
};

export default Subject;
