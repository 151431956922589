import styled from 'styled-components';

export const InsertDropdownMenu = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm1};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => constants.spacerSm3} 0;
  min-width: 7.5rem;
`;

export const InsertDropdownWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;
