import { truncate } from 'lodash';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useConsumptionCourseContext } from '../../../../../../contexts/ConsumptionCourseContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { ConsumptionStep } from '../../../../../../types/Editor';
import Icon from '../../../../../design_system/display/icons/Icon';
import { routes } from '../../../../publicApplication/applicationRouter';
import { ConsumptionStepLink, Emoji, StepEmojiWrapper, StepTitle } from '../../../shared/styles';
import MarkCompleteCheckMark from '../../components/MarkComplete/MarkCompleteCheckMark';
import { useAccessibleSteps } from '../../hooks/useAccesibleSteps';

const StepWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const TITLE_MAX_LENGTH = 60;

interface Props {
  step: ConsumptionStep;
}

const StepOption = ({ step }: Props) => {
  const {
    stepId: currentStepId,
    curriculum: { completionRequired },
    curriculum,
    course,
  } = useConsumptionCourseContext();
  const { slug } = useCurrentAccount();

  const isActive = currentStepId === step.id;

  const { shouldForceOrder } = useAccessibleSteps({
    course,
    curriculum,
    currentStepId: step.id,
  });

  const onClickHandler = useCallback(() => {
    if (!shouldForceOrder) {
      routes.consume({ id: step.id, slug }).push();
    }
  }, [shouldForceOrder, step.id, slug]);

  return (
    <ConsumptionStepLink
      aria-disabled={shouldForceOrder}
      className={`consumption-step-${step.id}-link`}
      forcedOrder={shouldForceOrder}
      isActive={isActive}
      onClick={onClickHandler}
    >
      <StepWrapper>
        <StepEmojiWrapper isDisabled={shouldForceOrder}>
          {step.emoji ? <Emoji>{step.emoji}</Emoji> : <Icon name='list-ol' weight='light' />}
        </StepEmojiWrapper>
        <StepTitle isActive={isActive} isDisabled={shouldForceOrder}>
          {truncate(step.title, { length: TITLE_MAX_LENGTH })}
        </StepTitle>
      </StepWrapper>
      {completionRequired && (
        <MarkCompleteCheckMark
          className='mark-complete-checkmark'
          completed={step.completed}
          forceCompletionOrder={shouldForceOrder}
        />
      )}
    </ConsumptionStepLink>
  );
};

export default StepOption;
