import styled from 'styled-components';

import Scrollbar from '../../../../styled/Scrollbar';

export const SearchResultsChipsContainer = styled.div<{
  isFullWidth: boolean;
}>`
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'max-content')};
  overflow-x: auto;
  display: flex;
  grid-gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${Scrollbar};

  &::-webkit-scrollbar-track {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  }

  &::-webkit-scrollbar-thumb {
    border: ${({ theme: { vars, constants } }) =>
      `${constants.borderWidth2xl} solid ${vars.foundationBase1}`};
  }
`;
