import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import { CurriculumElementTrackable, Owner } from '../../activities/types';
import NotificationUserName from '../NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';

const t = initTranslations('notifications');

export interface SurveyCommonTemplateProps {
  activityKey: string;
  trackable: CurriculumElementTrackable;
  owner: Owner;
}

const CommonTemplate = ({ activityKey, owner, trackable }: SurveyCommonTemplateProps) => {
  const { slug } = useCurrentAccount();
  const {
    id,
    name,
    associated_params: { curriculum_title },
  } = trackable;
  const { curriculum } = useContext(AccountTerminologyContext);
  const surveyConsumeUrl = routes.surveyConsume({ slug, id }).href;

  return (
    // ToDo: update to use RouterLink after survey page conversion to SPA
    <>
      <NotificationUserName user={owner} />
      <TextFragment>{t(activityKey)}</TextFragment>
      <SourceBadge sourceHref={surveyConsumeUrl} sourceName='curriculum' sourceText={name} />
      <TextFragment>
        {t('survey_survey.in_curriculum', { curriculum: curriculum.singular.toLowerCase() })}
      </TextFragment>
      <b>{curriculum_title}</b>
    </>
  );
};

export default CommonTemplate;
