import { useEffect, useState } from 'react';

import { AutoSaveStatus, UseAutoSaveIndicatorStateProps } from './types';

const useDeprecatedAutoSaveIndicatorState = ({
  autosaveStatus,
  isFetching,
  updatedAtInWords,
}: UseAutoSaveIndicatorStateProps) => {
  const [indicatorProps, setIndicatorProps] = useState({
    status: autosaveStatus,
    updatedAtInWords: updatedAtInWords || '',
  });

  useEffect(() => {
    setIndicatorProps({
      status: isFetching ? AutoSaveStatus.Saving : autosaveStatus,
      updatedAtInWords: updatedAtInWords || '',
    });
  }, [autosaveStatus, isFetching, updatedAtInWords]);

  return indicatorProps;
};

export default useDeprecatedAutoSaveIndicatorState;
