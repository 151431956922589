import { useTheme } from 'styled-components';

import { VerifyStatusData } from '../../components/application/curriculums/StatusBadges/VerifyStatusBadge/types';
import { BadgeType } from '../../components/design_system/display/badge/BadgeTypes';
import initTranslations from '../../lib/initTranslations';
import useCurrentAccount from '../useCurrentAccount';

type VerifyStatusBadgeDataArgs = {
  ownerName: string | undefined;
} & VerifyStatusData;

type VerifyStatusBadgeData = {
  verifyTooltipText: string;
  verifyBadgeText: string;
  verifyBadgeType: BadgeType;
  flyoutTitleColor: string;
  flyoutHeaderBackgroundColor: string;
  flyoutTitle: string;
  flyoutText: string;
  flyoutSubtext: string;
  flyoutButtonText: string;
};

const t = initTranslations('curriculums_view.badges.verify_badge');

export function useVerifyStatusBadgeData({
  isVerifiedContent,
  ownerName,
  verifiedDate,
  nextVerifyDate,
  verifyPeriod,
}: VerifyStatusBadgeDataArgs): VerifyStatusBadgeData {
  const {
    accountTerminology: {
      curriculum: { singular: curriculumSingular },
    },
  } = useCurrentAccount();
  const curriculum = curriculumSingular.toLowerCase();
  const { vars } = useTheme();
  const verifyTooltipDate = isVerifiedContent ? verifiedDate : nextVerifyDate;
  const tooltipContent = t('tooltip.content');
  const verifiedAtText = isVerifiedContent ? t('tooltip.was') : t('tooltip.was_last');
  const verifiedByText = t('tooltip.verified_by', { name: ownerName, date: verifyTooltipDate });
  const needsToBeVerifiedText = t('tooltip.needs_to_be_verified', { name: ownerName });
  const verifyTooltipText =
    tooltipContent + (verifiedDate ? verifiedAtText + verifiedByText : needsToBeVerifiedText);
  const verifyBadgeText = isVerifiedContent ? t('verified_content') : t('unverified_content');

  const verifyBadgeType = isVerifiedContent ? 'info' : 'error';

  const flyoutTitleColor = isVerifiedContent ? vars.stateInfo : vars.stateError;

  const flyoutHeaderBackgroundColor = isVerifiedContent
    ? vars.stateBadgeInfo
    : vars.stateBadgeError;

  const flyoutTitle = isVerifiedContent
    ? t('flyout.was_verified', { curriculum })
    : t('flyout.verification_needed');

  const unverifiedContentText = !!verifiedDate
    ? t('flyout.last_verified', {
        curriculum,
        date: verifiedDate,
      }) +
      t('flyout.days', {
        count: verifyPeriod,
      })
    : t('flyout.not_yet_verified', { curriculum });

  const flyoutText = isVerifiedContent
    ? t('flyout.remain_verified', { date: nextVerifyDate })
    : unverifiedContentText;

  const flyoutSubtext = isVerifiedContent
    ? t('flyout.verified_by_you', { date: verifiedDate })
    : t('flyout.make_sure');

  const flyoutButtonText = isVerifiedContent
    ? t('flyout.edit_reminder_settings')
    : t('flyout.mark_as_verified');

  return {
    verifyTooltipText,
    verifyBadgeText,
    verifyBadgeType,
    flyoutTitleColor,
    flyoutHeaderBackgroundColor,
    flyoutTitle,
    flyoutText,
    flyoutSubtext,
    flyoutButtonText,
  };
}
