import styled from 'styled-components';

import { StyledEditorContent } from '../../shared/styles';

export const EditorWrapper = styled.div`
  width: 100%;
  p.is-empty.is-editor-empty {
    font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }
`;

export const StyledSurveyEditorContent = styled(StyledEditorContent)`
  // Override editor bottom padding to be always visible
  .ProseMirror {
    padding: ${({ theme: { constants } }) => `0 0 ${constants.spacerMd2} 0`};
    iframe {
      max-width: 100%;
    }
  }
`;
