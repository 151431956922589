import styled from 'styled-components';

import { rightSectionStyles } from '../../shared/FullScreenOverlay/styles';

export const CheckoutBillingDetailsWrapper = styled.div`
  ${rightSectionStyles};
`;

export const CheckoutContentWrapper = styled.div<{ withDiscountSection: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 32rem;
  ${({ withDiscountSection }) => withDiscountSection && { marginRight: '4.375rem' }};
`;

export const CheckoutHr = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${({ theme: { vars } }) => vars.borderDisabled};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const CheckoutPaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
`;
