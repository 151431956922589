import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { routes } from '../../publicApplication/applicationRouter';
import { CertificateIcon, CertificateLink, CertificateText } from './styles';

const t = initTranslations('home.curriculum_assignments');

type CompletionCertLinkProps = { completionId: number };

const CompletionCertLink = ({ completionId }: CompletionCertLinkProps) => {
  const { slug } = useCurrentAccount();

  return (
    <CertificateLink
      className='cert-link'
      href={routes.completionCertificate({ completionId, slug }).href}
      target='_blank'
      text={
        <>
          <CertificateText text={t('download_certificate')} />
          <CertificateIcon name='arrow-down-to-line' size='2xs' />
        </>
      }
    />
  );
};

export default CompletionCertLink;
