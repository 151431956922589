export enum LoadingSize {
  small = '2rem',
  medium = '6rem',
  large = '10rem',
}
export type SortProps =
  | { sortIcon?: never; isSortable: boolean }
  | { sortIcon: 'sort-up' | 'sort-down' | 'sort'; isSortable: true };

export type SortIcon = {
  sortIcon: 'sort-up' | 'sort-down';
};
