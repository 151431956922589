import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { ToggleContainer } from '../../../AdvancedSettings/styles';
import {
  FormSectionDescription,
  FormSectionTitle,
  ToggleInfoSection,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings');

const DownloadPDF = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <ToggleContainer>
        <Toggle
          checked={values.enable_printing}
          className='pdf-downloads-setting'
          handleToggle={() => setFieldValue('enable_printing', !values.enable_printing)}
          id='enable-pdf-downloads'
          name='enable_printing'
        />
      </ToggleContainer>
      <ToggleInfoSection>
        <div>
          <FormSectionTitle>{t('printing_title')}</FormSectionTitle>
          <FormSectionDescription>{t('printing_description')}</FormSectionDescription>
        </div>
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default DownloadPDF;
