import React from 'react';

import ProgressBar from '../../../../../design_system/Triage/ProgressBar';
import {
  CompletionContainer,
  CompletionScore,
  ProgressBarWrapper,
} from '../../../IndividualUserReportPage/IndividualUserReportTable/Columns/styles';
import { ColumnComponentProps } from '../types';

const CompletionPercentage = ({ record: { average_completion } }: ColumnComponentProps) => {
  return (
    <CompletionContainer>
      <CompletionScore>{average_completion}</CompletionScore>
      <ProgressBarWrapper>
        <ProgressBar percent={parseFloat(average_completion)} thickness='md' />
      </ProgressBarWrapper>
    </CompletionContainer>
  );
};

export default CompletionPercentage;
