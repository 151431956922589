import React from 'react';

import { Activity, ActivityAssociationParams } from '../../activities/types';
import Assign, { CurriculumAssignProps } from './Assign';
import UploadWithAi, { CurriculumUploadWithAiProps } from './UploadWithAi';

interface CurriculumsActivitiesProps {
  'curriculum.assign': React.FC<CurriculumAssignProps>;
  'curriculum.assign_owner': React.FC<CurriculumAssignProps>;
  'curriculum.assign-owner': React.FC<CurriculumAssignProps>;
  'curriculum.uploaded_with_ai': React.FC<CurriculumUploadWithAiProps>;
}

const ACTIVITY_KEYS: CurriculumsActivitiesProps = {
  'curriculum.assign': Assign,
  'curriculum.assign_owner': Assign,
  'curriculum.assign-owner': Assign,
  'curriculum.uploaded_with_ai': UploadWithAi,
};

interface CurriculumsActivity extends Omit<Activity, 'trackable'> {
  trackable: ActivityAssociationParams;
}

interface CurriculumsCardProps {
  activity: CurriculumsActivity;
}

const CurriculumNotificationsDetails = ({ activity }: CurriculumsCardProps) => {
  const { key, trackable, owner } = activity;
  const NotificationMessage = ACTIVITY_KEYS[activity.key as keyof CurriculumsActivitiesProps];

  return <NotificationMessage activityKey={key} owner={owner} trackable={trackable} />;
};

export default CurriculumNotificationsDetails;
