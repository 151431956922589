import React, { memo, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { useCurriculumElements } from '../../../../../contexts/CurriculumElementsContext';
import { useGetStepsQuery } from '../../../../../redux/services/resourceApis/steps/stepsApi';
import { MetaUserAccess } from '../../../../../types/Curriculum';
import TableLoader from '../../../shared/Loaders/TableLoader';
import OutlineStepTableRow from '../../CurriculumAdmin/CurriculumAdminBottom/CurriculumOutline/CurriculumOutlineElementRow/OutlineStepTableRow/OutlineStepTableRow';

type Props = {
  id: number;
  curriculumId: number;
  isLocked: boolean | undefined;
  isMobile: boolean;
  eSignatureDisplayedAndRequired: boolean;
  courseId: number;
  canBeModified: boolean;
  blockEditPrivileges: boolean;
  userAccess?: MetaUserAccess;
};

const StepsList = memo(
  ({
    id,
    curriculumId,
    isLocked,
    isMobile,
    eSignatureDisplayedAndRequired,
    courseId,
    canBeModified,
    blockEditPrivileges,
    userAccess,
  }: Props) => {
    const { data: steps, isLoading } = useGetStepsQuery({ courseId });
    const { setCourseSteps } = useCurriculumElements();

    useEffect(() => {
      if (steps) {
        setCourseSteps((prev) => ({ ...prev, [courseId]: steps }));
      }
    }, [courseId, setCourseSteps, steps]);

    if (isLoading) return <TableLoader />;
    if (!steps) return <></>;

    const isLastStep = steps.length === 1;
    const isDragDisabled =
      isMobile || isLocked || eSignatureDisplayedAndRequired || blockEditPrivileges || isLastStep;

    return (
      <>
        {steps.map((step, index) => {
          return (
            <Draggable
              draggableId={`step-${step.id}-curriculumElement-${id}-course-${courseId}`}
              index={index}
              isDragDisabled={isDragDisabled}
              key={step.id}
            >
              {(provided) => (
                <OutlineStepTableRow
                  blockEditPrivileges={blockEditPrivileges}
                  canBeModified={canBeModified}
                  curriculumId={curriculumId}
                  isLastStep={isLastStep}
                  isLocked={isLocked}
                  key={step.id}
                  provided={provided}
                  step={{ ...step, courseId }}
                  userAccess={userAccess}
                />
              )}
            </Draggable>
          );
        })}
      </>
    );
  }
);
StepsList.displayName = 'StepsList';

export default StepsList;
