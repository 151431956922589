import styled, { css } from 'styled-components';

import { fontMd2, fontMd3, fontSm3, fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { FeedbackCTA, FeedbackCtaWrapper, StyledSuccessContainer } from './ResultsFeedback/styles';

export const ResultsWrapper = styled.span<{ isNewSearchUiEnabled: boolean }>`
  ${({ isNewSearchUiEnabled, theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;

    ${isNewSearchUiEnabled &&
    css`
      padding: ${constants.spacerMd3};
      background-color: ${vars.foundationBase1};
      border-radius: ${constants.borderRadiusLg};
      margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};

      ${Wrapper} {
        margin-bottom: 0;
      }

      ${AnswerWrapper} {
        margin-bottom: 0;
      }
    `}
  `};
`;

export const SearchResultsHeaderContainer = styled.div<{ hasSmallTitle?: boolean }>`
  display: flex;
  justify-content: space-between;

  & > p:last-child {
    margin-top: 0;
  }

  ${({ hasSmallTitle }) =>
    hasSmallTitle &&
    css`
      // DS Override: Updated font size for small title according to new search UI
      [class^='PageHeader__StyledPageTitle'] {
        margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
        ${fontMd3};
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  min-height: 6.224rem;
  ${fontMd2};
  line-height: 1.8rem;
`;

export const ModelTag = styled.span`
  text-align: right;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm3};
`;

export const FoundIn = styled.span<{ isGrey: boolean }>`
  ${({ isGrey, theme: { vars } }) => css`
    color: ${isGrey ? vars.textSubdued : vars.textDefault};
    ${fontSm5};
  `}
`;

export const SourcesWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  flex-wrap: wrap;
`;

export const SemanticResults = styled.div<{ hasMinHeight: boolean }>`
  ${({ hasMinHeight }) =>
    hasMinHeight &&
    css`
      min-height: 3.924rem;
    `}
`;

export const FoundInWrapper = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const MainResults = styled.div`
  ${({ theme: { constants, vars } }) => css`
    padding: ${constants.spacerMd2};
    border: ${constants.borderWidthMd} solid ${vars.trainualBrandPurpleSurface};

    border-radius: ${constants.borderRadiusLg} ${constants.borderRadiusLg};
    background-color: ${vars.trainualBrandPurpleSurfaceLight};

    ${SearchResultsHeaderContainer} {
      margin-bottom: ${constants.spacerMd2};
    }

    ${FeedbackCtaWrapper} {
      margin-bottom: 0;
    }

    ${FeedbackCTA} {
      padding: 0;
      height: auto;

      color: ${vars.accentPrimaryDefault};
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-thickness: ${({ theme: { constants } }) =>
        constants.borderWidthLg} !important;

      background-color: transparent;
    }

    ${StyledSuccessContainer} {
      margin: ${constants.spacerMd3} 0 0 0;
      padding: 0;

      background-color: transparent;
      color: ${vars.textSubdued};
      ${fontSm4};

      [class^='svg'] {
        padding-right: ${constants.spacerSm2};

        color: ${vars.textSubdued};
      }
    }
  `};
`;
