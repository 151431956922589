import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../../hooks/useCurriculumElement';
import { getCurriculumElementRoute } from '../../../../shared/getCurriculumElementRoute';
import { TitleWrapperRoute } from '../../../IndividualUserReportPage/IndividualUserReportTable/Columns/styles';
import { ColumnComponentProps } from '../types';
import ElementContent from './ElementContent';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Title = ({ record }: ColumnComponentProps) => {
  const { slug } = useCurrentAccount();
  const { id, title, element_type } = record;

  const { iconName } = useCurriculumElement()({ elementType: element_type, elementId: id });

  const curriculumElementRoute = getCurriculumElementRoute({
    element: record,
    slug,
  });

  return curriculumElementRoute ? (
    <TitleWrapperRoute to={curriculumElementRoute}>
      <ElementContent element_type={element_type} iconName={iconName} id={id} title={title} />
    </TitleWrapperRoute>
  ) : (
    <TitleWrapper>
      <ElementContent element_type={element_type} iconName={iconName} id={id} title={title} />
    </TitleWrapper>
  );
};

export default Title;
