import styled from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';

export const UrlFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-right: 0;

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

export const StyledButton = styled(DefaultButton)`
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-left: 0;

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-top: 0;
    margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;
