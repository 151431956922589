import styled from 'styled-components';

export const SurveyStatisticsWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd3} ${constants.spacerMd3}`};
`;

export const StatisticsWrapper = styled.div<{ displayStartSurveyButton?: boolean }>`
  display: flex;
  justify-content: space-between;

  ${({ displayStartSurveyButton, theme: { constants } }) =>
    displayStartSurveyButton && `margin-bottom: ${constants.spacerMd3};`}

  div:last-child {
    border-right: none;
  }
`;
