import React, { useEffect } from 'react';

import { FlowchartControlProvider } from '../../../../contexts/FlowchartControlProvider';
import { useFlowchartHandlers } from '../../../../hooks/flowcharts/useFlowchartHandlers';
import { ConsumptionFlowchartResponse } from '../../../../redux/services/resourceApis/flowcharts/types';
import { ContentType } from '../../../../types/ContentType';
import ElementTopNav from '../../curriculums/shared/ElementTopNav/ElementTopNav';
import { Canvas } from '../shared/BaseFlowchart/styles';
import Flowchart from '../shared/Flowchart';
import Footer from './Footer';

type Props = {
  data: ConsumptionFlowchartResponse | undefined;
  isLoading: boolean;
};

const Body = ({ data, isLoading: isDelayedLoading }: Props) => {
  const flowchartHandlers = useFlowchartHandlers();
  const { setEdges, setNodes, storeConnectionsData } = flowchartHandlers;

  useEffect(() => {
    if (data) {
      const { content, connections } = data;

      if (!content) return;

      const { nodes, edges } = content;

      setNodes(nodes);
      setEdges(edges);
      storeConnectionsData(connections);
    }
  }, [data, storeConnectionsData, setEdges, setNodes]);

  return (
    <FlowchartControlProvider flowchartHandlers={flowchartHandlers} readonly>
      <ElementTopNav contentType={ContentType.Flowchart} data={data} isLoading={isDelayedLoading} />
      <Canvas>
        <Flowchart flowchartId={data?.id} isLoading={isDelayedLoading} />
      </Canvas>
      <Footer />
    </FlowchartControlProvider>
  );
};

export default Body;
