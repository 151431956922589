import { ReactNode } from 'react';
interface OptionArray {
  id: number;
  filterOn: string;
  title: ReactNode;
}

export default function setMultiSelectOptions<T extends OptionArray>(
  allOptions: T[],
  assignedOptions: { id: number }[],
  name: string
) {
  return allOptions.map(({ id, title: label, filterOn }) => {
    return {
      id,
      name,
      label,
      checked: assignedOptions.findIndex((assignedOption) => assignedOption.id === id) !== -1,
      filterOn,
    };
  });
}
