import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { IncludesProps } from '../../../../redux/services/resourceApis/checkout/types';
import {
  IncludesFeatureWrapper,
  IncludesTitle,
  IncludesWrapper,
  StyledFeatureIcon,
} from './styles';

const t = initTranslations('checkout_overlay.includes');

const Includes = ({ features, planName }: IncludesProps) => {
  return (
    <IncludesWrapper>
      <IncludesTitle text={t('title')} />
      {features.map((feature, index) => (
        <IncludesFeatureWrapper key={index}>
          <StyledFeatureIcon name='circle-check' planName={planName} weight='solid' />
          {feature}
        </IncludesFeatureWrapper>
      ))}
    </IncludesWrapper>
  );
};

export default Includes;
