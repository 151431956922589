import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Route } from 'type-route';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import {
  useCreateSurveyQuestionMutation,
  useUpdateSurveyQuestionPositionMutation,
} from '../../../../redux/services/resourceApis/surveyQuestions/surveyQuestionsApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { isMultimediaContentOnly } from '../../editor/shared/IsMultimediaContentOnly';
import { routes, useRoute } from '../../publicApplication/applicationRouter';
import { DragOverlayItem } from '../../shared/DragAndDrop/styles';
import { handleDragCancel, handleDragStart } from '../../shared/DragAndDrop/utils';
import { Scrollable } from '../shared/styles';
import { AddQuestionButtonWrapper, TransitionGroup } from './styles';
import SurveyQuestionListItem from './SurveyQuestionListItem';
import { SurveyOptionsProps } from './types';

const ACTIVATION_CONSTRAINT = {
  distance: 8,
};

const SURVEY_TRANSITION_TIMEOUT = 500;

const useDndSensors = () => {
  return useSensors(
    useSensor(PointerSensor, {
      activationConstraint: ACTIVATION_CONSTRAINT,
    })
  );
};

const t = initTranslations('editor.create_survey');

const SurveyQuestionsList = ({ questions }: SurveyOptionsProps) => {
  const route = useRoute();
  const {
    params: { slug, id: currentQuestionId, surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const sensors = useDndSensors();
  const [activeId, setActiveId] = useState<string | null>(null);
  const [
    createSurveyQuestion,
    {
      isLoading: createQuestionLoading,
      data: createQuestionData,
      isSuccess: createQuestionIsSuccess,
      reset: resetCreateQuestion,
    },
  ] = useCreateSurveyQuestionMutation();
  const [updateSurveyQuestionPosition, updateSurveyQuestionPositionResult] =
    useUpdateSurveyQuestionPositionMutation();
  const handleDragEnd = useCallback(
    ({ over, active }: DragEndEvent): void => {
      if (over) {
        const overIndex = questions.findIndex((question) => question.id === over.id);
        updateSurveyQuestionPosition({
          id: active.id as number,
          position: overIndex + 1,
          surveyId,
        });
      }
    },
    [questions, surveyId, updateSurveyQuestionPosition]
  );

  useDisplayFlashOnResponse({
    result: updateSurveyQuestionPositionResult,
    errorMessage: t('drag_failure'),
  });

  useEffect(() => {
    if (createQuestionIsSuccess && !createQuestionLoading && createQuestionData) {
      routes.surveyQuestionEditor({ surveyId, id: createQuestionData.questionId, slug }).push();
      resetCreateQuestion();
    }
  }, [
    createQuestionData,
    createQuestionIsSuccess,
    createQuestionLoading,
    resetCreateQuestion,
    slug,
    surveyId,
  ]);

  return (
    <Scrollable className='questions-wrapper' ref={scrollRef}>
      <DndContext
        collisionDetection={closestCorners}
        onDragCancel={() => handleDragCancel(setActiveId)}
        onDragEnd={handleDragEnd}
        onDragStart={({ active }) => handleDragStart({ active, setActiveId })}
        sensors={sensors}
      >
        <SortableContext items={questions} strategy={verticalListSortingStrategy}>
          <TransitionGroup className='questions-list'>
            {questions.map((question, index) => {
              const { id, plainText, text, textJson } = question;
              const isActive = id === currentQuestionId;
              const isMultimedia = isMultimediaContentOnly({
                plainText,
                content: text,
                contentJson: textJson,
              });

              return (
                <CSSTransition
                  classNames='question'
                  key={`question-${id}`}
                  timeout={SURVEY_TRANSITION_TIMEOUT}
                >
                  <SurveyQuestionListItem
                    index={index}
                    isActive={isActive}
                    questionId={id}
                    questionText={isMultimedia ? t('multimedia_question') : plainText}
                    surveyId={surveyId}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          <DragOverlay>{activeId && <DragOverlayItem height='3.5rem' />}</DragOverlay>
        </SortableContext>
      </DndContext>
      <AddQuestionButtonWrapper>
        <DefaultButton
          buttonType='secondary'
          fullWidth
          iconName='plus'
          id='add-survey-question-button'
          loading={createQuestionLoading}
          onClick={() => createSurveyQuestion({ surveyId })}
          text={t('add_question')}
          type='submit'
        />
      </AddQuestionButtonWrapper>
    </Scrollable>
  );
};

export default SurveyQuestionsList;
