import React from 'react';

import { useTrainingPathContext } from '../../../../../../contexts/TrainingPathContext';
import { useTrainingPathSetContext } from '../../../../../../contexts/TrainingPathSetContext';
import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';

const t = initTranslations('training_path.path_config.config_set');

const ForceOrderToggle = () => {
  const {
    trainingPathSet: { forceOrder, trainingPathSetUuid },
    updateTrainingPathSet,
  } = useTrainingPathSetContext();
  const { updateInProgress } = useTrainingPathContext();

  return (
    <Toggle
      checked={forceOrder}
      className='force-order-toggle'
      handleToggle={() => !updateInProgress && updateTrainingPathSet({ forceOrder: !forceOrder })}
      id={`force-order-toggle-set-${trainingPathSetUuid}`}
      labelPosition='right'
      name='force-order'
      primaryLabel={t('complete_in_order')}
    />
  );
};

export default ForceOrderToggle;
