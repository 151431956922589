import styled, { css } from 'styled-components';

import ThemedImage from '../../../design_system/ThemedImage';
import Card from '../../../design_system/Triage/layout/Card';
import { fontMd2, fontSm5 } from '../../../styled/TypeSystem';
import RouterLink from '../../publicApplication/RouterLink';
import Checkmark from '../Checkmark';

export const StyledCard = styled(Card)`
  padding: 1.125rem ${({ theme: { constants } }) => constants.spacerMd1} 5.625rem;
  position: relative;
  text-align: center;
  margin-bottom: 0;
`;

const cardLinkStyle = css`
  display: inline-block;
  text-decoration: none;
  color: inherit;
  max-width: 36rem;
  width: 100%;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
  &:hover {
    text-decoration: none;
    color: inherit;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out;
  }
`;

export const CardLink = styled.a`
  ${cardLinkStyle};
`;

export const CardRouterLink = styled(RouterLink)`
  ${cardLinkStyle};
`;

export const CardLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.75rem;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  border-bottom: ${({ theme: { constants } }) => constants.borderRadiusXs} solid
    ${({ theme: { vars } }) => vars.borderSurface1};
`;

export const StyledCardLogo = styled(ThemedImage)`
  max-height: 1.75rem;
  max-width: 100%;
`;

export const CardBody = styled.div`
  min-height: 9.2rem;
`;

export const CardHeader = styled.div`
  display: -webkit-inline-flex;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd1};
  margin-bottom: 0;
`;

export const CardTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontMd2};
`;

export const CardTooltip = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const CardDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  min-height: 5.625rem;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

export const CardReadMore = styled.span`
  display: inline-block;
  text-decoration: underline;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  ${fontSm5};
`;

export const StyledCheckmark = styled(Checkmark)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${({ theme: { constants } }) => constants.spacerLg1};
`;

export const BadgeBeta = styled.span`
  position: absolute;
  top: ${({ theme: { constants } }) => constants.spacerMd1};
  text-transform: uppercase;
  font-size: ${({ theme: { constants } }) => constants.spacerMd2};
  right: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.stateInfo};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;
