import { FormikErrors } from 'formik';

import initTranslations from '../../../../lib/initTranslations';
import { PasswordValues } from './InterfaceAndTypes';

const t = initTranslations('settings.password.password_modal');

export const validatePassword = ({
  current_password,
  password,
  password_confirmation,
}: PasswordValues) => {
  const errors: FormikErrors<PasswordValues> = {};

  // This is handled on the BE too, but best to keep here as the BE will have a jumpy effect when processing
  // If this validation were to be removed.
  if (!current_password) {
    errors.current_password = t('errors.current_password_is_empty');
  }

  if (!password) {
    errors.password = t('errors.new_password_is_empty');
  }

  if (!password_confirmation) {
    errors.password_confirmation = t('errors.confirm_password_is_empty');
  }

  if (password !== password_confirmation) {
    errors.password_confirmation = t('errors.passwords_do_not_match');
  }

  return errors;
};
