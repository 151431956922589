import React from 'react';
import styled from 'styled-components';

import Scrollbar from '../../../../../styled/Scrollbar';
import { People } from '../../ResultTypes';
import ResultTableHead from './ResultTableHead';
import ResultTableRow from './ResultTableRow';

const StyledResultTable = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  overflow: hidden;
`;

const ResultTableBody = styled.div`
  overflow-y: auto;
  max-height: 10rem;
  ${Scrollbar};
`;

interface Props {
  data: Array<People>;
}

const ResultTable = ({ data }: Props) => {
  return (
    <StyledResultTable>
      <ResultTableHead />
      <ResultTableBody>
        {data.map((user) => (
          <ResultTableRow key={`${user.name}-${user.id}`} user={user} />
        ))}
      </ResultTableBody>
    </StyledResultTable>
  );
};

export default ResultTable;
