import React, { useState } from 'react';

import { useGroup } from '../../../../../contexts/GroupContext';
import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import { usePatchGroupUsersMutation } from '../../../../../redux/services/resourceApis/groups/groupsApi';
import { User } from '../../../../../types/User';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';
import ThreeDotMenu from '../../../../design_system/Triage/menus/ThreeDotMenu';
import { ThreeDotMenuOptionProps } from '../../../../design_system/Triage/menus/ThreeDotMenu/types';
import TeammateModal from '../../../people/TeammateModal/TeammateModal';

const t = initTranslations('groups.groups_edit.members.table.three_dot_actions');

type ActionMenuProps = {
  memberIds: number[];
  member: User;
};

const ActionMenu = ({ memberIds, member }: ActionMenuProps) => {
  const group = useGroup();
  const { setTeammateModalVisible } = useTeammateModal();
  const menuId: RegisteredMenuId = `group-member-${member.id}`;
  const [unassignUsersFromGroup, result] = usePatchGroupUsersMutation();
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] = useState(false);

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('remove_error', { groupName: group.name, memberName: member.name }),
  });

  const menuOptions: ThreeDotMenuOptionProps[] = [
    {
      title: t('edit_user'),
      onClick: () => {
        setTeammateModalVisible(true);
      },
      iconName: 'pencil',
      id: 'td-groups-edit-members-table-edit-user',
      visible: true,
    },
    {
      title: t('remove_from_group'),
      onClick: () => setShowRemoveConfirmationModal(true),
      iconName: 'xmark-circle',
      id: 'td-groups-edit-members-table-remove-user-from-group',
      visible: !group.everyone,
    },
  ];

  return (
    <>
      <ThreeDotMenu id={menuId} menuOptions={menuOptions} />
      <TeammateModal action='edit' />
      {showRemoveConfirmationModal && (
        <ConfirmationModal
          actionFunction={() => {
            unassignUsersFromGroup({
              groupId: group.id,
              user_ids: memberIds.filter((id) => id !== member.id),
            });
          }}
          actionText={t('remove_group_member_confirmation_modal.action_text')}
          hasPrimaryButton
          heapModalName='remove-group-member-confirmation-modal'
          message={t('remove_group_member_confirmation_modal.message')}
          onCloseRequest={() => setShowRemoveConfirmationModal(false)}
          processing={false}
          title={t('remove_group_member_confirmation_modal.title')}
        />
      )}
    </>
  );
};

export default ActionMenu;
