import { Form, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { OrganizationProfileData } from '../../../../redux/services/resourceApis/accountSettings/types';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { formatOptions } from '../../../design_system/core/CoreSelectField/CoreSelectField';
import { Option } from '../../../design_system/core/SelectOption/types';
import SingleSelect from '../../../design_system/Triage/fields/SingleSelectField/SingleSelectField';
import InputField from '../../../design_system/Triage/InputField';
import { ButtonRightPositionWrapper, InputRow, StyledFormGroup } from './styles';
import { OrganizationProfileFormProps } from './types';

const t = initTranslations('account_settings.organization_profile');

const OrganizationProfileForm = ({
  availableOrganizationSizes,
  availableIndustries,
  isLoading,
}: OrganizationProfileFormProps) => {
  const { values, touched, errors, handleSubmit, handleChange, setFieldValue, initialValues } =
    useFormikContext<OrganizationProfileData>();

  const isValuesUnchanged = isEqual(values, initialValues);

  const industryOption: Option[] = formatOptions(
    availableIndustries.map((option) => ({
      label: option,
      value: option,
    }))
  );
  const employeesOption: Option[] = formatOptions(
    availableOrganizationSizes.map((option) => ({
      label: option,
      value: option,
    }))
  );

  return (
    <Form
      autoComplete='off'
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSubmit();
        }
      }}
    >
      <InputRow>
        <StyledFormGroup>
          <InputField
            errorText={touched.name && errors.name}
            id='organization-name'
            label={t('organization_name')}
            name='name'
            onChange={handleChange}
            type='text'
            value={values.name}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <SingleSelect
            className='industry'
            defaultValue={values.industry}
            fieldLabelText={t('industry')}
            name='industry'
            onNonDefaultSelected={(industry: string) => setFieldValue('industry', industry)}
            options={industryOption}
            size='md'
            value={values.industry}
          />
        </StyledFormGroup>
      </InputRow>
      <InputRow>
        <StyledFormGroup>
          <SingleSelect
            className='employee_size'
            defaultValue={values.employee_size}
            fieldLabelText={t('of_employees')}
            name='employeeSize'
            onNonDefaultSelected={(employee: string) => setFieldValue('employee_size', employee)}
            options={employeesOption}
            size='md'
            value={values.employee_size}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <InputField
            errorText={touched.phone && errors.phone}
            id='phone-number'
            label={t('phone_number')}
            name='phone'
            onChange={handleChange}
            placeholder='(000) 000-0000'
            type='text'
            value={values.phone || ''}
          />
        </StyledFormGroup>
      </InputRow>
      <ButtonRightPositionWrapper>
        <DefaultButton
          disabled={isValuesUnchanged}
          id='save-organization-profile'
          loading={isLoading}
          text={t('save_changes')}
        />
      </ButtonRightPositionWrapper>
    </Form>
  );
};

export default OrganizationProfileForm;
