import React, { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

import { useAccountRoutesContext } from '../../../../contexts/AccountRoutesContext';
import { useCheckNoTrialFlow } from '../../../../hooks/billing/useCheckNoTrialFlow';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import usePageTitle from '../../../../hooks/usePageTitle';
import initTranslations from '../../../../lib/initTranslations';
import { AccountStatus, BackdropSetupStatus } from '../../../../types/AccountState';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import { mediaBreakpointPxLg } from '../../../styled/Breakpoint';
import RouteTo from '../../publicApplication/routeTo';
import SaveCaptureToStepModal from '../../steps/modals/trainualCapture/SaveCaptureToStepModal';
import HomePageContent from '../HomePageContent/HomePageContent';
import { Onboarding } from '../Onboarding/Onboarding';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: row;
  }
`;

type ShowSignUpModalProps = [BackdropSetupStatus, boolean, AccountStatus];

const t = initTranslations('home_page');

const canShowOnboardingFlow = (...args: ShowSignUpModalProps) => {
  const [backdropSetupStatus, isNoTrialFlow, status] = args;
  const signUpCompleted = ['backdrop_setup_finished', 'created_with_partner_admin_api'].includes(
    backdropSetupStatus
  );
  const accountNotActive = status !== 'active';
  const isNonActiveNoTrialBackdrop = accountNotActive && isNoTrialFlow;

  if (!signUpCompleted) {
    return !isNonActiveNoTrialBackdrop;
  } else {
    return false;
  }
};

const HomePage = () => {
  const accountRoutes = useAccountRoutesContext();
  const isNoTrialFlow = useCheckNoTrialFlow();
  const { productTerm } = useAccountTerminology();

  usePageTitle({ resourceName: t('home'), resourceType: 'home', productTerm });

  const { status: accountStatus, backdropSetupStatus } = useCurrentAccount();

  const showOnboardingFlow = useMemo(() => {
    return canShowOnboardingFlow(backdropSetupStatus, isNoTrialFlow, accountStatus);
  }, [backdropSetupStatus, accountStatus, isNoTrialFlow]);

  // Sends user to curriculum duplication if they are copying a public curriculum
  // Cookie is removed on that endpoint once curriculum is saved
  const [cookies] = useCookies(['public_curriculum_id']);
  useEffect(() => {
    const { public_curriculum_id } = cookies;

    if (!showOnboardingFlow && public_curriculum_id) {
      RouteTo(accountRoutes.view.duplicateCurriculum({ publicCurriculumId: public_curriculum_id }));
    }
  }, [showOnboardingFlow, cookies, accountRoutes.view, backdropSetupStatus]);

  return (
    <>
      <PageContainer id='home-page'>
        <HomePageContent />
      </PageContainer>
      <SaveCaptureToStepModal />
      {showOnboardingFlow && <Onboarding />}
    </>
  );
};

export default HomePage;
