import styled from 'styled-components';

import { mediaBreakpointPxLg, mediaBreakpointPxXl } from '../../../../../styled/Breakpoint';
import { MainSectionContent } from '../../../../shared/FullScreenOverlay/styles';
import { PaymentSummaryWrapper } from '../../../shared/BillingFullScreenOverlay/styles';

const PAYMENT_SECTION_WIDTH_MEDIUM = '20rem';
const PAYMENT_SECTION_WIDTH_LARGE = '26.5rem';

export const StyledMainSectionContent = styled(MainSectionContent)`
  max-width: 36.25rem;
  gap: 4rem;
`;

export const StyledMainSectionWrapper = styled.div`
  display: flex;
  flex-basis: ${({ theme: { constants } }) =>
    `calc(100% - ${PAYMENT_SECTION_WIDTH_MEDIUM} - ${constants.spacerMd3})`};
  justify-content: center;
  margin-left: 0.5rem;
  padding: ${({ theme: { constants } }) =>
    `4.625rem ${constants.spacerMd3} ${constants.spacerLg1}`};

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-basis: ${({ theme: { constants } }) =>
      `calc(100% - ${PAYMENT_SECTION_WIDTH_LARGE} - ${constants.spacerMd3})`};
  }

  @media (min-width: ${mediaBreakpointPxXl}) {
    padding: ${({ theme: { constants } }) =>
      `4.625rem ${constants.spacerLg3} ${constants.spacerLg1} 0`};
  }
`;

export const StyledPaymentSummaryWrapper = styled(PaymentSummaryWrapper)`
  position: fixed;
  width: ${PAYMENT_SECTION_WIDTH_MEDIUM};
  right: 0;
  top: 0;
  bottom: 0;

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: ${PAYMENT_SECTION_WIDTH_LARGE};
  }
`;
