import styled from 'styled-components';

import IconButton from '../../../design_system/buttons/IconButton';
import Link from '../../../design_system/Link';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontMd4, fontSm5 } from '../../../styled/TypeSystem';

export const Container = styled.div`
  width: 100%;
  max-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} 0`};
  margin-top: ${({ theme: { constants } }) => constants.spacerLg3};
`;

export const Title = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};

  ${fontMd4}
`;

export const RoundedCard = styled.div`
  max-width: 18rem;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${(props) => props.theme.constants.borderRadiusLg};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  overflow: hidden;
`;

export const AnimatedSvgElement = styled.g`
  transition: transform 0.5s ease-in-out;
`;

export const VideoSection = styled.div`
  height: 60%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .explain-inspire-video-play-button:hover + svg {
    .animated-image-element {
      transform: scale(1.08) translateX(-10px);
    }

    .animated-background-element {
      transform: scale(1.3) translate(-50px, -25px);
    }

    .animated-library-background-element {
      transform: scale(1.05) translateX(2px);
    }

    .animated-text-element {
      transform: translate(5px, -5px);
    }

    .animated-ray-element {
      transform: translate(-5px, -5px);
    }
  }
`;

export const TitleSection = styled.div`
  height: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerMd3}`};

  ${fontSm5}
`;

export const PlayButton = styled(IconButton)`
  height: ${({ theme: { constants } }) => constants.heightLg};
  width: ${({ theme: { constants } }) => constants.heightLg};
  background: ${({ theme: { vars } }) => vars.textSurface};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 148px;
  left: 20px;
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.5s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  }

  & > * {
    transition: transform 0.5s ease-in-out;
  }

  &:hover {
    transform: scale(1.2);

    & > * {
      transform: scale(0.85);
    }
  }
`;

export const DismissLink = styled(Link)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const VideoPlayer = styled.iframe<{ isVisible: boolean }>`
  width: 100%;
  height: 11rem;
  border: none;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: 60%;
    height: 25rem;
  }
`;
