import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import mergeArrayElements from '../../../../../../lib/mergeArrayElements';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { BrandText, DefaultDataStyled, StyledText, StyledTextWrapper } from '../Styles';
import { ContentAlignment } from '../TableTypes';

const t = initTranslations('users_table');

interface Props {
  data?: string | string[] | number | { date: string; time: string } | null;
  className?: string;
  contentAlignment?: ContentAlignment;
  id: string;
}

const TableBodyCell = ({ data, className, contentAlignment = 'start', id }: Props) => {
  const isArray = Array.isArray(data);
  const title = isArray ? data[0] : data;

  return (
    <DefaultDataStyled>
      <StyledTextWrapper contentAlignment={contentAlignment}>
        <StyledText className={className}>{title}</StyledText>
        {isArray && data.length > 1 && (
          <>
            <Tooltip id={id} text={mergeArrayElements(data, 3)} />
            <div data-for={id} data-tip>
              <BrandText>{t('headers.see_all')}</BrandText>
            </div>
          </>
        )}
      </StyledTextWrapper>
    </DefaultDataStyled>
  );
};

export default TableBodyCell;
