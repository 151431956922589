import { CalculateSensorPositionProps, CalculateSensorPositionReturn } from './types';

export const calculateScrollPercentage = (
  scrollX: number,
  scrollWidth: number,
  clientWidth: number
): number => (scrollX / (scrollWidth - clientWidth)) * 100;

export const calculateSensorPosition = ({
  mouseX,
  miniColumnsHolderWidth,
  sensorWidth,
}: CalculateSensorPositionProps): CalculateSensorPositionReturn => {
  const maxSensorPositionX = miniColumnsHolderWidth - sensorWidth;
  const newSensorPositionX = (mouseX * maxSensorPositionX) / 100;
  const boundedSensorPositionX = Math.max(0, Math.min(newSensorPositionX, maxSensorPositionX));

  return {
    newSensorPositionX: boundedSensorPositionX,
    maxSensorPositionX,
  };
};
