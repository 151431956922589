import React from 'react';

import useSlideout from '../../../../../../hooks/useSlideout';
import initTranslations from '../../../../../../lib/initTranslations';
import { setUserAssignmentReportSearchTerm } from '../../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import SearchField from '../../../../../design_system/Triage/fields/SearchField';
import {
  ActionButtons,
  ActionsHeaderContainer,
  ElementsCount,
  RightAlignedHeaderActions,
  SearchFieldWrapper,
} from '../../../AllUsersReportPage/AllUsersReportTable/ActionsHeader/styles';
import UserAssignmentDownloadCsvReport from '../../UserAssignmentDownloadCsvReport';
import { TableActionsHeaderProps } from './types';

const t = initTranslations('reports.actions_header');

const TableActionsHeader = ({
  isFetching,
  totalAssignmentsCount,
  curriculumId,
}: TableActionsHeaderProps) => {
  const dispatch = useAppDispatch();
  const { filters, searchTerm } = useAppSelector((state) => state.reports.userAssignmentReport);
  const { groupIds, completionScoreMin = 0, completionScoreMax = 100 } = filters;

  const appliedFiltersCount =
    groupIds.length + (completionScoreMin > 0 || completionScoreMax < 100 ? 1 : 0);

  const { close, open, isOpen } = useSlideout('user-assignment-report-filter-slideout');

  const toggleFilters = () => {
    isOpen ? close() : open();
  };

  return (
    <ActionsHeaderContainer className='users-outline-table-action-header'>
      <ElementsCount>{t('users_count', { count: totalAssignmentsCount })}</ElementsCount>
      <RightAlignedHeaderActions>
        <SearchFieldWrapper>
          <SearchField
            dataLoading={isFetching}
            placeholder={t('search_placeholder')}
            searchValue={searchTerm}
            setSearchValue={(value: string) => dispatch(setUserAssignmentReportSearchTerm(value))}
          />
        </SearchFieldWrapper>
        <ActionButtons>
          <DefaultButton
            buttonType='secondary'
            iconName='sliders'
            iconWeight='solid'
            id='user-assignment-report-filter-btn'
            onClick={toggleFilters}
            size='md'
            text={t('filters', { count: appliedFiltersCount })}
          />
          <UserAssignmentDownloadCsvReport curriculumId={curriculumId} />
        </ActionButtons>
      </RightAlignedHeaderActions>
    </ActionsHeaderContainer>
  );
};

export default TableActionsHeader;
