import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { AddonDataQuestionsAndAnswers } from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { AddonSectionTitle } from '../styles';
import CollapsibleItem from './CollapsibleItem/CollapsibleItem';

const t = initTranslations('simple_pricing.addon_overlay_content.questions_and_answers');

type QuestionsAndAnswersProps = {
  questionsAndAnswers: AddonDataQuestionsAndAnswers[];
};

const QuestionsAndAnswers = ({ questionsAndAnswers }: QuestionsAndAnswersProps) => {
  return (
    <section id='addon-overlay-questions-and-answers'>
      <AddonSectionTitle id='addon-overlay-questions-and-answers-title'>
        {t('title')}
      </AddonSectionTitle>
      <div id='questions-and-answers-wrapper'>
        {questionsAndAnswers.map(({ question, answer }) => (
          <CollapsibleItem key={question} title={question}>
            <div dangerouslySetInnerHTML={{ __html: answer }} />
          </CollapsibleItem>
        ))}
      </div>
    </section>
  );
};

export default QuestionsAndAnswers;
