import React, { useContext } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import titleize from '../../../../lib/titleize';
import { useGetCurriculumForEditQuery } from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { AccountTerminologyContext } from '../../../AccountTerminologyProvider';
import Emoji from '../../../design_system/display/Emoji/Emoji';
import ProfileRouterLink from '../../people/Profile/ProfileRouterLink';
import { routes } from '../../publicApplication/applicationRouter';
import ReportHeadRightColumn from '../IndividualUserReportPage/ReportHeadCard/ReportHeadRightColumn/ReportHeadRightColumn';
import {
  ReportHeadContainer,
  ReportHeadLastActive,
  ReportHeadReportsTo,
} from '../IndividualUserReportPage/ReportHeadCard/styles';
import {
  ReportHeadInfo,
  ReportHeadLeftColumn,
  ReportHeadRightColumnWrapper,
  ReportHeadSubtitle,
  ReportHeadTitle,
  StyledRouterLink,
  StyledSpan,
} from './styles';

const t = initTranslations('reports.user_assignment_report_table.curriculum_card');

const CurriculumReportHeadCard = ({ id }: { id: number }) => {
  const { slug } = useCurrentAccount();
  const { curriculum } = useContext(AccountTerminologyContext);
  const { data, isError } = useGetCurriculumForEditQuery(id);

  if (isError) return <div>{t('error')}</div>;
  if (!data) return <></>;

  const {
    completion_percentage: completionPercentage,
    emoji,
    groups_assigned: groupsAssigned,
    title,
    owner,
    sector,
    updated_at: updatedAt,
  } = data;

  return (
    <>
      <ReportHeadContainer className='report-head-container'>
        <ReportHeadLeftColumn>
          <StyledRouterLink
            className='emoji-styled-router-link'
            to={routes.curriculumEdit({
              slug,
              id,
            })}
          >
            <Emoji emoji={emoji} id='curriculum emoji' />
          </StyledRouterLink>
          <ReportHeadInfo>
            <StyledRouterLink
              to={routes.curriculumEdit({
                slug,
                id,
              })}
            >
              <ReportHeadTitle>{title}</ReportHeadTitle>
            </StyledRouterLink>
            <ReportHeadSubtitle>{titleize(sector)}</ReportHeadSubtitle>
            <ReportHeadReportsTo>
              {t('owned_by')}&nbsp;
              {owner ? (
                <>
                  <ProfileRouterLink breadcrumb={routes.manageUsers({ slug })} id={owner.id}>
                    <span>{owner.name}</span>
                  </ProfileRouterLink>
                  {owner.archived && (
                    <StyledSpan> {initTranslations('users')('owner_archived')}</StyledSpan>
                  )}
                </>
              ) : (
                t('not_set', { curriculum: curriculum.singular.toLowerCase() })
              )}
            </ReportHeadReportsTo>
            <ReportHeadLastActive>
              {t('last_updated')} <b>{updatedAt}</b>
            </ReportHeadLastActive>
          </ReportHeadInfo>
        </ReportHeadLeftColumn>
        <ReportHeadRightColumnWrapper>
          <ReportHeadRightColumn
            completionPercentage={completionPercentage}
            completionTitle={t('average_completion_rate')}
            groups={groupsAssigned}
          />
        </ReportHeadRightColumnWrapper>
      </ReportHeadContainer>
    </>
  );
};

export default CurriculumReportHeadCard;
