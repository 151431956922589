import { Dispatch, useMemo } from 'react';

import { useAccountTerminology } from '../components/AccountTerminologyProvider';
import { TableAction } from '../components/application/ContentLibrary/ContentLibraryPage/libraryReducer';
import initTranslations from '../lib/initTranslations';
import { ContentLibraryCurriculum } from '../types/Curriculum';
import { useIsAdmin } from './users/useIsPermission';

export type EmptyStateData = {
  action?: () => void;
  actionMessage?: string;
  message: string;
} | null;

const t = initTranslations('curriculums.content');

export const useEmptyStateData = (
  curriculums: ContentLibraryCurriculum[],
  isFiltering: boolean,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  tableDispatch: Dispatch<TableAction>
): EmptyStateData => {
  const {
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();
  const isAnAdmin = useIsAdmin();

  return useMemo(() => {
    if (!curriculums.length) {
      const message = isFiltering
        ? t('filter_empty_state.description')
        : t('empty_state.description');

      if (isAnAdmin) {
        const action = isFiltering
          ? () => tableDispatch({ type: 'resetFilters' })
          : () => setOpenModal(true);
        const actionMessage = isFiltering
          ? t('filter_empty_state.action_message')
          : t('empty_state.action_message', {
              subject: curriculumSingular.toLowerCase(),
            });

        return { action, actionMessage, message };
      } else {
        return { message };
      }
    }

    return null;
  }, [curriculums.length, isAnAdmin, curriculumSingular, isFiltering, setOpenModal, tableDispatch]);
};
