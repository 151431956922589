import React from 'react';

import { ColumnComponentProps, SurveyReportColumn } from '../types';
import AverageCorrectAnswers from './AverageCorrectAnswers';
import Order from './Order';
import Question from './Question';

type TableColumnsProps = {
  [key in Exclude<SurveyReportColumn, 'actions'>]: React.FC<ColumnComponentProps>;
};

const COLUMNS_KEYS: TableColumnsProps = {
  position: Order,
  text: Question,
  avg_correct_answers: AverageCorrectAnswers,
};

const TableColumn = ({ columnName, record }: ColumnComponentProps) => {
  const Column = COLUMNS_KEYS[columnName as keyof TableColumnsProps];

  return <Column columnName={columnName} record={record} />;
};

export default TableColumn;
