import {
  customDomainsFeatureIconStyles,
  delegationPlannerFeatureIconStyles,
  eSignatureFeatureIconStyles,
  multiLanguageFeatureIconStyles,
  trainingPathFeatureIconStyles,
} from '../../components/application/billing/simple_pricing/AddonFullScreenOverlays/AddonTrainualPlusFullScreenOverlay/FeatureSection/styles';
import { AddonTrainualPlusFeatureKind } from '../../redux/services/resourceApis/billing/simplePricingTypes';

export function getAddonTrainualPlusFeatureIconStyles(kind: AddonTrainualPlusFeatureKind) {
  switch (kind) {
    case 'delegation_planner':
      return delegationPlannerFeatureIconStyles;
    case 'e_signature':
      return eSignatureFeatureIconStyles;
    case 'training_path':
      return trainingPathFeatureIconStyles;
    case 'multi_language':
      return multiLanguageFeatureIconStyles;
    case 'custom_domains':
      return customDomainsFeatureIconStyles;
  }
}
