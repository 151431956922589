import styled from 'styled-components';

import SingleSelectField from '../../../../../design_system/Triage/fields/SingleSelectField';
import { fontMd1, fontSm5 } from '../../../../../styled/TypeSystem';

export const TotalSeatsSelectWrapper = styled.div<{ isLoading: boolean }>`
  max-width: 12.875rem;
  width: 100%;

  ${({ isLoading }) => isLoading && 'opacity: 0.5;'};
`;

// DS Override - to match design
export const StyledSelect = styled(SingleSelectField)<{ isValid: boolean; loading: boolean }>`
  ${({ loading }) => loading && 'cursor: not-allowed;'};

  .react-select__control {
    border: ${({ isValid, theme: { constants, vars } }) =>
      `${constants.borderWidthLg} solid ${
        isValid ? vars.trainualBrandBlueStrong : vars.stateError
      } !important`};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
    height: 2.375rem;
    ${({ loading }) => loading && 'pointer-events: none'};
  }
  .react-select__menu {
    margin-top: ${({ theme: { constants } }) =>
      `calc(${constants.spacerSm1} + ${constants.spacerSm2})`};
    ${fontSm5};
  }
  .react-select__menu-list {
    max-height: 9.75rem;
  }
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd1};
`;

export const StyledLabel = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm5};
`;
