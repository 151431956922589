import styled from 'styled-components';

import Link from '../../../../../design_system/Link';
import { mediaBreakpointPxLg } from '../../../../../styled/Breakpoint';
import { fontMd3, fontSm4 } from '../../../../../styled/TypeSystem';

export const AddonSectionTitle = styled.h5`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-top: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd3};
`;

// Bottom padding needs to prevent problems with scrolling on the bottom of the container
export const TermsOfServiceWrapper = styled.div`
  color: ${({ theme: { vars } }) => vars.foundationBase4};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerLg2};

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding-bottom: ${({ theme: { constants } }) => constants.spacerLg3};
  }

  ${fontSm4};
`;

export const TermsOfServiceLink = styled(Link)`
  ${fontSm4};
`;
