import styled from 'styled-components';

import DefaultButton from '../../../../../../../../design_system/buttons/DefaultButton';
import { fontSm4 } from '../../../../../../../../styled/TypeSystem';

export const ToolbarWrapper = styled.div`
  position: relative;
  min-height: ${({ theme: { constants } }) => constants.spacerLg3};
`;

export const HiddenElementsWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;

  div[class*='Poppable__Wrapper'] {
    inset: initial !important;
    transform: translate(-50%, -95%) !important;
    position: absolute !important;
  }

  div[class*='ToolbarContainer'] {
    div[class*='FlyoutPoppableContainer'] {
      button {
        padding-top: 0;
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
`;

export const ToolbarItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledRecordButton = styled(DefaultButton)`
  img {
    margin-right: ${({ theme: { constants } }) => constants.spacerSm1};
  }
`;

export const EmptyStateImage = styled.img`
  width: 8.5rem;
  height: auto;

  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const EmptyStateDescription = styled.span`
  max-width: 15rem;
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  ${fontSm4};
`;

export const RecordingInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  margin: ${({ theme: { constants } }) => constants.spacerMd2} 0;

  ${fontSm4};
`;
