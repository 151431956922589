import React, { useMemo } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { CompletionAction } from '../../../../../redux/services/resourceApis/curriculums/types';
import ObjectIsland from '../../../people/Shared/ObjectIsland/ObjectIsland';
import { ObjectIslandAction } from '../../../people/Shared/ObjectIsland/types';

const BulkActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 3rem;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
  max-width: 80rem;
  min-width: 60rem;
`;

interface BulkCompletionsActionsMenuProps {
  selectedCount: number;
  onCloseRequest: () => void;
  handleBulkActionSelection: (action: CompletionAction) => void;
}

const t = initTranslations('curriculum_edit.manage_completions.action_menu');

const BulkCompletionsActionsMenu = ({
  selectedCount,
  onCloseRequest,
  handleBulkActionSelection,
}: BulkCompletionsActionsMenuProps) => {
  const actions: ObjectIslandAction[] = useMemo(() => {
    const baseActions: ObjectIslandAction[] = [
      {
        label: t('clear_completions'),
        icon: 'circle-xmark',
        onClick: () => {
          handleBulkActionSelection('clear');
        },
      },
      {
        label: t('mark_complete'),
        icon: 'check',
        onClick: () => {
          handleBulkActionSelection('complete');
        },
      },
    ];

    return baseActions;
  }, [handleBulkActionSelection]);

  return (
    <BulkActionsContainer className='manage-completions-bulk-actions-menu'>
      <ObjectIsland
        actions={actions}
        onCloseRequest={onCloseRequest}
        selectedCount={selectedCount}
      />
    </BulkActionsContainer>
  );
};

export default BulkCompletionsActionsMenu;
