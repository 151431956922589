import React, { useState } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { GroupWithResponsibilities } from '../../../../../types/GroupWithResponsibilities';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Icon from '../../../../design_system/display/icons/Icon';
import FlexContainer from '../../../../styled/FlexContainer';
import { StyledEmptyMessage } from '../../../../styled/StyledEmptyMessage';
import { routes } from '../../../publicApplication/applicationRouter';
import RouterLink from '../../../publicApplication/RouterLink';
import { getChevronIconName } from '../../../shared/helpers';
import ResponsibilityNameList from '../UserGroupResponsibilities/ResponsibilityNameList';

const StyledGroupLi = styled.li`
  margin: ${({ theme: { constants } }) => `${constants.spacerLg1} 0 -${constants.spacerSm3} 0`};
`;

const GroupNameLink = styled(RouterLink)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const StyledGroupName = styled.h6`
  font-size: 0.875rem;
`;

const StyledGroupTitle = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: 0.625rem;
`;

const StyledLine = styled.hr`
  background-color: ${({ theme: { vars } }) => vars.foundationHover};
  margin: 0;
  height: 1px;
  border: none;
  flex-wrap: wrap;
`;

const IconContainer = styled.div`
  padding: 0.05rem ${({ theme: { constants } }) => constants.spacerSm3};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  }
`;

const StyledIcon = styled(Icon)`
  width: 0.75rem;
  height: 0.75rem;
`;

const t = initTranslations('users_profile');

interface Props {
  group: GroupWithResponsibilities;
  adminView: boolean;
}

const GroupLi = ({ group, adminView }: Props) => {
  const { onHoldPlan, slug, status: accountsStatus } = useCurrentAccount();
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();
  const hasResponsibilities = group.responsibilities.length > 0;
  const [expanded, setExpanded] = useState(hasResponsibilities);
  const dynamicChevron = getChevronIconName({ isActive: expanded, initialDirection: 'up' });
  const isTrialEndedOrOnHold = accountsStatus === 'trial_ended' || onHoldPlan;
  const canUpdateGroup = adminView && !isTrialEndedOrOnHold;
  const groupRoute = routes.group({
    slug,
    id: group.id,
    breadcrumb: routes.groups({ slug }),
    tab: 'overview',
  });

  if (group.everyone) return <></>;

  return (
    <StyledGroupLi id={`assigned-group-${group.id}`}>
      <StyledGroupTitle align='center' direction='row'>
        {canUpdateGroup ? (
          <GroupNameLink to={groupRoute}>{group.name}</GroupNameLink>
        ) : (
          <StyledGroupName>{group.name}</StyledGroupName>
        )}
        <IconContainer
          id={`assigned-group-responsibilities-chevron-${group.id}`}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <StyledIcon name={dynamicChevron} weight='solid' />
        </IconContainer>
      </StyledGroupTitle>

      {expanded &&
        (hasResponsibilities ? (
          <>
            <StyledLine />
            <ResponsibilityNameList isGroup responsibilities={group.responsibilities} />
          </>
        ) : (
          <StyledEmptyMessage id={`assigned-group-responsibilities-empty-state-${group.id}`}>
            {t('no_group_responsibilities', {
              responsibilities: responsibilityPlural.toLowerCase(),
            })}
            {canUpdateGroup && <RouterLink to={groupRoute}>{t('edit_group')}</RouterLink>}
          </StyledEmptyMessage>
        ))}
    </StyledGroupLi>
  );
};

export default GroupLi;
