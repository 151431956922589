import React, { useEffect, useMemo, useReducer } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import emptyStateImage from '../../../../images/empty_state/curriculums-assignments-empty-state.svg';
import initTranslations from '../../../../lib/initTranslations';
import { useGetAssignmentsCurriculumsQuery } from '../../../../redux/services/resourceApis/home/homeApi';
import { TrainingPathTag } from '../../../../redux/services/resourceApis/trainingPaths/types';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import NoResults from '../../../design_system/Triage/NoResults';
import Pagination from '../../../design_system/Triage/Paginate';
import CaughtUpForNow from '../CaughtUpForNow/CaughtUpForNow';
import CurriculumsAssignmentsTabs from '././CurriculumsAssignmentsTabs/CurriculumsAssignmentsTabs';
import CurriculumsAssignmentsItem from '././CurriculumsAssignmentstItem/CurriculumsAssignmentsItem';
import {
  CurriculumsAssignmentsItemKind,
  CurriculumsAssignmentsItemProps,
  SortingKind,
} from './CurriculumsAssignmentstItem/types';
import { curriculumsAssignmentsReducer } from './reducer';
import SortingDropdown from './SortingDropdown/SortingDropdown';
import SortingDropdownMenuOption from './SortingDropdown/SortingDropdownMenuOption/SortingDropdownMenuOption';
import {
  CurriculumsAssignmentsHead,
  CurriculumsAssignmentsList,
  CurriculumsAssignmentsListWrapper,
  CurriculumsAssignmentsSubtitle,
} from './styles';
import CurriculumsAssignmentsLoadingSkeleton from './TabsLoadingSkeleton/CurriculumAssignmentsLoadingSkeleton';
import TabsLoadingSkeleton from './TabsLoadingSkeleton/TabsLoadingSkeleton';
import { State } from './types';

type CurriculumsAssignmentsProps = {
  trainingPathHasUpcomingSet: boolean;
  userId: number;
  trainingPathTag?: TrainingPathTag;
};

const t = initTranslations('home.curriculum_assignments');

const CurriculumsAssignments = ({
  userId,
  trainingPathTag = 'published',
  trainingPathHasUpcomingSet,
}: CurriculumsAssignmentsProps) => {
  const {
    curriculum: { plural: curriculums },
  } = useAccountTerminology();
  const { accountPlan } = useCurrentAccount();
  const isBuildPlan = accountPlan === 'build';
  const incomplete = 'incomplete';
  const reference = 'reference';
  const curriculumTitle = 'curriculum_title';
  const defaultSorting = 'default_sorting';
  const queryString = new URLSearchParams(window.location.search);
  const queryKind = queryString.get('kind') || incomplete;
  const currentAssignmentsKind = (
    [incomplete, 'owned_by_you', 'completed', reference, 'recent'].includes(queryKind)
      ? queryKind
      : incomplete
  ) as SortingKind;
  const curriculumsAssignmentsKind: CurriculumsAssignmentsItemKind = isBuildPlan
    ? reference
    : currentAssignmentsKind;
  const isIncompleteKind = currentAssignmentsKind === incomplete;
  const sortOptionsData = useMemo(() => {
    const data = ['curriculum_reading_time', curriculumTitle];
    if (isIncompleteKind) {
      data.splice(0, 0, defaultSorting, 'completion_score');
    }
    return data;
  }, [isIncompleteKind]);

  const incompleteInitialSorting =
    localStorage.getItem('assignments_sorting_incomplete') || defaultSorting;
  const ownedByYouInitialSorting =
    localStorage.getItem('assignments_sorting_owned_by_you') || curriculumTitle;
  const completedInitialSorting =
    localStorage.getItem('assignments_sorting_completed') || curriculumTitle;
  const referenceInitialSorting =
    localStorage.getItem('assignments_sorting_reference') || curriculumTitle;
  const recentInitialSorting =
    localStorage.getItem('assignments_sorting_recent') || curriculumTitle;

  const initialState: State = {
    page: 1,
    sorting: {
      incomplete: incompleteInitialSorting,
      owned_by_you: ownedByYouInitialSorting,
      completed: completedInitialSorting,
      reference: referenceInitialSorting,
      recent: recentInitialSorting,
    },
    sortDirection: 'asc',
  };

  const [state, dispatch] = useReducer(curriculumsAssignmentsReducer, initialState);
  const { page, sorting, sortDirection } = state;

  const currentSorting = sorting[currentAssignmentsKind];

  useEffect(() => {
    dispatch({ type: 'setPage', page: 1 });
    dispatch({
      type: 'setSortDirection',
      sortDirection: currentSorting === 'completion_score' ? 'desc' : 'asc',
    });
  }, [currentSorting, sorting, isIncompleteKind, currentAssignmentsKind, sortOptionsData]);

  const getOptionsData = (options: string[]) => {
    return options.map((option, index) => (
      <SortingDropdownMenuOption
        isSelected={index === options.indexOf(currentSorting)}
        key={option}
        title={t(`sorting.${option}`)}
      />
    ));
  };

  const handleSorting = (index: number) => {
    const selectedSorting = sortOptionsData[index];
    dispatch({
      type: 'setSorting',
      sorting: { ...state.sorting, [currentAssignmentsKind]: selectedSorting },
    });
    localStorage.setItem(`assignments_sorting_${currentAssignmentsKind}`, selectedSorting);
  };

  const handlePagination = (page: number) => {
    dispatch({ type: 'setPage', page });
  };

  const { data, isFetching, isLoading } = useGetAssignmentsCurriculumsQuery({
    userId,
    kind: curriculumsAssignmentsKind,
    page,
    sortColDir: sortDirection,
    sortCol: currentSorting,
    tag: trainingPathTag,
  });

  if (isLoading) return <TabsLoadingSkeleton />;
  if (!data) return <></>;

  const {
    assignments,
    incompleteAssignmentsCount,
    completedAssignmentsCount,
    ownedAssignmentsCount,
    referenceAssignmentsCount,
    recentAssignmentsCount,
    limitValue,
    totalAssignmentsCount,
    totalPages,
  } = data;

  const kindToCountMap = {
    incomplete: incompleteAssignmentsCount,
    completed: completedAssignmentsCount,
    owned_by_you: ownedAssignmentsCount,
    reference: referenceAssignmentsCount,
    recent: recentAssignmentsCount,
  };
  const shouldLoadAssignments = kindToCountMap[curriculumsAssignmentsKind] > 0;
  const showSortingDropdown =
    !!totalAssignmentsCount &&
    sortOptionsData.length > 1 &&
    curriculumsAssignmentsKind !== 'recent';

  return (
    <div id='home-assignments'>
      <CurriculumsAssignmentsHead>
        {isBuildPlan ? (
          <CurriculumsAssignmentsSubtitle>
            {t('all_curriculums', { curriculums: curriculums.toLowerCase() })}
          </CurriculumsAssignmentsSubtitle>
        ) : (
          <>
            <CurriculumsAssignmentsTabs
              completedAssignmentsCount={completedAssignmentsCount}
              incompleteAssignmentsCount={incompleteAssignmentsCount}
              ownedAssignmentsCount={ownedAssignmentsCount}
              recentAssignmentsCount={recentAssignmentsCount}
              referenceAssignmentsCount={referenceAssignmentsCount}
            />
            {showSortingDropdown && (
              <SortingDropdown
                id='curriculums-assignments-sorting'
                options={getOptionsData(sortOptionsData)}
                setSelectedOption={handleSorting}
              />
            )}
          </>
        )}
      </CurriculumsAssignmentsHead>
      {isFetching && shouldLoadAssignments ? (
        <CurriculumsAssignmentsLoadingSkeleton />
      ) : (
        <>
          <CurriculumsAssignmentsListWrapper>
            {!!totalAssignmentsCount && shouldLoadAssignments ? (
              <CurriculumsAssignmentsList>
                {assignments.map((item: CurriculumsAssignmentsItemProps) => (
                  <CurriculumsAssignmentsItem
                    {...item}
                    key={item.id}
                    kind={curriculumsAssignmentsKind}
                    nextStepId={item.nextStepId}
                  />
                ))}
              </CurriculumsAssignmentsList>
            ) : trainingPathHasUpcomingSet ? (
              <CaughtUpForNow />
            ) : (
              <NoResults
                darkImage={emptyStateImage}
                heading={t(`empty_state.${currentAssignmentsKind}.title`, {
                  curriculums: curriculums.toLowerCase(),
                })}
                iconWidth='210px'
                lightImage={emptyStateImage}
                subHeaderText={
                  isBuildPlan
                    ? t(`empty_state.build_plan.subtitle`, {
                        curriculums: curriculums.toLowerCase(),
                      })
                    : t(`empty_state.${currentAssignmentsKind}.subtitle`, {
                        curriculums: curriculums.toLowerCase(),
                      })
                }
              />
            )}
          </CurriculumsAssignmentsListWrapper>
          {!!totalAssignmentsCount && (
            <Pagination
              activePage={page}
              itemsCountPerPage={limitValue}
              onChange={handlePagination}
              showPaginationDetails
              totalItemsCount={totalAssignmentsCount}
              totalPages={totalPages}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CurriculumsAssignments;
