import React from 'react';

import { useImageFlyoutContext } from '../../../../../contexts/ImageFlyoutContext';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import ThreeDotMenu from '../../../../design_system/Triage/menus/ThreeDotMenu';
import { ThreeDotMenuOptionProps } from '../../../../design_system/Triage/menus/ThreeDotMenu/types';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';

export const TOPIC_HEADER_THREE_DOT_MENU_ID = 'topic-header-three-dot-menu';

type Props = {
  flyoutMenuId: RegisteredMenuId;
  removeCoverImage: () => void;
};

const t = initTranslations('editor.topic.header.three_dot_menu');

const TopicHeaderThreeDotMenu = ({ flyoutMenuId, removeCoverImage }: Props) => {
  const { setNextMenu } = useActiveMenuHandler({ menuId: flyoutMenuId });
  const { setImageError, imageError } = useImageFlyoutContext();

  const menuOptions: ThreeDotMenuOptionProps[] = [
    {
      title: t('replace_cover_image'),
      onClick: () => {
        setNextMenu(flyoutMenuId);
        imageError && setImageError('');
      },
      iconName: 'arrow-up-from-bracket',
      id: 'td-add-cover-image',
      visible: true,
    },
    {
      title: t('remove'),
      onClick: removeCoverImage,
      iconName: 'trash-can',
      id: 'td-remove-cover-image',
      visible: true,
    },
  ];

  return (
    <ThreeDotMenu
      id={TOPIC_HEADER_THREE_DOT_MENU_ID}
      menuOptions={menuOptions}
      menuPlacement='bottom-end'
    />
  );
};

export default TopicHeaderThreeDotMenu;
