import React, { useCallback, useEffect, useState } from 'react';

import {
  TeammateModalProvider,
  useTeammateModal,
} from '../../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { deleteQueryParam, fetchQueryParameters } from '../../../../../../lib/getQueryParams';
import humanizeConnectedProvider from '../../../../../../lib/humanizeConnectedProvider';
import initTranslations from '../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useSendAllInvitesMutation } from '../../../../../../redux/services/resourceApis/users/usersApi';
import SplitButton from '../../../../../design_system/buttons/SplitButton';
import { MenuOptionProps } from '../../../../../design_system/core/MenuOptions';
import { routes } from '../../../../publicApplication/applicationRouter';
import routeTo from '../../../../publicApplication/routeTo';
import BulkUserUploadModal from '../../../BulkUserUploadModal';
import BulkUserUploadPayrollModal from '../../../BulkUserUploadPayrollModal/BulkUserUploadPayrollModal';
import SendAllInvitesModal from '../../../SendAllInvitesModal';
import AddTeammateWithConfirmation from '../../../TeammateModal/AddTeammateWithConfirmation';
import { SplitButtonWrapper } from '../Styles';
import {
  AddTeammateSplitButtonProps,
  SplitButtonContainerProps,
  SplitButtonMenuOptions,
} from '../TableTypes';
import PeopleDownloadCsvReport from './PeopleDownloadCsvReport';

const t = initTranslations('users_table.add_teammate_split_button');

const SplitButtonContainer = ({
  allUserIds,
  tableDispatch,
  setImportJobIds,
}: SplitButtonContainerProps) => {
  const { slug } = useCurrentAccount();
  const [showBulkUserUploadModal, setShowBulkUserUploadModal] = useState(false);
  const [showBulkUserUploadPayrollModal, setShowBulkUserUploadPayrollModal] = useState(false);
  const [showPeopleDownloadCsvReportModal, setShowPeopleDownloadCsvReportModal] = useState(false);
  const [showSendAllInvitesModal, setShowSendAllInvitesModal] = useState(false);
  const {
    payrollIntegrationSource,
    unlimitedUserSlots,
    noFreeSlots,
    usedUserSlotsCount,
    totalUserSlotsCount,
    status,
  } = useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const isTrialing = status === 'trialing';
  const isTrialEnded = status === 'trial_ended';
  const canReadBulkUserUploadModal = isTrialing
    ? false
    : ability.can('read', 'BulkUserUploadModal');
  const canReadHRISIntegrations = ability.can('read', 'HRISIntegrations');
  const canReadPayrollIntegration = ability.can('read', 'PayrollIntegration');
  const canReadPeopleDownloadCsvReport = ability.can('read', 'PeopleDownloadCsvReport');
  const resetCheckboxes = () => {
    tableDispatch({
      type: 'selectAllUsers',
      selectAllUsers: false,
    });
  };
  const { loadingPrefillOptions, setTeammateModalVisible } = useTeammateModal();
  const queryParams = fetchQueryParameters();
  const displayBulkUserUploadModal = queryParams.get('open_bulk_user_upload_modal') === 'true';
  const disableSplitButton = loadingPrefillOptions || isTrialEnded;

  const [sendAllInvites, result] = useSendAllInvitesMutation();
  const { error, data } = result;

  const successFunction = useCallback(() => {
    setShowSendAllInvitesModal(true);
  }, [setShowSendAllInvitesModal]);

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
    successFunction,
    errorMessage: messageFromError(error)?.join(', '),
  });

  const options: SplitButtonMenuOptions[] = [
    {
      displayed: true,
      value: {
        title: t('add_teammate'),
        onClick: () => setTeammateModalVisible(true),
        className: 'analytics-users-outline-table-add-teammate-option',
        iconName: 'user-plus',
        id: 'td-users-outline-add-teammate',
      },
    },
    {
      displayed: canReadBulkUserUploadModal,
      value: {
        title: t('bulk_add'),
        onClick: () => {
          resetCheckboxes();
          setShowBulkUserUploadModal(true);
        },
        className: 'analytics-users-outline-table-bulk-import-option',
        iconName: 'users',
        id: 'td-users-outline-bulk-add',
      },
    },
    {
      displayed: !!payrollIntegrationSource && canReadPayrollIntegration,
      value: {
        title: t('add_with_payroll_integration_source', {
          source: payrollIntegrationSource && humanizeConnectedProvider(payrollIntegrationSource),
        }),
        onClick: () => {
          resetCheckboxes();
          setShowBulkUserUploadPayrollModal(true);
        },
        className: `analytics-users-outline-table-invite-from-${payrollIntegrationSource}-option`,
        iconName: 'users',
        id: 'td-users-outline-add-with-payroll-integration-source',
      },
    },
    {
      displayed: !payrollIntegrationSource && !canReadPayrollIntegration && canReadHRISIntegrations,
      value: {
        title: t('add_with_hr_software'),
        onClick: () => {
          resetCheckboxes();
          routeTo(routes.integrations({ slug }));
        },
        className: 'analytics-users-outline-table-invite-from-hr-software-option',
        iconName: 'users',
        id: 'td-users-outline-add-with-hr-software',
      },
    },
    {
      displayed: !isTrialing,
      value: {
        title: t('send_all_invites'),
        onClick: () => {
          resetCheckboxes();
          sendAllInvites();
        },
        className: 'analytics-users-outline-table-send-all-invites-option',
        iconName: 'paper-plane',
        id: 'td-users-outline-send-all-invites',
      },
    },
    {
      displayed: canReadPeopleDownloadCsvReport,
      value: {
        title: t('export_csv'),
        onClick: () => {
          resetCheckboxes();
          setShowPeopleDownloadCsvReportModal(true);
        },
        className: 'analytics-users-outline-table-manage-people-table-option',
        iconName: 'file-export',
        id: 'td-export-manage-people-table-csv',
      },
    },
  ];

  const splitButtonMenuOptions: MenuOptionProps[] = options
    .filter(({ displayed }) => displayed)
    .map(({ value }) => value);

  useEffect(() => {
    if (displayBulkUserUploadModal) {
      setShowBulkUserUploadModal(displayBulkUserUploadModal);
      deleteQueryParam('open_bulk_user_upload_modal', true);
    }
  }, [displayBulkUserUploadModal]);

  return (
    <SplitButtonWrapper>
      <SplitButton
        disabled={disableSplitButton}
        dropDownClassName='analytics-users-outline-table-split-button-drop-down'
        loading={loadingPrefillOptions}
        mainButtonOnClick={() => setTeammateModalVisible(true)}
        mainMenuButtonId='add-teammate-button-modal-open'
        menuId='add-teammate-menu'
        menuOptions={splitButtonMenuOptions}
        text={t('add_teammate')}
      />
      {showSendAllInvitesModal && data && (
        <SendAllInvitesModal data={data} setShowSendAllInvitesModal={setShowSendAllInvitesModal} />
      )}
      {showPeopleDownloadCsvReportModal && (
        <PeopleDownloadCsvReport
          setShowConfirmationModal={setShowPeopleDownloadCsvReportModal}
          userIds={allUserIds}
        />
      )}
      {canReadBulkUserUploadModal && showBulkUserUploadModal && (
        <BulkUserUploadModal
          modalVisible={showBulkUserUploadModal}
          seatsRemaining={noFreeSlots ? '0' : String(totalUserSlotsCount - usedUserSlotsCount)}
          seatsUnlimited={unlimitedUserSlots}
          setImportJobIds={setImportJobIds}
          setModalVisible={setShowBulkUserUploadModal}
        />
      )}
      <BulkUserUploadPayrollModal
        setShowModal={setShowBulkUserUploadPayrollModal}
        showModal={showBulkUserUploadPayrollModal}
      />
    </SplitButtonWrapper>
  );
};

const AddTeammateSplitButton = ({
  allUserIds,
  tableDispatch,
  setImportJobIds,
}: AddTeammateSplitButtonProps) => {
  const ability = useCurrentUserAbilities();
  const canInviteUser = ability.can('create', 'User');

  return (
    <TeammateModalProvider>
      {canInviteUser && (
        <SplitButtonContainer
          allUserIds={allUserIds}
          setImportJobIds={setImportJobIds}
          tableDispatch={tableDispatch}
        />
      )}
      <AddTeammateWithConfirmation />
    </TeammateModalProvider>
  );
};

export default AddTeammateSplitButton;
