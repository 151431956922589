import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';

const t = initTranslations('account_settings.advanced_settings');

interface ProfileEmailSectionProps {
  enableProfileEmail: boolean;
  id: string;
}

const ProfileEmailSection = ({ enableProfileEmail, id }: ProfileEmailSectionProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('enable_profile_email_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={enableProfileEmail}
            className='enable-profile-email-setting'
            handleToggle={(e) => updateAccountData({ enable_profile_email: e.target.checked })}
            id='enable-profile-email'
            name='enable_profile_email'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('enable_profile_email_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default ProfileEmailSection;
