import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import {
  ActivityAssociationParams,
  AssociatedCurriculumParams,
  Owner,
} from '../../activities/types';
import { TextFragment } from '../styles';

const t = initTranslations('notifications');

export type GeneratedWithAiProps = {
  activityKey: string;
  trackable: ActivityAssociationParams & { associated_params: AssociatedCurriculumParams };
  owner?: Owner;
};

const GeneratedWithAi = ({ activityKey, trackable }: GeneratedWithAiProps) => {
  const { slug } = useCurrentAccount();
  const {
    curriculum_id: curriculumId,
    is_active_curriculum: isActiveCurriculum,
    curriculum_title: curriculumTitle,
  } = trackable.associated_params;

  return (
    <>
      <TextFragment>{t('survey_survey.test')}</TextFragment>
      {trackable.is_active ? (
        <SourceBadge
          sourceName='curriculum'
          sourceRoute={routes.surveyEditor({ slug, id: trackable.id })}
          sourceText={trackable.name}
        />
      ) : (
        <b>{trackable.name}</b>
      )}
      <TextFragment>{t('survey_survey.for')}</TextFragment>
      {isActiveCurriculum ? (
        <SourceBadge
          sourceName='curriculum'
          sourceRoute={routes.curriculumEdit({ slug, id: curriculumId })}
          sourceText={curriculumTitle}
        />
      ) : (
        <b>{curriculumTitle}</b>
      )}
      <TextFragment>{t(activityKey)}</TextFragment>
    </>
  );
};

export default GeneratedWithAi;
