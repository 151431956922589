import styled from 'styled-components';

import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Surface from '../../../design_system/Triage/layout/Surface';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontMd1, fontSm5 } from '../../../styled/TypeSystem';

export const StyledSurface = styled(Surface)`
  position: relative;
  border: none;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg1} ${constants.spacerMd3}`};

  @media (min-width: ${mediaBreakpointPxSm}) {
    padding: ${({ theme: { constants } }) => `${constants.spacerLg1} ${constants.spacerLg3}`};
  }
`;

export const BadgeBeta = styled.span`
  position: absolute;
  top: 0.275rem;
  left: ${({ theme: { constants } }) => constants.spacerMd1};
  text-transform: uppercase;
  color: ${({ theme: { vars } }) => vars.stateBadgeInfo};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};

  ${fontMd1}
`;

export const LogoSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme: { constants } }) => constants.spacerLg3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-top: 0;
  }
`;

export const StyledButton = styled(DefaultButton)`
  width: 100%;
  display: block;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
  }
`;

export const DescriptionSection = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};

  p {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  }

  ul {
    margin-left: ${({ theme: { constants } }) => constants.spacerLg1};
  }

  ${fontSm5};
`;
