import styled from 'styled-components';

import { fontMd1, fontSm4 } from '../../../../../../styled/TypeSystem';

export const PlanNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const PlanPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PlanPrice = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd1};
`;

export const PlanInterval = styled.span`
  ${fontSm4};
`;

export const PlanText = styled.span`
  ${fontMd1};
`;

export const PlanName = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;
