import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  MultiErrorPasswordErrorIdentifiersType,
  multiErrorPasswordErrorsData,
} from '../../../../../../lib/validation_schemas/multiErrorPassword';
import PasswordInputField from '../../../../../design_system/Triage/PasswordInputField';
import PasswordValidationPreview from '../../PasswordValidationPreview/PasswordValidationPreview';

const t = initTranslations('public_application.reset_password');

type ResetPasswordInputFieldProps = {
  password: string | string[];
  passwordError: string | undefined;
  passwordTouched: boolean | undefined;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatedPasswordValidationPreviewEnabled: boolean;
};

const ResetPasswordInputField = ({
  password,
  passwordError,
  passwordTouched,
  updatedPasswordValidationPreviewEnabled,
  handleChange,
}: ResetPasswordInputFieldProps) => {
  const isPasswordValidationPreviewError = Object.keys(multiErrorPasswordErrorsData).some(
    (key: keyof MultiErrorPasswordErrorIdentifiersType) =>
      passwordError?.includes(multiErrorPasswordErrorsData[key].identifier)
  );
  const errorText = !isPasswordValidationPreviewError && passwordTouched && passwordError;

  return updatedPasswordValidationPreviewEnabled ? (
    <PasswordValidationPreview
      isTouched={!!password.length || !!passwordTouched}
      validationErrors={passwordError || []}
    >
      <PasswordInputField
        autoFocus
        errorText={errorText}
        iconName='lock'
        label={t('password')}
        name='password'
        onChange={handleChange}
        placeholder={t('password')}
        type='password'
        value={password}
        wrapErrors
      />
    </PasswordValidationPreview>
  ) : (
    <PasswordInputField
      autoFocus
      errorText={errorText}
      iconName='lock'
      label={t('password')}
      name='password'
      onChange={handleChange}
      placeholder={t('password')}
      type='password'
      value={password}
      wrapErrors
    />
  );
};

export default ResetPasswordInputField;
