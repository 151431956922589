import React from 'react';

import { useGetUserAssigmentColumns } from '../../../../../hooks/user_assignment_report/useGetUserAssigmentColumns';
import { BodyRow } from '../../../people/UsersOutline/Table/Styles';
import { StyledColumn } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import TableColumn from './Columns/TableColumn';
import { TableRowProps } from './types';

const TableRow = ({ user, curriculumName, curriculumId, signaturesRequired }: TableRowProps) => {
  const { id } = user;
  const userAssigmentColumns = useGetUserAssigmentColumns(signaturesRequired);

  return (
    <>
      <BodyRow id={`users-assignment-report-tr-${id}`}>
        {userAssigmentColumns.map(({ columnName, contentAlignment }) => (
          <StyledColumn
            contentAlignment={contentAlignment}
            id={`users-assignment-report-column-${id}-${columnName}`}
            key={`users-assignment-report-column-${id}-${columnName}`}
          >
            <TableColumn
              columnName={columnName}
              curriculumId={curriculumId}
              curriculumName={curriculumName}
              record={user}
            />
          </StyledColumn>
        ))}
      </BodyRow>
    </>
  );
};

export default TableRow;
