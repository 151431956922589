import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useMemo } from 'react';

import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import usePublicConfigs from '../../../../../hooks/usePublicConfigs';

type StripeElementsProviderProps = {
  children: React.ReactNode;
};

const StripeElementsProvider = ({ children }: StripeElementsProviderProps) => {
  const { configs: privateConfigs } = usePrivateConfigs();
  const { configs: publicConfigs } = usePublicConfigs();
  const configOptions = { fonts: [{ cssSrc: publicConfigs['STRIPE_FONT_SRC'] }] };
  // We should use useMemo to prevent getting a new stripe instance during re-rendering
  const stripePromise = useMemo(
    () => loadStripe(privateConfigs['STRIPE_PUBLISHABLE_KEY'] || ''),
    [privateConfigs]
  );

  return (
    <Elements options={configOptions} stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default StripeElementsProvider;
