import React from 'react';

import GroupsIndexEmptyStateDark from '../../../../images/empty_state/groups-index-empty-state-dark.svg';
import GroupsIndexEmptyStateLight from '../../../../images/empty_state/groups-index-empty-state-light.svg';
import initTranslations from '../../../../lib/initTranslations';
import NoResults from '../../../design_system/Triage/NoResults';

const EmptyState = () => {
  const t = initTranslations('groups');

  return (
    <NoResults
      darkImage={GroupsIndexEmptyStateDark}
      heading={t('no_groups_heading')}
      iconWidth='35%'
      lightImage={GroupsIndexEmptyStateLight}
      showBackground={false}
      showBorder={false}
      subHeaderText={t('no_groups_advice')}
    />
  );
};

export default EmptyState;
