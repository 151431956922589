import { useEffect } from 'react';

import { routes, useRoute } from '../applicationRouter';
import { ShowNotificationParamValue } from '../login/Login/utils/useNotificationAfterRedirect';

type NewParams = { show_notification?: ShowNotificationParamValue };

const useRedirectToLogin = (isError: boolean) => {
  const { name, params } = useRoute();
  const isAcceptInvitationRoute = name === 'acceptInvitation';
  const relatedTokenKey = isAcceptInvitationRoute ? 'invitation_token' : 'reset_password_token';
  const hasRelatedToken = relatedTokenKey in params;
  const slugParam = 'slug' in params && params.slug;
  const loginRoute = slugParam && routes.login({ slug: slugParam });

  useEffect(() => {
    if (!loginRoute || (hasRelatedToken && !isError)) return;

    const newParams: NewParams = {
      ...(!isAcceptInvitationRoute && {
        show_notification: hasRelatedToken
          ? 'invalid_reset_password_token'
          : 'no_reset_password_token',
      }),
      ...(isAcceptInvitationRoute && { show_notification: 'invalid_invitation_token' }),
    };

    loginRoute.params = { ...loginRoute.params, ...newParams };
    loginRoute.replace();
  }, [hasRelatedToken, isAcceptInvitationRoute, isError, loginRoute, slugParam]);
};

export default useRedirectToLogin;
