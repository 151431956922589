import { Form } from 'formik';
import styled from 'styled-components';

import { mediaBreakpointPxMd, mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const FormWrapper = styled.div`
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  input {
    width: 100%;
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: 45%;
    margin-bottom: 0;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: 65%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InfoText = styled.div`
  display: flex;
  align-items: center;
  height: max-content;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin: ${({ theme: { constants } }) => `2.125rem auto 0 ${constants.spacerMd2}`};
  }
`;

export const InfoIconWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};

  svg {
    display: block;
    pointer-events: none;
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;

  @media (min-width: ${mediaBreakpointPxMd}) {
    margin-top: ${({ theme: { constants } }) => constants.spacerLg3};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};

  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 45%;
    margin-top: 5rem;
  }

  button {
    &:first-child {
      margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
    }
  }
`;

export const Label = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;
