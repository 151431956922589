import React, { ReactNode, useState } from 'react';
import { Collapse } from 'react-collapse';

import {
  CollapsibleItemContent,
  CollapsibleItemHeader,
  CollapsibleItemTitle,
  CollapsibleItemWrapper,
  StyledChevronIcon,
  StyledQuestionIcon,
} from './styles';

type CollapsibleItem = {
  children: ReactNode | ReactNode[];
  title: string;
};

const CollapsibleItem = ({ children, title }: CollapsibleItem) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <CollapsibleItemWrapper className='collapsible-item'>
      <CollapsibleItemHeader onClick={() => setIsCollapsed(!isCollapsed)}>
        <StyledQuestionIcon name='circle-question' size='md' />
        <CollapsibleItemTitle>{title}</CollapsibleItemTitle>
        <StyledChevronIcon
          className='collapsible-surface-chevron'
          isCollapsed={isCollapsed}
          name='chevron-down'
        />
      </CollapsibleItemHeader>
      <CollapsibleItemContent isCollapsed={isCollapsed}>
        <Collapse isOpened={!isCollapsed}>{children}</Collapse>
      </CollapsibleItemContent>
    </CollapsibleItemWrapper>
  );
};

export default CollapsibleItem;
