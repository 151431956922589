import styled, { css } from 'styled-components';

import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { fontMd1, fontMd3, fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { FormGroup } from '../../shared/FormGroup';

export const ToggleContainer = styled.div`
  max-width: 20%;
`;

export const TabSectionWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
  display: flex;
  flex-direction: column;
`;

export const ToggleSettingContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  margin: ${({ theme: { constants } }) => constants.spacerMd3} 0;
`;

export const ToggleInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ theme: { constants, vars } }) => ({
    marginBottom: constants.spacerMd2,
    label: {
      color: vars.textDefault,
      marginBottom: constants.spacerMd2,
      fontWeight: constants.fontSemibold,
    },
    input: {
      width: '100%',
      paddingTop: constants.spacerSm3,
      paddingBottom: constants.spacerSm3,
    },
    '> div': {
      marginTop: '0',
    },
  })};
`;
export const FormSectionTitle = styled.div`
  ${({ theme: { constants, vars } }) => ({
    color: vars.textDefault,
    fontWeight: constants.fontBold,
  })};
  ${fontMd1};
`;

export const FormSectionDescription = styled.div`
  ${({ theme: { vars } }) => ({ color: vars.textSubdued })};
  ${fontSm5};
`;

export const StyledFormGroup = styled(FormGroup)`
  width: 100%;
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: calc(50% - ${({ theme: { constants } }) => constants.spacerSm3});
  }
`;

export const SaveButtonWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    padding: ${constants.spacerMd2};
    background: ${vars.foundationSurface1};
    border-top: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
  `
);

export const OptionWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const MultiSelectInputRow = styled.div`
  width: 100%;

  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 50%;
  }
`;

export const SubduedDescription = styled.div`
  margin-top: 0;
  ${({ theme: { vars } }) => ({ color: vars.textPlaceholder })};
  ${fontSm4};
`;

export const SpecializationTagTerminologyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
`;

export const PageHeader = styled.div`
  ${({ theme: { constants, vars } }) => ({
    color: vars.textDefault,
    fontWeight: constants.fontBold,
    marginTop: constants.spacerLg1,
  })};
  ${fontMd3};
`;

export const FormSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.constants.spacerSm3};
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerSm3} 0 ${constants.spacerMd2}`};
`;
