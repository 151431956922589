import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { SectionDivider, ToggleContainer } from '../../../AdvancedSettings/styles';
import {
  FormSectionDescription,
  FormSectionTitle,
  ToggleInfoSection,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.general_settings');

const RestrictToSSO = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <>
      <SectionDivider />
      <ToggleSettingContainer>
        <ToggleContainer>
          <Toggle
            checked={values.sso_only_account}
            className='sso-only-account-setting'
            handleToggle={() => setFieldValue('sso_only_account', !values.sso_only_account)}
            id='sso-only-account-setting'
            name='sso_only_account'
          />
        </ToggleContainer>
        <ToggleInfoSection>
          <div>
            <FormSectionTitle>{t('sso_only_account_title')}</FormSectionTitle>
            <FormSectionDescription>{t('sso_only_account_description')}</FormSectionDescription>
          </div>
        </ToggleInfoSection>
      </ToggleSettingContainer>
    </>
  );
};

export default RestrictToSSO;
