import styled from 'styled-components';

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 14rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
`;
