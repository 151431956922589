import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useMutationObservable from '../../../../hooks/useMutationObservable';
import usePageTitle from '../../../../hooks/usePageTitle';
import initTranslations from '../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import { routes } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import AdvancedSettings from '../AdvancedSettings';
import BrandStyles from '../BrandStyles';
import OrganizationProfile from '../OrganizationProfile';
import ResponsibilitySpecializations from '../ResponsibilitySpecializations/ResponsibilitySpecializations';
import AccountSettingTabs from './AccountSettingTabs';

const t = initTranslations('navigation.header');

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

type Props = {
  route: Route<typeof routes.accountSettings>;
};

const AccountSettingsPage = ({ route }: Props) => {
  const {
    params: { tab },
  } = route;
  const { productTerm } = useAccountTerminology();
  const {
    splitFeatures: { accountSettingsTabsEnabled },
  } = useCurrentAccount();
  usePageTitle({ resourceType: 'account_settings', productTerm });

  const queryParams = new URLSearchParams(window.location.search);
  const section = queryParams.get('section');
  const settingsRef = useRef(null);

  const onSettingsMutation = useCallback(
    (_, observer) => {
      const element = section && document.getElementById(section);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        observer.disconnect();
      }
    },
    [section]
  );

  useMutationObservable(settingsRef.current, onSettingsMutation);

  return (
    <PageContent id='account-settings-page'>
      {accountSettingsTabsEnabled ? (
        <AccountSettingTabs tab={tab} />
      ) : (
        <>
          <PageHeader title={t('account_settings')} />
          <SettingsWrapper ref={settingsRef}>
            <OrganizationProfile />
            <BrandStyles />
            <ResponsibilitySpecializations />
            <AdvancedSettings />
          </SettingsWrapper>
        </>
      )}
    </PageContent>
  );
};

export default AccountSettingsPage;
