import React, { useContext } from 'react';
import { SaguaroTheme } from 'saguaro';
import { StyledComponent } from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import {
  DefaultHeaderStyled,
  HeaderCell,
  LeftAlignedHeaderStyled,
  RightAlignedHeaderStyled,
} from '../../../people/UsersOutline/Table/Styles';
import { Column } from './RequestColumn';

const t = initTranslations('requests.table');

type Props = {
  column: Column;
  activeHeader?: boolean;
  setActiveHeader?: () => void;
};

const RequestsTableHeaderCell = ({
  column: { columnName, stickyPosition = 'center' },
  activeHeader,
  setActiveHeader,
}: Props) => {
  let HeaderWrapper: StyledComponent<'th', SaguaroTheme>;
  const { curriculum } = useContext(AccountTerminologyContext);

  switch (stickyPosition) {
    case 'left':
      HeaderWrapper = LeftAlignedHeaderStyled;
      break;
    case 'right':
      HeaderWrapper = RightAlignedHeaderStyled;
      break;
    case 'center':
      HeaderWrapper = DefaultHeaderStyled;
  }

  return (
    <HeaderWrapper>
      <HeaderCell
        active={activeHeader}
        onClick={() => setActiveHeader && setActiveHeader()}
        stickyPosition={stickyPosition}
      >
        {columnName === 'curriculum_title' ? curriculum.singular : t(`headers.${columnName}`)}
      </HeaderCell>
    </HeaderWrapper>
  );
};

export default RequestsTableHeaderCell;
