import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { MultilineTruncatedText } from '../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../styled/TypeSystem';

export const FavoriteCurriculum = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FavoriteCurriculumEmoji = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.textDefault};
  text-decoration: none;
`;

export const FavoriteCurriculumTitle = styled.a`
  max-width: calc(100% - 4.375rem);
  margin-right: auto;
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars } }) => vars.textDefault};
  text-decoration: none;
  word-wrap: break-word;
  ${MultilineTruncatedText({ lineClamp: 2 })};
  ${fontSm5};
`;

export const FavoriteCurriculumIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.stateFavorite};
`;

export const FavoriteCurriculumWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  cursor: pointer;
`;

export const ShowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const FavoriteEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg2} 0`};
`;

export const FavoriteEmptyStateText = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm5};
`;
