import styled from 'styled-components';

import SearchField from '../../../design_system/Triage/fields/SearchField';
import Loader from '../../../design_system/Triage/Loader';
import DropdownMenu from '../../../design_system/Triage/menus/DropdownMenu';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';

export const SearchHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
    margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  }
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  margin-left: 0;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
    margin-top: 0;
  }
`;

export const StyledSearchField = styled(SearchField)`
  width: 100%;
`;

export const StyledLoader = styled(Loader)`
  width: 100%;
`;
