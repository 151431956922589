import React, { memo } from 'react';

import { EditableCurriculumElement } from '../../../../../types/CurriculumElement';
import { Placeholder } from '../../../shared/DragAndDrop/types';
import CurriculumElementRow from '../LibraryCurriculumElementRow/LibraryCurriculumElementRow';
import { CurriculumElementsTableProps } from '../LibraryCurriculumElementsTable/LibraryCurriculumElementsTable';

type LibraryCurriculumElementsListProps = {
  curriculumElements: EditableCurriculumElement[];
  isLocked: boolean;
  signaturable: boolean;
  placeholder: Placeholder;
  blockEditPrivileges: boolean;
  isContentLibrary?: boolean;
} & CurriculumElementsTableProps;

const LibraryCurriculumElementsList = memo((props: LibraryCurriculumElementsListProps) => {
  const {
    curriculumId,
    curriculumElements,
    isLocked,
    signaturable,
    placeholder,
    blockEditPrivileges,
    userAccess,
    isContentLibrary,
  } = props;

  return (
    <>
      {curriculumElements.map((curriculumElement, index) => {
        return (
          <CurriculumElementRow
            blockEditPrivileges={blockEditPrivileges}
            curriculumElement={curriculumElement}
            curriculumId={curriculumId}
            index={index}
            isContentLibrary={isContentLibrary}
            isLocked={isLocked}
            key={`curriculum-element-${curriculumElement.id}`}
            placeholder={placeholder}
            signaturable={signaturable}
            userAccess={userAccess}
          />
        );
      })}
    </>
  );
});
LibraryCurriculumElementsList.displayName = 'LibraryCurriculumElementsList';

export default LibraryCurriculumElementsList;
