import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DropdownMenus from '../../components/DropdownMenus/DropdownMenus';
import { HeadingWrapper, OutlineContentWrapper, StepLabel } from '../../shared/styles';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import StepOptions from './StepOptions';

const TopBlock = styled.div`
  top: 0;
  width: 100%;
`;

const OutlineContent = () => {
  const {
    step: { plural: stepTermPlural },
  } = useAccountTerminology();

  return (
    <OutlineContentWrapper>
      <HeadingWrapper>
        <Breadcrumbs />
        <DropdownMenus />
      </HeadingWrapper>
      <TopBlock>
        <StepLabel>{stepTermPlural}</StepLabel>
      </TopBlock>
      <StepOptions />
    </OutlineContentWrapper>
  );
};

export default OutlineContent;
