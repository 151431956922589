import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Checkbox from '../../../../../design_system/input/controls/Checkbox';
import { StyledP } from '../Styles';
import { ShowHideColumnsProps, UserColumn } from '../TableTypes';

const t = initTranslations('users_table');

const ShowHideColumns = ({ toggledColumns, tableDispatch }: ShowHideColumnsProps) => {
  const {
    curriculum: { plural: subjectTermPlural },
  } = useAccountTerminology();
  const onCheck = (columnName: UserColumn) => {
    tableDispatch({
      type: 'toggleColumnVisibility',
      column: columnName,
    });
  };

  return (
    <>
      <StyledP text={t('filters.columns')} />
      {toggledColumns
        .filter(({ hideable }) => hideable)
        .map(({ columnName, display }) => (
          <Checkbox
            checked={display === 'visible'}
            id={columnName}
            key={columnName}
            label={t(`headers.${columnName}`, { subjects: subjectTermPlural })}
            name={columnName}
            onCheck={() => onCheck(columnName)}
          />
        ))}
    </>
  );
};

export default ShowHideColumns;
