import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import useFieldErrorsFromFormik from '../../../../../hooks/useFieldErrorsFromFormik';
import usePublicConfigs from '../../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../../lib/initTranslations';
import { userFirstName } from '../../../../../lib/userNaming';
import { getResetPasswordSchema } from '../../../../../lib/validation_schemas/authorization/resetPassword';
import { multiErrorValidatorForSchema } from '../../../../../lib/validation_schemas/multiErrorPassword';
import { messageFromError } from '../../../../../redux/errors/helpers';
import {
  useGetUserNameByResetPasswordTokenQuery,
  useUpdatePasswordMutation,
} from '../../../../../redux/services/resourceApis/publicApplication/loginApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Loader from '../../../../design_system/Triage/Loader';
import PasswordInputField from '../../../../design_system/Triage/PasswordInputField';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { LoaderWrapper } from '../../../curriculums/shared/styles';
import { routes } from '../../applicationRouter';
import PublicPage from '../../PublicPage';
import {
  ActionWrapper,
  FormHeader,
  FormWrapper,
  H1,
  Logo,
  LogoWrapper,
  P,
} from '../../styles/shared';
import useAccountData from '../../utils/useAccountData';
import useRedirectToLogin from '../../utils/useRedirectToLogin';
import useRedirectToLoginOnSuccess from '../../utils/useRedirectToLoginOnSuccess';
import ResetPasswordInputField from '././ResetPasswordInputField/ResetPasswordInputField';

const SubmitButton = styled(DefaultButton)`
  width: 100%;

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-left: auto;
  }
`;

const t = initTranslations('public_application.reset_password');

export type ResetPasswordFormProps = {
  route: Route<typeof routes.resetPassword>;
};

type FormValues = {
  password: string;
  confirm_password: string;
};

const ResetPassword = ({ route }: ResetPasswordFormProps) => {
  const { configs } = usePublicConfigs();
  const multiErrorFormFields = ['password'] as (keyof FormValues)[];
  const updatedPasswordValidationPreviewEnabled = !!configs['UPDATED_PASSWORD_VALIDATION_PREVIEW'];
  const multiErrorFields = updatedPasswordValidationPreviewEnabled ? multiErrorFormFields : [];
  const validationSchema = getResetPasswordSchema(updatedPasswordValidationPreviewEnabled);
  const {
    errors: { password: passwordError, confirm_password: confirmPasswordError },
    touched: { password: passwordTouched, confirm_password: confirmPasswordTouched },
    values,
    handleChange,
    handleSubmit,
    setFieldError,
    setStatus,
    setSubmitting,
  } = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validate: multiErrorValidatorForSchema<FormValues>(validationSchema, multiErrorFields),
    onSubmit: (values: FormValues) => submitForm(values),
  });
  const { slug, reset_password_token } = route.params;
  const [updatePassword, result] = useUpdatePasswordMutation();
  const userQueryResult = useGetUserNameByResetPasswordTokenQuery({ slug, reset_password_token });
  const { isLoadingAccountData, accountData } = useAccountData(route.params.slug);
  const { password, confirm_password: confirmPassword } = values;
  const {
    error: updatePasswordError,
    isLoading: isLoadingUpdatePassword,
    isSuccess: isSuccessUpdatePassword,
  } = result;
  const { data: userData, isError: isUserError, isLoading: isLoadingUser } = userQueryResult;
  const messages = updatePasswordError && messageFromError(updatePasswordError);
  const isFieldErrorMessage = messages && typeof messages[0] === 'string';
  const isActionInProgress = isLoadingUpdatePassword || isSuccessUpdatePassword;
  const showConfirmPasswordError = confirmPasswordTouched && confirmPasswordError;

  const submitForm = useCallback(
    (formValues) => {
      updatePassword({
        slug,
        reset_password_token,
        ...formValues,
      });
    },
    [reset_password_token, slug, updatePassword]
  );

  useDisplayFlashOnResponse({
    result,
    errorMessage: isFieldErrorMessage ? messages?.join(', ') : undefined,
  });

  useFieldErrorsFromFormik({
    error: isFieldErrorMessage ? undefined : updatePasswordError,
    setFieldError,
    setStatus,
    setSubmitting,
    wrapErrors: true,
  });

  useRedirectToLogin(isUserError);

  useRedirectToLoginOnSuccess(result, { slug, show_notification: 'password_changed' });

  return (
    <>
      {isLoadingAccountData || isLoadingUser ? (
        <LoaderWrapper>
          <Loader size='lg' />
        </LoaderWrapper>
      ) : (
        <PublicPage id='reset-password-page'>
          <FormHeader>
            <LogoWrapper>
              <Logo src={accountData?.logo} />
            </LogoWrapper>
            <H1>
              {t('welcome_back', {
                userFirstName: userData?.name ? userFirstName(userData.name) : '',
              })}
            </H1>
          </FormHeader>
          <P text={t('reset_your_password')} />
          <form onSubmit={handleSubmit}>
            <FormWrapper>
              <ResetPasswordInputField
                handleChange={handleChange}
                password={password}
                passwordError={passwordError}
                passwordTouched={passwordTouched}
                updatedPasswordValidationPreviewEnabled={updatedPasswordValidationPreviewEnabled}
              />
              <PasswordInputField
                errorText={showConfirmPasswordError}
                iconName='lock'
                label={t('confirm_password')}
                name='confirm_password'
                onChange={handleChange}
                placeholder={t('confirm_password')}
                type='password'
                value={confirmPassword}
                wrapErrors
              />
              <ActionWrapper>
                <SubmitButton
                  disabled={isActionInProgress}
                  id='login-submit-button'
                  loading={isActionInProgress}
                  text={t('reset')}
                  type='submit'
                />
              </ActionWrapper>
            </FormWrapper>
          </form>
        </PublicPage>
      )}
    </>
  );
};

export default ResetPassword;
