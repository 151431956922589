import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Badge from '../../../design_system/display/badge';

const t = initTranslations('curriculums.content.view_by_all_groups');

type Props = {
  totalCurriculumsCount: number;
};

const CurriculumCountBadge = ({ totalCurriculumsCount }: Props) => {
  const {
    curriculum: { singular: curriculumTermSingular, plural: curriculumTermPlural },
  } = useAccountTerminology();
  return (
    <Badge
      className='content-count-badge'
      text={t('curriculum_count', {
        count: totalCurriculumsCount,
        curriculum: curriculumTermSingular.toLowerCase(),
        curriculums: curriculumTermPlural.toLowerCase(),
      })}
      type='general'
    />
  );
};

export default CurriculumCountBadge;
