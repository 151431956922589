import React from 'react';

import {
  useBulkDeactivateMutation,
  useFetchRestrictedBulkDeactivateUsersQuery,
} from '../../../../../../../redux/services/resourceApis/users/usersApi';
import { BulkArchiveModalProps } from '../types';
import BulkUserUpdateModal from './BulkUserUpdateModal';

const BulkArchive = ({ onClose, selectedUsersIds, onSuccess, type }: BulkArchiveModalProps) => {
  const { data, isLoading, isSuccess, isError, error } = useFetchRestrictedBulkDeactivateUsersQuery(
    { userIds: selectedUsersIds }
  );
  const fetchRestrictedResult = { isLoading, isSuccess, isError };
  const [bulkDeactivate, result] = useBulkDeactivateMutation();

  return (
    <BulkUserUpdateModal
      bulkUpdate={bulkDeactivate}
      bulkUpdateResult={result}
      data={data}
      fetchRestrictedError={error}
      fetchRestrictedResult={fetchRestrictedResult}
      onClose={onClose}
      onSuccess={onSuccess}
      selectedUsersIds={selectedUsersIds}
      type={type}
    />
  );
};

export default BulkArchive;
