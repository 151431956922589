import React from 'react';

import { useAccountTerminology } from '../../../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import {
  SearchFieldWrapper,
  StyledSearchField,
  TableActionsHeaderElementsWrapper,
} from '../Styles';
import {
  TableActionsHeaderFiltersProps,
  TableActionsHeaderProps,
  TableActionsHeaderSearchFieldProps,
} from '../TableTypes';
import AddTeammateSplitButton from './AddTeammateSplitButton';
import TableFilters from './TableFilters';

const t = initTranslations('users_table');

const TableActionsHeaderFilters = ({
  tableState,
  tableDispatch,
}: TableActionsHeaderFiltersProps) => {
  return (
    <TableFilters
      appliedFilters={tableState.appliedFilters}
      currentFilterSelection={tableState.flyout.currentFilterSelection}
      // Disable ShowHideColumns if users are selected
      showShowHideColumns={!tableState.selectAllUsers && !tableState.selectedUsers.length}
      sortColumn={tableState.sortColumn}
      tableDispatch={tableDispatch}
      toggledColumns={tableState.toggledColumns}
    />
  );
};

const TableActionsHeaderSearchField = ({
  searchValue,
  tableDispatch,
  isFetching,
}: TableActionsHeaderSearchFieldProps) => {
  const { productTerm } = useAccountTerminology();

  return (
    <SearchFieldWrapper>
      <StyledSearchField
        dataLoading={isFetching}
        placeholder={t('search_trainual', { productTerm })}
        searchValue={searchValue}
        setSearchValue={(value: string) =>
          tableDispatch({ type: 'setSearchValue', searchValue: value })
        }
      />
    </SearchFieldWrapper>
  );
};

const TableActionsHeader = ({
  allUserIds,
  isFetching,
  tableState,
  tableDispatch,
  setImportJobIds,
}: TableActionsHeaderProps) => {
  const { onHoldPlan } = useCurrentAccount();

  return (
    <div className='users-outline-table-action-header'>
      <TableActionsHeaderElementsWrapper>
        <TableActionsHeaderSearchField
          isFetching={isFetching}
          searchValue={tableState.searchValue}
          tableDispatch={tableDispatch}
        />
        <TableActionsHeaderFilters tableDispatch={tableDispatch} tableState={tableState} />
        {!onHoldPlan && (
          <AddTeammateSplitButton
            allUserIds={allUserIds}
            setImportJobIds={setImportJobIds}
            tableDispatch={tableDispatch}
          />
        )}
      </TableActionsHeaderElementsWrapper>
    </div>
  );
};

export default TableActionsHeader;
