import React, { useCallback } from 'react';

import { MenuOption, MenuOptions } from '../../../../design_system/core/MenuOptions';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import OutsideClickHandler from '../../../shared/OutsideClickHandler';
import { DropdownItemProps } from './types';

const DropdownItem = ({ menuId, menuOptionsClassName, menuOptions }: DropdownItemProps) => {
  const { closeMenu } = useActiveMenuHandler({ menuId });
  const handleOutsideClick = useCallback(
    (e: MouseEvent & { target: Element }) => {
      if (!(e.target as Element).classList.contains('add-delegation-card-trigger')) {
        closeMenu();
      }
    },
    [closeMenu]
  );

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <MenuOptions className={menuOptionsClassName} data-button-id={menuId}>
        {menuOptions.map((option, index) => (
          <MenuOption
            iconName={option.iconName}
            id={option.id}
            key={`three-dot-menu-option-${index}`}
            onClick={(e) => {
              e.preventDefault();
              option.onClick(e);
              closeMenu();
            }}
            title={option.title}
            tooltipText={option.tooltipText}
          />
        ))}
      </MenuOptions>
    </OutsideClickHandler>
  );
};

export default DropdownItem;
