import { OnboardingVariation } from '../../components/application/home/Onboarding/ChooseOnboardingVariationStep/types';
import { TaskData } from '../../components/application/home/Onboarding/task_driven/TasksList/types';
import { getSavedOnboardingState } from './getSavedOnboardingState';

export function getTaskDrivenOnboardingState(
  tasks: TaskData[] | undefined,
  onboardingVariation: OnboardingVariation | undefined
) {
  const { documenting, invite, organize } = getSavedOnboardingState();
  const isCompletedPublishSubject = isCompletedTask('publish_subject');
  const isCompletedInviteTeammate = isCompletedTask('invite_teammate');
  const isCompletedCreateGroup = isCompletedTask('create_group');
  const onboardingState = {
    documenting: Boolean(isCompletedPublishSubject || documenting),
    organize: Boolean(isCompletedCreateGroup || organize),
    invite: Boolean(isCompletedInviteTeammate || invite),
  };
  const isOrganizeBusinessVariation = onboardingVariation === 'organize_business';

  function isCompletedTask(taskKey: string) {
    return tasks?.find((task: TaskData) => task.key === taskKey)?.completed;
  }

  function getReorderedOnboardingState() {
    const { organize, ...secondObject } = onboardingState;

    return {
      organize,
      ...secondObject,
    };
  }

  return isOrganizeBusinessVariation ? getReorderedOnboardingState() : onboardingState;
}
