import React from 'react';

import { FetchPlansDataResponse } from '../../../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import { PerUserBillingPlanName } from '../../../../../../../../types/BillingPlanName';
import { Feature } from '../../../../../../../../types/Feature';
import Tooltip from '../../../../../../../design_system/display/Tooltip/Tooltip';
import { FeatureIcon } from '../../styles';
import {
  FeatureIconWrapper,
  FeatureIconsWrapper,
  FeatureRowWrapper,
  FeatureTitle,
  FeatureWrapper,
  InfoIcon,
  TooltipIconWrapper,
} from '../styles';

type FeatureProps = {
  feature: Feature;
  billingPlansPriceData: FetchPlansDataResponse | undefined;
};

const PlanFeature = ({ feature, billingPlansPriceData }: FeatureProps) => {
  return (
    <FeatureRowWrapper className={`compare-plans-feature-${feature.id}`} key={feature.id}>
      <FeatureWrapper>
        <FeatureTitle>{feature.title}</FeatureTitle>
        <Tooltip id={`feature-description-${feature.id}`} text={feature.tooltip} />
        <TooltipIconWrapper data-for={`feature-description-${feature.id}`} data-tip>
          <InfoIcon name='circle-info' size='md' />
        </TooltipIconWrapper>
      </FeatureWrapper>
      <FeatureIconsWrapper>
        {billingPlansPriceData &&
          billingPlansPriceData.billingPlans.map((billingPlan) => {
            const billingPlanName = billingPlan.name;
            const isFeatureIncluded = feature[billingPlanName as PerUserBillingPlanName];
            const isTrainPlan = billingPlanName === 'train';

            return (
              <FeatureIconWrapper
                className={`compare-plans-${billingPlanName}-icon`}
                key={billingPlanName}
              >
                <FeatureIcon
                  isFeatureIncluded={isFeatureIncluded}
                  isTrainPlan={isTrainPlan}
                  name={isFeatureIncluded ? 'circle-check' : 'circle-xmark'}
                  size='md'
                  weight='solid'
                />
              </FeatureIconWrapper>
            );
          })}
      </FeatureIconsWrapper>
    </FeatureRowWrapper>
  );
};

export default PlanFeature;
