import React, { useState } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import { useDeleteBoardMutation } from '../../../../../redux/services/resourceApis/boards/boardsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';
import ThreeDotMenu from '../../../../design_system/Triage/menus/ThreeDotMenu';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import { DelegationPlannerBoard } from '../../Board/types';
import BoardManageModal from '../../modals/BoardManageModal/BoardManageModal';

const t = initTranslations('delegation_planner.three_dot_menu');

const BoardThreeDot = ({
  board,
  setCurrentBoardIsDeleted,
}: {
  board: DelegationPlannerBoard;
  setCurrentBoardIsDeleted: (value: boolean) => void;
}) => {
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showBoardManageModal, setShowBoardManageModal] = useState(false);
  const menuId: RegisteredMenuId = `board-three-dot-${board.id}`;
  const [deleteBoard] = useDeleteBoardMutation();

  const { slug } = useCurrentAccount();

  const handleDelete = async () => {
    setCurrentBoardIsDeleted(true);
    await deleteBoard(board.id);
    setShowConfirmationModal(false);
    routeTo(routes.delegationPlanner({ slug }));
  };

  return (
    <>
      <ThreeDotMenu
        id={menuId}
        menuOptions={[
          {
            title: t('edit_name'),
            onClick: () => setShowBoardManageModal(true),
            iconName: 'pen',
            id: 'td-delegation-board-name-edit',
            visible: true,
          },
          {
            title: t('delete'),
            onClick: () => setShowConfirmationModal(true),
            iconName: 'trash-alt',
            id: 'td-delegation-board-delete',
            visible: true,
          },
        ]}
        menuPlacement='bottom-start'
      />
      {showConfirmationModal && (
        <ConfirmationModal
          actionFunction={() => {
            handleDelete();
          }}
          actionText={t('remove_board_action')}
          desktopSize='lg'
          heapModalName='confirm-delete-board-modal'
          message={t('remove_board_message', {
            responsibilities_lowercase: responsibilityPlural.toLowerCase(),
            responsibilities_uppercase: responsibilityPlural,
          })}
          onCloseRequest={() => {
            setShowConfirmationModal(false);
          }}
          processing={false}
          secondaryButtonText={t('secondary_button')}
          title={t('remove_board_title')}
        />
      )}
      {showBoardManageModal && (
        <BoardManageModal
          board={board}
          mode='edit'
          setShowModal={setShowBoardManageModal}
          showModal={showBoardManageModal}
        />
      )}
    </>
  );
};

export default BoardThreeDot;
