import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import IconButton from '../../../../design_system/buttons/IconButton';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';

// DS Override: Need to match design color
const CrossIconButton = styled(IconButton)`
  color: ${({ theme: { vars } }) => vars.textDefault};

  svg {
    pointer-events: none;
  }
`;

const t = initTranslations('editor_toolbar.go_back_button');

type Props = {
  onClick: () => void;
};

const GoBackIconButton = ({ onClick }: Props) => {
  const {
    curriculum: { singular: curriculumTerm },
  } = useAccountTerminology();

  return (
    <>
      <Tooltip
        id='redirect-to-previous-page-tooltip'
        place='left'
        text={t('tooltip_text', { curriculum: curriculumTerm.toLowerCase() })}
      />

      <div data-for='redirect-to-previous-page-tooltip' data-tip='true'>
        <CrossIconButton
          ariaLabel={t('tooltip_text', { curriculum: curriculumTerm.toLowerCase() })}
          buttonSize='md'
          id='redirect-to-curriculum-button'
          name='xmark'
          onClick={onClick}
          weight='regular'
        />
      </div>
    </>
  );
};

export default GoBackIconButton;
