import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { useFlashNotification } from '../../components/FlashNotificationContext';
import initTranslations from '../../lib/initTranslations';
import { CombinedBillingDetails } from '../../types/CombinedBillingDetails';

const t = initTranslations('update_payment_method_modal');

export const useGetStripeId = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { flash } = useFlashNotification();

  return async (updatePaymentMethodArgs: CombinedBillingDetails) => {
    const cardNumberElement = elements?.getElement(CardNumberElement);
    const stripeParams = {
      name: updatePaymentMethodArgs.name,
      address_line1: updatePaymentMethodArgs.addressLine1,
      address_line2: updatePaymentMethodArgs.addressLine2,
      address_city: updatePaymentMethodArgs.addressCity,
      address_state: updatePaymentMethodArgs.addressState,
      address_zip: updatePaymentMethodArgs.addressZip,
      address_country: updatePaymentMethodArgs.addressCountry,
    };

    if (stripe && cardNumberElement) {
      const { token, error } = await stripe.createToken(cardNumberElement, stripeParams);
      if (token) return token.id;

      // We already handle validation errors in useValidateStripeElements.ts
      if (error?.type !== 'validation_error') {
        flash('error', error?.message || t('error_message'));
      }
    } else {
      // stripe_id set to null when cc hasn't changed. Customer cc update only occurs when stripe_id param is present in request
      return null;
    }
  };
};
