import React from 'react';

import { CompletionsCardProps } from '../../activities/types';
import CommonTemplate, { CompletionCommonTemplateProps } from './CommonTemplate';

interface CompletionActivitiesProps {
  'completion.expire': React.FC<CompletionCommonTemplateProps>;
}

const ACTIVITY_KEYS: CompletionActivitiesProps = {
  'completion.expire': CommonTemplate,
};

const CompletionNotificationsDetails = ({ activity }: CompletionsCardProps) => {
  const NotificationMessage = ACTIVITY_KEYS[activity.key as keyof CompletionActivitiesProps];

  return <NotificationMessage activityKey={activity.key} trackable={activity.trackable} />;
};

export default CompletionNotificationsDetails;
