import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';

const TopNavLoader = () => {
  const {
    constants: { heightXs },
  } = useTheme();

  return <SkeletonLoader height={heightXs} width='27.5rem' />;
};

export default TopNavLoader;
