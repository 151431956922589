import styled from 'styled-components';

import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const RangeWrapper = styled.div`
  display: block;
`;

export const RangeThumb = styled.div`
  height: 1rem;
  width: 1rem;
  position: relative;
  z-index: 2 !important;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;

export const RangeMark = styled.div<{ index: number; value: number }>`
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  z-index: 1;
  cursor: pointer;
  &:after {
    content: '';
    height: 0.5rem;
    width: 0.5rem;
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
    background-color: ${({ theme: { vars }, index, value }) =>
      index < value ? vars.accentPrimaryDefault : vars.borderSurface2};
    position: absolute;
    inset: 0;
    margin: auto;
  }
`;

export const RangeMarkTextContainer = styled.div`
  position: relative;
`;

function calculateMarksPosition(count: number) {
  const styles = new Array(count - 1);
  const lastChildIndex = count - 1;
  let childIndex = 0;
  for (let index = 0; index < lastChildIndex; index += 1) {
    childIndex = (childIndex + 1) % count;
    styles[index] = `
      &:nth-child(${index + 1}n) {
        left: ${(100 / lastChildIndex) * index}%;
      }
    `;
  }
  return styles.join('');
}

export const RangeMarkText = styled.div<{ count: number; isActive: boolean }>`
  position: absolute;
  top: -10px;
  display: block;
  width: max-content;
  color: ${({ theme: { vars }, isActive }) =>
    isActive ? vars.accentPrimaryDefault : vars.textSubdued};
  font-weight: ${({ theme: { constants }, isActive }) =>
    isActive ? constants.fontBold : constants.fontRegular};
  transform: translateX(-50%) scale(-1, -1);
  writing-mode: tb-rl;
  transition: 0.2s ease-in-out;
  height: max-content;
  cursor: pointer;
  ${({ isActive }) => (isActive ? fontSm5 : fontSm4)};

  @media (min-width: ${mediaBreakpointPxMd}) {
    top: ${({ isActive }) => (isActive ? '-2px' : 0)};
    transform: translateX(-50%);
    writing-mode: initial;
  }

  ${({ count }) => calculateMarksPosition(count)};

  &:first-child {
    left: 0;
    @media (min-width: ${mediaBreakpointPxMd}) {
      left: -3px;
      transform: translateX(0);
    }
  }
  &:last-child {
    right: 0;
    left: inherit;
    transform: translateX(50%) scale(-1, -1);

    @media (min-width: ${mediaBreakpointPxMd}) {
      right: -3px;
      transform: translateX(0);
    }
  }
`;

export const RangeContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin: 1.75rem 0;
`;

export const RangeBar = styled.div`
  height: 0.25rem;
  width: 100%;
  align-self: center;
  cursor: default;
  background-color: ${({ theme: { vars } }) => vars.borderSurface2};
`;

export const RangeProgressBar = styled.div<{ rangeValue: number[]; valuesCount: number }>`
  position: absolute;
  height: 0.25rem;
  width: ${({ rangeValue, valuesCount }) => `${(rangeValue[0] * 100) / (valuesCount - 1)}%`};
  align-self: center;
  background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  cursor: default;
  left: 0;
`;
