import React from 'react';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import { AssociatedCurriculumParams, Owner } from '../../activities/types';
import NotificationUserName from '../NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';

const t = initTranslations('notifications');

export interface CommonTemplateProps {
  activityKey: string;
  trackable: { associated_params: AssociatedCurriculumParams };
  owner: Owner;
}

const CommonTemplate = ({ activityKey, owner, trackable }: CommonTemplateProps) => {
  const { slug } = useCurrentAccount();
  const { curriculum_id, curriculum_title, curriculum_sector } = trackable.associated_params;
  const curriculumUrl = routes.curriculumShow({ slug, id: curriculum_id });
  const requestsUrl = routes.requests({ slug, sector: curriculum_sector });

  const ACTIVITY_KEYS: { [P: string]: Route<typeof routes> } = {
    'request.create': requestsUrl,
    'request.approved': curriculumUrl,
    'request.denied': curriculumUrl,
  };
  const actionUrl = ACTIVITY_KEYS[activityKey];

  return (
    <>
      <NotificationUserName user={owner} />
      <TextFragment>{t(activityKey)}</TextFragment>
      <SourceBadge sourceName='curriculum' sourceRoute={actionUrl} sourceText={curriculum_title} />
    </>
  );
};

export default CommonTemplate;
