import React from 'react';
import styled from 'styled-components';

import useCurrentUser from '../../../../hooks/useCurrentUser';
import { RegisteredModalId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { useGetFollowingQuery } from '../../../../redux/services/resourceApis/followings/followingsApi';
import IconButton from '../../../design_system/buttons/IconButton';
import useActiveModalHandler from '../../publicApplication/utils/useActiveModalHandler';
import { HomeBlockHeader } from '../ProgressBlock/styles';
import { WidgetContainer } from '../shared/styles';
import FollowedUser from './FollowedUser';
import FollowingWidgetEmptyState from './FollowingWidgetEmptyState';
import ManageFollowingModal from './ManageFollowingModal/ManageFollowingModal';

const HomeBlockBody = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('home.following_widget');

const FollowingWidget = () => {
  const { id } = useCurrentUser();
  const { data: currentlyFollowing } = useGetFollowingQuery(id);
  const menuId: RegisteredModalId = 'manage-following-modal';
  const { setActiveModal, closeActiveModal, isModalOpen } = useActiveModalHandler(menuId);

  if (currentlyFollowing === undefined) return null;

  return (
    <>
      <WidgetContainer id='home-following-widget'>
        <HomeBlockHeader>
          {t('title')}
          <IconButton
            ariaLabel={t('empty_state.aria_label')}
            id='open-manage-following-modal-icon'
            name='user-plus'
            onClick={() => {
              setActiveModal(menuId);
            }}
          />
        </HomeBlockHeader>
        <HomeBlockBody>
          {currentlyFollowing.length !== 0 ? (
            <div id='followed-users-wrapper'>
              {currentlyFollowing.map((user) => (
                <FollowedUser key={`followed-user-${user.id}`} user={user} />
              ))}
            </div>
          ) : (
            <FollowingWidgetEmptyState openModal={() => setActiveModal(menuId)} />
          )}
        </HomeBlockBody>
      </WidgetContainer>

      {isModalOpen && <ManageFollowingModal closeModal={closeActiveModal} />}
    </>
  );
};

export default FollowingWidget;
