import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { MenuOption } from '../../../design_system/core/MenuOptions';
import { SharedOptionProps } from './types';

const t = initTranslations('three_dot_menu');

const CopyLink = ({ isDisabled, onClick, id, tooltipText }: SharedOptionProps) => {
  return (
    <MenuOption
      iconName='link'
      iconWeight='regular'
      id={id}
      isDisabled={isDisabled}
      onClick={onClick}
      title={t('copy_link')}
      tooltipText={tooltipText}
    />
  );
};

export default CopyLink;
