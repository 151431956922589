import styled, { css } from 'styled-components';

const wrapperDisplayOptions = css`
  display: inline-block;
  overflow: hidden;
  position: relative;
`;

const shimmer1Keyframes = css`
  @keyframes shimmer1 {
    0% {
      left: -30%;
    }
    8% {
      left: 120%;
      width: 6px;
    }
    9% {
      width: 0;
      left: 120%;
    }
    10% {
      left: -30%;
      width: 0;
    }
    11% {
      left: -30%;
      width: 4px;
    }
    17% {
      left: 120%;
      width: 4px;
    }
    18% {
      width: 0;
      left: 120%;
    }
    19% {
      left: -30%;
      width: 0;
    }
  }
`;

const shimmer2Keyframes = css`
  @keyframes shimmer2 {
    0% {
      left: -30%;
    }
    8% {
      left: 120%;
      width: 12px;
    }
    9% {
      width: 0;
      left: 120%;
    }
    10% {
      left: -30%;
      width: 0;
    }
    11% {
      left: -30%;
      width: 12px;
    }
    17% {
      left: 120%;
      width: 16px;
    }
    18% {
      width: 0;
      left: 120%;
    }
    19% {
      left: -30%;
      width: 0;
    }
  }
`;

export const CallToActionWrapper = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => isVisible && wrapperDisplayOptions};
  width: inherit;

  &:before,
  &:after {
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    content: '';
    background-color: ${({ theme: { vars } }) => vars.accentSubdued4};
    top: -1.25rem;
    bottom: -1.25rem;
    left: -1.5625rem;
    position: absolute;
    transform: rotate(-25deg);
    z-index: 10;
    pointer-events: none;
  }

  &:before {
    width: 0.25rem;
    margin-left: -9px;
    animation: shimmer1 6s linear infinite;

    ${shimmer1Keyframes};
  }

  &:after {
    width: 0.75rem;
    animation: shimmer2 6s linear infinite;

    ${shimmer2Keyframes};
  }
`;
