import React from 'react';
import styled from 'styled-components';

const SquareContainer = styled.div<{ selected: boolean }>`
  background-color: ${({ theme: { vars }, selected }) =>
    selected ? vars.accentPrimaryDefault : vars.foundationBase2};
  border: ${({ theme: { constants } }) => constants.borderWidthXs} solid;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusSm};
  width: 1.5rem;
  height: ${({ theme: { constants } }) => constants.heightXs};
`;

type Props = {
  selected: boolean;
  onClick: () => void;
  id: string;
  onMouseOver: React.MouseEventHandler<HTMLDivElement>;
};

const Square = ({ selected, onClick, onMouseOver, id }: Props) => {
  return (
    <SquareContainer id={id} onClick={onClick} onMouseOver={onMouseOver} selected={selected} />
  );
};

export default Square;
