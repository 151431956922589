import { DndContext, Modifier, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Sensor from './core/Sensor';
import { Container, Filler, Line, SyntheticPopup } from './styles';
import { RangeSliderProps } from './types';
import { toPercents } from './utils/toPercents';

const modifiers: Modifier[] = [restrictToHorizontalAxis, restrictToParentElement];

const RangeSlider = ({
  id,
  value,
  onSlideMove,
  onSlideEnd,
  min,
  max,
  step,
  color,
  popupContent,
}: RangeSliderProps) => {
  const ratio = value / max;
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isMouseOverSensor, setIsMouseOverSensor] = useState(false);

  const animationFrameId = useRef<number | null>(null);
  const lineRef = useRef<HTMLDivElement>(null);

  const calculateNewValue = useCallback(
    (xPosition: number) => {
      const steps = Math.round((xPosition * max) / step);

      return +Math.max(min, steps * step).toFixed(2);
    },
    [max, min, step]
  );

  const handleDrag = useCallback(
    (deltaX: number, parentClientWidth: number, callback?: (arg: number) => void) => {
      const xPosition = startX + deltaX;
      const xPositionInFloat = xPosition / parentClientWidth;
      const newValue = calculateNewValue(xPositionInFloat);

      callback && callback(newValue);

      return xPosition;
    },
    [startX, calculateNewValue]
  );

  const handleDragStart = useCallback(
    (event) => {
      const { active } = event;
      const parentClientWidth = active.data.current?.parentClientWidth;

      setIsDragging(true);
      setStartX(ratio * parentClientWidth);
    },
    [ratio]
  );

  const handleDragMove = useCallback(
    (event) => {
      const { active, delta } = event;

      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }

      animationFrameId.current = requestAnimationFrame(() => {
        handleDrag(delta.x, active.data.current?.parentClientWidth, onSlideMove);
      });
    },
    [handleDrag, onSlideMove]
  );

  const handleDragEnd = useCallback(
    (event) => {
      const { active, delta } = event;
      const newX = handleDrag(delta.x, active.data.current?.parentClientWidth, onSlideEnd);

      setIsDragging(false);
      setStartX(newX);
    },
    [handleDrag, onSlideEnd]
  );

  const handleMousePressEvent = useCallback(
    (event, isEnd = false) => {
      const rect = lineRef.current?.getBoundingClientRect();
      if (rect) {
        const x = event.clientX - rect.left;
        const newRatio = x / rect.width;
        const newValue = calculateNewValue(newRatio);

        if (isEnd) {
          onSlideEnd && onSlideEnd(newValue);

          return;
        }

        onSlideMove && onSlideMove(newValue);
      }
    },
    [calculateNewValue, onSlideEnd, onSlideMove]
  );

  const handleMousePressEvents = useCallback(
    (event) => {
      handleMousePressEvent(event, event.type === 'mouseup');
    },
    [handleMousePressEvent]
  );

  const handleMouseEnter = useCallback(() => {
    setIsMouseOverSensor(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsMouseOverSensor(false);
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  return (
    <DndContext
      modifiers={modifiers}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <Container
        $color={color}
        onMouseDown={handleMousePressEvents}
        onMouseUpCapture={handleMousePressEvents}
        ref={lineRef}
      >
        <Line $color={color}>
          <Filler $color={color} value={ratio} />
        </Line>

        {popupContent && (
          <SyntheticPopup
            style={{
              opacity: isDragging || isMouseOverSensor ? 1 : 0,
              left: `${toPercents(ratio || 0)}`,
            }}
          >
            {popupContent}
          </SyntheticPopup>
        )}

        <Sensor
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          id={id}
          ratio={ratio}
        />
      </Container>
    </DndContext>
  );
};

export default RangeSlider;
