import React, { FC, MouseEventHandler, useContext, useEffect, useState } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { useGetDataListQuery } from '../../../../../redux/services/resourceApis/relevantContent/relevantContentApi';
import IconButton from '../../../../design_system/buttons/IconButton';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import Hoverable from '../../../../design_system/Hoverable';
import { getChevronIconName } from '../../helpers';
import { TYPE_HIERARCHY } from '../_data';
import ContentBadge from '../ContentBadge/ContentBadge';
import { ContentFlyoutContext } from '../context/ContentFlyoutContext';
import Skeleton from '../Skeleton/Skeleton';
import { SelectedItem, TypeName } from '../types';
import {
  ActionSectionWrapper,
  CheckIcon,
  CheckWrapper,
  Content,
  ContentBadgeBox,
  Emoji,
  ExpandIconBox,
  Header,
  Heading,
  Info,
  StatusIcon,
  StatusIconWrapper,
  Title,
  Wrapper,
} from './styles';
import { AccordionProps } from './types';
import { isTargetInItems } from './utils';

const t = initTranslations('content_flyout');

const Accordion: FC<AccordionProps> = ({ data, children }) => {
  const { title, title_highlighted, emoji } = data;
  const {
    previouslySelectedItems,
    isSearchable,
    handleAddFetchedCourses,
    handleAddFetchedSteps,
    currentlySelectedItems,
    handleAddSelectedItem,
  } = useContext(ContentFlyoutContext);
  const [isCheckIconHovered, setIsCheckIconHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const isStep = data.__typename === TypeName.Step;
  const isCourse = data.__typename === TypeName.Course;
  const hasChildren = children && children.length > 0;
  const entitiesCount = isCourse ? data.steps_count : !isStep ? data.courses_count : 0;
  const isEmptyForSearch = isSearchable && !hasChildren;
  const isSimpleEmpty = !isSearchable && entitiesCount === 0;
  const isEmpty = isEmptyForSearch || isSimpleEmpty;
  const isLastLevel = isStep || isEmpty;

  const isPreviouslySelected = isTargetInItems(data, previouslySelectedItems);
  const isCurrentlySelected = isTargetInItems(data, currentlySelectedItems);

  const { data: dataList, isLoading } = useGetDataListQuery(
    { dataId: data.id, dataType: TYPE_HIERARCHY[data.__typename]?.lower || data.__typename },
    { skip: isSearchable || !isActive || (isActive && hasChildren) }
  );

  useEffect(() => {
    if (!isSearchable) {
      setIsActive(false);
      return;
    }

    if (!isEmptyForSearch) {
      setIsActive(true);
    }
  }, [isSearchable, isEmptyForSearch]);

  useEffect(() => {
    if (isSearchable) return;

    if (isCourse) {
      handleAddFetchedSteps(data.id, dataList?.steps || []);
    } else {
      handleAddFetchedCourses(data.id, dataList?.courses || []);
    }
    // We want to run this effect only when dataList changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList]);

  const handleSelect: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (isPreviouslySelected) return;

    const selectedItem: SelectedItem = { id: data.id, title, __typename: data.__typename };

    handleAddSelectedItem(selectedItem);
  };

  const handleHeaderClick = async () => {
    !isLastLevel && setIsActive(!isActive);
  };

  const getStatusCircleParams = () => {
    switch (data.__typename) {
      case TypeName.Curriculum:
        return {
          hideStatus: data.published,
          statusDraft: !data.published,
          statusTranslation: t('status_dots.unpublished'),
        };
      case TypeName.Course:
        return {
          hideStatus: data.status === 'finished',
          statusDraft: data.status === 'draft',
          statusTranslation: t(`status_dots.${data.status}`),
        };
      default:
        return { hideStatus: true, statusTranslation: '' };
    }
  };
  const { hideStatus, statusDraft, statusTranslation } = getStatusCircleParams();
  const statusTooltipId = `${data.__typename.toLowerCase()}-${data.id}-status-tooltip`;

  return (
    <Wrapper className='content-list-item'>
      <Header onClick={handleHeaderClick}>
        <Info>
          <ExpandIconBox>
            {!isLastLevel && (
              <IconButton
                ariaLabel={isActive ? 'Collapse' : 'Expand'}
                buttonSize='sm'
                name={getChevronIconName({ isActive: !isActive, initialDirection: 'right' })}
                weight='light'
              />
            )}
          </ExpandIconBox>

          <ContentBadgeBox>
            <ContentBadge typeName={data.__typename} />
          </ContentBadgeBox>

          <Title>
            {!!emoji && <Emoji>{emoji}</Emoji>}

            <Heading
              dangerouslySetInnerHTML={{ __html: isSearchable ? title_highlighted : title }}
            />
          </Title>
        </Info>

        <ActionSectionWrapper>
          {!hideStatus && <Tooltip id={statusTooltipId} place='top' text={statusTranslation} />}
          {!hideStatus && (
            <StatusIconWrapper data-for={statusTooltipId} data-tip>
              <StatusIcon baseColor={statusDraft} name='circle' size='2xs' weight='solid' />
            </StatusIconWrapper>
          )}

          <CheckWrapper isPreviouslySelected={isPreviouslySelected} onClick={handleSelect}>
            <Hoverable setIsHovered={setIsCheckIconHovered}>
              <CheckIcon
                isCurrentlySelected={isCurrentlySelected}
                name='circle-check'
                size='sm'
                weight={
                  isCheckIconHovered || isCurrentlySelected || isPreviouslySelected
                    ? 'solid'
                    : 'regular'
                }
              />
            </Hoverable>
          </CheckWrapper>
        </ActionSectionWrapper>
      </Header>

      {isActive && (
        <Content isDoubleIndented={isCourse}>{isLoading ? <Skeleton /> : children}</Content>
      )}
    </Wrapper>
  );
};

export default Accordion;
