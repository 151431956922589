import { useFormikContext } from 'formik';
import React from 'react';

import useAnalyticsUserEvents from '../../../../../../../hooks/useAnalyticsUserEvents';
import initTranslations from '../../../../../../../lib/initTranslations';
import { useAppSelector } from '../../../../../../../redux/hooks';
import DurationTimeSetter from '../../../../DurationTimeSetter/DurationTimeSetter';
import { SectionWrapper } from '../../styles';
import { ResponsibilityDetailsFormData } from '../types';
import { StyledTitle, TimeSetterClickableWrapper } from './styles';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.time_setter');

const ResponsibilityDurationTimeSetter = () => {
  const { currentBoardColumnResponsibility } = useAppSelector((state) => state.delegationPlanner);
  const { values, errors, setFieldValue } = useFormikContext<ResponsibilityDetailsFormData>();
  const { cdpResponsibilityDetailsElementClicked } = useAnalyticsUserEvents();

  if (!currentBoardColumnResponsibility) return <></>;

  const handleTimeClickedEvent = () => {
    cdpResponsibilityDetailsElementClicked({ element: 'time' });
  };

  return (
    <SectionWrapper>
      <StyledTitle>{t('time_commitment')}</StyledTitle>
      <TimeSetterClickableWrapper onClick={handleTimeClickedEvent}>
        <DurationTimeSetter
          elementId={currentBoardColumnResponsibility.id}
          errorText={errors.time}
          frequency={values.frequency}
          hours={values.time}
          setFrequency={(frequency) => setFieldValue('frequency', frequency)}
          setHours={(hours) => setFieldValue('time', hours)}
        />
      </TimeSetterClickableWrapper>
    </SectionWrapper>
  );
};

export default ResponsibilityDurationTimeSetter;
