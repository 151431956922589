import styled, { css } from 'styled-components';

import Scrollbar from '../../../styled/Scrollbar';
import { fontSm5 } from '../../../styled/TypeSystem';

export const Wrapper = styled.div`
  ${({ theme: { vars, constants } }) => css`
    width: 28rem;
    border: ${constants.borderWidthSm} solid ${vars.borderSurface1};
    background-color: ${vars.foundationSurface1};
    border-radius: ${constants.borderRadiusLg};
  `};
`;

export const Header = styled.div`
  ${({ theme: { vars, constants } }) => css`
    padding: ${constants.spacerMd2};
    border-bottom: ${constants.borderWidthSm} solid ${vars.borderSurface1};
  `};
`;

export const ListTitle = styled.h2`
  ${({ theme: { constants } }) => css`
    padding: ${constants.spacerMd2} 0 ${constants.spacerSm2} ${constants.spacerMd3};
    font-weight: ${constants.fontSemibold};
  `};

  ${fontSm5};
`;

export const Inner = styled.div`
  ${({ theme: { constants } }) => css`
    padding: ${constants.spacerSm3};
    height: 19.5rem;
    overflow-y: auto;

    ${Scrollbar};
  `};
`;

export const PaginationLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingImage = styled.img`
  width: 4rem;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: -${({ theme: { constants } }) => constants.spacerSm3};
`;

export const Controls = styled.div`
  ${({ theme: { vars, constants } }) => css`
    display: flex;
    justify-content: flex-end;
    padding: ${constants.spacerMd2};
    border-top: ${constants.borderWidthSm} solid ${vars.borderSurface1};
    gap: ${constants.spacerMd1};
  `};
`;
