import React from 'react';

import {
  StyledDateColumn,
  StyledTime,
} from '../../../AllUsersReportPage/AllUsersReportTable/Columns/styles';
import { ColumnComponentProps } from '../types';

const UpdatedAt = ({ record: { updated_at } }: ColumnComponentProps) => {
  return (
    <StyledDateColumn>
      <span>{updated_at.date}</span>
      <StyledTime>{updated_at.time}</StyledTime>
    </StyledDateColumn>
  );
};

export default UpdatedAt;
