import React from 'react';
import { ColorTokens, TrainualBrandPaletteOption } from 'saguaro';
import styled, { useTheme } from 'styled-components';

import { Sector } from '../../../../../types/Sector';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Icon from '../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../styled/TypeSystem';

const SectorLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 7.8rem;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

// TODO: Remove overflow: hidden and replace with truncation when the design team has decided on the truncation point
const SectorText = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${fontSm5};
`;

const getSoftDuotoneIconColor = (palette: TrainualBrandPaletteOption, vars: ColorTokens) => {
  switch (palette) {
    case 'purple':
      return vars.trainualBrandPurpleSubdued;
    case 'green':
      return vars.trainualBrandGreenSubdued;
    case 'yellow':
      return vars.trainualBrandYellowMedium;
    case 'magenta':
      return vars.trainualBrandMagentaSubdued;
    case 'blue':
      return vars.trainualBrandBlueSubdued;
    default:
      return vars.trainualBrandPurpleSubdued;
  }
};

const getHeavyDuotoneIconColor = (palette: TrainualBrandPaletteOption, vars: ColorTokens) => {
  switch (palette) {
    case 'purple':
      return vars.trainualBrandPurpleStrong;
    case 'green':
      return vars.trainualBrandGreenStrong;
    case 'yellow':
      return vars.trainualBrandYellowStrong;
    case 'magenta':
      return vars.trainualBrandMagentaStrong;
    case 'blue':
      return vars.trainualBrandBlueStrong;
    default:
      return vars.trainualBrandPurpleStrong;
  }
};

type Props = {
  sector: Sector;
};

const SectorLabel = ({ sector }: Props) => {
  const {
    process: { singular: processTermSingular },
    policy: { singular: policyTermSingular },
    company: { singular: companyTermSingular },
  } = useAccountTerminology();
  const { vars } = useTheme();

  let palette: TrainualBrandPaletteOption = 'purple';
  let sectorTitle = '';

  // TODO: Update colors with final design
  switch (sector) {
    case 'company':
      sectorTitle = companyTermSingular;
      palette = 'yellow';
      break;
    case 'policy':
      sectorTitle = policyTermSingular;
      palette = 'magenta';
      break;
    case 'process':
      sectorTitle = processTermSingular;
      palette = 'blue';
      break;
  }

  return (
    <SectorLabelWrapper>
      <StyledIcon
        name='file-alt'
        primaryColor={getHeavyDuotoneIconColor(palette, vars)}
        secondaryColor={getSoftDuotoneIconColor(palette, vars)}
        size='sm'
        weight='duotone'
      />
      <SectorText>{sectorTitle}</SectorText>
    </SectorLabelWrapper>
  );
};

export default SectorLabel;
