import React, { useReducer } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { useGetAccountSettingsQuery } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import CollapsibleSurface from '../../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import { SettingsFormProps } from '../InterfaceAndTypes';
import { FieldTitle, FormSectionContainer, FormSectionWrapper } from '../Styles';

const t = initTranslations('settings');

const PasswordSettings = ({ openModal }: SettingsFormProps) => {
  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);
  const { data } = useGetAccountSettingsQuery();
  if (!data) return <></>;

  const {
    advanced_settings: { sso_only_account: ssoOnlyAccount },
  } = data;
  if (ssoOnlyAccount) return <></>;

  return (
    <CollapsibleSurface
      collapseDispatch={collapseDispatch}
      id='organization-profile-settings-surface'
      isCollapsed={collapsed}
      title={t('password.title')}
    >
      <FormSectionWrapper className='my-settings-password-section'>
        <FormSectionContainer>
          <FieldTitle>{t('password.label')}</FieldTitle>
          <DefaultButton
            buttonType='primary'
            id='update-password-button'
            onClick={openModal}
            text={t('password.update_button')}
            type='button'
          />
        </FormSectionContainer>
      </FormSectionWrapper>
    </CollapsibleSurface>
  );
};

export default PasswordSettings;
