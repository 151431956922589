import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import Breadcrumbs from '../../../design_system/navigation/Breadcrumbs';
import { routes } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import { RequestsTableWrapper } from '../styles';
import RequestsTable from './RequestsTable';
import RequestsTabs from './RequestsTabs';

const t = initTranslations('requests');

const RequestsPage = () => {
  const { slug } = useCurrentAccount();
  const breadcrumbLink = routes.content({ slug }).href;

  return (
    <PageContent id='requests-page'>
      <Breadcrumbs
        currentPageText={t('requests')}
        redirectPath={breadcrumbLink}
        redirectText={t('content')}
      />

      <RequestsTabs />

      <RequestsTableWrapper>
        <RequestsTable />
      </RequestsTableWrapper>
    </PageContent>
  );
};

export default RequestsPage;
