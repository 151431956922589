import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import IconButton from '../../../design_system/buttons/IconButton';
import InputFieldWithCharacterCounter from '../../../design_system/Triage/InputFieldWithCharacterCounter';
import { SPECIALIZATION_LABEL_COLORS } from '../../DelegationPlanner/SpecializationColorPicker/constants';
import SpecializationColorPicker from '../../DelegationPlanner/SpecializationColorPicker/SpecializationColorPicker';
import { MAX_SPECIALIZATION_TAG_CHARACTERS, MIN_SPECIALIZATIONS_COUNT } from './constants';
import { FormElementWrapper } from './styles';
import {
  SpecializationError,
  SpecializationFormElementProps,
  SpecializationFormValues,
} from './types';

const t = initTranslations('account_settings.responsibility_specializations.form_element');

const SpecializationFormElement = ({
  arrayIndex,
  formValue,
  removeFormElement,
}: SpecializationFormElementProps) => {
  const { setFieldValue, handleChange, touched, errors, values } =
    useFormikContext<SpecializationFormValues>();
  const isLastActiveSpecialization =
    values.specializations.filter((tag) => !tag._destroy).length === MIN_SPECIALIZATIONS_COUNT;

  const handleRemoveFormElement = useCallback(() => {
    if (!!formValue.id) {
      setFieldValue(`specializations.${arrayIndex}._destroy`, true);
    } else {
      removeFormElement(arrayIndex);
    }
  }, [arrayIndex, formValue.id, removeFormElement, setFieldValue]);

  return (
    <FormElementWrapper
      animate={!formValue.id}
      className='responsibility-specialization-form-element'
    >
      <SpecializationColorPicker
        colors={SPECIALIZATION_LABEL_COLORS}
        id={`specialization-label-color-picker-${arrayIndex}`}
        menuId={`specialization-label-color-picker-${arrayIndex}`}
        selectedColor={formValue.color}
        setColor={(color) => setFieldValue(`specializations.${arrayIndex}.color`, color)}
      />
      <InputFieldWithCharacterCounter
        errorText={
          touched.specializations?.[arrayIndex]?.terminology &&
          errors.specializations?.[arrayIndex] &&
          (errors.specializations[arrayIndex] as SpecializationError).terminology
        }
        inputFor='Terminology'
        maxCharacters={MAX_SPECIALIZATION_TAG_CHARACTERS}
        name={`specializations.${arrayIndex}.terminology`}
        onChange={handleChange}
        placeholder={t('placeholder')}
        value={formValue.terminology}
      />
      {!isLastActiveSpecialization && (
        <IconButton
          ariaLabel={t('aria_remove_specialization')}
          buttonSize='sm'
          className='remove-specialization-tag-button'
          name='trash-can'
          onClick={handleRemoveFormElement}
        />
      )}
    </FormElementWrapper>
  );
};

export default SpecializationFormElement;
