import styled, { css } from 'styled-components';

import getBackgroundColor from '../../../../lib/surveys/getLetterBackgroundColor';
import Link from '../../../design_system/Link';
import { MultilineTruncatedText } from '../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import Scrollbar from '../../../styled/Scrollbar';
import { fontMd1, fontMd2, fontSm5 } from '../../../styled/TypeSystem';

export const Scrollable = styled.div`
  flex-grow: 1;
  overflow: hidden auto;
  ${Scrollbar};
`;

export const HeadingWrapper = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
  margin: 0 ${({ theme: { constants } }) => constants.spacerMd3};
  display: flex;
  flex-direction: column;
`;

export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-x: hidden;
`;

export const OutlineContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 16rem;
  height: 100vh;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-right: ${({ theme: { constants, vars } }) =>
    `${constants.borderRadiusXs} solid ${vars.borderSurface1}`};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 51; /* needs to be higher than the editor toolbar z-index */

  @media (min-width: ${mediaBreakpointPxXl}) {
    width: 20rem;
  }
`;

export const MainContentWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; /* This will allow it to take the remaining space */
  background-color: ${({ theme: { vars } }) => vars.foundationHover};
  overflow: hidden auto;
  scroll-behavior: smooth;
`;

export const Toolbar = styled.div`
  width: 100%;
  height: 3.75rem;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
  border-bottom: ${({ theme: { constants } }) => constants.borderWidthSm};
`;

export const ToolbarWrapper = styled.div`
  width: 100%;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 21; /* needs to be higher than the poppable z-index */
`;

export const SurveyTitle = styled.h1`
  text-align: center;
  margin: 0 ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${MultilineTruncatedText({})};

  ${fontMd2};
`;

const SurveyDataPreviewWrapperStyles = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SurveyDataPreviewWrapper = styled.div`
  margin: 3.75rem 5rem 0;
  ${SurveyDataPreviewWrapperStyles};
`;

export const SurveyEditDataPreviewWrapper = styled.div`
  margin: 3.75rem ${({ theme: { constants } }) => constants.spacerMd2} 0 5rem;
  ${SurveyDataPreviewWrapperStyles};
`;

export const SurveyDataWrapper = styled.div`
  width: 100%;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} 0`};
  display: flex;
  flex-direction: row;
`;

export const QuestionCardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ControlButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
  > * {
    flex-basis: calc(50% - ${({ theme: { constants } }) => constants.spacerSm3});
    margin-left: auto;
  }
`;

export const TooltipOverlay = styled.span`
  > * {
    width: 100%;
  }
`;

export const ChooseOptionsFormTitle = styled.span`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  ${fontSm5};
`;

// DS Override: remove underline to match designs
export const StyledAddChoiceLink = styled(Link)`
  text-decoration: none;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  &:focus {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledSvgWrapper = styled.div<{ svgColor: 'default' | 'accent' }>`
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  svg {
    width: 1.5rem;
    height: ${({ theme: { constants } }) => constants.heightXs};
    overflow: visible;

    path {
      fill: ${({ svgColor, theme: { vars } }) =>
        svgColor === 'accent' ? vars.accentPrimaryDefault : vars.textDefault};
    }
  }
`;

export const ResponsePlaceholderText = styled.div`
  width: 100%;
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} dashed ${vars.borderHover}`};

  ${fontMd1};
`;

export const SurveyChoiceLetter = styled.div<{ isCorrect: boolean; isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  height: ${({ theme: { constants } }) => constants.heightXs};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: ${getBackgroundColor};
  color: ${({ isSelected, theme: { vars } }) => (isSelected ? vars.textSurface : vars.textDefault)};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid`};
  border-color: ${({ isSelected, isCorrect, theme: { vars } }) =>
    !isSelected ? vars.borderSurface2 : isCorrect ? vars.stateHoverSuccess : vars.stateHoverError};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd1};
`;
