import styled, { css } from 'styled-components';

import { TruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { fontSm4 } from '../../../../../styled/TypeSystem';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const Wrapper = styled.div``;

export const StyledTitle = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm4};
`;

export const LinkedContentBlock = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.constants.spacerMd1};
`;

export const ContentTypeWrapper = styled.div`
  text-align: -webkit-center;
  min-width: 3.75rem;

  svg {
    min-width: 3.75rem;
  }
`;

export const ContentUrlFormWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
`;

export const LinkedContentWrapper = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${constants.spacerMd1};
    width: 100%;
    min-height: 4rem;
    border: ${constants.borderWidthMd} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusMd};
    gap: ${constants.spacerSm3};
    max-width: 23rem;
  `};
`;

export const StyledParagraph = styled.p`
  ${({ theme: { constants, vars } }) => css`
    margin-left: ${constants.spacerSm3};
    flex: 1;
    padding: ${({ theme: { constants } }) => `${constants.spacerMd1} 0 ${constants.spacerMd1} 0`};
    color: ${vars.textDefault};
    ${TruncatedText};
    ${fontSm4};
  `};
`;
