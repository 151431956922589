import React from 'react';

import { useOnboarding } from '../../../../../contexts/OnboardingContext';
import { CardContainer, CardDescription, CardEmoji, CardTitle } from './styles';
import { VariationCardProps } from './types';

const VariationCard = ({ emoji, title, description, type }: VariationCardProps) => {
  const { setFormData, formData } = useOnboarding();

  return (
    <CardContainer
      id={`onboarding-variation-card-${type}`}
      isActive={formData.onboarding_variation === type}
      onClick={() => setFormData({ ...formData, onboarding_variation: type })}
    >
      <CardEmoji>{emoji}</CardEmoji>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContainer>
  );
};

export default VariationCard;
