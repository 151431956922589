import { Dispatch, SetStateAction, useState } from 'react';

import { SimpleUser } from '../types/User';

export type PeopleState = {
  people: PeopleResponse;
  setPeople: Dispatch<SetStateAction<PeopleResponse>>;
  paginationLoading: boolean;
  dataIsLoading: boolean;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  requestNextPage: boolean;
  setRequestNextPage: Dispatch<SetStateAction<boolean>>;
};

export interface PeopleResponse {
  has_more: boolean;
  users: SimpleUser[];
  page: number;
}

export default function usePeopleState(): PeopleState {
  const [people, setPeople] = useState<PeopleResponse>({ has_more: true, users: [], page: 1 });
  const [errorMessage, setErrorMessage] = useState('');
  const [requestNextPage, setRequestNextPage] = useState(false);

  return {
    people,
    setPeople,
    paginationLoading: false,
    dataIsLoading: false,
    errorMessage,
    setErrorMessage,
    requestNextPage,
    setRequestNextPage,
  };
}
