import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction } from 'react';

import { usePlanBrandColors } from '../../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../../hooks/billing/usePlanTitle';
import { couponToDiscount } from '../../../../../../../lib/billing/stripeCouponToDiscount';
import { getFormattedPrice } from '../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../lib/initTranslations';
import {
  CurrentSimplePricingPlan,
  NewSimplePricingPlan,
} from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { AddonItems } from '../../../../../../../redux/services/resourceApis/billing/types';
import { Coupon } from '../../../../../../../types/Coupon';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { OverlayContentHeaderTitle } from '../../../../../shared/FullScreenOverlay/styles';
import AddonsSection from '../../../../shared/AddonsSection/AddonsSection';
import {
  AdditionalInfo,
  AddonsSectionWrapper,
  Divider,
  DueTodayWrapper,
  PlanCreditTitle,
  PlanCreditTitleWrapper,
  PlanCreditWrapper,
  PlanName,
  PlanPrice,
  StyledIcon,
  SummaryWrapper,
  TooltipIconWrapper,
} from '../../../../shared/BillingFullScreenOverlay/Summary/styles';
import PromoCodeField from '../../../../shared/PromoCodeField/PromoCodeField';
import { PlanInterval, PlanNameWrapper, PlanPriceWrapper } from '../../../../shared/styles';

const t = initTranslations('billing.full_screen_overlay_content.summary');

type SummaryProps = {
  addonItems: AddonItems;
  newPlanData: NewSimplePricingPlan;
  coupon: Coupon;
  currentPlan: CurrentSimplePricingPlan;
  currentDate: Date;
  isConfigurePlan?: boolean;
  setAppliedPromoCode?: Dispatch<SetStateAction<string>>;
  appliedPromoCode?: string;
};

const Summary = ({
  addonItems,
  newPlanData,
  coupon,
  currentDate,
  currentPlan,
  setAppliedPromoCode,
  appliedPromoCode,
}: SummaryProps) => {
  const formattedCurrentDate = dayjs(currentDate).format('MMM D'); // e.g. Jul 1
  const { name, dueToday, nextPaymentDate, credit, totalPrice, stripePlanId, billingInterval } =
    newPlanData;
  const discount = couponToDiscount(coupon);
  const showDueTodaySection = currentPlan.stripePlanId !== stripePlanId;
  const showCreditSection = credit < 0;
  const { color: planBrandColor } = usePlanBrandColors({ name });
  const planTitle = usePlanTitle({ name });
  const isAnnualInterval = billingInterval === 'year';

  return (
    <SummaryWrapper>
      <OverlayContentHeaderTitle withBottomMargin>{t('title')}</OverlayContentHeaderTitle>
      <PlanNameWrapper>
        <PlanName planBrandColor={planBrandColor}>{planTitle}</PlanName>
        {totalPrice && (
          <PlanPriceWrapper>
            <PlanPrice>{getFormattedPrice(totalPrice, discount, isAnnualInterval)}</PlanPrice>
            <PlanInterval>/{t('month')}</PlanInterval>
          </PlanPriceWrapper>
        )}
      </PlanNameWrapper>
      {showCreditSection && (
        <PlanCreditWrapper>
          <PlanCreditTitleWrapper>
            <PlanCreditTitle>{t('plan_credit')}</PlanCreditTitle>
            <TooltipIconWrapper data-for='plan-credit-tooltip' data-tip>
              <StyledIcon name='circle-info' weight='regular' />
            </TooltipIconWrapper>
            <Tooltip id='plan-credit-tooltip' place='bottom' text={t('plan_credit_tooltip_text')} />
          </PlanCreditTitleWrapper>
          <span>{getFormattedPrice(credit)}</span>
        </PlanCreditWrapper>
      )}
      {addonItems && (
        <>
          <Divider />
          <AddonsSectionWrapper>
            <AddonsSection addonItems={addonItems} />
          </AddonsSectionWrapper>
        </>
      )}
      {showDueTodaySection && (
        <>
          <Divider />
          <DueTodayWrapper>
            <span>{t('due_today')}</span>
            <span>
              {getFormattedPrice(dueToday)}
              {dueToday > 0 && <AdditionalInfo>{t('plus_taxes')}</AdditionalInfo>}
            </span>
          </DueTodayWrapper>
          <AdditionalInfo>
            {formattedCurrentDate} – {nextPaymentDate}
          </AdditionalInfo>
        </>
      )}
      {setAppliedPromoCode && (
        <PromoCodeField
          appliedPromoCode={appliedPromoCode}
          couponName={coupon?.name}
          setAppliedPromoCode={setAppliedPromoCode}
        />
      )}
    </SummaryWrapper>
  );
};

export default Summary;
