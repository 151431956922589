import styled from 'styled-components';

import Loader from '../../../../../design_system/Triage/Loader';
import { MultilineTruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../../styled/TypeSystem';

export const StyledLoader = styled(Loader)`
  height: 14rem;
  align-items: center;
`;

export const SearchContainer = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const FormElementsContainer = styled.div`
  width: 100%;
  height: 14rem;
  overflow-y: auto;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
`;

export const CheckboxElementContainer = styled.div`
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.constants.spacerMd2} 0;
`;

export const Emoji = styled.span`
  margin-right: ${({ theme }) => theme.constants.spacerSm3};
`;

export const CheckboxLabelContainer = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  ${MultilineTruncatedText({ lineClamp: 1 })};
`;

export const SmartTestModalDisclaimer = styled.div`
  width: 100%;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: ${({ theme: { constants } }) => constants.spacerMd1};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};

  ${fontSm5};
`;
