import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import usePageTitle from '../../../../hooks/usePageTitle';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import Loader, { LoaderContainer } from '../../../design_system/Triage/Loader';
import { mediaBreakpointPxMd, mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import FlexContainer from '../../../styled/FlexContainer';
import PageContent from '../../shared/page_content';

const OrgChartWrapper = styled(FlexContainer)`
  height: 100%;
`;

const HeaderWrapper = styled.div<{ hasPadding: boolean }>`
  padding-top: ${({ theme: { constants }, hasPadding }) =>
    hasPadding ? constants.spacerMd2 : '0'};
  padding-inline: ${({ theme: { constants }, hasPadding }) =>
    hasPadding ? constants.spacerMd2 : '0'};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    padding-top: ${({ theme: { constants }, hasPadding }) =>
      hasPadding ? constants.spacerMd3 : '0'};
    padding-inline: ${({ theme: { constants }, hasPadding }) =>
      hasPadding ? constants.spacerLg1 : '0'};
  }

  @media (min-width: ${mediaBreakpointPxXl}) {
    padding-top: ${({ theme: { constants }, hasPadding }) =>
      hasPadding ? constants.spacerLg2 : '0'};
    padding-inline: ${({ theme: { constants }, hasPadding }) =>
      hasPadding ? constants.spacerLg2 : '0'};
  }
`;

const BodyWrapper = styled.div<{ rootNodeSet: boolean }>`
  /* This is required to fix issues in safari and the svg tree */
  ${({ rootNodeSet }) => (rootNodeSet ? 'height: 1px;' : '')}
  flex-grow: 1;
  overflow: hidden;
`;

interface OrgChartProps {
  header: ReactElement;
  body: ReactElement;
  isLoading: boolean;
  error: unknown | undefined;
  rootNodeSet: boolean;
  pageId: 'org_chart_index' | 'role_chart_index';
}

const OrgChart = ({ isLoading, error, header, body, pageId, rootNodeSet }: OrgChartProps) => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: pageId, productTerm });

  if (error) return <BasicErrorDisplay error={error} />;

  return (
    <PageContent id={pageId} mode={rootNodeSet ? 'fill-content' : 'normal'}>
      <OrgChartWrapper direction='column'>
        <HeaderWrapper hasPadding={rootNodeSet}>{header}</HeaderWrapper>
        <BodyWrapper rootNodeSet={rootNodeSet}>
          {isLoading ? (
            <LoaderContainer>
              <Loader size='lg' />
            </LoaderContainer>
          ) : (
            body
          )}
        </BodyWrapper>
      </OrgChartWrapper>
    </PageContent>
  );
};

export default OrgChart;
