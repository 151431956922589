import { Editor } from '@tiptap/core';
import { Transaction } from '@tiptap/pm/state';
import { Content, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useCallback, useEffect, useState } from 'react';

import { AccountEditorProvider } from '../../../../../../../../contexts/AccountEditorContext';
import { EditorProvider } from '../../../../../../../../contexts/EditorContext';
import { EditorToolbarProvider } from '../../../../../../../../contexts/EditorToolbarContext';
import useContentStyles from '../../../../../../../../hooks/useContentStyles';
import useCurrentAccount from '../../../../../../../../hooks/useCurrentAccount';
import { StyledEditorContent } from '../../../../../../editor/shared/styles';
import Iframe from '../../../../../../editor/tiptap_extensions/iframe';
import { EditorWrapper } from './styles';
import Toolbar from './Toolbar/Toolbar';
import { LoomVideoResponseEditorProps } from './types';

const LoomVideoResponseEditor = ({
  content,
  handleLoomInsert,
  editable = false,
  questionId,
}: LoomVideoResponseEditorProps) => {
  const account = useCurrentAccount();
  const contentStyles = useContentStyles();
  const [initialContent, setInitialContent] = useState<Content | undefined>(content);
  const [isRecording, setIsRecording] = useState(false);

  const handleEditorUpdate = useCallback(
    (props: { editor: Editor; transaction: Transaction }) => {
      if (editable) {
        handleLoomInsert && handleLoomInsert(props.editor.getJSON());
        setIsRecording(false);
      }
    },
    [editable, handleLoomInsert]
  );

  const editor = useEditor(
    {
      extensions: [StarterKit, Iframe],
      onUpdate: handleEditorUpdate,
      editable: false,
      content: initialContent,
    },
    [initialContent]
  );

  useEffect(() => {
    setInitialContent(content);
    // We need to re-render only with questionId change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  if (!editor) return <></>;

  return (
    <EditorWrapper className='loom-video-response-editor'>
      <EditorProvider editor={editor}>
        <AccountEditorProvider account={account}>
          <StyledEditorContent $contentStyles={contentStyles} editor={editor} />
          {editable && (
            <EditorToolbarProvider buttons={[]} context='docked'>
              <Toolbar
                isEmpty={!content || editor.isEmpty}
                isRecording={isRecording}
                setIsRecording={setIsRecording}
              />
            </EditorToolbarProvider>
          )}
        </AccountEditorProvider>
      </EditorProvider>
    </EditorWrapper>
  );
};

export default LoomVideoResponseEditor;
