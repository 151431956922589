import styled from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';

export const SurveyScoreBadge = styled.div`
  width: fit-content;
  align-self: center;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5};
`;
