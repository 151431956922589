import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import styled, { useTheme } from 'styled-components';

import { useImageFlyoutContext } from '../../../../../contexts/ImageFlyoutContext';
import { getCroppedImg } from '../../../../../lib/cropImage';
import initTranslations from '../../../../../lib/initTranslations';
import { readFile } from '../../../../../lib/readFile';
import { PixelCrop } from '../../../../../types/Cropper';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import RepositionButtons from './RepositionButtons/RepositionButtons';
import { CenterTextContainer, SharedImageStyles } from './styles';

const CropContainer = styled.div`
  position: relative;
  ${SharedImageStyles}
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

const t = initTranslations('editor.topic.header');

type Props = {
  handleImageChange: (image: { image: string }) => void;
  cropContainerRef: React.RefObject<HTMLDivElement>;
  cancel: () => void;
  isLoading: boolean;
  setShowCropper: (showCropper: boolean) => void;
};

const HeaderCropper = ({
  handleImageChange,
  cropContainerRef,
  cancel,
  isLoading,
  setShowCropper,
}: Props) => {
  const { flash } = useFlashNotification();
  const {
    constants: { borderRadiusXl },
  } = useTheme();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [tempUrl, setTempUrl] = useState<string | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>({ x: 0, y: 0, width: 0, height: 0 });

  const onCropComplete = useCallback((_, croppedAreaPixels: PixelCrop) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const { imageFile, selectedImageUrl, imageDataDispatch } = useImageFlyoutContext();

  useEffect(() => {
    const fetchImageDataUrl = async () => {
      if (imageFile) {
        const fileDataUrl = (await readFile(imageFile)) as string;

        setTempUrl(fileDataUrl);
      } else if (selectedImageUrl) {
        setTempUrl(selectedImageUrl);
      } else {
        setTempUrl(null);
      }
    };

    fetchImageDataUrl();
  }, [imageFile, selectedImageUrl, setTempUrl]);

  const updateCoverPhotoWithCroppedImage = useCallback(() => {
    if (tempUrl) {
      (async () => {
        try {
          const croppedImage = await getCroppedImg({
            imageSrc: tempUrl,
            pixelCrop: croppedAreaPixels,
          });

          if (croppedImage) {
            handleImageChange({ image: croppedImage });
          }
        } catch (e) {
          flash('error', t('error_cropping_image'));
          setShowCropper(false);
          console.error(e);
        }

        imageDataDispatch({ type: 'resetImageData' });
      })();
    }
  }, [croppedAreaPixels, flash, handleImageChange, imageDataDispatch, setShowCropper, tempUrl]);

  const cancelButtonAction = () => {
    imageDataDispatch({ type: 'resetImageData' });
    cancel();
  };

  return (
    <CropContainer>
      {tempUrl && (
        <Cropper
          aspect={2}
          classes={{ cropAreaClassName: 'header-crop-area' }}
          crop={crop}
          cropSize={{
            width: cropContainerRef.current?.clientWidth || 1200,
            height: cropContainerRef.current?.clientHeight || 200,
          }}
          image={tempUrl}
          minZoom={1}
          objectFit='horizontal-cover'
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          showGrid={false}
          style={{ containerStyle: { borderRadius: `${borderRadiusXl} ${borderRadiusXl} 0 0` } }}
        />
      )}
      <ButtonsWrapper>
        <RepositionButtons
          cancel={cancelButtonAction}
          isLoading={isLoading}
          update={updateCoverPhotoWithCroppedImage}
        />
      </ButtonsWrapper>
      <CenterTextContainer>
        <span>{t('reposition_cover_image')}</span>
      </CenterTextContainer>
    </CropContainer>
  );
};

export default HeaderCropper;
