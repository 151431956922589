import { useCallback, useMemo } from 'react';
import { AccountState } from 'types/AccountState';
import { CurrentUser } from 'types/CurrentUser';

export default function useMutiny(
  user: CurrentUser | undefined,
  account: AccountState | undefined
) {
  const properties = useMemo(() => {
    return account && user
      ? {
          firstName: user.name.split(' ')[0],
          lastName: user.name.split(' ')[1],
          email: user.mutinyEmail,
          company: {
            id: account.mutinyId,
            name: account.name,
            employeeCount: account.totalUserSlotsCount,
            plan: account.stripePlanId,
            industry: account.industry,
          },
        }
      : null;
  }, [account, user]);

  const identify = useCallback(() => {
    if (!properties || !window.mutiny?.client || !user?.id) {
      return;
    }
    window.mutiny.client.identify(user.id, properties);
  }, [user?.id, properties]);

  return {
    mutinyIdentify: identify,
  };
}
