import { DynamicOverlayContextType, DynamicOverlayType } from './types';

export const initialDynamicOverlayValue: DynamicOverlayType = {
  isOpen: false,
  content: null,
  targetRef: null,
};

export const initialDynamicOverlayContextValue: DynamicOverlayContextType = {
  overlay: initialDynamicOverlayValue,
  openOverlay: () => null,
  closeOverlay: () => null,
};
