import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontMd1 } from '../../../../styled/TypeSystem';

export const PromoCodeFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
`;

export const PromoCodeFieldLabelWrapper = styled.div<{ hasPreAppliedPromoCode: boolean }>`
  display: flex;
  align-items: center;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  cursor: ${({ hasPreAppliedPromoCode }) => (hasPreAppliedPromoCode ? 'default' : 'pointer')};
  ${fontMd1};
`;

export const PromoCodeFieldChevron = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const PromoCodeInputWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => `calc(${constants.spacerMd3} + ${constants.spacerSm2})`};
`;

export const PromoCodePillButton = styled.button<{ hasPreAppliedPromoCode: boolean }>`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderRadiusXs} solid ${vars.borderDefault}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  text-decoration: none;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerMd2}`};
  width: max-content;
  cursor: ${({ hasPreAppliedPromoCode }) => (hasPreAppliedPromoCode ? 'default' : 'pointer')};
`;

export const PillText = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const PillName = styled.span`
  ${TruncatedText({})};
  max-width: 15rem;
`;
