import styled from 'styled-components';

import { fontSm4 } from '../../../../../styled/TypeSystem';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const Wrapper = styled.div``;

export const StyledTitle = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm4};
`;

export const LinkedContentBlock = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.constants.spacerMd1};
`;

export const ContentTypeWrapper = styled.div`
  text-align: -webkit-center;
  min-width: 3.75rem;

  svg {
    min-width: 3.75rem;
  }
`;

export const ContentUrlFormWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
`;
