import React, { useEffect, useMemo } from 'react';

import { useOrgChartConfigSlideout } from '../../../../../../contexts/OrgChartConfigSlideoutContext';
import { stringifyNumber } from '../../../../../../lib/convertValues';
import initTranslations from '../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useAssignReportsToMutation } from '../../../../../../redux/services/resourceApis/orgChart/orgChartApi';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import SingleSelectField from '../../../../../design_system/Triage/fields/SingleSelectField';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { extractResourcesFromError, parseOrgChartConflictError } from '../ReportsToConflictModal';
import { SelectWrapper, StyledResourceListItem } from './styles';
import { ResourceReportsToListItem } from './types';

const ResourceListItem = ({
  resource,
  setConflictResources,
  formatResourceOptions,
}: ResourceReportsToListItem) => {
  const { flash } = useFlashNotification();
  const { resources, orgChartResourceType, setIsSubmitting } = useOrgChartConfigSlideout();
  const t = initTranslations(`org_chart.${orgChartResourceType}_config_slideout.set_reports_to`);
  const { id: resourceId, parent, descendant_of: descendantOf } = resource;
  const parentId = parent?.id;
  const showAssistiveText = parentId && !descendantOf;
  const resourceOptions = useMemo(
    () => resources.filter((user) => user.id !== resourceId),
    [resourceId, resources]
  );
  const [assignReportsTo, result] = useAssignReportsToMutation();
  const { isLoading, error: updateReportsToError, reset } = result;

  useEffect(() => {
    setIsSubmitting(isLoading);
  }, [isLoading, setIsSubmitting]);

  useEffect(() => {
    if (updateReportsToError) {
      reset();
      setIsSubmitting(false);

      const error = parseOrgChartConflictError(updateReportsToError);
      if (!error) {
        flash('error', messageFromError(updateReportsToError)?.join(', '));
        return;
      }

      const { errorResource, errorParent, errorParentOfParent } = extractResourcesFromError(
        error,
        resources
      );

      if (errorResource && errorParent && errorParentOfParent) {
        setConflictResources([
          { resource: errorResource, reportsToResource: null },
          { resource: errorParent, reportsToResource: errorParentOfParent },
        ]);
      }
    }
  }, [flash, reset, resources, setConflictResources, setIsSubmitting, updateReportsToError]);

  return (
    <StyledResourceListItem id={`resource-${resourceId}`}>
      <SelectWrapper>
        <SingleSelectField
          className='reports-to-select'
          defaultValue={stringifyNumber(parentId)}
          disabled={isLoading}
          fieldLabelText={t('reports_to_header')}
          isClearable
          loading={isLoading}
          onNonDefaultSelected={(value: string) => {
            assignReportsTo({
              resourceId,
              reportsToResourceId: value,
              resourceType: orgChartResourceType,
            });
          }}
          options={formatResourceOptions(resourceOptions)}
          placeholder={t('select_placeholder')}
          value={stringifyNumber(parentId)}
        />
        {showAssistiveText && (
          <AssistiveText
            id={`assistive-text-help-${resourceId}`}
            text={t('assistive_text_help')}
            type='help'
          />
        )}
      </SelectWrapper>
    </StyledResourceListItem>
  );
};

export default ResourceListItem;
