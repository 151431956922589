import styled from 'styled-components';

import { mediaBreakpointPxLg } from '../../../../styled/Breakpoint';

export const CheckoutImageContainer = styled.div`
  max-width: 25rem;
  flex-grow: 1;
  > img {
    height: auto;
    width: 100%;
  }
`;

export const CheckoutLogosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: 3.75rem;

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: row;
  }
`;

export const CheckoutPartnerLogo = styled.img`
  max-width: 11.25rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  object-fit: cover;
`;

export const CheckoutTrainualLogo = styled.img<{ withDefaultLogo: boolean }>`
  max-width: ${({ withDefaultLogo }) => (withDefaultLogo ? '25rem' : '16.25rem')};
`;
