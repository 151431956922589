import { CropArgs } from '../types/Cropper';
import { generateRandomFileName } from './generateRandomFilename';
import { readFile } from './readFile';

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

// Returns the new bounding area of a rotated rectangle.
function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

async function toDataURL(url: string): Promise<string> {
  const response = await fetch(url).then((res) =>
    res.ok ? res : Promise.reject(new Error(`Failed to fetch image ${url}`))
  );
  const blob = await response.blob();
  const file = new File([blob], generateRandomFileName(), { type: blob.type });

  return readFile(file);
}

// This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
export async function getCroppedImg({
  imageSrc,
  pixelCrop = null,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
}: CropArgs) {
  const isBase64Src = imageSrc.startsWith('data:image/');
  const base64Image = isBase64Src ? imageSrc : await toDataURL(imageSrc); // Convert image to base64 to avoid cross-origin issues
  const image = (await createImage(base64Image)) as HTMLImageElement;
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) return null;

  const rotRad = getRadianAngle(rotation);

  // Calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // Set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // Translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // Draw rotated image
  ctx.drawImage(image, 0, 0);

  // CroppedAreaPixels values are bounding box relative
  // Extract the cropped image using these values
  if (pixelCrop) {
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // Set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // Paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    return canvas.toDataURL('image/png');
  }
}
