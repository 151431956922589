import { TiptapCollabProvider, WebSocketStatus } from '@hocuspocus/provider';
import { Collaboration } from '@tiptap/extension-collaboration';
import { CollaborationCursor } from '@tiptap/extension-collaboration-cursor';
import { Editor, useEditor } from '@tiptap/react';
import { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import { userInitials } from '../../../../../lib/userNaming';
import { EditorUser } from '../../../../../types/Editor';
import { paletteColorDecoder } from '../../../../design_system/helpers';
import { COLLABORATION_USER_COLORS } from '../../shared/constants/editor';
import { EditorExtensions } from '../../shared/constants/extensions';

type EditableContent = {
  collabState: WebSocketStatus;
  collabUsers: EditorUser[];
  editor: Editor | null;
  isAILoading: boolean;
};

type Props = {
  provider: TiptapCollabProvider;
  stepId: number;
};

const randomPaletteColors = () => {
  return COLLABORATION_USER_COLORS[Math.floor(Math.random() * COLLABORATION_USER_COLORS.length)];
};

export const useEditableContent = ({ provider, stepId }: Props): EditableContent => {
  const [isAILoading, setIsAILoading] = useState(false);
  const [collabState, setCollabState] = useState<WebSocketStatus>(WebSocketStatus.Connecting);
  const { configs: privateConfigs } = usePrivateConfigs();
  const { name, avatarUrl } = useCurrentUser();
  const { palettes } = useTheme();
  const decodedUserColor = useMemo(
    () => paletteColorDecoder(palettes, randomPaletteColors()),
    [palettes]
  );

  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          translate: 'no',
        },
      },
      extensions: [
        ...EditorExtensions({
          collaborationEnabled: true,
          isEditable: true,
          aiAppId: privateConfigs['TIPTAP_AI_APP'],
          aiToken: privateConfigs['TIPTAP_AI_SECRET'],
          setIsAILoading,
          palettes,
        }),
        Collaboration.configure({
          document: provider.document,
        }),
        CollaborationCursor.configure({
          provider,
          user: {
            avatarUrl,
            name,
            color: decodedUserColor,
          },
        }),
      ],
      autofocus: true,
      editable: true,
    },
    [stepId]
  );

  const collabUsers = useMemo(() => {
    if (!editor?.storage.collaborationCursor?.users) {
      return [];
    }

    return editor.storage.collaborationCursor?.users.map((user: EditorUser) => {
      return { ...user, initials: userInitials(user.name) };
    });
  }, [editor?.storage.collaborationCursor?.users]);

  useEffect(() => {
    provider.on('status', (event: { status: WebSocketStatus }) => {
      setCollabState(event.status);
    });
  }, [provider]);

  return { collabState, collabUsers, editor, isAILoading };
};
