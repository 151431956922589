import React, { Dispatch } from 'react';

import Avatar from '../../../../../design_system/display/avatar';
import Checkbox from '../../../../../design_system/input/controls/Checkbox';
import { TableAction } from '../reducer';
import {
  AvatarWrapper,
  NameCellWrapper,
  NameText,
  NameTitleWrapper,
  StyledNameCell,
  TitleText,
} from '../styles';

interface Props {
  id: number;
  name: string;
  avatar?: string;
  jobTitle?: string;
  selectedUsers: number[];
  tableDispatch: Dispatch<TableAction>;
}

const NameCell = ({ id, name, avatar, jobTitle, tableDispatch, selectedUsers }: Props) => {
  return (
    <StyledNameCell className='manage-completions-name-cell'>
      <NameCellWrapper id={`manage-completions-checkbox-wrapper-${id}`}>
        <Checkbox
          checked={selectedUsers.includes(id)}
          id={`manage-completions-name-cell-checkbox-${id}`}
          name='name-header-checkbox'
          onCheck={() => {
            tableDispatch({ type: 'toggleUserSelect', userId: id });
          }}
        />
        <AvatarWrapper id={`manage-completions-avatar-${id}`}>
          <Avatar image={avatar} name={name} shape='circle' size='sm' />
        </AvatarWrapper>
        <NameTitleWrapper id={`manage-completions-name-title-${id}`}>
          <NameText>{name}</NameText>
          <TitleText>{jobTitle}</TitleText>
        </NameTitleWrapper>
      </NameCellWrapper>
    </StyledNameCell>
  );
};

export default NameCell;
