import React from 'react';

import { HeaderRow } from '../../../people/UsersOutline/Table/Styles';
import { Column, RequestColumn, columns } from './RequestColumn';
import RequestsTableHeaderCell from './RequestsTableHeaderCell';

type Props = {
  activeHeader: RequestColumn;
};

const RequestsTableHeader = ({ activeHeader }: Props) => {
  return (
    <thead>
      <HeaderRow>
        {columns
          .filter(({ display }) => display !== 'hidden')
          .map((column: Column) => {
            const isActiveColumn = activeHeader === column.columnName;

            return (
              <RequestsTableHeaderCell
                activeHeader={isActiveColumn}
                column={column}
                key={`header-${column.columnName}`}
              />
            );
          })}
      </HeaderRow>
    </thead>
  );
};

export default RequestsTableHeader;
