import styled from 'styled-components';

import RouterLink from '../../publicApplication/RouterLink';

export const AttemptsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const StyledLink = styled(RouterLink)`
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const AnsweredQuestionsWrapper = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-start;
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: ${({ theme: { constants } }) => constants.spacerLg1};
`;

export const AnsweredQuestionsList = styled.div`
  width: 100%;
`;
