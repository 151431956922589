import React, { ReactNode } from 'react';
import styled from 'styled-components';

type RowItemsAlignment = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';

type RowJustify =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export interface Props {
  alignItems?: RowItemsAlignment;
  justifyContent?: RowJustify;
  className?: string;
  children: ReactNode;
}

const StyledRow = styled.div<Props>`
  display: flex;
  flex-flow: row wrap;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const Row = ({ children, ...props }: Props) => {
  return <StyledRow {...props}>{children}</StyledRow>;
};

export default Row;
