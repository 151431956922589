import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import InputField from '../../../../design_system/Triage/InputField';
import { fontSm4 } from '../../../../styled/TypeSystem';

export const StyledInputField = styled(InputField)`
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 0 0`};
`;

export const StyledIcon = styled(Icon)`
  margin-right: 0.375rem;
`;

export const InfoMessageWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0.38rem 0 0;
  ${fontSm4};
`;
