import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { DefaultHeaderStyled } from '../../../people/UsersOutline/Table/Styles';
import { StyledHeaderCell } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { StyledSortIcon } from '../../Styles';
import { SortProps } from '../../TableTypes';
import { Column } from './SurveyReportColumn';

const t = initTranslations('reports.survey_reports_table');

type Props = SortProps & {
  column: Column;
  activeHeader: boolean;
  setActiveHeader: () => void;
};

const SurveyReportTableHeaderCell = ({
  column: { columnName },
  activeHeader,
  setActiveHeader,
  sortIcon,
  isSortable,
}: Props) => {
  return (
    <DefaultHeaderStyled>
      <StyledHeaderCell
        active={activeHeader}
        className={isSortable ? `sortable-column ${columnName}` : undefined}
        isSortable={isSortable}
        onClick={() => setActiveHeader && setActiveHeader()}
      >
        {t(`headers.${columnName}`)}
        {sortIcon && <StyledSortIcon name={sortIcon} size='2xs' weight='solid' />}
      </StyledHeaderCell>
    </DefaultHeaderStyled>
  );
};

export default SurveyReportTableHeaderCell;
