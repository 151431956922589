import styled from 'styled-components';

export const StripeElementWrapper = styled.div<{
  withBottomMargin?: boolean;
  isRowWithTwoFields?: boolean;
}>(({ withBottomMargin, isRowWithTwoFields, theme: { constants, vars } }) => ({
  flexGrow: 1,
  marginBottom: withBottomMargin ? constants.spacerMd2 : 0,
  ...(isRowWithTwoFields && { flexBasis: '50%' }),

  '.StripeElement': {
    display: 'flex',
    alignItems: 'center',
    border: `${constants.borderWidthSm} solid ${vars.borderDefault}`,
    borderRadius: constants.borderRadiusLg,
    minHeight: constants.heightMd,
    padding: `0 ${constants.spacerMd2}`,
    backgroundColor: vars.foundationSurface1,
    cursor: 'text',

    '> div': {
      width: '100%',
    },

    '&--focus': {
      borderColor: vars.accentPrimaryDefault,
    },

    '&--invalid': {
      borderColor: vars.stateError,
    },
  },
}));
