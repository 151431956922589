import { useEffect, useState } from 'react';

const isTruncated = (el: HTMLElement, maxLines: number) => {
  if (maxLines === 1) return el.offsetWidth < el.scrollWidth;

  const style = window.getComputedStyle(el);
  const lineHeight = parseFloat(style.lineHeight);
  const maxHeight = lineHeight * maxLines;

  return el.scrollHeight > maxHeight;
};

type UseTruncatedTextOptions = {
  maxLines?: number;
};

const useTruncatedText = <T extends HTMLElement>(
  element: React.RefObject<T>,
  { maxLines = 2 }: UseTruncatedTextOptions = {}
) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const currentElement = element.current;
    if (currentElement) {
      setShowTooltip(isTruncated(currentElement, maxLines));
    }
  }, [element, maxLines]);

  return showTooltip;
};

export default useTruncatedText;
