import React from 'react';

import { RegisteredId } from '../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';

const t = initTranslations('billing.full_screen_overlay_content');

type SubmitButtonProps = {
  handleSubmit: () => void;
  isLoading: boolean;
  isDisabled: boolean;
  text: string;
  hasCardDetails: boolean;
  id: RegisteredId;
};

const SubmitButton = ({
  isLoading,
  isDisabled,
  handleSubmit,
  text,
  hasCardDetails,
  id,
}: SubmitButtonProps) => {
  const showTooltip = !(hasCardDetails || isLoading);

  return (
    <>
      <div data-for='submit-button-tooltip' data-tip='true'>
        <DefaultButton
          buttonType='primary'
          disabled={isDisabled}
          id={id}
          loading={isLoading}
          onClick={handleSubmit}
          text={text}
        />
      </div>
      {showTooltip && <Tooltip id='submit-button-tooltip' text={t('submit_button_tooltip')} />}
    </>
  );
};

export default SubmitButton;
