import React from 'react';

import ProgressBar from '../../../../../design_system/Triage/ProgressBar';
import {
  CompletionContainer,
  CompletionScore,
  ProgressBarWrapper,
} from '../../../IndividualUserReportPage/IndividualUserReportTable/Columns/styles';
import { ColumnComponentProps } from '../types';

const CompletionPercentage = ({ record: { completion_percentage } }: ColumnComponentProps) => {
  return (
    <CompletionContainer>
      <CompletionScore>{completion_percentage}</CompletionScore>
      <ProgressBarWrapper>
        <ProgressBar percent={parseFloat(completion_percentage)} thickness='md' />
      </ProgressBarWrapper>
    </CompletionContainer>
  );
};

export default CompletionPercentage;
