import React from 'react';
import styled from 'styled-components';

import InputField from '../../../../design_system/Triage/InputField';

const PickerContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
  max-width: 20rem;
`;

const InputFieldWrapper = styled.div<{ iconColor: string | null }>`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  svg {
    color: ${({ iconColor }) => iconColor || ''};
  }
`;

export interface Props {
  id: string;
  className?: string;
  hex: string | null;
  name?: string | undefined;
  placeholder: string;
  errorText?: string | false;
  setHexColor: (accent: string) => void;
}

const SimpleColorPicker = ({
  className,
  hex,
  id,
  errorText,
  name,
  placeholder,
  setHexColor,
}: Props) => {
  const handleHexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    hex = e.target.value;
    e.preventDefault();
    if (hex.startsWith('#') || hex.length == 0) {
      setHexColor(e.target.value);
    }
  };
  return (
    <PickerContainer className={className} id={id}>
      <InputFieldWrapper iconColor={hex}>
        <InputField
          errorText={errorText}
          iconName={hex ? 'circle' : undefined}
          iconWeight={hex ? 'solid' : undefined}
          name={name}
          onChange={(e) => handleHexChange(e)}
          placeholder={placeholder}
          value={hex || ''}
        />
      </InputFieldWrapper>
    </PickerContainer>
  );
};

export default SimpleColorPicker;
