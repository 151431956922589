import { Form, Formik, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { useGroup } from '../../../../contexts/GroupContext';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import useFieldErrorsFromFormik from '../../../../hooks/useFieldErrorsFromFormik';
import capitalize from '../../../../lib/capitalize';
import initTranslations from '../../../../lib/initTranslations';
import { useEditGroupMutation } from '../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupWithAssociations } from '../../../../redux/services/resourceApis/groups/types';
import { GroupKind, groupKinds } from '../../../../types/Group';
import { formatOptions } from '../../../design_system/core/CoreSelectField/CoreSelectField';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import SingleSelectField from '../../../design_system/Triage/fields/SingleSelectField';
import InputField from '../../../design_system/Triage/InputField';
import { FormGroup } from '../../shared/FormGroup';
import validateForm from './ValidateForm';

const t = initTranslations('groups.groups_edit.edit_modal');

export type EditGroupFormValues = {
  name: string;
  kind: GroupKind;
};

type EditGroupModalProps = {
  setEditGroupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  group: GroupWithAssociations;
};

const Modal = ({ group, setEditGroupModalOpen }: EditGroupModalProps) => {
  const {
    dirty,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldError,
    setFieldValue,
    setStatus,
    setSubmitting,
    values,
  } = useFormikContext<EditGroupFormValues>();
  const [editGroup, result] = useEditGroupMutation();
  const { error, reset } = result;
  const groupOptions = formatOptions(
    groupKinds.map((groupKind: GroupKind) => ({
      value: groupKind,
      label: capitalize(groupKind),
    }))
  );

  useEffect(() => {
    if (isSubmitting && isValid) {
      editGroup({
        id: group.id,
        name: values.name,
        kind: values.kind,
        description: group.description,
        everyone: false,
        __typename: 'Group',
      });
    }
  }, [editGroup, group, isSubmitting, isValid, values.kind, values.name]);

  useFieldErrorsFromFormik({
    error,
    setFieldError,
    setStatus,
    setSubmitting,
  });

  useDisplayFlashOnResponse({
    result,
    successFunction: () => {
      reset();
      setEditGroupModalOpen(false);
    },
    successMessage: t('update_successful'),
  });

  const taskModalArgs: TaskModalProps = {
    title: t('title'),
    onCloseRequest: () => {
      setEditGroupModalOpen(false);
    },
    primaryButtonText: t('primary_button_text'),
    primaryButtonTask: () => {
      handleSubmit();
    },
    processing: isSubmitting,
    secondaryButtonText: t('secondary_button_text'),
    heapModalName: 'edit-group-modal',
    isDisabled: !dirty || isSubmitting || !!errors.name,
  };

  return (
    <TaskModal {...taskModalArgs}>
      <Form
        autoComplete='off'
        className='edit-group-modal'
        onKeyDown={(event) => {
          if (dirty && event.key === 'Enter' && !errors.name) {
            handleSubmit();
          }
        }}
      >
        <FormGroup className='name-group'>
          <InputField
            autoFocus
            className='analytics-edit-group-name-field'
            disabled={group.everyone}
            errorText={errors.name && capitalize(errors.name)}
            id='name'
            label={t('name_field')}
            name='name'
            onChange={handleChange}
            placeholder={t('name_field_placeholder')}
            type='text'
            value={values.name}
          />
        </FormGroup>
        <FormGroup className='kind-group'>
          <SingleSelectField
            className='analytics-edit-group-kind-field'
            defaultValue={values.kind}
            fieldLabelText={t('group_field')}
            name='kind'
            onNonDefaultSelected={(option: string) => {
              setFieldValue('kind', option);
            }}
            options={groupOptions}
            value={values.kind}
          />
        </FormGroup>
      </Form>
    </TaskModal>
  );
};

export type EditGroupModalFormikWrapperProps = {
  setEditGroupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const GroupEditModal = ({ setEditGroupModalOpen }: EditGroupModalFormikWrapperProps) => {
  const group = useGroup();

  return (
    <Formik
      initialValues={group}
      onSubmit={() => {
        /* API request occurs in `Modal` */
      }}
      validate={validateForm}
      validateOnMount
    >
      <Modal group={group} setEditGroupModalOpen={setEditGroupModalOpen} />
    </Formik>
  );
};

export default GroupEditModal;
