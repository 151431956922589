import styled from 'styled-components';

import Badge from '../../../../../design_system/display/badge';
import { fontSm5 } from '../../../../../styled/TypeSystem';

export const TemplateRolesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm5};
`;

export const StyledBadge = styled(Badge)`
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};
`;
