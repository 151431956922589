import styled from 'styled-components';

import { TruncatedText } from '../../../../../design_system/Triage/TruncatedText';

export const SearchResultsChipsWrapper = styled.span`
  display: flex;
  width: 100%;
`;

export const SearchResultsChipsText = styled.span`
  display: block;
  ${TruncatedText({})};
`;

export const SearchResultsChipsCount = styled.span.attrs({ className: 'notranslate' })`
  display: flex;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;
