import React, { useEffect } from 'react';

import { AccountEditorProvider } from '../../../../contexts/AccountEditorContext';
import { ConsumptionCourseProvider } from '../../../../contexts/ConsumptionCourseContext';
import { EditorProvider } from '../../../../contexts/EditorContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useGetConsumptionTopicQuery } from '../../../../redux/services/resourceApis/courses/topicsApi';
import { GetStepResponse } from '../../../../redux/services/resourceApis/steps/types';
import { trainualApi } from '../../../../redux/services/trainualService';
import { store } from '../../../../redux/stores/store';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import { BaseContentWrapper } from '../shared/styles';
import ViewModeSkeleton from './components/ViewModeSkeleton/ViewModeSkeleton';
import Editor from './Editor/Editor';
import { useConsumeContent } from './hooks/useConsumeContent';
import OutlineContent from './OutlineContent/OutlineContent';
import ToolbarContainer from './ToolbarContainer';

type Props = {
  stepData: GetStepResponse;
};

const ConsumeContent = ({ stepData }: Props) => {
  const { productTerm } = useAccountTerminology();
  const account = useCurrentAccount();

  const { id: stepId, content, courseId } = stepData;

  const { editor } = useConsumeContent({ content, stepId });
  const { data, isLoading, error, isSuccess } = useGetConsumptionTopicQuery({ courseId });

  useEffect(() => {
    if (isSuccess) {
      store.dispatch(
        trainualApi.util.invalidateTags([{ type: 'AssignmentsCurriculums', id: 'LIST' }])
      );
    }
  }, [isSuccess]);

  if (error) return <BasicErrorDisplay error={error} />;
  if (isLoading || !data || !editor) return <ViewModeSkeleton />;

  const { curriculum, ...course } = data;

  return (
    <ConsumptionCourseProvider
      course={course}
      curriculum={curriculum}
      productTerm={productTerm}
      stepId={stepId}
    >
      <OutlineContent />
      <AccountEditorProvider account={account}>
        <EditorProvider editor={editor}>
          <BaseContentWrapper>
            <ToolbarContainer />
            <Editor step={stepData} />
          </BaseContentWrapper>
        </EditorProvider>
      </AccountEditorProvider>
    </ConsumptionCourseProvider>
  );
};

export default ConsumeContent;
