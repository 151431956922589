import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import LinkTabs, { LinkTab } from '../../../../design_system/navigation/LinkTabs';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import { routes } from '../../../publicApplication/applicationRouter';
import CurriculumCompletions from './CurriculumCompletions/CurriculumCompletions';
import CurriculumOutline from './CurriculumOutline/CurriculumOutline';

const TabsWrapper = styled.nav`
  overflow: auto hidden;
  white-space: nowrap;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  @media (min-width: ${mediaBreakpointPxMd}) {
    padding-inline: ${({ theme: { constants } }) => constants.spacerLg2};
  }
`;

export interface Props {
  curriculumId: number;
  tab?: string;
}

const t = initTranslations('curriculums.admin_bottom');

const CurriculumAdminBottom = ({ curriculumId, tab }: Props) => {
  const { slug } = useCurrentAccount();
  const isAnAdmin = useIsAdmin();
  const ability = useCurrentUserAbilities();
  const manageCompletions = ability.can('read', `ManageCurriculumAssigned-${curriculumId}`);
  const showCompletionTab = isAnAdmin || manageCompletions;
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();

  const SubjectOutlineTab: LinkTab = {
    name: t('tabs.subject_outline', { subject: subjectTermSingular }),
    tabId: 'subject-outline-tab',
    is_beta: false,
    to: routes.curriculumAdmin({ id: curriculumId, slug }),
  };

  const CompletionTab: LinkTab = {
    name: t('tabs.completion'),
    tabId: 'completion-tab',
    is_beta: false,
    to: routes.curriculumAdmin({ id: curriculumId, slug, tab: 'completion' }),
  };

  const tabs = showCompletionTab ? [SubjectOutlineTab, CompletionTab] : [SubjectOutlineTab];

  return (
    <>
      <TabsWrapper id='curriculum-admin-bottom'>
        <LinkTabs id='curriculum-admin-bottom-tabs' isUrlParams tabs={tabs} />
      </TabsWrapper>
      {showCompletionTab && tab === 'completion' ? (
        <CurriculumCompletions curriculumId={curriculumId} />
      ) : (
        <CurriculumOutline curriculumId={curriculumId} />
      )}
    </>
  );
};

export default CurriculumAdminBottom;
