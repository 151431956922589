import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import useWindowResize from '../../../../hooks/useWindowResize';
import { RegisteredId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { CharacterCountInterface } from '../../../design_system/ToBeDeprecated/InlineTextarea';
import TextAreaField from '../../../design_system/Triage/fields/TextAreaField';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import OutsideClickHandler from '../../shared/OutsideClickHandler';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
    align-items: center;
  }
`;

const InputRow = styled.div`
  position: relative;
  width: 100%;
`;

const InputWrapper = styled.div<{ hasError: boolean }>`
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars }, hasError }) => (hasError ? vars.stateError : vars.borderSurface2)};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  display: inline-block;
  padding: 0.625rem 1rem 1.5rem;
  position: relative;
  width: 100%;

  &:focus-within {
    border-color: ${({ theme: { vars }, hasError }) =>
      hasError ? vars.stateError : vars.accentPrimaryDefault};
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    padding: ${({ theme: { constants } }) =>
      `${constants.spacerSm1} 4.5rem ${constants.spacerSm1} 0`};
  }
`;

const StyledDefaultButton = styled(DefaultButton)`
  margin: ${({ theme: { constants } }) => `${constants.spacerMd2} 0 0 ${constants.spacerMd2}`};
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-top: 0;
    width: auto;
  }
`;

const ErrorMessageText = styled.div`
  color: ${({ theme: { vars } }) => vars.stateError};
  font-size: 0.875rem;
  max-width: 65%;

  @media (min-width: ${mediaBreakpointPxSm}) {
    max-width: 100%;
    position: absolute;
    overflow-x: visible;
    white-space: nowrap;
  }
`;

const StyledTextAreaField = styled(TextAreaField)`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: none;
  outline: none;
  font-size: 0.875rem;

  @media (min-width: ${mediaBreakpointPxSm}) {
    resize: none;
  }
  &::placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }
`;

const StyledCharacterCount = styled.span<{ hasError: boolean }>`
  right: 1rem;
  color: ${({ theme: { vars }, hasError }) => (hasError ? vars.stateError : vars.textSubdued)};
  font-size: 0.703rem;
  bottom: 0.5rem;
  position: absolute;
`;

const InputCharacterCount = ({ charactersUsed, maxCharacters }: CharacterCountInterface) => {
  if (!maxCharacters) {
    return <></>;
  }

  return (
    <StyledCharacterCount hasError={charactersUsed > maxCharacters}>
      {charactersUsed} / {maxCharacters}
    </StyledCharacterCount>
  );
};

const t = initTranslations('input_line_field');

export interface Props {
  buttonId: RegisteredId;
  buttonText?: string;
  cancelOrClose?: () => void;
  canSubmitEmpty?: boolean;
  closeAfterSave?: boolean;
  inputFor: string;
  initialValue?: string;
  isLoading: boolean;
  isSuccess: boolean;
  maxCharacters?: number;
  placeholder?: string;
  responseError: string | undefined;
  submitChanges(value: string): void;
  successMessage?: string;
  textAreaId: string;
}

const InputLineField = (props: Props) => {
  const { flash } = useFlashNotification();

  const {
    buttonId,
    buttonText = t('save'),
    cancelOrClose,
    canSubmitEmpty = true,
    closeAfterSave,
    initialValue,
    inputFor,
    isLoading,
    isSuccess,
    maxCharacters,
    placeholder,
    responseError,
    submitChanges,
    successMessage,
    textAreaId,
  } = props;

  const [value, setValue] = useState(initialValue || '');
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(!canSubmitEmpty);

  const charactersUsed = value.length;
  const { isExtraSmWindow } = useWindowResize();
  const shouldAutoFocus = cancelOrClose !== undefined;

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    clearErrors();
  };

  useEffect(() => {
    if (responseError) {
      setInputErrorMessage(responseError);
    }
  }, [responseError]);

  useEffect(() => {
    if (isSuccess) {
      setValue('');
      if (successMessage) flash('info', successMessage);
      closeAfterSave && cancelOrClose && cancelOrClose();
    }
  }, [cancelOrClose, closeAfterSave, flash, isSuccess, successMessage]);

  useEffect(() => {
    if (maxCharacters && charactersUsed > maxCharacters) {
      setInputErrorMessage(t('error_message_character_length', { inputFor, maxCharacters }));
    }

    setButtonDisabled(!canSubmitEmpty && charactersUsed === 0);
  }, [canSubmitEmpty, charactersUsed, inputFor, maxCharacters]);

  const validateAndSubmit = useCallback(() => {
    if (!!inputErrorMessage) {
      return;
    } else if (value.trim().length === 0 || charactersUsed === 0) {
      setInputErrorMessage(t('error_message_blank', { inputFor }));
      return;
    }

    submitChanges(value);
  }, [charactersUsed, inputErrorMessage, inputFor, submitChanges, value]);

  const clearErrors = () => setInputErrorMessage('');

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      validateAndSubmit();
    }
  };

  const onOutsideClick = useCallback(
    (e) => {
      const { id } = e.target;
      if (typeof id === 'string' && id.includes(buttonId)) {
        e.currentTarget.focus();
        validateAndSubmit();
      } else if (!isLoading && cancelOrClose) {
        cancelOrClose();
      }
    },
    [buttonId, cancelOrClose, isLoading, validateAndSubmit]
  );

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <Wrapper id='input-wrapper'>
        <InputRow>
          <InputWrapper hasError={!!inputErrorMessage}>
            <StyledTextAreaField
              autoFocus={shouldAutoFocus}
              id={textAreaId}
              onChange={onChange}
              onFocus={(e) => e.target.select()}
              onKeyDown={(e) => handleKeyDown(e)}
              placeholder={placeholder}
              rows={isExtraSmWindow ? 3 : 1}
              value={value}
            />
            <InputCharacterCount
              anchoredRightCharacterCount={false}
              charactersUsed={charactersUsed}
              maxCharacters={maxCharacters}
            />
          </InputWrapper>
          <ErrorMessageText>{inputErrorMessage}</ErrorMessageText>
        </InputRow>

        <StyledDefaultButton
          disabled={isLoading || !!inputErrorMessage || buttonDisabled}
          id={buttonId}
          onClick={validateAndSubmit}
          text={buttonText}
        />
      </Wrapper>
    </OutsideClickHandler>
  );
};

export default InputLineField;
