import React, { useEffect, useReducer } from 'react';
import { Route } from 'type-route';

import useDebounce from '../../../../../lib/useDebounce';
import { useGetManageCurriculumQuery } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import LoadingContainer from '../../../../design_system/LoadingContainer';
import Pagination from '../../../../design_system/Triage/Paginate';
import Portal from '../../../Portal';
import { routes } from '../../../publicApplication/applicationRouter';
import Header from './Header';
import ManageCompletionsTable from './ManageCompletionsTable';
import { TableState, reducer } from './reducer';
import { CompletionsContentDiv, CompletionsWrapper } from './styles';

export type Props = {
  route: Route<typeof routes.manageCompletions>;
};

const ManageCompletions = ({ route }: Props) => {
  const {
    params: { curriculumId },
  } = route;

  const initialTableState: TableState = {
    page: 1,
    searchValue: '',
    selectedUsers: [],
    unselectedUsers: [],
    selectAllUsers: false,
    searchClear: false,
  };

  const [tableState, tableDispatch] = useReducer(reducer, initialTableState);
  const debouncedSearchValue = useDebounce<string>(tableState.searchValue, 500);

  const { data, isLoading, isFetching } = useGetManageCurriculumQuery({
    id: curriculumId,
    search: debouncedSearchValue,
    page: tableState.page,
  });

  useEffect(() => {
    if (data?.allUserIds) {
      tableDispatch({ type: 'setAllIds', allIds: data.allUserIds });
    }
  }, [data?.allUserIds]);

  if (isLoading) return <LoadingContainer />;
  if (!data) return null;

  const { id, title, users, limitValue, totalPages, totalUserCount } = data;

  return (
    <Portal>
      <CompletionsWrapper id='manage-completions-page'>
        <CompletionsContentDiv>
          <Header
            curriculumTitle={title}
            id={id}
            isLoading={isFetching}
            searchValue={tableState.searchValue}
            setSearch={(value: string) =>
              tableDispatch({ type: 'setSearchValue', searchValue: value })
            }
            usersSelected={tableState.selectedUsers.length}
          />
          <ManageCompletionsTable
            curriculumId={id}
            tableDispatch={tableDispatch}
            tableState={tableState}
            users={users}
          />
          {totalPages > 1 && (
            <Pagination
              activePage={tableState.page}
              itemsCountPerPage={limitValue}
              onChange={(page) => {
                tableDispatch({ type: 'changePage', page });
              }}
              showPaginationDetails
              totalItemsCount={totalUserCount}
              totalPages={totalPages}
            />
          )}
        </CompletionsContentDiv>
      </CompletionsWrapper>
    </Portal>
  );
};

export default ManageCompletions;
