import { flatten } from 'lodash';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useJobsComplete, { JobCompleteStatus } from '../../../../../hooks/useJobsComplete';
import { useAbilityToEditUser } from '../../../../../hooks/users/ProfileSharedAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import {
  useGetUserAssignedSubjectsQuery,
  useSubjectAssignmentForUserMutation,
  useUpdateScoreForUserMutation,
} from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { AssignedSubject, AssignedTopic } from '../../../../../types/AssignedSubject';
import Loader from '../../../../design_system/Triage/Loader';
import { useFlashNotification } from '../../../../FlashNotificationContext';

interface AssignedCheckableDetails {
  checkboxName: string;
  checkboxId: string;
  checkboxChecked: boolean;
  disabled: boolean;
}

export type AssignedCheckableTopic = AssignedTopic & AssignedCheckableDetails;

export type AssignedCheckableSubject = Omit<AssignedSubject, 'assigned_subject_elements'> &
  AssignedCheckableDetails & {
    assigned_subject_elements: AssignedCheckableTopic[];
  };

export type ManagingAssignedSubjects =
  | 'mark-complete'
  | 'clear-completion'
  | 'assign-subject'
  | 'unassign-subject'
  | 'none';

interface AssignedSubjectsContext {
  managing: ManagingAssignedSubjects;
  setManaging: React.Dispatch<React.SetStateAction<ManagingAssignedSubjects>>;
  isManaging: boolean;
  ableToManage: boolean;
  selectSubject: (subject: AssignedCheckableSubject, checked: boolean) => void;
  selectedSubjectsNumber: number;
  selectTopic: (
    subject: AssignedCheckableSubject,
    topic: AssignedCheckableTopic,
    checked: boolean
  ) => void;
  selectedTopicsNumber: number;
  selectAllSubjects: (checked: boolean) => void;
  checkableSubjects: AssignedCheckableSubject[];
  userId: number;
  unassignSubjects: () => void;
  clearCompletion: () => void;
  markComplete: () => void;
  userProfilePage: boolean;
}

interface Props {
  children: ReactNode;
  userId: number;
  userProfilePage: boolean;
  limit?: number;
}

const t = initTranslations('profile');

const AssignedSubjectsContext = createContext({} as AssignedSubjectsContext);
export const useAssignedSubjects = () => useContext(AssignedSubjectsContext);

const mapCheckableSubjects = (
  managing: ManagingAssignedSubjects,
  subjects: AssignedSubject[],
  checkboxState: string[]
): AssignedCheckableSubject[] => {
  return subjects.map((subject) => {
    const subjectDisabled =
      checkboxDisabled(managing, subject) ||
      (!!subject.assigned_subject_elements.length &&
        subject.assigned_subject_elements.every((topic: AssignedTopic) =>
          checkboxDisabled(managing, subject, topic)
        ));
    const subjectChecked = checkboxState.includes(`select-subject-${subject.id}`);
    const topics = subject.assigned_subject_elements.map((topic: AssignedTopic) => {
      const topicDisabled = checkboxDisabled(managing, subject, topic);
      return {
        ...topic,
        checkboxId: `select-subject-${subject.id}-topic-${topic.curriculum_element_id}`,
        checkboxName: `select-subject-${subject.id}-topic-${topic.curriculum_element_id}`,
        checkboxChecked:
          !topicDisabled &&
          (subjectChecked ||
            checkboxState.includes(
              `select-subject-${subject.id}-topic-${topic.curriculum_element_id}`
            )),
        disabled: subjectDisabled || topicDisabled,
      };
    });

    return {
      ...subject,
      assigned_subject_elements: [...topics],
      checkboxId: `select-subject-${subject.id}`,
      checkboxName: `select-subject-${subject.id}`,
      checkboxChecked: subjectChecked || false,
      disabled: subjectDisabled,
    };
  });
};

const checkboxDisabled = (
  managing: ManagingAssignedSubjects,
  subject: AssignedSubject,
  topic?: AssignedTopic
) => {
  switch (managing) {
    case 'clear-completion':
    case 'mark-complete':
      return subject && !topic
        ? subject.reference ||
            !subject.assigned_subject_elements.some(
              (course: AssignedTopic) => course.steps_questions_count > 0
            )
        : topic?.status !== 'finished';
    case 'unassign-subject':
      return !!subject.assigned_through_groups.length;
    case 'assign-subject':
    case 'none':
      return false;
  }
};

const updateCheckedState = (currentCheckedState: string[], id: string, checked: boolean) => {
  if (checked) {
    return [...currentCheckedState, id];
  } else {
    return currentCheckedState.filter((checkboxId) => checkboxId !== id);
  }
};

const getSelectedSubjects = (subjects: AssignedCheckableSubject[]) => {
  return subjects.filter((subject) => subject.checkboxChecked).map((subject) => subject.id);
};

const getSelectedTopics = (subjects: AssignedCheckableSubject[]) => {
  const selectedCurriculumElements = subjects
    .map((subject) =>
      subject.assigned_subject_elements
        .filter((topic) => topic.checkboxChecked)
        .map((topic) => topic.curriculum_element_id)
    )
    .filter((array) => array.length > 0);
  return flatten(selectedCurriculumElements);
};

export const AssignedSubjectsProvider = ({ userId, children, userProfilePage, limit }: Props) => {
  const { flash } = useFlashNotification();

  const {
    data: subjects,
    isLoading,
    error,
    refetch,
  } = useGetUserAssignedSubjectsQuery({
    id: userId,
    limit,
  });

  const [subjectAssignment, { data: updateAssignmentData }] = useSubjectAssignmentForUserMutation();
  const { status: updateAssignementStatus } = useJobsComplete(
    updateAssignmentData?.job_ids,
    refetch
  );

  const [scoreAdjustment, { data: updateScoreData }] = useUpdateScoreForUserMutation();
  const { status: updateScoreStatus } = useJobsComplete(updateScoreData?.job_ids, refetch);

  useEffect(() => {
    if (
      updateAssignementStatus === JobCompleteStatus.error ||
      updateScoreStatus === JobCompleteStatus.error
    ) {
      // Even if we encounter an error while fetching the job status, we should still refetch the assigned subjects
      // to provide the most updated information to the user.
      refetch();
      flash('error', t('job_details_error'));
    }
  }, [flash, refetch, updateAssignementStatus, updateScoreStatus]);

  const [managing, setManaging] = useState<ManagingAssignedSubjects>('none');
  const isManaging = useMemo(
    () => managing !== 'none' && managing !== 'assign-subject',
    [managing]
  );
  const ableToManage = useAbilityToEditUser(userId);
  const [checkboxState, setCheckboxState] = useState<string[]>([]);

  const checkableSubjects = useMemo(() => {
    return mapCheckableSubjects(managing, subjects ?? [], checkboxState);
  }, [managing, subjects, checkboxState]);

  const selectSubject = useCallback(
    (subject: AssignedCheckableSubject, checked: boolean) => {
      setCheckboxState((previouslyCheckableSubjects) => {
        let newState = updateCheckedState(previouslyCheckableSubjects, subject.checkboxId, checked);
        subject.assigned_subject_elements
          .filter((topic) => !checkboxDisabled(managing, subject, topic))
          .forEach((topic) => {
            newState = updateCheckedState(newState, topic.checkboxId, checked);
          });
        return newState;
      });
    },
    [managing]
  );

  const selectTopic = useCallback(
    (
      subject: AssignedCheckableSubject,
      topic: AssignedCheckableTopic,
      checkboxChecked: boolean
    ) => {
      setCheckboxState((previouslyCheckableSubjects) => {
        const newState = updateCheckedState(
          previouslyCheckableSubjects,
          topic.checkboxId,
          checkboxChecked
        );
        const allTopicsSelected = subject.assigned_subject_elements
          .filter((topic) => !checkboxDisabled(managing, subject, topic))
          .map((topic) => topic.checkboxId)
          .every((checkboxId) => newState.includes(checkboxId));

        return updateCheckedState(newState, subject.checkboxId, allTopicsSelected);
      });
    },
    [managing]
  );

  const selectAllSubjects = useCallback(
    (checked: boolean) => {
      checkableSubjects
        .filter((subject) => !subject.disabled)
        .forEach((subject) => {
          selectSubject(subject, checked);
        });
    },
    [checkableSubjects, selectSubject]
  );

  const selectedSubjects = useMemo(
    () => getSelectedSubjects(checkableSubjects),
    [checkableSubjects]
  );

  const selectedSubjectsNumber = useMemo(() => selectedSubjects.length, [selectedSubjects.length]);

  const selectedTopics = useMemo(() => getSelectedTopics(checkableSubjects), [checkableSubjects]);

  const selectedTopicsNumber = useMemo(() => selectedTopics.length, [selectedTopics.length]);

  const unassignSubjects = useCallback(() => {
    selectAllSubjects(false);
    subjectAssignment({
      addSubjectIds: null,
      removeSubjectIds: selectedSubjects,
      userId,
    });
  }, [selectAllSubjects, selectedSubjects, subjectAssignment, userId]);

  const clearCompletion = useCallback(() => {
    selectAllSubjects(false);
    scoreAdjustment({
      markCompleteTopicIds: null,
      markIncompleteTopicIds: selectedTopics,
      userId,
    });
  }, [scoreAdjustment, selectAllSubjects, selectedTopics, userId]);

  const markComplete = useCallback(() => {
    selectAllSubjects(false);
    scoreAdjustment({
      markCompleteTopicIds: selectedTopics,
      markIncompleteTopicIds: null,
      userId,
    });
  }, [scoreAdjustment, selectAllSubjects, selectedTopics, userId]);

  if (isLoading) return <Loader />;
  if (error) return <BasicErrorDisplay error={error} />;
  if (!checkableSubjects) return <></>;

  return (
    <AssignedSubjectsContext.Provider
      value={{
        managing,
        setManaging,
        isManaging,
        ableToManage,
        selectSubject,
        selectedSubjectsNumber,
        selectTopic,
        selectedTopicsNumber,
        checkableSubjects,
        selectAllSubjects,
        userId,
        unassignSubjects,
        clearCompletion,
        markComplete,
        userProfilePage,
      }}
    >
      {children}
    </AssignedSubjectsContext.Provider>
  );
};
