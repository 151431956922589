import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { TemplatePreviewOptionElement } from '../../../../../../../redux/services/resourceApis/templates/types';
import { SurveyQuestionAnswerType } from '../../../../../../../types/SurveyQuestion';
import {
  ChooseOptionsFormTitle,
  ResponsePlaceholderText,
} from '../../../../../surveys/shared/styles';
import VideoResponseForm from '../../../../../surveys/shared/VideoResponseForm/VideoResponseForm';
import TemplatePreviewOptions from '../TemplatePreviewOptions/TemplatePreviewOptions';

type TemplatePreviewElementContentBodyProps = {
  answerType: SurveyQuestionAnswerType;
  options: Array<TemplatePreviewOptionElement>;
};

const t = initTranslations('template_preview.content');

const TemplatePreviewElementContentBody = ({
  answerType,
  options,
}: TemplatePreviewElementContentBodyProps) => {
  switch (answerType) {
    case 'multiple_choice':
    case 'choose_one_option':
    case 'true_or_false':
      return (
        <>
          <ChooseOptionsFormTitle>{t(`title.${answerType}`)}</ChooseOptionsFormTitle>
          <TemplatePreviewOptions answerType={answerType} options={options} />
        </>
      );
    case 'written_response':
      return <ResponsePlaceholderText>{t('title.written_response')}</ResponsePlaceholderText>;
    case 'video_response':
      return <VideoResponseForm />;
    default:
      return <></>;
  }
};

export default TemplatePreviewElementContentBody;
