import styled from 'styled-components';

import Flyout from '../../../../design_system/overlays/flyout';
import { SharedFlyoutStyles } from '../../shared/styles';

//DS Override: match width of design in Figma
export const StyledFlyout = styled(Flyout)`
  ${SharedFlyoutStyles};

  width: 25rem;

  .flyout-content {
    padding-top: 0;
    height: 13rem;
  }

  .flyout-footer {
    margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  }
`;
