import React, { useEffect } from 'react';
import { Route } from 'type-route';

import { useGetEditSurveyQuery } from '../../../../redux/services/resourceApis/surveys/surveysApi';
import { routes } from '../../publicApplication/applicationRouter';
import EditModeSurveySkeleton from './EditModeSurveySkeleton/EditModeSurveySkeleton';

// This component is only setup to support our current handling of redirecting to a first question in a survey to the edit page
const SurveyEdit = ({ route }: { route: Route<typeof routes.surveyEditor> }) => {
  const {
    params: { id, slug },
  } = route;
  const { data, isFetching } = useGetEditSurveyQuery({ id });

  useEffect(() => {
    if (!!data && !isFetching) {
      routes.surveyQuestionEditor({ surveyId: id, id: data.firstQuestionId, slug }).replace();
    }
  }, [data, id, isFetching, slug]);

  return <EditModeSurveySkeleton />;
};

export default SurveyEdit;
