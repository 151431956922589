import React from 'react';
import styled, { css } from 'styled-components';

import { useVerifyStatusBadgeData } from '../../../../../hooks/curriculum/useVerifyStatusBadgeData';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { useIsCurriculumOwner } from '../../../../../hooks/users/useIsPermission';
import UnverifiedBadge from '../../../../../images/unverified.svg';
import VerifiedBadge from '../../../../../images/verified.svg';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import { useMarkContentVerifiedMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import Flyout from '../../../../design_system/overlays/flyout/Flyout';
import { fontSm5 } from '../../../../styled/TypeSystem';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import { FlyoutSubtext, FlyoutText, FlyoutTextWrapper } from './styles';
import { VerifyStatusBadgeProps } from './types';

const BadgeWrapper = styled.div<{ isOwner: boolean; verified: boolean }>(
  ({ theme: { constants, vars }, isOwner, verified }) => css`
    display: flex;
    align-items: center;
    gap: ${constants.spacerSm3};
    background-color: ${verified ? vars.stateBadgeInfo : vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${verified ? vars.stateInfo : vars.borderSurface2};
    color: ${vars.textDefault};
    border-radius: ${constants.borderRadiusEndcap};
    cursor: ${isOwner ? 'pointer' : 'default'};
    padding: ${({ theme: { constants } }) => constants.spacerSm3};
    height: ${constants.heightMd};

    ${fontSm5};
  `
);

const t = initTranslations('curriculums_view.badges.verify_badge');

const AdminVerifyStatusBadge = ({
  owner,
  verifiedDate,
  verifyPeriod,
  nextVerifyDate,
  isVerifiedContent,
  curriculumId,
  setShowCurriculumModal,
  showOwnerFlyout = true,
}: VerifyStatusBadgeProps) => {
  const menuId: RegisteredMenuId = 'verify-status-flyout';
  const { closeMenu } = useActiveMenuHandler({ menuId });
  const {
    flyoutHeaderBackgroundColor,
    flyoutTitleColor,
    flyoutButtonText,
    flyoutTitle,
    flyoutText,
    flyoutSubtext,
    verifyBadgeText,
    verifyTooltipText,
  } = useVerifyStatusBadgeData({
    isVerifiedContent,
    ownerName: owner?.name,
    verifiedDate,
    nextVerifyDate,
    verifyPeriod,
  });
  const isOwner = useIsCurriculumOwner(owner?.id);
  const showFlyout = showOwnerFlyout && isOwner;

  const [markContentVerified, result] = useMarkContentVerifiedMutation();

  const onClickButtonHandler = () => {
    if (isVerifiedContent) {
      setShowCurriculumModal && setShowCurriculumModal(true);
    } else {
      markContentVerified({ id: curriculumId });
    }
    closeMenu();
  };

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('error_message'),
  });

  return showFlyout ? (
    <Flyout
      headerBackgroundColor={flyoutHeaderBackgroundColor}
      headerTextColor={flyoutTitleColor}
      id={menuId}
      placement='bottom'
      title={flyoutTitle}
      triggerButton={
        <BadgeWrapper isOwner={isOwner} verified={isVerifiedContent}>
          {isVerifiedContent ? (
            <img alt={t('verified_icon')} src={VerifiedBadge} />
          ) : (
            <img alt={t('unverified_icon')} src={UnverifiedBadge} />
          )}
          {verifyBadgeText}
        </BadgeWrapper>
      }
    >
      <FlyoutTextWrapper reverse={isVerifiedContent}>
        <FlyoutText>{flyoutText}</FlyoutText>
        <FlyoutSubtext>{flyoutSubtext}</FlyoutSubtext>
      </FlyoutTextWrapper>
      <DefaultButton
        buttonType={isVerifiedContent ? 'tertiary' : 'primary'}
        fullWidth
        id='edit-reminder-settings-button'
        onClick={onClickButtonHandler}
        text={flyoutButtonText}
      />
    </Flyout>
  ) : (
    <>
      <Tooltip id={`content-verified-tip-${curriculumId}`} text={verifyTooltipText} />
      <div data-for={`content-verified-tip-${curriculumId}`} data-tip>
        <BadgeWrapper isOwner={false} verified={isVerifiedContent}>
          {isVerifiedContent ? (
            <img alt={t('verified_icon')} src={VerifiedBadge} />
          ) : (
            <img alt={t('unverified_icon')} src={UnverifiedBadge} />
          )}
          {verifyBadgeText}
        </BadgeWrapper>
      </div>
    </>
  );
};

export default AdminVerifyStatusBadge;
