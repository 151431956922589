import styled, { css } from 'styled-components';

import { fontSm5 } from '../../../../../../styled/TypeSystem';

export const MenuItemContainer = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: ${constants.spacerMd2};
    background-color: ${vars.foundationBase1};
    border-radius: ${constants.borderRadiusMd};
    gap: ${constants.spacerSm3};

    &:hover {
      background-color: ${vars.foundationHover};
    }

    ${fontSm5};
  `};
`;
