import { useEffect } from 'react';

import {
  CheckoutCreateSubscriptionProps,
  CheckoutCreateSubscriptionResult,
} from '../../components/application/checkout/CheckoutFullScreenOverlay/types';
import { routes } from '../../components/application/publicApplication/applicationRouter';
import routeTo from '../../components/application/publicApplication/routeTo';
import { useGetStripeId } from '../../lib/billing/getStripeId';
import { mapBillingDetailsToStripeParams } from '../../lib/billing/mapBillingDetailsToStripeParams';
import { messageFromError } from '../../redux/errors/helpers';
import { useCheckoutCreateSubscriptionMutation } from '../../redux/services/resourceApis/checkout/checkoutApi';
import { CheckoutCreateSubscriptionParams } from '../../redux/services/resourceApis/checkout/types';
import useCurrentAccount from '../useCurrentAccount';
import useDisplayFlashOnResponse from '../useDisplayFlashOnResponse';

export function useCheckoutCreateSubscription(): CheckoutCreateSubscriptionResult {
  const [checkoutCreateSubscriptionMutation, checkoutCreateSubscriptionResult] =
    useCheckoutCreateSubscriptionMutation();
  const { isLoading, error, isSuccess } = checkoutCreateSubscriptionResult;
  const getStripeIdPromise = useGetStripeId();
  const { slug, status } = useCurrentAccount();
  const checkoutCreateSubscription = async ({
    validRequiredFields,
    billingDetails,
    totalAmount,
  }: CheckoutCreateSubscriptionProps) => {
    if (!validRequiredFields) return;

    const stripeId = await getStripeIdPromise(billingDetails);
    if (!stripeId) return;

    const stripeParams = mapBillingDetailsToStripeParams(billingDetails);
    const createSubscriptionParams: CheckoutCreateSubscriptionParams = {
      paymentMethod: {
        ...stripeParams,
        stripe_id: stripeId,
      },
      totalAmount,
    };

    checkoutCreateSubscriptionMutation(createSubscriptionParams);
  };

  useDisplayFlashOnResponse({
    result: checkoutCreateSubscriptionResult,
    errorMessage: messageFromError(error)?.join(', '),
  });

  useEffect(() => {
    if (isSuccess && status === 'active') {
      routeTo(routes.home({ slug }));
    }
  }, [isSuccess, status, slug]);

  return {
    checkoutCreateSubscription,
    isLoadingCreateSubscription: isLoading,
    isSuccessCreateSubscription: isSuccess,
  };
}
