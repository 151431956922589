import React from 'react';

import { UpdatePaymentMethodFormProps } from '../../../shared/UpdatePaymentMethodForm/types';
import UpdatePaymentMethodForm from '../../../shared/UpdatePaymentMethodForm/UpdatePaymentMethodForm';

type CheckoutPaymentMethodSectionProps = Omit<UpdatePaymentMethodFormProps, 'billingDetailsData'>;

const CheckoutPaymentMethodSection = ({
  dispatch,
  formData,
  paymentMethodFormErrors,
  validateField,
  validateFields,
  clearFieldError,
  columnDirectionOnMd,
  isFormProcessing,
}: CheckoutPaymentMethodSectionProps) => {
  return (
    <UpdatePaymentMethodForm
      billingDetailsData={undefined}
      clearFieldError={clearFieldError}
      columnDirectionOnMd={columnDirectionOnMd}
      dispatch={dispatch}
      formData={formData}
      isFormProcessing={isFormProcessing}
      paymentMethodFormErrors={paymentMethodFormErrors}
      validateField={validateField}
      validateFields={validateFields}
    />
  );
};

export default CheckoutPaymentMethodSection;
