import styled from 'styled-components';

const OrgChartStyledCard = styled.div`
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.foundationHover};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: ${({ theme: { vars } }) => vars.textDefault};
  height: 100%;
  width: 100%;
  text-align: left;
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  cursor: pointer;
`;

export default OrgChartStyledCard;
