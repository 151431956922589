import React from 'react';
import { Route } from 'type-route';

import { routes } from '../../../publicApplication/applicationRouter';
import AddonFullScreenOverlay from './AddonFullScreenOverlay/AddonFullScreenOverlay';
import AddonTrainualPlusFullScreenOverlay from './AddonTrainualPlusFullScreenOverlay/AddonTrainualPlusFullScreenOverlay';

export type AddonFullScreenOverlayFactoryProps = {
  route: Route<typeof routes.addonOverlay>;
};

const AddonFullScreenOverlayFactory = ({ route }: AddonFullScreenOverlayFactoryProps) => {
  switch (route.params.name) {
    case 'e_signature':
      return <AddonFullScreenOverlay route={route} />;
    case 'trainual_plus':
      return <AddonTrainualPlusFullScreenOverlay />;
    default:
      return <></>;
  }
};

export default AddonFullScreenOverlayFactory;
