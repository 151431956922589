import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

import IconButton from '../../../design_system/buttons/IconButton';
import FlexContainer from '../../../styled/FlexContainer';

const rowSpacings = css`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const RowContainer = styled(FlexContainer)<{
  expanded?: boolean;
  processing?: boolean;
}>`
  display: flex;
  user-select: none;
  background-color: ${({ processing, theme: { vars } }) =>
    processing ? vars.foundationBase2 : vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderRadiusXs} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  min-height: 3.5rem;
  width: 100%;

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  }

  ${rowSpacings};
`;
export const RowCardBody = styled.div<{ isLocked?: boolean }>(
  ({ isLocked, theme: { constants, vars } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: ${constants.spacerSm3} ${constants.spacerSm3} ${constants.spacerSm3}
      ${constants.spacerMd2};
    cursor: ${isLocked ? 'default' : 'move'};
    &:hover {
      background-color: ${vars.foundationSurface1};
    }
  `
);

export const RowActionsContainer = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: ${constants.spacerMd2};
  `
);

export const StatusBadgeWrapper = styled.div(
  ({ theme: { constants } }) => css`
    align-items: center;
    display: flex;
    gap: ${constants.spacerMd2};
    flex-shrink: 0;
  `
);

export const RowIcon = styled(IconButton)<{ isSurvey?: boolean }>`
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  width: ${({ theme: { constants } }) => constants.spacerMd2};
  cursor: ${({ isSurvey }) => (isSurvey ? 'default' : 'pointer')};
`;

export const RowIconContainer = styled(FlexContainer)`
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd2};
  width: 3rem;
  height: 3.5rem;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

export const ActionRowWrapper = styled.div<{ isSubjectOutline?: boolean }>`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderRadiusXs} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  overflow: hidden;
  position: relative;
  ${({ isSubjectOutline, theme: { constants } }) =>
    isSubjectOutline && {
      margin: `0 ${constants.spacerLg1} 0 ${constants.spacerMd3}`,
    }};
`;

export const ChildContainerRow = styled(animated.div)`
  margin-left: 3.5rem;
  overflow: hidden;
`;

export const InnerWrapper = styled.div`
  height: 3.5rem;
`;

export const ThreeDotWrapper = styled.div<{ isLocked?: boolean }>`
  margin-left: auto;
  .three-dot-menu-button {
    cursor: ${({ isLocked }) => (isLocked ? 'default' : 'pointer')};
  }
`;

export const EmojiWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-inline: ${({ theme: { constants } }) => constants.spacerSm3};
`;
