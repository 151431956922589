import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import { ToggleContainer } from '../../../AdvancedSettings/styles';
import { FormSectionDescription, FormSectionTitle, ToggleSettingContainer } from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.people_settings');

const DisplayEmailToggle = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <ToggleContainer>
        <Toggle
          checked={values.enable_profile_email}
          className='enable-profile-email-setting'
          handleToggle={() => setFieldValue('enable_profile_email', !values.enable_profile_email)}
          id='enable-profile-email'
          name='enable_profile_email'
        />
      </ToggleContainer>
      <div>
        <FormSectionTitle>{t('enable_profile_email_title')}</FormSectionTitle>
        <FormSectionDescription>{t('enable_profile_email_description')}</FormSectionDescription>
      </div>
    </ToggleSettingContainer>
  );
};

export default DisplayEmailToggle;
