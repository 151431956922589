import { animated, useSpring } from '@react-spring/web';
import React, { useState } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import Icon from '../../../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../../../styled/TypeSystem';
import { getChevronIconName } from '../../../../../shared/helpers';

const Title = styled.p`
  display: flex;
  padding-block: ${({ theme: { constants } }) => constants.spacerSm3};
  margin: 0;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
  text-align: left;
  ${fontSm5};
`;

const IconWrapper = styled.div`
  display: flex;
  margin: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} ${constants.spacerSm3} ${constants.spacerSm3} 0`};
  align-items: flex-start;
`;

const TitleWrapper = styled.button`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerMd2}`};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  overflow: hidden;
`;

const StepSubtext = styled.p`
  display: flex;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  flex-direction: column;
  justify-content: center;
  margin: 0;
  text-align: left;
  padding-block: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  ${fontSm5};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export type Props = {
  title: string;
  id: string;
  subtext: string;
};

const StepTitle = ({ title, id, subtext }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, bounds] = useMeasure();
  const containerStyle = useSpring({
    height: bounds.height,
    config: { tension: 500, friction: 50 },
  });
  return (
    <TitleWrapper className='expand-button' id={id} onClick={() => setIsOpen(!isOpen)}>
      <IconWrapper>
        <Icon
          fixedWidth
          name={getChevronIconName({ isActive: !isOpen, initialDirection: 'right' })}
          size='xs'
        />
      </IconWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <animated.div style={containerStyle}>
          <div ref={ref}>{isOpen && <StepSubtext>{subtext}</StepSubtext>}</div>
        </animated.div>
      </TextWrapper>
    </TitleWrapper>
  );
};

export default StepTitle;
