import styled from 'styled-components';

import { fontSm5 } from '../../../../../styled/TypeSystem';

export const InsertDropdownButton = styled.button`
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationHover};
  }
`;

export const InsertDropdownButtonText = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm5};
`;

export const InsertDropdownButtonIconWrapper = styled.span`
  display: flex;
  align-items: center;
  width: 1rem;
`;
