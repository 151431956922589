import React from 'react';

import getDocumentImageSrc from '../../../../../lib/document_import/getDocumentImageSrc';
import initTranslations from '../../../../../lib/initTranslations';
import { Sector, sectorsArray } from '../../../../../types/Sector';
import IconButton from '../../../../design_system/buttons/IconButton';
import DropdownWithPoppableMenu from '../../../shared/DropdownWithPoppableMenu';
import DropdownMenuOption from './DropdownMenuOption/DropdownMenuOption';
import {
  ActionsWrapper,
  CardBodyContainer,
  DocumentImage,
  DocumentImportCardContainer,
  DocumentInfoContainer,
  DocumentName,
  DocumentSectorSelectionContainer,
  InfoText,
  SelectLabel,
  SourceIconWrapper,
} from './styles';
import { DocumentImportCardProps } from './types';

const t = initTranslations('bulk_document_import');

const DocumentImportCard = ({
  document,
  infoText,
  removeDocument,
  setDocumentSector,
}: DocumentImportCardProps) => {
  const menuOptions = sectorsArray.map((sector: Sector) => (
    <DropdownMenuOption key={sector} sector={sector} />
  ));

  const fileMimeType: string = document.file.type || '';

  return (
    <DocumentImportCardContainer>
      <SourceIconWrapper>
        <DocumentImage src={getDocumentImageSrc(fileMimeType)} />
      </SourceIconWrapper>
      <CardBodyContainer>
        <DocumentInfoContainer>
          <DocumentName>{document.title}</DocumentName>
          <InfoText>{infoText}</InfoText>
        </DocumentInfoContainer>
        <DocumentSectorSelectionContainer>
          <SelectLabel>{t('save_to')}</SelectLabel>
          <DropdownWithPoppableMenu
            id='upload-sector-selection-dropdown'
            menuId={`upload-${document.sector}-sector-selection-menu`}
            menuPlacement='bottom-end'
            options={menuOptions}
            selectedOption={<DropdownMenuOption isSelected sector={document.sector} />}
            setSelectedOption={setDocumentSector}
            style='sector'
          />
        </DocumentSectorSelectionContainer>
      </CardBodyContainer>
      <ActionsWrapper>
        <IconButton ariaLabel='test' buttonSize='md' name='trash-can' onClick={removeDocument} />
      </ActionsWrapper>
    </DocumentImportCardContainer>
  );
};

export default DocumentImportCard;
