import React, { Fragment, useCallback } from 'react';

import { useEditorContext } from '../../../../../../contexts/EditorContext';
import { EditorFlyoutProvider } from '../../../../../../contexts/EditorFlyoutContext';
import { useEditorToolbarContext } from '../../../../../../contexts/EditorToolbarContext';
import { EmbedlyProvider } from '../../../../../../contexts/EmbedlyProvider';
import { ImageFlyoutProvider } from '../../../../../../contexts/ImageFlyoutContext';
import initTranslations from '../../../../../../lib/initTranslations';
import { usePostSurveyQuestionImageMutation } from '../../../../../../redux/services/axiosService';
import EmbedLinkFlyout from '../../../plugins/EmbedLink/EmbedLinkFlyout';
import FileFlyout from '../../../plugins/File/FileFlyout';
import EditorImageFlyout from '../../../plugins/Image/EditorImageFlyout';
import TableFlyout from '../../../plugins/Table/TableFlyout';
import VideoFlyout from '../../../plugins/Video/VideoFlyout';
import {
  ButtonComponent,
  Divider,
  ToolbarContainer,
  WithInsertDropdownToolbar,
} from '../../../toolbar';
import IframeButton from '../../../toolbar/buttons/components/insert/IframeButton';
import InsertDropdown from '../../InsertDropdown/InsertDropdown';
import InsertDropdownItem, {
  InsertDropdownItemProps,
} from '../../InsertDropdown/InsertDropdownItem/InsertDropdownItem';
import { ImageLayoutWrapper, StyledActionButtonsContainer, ToolbarWrapper } from './styles';

const t = initTranslations('editor.insert_dropdown');

const Toolbar = () => {
  const { context } = useEditorToolbarContext();
  const { editor } = useEditorContext();

  const videoBtnRef = React.useRef<HTMLButtonElement>(null);
  const imageBtnRef = React.useRef<HTMLButtonElement>(null);
  const tableBtnRef = React.useRef<HTMLButtonElement>(null);
  const fileBtnRef = React.useRef<HTMLButtonElement>(null);
  const linkBtnRef = React.useRef<HTMLButtonElement>(null);
  const iframeBtnRef = React.useRef<HTMLButtonElement>(null);

  const insertButtons: InsertDropdownItemProps[] = [
    {
      iconName: 'video',
      title: t('video'),
      onClick: () => videoBtnRef?.current?.click(),
    },
    {
      iconName: 'image',
      title: t('photo'),
      onClick: () => imageBtnRef?.current?.click(),
    },

    {
      iconName: 'link',
      title: t('link'),
      onClick: () => linkBtnRef?.current?.click(),
    },

    {
      iconName: 'table',
      title: t('table'),
      onClick: () => tableBtnRef?.current?.click(),
    },

    {
      iconName: 'file',
      title: t('file'),
      onClick: () => fileBtnRef?.current?.click(),
    },
    {
      iconName: 'file-code',
      title: t('iframe'),
      onClick: () => iframeBtnRef?.current?.click(),
    },
  ];

  const [uploadSurveyQuestionImage, surveyQuestionImageResult] =
    usePostSurveyQuestionImageMutation();
  const uploadSurveyQuestionImageCallback = useCallback(
    (image: FormData) => {
      uploadSurveyQuestionImage(image);
    },
    [uploadSurveyQuestionImage]
  );

  return (
    <ToolbarWrapper>
      <ToolbarContainer className={`editor_toolbar_${context}`}>
        {WithInsertDropdownToolbar.map((buttonGroup) => (
          <Fragment key={buttonGroup.name}>
            {buttonGroup.buttons.map((button) => {
              return <ButtonComponent buttonName={button} editor={editor} key={button} />;
            })}
            <Divider />
          </Fragment>
        ))}
        <InsertDropdown
          menuItems={
            <>
              {insertButtons.map((button) => {
                return <InsertDropdownItem key={button.title} {...button} />;
              })}
            </>
          }
        />
        <StyledActionButtonsContainer>
          <EditorFlyoutProvider triggerBtnRef={videoBtnRef}>
            <EmbedlyProvider>
              <VideoFlyout editor={editor} />
            </EmbedlyProvider>
          </EditorFlyoutProvider>
          <EditorFlyoutProvider triggerBtnRef={imageBtnRef}>
            <ImageFlyoutProvider
              uploadImage={uploadSurveyQuestionImageCallback}
              uploadResult={surveyQuestionImageResult}
            >
              <ImageLayoutWrapper>
                <EditorImageFlyout
                  menuId='survey-image-flyout'
                  menuItems={['upload', 'giphy', 'stock_image']}
                />
              </ImageLayoutWrapper>
            </ImageFlyoutProvider>
          </EditorFlyoutProvider>
          <EditorFlyoutProvider triggerBtnRef={linkBtnRef}>
            <EmbedlyProvider>
              <EmbedLinkFlyout editor={editor} />
            </EmbedlyProvider>
          </EditorFlyoutProvider>
          <EditorFlyoutProvider triggerBtnRef={fileBtnRef}>
            <FileFlyout editor={editor} simpleUpload />
          </EditorFlyoutProvider>
          <EditorFlyoutProvider triggerBtnRef={tableBtnRef}>
            <TableFlyout editor={editor} />
          </EditorFlyoutProvider>
          <EditorFlyoutProvider triggerBtnRef={iframeBtnRef}>
            <IframeButton editor={editor} />
          </EditorFlyoutProvider>
        </StyledActionButtonsContainer>
      </ToolbarContainer>
    </ToolbarWrapper>
  );
};

export default Toolbar;
