import { DropdownMenuOption } from '../components/design_system/Triage/menus/DropdownMenu';
import initTranslations from '../lib/initTranslations';

const t = initTranslations('sort_dropdown');

const useIntegrationSortDropdownOptions = () => {
  const options: DropdownMenuOption[] = [
    {
      label: t('sort_asc'),
      sortColumn: 'name',
      sortDirection: 'asc',
    },
    {
      label: t('sort_desc'),
      sortColumn: 'name',
      sortDirection: 'desc',
    },
  ];

  return {
    options,
  };
};

export default useIntegrationSortDropdownOptions;
