export default function createAndDownloadPDF(pdfDocument: string, filename: string) {
  // Decode base64 string to Uint8Array
  const binaryString = atob(pdfDocument);
  const binaryLen = binaryString.length;
  const binaryArray = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    binaryArray[i] = binaryString.charCodeAt(i);
  }

  // Create Blob from Uint8Array
  const blob = new Blob([binaryArray], { type: 'application/pdf' });

  // Create ObjectURL from Blob
  const url = URL.createObjectURL(blob);

  // Create a hidden anchor element and trigger download
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}
