import { useEffect, useRef } from 'react';
import { Route } from 'type-route';

import { routes, session } from '../components/application/publicApplication/applicationRouter';

type Update = {
  route: Route<typeof routes>;
  retry: () => void;
};
type Unblock = () => void;
type NavigationLockHandler = (update: Update) => void;

export const usePreventNavigation = (navigationLockHandler: NavigationLockHandler) => {
  const unblockRef = useRef<Unblock | null>(null);

  const unblock = () => {
    if (unblockRef.current) {
      unblockRef.current();
    }
  };

  useEffect(() => {
    unblockRef.current = session.block((update) => navigationLockHandler(update));

    return () => {
      unblock();
    };
  }, [navigationLockHandler]);

  return unblock;
};
