import React from 'react';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { useGetCurriculumBreadcrumbsDataQuery } from '../../../../redux/services/resourceApis/reports/reportsApi';
import Breadcrumbs from '../../../design_system/navigation/Breadcrumbs';
import { routes } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import SurveyReportTable from './SurveyReportTable';

const SurveyReportPage = ({
  route: {
    params: { curriculumId, surveyId },
  },
}: {
  route: Route<typeof routes.surveyReport>;
}) => {
  const { slug } = useCurrentAccount();
  const { isLoading, data } = useGetCurriculumBreadcrumbsDataQuery({
    id: curriculumId,
    elementId: surveyId,
  });
  if (isLoading) return <></>;
  if (!data) return <></>;

  return (
    <PageContent>
      <Breadcrumbs
        currentPageText={data.elementTitle}
        redirectPath={routes.curriculumByElementReport({ slug, id: curriculumId }).href}
        redirectText={data.curriculumTitle}
      />
      <SurveyReportTable surveyId={surveyId} />
    </PageContent>
  );
};

export default SurveyReportPage;
