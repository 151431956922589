import { AddonTrainualPlusFeatureKind } from '../../redux/services/resourceApis/billing/simplePricingTypes';
//TODO: GS3 Needs update to the correct path when we'll have the correct video
import DelegationPlannerPreviewSrc from '../../videos/Unlimited-E-signature-Preview-1.mp4';
import TrainingPathPreviewSrc from '../../videos/Unlimited-E-signature-Preview-2.mp4';

export function getAddonTrainualPlusOverlayVideoPreview(kind: AddonTrainualPlusFeatureKind) {
  switch (kind) {
    case 'delegation_planner':
      return DelegationPlannerPreviewSrc;
    case 'training_path':
      return TrainingPathPreviewSrc;
  }
}
