import React, { useEffect, useMemo } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  QuestionAnswer,
  setCurrentQuestionIndex,
  setQuestionAnswersData,
} from '../../../../../../redux/domains/surveyConsume/surveyConsumeSlice';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { isMultimediaContentOnly } from '../../../../editor/shared/IsMultimediaContentOnly';
import { Scrollable } from '../../../shared/styles';
import { QuestionsWrapper, SurveyQuestionsLabel } from '../../../shared/SurveyQuestionsList/styles';
import SurveyQuestionListItem from './SurveyQuestionListItem';
import { SurveyQuestionsListProps } from './types';

const t = initTranslations('survey_consume');

const SurveyQuestionsList = ({ questions, lastInProgressAttemptId }: SurveyQuestionsListProps) => {
  const dispatch = useAppDispatch();
  const questionAnswersData: QuestionAnswer[] = useMemo(
    () =>
      questions.map((question) => ({
        questionId: question.id,
        isAnswered: false,
      })),
    [questions]
  );

  useEffect(() => {
    dispatch(setQuestionAnswersData(questionAnswersData));
    dispatch(setCurrentQuestionIndex(0));
    // we need to execute slice update only on component mount or attempt start
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastInProgressAttemptId]);

  return (
    <>
      <SurveyQuestionsLabel>{t('questions')}</SurveyQuestionsLabel>
      <Scrollable className='survey-questions-wrapper'>
        <QuestionsWrapper>
          {questions.map((question, index) => {
            const { id, plainText, text, textJson } = question;
            const isMultimedia = isMultimediaContentOnly({
              plainText,
              content: text,
              contentJson: textJson,
            });

            return (
              <SurveyQuestionListItem
                displayCheckmarks={!!lastInProgressAttemptId}
                index={index}
                key={`question-${id}`}
                questionId={id}
                questionText={isMultimedia ? t('multimedia_question') : plainText}
              />
            );
          })}
        </QuestionsWrapper>
      </Scrollable>
    </>
  );
};

export default SurveyQuestionsList;
