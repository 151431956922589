import styled, { css, keyframes } from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';

export const Card = styled.div<{ isUserGroupCard?: boolean }>`
  ${({ theme: { constants, vars }, isUserGroupCard }) => {
    const highlighterAnimation = keyframes`
        0% {
            background-color: ${vars.accentSubdued1};
        }

        60% {
            background-color: ${vars.accentSubdued1}80;
        }

        100% {
            background-color: ${vars.accentSubdued1};
        }
    `;

    return css`
      max-width: 16.375rem;
      padding: ${constants.spacerMd2};
      transition: box-shadow 0.3s ease;
      border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
      border-radius: ${constants.borderRadiusMd};
      background-color: ${vars.foundationSurface1};

      &:hover {
        border: ${constants.borderWidthSm} solid
          ${isUserGroupCard ? vars.borderHover : vars.accentPrimaryDefault};

        button {
          display: block;
        }
      }

      &.highlighter-animation {
        box-shadow: inset 0 0 0 ${constants.borderWidthSm} ${vars.accentPrimaryDefault};
        animation: ${highlighterAnimation} 1.2s ease infinite;
        background-color: ${vars.accentSubdued1};

        &:hover,
        &:focus {
          animation: none;
          box-shadow: none;
        }
      }
    `;
  }}
`;

export const Title = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  ${fontSm5};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const InfoText = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};

  ${fontSm4};
`;

export const StyledIcon = styled(Icon)`
  padding-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const PillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  justify-content: start;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;
