import React, { useContext, useMemo, useReducer, useRef } from 'react';

import { OrgChartConfigSlideoutProvider } from '../../../../contexts/OrgChartConfigSlideoutContext';
import { PaywallContext } from '../../../../contexts/PaywallContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import useSlideout from '../../../../hooks/useSlideout';
import { useGetGroupChartDataQuery } from '../../../../redux/services/resourceApis/orgChart/orgChartApi';
import OrgChart from '../OrgChartShared';
import OrgChartConfigSlideout from '../OrgChartShared/OrgChartConfigSlideout';
import createNestedStructure from '../OrgChartShared/utils/createNestedStructure';
import { ZoomState, zoomReducer } from '../OrgChartShared/ZoomControl/OrgChartZoomControl';
import GroupChartBody from './GroupChartBody';
import GroupChartHeader from './GroupChartHeader';

const initialZoomState: ZoomState = {
  action: null,
  eventTime: null,
};

const GroupChart = () => {
  const ability = useCurrentUserAbilities();
  const orgChartContainerRef = useRef<HTMLDivElement>(null);
  const [zoomState, zoomDispatch] = useReducer(zoomReducer, initialZoomState);
  const canReadGroupChartConfigSlideout = ability.can('read', 'GroupChartConfigSlideout');
  const { close, isOpen, open } = useSlideout('org-chart-config-slideout');
  const { rootGroupId } = useCurrentAccount();
  const paywallCtx = useContext(PaywallContext);
  const isRoleChartPaywalled = paywallCtx.includes('role_chart');

  const showConfigure = () => {
    if (canReadGroupChartConfigSlideout) {
      isOpen ? close() : open();
    }
  };

  const { data: roleChartResponse, error, isLoading } = useGetGroupChartDataQuery();
  const roleChartDataClone = useMemo(() => {
    return createNestedStructure(roleChartResponse?.roleChartData);
  }, [roleChartResponse?.roleChartData]);

  return (
    <>
      <OrgChart
        body={
          <GroupChartBody
            canUpdateOrgChart={canReadGroupChartConfigSlideout}
            data={roleChartDataClone}
            disabled={isRoleChartPaywalled}
            isLoading={isLoading}
            orgChartContainerRef={orgChartContainerRef}
            showConfigure={showConfigure}
            translationPrefix='role_chart'
            zoomState={zoomState}
          />
        }
        error={error}
        header={
          <GroupChartHeader
            isRoleChartPaywalled={isRoleChartPaywalled}
            rootNodeSet={!!rootGroupId}
            showConfigure={showConfigure}
            zoomDispatch={zoomDispatch}
            zoomState={zoomState}
          />
        }
        isLoading={isLoading}
        pageId='role_chart_index'
        rootNodeSet={!!rootGroupId}
      />
      {canReadGroupChartConfigSlideout && (
        <OrgChartConfigSlideoutProvider orgChartResourceType='group'>
          <OrgChartConfigSlideout />
        </OrgChartConfigSlideoutProvider>
      )}
    </>
  );
};

export default GroupChart;
