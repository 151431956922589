import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontSm4 } from '../../../../styled/TypeSystem';

export const ModalHeaderContainer = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd3} ${constants.spacerLg3} 0 ${constants.spacerLg1}`};
`;

export const ModalContentWrapper = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerLg1};
`;

export const SectionWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) =>
    `calc(${constants.spacerSm3} + ${constants.borderWidthSm})`};

  // DS Override
  [class*='Poppable'] {
    translate: 5% -80%;
  }
`;

export const ControlButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerLg1}`};
  border-bottom-left-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border-bottom-right-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const PrimaryButtonWrapper = styled.div`
  width: 100%;

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
  }
`;

export const SectionItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  & > * {
    margin-right: ${({ theme: { constants } }) =>
      `calc(${constants.spacerSm3} + ${constants.borderWidthSm})`};
  }
`;

export const SectionItemTitle = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm4};
`;
