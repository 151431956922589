import styled from 'styled-components';

import Loader from '../../../design_system/Triage/Loader';
import {
  mediaBreakpointPx2xl,
  mediaBreakpointPxLg,
  mediaBreakpointPxMd,
} from '../../../styled/Breakpoint';

const RIGHT_CONTAINER_WIDTH = '22.25rem';
const RIGHT_CONTAINER_WIDTH_2XL = '25rem';

export const StyledLoader = styled(Loader)<{ withTopMargin?: boolean }>`
  margin: ${({ withTopMargin, theme: { constants } }) =>
    `${withTopMargin ? constants.spacerMd3 : 0} 0 ${constants.spacerMd3}`};
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: calc(100% - ${RIGHT_CONTAINER_WIDTH});
  }

  @media (min-width: ${mediaBreakpointPx2xl}) {
    width: calc(100% - ${RIGHT_CONTAINER_WIDTH_2XL});
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg3};
  gap: ${({ theme: { constants } }) => constants.spacerMd3};

  @media (min-width: ${mediaBreakpointPxMd}) {
    flex-flow: row wrap;
    padding-inline: ${({ theme: { constants } }) => constants.spacerLg2};
  }

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: column;
    margin-bottom: 0;
    width: ${RIGHT_CONTAINER_WIDTH};
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
    padding: ${({ theme: { constants } }) => constants.spacerMd3};
  }

  @media (min-width: ${mediaBreakpointPx2xl}) {
    width: ${RIGHT_CONTAINER_WIDTH_2XL};
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const PageContent = styled.div`
  width: 100%;
  max-width: 80rem;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg2} ${constants.spacerMd2}`};

  @media (min-width: ${mediaBreakpointPxMd}) {
    padding: ${({ theme: { constants } }) => constants.spacerLg2};
  }
`;
