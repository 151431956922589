import { pickBy } from 'lodash';
import { Route } from 'type-route';

import { routes, useRoute } from '../../components/application/publicApplication/applicationRouter';
import RouteTo from '../../components/application/publicApplication/routeTo';
import { OutlineTemplatesFilters } from '../../components/application/templates/TemplatesOutlinePage/TemplatesOutlineSlideout/types';
import { OutlineTemplatesParams } from '../../redux/services/resourceApis/templates/types';
import { useGetDefaultViewByFilter } from './useGetDefaultViewByFilter';

type TemplateQueryParams = string | number | OutlineTemplatesFilters | undefined;

export function useTemplateParamsToUrl() {
  const route = useRoute();
  const { params } = route as Route<typeof routes.templates>;
  const viewBy = useGetDefaultViewByFilter();

  return (param: OutlineTemplatesParams) => {
    const defaultPage = param.page === 1 ? undefined : param.page;
    const defaultSort = param.sort === 'most_popular' ? undefined : param.sort;
    const defaultViewBy = param.viewBy === viewBy ? undefined : param.viewBy;

    const templateParams = {
      author: param.author,
      category_ids: param.categoryIds,
      page: defaultPage,
      sectors: param.sectors,
      search: param.searchTerm,
      sort: defaultSort,
      view_by: defaultViewBy,
      suggested_groups: param.suggestedGroups,
      tags: param.tags,
    };

    const isNotEmptyParam = (value: TemplateQueryParams) =>
      value !== undefined && value !== '' && (Array.isArray(value) ? value.length : true);

    // Remove empty params from templateParams
    const filteredParams = pickBy(templateParams, (value) => isNotEmptyParam(value));

    RouteTo(
      routes.templates({
        slug: params.slug,
        ...filteredParams,
      })
    );
  };
}
