import React, { useEffect } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { UseQueryResult } from '../../../../redux/baseQueries/types';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useAddResponsibilityToResponsibiliableMutation } from '../../../../redux/services/resourceApis/responsibilities/responsibilitiesApi';
import { ResponsibilitiesResponse } from '../../../../redux/services/resourceApis/responsibilities/types';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import InputLineField from '../../groups/InputLineField';
import { RESPONSIBILITY_MAX_CHARACTERS } from '../../groups/shared/constants/groups';
import ResponsibilitiesList from '../ResponsibilitiesList';
import {
  AddResponsibilitiesWrapper,
  ResponsibilitiesListWrapper,
  StyledSurface,
  StyledTitleRow,
  TitleWrapper,
} from './shared/styles';
import { ResponsibilitiesProps } from './shared/types';
import Title from './Title';

const t = initTranslations('groups');

interface Props extends ResponsibilitiesProps {
  resource: { userId: number };
  userFirstName?: string;
  assignedResponsibilityResult: UseQueryResult<ResponsibilitiesResponse>;
}

const ResponsibilitiesUserProfile = ({
  resource,
  userFirstName,
  title,
  description,
  assignedResponsibilityResult,
}: Props) => {
  const { onHoldPlan, status: accountStatus } = useCurrentAccount();
  const [addResponsibility, result] = useAddResponsibilityToResponsibiliableMutation();
  const { isLoading, isSuccess, error, data, reset } = result;
  const isTrialEndedOrOnHold = accountStatus === 'trial_ended' || onHoldPlan;
  const {
    responsibility: { singular: responsibilitySingular, plural: responsibilityPlural },
  } = useAccountTerminology();

  useEffect(() => {
    if (isSuccess && data) reset();
  }, [data, isSuccess, reset]);

  return (
    <StyledSurface id='user-responsibilities'>
      <StyledTitleRow>
        <TitleWrapper>
          <Title firstName={userFirstName} titleText={title} />
          <p>{description}</p>
        </TitleWrapper>
      </StyledTitleRow>
      <ResponsibilitiesListWrapper>
        <ResponsibilitiesList
          responsibiliable_id={resource.userId}
          responsibiliable_type='User'
          result={assignedResponsibilityResult}
        />
      </ResponsibilitiesListWrapper>
      <AddResponsibilitiesWrapper>
        {!isTrialEndedOrOnHold && (
          <InputLineField
            buttonId='create-responsibility-inline'
            buttonText={t('add_responsibility_cta')}
            canSubmitEmpty={false}
            inputFor={t('add_responsibility_input_for', { responsibility: responsibilitySingular })}
            isLoading={isLoading}
            isSuccess={isSuccess}
            maxCharacters={RESPONSIBILITY_MAX_CHARACTERS}
            placeholder={t('add_responsibility_placeholder', {
              responsibilities: responsibilityPlural.toLowerCase(),
            })}
            responseError={messageFromError(error)?.join(', ')}
            submitChanges={(name) => addResponsibility({ resource, name: name.trim() })}
            textAreaId='responsibility-inline-textarea'
          />
        )}
      </AddResponsibilitiesWrapper>
    </StyledSurface>
  );
};

export default ResponsibilitiesUserProfile;
