import styled from 'styled-components';

export interface Props {
  background?: string;
  margin?: string;
}

const Line = styled.hr<Props>`
  background: ${({ theme, background }) => (background ? background : theme.vars.borderSurface2)};
  border: none;
  height: 1px;
  margin: ${({ margin }) => (margin ? margin : '1.5em 0')};
`;

export default Line;
