import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';

const StyledOuterCircle = styled.div<{ completed: boolean }>(
  ({ completed, theme: { constants, vars } }) => css`
    padding: ${constants.spacerSm2};
    border-radius: ${constants.borderRadiusCircle};
    outline: ${constants.borderWidthSm} solid ${completed ? vars.stateSuccess : vars.borderDefault};
    background-color: ${vars.foundationSurface1};
    width: fit-content;
  `
);

const StyledInnerCircle = styled.div<{ completed: boolean }>(
  ({ completed, theme: { constants, vars } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    /* Width should match the height variable in this case to make a circle, so I used the same height variable for width */
    width: ${constants.heightXs};
    height: ${constants.heightXs};
    border-radius: ${constants.borderRadiusCircle};
    background-color: ${completed ? vars.stateSuccess : vars.borderDefault};
  `
);

type CompletionStatusCircleProps = {
  completed: boolean;
};

const CompletionStatusCircle = ({ completed }: CompletionStatusCircleProps) => {
  const {
    vars: { stateSuccess, foundationSurface1 },
  } = useTheme();

  return (
    <StyledOuterCircle completed={completed}>
      <StyledInnerCircle completed={completed}>
        {completed && (
          <Icon
            name='circle-check'
            primaryColor={foundationSurface1}
            secondaryColor={stateSuccess}
            size='md'
            weight='duotone'
          />
        )}
      </StyledInnerCircle>
    </StyledOuterCircle>
  );
};

export default CompletionStatusCircle;
