import styled from 'styled-components';

import { fontSm4 } from '../../../../../../styled/TypeSystem';

export const Container = styled.div`
  display: flex;
  height: 11rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Image = styled.img`
  max-height: 4.25rem;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const Title = styled.p`
  max-width: 21rem;
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
`;
