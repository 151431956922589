import React from 'react';
import styled from 'styled-components';

import NotFound from '../../../../../../../../assets/images/empty_state/not-found-empty-state.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import ThemedImage from '../../../../../design_system/ThemedImage';
import { fontSm4 } from '../../../../../styled/TypeSystem';

const t = initTranslations('curriculums.ai_outliner.error_state');

const ErrorStateContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding-top: ${({ theme: { constants } }) => constants.spacerLg2};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const Label = styled.p<{ hasContentError: boolean }>`
  color: ${({ theme: { vars } }) => vars.textDefault};
  text-align: center;
  width: ${({ hasContentError }) => (hasContentError ? '12.5rem' : '13.875rem')};
  ${fontSm4};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const StyledDefaultButton = styled(DefaultButton)`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  border-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  &:hover {
    color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
    background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleSurfaceLight};
  }
`;

export type Props = {
  hasContentError?: boolean;
  onClick?: () => void;
};

const ErrorState = ({ hasContentError = false, onClick }: Props) => {
  return (
    <>
      <ErrorStateContainer id='smart-outline-error-state'>
        <ThemedImage alt='error-state-image' lightImage={NotFound} width='50%' />
        <Label hasContentError={hasContentError}>
          {hasContentError ? t('content_error') : t('api_error')}
        </Label>
      </ErrorStateContainer>
      {hasContentError && (
        <ButtonContainer id='content-error-button-container'>
          <StyledDefaultButton
            buttonType='secondary'
            id='smart-outline-generic-error-generate-button'
            onClick={onClick}
            size='md'
            text={t('error_button')}
            type='button'
          />
        </ButtonContainer>
      )}
    </>
  );
};

export default ErrorState;
