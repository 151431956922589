import { Action, AddTemplateFormOption } from './types';

export const addTemplateFormReducer = (
  state: AddTemplateFormOption,
  action: Action
): AddTemplateFormOption => {
  switch (action.type) {
    case 'selectedSectorChanged': {
      return { ...state, selectedSector: action.selectedSector };
    }
    case 'selectedOwnerIdChanged': {
      return { ...state, selectedOwnerId: action.selectedOwnerId };
    }
  }
};
