import React, { FC } from 'react';

import Tag from '../../../../design_system/display/Tag';
import PageHeader from '../../../../design_system/Triage/headers/PageHeader';
import MagicWandIcon from '../MagicWandIcon/MagicWandIcon';
import { SearchResultsHeaderContainer } from '../styles';
import { MainResultsTitleBox } from './styles';
import { ResultsHeaderProps } from './types';

const ResultsHeader: FC<ResultsHeaderProps> = ({ searchTerm }) => {
  return (
    <SearchResultsHeaderContainer hasSmallTitle>
      <MainResultsTitleBox>
        <MagicWandIcon />
        <PageHeader className='search-results-header' title={searchTerm} />
      </MainResultsTitleBox>

      <Tag size='md' type='accent' />
    </SearchResultsHeaderContainer>
  );
};

export default ResultsHeader;
