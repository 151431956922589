import React, { useCallback, useState } from 'react';

import {
  addSelectedUserId,
  removeSelectedUserId,
} from '../../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import Avatar from '../../../../design_system/display/avatar';
import Hoverable from '../../../../design_system/Hoverable';
import {
  ActionsWrapper,
  StyledIcon,
  GroupName as UserName,
  GroupKind as UserTitle,
} from '../SelectGroupModal/styles';
import { TUserSelectItemProps } from '../types';
import { AvatarAndNameWrapper, UserLabelWrapper, UserSelectableElement } from './styles';

const UserSelectItem = ({
  user,
  isInitiallySelected,
  isCurrentlySelected,
}: TUserSelectItemProps) => {
  const dispatch = useAppDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const isSelected = isCurrentlySelected || isInitiallySelected;
  const { id, title, name, avatar } = user;

  const handleItemSelectionToggle = useCallback(() => {
    if (isInitiallySelected) return;

    isCurrentlySelected ? dispatch(removeSelectedUserId(id)) : dispatch(addSelectedUserId(id));
  }, [dispatch, id, isCurrentlySelected, isInitiallySelected]);

  return (
    <Hoverable setIsHovered={setIsHovered}>
      <UserSelectableElement
        id={`user-selectable-element-${id}`}
        isInitiallySelected={isInitiallySelected}
        isSelected={isSelected}
        onClick={handleItemSelectionToggle}
      >
        <AvatarAndNameWrapper>
          <Avatar image={avatar} name={name} shape='circle' size='md' />
          <UserLabelWrapper>
            <UserName className='notranslate'>{name}</UserName>
            <UserTitle>{title}</UserTitle>
          </UserLabelWrapper>
        </AvatarAndNameWrapper>
        <ActionsWrapper>
          <StyledIcon
            className='delegation-planner-select-group-icon'
            isCurrentlySelected={isCurrentlySelected}
            isInitiallySelected={isInitiallySelected}
            name='circle-check'
            size='sm'
            weight={isHovered || isSelected ? 'solid' : 'regular'}
          />
        </ActionsWrapper>
      </UserSelectableElement>
    </Hoverable>
  );
};

export default UserSelectItem;
