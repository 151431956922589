import React, { FC } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Badge from '../../../../../design_system/display/badge';
import { StatusPillContainer } from './styles';
import { StatusPillProps } from './types';

const t = initTranslations('delegation_planner.status_pill');

const StatusPill: FC<StatusPillProps> = ({ isDraft }) => {
  return (
    <StatusPillContainer>
      <Badge text={isDraft ? t('draft') : t('applied')} type={isDraft ? 'caution' : 'success'} />
    </StatusPillContainer>
  );
};

export default StatusPill;
