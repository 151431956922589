import styled from 'styled-components';

export const StyledCheckmarkIcon = styled.span<{ correct?: boolean }>`
  min-width: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  color: ${({ theme: { vars } }) => vars.foundationSurface1};
  background-color: ${({ correct, theme: { vars } }) =>
    correct ? vars.stateHoverSuccess : vars.stateHoverError};
`;
