import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import { PageHeaderWrapper } from './styles';

const t = initTranslations('delegation_planner');

const DelegationPlannerHeader = () => {
  return (
    <PageHeaderWrapper>
      <PageHeader className='delegation-planner-header notranslate' title={t('title')} />
    </PageHeaderWrapper>
  );
};

export default DelegationPlannerHeader;
