import { GroupByMenuOption } from '../../types/GroupByMenuOption';
import initTranslations from '../initTranslations';

const t = initTranslations('templates_outline.view_by_dropdown');

const getTemplatesViewByDropdownOptions = () => {
  const options: GroupByMenuOption[] = [
    {
      label: t('recommended'),
      sortColumn: 'recommended',
      sortDirection: 'desc',
      groupBy: 'other',
      viewByLabel: t('view_by'),
    },
    {
      label: t('all'),
      sortColumn: 'all',
      sortDirection: 'asc',
      groupBy: 'other',
      viewByLabel: t('view_by'),
    },
  ];

  return {
    options,
  };
};

export default getTemplatesViewByDropdownOptions;
