import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../styled/TypeSystem';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const UpdatedAtText = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd1};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-left: auto;

  ${fontSm5};
`;

const LoadingIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const t = initTranslations('training_path.path_config.config_header');
type Props = {
  publishing?: boolean;
};

const Status = ({ publishing }: Props) => {
  const {
    trainingPath: { updatedAtInWords, trainingPathSets, isDraft },
    updateInProgress,
  } = useTrainingPathContext();

  const statusCopy = useMemo(() => {
    if (updateInProgress) {
      return publishing ? t('applying_changes') : t('saving_draft');
    }

    return isDraft
      ? t('draft_updated_at', { date: updatedAtInWords })
      : t('final_updated_at', { date: updatedAtInWords });
  }, [isDraft, publishing, updateInProgress, updatedAtInWords]);

  if (trainingPathSets.length === 0) return null;

  return (
    <Wrapper className='status-text'>
      <UpdatedAtText>
        {updateInProgress && (
          <LoadingIcon fixedWidth name='rotate' spinSpeed='fast' weight='solid' />
        )}
        {statusCopy}
      </UpdatedAtText>
    </Wrapper>
  );
};

export default Status;
