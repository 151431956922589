import React from 'react';
import { Route } from 'type-route';

import initTranslations from '../../../../../lib/initTranslations';
import { useCreateSurveyAttemptMutation } from '../../../../../redux/services/resourceApis/surveyAttempts/surveyAttemptsApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import { StatisticsWrapper, SurveyStatisticsWrapper } from './styles';
import SurveyStatisticBlock from './SurveyStatisticBlock/SurveyStatisticBlock';
import { SurveyStatisticsProps } from './types';

const t = initTranslations('surveys.survey_statistics');

const PreviewSurveyStatistics = ({
  displayStartSurveyButton = false,
  attemptsCount,
  lastFinishedAttemptTimestamp,
  questionsCount,
  minimumScore,
}: SurveyStatisticsProps) => {
  const route = useRoute();
  const {
    params: { id },
  } = route as Route<typeof routes.surveyConsume>;
  const [startAttempt, startAttemptResponse] = useCreateSurveyAttemptMutation();
  const lastAttemptInfoText = !!lastFinishedAttemptTimestamp
    ? t('last_attempt', { timestamp: lastFinishedAttemptTimestamp })
    : '';

  return (
    <SurveyStatisticsWrapper>
      <StatisticsWrapper displayStartSurveyButton={displayStartSurveyButton}>
        <SurveyStatisticBlock body={`${minimumScore}%`} title={t('score_needed_to_pass')} />
        <SurveyStatisticBlock body={questionsCount} title={t('number_of_questions')} />
        {attemptsCount !== undefined && (
          <SurveyStatisticBlock
            body={attemptsCount}
            infoText={lastAttemptInfoText}
            title={t('number_of_attempts')}
          />
        )}
      </StatisticsWrapper>

      {displayStartSurveyButton && (
        <DefaultButton
          buttonType='primary'
          fullWidth
          iconName='arrow-down'
          id='start-survey-button'
          loading={startAttemptResponse.isLoading}
          onClick={() => startAttempt({ surveyId: id })}
          text={t('start_test')}
        />
      )}
    </SurveyStatisticsWrapper>
  );
};

export default PreviewSurveyStatistics;
