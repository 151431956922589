import React, { useContext } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { CurriculumElementTrackable, Owner } from '../../activities/types';
import NotificationUserName from '../NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';

const t = initTranslations('notifications');

export type CourseCommonTemplateProps = {
  curriculumHref: string;
  activityKey: string;
  trackable: CurriculumElementTrackable;
  owner: Owner;
};

const CommonTemplate = ({
  activityKey,
  curriculumHref,
  owner,
  trackable,
}: CourseCommonTemplateProps) => {
  const { name, associated_params } = trackable;
  const { curriculum } = useContext(AccountTerminologyContext);

  return (
    <>
      <NotificationUserName user={owner} />
      <TextFragment>{t(activityKey)}</TextFragment>
      <SourceBadge sourceHref={curriculumHref} sourceName='curriculum' sourceText={name} />
      <TextFragment>
        {t('course.in_curriculum', { curriculum: curriculum.singular.toLowerCase() })}
      </TextFragment>
      <b>{associated_params.curriculum_title}</b>
    </>
  );
};

export default CommonTemplate;
