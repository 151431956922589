import styled, { css } from 'styled-components';

import { mediaBreakpointPxMd, mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { BoardControllerContainer } from '../BoardController/styles';
import { BoardToolsContainer, HeaderActionsBlock } from '../BoardTools/styles';
import { PageHeaderWrapper } from '../DelegationPlannerHeader/styles';
import { PositionValues } from './types';

export const headerContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const paddingBox = css`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    padding-inline: ${({ theme: { constants } }) => constants.spacerLg2};
  }
`;

export const PagePaddingBox = styled.div`
  ${paddingBox};
`;

export const BoardApplyingStateWrapper = styled.div<{ isApplying?: boolean }>`
  position: relative;
  width: 100%;
  height: calc(100% - 6.5rem);

  ${({ isApplying }) =>
    isApplying &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const BoardContainer = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: calc(100% - 1.5rem);
  overflow-x: auto;
  scroll-padding: 5rem;
  ${paddingBox};

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  > *:not(:last-child) {
    margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

export const PageContentWrapper = styled.div<{
  isFullscreenModeEnabled: boolean;
  positionValues: PositionValues;
}>`
  ${({ isFullscreenModeEnabled, positionValues: { top, left, bottom } }) => css`
    position: ${isFullscreenModeEnabled ? 'absolute' : 'static'};
    inset: ${isFullscreenModeEnabled ? '0' : `${top}px`} 0
      ${isFullscreenModeEnabled ? '0' : `${bottom}px`}
      ${isFullscreenModeEnabled ? '0' : `${left}px`};

    height: ${isFullscreenModeEnabled ? '100%' : 'calc(100vh - 9rem)'};
    padding: ${({ theme: { constants } }) => constants.spacerLg2} 0;

    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;

      width: 100vw;
      height: 100vh;

      background-color: ${({ theme: { vars } }) => vars.shadowBackground3};

      opacity: 0;
    }

    &:before {
      right: 0;
      transform: translateY(-100%);
    }

    &:after {
      left: 0;
      transform: translateX(-100%);
    }

    ${BoardHeader} {
      ${BoardToolsContainer} {
        ${HeaderActionsBlock} {
          &:nth-of-type(2) {
            position: relative;
            top: 0;
          }
        }
      }
    }

    ${isFullscreenModeEnabled &&
    css`
      z-index: 100;

      max-height: 100%;

      box-shadow: 0 20rem ${({ theme: { vars } }) => vars.shadowBackground3};
      transition: 0.5s ease-in-out;

      &:before,
      &:after {
        opacity: 1;
        transition: 0.5s ease-in-out;
      }

      ${BoardHeader} {
        ${BoardToolsContainer} {
          max-height: 2.25rem;

          transition: 0.5s ease-in-out;

          ${HeaderActionsBlock} {
            &:nth-of-type(1) {
              padding-right: 7rem;
              transition: 0.6s ease-in-out;
            }

            &:nth-of-type(2) {
              top: calc(-${({ theme: { constants } }) => constants.spacerLg2} - 100%);
              transition: 0.8s ease-in-out;

              @media (max-width: ${mediaBreakpointPxSm}) {
                top: unset;
                margin-top: -4.8rem;
              }
            }
          }
        }
      }

      ${PageHeaderWrapper} {
        max-height: 0;
        padding-bottom: 0;

        opacity: 0;
        transition: 0.8s ease-in-out;
      }

      ${BoardContainer} {
        transition: 0.5s ease-in-out;

        height: calc(100% - 1.5rem + ${({ theme: { constants } }) => constants.spacerLg3});
      }

      ${BoardControllerContainer} {
        bottom: 2rem;

        transition: 1s ease-in-out;
      }
    `}
  `};
`;

export const BoardHeader = styled.div<{ isApplying: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};

  ${({ isApplying }) =>
    isApplying &&
    css`
      [class*='HeaderActionsBlock']:not(:first-of-type) {
        opacity: 0.5;
        pointer-events: none;
      }
    `}

  @media (max-width: ${mediaBreakpointPxSm}) {
    flex-direction: column;
  }
`;

export const HeaderBannerWrapper = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    padding-inline: ${({ theme: { constants } }) => constants.spacerLg2};
  }
`;
