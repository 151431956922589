import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

import Icon from '../../../../../design_system/display/icons/Icon';
import {
  InsertDropdownButton,
  InsertDropdownButtonIconWrapper,
  InsertDropdownButtonText,
} from './styles';

export type InsertDropdownItemProps = {
  title: string;
  iconName: IconName;
  onClick: () => void;
};

const InsertDropdownItem = ({ title, iconName, onClick }: InsertDropdownItemProps) => {
  return (
    <InsertDropdownButton onClick={onClick}>
      <InsertDropdownButtonIconWrapper>
        <Icon name={iconName} />
      </InsertDropdownButtonIconWrapper>
      <InsertDropdownButtonText>{title}</InsertDropdownButtonText>
    </InsertDropdownButton>
  );
};

export default InsertDropdownItem;
