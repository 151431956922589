import React from 'react';
import { Route } from 'type-route';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import usePageTitle from '../../../../hooks/usePageTitle';
import { useGetCurriculumBreadcrumbsDataQuery } from '../../../../redux/services/resourceApis/reports/reportsApi';
import Breadcrumbs from '../../../design_system/navigation/Breadcrumbs';
import { routes } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import CourseReportTable from './CourseReportTable';

const CourseReportPage = ({
  route: {
    params: { curriculumId, courseId },
  },
}: {
  route: Route<typeof routes.courseReport>;
}) => {
  const { slug } = useCurrentAccount();
  const { isLoading, data } = useGetCurriculumBreadcrumbsDataQuery({
    id: curriculumId,
    elementId: courseId,
  });
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceName: data?.elementTitle, resourceType: 'course_reports', productTerm });

  if (isLoading) return <></>;
  if (!data) return <></>;

  return (
    <PageContent>
      <Breadcrumbs
        currentPageText={data.elementTitle}
        redirectPath={routes.curriculumByElementReport({ slug, id: curriculumId }).href}
        redirectText={data.curriculumTitle}
      />
      <CourseReportTable courseId={courseId} />
    </PageContent>
  );
};

export default CourseReportPage;
