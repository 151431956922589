import { Option } from 'components/design_system/core/SelectOption/types';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { scrollToSelectedOptionInSelect } from '../../../../../../lib/scrollToSelectedOptionInSelect';
import { StyledLabel, StyledSelect, TotalSeatsSelectWrapper } from './styles';

type TotalSeatsProps = {
  handleChange: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  isValid: boolean;
  seatsCount: string;
  options: Option[];
};

const t = initTranslations('per_user_pricing.full_screen_overlay_content.total_seats');

const TotalSeatsSelect = ({
  handleChange,
  isLoading,
  isValid,
  seatsCount,
  options,
}: TotalSeatsProps) => {
  return (
    <TotalSeatsSelectWrapper isLoading={isLoading}>
      <StyledLabel>{t('label')}</StyledLabel>
      <StyledSelect
        className='full-screen-overlay-total-seats-select'
        defaultValue={seatsCount}
        hideSelectedOptions={false}
        isValid={isValid}
        loading={isLoading}
        onMenuOpen={() => scrollToSelectedOptionInSelect('full-screen-overlay-total-seats-select')}
        onNonDefaultSelected={(value: string) => handleChange(Number(value))}
        options={options}
        value={seatsCount}
      />
    </TotalSeatsSelectWrapper>
  );
};

export default TotalSeatsSelect;
