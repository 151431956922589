import React from 'react';

import { useBulkExportPdfMutation } from '../../../../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import { BulkContentArchiveModalProps } from '../../types';
import BulkContentExportModal from './BulkContentExportModal';

const BulkContentExport = ({
  onClose,
  selectedIds,
  onSuccess,
  type,
  curriculumsChecked,
}: BulkContentArchiveModalProps) => {
  const [bulkExportPdf, result] = useBulkExportPdfMutation();

  return (
    <BulkContentExportModal
      bulkUpdate={({ curriculumIds }) => bulkExportPdf({ curriculumIds })}
      bulkUpdateResult={result}
      curriculumsChecked={curriculumsChecked}
      onClose={onClose}
      onSuccess={onSuccess}
      selectedIds={selectedIds}
      type={type}
    />
  );
};

export default BulkContentExport;
