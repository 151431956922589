import React from 'react';

import { useGetUserAssigmentColumns } from '../../../../../hooks/user_assignment_report/useGetUserAssigmentColumns';
import { HeaderRow } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { SortProps } from '../../TableTypes';
import { TableColumn, TableHeaderProps } from './types';
import UserAssignmentReportTableHeaderCell from './UserAssignmentReportTableHeaderCell';

const UserAssignmentReportTableHeader = ({
  tableHeaderState,
  dispatch,
  signaturesRequired,
}: TableHeaderProps) => {
  const userAssigmentColumns = useGetUserAssigmentColumns(signaturesRequired);
  return (
    <thead>
      <HeaderRow>
        {userAssigmentColumns.map((column: TableColumn) => {
          const activeColumn = tableHeaderState.sortCol === column.columnName;
          let sortProps: SortProps = { isSortable: column.sortable };

          if (column.sortable) {
            const sortIcon = activeColumn ? tableHeaderState.sortIcon : 'sort';
            sortProps = { isSortable: true, sortIcon };
          }

          return (
            <UserAssignmentReportTableHeaderCell
              activeHeader={activeColumn}
              column={column}
              key={`header-${column.columnName}`}
              setActiveHeader={() => {
                if (column.sortable) {
                  dispatch({ type: 'sortColumnChange', sortColumn: column.columnName });
                }
              }}
              {...sortProps}
            />
          );
        })}
      </HeaderRow>
    </thead>
  );
};

export default UserAssignmentReportTableHeader;
