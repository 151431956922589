import styled, { css } from 'styled-components';

import { fontSm4 } from '../../../../../../styled/TypeSystem';

export const DescriptionWrapper = styled.div`
  height: 7rem;

  span {
    margin-left: ${({ theme: { constants } }) =>
      `calc(${constants.spacerSm3} + ${constants.borderWidthSm})`};
  }
`;

export const Textarea = styled.textarea<{ readOnly: boolean }>`
  width: 100%;
  height: 100%;
  max-height: 7rem;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid transparent`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  outline: none;
  resize: none;

  ${fontSm4};

  &::placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  ${({ readOnly }) =>
    !readOnly &&
    css`
      &:hover {
        cursor: text;
        border-color: ${({ theme: { vars } }) => vars.borderDefault};
      }

      &:focus {
        outline: none;
        color: ${({ theme: { vars } }) => vars.textDefault};
        border-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
      }
    `}
`;
