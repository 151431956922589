import React from 'react';
import { Route } from 'type-route';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { FinchProvider } from '../../../../../types/IntegrationProvider';
import { routes } from '../../../publicApplication/applicationRouter';
import { NotificationLink, TextFragment } from '../styles';

export interface UserFinchTemplateProps {
  activityKey: string;
  parameters: { source: FinchProvider };
}

const FinchTemplate = ({ parameters, activityKey }: UserFinchTemplateProps) => {
  const t = initTranslations(`notifications.${activityKey}`);
  const { slug } = useCurrentAccount();
  const integrationName = initTranslations()(`finch_connect.provider.${parameters.source}`);
  const { payrollIntegrationSource } = useCurrentAccount();
  const { productTerm } = useAccountTerminology();
  const reauthorizeRoute = routes.integration({ slug, provider: parameters.source });
  const bulkInviteRoute = routes.manageUsers({ slug, open_react_finch_upload_modal: true });
  const updateUserUploadRoute = routes.manageUsers({
    slug,
    open_react_finch_upload_modal: true,
    update_upload_modal: true,
  });
  const bulkDeactivateRoute = routes.manageUsers({
    slug,
    open_finch_deactivate_users_modal: true,
  });
  const showClickable =
    payrollIntegrationSource === parameters.source ||
    activityKey === 'user.finch.need_to_reauthorize';

  const ACTIVITY_KEYS: { [P: string]: Route<typeof routes> } = {
    'user.finch.provider_connected': bulkInviteRoute,
    'user.finch.need_to_reauthorize': reauthorizeRoute,
    'user.finch.new_users_found': updateUserUploadRoute,
    'user.finch.new_users_to_deactivate': bulkDeactivateRoute,
  };
  const actionRoute = ACTIVITY_KEYS[activityKey];

  return (
    <>
      {showClickable ? (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: t('message', { integration: integrationName, productTerm }),
            }}
          />
          <NotificationLink to={actionRoute}>
            <TextFragment>{t('click_link', { productTerm })}</TextFragment>
          </NotificationLink>
        </>
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: t('message', { integration: integrationName, productTerm }),
          }}
        />
      )}
    </>
  );
};

export default FinchTemplate;
