import React, { FC, useEffect } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { setCurrentDragArgs } from '../../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useDispatchSetShowModal } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useMoveBoardColumnResponsibilityMutation } from '../../../../../redux/services/resourceApis/boardColumns/boardColumnsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';
import useLocalStorageState from '../../../publicApplication/utils/useLocalStorageState';
import useAutoSaveStatusUpdater from '../../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import { useCurrentBoardId } from '../../Board/hooks/useCurrentBoardId';

const t = initTranslations('delegation_planner.modals.move_responsibility_confirmation_modal');

export interface MoveResponsibilityConfirmationModalProps {
  resetMoveResponsibility: () => void;
}

const MoveResponsibilityConfirmationModal: FC<MoveResponsibilityConfirmationModalProps> = ({
  resetMoveResponsibility,
}) => {
  const { id: accountId } = useCurrentAccount();
  const currentBoardId = useCurrentBoardId();
  const dispatch = useAppDispatch();
  const dispatchShowModal = useDispatchSetShowModal();
  const [isMoveResponsibilityDontShowAgainEnabled, setIsMoveResponsibilityDontShowAgainEnabled] =
    useLocalStorageState({
      key: `isMoveResponsibilityDontShowAgainEnabled_${accountId}`,
      initialValue: false,
    });
  const { currentDragArgs } = useAppSelector((state) => state.delegationPlanner);
  const [moveBoardColumnResponsibility, moveBoardColumnResponsibilityResult] =
    useMoveBoardColumnResponsibilityMutation({});
  const { isLoading, isSuccess, isError } = moveBoardColumnResponsibilityResult;

  const handleDontShowAgain = () => {
    setIsMoveResponsibilityDontShowAgainEnabled(!isMoveResponsibilityDontShowAgainEnabled);
  };

  const handleCloseModal = (withReset = true) => {
    dispatch(setCurrentDragArgs(null));
    dispatchShowModal('moveResponsibilityConfirmationModal', false);

    withReset && resetMoveResponsibility();
  };

  const actionFunction = async () => {
    if (currentDragArgs) {
      await moveBoardColumnResponsibility({ boardId: currentBoardId, ...currentDragArgs }).then(
        () => {
          handleCloseModal(false);
        }
      );
    }
  };

  useEffect(() => {
    if (isError) {
      dispatchShowModal('cantMoveResponsibilityModal', true);
    }
  }, [isError, dispatchShowModal]);

  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();

  useAutoSaveStatusUpdater([
    {
      isSaving: isLoading,
      isSavedSuccessfully: isSuccess,
    },
  ]);

  return (
    <ConfirmationModal
      actionFunction={actionFunction}
      actionText={t('move')}
      desktopSize='xl'
      heapModalName='move-responsibility-confirmation-modal'
      message={t('message', { responsibility: responsibilitySingular.toLowerCase() })}
      onCloseRequest={handleCloseModal}
      primaryButtonAnimationTimeout={3000}
      processing={isLoading}
      tertiaryActionElement={
        <Checkbox
          checked={isMoveResponsibilityDontShowAgainEnabled}
          id='dont-show-again-move-responsibility-checkbox'
          label={t('dont_show_again')}
          name='dont-show-again-move-responsibility'
          onCheck={handleDontShowAgain}
        />
      }
      title={t('title', { responsibility: responsibilitySingular.toLowerCase() })}
    />
  );
};

export default MoveResponsibilityConfirmationModal;
