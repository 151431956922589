import React from 'react';

import { BodyRow } from '../../../people/UsersOutline/Table/Styles';
import { StyledColumn } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import TableColumn from './Columns/TableColumn';
import { columns } from './SurveyReportColumn';
import { TableRowProps } from './types';

const TableRow = ({ surveys }: TableRowProps) => {
  const { id } = surveys;

  return (
    <BodyRow id={`survey  -report-tr-${id}`}>
      {columns.map(({ columnName, contentAlignment }) => (
        <StyledColumn
          contentAlignment={contentAlignment}
          id={`survey-report-column-${id}-${columnName}`}
          key={`survey-report-column-${id}-${columnName}`}
        >
          <TableColumn columnName={columnName} record={surveys} />
        </StyledColumn>
      ))}
    </BodyRow>
  );
};

export default TableRow;
