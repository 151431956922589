import React from 'react';

import { FocusHandler } from '../../../../../hooks/billing/useValidateUpdatePaymentMethodForm/types';
import initTranslations from '../../../../../lib/initTranslations';
import { InputFieldWithEditLinkWrapper, StyledEditLink, StyledInputField } from '../styles';
import { ChangeHandler } from '../types';

type CardHolderFieldProps = {
  isFormProcessing: boolean;
  showStripeElements: boolean;
  handleChange: (arg: ChangeHandler) => void;
  validateField: (arg: FocusHandler) => void;
  value: string;
  errorText: string | false;
  setShowStripeElements: React.Dispatch<React.SetStateAction<boolean>>;
};

const t = initTranslations('update_payment_method_modal.card');

const CardHolderField = ({
  showStripeElements,
  handleChange,
  validateField,
  errorText,
  value,
  isFormProcessing,
  setShowStripeElements,
}: CardHolderFieldProps) => {
  return (
    <InputFieldWithEditLinkWrapper>
      <StyledInputField
        disabled={!showStripeElements || isFormProcessing}
        errorText={errorText}
        id='card-holder-name'
        label={t('cardholder_name')}
        name='name'
        onBlur={(event) => validateField({ event })}
        onChange={(event) => handleChange({ event })}
        placeholder={t('enter_name')}
        required
        type='text'
        value={value}
      />
      {!showStripeElements && (
        <StyledEditLink
          behavesAs='button'
          className='edit-card-holder-name'
          onClick={() => setShowStripeElements(true)}
          text={t('edit')}
        />
      )}
    </InputFieldWithEditLinkWrapper>
  );
};

export default CardHolderField;
