import React from 'react';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import LabelToolbarButton from '../LabelToolbarButton';

const t = initTranslations('editor_toolbar.insert_options');

const DividerToolbarButton = () => {
  const { editor } = useEditorContext();

  return (
    <LabelToolbarButton
      active={false}
      ariaLabel={t('aria_for_divider')}
      iconName='horizontal-rule'
      iconType='name'
      id='divider-button'
      label={t('divider')}
      onClick={() => editor.chain().focus().setHorizontalRule().run()}
    />
  );
};

export default DividerToolbarButton;
