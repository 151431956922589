import styled, { css } from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontMd1, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const DropZoneContainer = styled.div<{ isDisabled?: boolean }>`
  background-color: ${({ theme: { vars } }) => vars.foundationHover};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} dashed ${vars.accentPrimaryDefault}`};
  width: 100%;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

export const Dropzone = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerLg3} 0`};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DropzoneImage = styled.img`
  width: 9rem;
  max-height: 6rem;
`;

export const DropzoneDescription = styled.div`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm5};
`;

export const DropzoneHint = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
`;

export const DropzoneAssistiveText = styled.p`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  ${fontSm4};
`;

export const DropZoneButtonWrapper = styled.div`
  .dropzone {
    padding: 0;
  }
`;

const DividerStyles = css`
  content: '';
  display: block;
  width: 43%;
  height: 0.125rem;
  background-color: ${({ theme: { vars } }) => vars.borderSurface1};
  position: absolute;
  top: 10px;

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: 45%;
  }
`;

export const OptionsDivider = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin: ${({ theme: { constants } }) => constants.spacerMd3} 0;
  &:before {
    left: 0;
    ${DividerStyles}
  }
  &:after {
    right: 0;
    ${DividerStyles}
  }
`;

export const DividerText = styled.span`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  ${fontMd1};
`;

export const GoogleDrivePickerButton = styled(DefaultButton)`
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${({ theme: { constants } }) => constants.spacerLg1};
`;
