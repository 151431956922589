import React, { useState } from 'react';
import { Route } from 'type-route';

import { useGetSearchResultCardBadgeText } from '../../../../../../hooks/search/useGetSearchResultCardBadgeText';
import { useGetSearchResultCardContent } from '../../../../../../hooks/search/useGetSearchResultCardContent';
import { useGetSearchResultCardRoute } from '../../../../../../hooks/search/useGetSearchResultCardRoute';
import useAnalyticsUserEvents from '../../../../../../hooks/useAnalyticsUserEvents';
import { getSearchResultCardAdditionalText } from '../../../../../../lib/search/getSearchResultCardAdditionalText';
import { getSearchResultCardPlaceholderIcon } from '../../../../../../lib/search/getSearchResultCardPlaceholderIcon';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Avatar from '../../../../../design_system/display/avatar';
import Hoverable from '../../../../../design_system/Hoverable';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import {
  SearchResultsCardBadge,
  SearchResultsCardDescription,
  SearchResultsCardDetailsWrapper,
  SearchResultsCardEmojiWrapper,
  SearchResultsCardTitle,
  SearchResultsCardTitleWrapper,
  SearchResultsRightArrowIcon,
} from '../shared/styles';
import {
  SearchResultsCardAdditionalInfo,
  SearchResultsCardAdditionalInfoWrapper,
  SearchResultsCardAdditionalRow,
  SearchResultsCardBreadcrumb,
  SearchResultsCardBreadcrumbIcon,
  SearchResultsCardBreadcrumbWrapper,
  SearchResultsCardCurriculumTitle,
  SearchResultsCardLink,
} from './styles';
import { SearchResultsCardProps } from './types';

const SearchResultsCard = ({
  avatar,
  emoji,
  titleHighlighted,
  content,
  curriculumTitle,
  breadcrumbTitle,
  breadcrumbText,
  cardType,
  itemId,
  minutesToRead,
  ownerName,
  countOfElementsInside,
  surveyId,
  searchHistoryId,
  rankingNumber,
}: SearchResultsCardProps) => {
  const {
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();
  const [isHovered, setIsHovered] = useState(false);
  const isCircleEmojiWrapper = ['Group', 'User'].includes(cardType);
  const resourceId = surveyId || itemId;
  const currentRoute = useRoute();
  const {
    params: { search, filter, page },
  } = currentRoute as Route<typeof routes.search>;
  const route = useGetSearchResultCardRoute({
    cardType,
    itemId: resourceId,
    search,
    filter,
    page,
  });
  const badgeText = useGetSearchResultCardBadgeText(cardType);
  const cardPlaceholderIcon = getSearchResultCardPlaceholderIcon(cardType);
  const { ownerDetails, formattedMinutesToRead, elementsInsideDetails } =
    getSearchResultCardAdditionalText(minutesToRead, ownerName, countOfElementsInside, cardType);
  const contentText = useGetSearchResultCardContent(content, cardType);
  const isSurveyQuestion = cardType === 'Survey::Question';
  const showAdditionalInfo = ownerDetails || formattedMinutesToRead || elementsInsideDetails;
  const showBreadcrumb = breadcrumbTitle || breadcrumbText;
  const showCurriculumTitle =
    ['Survey::Survey', 'Course', 'Flowchart'].includes(cardType) && curriculumTitle;
  const showCardAdditionalRow = showCurriculumTitle || showAdditionalInfo;
  const { cdpSearchResultClicked } = useAnalyticsUserEvents();

  const handleClickOnSearchResultsCard = () => {
    cdpSearchResultClicked({
      id: resourceId,
      type: cardType,
      rankingNumber,
      searchHistoryId,
    });
  };

  return (
    <Hoverable className='search-result-card' setIsHovered={setIsHovered}>
      <div onClick={handleClickOnSearchResultsCard}>
        <SearchResultsCardLink to={route}>
          {avatar ? (
            <Avatar image={avatar} name='' shape='circle' size='md' />
          ) : (
            <SearchResultsCardEmojiWrapper isCircle={isCircleEmojiWrapper} isHovered={isHovered}>
              {emoji || cardPlaceholderIcon}
            </SearchResultsCardEmojiWrapper>
          )}
          <SearchResultsCardDetailsWrapper isHovered={isHovered}>
            <SearchResultsCardTitleWrapper>
              <SearchResultsCardTitle
                dangerouslySetInnerHTML={{ __html: titleHighlighted }}
                isSurveyQuestion={isSurveyQuestion}
              />
              {badgeText && (
                <SearchResultsCardBadge
                  isHovered={isHovered}
                  size='md'
                  text={badgeText}
                  type='general'
                />
              )}
            </SearchResultsCardTitleWrapper>
            {contentText && <SearchResultsCardDescription html={contentText} />}
            {showCardAdditionalRow && (
              <SearchResultsCardAdditionalRow>
                {showCurriculumTitle && (
                  <>
                    {`${curriculumSingular}:`}
                    <SearchResultsCardCurriculumTitle
                      dangerouslySetInnerHTML={{ __html: curriculumTitle }}
                    />
                  </>
                )}
                {showAdditionalInfo && (
                  <SearchResultsCardAdditionalInfoWrapper>
                    {ownerDetails && <SearchResultsCardAdditionalInfo text={ownerDetails} />}
                    {formattedMinutesToRead && (
                      <SearchResultsCardAdditionalInfo text={formattedMinutesToRead} />
                    )}
                    {elementsInsideDetails && (
                      <SearchResultsCardAdditionalInfo text={elementsInsideDetails} />
                    )}
                  </SearchResultsCardAdditionalInfoWrapper>
                )}
              </SearchResultsCardAdditionalRow>
            )}
            {showBreadcrumb && (
              <SearchResultsCardBreadcrumbWrapper>
                {breadcrumbTitle && (
                  <SearchResultsCardBreadcrumb
                    dangerouslySetInnerHTML={{ __html: breadcrumbTitle }}
                  />
                )}
                {breadcrumbText && (
                  <>
                    <SearchResultsCardBreadcrumbIcon
                      name='chevron-right'
                      size='2xs'
                      weight='solid'
                    />
                    <SearchResultsCardBreadcrumb
                      dangerouslySetInnerHTML={{ __html: breadcrumbText }}
                    />
                  </>
                )}
              </SearchResultsCardBreadcrumbWrapper>
            )}
          </SearchResultsCardDetailsWrapper>
          <SearchResultsRightArrowIcon
            isHovered={isHovered}
            name='arrow-right'
            size='sm'
            weight='regular'
          />
        </SearchResultsCardLink>
      </div>
    </Hoverable>
  );
};

export default SearchResultsCard;
