import { Form, Formik, useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { getEmailSchema } from '../../../../../lib/validation_schemas/email';
import { useResetPasswordMutation } from '../../../../../redux/services/resourceApis/publicApplication/loginApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Link from '../../../../design_system/Link';
import InputField from '../../../../design_system/Triage/InputField';
import Loader from '../../../../design_system/Triage/Loader';
import { LoaderWrapper } from '../../../curriculums/shared/styles';
import { routes } from '../../applicationRouter';
import PublicPage from '../../PublicPage';
import { ActionWrapper, FormWrapper, H1, Logo, LogoWrapper } from '../../styles/shared';
import useAccountData from '../../utils/useAccountData';
import useRedirectToLoginOnSuccess from '../../utils/useRedirectToLoginOnSuccess';
import { ForgotPasswordFormProps, ForgotPasswordProps, FormValues } from './types';

const t = initTranslations('public_application.forgot_password');

const initialValues: FormValues = {
  email: '',
};

const ForgotPasswordForm = ({ route, logo }: ForgotPasswordFormProps) => {
  const { slug, redirect_path } = route.params;
  const { isValid, isValidating, values, touched, errors, handleChange } =
    useFormikContext<FormValues>();
  const [resetPassword, result] = useResetPasswordMutation();
  const { isLoading, isSuccess, data } = result;
  const isActionInProgress = isLoading || isSuccess;
  const { email } = values;

  const submit = useCallback(() => {
    if (isValid && !isValidating) {
      resetPassword({ slug, email });
    }
  }, [email, isValid, isValidating, resetPassword, slug]);

  useDisplayFlashOnResponse({
    result,
    successMessage: data?.message,
  });

  useRedirectToLoginOnSuccess(result, { slug, email, redirect_path });

  return (
    <Form autoComplete='off'>
      <FormWrapper>
        <LogoWrapper>
          <Logo className='account-logo' src={logo} />
        </LogoWrapper>
        <H1>{t('title')}</H1>
        <InputField
          autoFocus
          errorText={touched.email && errors.email}
          iconName='envelope'
          label={t('email_label')}
          name='email'
          onChange={handleChange}
          placeholder={t('email_placeholder')}
          value={values.email}
        />
        <ActionWrapper>
          <div>
            {t('register_prompt')}
            <Link href={routes.root().href} text={t('register_cta')} />
          </div>
          <DefaultButton
            disabled={isActionInProgress}
            id='forgot-password-reset-button'
            loading={isActionInProgress}
            onClick={submit}
            text={t('cta')}
            type='submit'
          />
        </ActionWrapper>
      </FormWrapper>
    </Form>
  );
};

const ForgotPassword = ({ route }: ForgotPasswordProps) => {
  const { isLoadingAccountData, accountData } = useAccountData(route.params.slug);

  return (
    <>
      {isLoadingAccountData ? (
        <LoaderWrapper>
          <Loader size='lg' />
        </LoaderWrapper>
      ) : (
        <PublicPage id='forgot-password'>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {
              /* API request occurs in `LoginForm` */
            }}
            validateOnMount
            validationSchema={() =>
              getEmailSchema(t('form.errors.blank'), t('form.errors.invalid'))
            }
          >
            <ForgotPasswordForm logo={accountData?.logo} route={route} />
          </Formik>
        </PublicPage>
      )}
    </>
  );
};

export default ForgotPassword;
