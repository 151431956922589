import React from 'react';
import styled, { css } from 'styled-components';

const Pulse = styled.div`
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 75rem 100%;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }

  margin: ${({ theme: { constants } }) => constants.spacerMd2} 0;
`;

const LibraryRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 3.5rem;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd3}`};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};

  ${({ theme }) => {
    const { borderWidthXs } = theme.constants;
    const { foundationSurface1 } = theme.vars;
    return css`
      border: ${borderWidthXs} solid ${foundationSurface1};
      border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
    `;
  }}
`;

const TableWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  height: 10rem;
`;

const LoaderRow = () => {
  return (
    <LibraryRowContainer>
      <Pulse style={{ width: '40%', height: '1.5rem' }} />
      <div style={{ width: '20%' }} />
      <Pulse style={{ width: '10%', height: '1.5rem' }} />
      <Pulse style={{ width: '5%', height: '1.5rem' }} />
      <Pulse style={{ width: '10%', height: '1.5rem' }} />
    </LibraryRowContainer>
  );
};

const TableLoader = () => {
  return (
    <TableWrapper>
      <LoaderRow />
      <LoaderRow />
    </TableWrapper>
  );
};

export default TableLoader;
