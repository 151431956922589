import React from 'react';
import Lottie, { Options } from 'react-lottie';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { fontLg1, fontMd1 } from '../../../../../styled/TypeSystem';
import CompletionDelighterData from './completion_delighter_data.json';

const t = initTranslations('editor.success');

const CompletionDelighterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
`;

const Title = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};

  ${fontLg1};
`;

const Subtitle = styled.h4`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};

  ${fontMd1};
`;

export type Props = {
  subtitle: string;
};

const CompletionDelighter = ({ subtitle }: Props) => {
  const defaultOptions: Options = {
    loop: false,
    autoplay: true,
    animationData: CompletionDelighterData,
  };

  return (
    <CompletionDelighterContainer id='course-completion-delighter'>
      <Title>{t('success_title')}</Title>
      <Lottie options={defaultOptions} width={720} />
      <Subtitle>{subtitle}</Subtitle>
    </CompletionDelighterContainer>
  );
};

export default CompletionDelighter;
