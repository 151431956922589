import React from 'react';

import { useAnnualPercentageOfSavings } from '../../../../../hooks/billing/useAnnualPercentageOfSavings';
import initTranslations from '../../../../../lib/initTranslations';
import { BillingPlanName } from '../../../../../types/BillingPlanName';
import { DiscountDetails, DiscountsCouponCode } from '../styles';

type YearlyBillingPerkProps = {
  isManagePlanOverlayContent: boolean;
  productName: BillingPlanName;
};

const t = initTranslations('discounts_section');

const YearlyBillingPerk = ({ isManagePlanOverlayContent, productName }: YearlyBillingPerkProps) => {
  const percent = useAnnualPercentageOfSavings(productName);

  return (
    <>
      {isManagePlanOverlayContent ? (
        <>
          <DiscountDetails
            dangerouslySetInnerHTML={{
              __html: t('discount_applied', { percent }),
            }}
          />
          <DiscountsCouponCode>{t('yearly_billing_perk')}</DiscountsCouponCode>
        </>
      ) : (
        <DiscountDetails
          dangerouslySetInnerHTML={{
            __html: `${t('discount_applied', {
              percent,
            })} - ${t('yearly_billing_perk')}`,
          }}
        />
      )}
    </>
  );
};

export default YearlyBillingPerk;
