import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetUserProgressQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import ProgressBar from '../../../../design_system/Triage/ProgressBar';
import { routes } from '../../../publicApplication/applicationRouter';
import { Title } from '../../../shared/DetailedTitle/Title';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubheaderWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  flex-direction: column;
`;

const ProgressWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ProgressBarWidth = styled.div`
  display: flex;
  align-items: center;
  width: 8rem;
`;

export type OverviewHeaderProps = {
  userId: number;
};

const t = initTranslations('training_path.path_overview.overview_header');

const OverviewHeader = ({ userId }: OverviewHeaderProps) => {
  const ability = useCurrentUserAbilities();
  const canUpdateTP = ability.can('update', 'TrainingPath');
  const { slug } = useCurrentAccount();
  const { curriculum } = useAccountTerminology();
  const { data } = useGetUserProgressQuery(userId);

  if (!data) return null;

  const { completionPercentage, curriculumsRemainingCount, readingTimeRemaining } = data;
  const curriculumsRemaining = t('subheader.curriculums_remaining', {
    count: curriculumsRemainingCount,
    curriculum:
      curriculumsRemainingCount === 1
        ? curriculum.singular.toLowerCase()
        : curriculum.plural.toLowerCase(),
  });

  return (
    <Wrapper id={`user-${userId}-tp-overview-header`}>
      <HeaderWrapper>
        <Title fontSize='lg2' fontWeight='semibold' title={t('header')} />
        {canUpdateTP && (
          <DefaultButton
            behavesAs='a'
            href={routes.userTrainingPathConfig({ slug, id: userId }).href}
            id='training-path-overview-header-customize-button'
            text={t('customize')}
          />
        )}
      </HeaderWrapper>

      <SubheaderWrapper>
        <ProgressWrapper>
          <Title fontSize='md1' title={`${completionPercentage}%`} />
          <ProgressBarWidth>
            <ProgressBar percent={completionPercentage} thickness='lg' />
          </ProgressBarWidth>
        </ProgressWrapper>
        <Title fontColor='textSubdued' fontWeight='regular' title={curriculumsRemaining} />
        <Title
          fontColor='textSubdued'
          fontWeight='regular'
          title={`${readingTimeRemaining} ${t('subheader.read_time_remaining')}`}
        />
      </SubheaderWrapper>
    </Wrapper>
  );
};

export default OverviewHeader;
