import React, { useState } from 'react';

import { getTaskDrivenOnboardingTaskListEmoji } from '../../../../../../lib/home/getTaskDrivenOnboardingTaskListEmoji';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Link from '../../../../../design_system/Link';
import ProgressBar from '../../../../../design_system/Triage/ProgressBar';
import { HomeBlockBody, HomeBlockHeader } from '../../../ProgressBlock/styles';
import { WidgetContainer } from '../../../shared/styles';
import CelebrationAnimation from '../CelebrationAnimation/CelebrationAnimation';
import {
  AllDoneMessage,
  AllDoneWrapper,
  ProgressBarWrapper,
  ProgressText,
  StyledButton,
  Subheader,
  Task,
  TaskEmoji,
  TaskList,
  TaskListCloseIconButton,
  TaskListDismissIcon,
  TaskText,
} from './styles';
import { OnboardTaskKeys, TasksListProps } from './types';

const t = initTranslations('home.onboarding.tasks_list');

const TasksList = ({ tasks, setCurrentTaskNameState, onboardingState }: TasksListProps) => {
  const isClosedOnboardingModal =
    localStorage.getItem('task_driven_onboarding_first_steps_section_hidden') === 'true';
  const [taskListHidden, setTaskListHidden] = useState(isClosedOnboardingModal);

  const {
    curriculum: { singular: curriculumTermSingular },
    productTerm,
  } = useAccountTerminology();

  const completedTasksCount = tasks.filter((task) => task.completed).length;
  const progressPercentage = Math.round((completedTasksCount / tasks.length) * 100);
  const allTasksCompleted = tasks.every((task) => task.completed);

  function setUserClosedOnboardingModal(): void {
    localStorage.setItem('task_driven_onboarding_first_steps_section_hidden', 'true');
  }

  const handleHideClick = () => {
    setUserClosedOnboardingModal();
    setTaskListHidden(true);
  };

  const getCardNameByTaskKey = (key: OnboardTaskKeys) => {
    switch (key) {
      case 'publish_subject':
        return 'documenting';
      case 'invite_teammate':
        return 'invite';
      default:
        return 'organize';
    }
  };

  const handleClickOnTaskItem = (key: OnboardTaskKeys) => {
    const cardName = getCardNameByTaskKey(key);

    cardName && setCurrentTaskNameState(cardName);
  };

  const checkSkippedTask = (completed: boolean, key: OnboardTaskKeys) => {
    const cardName = getCardNameByTaskKey(key);

    return !completed && Boolean(onboardingState[cardName]);
  };

  if (taskListHidden) return <></>;

  return (
    <WidgetContainer id='tasks-list'>
      {allTasksCompleted ? (
        <AllDoneWrapper>
          <CelebrationAnimation />
          <AllDoneMessage>{t('all_done')}</AllDoneMessage>
          <StyledButton
            buttonType='tertiary'
            fullWidth
            id='hide-completed-task-list-button'
            onClick={handleHideClick}
            text={t('done')}
          />
          <TaskListCloseIconButton
            ariaLabel='Close'
            buttonSize='md'
            name='xmark'
            onClick={handleHideClick}
            weight='regular'
          />
        </AllDoneWrapper>
      ) : (
        <>
          <HomeBlockHeader>
            {t('header')}
            <Link
              behavesAs='button'
              onClick={handleHideClick}
              text={
                <>
                  {t('dismiss')}
                  <TaskListDismissIcon name='xmark' size='sm' />
                </>
              }
              underlineBehavior='dynamic'
            />
          </HomeBlockHeader>
          <HomeBlockBody>
            <Subheader>{t('subheader', { productTerm })}</Subheader>
            <ProgressBarWrapper>
              <ProgressBar percent={progressPercentage} thickness='lg' />
              <ProgressText>{progressPercentage}%</ProgressText>
            </ProgressBarWrapper>
            <TaskList>
              {tasks.map(({ completed, key }) => {
                const skipped = checkSkippedTask(completed, key);
                const emoji = getTaskDrivenOnboardingTaskListEmoji(key, completed, skipped);

                return (
                  <Task
                    completed={completed}
                    isClickable={!completed}
                    key={key}
                    onClick={() => !completed && handleClickOnTaskItem(key)}
                    skipped={skipped}
                  >
                    <TaskEmoji>{emoji}</TaskEmoji>
                    <TaskText completed={completed}>
                      {t(`task_keys.${key}`, {
                        curriculum: curriculumTermSingular.toLowerCase(),
                      })}
                    </TaskText>
                  </Task>
                );
              })}
            </TaskList>
          </HomeBlockBody>
        </>
      )}
    </WidgetContainer>
  );
};

export default TasksList;
