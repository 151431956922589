import React from 'react';

import SidebarSkeleton from '../../../components/SidebarSkeleton/SidebarSkeleton';
import StepContentSkeleton from '../../../components/StepContentSkeleton/StepContentSkeleton';
import { BaseContentWrapper, EditorWrapper, SkeletonWrapper } from '../../../shared/styles';
import ToolbarSkeleton from './ToolbarSkeleton/ToolbarSkeleton';

const ViewModeSkeleton = () => {
  return (
    <EditorWrapper>
      <SkeletonWrapper>
        <SidebarSkeleton />
        <BaseContentWrapper>
          <ToolbarSkeleton />
          <StepContentSkeleton />
        </BaseContentWrapper>
      </SkeletonWrapper>
    </EditorWrapper>
  );
};

export default ViewModeSkeleton;
