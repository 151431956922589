import { FormikErrors, useFormik } from 'formik';
import { isEqual } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { ContextQuestions } from '../../../../../../redux/services/resourceApis/curriculums/types';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import DeprecatedTextareaWithCharacterCount from '../../../../../design_system/ToBeDeprecated/DeprecatedTextareaWithCharacterCount';
import { fontSm5 } from '../../../../../styled/TypeSystem';

const t = initTranslations('curriculums.ai_outliner.slideout.custom');

const CustomQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerMd2} ${constants.spacerMd3} ${constants.spacerMd2}`};
  overflow-y: auto;
  flex-grow: 1;
`;

const CustomQuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionTitle = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5}
`;

const CancelAndSaveButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  gap: 0.625rem;
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

const CustomContextPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  height: 100%;
  overflow: hidden;
`;

export interface ContextQuestionsValues {
  keyLearningObjectives: string;
  whoIsItFor: string;
  infoToCover: string;
}

export type Props = {
  contextQuestions: ContextQuestions | null;
  onCancel: () => void;
  onSubmit: (values: ContextQuestionsValues) => void;
};

const ContextQuestionsPanel = ({ contextQuestions, onCancel, onSubmit }: Props) => {
  const {
    curriculum: { singular: subjectSingular },
    topic: { plural: topicPlural },
  } = useAccountTerminology();
  const formik = useFormik({
    initialValues: {
      keyLearningObjectives: contextQuestions?.key_learning_objectives || '',
      whoIsItFor: contextQuestions?.who_is_it_for || '',
      infoToCover: contextQuestions?.info_to_cover || '',
    },
    validate: () => validateForm(),
    onSubmit: (values: ContextQuestionsValues) => onSubmit(values),
  });

  const validateForm = useCallback(() => {
    const errors: FormikErrors<ContextQuestionsValues> = {};

    return errors;
  }, []);

  const { initialValues, values, handleChange } = formik;

  const isValuesUnchanged = useMemo(() => isEqual(initialValues, values), [initialValues, values]);

  const CUSTOM_AI_MAX_CHARACTERS = 500;
  return (
    <CustomContextPanelContainer>
      <CustomQuestions>
        <CustomQuestionBlock>
          <QuestionTitle>{t('key_learning_objectives')}</QuestionTitle>
          <DeprecatedTextareaWithCharacterCount
            aiOutliner
            anchoredRightCharacterCount
            attributeName='keyLearningObjectives'
            autofocus={false}
            id='custom-ai-objectives-field'
            inputFontSize='xs'
            maxCharacters={CUSTOM_AI_MAX_CHARACTERS}
            name='keyLearningObjectives'
            noTopMargin
            onChange={handleChange}
            placeholder={t('placeholder_key_learning_objectives', {
              subject: subjectSingular.toLowerCase(),
            })}
            resourceName='Key learning objectives'
            rows={4}
            value={values.keyLearningObjectives}
          />
        </CustomQuestionBlock>
        <CustomQuestionBlock>
          <QuestionTitle>{t('who_is_it_for')}</QuestionTitle>
          <DeprecatedTextareaWithCharacterCount
            aiOutliner
            anchoredRightCharacterCount
            attributeName='whoIsItFor'
            autofocus={false}
            id='custom-ai-who-for-field'
            inputFontSize='xs'
            maxCharacters={CUSTOM_AI_MAX_CHARACTERS}
            name='whoIsItFor'
            noTopMargin
            onChange={handleChange}
            placeholder={t('placeholder_who_is_it_for')}
            resourceName='name'
            rows={4}
            value={values.whoIsItFor}
          />
        </CustomQuestionBlock>
        <CustomQuestionBlock>
          <QuestionTitle>
            {t('info_to_cover', {
              subject: subjectSingular.toLowerCase(),
            })}
          </QuestionTitle>
          <DeprecatedTextareaWithCharacterCount
            aiOutliner
            anchoredRightCharacterCount
            attributeName='infoToCover'
            autofocus={false}
            id='custom-ai-info-field'
            inputFontSize='xs'
            maxCharacters={CUSTOM_AI_MAX_CHARACTERS}
            name='infoToCover'
            noTopMargin
            onChange={handleChange}
            placeholder={t('placeholder_info_to_cover', { topics: topicPlural.toLowerCase() })}
            resourceName='name'
            rows={4}
            value={values.infoToCover}
          />
        </CustomQuestionBlock>
      </CustomQuestions>
      <CancelAndSaveButtonsWrapper>
        <DefaultButton
          buttonType='secondary'
          fullWidth
          id='cancel-custom-ai-outline'
          onClick={onCancel}
          text={t('cancel')}
        />
        <DefaultButton
          buttonType='primary'
          disabled={isValuesUnchanged}
          fullWidth
          id='save-custom-ai-outline'
          onClick={() => formik.handleSubmit()}
          text={t('save')}
        />
      </CancelAndSaveButtonsWrapper>
    </CustomContextPanelContainer>
  );
};

export default ContextQuestionsPanel;
