import React from 'react';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../../lib/initTranslations';
import { OverlayContentHeaderTitle } from '../../../../../shared/FullScreenOverlay/styles';
import {
  StyledBadge,
  OverlayContentHeader as StyledOverlayContentHeader,
} from '../../shared/styles';
import { ManagePlanOverlayType } from '../types';

const t = initTranslations('per_user_pricing.manage_plan_overlay_content.header');

type OverlayContentHeaderProps = {
  type: ManagePlanOverlayType;
};

const OverlayContentHeader = ({ type }: OverlayContentHeaderProps) => {
  const { usedUserSlotsCount, totalUserSlotsCount, onHoldPlan, unlimitedUserSlots } =
    useCurrentAccount();

  const getHeaderTitle = () => {
    switch (type) {
      case 'upgrade':
        return t('title.upgrade');
      case 'downgrade':
        return t('title.downgrade');
      case 'manage-seats':
        return t('title.manage_seats');
    }
  };

  return (
    <StyledOverlayContentHeader>
      <OverlayContentHeaderTitle>{getHeaderTitle()}</OverlayContentHeaderTitle>
      {!onHoldPlan && (
        <StyledBadge
          className='seats-used-badge'
          text={t('seats_used', {
            used: usedUserSlotsCount,
            total: unlimitedUserSlots ? t('unlimited') : totalUserSlotsCount,
          })}
          type='general'
        />
      )}
    </StyledOverlayContentHeader>
  );
};

export default OverlayContentHeader;
