import React, { FC } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import BoardSaveStatus from '../BoardSaveStatus/BoardSaveStatus';
import AddCardDropdownMenu from '../dropdownMenus/AddCardDropdown/AddCardDropdown';
import FullscreenButton from '../FullscreenButton/FullscreenButton';
import { BoardToolsContainer, HeaderActionsBlock } from './styles';
import { BoardToolsProps } from './types';

const t = initTranslations('delegation_planner');

const BoardTools: FC<BoardToolsProps> = ({
  currentBoard,
  isEmptyBoard,
  handleApplyChanges,
  isBoardApplied,
  isBoardDataFetching,
  isChangesApplying,
  isFullscreenModeEnabled,
}) => {
  return (
    <BoardToolsContainer>
      <HeaderActionsBlock>
        <BoardSaveStatus
          currentBoard={currentBoard}
          isChangesApplying={isChangesApplying}
          isFetching={isBoardDataFetching}
        />
        <DefaultButton
          buttonType='primary'
          disabled={isChangesApplying || isBoardApplied || isEmptyBoard}
          id='delegation-planner-apply-changes-button'
          onClick={handleApplyChanges}
          text={t('apply_to_account')}
        />
      </HeaderActionsBlock>

      <HeaderActionsBlock>
        <FullscreenButton isFullscreenModeEnabled={isFullscreenModeEnabled} />

        <AddCardDropdownMenu
          isAnimated={isEmptyBoard}
          menuId='add-delegation-card-menu'
          menuOptionsClassName='add-delegation-card-menu-options'
          menuPlacement='bottom-start'
        />
      </HeaderActionsBlock>
    </BoardToolsContainer>
  );
};

export default BoardTools;
