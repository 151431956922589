import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import styled, { useTheme } from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import useIntegrationCardsImages from '../../../../hooks/useIntegrationCardsImages';
import initTranslations from '../../../../lib/initTranslations';
import ThemedImage from '../../../design_system/ThemedImage';
import PageWrapper from '../IntegrationShowPage/PageWrapper';
import { DescriptionSection, LogoSection } from '../IntegrationShowPage/styles';

const StyledIntegrationLogo = styled(ThemedImage)`
  width: 11.5rem;
`;

const ZapierTemplatesSection = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.vars.accentPrimaryDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  &:hover {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }
`;

const t = initTranslations('integrations.zapier');

const ZapierIntegrationShowPage = () => {
  const { mode } = useTheme();
  const { cardsData } = useIntegrationCardsImages();
  const { darkImage, lightImage } = cardsData['zapier'];
  const { productTerm } = useAccountTerminology();

  return (
    <PageWrapper integrationName={t('title')} isBeta={false}>
      <LogoSection>
        <StyledIntegrationLogo alt={t('title')} darkImage={darkImage} lightImage={lightImage} />
      </LogoSection>
      <DescriptionSection>
        <p>{t('description_p1', { productTerm })}</p>
        <p>
          {t('description_p2')}{' '}
          <StyledLink href='https://zapier.com/apps/trainual/integrations' target='_blank'>
            {t('zapier_integration')}
          </StyledLink>{' '}
          {t('description_p3')}
        </p>
      </DescriptionSection>
      <ZapierTemplatesSection>
        <HelmetProvider>
          <Helmet>
            <script
              src='https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js'
              type='module'
            />
            <link
              href='https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css'
              rel='stylesheet'
            />
          </Helmet>
        </HelmetProvider>
        <zapier-zap-templates
          apps='trainual'
          create-without-template='show'
          limit='10'
          link-target='new-tab'
          presentation='row'
          theme={mode}
        />
      </ZapierTemplatesSection>
    </PageWrapper>
  );
};

export default ZapierIntegrationShowPage;
