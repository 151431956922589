import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useGroup } from '../../../../contexts/GroupContext';
import useWindowResize from '../../../../hooks/useWindowResize';
import initTranslations from '../../../../lib/initTranslations';
import { UseQueryResult } from '../../../../redux/baseQueries/types';
import { messageFromError } from '../../../../redux/errors/helpers';
import {
  useAddResponsibilityToResponsibiliableMutation,
  useGetResponsibilitiesByGroupQuery,
} from '../../../../redux/services/resourceApis/responsibilities/responsibilitiesApi';
import { ResponsibilitiesResponse } from '../../../../redux/services/resourceApis/responsibilities/types';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import P from '../../../design_system/text/P';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { mediaBreakpointLg, mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import InputLineField from '../../groups/InputLineField';
import { RESPONSIBILITY_MAX_CHARACTERS } from '../../groups/shared/constants/groups';
import ResponsibilitiesList from '../../responsibilities/ResponsibilitiesList';
import { Title } from '../../shared/DetailedTitle/Title';
import SuggestionsResponsibilitiesFactory from '../SuggestionsResponsibilitiesFactory';
import {
  AddResponsibilitiesWrapper,
  GroupsStyledSurface,
  ResponsibilitiesListWrapper,
} from './shared/styles';
import { ResponsibilitiesProps } from './shared/types';

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;

const SuggestionsTertiaryWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  width: 100%;

  #show-suggested-responsibilities {
    justify-content: center;
    width: 100%;
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    #show-suggested-responsibilities {
      width: auto;
    }
  }
`;

const t = initTranslations('groups');

const ResponsibilitiesGroupShow = ({ title, description }: ResponsibilitiesProps) => {
  const {
    responsibility: { singular: responsibilitySingular, plural: responsibilityPlural },
  } = useAccountTerminology();
  const group = useGroup();
  const { width } = useWindowResize();
  const { setFlashLimit } = useFlashNotification();
  const [showSuggestionsModal, setShowSuggestionsModal] = useState(false);
  const assignedResponsibilitiesResult: UseQueryResult<ResponsibilitiesResponse> =
    useGetResponsibilitiesByGroupQuery(group.id);
  const [addResponsibility, result] = useAddResponsibilityToResponsibiliableMutation();
  const { isLoading, isSuccess, error, data, reset } = result;

  useEffect(() => {
    setFlashLimit(5);
  }, [setFlashLimit]);

  useEffect(() => {
    if (isSuccess && data) reset();
  }, [data, isSuccess, reset]);

  return (
    <GroupsStyledSurface className='responsibilities-surface'>
      <TitleWrapper>
        <StyledTitle fontSize='md2' fontWeight='semibold' title={title} />
        <DefaultButton
          buttonType='primary'
          className='suggested-responsibilities-modal-trigger-add-responsibilities'
          id='show-suggested-resps-primary'
          onClick={() => setShowSuggestionsModal(true)}
          text={
            width < mediaBreakpointLg
              ? t('add_suggestions_responsibility_primary_mobile')
              : t('add_suggestions_responsibility_primary', {
                  responsibilities: responsibilityPlural.toLowerCase(),
                })
          }
        />
      </TitleWrapper>
      <P text={description} />
      <ResponsibilitiesListWrapper>
        <ResponsibilitiesList
          responsibiliable_id={group.id}
          responsibiliable_type='Group'
          result={assignedResponsibilitiesResult}
        />
      </ResponsibilitiesListWrapper>
      <AddResponsibilitiesWrapper>
        <InputLineField
          buttonId='create-responsibility-inline'
          buttonText={t('add_responsibility_cta')}
          canSubmitEmpty={false}
          inputFor={t('add_responsibility_input_for', { responsibility: responsibilitySingular })}
          isLoading={isLoading}
          isSuccess={isSuccess}
          maxCharacters={RESPONSIBILITY_MAX_CHARACTERS}
          placeholder={t('add_responsibility_placeholder', {
            responsibilities: responsibilityPlural.toLowerCase(),
          })}
          responseError={messageFromError(error)?.join(', ')}
          submitChanges={(name) =>
            addResponsibility({ resource: { groupId: group.id }, name: name.trim() })
          }
          textAreaId='responsibility-inline-textarea'
        />
      </AddResponsibilitiesWrapper>
      <SuggestionsTertiaryWrapper>
        <DefaultButton
          buttonType='tertiary'
          className='suggested-responsibilities-modal-trigger-need-suggestions'
          iconName='plus'
          id='show-suggested-resps-tertiary'
          onClick={() => setShowSuggestionsModal(true)}
          text={t('add_suggestions_responsibility_tertiary')}
        />
      </SuggestionsTertiaryWrapper>
      {showSuggestionsModal && (
        <SuggestionsResponsibilitiesFactory closeModal={() => setShowSuggestionsModal(false)} />
      )}
    </GroupsStyledSurface>
  );
};

export default ResponsibilitiesGroupShow;
