import React from 'react';

import { ColumnComponentProps, ElementReportColumn } from '../types';
import ClickableArrow from './ClickableArrow';
import CompletionPercentage from './CompletionPercentage';
import ContentType from './ContentType';
import ESignature from './ESignature';
import Title from './Title';
import UpdatedAt from './UpdatedAt';
import UsersCompletedCount from './UsersCompletedCount';

type TableColumnsProps = {
  [key in Exclude<ElementReportColumn, 'actions'>]: React.FC<ColumnComponentProps>;
};

const COLUMNS_KEYS: TableColumnsProps = {
  title: Title,
  element_type: ContentType,
  completion_percentage: CompletionPercentage,
  users_completed_count: UsersCompletedCount,
  updated_at: UpdatedAt,
  clickable_arrow: ClickableArrow,
  e_signatures: ESignature,
};

const TableColumn = ({ columnName, record }: ColumnComponentProps) => {
  const Column = COLUMNS_KEYS[columnName as keyof TableColumnsProps];

  return <Column columnName={columnName} record={record} />;
};

export default TableColumn;
