import styled, { css } from 'styled-components';

import { fontMd1 } from '../../../styled/TypeSystem';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelStyles = css`
  display: block;
  margin-bottom: 0;
  ${fontMd1};
`;

export const FieldTitle = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${LabelStyles};
`;

export const FieldDescription = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  max-width: 80%;
  padding-top: ${({ theme: { constants } }) => constants.spacerMd3};
  ${LabelStyles};
`;

export const FormSectionWrapper = styled.section`
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

export const SectionDivider = styled.hr`
  border-width: ${({ theme: { constants } }) => constants.borderWidthSm} 0 0 0;
  border-style: solid;
  border-color: ${({ theme: { vars } }) => vars.borderSurface2};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
`;

export const FormSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
