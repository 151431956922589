import styled from 'styled-components';

export const StyledCheckmarkIcon = styled.span<{ completed?: boolean }>`
  min-width: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  ${({ completed, theme: { vars } }) =>
    completed
      ? `
          background-color: ${vars.accentPrimaryDefault};
          color: ${vars.foundationSurface1};
        `
      : `
          background-color: ${vars.foundationBase2};
          color: ${vars.textDisabled};
        `}
`;
