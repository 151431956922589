import { BaseOption } from '../components/design_system/core/SelectOption/types';

export default function convertToOption<
  T extends {
    id: number;
    name: string;
  }
>(resource: T[]): BaseOption[] {
  return resource.map((r) => ({
    label: r.name,
    value: String(r.id),
  }));
}
