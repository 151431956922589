import React from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useGetUserPrimaryDetailsQuery } from '../../../../redux/services/resourceApis/users/usersApi';
import Breadcrumbs from '../../../design_system/navigation/Breadcrumbs';
import Loader from '../../../design_system/Triage/Loader';
import { routes } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import OverviewHeader from './OverviewHeader/OverviewHeader';
import OverviewSets from './OverviewSets/OverviewSets';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export type PathOverviewProps = {
  route: Route<typeof routes.userTrainingPath>;
};

const t = initTranslations('user_profile');

const PathOverview = ({
  route: {
    params: { id },
  },
}: PathOverviewProps) => {
  const { slug } = useCurrentAccount();
  const { data: user, isLoading, error } = useGetUserPrimaryDetailsQuery(id);

  return (
    <PageContent id='user-training-path-overview'>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <BasicErrorDisplay error={error} />
      ) : (
        user && (
          <Breadcrumbs
            currentPageText={t('breadcrumbs.training_path')}
            isRedirectTextSentenceCase={false}
            redirectPath={routes.userProfile({ id, slug }).href}
            redirectText={user.name}
          />
        )
      )}
      <Wrapper>
        <OverviewHeader userId={id} />
        <OverviewSets userId={id} />
      </Wrapper>
    </PageContent>
  );
};

export default PathOverview;
