import { useState } from 'react';
import { Route } from 'type-route';

import { routes, useRoute } from '../../components/application/publicApplication/applicationRouter';
import { flash } from '../../components/FlashNotificationContext';
import { checkCreditCardData } from '../../lib/billing/checkCreditCardData';
import initTranslations from '../../lib/initTranslations';
import { UseQueryResult } from '../../redux/baseQueries/types';
import { useCheckModalState } from '../../redux/domains/modalsSlice/modalsSlice';
import { messageFromError } from '../../redux/errors/helpers';
import { usePurchaseAddonMutation } from '../../redux/services/resourceApis/billing/billingApi';
import {
  AddonDataResponse,
  AddonTrainualPlusDataResponse,
} from '../../redux/services/resourceApis/billing/simplePricingTypes';
import useDisplayFlashOnResponse from '../useDisplayFlashOnResponse';
import { useCloseFullScreenOverlay } from './useCloseFullScreenOverlay';

const t = initTranslations('simple_pricing.addon_overlay_content');

export const useAddonFullScreenOverlay = (
  queryResult: UseQueryResult<AddonDataResponse | AddonTrainualPlusDataResponse>,
  isStorybookEnvironment?: boolean,
  mockData?: AddonDataResponse | AddonTrainualPlusDataResponse
) => {
  const { data, error, isFetching } = queryResult;
  const route = useRoute();
  const addonData = isStorybookEnvironment ? mockData : data;
  const {
    params: { name },
  } = route as Route<typeof routes.addonOverlay>;
  const [purchaseAddon, purchaseAddonResult] = usePurchaseAddonMutation();
  const [isOpenedLightboxVideoPlayer, setIsOpenedLightboxVideoPlayer] = useState(false);
  const showUpdatePaymentMethodModal = useCheckModalState('updatePaymentMethod');
  const isOpenedModals = isOpenedLightboxVideoPlayer || showUpdatePaymentMethodModal;
  const cardDetails = addonData?.billingDetails?.cardDetails;
  const hasCardDetails = !!cardDetails && checkCreditCardData(cardDetails);
  const isLoadingSubmitButton = purchaseAddonResult.isLoading || isFetching || !addonData;
  const isDisabledSubmitButton = isLoadingSubmitButton || !hasCardDetails;
  const closeFullScreenOverlay = useCloseFullScreenOverlay();

  const handleSubmit = () => {
    if (!isDisabledSubmitButton) {
      purchaseAddon({ dueToday: addonData.newPlan.dueToday, name });
    }
  };

  useDisplayFlashOnResponse({
    result: purchaseAddonResult,
    successFunction: () => {
      flash('success', t(`flash.success.text.${name}`), {
        customTitle: t('flash.success.title'),
      });
      closeFullScreenOverlay();
    },
    errorMessage: messageFromError(purchaseAddonResult?.error)?.join(', '),
  });

  useDisplayFlashOnResponse({
    result: queryResult,
    errorFunction: closeFullScreenOverlay,
    errorMessage: messageFromError(error)?.join(', '),
  });

  return {
    isFetching,
    hasCardDetails,
    purchaseAddonResult,
    isOpenedModals,
    setIsOpenedLightboxVideoPlayer,
    isLoadingSubmitButton,
    isDisabledSubmitButton,
    handleSubmit,
    closeFullScreenOverlay,
    data: addonData,
  };
};
