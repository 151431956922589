import { useMemo } from 'react';

import { useAccountTerminology } from '../../components/AccountTerminologyProvider';
import { FilterList } from '../../components/application/searches/SearchResultsPage/SearchResultsFilter/types';
import initTranslations from '../../lib/initTranslations';

const t = initTranslations('search_results_page.filter');

export function useGetSearchResultsFilters() {
  const {
    curriculum: { plural: curriculumTermPlural },
    topic: { plural: topicTermPlural },
    step: { plural: stepTermPlural },
  } = useAccountTerminology();

  const filterList: FilterList = useMemo(() => {
    return {
      curriculum: {
        name: 'subjects',
        order: 1,
        title: curriculumTermPlural,
      },
      course: {
        name: 'topics',
        order: 2,
        title: topicTermPlural,
      },
      step: {
        name: 'steps',
        order: 3,
        title: stepTermPlural,
      },
      'survey::survey': {
        name: 'tests',
        order: 4,
        title: t('tests'),
      },
      'survey::question': {
        name: 'test_question',
        order: 5,
        title: t('test_questions'),
      },
      user: {
        name: 'people',
        order: 6,
        title: t('teammates'),
      },
      group: {
        name: 'groups',
        order: 7,
        title: t('groups'),
      },
      flowchart: {
        name: 'flowcharts',
        order: 8,
        title: t('flowcharts'),
      },
    };
  }, [curriculumTermPlural, stepTermPlural, topicTermPlural]);

  return filterList;
}
