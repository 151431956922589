import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTrainingPathContext } from '../../../../../../../contexts/TrainingPathContext';
import { useTrainingPathSetContext } from '../../../../../../../contexts/TrainingPathSetContext';
import { formatOptions } from '../../../../../../design_system/core/CoreSelectField/CoreSelectField';
import SingleSelectField from '../../../../../../design_system/Triage/fields/SingleSelectField';

const Wrapper = styled.div`
  width: 4.5rem;
`;

const delayOptions = () => {
  return formatOptions(
    Array.from({ length: 99 }, (_, i) => {
      const value = (i + 1).toString();
      return { label: value, value };
    })
  );
};

const DelayValueInput = () => {
  const {
    trainingPathSet: { delayValue },
    updateTrainingPathSet,
  } = useTrainingPathSetContext();
  const { updateInProgress } = useTrainingPathContext();
  const [newDelayValue, setNewDelayValue] = useState(delayValue);

  useEffect(() => {
    setNewDelayValue(delayValue);
  }, [delayValue]);

  return (
    <Wrapper>
      <SingleSelectField
        className='tp-delay-value'
        defaultValue={newDelayValue?.toString() || ''}
        expandMenuToFitOptions
        isMenuOpenedOnFocus
        isSearchable
        menuPosition='fixed'
        name='delay-value'
        onNonDefaultSelected={(value: string) => {
          if (updateInProgress) return;
          const delayValue = Number(value);
          delayValue !== newDelayValue && updateTrainingPathSet({ delayValue });
        }}
        options={delayOptions()}
        value={newDelayValue?.toString() || ''}
      />
    </Wrapper>
  );
};

export default DelayValueInput;
