import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { UseQueryResult } from '../../../../../redux/baseQueries/types';
import { ResponsibilitiesResponse } from '../../../../../redux/services/resourceApis/responsibilities/types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import {
  ResponsibilitiesListWrapper,
  StyledSurface,
  StyledTitleRow,
  TitleWrapper,
} from '../../../responsibilities/SurfaceResponsibilities/shared/styles';
import Title from '../../../responsibilities/SurfaceResponsibilities/Title';
import NonAdminUserResponsibilitiesList from '../NonAdminUserResponsibilitiesList';

const t = initTranslations('users_profile');

export interface Props {
  userFirstName?: string;
  result: UseQueryResult<ResponsibilitiesResponse>;
}

const NonAdminUserResponsibilities = ({ userFirstName, result }: Props) => {
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();

  return (
    <StyledSurface id='user-responsibilities'>
      <StyledTitleRow>
        <TitleWrapper>
          <Title
            firstName={userFirstName}
            titleText={t('user_responsibilities_heading', {
              responsibilities: responsibilityPlural,
            })}
          />
        </TitleWrapper>
      </StyledTitleRow>
      <ResponsibilitiesListWrapper>
        <NonAdminUserResponsibilitiesList result={result} />
      </ResponsibilitiesListWrapper>
    </StyledSurface>
  );
};

export default NonAdminUserResponsibilities;
