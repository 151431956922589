import React from 'react';

import useWindowResize from '../../../../../hooks/useWindowResize';
import {
  SearchResultsLoaderIconSkeleton,
  SearchResultsLoaderItem,
  SearchResultsLoaderRow,
  SearchResultsLoaderRowWrapper,
  SearchResultsLoaderSkeleton,
} from './styles';

const SearchResultsLoader = () => {
  const { isMobile } = useWindowResize();
  const surfaceCount = isMobile ? 2 : 3;

  return (
    <div id='search-results-loader'>
      {[...Array(surfaceCount)].map((_, index) => (
        <SearchResultsLoaderItem key={`surface_${index}`}>
          <SearchResultsLoaderIconSkeleton borderRadius='0' />
          <SearchResultsLoaderRowWrapper>
            {[...Array(2)].map((_, index) => (
              <SearchResultsLoaderRow key={`empty_row_${index}`}>
                <SearchResultsLoaderSkeleton borderRadius='0' />
              </SearchResultsLoaderRow>
            ))}
          </SearchResultsLoaderRowWrapper>
        </SearchResultsLoaderItem>
      ))}
    </div>
  );
};

export default SearchResultsLoader;
