import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import { Emoji } from '../../../../../types/Emoji';
import Icon from '../../../../design_system/display/icons/Icon/Icon';
import { IconWeight } from '../../../../design_system/display/icons/Icon/IconTypes';
import { fontSm5 } from '../../../../styled/TypeSystem';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 1rem;
`;

const Emoji = styled.span`
  ${fontSm5};
`;

// DS Override: Update icon color to match designs
const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

type Props = {
  emoji: Emoji;
  noEmojiIconName: IconName;
  noEmojiIconWeight?: IconWeight;
};

const SubjectEmoji = ({ emoji, noEmojiIconName, noEmojiIconWeight }: Props) => {
  return (
    <Wrapper>
      {emoji ? (
        <Emoji>{emoji}</Emoji>
      ) : (
        <StyledIcon name={noEmojiIconName} size='xs' weight={noEmojiIconWeight} />
      )}
    </Wrapper>
  );
};

export default SubjectEmoji;
