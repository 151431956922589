import { FormData } from '../../components/application/shared/UpdatePaymentMethodForm/types';
import { ValidateFieldsHandler } from '../../hooks/billing/useValidateUpdatePaymentMethodForm/types';

type CheckRequiredFieldsParams = {
  formData: FormData;
  validateFields: ValidateFieldsHandler;
  withStateUpdate?: boolean;
};

export const checkRequiredPaymentFields = ({
  formData,
  validateFields,
  withStateUpdate,
}: CheckRequiredFieldsParams) => {
  const { name, addressLine1, addressCity, addressZip, addressCountry, addressState } = formData;
  const fieldsToValidate = {
    name,
    addressLine1,
    addressCity,
    addressZip,
    addressCountry,
    addressState,
  };

  return validateFields({ fieldsToValidate, withStateUpdate });
};
