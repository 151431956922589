import initTranslations from '../../../../../lib/initTranslations';
import { readFile } from '../../../../../lib/readFile';
import {
  MAX_FILE_SIZE_MB,
  SUPPORTED_FILE_TYPES,
  SUPPORTED_PDF_FILE_TYPES,
} from '../../shared/constants/editor';
import { EmbedlyMenuItemSource } from '../Embedly/EmbedlyMenu/EmbedlyMenu';

const t = initTranslations('editor.file');

type ProcessFileProps = {
  selectedEmbedMenuItem: EmbedlyMenuItemSource | undefined;
  file: File;
  setFile: (file: File) => void;
  setFileError: (error: string) => void;
};

export const processFile = async ({
  file,
  setFileError,
  setFile,
  selectedEmbedMenuItem,
}: ProcessFileProps) => {
  const fileSize = file.size / 1024 / 1024;

  if (fileSize >= MAX_FILE_SIZE_MB) {
    setFileError(t('error.file_size', { fileSizeMb: MAX_FILE_SIZE_MB }));
  } else if (selectedEmbedMenuItem === 'file' && !SUPPORTED_PDF_FILE_TYPES.includes(file.type)) {
    setFileError(t('error.file_type_pdf'));
  } else if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
    setFileError(t('error.file_type'));
  } else {
    setFileError('');

    const newFileName = file.name.replace(/ /g, '_');
    const newFile = new File([file], newFileName, { type: file.type });

    setFile(newFile);
  }
};

export const setFormData = async (file: File) => {
  const fileDataUrl = (await readFile(file)) as string;
  const blob = await (await fetch(fileDataUrl)).blob();
  const fileParam = new File([blob], file.name, { type: file.type });
  const formData = new FormData();
  formData.append('file', fileParam);

  return formData;
};
