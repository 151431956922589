import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useKeyPress from '../../../../hooks/useKeyPress';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import initTranslations from '../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../redux/domains/modalsSlice/modalsSlice';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import CoreModal from '../../../design_system/core/CoreModal';
import Badge from '../../../design_system/display/badge';
import ConfirmationModal from '../../../design_system/overlays/modals/ConfirmationModal';
import { ModalSubtitle, ModalTitle } from '../../../styled/Modals';
import BeforeYouGetStarted from './BeforeYouGetStarted';
import FailedImport from './FailedImport';
import ImportTeammates from './ImportTeammates';
import RanOutOfSeats from './RanOutOfSeats';
import { Result } from './ResultTypes';
import SuccessImport from './SuccessImport';

const SubtitleButton = styled.span`
  text-decoration: underline;
  color: ${(props) => props.theme.vars.accentPrimaryDefault};
  cursor: pointer;
`;

const StyleImportTeammatesTitle = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .title-text {
    margin-right: 0.5rem;
  }
`;

const StyleBadge = styled(Badge)`
  margin: 0.25rem 0;
`;

interface Props {
  seatsRemaining: string;
  seatsUnlimited: boolean;
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  setImportJobIds: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const BulkUserUploadModal = (props: Props) => {
  const { permission } = useCurrentUser();
  const { nonBilling, status } = useCurrentAccount();
  const isBillingAdmin = permission === 'billing_admin';
  const showUpdateCardButton = isBillingAdmin && status === 'past_due';
  const showUpdatePlanButton = isBillingAdmin && !nonBilling && !showUpdateCardButton;
  const [showBulkUserUploadModal, setShowBulkUserUploadModal] = useState('');
  const [lastBulkUserUploadModal, setLastBulkUserUploadModal] = useState('');
  const { seatsRemaining, seatsUnlimited, modalVisible, setModalVisible, setImportJobIds } = props;
  const [uploadFileData, setUploadFileData] = useState(new FormData());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const dispatchShowModal = useDispatchSetShowModal();
  const [data, setData] = useState<Result>({
    import_status: '',
    failed: {
      amount: '',
      results_file: {
        xlsx: {
          name: '',
          path: '',
        },
        csv: {
          path: '',
        },
      },
    },
    success: {
      amount: '',
      seats_remaining: '',
      ran_out_of_seats: {
        failed: '',
        success: '',
        show_success_message: true,
        results_file: {
          xlsx: {
            name: '',
            path: '',
          },
          csv: {
            path: '',
          },
        },
      },
      need_to_update_plan: false,
      people: [],
    },
  });

  const t = initTranslations(`bulk_user_upload_modal.${showBulkUserUploadModal}`);
  const tBasic = initTranslations('bulk_user_upload_modal');
  const modalRef = useRef(null);
  const { productTerm } = useAccountTerminology();

  const setBulkUploadStep = (step: string) => {
    setShowBulkUserUploadModal(step);
    setLastBulkUserUploadModal(step);
  };

  useEffect(() => {
    setBulkUploadStep(modalVisible ? 'before_you_get_started' : '');
  }, [modalVisible]);

  let failedModalTitle: string;

  if (data.import_status === 'partially_failed') {
    failedModalTitle = t('title.partially_failed');
  } else {
    failedModalTitle = t('title.failed');
  }

  const closeModal = () => {
    ['partially_failed', 'success'].includes(data.import_status) &&
      ['failed_import', 'success_import'].includes(showBulkUserUploadModal) &&
      setShowConfirmationModal(true);
    setShowBulkUserUploadModal('');
    setModalVisible(false);
  };

  const redirectToBillingOverlay = () => {
    closeModal();
    navigateToBillingOverlay();
  };

  const renderBeforeYouGetStartedContent = () => {
    setBulkUploadStep('before_you_get_started');
  };

  const renderImportTeammatesContent = () => {
    setBulkUploadStep('import_teammates');
  };

  const renderResultContent = (status: string) => {
    status === 'success' ? setBulkUploadStep('success_import') : setBulkUploadStep('failed_import');
  };

  const renderSuccessContent = () => {
    setBulkUploadStep('success_import');
  };

  const renderRanOutOfSeats = () => {
    setBulkUploadStep('ran_out_of_seats');
  };

  const openUpdateCardModal = () => {
    closeModal();
    dispatchShowModal('updatePaymentMethod', true);
  };

  const getRanOutOfSeatsSubtitle = () => {
    const inviteTranslation = t('subtitle.to_invite_your_whole_team');

    if (showUpdatePlanButton) {
      return (
        <>
          <SubtitleButton onClick={redirectToBillingOverlay}>
            {t('subtitle.upgrade_now')}
          </SubtitleButton>{' '}
          {inviteTranslation}
        </>
      );
    }

    if (showUpdateCardButton) {
      return (
        <>
          <SubtitleButton onClick={openUpdateCardModal}>{t('subtitle.update_card')}</SubtitleButton>{' '}
          {inviteTranslation}
        </>
      );
    }

    return t('subtitle.contact_your_administrator');
  };

  const importTeammatesTitle = (
    <StyleImportTeammatesTitle>
      <span className='title-text'>{t('title')}</span>
      {seatsUnlimited ? (
        <StyleBadge size='md' text={t('seats_remaining.unlimited')} type='general' />
      ) : (
        <StyleBadge
          size='md'
          text={t('seats_remaining', { count: seatsRemaining })}
          type='general'
        />
      )}
    </StyleImportTeammatesTitle>
  );

  const currentModalContent = () => {
    switch (showBulkUserUploadModal) {
      case 'before_you_get_started':
        return (
          <BeforeYouGetStarted
            closeModal={closeModal}
            renderImportTeammatesContent={renderImportTeammatesContent}
          />
        );
      case 'import_teammates':
        return (
          <ImportTeammates
            closeModal={closeModal}
            renderBeforeYouGetStartedContent={renderBeforeYouGetStartedContent}
            renderRanOutOfSeats={renderRanOutOfSeats}
            renderResultContent={renderResultContent}
            setData={setData}
            setUploadFileData={setUploadFileData}
          />
        );
      case 'failed_import':
        return (
          <FailedImport
            closeModal={closeModal}
            data={data}
            isPartiallyFailedModal={data.import_status === 'partially_failed'}
            renderImportTeammatesContent={renderImportTeammatesContent}
            renderSuccessContent={renderSuccessContent}
          />
        );
      case 'success_import':
        return (
          <SuccessImport
            closeModal={closeModal}
            data={data.success}
            setImportJobIds={setImportJobIds}
            uploadFileData={uploadFileData}
          />
        );
      case 'ran_out_of_seats':
        return (
          <RanOutOfSeats
            closeModal={closeModal}
            data={data.success}
            renderSuccessContent={renderSuccessContent}
            showUpdatePlanModal={redirectToBillingOverlay}
          />
        );
    }
  };

  const modalTitle = () => {
    switch (showBulkUserUploadModal) {
      case 'import_teammates':
        return importTeammatesTitle;
      case 'failed_import':
        return failedModalTitle;
      default:
        return t('title');
    }
  };

  const openPreviousModal = () => {
    setShowConfirmationModal(false);
    lastBulkUserUploadModal === 'failed_import' && setShowBulkUserUploadModal('failed_import');
    lastBulkUserUploadModal === 'success_import' && setShowBulkUserUploadModal('success_import');
  };

  useKeyPress('Escape', closeModal);

  return (
    <div id='bulk-user-upload-modal' ref={modalRef}>
      {showBulkUserUploadModal && (
        <CoreModal
          closeIconAriaLabel={tBasic('aria_label_cancel')}
          desktopSize='xl'
          heapModalName='bulk-user-upload-modal'
          onCloseRequest={closeModal}
        >
          <ModalTitle>{modalTitle()}</ModalTitle>
          <ModalSubtitle>
            {showBulkUserUploadModal !== 'ran_out_of_seats'
              ? t('subtitle', { productTerm })
              : getRanOutOfSeatsSubtitle()}
          </ModalSubtitle>
          {currentModalContent()}
        </CoreModal>
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          actionFunction={() => setShowConfirmationModal(false)}
          actionText={tBasic('confirmation_modal.leave')}
          heapModalName='cancel-bulk-upload-modal'
          message={tBasic('confirmation_modal.message', { productTerm })}
          onCloseRequest={openPreviousModal}
          processing={false}
          secondaryButtonText={tBasic('confirmation_modal.go_back')}
          title={tBasic('confirmation_modal.leave_upload_process', { productTerm })}
        />
      )}
    </div>
  );
};

export default BulkUserUploadModal;
