import {
  ContentAccess,
  ESignature,
  GeneralAccess,
  Kind,
  Status,
} from '../../../../../redux/domains/curriculums/curriculumsSlice';
import { BaseOption } from '../../../../design_system/core/SelectOption/types';
import { initialContentState } from './ContentLibraryFilterSlideout';

type ContentState = {
  groupIds: string[] | null;
  roleIds: string[] | null;
  teamIds: string[] | null;
  userId: string | null;
  access: ContentAccess;
  generalAccess: GeneralAccess;
  status: Status;
  kind: Kind;
  userOptions: BaseOption[];
  eSignature: ESignature;
};

type ContentAction =
  | { type: 'setGroupIds'; groupIds: string[] | null }
  | { type: 'setRoleIds'; roleIds: string[] | null }
  | { type: 'setTeamIds'; teamIds: string[] | null }
  | { type: 'setOwner'; userId: string | null }
  | { type: 'setAccess'; access: ContentAccess }
  | { type: 'setStatus'; status: Status }
  | { type: 'setType'; kind: Kind }
  | { type: 'setGeneralAccess'; generalAccess: GeneralAccess }
  | { type: 'setESignature'; eSignature: ESignature }
  | { type: 'resetFilters' };

export const contentReducer = (state: ContentState, action: ContentAction): ContentState => {
  switch (action.type) {
    case 'setGroupIds': {
      return { ...state, groupIds: action.groupIds };
    }
    case 'setRoleIds': {
      return { ...state, roleIds: action.roleIds };
    }
    case 'setTeamIds': {
      return { ...state, teamIds: action.teamIds };
    }
    case 'setOwner': {
      return { ...state, userId: action.userId };
    }
    case 'setAccess': {
      return { ...state, access: action.access };
    }
    case 'setStatus': {
      return { ...state, status: action.status };
    }
    case 'setType': {
      return { ...state, kind: action.kind };
    }
    case 'setGeneralAccess': {
      return { ...state, generalAccess: action.generalAccess };
    }
    case 'setESignature': {
      return { ...state, eSignature: action.eSignature };
    }
    case 'resetFilters': {
      return initialContentState;
    }
  }
};
