import React from 'react';

import { useDiscountsLabel } from '../../../../hooks/billing/useDiscountsLabel';
import DiscountPigSvg from '../../../../images/billing/discount_pig.svg';
import ManagePlanDiscountPigSvg from '../../../../images/billing/manage_plan_discount_pig.svg';
import { couponToDiscount } from '../../../../lib/billing/stripeCouponToDiscount';
import initTranslations from '../../../../lib/initTranslations';
import { BillingPlanName } from '../../../../types/BillingPlanName';
import { Coupon } from '../../../../types/Coupon';
import {
  DiscountDetails,
  DiscountsCouponCode,
  DiscountsImage,
  DiscountsListWrapper,
  DiscountsSectionWrapper,
} from './styles';
import YearlyBillingPerk from './YearlyBillingPerk/YearlyBillingPerk';

type DiscountsSectionProps = {
  coupon: Coupon;
  showYearlyBillingPerk: boolean;
  isManagePlanOverlayContent?: boolean;
  productName: BillingPlanName;
};

const t = initTranslations('discounts_section');

const DiscountsSection = ({
  coupon,
  showYearlyBillingPerk,
  isManagePlanOverlayContent = false,
  productName,
}: DiscountsSectionProps) => {
  const discountImageSrc = isManagePlanOverlayContent ? ManagePlanDiscountPigSvg : DiscountPigSvg;
  const discount = couponToDiscount(coupon);

  const discountLabel = useDiscountsLabel(discount);

  return (
    <DiscountsSectionWrapper
      id='discounts-section'
      isManagePlanOverlayContent={isManagePlanOverlayContent}
    >
      <DiscountsImage
        isManagePlanOverlayContent={isManagePlanOverlayContent}
        src={discountImageSrc}
      />
      <DiscountsListWrapper isManagePlanOverlayContent={isManagePlanOverlayContent}>
        {coupon && (
          <>
            <DiscountDetails
              dangerouslySetInnerHTML={{
                __html: t('coupon_code', {
                  details: `${discountLabel} ${coupon.duration}`,
                }),
              }}
            />
            <DiscountsCouponCode>{t('code', { code: coupon.name })}</DiscountsCouponCode>
          </>
        )}
        {showYearlyBillingPerk && (
          <YearlyBillingPerk
            isManagePlanOverlayContent={isManagePlanOverlayContent}
            productName={productName}
          />
        )}
      </DiscountsListWrapper>
    </DiscountsSectionWrapper>
  );
};

export default DiscountsSection;
