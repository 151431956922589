import React from 'react';
import styled from 'styled-components';

import ProgressBar from '../../../../../design_system/Triage/ProgressBar';
import { fontSm4 } from '../../../../../styled/TypeSystem';

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const ProgressPercentage = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontSm4};
`;

export type Props = {
  id: string;
  percentage: number;
};

// Created a custom component because the ProgressBar component had a labelText prop
// that would have to be updated throughout the app
const ConsumptionProgressBar = ({ id, percentage }: Props) => {
  return (
    <ProgressBarContainer>
      <ProgressBarWrapper>
        <ProgressBar id={id} percent={percentage} thickness='md' />
      </ProgressBarWrapper>
      <ProgressPercentage>{percentage}%</ProgressPercentage>
    </ProgressBarContainer>
  );
};

export default ConsumptionProgressBar;
