import React, { FC } from 'react';
import { Route } from 'type-route';

import initTranslations from '../../../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { useDeleteBoardColumnResponsibilityMutation } from '../../../../../../../redux/services/resourceApis/boardColumnResponsibilities/boardColumnResponsibilitiesApi';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import Icon from '../../../../../../design_system/display/icons/Icon';
import { routes, useRoute } from '../../../../../publicApplication/applicationRouter';
import useAutoSaveStatusUpdater from '../../../../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import MoveResponsibilityFormPopup from '../../../../MoveResponsibilityFormPopup/MoveResponsibilityFormPopup';
import { SectionItemContainer, SectionItemTitle } from '../../styles';
import { MenuItemContainer } from './styles';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.options');

const OptionsMenu: FC = () => {
  const {
    params: { id: boardId },
  } = useRoute() as Route<typeof routes.delegationPlannerBoard>;
  const dispatchShowModal = useDispatchSetShowModal();
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();
  const { currentBoardColumnResponsibility, currentColumnId } = useAppSelector(
    (state) => state.delegationPlanner
  );

  const [deleteBoardColumnResponsibility, deleteBoardColumnResponsibilityResult] =
    useDeleteBoardColumnResponsibilityMutation();
  const { isLoading, isSuccess } = deleteBoardColumnResponsibilityResult;

  const handleDeleteBoardColumnResponsibility = async () => {
    await deleteBoardColumnResponsibility({
      boardId,
      boardColumnId: currentColumnId,
      id: currentBoardColumnResponsibility?.id,
    });
    dispatchShowModal('responsibilityDetailsModal', false);
  };

  useAutoSaveStatusUpdater([
    {
      isSaving: isLoading,
      isSavedSuccessfully: isSuccess,
    },
  ]);

  return (
    <SectionItemContainer>
      <SectionItemTitle>{t('title')}</SectionItemTitle>
      <MoveResponsibilityFormPopup
        id='delegation-planner-move-responsibility-form-popup'
        menuId='delegation-planner-move-responsibility-popup'
        trigger={
          <MenuItemContainer id='move-responsibility-option'>
            <Icon name='arrow-right' size='xs' />
            {t('delegate', { responsibility: responsibilitySingular.toLowerCase() })}
          </MenuItemContainer>
        }
      />

      <MenuItemContainer
        id='remove-responsibility-option'
        onClick={handleDeleteBoardColumnResponsibility}
      >
        <Icon name='trash-can' size='xs' />
        {t('remove', { responsibility: responsibilitySingular.toLowerCase() })}
      </MenuItemContainer>
    </SectionItemContainer>
  );
};

export default OptionsMenu;
