import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { routes } from '../../../../publicApplication/applicationRouter';
import { NotificationLink } from '../../styles';

const t = initTranslations('notifications');

export interface ESignaturesUsageTemplateProps {
  activityKey: string;
}

const ESignaturesUsageTemplate = ({ activityKey }: ESignaturesUsageTemplateProps) => {
  const { slug } = useCurrentAccount();

  return (
    <>
      {t(activityKey)}
      <NotificationLink to={routes.billing({ slug })}>
        {t('account.e_signatures.billing_plans_page')}
      </NotificationLink>
      {t('account.e_signatures.for_more_details')}
    </>
  );
};

export default ESignaturesUsageTemplate;
