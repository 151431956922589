import { trainualApi } from '../../trainualService';
import { assignUsersListTags } from '../curriculums/helpers';
import { ModalDataByCurriculum, ModalDataByStep } from './types';

const shareChangesApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    modalDataByStep: builder.query<ModalDataByStep, number | undefined>({
      query: (id: number) => ({ url: `ajax/fetch_modal_data_by_step/${id}` }),
      providesTags: (result) => {
        return result ? [{ type: 'Step', id: result.step.id }] : [];
      },
    }),
    modalDataByCurriculum: builder.query<ModalDataByCurriculum, number>({
      query: (id) => ({ url: `ajax/fetch_modal_data_by_curriculum/${id}` }),
      providesTags: (result, errors, id) => assignUsersListTags(id),
    }),
  }),
});

export const { useModalDataByStepQuery, useModalDataByCurriculumQuery } = shareChangesApi;
