import { PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';

import isTouchDevice from '../../../../../lib/isTouchDevice';
import { BOARD_POINTER_SENSOR_OPTIONS, BOARD_TOUCH_SENSOR_OPTIONS } from '../_data';

export const useBoardSensors = () => {
  const touchSensor = useSensor(TouchSensor, BOARD_TOUCH_SENSOR_OPTIONS);
  const pointerSensor = useSensor(PointerSensor, BOARD_POINTER_SENSOR_OPTIONS);

  const detectSensor = () => (isTouchDevice() ? touchSensor : pointerSensor);

  return useSensors(detectSensor());
};
