import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAdminUpdateCurriculumMutation } from '../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CURRICULUM_DESCRIPTION_MAX_CHARACTERS } from '../../../../../../types/Curriculum';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import ClickInlineTextarea from '../../../../../design_system/Triage/ClickInlineTextarea/ClickInlineTextarea';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import ComposeButton from '../../../../ai/ComposeButton/ComposeButton';

const Wrapper = styled.div<{ locked: boolean }>`
  /* The Negative 1rem - border width margin is added to align inner text with the rest of the 
    TopSurface content rather than hidden input border */
  margin-left: ${({ theme: { constants } }) => `calc(-${constants.spacerSm3} + 1px)`};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  max-width: ${(props) => (props.locked ? '100%' : 'max-content')};
`;

const ComposeButtonWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const StyledDefaultButton = styled(DefaultButton)<{ visible: boolean }>`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  cursor: ${({ visible }) => (visible ? 'pointer' : 'default')};
`;

const DescriptionActionsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

const composeButton = (onClick?: () => void) => {
  return (
    <StyledDefaultButton
      buttonType='secondary'
      iconName='wand-magic-sparkles'
      id='compose-subject-ai-description'
      onClick={onClick}
      text={t('auto_compose')}
      visible={!!onClick}
    />
  );
};

type Props = {
  description: string | undefined;
  id: number;
  isLocked?: boolean;
};

const t = initTranslations('curriculums.description');

const Description = ({ description, id, isLocked }: Props) => {
  const { flash } = useFlashNotification();
  const [update, result] = useAdminUpdateCurriculumMutation();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const {
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();

  useEffect(() => {
    if (description && description.length > CURRICULUM_DESCRIPTION_MAX_CHARACTERS) {
      flash('error', t('description_too_long', { count: CURRICULUM_DESCRIPTION_MAX_CHARACTERS }));
    }
  }, [description, flash]);

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
    errorMessage: t('error_message'),
  });

  const setDescription = useCallback(
    (newDesc: string) => {
      update({ description: newDesc, id });
    },
    [id, update]
  );

  if (isLocked) {
    if (description) {
      return (
        <Wrapper id='locked-description' locked={!isLocked}>
          <Tooltip id='locked-description-tooltip' place='top' text={t('locked_tooltip')} />
          <p data-for='locked-description-tooltip' data-tip>
            {description}
          </p>
        </Wrapper>
      );
    } else {
      return null;
    }
  } else {
    return (
      <Wrapper id='editable-description' locked={!isLocked}>
        <DescriptionActionsWrapper>
          <ClickInlineTextarea
            attributeName='description'
            initialValue={description}
            inputFontSize='sm'
            maxCharacters={CURRICULUM_DESCRIPTION_MAX_CHARACTERS}
            onBlur={(e) => {
              if (e?.relatedTarget?.id !== 'compose-subject-ai-description') {
                setIsInputFocused(false);
                return;
              }
              const timer = setTimeout(() => setIsInputFocused(false), 300);

              return () => {
                clearTimeout(timer);
              };
            }}
            onFocus={() => setIsInputFocused(true)}
            placeholder={t('placeholder', { subject: curriculumSingular })}
            resourceName={curriculumSingular}
            submitChanges={setDescription}
          />
          <ComposeButtonWrapper>
            <ComposeButton
              background='gray'
              composeModalType='subjectDescription'
              customButton={composeButton}
              metadata={{ curriculumId: id }}
              showButton={isInputFocused}
              text={t('auto_compose')}
            />
          </ComposeButtonWrapper>
        </DescriptionActionsWrapper>
      </Wrapper>
    );
  }
};

export default Description;
