import React from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import Link from '../../../../design_system/Link';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { routes } from '../../../publicApplication/applicationRouter';
import RouterLink from '../../../publicApplication/RouterLink';

const DetailsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  align-items: center;
`;

const Percentage = styled.p`
  margin: 0;
  width: ${({ theme: { constants } }) => constants.spacerLg1};
  text-align: right;
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const PercentageBar = styled.div`
  display: none;

  @media (min-width: ${mediaBreakpointPxMd}) {
    display: initial;
    height: 6px;
    width: 5rem;
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  }
`;

const PercentComplete = styled.div<{ percent: number }>`
  height: 6px;
  width: ${({ percent }) => `${(percent / 100) * 5}rem`};
  background-color: ${({ theme: { vars } }) => vars.stateSuccess};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
`;

const LinkWrapper = styled.div`
  display: flex;
  // DS Override: Need to match design text-decoration
  a {
    text-decoration: none;
  }
`;

interface Props extends DetailsProps {
  to: Route<typeof routes>;
}

interface DetailsProps {
  className: string;
  percent: number;
}

const Details = ({ className, percent }: DetailsProps) => (
  <DetailsWrapper className={className}>
    <Percentage>{percent}%</Percentage>
    <PercentageBar>
      <PercentComplete percent={percent} />
    </PercentageBar>
  </DetailsWrapper>
);

const CompletionDetails = ({ className, percent, to }: Props) => {
  const useRouter = !!to && !window.Turbolinks;
  if (percent > 0) {
    return (
      <LinkWrapper>
        {useRouter ? (
          <RouterLink to={to}>
            <Details className={className} percent={percent} />
          </RouterLink>
        ) : (
          <Link href={to.href} text={<Details className={className} percent={percent} />} />
        )}
      </LinkWrapper>
    );
  } else {
    return <Details className={className} percent={percent} />;
  }
};

export default CompletionDetails;
