import React, { FC, MouseEventHandler } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import noResultsImage from '../../../../../images/empty_state/content-flyout-no-results-yet.svg';
import noSearchResultsImage from '../../../../../images/empty_state/content-flyout-no-search-results.svg';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../publicApplication/applicationRouter';
import { EmptyStateContainer, EmptyStateImage, EmptyStateTitle } from './styles';
import { EmptyStateProps } from './types';

const t = initTranslations('content_flyout.empty_state');

const EmptyState: FC<EmptyStateProps> = ({ isSearchEmptyState, currentSearchTerm }) => {
  const { slug } = useCurrentAccount();

  const handleCreateContentClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    window.open(routes.content({ slug }).href, '_blank', 'noopener,noreferrer');
  };

  return (
    <EmptyStateContainer>
      <EmptyStateImage src={isSearchEmptyState ? noSearchResultsImage : noResultsImage} />
      <EmptyStateTitle>
        {isSearchEmptyState ? t('search_title', { search_term: currentSearchTerm }) : t('title')}
      </EmptyStateTitle>
      {!isSearchEmptyState && (
        <DefaultButton
          buttonType='tertiary'
          id='content-flyout-create-content-button'
          onClick={handleCreateContentClick}
          text={t('create_content')}
        />
      )}
    </EmptyStateContainer>
  );
};

export default EmptyState;
