import { FormikErrors, useFormik } from 'formik';
import React from 'react';
import { Route } from 'type-route';

import useWindowResize from '../../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateSurveyQuestionMutation } from '../../../../../../redux/services/resourceApis/surveyQuestions/surveyQuestionsApi';
import InputField from '../../../../../design_system/Triage/InputField';
import RadioField from '../../../../../design_system/Triage/RadioField';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import { ResponsePlaceholderText } from '../../../shared/styles';
import { WordCountInputWrapper, WordCountRadioButtonsWrapper } from './styles';
import { FormValues, WrittenResponseFormProps } from './types';

const t = initTranslations('survey_edit.question.written_response_form');

const WrittenResponseForm = ({ wordCount }: WrittenResponseFormProps) => {
  const route = useRoute();
  const {
    params: { id, surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;
  const { isTablet, isMobile } = useWindowResize();
  const isSmallScreen = isTablet || isMobile;
  const [updateSurveyQuestion] = useUpdateSurveyQuestionMutation();

  const validateForm = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (values.wordCountLimit === 'withLimit' && values.wordcount < 1) {
      errors.wordcount = t('errors.value_less_than_min');
    }

    return errors;
  };

  const handleFormSubmit = (values: FormValues) => {
    if (wordCount !== values.wordcount) {
      updateSurveyQuestion({ id, surveyId, wordcount: values.wordcount });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      wordcount: wordCount || 1,
      wordCountLimit: !!wordCount ? 'withLimit' : 'noLimit',
    },
    validate: validateForm,
    onSubmit: handleFormSubmit,
  });

  const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWordCountLimit = e.target.value === 'withLimit' ? formik.values.wordcount : null;

    updateSurveyQuestion({ id, surveyId, wordcount: newWordCountLimit });
  };

  const handleEditorKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  return (
    <>
      <ResponsePlaceholderText>{t('title')}</ResponsePlaceholderText>
      <WordCountInputWrapper>
        <WordCountRadioButtonsWrapper>
          <RadioField
            isCustomRadioField
            name='wordCountLimit'
            onChange={handleRadioButtonChange}
            options={[
              { text: t('no_minimum_word_count'), value: 'noLimit' },
              { text: t('require_minimum_word_count'), value: 'withLimit' },
            ]}
            orientation={isSmallScreen ? 'vertical' : 'horizontal'}
            value={formik.values.wordCountLimit}
          />
        </WordCountRadioButtonsWrapper>

        {formik.values.wordCountLimit === 'withLimit' && (
          <InputField
            errorText={formik.touched.wordcount && formik.errors.wordcount}
            min={1}
            name='wordcount'
            onBlur={() => formik.handleSubmit()}
            onChange={formik.handleChange}
            onKeyDown={handleEditorKeydown}
            type='number'
            value={formik.values.wordcount}
          />
        )}
      </WordCountInputWrapper>
    </>
  );
};

export default WrittenResponseForm;
