import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useAnalyticsUserEvents from '../../../hooks/useAnalyticsUserEvents';
import useCurrentAccount from '../../../hooks/useCurrentAccount';
import usePageTitle from '../../../hooks/usePageTitle';
import usePublicConfigs from '../../../hooks/usePublicConfigs';
import initTranslations from '../../../lib/initTranslations';
import { useGetBoardsListQuery } from '../../../redux/services/resourceApis/boards/boardsApi';
import VideoEmptyStatePreviewSrc from '../../../videos/delegation-planner-explanation-video-preview.mp4';
import { useAccountTerminology } from '../../AccountTerminologyProvider';
import { routes } from '../publicApplication/applicationRouter';
import useLocalStorageState from '../publicApplication/utils/useLocalStorageState';
import PageContent from '../shared/page_content';
import VideoEmptyState from '../shared/VideoEmptyState/VideoEmptyState';
import TrainualPlusDelegationPlannerBanner from './banners/TrainualPlusDelegationPlannerBanner/TrainualPlusDelegationPlannerBanner';
import BoardManageModal from './modals/BoardManageModal/BoardManageModal';
import { TrainualPlusBannerWrapper } from './styles';

const t = initTranslations('delegation_planner.page_empty_state');

const DelegationPlannerPage = () => {
  const { id: accountId } = useCurrentAccount();
  const { cdpEmptyStateVideoClicked } = useAnalyticsUserEvents();
  const [showBoardManageModal, setShowBoardManageModal] = useState(false);
  const { data: boardsResponse, isFetching } = useGetBoardsListQuery();
  const { responsibility, productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'delegation_planner', productTerm });
  const lowercaseResponsibilitiesTerm = responsibility.plural.toLowerCase();
  const { configs } = usePublicConfigs();
  const account = useCurrentAccount();
  const { delegationPlannerEnabled, slug } = account;

  const boards = useMemo(() => boardsResponse?.boards ?? [], [boardsResponse]);

  const [currentDelegationBoardId] = useLocalStorageState({
    key: `currentDelegationBoardId_${accountId}`,
    initialValue: 0,
  });

  const handleCreateBoard = useCallback(() => {
    setShowBoardManageModal(true);
  }, []);

  const handleVideoEmptyStateAnalytics = () => {
    cdpEmptyStateVideoClicked({ action: 'played', video_type: 'delegation-planner-tutorial' });
  };

  const redirectToCurrentBoard = useCallback(() => {
    const targetBoardId = boards.some((board) => board.id === currentDelegationBoardId)
      ? currentDelegationBoardId
      : boards[0].id;

    routes.delegationPlannerBoard({ slug, id: targetBoardId }).replace();
  }, [boards, slug, currentDelegationBoardId]);

  useEffect(() => {
    if (delegationPlannerEnabled && !isFetching && boards.length > 0) {
      redirectToCurrentBoard();
    }
  }, [boards.length, delegationPlannerEnabled, isFetching, redirectToCurrentBoard]);

  if (!delegationPlannerEnabled) return null;

  if (!isFetching && boards.length === 0) {
    return (
      <>
        <PageContent id='delegation-planner-page'>
          <VideoEmptyState
            actionButtonFunction={handleCreateBoard}
            actionButtonText={t('start_planning')}
            actionDescription={t('description', {
              responsibilities: lowercaseResponsibilitiesTerm,
            })}
            actionTitle={t('subtitle_html')}
            openPlayerCallback={handleVideoEmptyStateAnalytics}
            showWatchSvg
            title={t('title')}
            videoPreviewSrc={VideoEmptyStatePreviewSrc}
            videoUrl={configs['DELEGATION_PLANNER_EXPLAIN_AND_INSPIRE_VIDEO_URL']}
          />
          <TrainualPlusBannerWrapper>
            <TrainualPlusDelegationPlannerBanner />
          </TrainualPlusBannerWrapper>
        </PageContent>
        {showBoardManageModal && (
          <BoardManageModal
            mode='add'
            setShowModal={setShowBoardManageModal}
            showModal={showBoardManageModal}
          />
        )}
      </>
    );
  }

  return <></>;
};

export default DelegationPlannerPage;
