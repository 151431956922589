import { BillingDetails } from '../../types/BillingDetails';
import { CombinedBillingDetails } from '../../types/CombinedBillingDetails';

type KeysType = keyof CombinedBillingDetails;

export function parseBillingDetailsResponse(details: BillingDetails): CombinedBillingDetails {
  const billingDetails = { ...details.cardDetails, ...details.billingAddressDetails };
  const result = {} as CombinedBillingDetails;

  Object.keys(billingDetails).forEach((key: KeysType) => {
    const value = billingDetails[key];

    if (value) {
      result[key] = value;
    }
  });

  return result;
}
