import { darken } from 'polished';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from 'styled-components';

import FieldLabel from '../../../../../design_system/core/FieldLabel';
import {
  MultiSelectStyles,
  SelectProp,
} from '../../../../../design_system/Triage/fields/MultiSelectField/MultiSelectField';
import { CustomStyle } from '../../../../../design_system/Triage/fields/SharedSelect/Styles';
import { CreatableOption, CreatableSelectFieldProps } from './types';

const formatCreatableOptions = (value: string[], invalidValues: string[] = []) => {
  return value.map((value) => ({
    label: value,
    value,
    isInvalid: invalidValues.includes(value),
  }));
};

const CreatableSelectField = (props: CreatableSelectFieldProps) => {
  const {
    autoFocus,
    onValueChanged,
    fieldLabelText,
    placeholder,
    className = '',
    isClearable = false,
    isMenuOpenedOnClick = false,
    isMenuOpenedOnFocus = false,
    isMulti = true,
    isValid = true,
    invalidValues,
    isValidNewOption,
    size = 'md',
    name,
    selectedValue,
  } = props;

  const CreatableSelectStyles = {
    multiValue: (
      provided: object,
      { data: { isInvalid }, selectProps: { constants, vars } }: SelectProp
    ) => {
      return {
        ...provided,
        backgroundColor: isInvalid ? vars.stateError : vars.accentPrimaryDefault,
        color: vars.textSurface,
        marginLeft: 0,
        marginRight: constants.spacerSm2,
      };
    },

    multiValueRemove: (
      provided: object,
      { isFocused, data: { isInvalid }, selectProps: { vars } }: SelectProp
    ) => {
      const validationColor = isInvalid ? vars.stateError : vars.accentPrimaryDefault;
      return {
        ...provided,
        background: isFocused
          ? darken(0.1, validationColor || vars.trainualBrandPurpleMedium)
          : validationColor,
        cursor: 'pointer',
        ':hover': {
          backgroundColor: darken(0.1, validationColor || vars.trainualBrandPurpleMedium),
          color: vars.textSurface,
        },
      };
    },
  };

  const theme = useTheme();
  const styles = { ...CustomStyle, ...MultiSelectStyles, ...CreatableSelectStyles };
  const initialCreatableOptions = formatCreatableOptions(selectedValue || []);
  const [creatableOptions, setCreatableOptions] =
    useState<CreatableOption[]>(initialCreatableOptions);

  const handleValueChanged = (options: CreatableOption[] | null) => {
    if (!options || options?.length === 0) {
      onValueChanged && onValueChanged([]);
      return;
    }

    const values = options.map((option) => option.value);

    onValueChanged && onValueChanged(values);
  };

  useEffect(() => {
    setCreatableOptions(formatCreatableOptions(selectedValue || [], invalidValues));
  }, [invalidValues, selectedValue]);

  const CreatableSelectProps: object = {
    autoFocus,
    className,
    classNamePrefix: 'react-select',
    constants: theme.constants,
    isClearable,
    isMulti,
    isValid,
    isValidNewOption,
    name,
    onChange: handleValueChanged,
    openMenuOnClick: isMenuOpenedOnClick,
    openMenuOnFocus: isMenuOpenedOnFocus,
    placeholder,
    size,
    styles,
    value: creatableOptions || null,
    vars: theme.vars,
  };

  return (
    <>
      {fieldLabelText && <FieldLabel text={fieldLabelText} />}
      <CreatableSelect components={{ DropdownIndicator: null }} {...CreatableSelectProps} />
    </>
  );
};

export default CreatableSelectField;
