import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { OrgChartGroup } from '../../../../types/OrgChart';
import Icon from '../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import AvatarsGroup from '../../shared/AvatarsGroup/AvatarsGroup';
import OrgChartStyledCard from '../OrgChartShared/OrgChartStyledCard';

const StyledCard = styled(OrgChartStyledCard)`
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  display: flex;
  flex-direction: column;
`;

const Name = styled.h3`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm5};
  ${TruncatedText({})};
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.span`
  padding: 0 ${({ theme: { constants } }) => constants.spacerSm3} 0
    ${({ theme: { constants } }) => constants.spacerSm2};
`;

const NoUsers = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
`;

interface Props {
  canUpdateOrgChart: boolean;
  group: OrgChartGroup;
}

const t = initTranslations('role_chart.card');

const GroupChartCard = ({ group }: Props) => {
  const { id, name, users } = group;
  return (
    <StyledCard className='group-chart-card' id={`group-card-${id}`}>
      <CardContentWrapper>
        <Name>{name}</Name>
        {users.length ? (
          <AvatarsGroup i18Key='avatars' totalUserCount={users.length} users={users.slice(0, 4)} />
        ) : (
          <NoUsers>
            <IconWrapper>
              <Icon name='user' />
            </IconWrapper>
            {t('no_users')}
          </NoUsers>
        )}
      </CardContentWrapper>
    </StyledCard>
  );
};

export default GroupChartCard;
