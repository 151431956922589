import { SaveState } from '../../../../contexts/EditorContext';

export enum AutoSaveStatus {
  Saving = 'saving',
  Saved = 'saved',
  NoChanges = 'no-changes',
}

export type AutoSaveStatusIndicatorProps = {
  autosaveStatus: SaveState;
  isFetching: boolean;
  updatedAtInWords: string;
  lastEditedBy?: string;
  lastEditedOn?: string;
};
