import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import usePeopleState, { PeopleState } from '../hooks/usePeopleState';
import initTranslations from '../lib/initTranslations';
import { useGetUserDirectoryQuery } from '../redux/services/resourceApis/users/usersApi';
import { useSearch } from './SearchContext';

const UsersContext = createContext({} as PeopleState);
export const useUsers = () => useContext(UsersContext);

const t = initTranslations('people');

interface Props {
  children: ReactNode;
}

export const UsersProvider = ({ children }: Props) => {
  const state = usePeopleState();
  const { setPeople, people, setErrorMessage, requestNextPage, setRequestNextPage } = state;
  const { debouncedSearchValue, selectedSortOption } = useSearch();
  const { sortColumn: sort_col, sortDirection: sort_col_dir } = selectedSortOption;

  const [queryState, setQueryState] = useState({
    page: 1,
    sort_col,
    sort_col_dir,
    search_query: debouncedSearchValue,
  });

  const { data, isLoading, isFetching, isError } = useGetUserDirectoryQuery(queryState, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setQueryState({
      page: 1,
      sort_col,
      sort_col_dir,
      search_query: debouncedSearchValue,
    });
  }, [debouncedSearchValue, sort_col, sort_col_dir]);

  useEffect(() => {
    if (requestNextPage) {
      if (people.has_more)
        setQueryState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
      setRequestNextPage(false);
    }
  }, [requestNextPage, setRequestNextPage, people]);

  useEffect(() => {
    if (isError) setErrorMessage(t('error_message'));
  }, [isError, setErrorMessage]);

  useEffect(() => {
    if (data) {
      const { has_more, users: newUsers, page } = data;
      setPeople((prevState) => {
        const users = page === 1 ? newUsers : [...prevState.users, ...newUsers];
        return { has_more, users, page };
      });
    }
  }, [data, setPeople]);

  return (
    <UsersContext.Provider
      value={{ ...state, paginationLoading: isFetching, dataIsLoading: isLoading }}
    >
      {children}
    </UsersContext.Provider>
  );
};
