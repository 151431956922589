type CollapsibleFormElementOption = { collapsed: boolean; searchValue: string };
type Action = { type: 'toggleCollapsed' } | { type: 'setSearchValue'; searchValue: string };

export const initialCollapseState: CollapsibleFormElementOption = {
  collapsed: true,
  searchValue: '',
};

export const collapseReducer = (
  state: CollapsibleFormElementOption,
  action: Action
): CollapsibleFormElementOption => {
  switch (action.type) {
    case 'toggleCollapsed': {
      return { ...state, collapsed: !state.collapsed };
    }
    case 'setSearchValue': {
      return { ...state, searchValue: action.searchValue };
    }
  }
};
