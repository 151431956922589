import React from 'react';

import { RequestsCardProps } from '../../activities/types';
import CommonTemplate, { CommonTemplateProps } from './CommonTemplate';

interface RequestActivitiesProps {
  'request.create': React.FC<CommonTemplateProps>;
  'request.approved': React.FC<CommonTemplateProps>;
  'request.denied': React.FC<CommonTemplateProps>;
}

const ACTIVITY_KEYS: RequestActivitiesProps = {
  'request.create': CommonTemplate,
  'request.approved': CommonTemplate,
  'request.denied': CommonTemplate,
};

const RequestNotificationsDetails = ({ activity }: RequestsCardProps) => {
  const NotificationMessage = ACTIVITY_KEYS[activity.key as keyof RequestActivitiesProps];

  return (
    <NotificationMessage
      activityKey={activity.key}
      owner={activity.owner}
      trackable={activity.trackable}
    />
  );
};

export default RequestNotificationsDetails;
