import { map } from 'lodash';

import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import { CurriculumElementState } from '../../../../types/CurriculumElement';
import { trainualApi } from '../../trainualService';
import { curriculumElementListTags } from './helpers';
import {
  CreateElementParams,
  CreateElementResponse,
  CurriculumElementTag,
  DeleteCurriculumElementParams,
  DragCurriculumElementParams,
  DuplicateCurriculumElementParams,
  UpdateElementParams,
  UpdateParams,
} from './types';

const curriculumElementsApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    createElement: builder.mutation<CreateElementResponse, CreateElementParams>({
      query: ({
        element,
        elementKind,
        curriculumId,
        aiCompletionId,
        signatureRequired,
      }: CreateElementParams) => ({
        url: 'ajax/curriculum_elements/element',
        method: 'POST',
        body: toSnakeCase({
          element,
          elementKind,
          curriculumId,
          aiCompletionId,
          signatureRequired,
        }),
      }),
      transformResponse: (response: CreateElementResponse) => {
        return toCamelCase(response);
      },
      invalidatesTags: (_result, _error, arg) => [
        ...curriculumElementListTags({ curriculumId: arg.curriculumId }),
        { type: 'TaskLists', id: 'LIST' },
      ],
    }),
    duplicateCurriculumElement: builder.mutation<undefined, DuplicateCurriculumElementParams>({
      query: ({ id, title }: DuplicateCurriculumElementParams) => ({
        url: `ajax/curriculum_elements/${id}/copy`,
        method: 'POST',
        body: { title },
      }),
      invalidatesTags: (_result, _error, arg) => curriculumElementListTags({ id: arg.id }),
    }),
    dragCurriculumElement: builder.mutation<undefined, DragCurriculumElementParams>({
      query: ({ id, position, newCurriculumId }) => ({
        url: `ajax/curriculum_elements/${id}`,
        method: 'PUT',
        body: { curriculum_element: { position, new_curriculum_id: newCurriculumId } },
      }),
      onQueryStarted({ id, newCurriculumId, position }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          curriculumElementsApi.util.updateQueryData(
            'getCurriculumElementsForAdmin',
            newCurriculumId,
            (draft) => {
              const curriculumElements = draft.curriculumElements;
              const destinationIndex = position - 1;
              const [reorderedCurriculumElement] = curriculumElements.splice(
                curriculumElements.findIndex((curriculumElement) => curriculumElement.id === id),
                1
              );

              curriculumElements.splice(destinationIndex, 0, reorderedCurriculumElement);
            }
          )
        );

        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (_result, _error, arg) => curriculumElementListTags({ id: arg.id }),
    }),
    deleteCurriculumElement: builder.mutation<undefined, DeleteCurriculumElementParams>({
      query: ({ id }: DeleteCurriculumElementParams) => ({
        url: `ajax/curriculum_elements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => curriculumElementListTags({ id: arg.id }),
    }),
    getCurriculumElementsForAdmin: builder.query<CurriculumElementState, number>({
      query: (id) => ({
        url: `ajax/curriculums/admin/${id}/curriculum_elements`,
        method: 'GET',
      }),
      transformResponse: (response: CurriculumElementState) => {
        return toCamelCase(response);
      },
      providesTags: (result, _error, curriculumId) => {
        const elementTags: CurriculumElementTag[] = [];

        return [
          ...map(result?.curriculumElements, (ce) => {
            elementTags.push({
              type: 'CurriculumElement',
              id: `Element-${ce.elementId}`,
            });
            return { type: 'CurriculumElement', id: ce.id } as const;
          }),
          ...elementTags,
          { type: 'CurriculumElement', id: `Curriculum-${curriculumId}` },
        ];
      },
    }),
    updateCurriculumElementService: builder.mutation<undefined, UpdateParams>({
      query: ({ curriculumElementId, newCurriculumId, title }) => ({
        url: `ajax/curriculum_elements/${curriculumElementId}`,
        body: {
          curriculum_element: {
            new_curriculum_id: newCurriculumId,
            new_curriculum_title: title,
          },
        },
        method: 'PUT',
      }),
      invalidatesTags: (result, _error, arg) => {
        const { curriculumElementId, newCurriculumId } = arg;
        const newCurriculumTag = newCurriculumId
          ? curriculumElementListTags({ curriculumId: Number(newCurriculumId) })
          : [];

        return result
          ? [
              ...newCurriculumTag,
              ...curriculumElementListTags({ id: curriculumElementId }),
              { type: 'Curriculum', id: 'CURRICULUM_LIST_process' as const },
              { type: 'Curriculum', id: 'CURRICULUM_LIST_company' as const },
              { type: 'Curriculum', id: 'CURRICULUM_LIST_policy' as const },
            ]
          : [];
      },
    }),
    updateElement: builder.mutation<undefined, UpdateElementParams>({
      query: ({ curriculumElementId, status }) => ({
        url: `ajax/curriculum_elements/${curriculumElementId}/element`,
        method: 'PUT',
        body: {
          status,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'CurriculumElement', id: arg.curriculumElementId },
        { type: 'CurriculumElement', id: 'LIST' },
        { type: 'Curriculum', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useUpdateCurriculumElementServiceMutation,
  useCreateElementMutation,
  useDeleteCurriculumElementMutation,
  useDuplicateCurriculumElementMutation,
  useDragCurriculumElementMutation,
  useGetCurriculumElementsForAdminQuery,
  useUpdateElementMutation,
} = curriculumElementsApi;
