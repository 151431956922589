import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { Route } from 'type-route';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { sanitizeTags } from '../../../../../../lib/sanitize';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useUpdateSurveyAttemptMutation } from '../../../../../../redux/services/resourceApis/surveyAttempts/surveyAttemptsApi';
import RichTextareaWithInsert from '../../../../editor/components/RichTextareaWithInsert';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import {
  CardContent,
  CardFooter,
  CardHeader,
  OptionsFormWrapper,
  QuestionCard,
  QuestionPosition,
} from '../../../shared/SurveyQuestion/styles';
import ChooseOptionsForm from './ChooseOptionsForm/ChooseOptionsForm';
import MultipleChoiceForm from './ChooseOptionsForm/MultipleChoiceForm';
import ConsumptionFormControlButtons from './ConsumptionFormControlButtons';
import {
  FormTypeComponentProps,
  FormValues,
  SurveyQuestionAnswerValue,
  SurveyQuestionCardProps,
} from './types';
import VideoResponseForm from './VideoResponseForm/VideoResponseForm';
import WrittenResponseForm from './WrittenResponseForm/WrittenResponseForm';

const SurveyQuestionCard = ({
  questions,
  lastInProgressAttemptId,
  surveyStatus,
}: SurveyQuestionCardProps) => {
  const route = useRoute();
  const {
    params: { slug, id },
  } = route as Route<typeof routes.surveyConsume>;
  const { currentQuestionIndex } = useAppSelector((state) => state.surveyConsume);
  const currentQuestion = questions[currentQuestionIndex] || questions[0];
  const [submitAttempt, submitAttemptResponse] = useUpdateSurveyAttemptMutation();

  const initialFormValues = useMemo(
    () => ({
      answersAttributes: questions.map((question) => {
        let response = {};

        switch (question.answerType) {
          case 'written_response':
            response = { questionId: question.id, writtenResponse: '' };
            break;
          case 'true_or_false':
          case 'multiple_choice':
          case 'choose_one_option':
            response = { questionId: question.id, optionId: null };
            break;
          case 'video_response':
            response = { questionId: question.id, responseJson: null };
            break;
        }

        return response;
      }),
    }),
    [questions]
  );

  const sanitizeAnswers = (answers: SurveyQuestionAnswerValue[]): SurveyQuestionAnswerValue[] =>
    answers.map((answer) =>
      'writtenResponse' in answer
        ? { ...answer, writtenResponse: sanitizeTags(answer.writtenResponse) }
        : answer
    );

  const handleFormSubmit = (values: FormValues) => {
    const sanitizedValues = {
      ...values,
      answersAttributes: sanitizeAnswers(values.answersAttributes),
    };

    submitAttempt({
      ...sanitizedValues,
      surveyId: id,
      id: lastInProgressAttemptId,
    });
  };

  useDisplayFlashOnResponse({
    result: submitAttemptResponse,
    errorMessage: messageFromError(submitAttemptResponse?.error)?.join(', '),
    successFunction: routes.surveyResults({ slug, surveyId: id, id: lastInProgressAttemptId }).push,
  });

  const RESPONSE_FORMS: FormTypeComponentProps = {
    true_or_false: ChooseOptionsForm,
    video_response: VideoResponseForm,
    written_response: WrittenResponseForm,
    choose_one_option: ChooseOptionsForm,
    multiple_choice: MultipleChoiceForm,
  };
  const ResponseForm = RESPONSE_FORMS[currentQuestion.answerType as keyof FormTypeComponentProps];

  return (
    <Formik initialValues={initialFormValues} onSubmit={handleFormSubmit}>
      <QuestionCard id='question-consumption-card'>
        <CardHeader>
          <QuestionPosition>{currentQuestionIndex + 1}</QuestionPosition>
        </CardHeader>
        <CardContent>
          <OptionsFormWrapper>
            <RichTextareaWithInsert
              content={currentQuestion.textJson || currentQuestion.text}
              questionId={currentQuestion.id}
              surveyId={id}
            />
            <ResponseForm question={currentQuestion} />
          </OptionsFormWrapper>
        </CardContent>
        <CardFooter>
          <ConsumptionFormControlButtons
            isSubmitLoading={submitAttemptResponse.isLoading}
            isSurveyFinished={surveyStatus === 'finished'}
          />
        </CardFooter>
      </QuestionCard>
    </Formik>
  );
};

export default SurveyQuestionCard;
