import React, { useEffect, useReducer } from 'react';

import { useAccountRoutesContext } from '../../../../../contexts/AccountRoutesContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import EmptyStateDark from '../../../../../images/searches_empty_state_dark.svg';
import EmptyState from '../../../../../images/searches_empty_state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { ManageNotificationsOption } from '../../../../../redux/services/resourceApis/notifications/types';
import { useGetRequestsQuery } from '../../../../../redux/services/resourceApis/requests/requestsApi';
import Avatar from '../../../../design_system/display/avatar';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import TableBodyCell from '../../../people/UsersOutline/Table/Body/TableBodyCell';
import LoadingTable from '../../../people/UsersOutline/Table/LoadingTable';
import {
  AvatarWrapper,
  BodyRow,
  LeftAlignedDataStyled,
  NameCell,
  StyledText,
  TableLink,
  TableStyled,
  TableWrapper,
  TruncatedTableCell,
} from '../../../people/UsersOutline/Table/Styles';
import { routes as spaRoutes, useRoute } from '../../../publicApplication/applicationRouter';
import { tableReducer } from './reducer';
import { columns } from './RequestColumn';
import RequestsStatusColumn from './RequestsStatusColumn';
import RequestsTableHeader from './RequestsTableHeader';

const t = initTranslations('requests.table');

const initialState: ManageNotificationsOption = {
  page: 1,
};

const RequestsTable = () => {
  const routes = useAccountRoutesContext();
  const { slug } = useCurrentAccount();
  const isActiveRequestsTab = window.location.pathname == routes.view.requests({});
  const requestsKind = isActiveRequestsTab ? 'active' : 'archived';
  const [tableState, dispatch] = useReducer(tableReducer, initialState);
  const urlParams = new URLSearchParams(window.location.search);
  const route = useRoute();

  const { data, isLoading, isError } = useGetRequestsQuery({
    page: tableState.page,
    kind: requestsKind,
    sector: urlParams.get('sector'),
  });

  useEffect(() => {
    dispatch({ type: 'changePage', page: 1 });
  }, [route]);

  if (isLoading) return <LoadingTable />;
  if (isError) return <div>{t('error')}</div>;
  if (!data) return <></>;

  const {
    requests,
    limit_value: limitValue,
    total_pages: totalPages,
    total_requests_count: totalRequestsCount,
  } = data;

  return (
    <>
      {requests.length ? (
        <>
          <TableWrapper>
            <TableStyled className='requests-table'>
              <RequestsTableHeader activeHeader='requester_name' />
              <tbody>
                {requests.map((request) => {
                  return (
                    <BodyRow
                      id={`requests-table-tr-${request.id}`}
                      key={`requests-table-tr-${request.id}`}
                    >
                      <LeftAlignedDataStyled className='left-table-data'>
                        <NameCell>
                          <AvatarWrapper
                            href={routes.view.users({ userId: request.requester_id })}
                            target='_blank'
                          >
                            <Avatar
                              image={request.requester_avatar}
                              name={request.requester_name}
                              shape='circle'
                              size='sm'
                            />
                          </AvatarWrapper>
                          <StyledText>
                            <TableLink
                              href={routes.view.users({ userId: request.requester_id })}
                              rel='noreferrer'
                              target='_blank'
                            >
                              {request.requester_name}
                            </TableLink>
                          </StyledText>
                        </NameCell>
                      </LeftAlignedDataStyled>

                      <TruncatedTableCell>
                        <TableLink
                          href={spaRoutes.curriculumShow({ slug, id: request.curriculum_id }).href}
                          rel='noreferrer'
                          target='_blank'
                        >
                          <StyledText>{request.curriculum_title}</StyledText>
                        </TableLink>
                      </TruncatedTableCell>
                      {columns
                        .filter(
                          ({ display, customColumn }) =>
                            display !== 'static' && display !== 'hidden' && !customColumn
                        )
                        .map(({ columnName, contentAlignment, statusColumn }) =>
                          statusColumn ? (
                            <RequestsStatusColumn
                              key={`td-requests-table-${request.id}-status`}
                              requestId={request.id}
                              resolved={request.resolved}
                              status={request.status}
                            />
                          ) : (
                            <TableBodyCell
                              contentAlignment={contentAlignment}
                              data={request[columnName]}
                              id={`td-requests-table-${request.id}-${columnName}`}
                              key={`td-requests-table-${request.id}-${columnName}`}
                            />
                          )
                        )}
                    </BodyRow>
                  );
                })}
              </tbody>
            </TableStyled>
          </TableWrapper>
          <Pagination
            activePage={tableState.page}
            itemsCountPerPage={limitValue}
            onChange={(page) => {
              dispatch({ type: 'changePage', page });
            }}
            showPaginationDetails
            totalItemsCount={totalRequestsCount}
            totalPages={totalPages}
          />
        </>
      ) : (
        <NoResults
          darkImage={EmptyStateDark}
          heading={t('no_results_found')}
          iconWidth='45%'
          lightImage={EmptyState}
        />
      )}
    </>
  );
};

export default RequestsTable;
