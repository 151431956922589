import styled from 'styled-components';

import { fontMd1, fontSm4, fontSm5 } from '../../../styled/TypeSystem';

export const HomeBlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  ${fontMd1};
`;

export const HomeBlockBody = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const ProgressItem = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm5};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InfoIcon = styled.div`
  display: inline-block;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-bottom: ${({ theme: { constants } }) => `-${constants.spacerSm1}`};

  svg {
    display: block;
    pointer-events: none;
  }
`;

export const ProgressRow = styled.div`
  display: flex;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
  &:last-child {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 3.75rem;
  height: max-content;
  display: flex;
  justify-content: center;
`;

export const CompanyProgressBarWrapper = styled.div`
  position: relative;
`;

export const CompanyProgressBarPercentage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  position: absolute;
  inset: 0;
  margin: auto;
  ${fontSm4};
`;

export const ProgressContent = styled.div`
  width: calc(100% - 3.75rem);
  padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const ProgressSubtitle = styled.h5`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  overflow-wrap: anywhere;
  ${fontMd1};
`;
