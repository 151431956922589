import React from 'react';
import styled from 'styled-components';

import AddUserIllustration from '../../../../images/add_user_illustration.svg';
import initTranslations from '../../../../lib/initTranslations';
import { Title } from '../../shared/DetailedTitle/Title';

const EmptyStateWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  flex-direction: column;
  align-items: center;
  padding-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
`;

const StyledImg = styled.img`
  width: 5rem;
`;

const EmptyStateTextWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  flex-direction: column;
  align-items: center;
`;

type FollowingWidgetEmptyStateProps = {
  openModal(): void;
};

const t = initTranslations('home.following_widget');

const FollowingWidgetEmptyState = ({ openModal }: FollowingWidgetEmptyStateProps) => {
  return (
    <EmptyStateWrapper id='following-widget-empty-state'>
      <StyledImg src={AddUserIllustration} />
      <EmptyStateTextWrapper>
        <Title fontSize='md1' fontWeight='semibold' title={t('empty_state.not_following_anyone')} />
        <Title
          fontColor='accentPrimaryDefault'
          fontWeight='regular'
          id='empty-state-button'
          onClick={() => {
            openModal();
          }}
          title={t('empty_state.search_people_to_follow')}
        />
      </EmptyStateTextWrapper>
    </EmptyStateWrapper>
  );
};

export default FollowingWidgetEmptyState;
