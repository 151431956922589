import React from 'react';

import { ActivityCardProps } from '../activities/ActivityCard/types';
import { Activity } from '../activities/types';
import AccountNotificationsDetails from './AccountNotifications/AccountNotificationsDetails';
import CompletionNotificationsDetails from './CompletionNotifications/CompletionNotificationsDetails';
import CourseNotificationsDetails from './CourseNotifications/CourseNotificationsDetails';
import CurriculumNotificationsDetails from './CurriculumNotifications/CurriculumNotificationsDetails';
import RequestNotificationsDetails from './RequestNotifications/RequestNotificationsDetails';
import SurveyNotificationsDetails from './SurveyNotifications/SurveyNotificationsDetails';
import UserNotificationsDetails from './UserNotifications/UserNotificationsDetails';

interface ActivityMessageProps {
  Account: React.FC<ActivityCardProps>;
  Completion: React.FC<ActivityCardProps>;
  Course: React.FC<ActivityCardProps>;
  Curriculum: React.FC<ActivityCardProps>;
  Request: React.FC<ActivityCardProps>;
  'Survey::Survey': React.FC<ActivityCardProps>;
  User: React.FC<ActivityCardProps>;
}

const DETAILS: ActivityMessageProps = {
  Account: AccountNotificationsDetails,
  Completion: CompletionNotificationsDetails,
  Course: CourseNotificationsDetails,
  Curriculum: CurriculumNotificationsDetails,
  Request: RequestNotificationsDetails,
  'Survey::Survey': SurveyNotificationsDetails,
  User: UserNotificationsDetails,
};

const NotificationDetails = ({ activity }: { activity: Activity }) => {
  const ActivityDetailedMessage = DETAILS[activity.trackable_type as keyof ActivityMessageProps];

  return <ActivityDetailedMessage activity={activity} />;
};

export default NotificationDetails;
