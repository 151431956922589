import React, { useState } from 'react';

import { TopicHeaderContainer } from './styles';
import TopicCoverImage from './TopicCoverImage';
import TopicTitleContainer from './TopicTitleContainer';

export const TOPIC_HEADER_FLYOUT_MENU_ID = 'topic-header-image-flyout';

export type Props = {
  topicId: number;
  topicTitle: string;
  topicEmoji: string | null;
  coverImage: string | null;
};

const TopicHeader = ({ topicTitle, topicEmoji, topicId, coverImage }: Props) => {
  const [showCropper, setShowCropper] = useState(false);

  return (
    <TopicHeaderContainer applyGap={showCropper && !topicEmoji} className='topic-header-container'>
      <TopicCoverImage
        coverImage={coverImage}
        setShowCropper={setShowCropper}
        showCropper={showCropper}
        topicEmoji={topicEmoji}
        topicId={topicId}
      />
      <TopicTitleContainer
        id={topicId}
        isFlatTop={!!coverImage || showCropper}
        title={topicTitle}
      />
    </TopicHeaderContainer>
  );
};

export default TopicHeader;
