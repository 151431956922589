import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { CompletionAction } from '../../../../../../redux/services/resourceApis/curriculums/types';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { ActionButtonsWrapper, StyledActionCell } from '../styles';

interface Props {
  id: number;
  updateCompletion: (action: CompletionAction, userId: number) => void;
}

const t = initTranslations('curriculum_edit.manage_completions.action_row');

const ActionCell = ({ id, updateCompletion }: Props) => {
  return (
    <StyledActionCell>
      <ActionButtonsWrapper className='manage-completions-action-cell'>
        <DefaultButton
          buttonType='tertiary'
          iconName='circle-xmark'
          id={`manage-completions-page-clear-button-${id}`}
          onClick={() => updateCompletion('clear', id)}
          text={t('clear_completion')}
        />
        <DefaultButton
          buttonType='tertiary'
          iconName='check'
          id={`manage-completions-page-complete-button-${id}`}
          onClick={() => updateCompletion('complete', id)}
          text={t('mark_complete')}
        />
      </ActionButtonsWrapper>
    </StyledActionCell>
  );
};

export default ActionCell;
