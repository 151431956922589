import { Hooks, SDKButtonInterface, createInstance } from '@loomhq/record-sdk';
import { useEffect, useState } from 'react';

import usePrivateConfigs from './usePrivateConfigs';

type LoomButtonConfig = {
  element: HTMLElement | undefined;
  hooks?: Hooks;
};

type PseudoButtonFn = (config: LoomButtonConfig) => SDKButtonInterface;
type LoomSetupMethod = PseudoButtonFn;

export default function useLoom(): LoomSetupMethod | null {
  const { configs } = usePrivateConfigs();
  const publicAppId = configs['LOOM_API_KEY'];
  const [setupButton, setSetupButton] = useState<LoomSetupMethod | null>(null);

  if (!publicAppId) {
    throw 'Loom public app id not found.';
  }

  useEffect(() => {
    async function setupLoom() {
      try {
        const instance = await createInstance({
          mode: 'standard',
          publicAppId,
        });

        const { configureButton } = instance;
        setSetupButton(() => configureButton);
      } catch (e) {
        console.error(`Error setting up Loom: ${e}`);
      }
    }

    setupLoom();
  }, [publicAppId]);

  return setupButton;
}
