import React from 'react';

import usePrivateConfigs from '../../../../../../../hooks/usePrivateConfigs';
import usePublicConfigs from '../../../../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import P from '../../../../../../design_system/text/P';
import { NeedMoreSeatsButtonsWrapper, NeedMoreSeatsTitle, NeedMoreSeatsWrapper } from './styles';

type NeedMoreSeatsProps = {
  maxSeatsCount: string;
};

const t = initTranslations('per_user_pricing.manage_plan_overlay_content.need_more_seats');

const NeedMoreSeats = ({ maxSeatsCount }: NeedMoreSeatsProps) => {
  const { configs: publicConfigs } = usePublicConfigs();
  const { configs: privateConfigs } = usePrivateConfigs();

  return (
    <NeedMoreSeatsWrapper className='need-more-seats'>
      <NeedMoreSeatsTitle>{t('title')}</NeedMoreSeatsTitle>
      <P text={t('body', { selected_seats_count: maxSeatsCount })} />
      <NeedMoreSeatsButtonsWrapper>
        <DefaultButton
          behavesAs='a'
          buttonType='tertiary'
          href={publicConfigs['NEED_MORE_SEATS_HELP_PAGE']}
          id='need-more-seats-need-help-link'
          target='_blank'
          text={t('cta.need_help')}
        />
        <DefaultButton
          behavesAs='a'
          buttonType='primary'
          href={privateConfigs['THOUSAND_PLUS_LINK']}
          id='need-more-seats-request-quote-link'
          target='_blank'
          text={t('cta.request_quote')}
        />
      </NeedMoreSeatsButtonsWrapper>
    </NeedMoreSeatsWrapper>
  );
};

export default NeedMoreSeats;
