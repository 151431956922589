import { useFormikContext } from 'formik';
import React from 'react';

import { OnboardingData, useOnboarding } from '../../../../../contexts/OnboardingContext';
import initTranslations from '../../../../../lib/initTranslations';
import SingleSelect from '../../../../design_system/Triage/fields/SingleSelectField/SingleSelectField';
import InputField, { ErrorText } from '../../../../design_system/Triage/InputField';
import ImageUpload from '../ImageUpload';
import { PreFillableInputField } from '../styles';
import AgreeToReceiveSmsCheckbox from './AgreeToReceiveSmsCheckbox/AgreeToReceiveSmsCheckbox';
import {
  FormContainer,
  FormImage,
  FormInputs,
  InputRow,
  StepButton,
  StyledForm,
  UploadImageLabel,
} from './styles';
import { SourceLabelsValues, UserOnboardingInfoStepData } from './types';

const t = initTranslations('home.onboarding.user_info_step');

const UserInfoForm = () => {
  const { values, touched, errors, handleSubmit, handleChange, setFieldValue } =
    useFormikContext<UserOnboardingInfoStepData>();
  const { setFormData, formData, initialFormData, isInputFieldPrefilled } = useOnboarding();
  const { data, isLoadingData } = useOnboarding();
  const isCustomSource = () => {
    return [
      'Other',
      'Friend/Referral',
      'Conference/Event',
      'Organization/Community',
      'Podcast',
    ].includes(values.source);
  };

  const setSourceValue = (source: string) => {
    setFieldValue('source', source);
    isCustomSource() && setFieldValue('custom_source', '');
  };

  const sourceLabels: SourceLabelsValues = {
    Other: 'labels.tell_us_where',
    'Friend/Referral': 'labels.what_is_their_name',
    'Conference/Event': 'labels.which_event',
    'Organization/Community': 'labels.which_one',
    Podcast: 'labels.what_show',
  };
  const customSourceLabel = t(sourceLabels[values.source]);

  const accountSourceOptions = data?.accountSource.map((i) => {
    return {
      label: i,
      value: i,
      searchableTerm: i,
    };
  });

  return (
    <FormContainer>
      <StyledForm
        autoComplete='off'
        id='user-info-step-form'
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        onSubmit={handleSubmit}
      >
        <FormInputs>
          <InputRow>
            <PreFillableInputField
              displayPrefilled={isInputFieldPrefilled(initialFormData.user_name, values.user_name)}
              errorText={touched.user_name && errors.user_name}
              isError={!!(touched.user_name && errors.user_name)}
              label={t('labels.user_name')}
              name='user_name'
              onChange={handleChange}
              placeholder={t('placeholder.user_name')}
              required
              value={values.user_name}
            />
          </InputRow>
          <InputRow>
            <InputField
              errorText={touched.phone && errors.phone}
              label={t('labels.mobile_number')}
              name='phone'
              onChange={handleChange}
              placeholder={t('placeholder.mobile_number')}
              value={values.phone}
            />
            <AgreeToReceiveSmsCheckbox
              agreeToReceiveSms={values.agreed_to_receive_sms}
              hasPhoneError={!!errors.phone}
              hasPhoneValue={!!values.phone}
              setFieldValue={setFieldValue}
            />
          </InputRow>
          <InputRow>
            <SingleSelect
              className='source-select'
              defaultValue={values.source}
              disabled={isLoadingData}
              fieldLabelText={t('labels.how_you_hear_about_us')}
              isValid={!(touched.source && errors.source)}
              loading={isLoadingData}
              name='source'
              onNonDefaultSelected={(source: string) => setSourceValue(source)}
              options={accountSourceOptions || []}
              placeholder={t('placeholder.select_source')}
              required
              size='md'
              value={values.source}
            />
            {errors.source && <ErrorText>{touched.source && errors.source}</ErrorText>}
          </InputRow>

          {isCustomSource() && (
            <InputRow>
              <InputField
                errorText={touched.custom_source && errors.custom_source}
                label={customSourceLabel}
                name='custom_source'
                onChange={handleChange}
                value={values.custom_source}
              />
            </InputRow>
          )}
        </FormInputs>
        <FormImage>
          <UploadImageLabel>{t('labels.upload_image')}</UploadImageLabel>
          <ImageUpload
            descriptionText={t('uploader.description')}
            imageSource={formData.avatar}
            resetButtonId='onboarding-remove-avatar-button'
            resetButtonText={t('uploader.remove_image')}
            setImage={(image) =>
              setFormData((prev: OnboardingData) => {
                return { ...prev, avatar: image };
              })
            }
            uploadButtonId='onboarding-upload-avatar-button'
            uploadButtonText={t('uploader.upload_image')}
          />
        </FormImage>
        <StepButton
          buttonType='primary'
          id='onboarding-user-info-step-submit'
          size='md'
          text={t('next')}
          type='submit'
        />
      </StyledForm>
    </FormContainer>
  );
};

export default UserInfoForm;
