import { useFormikContext } from 'formik';
import React, { useEffect, useReducer } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import CollapsibleSurface from '../../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import { SettingsFormValues } from '../InterfaceAndTypes';
import {
  FieldDescription,
  FieldTitle,
  FormSectionContainer,
  FormSectionWrapper,
  SectionDivider,
} from '../Styles';

const t = initTranslations('settings');

const AdvancedSettings = () => {
  const currentUser = useCurrentUser();
  const {
    splitFeatures: { deprecateDarkModeEnabled },
  } = useCurrentAccount();
  const darkModeEnabled = currentUser.styleMode === 'dark';
  const ability = useCurrentUserAbilities();
  const canManageSettings = ability.can('read', 'ManageSettings');
  const { values, handleChange, setFieldValue } = useFormikContext<SettingsFormValues>();

  // If we toggle the dark mode from the top nav while on the my setting page, we need to sync that change to the dark
  // mode enabled checkbox to ensure we don't override their changes on save.
  useEffect(() => {
    setFieldValue('dark_mode_enabled', darkModeEnabled);
  }, [darkModeEnabled, setFieldValue]);
  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);

  if (!canManageSettings && deprecateDarkModeEnabled) return <></>;

  return (
    <CollapsibleSurface
      collapseDispatch={collapseDispatch}
      id='organization-profile-settings-surface'
      isCollapsed={collapsed}
      title={t('advanced.title')}
    >
      <FormSectionWrapper className='my-settings-advanced-section'>
        {!deprecateDarkModeEnabled && (
          <>
            <FormSectionContainer>
              <FieldTitle>{t('advanced.dark_mode.title')}</FieldTitle>
              <Checkbox
                checked={values.dark_mode_enabled}
                className='analytics-dark_mode-check'
                id='dark_mode_enabled'
                name='dark_mode_enabled'
                onCheck={handleChange}
              />
            </FormSectionContainer>
            <FormSectionContainer>
              <FieldDescription>{t('advanced.dark_mode.description')}</FieldDescription>
            </FormSectionContainer>
            <SectionDivider />
          </>
        )}
        <FormSectionContainer>
          <FieldTitle>{t('advanced.high_contrast_mode.title')}</FieldTitle>
          <Checkbox
            checked={values.high_contrast_mode}
            className='analytics-high_contrast_mode-check'
            id='high_contrast_mode'
            name='high_contrast_mode'
            onCheck={handleChange}
          />
        </FormSectionContainer>
        <FormSectionContainer>
          <FieldDescription>{t('advanced.high_contrast_mode.description')}</FieldDescription>
        </FormSectionContainer>
      </FormSectionWrapper>
    </CollapsibleSurface>
  );
};

export default AdvancedSettings;
