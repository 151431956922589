import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import useFieldErrorsFromFormik from '../../../../../hooks/useFieldErrorsFromFormik';
import initTranslations from '../../../../../lib/initTranslations';
import { useChangePasswordMutation } from '../../../../../redux/services/resourceApis/users/usersApi';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import InputField from '../../../../design_system/Triage/InputField';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import { FormGroup } from '../../../shared/FormGroup';
import { ModalProps, PasswordValues } from '../InterfaceAndTypes';
import { validatePassword } from '../ValidatePassword';

const t = initTranslations('settings.password.password_modal');

const PasswordModal = ({ closeModal }: ModalProps) => {
  const {
    values,
    handleSubmit,
    handleChange,
    touched,
    errors,
    isValidating,
    isSubmitting,
    setFieldError,
    setSubmitting,
    setStatus,
    status,
  } = useFormikContext<PasswordValues>();
  const { flash } = useFlashNotification();
  const [updatePassword, result] = useChangePasswordMutation();
  const { isSuccess, error, isLoading } = result;
  const [isCurrentPasswordDisplayed, setIsCurrentPasswordDisplayed] = useState(false);
  const [isNewPasswordDisplayed, setIsNewPasswordDisplayed] = useState(false);
  const [isConfirmPasswordDisplayed, setIsConfirmPasswordDisplayed] = useState(false);

  const taskModalArgs: TaskModalProps = {
    title: t('title'),
    subtitle: t('subtitle'),
    processing: isLoading,
    onCloseRequest: closeModal,
    primaryButtonText: t('confirm_cta'),
    primaryButtonTask: async () => {
      await handleSubmit();
    },
    isDisabled: isLoading,
    secondaryButtonText: t('cancel_cta'),
    heapModalName: `update-password-modal`,
  };

  useEffect(() => {
    if (!isValidating && isSubmitting && status === 'submitted') {
      updatePassword(values);
    }
  }, [isSubmitting, isValidating, status, updatePassword, values]);

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      flash('info', t('success_flash'));
    }
  }, [closeModal, flash, isSuccess]);

  useFieldErrorsFromFormik({
    error,
    setFieldError,
    setStatus,
    setSubmitting,
    wrapErrors: true,
  });

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <Form
        autoComplete='off'
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSubmit();
          }
        }}
      >
        <FormGroup className='current-password-group'>
          <InputField
            autoFocus
            className='current-password-field'
            errorText={touched.current_password && errors.current_password}
            iconButtonAriaLabel={
              isCurrentPasswordDisplayed
                ? t('inputs.hide_icon_aria_label')
                : t('inputs.show_icon_aria_label')
            }
            iconButtonId='current-password-show-hide-iconbutton'
            iconButtonIsDataTip
            iconButtonName={isCurrentPasswordDisplayed ? 'eye-slash' : 'eye'}
            iconButtonOnClick={() => setIsCurrentPasswordDisplayed(!isCurrentPasswordDisplayed)}
            iconButtonTooltipId='show-hide-current-password'
            iconButtonTooltipText={
              isCurrentPasswordDisplayed
                ? t('inputs.tooltip_hide_text')
                : t('inputs.tooltip_show_text')
            }
            id='current-password'
            label={t('inputs.current_password')}
            name='current_password'
            onChange={handleChange}
            type={isCurrentPasswordDisplayed ? 'text' : 'password'}
            value={values.current_password}
            wrapErrors
          />
        </FormGroup>
        <FormGroup className='create-new-password-group'>
          <InputField
            className='password-field'
            errorText={touched.password && errors.password}
            iconButtonAriaLabel={
              isNewPasswordDisplayed
                ? t('inputs.hide_icon_aria_label')
                : t('inputs.show_icon_aria_label')
            }
            iconButtonId='new-password-show-hide-iconbutton'
            iconButtonIsDataTip
            iconButtonName={isNewPasswordDisplayed ? 'eye-slash' : 'eye'}
            iconButtonOnClick={() => setIsNewPasswordDisplayed(!isNewPasswordDisplayed)}
            iconButtonTooltipId='show-hide-new-password'
            iconButtonTooltipText={
              isNewPasswordDisplayed ? t('inputs.tooltip_hide_text') : t('inputs.tooltip_show_text')
            }
            id='password'
            label={t('inputs.new_password')}
            name='password'
            onChange={handleChange}
            type={isNewPasswordDisplayed ? 'text' : 'password'}
            value={values.password}
            wrapErrors
          />
        </FormGroup>
        <FormGroup className='confirm-new-password-group'>
          <InputField
            className='password-confirmation-field'
            errorText={touched.password_confirmation && errors.password_confirmation}
            iconButtonAriaLabel={
              isConfirmPasswordDisplayed
                ? t('inputs.hide_icon_aria_label')
                : t('inputs.show_icon_aria_label')
            }
            iconButtonId='confirm-password-show-hide-iconbutton'
            iconButtonIsDataTip
            iconButtonName={isConfirmPasswordDisplayed ? 'eye-slash' : 'eye'}
            iconButtonOnClick={() => setIsConfirmPasswordDisplayed(!isConfirmPasswordDisplayed)}
            iconButtonTooltipId='show-hide-confirm-password'
            iconButtonTooltipText={
              isConfirmPasswordDisplayed
                ? t('inputs.tooltip_hide_text')
                : t('inputs.tooltip_show_text')
            }
            id='password-confirmation'
            label={t('inputs.confirm_password')}
            name='password_confirmation'
            onChange={handleChange}
            type={isConfirmPasswordDisplayed ? 'text' : 'password'}
            value={values.password_confirmation}
            wrapErrors
          />
        </FormGroup>
      </Form>
    </TaskModal>
  );
};

const PasswordModalWrapper = ({ closeModal }: ModalProps) => {
  const initialValues: PasswordValues = {
    current_password: '',
    password: '',
    password_confirmation: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        values: PasswordValues,
        { setSubmitting, setStatus }: FormikHelpers<PasswordValues>
      ) => {
        setStatus('submitted');
        setSubmitting(false);
      }}
      validate={validatePassword}
    >
      <PasswordModal closeModal={closeModal} />
    </Formik>
  );
};

export default PasswordModalWrapper;
