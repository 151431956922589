import { StrictModifier } from 'react-popper';

export const POPPER_MODIFIERS: StrictModifier[] = [
  {
    name: 'flip',
    options: {
      fallbackPlacements: ['top-end'],
    },
  },
];
