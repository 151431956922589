import React, { FC } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { toggleFullscreenMode } from '../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import { PopupText, SquareButton, SyntheticPopup, Wrapper } from './styles';
import { FullscreenButtonProps } from './types';

const t = initTranslations('delegation_planner');

const FullscreenButton: FC<FullscreenButtonProps> = ({ isFullscreenModeEnabled }) => {
  const dispatch = useAppDispatch();
  const handleSquareButtonClick = () => dispatch(toggleFullscreenMode());

  return (
    <Wrapper>
      <SyntheticPopup>
        <PopupText>{t('fullscreen_tooltip')}</PopupText>
      </SyntheticPopup>

      <SquareButton
        isFullscreenModeEnabled={isFullscreenModeEnabled}
        onClick={handleSquareButtonClick}
      >
        {Array.from({ length: 8 }).map((_, index) => (
          <span key={index} />
        ))}
      </SquareButton>
    </Wrapper>
  );
};

export default FullscreenButton;
