import styled, { css } from 'styled-components';

import hexToRGBA from '../../../../lib/HexToRGBA';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { fontSm4 } from '../../../styled/TypeSystem';
import { MAX_SCORE } from './SpecializationPill';

const ExpandedPillStyles = css`
  padding-right: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-left: ${({ theme: { constants } }) => constants.spacerSm3};
  height: ${({ theme: { constants } }) => constants.heightXs};
  display: block;
  line-height: ${({ theme: { constants } }) => constants.heightXs};

  ${TruncatedText({})};
  ${fontSm4};
`;

export const Pill = styled.div<{
  score: number;
  percentage?: number;
  arePillsExpanded: boolean;
  isAlwaysExpanded?: boolean;
  color: string;
}>`
  ${({ arePillsExpanded, isAlwaysExpanded, score, percentage, color, theme: { constants } }) => {
    const isExpanded = arePillsExpanded || isAlwaysExpanded;

    return css`
      position: relative;
      padding: ${constants.spacerSm2};
      border-radius: ${constants.borderRadiusXl};
      background: ${hexToRGBA(color, 0.5)};
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${isExpanded ? 'auto' : constants.spacerMd3};
      height: ${isExpanded ? 'auto' : constants.spacerSm3};
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: ${percentage || (score / MAX_SCORE) * 100}%;
        height: 100%;
        background: ${color};
        transition: all 0.3s ease;
      }

      ${isExpanded && ExpandedPillStyles};
    `;
  }};
`;

export const PillText = styled.span`
  position: relative;
  z-index: 1;
  max-width: 190px;
  ${TruncatedText({})};
`;
