import styled from 'styled-components';

import { fontSm4 } from '../../../../styled/TypeSystem';
import { SurveyScoreBadge } from '../../shared/SurveyScoreBadge/styles';

export const ConsumptionProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme: { constants } }) =>
    `0 ${constants.spacerMd3} ${constants.spacerMd3} ${constants.spacerMd3}`};
`;

export const ProgressPercentage = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontSm4};
`;

export const ViewModeToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const PassingScoreBadge = styled(SurveyScoreBadge)`
  background-color: ${({ theme: { vars } }) => vars.stateBadgeInfo};
`;
