import { sortIcon } from '../../SortIcon';
import { Action, ManageElementReportOption } from './types';

export const tableReducer = (
  state: ManageElementReportOption,
  action: Action
): ManageElementReportOption => {
  const { sortCol, sortColDir } = state;

  switch (action.type) {
    case 'sortColumnChange': {
      const newSortDirection = sortCol === action.sortCol && sortColDir === 'asc' ? 'desc' : 'asc';
      return {
        ...state,
        sortCol: action.sortCol,
        sortColDir: newSortDirection,
        sortIcon: sortIcon(newSortDirection),
      };
    }
  }
};
