import React, { useMemo, useReducer } from 'react';
import { Sector } from 'types/Sector';

import { useCurriculumEdit } from '../../../../../contexts/CurriculumEditContext';
import { TeammateModalProvider } from '../../../../../contexts/TeammateContext';
import useCopyUrlLink from '../../../../../hooks/useCopyUrlLink';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetCurriculumElementsForAdminQuery } from '../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import ThreeDotMenu from '../../../../design_system/Triage/menus/ThreeDotMenu';
import {
  ThreeDotMenuOptionProps,
  ThreeDotMenuProps,
} from '../../../../design_system/Triage/menus/ThreeDotMenu/types';
import { routes } from '../../../publicApplication/applicationRouter';
import CurriculumModal from '../../modals/CurriculumModal/CurriculumModal';
import ExportPDFModal from '../../modals/ExportPDFModal';
import ShareChangesModal from '../../ShareChangesModal/ShareChangesModal';
import { initialState, menuReducer } from './menuReducer';

interface Props extends Omit<ThreeDotMenuProps, 'menuOptions'> {
  curriculumId: number;
  curriculumTitle: string;
  sector: Sector;
  completionRequired: boolean | undefined;
  canDeleteSubject: boolean;
  containsSignature?: boolean; // TODO: Remove when refactor GS2-3562 happens
  reportsAccess?: boolean;
  published: boolean;
}

const t = initTranslations('curriculums.three_dot_menu');

const Menu = ({
  curriculumId,
  sector,
  curriculumTitle,
  completionRequired,
  canDeleteSubject,
  containsSignature,
  reportsAccess,
  published,
  ...propsToPass
}: Props) => {
  const { copyUrlLink } = useCopyUrlLink();
  const {
    paywalledFeatures,
    slug,
    splitFeatures: { curriculumPrintViewEnabled },
  } = useCurrentAccount();

  const ability = useCurrentUserAbilities();
  const [{ editModal, exportModal, shareModal }, dispatch] = useReducer(menuReducer, initialState);
  const {
    curriculum: { singular: curriculumTermSingular },
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const { setShowDeleteCurriculumModal } = useCurriculumEdit();
  const { data } = useGetCurriculumElementsForAdminQuery(curriculumId);
  const elementFinished =
    data?.curriculumElements?.some((ce) => ce.element.status === 'finished') || false;
  const canExportPdf = ability.can('create', `ExportCurriculum-${curriculumId}`);
  const reportsPaywalled = paywalledFeatures.includes('reports');
  const reportRoute = reportsPaywalled
    ? routes.reportsUpgrade({ slug })
    : routes.curriculumByUserReport({ slug, id: curriculumId });
  const notifyTeamToolTipText = useMemo(() => {
    if (!published) {
      return t('notify_team_unpublished_subject_tooltip', {
        subject: curriculumTermSingular,
      });
    } else if (!elementFinished) {
      return t('notify_team_no_finished_elements_tooltip', {
        topic: topicTermSingular.toLowerCase(),
      });
    } else {
      return '';
    }
  }, [elementFinished, published, curriculumTermSingular, topicTermSingular]);

  const curriculumPrintRoute = routes.curriculumPrint({ slug, id: curriculumId }).href;
  const navigateToPrintView = () => window.open(curriculumPrintRoute, '_blank');

  const copy = () => {
    copyUrlLink({
      path: routes.curriculumShow({ slug, id: curriculumId }).href,
      flashText: t('copy_link_flash'),
    });
  };

  const curriculumRestrictedToModify = ability.can(
    'read',
    `CurriculumRestrictedToModify-${curriculumId}`
  );

  const options: ThreeDotMenuOptionProps[] = [
    {
      title: t('edit_subject_settings', {
        subject: curriculumTermSingular.toLowerCase(),
      }),
      onClick: () => {
        dispatch({ type: 'edit', showEdit: true });
      },
      id: 'td-curriculum-edit-edit',
      iconName: 'cog',
      iconWeight: 'regular',
      visible: ability.can('update', `EditCurriculum-${curriculumId}`),
    },
    {
      title: t('copy_link'),
      onClick: copy,
      id: 'td-curriculum-edit-copy-link',
      iconName: 'link',
      iconWeight: 'regular',
      visible: true,
    },
    {
      title: t('view_subject_report', { subject: curriculumTermSingular.toLowerCase() }),
      onClick: () => reportRoute.push(),
      id: 'td-curriculum-edit-view-report',
      iconName: 'file-lines',
      iconWeight: 'regular',
      visible: !!reportsAccess && (completionRequired || reportsPaywalled),
    },
    {
      title: t('print'),
      onClick: () => navigateToPrintView(),
      id: 'td-subject-show-print-pdf',
      iconName: 'file-export',
      iconWeight: 'regular',
      visible: Boolean(canExportPdf && curriculumPrintViewEnabled),
    },
    {
      title: t('email_pdf'),
      onClick: () => dispatch({ type: 'export', showExport: true }),
      id: 'td-subject-export-pdf',
      iconName: 'envelope',
      iconWeight: 'regular',
      visible: canExportPdf,
    },
    {
      title: t('share_changes'),
      onClick: () => dispatch({ type: 'share', showShare: true }),
      id: 'td-subject-notify-team',
      iconName: 'paper-plane',
      iconWeight: 'regular',
      visible: true,
      tooltipText: notifyTeamToolTipText,
      isDisabled: !published || !elementFinished,
    },
    {
      title: t('delete_subject', { subject: curriculumTermSingular.toLowerCase() }),
      onClick: () => setShowDeleteCurriculumModal(true),
      id: 'td-curriculum-edit-delete',
      isDisabled: curriculumRestrictedToModify,
      iconName: 'trash-alt',
      iconWeight: 'regular',
      tooltipText: curriculumRestrictedToModify ? t('disabled_by_e_signature') : '',
      visible: canDeleteSubject,
    },
  ];

  return (
    <>
      <ThreeDotMenu menuOptions={options} {...propsToPass} />
      {editModal && (
        <div data-disabled={containsSignature && completionRequired} id='curriculum-modal'>
          <TeammateModalProvider>
            <CurriculumModal
              closeRequest={() => dispatch({ type: 'edit', showEdit: false })}
              curriculumId={curriculumId}
              sector={sector}
            />
          </TeammateModalProvider>
        </div>
      )}
      {shareModal && (
        <ShareChangesModal
          closeRequest={() => dispatch({ type: 'share', showShare: false })}
          completionRequired={completionRequired}
          curriculumId={curriculumId}
          curriculumTitle={curriculumTitle}
          dataSource='curriculum'
        />
      )}
      {exportModal && (
        <ExportPDFModal
          closePdfModal={() => dispatch({ type: 'export', showExport: false })}
          id={curriculumId}
          title={curriculumTitle}
        />
      )}
    </>
  );
};

export default Menu;
