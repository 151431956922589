import { Formik, FormikErrors } from 'formik';
import React, { useMemo, useState } from 'react';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import useSlideout from '../../../../hooks/useSlideout';
import capitalize from '../../../../lib/capitalize';
import convertToOption from '../../../../lib/convertToOption';
import initTranslations from '../../../../lib/initTranslations';
import { useUpdateSurveyMutation } from '../../../../redux/services/resourceApis/surveys/surveysApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { formatOptions } from '../../../design_system/core/CoreSelectField/CoreSelectField';
import FieldLabel from '../../../design_system/core/FieldLabel';
import { OptionWithSourceBadgeProps } from '../../../design_system/core/SelectOption/types';
import MultiSelectField from '../../../design_system/Triage/fields/MultiSelectField';
import InputField from '../../../design_system/Triage/InputField';
import RadioField from '../../../design_system/Triage/RadioField';
import {
  Form,
  StyledInputField,
  StyledSurveyOptionsWrapper,
  SurveyOptionsDataWrapper,
  SurveySlideoutFooter,
} from './styles';
import { FormValues, SurveySlideoutOptionsProps } from './types';

const t = initTranslations('editor.create_survey');

const SurveySlideoutOptions = ({ survey }: SurveySlideoutOptionsProps) => {
  const [isFlashMessageEnabled, setIsFlashMessageEnabled] = useState(false);
  const { close } = useSlideout('survey-options-slideout');

  const orderOptions = [
    {
      value: 'in_order',
      text: t('in_set_order'),
    },
    {
      value: 'randomize',
      text: capitalize(t('random')),
    },
  ];
  const baseNumberOfQuestionOptions = [
    {
      value: 'all',
      text: t('all'),
    },
    {
      value: 'random',
      text: t('custom_amount'),
    },
  ];

  const userOptions: OptionWithSourceBadgeProps[] = useMemo(() => {
    return formatOptions(convertToOption(survey.users)).map((option) => {
      const user = survey.users.find((user) => String(user.id) === option.value);
      return {
        ...option,
        hasAvatar: true,
        metaValue: user?.email,
        avatarName: user?.name,
        avatarImage: user?.avatar,
        sourceName: 'users',
      };
    });
  }, [survey.users]);

  const validateForm = ({
    questions_display,
    questions_display_number,
    minimum_score,
  }: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (
      questions_display === 'random' &&
      (questions_display_number < 1 || questions_display_number > survey.questions.length)
    ) {
      errors.questions_display_number = t('questions_display_number_error');
    }

    if (!minimum_score) {
      errors.minimum_score = t('score_value_is_incorrect');
    } else if (minimum_score < 0) {
      errors.minimum_score = t('value_less_than_min');
    } else if (minimum_score > 100) {
      errors.minimum_score = t('value_greater_than_max');
    }

    return errors;
  };

  const [updateSurvey, updateSurveyResult] = useUpdateSurveyMutation();
  const initialValues: FormValues = {
    user_ids: survey.userIds,
    questions_order: survey.questionsOrder,
    questions_display: survey.questionsDisplay,
    questions_display_number: survey.questionsDisplayNumber || 1,
    minimum_score: survey.minimumScore,
  };

  const handleSubmit = (values: FormValues) => {
    const payload = {
      ...values,
      id: survey.id,
      name: survey.name,
    };
    updateSurvey(payload);
    setIsFlashMessageEnabled(true);
  };

  const handleFlashSuccess = () => {
    close();
    setIsFlashMessageEnabled(false);
  };

  useDisplayFlashOnResponse({
    result: updateSurveyResult,
    successMessage: t('successfully_updated'),
    successFunction: handleFlashSuccess,
    skip: !isFlashMessageEnabled,
    successFlashType: 'success',
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validateForm}>
      {(formikProps) => {
        const handleNonDefaultSelected = (user_ids: string[]) => {
          formikProps.setFieldValue('user_ids', user_ids);
        };

        const modifiedNumberOfQuestionOptions = baseNumberOfQuestionOptions.map((option) => {
          if (option.value === 'random') {
            return {
              ...option,
              text: `${t('custom_amount')} (${
                formikProps.values.questions_order === 'in_order' ? t('in_order') : t('random')
              })`,
            };
          }
          return option;
        });

        return (
          <Form onSubmit={(e) => e.preventDefault()}>
            <SurveyOptionsDataWrapper>
              <StyledSurveyOptionsWrapper>
                <MultiSelectField
                  defaultValue={null}
                  fieldLabelText={t('email_results_to')}
                  menuPosition='absolute'
                  onNonDefaultSelected={handleNonDefaultSelected}
                  options={userOptions}
                  placeholder={t('search_by_name')}
                  size='md'
                  value={formikProps.values.user_ids}
                />
              </StyledSurveyOptionsWrapper>

              <StyledSurveyOptionsWrapper>
                <RadioField
                  fieldLabelText={t('question_order')}
                  name='questions_order'
                  onChange={formikProps.handleChange}
                  options={orderOptions}
                  orientation='vertical'
                  value={formikProps.values.questions_order}
                />
              </StyledSurveyOptionsWrapper>

              <StyledSurveyOptionsWrapper>
                <RadioField
                  fieldLabelText={t('number_of_questions_to_display')}
                  name='questions_display'
                  onChange={formikProps.handleChange}
                  options={modifiedNumberOfQuestionOptions}
                  orientation='vertical'
                  value={formikProps.values.questions_display}
                />

                {formikProps.values.questions_display === 'random' && (
                  <StyledInputField
                    errorText={
                      formikProps.touched.questions_display_number &&
                      formikProps.errors.questions_display_number
                    }
                    min={1}
                    name='questions_display_number'
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    placeholder={t('enter_number_of_questions')}
                    type='number'
                    value={formikProps.values.questions_display_number}
                  />
                )}
              </StyledSurveyOptionsWrapper>

              <StyledSurveyOptionsWrapper>
                <FieldLabel text={t('passing_score')} />
                <InputField
                  errorText={formikProps.touched.minimum_score && formikProps.errors.minimum_score}
                  name='minimum_score'
                  onBlur={formikProps.handleBlur}
                  onChange={formikProps.handleChange}
                  placeholder='0 - 100'
                  type='number'
                  value={formikProps.values.minimum_score}
                />
              </StyledSurveyOptionsWrapper>

              <SurveySlideoutFooter>
                <DefaultButton
                  buttonType='primary'
                  disabled={false}
                  fullWidth
                  id='survey-submit-options-button'
                  onClick={() => formikProps.handleSubmit()}
                  size='md'
                  text={t('save')}
                  type='submit'
                />
              </SurveySlideoutFooter>
            </SurveyOptionsDataWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SurveySlideoutOptions;
