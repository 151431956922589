import React, { useCallback } from 'react';

import { ContentSearchProvider } from '../../../../../contexts/ContentSearchContext';
import { EmbedlyProvider } from '../../../../../contexts/EmbedlyProvider';
import { ImageFlyoutProvider } from '../../../../../contexts/ImageFlyoutContext';
import { usePostStepImageMutation } from '../../../../../redux/services/axiosService';
import EmbedCaptureFlyout from '../../plugins/EmbedCaptureFlyout/EmbedCaptureFlyout';
import EmbedLinkFlyout from '../../plugins/EmbedLink/EmbedLinkFlyout';
import EmbedlyFlyout from '../../plugins/Embedly/EmbedlyFlyout/EmbedlyFlyout';
import EmbedTrainualFlyout from '../../plugins/EmbedTrainual/EmbedTrainualFlyout';
import FileFlyout from '../../plugins/File/FileFlyout';
import EditorImageFlyout from '../../plugins/Image/EditorImageFlyout';
import LoomRecordButton from '../../plugins/Loom/LoomRecordButton';
import TableFlyout from '../../plugins/Table/TableFlyout';
import VideoFlyout from '../../plugins/Video/VideoFlyout';
import ComposeToolbarButton from '../buttons/components/insert/ComposeToolbarButton';
import DividerToolbarButton from '../buttons/components/insert/DividerToolbarButton';
import EmojiToolbarButton from '../buttons/components/insert/EmojiToolbarButton';
import IconToolbarButton from '../buttons/components/insert/IconToolbarButton';
import IframeButton from '../buttons/components/insert/IframeButton';
import { ToolbarContainer } from '../ToolbarStyles';

const InsertToolbar = () => {
  const [uploadImage, result] = usePostStepImageMutation();
  const uploadImageCallback = useCallback(
    (image: FormData) => {
      uploadImage(image);
    },
    [uploadImage]
  );

  return (
    <ToolbarContainer id='insert-toolbar'>
      <EmbedlyFlyout />
      <EmbedlyProvider>
        <LoomRecordButton />
      </EmbedlyProvider>
      <ComposeToolbarButton />
      <EmbedlyProvider>
        <VideoFlyout />
      </EmbedlyProvider>
      <ImageFlyoutProvider uploadImage={uploadImageCallback} uploadResult={result}>
        <EditorImageFlyout
          menuId='editor-image-flyout'
          menuItems={['upload', 'stock_image', 'giphy']}
        />
      </ImageFlyoutProvider>
      <EmbedlyProvider>
        <EmbedLinkFlyout />
      </EmbedlyProvider>
      <ContentSearchProvider>
        <EmbedTrainualFlyout />
      </ContentSearchProvider>
      <EmbedCaptureFlyout />
      <FileFlyout />
      <TableFlyout />
      <EmojiToolbarButton />
      <IconToolbarButton />
      <DividerToolbarButton />
      <IframeButton />
    </ToolbarContainer>
  );
};

export default InsertToolbar;
