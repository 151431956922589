import React, { useMemo, useState } from 'react';

import { useCheckSimplePricingOrSimplePricingV3Plan } from '../../../../../hooks/billing/useCheckSimplePricingOrSimplePricingV3Plan';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import delegationPlannerUncheckedImage from '../../../../../images/addons/trainual_plus/delegation_planner_opt_in.svg';
import plusOptedInImage from '../../../../../images/addons/trainual_plus/opted_in.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { useCreateTrainualPlusOptInMutation } from '../../../../../redux/services/resourceApis/trainualPlusOptIns/trainualPlusOptInsApi';
import {
  AcknowledgementLabel,
  AcknowledgementText,
  ActionButton,
  ActionsContainer,
  BannerContainer,
  BannerWrapper,
  CheckboxIcon,
  ContentWrapper,
  InfoImage,
  OptedInImage,
  StyledIconButton,
  Subtitle,
  Title,
} from './styles';

const t = initTranslations('delegation_planner.banners.trainual_plus_banner');

const TrainualPlusDelegationPlannerBanner = () => {
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [createOptIn, { isLoading: createOptInLoading }] = useCreateTrainualPlusOptInMutation();

  const { permission } = useCurrentUser();
  const isSimplePricingPlan = useCheckSimplePricingOrSimplePricingV3Plan();
  const {
    optedInForTrainualPlus: isOptedIn,
    splitFeatures: { trainualPlusDpBannerEnabled },
  } = useCurrentAccount();
  const isBillingAdmin = permission === 'billing_admin';
  const isAdmin = permission === 'admin';
  const checkboxIconName = isAcknowledged ? 'square-check' : 'square';
  const checkboxIconWeight = isAcknowledged ? 'solid' : 'regular';
  const displayBanner = trainualPlusDpBannerEnabled && (isBillingAdmin || isAdmin);
  const displayActions = !isAdmin && !isOptedIn;

  const subtitleTranslation = useMemo(() => {
    if (isOptedIn) return t('opted_in_subtitle');

    return isAdmin ? t('admin_subtitle') : t('info_subtitle');
  }, [isAdmin, isOptedIn]);

  if (!displayBanner) return null;

  const toggleAcknowledgement = () => {
    setIsAcknowledged(!isAcknowledged);
  };

  const handleCreateOptIn = () => {
    createOptIn();
  };

  return (
    <BannerWrapper className='trainual-plus-opt-in-banner'>
      <BannerContainer>
        {isOptedIn ? (
          <OptedInImage src={plusOptedInImage} />
        ) : (
          <InfoImage src={delegationPlannerUncheckedImage} />
        )}
        <ContentWrapper>
          <Title>{t(isOptedIn ? 'opted_in_title' : 'info_title')}</Title>
          <Subtitle>{subtitleTranslation}</Subtitle>
          {displayActions && (
            <ActionsContainer>
              {isSimplePricingPlan && (
                <>
                  <AcknowledgementLabel>
                    <StyledIconButton
                      ariaLabel=''
                      customIcon={
                        <CheckboxIcon
                          isChecked={isAcknowledged}
                          name={checkboxIconName}
                          size='md'
                          weight={checkboxIconWeight}
                        />
                      }
                      id='trainual-plus-acknowledgement-checkbox-button'
                      onClick={toggleAcknowledgement}
                    />
                    <AcknowledgementText>{t('acknowledgement')}</AcknowledgementText>
                  </AcknowledgementLabel>
                  <ActionButton
                    disabled={!isAcknowledged}
                    id='trainual-plus-opt-in-button'
                    loading={createOptInLoading}
                    onClick={handleCreateOptIn}
                    text={t('opt_in')}
                  />
                </>
              )}
              {!isSimplePricingPlan && (
                <ActionButton
                  behavesAs='a'
                  href='mailto:support@trainual.com?subject=Interest in Trainual+'
                  id='trainual-plus-talk-to-us-button'
                  target='_blank'
                  text={t('talk_to_us')}
                />
              )}
            </ActionsContainer>
          )}
        </ContentWrapper>
      </BannerContainer>
    </BannerWrapper>
  );
};

export default TrainualPlusDelegationPlannerBanner;
