import React from 'react';
import styled from 'styled-components';

import { CurriculumElementsProvider } from '../../../../../../contexts/CurriculumElementsContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useSlideout from '../../../../../../hooks/useSlideout';
import initTranslations from '../../../../../../lib/initTranslations';
import { setSelectedContentType } from '../../../../../../redux/domains/curriculums/curriculumsSlice';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { useGetCurriculumForEditQuery } from '../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { ElementKind, elementKinds } from '../../../../../../types/CurriculumElement';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import SplitButton from '../../../../../design_system/buttons/SplitButton';
import { MenuOptionProps } from '../../../../../design_system/core/MenuOptions';
import useActiveMenuHandler from '../../../../publicApplication/utils/useActiveMenuHandler';
import { determineText } from '../../../../shared/CurriculumElements/CurriculumElementActionRow/CurriculumElementActionRow';
import { GlowingIconButton } from '../../../CurriculumEdit/AIOutliner/GlowingIconButton/GlowingIconButton';
import CurriculumElementsTable from '../../../CurriculumEdit/CurriculumElementsTable/CurriculumElementsTable';

const OutlineWrapper = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd1};
  padding-bottom: 8rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const AddContentButton = styled(SplitButton)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const t = initTranslations('curriculum_outline_view');

export interface Props {
  curriculumId: number;
}

const CurriculumOutline = ({ curriculumId }: Props) => {
  const { open, close, isOpen } = useSlideout('ai-outliner');
  const { data } = useGetCurriculumForEditQuery(curriculumId);
  const {
    splitFeatures: { contentFlowchartsEnabled },
  } = useCurrentAccount();
  const {
    step: { plural: stepTermPlural },
    curriculum: { singular: subjectTermSingular },
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const menuId = 'subject-outline-add-content-menu';
  const { closeMenu, setActiveMenuId, isMenuOpen } = useActiveMenuHandler({ menuId });
  const dispatch = useAppDispatch();

  const scrollToCreateTopicInput = () => {
    const inputElement = document.querySelector<HTMLInputElement>(
      'input[name="new-curriculum-element-title"]'
    );

    if (inputElement) {
      const scrollIntoViewPromise = new Promise<void>((resolve) => {
        const observer = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            observer.disconnect();
            resolve();
          }
        });
        observer.observe(inputElement);
      });

      // Scroll the element into view
      inputElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // When the scroll is done, focus the input element
      scrollIntoViewPromise.then(() => {
        setTimeout(() => {
          inputElement.focus();
        }, 300);
      });
    }
  };

  if (!data) return <></>;

  const dropdownText = determineText(topicTermSingular, stepTermPlural, subjectTermSingular);

  const addContentMenuOptions: MenuOptionProps[] = elementKinds
    .filter((kind) => contentFlowchartsEnabled || kind !== 'flowchart')
    .map((kind: ElementKind) => {
      const { iconName, title, description } = dropdownText[kind];

      return {
        title,
        description,
        onClick: () => {
          scrollToCreateTopicInput();
          dispatch(setSelectedContentType(elementKinds.indexOf(kind)));
        },
        iconName,
        id: `add-content-${kind}-dropdown`,
      };
    });

  const mainButtonOnClick = () => {
    scrollToCreateTopicInput();
    dispatch(setSelectedContentType(0));
  };

  const { locked, signaturable } = data;
  return (
    <OutlineWrapper>
      <CurriculumElementsProvider>
        <ButtonsWrapper>
          <GlowingIconButton iconWithLabel onClick={() => (isOpen ? close() : open())} />
          <AddContentButton
            closeActiveMenu={closeMenu}
            isActiveMenu={isMenuOpen}
            mainButtonOnClick={mainButtonOnClick}
            mainMenuButtonId='subject-outline-add-content-button'
            menuId={menuId}
            menuOptions={addContentMenuOptions}
            setActiveMenu={() => setActiveMenuId(menuId)}
            text={t('add_content')}
          />
        </ButtonsWrapper>
        <CurriculumElementsTable
          curriculumId={curriculumId}
          isLocked={locked}
          signaturable={signaturable}
          userAccess='edit'
        />
      </CurriculumElementsProvider>
    </OutlineWrapper>
  );
};

export default CurriculumOutline;
