import React from 'react';
import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';

const StyledCheckmark = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledCheckmarkIcon = styled.span`
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  font-size: 0.75rem;
  text-align: center;
  line-height: 1.125rem;
  background-color: ${({ theme: { vars } }) => vars.stateSuccess};
  color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

const StyledCheckmarkText = styled.span`
  margin-left: 0.375rem;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.3;
`;

export interface Props {
  text?: string;
  className?: string;
}

const Checkmark = ({ text, className }: Props) => {
  return (
    <StyledCheckmark className={className}>
      <StyledCheckmarkIcon>
        <Icon name='check' size='2xs' weight='solid' />
      </StyledCheckmarkIcon>
      {text && <StyledCheckmarkText>{text}</StyledCheckmarkText>}
    </StyledCheckmark>
  );
};

export default Checkmark;
