import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { setShouldScrollBoardLeft } from '../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DetailedAvatar, {
  DependentAvatarProps,
} from '../../../design_system/display/DetailedAvatar/DetailedAvatar';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import AddResponsibilityCard from '../AddResponsibilityCard/AddResponsibilityCard';
import BoardColumnTools from '../BoardColumnTools/BoardColumnTools';
import { EDragElements } from '../BoardTrack/types';
import GroupResponsibilityCard from '../GroupResponsibilityCard/GroupResponsibilityCard';
import ResponsibilityDraggableComponent from '../ResponsibilityDraggableComponent/ResponsibilityDraggableComponent';
import { setGrabCursor } from '../shared/utils/setGrabCursor';
import {
  AddResponsibilityElement,
  AddResponsibilityWrapper,
  BoardColumnContainer,
  BoardColumnHeader,
  BoardColumnTrack,
  ResponsibilityCardsGlobalListContainer,
  ResponsibilityCardsNonGroupListContainer,
} from './styles';
import { BoardColumnProps } from './types';
import { isNonGroupListIntersected } from './utils';

const t = initTranslations('delegation_planner.card');

const BoardColumn: FC<BoardColumnProps> = ({
  column,
  holderHeight,
  isDragging: onPropsIsDragging,
}) => {
  const [isOpenAddResponsibility, setIsOpenAddResponsibility] = useState(false);
  const {
    responsibility: { singular: responsibilitySingular, plural: responsibilityPlural },
  } = useAccountTerminology();

  const { lastAddedColumnableId } = useAppSelector((state) => state.delegationPlanner);
  const dispatch = useAppDispatch();
  const { slug } = useCurrentAccount();

  const directResponsibilitiesCount = column.boardColumnResponsibilities.length;
  const groupResponsibilitiesCount = useMemo(
    () =>
      column.groupResponsibilities.reduce(
        (sum, group) => sum + group.boardColumnResponsibilities.length,
        0
      ),
    [column.groupResponsibilities]
  );
  const totalResponsibilitiesCount = directResponsibilitiesCount + groupResponsibilitiesCount;

  const addResponsibilityWrapperRef = useRef<HTMLDivElement>(null);
  const responsibilityCardsNonGroupListContainerRef = useRef<HTMLDivElement | null>(null);

  const isResponsibilityCardsNonGroupContainerIntersected = isNonGroupListIntersected(
    responsibilityCardsNonGroupListContainerRef
  );

  const isUserTypeCard = column.columnType === 'User';
  const avatarProps = (
    avatarImage: string | undefined
  ): DependentAvatarProps & { isGroupAvatar?: boolean } => {
    if (isUserTypeCard) {
      return { avatarImage };
    } else {
      return { avatarIcon: 'users' };
    }
  };
  const scrollToAddResponsibilityWrapper = () => {
    setTimeout(() => {
      addResponsibilityWrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 400);
  };

  const toggleAddResponsibilityClick = () => {
    setIsOpenAddResponsibility(!isOpenAddResponsibility);
    scrollToAddResponsibilityWrapper();
  };

  const responsibilitiesIds = useMemo(
    () => column.boardColumnResponsibilities.map((r) => r.id),
    [column.boardColumnResponsibilities]
  );

  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id: column.id,
    data: {
      type: EDragElements.Column,
      column,
      isTargetListIntersected: isResponsibilityCardsNonGroupContainerIntersected,
      targetListRef: responsibilityCardsNonGroupListContainerRef,
    },
  });

  const handleAvatarClick = () => {
    const avatarRoute = isUserTypeCard
      ? routes.userProfile({
          slug,
          id: column.columnableId,
          breadcrumb: routes.delegationPlanner({ slug }),
        })
      : routes.group({
          slug,
          id: column.columnableId,
          tab: 'overview',
        });

    routeTo(avatarRoute);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    if (isDragging) {
      setGrabCursor(isDragging);
    }
  }, [isDragging]);

  useEffect(() => {
    if (column.columnableId === lastAddedColumnableId) {
      dispatch(setShouldScrollBoardLeft(true));
    }
    // Execute only on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDragging) {
    return <BoardColumnTrack ref={setNodeRef} style={style}></BoardColumnTrack>;
  }

  return (
    <BoardColumnTrack ref={setNodeRef} style={style}>
      <BoardColumnContainer>
        <BoardColumnHeader
          {...attributes}
          {...listeners}
          isDragging={onPropsIsDragging}
          isGroupAvatar={!isUserTypeCard}
        >
          <DetailedAvatar
            id='detailed-avatar-header-board-card-element'
            label={column.columnLabel}
            onAvatarClick={handleAvatarClick}
            size='md'
            subLabel={t('responsibilities_count', {
              count: totalResponsibilitiesCount,
              responsibility: responsibilitySingular.toLowerCase(),
              responsibilities: responsibilityPlural.toLowerCase(),
            })}
            {...avatarProps(column.avatarUrl)}
          />

          <BoardColumnTools
            columnId={column.id}
            hideThreeDot={onPropsIsDragging}
            totalWorkingTime={column.workingTimeTotalPerWeek}
          />
        </BoardColumnHeader>

        <ResponsibilityCardsGlobalListContainer>
          {column.groupResponsibilities.map((groupResponsibility) => (
            <GroupResponsibilityCard
              columnId={column.id}
              groupResponsibilityCard={groupResponsibility}
              key={groupResponsibility.groupId}
            />
          ))}

          <ResponsibilityCardsNonGroupListContainer
            ref={responsibilityCardsNonGroupListContainerRef}
          >
            <SortableContext items={responsibilitiesIds}>
              {column.boardColumnResponsibilities.map((responsibilityCard) => (
                <ResponsibilityDraggableComponent
                  boardColumnResponsibility={responsibilityCard}
                  columnId={column.id}
                  holderHeight={holderHeight}
                  key={responsibilityCard.id}
                />
              ))}
            </SortableContext>
          </ResponsibilityCardsNonGroupListContainer>
        </ResponsibilityCardsGlobalListContainer>

        <AddResponsibilityWrapper ref={addResponsibilityWrapperRef}>
          {!onPropsIsDragging && (
            <AddResponsibilityCard
              columnId={column.id}
              hideCard={() => setIsOpenAddResponsibility(false)}
              isOpen={isOpenAddResponsibility}
            />
          )}

          <AddResponsibilityElement
            isVisible={!isOpenAddResponsibility}
            onClick={toggleAddResponsibilityClick}
          >
            {t('add_responsibility', { responsibility: responsibilitySingular.toLowerCase() })}
          </AddResponsibilityElement>
        </AddResponsibilityWrapper>
      </BoardColumnContainer>
    </BoardColumnTrack>
  );
};

export default BoardColumn;
