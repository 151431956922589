import styled from 'styled-components';

import IconButton from '../../../../../../design_system/buttons/IconButton';
import Link from '../../../../../../design_system/Link';
import { mediaBreakpointPxLg } from '../../../../../../styled/Breakpoint';
import {
  MAIN_CONTENT_MAX_WIDTH,
  mainSectionStyles,
} from '../../../../../shared/FullScreenOverlay/styles';

export const PaymentMethodSectionWrapper = styled.div`
  justify-content: flex-start;
  flex-direction: column;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} ${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerMd2}`};

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding: ${({ theme: { constants } }) =>
      `${constants.spacerSm3} ${constants.spacerLg1} ${constants.spacerMd2} ${constants.spacerMd3}`};
  }

  ${mainSectionStyles};
`;

export const PaymentMethodHeaderWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
`;

export const PaymentMethodSectionContent = styled.div`
  width: 100%;
  max-width: ${MAIN_CONTENT_MAX_WIDTH};
  margin: 0 auto;
`;

export const BackToConfigurePlanLinkWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: 2.375rem;
`;

export const ArrowIconButton = styled(IconButton)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  padding: ${({ theme: { constants } }) =>
    `0 calc(${constants.spacerSm3} + ${constants.spacerSm2}) 0 0`};
  cursor: pointer;
`;

// DS Override - to match design
export const StyledLink = styled(Link)`
  &,
  &:hover {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }
`;
