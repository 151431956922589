import React, { useMemo } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import LinkTabs, { LinkTab } from '../../../../design_system/navigation/LinkTabs';
import { routes } from '../../../publicApplication/applicationRouter';
import { TabsWrapper } from './styles';
import { CurriculumsAssignmentTabsProps } from './types';

const t = initTranslations('home.curriculum_assignments.tabs');

const CurriculumsAssignmentsTabs = ({
  incompleteAssignmentsCount,
  completedAssignmentsCount,
  ownedAssignmentsCount,
  referenceAssignmentsCount,
  recentAssignmentsCount,
}: CurriculumsAssignmentTabsProps) => {
  const { slug } = useCurrentAccount();

  const tabs: LinkTab[] = useMemo(
    () => [
      {
        count: ` (${incompleteAssignmentsCount})`,
        name: t('to_do'),
        to: routes.home({ slug, kind: 'incomplete' }),
        is_beta: false,
      },
      {
        count: ` (${recentAssignmentsCount})`,
        name: t('recent'),
        to: routes.home({ slug, kind: 'recent' }),
        is_beta: false,
      },
      {
        count: ` (${ownedAssignmentsCount})`,
        name: t('owned_by_you'),
        to: routes.home({ slug, kind: 'owned_by_you' }),
        is_beta: false,
      },
      {
        count: ` (${completedAssignmentsCount})`,
        name: t('completed_curriculums'),
        to: routes.home({ slug, kind: 'completed' }),
        is_beta: false,
      },
      {
        count: ` (${referenceAssignmentsCount})`,
        name: t('reference_curriculums'),
        to: routes.home({ slug, kind: 'reference' }),
        is_beta: false,
      },
    ],
    [
      completedAssignmentsCount,
      incompleteAssignmentsCount,
      ownedAssignmentsCount,
      referenceAssignmentsCount,
      recentAssignmentsCount,
      slug,
    ]
  );

  return (
    <TabsWrapper>
      <LinkTabs id='home-tab' isUrlParams tabs={tabs} />
    </TabsWrapper>
  );
};

export default CurriculumsAssignmentsTabs;
