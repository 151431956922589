export type SurveyTextJsonType = {
  type: string;
  content: Array<{
    type: string;
    content?: Array<{
      text: string;
      type: string;
    }>;
    attrs?: Record<string, unknown>;
  }>;
};

type Props = {
  plainText: string;
  content?: string;
  contentJson: SurveyTextJsonType | null;
};

export const isMultimediaContentOnly = ({ plainText, content, contentJson }: Props) => {
  const hasNoPlainText = !plainText?.length;

  if (contentJson) {
    const hasTextJsonContent = contentJson.content && contentJson.content.length > 0;
    const isSingleEmptyParagraph =
      contentJson.content.length === 1 &&
      contentJson.content[0].type === 'paragraph' &&
      (!contentJson.content[0].content ||
        contentJson.content[0].content[0].text?.trim().length === 0);

    return hasNoPlainText && hasTextJsonContent && !isSingleEmptyParagraph;
  }

  return hasNoPlainText && !!content?.length;
};
