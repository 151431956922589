import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useDeleteSubjectMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';
import { routes } from '../../../publicApplication/applicationRouter';

interface Props {
  id: number;
  closeModal: () => void;
}

const t = initTranslations('curriculums.delete_modal');

const DeleteCurriculumConfirmationModal = ({ id, closeModal }: Props) => {
  const [deleteSubject, result] = useDeleteSubjectMutation();
  const { isLoading } = result;
  const { slug } = useCurrentAccount();
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();

  useDisplayFlashOnResponse({
    result,
    successFunction: () => {
      closeModal();
      routes.content({ slug, curriculum_deleted: true }).push();
    },
    errorMessage: t('flash_error', { subject: subjectTermSingular }),
  });

  return (
    <ConfirmationModal
      actionFunction={() => {
        deleteSubject({ id, isEditPageRequest: true });
      }}
      actionText={t('confirm', { subject: subjectTermSingular.toLowerCase() })}
      desktopSize='md'
      heapModalName='delete-curriculum-confirmation-modal'
      message={t('subtitle', { subject: subjectTermSingular.toLowerCase() })}
      onCloseRequest={closeModal}
      processing={isLoading}
      secondaryButtonText={t('cancel')}
      title={t('title', { subject: subjectTermSingular.toLowerCase() })}
    />
  );
};

export default DeleteCurriculumConfirmationModal;
