import styled from 'styled-components';

import Link from '../../../../../design_system/Link';

export const SignatureInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
`;

export const InfoTitle = styled.div`
  ${({ theme: { constants, vars } }) => ({
    color: vars.textDefault,
    fontWeight: constants.fontSemibold,
  })};
`;

export const BillingDetails = styled.p`
  flex-direction: column;
`;

export const StyledLink = styled(Link)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;
