import React from 'react';

import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import initTranslations from '../../../../../../../lib/initTranslations';
import { reportsApi } from '../../../../../../../redux/services/resourceApis/reports/reportsApi';
import ConfirmationModal from '../../../../../../design_system/overlays/modals/ConfirmationModal';

const t = initTranslations('reports.people_csv');

type BulkExportCsvReportModalProps = {
  userIds: number[];
  onCancel: () => void;
  onConfirm: () => void;
};

const BulkExportCsvReportModal = ({
  userIds,
  onCancel,
  onConfirm,
}: BulkExportCsvReportModalProps) => {
  const [getUserCSV, result] = reportsApi.useLazyGetAllUsersCSVQuery();
  const { isLoading } = result;
  const { email } = useCurrentUser();

  return (
    <ConfirmationModal
      actionFunction={() => {
        getUserCSV({ userIds });
        onConfirm();
      }}
      actionText={t('primary_button')}
      desktopSize='lg'
      heapModalName='confirm-csv-modal'
      message={t('message', { email })}
      onCloseRequest={onCancel}
      processing={isLoading}
      secondaryButtonText={t('cancel')}
      title={t('title')}
    />
  );
};

export default BulkExportCsvReportModal;
