import React from 'react';

import { SurveyError } from '../../../../../redux/services/resourceApis/surveys/types';
import { SurveyStatisticsWrapper } from '../../../shared/preview/PreviewSurveyStatistics/styles';
import SurveyErrorPreview from './SurveyErrorPreview';
import SurveyQuestionErrorPreview from './SurveyQuestionErrorPreview';
import { ErrorComponents } from './types';

const ErrorsPreview = ({ errors }: { errors: SurveyError[] }) => {
  const errorTypeComponentMap: ErrorComponents = {
    survey: SurveyErrorPreview,
    survey_question: SurveyQuestionErrorPreview,
  };

  return (
    <SurveyStatisticsWrapper>
      {errors.map((error) => {
        const ErrorComponent = errorTypeComponentMap[error.errorType as keyof ErrorComponents];
        return <ErrorComponent error={error} key={`survey-error-${error.attribute}`} />;
      })}
    </SurveyStatisticsWrapper>
  );
};

export default ErrorsPreview;
