import { Editor, JSONContent, useEditor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import { useGetStepQuery } from '../../../../redux/services/resourceApis/steps/stepsApi';
import { GetStepResponse } from '../../../../redux/services/resourceApis/steps/types';
import { EditorExtensions } from '../shared/constants/extensions';

type EditorContent = {
  editor: Editor | null;
  isAILoading: boolean;
  stepData: GetStepResponse | undefined;
};

type EditorContentProps = {
  isEditable: boolean;
  stepId: number;
};

export const useEditorContent = ({ stepId, isEditable }: EditorContentProps): EditorContent => {
  const [isAILoading, setIsAILoading] = useState(false);
  const { data: stepData } = useGetStepQuery(stepId, { refetchOnMountOrArgChange: true });
  const { configs: privateConfigs } = usePrivateConfigs();
  const { palettes } = useTheme();

  const [initialContent, setInitialContent] = useState<
    { content: JSONContent | string; id: number } | undefined
  >();

  // This is necessary to not have the editor re-initialize when the step data changes through Redux mutations
  // but still allow for things like reading time to propagate through
  useEffect(() => {
    // If there is content from API and no initial content, set initial content (e.g. when loading the first step)
    if (stepData?.content && !initialContent) {
      setInitialContent({ content: stepData.content, id: stepData.id });
    }

    // If there is content from API and initial content, check if they are the same step (e.g. when switching steps)
    else if (stepData?.content && initialContent?.id !== stepData.id) {
      setInitialContent({ content: stepData.content, id: stepData.id });
    }

    // If there is no content and there is initial content, clear initial content (e.g. when deleting a step + adding a new step)
    else if (!stepData?.content && initialContent) {
      setInitialContent(undefined);
    }
  }, [initialContent, stepData]);

  const editor = useEditor(
    {
      content: initialContent?.content,
      editorProps: {
        attributes: {
          translate: isEditable ? 'no' : 'yes',
        },
      },
      onCreate: (editor) => {
        if (!!initialContent?.content) {
          editor.editor.commands.setContent(initialContent.content);
        }
      },
      extensions: isEditable
        ? EditorExtensions({
            isEditable: true,
            aiAppId: privateConfigs['TIPTAP_AI_APP'],
            aiToken: privateConfigs['TIPTAP_AI_SECRET'],
            setIsAILoading,
            palettes,
          })
        : EditorExtensions({
            isEditable: false,
            palettes,
          }),
      autofocus: true,
      editable: isEditable,
    },
    [stepId, initialContent, isEditable]
  );

  return { editor, isAILoading, stepData };
};
