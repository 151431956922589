import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import Icon from '../../../../../../design_system/display/icons/Icon';
import {
  CourseEmojiWrapper as IconWrapper,
  CourseTitle as Title,
  TitleWrapper,
} from '../../shared/styles';

const t = initTranslations('e_signature.container');

const ESignatureTitle = () => {
  return (
    <TitleWrapper>
      <IconWrapper>
        <Icon name='signature' size='lg' weight='regular' />
      </IconWrapper>
      <Title>{t('title')}</Title>
    </TitleWrapper>
  );
};

export default ESignatureTitle;
