import React from 'react';

import DownloadCsvReport from '../../../DownloadCsvReport/DownloadCsvReport';

type ElementDownloadCsvReportProps = {
  downloadCsvDocument: () => void;
};

const ElementDownloadCsvReport = ({ downloadCsvDocument }: ElementDownloadCsvReportProps) => {
  return (
    <DownloadCsvReport
      btnSize='md'
      downloadCsvDocument={downloadCsvDocument}
      id='element-button-download-csv'
    />
  );
};

export default ElementDownloadCsvReport;
