import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import { ActivityAssociationParams, Owner } from '../../activities/types';
import NotificationUserName from '../NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';

const t = initTranslations('notifications');

export type CurriculumAssignProps = {
  activityKey: string;
  trackable: ActivityAssociationParams;
  owner: Owner;
};

const Assign = ({ activityKey, owner, trackable }: CurriculumAssignProps) => {
  const { curriculum } = useContext(AccountTerminologyContext);
  const { slug } = useCurrentAccount();
  const ownerAssignment = ['curriculum.assign-owner', 'curriculum.assign_owner'].includes(
    activityKey
  );
  const curriculumTerm = curriculum.singular.toLowerCase();
  const messageText = ownerAssignment
    ? t('curriculum.assign_owner', { curriculum: curriculumTerm })
    : t(`${activityKey}`, { curriculum: curriculumTerm });

  const curriculumRoute = ownerAssignment
    ? routes.curriculumEdit({ slug, id: trackable.id })
    : routes.curriculumShow({ slug, id: trackable.id });

  const trackableText = (
    <>
      {trackable.is_active ? (
        <SourceBadge
          sourceName='curriculum'
          sourceRoute={curriculumRoute}
          sourceText={trackable.name}
        />
      ) : (
        <b>{trackable.name}</b>
      )}
    </>
  );

  return (
    <>
      <NotificationUserName user={owner} />
      <TextFragment>{messageText}</TextFragment>
      {trackableText}
    </>
  );
};

export default Assign;
