import React, { useCallback } from 'react';

import { RegisteredMenuId } from '../../../../lib/idRegistry';
import Poppable from '../../../Poppable';
import useActiveMenuHandler from '../../publicApplication/utils/useActiveMenuHandler';
import OutsideClickHandler from '../../shared/OutsideClickHandler';
import {
  ColorCircle,
  ColorCirclesWrapper,
  ColorPickerTriggerButton,
  PickerContainer,
  StyledIcon,
} from './styles';

export type Props = {
  id: string;
  menuId: RegisteredMenuId;
  colors: string[];
  className?: string;
  selectedColor: string;
  setColor: (color: string | { hex: string }) => void;
};

const SpecializationColorPicker = ({
  className,
  id,
  colors,
  selectedColor,
  setColor,
  menuId,
}: Props) => {
  const { closeMenu, handleMenuClick, isMenuOpen } = useActiveMenuHandler({
    menuId,
  });

  const handleOutsideClick = useCallback(
    (e: MouseEvent & { target: Element }) => {
      if (
        !(e.target as Element).classList.contains(
          'specialization-label-color-picker-trigger-button'
        )
      ) {
        closeMenu();
      }
    },
    [closeMenu]
  );

  return (
    <Poppable
      isOpen={isMenuOpen}
      item={
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <PickerContainer className={className} id={id}>
            <ColorCirclesWrapper>
              {colors.map((color) => (
                <ColorCircle
                  color={color}
                  key={color}
                  onClick={() => {
                    setColor(color);
                  }}
                >
                  {color === selectedColor && <StyledIcon name='check' size='xs' weight='solid' />}
                </ColorCircle>
              ))}
            </ColorCirclesWrapper>
          </PickerContainer>
        </OutsideClickHandler>
      }
      onClick={() => handleMenuClick()}
      placement='bottom-start'
      trigger={
        <ColorPickerTriggerButton
          ariaLabel={
            isMenuOpen ? 'Close specializations color picker' : 'Open specializations color picker'
          }
          buttonSize='sm'
          className='specialization-label-color-picker-trigger-button'
          color={selectedColor}
          name='chevron-down'
          weight='solid'
        />
      }
    />
  );
};

export default SpecializationColorPicker;
