import { animated, useSpring } from '@react-spring/web';
import React, { useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../lib/initTranslations';
import { useGetDirectoryGroupMembersQuery } from '../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupResponse } from '../../../../redux/services/resourceApis/groups/types';
import { SimpleUser } from '../../../../types/User';
import IconButton from '../../../design_system/buttons/IconButton';
import NoResults from '../../../design_system/Triage/NoResults';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { fontMd1 } from '../../../styled/TypeSystem';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import AvatarsGroup from '../../shared/AvatarsGroup/AvatarsGroup';
import { getChevronIconName } from '../../shared/helpers';
import UserCard from '../UserCard';
import { CardRow } from '../UserCards';

const ViewByHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const Title = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  ${TruncatedText({})};
  ${fontMd1};
`;

const Subtext = styled.span`
  color: ${(props) => props.theme.vars.textPlaceholder};
  margin-right: ${(props) => props.theme.constants.spacerMd2};
`;

const ChevronWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme: { constants } }) => constants.heightSm};
  width: 2rem;
  flex-shrink: 0;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ theme: { vars } }) => vars.foundationHover};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
`;

const ChildContainerRow = styled(animated.div)`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerSm3}`};
`;

const Spacing = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const t = initTranslations('people');

type UsersListProps = { group: GroupResponse };

const UsersList = ({ group: { id } }: UsersListProps) => {
  const ability = useCurrentUserAbilities();
  const { slug } = useCurrentAccount();

  const { data: groupResult } = useGetDirectoryGroupMembersQuery(id);
  const [groupUsers, setGroupUsers] = useState<SimpleUser[]>();

  useEffect(() => {
    if (groupResult) {
      setGroupUsers(groupResult);
    }
  }, [groupResult]);

  if (groupUsers?.length === 0 && ability.can('read', 'Groups'))
    return (
      <Spacing>
        <NoResults
          className='directory-group-no-results'
          darkImage=''
          heading={t('group_members.empty_state.header')}
          iconWidth='20%'
          lightImage=''
          minHeight='unset'
          showBorder={false}
          showImage={false}
          subHeaderCta={{
            action: () => routeTo(routes.group({ slug, id, tab: 'overview' })),
            text: t('group_members.empty_state.action_link'),
          }}
        />
      </Spacing>
    );

  return (
    <CardRow className='group-user-list'>
      {groupUsers && groupUsers.map((user, i) => <UserCard key={`${user.id}-${i}`} user={user} />)}
    </CardRow>
  );
};

type Props = {
  group: GroupResponse;
  sourceSubtext?: string;
};

const ViewByGroup = (props: Props) => {
  const { group, sourceSubtext } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [ref, bounds] = useMeasure();
  const containerStyle = useSpring({
    height: isOpen ? bounds.height : 0,
    config: { tension: 400, friction: 40 },
  });

  const { id, user_count, name, users } = group;

  return (
    <div className={`directory-group-${id}-container`}>
      <ViewByHeaderContainer className={`view-by-group-${id}`}>
        <ChevronWrapper>
          <IconButton
            ariaLabel={isOpen ? t('aria_label.show_content') : t('aria_label.hide_content')}
            buttonSize='sm'
            id={`toggle-expand-view-by-row-${id}`}
            name={getChevronIconName({ isActive: !isOpen, initialDirection: 'right' })}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            weight='light'
          />
        </ChevronWrapper>
        <Title>{name}</Title>
        {sourceSubtext && <Subtext>{sourceSubtext}</Subtext>}
        {user_count > 0 && (
          <AvatarsGroup i18Key='avatars' totalUserCount={user_count} users={users.slice(0, 3)} />
        )}
      </ViewByHeaderContainer>
      <ChildContainerRow style={containerStyle}>
        <div ref={ref}>{isOpen && <UsersList group={group} />}</div>
      </ChildContainerRow>
    </div>
  );
};

export default ViewByGroup;
