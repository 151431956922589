import React from 'react';

import BulkArchive from './BulkUserUpdate/BulkArchive';
import BulkExportCsvReportModal from './BulkUserUpdate/BulkExportCsvReportModal';
import BulkUnarchive from './BulkUserUpdate/BulkUnarchive';
import BulkUpdatePermissions from './BulkUserUpdate/BulkUpdatePermissions';
import { BulkUserManagementModalsFactoryProps } from './types';

const BulkUserManagementModalsFactory = ({
  modal = 'none',
  setBulkUserManagementModal,
  selectedUsersIds,
  onCloseRequest,
}: BulkUserManagementModalsFactoryProps) => {
  const closeBulkUserManagementModal = () => {
    setBulkUserManagementModal('none');
  };
  const closeBulkUserManagementMenu = () => {
    closeBulkUserManagementModal();
    onCloseRequest();
  };

  switch (modal) {
    case 'archive':
      return (
        <BulkArchive
          onClose={closeBulkUserManagementModal}
          onSuccess={closeBulkUserManagementMenu}
          selectedUsersIds={selectedUsersIds}
          type={modal}
        />
      );
    case 'unarchive':
      return (
        <BulkUnarchive
          onClose={closeBulkUserManagementModal}
          onSuccess={closeBulkUserManagementMenu}
          selectedUsersIds={selectedUsersIds}
          type={modal}
        />
      );
    case 'update-permissions':
      return (
        <BulkUpdatePermissions
          onClose={closeBulkUserManagementModal}
          onSuccess={closeBulkUserManagementModal}
          selectedUsersIds={selectedUsersIds}
          type={modal}
        />
      );
    case 'export':
      return (
        <BulkExportCsvReportModal
          onCancel={closeBulkUserManagementModal}
          onConfirm={closeBulkUserManagementMenu}
          userIds={selectedUsersIds}
        />
      );
    case 'none':
      return <></>;
  }
};

export default BulkUserManagementModalsFactory;
