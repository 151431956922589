import { Position } from './types';

export const POSITIONS: Position[] = [
  { top: 0, left: 0, transform: '' },
  { top: 0, left: 0, transform: 'rotate(90deg) translate(-120%, -33%)' },
  { top: 0, right: 0, transform: '' },
  { top: 0, right: 0, transform: 'rotate(-90deg) translate(120%, -33%)' },
  { bottom: 0, left: 0, transform: '' },
  { bottom: 0, left: 0, transform: 'rotate(-90deg) translate(-120%, 33%)' },
  { bottom: 0, right: 0, transform: '' },
  { bottom: 0, right: 0, transform: 'rotate(90deg) translate(120%, 33%)' },
];

export const FULLSCREEN_TRANSFORMS: string[] = [
  'rotate(-45deg) translateX(2px)',
  'rotate(-45deg) translate(2px, 4px)',
  'rotate(90deg) translate(7px, 3px)',
  'rotate(0) translateX(-8px)',
  'rotate(-90deg) translate(7px, 3px)',
  'rotate(-180deg) translate(-8px, 0)',
  'rotate(-45deg) translate(-2px, -4px)',
  'rotate(135deg) translate(2px, 0)',
];
