import styled from 'styled-components';

import { fontSm4 } from '../../../../styled/TypeSystem';

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const InputLabel = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm4};
`;
