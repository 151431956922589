import React, { useCallback, useMemo } from 'react';

import { useTrainingPathContext } from '../../../../../../../contexts/TrainingPathContext';
import { useTrainingPathSetContext } from '../../../../../../../contexts/TrainingPathSetContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import Toggle from '../../../../../../design_system/input/controls/Toggle';

const t = initTranslations('training_path.path_config.config_set');

const DelayToggle = () => {
  const {
    trainingPathSet: { trainingPathSetUuid },
    delayEnabled,
    updateTrainingPathSet,
  } = useTrainingPathSetContext();
  const { updateInProgress } = useTrainingPathContext();

  const toggleDelay = useCallback(
    (enabled: boolean) => {
      const delayType = enabled ? 'days' : null;
      const delayValue = enabled ? 2 : null;

      updateTrainingPathSet({
        delayType,
        delayValue,
      });
    },
    [updateTrainingPathSet]
  );

  const tooltipProps = useMemo(() => {
    if (delayEnabled) return {};

    return {
      tooltipId: `delay-tooltip-set-${trainingPathSetUuid}`,
      tooltipText: t('delay_tooltip'),
    };
  }, [delayEnabled, trainingPathSetUuid]);

  return (
    <Toggle
      checked={delayEnabled}
      className='delay-toggle'
      handleToggle={() => !updateInProgress && toggleDelay(!delayEnabled)}
      id={`delay-toggle-set-${trainingPathSetUuid}`}
      labelPosition='right'
      name='delay'
      primaryLabel={t('delay_label')}
      {...tooltipProps}
    />
  );
};

export default DelayToggle;
