import styled from 'styled-components';

import { fontSm4 } from '../../../../../../styled/TypeSystem';

export const AddSpecializationsRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
  width: 15rem;
`;

export const AddSpecializationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm4};
`;

export const SpecializationsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};

  margin-right: ${({ theme: { constants } }) =>
    `calc(${constants.spacerSm3} + ${constants.borderWidthSm})`};
`;
