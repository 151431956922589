import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import capitalize from '../../../../lib/capitalize';
import initTranslations from '../../../../lib/initTranslations';
import Editable from '../../../application/groups/Editable';
import { fontSm5 } from '../../../styled/TypeSystem';
import DeprecatedTextareaWithCharacterCount from '../DeprecatedTextareaWithCharacterCount';

interface TextDisplay {
  attributeName: string;
  text: string | undefined;
  truncate: boolean;
  setEditState: () => void;
  isPlaceholder: boolean;
  infoText: string;
  isTextClickable?: boolean;
}

export interface CharacterCountInterface {
  maxCharacters?: number;
  charactersUsed: number;
  anchoredRightCharacterCount: boolean;
  hasFieldError?: boolean;
  //anchor prop set to required for usability in AI outliner
}

export const sharedTextDisplayStyles = css`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd1};
  ${fontSm5};
  line-height: 1.375rem;
`;

const StyledTextDisplay = styled.p<{ truncate: boolean; isPlaceholder: boolean }>`
  color: ${({ isPlaceholder, theme: { vars } }) =>
    isPlaceholder ? vars.textPlaceholder : vars.textDefault};
  font-weight: normal;
  ${({ truncate }) =>
    truncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    `};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${sharedTextDisplayStyles};
`;

const TextDisplay = ({
  attributeName,
  isPlaceholder,
  text,
  truncate,
  setEditState,
  infoText,
  isTextClickable,
}: TextDisplay) => {
  return (
    <Editable
      attributeName={attributeName}
      infoText={infoText}
      isTextClickable={isTextClickable}
      setEditState={setEditState}
      truncate={truncate}
    >
      <StyledTextDisplay isPlaceholder={isPlaceholder} truncate={truncate}>
        {text}
      </StyledTextDisplay>
    </Editable>
  );
};

const t = initTranslations('groups');

export interface Props {
  attributeName: string;
  initialValue: string | undefined;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  label?: string;
  maxCharacters?: number;
  placeholder: string;
  resourceName: string;
  submitChanges(value: string | undefined): void;
  truncate?: boolean;
  infoText: string;
  isTextClickable?: boolean;
}

const InlineTextarea = (props: Props) => {
  const {
    attributeName,
    initialValue,
    isSuccess,
    isError,
    isLoading,
    label,
    maxCharacters,
    placeholder,
    resourceName,
    submitChanges,
    truncate = false,
    infoText,
    isTextClickable = false,
  } = props;
  const [value, setValue] = useState(initialValue);
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const characters = value ?? '';
  const charactersUsed = characters.length;

  const clearErrors = () => setErrorMessage('');

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    clearErrors();
  };

  const errorFunction = useCallback(() => setCurrentValue(initialValue), [initialValue]);

  useDisplayFlashOnResponse({
    result: { isSuccess, isError, isLoading },
    errorFunction,
    errorMessage: t('inline_textarea.error_message', { resourceName: capitalize(resourceName) }),
  });

  useEffect(() => {
    setValue(initialValue);
    setCurrentValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (maxCharacters && charactersUsed > maxCharacters) {
      setErrorMessage(
        t('inline_textarea.error_message_character_length', {
          resourceName: capitalize(resourceName),
          attributeName,
          maxCharacters,
        })
      );
    }
  }, [attributeName, resourceName, charactersUsed, maxCharacters]);

  const validateAndSubmit = async () => {
    if (!!errorMessage) return;

    setIsEditing(false);

    if (value === currentValue) return;

    setCurrentValue(value);
    submitChanges(value);
  };

  if (isEditing) {
    return (
      <DeprecatedTextareaWithCharacterCount
        attributeName={attributeName}
        id={`editable-${attributeName}-field`}
        inputFontSize='sm'
        label={label}
        maxCharacters={maxCharacters}
        onChange={onChange}
        placeholder={placeholder}
        resourceName={resourceName}
        submitChanges={validateAndSubmit}
        value={value || ''}
      />
    );
  }

  return (
    <TextDisplay
      attributeName={attributeName}
      infoText={infoText}
      isPlaceholder={!currentValue || placeholder === currentValue}
      isTextClickable={isTextClickable}
      setEditState={() => setIsEditing(true)}
      text={currentValue || placeholder}
      truncate={truncate}
    />
  );
};

export default InlineTextarea;
