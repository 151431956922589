import React from 'react';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { useGetSurveyByUserReportQuery } from '../../../../redux/services/resourceApis/reports/reportsApi';
import Breadcrumbs from '../../../design_system/navigation/Breadcrumbs';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import Loader from '../../../design_system/Triage/Loader';
import FlexContainer from '../../../styled/FlexContainer';
import { routes } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import AnsweredQuestionCard from '../../surveys/shared/SurveyResults/AnsweredQuestionCard/AnsweredQuestionCard';
import Attempts from './Attempts';
import { AnsweredQuestionsList, AnsweredQuestionsWrapper } from './styles';

const SurveyReportByUserPage = ({
  route: {
    params: { attemptId, curriculumId, surveyId, userId },
  },
}: {
  route: Route<typeof routes.surveyByUserReport>;
}) => {
  const { slug } = useCurrentAccount();
  const t = initTranslations('reports.survey_reports_by_user');

  const { data, isFetching } = useGetSurveyByUserReportQuery({
    surveyId,
    attemptId,
  });
  if (isFetching) return <Loader />;
  if (!data) return <></>;

  const {
    position,
    pageTitle,
    survey: { attempts, answeredQuestions },
  } = data;

  return (
    <PageContent>
      <Breadcrumbs
        currentPageText={t('breadcrumbs.attempt', { position })}
        redirectPath={
          userId
            ? routes.individualUserReport({ slug, id: userId }).href
            : routes.curriculumByUserReport({ slug, id: curriculumId }).href
        }
        redirectText={t('breadcrumbs.redirect_text')}
      />
      <PageHeader title={pageTitle} />
      <FlexContainer>
        <Attempts attempts={attempts} curriculumId={curriculumId} surveyId={surveyId} />
        <AnsweredQuestionsWrapper>
          <AnsweredQuestionsList>
            {answeredQuestions.map((answeredQuestion, index) => (
              <AnsweredQuestionCard
                answeredQuestion={answeredQuestion}
                key={answeredQuestion.id}
                position={index + 1}
              />
            ))}
          </AnsweredQuestionsList>
        </AnsweredQuestionsWrapper>
      </FlexContainer>
    </PageContent>
  );
};

export default SurveyReportByUserPage;
