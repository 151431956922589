import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { Scrollable } from '../../../shared/styles';
import { QuestionsWrapper, SurveyQuestionsLabel } from '../../../shared/SurveyQuestionsList/styles';
import AnsweredQuestionListItem from './AnsweredQuestionListItem';
import { AnsweredQuestionListProps } from './types';

const t = initTranslations('survey_results');

const AnsweredQuestionsList = ({ questions }: AnsweredQuestionListProps) => {
  return (
    <>
      <SurveyQuestionsLabel>{t('questions')}</SurveyQuestionsLabel>
      <Scrollable className='answered-survey-questions-wrapper'>
        <QuestionsWrapper>
          {questions.map((question, index) => {
            return <AnsweredQuestionListItem index={index} key={question.id} question={question} />;
          })}
        </QuestionsWrapper>
      </Scrollable>
    </>
  );
};

export default AnsweredQuestionsList;
