import { useEffect } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { useDispatchSetShowModal } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { delegationPlannerApi } from '../../../../../redux/services/resourceApis/delegationPlanner/delegationPlannerApi';
import useAutoSaveStatusUpdater from '../../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import { ApplyChangesResult } from '../types';

export const useBoardObservableEffects = (applyChangesResult: ApplyChangesResult) => {
  const dispatch = useAppDispatch();
  const dispatchShowModal = useDispatchSetShowModal();

  const { isLoading: isChangesApplying, isSuccess: isChangesAppliedSuccessfully } =
    applyChangesResult;

  const handleOpenCelebratoryModal = () => {
    applyChangesResult.reset();
    dispatchShowModal('delegationCelebratoryModal', true);
  };

  useDisplayFlashOnResponse({
    result: applyChangesResult,
    errorMessage: 'Something went wrong',
    successFunction: handleOpenCelebratoryModal,
  });

  useAutoSaveStatusUpdater([
    { isSaving: isChangesApplying, isSavedSuccessfully: isChangesAppliedSuccessfully },
  ]);

  useEffect(() => {
    dispatch(delegationPlannerApi.endpoints.getSpecializationsData.initiate(undefined));
    // Execute prefetch only on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
