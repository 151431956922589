import { FormikErrors, useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../lib/initTranslations';
import inputContainsSpaces from '../../../../lib/inputContainsSpaces';
import { messageFromError } from '../../../../redux/errors/helpers';
import {
  useConnectGoogleIntegrationMutation,
  useConnectMicrosoftIntegrationMutation,
  useConnectOktaIntegrationMutation,
} from '../../../../redux/services/resourceApis/integrations/integrationsApi';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import InputField from '../../../design_system/Triage/InputField';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { IntegrationModalProps } from './index';

const StyledInputRow = styled.div`
  margin-bottom: ${({ theme }) => theme.constants.spacerMd2};
  input {
    width: 100%;
    padding-top: ${({ theme }) => theme.constants.spacerSm3};
    padding-bottom: ${({ theme }) => theme.constants.spacerSm3};
  }
`;

interface FormValues {
  organization_domain?: string;
  tenant_id?: string;
  client_id: string;
  client_secret: string;
  sign_in_btn_text?: string;
}

export interface FormData {
  sso_configuration: FormValues;
}

const t = initTranslations('connect_sso_integration_modal');

const ConnectIntegrationModal = ({ closeModal, provider }: IntegrationModalProps) => {
  const { flash } = useFlashNotification();
  const { configs } = usePrivateConfigs();
  const oktaSetupGuide = configs['OKTA_SETUP_GUIDE_URL'];
  const googleSetupGuide = configs['GOOGLE_SETUP_GUIDE_URL'];
  const microsoftSetupGuide = configs['MICROSOFT_SETUP_GUIDE_URL'];

  const isOkta = provider === 'okta';
  let guideLink: string | undefined;
  if (provider === 'okta') {
    guideLink = oktaSetupGuide;
  } else if (provider === 'google') {
    guideLink = googleSetupGuide;
  } else {
    guideLink = microsoftSetupGuide;
  }

  const [connectOktaIntegration, resultConnectOktaIntegration] =
    useConnectOktaIntegrationMutation();
  const {
    isSuccess: isSuccessOkta,
    isLoading: isLoadingOkta,
    isError: isErrorOkta,
    data: dataOkta,
    error: errorOkta,
  } = resultConnectOktaIntegration;

  const [connectGoogleIntegration, resultConnectGoogleIntegration] =
    useConnectGoogleIntegrationMutation();
  const {
    isSuccess: isSuccessGoogle,
    isLoading: isLoadingGoogle,
    isError: isErrorGoogle,
    data: dataGoogle,
    error: errorGoogle,
  } = resultConnectGoogleIntegration;

  const [connectMicrosoftIntegration, resultConnectMicrosoftIntegration] =
    useConnectMicrosoftIntegrationMutation();
  const {
    isSuccess: isSuccessMicrosoft,
    isLoading: isLoadingMicrosoft,
    isError: isErrorMicrosoft,
    data: dataMicrosoft,
    error: errorMicrosoft,
  } = resultConnectMicrosoftIntegration;

  const isSuccess = isSuccessOkta || isSuccessGoogle || isSuccessMicrosoft;
  const isLoading = isLoadingOkta || isLoadingGoogle || isLoadingMicrosoft;
  const isError = isErrorOkta || isErrorGoogle || isErrorMicrosoft;
  const data = dataOkta || dataGoogle || dataMicrosoft;
  const error = errorOkta || errorGoogle || errorMicrosoft;

  const submitForm = (values: FormValues) => {
    const formattedData: FormData = {
      sso_configuration: values,
    };

    switch (provider) {
      case 'okta':
        return connectOktaIntegration(formattedData);
      case 'microsoft':
        return connectMicrosoftIntegration(formattedData);
      case 'google':
      default:
        return connectGoogleIntegration(formattedData);
    }
  };

  const formik = useFormik({
    initialValues: {
      organization_domain: '',
      tenant_id: '',
      client_id: '',
      client_secret: '',
    },
    validate: (values: FormValues) => validateForm(values),
    onSubmit: (values: FormValues) => submitForm(values),
  });

  useEffect(() => {
    if (isSuccess && data) {
      flash('info', t('integration_successfully_connected'), { autoClose: 5000 });
      closeModal();
    }
    if (isError) {
      flash('warn', messageFromError(error)?.join(', '), { autoClose: 5000 });
    }
  }, [closeModal, data, error, flash, isError, isSuccess]);

  const validateForm = useCallback(
    (values: FormValues) => {
      const { client_id, tenant_id, client_secret, organization_domain } = values;
      const errors: FormikErrors<FormValues> = {};
      if (!client_id) {
        errors.client_id = t('inputs.errors.required_field');
      } else if (inputContainsSpaces(client_id)) {
        errors.client_id = t('inputs.errors.contains_spaces');
      }
      if (!client_secret) {
        errors.client_secret = t('inputs.errors.required_field');
      } else if (inputContainsSpaces(client_secret)) {
        errors.client_secret = t('inputs.errors.contains_spaces');
      }
      if (isOkta && !organization_domain) {
        errors.organization_domain = t('inputs.errors.required_field');
      } else if (organization_domain && inputContainsSpaces(organization_domain)) {
        errors.organization_domain = t('inputs.errors.contains_spaces');
      }
      if (tenant_id && inputContainsSpaces(tenant_id)) {
        errors.tenant_id = t('inputs.errors.contains_spaces');
      }

      return errors;
    },
    [isOkta]
  );

  const taskModalArgs: TaskModalProps = {
    heapModalName: 'connect-sso-integration-modal',
    title: t(`${provider}.title`),
    onCloseRequest: closeModal,
    processing: isLoading,
    primaryButtonText: t('connect'),
    isDisabled: false,
    primaryButtonTask: async () => {
      await formik.handleSubmit();
    },
    tertiaryButton: {
      text: t(`${provider}.open_guide`),
      task: () => {
        window.open(guideLink, '_blank');
      },
    },
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <StyledInputRow>
        <InputField
          errorText={formik.touched.client_id && formik.errors.client_id}
          label={t('application_client_id')}
          labelTooltipId='client-id-tooltip-id'
          labelTooltipText={t('client_id_tooltip')}
          name='client_id'
          onChange={formik.handleChange}
          placeholder={t('inputs.placeholder')}
          value={formik.values.client_id}
        />
      </StyledInputRow>
      <StyledInputRow>
        <InputField
          errorText={formik.touched.client_secret && formik.errors.client_secret}
          label={t('application_client_secret')}
          labelTooltipId='client-secret-tooltip-id'
          labelTooltipText={t('client_secret_tooltip')}
          name='client_secret'
          onChange={formik.handleChange}
          placeholder={t('inputs.placeholder')}
          type='password'
          value={formik.values.client_secret}
        />
      </StyledInputRow>
      <>
        {(() => {
          switch (provider) {
            case 'okta':
              return (
                <>
                  <StyledInputRow>
                    <InputField
                      errorText={
                        formik.touched.organization_domain && formik.errors.organization_domain
                      }
                      label={t('okta_domain')}
                      labelTooltipId='okta-domain-tooltip-id'
                      labelTooltipText={t('okta_domain_tooltip')}
                      name='organization_domain'
                      onChange={formik.handleChange}
                      placeholder={t('inputs.placeholder')}
                      value={formik.values.organization_domain}
                    />
                  </StyledInputRow>
                  <StyledInputRow>
                    <InputField
                      errorText={formik.touched.sign_in_btn_text && formik.errors.sign_in_btn_text}
                      label={t('okta_sign_in_btn_text')}
                      labelTooltipId='okta-okta_sign-in-btn-text-tooltip-id'
                      labelTooltipText={t('okta_sign_in_btn_text_tooltip')}
                      name='sign_in_btn_text'
                      onChange={formik.handleChange}
                      placeholder={t('okta_sign_in_btn_text_placeholder')}
                      value={formik.values.sign_in_btn_text}
                    />
                  </StyledInputRow>
                </>
              );
            case 'microsoft':
              return (
                <StyledInputRow>
                  <InputField
                    errorText={formik.touched.tenant_id && formik.errors.tenant_id}
                    label={t('microsoft_tenant_id')}
                    labelTooltipId='microsoft-tenant-tooltip-id'
                    labelTooltipText={t('microsoft_tenant_id_tooltip')}
                    name='tenant_id'
                    onChange={formik.handleChange}
                    placeholder={t('inputs.placeholder')}
                    value={formik.values.tenant_id}
                  />
                </StyledInputRow>
              );
            default:
              return null;
          }
        })()}
      </>
    </TaskModal>
  );
};

export default ConnectIntegrationModal;
