import React from 'react';

import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import Poppable from '../../../../Poppable';
import { mediaBreakpointXl } from '../../../../styled/Breakpoint';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import DropdownItem from './DropdownItem';
import { AddIconWrapper, StyledIcon } from './styles';
import { AddCardDropdownMenuProps, AddCardMenuOptionProps } from './types';

const t = initTranslations('delegation_planner.add_card_dropdown');

const AddCardDropdownMenu = ({
  menuId,
  menuOptionsClassName = 'open-add-card-dropdown-menu',
  menuPlacement,
  menuStrategy,
  isAnimated,
}: AddCardDropdownMenuProps) => {
  const dispatchShowModal = useDispatchSetShowModal();
  const { width } = useWindowResize();
  const { handleMenuClick: handleActiveMenuClick, isMenuOpen } = useActiveMenuHandler({ menuId });
  const tooltipId = 'add-card-dropdown-menu-tooltip';

  const menuOptions: AddCardMenuOptionProps[] = [
    {
      title: t('group_option'),
      onClick: () => dispatchShowModal('delegationSelectGroupModal', true),
      iconName: 'users',
      id: 'delegation-planner-add-group-card',
    },
    {
      title: t('user_option'),
      onClick: () => dispatchShowModal('delegationSelectUserModal', true),
      iconName: 'user',
      id: 'delegation-planner-add-user-card',
    },
  ];

  const handleMenuClick = () => {
    handleActiveMenuClick();
  };

  const placement = menuPlacement
    ? menuPlacement
    : width >= mediaBreakpointXl
    ? 'bottom-start'
    : 'bottom-end';

  return (
    <Poppable
      isOpen={isMenuOpen}
      item={
        <DropdownItem
          menuId={menuId}
          menuOptions={menuOptions}
          menuOptionsClassName={menuOptionsClassName}
        />
      }
      onClick={handleMenuClick}
      placement={placement}
      strategy={menuStrategy}
      trigger={
        <>
          <Tooltip id={tooltipId} place='top' text={t('tooltip')} />
          <AddIconWrapper
            className='add-delegation-card-trigger'
            data-for={tooltipId}
            data-tip
            id='add-delegation-card-trigger'
            isAnimated={isAnimated}
          >
            <StyledIcon name='plus' size='md' />
          </AddIconWrapper>
        </>
      }
    />
  );
};

export default AddCardDropdownMenu;
