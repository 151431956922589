import * as yup from 'yup';

export const getConfirmPasswordSchema = (
  requiredTranslation: string,
  passwordConfirmationTranslation: string
) => {
  return yup.object().shape({
    confirm_password: yup
      .string()
      .trim()
      .required(requiredTranslation)
      .oneOf([yup.ref('password'), null], passwordConfirmationTranslation),
  });
};
