import styled from 'styled-components';

import AddonInfoCardShine from '../../../../../../../images/billing/addon_info_card_shine.svg';
import Icon from '../../../../../../design_system/display/icons/Icon';
import P from '../../../../../../design_system/text/P';
import { mediaBreakpointPxLg, mediaBreakpointPxXl } from '../../../../../../styled/Breakpoint';
import { fontLg2, fontSm2, fontSm4 } from '../../../../../../styled/TypeSystem';

export const AddonInfoCardWrapper = styled.div`
  position: relative;
`;

export const AddonInfoCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidth2xl} solid ${vars.trainualBrandPurpleStrong}`};
  box-shadow: ${({ theme: { vars } }) => `${vars.trainualBrandPurpleStrong} -16px 16px 0 0`};
  z-index: 2;
  &:before {
    content: url(${AddonInfoCardShine});
    position: absolute;
    bottom: -53px;
    left: -43px;
  }
`;

export const AddonInfoCardFeaturesWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerLg1}`};
`;

export const AddonInfoCardTitle = styled.h2`
  margin-top: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  padding-right: 4rem;
`;

export const AddonInfoCardFeaturesList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};

  @media (max-width: ${mediaBreakpointPxLg}) {
    grid-template-columns: 1fr;
  }
`;

export const AddonFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const AddonFeatureDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
`;

export const AddonFeatureTitle = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm4};
`;

export const AddonFeatureDescription = styled(P)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
`;

export const AddonFeatureIcon = styled(Icon)<{
  addonSecondaryColor: string;
}>`
  color: ${({ addonSecondaryColor }) => addonSecondaryColor};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm1};
`;

export const AddonFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerLg1}`};
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) =>
    `0 0 ${constants.borderRadiusXl} ${constants.borderRadiusXl}`};
`;

export const AddonBillingPeriod = styled(P)`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm2};
`;

export const AddonPrice = styled(P)`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontLg2};
`;

export const AddonInfoCardArrowImg = styled.img`
  visibility: visible;
  position: absolute;
  top: -38px;
  right: -75px;
  z-index: 1;

  @media (max-width: ${mediaBreakpointPxXl}) {
    visibility: hidden;
  }
`;

export const AddonInfoCardLogo = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  height: 5.625rem;
  width: 7.5rem;
`;
