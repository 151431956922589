import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { mediaBreakpointPxLg } from '../../../styled/Breakpoint';
import { headerContainerStyles, paddingBox } from '../Board/styles';
import { Wrapper as BoardAutoSaveStatusWrapper } from '../BoardSaveStatus/styles';
import { Wrapper as FullscreenButtonWrapper } from '../FullscreenButton/styles';

export const HeaderActionsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  ${FullscreenButtonWrapper} {
    margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

export const BoardToolsContainer = styled.div`
  ${headerContainerStyles};
  ${paddingBox};
  justify-content: space-between;
  padding-inline-start: 0 !important;
  gap: ${({ theme: { constants } }) => constants.spacerLg2};
  align-items: flex-end;
  max-height: 7.5rem;

  ${BoardAutoSaveStatusWrapper} {
    @media (max-width: ${mediaBreakpointPxLg}) {
      display: none;
    }
  }
`;

export const TooltipIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  pointer-events: none;
  max-width: 1.25rem;
  height: 1.25rem;
`;
