import { isEqual, sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import initTranslations from '../../../../../lib/initTranslations';
import setMultiSelectOptions from '../../../../../lib/multiSelectOptions';
import {
  useBatchCreateFollowingMutation,
  useBatchDeleteFollowingMutation,
  useGetFollowingQuery,
} from '../../../../../redux/services/resourceApis/followings/followingsApi';
import { useGetManageableUsersQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import DetailedAvatar from '../../../../design_system/display/DetailedAvatar/DetailedAvatar';
import { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import { ChecklistOption } from '../../../../design_system/Triage/Checklist';
import SearchableChecklistModal from '../../../shared/SearchableChecklistModal/SearchableChecklistModal';

const t = initTranslations('home.following_widget.update_following_modal');

export interface ManageFollowingModalProps {
  closeModal: () => void;
}

const ManageFollowingModal = ({ closeModal }: ManageFollowingModalProps) => {
  const { id } = useCurrentUser();
  const { isLoading, data, error } = useGetManageableUsersQuery(id);
  const { data: followedUsers } = useGetFollowingQuery(id);
  const [checkboxOptions, setCheckboxOptions] = useState<ChecklistOption[]>([]);
  const [isLoadingCheckboxOptions, setIsLoadingCheckboxOptions] = useState(true);
  const [selectedUserOptions, setSelectedUserOptions] = useState<ChecklistOption[]>([]);
  const [batchCreateFollowing, createResult] = useBatchCreateFollowingMutation();
  const [batchDeleteFollowing, deleteResult] = useBatchDeleteFollowingMutation();
  const { isLoading: isLoadingCreate } = createResult;
  const { isLoading: isLoadingDelete } = deleteResult;
  const changesMade = useMemo(() => {
    if (followedUsers === undefined) return false;

    const initialFollowedIds = sortBy(followedUsers.map((user) => user.id));
    const selectedFollowedIds = sortBy(selectedUserOptions.map((selectedUser) => selectedUser.id));
    return !isEqual(initialFollowedIds, selectedFollowedIds);
  }, [followedUsers, selectedUserOptions]);

  useEffect(() => {
    const allFollowableUsers = data?.users.filter((user) => user.id !== id);

    if (allFollowableUsers && followedUsers !== undefined) {
      setCheckboxOptions(
        setMultiSelectOptions(
          allFollowableUsers.map((user) => ({
            ...user,
            title: (
              <DetailedAvatar
                avatarImage={user.avatar}
                id={`follow-user-detailed-avatar-${user.id}`}
                label={user.name}
                onClick={() => {
                  /* This empty onClick is needed so that a cursor shows up when hovering */
                }}
                subLabel={user.title}
              />
            ),
            filterOn: user.name,
          })),
          followedUsers.map((user) => ({ id: user.id })),
          'user'
        )
      );
      setIsLoadingCheckboxOptions(false);
    }
  }, [followedUsers, data?.users, id]);

  useEffect(() => {
    setSelectedUserOptions(checkboxOptions.filter((item) => item.checked));
  }, [checkboxOptions]);

  const initialSelectedIds = checkboxOptions
    .filter((s) => s.checked)
    .map((curriculum) => curriculum.id);
  const currentlySelectedIds = selectedUserOptions
    .filter((s) => s.checked)
    .map((curriculum) => curriculum.id);

  const addedIds = currentlySelectedIds.filter((id) => !initialSelectedIds.includes(id));
  const removedIds = initialSelectedIds.filter((id) => !currentlySelectedIds.includes(id));

  const taskModalArgs: TaskModalProps = {
    title: t('title'),
    subtitle: t('subtitle'),
    isDisabled: isLoadingCreate || isLoadingDelete || !changesMade,
    processing: isLoadingCreate || isLoadingDelete,
    onCloseRequest: closeModal,
    primaryButtonText: t('submit_action'),
    primaryButtonTask: () => {
      if (!!addedIds.length) batchCreateFollowing({ id, userIds: addedIds });
      if (!!removedIds.length) batchDeleteFollowing({ id, userIds: removedIds });
      closeModal();
    },
    heapModalName: 'manage-followed-users-modal',
  };

  return (
    <SearchableChecklistModal
      checkboxOptions={checkboxOptions}
      errorMessage={error ? t('error_message') : null}
      isLoading={isLoading || isLoadingCheckboxOptions}
      numberSelected={selectedUserOptions.length}
      onSelectionChanged={(selectedOptions: ChecklistOption[]) =>
        setSelectedUserOptions(selectedOptions)
      }
      search_placeholder={t('search_placeholder')}
      taskModalProps={taskModalArgs}
    />
  );
};

export default ManageFollowingModal;
