type LoomVideoToolbarStateOption = {
  insertionType: null | 'record' | 'insert';
  isConfirmationModalOpen: boolean;
};
type LoomVideoToolbarAction =
  | {
      type: 'setToolbarState';
      insertionType: null | 'record' | 'insert';
      isConfirmationModalOpen: boolean;
    }
  | { type: 'setIsConfirmationModalOpen'; isConfirmationModalOpen: boolean };

export const initialLoomVideoToolbarState: LoomVideoToolbarStateOption = {
  insertionType: null,
  isConfirmationModalOpen: false,
};

export const LoomVideoToolbarState = (
  state: LoomVideoToolbarStateOption,
  action: LoomVideoToolbarAction
): LoomVideoToolbarStateOption => {
  switch (action.type) {
    case 'setIsConfirmationModalOpen': {
      return { ...state, isConfirmationModalOpen: action.isConfirmationModalOpen };
    }
    case 'setToolbarState': {
      return {
        insertionType: action.insertionType,
        isConfirmationModalOpen: action.isConfirmationModalOpen,
      };
    }
  }
};
