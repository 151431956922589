import styled from 'styled-components';

import { mediaBreakpointPxLg } from '../../../../../styled/Breakpoint';

export const WordCountInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusSm};
  background-color: ${({ theme: { vars } }) => vars.foundationHover};

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: row;
  }
`;

export const WordCountRadioButtonsWrapper = styled.div`
  min-width: 100%;
  margin: ${({ theme: { constants } }) => `0 0 ${constants.spacerSm3} 0`};

  @media (min-width: ${mediaBreakpointPxLg}) {
    min-width: 75%;
    margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 0 0`};
  }
`;
