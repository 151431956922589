import styled from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Link from '../../../../design_system/Link';
import { fontSm4 } from '../../../../styled/TypeSystem';
import { TEMPLATE_PREVIEW_TOP_NAV_BAR_HEIGHT } from '../styles';

export const TemplatePreviewTopNavBarWrapper = styled.div`
  width: 100%;
  height: ${TEMPLATE_PREVIEW_TOP_NAV_BAR_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: ${({ theme: { constants } }) => `0.875rem ${constants.spacerLg2}`};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: ${({ theme: { vars } }) => vars.shadowCenterSmall};
`;

export const TemplatePreviewBackToTemplatesLink = styled(Link)`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textDefault};
  cursor: pointer;
`;

export const TemplatePreviewBackToTemplatesLabel = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm4};
`;

export const TemplatePreviewButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const TemplatePreviewShareTemplateButton = styled(DefaultButton)`
  > * {
    pointer-events: none !important;
  }
`;
