import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { OverlayContentHeaderTitle } from '../../../../../shared/FullScreenOverlay/styles';
import { OverlayContentHeader as StyledOverlayContentHeader } from '../../shared/styles';

const t = initTranslations('per_user_pricing.configure_plan_overlay_content');

const OverlayContentHeader = () => {
  return (
    <StyledOverlayContentHeader>
      <OverlayContentHeaderTitle>{t('title')}</OverlayContentHeaderTitle>
    </StyledOverlayContentHeader>
  );
};

export default OverlayContentHeader;
