import styled from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { MultilineTruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { fontMd1, fontSm4 } from '../../../../../styled/TypeSystem';

export const OutlineElementContainer = styled.div<{ isExpanded: boolean }>`
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars }, isExpanded }) => isExpanded && vars.accentSubdued1};

  &:hover {
    background-color: ${({ theme: { vars }, isExpanded }) => !isExpanded && vars.foundationHover};
  }
`;

export const OutlineElementHead = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const OutlineElementIconWrapper = styled.div`
  width: 1rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd1};
`;

export const OutlineElementIcon = styled(Icon)<{ isExpanded: boolean }>`
  width: 1rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars }, isExpanded }) =>
    isExpanded ? vars.accentStrong2 : vars.textSubdued};
`;

export const OutlineElementHeadInner = styled.div`
  width: calc(100% - 3rem);
`;

export const OutlineElementTitle = styled.h3<{ isExpanded: boolean }>`
  margin: 0;
  color: ${({ theme: { vars }, isExpanded }) =>
    isExpanded ? vars.accentStrong2 : vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${MultilineTruncatedText({ lineClamp: 2 })};
  ${fontMd1};
`;

export const OutlineElementType = styled.h6<{ isExpanded: boolean }>`
  margin: 0;
  color: ${({ theme: { vars }, isExpanded }) =>
    isExpanded ? vars.accentStrong1 : vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm4};
`;

export const OutlineElementItemsContainer = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;
