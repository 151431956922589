import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import IconButton from '../../../../../design_system/buttons/IconButton';

export const sharedAIIconButtonStyles = css`
  color: ${({ theme: { vars } }) => vars.textSurface};
  background-color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
  box-shadow: 0 0 16px 0 ${({ theme: { vars } }) => vars.trainualBrandGreenMedium};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthMd} solid ${vars.trainualBrandGreenMedium}`};
  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.textSurface};
    background-color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthMd} solid ${vars.trainualBrandGreenMedium}`};

    svg {
      color: ${({ theme: { vars } }) => vars.textSurface};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};

  ${sharedAIIconButtonStyles};
`;

const StyledIconButtonWithLabel = styled(DefaultButton)`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthMd} solid ${vars.accentPrimaryDefault}`};
  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
    svg {
      color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    }
  }
  svg {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }
`;

export type Props = {
  onClick?: () => void;
  iconWithLabel?: boolean;
};

const t = initTranslations('curriculums.ai_outliner');

export const GlowingIconButton = ({ onClick, iconWithLabel = false }: Props) => {
  return (
    <>
      {iconWithLabel ? (
        <StyledIconButtonWithLabel
          iconName='wand-magic-sparkles'
          id='ai-glowing-icon-button'
          onClick={onClick}
          text={t('icon_text_label')}
        />
      ) : (
        <StyledIconButton
          ariaLabel={t('icon_aria_label')}
          id='ai-glowing-icon-button'
          name='wand-magic-sparkles'
          onClick={onClick}
        />
      )}
    </>
  );
};
