import { Formik, FormikErrors } from 'formik';
import React from 'react';

import { useOnboarding } from '../../../../../contexts/OnboardingContext';
import initTranslations from '../../../../../lib/initTranslations';
import { isHexColor } from '../../../../design_system/helpers';
import CustomizeAccentColorStepForm from './CustomizeAccentColorStepForm';
import { AccentColor } from './types';

const t = initTranslations('home.onboarding.customize_accent_color_step');

const CustomizeAccentColorStep = () => {
  const { formData, setFormData, sliderRef } = useOnboarding();

  const initialValues = {
    accent_color: formData.accent_color,
  };

  const onSubmit = (values: AccentColor) => {
    setFormData({ ...formData, ...values });
    sliderRef?.current?.slickNext();
  };

  const validate = ({ accent_color }: AccentColor) => {
    const errors: FormikErrors<AccentColor> = {};
    if (accent_color && !isHexColor(accent_color)) {
      errors.accent_color = t('errors.invalid');
    }
    return errors;
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <CustomizeAccentColorStepForm />
    </Formik>
  );
};

export default CustomizeAccentColorStep;
