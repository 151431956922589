import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Editable from '../../../application/groups/Editable';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontMd2, fontSm5 } from '../../../styled/TypeSystem';
import Link from '../../Link/Link';
import InputFieldWithCharacterCounter from '../InputFieldWithCharacterCounter';
import { MultilineTruncatedText } from '../TruncatedText';

export const StyledLink = styled(Link)`
  user-select: none;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textDefault};
  cursor: pointer;
  text-decoration: none;

  :hover {
    color: ${({ theme: { vars } }) => vars.textDefault};
    text-decoration: underline;
  }

  ${fontSm5};
  ${MultilineTruncatedText({ lineClamp: 1 })};
`;

const StyledH6 = styled.h6<{ truncate?: boolean }>`
  display: inline-block;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-bottom: 0;
  font-size: ${({ theme: { constants } }) => constants.spacerMd2};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};

  ${({ truncate }) => truncate && MultilineTruncatedText({ lineClamp: 2 })};

  @media (min-width: ${mediaBreakpointPxSm}) {
    font-size: 1.125rem;
  }
`;

const StyledInputField = styled(InputFieldWithCharacterCounter)<{
  isLinkTitle?: string;
}>`
  ${(props) => (props.isLinkTitle ? fontSm5 : fontMd2)}
`;

type LabelOrLinkProps =
  | { href: string; inputFor?: never; label?: never }
  | { href?: never; inputFor: string; label: string };

export type Props = {
  attributeName: string;
  errorMessage: string;
  initialValue: string;
  isSuccess: boolean;
  maxCharacters?: number;
  placeholder?: string;
  responseError: string | undefined;
  submitChanges(value: string): void;
  truncate?: boolean;
  focusText?: boolean;
  infoText: string;
  isTextClickable?: boolean;
} & LabelOrLinkProps;

const InlineEditInput = (props: Props) => {
  const {
    attributeName,
    errorMessage,
    href,
    initialValue,
    inputFor,
    isSuccess,
    label,
    maxCharacters,
    placeholder,
    responseError,
    submitChanges,
    truncate = false,
    focusText = false,
    infoText,
    isTextClickable = false,
  } = props;
  const { flash } = useFlashNotification();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [currentValue, setCurrentValue] = useState(initialValue);
  const charactersUsed = value.length;

  const validateAndSubmit = async () => {
    setIsEditing(false);

    if (value === currentValue) return;

    const isEmpty = !value || value.trim() === '';
    const overMaxChars = maxCharacters && charactersUsed > maxCharacters;

    if (isEmpty || overMaxChars) {
      setValue(currentValue);
      return;
    }

    submitChanges(value);
  };

  useEffect(() => {
    setValue(initialValue);
    setCurrentValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (responseError) {
      flash('error', errorMessage);
      setValue(currentValue);
    }
  }, [currentValue, errorMessage, flash, responseError]);

  useEffect(() => {
    if (isSuccess) setCurrentValue(value);
  }, [isSuccess, value]);

  return (
    <>
      {!isEditing && (
        <Editable
          attributeName={attributeName}
          infoText={infoText}
          isTextClickable={isTextClickable}
          setEditState={() => setIsEditing(true)}
          truncate={truncate}
        >
          {href ? (
            <StyledLink href={href} text={value} />
          ) : (
            <StyledH6 truncate={truncate}>{value}</StyledH6>
          )}
        </Editable>
      )}
      {isEditing && (
        <StyledInputField
          autoFocus
          focusText={focusText}
          id={`editable-${attributeName}-field`}
          inputFor={inputFor}
          isLinkTitle={href}
          label={label}
          maxCharacters={maxCharacters}
          onBlur={validateAndSubmit}
          onChange={(event) => setValue(event.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && validateAndSubmit()}
          placeholder={placeholder}
          value={value}
        />
      )}
    </>
  );
};

export default InlineEditInput;
