import styled from 'styled-components';

import { MultilineTruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontLg2, fontMd1, fontSm4 } from '../../../../styled/TypeSystem';

export const CurriculumElementHeaderWrapper = styled.div<{ cover?: string | null }>`
  width: 100%;
  max-width: 80rem;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-bottom-left-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  border-bottom-right-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  ${({ cover, theme: { constants } }) =>
    !cover && {
      borderTopLeftRadius: constants.borderRadiusXl,
      borderTopRightRadius: constants.borderRadiusXl,
    }};
`;

export const HeaderBodyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const HeaderBody = styled.div`
  width: 100%;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerLg1} ${constants.spacerMd2} ${constants.spacerMd3} ${constants.spacerLg2}`};
`;

export const HeaderLabel = styled.span`
  color: ${({ theme: { vars } }) => vars.textDisabled};
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerMd2} ${constants.spacerMd3} ${constants.spacerLg2}`};
  ${fontSm4};
`;

export const Title = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${MultilineTruncatedText({ lineClamp: 2 })};
  ${fontLg2};
`;

export const Description = styled.p`
  margin: ${({ theme: { constants } }) => constants.spacerMd2} 0;
  ${fontMd1};
`;

export const EmojiWrapper = styled.div`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};

  width: 4rem;
  height: 4rem;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  position: absolute;
  left: 40px;
  top: -40px;
`;

export const Emoji = styled.span`
  ${fontLg2};
`;

export const CoverWrapper = styled.div`
  border-radius: ${({ theme: { constants } }) =>
    `${constants.borderRadiusXl} ${constants.borderRadiusXl} 0 0`};
  overflow: hidden;
  height: 12.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
