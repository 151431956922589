import React, { FC } from 'react';

import { StyledIcon } from '../../ResponsibilityCard/styles';
import { FlexCenteredContainer, SkeletonCard, SkeletonTextLine } from '../styles';
import { BoardCardSkeletonProps, SkeletonTextWidths } from '../types';

const BoardCardSkeleton: FC<BoardCardSkeletonProps> = ({ textTitles }) => {
  return (
    <SkeletonCard>
      <FlexCenteredContainer>
        {textTitles.map((textTitle, index) => (
          <SkeletonTextLine key={index} width={textTitle} />
        ))}
      </FlexCenteredContainer>

      <FlexCenteredContainer>
        <StyledIcon name='clock' size='2xs' weight='regular' />

        <SkeletonTextLine width={SkeletonTextWidths.Small} />
      </FlexCenteredContainer>
    </SkeletonCard>
  );
};

export default BoardCardSkeleton;
