import React from 'react';
import { useTheme } from 'styled-components';

import MoveToNewPlanArrowImage from '../../../../../../../images/billing/move_to_new_plan_arrow.svg';
import { getPlanCardStatusText } from '../../../../../../../lib/billing/getPlanCardStatusText';
import initTranslations from '../../../../../../../lib/initTranslations';
import { SkeletonLoader } from '../../../../../shared/SkeletonLoader/styles';
import { SpaceBetweenWrapper } from '../../../styles';
import {
  MoveToNewPlanImg,
  PlanCardFooterWrapper,
  PlanCardStatus,
  PlanCardsWrapper,
  SkeletonDivider,
  StyledPlanCardWrapper,
} from '../styles';

type PlanCardSkeletonProps = {
  isManageSeatsOverlay?: boolean;
};

const t = initTranslations('per_user_pricing.manage_plan_overlay_content.plan_cards');

const PlanCardsSkeleton = ({ isManageSeatsOverlay }: PlanCardSkeletonProps) => {
  const {
    constants: { spacerMd2, spacerSm2, spacerSm3 },
  } = useTheme();

  return (
    <PlanCardsWrapper>
      {['first-plan-card', 'second-plan-card'].map((cardName) => {
        const skeletonCardId = `${cardName}-skeleton`;
        const isNewPlan = cardName === 'second-plan-card';

        return (
          <StyledPlanCardWrapper id={skeletonCardId} isNewPlan={isNewPlan} key={skeletonCardId}>
            {!isNewPlan && (
              <MoveToNewPlanImg alt={t('alt_move_to_new_plan')} src={MoveToNewPlanArrowImage} />
            )}
            <PlanCardStatus
              text={getPlanCardStatusText(isNewPlan, isManageSeatsOverlay)}
              withMarginBottom={isManageSeatsOverlay}
            />
            {!isManageSeatsOverlay && (
              <SkeletonLoader marginBottom={spacerMd2} marginTop={spacerSm2} width='40%' />
            )}

            <SkeletonLoader marginBottom={spacerSm3} width='70%' />
            <SkeletonLoader marginBottom={spacerMd2} width='45%' />

            <SpaceBetweenWrapper>
              <SkeletonLoader width='60%' />
              <SkeletonLoader width='30%' />
            </SpaceBetweenWrapper>

            <PlanCardFooterWrapper>
              <SkeletonDivider />
              <SkeletonLoader width='50%' />
            </PlanCardFooterWrapper>
          </StyledPlanCardWrapper>
        );
      })}
    </PlanCardsWrapper>
  );
};

export default PlanCardsSkeleton;
