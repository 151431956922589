import React from 'react';
import { SaguaroTheme } from 'saguaro';
import styled, { StyledComponent } from 'styled-components';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useWindowResize from '../../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Icon from '../../../../../design_system/display/icons/Icon';
import Checkbox from '../../../../../design_system/input/controls/Checkbox';
import {
  CheckboxWrapper,
  DefaultHeaderStyled,
  HeaderCell,
  LeftAlignedHeaderStyled,
  RightAlignedHeaderStyled,
} from '../Styles';
import { Column, TableAction, TableState } from '../TableTypes';

const StyledSortIcon = styled(Icon)`
  margin: 0 0 ${({ theme: { constants } }) => `${constants.spacerSm1} ${constants.spacerSm2}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export type SortProps =
  | { sortIcon?: never; isSortable: boolean }
  | { sortIcon: 'sort-up' | 'sort-down' | 'sort'; isSortable: true };

const t = initTranslations('users_table');

type Props = SortProps & {
  column: Column;
  activeHeader: boolean;
  setActiveHeader: () => void;
  tableState: TableState;
  dispatch: React.Dispatch<TableAction>;
};

const TableHeaderCell = ({
  column: { columnName, stickyPosition = 'center' },
  activeHeader,
  setActiveHeader,
  sortIcon,
  isSortable,
  tableState,
  dispatch,
}: Props) => {
  let HeaderWrapper: StyledComponent<'th', SaguaroTheme>;
  const {
    curriculum: { plural: subjectTermPlural },
  } = useAccountTerminology();
  const { isDesktop } = useWindowResize();
  const { accountPlan: plan } = useCurrentAccount();
  const canReadBulkUserManagement = isDesktop && plan !== 'build';

  switch (stickyPosition) {
    case 'left':
      HeaderWrapper = LeftAlignedHeaderStyled;
      break;
    case 'right':
      HeaderWrapper = RightAlignedHeaderStyled;
      break;
    case 'center':
      HeaderWrapper = DefaultHeaderStyled;
  }

  return (
    <HeaderWrapper>
      <HeaderCell
        active={activeHeader}
        className={isSortable ? `sortable-column ${columnName}` : undefined}
        isSortable={isSortable}
        onClick={() => setActiveHeader && setActiveHeader()}
        stickyPosition={stickyPosition}
      >
        {columnName === 'name' && canReadBulkUserManagement && (
          <CheckboxWrapper onClick={(e) => e.stopPropagation()}>
            <Checkbox
              checkboxStyle='half'
              checked={tableState.unselectedUsers.length ? false : tableState.selectAllUsers}
              id='users-select-all'
              name='users-select-all'
              onCheck={() => {
                dispatch({
                  type: 'selectAllUsers',
                  selectAllUsers: !tableState.selectAllUsers,
                });
              }}
              tooltipHoverCheckbox
              tooltipId='users-deselect-all-tooltip'
              tooltipText={
                tableState.selectAllUsers && !tableState.unselectedUsers.length
                  ? t('users_deselect_all_tooltip')
                  : ''
              }
            />
          </CheckboxWrapper>
        )}
        {columnName !== 'three_dot_menu' &&
          t(`headers.${columnName}`, { subjects: subjectTermPlural })}
        {sortIcon && <StyledSortIcon name={sortIcon} size='2xs' weight='solid' />}
      </HeaderCell>
    </HeaderWrapper>
  );
};

export default TableHeaderCell;
