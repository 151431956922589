import styled, { css } from 'styled-components';

import { StyledAvatar } from '../../../design_system/display/avatar/Avatar';
import { MenuButton } from '../../../design_system/Triage/menus/ThreeDotMenu/styles';
import { MultilineTruncatedText } from '../../../design_system/Triage/TruncatedText';
import Scrollbar from '../../../styled/Scrollbar';
import { fontSm4 } from '../../../styled/TypeSystem';
import { GroupResponsibilityCardContainer } from '../GroupResponsibilityCard/styles';
import { COLUMN_LABEL_AVAILABLE_WIDTH } from '../shared/constants/boards';
import { COLUMN_WIDTH } from '../shared/constants/sizes';

export const BoardColumnTrack = styled.div`
  ${({ theme: { constants } }) => css`
    width: ${COLUMN_WIDTH}rem;
    padding-bottom: ${constants.spacerSm3};
    height: 100%;
    flex-shrink: 0;
    touch-action: manipulation;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
  `};
`;

export const BoardColumnContainer = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: ${COLUMN_WIDTH}rem;
    max-height: 100%;
    min-height: 7.5rem;
    height: fit-content;
    background-color: ${vars.foundationBase1};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};

    border-radius: ${constants.borderRadiusMd};
  `};
`;

export const BoardColumnHeader = styled.div<{
  isGroupAvatar?: boolean;
  isDragging?: boolean;
}>`
  ${({ isDragging, theme: { constants } }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: ${constants.spacerMd2} ${constants.spacerMd2} ${constants.spacerSm3};
    margin-bottom: ${constants.spacerSm3};
    cursor: ${isDragging ? 'grabbing' : 'grab'};

    [class^='DetailedAvatar'] {
      cursor: inherit;
      max-width: ${COLUMN_LABEL_AVAILABLE_WIDTH}rem;

      [class^='Avatar'] {
        cursor: ${isDragging && 'grabbing'};
      }
      [class^='DetailedAvatar__AvatarWrapper'] {
        height: fit-content;
      }
      [class*='FirstLineLabelWrapper'] {
        > * {
          max-height: 1.125rem;
          transition: 0.5s ease-out;
          ${!isDragging &&
          css`
            &:hover {
              ${MultilineTruncatedText({
                lineClamp: 5,
              })}
              max-height: 5.625rem;
            }
          `}
          word-break: break-word;
        }
      }
    }

    &:hover {
      ${MenuButton} {
        display: flex;
      }

      [class^='Badge'] {
        display: none;
      }
    }

    > * {
      position: relative;
      z-index: 1;
    }
  `}
  // DS Override: Update group avatar placeholder background color to match designs
    ${StyledAvatar} {
    ${({ isGroupAvatar, theme: { vars } }) =>
      isGroupAvatar && `background-color: ${vars.trainualBrandBlueSubdued}`};
  }
`;

export const ResponsibilityCardsGlobalListContainer = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100% - 7.5rem);
    padding: 0 ${constants.spacerMd2};
    overflow-y: auto;

    ${Scrollbar};

    &::-webkit-scrollbar-thumb {
      background-color: ${vars.borderDisabled};
      border: 5px solid ${vars.foundationBase1};
    }

    ${GroupResponsibilityCardContainer} {
      margin-bottom: ${constants.spacerMd1};
    }
  `};
`;

export const ResponsibilityCardsNonGroupListContainer = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: ${constants.spacerSm3};
    gap: ${constants.spacerSm3};
    overflow-x: clip;
  `};
`;

export const AddResponsibilityElement = styled.button<{ isVisible: boolean }>`
  ${({ theme: { constants, vars }, isVisible }) => css`
    position: relative;
    cursor: pointer;
    text-align: center;
    border-radius: ${constants.borderRadiusMd};
    padding: ${isVisible ? `${constants.spacerSm3} 0` : 0};
    color: ${vars.textDefault};
    background-color: transparent;
    max-height: ${isVisible ? '3.5rem' : 0};
    overflow: hidden;
    outline: none;
    border: none;
    transition: 0.3s ease-out;
    width: 100%;
    ${fontSm4};

    &:hover {
      color: ${vars.accentPrimaryDefault};
      background-color: ${vars.foundationSurface1};
    }
  `};
`;

export const AddResponsibilityWrapper = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerMd2};
  `};
`;

export const CardPlaceholder = styled.div<{ height: number }>`
  ${({ height, theme: { constants, vars } }) => css`
    width: 100%;
    flex: none;
    max-width: calc(18.5rem - ${constants.spacerMd2} - ${constants.spacerMd2});
    height: ${height}px;
    background-color: ${vars.foundationBase2};
    border: ${constants.borderWidthMd} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusMd};
    background-image: linear-gradient(
      135deg,
      ${vars.borderSurface2} 4.55%,
      ${vars.foundationBase2} 4.55%,
      ${vars.foundationBase2} 50%,
      ${vars.borderSurface2} 50%,
      ${vars.borderSurface2} 54.55%,
      ${vars.foundationBase2} 54.55%,
      ${vars.foundationBase2} 100%
    );
    background-size: ${constants.spacerLg1} ${constants.spacerLg1};
    transition: 0.2s ease-out;
  `};
`;
