import { useFormik } from 'formik';
import { keys, pickBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../../redux/errors/BasicErrorDisplay';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useExportTopicMutation } from '../../../../../../redux/services/resourceApis/courses/topicsApi';
import { useGetCurriculumElementsForAdminQuery } from '../../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { isCourse } from '../../../../../../types/Course';
import { EditableCurriculumElement } from '../../../../../../types/CurriculumElement';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Checkbox from '../../../../../design_system/input/controls/Checkbox';
import TaskModal, { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';
import { ErrorText } from '../../../../../design_system/Triage/InputField';
import Loader from '../../../../../design_system/Triage/Loader';
import { routes } from '../../../../publicApplication/applicationRouter';
import { LoaderWrapper } from '../../styles';

const t = initTranslations('export_course_modal');

type ModalAction = 'print' | 'pdf';

export interface Props {
  curriculumId: number;
  courseId: number;
  action?: ModalAction;
  closeRequest: () => void;
}

interface CheckboxValues {
  [key: number]: boolean;
}
interface FormValues {
  checkboxes: CheckboxValues;
}

const ExportCourseModal = ({ courseId, action = 'pdf', curriculumId, closeRequest }: Props) => {
  const {
    topic: { plural: topicsTerm, singular: topicTerm },
  } = useAccountTerminology();
  const {
    isLoading: isLoadingCurriculumElements,
    error: curriculumElementsError,
    data,
  } = useGetCurriculumElementsForAdminQuery(curriculumId);
  const [exportTopic, result] = useExportTopicMutation();
  const { isLoading, error } = result;
  const { curriculumElements: currEles } = data || {};
  const curriculumElements = useMemo(() => {
    return currEles?.filter((element) => isCourse(element));
  }, [currEles]);

  useDisplayFlashOnResponse({
    result,
    errorMessage: messageFromError(error)?.join(', '),
    successFunction: closeRequest,
  });

  const validateForm = useCallback(
    ({ checkboxes }: FormValues) => {
      if (!Object.values(checkboxes).some((checked) => checked)) {
        return { checkboxes: t('no_courses_checked', { topic: topicTerm }) };
      }
      return {};
    },
    [topicTerm]
  );

  const { slug } = useCurrentAccount();

  const submitForm = useCallback(
    (values: FormValues) => {
      const coursesIds = keys(pickBy(values.checkboxes, Boolean));
      if (action === 'print') {
        const curriculumPrintRoute = routes.curriculumPrint({
          slug,
          id: curriculumId,
          course_ids: coursesIds,
        }).href;
        window.open(curriculumPrintRoute, '_blank');
        closeRequest();
      } else if (action === 'pdf') {
        exportTopic({ curriculumId, coursesIds });
      }
    },
    [closeRequest, slug, action, exportTopic, curriculumId]
  );

  const initialCheckboxes = useMemo(() => {
    if (curriculumElements) {
      return curriculumElements.reduce(
        (acc, curriculumElement) => ({
          ...acc,
          [curriculumElement.elementId]: curriculumElement.elementId === courseId,
        }),
        {} as CheckboxValues
      );
    } else {
      return {} as CheckboxValues;
    }
  }, [courseId, curriculumElements]);

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues: {
      checkboxes: initialCheckboxes,
    },
    validate: (values: FormValues) => validateForm(values),
    onSubmit: (values: FormValues) => submitForm(values),
  });

  if (isLoadingCurriculumElements)
    return (
      <LoaderWrapper>
        <Loader size='lg' />
      </LoaderWrapper>
    );
  if (curriculumElementsError) return <BasicErrorDisplay error={curriculumElementsError} />;
  if (!curriculumElements) return <></>;

  const taskModalArgs: TaskModalProps = {
    title:
      action === 'pdf'
        ? t('title_pdf', { topics: topicsTerm })
        : t('title_print', { topics: topicsTerm }),
    onCloseRequest: closeRequest,
    heapModalName: 'export-courses-modal',
    primaryButtonText: action === 'pdf' ? t('export_button_pdf') : t('export_button_print'),
    primaryButtonTask: handleSubmit,
    processing: isLoading,
    isDisabled: false,
    secondaryButtonText: t('cancel_button'),
    subtitle:
      action === 'pdf'
        ? t('description_pdf', { topics: topicsTerm })
        : t('description_print', { topics: topicsTerm }),
  };

  return (
    <TaskModal {...taskModalArgs}>
      <form id='export-course-form'>
        {curriculumElements.map(
          ({ element: { title }, elementId }: EditableCurriculumElement, index) => (
            <Checkbox
              bottomMargin='1rem'
              checked={values.checkboxes[elementId]}
              id={`checkboxes.${elementId}`}
              key={index}
              label={title}
              name={`checkboxes.${elementId}`}
              onCheck={handleChange}
            />
          )
        )}
        {touched.checkboxes && <ErrorText>{errors.checkboxes}</ErrorText>}
      </form>
    </TaskModal>
  );
};

export default ExportCourseModal;
