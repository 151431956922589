import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useReducer } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import {
  ElementReportPageFilterState,
  setElementReportFilters,
} from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetElementReportTableFiltersDataQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import { elementKinds } from '../../../../../types/CurriculumElement';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import CollapsibleFiltersSection from '../../../shared/CollapsibleFiltersSection/CollapsibleFiltersSection';
import CompletionScoreSection from '../../CompletionScoreSection/CompletionScoreSection';
import { CheckboxWrapper } from '../../IndividualUserReportPage/IndividualUserReportFilters/styles';
import ReportFiltersSlideout from '../../ReportFiltersSlideout/ReportFiltersSlideout';
import { filterReducer } from './reducer';

const t = initTranslations('reports.element_reports.actions_header.filters');

const Index = ({ curriculumId }: { curriculumId: number }) => {
  const {
    splitFeatures: { contentFlowchartsEnabled },
  } = useCurrentAccount();
  const dispatch = useAppDispatch();
  const { filters: appliedFilters } = useAppSelector((state) => state.reports.elementReport);

  const initialFilterState: ElementReportPageFilterState = useMemo(
    () => ({
      completionScoreMin: 0,
      completionScoreMax: 100,
      contentTypeFilters: [],
    }),
    []
  );

  const [filterState, dispatchState] = useReducer(filterReducer, initialFilterState);
  const { completionScoreMax, completionScoreMin, contentTypeFilters } = filterState;

  const appliedFiltersCount =
    (completionScoreMin > 0 || completionScoreMax < 100 ? 1 : 0) + contentTypeFilters.length;
  const noAppliedFilters = appliedFiltersCount === 0;
  const isDisabledApplyBtn = isEqual(appliedFilters, filterState) && noAppliedFilters;

  const clearFilters = () => {
    dispatch(setElementReportFilters(initialFilterState));
    dispatchState({ type: 'setDefaultFilters' });
  };

  const applyFilters = () => {
    dispatch(
      setElementReportFilters({
        completionScoreMax,
        completionScoreMin,
        contentTypeFilters,
      })
    );
  };

  useEffect(() => {
    dispatch(setElementReportFilters(initialFilterState));
  }, [dispatch, initialFilterState]);

  const elementAttributes = useCurriculumElement();

  const { data } = useGetElementReportTableFiltersDataQuery({ id: curriculumId });
  if (!data) return <></>;

  const { curriculumElementsTypeCount, curriculumsCompletionData } = data || {};

  const completionData = Object.entries(curriculumsCompletionData).map((item) => ({
    name: item[0],
    count: item[1],
  }));

  const handleContentFilterCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string[]
  ) => {
    let updatedContentFilters = [...value];
    if (event.target.checked) {
      updatedContentFilters = [...value, event.target.name];
    } else {
      updatedContentFilters.splice(value.indexOf(event.target.name), 1);
    }
    return dispatchState({
      type: 'setContentTypeFilters',
      contentTypeFilters: updatedContentFilters,
    });
  };

  const elementKindsList = contentFlowchartsEnabled
    ? elementKinds
    : elementKinds.filter((element) => element !== 'flowchart');

  return (
    <ReportFiltersSlideout
      applyFilters={applyFilters}
      clearFilters={clearFilters}
      disabledApplyButton={isDisabledApplyBtn}
      disabledClearButton={noAppliedFilters}
      slideoutType='element-report-filter-slideout'
      title={t('title')}
    >
      <CollapsibleFiltersSection title={t('content_types')}>
        {elementKindsList.map((element) => (
          <CheckboxWrapper key={element}>
            <Checkbox
              checked={contentTypeFilters.includes(element)}
              disabled={curriculumElementsTypeCount[element] === 0}
              id={`filter-content-type-${element}`}
              label={`${elementAttributes({ elementKind: element }).termSingular} (${
                curriculumElementsTypeCount[element]
              })`}
              name={element}
              onCheck={(e) => handleContentFilterCheckbox(e, contentTypeFilters)}
            />
          </CheckboxWrapper>
        ))}
      </CollapsibleFiltersSection>
      <CompletionScoreSection
        completionScoreMax={completionScoreMax}
        completionScoreMin={completionScoreMin}
        data={completionData}
        setMaxCompletionScore={(values) => {
          dispatchState({
            type: 'setMaxCompletionScore',
            completionScoreMax: values,
          });
        }}
        setMinCompletionScore={(values) => {
          dispatchState({
            type: 'setMinCompletionScore',
            completionScoreMin: values,
          });
        }}
      />
    </ReportFiltersSlideout>
  );
};

export default Index;
