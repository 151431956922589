interface State {
  showFinishModal: boolean;
  showAssignModal: boolean;
  showManageUsers: boolean;
}
interface Action {
  type:
    | 'SHOW_FINISH_MODAL'
    | 'HIDE_FINISH_MODAL'
    | 'SHOW_ASSIGN_MODAL'
    | 'HIDE_ASSIGN_MODAL'
    | 'SHOW_MANAGE_USERS'
    | 'HIDE_MANAGE_USERS';
}

const modalReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SHOW_FINISH_MODAL':
      return { ...state, showFinishModal: true };
    case 'HIDE_FINISH_MODAL':
      return { ...state, showFinishModal: false };
    case 'SHOW_ASSIGN_MODAL':
      return { ...state, showAssignModal: true };
    case 'HIDE_ASSIGN_MODAL':
      return { ...state, showAssignModal: false };
    case 'SHOW_MANAGE_USERS':
      return { ...state, showManageUsers: true, showAssignModal: false };
    case 'HIDE_MANAGE_USERS':
      return { ...state, showManageUsers: false };
    default:
      return state;
  }
};
const initialState = {
  showFinishModal: false,
  showAssignModal: false,
  showManageUsers: false,
};

export { modalReducer, initialState };
