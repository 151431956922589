import React, { FC } from 'react';

import { useCheckModalState } from '../../../../redux/domains/modalsSlice/modalsSlice';
import CantMoveResponsibilityModal from '../modals/CantMoveResponsibilityModal/CantMoveResponsibilityModal';
import CelebratoryModal from '../modals/CelebratoryModal/CelebratoryModal';
import CreateAndSelectGroupModal from '../modals/CreateAndSelectGroupModal/CreateAndSelectGroupModal';
import MoveResponsibilityConfirmationModal from '../modals/MoveResponsibilityConfirmationModal/MoveResponsibilityConfirmationModal';
import ResponsibilityDetailsModal from '../modals/ResponsibilityDetailsModal/ResponsibilityDetailsModal';
import SelectGroupModal from '../modals/SelectGroupModal/SelectGroupModal';
import SelectUserModal from '../modals/SelectUserModal/SelectUserModal';
import { BoardModalsProps } from './types';

const BoardModals: FC<BoardModalsProps> = ({
  selectedUserIds,
  selectedGroupIds,
  currentBoardColumnResponsibility,
  currentBoardColumn,
  resetMoveResponsibility,
}) => {
  const isSelectGroupModalOpen = useCheckModalState('delegationSelectGroupModal');
  const isSelectUserModalOpen = useCheckModalState('delegationSelectUserModal');
  const isCreateAndSelectGroupModalOpen = useCheckModalState('delegationCreateAndSelectGroupModal');
  const isResponsibilityDetailsModalOpen = useCheckModalState('responsibilityDetailsModal');
  const isCelebratoryModalOpen = useCheckModalState('delegationCelebratoryModal');
  const isMoveResponsibilityConfirmationModalOpen = useCheckModalState(
    'moveResponsibilityConfirmationModal'
  );
  const isCantMoveResponsibilityModalOpen = useCheckModalState('cantMoveResponsibilityModal');

  const displayResponsibilityDetailsModalOpen =
    isResponsibilityDetailsModalOpen && currentBoardColumnResponsibility && currentBoardColumn;
  return (
    <>
      {isSelectUserModalOpen && <SelectUserModal selectedEntityIds={selectedUserIds} />}
      {isSelectGroupModalOpen && <SelectGroupModal selectedEntityIds={selectedGroupIds} />}
      {isCreateAndSelectGroupModalOpen && <CreateAndSelectGroupModal />}
      {displayResponsibilityDetailsModalOpen && (
        <ResponsibilityDetailsModal
          boardColumn={currentBoardColumn}
          boardColumnResponsibility={currentBoardColumnResponsibility}
        />
      )}
      {isMoveResponsibilityConfirmationModalOpen && (
        <MoveResponsibilityConfirmationModal resetMoveResponsibility={resetMoveResponsibility} />
      )}
      {isCelebratoryModalOpen && <CelebratoryModal />}
      {isCantMoveResponsibilityModalOpen && <CantMoveResponsibilityModal />}
    </>
  );
};

export default BoardModals;
