import styled from 'styled-components';

import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { FormGroup } from '../../shared/FormGroup';

export const InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ theme: { constants, vars } }) => ({
    marginBottom: constants.spacerMd2,
    label: {
      color: vars.textDefault,
      marginBottom: constants.spacerMd2,
      fontWeight: constants.fontSemibold,
    },
    input: {
      width: '100%',
      paddingTop: constants.spacerSm3,
      paddingBottom: constants.spacerSm3,
    },
    '> div': {
      marginTop: '0',
    },
  })};
`;

export const StyledFormGroup = styled(FormGroup)`
  width: 100%;
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: calc(50% - ${({ theme: { constants } }) => constants.spacerSm3});
  }
`;

export const ButtonRightPositionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TitleInner = styled.div`
  width: 18rem;
`;
