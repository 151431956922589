import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';

const ButtonContainer = styled.div`
  border-top: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('curriculums.ai_outliner.refresh_suggestions');

export type Props = {
  onClick: () => void;
  isLoading: boolean;
};

const RefreshCourseSuggestions = ({ onClick, isLoading }: Props) => {
  const {
    topic: { plural: topicTermPlural },
  } = useAccountTerminology();
  return (
    <ButtonContainer id='refresh-suggestions-button-container'>
      <DefaultButton
        buttonType='secondary'
        fullWidth
        iconName='refresh'
        iconPosition='left'
        id='open-ai-refresh-course-suggestions-button'
        loading={isLoading}
        onClick={onClick}
        size='md'
        text={
          isLoading
            ? t('loading_text', { topics: topicTermPlural.toLowerCase() })
            : t('secondary_button')
        }
      />
    </ButtonContainer>
  );
};

export default RefreshCourseSuggestions;
