import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { useGetUserActivitiesQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import Surface from '../../../../design_system/Triage/layout/Surface';
import ActivityCardsList from '../../../shared/activities/ActivityCardsList';

const StyledSurface = styled(Surface)`
  position: relative;
  border: none;
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
`;

interface LatestActivitiesProps {
  userId: number;
}

const t = initTranslations('user_profile.latest_activities');

const LatestActivities = ({ userId }: LatestActivitiesProps) => {
  const { isLoading, data, isError } = useGetUserActivitiesQuery(userId);

  if (isLoading) return <></>;
  if (isError) return <div>{t('error')}</div>;

  if (!data) return <></>;

  return (
    <StyledSurface>
      <ActivityCardsList
        activityGroups={data}
        seeAll={t('see_all_activities')}
        title={t('title')}
        userId={userId}
      />
    </StyledSurface>
  );
};

export default LatestActivities;
