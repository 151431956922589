import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { mediaBreakpointPxSm, mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import { fontSm5 } from '../../../styled/TypeSystem';

export const FormSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 ${constants.spacerMd2}`};
`;

export const FormSubSection = styled.div`
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerSm3} 0 ${constants.spacerMd2}`};

  &[id='custom-terminology-form'] {
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
    padding: ${({ theme: { constants } }) =>
      `${constants.spacerMd2} ${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerMd2}`};

    @media (min-width: ${mediaBreakpointPxSm}) {
      padding: ${({ theme: { constants } }) =>
        `${constants.spacerMd2} ${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerMd3}`};
    }
  }
`;

export const FormSectionTitle = styled.div`
  ${({ theme: { constants, vars } }) => ({
    color: vars.textDefault,
    fontWeight: constants.fontSemibold,
  })};
  ${fontSm5};
`;

export const FormSectionDescription = styled.div`
  max-width: 80%;
  ${fontSm5}
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const InputRow = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const ToggleContainer = styled.div`
  max-width: 20%;
`;

export const TerminologyInputRow = styled.div`
  width: 80%;
`;

export const TerminologySettingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const TerminologyPluralsSettingWrapper = styled.div`
  width: 25%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};

  @media (min-width: ${mediaBreakpointPxXl}) {
    width: 17%;
  }
`;

export const TerminologySettingLabel = styled.span`
  margin: ${({ theme: { constants } }) => `0 0 ${constants.spacerSm2} ${constants.spacerSm3}`};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  white-space: nowrap;

  ${fontSm5}
`;

export const SectionDivider = styled.hr`
  border-width: ${({ theme: { constants } }) => constants.borderWidthSm} 0 0 0;
  border-style: solid;
  border-color: ${({ theme: { vars } }) => vars.borderSurface2};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;
