import styled from 'styled-components';

import { TruncatedText } from '../../../../../../design_system/Triage/TruncatedText';
import { fontMd1, fontSm5 } from '../../../../../../styled/TypeSystem';

export const RestrictionWrapper = styled.div`
  padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd1};
`;

export const UsersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const UserAvatar = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const UserName = styled.div`
  ${fontSm5};
  ${TruncatedText({})};
`;

export const SingleSelectWrapper = styled.div`
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;
