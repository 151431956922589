import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import scrollToElement from '../../../../../../lib/scrollToElement';
import Icon from '../../../../../design_system/display/icons/Icon';
import { isMultimediaContentOnly } from '../../../../editor/shared/IsMultimediaContentOnly';
import {
  ListItemContainer,
  PositionText,
  QuestionText,
} from '../../../shared/SurveyQuestionsList/styles';
import { StyledCheckmarkIcon } from './styles';
import { AnsweredQuestionListItemProps } from './types';

const t = initTranslations('survey_consume');

const AnsweredQuestionListItem = ({ index, question }: AnsweredQuestionListItemProps) => {
  const { plainText, textJson, id } = question;
  const isMultimedia = isMultimediaContentOnly({
    plainText,
    contentJson: textJson,
  });
  const questionTitle = isMultimedia ? t('multimedia_question') : plainText;

  const handleListItemClick = () => {
    scrollToElement(`answered-question-card-${id}`);
  };

  return (
    <ListItemContainer onClick={handleListItemClick}>
      <PositionText>{index + 1}</PositionText>
      <QuestionText>{questionTitle}</QuestionText>
      <StyledCheckmarkIcon correct={question.correct}>
        <Icon name='check' size='2xs' weight='solid' />
      </StyledCheckmarkIcon>
    </ListItemContainer>
  );
};

export default AnsweredQuestionListItem;
