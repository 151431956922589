import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import { ESignatureRowContainer, ESignatureRowContent } from './signatureLabelStyles';

const t = initTranslations('outline_content');

const ESignatureLabel = () => {
  return (
    <ESignatureRowContainer id='e-signature-label'>
      <ESignatureRowContent>
        <Icon name='signature' size='xs' weight='solid' />
        <span>{t('e_signature_status')}</span>
      </ESignatureRowContent>
    </ESignatureRowContainer>
  );
};

export default ESignatureLabel;
