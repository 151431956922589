import React from 'react';
import styled from 'styled-components';

import titleize from '../../../../lib/titleize';
import { OrgChartUser } from '../../../../types/OrgChart';
import { UserStatus } from '../../../../types/User';
import Avatar from '../../../design_system/display/avatar';
import Badge from '../../../design_system/display/badge';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import FlexContainer from '../../../styled/FlexContainer';
import { fontMd1, fontSm3 } from '../../../styled/TypeSystem';
import OrgChartStyledCard from '../OrgChartShared/OrgChartStyledCard';

const StyledCard = styled(OrgChartStyledCard)`
  padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const TopRow = styled(FlexContainer)`
  margin: 0.375rem 0;
  min-height: ${({ theme: { constants } }) => constants.heightXs};
  padding-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const Content = styled.div`
  padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const AvatarWrapper = styled.div`
  margin-top: -${({ theme: { constants } }) => constants.spacerMd3};
`;

const Name = styled.h3<{ isActive: boolean }>`
  color: ${({ isActive, theme: { vars } }) =>
    isActive ? vars.accentPrimaryDefault : vars.textDisabled};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: 0;
  ${fontMd1};
  ${TruncatedText({})};
`;

const JobTitle = styled.p<{
  isActive: boolean;
}>`
  color: ${({ isActive, theme: { vars } }) => (isActive ? vars.textDefault : vars.textDisabled)};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm3};
  ${TruncatedText({})};
`;

interface Props {
  canUpdateOrgChart: boolean;
  user: OrgChartUser;
}

const mapBadgeColor = (status: UserStatus) => {
  switch (status) {
    case 'pending': {
      return 'caution';
    }
    case 'archived': {
      return 'general';
    }
    case 'not invited': {
      return 'info';
    }
  }
};

const OrgChartCard = ({ canUpdateOrgChart, user }: Props) => {
  const { id, name, title, status, avatar } = user;
  const statusType = mapBadgeColor(status);

  return (
    <StyledCard className='org-chart-card' id={`user-card-${id}`}>
      <TopRow justifyContent='space-between'>
        <AvatarWrapper>
          <Avatar crossOrigin='anonymous' image={avatar} name={name} shape='circle' size='md' />
        </AvatarWrapper>

        {canUpdateOrgChart && statusType && <Badge text={titleize(status)} type={statusType} />}
      </TopRow>
      <Content>
        <Name isActive={!statusType}>{name}</Name>
        <JobTitle isActive={!statusType}>{title}</JobTitle>
      </Content>
    </StyledCard>
  );
};

export default OrgChartCard;
