import DOMPurify from 'dompurify';

enum Entity {
  Amp = '&',
  Lt = '<',
  Gt = '>',
  Quot = '"',
  Apos = "'",
  Space = ' ',
  EncodedAmp = '&amp;',
  EncodedLt = '&lt;',
  EncodedGt = '&gt;',
  EncodedQuot = '&quot;',
  EncodedApos = '&#39;',
  EncodedSpace = '&nbsp;',
}

const replacements: { [key: string]: string } = {
  [Entity.EncodedAmp]: Entity.Amp,
  [Entity.EncodedLt]: Entity.Lt,
  [Entity.EncodedGt]: Entity.Gt,
  [Entity.EncodedQuot]: Entity.Quot,
  [Entity.EncodedApos]: Entity.Apos,
  [Entity.EncodedSpace]: Entity.Space,
};

const sInputRegex = new RegExp(Object.keys(replacements).join('|'), 'gi');

const sanitizeInput = (input: string, deepReplace = true): string => {
  const purifiedInput = DOMPurify.sanitize(input, { ALLOWED_TAGS: [] });

  if (!deepReplace) return purifiedInput;

  return sanitizeTags(purifiedInput);
};

const sanitizeTags = (input: string): string => {
  return input.replace(sInputRegex, (match) => replacements[match]);
};

export { sanitizeInput, sanitizeTags };
