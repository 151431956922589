import initTranslations from '../../../../lib/initTranslations';
import { EDurationTimeFrequency } from '../BoardColumn/types';

const t = initTranslations('delegation_planner.frequency_dropdown.frequencies');

export const DURATION_FREQUENCIES: Record<EDurationTimeFrequency, string> = {
  [EDurationTimeFrequency.Day]: t('daily'),
  [EDurationTimeFrequency.Week]: t('weekly'),
  [EDurationTimeFrequency.Month]: t('monthly'),
  [EDurationTimeFrequency.Quarter]: t('quarterly'),
  [EDurationTimeFrequency.Year]: t('yearly'),
};
