import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import Link from '../../../../design_system/Link';
import P from '../../../../design_system/text/P';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

const StatusBlock = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerLg1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

export const SubtitleBlock = styled.div`
  margin-top: -${({ theme: { constants } }) => constants.spacerMd3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const NotInvitedBlock = styled(StatusBlock)`
  background-color: ${({ theme: { vars } }) => vars.stateBackgroundError};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const SuccessInvitedBlock = styled(StatusBlock)`
  background-color: ${({ theme: { vars } }) => vars.stateBackgroundSuccess};
`;

export const TitleBlock = styled.div<{ isInvitesSent?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ isInvitesSent, theme: { constants } }) =>
    !isInvitesSent && constants.spacerSm3};
`;

export const SuccessTitleBlock = styled(TitleBlock)`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const StyledSuccessIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.stateSuccess};
`;

export const StyledErrorIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const StyledLink = styled(Link)`
  font-size: inherit;
`;

export const Description = styled(P)`
  ${fontSm4};
`;

export const StyledP = styled.p`
  ${fontSm5};
`;
