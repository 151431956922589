import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { Route } from 'type-route';

import { useResponsibilityHeaderTitleHandles } from '../../../../../../hooks/responsibility/useResponsibilityHeaderTitleHandlers';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useGetBoardColumnsQuery } from '../../../../../../redux/services/resourceApis/boardColumns/boardColumnsApi';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import Avatar from '../../../../../design_system/display/avatar';
import { Props as AvatarProps } from '../../../../../design_system/display/avatar/Avatar';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import EditorInput from '../../../../shared/EditorInput/EditorInput';
import { BoardColumn } from '../../../BoardColumn/types';
import { ResponsibilityDetailsFormData } from '../ResponsibilityDetails/types';
import { ModalHeaderData } from '../types';
import { AssistiveTextContainer, HeaderAvatarWrapper } from './styles';

type ModalHeaderProps = {
  column: BoardColumn;
  headerData: ModalHeaderData;
};

const ModalHeader: FC<ModalHeaderProps> = ({ column: initialBoardColumn, headerData }) => {
  const {
    params: { id: currentBoardId },
  } = useRoute() as Route<typeof routes.delegationPlannerBoard>;
  const { data: boardColumnsResponse } = useGetBoardColumnsQuery({
    boardId: currentBoardId,
  });
  const boardColumns = boardColumnsResponse?.boardColumns || [];
  const { initialValues, setFieldValue, values } =
    useFormikContext<ResponsibilityDetailsFormData>();
  const { handleTitleValidation, handleBeforeInput, handleChange } =
    useResponsibilityHeaderTitleHandles(initialValues, setFieldValue, values.name);
  const { currentBoardColumnResponsibility } = useAppSelector((state) => state.delegationPlanner);
  const isEditable = !currentBoardColumnResponsibility?.groupId;
  const currentBoardColumn =
    boardColumns.find((column) => column.id === +values.targetColumnId) || initialBoardColumn;
  const isError = headerData.name === values.name && !!headerData.error;

  const isGroupTypeCard = currentBoardColumn.columnType === 'Group';
  const avatarProps = (image: string | undefined): AvatarProps => {
    const commonProps = {
      name: currentBoardColumn.columnLabel,
      shape: 'circle',
      size: 'xs',
    } as AvatarProps;

    if (isGroupTypeCard) {
      return { ...commonProps, icon: 'users' };
    } else {
      return { ...commonProps, image };
    }
  };

  return (
    <>
      <EditorInput
        $isError={isError}
        className='responsibility-title-header-input'
        disabled={!isEditable}
        onBeforeInput={handleBeforeInput}
        onBlur={handleTitleValidation}
        onChange={handleChange}
        spellCheck={false}
        value={values.name}
      />
      {isError && (
        <AssistiveTextContainer>
          <AssistiveText id='input-error-text' text={headerData.error} type='error' />
        </AssistiveTextContainer>
      )}
      <HeaderAvatarWrapper isGroupAvatar={isGroupTypeCard}>
        <Avatar {...avatarProps(currentBoardColumn.avatarUrl)} />
        <span>{currentBoardColumn.columnLabel}</span>
      </HeaderAvatarWrapper>
    </>
  );
};

export default ModalHeader;
