import React, { FC, useCallback, useEffect, useState } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import Poppable from '../../../../Poppable';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import OutsideClickHandler from '../../../shared/OutsideClickHandler';
import { TDurationTimeFrequency } from '../../BoardColumn/types';
import FrequenciesPopup from '../../FrequenciesPopup/FrequenciesPopup';
import { FrequencyDropdownContainer, FrequencyValue, StyledIconButton } from './styles';
import { TFrequencyDropdownProps } from './types';

const t = initTranslations('delegation_planner.frequency_dropdown');

const placement = 'bottom-start';

const FrequencyDropdown: FC<TFrequencyDropdownProps> = ({
  menuId,
  setFrequency,
  currentFrequency,
  usePortal,
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState(currentFrequency);

  const { handleMenuClick, isMenuOpen, closeMenu } = useActiveMenuHandler({ menuId });

  const handleFrequencySelect = (value: TDurationTimeFrequency) => {
    setSelectedFrequency(value);
    setFrequency(value);
    closeMenu();
  };

  const handleOutsideClick = useCallback(
    (e: MouseEvent & { target: Element }) => {
      if (!(e.target as Element).closest('.duration-frequency-popup-trigger')) {
        closeMenu();
      }
    },
    [closeMenu]
  );

  useEffect(() => {
    if (currentFrequency) {
      setSelectedFrequency(currentFrequency);
    }
  }, [currentFrequency]);

  return (
    <Poppable
      isOpen={isMenuOpen}
      item={
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <FrequenciesPopup
            onClose={closeMenu}
            onSelectValue={handleFrequencySelect}
            selectedValue={selectedFrequency}
          />
        </OutsideClickHandler>
      }
      menuStyling={{ zIndex: 101 }}
      onClick={handleMenuClick}
      placement={placement}
      strategy='absolute'
      trigger={
        <FrequencyDropdownContainer className='duration-frequency-popup-trigger'>
          <FrequencyValue>
            {t('frequency_value', { value: selectedFrequency.substring(0, 1) })}
          </FrequencyValue>
          <StyledIconButton
            ariaLabel={t('aria_label.open_frequency_dropdown')}
            buttonSize='sm'
            className='duration-frequency-chevron'
            id={`${menuId}-trigger`}
            name='chevron-down'
            weight='solid'
          />
        </FrequencyDropdownContainer>
      }
      usePortal={usePortal}
    />
  );
};

export default FrequencyDropdown;
