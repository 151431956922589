import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import useWindowResize from '../../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../../lib/initTranslations';
import InputFieldWithCharacterCounter from '../../../../../design_system/Triage/InputFieldWithCharacterCounter';
import { CUSTOM_TERMINOLOGY_MAX_CHARACTERS } from '../../../shared/constants/accountSettings';
import { SettingsData } from '../../types';
import TerminologyPluralsSettingToggle from './TerminologyPluralsSettingToggle';

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

const InputSection = styled.div`
  display: flex;
  align-items: end;
`;

const TermWrapper = styled.div`
  flex-grow: 1;
`;

const t = initTranslations('account_settings.content_settings.custom_terminology_form');

const CustomTerminologyForm = () => {
  const { values, errors, handleChange, setFieldValue } = useFormikContext<SettingsData>();
  const { isMobile } = useWindowResize();

  return (
    <InputGrid>
      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.subject_term}
            id='subject-term'
            inputFor='subject_term'
            label={t('curriculum.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='subject_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.subject_term}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralize_subject_term}
          className='subject-term-pluralize'
          displaySettingLabel
          handleToggle={() =>
            setFieldValue('pluralize_subject_term', !values.pluralize_subject_term)
          }
          id='enable-subject-term-pluralize'
          name='pluralize_subject_term'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.company_term}
            id='company-term'
            inputFor='company_term'
            label={t('company.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='company_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.company_term}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralize_company_term}
          className='company-term-pluralize'
          displaySettingLabel={!isMobile}
          handleToggle={() =>
            setFieldValue('pluralize_company_term', !values.pluralize_company_term)
          }
          id='enable-company-term-pluralize'
          name='pluralize_company_term'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.topic_term}
            id='topic-term'
            inputFor='topic_term'
            label={t('course.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='topic_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.topic_term}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralize_topic_term}
          className='topic-term-pluralize'
          handleToggle={() => setFieldValue('pluralize_topic_term', !values.pluralize_topic_term)}
          id='enable-topic-term-pluralize'
          name='pluralize_topic_term'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.policy_term}
            id='policy-term'
            inputFor='policy_term'
            label={t('policy.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='policy_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.policy_term}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralize_policy_term}
          className='policy-term-pluralize'
          handleToggle={() => setFieldValue('pluralize_policy_term', !values.pluralize_policy_term)}
          id='enable-policy-term-pluralize'
          name='pluralize_policy_term'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.step_term}
            id='step-term'
            inputFor='step_term'
            label={t('step.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='step_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.step_term}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralize_step_term}
          className='step-term-pluralize'
          handleToggle={() => setFieldValue('pluralize_step_term', !values.pluralize_step_term)}
          id='enable-step-term-pluralize'
          name='pluralize_step_term'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.process_term}
            id='process-term'
            inputFor='process_term'
            label={t('process.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='process_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.process_term}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralize_process_term}
          className='process-term-pluralize'
          handleToggle={() =>
            setFieldValue('pluralize_process_term', !values.pluralize_process_term)
          }
          id='enable-process-term-pluralize'
          name='pluralize_process_term'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.responsibility_term}
            id='responsibility-term'
            inputFor='responsibility_term'
            label={t('responsibility.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='responsibility_term'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.responsibility_term}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralize_responsibility_term}
          className='responsibility-term-pluralize'
          handleToggle={() =>
            setFieldValue('pluralize_responsibility_term', !values.pluralize_responsibility_term)
          }
          id='enable-responsibility-term-pluralize'
          name='pluralize_responsibility_term'
        />
      </InputSection>
    </InputGrid>
  );
};

export default CustomTerminologyForm;
