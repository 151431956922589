import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Badge from '../../../../../design_system/display/badge';
import { CurriculumInfoItem } from '../styles';

const t = initTranslations('home.curriculum_assignments');

type CurriculumAssigmentsBadgesProps = {
  showESignatureBadge: boolean;
  showOverdueBadge: boolean;
  showJustUpdatedBadge: boolean;
};

const CurriculumAssignmentsBadges = ({
  showESignatureBadge,
  showOverdueBadge,
  showJustUpdatedBadge,
}: CurriculumAssigmentsBadgesProps) => (
  <>
    {showESignatureBadge && (
      <CurriculumInfoItem>
        <Badge clickable size='md' text={t('e_signature_required')} type='trainual-purple' />
      </CurriculumInfoItem>
    )}
    {showOverdueBadge && (
      <CurriculumInfoItem>
        <Badge clickable size='md' text={t('overdue')} type='error' />
      </CurriculumInfoItem>
    )}
    {showJustUpdatedBadge && (
      <CurriculumInfoItem>
        <Badge clickable size='md' text={t('just_updated')} type='info' />
      </CurriculumInfoItem>
    )}
  </>
);

export default CurriculumAssignmentsBadges;
