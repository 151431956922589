import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import LabelToolbarButton from '../LabelToolbarButton';

const t = initTranslations('editor_toolbar.insert_options');

interface Props {
  active: boolean;
  className: string;
  onClick?: () => void;
}

const TableToolbarButton = ({ active, className, onClick }: Props) => {
  return (
    <LabelToolbarButton
      active={active}
      ariaLabel={t('aria_for_table')}
      className={className}
      iconName='table-cells-large'
      iconType='name'
      id='table-button'
      label={t('table')}
      onClick={onClick}
    />
  );
};

export default TableToolbarButton;
