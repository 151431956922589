import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import { getAddressDataByGoogleAddressComponents } from '../../../../../lib/billing/getAddressDataByGoogleAddressComponents';
import initTranslations from '../../../../../lib/initTranslations';
import { InputFieldWrapper, StyledInputField } from '../styles';
import { GoogleAutocompleteDropdownStyle } from './styles';
import { AddressFieldProps, GooglePlace } from './types';

const t = initTranslations('update_payment_method_modal');

const AddressField = ({
  value = '',
  errorText,
  dispatch,
  validateField,
  validateFields,
  handleChange,
  isFormProcessing,
}: AddressFieldProps) => {
  const { configs } = usePrivateConfigs();
  const placesApiKey = configs['PLACES_API_KEY'];
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: placesApiKey,
    options: { types: ['geocode'] },
    onPlaceSelected: setAddressDataByGoogleAddressComponents,
  });

  function setAddressDataByGoogleAddressComponents({ address_components }: GooglePlace) {
    const parsedGoogleResponse = getAddressDataByGoogleAddressComponents(address_components);

    if (parsedGoogleResponse) {
      const { addressLine2, ...fieldsToValidate } = parsedGoogleResponse;

      validateFields({ fieldsToValidate });

      dispatch({
        type: 'updateFormData',
        payload: {
          updatedData: { ...fieldsToValidate, addressLine2 },
          paymentDetailsChanged: true,
        },
      });
    }
  }

  return (
    <InputFieldWrapper withMarginBottom>
      <StyledInputField
        disabled={isFormProcessing}
        errorText={errorText}
        forwardRef={ref}
        id='addressLine1'
        label={t('address.billing_address')}
        name='addressLine1'
        onBlur={(event) => validateField({ event })}
        onChange={(event) => handleChange({ event })}
        placeholder={t('address.enter_your_address')}
        required
        type='text'
        value={value}
      />
      <GoogleAutocompleteDropdownStyle />
    </InputFieldWrapper>
  );
};

export default AddressField;
