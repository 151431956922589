import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useReducer } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import {
  UserAssignmentReportPageFilterState,
  setUserAssignmentReportFilters,
} from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetUserAssignmentReportTableFiltersDataQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import { groupsToOptions } from '../../../../../types/Group';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import CollapsibleFiltersSection from '../../../shared/CollapsibleFiltersSection/CollapsibleFiltersSection';
import CompletionScoreSection from '../../CompletionScoreSection/CompletionScoreSection';
import ReportFiltersSlideout from '../../ReportFiltersSlideout/ReportFiltersSlideout';
import { filterReducer } from './reducer';

const t = initTranslations('reports.user_assignment_report_filters');

const UserAssignmentReportFilters = ({ curriculumId }: { curriculumId: number }) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.reports.userAssignmentReport);

  const initialFilterState: UserAssignmentReportPageFilterState = useMemo(
    () => ({
      completionScoreMin: 0,
      completionScoreMax: 100,
      groupIds: [],
      teamIds: [],
      roleIds: [],
    }),
    []
  );

  const [filterState, dispatchState] = useReducer(filterReducer, initialFilterState);
  const {
    completionScoreMax = 100,
    completionScoreMin = 0,
    groupIds,
    teamIds,
    roleIds,
  } = filterState;

  const appliedFiltersCount =
    groupIds.length + (completionScoreMin > 0 || completionScoreMax < 100 ? 1 : 0);

  const noAppliedFilters = appliedFiltersCount === 0;
  const isDisabledApplyBtn = isEqual(filters, filterState) && noAppliedFilters;

  const clearFilters = () => {
    dispatch(setUserAssignmentReportFilters(initialFilterState));
    dispatchState({ type: 'setDefaultFilters' });
  };

  const applyFilters = () => {
    dispatch(
      setUserAssignmentReportFilters({
        groupIds,
        roleIds,
        teamIds,
        completionScoreMax,
        completionScoreMin,
      })
    );
  };

  useEffect(() => {
    dispatch(setUserAssignmentReportFilters(initialFilterState));
  }, [dispatch, initialFilterState]);

  const { data } = useGetUserAssignmentReportTableFiltersDataQuery({ id: curriculumId });

  if (!data) return <></>;

  const { completionData, groups } = data;

  const teams = groups.filter(({ kind }) => kind === 'team');
  const roles = groups.filter(({ kind }) => kind === 'role');

  const assignmentsCompletionData = Object.entries(completionData).map((item) => ({
    name: item[0],
    count: item[1],
  }));

  return (
    <ReportFiltersSlideout
      applyFilters={applyFilters}
      clearFilters={clearFilters}
      disabledApplyButton={isDisabledApplyBtn}
      disabledClearButton={noAppliedFilters}
      slideoutType='user-assignment-report-filter-slideout'
      title={t('title')}
    >
      <CompletionScoreSection
        completionScoreMax={completionScoreMax}
        completionScoreMin={completionScoreMin}
        data={assignmentsCompletionData}
        setMaxCompletionScore={(values) => {
          dispatchState({
            type: 'setMaxCompletionScore',
            completionScoreMax: values,
          });
        }}
        setMinCompletionScore={(values) => {
          dispatchState({
            type: 'setMinCompletionScore',
            completionScoreMin: values,
          });
        }}
      />
      <CollapsibleFiltersSection title={t('group')}>
        <MultiSelectField
          defaultValue={[]}
          onNonDefaultSelected={(value: string[]) => {
            dispatchState({ type: 'setGroups', groupIds: value });
            const teamIds = teams.map(({ id }) => `${id}`);
            const roleIds = roles.map(({ id }) => `${id}`);
            const newTeamIds = value.filter((id) => teamIds.includes(id));
            const newRoleIds = value.filter((id) => roleIds.includes(id));
            dispatchState({ type: 'setRoles', roleIds: newRoleIds });
            dispatchState({ type: 'setTeams', teamIds: newTeamIds });
          }}
          options={groupsToOptions(groups)}
          placeholder={t('group_placeholder')}
          value={groupIds}
        />
      </CollapsibleFiltersSection>
    </ReportFiltersSlideout>
  );
};

export default UserAssignmentReportFilters;
