import React from 'react';
import styled from 'styled-components';

import { ElementKind } from '../../../../../types/CurriculumElement';

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ButtonWrapper = styled.div`
  width: 50%;
`;

export type NavigationButtonProps = {
  id: number;
  elementKind: ElementKind;
  mode: 'edit' | 'consume';
};

type Props = {
  previousButton: JSX.Element | null;
  nextButton: JSX.Element | null;
};

const BaseNavigationButtons = ({ previousButton, nextButton }: Props) => {
  return (
    <ButtonsWrapper>
      <ButtonWrapper>{previousButton}</ButtonWrapper>
      <ButtonWrapper>{nextButton}</ButtonWrapper>
    </ButtonsWrapper>
  );
};

export default BaseNavigationButtons;
