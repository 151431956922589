import styled from 'styled-components';

export const StyledActionButtonsContainer = styled.div`
  /* The following styles are needed to fix the flyout position issue. */
  display: flex;
  position: absolute;
  width: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;

  div[class*='Poppable__Wrapper'] {
    left: 50%;
    transform: translateX(-50%) !important;
    position: absolute !important;
  }

  div[class*='ToolbarContainer'] {
    div[class*='FlyoutPoppableContainer'] {
      button {
        padding-top: 0;
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;

export const ImageLayoutWrapper = styled.div`
  div[class*='ImageFlyout__StyledFlyout'] {
    min-width: 32rem;
  }
`;

export const ToolbarWrapper = styled.div`
  position: relative;
  min-height: ${({ theme: { constants } }) => constants.spacerLg3};
`;
