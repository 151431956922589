import styled from 'styled-components';

import { rightSectionStyles } from '../../../shared/FullScreenOverlay/styles';

export const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-top: ${({ theme: { constants } }) => constants.spacerMd3};
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const PaymentSummaryFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const PaymentSummaryWrapper = styled.div`
  ${rightSectionStyles};
`;
