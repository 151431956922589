import styled from 'styled-components';

import { MultilineTruncatedText } from '../../../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../../../styled/TypeSystem';

export const OutlineElementItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm2} ${constants.spacerSm3} ${constants.spacerSm2} ${constants.spacerMd3}`};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const OutlineElementItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm5};
`;

export const OutlineElementItemTitle = styled.span`
  width: calc(100% - 1.5rem);
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${MultilineTruncatedText({ lineClamp: 2 })};
  ${fontSm5};
`;
