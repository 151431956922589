import React, { useCallback, useState } from 'react';
import { PaletteOption } from 'saguaro';
import styled from 'styled-components';

import InputField from '../../../../components/design_system/Triage/InputField';
import useTabState from '../../../../hooks/useTabState';
import initTranslations from '../../../../lib/initTranslations';
import Tabs from '../../../design_system/navigation/Tabs';
import Surface from '../../../design_system/Triage/layout/Surface';
import { fontSm5 } from '../../../styled/TypeSystem';
import {
  PalettePickerPopupButton,
  PalettePickerPopupInputRow,
  PalettePickerPopupWrapper,
  PaletteSwatchSquare,
  PaletteSwatchSquareWrapper,
} from './styles';
import { PalettePickerPopupProps } from './types';

const t = initTranslations('account_settings.palette_picker');
const HexCodeWarning = styled(Surface)`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border-color: ${({ theme: { vars } }) => vars.borderSurface1};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm5}
`;
const Footer = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  display: flex;
  justify-content: flex-end;
`;

const ActiveTabsWrapper = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ActiveTab = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm1};
`;

const PalettePickerPopup = ({
  onChange,
  palette: initialPalette,
  palettes,
  hex,
  hidePalettePicker,
}: PalettePickerPopupProps) => {
  const [currentHex, setCurrentHex] = useState(hex);
  const [currentPalette, setCurrentPalette] = useState(initialPalette);
  const { activeTab, setActiveTab } = useTabState(
    initialPalette ? 'theme_color_tab' : 'hex_code_tab'
  );

  const save = useCallback(() => {
    if (activeTab == 'hex_code_tab') {
      onChange(currentHex);
    } else if (activeTab == 'theme_color_tab') {
      if (!currentPalette) {
        return;
      }
      onChange(currentPalette);
    }
    hidePalettePicker();
  }, [activeTab, currentHex, currentPalette, hidePalettePicker, onChange]);

  return (
    <PalettePickerPopupWrapper>
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          {
            name: t('theme_color_tab'),
            tabId: 'theme_color_tab',
          },
          {
            name: t('hex_code_tab'),
            tabId: 'hex_code_tab',
          },
        ]}
      />
      <ActiveTabsWrapper>
        {activeTab == 'theme_color_tab' && (
          <ActiveTab>
            <PaletteSwatchSquareWrapper>
              {palettes?.map((palette: PaletteOption) => (
                <PaletteSwatchSquare
                  active={palette == currentPalette}
                  key={palette}
                  onClick={() => {
                    setCurrentPalette(palette);
                  }}
                  palette={palette}
                />
              ))}
            </PaletteSwatchSquareWrapper>
          </ActiveTab>
        )}
        {activeTab == 'hex_code_tab' && (
          <ActiveTab>
            <PalettePickerPopupInputRow>
              <InputField
                onChange={(e) => {
                  e.preventDefault();
                  setCurrentHex(e.target.value.trim());
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    hidePalettePicker();
                  }
                }}
                placeholder={t('hex_color')}
                value={currentHex}
              />
              <HexCodeWarning>{t('hex_code_warning')}</HexCodeWarning>
            </PalettePickerPopupInputRow>
          </ActiveTab>
        )}
      </ActiveTabsWrapper>
      <Footer>
        <PalettePickerPopupButton
          disabled={
            (activeTab == 'theme_color_tab' && !currentPalette) ||
            (activeTab == 'hex_code_tab' &&
              (!currentHex || (currentHex.length !== 4 && currentHex.length !== 7)))
          }
          id='save-palette-picker-changes'
          loading={false}
          onClick={save}
          text={t('save')}
          type='button'
        />
      </Footer>
    </PalettePickerPopupWrapper>
  );
};
export default PalettePickerPopup;
