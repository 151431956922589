import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';

const t = initTranslations('search_results_page.filter');

const SearchResultsFilterButton = () => {
  return (
    <DefaultButton
      buttonType='tertiary'
      className='flyout-trigger'
      iconName='sliders'
      iconWeight='solid'
      id='search-results-filter-button'
      text={t('filter')}
    />
  );
};

export default SearchResultsFilterButton;
