import { isEqual } from 'lodash';
import React, { useReducer } from 'react';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateFormattedAccountDataMutation } from '../../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import InputField from '../../../../../design_system/Triage/InputField';
import { InputRow } from '../../styles';
import CreatableSelectField from '../CreatableSelectField';
import { IpAddressesReducer } from './reducer';
import { CurrentIpAddressWrapper, CurrentIpInputSection } from './styles';
import { WhitelistedIpAddressesFormProps, WhitelistedIpAddressesFormState } from './types';

const t = initTranslations('account_settings.advanced_settings.whitelisted_ip_addresses_form');

const WhitelistedIpAddressesForm = ({ ipWhitelist, remoteIp }: WhitelistedIpAddressesFormProps) => {
  const initialState: WhitelistedIpAddressesFormState = {
    selectedIpAddresses: ipWhitelist,
  };

  const [localState, dispatchState] = useReducer(IpAddressesReducer, initialState);
  const { selectedIpAddresses } = localState;

  const [updateAccountData, result] = useUpdateFormattedAccountDataMutation();
  const { isLoading } = result;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  const formattedData = selectedIpAddresses.length ? selectedIpAddresses : [null];

  const isValuesUnchanged = isEqual(selectedIpAddresses, ipWhitelist);

  return (
    <>
      <InputRow>
        <CreatableSelectField
          fieldLabelText={t('label')}
          name='ip_whitelist'
          onValueChanged={(value: string[]) =>
            dispatchState({ type: 'changeSelectedIpAddresses', selectedIpAddresses: value })
          }
          placeholder=''
          selectedValue={selectedIpAddresses}
        />
      </InputRow>
      <CurrentIpAddressWrapper>
        <CurrentIpInputSection>
          <InputField
            id='current-ip-input'
            label={t('current_ip_label')}
            readOnly
            value={remoteIp}
          />
        </CurrentIpInputSection>
        <DefaultButton
          disabled={isValuesUnchanged}
          id='save-ip-whitelist'
          loading={isLoading}
          onClick={() => updateAccountData({ ip_whitelist: formattedData })}
          text={t('save_changes')}
        />
      </CurrentIpAddressWrapper>
    </>
  );
};

export default WhitelistedIpAddressesForm;
