import React, { useEffect, useReducer, useState } from 'react';
import { Route } from 'type-route';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import { useGetDefaultViewByFilter } from '../../../../../hooks/templates/useGetDefaultViewByFilter';
import { useTemplateParamsToUrl } from '../../../../../hooks/templates/useTemplateParamsToUrl';
import usePageTitle from '../../../../../hooks/usePageTitle';
import useSlideout from '../../../../../hooks/useSlideout';
import NoResultsGraphic from '../../../../../images/searches_empty_state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { getTemplateFiltersCount } from '../../../../../lib/templates/getTemplateFiltersCount';
import { useGetOutlineTemplatesQuery } from '../../../../../redux/services/resourceApis/templates/templatesApi';
import {
  OutlineTemplatesSort,
  OutlineTemplatesViewByFilter,
} from '../../../../../redux/services/resourceApis/templates/types';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { HeaderWrapper as Header } from '../../../../design_system/Triage/headers/Header';
import Loader from '../../../../design_system/Triage/Loader';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import PageContent from '../../../shared/page_content';
import TemplateOutlineRow from '../TemplateOutlineRow/TemplateOutlineRow';
import { TemplateOutline } from '../TemplateOutlineRow/types';
import TemplatesOutlineSearch from '../TemplatesOutlineSearch/TemplatesOutlineSearch';
import TemplatesOutlineSlideout from '../TemplatesOutlineSlideout/TemplatesOutlineSlideout';
import TemplatesOutlineSortDropdown from '../TemplatesOutlineSortDropdown/TemplatesOutlineSortDropdown';
import TemplatesOutlineViewByDropdown from '../TemplatesOutlineViewByDropdown/TemplatesOutlineViewByDropdown';
import { searchReducer } from './reducer';
import {
  ActionsRow,
  PaginationWrapper,
  TemplatesOutlineDropdownsWrapper,
  TemplatesOutlineLoaderWrapper,
  TemplatesSearchAndFilterWrapper,
} from './styles';
import { SearchState } from './types';

const t = initTranslations('templates_outline');

export const TEMPLATES_OUTLINE_SLIDEOUT_TYPE = 'templates-outline-filter-slideout';

const TemplatesOutlineIndexPage = () => {
  const route = useRoute();
  const viewBy = useGetDefaultViewByFilter();
  const {
    params: { author, category_ids, page, search, sort, sectors, suggested_groups, tags, view_by },
  } = route as Route<typeof routes.templates>;
  const initialSearchState: SearchState = {
    searchTerm: search || '',
    sort: (sort as OutlineTemplatesSort) || 'most_popular',
    viewBy: (view_by as OutlineTemplatesViewByFilter) || viewBy,
    page: page || 1,
    filters: {
      author: author || [],
      suggestedGroups: suggested_groups || [],
      tags: tags || [],
      sectors: sectors || [],
      categoryIds: category_ids || [],
    },
  };

  const applyTemplatesParamsToUrl = useTemplateParamsToUrl();
  const [searchState, dispatchState] = useReducer(searchReducer, initialSearchState);
  const { close, open, isOpen } = useSlideout(TEMPLATES_OUTLINE_SLIDEOUT_TYPE);
  const outlineTemplatesParams = {
    searchTerm: searchState.searchTerm,
    sort: searchState.sort,
    viewBy: searchState.viewBy,
    page: searchState.page,
    ...initialSearchState.filters,
  };
  const { data, isFetching } = useGetOutlineTemplatesQuery({ ...outlineTemplatesParams });
  const appliedFilters = searchState.filters;
  const appliedFiltersCount = getTemplateFiltersCount(appliedFilters);
  const showTemplatesOutline = data && data.templates.length;
  const [searchValue, setSearchValue] = useState(searchState.searchTerm);
  const { productTerm } = useAccountTerminology();

  const clearFilters = () => {
    setSearchValue('');
    dispatchState({ type: 'clearFilters' });
    dispatchState({ type: 'changeSearchTerm', searchTerm: '' });
  };

  useEffect(() => {
    applyTemplatesParamsToUrl({
      ...searchState,
      ...appliedFilters,
    });
  }, [applyTemplatesParamsToUrl, appliedFilters, searchState]);

  usePageTitle({ resourceType: 'templates', productTerm });

  return (
    <PageContent>
      <Header subtitle={t('subtitle', { productTerm })} title={t('title')} />
      <ActionsRow>
        <TemplatesOutlineDropdownsWrapper>
          <TemplatesOutlineViewByDropdown
            dispatchSearchState={dispatchState}
            viewBy={searchState.viewBy}
          />
          <TemplatesOutlineSortDropdown
            dispatchSearchState={dispatchState}
            sort={searchState.sort}
          />
        </TemplatesOutlineDropdownsWrapper>
        <TemplatesSearchAndFilterWrapper>
          <TemplatesOutlineSearch
            dispatchSearchState={dispatchState}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <DefaultButton
            buttonType='secondary'
            iconName='sliders'
            iconWeight='solid'
            id='templates-outline-filter-button'
            onClick={() => (isOpen ? close() : open())}
            size='md'
            text={t('filter_button_text', { count: appliedFiltersCount })}
          />
        </TemplatesSearchAndFilterWrapper>
      </ActionsRow>
      {isFetching ? (
        <TemplatesOutlineLoaderWrapper>
          <Loader />
        </TemplatesOutlineLoaderWrapper>
      ) : (
        <>
          {showTemplatesOutline ? (
            <div className='templates-outline'>
              {data.templates.map((template: TemplateOutline) => (
                <TemplateOutlineRow key={template.id} template={template} />
              ))}
              <PaginationWrapper>
                <Pagination
                  activePage={searchState.page}
                  itemsCountPerPage={data.limitValue}
                  onChange={(page) => {
                    dispatchState({ type: 'changePage', page });
                  }}
                  showPaginationDetails
                  totalItemsCount={data.totalTemplatesCount}
                  totalPages={data.totalPages}
                />
              </PaginationWrapper>
            </div>
          ) : (
            <NoResults
              darkImage={NoResultsGraphic}
              heading={t('no_result.title')}
              iconWidth='70%'
              lightImage={NoResultsGraphic}
              minHeight='20rem'
              showBorder={false}
              subHeaderCta={{
                action: clearFilters,
                text: t('no_result.action_link_text'),
              }}
              subHeaderText={t('no_result.text')}
              width='32rem'
            />
          )}
        </>
      )}
      <TemplatesOutlineSlideout
        appliedFilters={appliedFilters}
        appliedFiltersCount={appliedFiltersCount}
        dispatchSearchState={dispatchState}
      />
    </PageContent>
  );
};

export default TemplatesOutlineIndexPage;
