import React from 'react';

import { BreadcrumbWrapper } from '../../../editor/shared/styles';
import FullScreenOverlay from '../../../shared/FullScreenOverlay/FullScreenOverlay';
import {
  CurriculumElementHeaderWrapper,
  HeaderBody,
  HeaderBodyWrapper,
} from '../../../shared/preview/PreviewCurriculumElementHeader/styles';
import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';
import {
  HeadingWrapper,
  MainContentWrapper,
  MainWrapper,
  OutlineContentWrapper,
  SurveyDataWrapper,
  SurveyEditDataPreviewWrapper,
  Toolbar,
  ToolbarWrapper,
} from '../../shared/styles';
import {
  AddQuestionButtonWrapper,
  ButtonWrapper,
  ContentDropdownWrapper,
} from '../../SurveyEdit/styles';

const ViewModeSurveySkeleton = () => {
  return (
    <FullScreenOverlay id='view-mode-survey-skeleton' withoutCloseIcon>
      <MainWrapper>
        <OutlineContentWrapper>
          <HeadingWrapper>
            <SkeletonLoader width='8.5rem' />
          </HeadingWrapper>
          <BreadcrumbWrapper>
            <SkeletonLoader height='1.25rem' marginTop='3.5rem' width='10.625rem' />
          </BreadcrumbWrapper>
          <ContentDropdownWrapper></ContentDropdownWrapper>
          <AddQuestionButtonWrapper>
            <SkeletonLoader height='1.25rem' marginTop='2rem' width='12.5rem' />
            <SkeletonLoader height='1.25rem' marginTop='2rem' width='12.5rem' />
            <SkeletonLoader height='1.25rem' marginTop='2rem' width='12.5rem' />
          </AddQuestionButtonWrapper>
        </OutlineContentWrapper>
        <MainContentWrapper>
          <ToolbarWrapper>
            <Toolbar></Toolbar>
          </ToolbarWrapper>
          <SurveyDataWrapper>
            <SurveyEditDataPreviewWrapper>
              <CurriculumElementHeaderWrapper>
                <HeaderBodyWrapper>
                  <HeaderBody>
                    <SkeletonLoader height='1.5rem' marginTop='0.5rem' width='15.625rem' />
                    <SkeletonLoader height='1.25rem' marginTop='1rem' width='100%' />
                    <SkeletonLoader height='1.25rem' marginTop='1rem' width='80%' />
                    <SkeletonLoader
                      height='1.25rem'
                      marginBottom='2.5rem'
                      marginTop='1rem'
                      width='100%'
                    />
                  </HeaderBody>
                </HeaderBodyWrapper>
              </CurriculumElementHeaderWrapper>
            </SurveyEditDataPreviewWrapper>
            <ButtonWrapper>
              <SkeletonLoader height='3.5rem' width='4rem' />
            </ButtonWrapper>
          </SurveyDataWrapper>
        </MainContentWrapper>
      </MainWrapper>
    </FullScreenOverlay>
  );
};

export default ViewModeSurveySkeleton;
