import React from 'react';

import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { NavigationButtonProps } from './BaseNavigationButtons';

const t = initTranslations('curriculums_view.flowchart.footer.navigation_buttons');

const PreviousButton = ({ id, elementKind, mode }: NavigationButtonProps) => {
  const { editRoute, showRoute } = useCurriculumElement()({ elementId: id, elementKind });

  const route = mode === 'edit' ? editRoute : showRoute;

  return (
    <DefaultButton
      behavesAs='a'
      buttonType='secondary'
      fullWidth
      iconName='arrow-left'
      id='previous-content-button'
      text={t('previous')}
      {...route.link}
    />
  );
};

export default PreviousButton;
