import styled from 'styled-components';

import IconButton from '../../../../design_system/buttons/IconButton';

export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  z-index: 1000;
`;

export const VideoPlayerContent = styled.div`
  position: absolute;
  width: 80%;
  height: auto;
  max-width: 46rem;
  max-height: 26rem;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -45%);
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopMedium};
  aspect-ratio: 459 / 258;
`;

export const VideoPlayerIframe = styled.iframe`
  display: block;
  width: 100%;
  height: 100%;
  border: none;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: -50px;
  top: -50px;
  z-index: 1;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd2} 0 0`};
`;

export const CloseIconButton = styled(IconButton)`
  width: 2rem;
  height: ${({ theme: { constants } }) => constants.heightSm};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { vars } }) => vars.textDefault};

  &:hover {
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;
