import { CardType } from '../../components/application/searches/SearchResultsPage/search_result_cards/SearchResultsCard/types';
import initTranslations from '../initTranslations';

type getSearchResultCardAdditionalTextArgs = [number?, string?, number?, CardType?];

const t = initTranslations('search_results_page.result_card');

export function getSearchResultCardAdditionalText(...args: getSearchResultCardAdditionalTextArgs) {
  const [minutesToRead, ownerName, countOfElementsInside, typeOfElementsInside] = args;
  const showElementsInsideDetails = Boolean(countOfElementsInside && typeOfElementsInside);
  const elementsInsideTranslationKey =
    showElementsInsideDetails && getElementsInsideTranslationKey();
  const isAvailableMinutesToRead = Boolean(minutesToRead);
  const formattedMinutesToRead =
    isAvailableMinutesToRead && t('read_time', { count: minutesToRead });
  const ownerDetails = ownerName && t('owner', { owner: ownerName });

  function getElementsInsideTranslationKey() {
    switch (typeOfElementsInside) {
      case 'Course':
        return 'contains_element.step';
      case 'Survey::Survey':
        return 'contains_element.question';
    }
  }

  return {
    ownerDetails,
    formattedMinutesToRead,
    elementsInsideDetails:
      elementsInsideTranslationKey &&
      t(elementsInsideTranslationKey, { count: countOfElementsInside }),
  };
}
