import React, { useCallback } from 'react';
import styled from 'styled-components';

import useSearchResultsWithBadge from '../../../../hooks/useSearchResultsWithBadge';
import { ContentSearchResults } from '../../../../redux/services/resourceApis/searches/types';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Badge from '../../../design_system/display/badge';
import { MultilineTruncatedText } from '../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../styled/TypeSystem';
import ContentSearchEmptyState from './ContentSearchEmptyState';

const SearchResultWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { constants } }) => constants.spacerMd1};
  width: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-top: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    cursor: pointer;
  }

  ${({ theme: { vars }, isSelected }) =>
    isSelected && {
      backgroundColor: vars.foundationBase1,
    }};
`;

const StyledRadio = styled.input`
  min-width: ${({ theme: { constants } }) => constants.height2xs};
  min-height: ${({ theme: { constants } }) => constants.height2xs};
  cursor: pointer;
`;

const ResultTitle = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  width: 18.75rem;

  ${MultilineTruncatedText({})};
  ${fontSm5};
`;

const ResultItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type Props = {
  results: ContentSearchResults[] | undefined;
  selectedValue: ContentSearchResults | null;
  onSelect: (value: ContentSearchResults | null) => void;
  hasSearched: boolean;
};

const ContentSearchResultsList = ({ hasSearched, results, onSelect, selectedValue }: Props) => {
  const { curriculum, step, topic, flowchart } = useAccountTerminology();

  const resultsWithBadge = useSearchResultsWithBadge(
    results,
    curriculum.singular,
    topic.singular,
    step.singular,
    flowchart.singular
  );

  const handleRadioChange = useCallback(
    (selectedResult) => {
      onSelect(selectedResult);
    },
    [onSelect]
  );

  // using boolean for readability instead of results?.length ? (...)
  const hasResults = results && results.length > 0;

  return hasResults ? (
    <>
      {resultsWithBadge.map((result) => (
        <SearchResultWrapper
          isSelected={selectedValue?.webLink === result.webLink}
          key={result.id}
          onClick={() => handleRadioChange(result)}
        >
          <ResultItemWrapper>
            <StyledRadio
              checked={selectedValue?.webLink === result.webLink}
              onChange={(e) => {
                e.stopPropagation();

                handleRadioChange(result);
              }}
              type='radio'
              value={result.webLink}
            />
            <ResultTitle>{result.title}</ResultTitle>
          </ResultItemWrapper>
          <Badge text={result.badge.text} type={result.badge.type} />
        </SearchResultWrapper>
      ))}
    </>
  ) : (
    <ContentSearchEmptyState hasSearched={hasSearched} />
  );
};

export default ContentSearchResultsList;
