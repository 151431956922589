import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useGroup } from '../../../../../contexts/GroupContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useEditGroupMutation } from '../../../../../redux/services/resourceApis/groups/groupsApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import InlineTextarea, {
  sharedTextDisplayStyles,
} from '../../../../design_system/ToBeDeprecated/InlineTextarea';
import Surface from '../../../../design_system/Triage/layout/Surface';
import ComposeButton from '../../../ai/ComposeButton/ComposeButton';
import { Title } from '../../../shared/DetailedTitle/Title';
import { DESCRIPTION_MAX_CHARACTERS } from '../../shared/constants/groups';

const DescriptionWrapper = styled(Surface)(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerSm2};
    background-color: ${vars.foundationSurface1};
    border-radius: ${constants.borderRadiusLg};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  `
);

const GroupTitleWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${constants.spacerLg2};
  `
);

const Description = styled.div`
  ${sharedTextDisplayStyles};
`;

const ButtonWrapper = styled.div`
  width: 9.625rem;
`;

const StyledDefaultButton = styled(DefaultButton)`
  width: 100%;
  text-wrap: nowrap;
  margin: 0;
`;

const t = initTranslations('groups.groups_edit.content.description');

const composeButton = (onClick: () => void) => {
  return (
    <StyledDefaultButton
      buttonType='tertiary'
      iconName='wand-magic-sparkles'
      id='compose-group-ai-description'
      onClick={onClick}
      text={t('auto_compose')}
    />
  );
};

type DescriptionDisplayProps = {
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  editDescription(description: string): void;
};

const DescriptionDisplay = ({
  isSuccess,
  isError,
  isLoading,
  editDescription,
}: DescriptionDisplayProps) => {
  const group = useGroup();

  if (group.everyone) {
    return <Description>{group.description}</Description>;
  }

  return (
    <InlineTextarea
      attributeName='description'
      infoText={t('edit')}
      initialValue={group.description || undefined}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      maxCharacters={DESCRIPTION_MAX_CHARACTERS}
      placeholder={t('placeholder')}
      resourceName='group'
      submitChanges={editDescription}
    />
  );
};

const GroupDescription = () => {
  const group = useGroup();
  const [editGroup, result] = useEditGroupMutation();
  const { isSuccess, isError, isLoading, reset } = result;

  const editDescription = useCallback(
    (description: string) => {
      editGroup({ ...group, description });
    },
    [editGroup, group]
  );

  useDisplayFlashOnResponse({
    result,
    successFunction: reset,
    successMessage: t('flash_success'),
  });

  return (
    <DescriptionWrapper id='group-description'>
      <GroupTitleWrapper>
        <Title fontSize='md2' fontWeight='semibold' title={t('title')} />
        {!group.everyone && (
          <ButtonWrapper>
            <ComposeButton
              background='gray'
              composeModalType='groupDescription'
              customButton={composeButton}
              disabled={false}
              disabledTooltipText={t('ai.generate_button_disabled_tooltip')}
              text={t('auto_compose')}
            />
          </ButtonWrapper>
        )}
      </GroupTitleWrapper>

      <DescriptionDisplay
        editDescription={editDescription}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </DescriptionWrapper>
  );
};

export default GroupDescription;
