import styled, { css } from 'styled-components';

import { MultilineTruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { fontLg2, fontMd5 } from '../../../../../styled/TypeSystem';

const sharedEmojisStyles = css`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const sharedTitleStyles = css`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin: 0;
`;

export const CourseEmojiWrapper = styled.div`
  ${sharedEmojisStyles};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthMd} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};

  height: 4rem;
  width: 4rem;
`;

export const StepEmojiWrapper = styled.div`
  ${sharedEmojisStyles};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};

  height: 2rem;
  width: 2rem;
`;

export const CourseTitle = styled.h1`
  ${sharedTitleStyles};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};

  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontLg2};
  /* Designs call for 48px line height, so overriding the defaults from fontLg2 */
  line-height: 3.04rem;
`;

export const StepTitle = styled.h2`
  ${sharedTitleStyles};

  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${MultilineTruncatedText({})};
  ${fontMd5};
`;

export const TitleWrapper = styled.div`
  display: flex;
`;
