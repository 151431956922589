import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { Title } from '../../../DetailedTitle/Title';

const Wrapper = styled.div<{ hasIcon: boolean; fullWidth: boolean }>(
  ({ theme: { constants }, hasIcon, fullWidth }) => css`
    display: flex;
    gap: ${constants.spacerMd1};

    ${!fullWidth &&
    css`
      max-width: 20rem;
    `}

    ${!hasIcon &&
    css`
      margin-left: ${constants.spacerLg1};
    `}
  `
);

const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  min-width: 1.25rem;
  min-height: 1.25rem;
`;

const DescriptiveOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export type DescriptiveDropdownOptionProps = {
  id: string;
  iconName: IconName | null;
  title: string;
  description: string;
  fullWidth?: true;
};

const DescriptiveDropdownOption = ({
  id,
  iconName,
  title,
  description,
  fullWidth,
}: DescriptiveDropdownOptionProps) => {
  return (
    <Wrapper fullWidth={!!fullWidth} hasIcon={!!iconName} id={id}>
      {iconName && <StyledIcon name={iconName} />}
      <DescriptiveOptionWrapper>
        <Title fontWeight='medium' title={title} />
        <Title fontColor='placeholder' fontWeight='regular' title={description} />
      </DescriptiveOptionWrapper>
    </Wrapper>
  );
};

export default DescriptiveDropdownOption;
