import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  background-clip: border-box;
  border: 0.0625rem solid ${({ theme: { vars } }) => vars.borderSurface2};
  border-radius: 0.3125rem;
`;

export interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
}

const Card = ({ children, className }: Props) => {
  return <StyledCard className={className}>{children}</StyledCard>;
};

export default Card;
