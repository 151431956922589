import styled, { css } from 'styled-components';

import Badge from '../../../../../design_system/display/badge';
import { fontSm3 } from '../../../../../styled/TypeSystem';

export const AnnualSavingsWrapper = styled.div`
  display: flex;
  min-height: 2.125rem;
`;

export const AnnualSavingsBaseStyles = css`
  background-color: ${({ theme: { vars } }) => vars.stateBadgeSuccess};
  border-radius: ${({ theme: { constants } }) =>
    `calc(${constants.borderRadiusXl} + ${constants.borderRadiusMd})`};
  height: 1.375rem;
  width: 100%;
`;

export const AnnualSavingsBadge = styled(Badge)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  text-align: center;
  ${AnnualSavingsBaseStyles};
  ${fontSm3};
`;
