import styled from 'styled-components';

export const PageHeaderWrapper = styled.div`
  z-index: 1;
  display: flex;
  max-height: 6rem;
  padding-bottom: ${({ theme: { constants } }) => constants.spacerLg2};

  opacity: 1;
`;
