import styled from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';

export const EmptyStateContainer = styled.div`
  display: flex;
  height: 18.5rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const EmptyStateImage = styled.img`
  max-height: 8.675rem;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const EmptyStateTitle = styled.p`
  max-width: 15rem;
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm5};
`;
