import { useCallback } from 'react';
import { CurrentUser } from 'types/CurrentUser';

export default function useAppcues(user: CurrentUser | undefined) {
  const identify = useCallback(() => {
    if (user && window.Appcues) {
      window.Appcues.identify(user.appcuesId, user.appcuesProperties);
    }
  }, [user]);

  return {
    identify,
  };
}
