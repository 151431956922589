import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useEffect, useMemo, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateStepMutation } from '../../../../../../redux/services/resourceApis/steps/stepsApi';
import { MetaUserAccess } from '../../../../../../types/Curriculum';
import { LimitedStep } from '../../../../../../types/Step';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { RowCardBody, RowContainer } from '../../../../curriculums/shared/CurriculumRowStyles';
import { routes } from '../../../../publicApplication/applicationRouter';
import EditableCurriculumElementTitle from '../../../../shared/CurriculumElements/EditableCurriculumElementTitle';
import { viewableUserAccess } from '../../../../shared/helpers';
import StepThreeDot from '../../../../shared/StepThreeDot/StepThreeDot';
import EmojiSelect from '../../../components/SubjectEmoji/EmojiSelect';

const EmojiWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-inline: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export type Props = {
  curriculumId: number;
  isLocked?: boolean;
  step: LimitedStep;
  canBeModified: boolean;
  provided: DraggableProvided;
  blockEditPrivileges: boolean;
  userAccess?: MetaUserAccess;
  isLastStep: boolean;
};

const t = initTranslations('curriculums_view');

const StepTableRow = ({
  canBeModified,
  curriculumId,
  isLocked,
  step,
  provided,
  blockEditPrivileges,
  userAccess,
  isLastStep,
}: Props) => {
  const { id, title, emoji, courseId } = step;
  const { slug } = useCurrentAccount();
  const [isEditing, setIsEditing] = useState(false);

  const [updateStep, result] = useUpdateStepMutation();
  const { isSuccess, error } = result;
  const { flash } = useFlashNotification();

  useEffect(() => {
    if (error) {
      flash('error', t('update_title_error'));
    }
  }, [error, flash]);

  const stepUrl = useMemo(() => {
    if (!userAccess || userAccess === 'pending') {
      return routes.curriculumRequestAccess({ id: curriculumId, slug }).href;
    } else if (viewableUserAccess(userAccess)) {
      return routes.consume({ slug, id }).href;
    } else {
      return routes.editor({ slug, id }).href;
    }
  }, [curriculumId, id, slug, userAccess]);

  return (
    <RowContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='step-row'
      id={`step-row-${id}`}
      style={{ ...provided.draggableProps.style }}
    >
      <RowCardBody isLocked={isLocked || blockEditPrivileges}>
        <EmojiWrapper id={`step-${id}-row-emoji-picker`}>
          <EmojiSelect
            clearEmoji={() => {
              updateStep({ id, emoji: null, courseId });
            }}
            emoji={emoji}
            noEmojiIconName='list-ol'
            noEmojiIconWeight='light'
            onEmojiSelect={(emoji: BaseEmoji) => {
              updateStep({ emoji: emoji.native, id, courseId });
            }}
          />
        </EmojiWrapper>
        <EditableCurriculumElementTitle
          editable={!blockEditPrivileges}
          id={id}
          isEditing={isEditing}
          isLocked={isLocked}
          isSuccess={isSuccess}
          maxCharacters={250}
          route={stepUrl}
          setIsEditing={setIsEditing}
          title={title}
          updateElement={({ id, title }) => updateStep({ id, title, courseId })}
        />

        {!blockEditPrivileges && (
          <StepThreeDot
            canBeModified={canBeModified}
            isLastStep={isLastStep}
            isLocked={isLocked}
            renameClick={() => setIsEditing(true)}
            step={step}
            viewingFrom='curriculum'
          />
        )}
      </RowCardBody>
    </RowContainer>
  );
};

export default StepTableRow;
