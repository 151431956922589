import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { fontMd1, fontMd2, fontMd5, fontSm5 } from '../../../../styled/TypeSystem';

export const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const ReportingListWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const HeadsUpLabel = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

// DS Override - Each icon needs space on the right side
export const StyledIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

// DS Override - Need colored warning icon
export const WarningIcon = styled(StyledIcon)`
  color: ${({ theme: { vars } }) => vars.stateError};
`;

export const ReportingList = styled.ol`
  padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const ReportingListItem = styled.li`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const HeaderTitle = styled.h2`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd5};
`;

export const HeaderDescription = styled.p`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm5};
  line-height: initial;
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const SavingLabel = styled.div<{ visible: boolean }>`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  ${fontMd1};
`;

export const CreateButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const HighestRankingTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const HighestRankingTitle = styled.h3`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin: ${({ theme: { constants } }) => `${constants.spacerMd2} 0`};
  ${fontMd2};
`;

export const DisabledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  opacity: 0.6;
`;

export const SurfaceWrapper = styled.div`
  position: relative;
`;

export const StyledCreateResourceDropdownButton = styled.div`
  cursor: pointer;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  }
`;

export const StyledCreateResourceLabel = styled.span`
  margin: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} 0 ${constants.spacerSm3} ${constants.spacerSm3}`};
  ${fontSm5};
`;
