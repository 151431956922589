import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useGetAccountSettingsQuery } from '../../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { formatOptions } from '../../../../../design_system/core/CoreSelectField/CoreSelectField';
import Toggle from '../../../../../design_system/input/controls/Toggle';
import MultiSelectField from '../../../../../design_system/Triage/fields/MultiSelectField';
import { Title } from '../../../../shared/DetailedTitle/Title';
import { ToggleContainer } from '../../../AdvancedSettings/styles';
import {
  FormSectionDescription,
  FormSectionTitle,
  MultiSelectInputRow,
  OptionWrapper,
  SubduedDescription,
  ToggleInfoSection,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.people_settings');

const ShareOrgChart = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();
  const { data } = useGetAccountSettingsQuery();

  const groupOptions = useMemo(
    () =>
      data?.account_groups
        ? formatOptions(data?.account_groups).map((option, index) => {
            if (!option.label) {
              return option;
            }

            const kind = data.account_groups[index]?.kind;
            const label = (
              <OptionWrapper>
                <Title fontWeight='regular' title={option.label.toString()} />
                <Title
                  fontColor='placeholder'
                  fontWeight='regular'
                  title={`(${t(`group_kinds.${kind}`)})`}
                />
              </OptionWrapper>
            );

            return { ...option, label };
          })
        : [],
    [data?.account_groups]
  );

  return (
    <ToggleSettingContainer>
      <ToggleContainer>
        <Toggle
          checked={values.enable_org_chart}
          className='org-chart-setting'
          handleToggle={(e) => setFieldValue('enable_org_chart', e.target.checked)}
          id='enable-org-chart'
          name='enable_org_chart'
        />
      </ToggleContainer>
      <ToggleInfoSection>
        <div>
          <FormSectionTitle>{t('org_chart_title')}</FormSectionTitle>
          <FormSectionDescription>{t('org_chart_description')}</FormSectionDescription>
        </div>
        {values.enable_org_chart && (
          <div id='org-chart-groups-form'>
            <FormSectionTitle>{t('groups_restricted_from_org_chart.title')}</FormSectionTitle>
            <MultiSelectInputRow>
              <MultiSelectField
                className='org-chart-groups-select'
                defaultValue={[]}
                name='groups_restricted_from_org_chart'
                onNonDefaultSelected={(value: string[]) =>
                  setFieldValue('groups_restricted_from_org_chart', value)
                }
                options={groupOptions}
                placeholder={t('groups_restricted_from_org_chart.restrict_groups_placeholder')}
                value={values.groups_restricted_from_org_chart}
              />
            </MultiSelectInputRow>
            <SubduedDescription>
              {t('groups_restricted_from_org_chart.description')}
            </SubduedDescription>
          </div>
        )}
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default ShareOrgChart;
