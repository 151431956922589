import { Color } from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import { Link } from '@tiptap/extension-link';
import { Placeholder } from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { Content, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { debounce, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { AccountEditorProvider } from '../../../../../contexts/AccountEditorContext';
import { EditorProvider } from '../../../../../contexts/EditorContext';
import { EditorToolbarProvider } from '../../../../../contexts/EditorToolbarContext';
import useContentStyles from '../../../../../hooks/useContentStyles';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { useSetAutosaveStatus } from '../../../../../redux/domains/autoSave/autosaveSlice';
import { useUpdateSurveyQuestionMutation } from '../../../../../redux/services/resourceApis/surveyQuestions/surveyQuestionsApi';
import EmbedCard from '../../tiptap_extensions/embed_card';
import FileDownload from '../../tiptap_extensions/file_download';
import FontSize from '../../tiptap_extensions/font_size';
import EditorHeading from '../../tiptap_extensions/heading';
import Iframe from '../../tiptap_extensions/iframe';
import Paragraph from '../../tiptap_extensions/paragraph';
import { CustomTextStyle } from '../../tiptap_extensions/paragraph/textStyle';
import ResizableImage from '../../tiptap_extensions/resizable_image';
import { TableCell } from '../../tiptap_extensions/table/cell';
import { TableHeader } from '../../tiptap_extensions/table/header';
import { TableRow } from '../../tiptap_extensions/table/row';
import { EditorTable } from '../../tiptap_extensions/table/table';
import BubbleMenuComponent from '../BubbleMenu/BubbleMenu';
import { EditorWrapper, StyledSurveyEditorContent } from './styles';
import Toolbar from './Toolbar/index';
import { Props } from './types';

const t = initTranslations('rich_textarea_with_insert');

const RichTextareaWithInsert = ({
  content,
  editable = false,
  questionId,
  surveyId,
  placeholderText = t('placeholder'),
  bubbleToolbarButtons = [],
}: Props) => {
  const account = useCurrentAccount();
  const contentStyles = useContentStyles();
  const { palettes } = useTheme();
  const [initialContent, setInitialContent] = useState<
    { content: Content; id: number } | undefined
  >({ content, id: questionId });
  const editorContentRef = useRef(content);
  const setAutosaveStatus = useSetAutosaveStatus();
  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          translate: editable ? 'no' : 'yes',
        },
      },
      extensions: [
        StarterKit.configure({ heading: false, paragraph: false }),
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        EditorHeading,
        Paragraph,
        ResizableImage,
        Iframe,
        EmbedCard,
        FileDownload,
        EditorTable,
        TableCell.configure({
          palettes,
        }),
        TableHeader,
        TableRow,
        FontSize,
        CustomTextStyle.configure({
          palettes,
        }),
        FontFamily,
        Color,
        Link.configure({
          openOnClick: false,
        }),
        Placeholder.configure({
          placeholder: placeholderText,
        }),
      ],
      onUpdate() {
        debouncedUpdate();
      },
      content: initialContent?.content,
      editable,
    },
    [initialContent]
  );

  const [updateQuestion, { isLoading, isSuccess }] = useUpdateSurveyQuestionMutation();

  const debouncedUpdate = useMemo(
    () =>
      debounce(() => {
        if (editor && editable) {
          const textJson = editor.isEmpty ? null : editor.getJSON();

          editorContentRef.current = textJson;
          updateQuestion({
            id: questionId,
            surveyId,
            textJson,
          });
        }
      }, 2000),
    [editable, editor, questionId, surveyId, updateQuestion]
  );

  useEffect(() => {
    if (isLoading) {
      setAutosaveStatus('saving');
    } else if (isSuccess) {
      setAutosaveStatus('saved');
    }
  }, [setAutosaveStatus, questionId, isLoading, isSuccess]);

  const immediateUpdate = useCallback(() => {
    if (!editable) return;
    if (!editor || (editor.isEmpty && !editorContentRef.current)) return;

    const textJson = editor.getJSON();

    if (!isEqual(textJson, editorContentRef.current)) {
      updateQuestion({
        id: questionId,
        surveyId,
        textJson: editor.isEmpty ? null : textJson,
      });
    }
  }, [editable, editor, questionId, surveyId, updateQuestion]);

  useEffect(() => {
    if (editor) editorContentRef.current = editor.getJSON();

    return () => {
      debouncedUpdate.cancel();
      immediateUpdate();
    };
    // We need to re-render only with editor change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  useEffect(() => {
    setInitialContent({ content, id: questionId });
    // We need to re-render only with questionId change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  if (!editor) return <></>;

  return (
    <EditorWrapper>
      <EditorProvider editor={editor}>
        <AccountEditorProvider account={account}>
          <EditorToolbarProvider buttons={bubbleToolbarButtons} context='bubble'>
            <BubbleMenuComponent />
          </EditorToolbarProvider>
          <EditorToolbarProvider buttons={[]} context='docked'>
            {editor.isEditable && <Toolbar />}
          </EditorToolbarProvider>
          <StyledSurveyEditorContent $contentStyles={contentStyles} editor={editor} />
        </AccountEditorProvider>
      </EditorProvider>
    </EditorWrapper>
  );
};

export default RichTextareaWithInsert;
