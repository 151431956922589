import styled, { css } from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import P from '../../../../../design_system/text/P';
import { TruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import RouterLink from '../../../../publicApplication/RouterLink';
import { searchResultsCardLink } from '../shared/styles';

const inheritTextStyles = css`
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
`;

export const SearchResultsCardLink = styled(RouterLink)`
  ${searchResultsCardLink};
`;

export const SearchResultsCardAdditionalInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  &:before {
    content: '(';
  }

  &:after {
    content: ')';
  }
`;

export const SearchResultsCardAdditionalInfo = styled(P)`
  ${inheritTextStyles};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};

  &:after {
    content: ',';
  }

  &:last-child {
    margin-right: 0;

    &:after {
      content: '';
    }
  }

  ${TruncatedText({})};
`;

export const SearchResultsCardBreadcrumbWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
`;

export const SearchResultsCardBreadcrumb = styled.p`
  margin: 0;
  ${inheritTextStyles};
  ${TruncatedText({})};
`;

export const SearchResultsCardCurriculumTitle = styled.p`
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};
  ${TruncatedText({})};
`;

export const SearchResultsCardAdditionalRow = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  display: flex;
`;

export const SearchResultsCardBreadcrumbIcon = styled(Icon)`
  color: inherit;
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerSm2}`};
`;
