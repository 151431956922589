import styled, { keyframes } from 'styled-components';

import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { fontSm5 } from '../../../../../styled/TypeSystem';

export const ModalContentWrapper = styled.div`
  padding: 7rem ${({ theme: { constants } }) => constants.spacerLg1};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledLoadingImage = styled.img`
  width: 7rem;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const LoadingTextWrapper = styled.div`
  min-width: 15rem;
  max-width: 20rem;
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

export const LoadingTitle = styled.p`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
`;

export const ControlButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const fillAnimation = keyframes`
  0% { background-size: 0 100%; }
  100% { background-size: 100% 100%; }
`;

export const StyledProgressButton = styled(DefaultButton)<{ animationTime: number }>`
  background-image: ${({
    theme: {
      vars: { accentPrimaryDefault },
    },
  }) => `linear-gradient(to right, ${accentPrimaryDefault}, ${accentPrimaryDefault})`};
  background-repeat: no-repeat;
  background-size: 0 100%;

  &:disabled {
    animation: ${fillAnimation} ${({ animationTime }) => animationTime}ms linear forwards;
  }
`;
