import { useCookies } from 'react-cookie';

import { routes } from '../components/application/publicApplication/applicationRouter';
import routeTo from '../components/application/publicApplication/routeTo';
import { AddonName } from '../types/Addon';
import { FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME } from './billing/useCloseFullScreenOverlay';
import useCurrentAccount from './useCurrentAccount';

type RedirectToAddonOverlayProps = {
  name: AddonName;
};

export const useRedirectToAddonOverlay = () => {
  const [cookies, setCookie] = useCookies([FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME]);
  const { slug } = useCurrentAccount();

  const getPreviousPathValue = () => {
    const location = window.location;
    const previousPath = cookies[FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME];
    const currentPath = location.pathname + location.search;
    const isArrayOfPreviousPaths = Array.isArray(previousPath);

    if (isArrayOfPreviousPaths) {
      return [...previousPath, currentPath];
    } else if (previousPath && !isArrayOfPreviousPaths) {
      return [previousPath, currentPath];
    } else {
      return currentPath;
    }
  };
  const setPreviousPathCookie = () => {
    setCookie(FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME, getPreviousPathValue(), {
      path: `/${slug}`,
    });
  };

  return ({ name }: RedirectToAddonOverlayProps) => {
    setPreviousPathCookie();

    routeTo(
      routes.addonOverlay({
        slug,
        name: name as AddonName,
      })
    );
  };
};
