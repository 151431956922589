import { FormikErrors, useFormik } from 'formik';
import React, { useEffect, useReducer, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import styled from 'styled-components';

import { useGroup } from '../../../../../contexts/GroupContext';
import useAnalyticsUserEvents from '../../../../../hooks/useAnalyticsUserEvents';
import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import {
  responsibilitiesApi,
  useBatchCreateResponsibilitiesMutation,
} from '../../../../../redux/services/resourceApis/responsibilities/responsibilitiesApi';
import { Suggestion } from '../../../../../types/Suggestion';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import CreatableSelectField from '../../../../design_system/Triage/fields/CreatableSelectField/CreatableSelectField';
import { Form, TaskErrorDiv } from '../../../../styled/Modals';
import { SuggestionsSubheader } from '../../../groups/modals/CreateGroupNameModal/styles';
import { initialState, reducer } from '../../../groups/shared/modals/Suggestions/reducer';
import UnselectedPills from '../../../groups/shared/modals/Suggestions/UnselectedPills';

const t = initTranslations('suggestions_responsibilities.modal');

const Wrapper = styled.div`
  width: 100%;
`;

export type Props = {
  closeModal: () => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  suggestedResponsibilities: Suggestion[] | undefined;
};

const mapDispatch = {
  getValidateResponsibility: responsibilitiesApi.endpoints.getValidateResponsibility.initiate,
};
type ConnectedResponsibilityProps = ConnectedProps<typeof connector> & Props;
const connector = connect(undefined, mapDispatch);

const SuggestionsResponsibilitiesModal = (props: ConnectedResponsibilityProps) => {
  const group = useGroup();
  const {
    responsibility: { singular: responsibilitySingular, plural: responsibilityPlural },
  } = useAccountTerminology();
  const { cdpSuggestionsResponsibilitiesModalClicked } = useAnalyticsUserEvents();
  const [{ unselectedPills, selectedPills }, dispatch] = useReducer(reducer, initialState);
  const [batchCreateResponsibilities, { isLoading: isSubmitting, isSuccess: isSubmitted }] =
    useBatchCreateResponsibilitiesMutation();
  const [, setIsValidating] = useState(false);
  const {
    closeModal,
    getValidateResponsibility,
    isError: isFetchDataError,
    isLoading: isFetchingData,
    isSuccess: isFetchingDataSuccess,
    suggestedResponsibilities,
  } = props;

  useEffect(() => {
    if (isFetchingDataSuccess && suggestedResponsibilities) {
      dispatch({ type: 'loaded', data: suggestedResponsibilities });
    }
  }, [isFetchingDataSuccess, suggestedResponsibilities]);

  useEffect(() => {
    if (isSubmitted) {
      closeModal();
    }
  }, [closeModal, isSubmitted]);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: async ({ name }) => {
      setIsValidating(true);

      const trimmedName = name.trim();
      const errors: FormikErrors<{ name: string }> = {};
      const { error } = await getValidateResponsibility(
        {
          id: group.id,
          name: trimmedName,
          type: 'Group',
        },
        { forceRefetch: true }
      );
      const selectedClientResponsibilityExists = selectedPills.find(
        (suggestion) => suggestion.name.toLowerCase() === trimmedName.toLowerCase()
      );

      if (error) {
        errors.name = messageFromError(error)?.join(', ');
      } else if (selectedClientResponsibilityExists) {
        errors.name = t('inputs.custom_name.error.existing', {
          responsibility: responsibilitySingular.toLowerCase(),
          responsibilities: responsibilityPlural.toLowerCase(),
        });
      } else if (!name || trimmedName === '') {
        errors.name = t('inputs.custom_name.error.empty', {
          responsibility: responsibilitySingular,
        });
      }

      setIsValidating(false);
      return errors;
    },
    onSubmit: ({ name }, { resetForm, setSubmitting }) => {
      const trimmedName = name.trim();
      setSubmitting(false);
      resetForm();

      dispatch({ type: 'addCustom', name: trimmedName });
    },
  });

  const { configs } = usePrivateConfigs();
  const responsibilitiesHelpPage = configs['RESPONSIBILITIES_HELP_PAGE'];

  const taskModalArgs: TaskModalProps = {
    title: t('title', { responsibilities: responsibilityPlural.toLowerCase() }),
    subtitleLink: {
      subtitle: t('subtitle', { responsibilities: responsibilityPlural.toLowerCase() }),
      linkText: t('learn_more'),
      linkUrl: responsibilitiesHelpPage || '',
    },
    processing: isFetchingData || isSubmitting,
    isDisabled: selectedPills.length === 0,
    onCloseRequest: closeModal,
    primaryButtonText: t('submit'),
    primaryButtonTask: () => {
      cdpSuggestionsResponsibilitiesModalClicked({
        action: 'responsibilities_saved',
        numberOfCustom: selectedPills.filter(({ kind }) => kind === 'custom').length,
        numberOfSuggested: selectedPills.filter(({ kind }) => kind === 'original').length,
      });
      batchCreateResponsibilities({ group_id: group.id, responsibilities: selectedPills });
    },
    heapModalName: 'suggestions-responsibilities-modal',
    desktopSize: 'xl',
  };

  // ToDo: move all the callback functions to a separate handlers
  // skipped for now to keep all the FF changes with the less code possible
  return (
    <TaskModal {...taskModalArgs}>
      <Form>
        <Wrapper>
          <CreatableSelectField
            className='analytics-custom-responsibility-name-field'
            errorText={formik.touched.name && formik.errors.name}
            id='custom-responsibility-name'
            inputValue={formik.values.name}
            isValid={!Object.keys(formik.errors).length}
            onInputChange={(value) => {
              formik.setFieldValue('name', value);
              formik.setErrors({});
            }}
            onValueChanged={() => {
              formik.handleSubmit();
              cdpSuggestionsResponsibilitiesModalClicked({ action: 'custom_enter' });
            }}
            onValueRemove={(id) => {
              cdpSuggestionsResponsibilitiesModalClicked({ action: 'deselect_pill' });
              dispatch({ type: 'removePill', id });
            }}
            selectedValues={selectedPills.map((pill) => ({ label: pill.name, value: pill.id }))}
          />
        </Wrapper>
      </Form>
      <SuggestionsSubheader>{t('suggestions_subheader')}</SuggestionsSubheader>
      <UnselectedPills
        dataIsLoading={isFetchingData}
        emptyText={t('empty_message')}
        maxDesktopCount={6}
        onModify={(id) => {
          cdpSuggestionsResponsibilitiesModalClicked({ action: 'select_pill' });
          dispatch({ type: 'addPill', id });
        }}
        resource='responsibility'
        suggestions={unselectedPills}
      />
      {isFetchDataError && <TaskErrorDiv>{t('error_message')}</TaskErrorDiv>}
    </TaskModal>
  );
};

export default connector(SuggestionsResponsibilitiesModal);
