import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { TextWrapper } from './styles';

const t = initTranslations('per_user_pricing.manage_plan_overlay_content');

const LosingKeyFeatures = () => {
  return <TextWrapper>{t('losing_key_features')}</TextWrapper>;
};

export default LosingKeyFeatures;
