import styled from 'styled-components';

import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxLg } from '../../../styled/Breakpoint';
import { fontLg1, fontMd1, fontSm4 } from '../../../styled/TypeSystem';

export const VideoEmptyStateContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const VideoEmptyStateTitle = styled.h1`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${TruncatedText({})};
  ${fontLg1};
  line-height: normal;
`;

export const VideoEmptyStateActionSection = styled.div`
  width: 80%;
  max-width: 36rem;
  position: relative;
  top: -60px;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => `${constants.spacerLg1} ${constants.spacerMd3}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  text-align: center;

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: 40%;
  }
`;

export const VideoEmptyStateActionTitle = styled.h1`
  ${fontMd1};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const VideoEmptyStateActionDescription = styled.div`
  ${fontSm4};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
`;
