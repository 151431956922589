import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import OutlineElementItem from '././OutlineElementItem/OutlineElementItem';
import { OutlineElementItemProps } from './OutlineElementItem/types';
import {
  OutlineElementContainer,
  OutlineElementHead,
  OutlineElementHeadInner,
  OutlineElementIcon,
  OutlineElementIconWrapper,
  OutlineElementItemsContainer,
  OutlineElementTitle,
  OutlineElementType,
} from './styles';
import { OutlineElementProps } from './types';

const t = initTranslations('template_preview.outline');

const OutlineElement = ({
  activeIndex,
  emoji,
  elementKind,
  title,
  elements,
  index,
  handleToggle,
}: OutlineElementProps) => {
  const isCourse = elementKind === 'course';
  const isExpanded = activeIndex === index;
  const itemIcon = isExpanded ? 'caret-down' : 'caret-right';
  const showOutlineElementItems = elements && isExpanded;

  return (
    <OutlineElementContainer isExpanded={isExpanded}>
      <OutlineElementHead onClick={handleToggle}>
        <OutlineElementIcon isExpanded={isExpanded} name={itemIcon} weight='solid' />
        <OutlineElementIconWrapper>
          {emoji || <Icon name='file-lines' size='sm' />}
        </OutlineElementIconWrapper>
        <OutlineElementHeadInner>
          <OutlineElementType isExpanded={isExpanded}>
            {isCourse ? t('topic') : t('test')}
          </OutlineElementType>
          <OutlineElementTitle isExpanded={isExpanded}>{title}</OutlineElementTitle>
        </OutlineElementHeadInner>
      </OutlineElementHead>
      {showOutlineElementItems && (
        <OutlineElementItemsContainer>
          {elements.map(({ id, title, elementKind, text, emoji }: OutlineElementItemProps) => (
            <OutlineElementItem
              emoji={emoji}
              id={id}
              key={id}
              {...(isCourse && { title })}
              {...(!isCourse && { text })}
              elementKind={elementKind}
            />
          ))}
        </OutlineElementItemsContainer>
      )}
    </OutlineElementContainer>
  );
};

export default OutlineElement;
