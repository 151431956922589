import { Formik, FormikErrors } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import isValidPhoneNumber from '../../../../lib/isValidPhoneNumber';
import {
  useGetAccountSettingsQuery,
  useToggleAccountOptOutFeatureMutation,
  useUpdateAccountDataMutation,
  useUpdateAccountDefaultSettingsMutation,
  useUpdateCurriculumDefaultSettingsMutation,
} from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { isHexColor } from '../../../design_system/helpers';
import LinkTabs, { LinkTab } from '../../../design_system/navigation/LinkTabs';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import { routes } from '../../publicApplication/applicationRouter';
import { SpecializationFormValue } from '../ResponsibilitySpecializations/types';
import { CUSTOM_TERMINOLOGY_MAX_CHARACTERS } from '../shared/constants/accountSettings';
import AccountSettingsForm from '../Tabs/Forms/AccountSettingsForm';
import { SettingsData } from '../Tabs/types';

const TabsWrapper = styled.nav`
  overflow: auto hidden;
  white-space: nowrap;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

const t = initTranslations('account_settings');

type Prop = {
  tab?: string;
};

const AccountSettingsTabs = ({ tab }: Prop) => {
  const { slug } = useCurrentAccount();
  const { data, isLoading: isLoadingData } = useGetAccountSettingsQuery();

  const [updateAccountData, result] = useUpdateAccountDataMutation();
  const [toggleAccountFeature, toggleResult] = useToggleAccountOptOutFeatureMutation();
  const [updateDefaultSettings, defaultSettingsResult] =
    useUpdateCurriculumDefaultSettingsMutation();
  const [updateAccountDefaultData, AccountDefaultSettingsResult] =
    useUpdateAccountDefaultSettingsMutation();

  const { isLoading: isLoadingAccountData } = result;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  useDisplayFlashOnResponse({
    result: toggleResult,
    errorMessage: t('toggle_error_message'),
  });

  useDisplayFlashOnResponse({
    result: defaultSettingsResult,
    errorMessage: t('default_settings_error_message'),
  });

  useDisplayFlashOnResponse({
    result: AccountDefaultSettingsResult,
    errorMessage: t('default_settings_error_message'),
  });

  const specializations: SpecializationFormValue[] = useMemo(() => {
    if (!data) return [];

    return data.responsibility_specializations.map(({ id, terminology, color }) => ({
      id,
      terminology,
      color,
    }));
  }, [data]);

  if (!data || isLoadingData) return null;

  const {
    employee_size: employeeSize,
    phone,
    name,
    industry,
    accent_color: accentColor,
    accent_palette: accentPalette,
    logo_url: logoUrl,
    logo_background_color: logoBackgroundColor,
    brand_styles,
    default_brand_styles,
    advanced_settings,
  } = data;

  const initialValues = {
    name,
    industry,
    employee_size: employeeSize,
    phone,
    ip_whitelist: advanced_settings.ip_whitelist,
    remote_ip: advanced_settings.remote_ip,
    restrict_by_ip: advanced_settings.restrict_by_ip,
    accent_color: accentColor,
    accent_palette: accentPalette,
    logo_background_color: logoBackgroundColor,
    logoImageUrl: logoUrl,
    beta_features: advanced_settings.beta_features,
    sso_only_account: advanced_settings.sso_only_account,
    showModal: false,
    public_curriculums: advanced_settings.public_curriculums,
    enable_printing: advanced_settings.enable_printing,
    enable_people_directory: advanced_settings.enable_people_directory,
    groups_restricted_from_directory: advanced_settings.groups_restricted_from_directory,
    enable_org_chart: advanced_settings.enable_org_chart,
    groups_restricted_from_org_chart: advanced_settings.groups_restricted_from_org_chart,
    enable_role_chart: advanced_settings.enable_org_chart,
    groups_restricted_from_role_chart: advanced_settings.groups_restricted_from_role_chart,
    enable_profile_phone_number: advanced_settings.enable_profile_phone_number,
    enable_profile_email: advanced_settings.enable_profile_email,
    specializations,
    selectedPermissions: advanced_settings.signature_permissions,
    default_access_control: advanced_settings.default_access_control,
    brand_styles,
    default_brand_styles,
    lock_brand_styles: advanced_settings.lock_brand_styles,
    enable_sector_libraries: advanced_settings.sector_libraries,
    company_sector: advanced_settings.company_sector,
    policy_sector: advanced_settings.policy_sector,
    content_page_default_view_by: advanced_settings.content_page_default_view_by,
    custom_terminology: advanced_settings.custom_terminology,
    company_term: advanced_settings.custom_terminologies.company_term,
    policy_term: advanced_settings.custom_terminologies.policy_term,
    process_term: advanced_settings.custom_terminologies.process_term,
    subject_term: advanced_settings.custom_terminologies.subject_term,
    step_term: advanced_settings.custom_terminologies.step_term,
    topic_term: advanced_settings.custom_terminologies.topic_term,
    responsibility_term: advanced_settings.custom_terminologies.responsibility_term,
    pluralize_company_term: advanced_settings.custom_terminology_settings.pluralize_company_term,
    pluralize_process_term: advanced_settings.custom_terminology_settings.pluralize_process_term,
    pluralize_responsibility_term:
      advanced_settings.custom_terminology_settings.pluralize_responsibility_term,
    pluralize_step_term: advanced_settings.custom_terminology_settings.pluralize_step_term,
    pluralize_policy_term: advanced_settings.custom_terminology_settings.pluralize_policy_term,
    pluralize_subject_term: advanced_settings.custom_terminology_settings.pluralize_subject_term,
    pluralize_topic_term: advanced_settings.custom_terminology_settings.pluralize_topic_term,
  };

  const onSubmit = (value: SettingsData) => {
    const formattedValues = {
      ...value,
      responsibility_specializations_attributes: value.specializations,
      signature_permissions: value.selectedPermissions,
      terminology_on: value.custom_terminology,
    };

    if (advanced_settings.public_curriculums !== value.public_curriculums)
      toggleAccountFeature({ feature: 'public_curriculums' });

    if (advanced_settings.default_access_control !== value.default_access_control)
      updateDefaultSettings({ default_access_control: value.default_access_control });

    if (advanced_settings.content_page_default_view_by !== value.content_page_default_view_by)
      updateAccountDefaultData({
        content_page_default_view_by: value.content_page_default_view_by,
      });

    if (advanced_settings.sector_libraries !== value.enable_sector_libraries)
      toggleAccountFeature({ feature: 'sector_libraries' });

    if (advanced_settings.company_sector !== value.company_sector)
      toggleAccountFeature({ feature: 'company_sector' });

    if (advanced_settings.policy_sector !== value.policy_sector)
      toggleAccountFeature({ feature: 'policy_sector' });

    updateAccountData(formattedValues);
  };

  const validate = ({
    name,
    phone,
    logo_background_color,
    accent_color,
    company_term,
    process_term,
    step_term,
    subject_term,
    topic_term,
    policy_term,
    responsibility_term,
  }: SettingsData) => {
    const errors: FormikErrors<SettingsData> = {};
    if (!name) errors.name = t('errors.required');
    if (phone) {
      if (!isValidPhoneNumber(phone)) {
        errors.phone = t('errors.invalid');
      }
    }
    if (!logo_background_color) {
      errors.logo_background_color = t('errors.required');
    } else if (!isHexColor(logo_background_color)) {
      errors.logo_background_color = t('errors.invalid');
    }
    if (!accent_color) {
      errors.accent_color = t('errors.required');
    } else if (!isHexColor(accent_color)) {
      errors.accent_color = t('errors.invalid');
    }

    const maxCharactersError = t(
      'content_settings.custom_terminology_form.errors.max_characters_exceeded'
    );
    if (company_term && company_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.company_term = maxCharactersError;
    }
    if (process_term && process_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.process_term = maxCharactersError;
    }
    if (step_term && step_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.step_term = maxCharactersError;
    }
    if (subject_term && subject_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.subject_term = maxCharactersError;
    }
    if (topic_term && topic_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.topic_term = maxCharactersError;
    }
    if (policy_term && policy_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.policy_term = maxCharactersError;
    }
    if (responsibility_term && responsibility_term.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.responsibility_term = maxCharactersError;
    }

    return errors;
  };

  const GeneralTab: LinkTab = {
    name: t('tabs.general'),
    tabId: 'general-tab',
    is_beta: false,
    to: routes.accountSettings({ slug }),
  };

  const ContentTab: LinkTab = {
    name: t('tabs.content'),
    tabId: 'content-tab',
    is_beta: false,
    to: routes.accountSettings({ slug, tab: 'content' }),
  };

  const PeopleTab: LinkTab = {
    name: t('tabs.people'),
    tabId: 'people-tab',
    is_beta: false,
    to: routes.accountSettings({ slug, tab: 'people' }),
  };

  const DelegationTab: LinkTab = {
    name: t('tabs.delegation'),
    tabId: 'delegation-tab',
    is_beta: false,
    to: routes.accountSettings({ slug, tab: 'delegation' }),
  };

  const tabs = [GeneralTab, ContentTab, PeopleTab, DelegationTab];

  return (
    <>
      <PageHeader title={t('settings')} />
      <TabsWrapper id='account-settins-tabs-wrapper'>
        <LinkTabs id='account-settins-tabs' isUrlParams tabs={tabs} />
      </TabsWrapper>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate} validateOnBlur>
        <AccountSettingsForm activeTab={tab} isLoading={isLoadingAccountData} />
      </Formik>
    </>
  );
};

export default AccountSettingsTabs;
