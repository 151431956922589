import React from 'react';
import { Route } from 'type-route';

import Loader from '../../../../design_system/Triage/Loader';
import { LoaderWrapper } from '../../../curriculums/shared/styles';
import { routes } from '../../applicationRouter';
import PublicPage from '../../PublicPage';
import useAccountData from '../../utils/useAccountData';
import LoginForm from './LoginForm/LoginForm';
import useNotificationAfterRedirect from './utils/useNotificationAfterRedirect';

export type Props = {
  route: Route<typeof routes.login>;
};

const Login = ({ route }: Props) => {
  const { isLoadingAccountData, isSuccessAccountData, accountData } = useAccountData(
    route.params.slug,
    route.params.account_id
  );

  useNotificationAfterRedirect({ isSuccessAccountData, accountData });

  return (
    <>
      {isLoadingAccountData ? (
        <LoaderWrapper>
          <Loader size='lg' />
        </LoaderWrapper>
      ) : (
        <PublicPage id='login-page'>
          <LoginForm accountData={accountData} route={route} />
        </PublicPage>
      )}
    </>
  );
};

export default Login;
