import styled from 'styled-components';

import DefaultButton from '../../design_system/buttons/DefaultButton';

export const RequestsTableWrapper = styled.div`
  min-height: 22rem;
`;

export const ApproveRequestButton = styled(DefaultButton)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;
