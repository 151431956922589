import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import Badge from '../../../../design_system/display/badge';
import CompletionCertIcon from '../../../curriculums/CompletionCert/CompletionCertIcon';
import { routes } from '../../../publicApplication/applicationRouter';
import {
  AssignedCheckableSubject,
  AssignedCheckableTopic,
  useAssignedSubjects,
} from './AssignedSubjectsContext';
import CompletionDetails from './CompletionDetails';
import TooltipBadge from './TooltipBadge';

const InfoWrapper = styled.div<{ isUnpublishedBadgePresent: boolean; userProfilePage: boolean }>`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  align-items: center;
`;

type IncomingType =
  | {
      topic?: never;
    }
  | {
      topic: AssignedCheckableTopic;
    };

type Props = {
  subject: AssignedCheckableSubject;
} & IncomingType;

const t = initTranslations('profile');

const Info = ({ subject, topic }: Props) => {
  const { slug } = useCurrentAccount();
  const { userProfilePage } = useAssignedSubjects();
  const { isExtraSmWindow } = useWindowResize();
  const subjectUnpublishedTooltipId = `subject-${subject.id}-unpublished-tooltip`;
  const subjectReferenceTooltipId = `subject-${subject.id}-reference-tooltip`;
  const topicTooltipId = `topic-draft-or-pending-tooltip-${
    topic ? topic.curriculum_element_id : 'null-topic'
  }`;
  const subjectUnpublishedTooltipText = t('tooltips.unpublished');
  const subjectReferenceTooltipText = t('tooltips.reference');
  const topicTooltipText = topic
    ? [
        topic.status === 'draft' ? t('tooltips.draft') : t('tooltips.pending'),
        t('tooltips.not_counted_towards_total_completion'),
      ]
    : '';

  return (
    <InfoWrapper
      isUnpublishedBadgePresent={!topic && !subject.published}
      userProfilePage={userProfilePage}
    >
      {!topic &&
        !subject.published &&
        (isExtraSmWindow ? (
          <TooltipBadge
            badgeSize='circle'
            badgeType='caution'
            tooltipId={subjectUnpublishedTooltipId}
            tooltipText={subjectUnpublishedTooltipText}
          />
        ) : (
          <Badge size='md' text={t('badges.unpublished')} type='caution' />
        ))}
      {topic && topic.status !== 'finished' && (
        <TooltipBadge
          badgeSize='circle'
          badgeType={topic.status === 'draft' ? 'general' : 'caution'}
          tooltipId={topicTooltipId}
          tooltipText={topicTooltipText}
        />
      )}
      {subject.reference && !topic ? (
        isExtraSmWindow ? (
          <TooltipBadge
            badgeSize='circle'
            badgeType='general'
            tooltipId={subjectReferenceTooltipId}
            tooltipText={subjectReferenceTooltipText}
          />
        ) : (
          <Badge size='md' text={t('badges.reference')} type='general' />
        )
      ) : (
        !subject.reference && (
          <>
            {!topic && subject.completion_certificate_available && subject.completion_id && (
              <CompletionCertIcon completionId={subject.completion_id} subjectId={subject.id} />
            )}
            <CompletionDetails
              className={topic ? 'topic-completion-percentage' : 'subject-completion-percentage'}
              percent={topic ? topic.completion_percentage : subject.completion_percentage}
              to={
                topic
                  ? routes.courseReport({
                      slug,
                      courseId: topic.id,
                      curriculumId: subject.id,
                    })
                  : routes.curriculumByUserReport({ slug, id: subject.id })
              }
            />
          </>
        )
      )}
    </InfoWrapper>
  );
};

export default Info;
