import { CSS, Transform } from '@dnd-kit/utilities';
import styled, { css } from 'styled-components';

export const DragOverlayItem = styled.div<{ height: string }>`
  min-height: ${({ height }) => height};
  width: 100%;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} dashed ${vars.textDefault}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
`;

export const DragStyle = (
  transition: string | undefined,
  transform: Transform | null,
  isDragging: boolean
) => {
  return {
    transition,
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '100' : undefined,
    opacity: isDragging ? 0.3 : undefined,
  };
};

export const PlaceholderBlock = styled.div(
  ({ theme: { constants, vars } }) => css`
    position: absolute;
    border: ${constants.borderWidthSm} dashed ${vars.borderDefault};
    border-radius: ${constants.borderRadiusLg};
  `
);
