import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { routes } from '../../../../publicApplication/applicationRouter';
import { BreadcrumbAction, BreadcrumbLink, BreadcrumbWrapper } from '../styles';

const t = initTranslations('curriculum_edit.manage_completions.breadcrumb');

export interface Props {
  curriculumTitle: string;
  id: number;
}

const Breadcrumb = ({ curriculumTitle, id }: Props) => {
  const { slug } = useCurrentAccount();

  const route = routes.curriculumEdit({ slug, id });

  return (
    <BreadcrumbWrapper>
      <BreadcrumbLink
        href={route.href}
        prefixIconName='arrow-left'
        text={
          <BreadcrumbAction id='manage-completions-breadcrumb'>
            {t('back_to', { curriculumTitle })}
          </BreadcrumbAction>
        }
        underlineBehavior='dynamic'
      />
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
