import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import { useGetCurriculumQuery } from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import Loader from '../../../design_system/Triage/Loader';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import PageContent from '../../shared/page_content';
import CurriculumBreadCrumbs from '../shared/BreadCrumbs';
import { BreadCrumbsToggleWrapper, LoaderWrapper } from '../shared/styles';
import CurriculumElementRow from './CurriculumElementRow';
import TopSurface from './TopSurface';
import UnpublishedContent from './UnpublishedContent/UnpublishedContent';

const ElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export type Props = {
  route: Route<typeof routes.curriculumShow>;
};

const CurriculumShow = ({ route }: Props) => {
  const { id, flashMessage, breadcrumb } = route.params;
  const { flash } = useFlashNotification();
  const { slug } = useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const canViewCurriculum = ability.can('read', `ShowCurriculum-${id}`);
  const { isLoading, data: curriculum } = useGetCurriculumQuery(id, { skip: !canViewCurriculum });

  useEffect(() => {
    flash('warning', flashMessage);
  }, [flash, flashMessage]);

  if (!canViewCurriculum) {
    routeTo(routes.curriculumRequestAccess({ id, slug }).href);
    return <></>;
  }

  if (isLoading)
    return (
      <LoaderWrapper>
        <Loader size='lg' />
      </LoaderWrapper>
    );
  if (!curriculum) return <></>;

  const { emoji, title, curriculum_elements: curriculumElements } = curriculum;

  return (
    <PageContent id='curriculum-show'>
      <BreadCrumbsToggleWrapper>
        <CurriculumBreadCrumbs breadcrumb={breadcrumb} emoji={emoji} title={title} />
      </BreadCrumbsToggleWrapper>
      <TopSurface curriculum={curriculum} />
      <ElementsWrapper id='elements-wrapper'>
        {curriculumElements.length === 0 ? (
          <UnpublishedContent />
        ) : (
          curriculumElements.map((curriculumElement) => (
            <CurriculumElementRow
              curriculumElement={curriculumElement}
              key={curriculumElement.id}
            />
          ))
        )}
      </ElementsWrapper>
    </PageContent>
  );
};

export default CurriculumShow;
