import { useCallback, useEffect, useMemo, useState } from 'react';

import { AutoSaveStatus } from '../components/application/shared/AutoSaveStatusUpdater/types';
import useActionCableChannel, { ChannelProps } from './useActionCableChannel';

type SavingResponse =
  | { status: AutoSaveStatus.NoChanges }
  | { status: AutoSaveStatus.Saving }
  | { status: AutoSaveStatus.Saved }
  | { status: 'error' };

const useAutoSaving = (channelProps: ChannelProps) => {
  const [showSaving, setShowSaving] = useState(false);

  useEffect(() => {
    return () => {
      if (showSaving) {
        setShowSaving(false); // Cleanup state to avoid memory leaks
      }
    };
  }, [showSaving]);

  const handleReceived = useCallback((data: SavingResponse) => {
    if (data.status === AutoSaveStatus.Saving) {
      setShowSaving(true);

      // This should be temporarily until the DeprecatedAutoSaveStatusIndicator is built out more to handle:
      // Saved (with tooltip), and error states
      setTimeout(() => {
        setShowSaving(false);
      }, 2000);
    }
  }, []);

  const memoizedChannelProps = useMemo(() => {
    return channelProps;
  }, [channelProps]);

  useActionCableChannel<SavingResponse>(memoizedChannelProps, handleReceived);

  return { showSaving };
};

export default useAutoSaving;
