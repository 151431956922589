import React from 'react';
import styled, { useTheme } from 'styled-components';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import { SkeletonLoader } from '../../../../../shared/SkeletonLoader/styles';
import { PlanCardFooterWrapper, SkeletonDivider } from '../../PlanCards/styles';
import { StyledPlanCardWrapper } from '../styles';

const FlexWrapper = styled.div`
  display: flex;
`;

const NewPlanCardSkeleton = () => {
  const {
    splitFeatures: { simplePricingEnabled, simplePricingV3Enabled },
  } = useCurrentAccount();
  const {
    constants: { spacerSm3, spacerMd2, spacerLg2 },
  } = useTheme();

  return (
    <StyledPlanCardWrapper>
      <FlexWrapper>
        <SkeletonLoader marginBottom={spacerLg2} marginRight={spacerMd2} width='15%' />
        {simplePricingEnabled && (
          <SkeletonLoader marginBottom={spacerLg2} marginRight={spacerMd2} width='15%' />
        )}
        {simplePricingV3Enabled && (
          <SkeletonLoader marginBottom={spacerLg2} marginRight={spacerMd2} width='15%' />
        )}
        <SkeletonLoader marginBottom={spacerLg2} width='15%' />
      </FlexWrapper>

      <SkeletonLoader marginBottom={spacerSm3} width='35%' />
      <SkeletonLoader marginBottom={spacerMd2} width='15%' />

      <FlexWrapper>
        <SkeletonLoader marginRight={spacerSm3} width='30%' />
        <SkeletonLoader width='20%' />
      </FlexWrapper>

      <PlanCardFooterWrapper>
        <SkeletonDivider />
        <SkeletonLoader width='30%' />
      </PlanCardFooterWrapper>
    </StyledPlanCardWrapper>
  );
};

export default NewPlanCardSkeleton;
