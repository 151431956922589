import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../../../components/AccountTerminologyProvider';
import usePrivateConfigs from '../../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../../lib/initTranslations';
import Link from '../../../../../design_system/Link';
import TaskModal, { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';
import { fontSm5 } from '../../../../../styled/TypeSystem';
import ManageStepExternalLinksForm from '../ManageStepExternalLinksForm/ManageStepExternalLinksForm';
import StepExternalLinksList from '../StepExternalLinksList/StepExternalLinksList';

const StyledLink = styled(Link)`
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};
`;

const HintTextContainer = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const NotifyInfoSection = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  ${fontSm5};
`;

// eslint-disable-next-line import/no-unused-modules
export type Props = {
  stepId: number;
  onClose: () => void;
};

const t = initTranslations('steps.modals.manage_step_external_links');
const ManageStepExternalLinksModal = ({ stepId, onClose }: Props) => {
  const { configs } = usePrivateConfigs();
  const chromeExtensionHelpPage =
    configs['CHROME_EXTENSION_HELP_PAGE'] ||
    'https://help.trainual.com/en/articles/2986175-trainual-chrome-extension';
  const taskModalArgs: TaskModalProps = {
    title: t('title'),
    onCloseRequest: onClose,
    processing: false,
    secondaryButtonText: t('done'),
    heapModalName: 'manage-step-external-service-link-modal',
  };
  const { productTerm } = useAccountTerminology();

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <>
        {t('detailed_subtitle_p1')}
        <StyledLink
          href={chromeExtensionHelpPage}
          target='_blank'
          text={t('learn_more', { productTerm })}
        />
        {t('detailed_subtitle_p2')}
        <HintTextContainer>{t('detailed_subtitle_p3')}</HintTextContainer>
      </>
      <ManageStepExternalLinksForm action='create' elementId={stepId} />
      <StepExternalLinksList stepId={stepId} />
      <NotifyInfoSection>{t('notify_info_text')}</NotifyInfoSection>
    </TaskModal>
  );
};

export default ManageStepExternalLinksModal;
