import { Route } from 'type-route';

import { routes } from '../../components/application/publicApplication/applicationRouter';
import { FilterName } from '../../components/application/searches/SearchResultsPage/SearchResultsFilter/types';
import { SearchKind } from '../../components/application/searches/SearchResultsPage/types';
import { safeDecodeURIComponent } from '../../lib/safeDecodeURIComponent';
import {
  useGetSearchCompanyResultsQuery,
  useGetSearchTemplatesResultsQuery,
} from '../../redux/services/resourceApis/searches/searchesApi';
import { useGetSearchKinds } from './useGetSearchKinds';

export const useGetSearchResultData = (route: Route<typeof routes.search>) => {
  const {
    params: { search, filters, page, kind },
  } = route as Route<typeof routes.search>;

  const searchTerm = safeDecodeURIComponent(search || '');
  const querySearchFilter = (filters || []) as FilterName[];
  const availableKind = useGetSearchKinds();

  const querySearchKind = (
    kind && availableKind.includes(kind as SearchKind) ? kind : 'company'
  ) as SearchKind;
  const currentPage = page || 1;

  const isCompanyKind = querySearchKind === 'company';
  const isTemplatesKind = querySearchKind === 'templates';
  const isHelpCenterKind = querySearchKind === 'help_center';

  const {
    data: companyData,
    isLoading: isLoadingCompanyData,
    isFetching: isFetchingCompanyData,
  } = useGetSearchCompanyResultsQuery(
    {
      page: currentPage,
      search: searchTerm,
      filter: querySearchFilter,
    },
    { skip: !isCompanyKind && !isHelpCenterKind }
  );

  const {
    data: templatesData,
    isFetching: isFetchingTemplatesData,
    isLoading: isLoadingTemplatesData,
  } = useGetSearchTemplatesResultsQuery(
    {
      page: currentPage,
      search: searchTerm,
      filter: querySearchFilter,
    },
    { skip: !isTemplatesKind }
  );

  const isFetching = isFetchingCompanyData || isFetchingTemplatesData;

  const isLoading = isLoadingCompanyData || isLoadingTemplatesData;

  const getCurrentData = (kind: SearchKind) => {
    switch (kind) {
      case 'company':
      case 'help_center':
        return companyData && companyData;
      case 'templates':
        return templatesData && templatesData;
      default:
        return null;
    }
  };

  const data = getCurrentData(querySearchKind);

  return {
    data,
    filters: querySearchFilter,
    isLoading,
    isFetching,
    isCompanyKind,
    isTemplatesKind,
    currentPage,
    querySearchKind,
    searchTerm,
  };
};
