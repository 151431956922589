import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { setQuestionAnswered } from '../../../../../../../redux/domains/surveyConsume/surveyConsumeSlice';
import { useAppDispatch } from '../../../../../../../redux/hooks';
import { ChooseOptionsFormTitle } from '../../../../shared/styles';
import { FormProps, FormValues, MultipleChoiceFormValues } from '../types';
import ElementSelectionFields from './ElementSelectionFields/ElementSelectionFields';

const t = initTranslations('survey_consume.multiple_choice_form');

const MultipleChoiceForm = ({ question }: FormProps) => {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const dispatch = useAppDispatch();
  const currentQuestionFormValueIndex = values.answersAttributes.findIndex(
    (answer) => answer.questionId === question.id
  );
  const currentQuestionFormValue = values.answersAttributes[
    currentQuestionFormValueIndex
  ] as MultipleChoiceFormValues;
  const selectedOptionIds = currentQuestionFormValue.optionIds || [];

  const getFieldOptions = () => {
    return question.options.map((option) => ({
      text: option.text,
      position: option.position,
      value: option.id,
    }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const optionId = parseInt(e.target.value, 10);
    const path = `answersAttributes[${currentQuestionFormValueIndex}].optionIds`;
    const updatedOptionIds = selectedOptionIds.includes(optionId)
      ? selectedOptionIds.filter((item) => item !== optionId)
      : [...selectedOptionIds, optionId];

    dispatch(setQuestionAnswered({ questionId: question.id, isAnswered: true }));
    setFieldValue(path, updatedOptionIds);
  };

  return (
    <>
      <ChooseOptionsFormTitle>{t('title')}</ChooseOptionsFormTitle>
      <ElementSelectionFields
        inputType='checkbox'
        name={`question-${question.id}-checkbox-options`}
        onChange={handleOnChange}
        options={getFieldOptions()}
        selectedValue={selectedOptionIds}
      />
    </>
  );
};

export default MultipleChoiceForm;
