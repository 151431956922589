import React from 'react';
import styled from 'styled-components';

import ProgressTimelineCircle from './ProgressTimelineCircle';

const ProgressWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding-top: ${({ theme: { constants } }) => constants.spacerMd1};
`;

const CircleWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Line = styled.div<{ completed: boolean }>`
  width: 2px;
  height: 100%;
  background-color: ${({ completed, theme: { vars } }) =>
    completed ? vars.stateSuccess : vars.borderDefault};
  position: absolute;
  top: 1.75rem;
`;

export type ProgressTimelineProps = ProgressAvatar & { hasLine: boolean };

export type ProgressAvatar = { avatar?: string; name: string; completionStatus: CompletionStatus };

export type CompletionStatus = 'not started' | 'in progress' | 'completed';

const ProgressTimeline = ({ completionStatus, hasLine, avatar, name }: ProgressTimelineProps) => {
  return (
    <ProgressWrapper>
      <CircleWrapper>
        <ProgressTimelineCircle avatar={avatar} completionStatus={completionStatus} name={name} />
      </CircleWrapper>
      {hasLine && <Line completed={completionStatus === 'completed'} />}
    </ProgressWrapper>
  );
};

export default ProgressTimeline;
