import styled from 'styled-components';

import IconButton from '../../../../../design_system/buttons/IconButton';
import Loader from '../../../../../design_system/Triage/Loader';
import { fontMd1, fontSm5 } from '../../../../../styled/TypeSystem';

export const ElementWrapper = styled.section`
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const ElementHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
`;

export const StyledLoader = styled(Loader)`
  height: 14rem;
  align-items: center;
`;

export const ElementTitle = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontMd1};
`;

export const StyledIconButton = styled(IconButton)`
  padding: ${({ theme: { constants } }) => constants.spacerSm1};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const CollapsibleSection = styled.section`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-left: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const CollapsibleFormElementsContainer = styled.div`
  width: 100%;
  height: 14rem;
  overflow-y: auto;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const SearchContainer = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  ${fontSm5};
`;
