import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { FollowUser } from '../../../../redux/services/resourceApis/followings/types';
import Avatar from '../../../design_system/display/avatar';
import Icon from '../../../design_system/display/icons/Icon';
import ProgressBar from '../../../design_system/Triage/ProgressBar';
import { routes } from '../../publicApplication/applicationRouter';
import { Title } from '../../shared/DetailedTitle/Title';

const UserWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  cursor: pointer;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerMd2}`};
  align-items: center;

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const Details = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ReadableDetails = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

type FollowedUserProps = {
  user: FollowUser;
};

const FollowedUser = ({ user: { id, name, avatar, completionPercentage } }: FollowedUserProps) => {
  const { slug } = useCurrentAccount();

  return (
    <UserWrapper
      id={`followed-user-${id}`}
      onClick={() => {
        routes.userProfile({ id, slug }).push();
      }}
    >
      <Wrapper>
        <Avatar image={avatar} name={name} shape='circle' size='sm' />
        <Details>
          <ReadableDetails>
            <Title fontWeight='bold' title={name} />
            <Title fontColor='placeholder' fontWeight='bold' title={`${completionPercentage}%`} />
          </ReadableDetails>
          <ProgressBar percent={completionPercentage} thickness='lg' />
        </Details>
      </Wrapper>
      <Icon name='chevron-right' />
    </UserWrapper>
  );
};

export default FollowedUser;
