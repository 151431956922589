import { toCamelCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import {
  ConsumptionFlowchartResponse,
  EditorFlowchartResponse,
  UpdateFlowchartParams,
} from './types';

const flowchartsApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getFlowchart: builder.query<ConsumptionFlowchartResponse, number>({
      query: (id: number) => ({ url: `ajax/flowcharts/${id}` }),
      providesTags: (result, _error, arg) =>
        result ? [{ type: 'CurriculumElement', id: `Element-${arg}` }] : [],
      transformResponse: (response: ConsumptionFlowchartResponse) => {
        return toCamelCase(response);
      },
    }),
    getEditFlowchart: builder.query<EditorFlowchartResponse, number>({
      query: (id: number) => ({ url: `ajax/flowcharts/${id}/edit` }),
      providesTags: (result) =>
        result
          ? [
              { type: 'CurriculumElement', id: result.curriculumElement.id },
              { type: 'Curriculum', id: result.curriculum.id },
            ]
          : [],
      transformResponse: (response: EditorFlowchartResponse) => {
        return toCamelCase(response);
      },
    }),
    updateFlowchart: builder.mutation<undefined, UpdateFlowchartParams>({
      query: ({ id, content, emoji, lastEditorId, title }: UpdateFlowchartParams) => ({
        url: `ajax/flowcharts/${id}`,
        method: 'PUT',
        body: { content, emoji, lastEditorId, title },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'CurriculumElement', id: `Element-${arg.id}` },
      ],
    }),
    completeFlowchart: builder.mutation<undefined, number>({
      query: (id: number) => ({ url: `ajax/flowcharts/${id}/complete`, method: 'PUT' }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'CurriculumElement', id: `Element-${arg}` },
        { type: 'Curriculum', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetFlowchartQuery,
  useGetEditFlowchartQuery,
  useUpdateFlowchartMutation,
  useCompleteFlowchartMutation,
} = flowchartsApi;
