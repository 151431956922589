import React from 'react';

import { ColumnComponentProps, UserAssignmentReportColumn } from '../types';
import Completion from './Completion';
import DateCompleted from './DateCompleted';
import DueDate from './DueDate';
import ESignature from './ESignature';
import Name from './Name';

type TableColumnsProps = {
  [key in Exclude<UserAssignmentReportColumn, 'actions'>]: React.FC<ColumnComponentProps>;
};

const COLUMNS_KEYS: TableColumnsProps = {
  name: Name,
  completion: Completion,
  due_date: DueDate,
  date_completed: DateCompleted,
  e_signatures: ESignature,
};

const TableColumn = ({
  columnName,
  record,
  curriculumId,
  curriculumName,
}: ColumnComponentProps) => {
  const Column = COLUMNS_KEYS[columnName as keyof TableColumnsProps];

  return (
    <Column
      columnName={columnName}
      curriculumId={curriculumId}
      curriculumName={curriculumName}
      record={record}
    />
  );
};

export default TableColumn;
