import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';

import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import InputField from '../../../../design_system/Triage/InputField';
import { getChevronIconName } from '../../../shared/helpers';
import {
  PillName,
  PillText,
  PromoCodeFieldChevron,
  PromoCodeFieldLabelWrapper,
  PromoCodeFieldWrapper,
  PromoCodeInputWrapper,
  PromoCodePillButton,
  StyledIcon,
} from './styles';
import { FormValues, PromoCodeFieldProps } from './types';

const t = initTranslations('per_user_pricing.configure_plan_overlay_content.promo_code_field');

const promoCodeFieldValidationSchema = () => {
  return yup.object().shape({
    promoCode: yup.string().trim().required(t('validation.required')),
  });
};

const PromoCodeField = ({
  couponName,
  setAppliedPromoCode,
  appliedPromoCode = '',
}: PromoCodeFieldProps) => {
  const [collapsed, setCollapsed] = useState(!appliedPromoCode);
  const { setValues, errors, values, handleChange, handleSubmit, touched } = useFormik({
    initialValues: {
      promoCode: appliedPromoCode,
    },
    validationSchema: promoCodeFieldValidationSchema(),
    onSubmit: (values) => submitForm(values),
  });
  const submitForm: (values: FormValues) => void = useCallback(
    ({ promoCode }) => setAppliedPromoCode(promoCode),
    [setAppliedPromoCode]
  );
  const handleRemovePromoCode = () => {
    setValues({ promoCode: '' });
    setAppliedPromoCode('');
    setCollapsed(false);
  };
  const handleClickLabel = () => {
    if (couponName) return;

    setCollapsed(!collapsed);
  };
  const hasAppliedPromoCode = appliedPromoCode && couponName;
  const hasPreAppliedPromoCode = !appliedPromoCode && couponName;
  const noPromoCodeApplied = !hasAppliedPromoCode && !hasPreAppliedPromoCode;
  const dynamicChevron = getChevronIconName({ isActive: !collapsed, initialDirection: 'up' });
  const showInput = noPromoCodeApplied && !collapsed;
  const showPromoCodePill = hasAppliedPromoCode || hasPreAppliedPromoCode;
  const promoCodePillName = hasPreAppliedPromoCode ? couponName : values.promoCode;
  const errorText =
    (touched.promoCode && errors.promoCode) ||
    (appliedPromoCode && !couponName && t('validation.invalid'));

  return (
    <PromoCodeFieldWrapper>
      <PromoCodeFieldLabelWrapper
        hasPreAppliedPromoCode={!!hasPreAppliedPromoCode}
        onClick={handleClickLabel}
      >
        {t('label')}
        {noPromoCodeApplied && <PromoCodeFieldChevron name={dynamicChevron} weight='solid' />}
      </PromoCodeFieldLabelWrapper>
      {showInput && (
        <PromoCodeInputWrapper>
          <InputField
            errorText={errorText}
            id='promo-code-field'
            name='promoCode'
            onChange={handleChange}
            placeholder={t('placeholder')}
            type='text'
            value={values.promoCode}
          />
          <DefaultButton
            buttonType='secondary'
            id='apply-promo-code-button'
            onClick={() => handleSubmit()}
            text={t('cta')}
          />
        </PromoCodeInputWrapper>
      )}
      {showPromoCodePill && (
        <PromoCodePillButton
          className='promo-code-pill-button'
          hasPreAppliedPromoCode={!!hasPreAppliedPromoCode}
          onClick={handleRemovePromoCode}
        >
          <PillText>
            <PillName>{promoCodePillName}</PillName>
            {!hasPreAppliedPromoCode && <StyledIcon name='times' weight='regular' />}
          </PillText>
        </PromoCodePillButton>
      )}
    </PromoCodeFieldWrapper>
  );
};

export default PromoCodeField;
