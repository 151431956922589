import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AdjacentCurriculumElementProps } from '../../../../../types/Editor';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../publicApplication/applicationRouter';
import BaseNavigationButtons from '../../shared/BaseNavigationButtons/BaseNavigationButtons';
import NextButton from '../../shared/BaseNavigationButtons/NextButton';
import PreviousButton from '../../shared/BaseNavigationButtons/PreviousButton';

const t = initTranslations('curriculums_view.flowchart.footer.navigation_buttons');

type MarkCompleteButtonProps = {
  isLoading: boolean;
  markComplete: () => void;
};

const MarkCompleteButton = ({ isLoading, markComplete }: MarkCompleteButtonProps) => {
  return (
    <DefaultButton
      fullWidth
      iconName='check'
      id='next-content-button'
      loading={isLoading}
      onClick={markComplete}
      text={t('mark_complete')}
    />
  );
};

const BackToHomeButton = () => {
  const { slug } = useCurrentAccount();
  const route = routes.home({ slug });

  return (
    <DefaultButton
      behavesAs='a'
      fullWidth
      iconName='house-chimney'
      id='next-content-button'
      text={t('back_to_home')}
      {...route.link}
    />
  );
};

export type Props = {
  previous: AdjacentCurriculumElementProps | null;
  next: AdjacentCurriculumElementProps | null;
  completionRequired: boolean;
  isCompleted: boolean;
  curriculumPublished: boolean;
  elementStatusFinished: boolean;
  markComplete: () => void;
  isLoading: boolean;
};

const NavigationButtons = ({
  previous,
  next,
  completionRequired,
  isCompleted,
  curriculumPublished,
  elementStatusFinished,
  markComplete,
  isLoading,
}: Props) => {
  let nextButton = null;

  if (curriculumPublished && elementStatusFinished && completionRequired && !isCompleted) {
    nextButton = <MarkCompleteButton isLoading={isLoading} markComplete={markComplete} />;
  } else {
    if (next === null) {
      nextButton = <BackToHomeButton />;
    } else {
      nextButton = <NextButton mode='consume' {...next} />;
    }
  }

  const previousButton = previous && <PreviousButton mode='consume' {...previous} />;

  return <BaseNavigationButtons nextButton={nextButton} previousButton={previousButton} />;
};

export default NavigationButtons;
