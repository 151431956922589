import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useGroupsIndex } from '../../../../contexts/GroupsIndexContext';
import { DataCell, DataRow } from '../shared/table_styles';

const StyledDataRow = styled(DataRow)`
  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationSurface1};
  }
`;

const StyledDataCell = styled(DataCell)`
  height: 3.0625rem;
`;

const PlaceholderP = styled.p<{ width: string }>`
  background: ${({ theme: { vars } }) => vars.foundationHover};
  filter: blur(3.5px);
  border-radius: 5px;
  width: ${({ width }) => width};
  height: 50%;
`;

const EmptyRow = () => {
  return (
    <StyledDataRow>
      <StyledDataCell>
        <PlaceholderP width='26%' />
      </StyledDataCell>
      <StyledDataCell>
        <PlaceholderP width='55%' />
      </StyledDataCell>
      <StyledDataCell>
        <PlaceholderP width='55%' />
      </StyledDataCell>
      <StyledDataCell>
        <PlaceholderP width='29%' />
      </StyledDataCell>
      <StyledDataCell>
        <PlaceholderP width='6%' />
      </StyledDataCell>
    </StyledDataRow>
  );
};

const LoadingGroups = () => {
  const {
    data: { counts_by_kind },
    queryParams: { group_kind, page },
  } = useGroupsIndex();

  const count = useMemo(() => {
    const groupKindInfo = counts_by_kind[group_kind];
    const { pages, remainder } = groupKindInfo;
    const onLastPage = page > pages && remainder > 0;

    return onLastPage ? remainder : 25;
  }, [group_kind, counts_by_kind, page]);

  return (
    <>
      {[...Array(count)].map((_, index) => (
        <EmptyRow key={`empty_row_${index}`} />
      ))}
    </>
  );
};

export default LoadingGroups;
