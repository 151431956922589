import styled, { css, keyframes } from 'styled-components';

const shimmer = keyframes`
        0% {
          background-position: -1200px 0;
        }
        100% {
          background-position: 1200px 0;
        }
      `;

export const Wrapper = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    align-items: center;
    gap: ${constants.spacerLg1};
    margin-left: ${constants.spacerSm3};
    height: ${constants.spacerLg3};
  `};
`;

export const Circle = styled.div`
  ${({ theme: { constants, vars } }) => css`
    width: ${constants.spacerMd1};
    height: ${constants.spacerMd1};
    border-radius: ${constants.borderRadiusCircle};
    background-color: ${vars.foundationBase3};
  `};
`;

export const TextLine = styled.div<{ lineWidth: number }>`
  ${({ lineWidth, theme: { constants, vars } }) => css`
    width: ${lineWidth}rem;
    height: ${constants.spacerMd1};
    background: ${vars.foundationBase3};
    background: linear-gradient(
      to right,
      ${vars.foundationBase2} 8%,
      ${vars.foundationBase1} 18%,
      ${vars.foundationBase2} 33%
    );
    border-radius: ${constants.borderRadiusLg};
    animation: ${shimmer} 9s infinite forwards;
  `};
`;
