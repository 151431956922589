import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetSectorSettingsQuery } from '../../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Chip from '../../../../design_system/buttons/Chip/Chip';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';

const ChipContainer = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} 0`};
  justify-content: space-between;
`;

type ProcessChipType = 'all' | 'company' | 'policy' | 'process';

const t = initTranslations('curriculums.content');

type Props = {
  sector: ProcessChipType;
};

const SectorFilters = ({ sector }: Props) => {
  const {
    process: { plural: processTermPlural },
    policy: { plural: policyTermPlural },
    company: { singular: companyTermSingular },
  } = useAccountTerminology();
  const { data } = useGetSectorSettingsQuery();
  const { slug } = useCurrentAccount();
  const clickHandler = useCallback(
    (badgeId: ProcessChipType) => {
      if (badgeId === 'all') {
        routeTo(routes.content({ slug }));
      } else {
        routeTo(routes.content({ slug, sector: badgeId }));
      }
    },
    [slug]
  );

  const allowedSectors = useMemo(() => {
    let sectors;
    if (data) {
      sectors = ['all', 'process'];
      const { hidePolicySector, hideCompanySector } = data;

      if (!hidePolicySector) sectors.push('policy');
      if (!hideCompanySector) sectors.push('company');
    }

    return sectors;
  }, [data]);

  useEffect(() => {
    if (allowedSectors && !allowedSectors.includes(sector)) {
      routes.content({ slug }).push();
    }
  }, [allowedSectors, sector, slug]);

  if (!data) return <></>;

  const { hidePolicySector, hideCompanySector } = data;

  return (
    // TODO: update colors once design is finalized
    <ChipContainer id='sector-chips'>
      <Chip
        active={sector === 'all'}
        icon='house-chimney'
        id='all-content-chip'
        onClick={() => clickHandler('all')}
        palette='purple'
        text={t('all_content')}
      />
      {!hideCompanySector && (
        <Chip
          active={sector === 'company'}
          icon='file-alt'
          id='company-chip'
          onClick={() => clickHandler('company')}
          palette='yellow'
          text={companyTermSingular}
        />
      )}
      {!hidePolicySector && (
        <Chip
          active={sector === 'policy'}
          icon='file-alt'
          id='policy-chip'
          onClick={() => clickHandler('policy')}
          palette='magenta'
          text={policyTermPlural}
        />
      )}
      <Chip
        active={sector === 'process'}
        icon='file-alt'
        id='process-chip'
        onClick={() => clickHandler('process')}
        palette='blue'
        text={processTermPlural}
      />
    </ChipContainer>
  );
};

export default SectorFilters;
