import React, { useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import EmptyStateDark from '../../../../../images/reports-empty-state-dark.svg';
import EmptyState from '../../../../../images/reports-empty-state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import { setElementReportPage } from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetElementReportQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import LoadingTable from '../../../people/UsersOutline/Table/LoadingTable';
import { TableStyled, TableWrapper } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { sortIcon } from '../../SortIcon';
import TableActionsHeader from './ActionsHeader/TableActionsHeader';
import ElementReportTableHeader from './ElementReportTableHeader';
import { TableHeaderState } from './ElementReportTableTypes';
import { tableReducer } from './reducer';
import TableRow from './TableRow';

const ElementReportTable = ({ curriculumId }: { curriculumId: number }) => {
  const t = initTranslations('reports');
  const { slug } = useCurrentAccount();
  const cookiePath = `/${slug}/curriculum_report`;
  const [cookies, setCookie] = useCookies(['element_reports_selected_sort']);
  const isCookies = cookies.element_reports_selected_sort;
  const sortColumn = isCookies && cookies.element_reports_selected_sort['column'];
  const sortDirection = isCookies && cookies.element_reports_selected_sort['dir'];

  const { page, filters, searchTerm } = useAppSelector((state) => state.reports.elementReport);
  const debouncedSearchValue = useDebounce<string>(searchTerm.trim(), 500);

  const initialHeaderState: TableHeaderState = {
    sortCol: sortColumn ?? 'position',
    sortColDir: sortDirection ?? 'asc',
    sortIcon: sortIcon(sortDirection),
    page: 1,
  };

  const dispatchSlice = useAppDispatch();
  const [tableHeaderState, dispatch] = useReducer(tableReducer, initialHeaderState);

  useEffect(() => {
    setCookie(
      'element_reports_selected_sort',
      JSON.stringify({ column: tableHeaderState.sortCol, dir: tableHeaderState.sortColDir }),
      { path: cookiePath }
    );
  }, [
    cookiePath,
    setCookie,
    tableHeaderState,
    tableHeaderState.sortColDir,
    tableHeaderState.sortCol,
  ]);

  const { data, isFetching, isError } = useGetElementReportQuery({
    ...filters,
    sortCol: tableHeaderState.sortCol,
    sortColDir: tableHeaderState.sortColDir,
    id: curriculumId,
    searchTerm: debouncedSearchValue,
    page,
  });

  if (isError) return <div>{t('error')}</div>;
  if (!data) return <LoadingTable />;

  const {
    curriculum_elements,
    signature_enabled: signatureEnabled,
    limit_value: limitValue,
    total_pages: totalPages,
    total_curriculum_elements_count: totalElementsCount,
  } = data;

  return (
    <>
      <TableActionsHeader
        curriculumId={curriculumId}
        isFetching={isFetching}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        totalElementsCount={totalElementsCount}
      />
      {isFetching ? (
        <LoadingTable />
      ) : (
        <>
          {curriculum_elements.length ? (
            <>
              <TableWrapper>
                <TableStyled className='elements-report-table'>
                  <ElementReportTableHeader
                    dispatch={dispatch}
                    signatureEnabled={signatureEnabled}
                    tableHeaderState={tableHeaderState}
                  />
                  <tbody>
                    {curriculum_elements.map((element) => {
                      return (
                        <TableRow
                          curriculum_element={element}
                          key={`element-report-tr-${element.id}`}
                          signatureEnabled={signatureEnabled}
                        />
                      );
                    })}
                  </tbody>
                </TableStyled>
              </TableWrapper>
              <Pagination
                activePage={page}
                itemsCountPerPage={limitValue}
                onChange={(page) => {
                  dispatchSlice(setElementReportPage(page));
                }}
                showPaginationDetails
                totalItemsCount={totalElementsCount}
                totalPages={totalPages}
              />
            </>
          ) : (
            <NoResults
              darkImage={EmptyStateDark}
              heading={t('no_results_found')}
              iconWidth='45%'
              lightImage={EmptyState}
            />
          )}
        </>
      )}
    </>
  );
};

export default ElementReportTable;
