import React, { useCallback, useState } from 'react';

import useDisplayFlashOnResponse from '../../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../../redux/errors/helpers';
import { ReduxResult } from '../../../../../../../types/Redux';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import TaskModal, {
  TaskModalProps,
} from '../../../../../../design_system/overlays/modals/TaskModal';
import SectorChipSelect, {
  ProcessChipType,
} from '../../../../../curriculums/modals/CurriculumModal/SectorChipSelect';
import { BulkContentMoveModalProps } from '../../types';

const t = initTranslations('curriculums.content.bulk_actions.move_modal');

type SelectedSector = {
  label: string;
  value: ProcessChipType;
};

const BulkContentMoveModal = ({
  selectedIds,
  type,
  onClose,
  bulkUpdate,
  bulkUpdateResult,
}: BulkContentMoveModalProps) => {
  const {
    policy: { plural: policyTermPlural },
    company: { singular: companyTermSingular },
    process: { plural: processTermPlural },
    curriculum: { singular: subjectTermSingular, plural: subjectTermPlural },
  } = useAccountTerminology();
  const [selectedChip, setSelectedChip] = useState<SelectedSector>();

  const handleChipSelect = useCallback(
    (selectedChip: ProcessChipType) => {
      switch (selectedChip) {
        case 'company':
          setSelectedChip({ label: companyTermSingular, value: 'company' });
          break;
        case 'policy':
          setSelectedChip({ label: policyTermPlural, value: 'policy' });
          break;
        case 'process':
          setSelectedChip({ label: processTermPlural, value: 'process' });
          break;
      }
    },
    [companyTermSingular, policyTermPlural, processTermPlural]
  );

  const count = selectedIds.length;

  const moveToButtonText = selectedChip?.value ? selectedChip.label : undefined;

  useDisplayFlashOnResponse({
    result: bulkUpdateResult || ({} as ReduxResult),
    successMessage: t('success_flash', { subjects: subjectTermPlural.toLowerCase() }),
    successFunction: onClose,
    errorMessage: messageFromError(bulkUpdateResult?.error)?.join(', '),
  });

  const taskModalArgs: TaskModalProps = {
    title: t('title', {
      count,
      subject: subjectTermSingular.toLowerCase(),
      subjects: subjectTermPlural.toLowerCase(),
    }),
    onCloseRequest: onClose,
    primaryButtonText:
      selectedChip === undefined
        ? t('button_text_no_sector')
        : t('button_text_sector', { sector: moveToButtonText }),
    primaryButtonTask:
      bulkUpdate && selectedChip
        ? () =>
            bulkUpdate({
              curriculumIds: selectedIds,
              sector: selectedChip.value,
            })
        : () => ({}),
    isDisabled: !selectedChip,
    secondaryButtonText: t('cancel'),
    subtitle: t('subtitle', {
      count,
      subject: subjectTermSingular.toLowerCase(),
      subjects: subjectTermPlural.toLowerCase(),
    }),
    heapModalName: `bulk-content-${type}-modal`,
    processing: false,
    scrollInsideBody: true,
    desktopSize: 'lg',
  };

  return (
    <TaskModal {...taskModalArgs}>
      <SectorChipSelect
        disabled={false}
        onChipSelect={handleChipSelect}
        sector={selectedChip?.value}
      />
    </TaskModal>
  );
};

export default BulkContentMoveModal;
