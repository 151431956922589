import { Formik, FormikProps } from 'formik';
import React from 'react';
import { Route } from 'type-route';

import initTranslations from '../../../../../../lib/initTranslations';
import { sanitizeInput, sanitizeTags } from '../../../../../../lib/sanitize';
import { useUpdateSurveyQuestionOptionMutation } from '../../../../../../redux/services/resourceApis/surveyQuestionOptions/surveyQuestionOptionsApi';
import { SurveyOption } from '../../../../../../types/SurveyOption';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import { ChoiceTextEditorInput } from './styles';
import { FormValues } from './types';

const t = initTranslations('survey_edit.question.choose_options_form.option');

const ChoiceTextEditor = ({ option }: { option: SurveyOption }) => {
  const route = useRoute();
  const {
    params: { id: questionId, surveyId },
  } = route as Route<typeof routes.surveyQuestionEditor>;
  const { id, text, position } = option;
  const [updateOption] = useUpdateSurveyQuestionOptionMutation();

  const handleEditorKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  const handleFormSubmit = (values: FormValues) => {
    const sanitizedText = sanitizeTags(values.text);

    if (text !== sanitizedText) {
      updateOption({ id, surveyId, questionId, text: sanitizedText });
    }
  };

  return (
    <Formik enableReinitialize initialValues={{ text }} onSubmit={handleFormSubmit}>
      {({ setFieldValue, handleSubmit, values }: FormikProps<FormValues>) => (
        <ChoiceTextEditorInput
          html={values.text}
          id={`option-title-${id}`}
          inputMode='text'
          onBlur={() => handleSubmit()}
          onChange={(e) => {
            setFieldValue('text', sanitizeInput(e.target.value, false));
          }}
          onKeyDown={handleEditorKeydown}
          placeholder={t('input_placeholder', { position })}
        />
      )}
    </Formik>
  );
};

export default ChoiceTextEditor;
