import { useMemo } from 'react';

import { useConsumptionCourseContext } from '../../../../../contexts/ConsumptionCourseContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import { Terminology } from '../../../../../types/AccountTerminology';
import { NavigationResult } from '../../../../../types/Editor';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { routes } from '../../../publicApplication/applicationRouter';
import { useStepRange } from '../../hooks/useStepRange';

const t = initTranslations('editor.navigation_buttons');

type NavigateStepProps = {
  id: number;
  slug: string;
  stepTerm: Terminology;
};

const navigateStep = ({ id, slug, stepTerm }: NavigateStepProps) => {
  return {
    route: routes.consume({ id, slug }).href,
    term: stepTerm.singular,
  };
};

export const usePreviousAndNextResource = (currentStepId: number): NavigationResult => {
  const { slug } = useCurrentAccount();
  const { step: stepTerm } = useAccountTerminology();
  const {
    curriculum: { published, completionRequired },
    course: { adjacentCurriculumElements, steps, status: courseStatus },
    currentStepCompleted,
  } = useConsumptionCourseContext();
  const { previous: previousCurriculumElement, next: nextCurriculumElement } =
    adjacentCurriculumElements;
  const elementAttributes = useCurriculumElement();
  const { isFirstStep, isLastStep, currentStepIndex } = useStepRange(steps, currentStepId);

  const markCompletedTerm = useMemo(() => {
    if (completionRequired) {
      return t('mark_completed');
    } else {
      if (isLastStep) {
        if (nextCurriculumElement) {
          return stepTerm.singular;
        } else {
          return t('back_to');
        }
      } else {
        return stepTerm.singular;
      }
    }
  }, [completionRequired, isLastStep, nextCurriculumElement, stepTerm.singular]);

  const previousResource = useMemo(() => {
    let resource = null;

    if (isFirstStep && previousCurriculumElement) {
      const { id: elementId, elementKind } = previousCurriculumElement;
      const { showRoute, termSingular } = elementAttributes({ elementKind, elementId });

      resource = {
        route: showRoute.href,
        term: termSingular.toLowerCase(),
      };
    } else if (currentStepIndex > 0) {
      const previousStepId = steps[currentStepIndex - 1].id;

      resource = navigateStep({ id: previousStepId, slug, stepTerm });
    }

    return resource;
  }, [
    currentStepIndex,
    isFirstStep,
    previousCurriculumElement,
    elementAttributes,
    slug,
    stepTerm,
    steps,
  ]);

  const nextResource = useMemo(() => {
    let resource = null;

    if (isLastStep && nextCurriculumElement) {
      const { id: elementId, elementKind } = nextCurriculumElement;
      const { showRoute, termSingular } = elementAttributes({ elementKind, elementId });

      resource = {
        route: showRoute.href,
        term: termSingular.toLowerCase(),
      };
    } else if (currentStepIndex < steps.length - 1) {
      const nextStepId = steps[currentStepIndex + 1].id;
      resource = navigateStep({ id: nextStepId, slug, stepTerm });
    }

    if (published && courseStatus === 'finished') {
      if (currentStepCompleted) {
        if (!resource) {
          resource = {
            route: routes.home({ slug }).href,
            term: t('back_to'),
          };
        }
      } else {
        resource = {
          route: '#',
          term: markCompletedTerm,
        };
      }
    }

    return resource;
  }, [
    isLastStep,
    nextCurriculumElement,
    elementAttributes,
    currentStepIndex,
    steps,
    published,
    courseStatus,
    slug,
    stepTerm,
    currentStepCompleted,
    markCompletedTerm,
  ]);

  return { previous: previousResource, next: nextResource };
};
