import { useState } from 'react';

type TabState = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
};

export default function useTabState(initialTab: string): TabState {
  const [activeTab, setActiveTab] = useState<string>(initialTab);

  return {
    activeTab,
    setActiveTab,
  };
}
