import { useCallback } from 'react';

import { AccountState } from '../types/AccountState';

export default function useGoogleTagManager(account: AccountState | undefined) {
  const pushAccountStatusToDataLayer = useCallback(() => {
    if (!account?.status) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ accountStatus: `${account.status}` });
  }, [account?.status]);

  return {
    pushAccountStatusToDataLayer,
  };
}
