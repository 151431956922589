import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { LoadingIcon, UpdatedAtText } from './styles';
import { AutoSaveStatus, AutoSaveStatusIndicatorProps } from './types';

const t = initTranslations('autosave_status_indicator');

const DeprecatedAutoSaveStatusIndicator = ({
  updatedAtInWords,
  status,
}: AutoSaveStatusIndicatorProps) => {
  return (
    <>
      {status === AutoSaveStatus.Saving && (
        <UpdatedAtText>
          <>
            <LoadingIcon
              className='chromatic-ignore'
              fixedWidth
              name='rotate'
              spinSpeed='fast'
              weight='solid'
            />
            {t('saving')}
          </>
        </UpdatedAtText>
      )}
      {(status === AutoSaveStatus.Saved || status === AutoSaveStatus.NoChanges) && (
        <UpdatedAtText>{t('updated_on', { date: updatedAtInWords })}</UpdatedAtText>
      )}
    </>
  );
};

export default DeprecatedAutoSaveStatusIndicator;
