import { Form, Formik, FormikState } from 'formik';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import { ContentSearchContext } from '../../../../../contexts/ContentSearchContext';
import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useEditorToolbarContext } from '../../../../../contexts/EditorToolbarContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { ReactComponent as TrainualLogo } from '../../../../../images/trainual_logo_avatar.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { ContentType } from '../../../../../redux/services/resourceApis/searches/types';
import Flyout from '../../../../design_system/overlays/flyout';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import ContentSearch from '../../../shared/ContentSearch/ContentSearch';
import { insertEditorContent } from '../../lib/insertEditorContent';
import { EditorInputContainer } from '../../shared/styles';
import { EmbedExtensionName } from '../../tiptap_extensions/types';
import LabelToolbarButton from '../../toolbar/buttons/components/LabelToolbarButton';
import { EmbedlyFlyoutId } from '../Embedly/EmbedlyFlyout/EmbedlyFlyout';

// DS Override: Expanding the flyout to match the designs in Figma
const StyledFlyout = styled(Flyout)`
  width: 32rem;
  height: 27.5rem;
`;

const t = initTranslations('editor.embed_trainual');

export type Values = {
  description: string | undefined;
  title: string;
  url: string;
  type: ContentType | null;
};

const initialValues: Values = {
  description: '',
  title: '',
  url: '',
  type: null,
};

const embedTrainualValidationSchema = () => {
  return yup.object().shape({
    title: yup.string().required(),
    url: yup.string().required(),
    type: yup.mixed().oneOf(Object.values(ContentType)).required(),
  });
};

const EmbedTrainualFlyout = () => {
  const { logoUrl } = useCurrentAccount();
  const { editor } = useEditorContext();
  const { selectedEmbedMenuItem, setSelectedEmbedMenuItem } = useEditorToolbarContext();
  const menuId = 'trainual-flyout';
  const { isMenuOpen, setActiveMenuId, closeMenu } = useActiveMenuHandler({ menuId });
  const { updateContentSelectedValue, updateContentSearchValue } = useContext(ContentSearchContext);
  const { productTerm } = useAccountTerminology();

  const handleSubmit = useCallback(
    (values: Values) => {
      const attrs = {
        contentType: 'media',
        contentUrl: values.url,
        description: values.description,
        imgUrl: logoUrl,
        title: values.title,
        size: 'medium',
      };

      insertEditorContent({ editor, content: { type: EmbedExtensionName.EMBED_CARD, attrs } });
    },
    [editor, logoUrl]
  );

  const onClose = useCallback(
    (resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void) => {
      setSelectedEmbedMenuItem(undefined);
      updateContentSelectedValue(null);
      updateContentSearchValue('');
      resetForm();
      closeMenu();
    },
    [closeMenu, setSelectedEmbedMenuItem, updateContentSearchValue, updateContentSelectedValue]
  );

  const navigateBackToEmbedlyMenu = useCallback(() => {
    setActiveMenuId(selectedEmbedMenuItem ? EmbedlyFlyoutId : null);
  }, [selectedEmbedMenuItem, setActiveMenuId]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        onClose(actions.resetForm);
      }}
      validationSchema={embedTrainualValidationSchema}
    >
      {({ dirty, isValid, setValues, handleSubmit, resetForm }) => {
        return (
          <StyledFlyout
            className='trainual-flyout'
            id={menuId}
            onClose={() => onClose(resetForm)}
            placement='bottom-start'
            primaryButtonDisabled={!(isValid && dirty)}
            primaryButtonTask={handleSubmit}
            primaryButtonText={t('button_text')}
            secondaryButtonTask={() => {
              onClose(resetForm);
              navigateBackToEmbedlyMenu();
            }}
            secondaryButtonText={selectedEmbedMenuItem ? t('back') : t('cancel')}
            title={t('title', { productTerm })}
            triggerButton={
              <LabelToolbarButton
                Svg={TrainualLogo}
                active={isMenuOpen}
                ariaLabel={t('aria_label_embed_button')}
                className='flyout-trigger'
                iconType='svg'
                id='trainual-embed-button'
                label={t('trainual')}
                onClick={() => {
                  resetForm();
                }}
              />
            }
          >
            <EditorInputContainer>
              <Form>
                <ContentSearch resetForm={resetForm} setValues={setValues} />
              </Form>
            </EditorInputContainer>
          </StyledFlyout>
        );
      }}
    </Formik>
  );
};

export default EmbedTrainualFlyout;
