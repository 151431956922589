import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { constants } }) => css`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    width: ${constants.spacerMd3};
    height: ${constants.spacerMd3};

    border-radius: ${constants.borderRadiusLg} ${constants.borderRadiusLg} 0
      ${constants.borderRadiusLg};
    background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};

    color: ${({ theme: { vars } }) => vars.foundationBase1};

    overflow: hidden;

    > * {
      position: relative;
      z-index: 3;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;

      width: ${constants.spacerMd3};
      height: ${constants.spacerMd3};

      border-radius: ${constants.borderRadiusCircle};
    }

    &:before {
      top: ${constants.spacerSm1};
      left: -${constants.spacerSm2};
      z-index: 2;

      background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
    }

    &:after {
      top: ${constants.spacerMd1};
      right: -${constants.spacerSm3};
      z-index: 1;

      background-color: ${({ theme: { vars } }) => vars.trainualBrandMagentaMedium};
    }
  `}
`;
