import React from 'react';

import { checkCreditCardData } from '../../../../../../lib/billing/checkCreditCardData';
import { NullableBillingDetails } from '../../../../../../redux/services/resourceApis/billing/types';
import { BillingCardDetails } from '../../../../../../types/BillingCardDetails';
import { CardDetailsSectionWrapper } from '../../../per_user_pricing/FullScreenOverlayContent/shared/styles';
import CardDetails from '../../CardDetails/CardDetails';

type CardDetailsSectionProps = {
  cardDetails: NullableBillingDetails<BillingCardDetails>;
  isMaxSeatsCount: boolean;
};

const CardDetailsSection = ({ cardDetails, isMaxSeatsCount }: CardDetailsSectionProps) => {
  const hasCardDetails = checkCreditCardData(cardDetails);

  return (
    <>
      {!isMaxSeatsCount && (
        <CardDetailsSectionWrapper hasCardDetails={hasCardDetails}>
          <CardDetails cardDetails={cardDetails} />
        </CardDetailsSectionWrapper>
      )}
    </>
  );
};

export default CardDetailsSection;
