import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';

import { reportsApi } from '../../../../redux/services/resourceApis/reports/reportsApi';
import DownloadCsvReport from '../DownloadCsvReport/DownloadCsvReport';

const CourseDownloadCsvReport = ({ courseId }: { courseId: number }) => {
  const [getCsv] = reportsApi.useLazyGetCourseReportCsvQuery();

  const [cookies] = useCookies(['course_report_selected_sort']);
  const isCookies = cookies.course_report_selected_sort;
  const sortColumn = isCookies && cookies.course_report_selected_sort['column'];
  const sortDirection = isCookies && cookies.course_report_selected_sort['dir'];

  const downloadCsvDocument = useCallback(() => {
    getCsv({ id: courseId, sortCol: sortColumn, sortColDir: sortDirection });
  }, [courseId, getCsv, sortColumn, sortDirection]);

  return (
    <DownloadCsvReport downloadCsvDocument={downloadCsvDocument} id='course-button-download-csv' />
  );
};

export default CourseDownloadCsvReport;
