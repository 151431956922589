import React from 'react';
import styled from 'styled-components';

import { fontSm3, fontSm5 } from '../../../../styled/TypeSystem';

const StyledActionDropdownMenuOption = styled.span`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

const ButtonTextWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const Title = styled.p<{ isSelected: boolean }>`
  margin: 0;
  font-weight: ${({ isSelected, theme: { constants } }) =>
    isSelected ? constants.fontBold : constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const Description = styled.p`
  margin: ${({ theme: { constants } }) => `0 0 0 ${constants.spacerMd3}`};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm3};
`;

export enum OptionType {
  Edit = 'edit',
  View = 'view',
  Finished = 'finished',
  PendingReview = 'pendingReview',
  Draft = 'draft',
}

export interface ActionDropdownMenuOptionData {
  description: string;
  icon: React.ReactNode;
  title: string;
  onClick?: () => void;
  optionType: OptionType;
}

interface ActionDropdownMenuOptionProps extends ActionDropdownMenuOptionData {
  isSelected: boolean;
}

const ActionDropdownMenuOption = ({
  description,
  icon,
  title,
  isSelected,
  onClick,
  optionType,
}: ActionDropdownMenuOptionProps) => {
  return (
    <StyledActionDropdownMenuOption className={`${optionType}-option`} onClick={onClick}>
      <ButtonTextWrapper>
        <IconWrapper>{icon}</IconWrapper>

        <Title isSelected={isSelected}>{title}</Title>
      </ButtonTextWrapper>

      <Description>{description}</Description>
    </StyledActionDropdownMenuOption>
  );
};

export default ActionDropdownMenuOption;
