import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../../../lib/initTranslations';
import { User } from '../../../../../../../../types/User';
import Avatar from '../../../../../../../design_system/display/avatar';
import { fontSm5 } from '../../../../../../../styled/TypeSystem';
import AvatarsGroup from '../../../../../../shared/AvatarsGroup/AvatarsGroup';

const StyledButton = styled.button<{ disabled?: boolean }>`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    gap: ${constants.spacerSm3};
    align-items: center;
    justify-content: center;
    background: ${vars.foundationSurface1};
    cursor: pointer;
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusEndcap};
    padding: 0 ${constants.spacerSm2} 0 ${constants.spacerMd1};
    height: ${constants.heightMd};
    outline: none;
    max-width: 20rem;
    color: ${vars.textDefault};

    &:hover,
    &:active,
    &:focus {
      border-color: ${vars.accentPrimaryDefault};
    }

    ${fontSm5};
  `};
`;

const StyledAvatar = styled(Avatar)`
  &.no-users-empty-avatar {
    margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

const t = initTranslations('curriculums.top_surface');

export type Props = {
  onClick: () => void;
  allUsersAssigned: User[];
};

const ShareButton = ({ onClick, allUsersAssigned }: Props) => {
  return (
    <StyledButton id='curriculum-share-button' onClick={onClick}>
      {t('share_button.title')}
      {allUsersAssigned.length > 0 ? (
        <AvatarsGroup
          avatarSize='xs'
          i18Key='avatars'
          totalUserCount={allUsersAssigned.length}
          users={allUsersAssigned.slice(0, 3)}
        />
      ) : (
        <StyledAvatar icon='user' name='' shape='circle' size='xs' />
      )}
    </StyledButton>
  );
};

export default ShareButton;
