import styled from 'styled-components';

import { fontMd3, fontSm4 } from '../../../styled/TypeSystem';

export const SearchResultsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const SearchResultsTitle = styled.h2`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd3};
`;

export const SearchResultsFoundBox = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};

  ${SearchResultsTitle} {
    color: ${({ theme: { vars } }) => vars.textDefault};
    margin: 0;
  }
`;

export const SearchResultsSubTitle = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm4};
`;

export const SearchResultsLoaderWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg2};
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const SearchResultsPageHeader = styled.div`
  > .search-results-header {
    padding: ${({ theme: { constants } }) => constants.spacerMd3};
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  }
`;
