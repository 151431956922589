import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';

import IconButton from '../../../../../design_system/buttons/IconButton';
import { fontMd1 } from '../../../../../styled/TypeSystem';

export const ChoiceElementWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const ChoiceLetter = styled.div<{ isCorrect: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: ${({ theme: { constants } }) => constants.heightXs};
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerSm3}`};
  background-color: ${({ isCorrect, theme: { vars } }) =>
    isCorrect ? vars.stateHoverSuccess : vars.foundationSurface1};
  color: ${({ isCorrect, theme: { vars } }) => (isCorrect ? vars.textSurface : vars.textDefault)};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ isCorrect, theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${isCorrect ? vars.stateHoverSuccess : vars.borderSurface2}`};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd1};
`;

export const ChoiceInputWrapper = styled.div<{ isCorrect: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${({ theme: { constants } }) => constants.heightLg};
  width: 100%;
  border: ${({ isCorrect, theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${isCorrect ? vars.stateHoverSuccess : vars.borderSurface2}`};
  border-radius: ${(props) => props.theme.constants.borderRadiusMd};
  background-color: ${({ isCorrect, theme: { vars } }) =>
    isCorrect ? vars.stateBackgroundSuccess : vars.foundationBase1};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const ChoiceTextEditorInput = styled(ContentEditable)<{ placeholder: string }>`
  width: 100%;

  margin: ${({ theme: { constants } }) =>
    `${constants.spacerSm2} ${constants.spacerSm2} ${constants.spacerSm2} 0`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};

  :empty:before {
    content: attr(placeholder);
    display: block;
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  &:focus {
    outline: none;
  }

  ${fontMd1};
`;

export const ChoiceRemoveButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: ${({ theme: { constants } }) => constants.heightSm};
  background: none;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.accentPrimaryDefault}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};

  &:active,
  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }

  &:enabled {
    background: none;
  }
`;
