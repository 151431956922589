import React from 'react';

import { useOnboarding } from '../../../../../contexts/OnboardingContext';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import {
  OnboardingStepBullet,
  OnboardingStepBulletSm,
  OnboardingStepBulletWrapper,
  OnboardingStepContainer,
  OnboardingStepHeader,
  OnboardingStepHeaderInner,
  OnboardingStepInner,
  OnboardingStepNumber,
  OnboardingStepTitle,
  OnboardingStepWrapper,
  OnboardingSubmitSection,
} from './styles';
import { Props } from './types';

const t = initTranslations('home.onboarding');

const OnboardingStep = ({ title, children, number }: Props) => {
  const { sliderRef, currentSlide } = useOnboarding();

  const handlePrevSlide = (e: React.MouseEvent<HTMLButtonElement>) => {
    sliderRef?.current?.slickPrev();
    e.currentTarget.blur();
  };

  return (
    <OnboardingStepWrapper visible={currentSlide !== number}>
      <OnboardingStepContainer>
        {title && number && (
          <OnboardingStepHeader className='onboarding-step-header'>
            <OnboardingStepBulletWrapper>
              <OnboardingStepBullet className='onboarding-step-bullet' />
            </OnboardingStepBulletWrapper>
            <OnboardingStepHeaderInner className='onboarding-step-header-inner'>
              <OnboardingStepNumber>{t('step_number', { number })}</OnboardingStepNumber>
              <OnboardingStepTitle className='onboarding-step-title'>{title}</OnboardingStepTitle>
            </OnboardingStepHeaderInner>
          </OnboardingStepHeader>
        )}
        <OnboardingStepInner className='onboarding-step-inner'>{children}</OnboardingStepInner>
        {!title && !number && (
          <OnboardingSubmitSection>
            <OnboardingStepBulletSm />
            <DefaultButton
              buttonType='tertiary'
              id='onboarding-customize-accent-step-back'
              onClick={handlePrevSlide}
              size='md'
              text={t('back')}
            />
            <DefaultButton
              buttonType='primary'
              id='onboarding-finish-setup'
              size='md'
              text={t('finish_setup')}
              type='submit'
            />
          </OnboardingSubmitSection>
        )}
      </OnboardingStepContainer>
    </OnboardingStepWrapper>
  );
};

export default OnboardingStep;
