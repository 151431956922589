import React from 'react';

export type FormattedDateProps = {
  date: string;
  time?: boolean;
};

const FormattedDate = ({ date, time = false }: FormattedDateProps) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  if (time) {
    const formattedTime = new Date(date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return (
      <div>
        {formattedDate} - {formattedTime}
      </div>
    );
  }
  return <div>{formattedDate}</div>;
};

export default FormattedDate;
