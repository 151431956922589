import React, { useCallback, useMemo, useState } from 'react';

import { useOrgChartConfigSlideout } from '../../../../../contexts/OrgChartConfigSlideoutContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { stringToNumber } from '../../../../../lib/convertValues';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useUpdateRootResourceMutation } from '../../../../../redux/services/resourceApis/orgChart/orgChartApi';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import { TaskErrorDiv } from '../../../../styled/Modals';
import {
  HeadsUpLabel,
  ReportingList,
  ReportingListItem,
  ReportingListWrapper,
  WarningIcon,
} from './styles';
import { OrgChartConfigSlideoutReplaceRootGroupModalProps } from './types';

const OrgChartConfigSlideoutReplaceRootResourceModal = ({
  closeModal,
  options,
  defaultValue,
}: OrgChartConfigSlideoutReplaceRootGroupModalProps) => {
  const { orgChartResourceType } = useOrgChartConfigSlideout();
  const t = initTranslations(
    `org_chart.${orgChartResourceType}_config_slideout.replace_root_resource_modal`
  );
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const { resources } = useOrgChartConfigSlideout();
  const currentCeoSelected = defaultValue === selectedValue;
  const selectedResource = useMemo(
    () => resources.find((resource) => resource.id === stringToNumber(selectedValue)),
    [resources, selectedValue]
  );
  const rootReportingResources = useMemo(
    () =>
      resources.filter(
        (resource) =>
          resource.parent?.id === stringToNumber(defaultValue) &&
          resource.id !== stringToNumber(selectedValue)
      ),
    [defaultValue, resources, selectedValue]
  );

  const [updateRootResource, result] = useUpdateRootResourceMutation();
  const { isLoading, error: updateRootResourceError } = result;

  const error = useMemo(() => {
    return messageFromError(updateRootResourceError)?.join(', ');
  }, [updateRootResourceError]);

  const successFunction = useCallback(() => {
    if (selectedResource && selectedValue) closeModal();
  }, [closeModal, selectedResource, selectedValue]);

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success'),
    successFunction,
  });

  const taskModalArgs: TaskModalProps = {
    title: t('title'),
    subtitle: t('description'),
    onCloseRequest: closeModal,
    primaryButtonText: t('confirm'),
    primaryButtonTask: () =>
      selectedValue &&
      updateRootResource({ resourceId: selectedValue, resourceType: orgChartResourceType }),
    processing: isLoading,
    isDisabled: currentCeoSelected || !selectedValue,
    secondaryButtonText: t('cancel'),
    heapModalName: 'replace-root-modal',
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='md'>
      <div id='replace-root-modal-body'>
        <SingleSelectField
          className={`replace-root-${orgChartResourceType}-select`}
          defaultValue={selectedValue}
          disabled={isLoading}
          loading={isLoading}
          onDefaultSelected={() => setSelectedValue(defaultValue)}
          onNonDefaultSelected={(value: string) => setSelectedValue(value)}
          options={options}
          placeholder={t('select_placeholder')}
          value={selectedValue}
        />
        {!currentCeoSelected && selectedResource && rootReportingResources.length > 0 && (
          <ReportingListWrapper>
            <HeadsUpLabel>
              <WarningIcon name='exclamation-circle' weight='solid' />
              {t('heads_up', { selected_resource: selectedResource.name })}
            </HeadsUpLabel>
            <ReportingList id='new-reporting-list'>
              {rootReportingResources.map((resource) => {
                return (
                  <ReportingListItem key={`new-reporting-${resource.id}-${resource.name}`}>
                    {resource.name}
                  </ReportingListItem>
                );
              })}
            </ReportingList>
          </ReportingListWrapper>
        )}
        {error && <TaskErrorDiv>{error}</TaskErrorDiv>}
      </div>
    </TaskModal>
  );
};

export default OrgChartConfigSlideoutReplaceRootResourceModal;
