import styled from 'styled-components';

import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';

export const OnboardingImageWrapper = styled.div<{ currentSlide: number }>`
  display: none;

  @media (min-width: ${mediaBreakpointPxMd}) {
    display: block;
    position: fixed;
    transform-origin: top left;
    transition: 0.3s ease-in-out;
    z-index: 10;

    top: ${({ currentSlide }) => (currentSlide === 3 ? '-24%' : currentSlide === 4 ? '0' : '-52%')};
    left: ${({ currentSlide }) =>
      currentSlide === 3 ? '47%' : currentSlide === 4 ? '52%' : '45%'};
    transform: ${({ currentSlide }) =>
      currentSlide === 3 ? 'scale(1.7)' : currentSlide === 4 ? 'scale(1.2)' : 'scale(3)'};

    ${({ currentSlide }) =>
      [2, 3, 4].includes(currentSlide)
        ? {
            opacity: 1,
            visibility: 'visible',
          }
        : {
            opacity: 0,
            visibility: 'hidden',
          }};
  }
`;

export const AnimatedSvgElement = styled.g<{ currentSlide: number }>`
  opacity: ${({ currentSlide }) => (currentSlide === 4 ? '1' : '0')};
  transform: scale(${({ currentSlide }) => (currentSlide === 4 ? '1' : '0.4')})
    translate(${({ currentSlide }) => (currentSlide === 4 ? '0px, 0px' : '20px, 20px')});
  transition: opacity 1s ease-in-out, transform 1.2s ease-in-out;
  transform-origin: center center;
`;

export const AnimatedRotatingSvgElement = styled.g<{
  currentSlide: number;
  position: 'top' | 'bottom';
}>`
  opacity: ${({ currentSlide }) => (currentSlide === 4 ? '1' : '0')};
  transform: scale(${({ currentSlide }) => (currentSlide === 4 ? '1' : '0.4')})
    rotate(${({ currentSlide }) => (currentSlide === 4 ? '0deg' : '-75deg')});
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  transform-origin: ${({ position }) => (position === 'top' ? '60% 20%' : '8% 85%')};
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 190px;
  left: 39px;
  width: 6rem;
  height: 2rem;
`;

export const Logo = styled.img`
  max-height: calc(100% - 0.5rem);
  max-width: 60%;
  margin: 0 auto;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${({ theme: { vars } }) => vars.borderSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  top: 186px;
  right: 54px;
  width: 1.25rem;
  height: 1.25rem;
`;
