import { Size } from '../../types/design_system/Size';

export const getVideoPreviewButtonSize = (size: Size) => {
  switch (size) {
    case 'xs':
      return {
        width: '2.25rem',
        height: '2.25rem',
      };
    case 'md':
      return {
        width: '4rem',
        height: '4rem',
      };
  }
};
