import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../../../../shared/SkeletonLoader/styles';
import { SpaceBetweenWrapper } from '../../../styles';
import { NextPaymentWrapper } from '../styles';

type NextPaymentSkeletonProps = {
  withDetails?: boolean;
};

const NextPaymentSkeleton = ({ withDetails = true }: NextPaymentSkeletonProps) => {
  const {
    constants: { spacerSm3 },
  } = useTheme();

  return (
    <NextPaymentWrapper>
      <SkeletonLoader marginBottom={spacerSm3} width='50%' />
      {withDetails && (
        <SpaceBetweenWrapper>
          <SkeletonLoader marginBottom={spacerSm3} width='65%' />
          <SkeletonLoader marginBottom={spacerSm3} width='30%' />
        </SpaceBetweenWrapper>
      )}
    </NextPaymentWrapper>
  );
};

export default NextPaymentSkeleton;
