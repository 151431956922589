import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useToggleAccountOptOutFeatureMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';

const t = initTranslations('account_settings.advanced_settings');

interface Props {
  publicCurriculums: boolean;
}

const PublicCurriculums = ({ publicCurriculums }: Props) => {
  const [toggleAccountFeature, result] = useToggleAccountOptOutFeatureMutation();

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id='public-curriculums'>
      <FormSectionContainer>
        <FormSectionTitle>{t('public_curriculums_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={publicCurriculums}
            handleToggle={() => toggleAccountFeature({ feature: 'public_curriculums' })}
            id='public-curriculums-toggle'
            name='public-curriculums-toggle'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('public_curriculums_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default PublicCurriculums;
