import { camelCase, mapKeys } from 'lodash';
import React, { useEffect, useState } from 'react';
import useUndo from 'use-undo';

import initTranslations from '../../../../lib/initTranslations';
import ColorPicker from '../../../design_system/buttons/DeprecatedColorPicker';
import Icon from '../../../design_system/display/icons/Icon';
import { FONT_FAMILIES, FONT_SIZES } from '../../editor/shared/constants/editor';
import DropdownWithPoppableMenu from '../../shared/DropdownWithPoppableMenu';
import OutsideClickHandler from '../../shared/OutsideClickHandler';
import { DEFAULT_ACCENT_COLORS, TEXT_ALIGN } from '../shared/constants/accountSettings';
import TextStyleBarButton from './TextStyleBarButton';
import {
  ColorPickerWrapper,
  DropdownMenuValue,
  DropdownWrapper,
  TextStyleBarContainer,
  TextStyleBarWrapper,
  TriggerWrapper,
} from './TextStyleBarStyles';
import { TextStyleBarProps } from './TextStyleBarTypes';

const t = initTranslations('account_settings.brand_styles.text_style_bar');

const TextStyleBar = ({
  className = 'text-style-bar',
  displayTextStyleBar = false,
  fontData,
  setFontData,
  triggerElement,
}: TextStyleBarProps) => {
  const [showStyleBar, setShowStyleBar] = useState(displayTextStyleBar);

  const [dataState, { set: setData, undo: undoData, redo: redoData, canUndo, canRedo }] =
    useUndo(fontData);
  const { present: presentData } = dataState;

  const isFontWeightBold = presentData['font-weight'] === '600';
  const isFontStyleItalic = presentData['font-style'] === 'italic';
  const isTextDecorationUnderline = presentData['text-decoration'] === 'underline';

  useEffect(() => {
    setFontData(presentData);
  }, [presentData, setFontData]);

  const fontSizeOptions = FONT_SIZES.map((item, index) => (
    <div key={`option-item-${index}`}>{item}</div>
  ));

  const fontSizeIndex = FONT_SIZES.indexOf(fontData['font-size'].slice(0, -2));

  const textAlignOptions = TEXT_ALIGN.map((item, index) => (
    <div key={`option-item-${index}`}>
      <Icon name={item.icon} />
    </div>
  ));

  const textAlignIndex = TEXT_ALIGN.map((e) => e.align).indexOf(presentData['text-align']);

  const fontFamilyOptions = FONT_FAMILIES.map((item, index) => (
    <div key={`option-item-${index}`} style={{ fontFamily: item }}>
      {item}
    </div>
  ));

  const fontFamilyIndex = FONT_FAMILIES.indexOf(presentData['font-family']);

  const camelCaseCssProperty = mapKeys(fontData, function (value, key) {
    return camelCase(key);
  });

  return (
    <TextStyleBarWrapper>
      <TriggerWrapper
        onClick={() => setShowStyleBar(true)}
        style={{ ...camelCaseCssProperty, pointerEvents: !showStyleBar ? 'auto' : 'none' }}
      >
        {triggerElement}
      </TriggerWrapper>
      {showStyleBar && (
        <OutsideClickHandler onOutsideClick={() => setShowStyleBar(false)}>
          <TextStyleBarContainer className={className}>
            <TextStyleBarButton
              active={isFontWeightBold}
              ariaLabel={t('bold')}
              className={isFontWeightBold ? 'is-active' : ''}
              id='bold-button'
              name='bold'
              onClick={() => {
                setData({
                  ...presentData,
                  'font-weight': isFontWeightBold ? 'normal' : '600',
                });
              }}
            />
            <TextStyleBarButton
              active={isFontStyleItalic}
              ariaLabel={t('italic')}
              className={isFontStyleItalic ? 'is-active' : ''}
              id='italic-button'
              name='italic'
              onClick={() => {
                setData({
                  ...presentData,
                  'font-style': isFontStyleItalic ? 'normal' : 'italic',
                });
              }}
            />
            <TextStyleBarButton
              active={isTextDecorationUnderline}
              ariaLabel={t('underline')}
              className={isTextDecorationUnderline ? 'is-active' : ''}
              id='underline-button'
              name='underline'
              onClick={() => {
                setData({
                  ...presentData,
                  'text-decoration': isTextDecorationUnderline ? 'none' : 'underline',
                });
              }}
            />
            <ColorPickerWrapper>
              <ColorPicker
                ariaLabel={t('text_color')}
                buttonId='account-text-color'
                color={presentData.color}
                defaultColors={DEFAULT_ACCENT_COLORS}
                menuId='text-color-picker'
                name='text-style-color-picker'
                setColor={(color) => {
                  setData({
                    ...presentData,
                    color,
                  });
                }}
                type='text-color'
              />
            </ColorPickerWrapper>
            <DropdownWrapper>
              <DropdownWithPoppableMenu
                activeOptionIndex={fontFamilyIndex}
                id='font-family-dropdown'
                menuId='font-family-menu'
                menuPlacement='bottom-end'
                options={fontFamilyOptions}
                selectedOption={
                  <DropdownMenuValue>
                    <Icon name='a' />
                  </DropdownMenuValue>
                }
                setSelectedOption={(index: number) =>
                  setData({
                    ...presentData,
                    'font-family': FONT_FAMILIES[index],
                  })
                }
              />
            </DropdownWrapper>
            <DropdownWrapper>
              <DropdownWithPoppableMenu
                activeOptionIndex={fontSizeIndex}
                id='font-size-dropdown'
                menuId='font-size-menu'
                menuPlacement='bottom-end'
                options={fontSizeOptions}
                selectedOption={<DropdownMenuValue>{presentData['font-size']}</DropdownMenuValue>}
                setSelectedOption={(index: number) =>
                  setData({
                    ...presentData,
                    'font-size': `${FONT_SIZES[index]}px`,
                  })
                }
              />
            </DropdownWrapper>
            <DropdownWrapper>
              <DropdownWithPoppableMenu
                activeOptionIndex={textAlignIndex}
                id='text-align-dropdown'
                menuId='text-align-menu'
                menuPlacement='bottom-end'
                options={textAlignOptions}
                selectedOption={
                  <DropdownMenuValue>
                    <Icon name={TEXT_ALIGN[textAlignIndex].icon} />
                  </DropdownMenuValue>
                }
                setSelectedOption={(index: number) =>
                  setData({
                    ...presentData,
                    'text-align': TEXT_ALIGN[index].align,
                  })
                }
              />
            </DropdownWrapper>
            <TextStyleBarButton
              ariaLabel={t('undo')}
              disabled={!canUndo}
              id='undo-button'
              name='rotate-left'
              onClick={undoData}
            />
            <TextStyleBarButton
              ariaLabel={t('redo')}
              disabled={!canRedo}
              id='redo-button'
              name='rotate-right'
              onClick={redoData}
            />
          </TextStyleBarContainer>
        </OutsideClickHandler>
      )}
    </TextStyleBarWrapper>
  );
};

export default TextStyleBar;
