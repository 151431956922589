import styled, { css } from 'styled-components';

import IconButton from '../../../design_system/buttons/IconButton';
import Icon from '../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../styled/TypeSystem';

export const PickerContainer = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    max-width: 12rem;
    margin-top: ${constants.spacerSm3};
    padding: ${constants.spacerSm3};
    box-shadow: ${vars.shadowTopSmall};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface1};
    border-radius: ${constants.borderRadiusMd};
    background-color: ${vars.foundationSurface1};
  `};
`;

export const ColorCirclesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ColorCircle = styled.div<{ color: string }>`
  width: 1.625rem;
  height: 1.625rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  margin: ${({ theme: { constants } }) => constants.spacerSm2};
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const ColorPickerTriggerButton = styled(IconButton)<{ color: string }>`
  ${({ color, theme: { constants } }) => css`
    padding: ${constants.spacerSm1};
    border-radius: ${constants.borderRadiusCircle};
    background-color: ${color};

    & > svg {
      ${fontSm5};
    }
  `};
`;
