import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import titleize from '../../../../../../../lib/titleize';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import CoreModal from '../../../../../../design_system/core/CoreModal';
import ModalFooterContainer from '../../../../../../design_system/core/Layout/ModalContainers/ModalFooterContainer';
import ModalHeaderContainer from '../../../../../../design_system/core/Layout/ModalContainers/ModalHeaderContainer';
import { mediaBreakpointPxSm } from '../../../../../../styled/Breakpoint';
import { fontMd3, fontSm5 } from '../../../../../../styled/TypeSystem';

const Title = styled.h1`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd3};
`;

const MainButtonWrapper = styled.span`
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-top: 0;
    margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

const ModalBodyContainer = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerSm3} 0;
  display: flex;
  justify-content: start;
  flex-direction: column;
`;

const ModalMessage = styled.span`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm5};
`;

const t = initTranslations('curriculums.content.bulk_actions.move_modal.e_sig');

type Props = {
  onClose: () => void;
};

const ESigErrorModal = ({ onClose }: Props) => {
  const {
    policy: { plural: policyTermPlural },
    curriculum: { plural: subjectTermPlural },
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();

  return (
    <CoreModal
      closeIconAriaLabel={t('aria_label_close')}
      desktopSize='md'
      heapModalName='bulk-content-move-esig-modal'
      onCloseRequest={onClose}
    >
      <ModalHeaderContainer>
        <Title>{t('title')}</Title>
      </ModalHeaderContainer>
      <ModalBodyContainer>
        <ModalMessage
          dangerouslySetInnerHTML={{
            __html: t('message_one', {
              subjects: subjectTermPlural.toLowerCase(),
              topic: topicTermSingular.toLowerCase(),
            }),
          }}
        />
        <ModalMessage
          dangerouslySetInnerHTML={{
            __html: t('message_two', {
              subjects: titleize(subjectTermPlural),
              policies: policyTermPlural,
              topic: topicTermSingular.toLowerCase(),
            }),
          }}
        />
        <ModalMessage
          dangerouslySetInnerHTML={{
            __html: t('message_three', {
              subjects: subjectTermPlural.toLowerCase(),
              topic: topicTermSingular.toLowerCase(),
            }),
          }}
        />
      </ModalBodyContainer>
      <ModalFooterContainer>
        <MainButtonWrapper>
          <DefaultButton
            buttonType='primary'
            id='bulk-move-esig-confirm-button'
            onClick={onClose}
            text={t('button_text')}
          />
        </MainButtonWrapper>
      </ModalFooterContainer>
    </CoreModal>
  );
};

export default ESigErrorModal;
