import * as yup from 'yup';

import initTranslations from '../../../../../lib/initTranslations';
import isUrl from '../../../../../lib/isUrl';
import { TDurationTimeFrequency } from '../../BoardColumn/types';
import {
  CONTENT_URL_TITLE_MAX_CHARACTERS,
  MAX_HOURS_PER_DAY,
  MAX_HOURS_PER_MONTH,
  MAX_HOURS_PER_QUARTER,
  MAX_HOURS_PER_WEEK,
  MAX_HOURS_PER_YEAR,
} from '../constants/modals';

const t = initTranslations('delegation_planner');

const generateTimeLimitError = (max: number, frequency: TDurationTimeFrequency): string =>
  t('validations.errors.time_limit_exceeded', {
    max,
    frequency: t(`time_frequencies.${frequency}`),
  });

const frequencyMaxHoursMap: Record<TDurationTimeFrequency, number> = {
  day: MAX_HOURS_PER_DAY,
  week: MAX_HOURS_PER_WEEK,
  month: MAX_HOURS_PER_MONTH,
  quarter: MAX_HOURS_PER_QUARTER,
  year: MAX_HOURS_PER_YEAR,
};

const contentTitleValidator = yup
  .string()
  .trim()
  .max(
    CONTENT_URL_TITLE_MAX_CHARACTERS,
    t('validations.errors.too_long', { max: CONTENT_URL_TITLE_MAX_CHARACTERS })
  )
  .test('content-title-required', t('validations.errors.title_required'), function (title) {
    const { lastSavedContentTitle, contentUrl } = this.parent;
    return lastSavedContentTitle?.length || contentUrl?.length ? !!title?.length : true;
  });

const contentUrlValidator = yup
  .string()
  .trim()
  .test('content-url-valid', t('validations.errors.url_invalid'), (url) => !url || isUrl(url))
  .test('content-url-required', t('validations.errors.url_required'), function (url) {
    const { lastSavedContentUrl, contentTitle } = this.parent;
    return lastSavedContentUrl?.length || contentTitle?.length ? !!url?.length : true;
  });

const timeFrequencyValidationSchema = yup.object().shape({
  time: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .integer()
    .when('frequency', (frequency, schema) => {
      const maxHours = frequencyMaxHoursMap[frequency as TDurationTimeFrequency];
      return maxHours ? schema.max(maxHours, generateTimeLimitError(maxHours, frequency)) : schema;
    }),
  frequency: yup
    .string()
    .oneOf(Object.keys(frequencyMaxHoursMap) as TDurationTimeFrequency[])
    .required(t('validations.errors.frequency_required')),
});

export const responsibilityContentUrlValidationSchema = yup.object().shape({
  contentTitle: contentTitleValidator,
  contentUrl: contentUrlValidator,
});

export const responsibilityDetailsModalValidationSchema = yup
  .object()
  .shape({
    contents_urls: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .trim()
          .max(
            CONTENT_URL_TITLE_MAX_CHARACTERS,
            t('validations.errors.too_long', { max: CONTENT_URL_TITLE_MAX_CHARACTERS })
          )
          .test('title-required', t('validations.errors.title_required'), function (title) {
            const { url } = this.parent;
            if (url?.length) return !!title?.length;
            return true;
          }),
        url: yup
          .string()
          .trim()
          .test('url-valid', t('validations.errors.url_invalid'), (url) => {
            return !url || isUrl(url);
          })
          .test('url-required', t('validations.errors.url_required'), function (url) {
            const { title } = this.parent;
            if (title?.length) return !!url?.length;
            return true;
          }),
      })
    ),
  })
  .concat(timeFrequencyValidationSchema);

export const getAddResponsibilityValidationSchema = (requiredMessage: string) => {
  return yup
    .object()
    .shape({
      name: yup.string().trim().required(requiredMessage),
    })
    .concat(timeFrequencyValidationSchema);
};
