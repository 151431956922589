import React, { FC } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { userFirstName } from '../../../../../../../lib/userNaming';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { useGetSpecializationsDataQuery } from '../../../../../../../redux/services/resourceApis/delegationPlanner/delegationPlannerApi';
import SpecializationsThreeDot from '../../../../dropdownMenus/SpecializationsThreeDot/SpecializationsThreeDot';
import Specialization from '../../../../Specialization/Specialization';
import { ISpecialization } from '../../../../types';
import {
  AddSpecializationsHeader,
  AddSpecializationsRoot,
  SpecializationsList,
  Title,
} from './styles';
import { AddSpecializationsProps } from './types';

const t = initTranslations('delegation_planner.specializations');

const AddSpecializations: FC<AddSpecializationsProps> = ({ isGroup, columnLabel }) => {
  const { data: globalSpecializations } = useGetSpecializationsDataQuery(undefined);
  const { currentBoardColumnResponsibility } = useAppSelector((state) => state.delegationPlanner);
  const { specializations: responsibilitySpecializations } = currentBoardColumnResponsibility;

  const mergedData: ISpecialization[] =
    globalSpecializations?.map((specialization) => {
      const scoreInfo = responsibilitySpecializations?.find(
        (item) => item.id === specialization.id
      );

      return {
        ...specialization,
        ...(scoreInfo && { score: scoreInfo.score, checked: true }),
      };
    }) ?? [];

  const name = userFirstName(columnLabel);

  const title = isGroup ? t('group_title') : t('named_title', { name });

  return (
    <AddSpecializationsRoot>
      <AddSpecializationsHeader>
        <Title>{title}</Title>

        <SpecializationsThreeDot
          boardColumnResponsibilityId={currentBoardColumnResponsibility.id}
        />
      </AddSpecializationsHeader>

      <SpecializationsList>
        {mergedData.map((specialization) => (
          <Specialization key={specialization.id} {...specialization} />
        ))}
      </SpecializationsList>
    </AddSpecializationsRoot>
  );
};

export default AddSpecializations;
