import { Sector } from '../../../../types/Sector';
import { DocumentImportData } from './types';

type ManageImportFilesOption = {
  bulkDocumentImportData: DocumentImportData[];
};

export type BulkImportFilesReducerAction =
  | {
      type: 'addImportFiles';
      bulkDocumentImportData: DocumentImportData[];
    }
  | {
      type: 'editSector';
      id: string;
      newSectorValue: Sector;
    }
  | {
      type: 'removeImportFile';
      id: string;
    };

export const bulkImportFilesReducer = (
  state: ManageImportFilesOption,
  action: BulkImportFilesReducerAction
): ManageImportFilesOption => {
  switch (action.type) {
    case 'addImportFiles': {
      return {
        ...state,
        bulkDocumentImportData: [...state.bulkDocumentImportData, ...action.bulkDocumentImportData],
      };
    }
    case 'editSector': {
      return {
        ...state,
        bulkDocumentImportData: state.bulkDocumentImportData.map((item) =>
          item.id === action.id ? { ...item, sector: action.newSectorValue } : item
        ),
      };
    }
    case 'removeImportFile': {
      return {
        ...state,
        bulkDocumentImportData: state.bulkDocumentImportData.filter(
          (item) => item.id !== action.id
        ),
      };
    }
  }
};
