import React, { useCallback } from 'react';
import { CustomNodeElementProps } from 'react-d3-tree/lib/types/common';

import useSlideout from '../../../../hooks/useSlideout';
import useWindowResize from '../../../../hooks/useWindowResize';
import NoResultsGraphicDark from '../../../../images/org_chart_dark.svg';
import NoResultsGraphic from '../../../../images/org_chart.svg';
import initTranslations from '../../../../lib/initTranslations';
import { OrgChartUser } from '../../../../types/OrgChart';
import NoResults from '../../../design_system/Triage/NoResults';
import { OrgChartBodyProps } from '../OrgChartShared/OrgChartBodyProps';
import OrgChartCardWrapper from '../OrgChartShared/OrgChartCardWrapper';
import OrgChartSharedBody from '../OrgChartShared/OrgChartSharedBody';
import OrgChartCard from './OrgChartCard';

const t = initTranslations('org_chart');

const OrgChartBody = ({
  canUpdateOrgChart,
  zoomState,
  data,
  isLoading,
  translationPrefix,
  showConfigure,
  orgChartContainerRef,
  resetOrgChart,
  searchOrgChartUser,
  setSearchOrgChartUser,
  downloadOrgChart,
}: OrgChartBodyProps<OrgChartUser>) => {
  const userProfileSlideout = useSlideout('user-profile');
  const { isExtraSmWindow } = useWindowResize();

  const handleOnClick = useCallback(
    ({ id: userId }: OrgChartUser) => {
      userProfileSlideout.open({ userId: userId.toString() });
    },
    [userProfileSlideout]
  );

  const orgChartCardView = useCallback(
    (rd3tNodeProps: CustomNodeElementProps) => {
      const nodeDatum = rd3tNodeProps.nodeDatum as unknown as OrgChartUser;

      return (
        <OrgChartCardWrapper
          event={rd3tNodeProps}
          onClick={handleOnClick}
          translationPrefix={translationPrefix}
        >
          <OrgChartCard canUpdateOrgChart={canUpdateOrgChart} user={nodeDatum} />
        </OrgChartCardWrapper>
      );
    },
    [canUpdateOrgChart, handleOnClick, translationPrefix]
  );

  return (
    <OrgChartSharedBody<OrgChartUser>
      NoResultsComponent={
        <NoResults
          darkImage={NoResultsGraphicDark}
          heading={t('empty_state.no_results_heading')}
          iconWidth={isExtraSmWindow ? '100%' : '590px'}
          lightImage={NoResultsGraphic}
          showSubheader={canUpdateOrgChart}
          subHeaderCta={{
            action: showConfigure,
            text: t('empty_state.no_results_advice'),
          }}
          subHeaderText={t('empty_state.no_results_advice_2')}
        />
      }
      canUpdateOrgChart={canUpdateOrgChart}
      cardSize={{ height: 150, width: 215 }}
      cardView={orgChartCardView}
      data={data}
      downloadOrgChart={downloadOrgChart}
      isLoading={isLoading}
      orgChartContainerRef={orgChartContainerRef}
      resetOrgChart={resetOrgChart}
      searchOrgChartUser={searchOrgChartUser}
      setSearchOrgChartUser={setSearchOrgChartUser}
      translationPrefix={translationPrefix}
      zoomState={zoomState}
    />
  );
};

export default OrgChartBody;
