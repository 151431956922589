import React from 'react';
import styled from 'styled-components';

import usePrivateConfigs from '../../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../../lib/initTranslations';
import Icon from '../../../../../design_system/display/icons/Icon';
import Link from '../../../../../design_system/Link';
import { fontSm5 } from '../../../../../styled/TypeSystem';

const ESignatureDisclaimerWrapper = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationHover};
  margin: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ESignatureDisclaimerContent = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  display: flex;
  flex-direction: column;
  ${fontSm5};
`;

const DisclaimerLabel = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  display: flex;
  flex-direction: row;
`;

const DisclaimerLinkWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('outline_content');

const ESignatureDisclaimer = () => {
  const { configs } = usePrivateConfigs();
  const signatureHelpPage = configs['SIGNATURE_HELP_PAGE'];

  return (
    <ESignatureDisclaimerWrapper id='e-signature-disclaimer'>
      <ESignatureDisclaimerContent>
        <DisclaimerLabel>
          <Icon name='info-circle' size='xs' />
          <span>{t('e_signature_disclaimer')}</span>
        </DisclaimerLabel>
        <DisclaimerLinkWrapper>
          <Link color='monochrome' external href={signatureHelpPage} text={t('learn_more')} />
        </DisclaimerLinkWrapper>
      </ESignatureDisclaimerContent>
    </ESignatureDisclaimerWrapper>
  );
};

export default ESignatureDisclaimer;
