import React, { ReactNode } from 'react';
import styled from 'styled-components';

import {
  mediaBreakpointPxLg,
  mediaBreakpointPxMd,
  mediaBreakpointPxSm,
  mediaBreakpointPxXl,
} from '../../../../../styled/Breakpoint';

type ColumnSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface Props {
  lg?: ColumnSizes;
  md?: ColumnSizes;
  sm?: ColumnSizes;
  xl?: ColumnSizes;
  xs?: ColumnSizes;
  className?: string;
  children: ReactNode;
}

function findColumnWidth(width: number) {
  return (width * 100) / 12;
}

const StyledColumn = styled.div<Props>`
  flex: 1 1 0;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  box-sizing: border-box;
  ${({ xs }) =>
    xs &&
    `
    flex: 0 0 ${findColumnWidth(xs)}%;
    max-width: ${findColumnWidth(xs)}%;
  `}
  ${({ sm }) =>
    sm &&
    `
    @media screen and (min-width: ${mediaBreakpointPxSm}) {
      flex: 0 0 ${findColumnWidth(sm)}%;
      max-width: ${findColumnWidth(sm)}%;
    }
  `}
  ${({ md }) =>
    md &&
    `
    @media screen and (min-width: ${mediaBreakpointPxMd}) {
      flex: 0 0 ${findColumnWidth(md)}%;
      max-width: ${findColumnWidth(md)}%;
    }
  `}
  ${({ lg }) =>
    lg &&
    `
    @media screen and (min-width: ${mediaBreakpointPxLg}) {
      flex: 0 0 ${findColumnWidth(lg)}%;
      max-width: ${findColumnWidth(lg)}%;
    }
  `}
  ${({ xl }) =>
    xl &&
    `
    @media screen and (min-width: ${mediaBreakpointPxXl}) {
      flex: 0 0 ${findColumnWidth(xl)}%;
      max-width: ${findColumnWidth(xl)}%;
    }
  `}
`;

const Column = ({ children, ...props }: Props) => {
  return <StyledColumn {...props}>{children}</StyledColumn>;
};

export default Column;
