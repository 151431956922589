import React from 'react';

import ProgressBar from '../../../../../design_system/Triage/ProgressBar';
import {
  CompletionContainer,
  CompletionScore,
  ProgressBarWrapper,
} from '../../../AllUsersReportPage/AllUsersReportTable/Columns/styles';
import { ColumnComponentProps } from '../types';

const Completion = ({ record }: ColumnComponentProps) => {
  const { completion } = record;

  return (
    <CompletionContainer>
      <CompletionScore>{completion}</CompletionScore>
      <ProgressBarWrapper>
        <ProgressBar percent={parseFloat(completion)} thickness='md' />
      </ProgressBarWrapper>
    </CompletionContainer>
  );
};

export default Completion;
