import React from 'react';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
`;

type Props = {
  fileLink: string;
};

const PdfIframePreview = ({ fileLink }: Props) => {
  return (
    <StyledIframe
      allow='fullscreen'
      allowFullScreen
      className='pdf-preview-iframe'
      frameBorder='0'
      src={`${window.location.origin}/pdf.js/web/viewer.html?file=${fileLink}`}
    />
  );
};

export default PdfIframePreview;
