import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RegisteredId } from '../../../../../lib/idRegistry';
import { GroupByMenuOption } from '../../../../../types/GroupByMenuOption';
import { SortDirection } from '../../../../../types/SortDirection';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import DefaultButton from '../../../buttons/DefaultButton';

const DropdownWrapper = styled.div<{ size: DropdownMenuSize }>`
  height: ${({ theme: { constants } }) => constants.heightMd};
  ${({ size }) => getDropdownMenuSize(size)};
`;

const DropdownButton = styled(DefaultButton)`
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const DropdownMenuListWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  width: fit-content;
`;

const DropdownMenuWrapper = styled.div`
  z-index: 10;
  width: 10rem;
  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
  }
`;

const MenuButton = styled.button`
  text-align: left;
  font-size: 0.875rem;
  background: transparent;
  border: none;
  color: ${({ theme: { vars } }) => vars.textDefault};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationHover};
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    cursor: pointer;
  }
  ${TruncatedText({})};
`;

export interface DropdownMenuOption {
  label: string;
  sortColumn: string;
  sortDirection: SortDirection;
  useInGroups?: boolean;
}

export interface DropdownMenuListProps {
  dropdownOpen: boolean;
  setSelectedOption: (IDropdownMenuOption: DropdownMenuOption) => void;
  toggleDropdown: () => void;
  options: DropdownMenuOption[];
}

const DropdownMenuList = (props: DropdownMenuListProps) => {
  const { dropdownOpen, setSelectedOption, options, toggleDropdown } = props;

  return (
    <>
      {dropdownOpen && (
        <DropdownMenuListWrapper className='dropdown-menu-list-wrapper'>
          {options.map((option) => {
            return (
              <MenuButton
                id={`${option.label
                  .toLowerCase() // Convert to lowercase
                  .replace(/\s+/g, '-') // Replace spaces with hyphens
                  .replace(/[^\w-]/g, '')}-dropdown-option`}
                key={`${option.sortColumn}_${option.sortDirection}_button`}
                onClick={() => {
                  toggleDropdown();
                  setSelectedOption(option);
                }}
              >
                {option.label}
              </MenuButton>
            );
          })}
        </DropdownMenuListWrapper>
      )}
    </>
  );
};

const dropdownMenuSizes = ['sm', 'md', 'max-content'] as const;
export type DropdownMenuSize = (typeof dropdownMenuSizes)[number];
export const getDropdownMenuSize = (size: DropdownMenuSize) => {
  switch (size) {
    case 'sm':
      return {
        width: '10.625rem',
      };
    case 'md':
      return {
        width: '13.25rem',
      };
    case 'max-content':
      return {
        width: 'max-content',
      };
  }
};

export interface Props {
  className?: string;
  id?: RegisteredId;
  options: DropdownMenuOption[];
  selectedOption: DropdownMenuOption | GroupByMenuOption;
  setSelectedOption: (IDropdownMenuOption: DropdownMenuOption) => void;
  size?: DropdownMenuSize;
}

const DropdownMenu = ({
  className,
  options,
  selectedOption,
  setSelectedOption,
  size = 'sm',
  id = 'sort-order-dropdown-button',
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    const closeDropdown = () => setDropdownOpen(false);
    if (dropdownOpen) {
      window.addEventListener('click', closeDropdown);
    }
    return () => window.removeEventListener('click', closeDropdown);
  }, [dropdownOpen, setDropdownOpen]);

  return (
    <DropdownMenuWrapper className={className}>
      <DropdownWrapper size={size}>
        <DropdownButton
          buttonType='secondary'
          iconName={dropdownOpen ? 'angle-up' : 'angle-down'}
          iconPosition='right'
          iconWeight='solid'
          id={id}
          onBlur={() => setTimeout(() => setDropdownOpen(false), 200)}
          onClick={toggleDropdown}
          text={
            selectedOption && 'viewByLabel' in selectedOption
              ? selectedOption.viewByLabel + ' ' + selectedOption.label.toLowerCase()
              : selectedOption.label
          }
        />

        <DropdownMenuList
          dropdownOpen={dropdownOpen}
          options={options}
          setSelectedOption={setSelectedOption}
          toggleDropdown={toggleDropdown}
        />
      </DropdownWrapper>
    </DropdownMenuWrapper>
  );
};

export default DropdownMenu;
