import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledSurface = styled.div`
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface2};
  border-radius: 0.3rem;
  padding: 1.7rem 1.5rem;
  width: 100%;
  min-width: 0;

  @media (max-width: 415px) {
    padding: 0.9rem 0.9rem 0.9rem 0.95rem;
  }
`;

export interface Props {
  children: ReactNode | ReactNode[];
  id?: string;
  className?: string;
}

const Surface = (props: Props) => {
  const { children, ...propsToPass } = props;

  return <StyledSurface {...propsToPass}>{children}</StyledSurface>;
};

export default Surface;
