import React, { ReactNode, createContext, useContext } from 'react';

interface CurriculumEditProviderContext {
  showDeleteCurriculumModal: boolean;
  setShowDeleteCurriculumModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CurriculumEditContext = createContext<CurriculumEditProviderContext>(
  {} as CurriculumEditProviderContext
);

export const useCurriculumEdit = () => useContext(CurriculumEditContext);

interface ProviderProps {
  children: ReactNode;
  showDeleteCurriculumModal: boolean;
  setShowDeleteCurriculumModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CurriculumEditProvider = ({
  children,
  showDeleteCurriculumModal,
  setShowDeleteCurriculumModal,
}: ProviderProps) => {
  return (
    <CurriculumEditContext.Provider
      value={{
        showDeleteCurriculumModal,
        setShowDeleteCurriculumModal,
      }}
    >
      {children}
    </CurriculumEditContext.Provider>
  );
};
