import React from 'react';

import { RegisteredMenuId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { ThreeDotWrapper } from '../../curriculums/shared/CurriculumRowStyles';
import CurriculumElementThreeDotMenu from './CurriculumElementThreeDotMenu/CurriculumElementThreeDotMenu';
import { CurriculumElementThreeDotProps } from './types';

const t = initTranslations('curriculums.tooltips');

const CurriculumElementThreeDot = ({
  curriculumElement,
  curriculumId,
  isCurriculumLocked,
  eSignatureDisplayedAndRequired,
  signaturable,
  canBeModified,
  renameClickHandler,
}: CurriculumElementThreeDotProps) => {
  const {
    curriculum: { singular: curriculumTermSingular },
  } = useAccountTerminology();
  const menuId: RegisteredMenuId = `curriculum-element-three-dot-${curriculumElement.id}`;

  return (
    <>
      {isCurriculumLocked && (
        <Tooltip
          id={`locked-ce-element-${curriculumElement.id}`}
          text={t('subject_locked', { subject: curriculumTermSingular })}
        />
      )}
      <ThreeDotWrapper
        className='curriculum-elements-three-dot-wrapper'
        isLocked={isCurriculumLocked}
        {...(isCurriculumLocked && {
          'data-for': `locked-ce-element-${curriculumElement.id}`,
          'data-tip': true,
        })}
      >
        <CurriculumElementThreeDotMenu
          canBeModified={canBeModified}
          curriculumElement={curriculumElement}
          curriculumId={curriculumId}
          eSignatureDisplayedAndRequired={eSignatureDisplayedAndRequired}
          iconFontSize='1.5rem'
          id={menuId}
          isDisabled={isCurriculumLocked}
          renameClickHandler={renameClickHandler}
          signaturable={signaturable}
        />
      </ThreeDotWrapper>
    </>
  );
};

export default CurriculumElementThreeDot;
