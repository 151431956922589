import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useConsumptionCourseContext } from '../../../../../../contexts/ConsumptionCourseContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { useCompleteStepMutation } from '../../../../../../redux/services/resourceApis/steps/stepsApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { routes } from '../../../../publicApplication/applicationRouter';
import NavigationButtonsFactory from '../../../components/NavigationButtonsFactory/NavigationButtonsFactory';
import { useGetIncompleteStepInfo } from '../../../hooks/useIncompleteStepInfo';
import { useStepRange } from '../../../hooks/useStepRange';
import { useAccessibleSteps } from '../../hooks/useAccesibleSteps';
import { usePreviousAndNextResource } from '../../hooks/usePreviousAndNextResource';
import MoveToSigningStepConfirmationModal from '../MoveToSigningStepConfirmationModal/MoveToSigningStepConfirmationModal';
import ReachedESignatureUsageLimitModal from '../ReachedESignatureUsageLimitModal/ReachedESignatureUsageLimitModal';

const t = initTranslations('editor.navigation_buttons');

type Props = {
  stepId: number;
};

const NavigationButtons = ({ stepId }: Props) => {
  const {
    curriculum: { published: curriculumPublished, completionRequired },
    course: {
      id: courseId,
      adjacentCurriculumElements: { next: nextCurriculumElement },
      status: courseStatus,
      steps,
      eSignatureDisplayedAndRequiredForUser,
    },
    curriculum,
    course,
    currentStepCompleted,
    isCourseCompleteShowing,
    setIsCourseCompleteShowing,
  } = useConsumptionCourseContext();
  const { slug, reachedESignatureFeatureUsageLimit } = useCurrentAccount();
  const {
    step: { singular: stepTerm },
  } = useAccountTerminology();
  const { flash } = useFlashNotification();
  const { previous, next } = usePreviousAndNextResource(stepId);
  const { isLastStep, currentStepIndex } = useStepRange(steps, stepId);
  const { onlyIncompleteStep } = useGetIncompleteStepInfo(steps, stepId);
  const [trigger, { isLoading, data }] = useCompleteStepMutation();
  const showSignatureButton = eSignatureDisplayedAndRequiredForUser && onlyIncompleteStep;
  const [justCompleted, setJustCompleted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const previousStep = steps[currentStepIndex - 1];

  const { nextStepId, shouldForceOrder } = useAccessibleSteps({
    course,
    curriculum,
    currentStepId: stepId,
  });

  const markStepComplete = useCallback(() => {
    trigger({ stepId, courseId });
    setJustCompleted(true);
  }, [courseId, stepId, trigger]);

  useEffect(() => {
    if (data) {
      const { nextElement, allCoursesCompleted } = data;

      if (allCoursesCompleted && !eSignatureDisplayedAndRequiredForUser) {
        setIsCourseCompleteShowing(true);
        return;
      }
      if (!nextElement) return routes.home({ slug }).push();

      switch (nextElement.elementKind) {
        case 'course':
        case 'survey':
        case 'flowchart':
          setIsCourseCompleteShowing(true);
          return;
        case 'step':
          return routes.consume({ slug, id: nextElement.id }).push();
      }
    }
  }, [data, eSignatureDisplayedAndRequiredForUser, setIsCourseCompleteShowing, slug]);

  useEffect(() => {
    if (previousStep && shouldForceOrder && nextStepId && !justCompleted) {
      routes.consume({ id: nextStepId, slug }).push();
      flash('info', t('force_order_flash', { stepTerm: stepTerm.toLowerCase() }));
    }
  }, [flash, justCompleted, next, nextStepId, previousStep, shouldForceOrder, slug, stepTerm]);

  const previousStepIdRef = useRef<number>(stepId);

  useEffect(() => {
    if (isCourseCompleteShowing && previousStepIdRef.current !== stepId) {
      setIsCourseCompleteShowing(false);
    }
    previousStepIdRef.current = stepId;
  }, [stepId, isCourseCompleteShowing, setIsCourseCompleteShowing]);

  return (
    <>
      <div id='consume-navigation-buttons'>
        <NavigationButtonsFactory
          modeAttrs={{
            mode: 'consume',
            curriculumPublished,
            markStepComplete,
            currentStepCompleted,
            completionRequired,
            isCourseCompleteShowing,
            isLoading,
            isLastStep,
            hasNextCurriculumElement: !!nextCurriculumElement,
            courseStatus,
            setIsCourseCompleteShowing,
            showSignatureButton,
            onSignatureClick: () => setShowModal(true),
          }}
          next={next}
          previous={previous}
        />
      </div>
      {showModal && (
        <>
          {reachedESignatureFeatureUsageLimit ? (
            <ReachedESignatureUsageLimitModal closeModal={() => setShowModal(false)} />
          ) : (
            <MoveToSigningStepConfirmationModal closeModal={() => setShowModal(false)} />
          )}
        </>
      )}
    </>
  );
};

export default NavigationButtons;
