import React from 'react';

import { HeaderRow } from '../Styles';
import { Column, TableAction, TableState } from '../TableTypes';
import TableHeaderCell, { SortProps } from './TableHeaderCell';

type Props = {
  tableState: TableState;
  dispatch: React.Dispatch<TableAction>;
  columns: Column[];
};

const TableHeader = ({ tableState, dispatch, columns }: Props) => {
  return (
    <thead>
      <HeaderRow>
        {columns
          .filter(({ display }) => display !== 'hidden')
          .map((column: Column) => {
            const activeColumn = tableState.sortColumn === column.columnName;
            let sortProps: SortProps = { isSortable: column.sortable };

            // Disable sorting if users are selected
            if (tableState.selectedUsers.length || tableState.selectAllUsers) {
              sortProps = { isSortable: false };
            } else {
              if (column.sortable) {
                const sortIcon = activeColumn ? tableState.sortIcon : 'sort';
                sortProps = { isSortable: true, sortIcon };
              }
            }

            return (
              <TableHeaderCell
                activeHeader={activeColumn}
                column={column}
                dispatch={dispatch}
                key={`header-${column.columnName}`}
                setActiveHeader={() => {
                  if (
                    column.sortable &&
                    !tableState.selectAllUsers &&
                    !tableState.selectedUsers.length
                  ) {
                    dispatch({ type: 'sortColumnChange', sortColumn: column.columnName });
                  }
                }}
                tableState={tableState}
                {...sortProps}
              />
            );
          })}
      </HeaderRow>
    </thead>
  );
};

export default TableHeader;
