import React from 'react';

import { useCheckNoTrialFlow } from '../../../../../hooks/billing/useCheckNoTrialFlow';
import { useCheckModalState } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import FullScreenOverlay from '../../../shared/FullScreenOverlay/FullScreenOverlay';
import { useGetFullScreenOverlayContent } from './utils/useGetFullScreenOverlayContent';

type BillingFullScreenOverlayFactoryProps = {
  id: string;
};

const BillingFullScreenOverlayFactory = ({ id }: BillingFullScreenOverlayFactoryProps) => {
  const isNoTrialFlow = useCheckNoTrialFlow();
  const fullScreenOverlayContent = useGetFullScreenOverlayContent();
  const showUpdatePaymentMethodModal = useCheckModalState('updatePaymentMethod');
  const withoutCloseIcon =
    isNoTrialFlow &&
    [
      'configure-plan-overlay',
      'compare-plans-overlay',
      'pick-a-simple-pricing-plan-overlay',
    ].includes(id);
  const fixedCloseIcon = !withoutCloseIcon && id === 'compare-plans-overlay';

  return (
    <FullScreenOverlay
      fixedCloseIcon={fixedCloseIcon}
      id={id}
      withOpenedModal={showUpdatePaymentMethodModal}
      withoutCloseIcon={withoutCloseIcon}
    >
      {fullScreenOverlayContent}
    </FullScreenOverlay>
  );
};

export default BillingFullScreenOverlayFactory;
