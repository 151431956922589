import { Form, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useReducer } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { deleteQueryParam } from '../../../../../../lib/getQueryParams';
import initTranslations from '../../../../../../lib/initTranslations';
import { useSaveTrainualCaptureContentToStepMutation } from '../../../../../../redux/services/resourceApis/steps/stepsApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import FieldLabel from '../../../../../design_system/core/FieldLabel';
import ConfirmationModal from '../../../../../design_system/overlays/modals/ConfirmationModal';
import TaskModal, { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';
import InputField from '../../../../../design_system/Triage/InputField';
import RadioField from '../../../../../design_system/Triage/RadioField';
import { routes } from '../../../../publicApplication/applicationRouter';
import { initialModalReducer, modalReducer } from './reducer';
import SelectCourseModal from './SelectCourseModal';
import { ImportOptionsWrapper, InputRow } from './styles';
import { FormValues, StepWithCaptureContent } from './types';

const t = initTranslations('steps.modals.trainual_capture.save_capture_to_step.import_capture');

const ImportCaptureModal = () => {
  const { slug } = useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const { curriculum, step, productTerm } = useAccountTerminology();
  const [modalState, dispatch] = useReducer(modalReducer, initialModalReducer);
  const { showModal, showSelectCourseModal, captureId, captureHeight } = modalState;
  const {
    values,
    touched,
    errors,
    isSubmitting,
    isValid,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormikContext<FormValues>();
  const [saveCaptureToStep, result] = useSaveTrainualCaptureContentToStepMutation();
  const { data } = result;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const displayModal = queryParams.get('open_trainual_capture_modal') === 'true';
    const id = queryParams.get('id');
    const height = queryParams.get('height');
    const captureTitle = queryParams.get('title');

    if (displayModal) {
      dispatch({ type: 'setShowModal', showModal: true });
      dispatch({ type: 'setModalData', captureId: id, captureHeight: height });
      setFieldValue('stepTitle', captureTitle || '');

      const params = ['open_trainual_capture_modal', 'id', 'height', 'title'];
      params.map((param) => deleteQueryParam(param));
    }
    // We only want to add event listeners once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successFunction = useCallback(() => {
    if (!data) return;

    routes.editor({ slug, id: data.id }).push();
  }, [data, slug]);

  useDisplayFlashOnResponse({
    result,
    successFunction,
    errorMessage: t('errors.capture_saving_error'),
  });

  const submitForm = useCallback(() => {
    const step: StepWithCaptureContent = {
      step_title: values.stepTitle,
      capture_id: captureId,
      capture_height: captureHeight,
    };

    if (showSelectCourseModal) {
      step.course_id = values.courseId;
    }

    saveCaptureToStep(step);
  }, [captureHeight, captureId, saveCaptureToStep, values, showSelectCourseModal]);

  useEffect(() => {
    if (isSubmitting && isValid) {
      submitForm();
    }
  }, [isSubmitting, isValid, submitForm]);

  const primaryButtonAction = () => {
    if (values.importOption === 'draft') {
      handleSubmit();
    } else {
      dispatch({ type: 'setShowModal', showModal: false });
      dispatch({ type: 'setShowSelectCourseModal', showSelectCourseModal: true });
    }
  };

  function generateImportOptions(types: ('topic' | 'draft')[]) {
    return types.map((type) => ({
      text: t(`import_options.${type}`, {
        subject: curriculum.singular.toLowerCase(),
      }),
      value: type,
    }));
  }

  const taskModalArgs: TaskModalProps = {
    title: t('title', { productTerm }),
    subtitle: t('subtitle'),
    onCloseRequest: () => dispatch({ type: 'setShowModal', showModal: false }),
    processing: isSubmitting,
    primaryButtonText: t('save'),
    primaryButtonTask: primaryButtonAction,
    isDisabled: isSubmitting || !values.stepTitle,
    heapModalName: 'save-capture-to-step-modal',
  };

  if (ability.can('create', 'Curriculum')) {
    return (
      <div id='save-capture-to-step-modal-wrapper'>
        {showModal && (
          <Form autoComplete='off'>
            <TaskModal {...taskModalArgs} desktopSize='lg'>
              <InputRow>
                <InputField
                  autoFocus
                  errorText={touched.stepTitle && errors.stepTitle}
                  id='new-capture-step-name'
                  label={t('input_label', { step: step.singular })}
                  name='stepTitle'
                  onChange={handleChange}
                  placeholder={t('input_placeholder', { step: step.singular.toLowerCase() })}
                  required
                  type='text'
                  value={values.stepTitle}
                />
              </InputRow>
              <ImportOptionsWrapper>
                <FieldLabel text={t('import_options.label')} />
                <RadioField
                  name='importOption'
                  onChange={handleChange}
                  options={generateImportOptions(['topic', 'draft'])}
                  orientation='vertical'
                  value={values.importOption}
                />
              </ImportOptionsWrapper>
            </TaskModal>
          </Form>
        )}
        <SelectCourseModal dispatch={dispatch} showSelectCourseModal={showSelectCourseModal} />
      </div>
    );
  }

  return (
    <div id='unable-to-save-capture-confirmation-modal-wrapper'>
      {showModal && (
        <ConfirmationModal
          actionFunction={() => dispatch({ type: 'setShowModal', showModal: false })}
          actionText=''
          desktopSize='xl'
          hasPrimaryButton={false}
          heapModalName='unable-to-save-capture-confirmation-modal'
          message={t('not_authorized_to_import.message')}
          onCloseRequest={() => dispatch({ type: 'setShowModal', showModal: false })}
          processing={false}
          secondaryButtonText={t('close')}
          title={t('not_authorized_to_import.title')}
        />
      )}
    </div>
  );
};

export default ImportCaptureModal;
