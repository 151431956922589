import React from 'react';

import { RegisteredId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import { CurriculumElementStatus } from '../../../../../types/CurriculumElement';
import { Navigation } from '../../../../../types/Editor';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import {
  ButtonType,
  DefaultButtonProps,
} from '../../../../design_system/buttons/DefaultButton/ButtonTypes';
import { NavigationButtonWrapper, NavigationButtonsWrapper } from '../../shared/styles';

const t = initTranslations('editor.navigation_buttons');

type ModeProps =
  | {
      mode: 'edit';
      curriculumPublished?: never;
      markStepComplete?: never;
      currentStepCompleted?: never;
      isLoading?: never;
      isLastStep?: never;
      completionRequired?: never;
      nextCurriculumElement?: never;
      courseStatus?: never;
      onSignatureClick?: never;
      showSignatureButton?: never;
      isCourseCompleteShowing?: never;
      setIsCourseCompleteShowing?: never;
    }
  | {
      mode: 'consume';
      curriculumPublished: boolean;
      markStepComplete?: () => void;
      isLoading?: boolean;
      isLastStep: boolean;
      currentStepCompleted: boolean;
      completionRequired: boolean;
      hasNextCurriculumElement: boolean;
      courseStatus: CurriculumElementStatus;
      isCourseCompleteShowing: boolean;
      setIsCourseCompleteShowing: (isCourseComplete: boolean) => void;
      onSignatureClick?: () => void;
      showSignatureButton: boolean;
    };

type CommonButtonProps = {
  buttonType: ButtonType;
  fullWidth: boolean;
  id: RegisteredId;
};

export type Props = {
  previous: Navigation | null;
  next: Navigation | null;
  modeAttrs: ModeProps;
};

type NextButtonProps = {
  next: Navigation | null;
  modeAttrs: ModeProps;
};

const NextButton = ({ next, modeAttrs }: NextButtonProps) => {
  const { mode } = modeAttrs;
  const nextButtonType = mode === 'consume' ? 'primary' : 'tertiary';

  if (!next) return null;
  const nextTerm = next.term.toLowerCase();

  const commonButtonProps: CommonButtonProps = {
    fullWidth: true,
    id: 'next-editor-button',
    buttonType: 'secondary',
  };

  if (mode === 'consume') {
    const {
      isLoading,
      isLastStep,
      curriculumPublished,
      currentStepCompleted,
      completionRequired,
      hasNextCurriculumElement,
      courseStatus,
      showSignatureButton,
      onSignatureClick,
      markStepComplete,
    } = modeAttrs;

    let buttonProps: DefaultButtonProps;

    if (!curriculumPublished) {
      buttonProps = {
        ...commonButtonProps,
        behavesAs: 'a',
        href: next.route,
        iconName: 'arrow-down',
        text: t('next', { term: nextTerm }),
      };
    }

    if (curriculumPublished && courseStatus === 'finished') {
      if (currentStepCompleted) {
        if (isLastStep) {
          if (hasNextCurriculumElement) {
            buttonProps = {
              ...commonButtonProps,
              behavesAs: 'a',
              href: next.route,
              iconName: 'arrow-down',
              text: t('next', { term: nextTerm }),
            };
          } else {
            buttonProps = {
              ...commonButtonProps,
              behavesAs: 'a',
              buttonType: 'primary',
              href: next.route,
              iconName: 'house-chimney',
              text: next.term,
            };
          }
        } else {
          buttonProps = {
            ...commonButtonProps,
            behavesAs: 'a',
            href: next.route,
            iconName: 'arrow-down',
            text: t('next', { term: nextTerm }),
          };
        }
      } else {
        if (completionRequired) {
          buttonProps = {
            ...commonButtonProps,
            behavesAs: 'button',
            buttonType: nextButtonType,
            disabled: isLoading,
            loading: isLoading,
            onClick: markStepComplete,
            text: next.term,
          };
          if (showSignatureButton) {
            buttonProps = {
              ...commonButtonProps,
              behavesAs: 'button',
              onClick: onSignatureClick,
              iconName: 'signature',
              text: t('sign_and_complete'),
              buttonType: 'primary',
            };
          }
        } else {
          if (isLastStep) {
            if (hasNextCurriculumElement) {
              buttonProps = {
                ...commonButtonProps,
                behavesAs: 'button',
                buttonType: nextButtonType,
                iconName: 'arrow-down',
                disabled: isLoading,
                loading: isLoading,
                onClick: markStepComplete,
                text: t('next', { term: nextTerm }),
              };
            } else {
              buttonProps = {
                ...commonButtonProps,
                behavesAs: 'button',
                buttonType: nextButtonType,
                disabled: isLoading,
                loading: isLoading,
                onClick: markStepComplete,
                iconName: 'house-chimney',
                text: next.term,
              };
            }
          } else {
            buttonProps = {
              ...commonButtonProps,
              behavesAs: 'button',
              buttonType: nextButtonType,
              iconName: 'arrow-down',
              disabled: isLoading,
              loading: isLoading,
              onClick: markStepComplete,
              text: t('next', { term: nextTerm }),
            };
          }
        }
      }

      return <DefaultButton {...buttonProps} />;
    }
  }

  // Edit Mode
  return (
    <DefaultButton
      {...commonButtonProps}
      behavesAs='a'
      buttonType={nextButtonType}
      href={next.route}
      iconName='arrow-down'
      text={t('next', { term: nextTerm })}
    />
  );
};

const NavigationButtonsFactory = ({ previous, next, modeAttrs }: Props) => {
  const { mode } = modeAttrs;
  const previousButtonType = mode === 'consume' ? 'secondary' : 'tertiary';
  const previousTerm = previous?.term.toLowerCase();

  return (
    <>
      <NavigationButtonsWrapper>
        <NavigationButtonWrapper>
          {previous && (
            <DefaultButton
              behavesAs='a'
              buttonType={previousButtonType}
              fullWidth
              href={previous.route}
              iconName='arrow-up'
              id='previous-editor-button'
              text={t('previous', { term: previousTerm })}
            />
          )}
        </NavigationButtonWrapper>
        <NavigationButtonWrapper>
          <NextButton modeAttrs={modeAttrs} next={next} />
        </NavigationButtonWrapper>
      </NavigationButtonsWrapper>
    </>
  );
};

export default NavigationButtonsFactory;
