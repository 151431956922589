import React from 'react';
import styled from 'styled-components';

import Link from '../../../../design_system/Link';
import { Title } from '../../../shared/DetailedTitle/Title';
import { FontSize, FontSizeSelected } from '../../Slideouts/Shared/FontSize';
import { LabelWrapper } from './styles';

const InviteLabelWrapper = styled(LabelWrapper)`
  flex-wrap: wrap;
`;

const LabelText = styled.p<{ fontSize?: FontSize }>`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${FontSizeSelected};
`;

type SecondaryUserDetailStatusProps = {
  inviteUserText: string;
  labelText: string;
  labelValue: string;
  onSendInviteClick: () => void;
  showInviteLink: boolean;
};

const SecondaryUserDetailStatus = ({
  inviteUserText,
  labelText,
  labelValue,
  onSendInviteClick,
  showInviteLink,
}: SecondaryUserDetailStatusProps) => {
  return (
    <InviteLabelWrapper>
      <LabelWrapper>
        <Title fontWeight='semibold' title={labelValue} />
        <LabelText>{labelText}</LabelText>
      </LabelWrapper>
      {showInviteLink && (
        <Link
          behavesAs='button'
          onClick={onSendInviteClick}
          text={inviteUserText}
          underlineBehavior='dynamic'
        />
      )}
    </InviteLabelWrapper>
  );
};

export default SecondaryUserDetailStatus;
