import { createGlobalStyle } from 'styled-components';

import PinIcon from '../../../../../images/google_autocomplete_pin.svg';
import { CORE_MODAL_BACKDROP_Z_INDEX } from '../../../../design_system/core/CoreModal/CoreModal';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm4 } from '../../../../styled/TypeSystem';

export const GoogleAutocompleteDropdownStyle = createGlobalStyle`
  .pac-container {
    font-family: 'Poppins', sans-serif;
    min-width: 18.75rem;
    margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
    box-shadow: ${({ theme: { vars } }) => vars.shadowTopMedium};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
    border: none;
    z-index: calc(${CORE_MODAL_BACKDROP_Z_INDEX} + 1);

    .pac-item {
      display: flex;
      align-items: center;
      max-width: 100%;
      padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
      border-top: none;
      border-bottom: ${({ theme: { constants, vars } }) =>
        `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
      color: ${({ theme: { vars } }) => vars.textDefault};
      cursor: pointer;

      &:last-of-type {
        border-bottom: 0;
        margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
      }
      .pac-icon {
        flex-shrink: 0;
        width: ${({ theme: { constants } }) =>
          `calc(${constants.spacerSm2} + ${constants.spacerMd2})`};
        height: ${({ theme: { constants } }) =>
          `calc(${constants.spacerSm2} + ${constants.spacerMd2})`};
        margin-top: 0;
        background-image: url(${PinIcon});
        background-size: auto;
        background-position: center;
      }
      .pac-item-query {
        font-size: inherit;
      }
      span:last-of-type {
        ${TruncatedText({})};
      }

      ${fontSm4};
    }
  }
`;
