import { getFormattedPrice } from '../../lib/getFormattedPrice';
import initTranslations from '../../lib/initTranslations';
import { Discount } from '../../types/Discount';
import useCurrentAccount from '../useCurrentAccount';

type PricePerIntervalProps = {
  isAnnualInterval: boolean;
  isHoldPlan: boolean;
  isFree3SeatsPlan: boolean;
  totalPrice: number;
  discount: Discount | undefined;
  plusTaxes?: boolean;
};

const t = initTranslations('billing.price');

export const usePricePerInterval = ({
  isAnnualInterval,
  isHoldPlan,
  isFree3SeatsPlan,
  totalPrice,
  discount,
}: PricePerIntervalProps) => {
  const {
    splitFeatures: { simplePricingEnabled, simplePricingV3Enabled },
  } = useCurrentAccount();
  if (isHoldPlan) {
    return t('hold_price_per_month', { price: getFormattedPrice(totalPrice) });
  }
  const isSimplePricingPlans = simplePricingEnabled || simplePricingV3Enabled;
  const isAnnualPricePerUserInterval = isSimplePricingPlans ? false : isAnnualInterval;
  const intervalKey = isAnnualPricePerUserInterval
    ? 'price_per_year_interval'
    : 'price_per_month_interval';
  const pricePerInterval = t(intervalKey, {
    price: getFormattedPrice(totalPrice, discount, isAnnualInterval),
  });
  const taxesSuffix = isFree3SeatsPlan ? '' : t('plus_taxes');

  return pricePerInterval + taxesSuffix;
};
