import React from 'react';

import ControlLabel from '../../../design_system/core/Labels/ControlLabel/ControlLabel';
import { Button, TextStyleBarButtonWrapper } from './TextStyleBarStyles';
import { TextStyleBarButtonProps } from './TextStyleBarTypes';

const TextStyleBarButton = ({
  active,
  ariaLabel,
  className = '',
  disabled = false,
  id,
  label = '',
  name,
  onClick,
  weight = 'regular',
}: TextStyleBarButtonProps) => {
  return (
    <TextStyleBarButtonWrapper>
      <Button
        active={active}
        ariaLabel={ariaLabel}
        buttonSize='sm'
        className={className}
        disabled={disabled}
        id={id}
        name={name}
        onClick={onClick}
        weight={weight}
      />
      <ControlLabel htmlFor={id} text={label} />
    </TextStyleBarButtonWrapper>
  );
};

export default TextStyleBarButton;
