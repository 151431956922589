import React, { useEffect, useState } from 'react';

import { parseBillingDetailsResponse } from '../../../../lib/billing/parseBillingDetailsResponse';
import initTranslations from '../../../../lib/initTranslations';
import AddressField from './AddressField/AddressField';
import CardHolderField from './CardHolderField/CardHolderField';
import CountrySelect from './CountrySelect/CountrySelect';
import CurrentCreditCardField from './CurrentCreditCardField/CurrentCreditCardField';
import StripeElements from './StripeElements/StripeElements';
import {
  Form,
  FormColumn,
  InputFieldWrapper,
  RowWithTwoInputField,
  StyledInputField,
} from './styles';
import {
  ChangeHandler,
  UpdatePaymentMethodFormProps,
  UpdatePaymentMethodNameOfFields,
} from './types';

const t = initTranslations('update_payment_method_modal.address');

const UpdatePaymentMethodForm = ({
  columnDirectionOnMd,
  formData,
  dispatch,
  isFormProcessing,
  clearFieldError,
  paymentMethodFormErrors,
  validateFields,
  validateField,
  billingDetailsData,
}: UpdatePaymentMethodFormProps) => {
  const { last4, expMonth, expYear, brand } = formData;
  const isExistsCurrentCreditCard = Boolean(brand && expMonth && expYear && last4);
  const [showStripeElements, setShowStripeElements] = useState<boolean>(!isExistsCurrentCreditCard);

  const handleChange = ({ event, name = '', value = '' }: ChangeHandler) => {
    const fieldName = (event ? event.target.name : name) as UpdatePaymentMethodNameOfFields;
    const fieldValue = event ? event.target.value : value;
    const isFieldWithError = fieldName !== 'addressLine2' && paymentMethodFormErrors[fieldName];

    isFieldWithError && clearFieldError(fieldName);

    dispatch({
      type: 'updateFormData',
      payload: { updatedData: { [fieldName]: fieldValue }, paymentDetailsChanged: true },
    });
  };

  useEffect(() => {
    if (billingDetailsData) {
      dispatch({ type: 'setFormData', payload: parseBillingDetailsResponse(billingDetailsData) });
    }
  }, [billingDetailsData, dispatch]);

  useEffect(() => {
    setShowStripeElements(!isExistsCurrentCreditCard);
  }, [isExistsCurrentCreditCard]);

  return (
    <>
      <Form columnDirectionOnMd={columnDirectionOnMd} id='update-payment-method-form'>
        <FormColumn>
          <InputFieldWrapper withMarginBottom>
            <CardHolderField
              errorText={paymentMethodFormErrors.name}
              handleChange={handleChange}
              isFormProcessing={isFormProcessing}
              setShowStripeElements={setShowStripeElements}
              showStripeElements={showStripeElements}
              validateField={validateField}
              value={formData.name}
            />
          </InputFieldWrapper>
          {showStripeElements ? (
            <StripeElements dispatch={dispatch} isFormProcessing={isFormProcessing} />
          ) : (
            <CurrentCreditCardField
              cardDetails={{ brand, expMonth, expYear, last4 }}
              setShowStripeElements={setShowStripeElements}
            />
          )}
        </FormColumn>
        <FormColumn>
          <AddressField
            dispatch={dispatch}
            errorText={paymentMethodFormErrors.addressLine1}
            handleChange={handleChange}
            isFormProcessing={isFormProcessing}
            validateField={validateField}
            validateFields={validateFields}
            value={formData.addressLine1}
          />
          <InputFieldWrapper withMarginBottom>
            <StyledInputField
              disabled={isFormProcessing}
              id='addressLine2'
              label={t('apt_suite')}
              name='addressLine2'
              onChange={(event) => handleChange({ event })}
              placeholder={t('apt_suite')}
              type='text'
              value={formData.addressLine2}
            />
          </InputFieldWrapper>
          <RowWithTwoInputField>
            <InputFieldWrapper withMarginBottom>
              <StyledInputField
                disabled={isFormProcessing}
                errorText={paymentMethodFormErrors.addressCity}
                id='addressCity'
                label={t('city')}
                name='addressCity'
                onBlur={(event) => validateField({ event })}
                onChange={(event) => handleChange({ event })}
                placeholder={t('enter_city')}
                required
                type='text'
                value={formData.addressCity}
              />
            </InputFieldWrapper>
            <InputFieldWrapper withMarginBottom>
              <StyledInputField
                disabled={isFormProcessing}
                errorText={paymentMethodFormErrors.addressState}
                id='addressState'
                label={t('state')}
                name='addressState'
                onBlur={(event) => validateField({ event })}
                onChange={(event) => handleChange({ event })}
                placeholder={t('enter_state')}
                required
                type='text'
                value={formData.addressState}
              />
            </InputFieldWrapper>
          </RowWithTwoInputField>
          <RowWithTwoInputField>
            <InputFieldWrapper>
              <StyledInputField
                disabled={isFormProcessing}
                errorText={paymentMethodFormErrors.addressZip}
                id='addressZip'
                label={t('zip_postal_code')}
                name='addressZip'
                onBlur={(event) => validateField({ event })}
                onChange={(event) => handleChange({ event })}
                placeholder={t('enter_your_code')}
                required
                type='text'
                value={formData.addressZip}
              />
            </InputFieldWrapper>
            <CountrySelect
              errorText={paymentMethodFormErrors.addressCountry}
              handleChange={handleChange}
              isFormProcessing={isFormProcessing}
              validateField={validateField}
              value={formData.addressCountry}
            />
          </RowWithTwoInputField>
        </FormColumn>
      </Form>
    </>
  );
};

export default UpdatePaymentMethodForm;
