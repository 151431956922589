import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { Emoji } from '../../../../../types/Emoji';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import EmojiSelect from '../../components/SubjectEmoji/EmojiSelect';

const EmojiWrapper = styled.span`
  flex-shrink: 0;
  margin-inline: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
`;

const CheckboxWrapper = styled.span`
  flex-shrink: 0;
  margin-inline: ${({ theme: { constants } }) => `0 ${constants.spacerMd1}`};
`;

// Add the missing import statement for RedirectOptions

type Props = {
  anAdmin: boolean;
  contentBulkActionsEnabled: boolean;
  emoji: Emoji;
  id: number;
  isAdminHovered: boolean;
  isChecked: boolean;
  onCheckboxChange?: (id: number, checked: boolean) => void;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
  handleEmojiClear: () => void;
  handleEmojiSelect: (baseEmoji: BaseEmoji) => void;
};

const LibraryRowBulkActions = ({
  anAdmin,
  contentBulkActionsEnabled,
  emoji,
  handleEmojiClear,
  handleEmojiSelect,
  id,
  isAdminHovered,
  isChecked,
  onCheckboxChange,
  setIsChecked,
}: Props) => {
  const handleCheckboxClick = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    if (onCheckboxChange) {
      onCheckboxChange(id, newCheckedState);
    }
  };

  return (
    <>
      {contentBulkActionsEnabled ? (
        (anAdmin && isAdminHovered) || isChecked ? (
          <CheckboxWrapper className='bulk-action-checkbox-wrapper'>
            <Checkbox
              checked={isChecked}
              id={`bulk-content-subject-checkbox-${id}`}
              name='bulk-checkbox'
              onCheck={handleCheckboxClick}
            />
          </CheckboxWrapper>
        ) : (
          <EmojiWrapper className='lib-row-emoji-picker'>
            <EmojiSelect
              clearEmoji={handleEmojiClear}
              emoji={emoji}
              noEmojiIconName='file-lines'
              onEmojiSelect={handleEmojiSelect}
            />
          </EmojiWrapper>
        )
      ) : (
        <EmojiWrapper className='lib-row-emoji-picker'>
          <EmojiSelect
            clearEmoji={handleEmojiClear}
            emoji={emoji}
            noEmojiIconName='file-lines'
            onEmojiSelect={handleEmojiSelect}
          />
        </EmojiWrapper>
      )}
    </>
  );
};

export default LibraryRowBulkActions;
