import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import {
  useGetCurriculumRequestAccessQuery,
  useRequestAccessMutation,
} from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Badge from '../../../design_system/display/badge';
import Icon from '../../../design_system/display/icons/Icon';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import Breadcrumbs from '../../../design_system/navigation/Breadcrumbs';
import P from '../../../design_system/text/P';
import Line from '../../../design_system/Triage/layout/Line';
import Loader from '../../../design_system/Triage/Loader';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import PageContent from '../../shared/page_content';
import { RoundedCardSurface } from '../../shared/RoundedSurfaceCard/RoundedCardSurface';
import { EmojiWrapper } from '../../shared/styles';
import {
  ContentWrapper,
  H1,
  ReadingTimeWrapper,
  StyledIcon,
  Title,
  TitleWrapper,
} from '../CurriculumShow/CurriculumShowStyles';
import getCurrentPageText from '../shared/BreadCrumbs/currentPageTextHelper';
import { LoaderWrapper } from '../shared/styles';

const BreadCrumbWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ theme: { constants } }) => `0 0 ${constants.spacerMd2} ${constants.spacerSm3}`};
`;

const RequestButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledReadingTimeWrapper = styled(ReadingTimeWrapper)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const Wrapper = styled.div`
  min-height: 3rem;
`;

const t = initTranslations('curriculum_show');

export type Props = {
  route: Route<typeof routes.curriculumRequestAccess>;
};

const RequestAccessPage = ({ route }: Props) => {
  const { id } = route.params;
  const { slug } = useCurrentAccount();
  const [requestAccess, result] = useRequestAccessMutation();
  const ability = useCurrentUserAbilities();
  const canViewCurriculum = ability.can('read', `ShowCurriculum-${id}`);
  const { isLoading, data: curriculum } = useGetCurriculumRequestAccessQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const theme = useTheme();
  useDisplayFlashOnResponse({
    result,
    successMessage: t('request_sent'),
    errorMessage: messageFromError(result.error)?.join(', '),
  });

  if (canViewCurriculum) {
    routeTo(routes.curriculumShow({ slug, id }).href);
  }

  if (isLoading)
    return (
      <LoaderWrapper>
        <Loader size='lg' />
      </LoaderWrapper>
    );
  if (!curriculum) return <></>;

  const {
    description,
    emoji,
    minutes_to_read: minutesToRead,
    minutes_to_read_formatted: minutesToReadFormatted,
    title,
    request_pending: requestPending,
    reference,
  } = curriculum;
  const currentPageText = getCurrentPageText({ emoji, title });
  const showTimeEstimates = minutesToRead > 0;

  return (
    <PageContent id={`curriculum-${id}-request-access-page`}>
      <BreadCrumbWrapper id='restricted-breadcrumbs'>
        <Breadcrumbs
          currentPageText={currentPageText}
          redirectPath={routes.content({ slug }).href}
          redirectText={t('content')}
        />
      </BreadCrumbWrapper>
      <RoundedCardSurface id='curriculum-info'>
        <ContentWrapper>
          <Wrapper>
            <TitleWrapper>
              <EmojiWrapper>
                {emoji || <Icon name='file-lines' size='md' weight='regular' />}
              </EmojiWrapper>
              <Title>
                <H1>{title}</H1>
                {reference && <Badge text={t('badges.reference')} type='general' />}
              </Title>
            </TitleWrapper>
            <P id='curriculum-description' text={description} />
            {showTimeEstimates && (
              <StyledReadingTimeWrapper id='reading-time'>
                <Tooltip id='reading-time-tooltip' text={t('reading_time_tooltip')} />
                <div data-for='reading-time-tooltip' data-tip>
                  <StyledIcon name='info-circle' />
                </div>
                <span>{t('time_to_read', { time_to_read: minutesToReadFormatted })}</span>
              </StyledReadingTimeWrapper>
            )}
          </Wrapper>
          <div id='bottom-section'>
            <Line margin={`${theme.constants.spacerSm3} 0 ${theme.constants.spacerMd2} 0`} />
            <RequestButtonWrapper>
              <DefaultButton
                disabled={requestPending}
                id={`request-curriculum-${id}-access-page-button`}
                onClick={() => requestAccess({ curriculumId: id })}
                text={requestPending ? t('requested') : t('request_access')}
              />
            </RequestButtonWrapper>
          </div>
        </ContentWrapper>
      </RoundedCardSurface>
    </PageContent>
  );
};

export default RequestAccessPage;
