import styled from 'styled-components';

export const InputRow = styled.div`
  input {
    width: 100%;
    padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
    padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

export const ImportOptionsWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;
