import { useCallback, useEffect, useRef } from 'react';

import { useSetAutosaveStatus } from '../../../../redux/domains/autoSave/autosaveSlice';
import { AutoSaveCondition, AutoSaveStatus } from './types';

const useAutoSaveStatusUpdater = (conditions: AutoSaveCondition[]): void => {
  const setAutosaveStatus = useSetAutosaveStatus();
  const conditionsRef = useRef('');
  const currentConditionsStr = JSON.stringify(conditions);

  const updateAutosaveStatus = useCallback(() => {
    if (conditionsRef.current !== currentConditionsStr) {
      const isAnySaving = conditions.some((condition) => condition.isSaving);
      const isAllSaved = conditions.every(
        (condition) => condition.isSavedSuccessfully || !condition.isSaving
      );

      const determineStatus = (): AutoSaveStatus => {
        if (isAnySaving) return AutoSaveStatus.Saving;
        if (isAllSaved) return AutoSaveStatus.Saved;

        return AutoSaveStatus.NoChanges;
      };

      const status = determineStatus();
      setAutosaveStatus(status);

      conditionsRef.current = currentConditionsStr;
    }
  }, [currentConditionsStr, conditions, setAutosaveStatus]);

  useEffect(() => {
    updateAutosaveStatus();
  }, [updateAutosaveStatus]);
};

export default useAutoSaveStatusUpdater;
