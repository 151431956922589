import React from 'react';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../../../hooks/useDisplayFlashOnResponse';
import { featuresListData } from '../../../../../../../lib/featuresListData';
import initTranslations from '../../../../../../../lib/initTranslations';
import { useFetchPlansDataQuery } from '../../../../../../../redux/services/resourceApis/billing/billingApi';
import { Feature } from '../../../../../../../types/Feature';
import PlanDetails from '../PlanDetails/PlanDetails';
import PlanFeature from './FeatureList/PlanFeature';
import {
  OverlayContentWrapper,
  PlanSection,
  PlansAndFeaturesWrapper,
  PlansSection,
  PlansTitle,
  PlansWrapper,
} from './styles';

const t = initTranslations('per_user_pricing.billing_page.plans.compare_plans_overlay');

const ComparePlansOverlayContent = () => {
  const { status } = useCurrentAccount();
  const billingPlansPriceResult = useFetchPlansDataQuery();
  const { data: billingPlansPriceData } = billingPlansPriceResult;
  const isTrialingOrTrialEnded = status === 'trialing' || status === 'trial_ended';

  useDisplayFlashOnResponse({
    result: billingPlansPriceResult,
    errorMessage: t('error_message'),
  });

  return (
    <OverlayContentWrapper>
      <PlansAndFeaturesWrapper>
        <PlansWrapper>
          <PlansTitle>{t('title')}</PlansTitle>
          <PlansSection>
            {billingPlansPriceData &&
              billingPlansPriceData.billingPlans.map((billingPlan) => (
                <PlanSection
                  className={`compare-plans-overlay-${billingPlan.name}-plan`}
                  key={billingPlan.name}
                >
                  <PlanDetails
                    interval={billingPlan.interval}
                    isComparePlanOverlay
                    isTrialingOrTrialEnded={isTrialingOrTrialEnded}
                    planName={billingPlan.name}
                    seatsIncluded={billingPlan.seatsIncluded}
                    unitPrice={billingPlan.unitPrice}
                  />
                </PlanSection>
              ))}
          </PlansSection>
        </PlansWrapper>
        {featuresListData.map((feature: Feature) => {
          return (
            <PlanFeature
              billingPlansPriceData={billingPlansPriceData}
              feature={feature}
              key={feature.id}
            />
          );
        })}
      </PlansAndFeaturesWrapper>
    </OverlayContentWrapper>
  );
};

export default ComparePlansOverlayContent;
