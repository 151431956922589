import React, { ReactNode, useReducer } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { getChevronIconName } from '../../../../shared/helpers';
import { collapseReducer, initialCollapseState } from './reducer';
import {
  CollapsibleSection,
  ElementHeader,
  ElementTitle,
  ElementWrapper,
  StyledIconButton,
} from './styles';

const t = initTranslations('collapsible_surface.aria_label');

type Props = {
  id: string;
  children: ReactNode | ReactNode[];
  title: string | ReactNode;
};

const CollapsibleFormElement = ({ id, children, title }: Props) => {
  const [{ collapsed }, dispatch] = useReducer(collapseReducer, initialCollapseState);

  return (
    <ElementWrapper className='collapsible-template-element' id={id}>
      <ElementHeader onClick={() => dispatch({ type: 'toggleCollapsed' })}>
        <StyledIconButton
          ariaLabel={collapsed ? t('show_content') : t('hide_content')}
          buttonSize='sm'
          className='collapsible-form-element-icon'
          name={getChevronIconName({ isActive: !collapsed, initialDirection: 'up' })}
        />
        <ElementTitle>{title}</ElementTitle>
      </ElementHeader>
      {collapsed ? <></> : <CollapsibleSection>{children}</CollapsibleSection>}
    </ElementWrapper>
  );
};

export default CollapsibleFormElement;
