import React, { useMemo } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../lib/initTranslations';
import LinkTabs, { LinkTab } from '../../../design_system/navigation/LinkTabs';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { routes as routerRoutes } from '../../publicApplication/applicationRouter';

const t = initTranslations('org_chart');

const StyledContainer = styled.div`
  @media (max-width: ${mediaBreakpointPxSm}) {
    order: 1;
    margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

const OrgChartTabs = () => {
  const ability = useCurrentUserAbilities();
  const { slug } = useCurrentAccount();
  const showOrgChart = ability.can('read', 'OrgChart');
  const showGroupChart = ability.can('read', 'GroupChart');

  const tabs: LinkTab[] = useMemo(() => {
    return [
      {
        is_beta: false,
        name: t('tabs.people'),
        to: routerRoutes.orgChart({ slug }),
      },
      {
        is_beta: false,
        name: t('tabs.groups'),
        to: routerRoutes.roleChart({ slug }),
      },
    ];
  }, [slug]);

  if (!showGroupChart || !showOrgChart) return <></>;

  return (
    <StyledContainer>
      <LinkTabs id='orgchart-tab' isUrlParams={false} tabs={tabs} />
    </StyledContainer>
  );
};

export default OrgChartTabs;
