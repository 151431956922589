import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import useCurrentUserAbilities from '../../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAdminUpdateCurriculumMutation } from '../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CurriculumEdit } from '../../../../../../redux/services/resourceApis/curriculums/types';
import { Emoji } from '../../../../../../types/Emoji';
import Icon from '../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { EmojiWrapper } from '../../../../shared/CurriculumCard/CurriculumCardStyles';
import EmojiPicker from '../../../../shared/EmojiPicker';
import CurriculumThreeDot from '../../CurriculumThreeDot';
import Title from './Title';

const TopRightBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* The Negative 0.5rem - border width margin is added to align inner text with the rest of the 
  TopSurface content rather than hidden input border */
  margin-left: ${({ theme: { constants } }) => `calc(-${constants.spacerSm3} + 1px)`};
`;

// DS Override: Pointer events none was required to get tooltip to show up
const StyledIcon = styled(Icon)`
  pointer-events: none;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

type Props = {
  emoji: Emoji;
  title: string;
  id: number;
  isLocked?: boolean;
  containsSignature?: boolean;
  curriculum: CurriculumEdit;
};

const t = initTranslations('curriculums.edit');

const Header = ({ emoji, title, id, isLocked, containsSignature, curriculum }: Props) => {
  const [showPicker, setShowPicker] = useState(false);
  const [update, result] = useAdminUpdateCurriculumMutation();

  const {
    can_assign_users: canAssignUsers,
    completion_required: completionRequired,
    curriculum_can_be_modified: curriculumCanBeModified,
    user_can_delete_subject: userCanDeleteSubject,
    published,
    sector,
  } = curriculum;

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('emoji_error'),
  });

  const emojiSelected = useCallback(
    (emoji: BaseEmoji) => {
      update({ emoji: emoji.native, id });
      setShowPicker(false);
    },
    [id, update]
  );

  const clearEmoji = useCallback(() => {
    update({ emoji: null, id });
  }, [id, update]);

  const ability = useCurrentUserAbilities();
  const canViewThreeDot = ability.can('read', `AdminCurriculumThreeDotMenu-${id}`);

  return (
    <HeaderWrapper>
      <Tooltip id='update-emoji-tooltip' place='top' text={t('update_emoji')} />
      <EmojiPicker
        emojiPresent={emoji}
        isOpen={showPicker}
        onClick={() => setShowPicker(!showPicker)}
        onClickOutside={() => setShowPicker(false)}
        onEmojiSelect={emojiSelected}
        removeButtonAction={clearEmoji}
        trigger={
          <EmojiWrapper
            clickable
            data-for='update-emoji-tooltip'
            data-tip
            id='emoji-wrapper'
            onClick={() => setShowPicker(!showPicker)}
          >
            {emoji ? (
              emoji
            ) : (
              <StyledIcon className='default-emoji' name='file-alt' size='md' weight='regular' />
            )}
          </EmojiWrapper>
        }
      />
      <TitleWrapper>
        <Title containsSignature={containsSignature} id={id} isLocked={isLocked} title={title} />
        {canViewThreeDot && (
          <TopRightBlock>
            <CurriculumThreeDot
              canDeleteSubject={userCanDeleteSubject}
              completionRequired={completionRequired}
              containsSignature={!curriculumCanBeModified}
              curriculumId={id}
              curriculumTitle={title}
              published={published}
              reportsAccess={canAssignUsers}
              sector={sector}
            />
          </TopRightBlock>
        )}
      </TitleWrapper>
    </HeaderWrapper>
  );
};

export default Header;
