import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';

import CelebrationAnimationData from './celebration_animation_data.json';

const CelebrationAnimationContainer = styled.div`
  height: 10rem;
`;

const CelebrationAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CelebrationAnimationData,
  };

  return (
    <CelebrationAnimationContainer>
      <Lottie options={defaultOptions} width={160} />
    </CelebrationAnimationContainer>
  );
};

export default CelebrationAnimation;
