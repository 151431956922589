import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Toggle from '../../../../design_system/input/controls/Toggle';
import {
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  ToggleContainer,
} from '../styles';
import { CustomTerminologySectionProps } from './types';

const t = initTranslations('account_settings.advanced_settings');

const CustomTerminologySection = ({
  enableCustomTerminology,
  id,
}: CustomTerminologySectionProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  return (
    <div id={id}>
      <FormSectionContainer>
        <FormSectionTitle>{t('custom_terminology_title')}</FormSectionTitle>
        <ToggleContainer>
          <Toggle
            checked={enableCustomTerminology}
            className='custom-terminology-setting'
            handleToggle={(e) => updateAccountData({ terminology_on: e.target.checked })}
            id='enable-custom-terminology'
            name='enable_custom_terminology'
          />
        </ToggleContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormSectionDescription>{t('custom_terminology_description')}</FormSectionDescription>
      </FormSectionContainer>
    </div>
  );
};

export default CustomTerminologySection;
