import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../../../../shared/SkeletonLoader/styles';

const AddonOverlayContentSkeleton = () => {
  const {
    constants: { spacerMd1, spacerMd3, spacerLg2, borderRadiusXl, borderRadiusMd },
  } = useTheme();
  return (
    <div>
      <SkeletonLoader borderRadius={borderRadiusXl} height='20rem' marginBottom={spacerLg2} />

      <SkeletonLoader
        borderRadius={borderRadiusMd}
        height='3.75rem'
        marginBottom={spacerMd3}
        width='75%'
      />

      <SkeletonLoader borderRadius={borderRadiusMd} marginBottom={spacerMd1} width='50%' />
      <SkeletonLoader borderRadius={borderRadiusMd} height='3rem' marginBottom={spacerMd3} />

      <SkeletonLoader borderRadius={borderRadiusMd} marginBottom={spacerMd1} width='50%' />
      <SkeletonLoader borderRadius={borderRadiusMd} height='3rem' marginBottom={spacerMd3} />
    </div>
  );
};

export default AddonOverlayContentSkeleton;
