import styled from 'styled-components';

import P from '../../../../../../design_system/text/P';

export const FeatureListItems = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const FeatureListItem = styled.li`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FeatureListItemTitle = styled(P)<{ itemTitleColor: string }>`
  color: ${({ itemTitleColor }) => itemTitleColor};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;
