import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import usePageTitle from '../../../../hooks/usePageTitle';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import { useIsAdmin } from '../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../lib/initTranslations';
import { useGetTrainingOrderCurriculumsQuery } from '../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Link from '../../../design_system/Link/Link';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import Loader from '../../../design_system/Triage/Loader';
import { routes } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import BackBreadcrumb from '../components/BackBreadcrumb/BackBreadcrumb';
import SubjectOrderTable from './SubjectOrderTable/SubjectOrderTable';

const t = initTranslations('curriculums.content');

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const SetOrderPage = () => {
  const {
    curriculum: { plural: curriculumTermPlural },
    productTerm,
  } = useAccountTerminology();
  usePageTitle({ resourceType: 'content_order', productTerm });
  const { slug } = useCurrentAccount();
  const { configs } = usePrivateConfigs();
  const canSetTrainingOrder = useIsAdmin();
  const [page, setPage] = useState(1);
  const { data } = useGetTrainingOrderCurriculumsQuery({ page }, { skip: !canSetTrainingOrder });

  useEffect(() => {
    if (!canSetTrainingOrder) {
      routes.content({ slug }).replace();
    }
  }, [canSetTrainingOrder, slug]);

  if (!canSetTrainingOrder) {
    return null;
  }

  return (
    <PageContent>
      <TopSection>
        <BackBreadcrumb />
        <HeaderSection id='set-order-header'>
          <PageHeader
            subtitle={
              <>
                {t('set_order.description', { subjects: curriculumTermPlural.toLowerCase() })}
                <Link
                  behavesAs='a'
                  external
                  href={configs['SET_TRAINING_ORDER_HELP_PAGE']}
                  text={t('set_order.help_link_text')}
                />
              </>
            }
            title={t('set_order.title')}
          />
        </HeaderSection>
      </TopSection>
      {!data ? (
        <LoaderWrapper>
          <Loader size='lg' />
        </LoaderWrapper>
      ) : (
        <SubjectOrderTable
          curriculums={data.curriculums}
          pagination={{
            activePage: page,
            itemsCountPerPage: data.limitValue,
            onChange: (page) => setPage(page),
            showPaginationDetails: true,
            totalItemsCount: data.totalCurriculumsCount,
            totalPages: data.totalPages,
          }}
          updatedAt={data.updatedAt}
        />
      )}
    </PageContent>
  );
};

export default SetOrderPage;
