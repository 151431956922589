import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import Badge from '../../../design_system/display/badge';
import DetailedTitle from '../../shared/DetailedTitle/DetailedTitle';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  max-width: 75%;
`;

const t = initTranslations('assign_curriculums_modal.badges');

type AssignSubjectProps = {
  curriculum: {
    title: string;
    published: boolean;
    emoji: string | null | undefined;
  };
};

const AssignSubject = ({ curriculum }: AssignSubjectProps) => {
  const badgeType = curriculum.published ? 'success' : 'caution';

  return (
    <Wrapper>
      <TitleWrapper>
        <DetailedTitle
          emoji={curriculum.emoji}
          fontWeight='regular'
          title={curriculum.title}
          truncate='oneLine'
        />
      </TitleWrapper>
      <Badge text={t(badgeType)} type={badgeType} />
    </Wrapper>
  );
};

export default AssignSubject;
