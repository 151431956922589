import { Form, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { PaletteOption } from 'saguaro';

import { OnboardingData, useOnboarding } from '../../../../../contexts/OnboardingContext';
import { useCheckCustomAccentColor } from '../../../../../hooks/useCheckCustomAccentColor';
import initTranslations from '../../../../../lib/initTranslations';
import { OFFERED_ACCENT_PALETTES } from '../../../account_settings/shared/constants/accountSettings';
import OnboardingStep from '../OnboardingStep/OnboardingStep';
import SimpleColorPicker from '../SimpleColorPicker';
import SimplePalettePicker from '../SimplePalettePicker';
import {
  CustomizeThemeDescription,
  CustomizeThemeStepContainer,
  CustomizeThemeTitle,
} from './styles';

const t = initTranslations('home.onboarding.customize_accent_color_step');

const CustomizeAccentColorStepForm = () => {
  const { formData, setFormData } = useOnboarding();
  const { touched, errors, handleSubmit, setFieldValue } = useFormikContext<OnboardingData>();
  const { accent_color: accentColor } = formData;
  const customAccentColor = useCheckCustomAccentColor();
  const initialAccentHexColor = customAccentColor ? accentColor : null;
  const [accentHexColor, setAccentHexColor] = useState<string | null>(initialAccentHexColor);

  const handleHexChange = useCallback(
    (hex: string) => {
      setAccentHexColor(hex);

      if ([4, 7].includes(hex.length)) {
        setFieldValue('accent_color', hex);
        setFieldValue('accent_palette', null);
        setFormData({ ...formData, accent_color: hex, accent_palette: null });
      } else if (hex.length == 0) {
        setFieldValue('accent_color', null);
        setFieldValue('accent_palette', 'purple');
        setFormData({ ...formData, accent_color: null, accent_palette: 'purple' });
      }
    },
    [formData, setFieldValue, setFormData]
  );

  const handlePaletteChange = useCallback(
    (palette: PaletteOption) => {
      setFieldValue('accent_color', null);
      setFieldValue('accent_palette', palette);
      setAccentHexColor(null);
      setFormData({ ...formData, accent_color: null, accent_palette: palette });
    },
    [formData, setFieldValue, setFormData]
  );

  return (
    <Form autoComplete='off' id='customize-accent-color-step-form' onSubmit={handleSubmit}>
      <OnboardingStep>
        <CustomizeThemeStepContainer>
          <CustomizeThemeTitle>{t('title')}</CustomizeThemeTitle>
          <CustomizeThemeDescription>{t('theme_color_description')}</CustomizeThemeDescription>
          <SimplePalettePicker
            activePalette={formData.accent_palette}
            className='accent-palette-picker'
            id='onboarding-accent-palette-picker'
            palettes={OFFERED_ACCENT_PALETTES}
            setPalette={handlePaletteChange}
          />
          <CustomizeThemeDescription>{t('hex_description')}</CustomizeThemeDescription>
          <SimpleColorPicker
            className='accent-color-picker'
            errorText={touched.accent_color && errors.accent_color}
            hex={accentHexColor}
            id='onboarding-accent-color-picker'
            name='accent_color'
            placeholder={t('hex_placeholder')}
            setHexColor={handleHexChange}
          />
        </CustomizeThemeStepContainer>
      </OnboardingStep>
    </Form>
  );
};

export default CustomizeAccentColorStepForm;
