import DOMPurify from 'dompurify';
import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import initTranslations from '../../../../../lib/initTranslations';
import { sanitizeInput } from '../../../../../lib/sanitize';
import { useUpdateStepMutation } from '../../../../../redux/services/resourceApis/steps/stepsApi';
import { Emoji } from '../../../../../types/Emoji';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Icon from '../../../../design_system/display/icons/Icon';
import { ErrorText as BaseErrorText } from '../../../../design_system/Triage/InputField';
import { fontMd3, fontMd4 } from '../../../../styled/TypeSystem';
import EmojiPicker from '../../../shared/EmojiPicker';
import { EmojiWrapper } from '../../../shared/styles';
import { getValidationSchema } from '../../shared/validators';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FullWidthContainer = styled.div`
  width: 100%;
`;

const StyledContentEditor = styled(ContentEditable)<{ placeholder: string }>`
  width: 100%;
  overflow: hidden;
  border: none;

  margin: ${({ theme: { constants } }) => constants.spacerSm2} 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};

  :empty:before {
    content: attr(placeholder);
    display: block;
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  &:focus {
    outline: none;
  }

  ${fontMd4};
`;

const ErrorText = styled(BaseErrorText)`
  margin-top: -${({ theme: { constants } }) => constants.spacerSm2};
`;

const StyledEmojiWrapper = styled(EmojiWrapper)`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  border-radius: ${({ theme }) => theme.constants.borderRadiusCircle};
  ${fontMd3};
`;

const t = initTranslations('editor.step');

type FormValues = {
  id: number;
  courseId: number;
  emoji: Emoji;
  title: string;
};

export type Props = FormValues;

const StepTitle = ({ emoji, id, title, courseId }: Props) => {
  const { setSaveState, setIsEditingTitle } = useEditorContext();
  const {
    step: { singular: stepTerm },
  } = useAccountTerminology();
  const [updateStep, result] = useUpdateStepMutation();
  const { isLoading, isSuccess } = result;
  const [showPicker, setShowPicker] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(title);

  useEffect(() => {
    if (isLoading) {
      setSaveState('saving');
    } else if (isSuccess) {
      setSaveState('saved');
    }
  }, [setSaveState, isLoading, isSuccess]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ emoji, id, title, courseId }}
      onSubmit={(values) => {
        const newTitle = sanitizeInput(values.title);
        setCurrentTitle(newTitle);
        return updateStep({ ...values, title: newTitle });
      }}
      validationSchema={getValidationSchema()}
    >
      {({
        isSubmitting,
        isValidating,
        isValid,
        errors,
        setFieldValue,
        handleSubmit,
        values,
      }: FormikProps<FormValues>) => {
        if (isSubmitting && !isValidating && !isValid) {
          setFieldValue('title', currentTitle);
        }

        const handleBlur = () => {
          setIsEditingTitle(false);
          handleSubmit();
        };

        return (
          <div>
            <TitleWrapper>
              <EmojiPicker
                emojiPresent={values.emoji}
                isOpen={showPicker}
                onClick={() => setShowPicker(!showPicker)}
                onClickOutside={() => setShowPicker(false)}
                onEmojiSelect={(emoji: BaseEmoji) => {
                  setFieldValue('emoji', emoji.native);
                  handleSubmit();
                  setShowPicker(false);
                }}
                removeButtonAction={() => {
                  setFieldValue('emoji', null);
                  handleSubmit();
                }}
                trigger={
                  <StyledEmojiWrapper id='emoji-picker-trigger'>
                    {values.emoji || <Icon name='list-ol' size='sm' weight='regular' />}
                  </StyledEmojiWrapper>
                }
              />
              <FullWidthContainer>
                <StyledContentEditor
                  html={values.title}
                  id='title'
                  inputMode='text'
                  onBlur={handleBlur}
                  onChange={(e) => {
                    let sanitized = DOMPurify.sanitize(e.target.value, { ALLOWED_TAGS: [] });
                    sanitized = sanitized.replace(/&nbsp;/g, ' ');

                    setFieldValue('title', sanitized);
                  }}
                  onFocus={() => setIsEditingTitle(true)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }}
                  placeholder={t('placeholder', { step: stepTerm.toLowerCase() })}
                />
                {errors && <ErrorText>{errors.title}</ErrorText>}
              </FullWidthContainer>
            </TitleWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default StepTitle;
