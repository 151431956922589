import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import {
  useCheckModalState,
  useDispatchSetShowModal,
} from '../../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import CurriculumModal from '../../../../curriculums/modals/CurriculumModal/CurriculumModal';
import { TypeName } from '../../../../shared/ContentFlyout/types';
import { TResponsibilityContentUrl } from '../../../BoardColumn/types';
import ResponsibilityLinkedContent from '../ResponsibilityLinkedContent/ResponsibilityLinkedContent';
import { CONTENTABLE_URLS } from '../ResponsibilityLinkedContent/utils';
import AddSpecializations from './AddSpecializations/AddSpecializations';
import ConnectedContentInfo from './ConnectedContentInfo/ConnectedContentInfo';
import Description from './Description/Description';
import OptionsMenu from './OptionsMenu/OptionsMenu';
import ResponsibilityDurationTimeSetter from './ResponsibilityDurationTimeSetter/ResponsibilityDurationTimeSetter';
import { ResponsibilityDetailsContainer, SectionContainer } from './styles';
import { ResponsibilityDetailsFormData, ResponsibilityDetailsProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal');

const ResponsibilityDetails: FC<ResponsibilityDetailsProps> = ({ boardColumn }) => {
  const { currentBoardColumnResponsibility } = useAppSelector((state) => state.delegationPlanner);
  const { slug } = useCurrentAccount();
  const {
    curriculum: { singular: subjectTermSingular },
    responsibility: { singular: responsibilityTermSingular },
  } = useAccountTerminology();
  const { columnLabel, columnType } = boardColumn;
  const isEditable = !currentBoardColumnResponsibility?.groupId;
  const isGroupTypeCard = columnType === 'Group';
  const dispatchShowModal = useDispatchSetShowModal();
  const isCurriculumModalOpen = useCheckModalState('delegationCreateAndConnectCurriculumModal');
  const { values, setValues } = useFormikContext<ResponsibilityDetailsFormData>();

  const handleConnectCreatedCurriculumContent = (curriculumId: number, curriculumTitle: string) => {
    const typeName = TypeName.Curriculum;
    const url = CONTENTABLE_URLS({ id: curriculumId, slug })[typeName];
    const contentUrl: TResponsibilityContentUrl = {
      url,
      title: curriculumTitle,
      contentableId: curriculumId,
      contentableType: typeName,
    };

    setValues({
      ...values,
      contents_urls: [contentUrl, ...values.contents_urls],
    });
  };

  return (
    <ResponsibilityDetailsContainer>
      <SectionContainer>
        <ResponsibilityDurationTimeSetter />
        <Description />
        <ResponsibilityLinkedContent
          boardColumn={boardColumn}
          columnLabel={columnLabel}
          isEditable={isEditable}
          isGroupTypeCard={isGroupTypeCard}
        />
        {!isEditable && <ConnectedContentInfo />}
      </SectionContainer>
      <SectionContainer>
        <AddSpecializations columnLabel={columnLabel} isGroup={isGroupTypeCard} />

        {isEditable && <OptionsMenu />}
      </SectionContainer>
      {isCurriculumModalOpen && (
        <CurriculumModal
          afterCreateCurriculumAction={handleConnectCreatedCurriculumContent}
          closeRequest={() => dispatchShowModal('delegationCreateAndConnectCurriculumModal', false)}
          providedFormSubtitle={t('curriculum_modal.subtitle', {
            subject: subjectTermSingular.toLowerCase(),
            responsibility: responsibilityTermSingular.toLowerCase(),
          })}
          providedFormTitle={t('curriculum_modal.title', {
            subject: subjectTermSingular.toLowerCase(),
          })}
        />
      )}
    </ResponsibilityDetailsContainer>
  );
};

export default ResponsibilityDetails;
