import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader, SkeletonLoaderWrapper } from '../../../../../shared/SkeletonLoader/styles';

const AddonOverlayContentSkeleton = () => {
  const {
    constants: { borderRadiusMd, borderRadiusXl },
  } = useTheme();
  return (
    <div>
      <SkeletonLoader borderRadius={borderRadiusMd} height='5.5rem' marginBottom='0.815rem' />
      <SkeletonLoader
        borderRadius={borderRadiusMd}
        height='3rem'
        marginBottom='6.5rem'
        marginLeft='auto'
        marginRight='auto'
        width='80%'
      />

      <SkeletonLoader
        borderRadius={borderRadiusXl}
        height='9rem'
        marginLeft='auto'
        marginRight='auto'
        width='86%'
      />
      <SkeletonLoaderWrapper>
        <SkeletonLoader
          borderRadius={borderRadiusXl}
          height='6.625rem'
          marginLeft='auto'
          marginRight='auto'
          position='absolute'
          right='12%'
          top='-1.375rem'
          width='12.375rem'
          withBorder
        />
      </SkeletonLoaderWrapper>

      <SkeletonLoader
        borderRadius={borderRadiusMd}
        height='3.25rem'
        marginBottom='1rem'
        marginLeft='auto'
        marginRight='auto'
        marginTop='8.5rem'
        width='75%'
      />
      <SkeletonLoader
        borderRadius={borderRadiusMd}
        height='3.25rem'
        marginLeft='auto'
        marginRight='auto'
        width='78%'
      />
    </div>
  );
};

export default AddonOverlayContentSkeleton;
