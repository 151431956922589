import { SignaturePermissionsFormAction, SignaturePermissionsFormState } from './types';

export const PermissionsReducer = (
  state: SignaturePermissionsFormState,
  action: SignaturePermissionsFormAction
): SignaturePermissionsFormState => {
  switch (action.type) {
    case 'changeSelectedPermissions': {
      return { ...state, selectedPermissions: action.selectedPermissions };
    }
  }
};
