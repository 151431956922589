import React, { useMemo } from 'react';
import { Route } from 'type-route';

import { useAddonFullScreenOverlay } from '../../../../../../hooks/billing/useAddonFullScreenOverlay';
import usePublicConfigs from '../../../../../../hooks/usePublicConfigs';
import { getAddonOverlayVideoPreview } from '../../../../../../lib/billing/getAddonOverlayVideoPreview';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAddonDataQuery } from '../../../../../../redux/services/resourceApis/billing/billingApi';
import { AddonDataResponse } from '../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { FullScreenOverlayStorybookProps } from '../../../../../../types/FullScreenOverlay';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../../publicApplication/applicationRouter';
import FullScreenOverlay from '../../../../shared/FullScreenOverlay/FullScreenOverlay';
import { OverlayContentWrapper } from '../../../../shared/FullScreenOverlay/styles';
import CardDetailsSection from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSection';
import CardDetailsSkeleton from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSectionSkeleton/CardDetailsSectionSkeleton';
import NextPayment from '../../../shared/BillingFullScreenOverlay/NextPayment/NextPayment';
import NextPaymentSkeleton from '../../../shared/BillingFullScreenOverlay/NextPayment/NextPaymentSkeleton/NextPaymentSkeleton';
import {
  ButtonsGroup,
  PaymentSummaryFooterWrapper,
} from '../../../shared/BillingFullScreenOverlay/styles';
import SubmitButton from '../../../shared/BillingFullScreenOverlay/SubmitButton/SubmitButton';
import SummarySkeleton from '../../../shared/BillingFullScreenOverlay/Summary/SummarySkeleton/SummarySkeleton';
import { AddonFullScreenOverlayFactoryProps } from '../AddonFullScreenOverlayFactory';
import {
  StyledMainSectionContent,
  StyledMainSectionWrapper,
  StyledPaymentSummaryWrapper,
} from '../shared/styles';
import AddonOverlayContentSkeleton from './AddonOverlayContentSkeleton/AddonOverlayContentSkeleton';
import FeatureList from './FeatureList/FeatureList';
import AddonInfoCard from './OverlayAddonInfoCard/AddonInfoCard';
import PreviewExperience from './PreviewExperience/PreviewExperience';
import QuestionsAndAnswers from './QuestionsAndAnswers/QuestionsAndAnswers';
import { TermsOfServiceLink, TermsOfServiceWrapper } from './styles';
import Summary from './Summary/Summary';

export type AddonFullScreenOverlayProps = {
  route: Route<typeof routes.addonOverlay>;
  currentDate?: Date;
} & FullScreenOverlayStorybookProps<AddonDataResponse> &
  AddonFullScreenOverlayFactoryProps;

const t = initTranslations('simple_pricing.addon_overlay_content');

const AddonFullScreenOverlay = ({
  route,
  isStorybookEnvironment,
  mockData,
  currentDate = new Date(),
}: AddonFullScreenOverlayProps) => {
  const { name } = route.params;
  const { configs } = usePublicConfigs();
  const queryResult = useAddonDataQuery({ name }, { skip: isStorybookEnvironment });
  const {
    isOpenedModals,
    setIsOpenedLightboxVideoPlayer,
    hasCardDetails,
    isLoadingSubmitButton,
    isDisabledSubmitButton,
    handleSubmit,
    closeFullScreenOverlay,
    data,
    isFetching,
  } = useAddonFullScreenOverlay(queryResult, isStorybookEnvironment, mockData);
  const addonData = data as AddonDataResponse;
  const isAvailableOverlayData = !isFetching && !!addonData;
  const videoPreviewSrc = getAddonOverlayVideoPreview(name);
  const showPreviewSection = videoPreviewSrc?.length;
  const addonPrice = useMemo(
    () => addonData?.addonItems?.find((addon) => addon.status === 'new')?.price,
    [addonData]
  );
  const showOverlayAddonInfoCard = !!addonPrice;

  return (
    <FullScreenOverlay
      fixedCloseIcon
      id='addon-full-screen-overlay'
      withOpenedModal={isOpenedModals}
    >
      <OverlayContentWrapper>
        <StyledMainSectionWrapper>
          <StyledMainSectionContent>
            {isAvailableOverlayData ? (
              <>
                {showOverlayAddonInfoCard && (
                  <AddonInfoCard
                    addonName={name}
                    features={addonData.info.features}
                    marketingName={addonData.info.marketingName}
                    price={addonPrice}
                    secondaryColor={addonData.info.secondaryColor}
                  />
                )}
                <FeatureList
                  featureDescriptions={addonData.info.featureDescriptions}
                  itemTitleColor={addonData.info.secondaryColor}
                  title={addonData.info.marketingSlogan}
                />
                {showPreviewSection && (
                  <PreviewExperience
                    closePlayerCallback={() => setIsOpenedLightboxVideoPlayer(false)}
                    openPlayerCallback={() => setIsOpenedLightboxVideoPlayer(true)}
                    overviewVideoLinks={addonData.info.overviewVideoLinks}
                    videoPreviewSrc={videoPreviewSrc}
                  />
                )}
                <QuestionsAndAnswers questionsAndAnswers={addonData.info.questionsAndAnswers} />
                <TermsOfServiceWrapper>
                  {t('terms_of_service.text')}
                  <TermsOfServiceLink
                    href={configs['TERMS_URL']}
                    target='_blank'
                    text={t('terms_of_service.link')}
                  />
                </TermsOfServiceWrapper>
              </>
            ) : (
              <AddonOverlayContentSkeleton />
            )}
          </StyledMainSectionContent>
        </StyledMainSectionWrapper>
        <StyledPaymentSummaryWrapper>
          {isAvailableOverlayData ? (
            <Summary
              addonItems={addonData.addonItems}
              currentDate={currentDate}
              newPlanData={addonData.newPlan}
              primaryColor={addonData.info.primaryColor}
            />
          ) : (
            <SummarySkeleton />
          )}
          <PaymentSummaryFooterWrapper>
            {isAvailableOverlayData ? (
              <>
                <CardDetailsSection
                  cardDetails={addonData.billingDetails.cardDetails}
                  isMaxSeatsCount={false}
                />
                <NextPayment newPlanData={addonData.newPlan} withDetails={false} />
              </>
            ) : (
              <>
                <CardDetailsSkeleton />
                <NextPaymentSkeleton withDetails={false} />
              </>
            )}
            <ButtonsGroup>
              <DefaultButton
                buttonType='secondary'
                id='addon-full-screen-overlay-cancel-button'
                onClick={closeFullScreenOverlay}
                text={t('cta.cancel')}
              />
              <SubmitButton
                handleSubmit={handleSubmit}
                hasCardDetails={hasCardDetails}
                id='addon-full-screen-overlay-primary-button'
                isDisabled={isDisabledSubmitButton}
                isLoading={isLoadingSubmitButton}
                text={t('cta.buy_now')}
              />
            </ButtonsGroup>
          </PaymentSummaryFooterWrapper>
        </StyledPaymentSummaryWrapper>
      </OverlayContentWrapper>
    </FullScreenOverlay>
  );
};

export default AddonFullScreenOverlay;
