import React, { useEffect, useReducer, useState } from 'react';
import { useTheme } from 'styled-components';
import { Route } from 'type-route';

import { useCheckNoTrialFlow } from '../../../../../../hooks/billing/useCheckNoTrialFlow';
import { useCloseFullScreenOverlay } from '../../../../../../hooks/billing/useCloseFullScreenOverlay';
import { useCreateSubscription } from '../../../../../../hooks/billing/useCreateSubscription';
import { useValidateUpdatePaymentMethodForm } from '../../../../../../hooks/billing/useValidateUpdatePaymentMethodForm/useValidateUpdatePaymentMethodForm';
import { checkCreditCardData } from '../../../../../../lib/billing/checkCreditCardData';
import { checkRequiredPaymentFields } from '../../../../../../lib/billing/checkRequiredPaymentFields';
import { getNewPlanTotalAmount } from '../../../../../../lib/billing/getNewPlanTotalAmount';
import { parseBillingDetailsResponse } from '../../../../../../lib/billing/parseBillingDetailsResponse';
import { setQueryParamsFromResponse } from '../../../../../../lib/billing/setQueryParamsFromResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { FetchUpcomingInvoiceDataResponse } from '../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import { billingPlanTypes } from '../../../../../../types/BillingPlanName';
import { FullScreenOverlayStorybookProps } from '../../../../../../types/FullScreenOverlay';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../../publicApplication/applicationRouter';
import routeTo from '../../../../publicApplication/routeTo';
import DiscountsSection from '../../../../shared/DiscountsSection/DiscountsSection';
import {
  MainSectionContent,
  MainSectionWrapper,
  OverlayContentWrapper,
} from '../../../../shared/FullScreenOverlay/styles';
import { SkeletonLoader } from '../../../../shared/SkeletonLoader/styles';
import { initState, reducer } from '../../../../shared/UpdatePaymentMethodForm/reducer';
import CardDetailsSection from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSection';
import CardDetailsSkeleton from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSectionSkeleton/CardDetailsSectionSkeleton';
import NewPlanCard from '../../../shared/BillingFullScreenOverlay/NewPlanCard/NewPlanCard';
import NewPlanCardSkeleton from '../../../shared/BillingFullScreenOverlay/NewPlanCard/NewPlanCardSkeleton/NewPlanCardSkeleton';
import {
  ButtonsGroup,
  PaymentSummaryFooterWrapper,
  PaymentSummaryWrapper,
} from '../../../shared/BillingFullScreenOverlay/styles';
import SummarySkeleton from '../../../shared/BillingFullScreenOverlay/Summary/SummarySkeleton/SummarySkeleton';
import BillingIntervalToggle from '../../../shared/BillingIntervalToggler/BillingIntervalToggle';
import AnnualSavings from '../AnnualSavings/AnnualSavings';
import AnnualSavingsSkeleton from '../AnnualSavings/AnnualSavingsSkeleton/AnnualSavingsSkeleton';
import NeedMoreSeats from '../ManagePlanOverlayContent/NeedMoreSeats/NeedMoreSeats';
import SeatsDecreasingError from '../SeatsDecreasingError/SeatsDecreasingError';
import { BillingIntervalWrapper, SeatsAndBillingIntervalWrapper } from '../shared/styles';
import Summary from '../Summary/Summary';
import TotalSeatsSelect from '../TotalSeatsSelect/TotalSeatsSelect';
import { useCheckAbilityToShowOverlay } from '../utils/useCheckAbilityToShowOverlay';
import { useFullScreenOverlay } from '../utils/useFullScreenOverlay';
import OverlayContentHeader from './OverlayContentHeader/OverlayContentHeader';
import PaymentMethodSection from './PaymentMethodSection/PaymentMethodSection';
import { Subtitle } from './styles';

const t = initTranslations('per_user_pricing.configure_plan_overlay_content');

export type ConfigurePlanOverlayContentProps = {
  route: Route<typeof routes.configurePlan>;
  currentDate?: Date;
} & FullScreenOverlayStorybookProps<FetchUpcomingInvoiceDataResponse>;

const ConfigurePlanOverlayContent = ({
  route,
  currentDate = new Date(),
  isStorybookEnvironment,
  mockData,
  showPaymentMethodSection,
}: ConfigurePlanOverlayContentProps) => {
  const fullScreenOverlayState = useFullScreenOverlay({
    route,
    isStorybookEnvironment,
    mockData,
  });
  const {
    createSubscription,
    isLoadingCreateSubscription,
    isSuccessCreateSubscription: isRedirectInProgress,
  } = useCreateSubscription();
  const canViewFullScreenOverlay = useCheckAbilityToShowOverlay('configure-plan');

  const {
    upcomingInvoiceData,
    seatsCount,
    seatsOptions,
    slug,
    isLoadingOrFetching,
    isAnnualInterval,
    isMaxSeatsCount,
    isPastDue,
    showDiscounts,
    showSeatsDecreasingError,
    selectedBillingInterval,
    setSelectedBillingInterval,
    selectedPlanName,
    setSelectedPlanName,
    selectedSeatsCount,
    setSelectedSeatsCount,
    setAppliedPromoCode,
    appliedPromoCode,
  } = fullScreenOverlayState;
  const isNoTrialFlow = useCheckNoTrialFlow();
  const closeFullScreenOverlay = useCloseFullScreenOverlay();
  const {
    constants: { spacerSm2 },
  } = useTheme();
  const [showPaymentSection, setShowPaymentSection] = useState(showPaymentMethodSection || false);
  const [isLoadingSubmitButton, setIsLoadingSubmitButton] = useState(false);
  const [formState, dispatch] = useReducer(reducer, initState);
  const { data: formData, isFormError, isStripeError, paymentDetailsChanged } = formState;
  const { formErrors, validateFields, validateField, clearFieldError, resetErrorState } =
    useValidateUpdatePaymentMethodForm(dispatch);
  const billingDetails = upcomingInvoiceData?.billingDetails;
  const cardDetails = billingDetails?.cardDetails;
  const hasCardDetails = cardDetails && checkCreditCardData(cardDetails);
  const isAvailableOverlayData = !isLoadingOrFetching && !!upcomingInvoiceData;
  const isPaymentError =
    showPaymentSection && (isFormError || isStripeError || !paymentDetailsChanged);
  const isDisabledSubmitButton = isLoadingSubmitButton || isPaymentError;
  const validRequiredFields = checkRequiredPaymentFields({
    formData,
    validateFields,
    withStateUpdate: false,
  });
  const showCardDetailsSection = hasCardDetails && validRequiredFields;
  const isPurchaseStep = showPaymentSection || showCardDetailsSection;
  const submitButtonTranslationKey = isPurchaseStep ? 'purchase' : 'go_to_payment';
  const secondaryButtonTranslationKey = isNoTrialFlow ? 'compare_plans' : 'cancel';

  const handleClickOnSubmitButton = () => {
    if (isPurchaseStep) {
      setIsLoadingSubmitButton(true);

      createSubscription({
        overlayParams: {
          interval: selectedBillingInterval,
          plan: selectedPlanName,
          quantity: selectedSeatsCount,
        },
        billingDetails: formData,
        coupon: appliedPromoCode,
        totalAmount: getNewPlanTotalAmount(upcomingInvoiceData?.newPlan),
        validRequiredFields: checkRequiredPaymentFields({ formData, validateFields }),
      }).then(() => setIsLoadingSubmitButton(false));
    } else {
      setShowPaymentSection(true);
    }
  };

  const handleClickOnSecondaryButton = () => {
    if (isNoTrialFlow) {
      routeTo(routes.comparePlans({ slug }));
    } else {
      closeFullScreenOverlay();
    }
  };

  useEffect(() => {
    const result = billingDetails && parseBillingDetailsResponse(billingDetails);
    result && dispatch({ type: 'setFormData', payload: result });
  }, [billingDetails]);

  useEffect(() => {
    setQueryParamsFromResponse({ selectedBillingInterval, selectedSeatsCount, selectedPlanName });
  }, [selectedBillingInterval, selectedPlanName, selectedSeatsCount]);

  useEffect(() => {
    setIsLoadingSubmitButton(
      isLoadingCreateSubscription || isLoadingOrFetching || isRedirectInProgress
    );
  }, [isLoadingCreateSubscription, isLoadingOrFetching, isRedirectInProgress]);

  useEffect(() => {
    !(canViewFullScreenOverlay || isRedirectInProgress) && routeTo(routes.billing({ slug }));
  }, [canViewFullScreenOverlay, slug, isRedirectInProgress]);

  return (
    <OverlayContentWrapper>
      {showPaymentSection ? (
        <PaymentMethodSection
          billingDetailsData={billingDetails}
          clearFieldError={clearFieldError}
          dispatch={dispatch}
          formData={formData}
          isFormProcessing={isLoadingCreateSubscription}
          paymentMethodFormErrors={formErrors}
          resetErrorState={resetErrorState}
          setShowPaymentSection={setShowPaymentSection}
          validateField={validateField}
          validateFields={validateFields}
        />
      ) : (
        <MainSectionWrapper>
          <MainSectionContent>
            <div>
              <OverlayContentHeader />
              {/*TODO: GS3 Replace mock data in the future tasks*/}
              {isAvailableOverlayData ? (
                <Subtitle text={t('subtitle', { count: 10 })} />
              ) : (
                <SkeletonLoader height='1.125rem' marginTop={spacerSm2} width='8.875rem' />
              )}
            </div>
            <SeatsAndBillingIntervalWrapper>
              <TotalSeatsSelect
                handleChange={setSelectedSeatsCount}
                isLoading={isLoadingOrFetching}
                isValid={!showSeatsDecreasingError}
                options={seatsOptions}
                seatsCount={seatsCount}
              />
              <BillingIntervalWrapper
                className='configure-plan-billing-interval-toggle-wrapper'
                isLoading={isLoadingOrFetching}
              >
                {isAvailableOverlayData ? (
                  <AnnualSavings newPlanName={upcomingInvoiceData.newPlan.name} />
                ) : (
                  <AnnualSavingsSkeleton />
                )}
                <BillingIntervalToggle
                  handleChange={setSelectedBillingInterval}
                  isAnnualChecked={isAnnualInterval}
                  isAnnualValueFirst
                  isDisabled={isPastDue}
                  isLoading={isLoadingOrFetching}
                />
              </BillingIntervalWrapper>
              {showSeatsDecreasingError && (
                <SeatsDecreasingError selectedSeatsCount={Number(selectedSeatsCount)} />
              )}
            </SeatsAndBillingIntervalWrapper>
            {isAvailableOverlayData ? (
              <NewPlanCard
                billingInterval={upcomingInvoiceData.newPlan.billingInterval}
                billingPlanList={billingPlanTypes}
                coupon={upcomingInvoiceData.coupon}
                id='configure-plan-card'
                newPlanName={upcomingInvoiceData.newPlan.name}
                seatsIncluded={upcomingInvoiceData.newPlan.seatsIncluded}
                selectedPlanName={selectedPlanName}
                setSelectedPlanName={setSelectedPlanName}
                totalPrice={upcomingInvoiceData.newPlan.totalPrice}
              />
            ) : (
              <NewPlanCardSkeleton />
            )}
          </MainSectionContent>
        </MainSectionWrapper>
      )}
      <PaymentSummaryWrapper className='configure-plan-overlay-payment-summary'>
        {isAvailableOverlayData ? (
          <Summary
            addonItems={upcomingInvoiceData.addonItems}
            appliedPromoCode={appliedPromoCode}
            coupon={upcomingInvoiceData.coupon}
            currentDate={currentDate}
            currentPlan={upcomingInvoiceData.currentPlan}
            isConfigurePlan
            isMaxSeatsCount={isMaxSeatsCount}
            newPlanData={upcomingInvoiceData.newPlan}
            setAppliedPromoCode={setAppliedPromoCode}
          />
        ) : (
          <SummarySkeleton />
        )}
        <PaymentSummaryFooterWrapper>
          {showDiscounts && isAvailableOverlayData && (
            <DiscountsSection
              coupon={upcomingInvoiceData.coupon}
              isManagePlanOverlayContent
              productName={upcomingInvoiceData.newPlan.name}
              showYearlyBillingPerk={isAnnualInterval}
            />
          )}
          {isAvailableOverlayData ? (
            showCardDetailsSection && (
              <CardDetailsSection cardDetails={cardDetails} isMaxSeatsCount={isMaxSeatsCount} />
            )
          ) : (
            <CardDetailsSkeleton />
          )}
          {isMaxSeatsCount ? (
            <NeedMoreSeats maxSeatsCount={seatsCount} />
          ) : (
            <ButtonsGroup>
              <DefaultButton
                buttonType='secondary'
                id='configure-plan-cancel-button'
                onClick={handleClickOnSecondaryButton}
                text={t(`cta.${secondaryButtonTranslationKey}`)}
              />
              <DefaultButton
                buttonType='primary'
                disabled={isDisabledSubmitButton}
                id='configure-plan-primary-button'
                loading={isLoadingSubmitButton}
                onClick={handleClickOnSubmitButton}
                text={t(`cta.${submitButtonTranslationKey}`)}
              />
            </ButtonsGroup>
          )}
        </PaymentSummaryFooterWrapper>
      </PaymentSummaryWrapper>
    </OverlayContentWrapper>
  );
};

export default ConfigurePlanOverlayContent;
