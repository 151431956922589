import React from 'react';

import ContentTabSection from './ContentTabSection';
import DelegationTabSection from './DelegationTabSection';
import GeneralTabSection from './GeneralTabSection';
import PeopleTabSection from './PeopleTabSection';

type Props = {
  activeTab?: string;
};

const ActiveSettingsTab = ({ activeTab }: Props) => {
  switch (activeTab) {
    case 'general':
      return <GeneralTabSection />;
    case 'content':
      return <ContentTabSection />;
    case 'people':
      return <PeopleTabSection />;
    case 'delegation':
      return <DelegationTabSection />;
    default:
      return <GeneralTabSection />;
  }
};

export default ActiveSettingsTab;
