import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { fontMd3, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const TemplatePreviewInfoContainer = styled.div`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd3} ${constants.spacerMd2}`};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${vars.borderSurface1} solid ${constants.borderWidthSm}`};
`;

export const TemplatePreviewInfoHeader = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const TemplatePreviewInfoHeaderLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TemplatePreviewInfoTitleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm1};
`;

export const TemplatePreviewInfoIconWrapper = styled.div`
  width: 2rem;
  height: ${({ theme: { constants } }) => constants.heightSm};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${vars.borderSurface1} solid ${constants.borderWidthSm}`};
  ${fontMd3};
`;

export const TemplatePreviewInfoDownloads = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5};
`;

export const TemplatePreviewInfoDownloadsIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const TemplatePreviewInfoDescription = styled.p`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
`;

export const TemplatePreviewInfoProTip = styled.div`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.accentStrong1};
  background-color: ${({ theme: { vars } }) => vars.accentSubdued2};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm4};
`;
