import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledStatusBlockText = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

interface StatusBlockTextProps {
  children: ReactNode;
}

const StatusBlockText = ({ children }: StatusBlockTextProps) => {
  return <StyledStatusBlockText>{children}</StyledStatusBlockText>;
};

export default StatusBlockText;
