import React from 'react';

import { ColumnComponentProps, CourseReportColumn } from '../types';
import CompletionPercentage from './CompletionPercentage';
import Order from './Order';
import Title from './Title';
import UpdatedAt from './UpdatedAt';
import UsersAssigned from './UsersAssigned';
import UsersCompletedCount from './UsersCompletedCount';

type TableColumnsProps = {
  [key in Exclude<CourseReportColumn, 'actions'>]: React.FC<ColumnComponentProps>;
};

const COLUMNS_KEYS: TableColumnsProps = {
  title: Title,
  average_completion: CompletionPercentage,
  users_completed_count: UsersCompletedCount,
  updated_at: UpdatedAt,
  position: Order,
  users_assigned_count: UsersAssigned,
};

const TableColumn = ({ columnName, record }: ColumnComponentProps) => {
  const Column = COLUMNS_KEYS[columnName as keyof TableColumnsProps];

  return <Column columnName={columnName} record={record} />;
};

export default TableColumn;
