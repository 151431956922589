import { Form, Formik } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import { sanitizeTags } from '../../../../../lib/sanitize';
import { useCheckModalState } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import { closeResponsibilityDetailsModal } from '../../../../../redux/domains/responsibilityDetailsModal/responsibilityDetailsModalSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { delegationPlannerApi } from '../../../../../redux/services/resourceApis/delegationPlanner/delegationPlannerApi';
import {
  useEditResponsibilityMutation,
  useGetEditResponsibilityDetailsModalDataQuery,
} from '../../../../../redux/services/resourceApis/responsibilities/responsibilitiesApi';
import { GetResponsibilityDetailsModalParams } from '../../../../../redux/services/resourceApis/responsibilities/types';
import { heapModalNameActionIds } from '../../../../../types/TaskTracking';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import CoreModal from '../../../../design_system/core/CoreModal';
import { mediaBreakpointSm } from '../../../../styled/Breakpoint';
import { extractDomainName } from '../../../DelegationPlanner/modals/utils';
import {
  DEFAULT_FREQUENCY,
  DEFAULT_WORKING_TIME,
} from '../../../DelegationPlanner/shared/constants/modals';
import { responsibilityDetailsModalValidationSchema } from '../../../DelegationPlanner/shared/utils/validators';
import { BoardResponseError } from '../../../DelegationPlanner/types';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import { LocalStorageKey } from '../../../publicApplication/utils/useLocalStorageState';
import ModalHeader from './ModalHeader/ModalHeader';
import ResponsibilityDetails from './ResponsibilityDetails/ResponsibilityDetails';
import { ResponsibilityDetailsFormData } from './ResponsibilityDetails/types';
import {
  ControlButtonsGroup,
  ModalContentWrapper,
  ModalHeaderContainer,
  PrimaryButtonWrapper,
} from './styles';
import {
  EditResponsibilitySubmitData,
  ModalHeaderData,
  ResponsibilityDetailsModalProps,
} from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal');
const INVALIDATE_TAGS_KEY: LocalStorageKey = 'invalidateDPSpecializationsTags';

const ResponsibilityDetailsModal: FC<ResponsibilityDetailsModalProps> = ({
  responsibiliableId,
  type,
  id,
}) => {
  const responsibilityQuery: GetResponsibilityDetailsModalParams = {
    id,
    type,
    responsibiliable_id: responsibiliableId as number,
  };

  const { data: responsibilityResponse } =
    useGetEditResponsibilityDetailsModalDataQuery(responsibilityQuery);
  const [modalHeadedData, setModalHeadedData] = useState<ModalHeaderData>({ name: '', error: '' });
  const heapActionIds = heapModalNameActionIds('responsibility-details-modal');
  const { isMenuOpen: isMoveResponsibilityMenuOpen } = useActiveMenuHandler({
    menuId: 'delegation-planner-move-responsibility-popup',
  });
  const { width } = useWindowResize();
  const dispatch = useAppDispatch();
  const isCurriculumModalOpen = useCheckModalState('delegationCreateAndConnectCurriculumModal');
  const displayControlButtonsFullWidth = width < mediaBreakpointSm;
  const [editResponsibility, result] = useEditResponsibilityMutation();
  const { isLoading, isSuccess, error } = result;
  const updateResponsibilityError = error as BoardResponseError;
  const processing = isLoading,
    disabled = isLoading || isMoveResponsibilityMenuOpen;

  const handleCloseRequest = () => {
    dispatch(closeResponsibilityDetailsModal());
  };

  const preprocessedContentUrls = useMemo(() => {
    return responsibilityResponse?.contentUrls.map((contentUrl) => ({
      ...contentUrl,
      title: contentUrl.title || extractDomainName(contentUrl.url),
    }));
  }, [responsibilityResponse?.contentUrls]);

  const initialFormValues: ResponsibilityDetailsFormData = {
    name: responsibilityResponse?.name || '',
    description: responsibilityResponse?.description || '',
    time: responsibilityResponse?.workingHours?.workingTime || DEFAULT_WORKING_TIME,
    frequency: responsibilityResponse?.workingHours?.frequency || DEFAULT_FREQUENCY,
    specializations: responsibilityResponse?.assignedResponsibilitySpecializations || [],
    contents_urls: preprocessedContentUrls || [],
  };

  const transformFormValuesForSubmission = (
    values: ResponsibilityDetailsFormData
  ): EditResponsibilitySubmitData => {
    const { description, name, time, frequency, specializations } = values;

    const assignedResponsibilityAttributes = {
      workingTime: time,
      frequency,
      assignedResponsibilitySpecializationsAttributes: specializations.map((specialization) => ({
        ...(specialization?.id && { id: specialization.id }),
        responsibilitySpecializationId: specialization?.responsibilitySpecializationId,
        specializationScore: specialization.specializationScore,
        color: specialization.color,
        terminology: specialization.terminology,
        ...(specialization._destroy && { _destroy: true }),
      })),
    };

    return {
      name: sanitizeTags(name),
      description,
      type,
      responsibiliableId: responsibiliableId as number,
      assignedResponsibilityAttributes,
      contentUrlsAttributes: values.contents_urls,
    };
  };

  const handleSubmit = async (values: ResponsibilityDetailsFormData) => {
    const submissionValues = transformFormValuesForSubmission(values);

    if (!responsibilityResponse) return;

    await editResponsibility({ id: responsibilityResponse?.id, params: submissionValues });
    setModalHeadedData((prevState) => ({ ...prevState, name: values.name }));
  };

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === INVALIDATE_TAGS_KEY) {
        dispatch(delegationPlannerApi.util.invalidateTags(['ResponsibilitySpecialization']));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch, handleStorageChange]);

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('error_message'),
    successFunction: handleCloseRequest,
  });

  useEffect(() => {
    if (updateResponsibilityError) {
      setModalHeadedData((prevState) => ({
        ...prevState,
        error: updateResponsibilityError.data.baseErrors[0],
      }));
    } else {
      setModalHeadedData({ name: '', error: '' });
    }
  }, [result, updateResponsibilityError, isSuccess]);

  useEffect(() => {
    const activeElementBlurTimeout = setTimeout(() => {
      const activeElement = document.activeElement as HTMLElement;

      activeElement && activeElement.blur();
    }, 0);

    return () => clearTimeout(activeElementBlurTimeout);
  }, []);

  return (
    <CoreModal
      closeIconAriaLabel={t('aria_label_cancel')}
      desktopSize='xl'
      disabledPadding={{ horizontal: { all: true }, vertical: { all: true } }}
      heapModalName='responsibility-details-modal'
      onCloseRequest={handleCloseRequest}
      visible={!isCurriculumModalOpen}
    >
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={responsibilityDetailsModalValidationSchema}
      >
        {({ handleSubmit }) => (
          <Form autoComplete='off'>
            <ModalHeaderContainer isError={!!modalHeadedData.error}>
              <ModalHeader
                headerData={modalHeadedData}
                responsibility={responsibilityResponse}
                type={type}
              />
            </ModalHeaderContainer>
            <ModalContentWrapper>
              <ResponsibilityDetails
                responsibiliableId={responsibiliableId as number}
                responsibility={responsibilityResponse}
                type={type}
              />
            </ModalContentWrapper>
            <ControlButtonsGroup>
              <DefaultButton
                buttonType='secondary'
                className={heapActionIds.cancel}
                disabled={disabled}
                fullWidth={displayControlButtonsFullWidth}
                id={heapActionIds.cancel}
                onClick={() => !processing && handleCloseRequest()}
                size='md'
                text={t('cancel')}
              />
              <PrimaryButtonWrapper>
                <DefaultButton
                  buttonType='primary'
                  className={heapActionIds.submit}
                  disabled={disabled}
                  fullWidth={displayControlButtonsFullWidth}
                  id={heapActionIds.submit}
                  loading={processing}
                  onClick={() => handleSubmit()}
                  size='md'
                  text={t('save_changes')}
                  type='button'
                />
              </PrimaryButtonWrapper>
            </ControlButtonsGroup>
          </Form>
        )}
      </Formik>
    </CoreModal>
  );
};

export default ResponsibilityDetailsModal;
