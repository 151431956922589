import styled from 'styled-components';

import P from '../../../../../../../design_system/text/P';
import { mediaBreakpointPxLg } from '../../../../../../../styled/Breakpoint';
import { fontMd1, fontSm5 } from '../../../../../../../styled/TypeSystem';

export const ConsiderThisPlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConsiderThisPlanTitle = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerSm1};
  ${fontMd1};
`;

export const ConsiderThisPlanButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
  gap: ${({ theme: { constants } }) => `calc(${constants.spacerSm3} + ${constants.spacerSm2})`};
  flex-direction: column;
  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: row;
  }
`;

export const StyledP = styled(P)`
  ${fontSm5};
`;
