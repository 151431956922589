import { FormikErrors, useFormik } from 'formik';
import React, { KeyboardEvent } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import {
  useCreateBoardMutation,
  useUpdateBoardMutation,
} from '../../../../../redux/services/resourceApis/boards/boardsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import useAutoSaveStatusUpdater from '../../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import { DelegationPlannerBoard } from '../../Board/types';
import { NAME_MAX_CHARACTERS } from '../../shared/constants/boards';
import { InfoMessageWrapper, StyledIcon, StyledInputField } from './styles';
import { BoardManageModalProps } from './types';

const t = initTranslations('delegation_planner.modals.board_manage_modal');

const BoardManageModal = ({ board, showModal, setShowModal, mode }: BoardManageModalProps) => {
  const [createBoard] = useCreateBoardMutation();
  const [updateBoard, { isLoading, isSuccess }] = useUpdateBoardMutation();
  const {
    slug,
    accountTerminology: {
      responsibility: { plural: responsibilityPlural },
    },
  } = useCurrentAccount();

  const { responsibility } = useAccountTerminology();
  const lowercaseResponsibilitiesTerm = responsibility.plural.toLowerCase();

  const formik = useFormik({
    initialValues: {
      name: board?.name || '',
    },
    validate: (values) => {
      const errors: FormikErrors<{ name: string }> = {};
      if (!values.name.trim()) {
        errors.name = t('inputs.error.empty');
      } else if (values.name.length > NAME_MAX_CHARACTERS) {
        errors.name = t('inputs.error.too_long', { max: NAME_MAX_CHARACTERS });
      }
      return errors;
    },
    onSubmit: (values) => {
      handleSave(values.name);
    },
  });

  const taskModalArgs: TaskModalProps = {
    title: mode === 'add' ? t('add_new_board') : t('edit_board_name'),
    subtitle: t('add_new_board_subtitle', { responsibilities: lowercaseResponsibilitiesTerm }),
    onCloseRequest: () => setShowModal(false),
    processing: false,
    primaryButtonText: t('save'),
    primaryButtonTask: formik.handleSubmit,
    heapModalName: 'board-manage-modal',
  };

  const handleSave = async (name: string) => {
    const boardData = { name };
    const boardId = board?.id;

    if (mode === 'add') {
      createBoard(boardData)
        .unwrap()
        .then((response: DelegationPlannerBoard) => {
          routeTo(routes.delegationPlannerBoard({ slug, id: response.id }));
        });
    } else if (mode === 'edit' && boardId) {
      await updateBoard({ id: boardId, ...boardData });
    }

    setShowModal(false);
  };

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  useAutoSaveStatusUpdater([
    {
      isSaving: isLoading,
      isSavedSuccessfully: isSuccess,
    },
  ]);

  if (!showModal) return <></>;

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <StyledInputField
        autoFocus
        errorText={formik.touched.name && formik.errors.name}
        name='name'
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        onKeyPress={handleEnterKeyPress}
        placeholder={t('add_new_board_placeholder', {
          responsibilities: responsibilityPlural.toLowerCase(),
        })}
        type='text'
        value={formik.values.name}
      />
      {!(formik.touched.name && formik.errors.name) && (
        <InfoMessageWrapper>
          <StyledIcon name='circle-info' />
          {t('board_admin_access_info')}
        </InfoMessageWrapper>
      )}
    </TaskModal>
  );
};

export default BoardManageModal;
